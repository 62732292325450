import React, { useEffect, useRef } from 'react'

import { FormApi } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'

import { AutoSubmit } from '@frontend/components/auto-submit'

import { FilterResetButton } from './filter-reset-button'
import { FilterCopyLinkButton } from './filter-copy-link-button'
import { ButtonWrapper } from './filters-components'

interface FiltersFormContainerProps {
    onFilterChange: (filters: any) => void
    initialValues: any
    children: React.ReactNode
    query?: any
    onLinkCopy?: (filters: any) => void
    linkAlways?: boolean
}

export const FiltersFormContainer = ({
    onFilterChange,
    initialValues,
    children,
    query,
    onLinkCopy,
}: FiltersFormContainerProps) => {
    const theForm = useRef<FormApi>()
    useEffect(() => {
        if (query && theForm.current) {
            for (const key in query) {
                if (query.hasOwnProperty(key)) {
                    theForm.current.change(key, query[key])
                }
            }
        }
    }, [initialValues])

    return (
        <Form onSubmit={onFilterChange} initialValues={initialValues} mutators={{ ...arrayMutators }}>
            {({ values, form, valid }) => {
                theForm.current = form
                const resetButtonVisible = JSON.stringify(values) !== JSON.stringify(initialValues)

                return (
                    <>
                        <AutoSubmit submit={() => valid && onFilterChange(values)} debounceTime={500} />
                        {children}
                        <ButtonWrapper>
                            {onLinkCopy && <FilterCopyLinkButton onClick={() => onLinkCopy(values)} />}
                            {resetButtonVisible && <FilterResetButton onClick={() => form.reset()} />}
                        </ButtonWrapper>
                    </>
                )
            }}
        </Form>
    )
}
