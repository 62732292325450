import React, { useContext, useEffect, useMemo } from 'react'

import { ApolloError } from 'apollo-client'

import { ProductColumnTypes } from '@bc/config'
import graphqlUtils from '@bc/graphql-utils'
import { tagManager } from '@bc/gtm'
import { extractLocale } from '@bc/translations'
import { GQLMaterial, GQLGetMaterialsFilters } from '@bc/types'
import {
    ContentType,
    getBasis,
    ResponsiveButton,
    Table,
    TableCell,
    TableColumnType,
    TableMobileLabel,
    TableRow,
    Text,
    Tooltip,
    TextHighlight,
} from '@bc/ui'
import { useFormatMessage, useFormatNumber, useFormatUom } from '@frontend/components/hooks'
import { MaterialDocuments } from '@frontend/components/material-documents'
import { TooltipWrapper } from '@frontend/components/tooltips'
import { PlatformConfigContext, SortContext, UserContext, FiltersContextData, FiltersContext } from '@frontend/context'

import { QueryResultList } from '../query-result-list'
import { MaterialPackaging } from '../cards'

export interface ProductsTableProps {
    error: ApolloError | undefined
    materials: GQLMaterial[] | undefined
    list: 'product list' | 'my products'
    onRequest: (material: GQLMaterial) => void
}

export const ProductsTable = ({ materials, error, list, onRequest }: ProductsTableProps): JSX.Element => {
    const t = useFormatMessage()
    const formatNumber = useFormatNumber()
    const formatUom = useFormatUom()

    const { shopConfig } = useContext(PlatformConfigContext)
    const { userLocale } = useContext(UserContext)
    const { onSortChange, sort } = useContext(SortContext)
    const filterContext: FiltersContextData<GQLGetMaterialsFilters> = useContext(FiltersContext)
    const {
        casNumber: filterCasNumber,
        einecsNumber: filterEinecsNumber,
        name: filterMaterialName,
        materialId: filterMaterialId,
    } = filterContext?.query ?? {}

    const { language } = extractLocale(userLocale)

    const columns = useMemo(() => {
        const allColumns: TableColumnType[] = [
            {
                name: 'materialId',
                sticky: false,
                ratio: 1,
                title: t('products:column.material-number'),
                type: ContentType.STRING,
                sortable: false,
                Content: ({ material, column }) => (
                    <>
                        {column.title && <TableMobileLabel>{column.title}</TableMobileLabel>}
                        {material?.id ? (
                            <div>
                                <Text data-test-class="text-product-number">
                                    <TextHighlight search={filterMaterialId}>{material?.id}</TextHighlight>
                                </Text>
                            </div>
                        ) : (
                            <>-</>
                        )}
                    </>
                ),
            },
            {
                name: 'name',
                sticky: false,
                ratio: 2,
                title: t('products:column.material'),
                type: ContentType.STRING,
                Content: ({ material, column }) => (
                    <>
                        {column.title && <TableMobileLabel>{column.title}</TableMobileLabel>}
                        {material?.name ? (
                            <div>
                                <Text data-test-class="text-product-name">
                                    <TextHighlight search={filterMaterialName}>{material?.name}</TextHighlight>
                                </Text>
                                <MaterialPackaging material={material} />
                            </div>
                        ) : (
                            <>-</>
                        )}
                    </>
                ),
            },
            {
                name: 'casNumber',
                sticky: false,
                ratio: 1,
                title: t('products:column.cas-no'),
                type: ContentType.STRING,
                Content: ({ material, column }) => {
                    const CASNumberList = graphqlUtils.material.getCASNumbers(material)
                    return (
                        <>
                            {column.title && <TableMobileLabel>{column.title}</TableMobileLabel>}
                            <Text data-test-class="text-cas-number">
                                {CASNumberList.length ? (
                                    CASNumberList.length === 1 ? (
                                        <TextHighlight search={filterCasNumber}>
                                            {CASNumberList[0] || '-'}
                                        </TextHighlight>
                                    ) : (
                                        <TooltipWrapper>
                                            {t('products:blend')}
                                            <Tooltip placement={'bottom'}>
                                                {CASNumberList.map(cas => (
                                                    <Text key={cas} block noMargin>
                                                        {cas}
                                                    </Text>
                                                ))}
                                            </Tooltip>
                                        </TooltipWrapper>
                                    )
                                ) : (
                                    '-'
                                )}
                            </Text>
                        </>
                    )
                },
            },
            {
                name: 'einecsNumber',
                sticky: false,
                ratio: 1,
                title: t('products:column.einecs'),
                type: ContentType.STRING,
                sortable: false,
                Content: ({ material, column }) => {
                    const einecsNumberList = graphqlUtils.material.getEinecsNumber(material)
                    return (
                        <>
                            {column.title && <TableMobileLabel>{column.title}</TableMobileLabel>}
                            <Text data-test-class="text-einecs">
                                {einecsNumberList.length ? (
                                    einecsNumberList.length === 1 ? (
                                        <TextHighlight search={filterEinecsNumber}>{einecsNumberList[0]}</TextHighlight>
                                    ) : (
                                        <TooltipWrapper>
                                            {t('products:blend')}
                                            <Tooltip placement={'bottom'}>
                                                {einecsNumberList.map(einecs => (
                                                    <Text key={einecs} block noMargin>
                                                        {einecs}
                                                    </Text>
                                                ))}
                                            </Tooltip>
                                        </TooltipWrapper>
                                    )
                                ) : (
                                    '-'
                                )}
                            </Text>
                        </>
                    )
                },
            },
            {
                name: 'unitCapacity',
                sticky: false,
                ratio: 0.75,
                title: t('products:column.unit-capacity'),
                type: ContentType.STRING,
                sortable: false,
                Content: ({ material: { packagingQuantity } = {}, column }) => (
                    <>
                        {column.title && <TableMobileLabel>{column.title}</TableMobileLabel>}
                        {packagingQuantity?.amount ? (
                            <Text data-test-class="text-unit-capacity">
                                {formatNumber(packagingQuantity.amount) + ' ' + formatUom(packagingQuantity.uom)}
                            </Text>
                        ) : (
                            <>-</>
                        )}
                    </>
                ),
            },
            {
                name: 'packagingName',
                sticky: false,
                ratio: 0.75,
                title: t('general:packaging'),
                type: ContentType.ARRAY_STRING,
                Content: ({ material: { packaging } = {}, column }) => (
                    <>
                        {column.title && <TableMobileLabel>{column.title}</TableMobileLabel>}
                        <Text data-test-class="text-packaging">{packaging?.name || '-'}</Text>
                    </>
                ),
            },
            {
                name: 'fillingWeight',
                sticky: false,
                ratio: 0.75,
                title: t('products:column.filling-weight'),
                type: ContentType.STRING,
                sortable: false,
                Content: ({ material: { packagingQuantity } = {}, column }) => (
                    <>
                        {column.title && <TableMobileLabel>{column.title}</TableMobileLabel>}
                        {packagingQuantity?.amount ? (
                            <Text data-test-class="text-filling-weight">
                                {formatNumber(packagingQuantity.amount) + ' ' + formatUom(packagingQuantity.uom)}
                            </Text>
                        ) : (
                            <>-</>
                        )}
                    </>
                ),
            },
            {
                name: 'supplier',
                sticky: false,
                ratio: 0.75,
                title: t('products:column.supplier'),
                type: ContentType.ARRAY_STRING,
                sortable: false,
                Content: ({ material: { manufacturer } = {}, column }) => (
                    <>
                        {column.title && <TableMobileLabel>{column.title}</TableMobileLabel>}
                        <Text data-test-class="text-supplier">{manufacturer?.name || '-'}</Text>
                    </>
                ),
            },
            {
                name: 'msdsFile',
                sticky: false,
                ratio: 1,
                overflowVisible: true,
                title: t('general:documents'),
                type: ContentType.STRING,
                Content: ({ column, material }) =>
                    material ? (
                        <MaterialDocuments material={material} title={column.title} language={language} />
                    ) : null,
            },
            {
                name: 'actions',
                sortable: false,
                sticky: false,
                ratio: 1,
                type: ContentType.STRING,
                overflowVisible: true,
                Content: ({ material }) =>
                    material?.name ? (
                        <ResponsiveButton
                            icon="Question"
                            variant="action"
                            onClick={() => {
                                tagManager.tags.productRequestInformationStart(material)
                                onRequest(material)
                            }}
                            data-test-class="button-rfi">
                            {t('product-list:request-info')}
                        </ResponsiveButton>
                    ) : null,
            },
        ]
        return allColumns.filter((column: TableColumnType) =>
            shopConfig.current?.productListColumns.includes(column.name as ProductColumnTypes),
        )
    }, [filterCasNumber, filterEinecsNumber, filterMaterialName, filterMaterialId, language])

    useEffect(() => {
        fireTag(materials)
    }, [materials])

    const fireTag = (updatedMaterials: GQLMaterial[] = []) => {
        if (updatedMaterials.length > 0) {
            tagManager.tags.productImpressions(updatedMaterials, list)
        }
    }

    return (
        <Table columns={columns} onChangeSort={onSortChange} sort={sort} data-test-id="table-product-list">
            <QueryResultList error={error} amountOfResults={materials?.length} noResultText={t('not-found:orders')}>
                {materials?.map((material, index) => (
                    <TableRow
                        key={`${material.id}-${index}`}
                        coloured={index % 2 === 0}
                        data-test-id={`row-product-${material.id}`}>
                        {columns.map((column, columnIndex) => (
                            <TableCell
                                key={column.name}
                                ratio={column.ratio}
                                first={columnIndex === 0}
                                overflowVisible={column.overflowVisible}
                                hiddenMin={column.hiddenMin}
                                hiddenMax={column.hiddenMax}
                                mobileWidth={column.mobileWidth}
                                basis={getBasis(columns, column.ratio)}>
                                <column.Content column={column} material={material} />
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </QueryResultList>
        </Table>
    )
}
