// CSS Transition
interface TransitionConfig {
    duration: string
    easing: string
    config?: string
}
export const transition: TransitionConfig = {
    duration: '500ms',
    easing: 'ease-in-out',
}
transition.config = `${transition.duration} ${transition.easing}`
