
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MaterialProductFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Material"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"product"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ProductFragment"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"ProductExtendedFragment"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"documents"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DocumentFragment"},"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":315}};
    doc.loc.source = {"body":"#import './product.fragment.graphql'\n#import './product-extended.fragment.graphql'\n#import './document.fragment.graphql'\n\nfragment MaterialProductFragment on Material {\n    product {\n        ...ProductFragment\n        ...ProductExtendedFragment\n        documents {\n            ...DocumentFragment\n        }\n    }\n} ","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./product.fragment.graphql').definitions));
doc.definitions = doc.definitions.concat(unique(require('./product-extended.fragment.graphql').definitions));
doc.definitions = doc.definitions.concat(unique(require('./document.fragment.graphql').definitions));


      module.exports = doc;
    
