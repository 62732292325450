import * as customer from './customer'
import * as dev from './dev-mode'
import * as document from './document'
import * as material from './material'
import * as order from './order'
import * as price from './price'
import * as user from './user'

export default {
    customer,
    document,
    dev,
    material,
    order,
    price,
    user,
}
