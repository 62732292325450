import React from 'react'

import { colors } from '@bc/ui/src/config'

import * as OrderedListComponents from './ordered-list-components'

export interface OrderedListProps {
    items: string[]
    numberColor?: keyof typeof colors
    color?: keyof typeof colors
}

export const OrderedList: React.SFC<OrderedListProps> = ({ color, numberColor, items = [] }) => (
    <OrderedListComponents.List>
        {items.map((item: string, index: number) => (
            <OrderedListComponents.ListItem color={color} numberColor={numberColor} key={`${item}-${index}`}>
                {item}
            </OrderedListComponents.ListItem>
        ))}
    </OrderedListComponents.List>
)
