import React from 'react'

import styled from 'styled-components'
import { colors, spacing, transition } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'
import { SvgIconButton } from '../svg/svg-icon'
import { SvgIconSize } from '../svg'
import { TextProps, Text } from '../text'

export const Wrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    padding: ${spacing.xs + 1}px;
    width: 300px;
`

export const Content = styled.div<ToggleBaseProps>`
    display: flex;
    flex-direction: column;
    gap: ${spacing.xxs}px;
    transition: ${transition.config};
    transition-property: opacity;
    ${media.max('md')} {
        opacity: ${({ open }) => (open ? 0 : 1)};
        padding-top: ${spacing.xs}px;
    }

    ${media.min('md')} {
        opacity: ${({ open }) => (open ? 1 : 0)};
    }
`

interface ToggleBaseProps {
    open: boolean
}

export interface ToggleProps extends ToggleBaseProps {
    onClick?: VoidFunction
    size?: SvgIconSize
}
export const ToggleButton = styled(SvgIconButton)<ToggleProps>`
    transition: transform ${transition.config};
    transform: ${({ open }) => (open ? 'rotate(0deg)' : 'rotate(180deg)')};
    margin-left: 1rem;
`

export const Toggle = (props: ToggleProps) => (
    <ToggleButton icon="Chevron_Left" size="20" fill={colors.deepPurple} {...props} />
)

const toggleMs = '250ms'
const toggleAni = (props: string, delay = '') => `${props} ${toggleMs} ${transition.easing} ${delay}`

export const AnimatedToggleWrapper = styled.div<ToggleBaseProps>`
    position: relative;
    display: none;
    justify-content: space-between;
    align-self: flex-end;
    margin: 5px 0 25px 0;
    z-index: 10;
    white-space: nowrap;

    ${media.min('md')} {
        height: auto;
        display: flex;
    }

    transform-origin: right;

    transition: ${[toggleAni('transform'), toggleAni('min-width', toggleMs)].join(',')};
    transform: rotate(0deg);
    min-width: 100%;

    ${({ open }) =>
        !open
            ? `
                transform: rotate(-90deg);
                transition: ${[toggleAni('min-width'), toggleAni('transform', toggleMs)].join(',')};
                min-width: 0%;
            `
            : ``}

    & > button {
        ${({ open }) =>
            `
            transform: rotate(${open ? '0' : '-90'}deg);
            transition: ${toggleAni('transform', open ? toggleMs : undefined)};
        `}
    }

    & .filter-counter {
        ${({ open }) =>
            `
            transform: rotate(${open ? '0' : '90'}deg);
            transition: ${toggleAni('transform', open ? toggleMs : undefined)};
        `}
  },
`

export const ToolbarToggle = styled.button<ToggleBaseProps>`
    display: flex;
    align-items: center;
    z-index: 2;
    perspective: 100;

    ${media.min('md')} {
        display: none;
    }
`

interface FilterProps {
    open: boolean
}

export const Filter = styled.aside`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    min-height: 100vh;
    border-right: 1px solid ${colors.neutral20};
    overflow: auto;
    z-index: 1;
    flex-shrink: 0;
    background: ${colors.white};
    flex-direction: row-reverse;

    ${media.min('md')} {
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        height: auto;
        max-height: none;
        box-shadow: none;
        transition: width ${transition.config};
        width: ${({ open }: FilterProps) => (open ? `300px` : `56px`)};
    }

    ${media.max('md')} {
        transition: ${({ open }: FilterProps) =>
            open
                ? `transform ${transition.config}, max-height 0s ${transition.duration}`
                : `transform ${transition.config}`};

        max-height: ${({ open }: FilterProps) => (open ? 0 : '100000000px')};
        transform: ${({ open }: FilterProps) => (open ? 'translateX(-100%)' : 'none')};
        padding-top: 20px;
    }
`

export const Toolbar = styled.section`
    position: relative;
    padding: ${spacing.xxs}px ${spacing.xs}px;
    padding-top: ${spacing.xs}px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    background: ${colors.white};

    ${media.min('md')} {
        background: transparent;
        padding: 0;
        z-index: auto;
    }
`
interface TextWithCounterProps extends TextProps {
    counter: number
}
export const TextWithCounter = styled(Text)<TextWithCounterProps>``
