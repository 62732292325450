import React, { useContext } from 'react'

import { useApolloClient, useMutation } from '@apollo/react-hooks'

import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'

import { Button, FullScreenLoaderRound, Text, useDocumentTitle } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { markdownRenderers } from '@frontend/utils'

import { PlatformConfigContext, SessionContext, SessionContextData, UserContext } from '@frontend/context'

import { getPrivacyPolicyUrl } from '@bc/graphql-utils/src/user'
import MeQuery from '../../graphql/me/me.graphql'
import patchUserDataMutation from './query.graphql'
import * as TermsOfUsageComponents from './styles/terms-of-usage-components'

export const AcceptTermsOfUsage = () => {
    const t = useFormatMessage()
    useDocumentTitle(t('terms-of-usage:headline'))

    const { appConfig } = useContext(PlatformConfigContext)
    const { me } = useContext(UserContext)
    const [sessionStore]: SessionContextData = useContext(SessionContext)

    const client = useApolloClient()
    const refetchQueries = () => [{ query: MeQuery }]
    const [acceptTermsMutation, { loading }] = useMutation(patchUserDataMutation, {
        awaitRefetchQueries: true,
        refetchQueries,
    })

    const updatedTerms = me && !me.acceptedTerms

    const acceptTerms = async () => {
        const acceptedTerms = {
            version: appConfig.termsOfUsageVersion,
            acceptedAt: new Date(),
        }

        await client.cache.reset()

        await acceptTermsMutation({
            variables: { acceptedTerms },
        })
    }

    const rejectTerms = async () => {
        sessionStore.logout()
    }

    return (
        <>
            <FullScreenLoaderRound isVisible={loading} />
            <TermsOfUsageComponents.Title>
                <Text h1 noMargin data-test-id="text-page-title">
                    {t('terms-of-usage:headline')}
                </Text>
            </TermsOfUsageComponents.Title>
            {updatedTerms && (
                <TermsOfUsageComponents.Subtitle>
                    <Text p color="neutral90">
                        {t('terms-of-usage:updated')}
                    </Text>
                </TermsOfUsageComponents.Subtitle>
            )}
            <TermsOfUsageComponents.Box>
                <TermsOfUsageComponents.Scroller>
                    <ReactMarkdown
                        renderers={markdownRenderers}
                        source={t('terms-of-usage:content', undefined, {
                            dataPrivacyLink: getPrivacyPolicyUrl(me?.meta),
                        })}
                        plugins={[breaks]}
                    />
                </TermsOfUsageComponents.Scroller>
            </TermsOfUsageComponents.Box>

            <TermsOfUsageComponents.ButtonBar>
                <Button
                    variant="outline"
                    title={t('terms-of-usage:decline')}
                    onClick={rejectTerms}
                    data-test-id="button-reject-terms">
                    {t('terms-of-usage:decline')}
                </Button>
                <Button
                    variant="action"
                    title={t('terms-of-usage:accept')}
                    onClick={acceptTerms}
                    data-test-id="button-accept-terms">
                    {t('terms-of-usage:accept')}
                </Button>
            </TermsOfUsageComponents.ButtonBar>
        </>
    )
}
