import { Li, Ul } from '@bc/ui/src/components/form-elements/components/upload-files-components'
import React from 'react'

import { Text, Button } from '@bc/ui'

interface ListAttachedFilesProps {
    file?: File
    change: any
}

export const ListAttachedFiles = ({ file, change }: ListAttachedFilesProps) => {
    const removeFile = () => change('file', undefined)

    return (
        <>
            {file && (
                <Ul>
                    <Li>
                        <Text noMargin>{file.name}</Text>
                        <Button onClick={() => removeFile()} variant="error" icon="Delete" buttonSize="small" />
                    </Li>
                </Ul>
            )}
        </>
    )
}
