import React, { useContext, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { MutationFunction } from '@apollo/react-common'
import { useApolloClient } from '@apollo/react-hooks'

import { Resources } from '@bc/config'
import { GQLBaseOrderLine, GQLCustomer, GQLMainSalesArea } from '@bc/types'
import { CompaniesFilters, useFormatMessage, useHasAccess } from '@frontend/components'
import {
    FiltersContextData,
    FiltersProvider,
    SessionContext,
    SessionContextData,
    ShoppingCartContext,
    ShoppingCartContextData,
    UserContext,
} from '@frontend/context'

import { useFilters } from '@frontend/components/hooks/use-filters'
import { MyCompaniesListPage } from './my-companies-list-page'
import { MyCompaniesSearchPage } from './my-companies-search-page'

interface GqlProps {
    patchUserData: MutationFunction
}

type FilterOperator = 'contains' | 'starts-with'

type CompaniesPageQuery = CompaniesFilters

type MyCompaniesPageCombinedProps = RouteComponentProps<{ page: string }> & GqlProps
const initialFilters: CompaniesFilters = {}

const MyCompaniesPageContainer = ({ history }: MyCompaniesPageCombinedProps) => {
    const t = useFormatMessage()

    const [shoppingStore]: ShoppingCartContextData<GQLBaseOrderLine> = useContext(ShoppingCartContext)
    const [sessionStore]: SessionContextData = useContext(SessionContext)
    const hasAccess = useHasAccess()
    const client = useApolloClient()
    const { me } = useContext(UserContext)

    const { filters, updateFilters } = useFilters<CompaniesFilters, CompaniesPageQuery>()

    const [customers, setCustomers] = useState<GQLCustomer[]>(me?.customers ?? [])

    const getFilteredCustomers = (newFilters: CompaniesFilters) => {
        if (!me?.customers) {
            return []
        }

        return me.customers
            .filter(Boolean)
            .filter(filterCustomerBy('name', newFilters.name!, 'contains'))
            .filter(filterCustomerBy('id', newFilters.id!, 'contains'))
    }

    const filterCustomerBy = (filter: keyof CompaniesFilters, value: string, operator: FilterOperator) => (
        customer: GQLCustomer,
    ) => {
        if (!value) {
            return true
        }

        switch (operator) {
            case 'contains': {
                return String(customer[filter]).toLowerCase().indexOf(value.toLowerCase()) > -1
            }

            case 'starts-with':
            default: {
                return String(customer[filter]).toLowerCase().indexOf(value.toLowerCase()) === 0
            }
        }
    }

    const handleFilterChange = (newFilters: CompaniesFilters) => {
        const filteredCustomers = getFilteredCustomers(newFilters)

        updateFilters(newFilters)
        setCustomers(filteredCustomers)
    }

    const handleSelectCustomer = async (customer: GQLCustomer, mainSalesArea: GQLMainSalesArea) => {
        sessionStore.setActiveCustomer({
            cid: customer.id,
            so: mainSalesArea.salesOrganisation,
            sid: mainSalesArea.salesAreaId,
        })

        shoppingStore.clear()
        await client.resetStore()

        history.push(t('route:order-history'), { page: 1, sort: '-baseOrderDate' })
    }

    const filterProviderProps: FiltersContextData<CompaniesFilters> = {
        onFilterChange: handleFilterChange,
        filters: initialFilters,
        query: filters,
    }

    return hasAccess(Resources.SearchCustomers) ? (
        <MyCompaniesSearchPage onSelectCustomer={handleSelectCustomer} />
    ) : (
        <FiltersProvider {...filterProviderProps}>
            <MyCompaniesListPage customers={customers} onSelectCustomer={handleSelectCustomer} />
        </FiltersProvider>
    )
}

export default withRouter(MyCompaniesPageContainer)
