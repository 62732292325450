import styled from 'styled-components'

import { media } from '@bc/ui/src/helpers'
import { gridConfig } from './grid-config'

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -${gridConfig.gridGutterSmall}px;
    margin-right: -${gridConfig.gridGutterSmall}px;
    margin-bottom: ${gridConfig.gridGutterSmall}px;
    &:last-child {
        margin-bottom: 0;
    }
    ${media.min('md')} {
        margin-left: -${gridConfig.gridGutter}px;
        margin-right: -${gridConfig.gridGutter}px;
        margin-bottom: ${gridConfig.gridGutter}px;
    }
`
