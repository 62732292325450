import React, { useMemo, useState } from 'react'
import { GQLCustomer } from '@bc/types'
import { ShopFeatures, Resources, RegionFeatures } from '@bc/config'
import { SvgIcon, colors } from '@bc/ui'

import { useFormatMessage, useHasAccess } from '../hooks'
import * as NavComponents from './navigation-components'

interface MainMenuProps {
    currentCustomer?: GQLCustomer
}
export const MainMenu = ({ currentCustomer }: MainMenuProps) => {
    const t = useFormatMessage()
    const hasAccess = useHasAccess()
    const [open, setOpen] = useState(false)

    const myCompaniesLink = {
        href: t('route:my-customers'),
        title: t('route:my-customers.label'),
        id: 'my-customers',
    }

    const userManagementLink = {
        href: t('route:contacts'),
        title: t('route:contacts.label'),
        id: 'contacts',
    }

    const orderHistory = {
        href: t('route:order-history'),
        title: t('route:order-history.label'),
        id: 'order-history',
    }

    const quotes = {
        href: t('route:quotes'),
        title: t('route:quotes.label'),
        id: 'quotes',
    }

    const orderInvoices = {
        href: t('route:invoices'),
        title: t('route:invoices.label'),
        id: 'invoices',
    }

    const productList = {
        href: t('route:product-list'),
        title: t('route:product-list.label'),
        id: 'product-list',
    }

    const requests = {
        href: t('route:requests'),
        title: t('route:requests.label'),
        id: 'requests',
    }

    // @todo cleanup
    const getPrivateLinks = () => {
        const links = []

        // get CSR dashboard links?
        if (currentCustomer) {
            if (hasAccess(ShopFeatures.Quotes) && hasAccess(Resources.Quotes)) {
                links.push(quotes)
            }

            if (hasAccess(Resources.Orders)) {
                links.push(orderHistory)
            }

            if (hasAccess(ShopFeatures.Invoices) && hasAccess(Resources.Invoices)) {
                links.push(orderInvoices)
            }

            if (hasAccess(Resources.ProductList)) {
                links.push(productList)
            }

            if (hasAccess(RegionFeatures.Requests) && hasAccess(Resources.Requests)) {
                links.push(requests)
            }
        } else {
            if (hasAccess(Resources.MyCompanies)) {
                links.push(myCompaniesLink)
            }

            // who has access to Users, also has Request with no customer
            if (hasAccess(Resources.Users)) {
                links.push(userManagementLink)

                if (hasAccess(RegionFeatures.Requests) && hasAccess(Resources.Requests)) {
                    links.push(requests)
                }
            }
        }

        return links
    }

    const privateLinks = useMemo(getPrivateLinks, [currentCustomer])

    return (
        <NavComponents.NavWrapper>
            <NavComponents.BurgerMenu onClick={() => setOpen(true)}>
                <SvgIcon size="24" icon="Menu" fill={colors.white} />
            </NavComponents.BurgerMenu>
            <NavComponents.Links open={open}>
                <NavComponents.CloseButton onClick={() => setOpen(false)}>
                    <SvgIcon size="24" icon="Close" />
                </NavComponents.CloseButton>
                {privateLinks.map(l => (
                    <NavComponents.Link
                        key={l.title}
                        to={l.href}
                        title={l.title}
                        exact
                        onClick={() => setOpen(false)}
                        data-test-id={`link-${l.id}`}>
                        {l.title}
                    </NavComponents.Link>
                ))}
            </NavComponents.Links>
        </NavComponents.NavWrapper>
    )
}
