import React, { useContext } from 'react'

import { FormApi } from 'final-form'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import { MessageMap } from '@bc/translations'
import { GQLRequest, GQLRequestStatus, GQLUpdateRequestInput } from '@bc/types'
import { TextInput, Button, SelectInput, selectFindOption, FormInputComponents } from '@bc/ui'
import { UploadFiles } from '@bc/ui/src/components/form-elements/upload-files'

import { FormatMessage } from '@frontend/components/hooks'
import { ToastsContext, ToastsContextData } from '@frontend/context'
import { finalFormValidations } from '@frontend/utils'

import * as SharedFormComponents from './_shared'

const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    text-align: left;
    flex-grow: 1;
`

const Stretched = styled.div`
    flex-grow: 1;
`

interface EditRequestFormProps {
    onConfirm: (values: GQLUpdateRequestInput) => void
    onCancel: () => void
    open: boolean
    t: FormatMessage
    request: GQLRequest
}

export const EditRequestForm = ({ t, request, onConfirm, onCancel }: EditRequestFormProps) => {
    const maxFileSizeMb = 10

    const [toastsStore]: ToastsContextData = useContext(ToastsContext)
    const onSubmit = (values: GQLUpdateRequestInput, form: FormApi<GQLUpdateRequestInput>) => {
        onConfirm(values)
        setTimeout(form.reset, 0)
    }

    const mimeTypes = ['image/jpeg', 'image/gif', 'image/png', 'application/pdf']

    const filterFiles = (files: any[]): any[] => {
        const maxFileSize = maxFileSizeMb * 1024 * 1024
        const maxPayloadSize = maxFileSizeMb * 2.5 * 1024 * 1024
        let payloadSize = 0

        return files.filter((file: File) => {
            payloadSize += file.size
            // Filter max file-size
            if (file.size > maxFileSize) {
                toastsStore.addToast({
                    message: t('requests:file-too-big', undefined, { name: file.name }),
                    type: 'error',
                })
                return false
            }
            // Filter max payload
            if (payloadSize > maxPayloadSize) {
                toastsStore.addToast({
                    message: t('requests:max-upload', undefined, { name: file.name, mb: '25' }),
                    type: 'error',
                })
                return false
            }
            // Filter allowed mime types
            if (!mimeTypes.includes(file.type)) {
                toastsStore.addToast({
                    message: t('requests:mime-types', undefined, {
                        name: file.name,
                        type: file.type,
                    }),
                    type: 'error',
                })
                return false
            }

            return true
        })
    }

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{
                status: request.status,
                comments: request.comments,
            }}>
            {({ handleSubmit, form, values }) => (
                <FormWrapper onSubmit={handleSubmit}>
                    <Stretched>
                        <FormInputComponents.InputWrapper>
                            <Field
                                name="status"
                                parse={option => option?.value || ''}
                                validate={finalFormValidations.required(t)}
                                render={({ input }) => {
                                    const { value, ...restInput } = input
                                    const options = Object.keys(GQLRequestStatus).map(s => ({
                                        value: s,
                                        label: t(`request-status:${s}` as keyof MessageMap),
                                    }))
                                    const selectedOption = selectFindOption(options, value)

                                    return (
                                        <SelectInput
                                            {...restInput}
                                            value={selectedOption}
                                            labelText={t('requests:status')}
                                            options={options}
                                            data-test-id="select-request-status"
                                        />
                                    )
                                }}
                            />
                            <Field
                                name="comments"
                                render={({ input }) => (
                                    <TextInput
                                        labelText={t('requests:comments')}
                                        {...input}
                                        textarea
                                        placeholder={t('general:type-here')}
                                        data-test-id="input-comments"
                                    />
                                )}
                            />
                            <Field
                                name="files[]"
                                render={({ input }) => (
                                    <UploadFiles
                                        {...input}
                                        label={t('requests:documents')}
                                        fileInputLabel={t('general:upload')}
                                        maxUpload={t('requests:documents-max-size', undefined, {
                                            maxSize: maxFileSizeMb,
                                        })}
                                        mimeTypes={mimeTypes}
                                        filterFiles={filterFiles}
                                        deleteFiles={values.deleteFiles}
                                        files={request.files}
                                        change={form.change}
                                        multiple={true}
                                    />
                                )}
                            />
                        </FormInputComponents.InputWrapper>
                    </Stretched>
                    <SharedFormComponents.ButtonsWrapper>
                        <Button
                            onClick={onCancel}
                            variant="outline"
                            title={t('general:cancel')}
                            data-test-id="button-cancel">
                            {t('general:cancel')}
                        </Button>
                        <Button
                            type="submit"
                            variant="action"
                            title={t('general:save-and-send')}
                            data-test-id="button-submit-request">
                            {t('general:save-and-send')}
                        </Button>
                    </SharedFormComponents.ButtonsWrapper>
                </FormWrapper>
            )}
        </Form>
    )
}
