import { EventTag, VirtualPageviewTag } from '../types'
import { dataLayer } from '../utils'

export const customerSearch = (query: string) => {
    dataLayer<EventTag>({
        event: 'gtm.customerSearch',
        eventCategory: 'csr',
        eventAction: 'customer search',
        eventLabel: query,
    })
}

export const searchUsername = (query: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.contactsSearchUsername',
        virtualURL: `/contactssearchusername?q=${query}&n=contactssearchusernamebar`,
        virtualTitle: 'contacts search - search username',
    })
}

export const searchCustomerId = (query: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.contactsSearchId',
        virtualURL: `/contactssearchid?q=${query}&n=contactssearchidbar`,
        virtualTitle: 'contacts search - search customer id',
    })
}

const getMappedSort = (sort: string) => {
    let sortOrder = 'ascending'
    let sortType = sort

    if (sortType[0] === '-') {
        sortOrder = 'descending'
        sortType = sortType.substr(1)
    }

    return `${sortType}: ${sortOrder}`
}

export const contactsSort = (sort: string) => {
    dataLayer<EventTag>({
        event: `gtm.contactsSort`,
        eventCategory: 'contacts',
        eventAction: 'sort',
        eventLabel: getMappedSort(sort),
    })
}

export const createUser = (id: string) => {
    dataLayer<EventTag>({
        event: 'gtm.contactsCreateAccount',
        eventCategory: 'contacts',
        eventAction: 'create account',
        eventLabel: id,
    })
}
