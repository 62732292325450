import { blueTheme, darkTheme, defaultTheme, lightTheme, sidebarTheme } from './themes'
export type CardTheme = 'none' | 'light' | 'blue' | 'dark' | 'sidebar'

export interface CardThemeConfig {
    wrapper?: string
    body?: string
    footer?: string
    header?: string
}
interface CartThemes {
    light: CardThemeConfig
    dark: CardThemeConfig
    blue: CardThemeConfig
    none: CardThemeConfig
    sidebar: CardThemeConfig
}

const cardThemes: CartThemes = {
    light: lightTheme,
    dark: darkTheme,
    blue: blueTheme,
    none: defaultTheme,
    sidebar: sidebarTheme,
}
export const getCardThemeConfig = (theme: CardTheme): CardThemeConfig =>
    cardThemes[theme] ? cardThemes[theme] : defaultTheme
