import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'

import { default as UserManagementContainer } from './user-management-container'

export class UserManagementPage extends React.Component<RouteComponentProps<undefined>> {
    public render() {
        const {
            match: { url },
        } = this.props

        return <Route path={url} exact component={UserManagementContainer} />
    }
}
