// tslint:disable no-invalid-template-strings

import { MessageMap } from '../../'

const messages: MessageMap = {
    'gender:male': 'Masculin',
    'gender:female': 'Féminin',
    'gender:unknown': 'Inconnu',

    // Routes
    'route:maintenance': '/maintenance',
    'route:document': '/document',
    'route:document-not-found': '/document-not-found',

    'route:session-expired': '/session-expired',

    'route:logout': '/logout',
    'route:logout.label': 'Se déconnecter',

    'route:reset-password': '/reset-password',
    'route:reset-password.label': 'Réinitialiser votre mot de passe',

    'route:set-password': '/set-password',
    'route:set-password.label': 'Initialiser le mot de passe',

    'route:forgot-password': '/forgot-password',

    'route:terms-of-usage': '/terms-of-usage',
    'route:terms-of-usage.label': 'Conditions d’utilisation',

    'route:product-list': '/product-list',
    'route:product-list.label': 'Produits',

    'route:order-history': '/my-orders',
    'route:order-history.label': 'Commandes',

    'route:invoices': '/invoices',
    'route:invoices.label': 'Factures',

    'route:cart-overview': '/checkout',
    'route:cart-overview.label': 'Passer la commande',

    'route:imprint.label': 'Colofon',
    'route:data-privacy-policy.label': 'La déclaration de confidentialité',
    'route:faq.label': 'FAQ',

    'route:my-customers': '/my-customers',
    'route:my-customers.label': 'Mes comptes',

    'route:contacts': '/contacts',
    'route:contacts.label': 'Mes utilisateurs',

    'route:quotes': '/quotes',
    'route:quotes.label': 'Quotes',

    'route:requests': '/requests',
    'route:requests.label': 'Demandes',

    'route:account': '/account',

    // Salutations
    'salutation.formal.male': 'Cher Monsieur {name}',
    'salutation.formal.female': 'Chère Madame {name}',
    'salutation.formal.unknown': 'Cher client',
    'salutation.informal.male': 'Cher client',
    'salutation.informal.female': 'Cher client',
    'salutation.informal.unknown': 'Cher client',
    'salutation.internal.unknown': 'Cher collègue',

    // Email subjects
    'email:subject:ORDER_CONFIRM': 'Brenntag Connect | Numéro de commande {orderID} Nouvelle commande Brenntag Connect',
    'email:subject:RFI_SALES_PERSON': 'Brenntag Connect | Demande d’information {uid} de {name}',
    'email:subject:RFI_CUSTOMER': 'Brenntag Connect | Demande d’information {uid}',
    'email:subject:RFI_COMPLETE': 'Brenntag Connect | Réponse à votre demande {uid}',
    'email:subject:RQ_CUSTOMER': 'Brenntag Connect | Demande de commande {uid}',
    'email:subject:RQ_SALES_PERSON': 'Brenntag Connect | Demande de commande {uid} de {name}',
    'email:subject:ORDER_WO_PRICE': 'Brenntag Connect | Commande sans un prix valable',
    'email:subject:RESET_PW': 'Brenntag Connect | Demande de configuration du mot de passe',
    'email:subject:WELCOME': 'Brenntag Connect | Bienvenue sur Brenntag Connect',
    'email:subject:SC_CUSTOMER': 'Brenntag Connect | Demande de commande {uid}',
    'email:subject:SC_SALES_PERSON': 'Brenntag Connect | Demande de commande {uid} de {name}',

    // Cookiebar
    'cookiebar:text1':
        'Pour vous offrir une meilleure expérience ce site utilise des cookies. En cliquant sur le bouton OK, vous donnez votre accord sur l’utilisation de notre politique de cookies.',
    'cookiebar:text2': 'Pour des conseils plus détaillés, veuillez vous référer à notre',
    'cookiebar:accept-button': 'OK',

    // FAQ
    'faq:contact-title': 'Dites-nous comment nous pouvons vous aider',
    'faq:contact-text':
        'Nous sommes là pour vous donner la meilleure expérience possible de Brenntag Connect. Quelle que soit votre question ou problème, envoyez-nous un e-mail et nous y répondrons dans les plus brefs délais.',
    'faq:browse-categories': 'Parcourir les catégories',

    // Login page
    'login:header': 'Connectez-vous à votre compte',
    'login:welcome-header': 'Bienvenue à Brenntag Connect!',
    'login:intro':
        'Brenntag Connect est votre plateforme où vous pouvez facilement commander des produits chimiques et trouver une collection complète et actualisée d’informations utiles.',
    'login:intro-benefit': 'En tant que client enregistré, vous bénéficiez de nos services en ligne :',
    'login:intro-bullet-1': 'Demander des informations et des documentations détaillées sur les produits',
    'login:intro-bullet-2': 'Consultez les prix actuels (sous réserve de disponibilité)',
    'login:intro-bullet-3': 'Commandez des produits rapidement et facilement',
    'login:intro-bullet-4': 'Consultez l’historique de vos commandes et les factures des 24 derniers mois',
    'login:help-title': 'Pouvons-nous vous aider?',
    'login:help-instruction': 'Veuillez contacter notre service clientèle. Nous serons heureux de vous aider.',
    'login:help-phone-number': 'Numéro de téléphone:',
    'login:help-office-hours': 'Nos heures de bureau:',
    'login:contact-more': 'Ou dites-nous en plus sur votre demande:',
    'login:contact-button': 'Contactez-nous',
    'login:copyright': 'Tous droits réservés.',
    'login:brenntag': '© Brenntag SE',

    'login:username': 'Adresse e-mail',
    'login:username-placeholder': 'votre@email.com',
    'login:password': 'Mot de passe',
    'login:password-placeholder': 'saisissez votre mot de passe',
    'login:button.forgot-password': 'Oublié votre mot de passe ?',
    'login:back-to-login': 'Aller à la page de connexion',

    // Login page with MFA
    'login-mfa:title': 'Confirmez votre identité',
    'login-mfa:intro':
        'Nous venons d’envoyer un code de vérification à votre adresse e-mail. Veuillez entrer le code que vous avez reçu dans l’e-mail et cliquez sur "Vérifier".',
    'login-mfa-form:input': 'Saisissez le code à 6 chiffres',
    'login-mfa-form:submit': 'Verifiez',

    // Login form
    'login-form:error': 'La connexion a échoué, veuillez vérifier vos données de connexion',
    'login-form:error.access_denied': 'Connexion échouée, veuillez vérifier vos identifiants',
    'login-form:error.too_many_attempts': 'Votre compte est bloqué suite a trop d’essais pour se connecter',

    // Reset password page
    'reset-password:title': 'Définissez votre mot de passe',
    'reset-password:body':
        'Veuillez vérifier votre boîte e-mail - nous vous avons envoyé un e-mail pour définir votre mot de passe.',

    // Set password token error
    'warning:invalid-token:title': 'Nous sommes désolés!',
    'warning:invalid-token:description': 'Votre session a expiré! Veuillez réinitialiser le mot de passe.',

    // Auth error page
    'auth-error:message': 'Connexion échouée, s’il vous plaît essayez de vous connecter a nouveau',
    'auth-error:expired-link:title': 'Ce lien d’activation a expiré.',
    'auth-error:expired-link:message':
        'Votre lien d’activation a expiré, veuillez demander un nouveau lien en réinitialisant votre mot de passe.',
    'auth-error:link-text': 'Réinitialisez votre mot de passe',
    'auth0-error:passworddictionaryerror': 'Le mot de passe est trop commun.',
    'auth0-error:passwordstrengtherror': 'Le mot de passe est trop faible.',
    'auth0-error:passwordhistoryerror': 'Le mot de passe a déjà été utilisé.',
    'auth0-error:passwordnouserinfoerror':
        'Le mot de passe ne doit pas contenir des informations sur l’utilisateur (tel que nom ou email) .',
    'auth0-error:csrfinvalidtokenerror': 'Votre session a expiré! Veuillez réinitialiser le mot de passe.',

    // Rate limit error
    'rate-limit-error:message': 'Trop de demandes. Veuillez réessayer plus tard',

    // Forgot password form
    'forgot-password-form:title': 'Avez vous oublié votre mot de passe?',
    'forgot-password-form:description':
        'Si vous êtes déjà enregistré avec nous, vous recevrez un message per email pour réinitialiser votre mot de passe.',

    'forgot-password-form:email': 'Adresse e-mail',
    'forgot-password-form:submit': 'Envoyer un e-mail',
    'forgot-password-form:success': 'Nous venons de vous envoyer un email pour réinitialiser votre mot de passe',

    // Set password form
    'set-password-form:title': 'Initialiser le mot de passe',
    'set-password-form:description': 'Définir un nouveau mot de passe',
    'set-password-form:new-password': 'Nouveau mot de passe',
    'set-password-form:new-password-confirmation': 'Confirmez votre nouveau mot de passe',
    'set-password-form:password-instruction':
        'Vous devez définir un nouveau mot de passe. Le nouveau mot de passe est valable pendant 180 jours.',
    'set-password-form:success-title': 'Succès!',
    'set-password-form:success':
        'Cher client, \ndans le cadre de nos initiatives continues pour vous fournir un accès sécurisé, nous avons mis en place l’authentification à 2 facteurs (2FA). Par conséquent, nous vous avons automatiquement déconnecté après que vous ayez défini votre mot de passe initial. \nVeuillez aller à la page de connexion, entrer votre mot de passe et le confirmer avec le code de vérification que vous recevrez dans votre boîte e-mail pour avoir accès à Brenntag Connect.',

    // My Customers Page
    'my-customers:city': 'Ville',
    'my-customers:customer-id': 'Numéro client',
    'my-customers:customer-name': 'Nom du client',
    'my-customers:customer-vat-number': 'Numéro TVA',
    'my-customers:headline': 'Mes comptes',
    'my-customers:last-order-date': 'Date de la dernière commande',
    'my-customers:products': 'Produits',
    'my-customers:outstanding-orders': 'Commandes en cours',
    'my-customers:items-in-cart': 'Produits dans le panier',
    'my-customers:search-for-customer': 'Rechercher un client',
    'my-customers:select-user': 'Selectionner',
    'my-customers:use-search-options': 'Utilisez l’une des options de recherche suivantes:',
    'my-customers:search-error': 'Une erreur s’est produite lors de la recherche de clients.',
    'my-customers:division': 'Département',

    // Requests Page
    'requests:title-csr': 'Demandes des clients',
    'requests:title-customer': 'Mes demandes',
    'requests:application': 'Application',
    'requests:csr-subtitle-one': 'Vous souhaitez nous contacter ? Envoyez-nous un e-mail à l’adresse suivante:',
    'requests:csr-subtitle-two': 'ou appelez-nous au numéro suivant {csrPhone}',
    'requests:delivery-date': 'Date de livraison',
    'requests:documents': 'Documents',
    'requests:documents-max-size': '({maxSize} MB au maximum)',
    'requests:email': 'Email d’utilisateur',
    'requests:messages': 'Messages',
    'requests:po-reference': 'Référence du client',
    'requests:quantity-annual': 'Quantité annuelle',
    'requests:quantity-call-off': 'Quantité par livraison',
    'requests:quantity-sample': 'Quantité d’echantillon',
    'requests:requested': 'Envoyé le',
    'requests:requested-documents': 'Documents demandés',
    'requests:ship-to-address': 'Envoyer à l’adresse',
    'requests:status': 'Statut',
    'requests:button.edit-complete': 'Modifier la réponse',
    'requests:button.edit': 'Réponse',
    'requests:edit-modal-title': 'Modifier la sollicitation',
    'requests:edit-modal-summary': 'Résumé de demande',
    'requests:comments': 'Commentaires',
    'requests:file-too-big': 'Le fichier {name} est trop long',
    'requests:max-upload': 'Le maximum de {mb}MB a ete attaint, le fichier {name} n’a pas pu etre ajoute',
    'requests:mime-types': 'Le fichier {name} de type: {type} n’est pas authorise',
    'requests:customer': 'Client',
    'requests:recipient': 'Destinataire',
    'requests:details': 'Détails',
    'requests:user-comment': 'Commentaire de l’utilisateur',
    'requests:response': 'Notre équipe répondra à votre demande dans les plus brefs délais!',
    'requests:questions': 'Vous n’avez toujours pas reçu ce que vous aviez demandé? Contactez-nous à ',
    'user:form:usertype-viewer': 'Cet utilisateur ne peut télécharger que des documents.',
    'user:form:label-usertype': 'Autorisations utilisateur',

    // Request enum
    'request-status:COMPLETED': 'Complété',
    'request-status:PENDING': 'En attente',
    'request-type:DOCUMENT': 'Document',
    'request-type:PRICE': 'Prix',
    'request-type:PROFILE': 'Profil',
    'request-type:QUOTE': 'Commande',
    'request-type:SAMPLE': 'Échantillon',
    'request-type:CART': 'Commande',

    // User management page
    'user:country': 'Pays',
    'user:role': 'Rôle',
    'user:customer-id': 'Numéro client',
    'user:last-login': 'Dernière connexion',
    'user:send-emails': 'Envoyer',
    'user:email-welcome': 'Renvoyer email de bienvenue',
    'user:email-welcome-to-support': 'Envoyer email de bienvenue à DigiB Support',
    'user:email-password': 'Renvoyer email de réinitialisation du mot de passe',
    'user:email-password-to-support': 'Envoyer email de réinitialisation du mot de passe à DigiB Support',
    'user:admin-actions': 'Actions de l’administrateur',
    'user:select-admin-action': 'Sélectionnez une action',
    'user:email-welcome-sent': 'Envoyé sur',
    'user:edit-user': 'Modifier',
    'user:create-new-user': 'Créer un utilisateur',
    'user:sort:username': 'Nom de l’utilisateur',
    'user:sort:logindate': 'Date de connexion',
    'user:email-modal-welcome-title': 'Envoyez de nouveau l’email de bienvenue',
    'user:email-modal-welcome-text':
        'Êtes-vous sûr de vouloir envoyez à nouveau l’email de bienvenue à cet utilisateur ({email})?',
    'user:email-modal-welcome-to-support-text':
        'Êtes-vous sûr de vouloir envoyez à l’e-mail de bienvenue à DigiB Support?',
    'user:email-modal-welcome-cancel': 'Annuler',
    'user:email-modal-welcome-confirm': 'Envoyer email',
    'user:email-modal-pwreset-title': 'Envoyer email pour réinitialiser le mot de passe',
    'user:email-modal-pwreset-text':
        'Êtes-vous sûr de vouloir envoyez l’email pour réinitialiser ({email}) le mote de passe?',
    'user:email-modal-pwreset-to-support-text':
        'Êtes-vous sûr de vouloir envoyez à l’e-mail de réinitialisation de mot de passe à DigiB Support?',
    'user:email-modal-pwreset-cancel': 'Annuler',
    'user:email-modal-pwreset-confirm': 'Envoyer email',
    'user:edit-user-modal-title': 'Modifier l’utilisateur',
    'user:create-user-modal-title': 'Nouvel utilisateur',
    'user:limited-search-results':
        'Présentation {number} des {count} résultats. Veuillez utiliser les filtres sur la gauche pour trouver des utilisateurs spécifiques.',

    // Form labels & placeholders user management
    'user:form:label-email': 'Email',
    'user:form:label-email-placeholder': 'Entrer email',
    'user:form:label-firstname': 'Prénom',
    'user:form:label-firstname-placeholder': 'Entrez votre Prénom',
    'user:form:label-lastname': 'Nom',
    'user:form:label-lastname-placeholder': 'Entrez votre Nom',
    'user:form:label-customerids': 'Numéro client',
    'user:form:label-customerids-placeholder': 'Entrez numéro du client',
    'user:form:label-language': 'Langue',
    'user:form:label-gender': 'Sexe',
    'user:form:label-country': 'Pays',
    'user:form:label-customer-id-and-sales-org': 'Numéro du client et organisation commerciale',
    'user:form:label-customer-grant-access': 'Accorder l’accès à un ou plusieurs clients:',
    'user:form:error-customer-grant-access': 'Accorder l’accès à un ou plusieurs clients.',
    'user:form:error-select-one-sales-org': 'Ajoutez au moins une organisation commerciale.',
    'user:form:error-search': 'Il s’est produit une erreur, essayez une nouvelle recherche.',
    'user:form:search-no-results':
        'Aucun résultat trouvé, vous pouvez également effectuer une recherche par nom, numéro du client ou numéro de TVA.',
    'user:form:label-mfa': 'Authentification multifaction (AMF)',
    'user:form:label-enablemfa': 'Activer l’AMF',
    'user:form:label-placeholder': 'Veuillez sélectionner les autorisations utilisateurs',

    // Customer Selection Dropdown
    'company:selection:label-bill-to': 'Adresse de facturation:',
    'company:selection:label-country': 'Pays:',
    'company:selection:change': 'Changer',
    'company:selection:label-customer-id': 'Numéro du client:',
    'company:selection:label-vat': 'Numéro de TVA:',
    'company:selection:label-name': 'Nom:',
    'company:selection:title': 'Please select a company',
    'company:selection:intro.one': 'In order to proceed and view relevant information',
    'company:selection:intro.two': 'We request you to select the company name in the table below.',

    // Products
    'products:column.material': 'Produit',
    'products:card.material': 'Nom du produit',
    'products:column.cas-no': 'Numéro CAS',
    'products:column.filling-weight': 'Poids de remplissage',
    'products:column.supplier': 'Fourniseur',
    'products:column.grade': 'Qualité',
    'products:column.detail-packaging': 'Détails d’emballage',
    'products:column.einecs': 'Einecs',
    'products:column.unit-capacity': 'capacité unitaire',

    'products:more-details-button-text': 'Plus de détails',
    'products:blend': '...',
    'products:column.material-number': 'Numéro de produit',

    // Product list
    'product-list:headline': '{count} Produits',
    'product-list:empty-cart': 'Liste des produits',
    'product-list:request-info': 'Info',

    // Product Modal
    'product-modal:scale-prices': 'Prix actuel',

    // Contact person
    'contact-person:title': 'Avez vous des questions sur cette commande?',
    'contact-person:subtitle': 'Contactez notre Life Science service clientèle',
    'contact-person:customer-service': 'Life Science Service Clientèle',

    // Add to cart form
    'add-to-cart-form:success-toast': 'Le produit a été ajouté avec succès à votre panier',

    // Checkout
    'checkout:headline': 'Récapitulatif de commande',
    'checkout:products': 'Produits',
    'checkout:empty-cart': 'Votre panier est vide',
    'checkout:empty-cart-message':
        'Commencez par ajouter les produits à votre commande ou rechercher dans la list de produits',
    'checkout:button-continue-shopping': 'Continuer vos achats',

    'checkout-success:order-submitted': 'Votre commande a été soumise!',
    'checkout-success:final-instruction':
        'Vous recevrez un e-mail de notre part dès que votre commande est approuvée.<br/>Utilisez Brenntag Connect pour télécharger les factures et les fiches de données de sécurité. <br/> <br/> Pour toute question relative à cette commande, veuillez envoyer un e-mail à <a href="mailto:{email}">{email}</a>',
    'checkout-success:order-number': 'Numéro de Demande',
    'checkout-success:order-date': 'Date de la demande',
    'checkout-success:button-view-requests': 'Afficher les demandes',
    'checkout-success:button-view-orders': 'Afficher les commandes',

    // Delivery overview
    'delivery-overview:title': 'Récapitulatif de livraison',
    'delivery-overview:billing-address': 'Adresse de facturation',
    'delivery-overview:comment': 'Commentaires',
    'delivery-overview:delivery-date': 'Date souhaitée de livraison',
    'delivery-overview:legal-terms': ' sont d’application sur cette commande.',
    'delivery-overview:legal-terms-name': 'Nos conditions générales de vente',
    'delivery-overview:legal-terms-price': 'Prix hors TVA et frais supplémentaires',
    'delivery-overview:payment-terms': 'Conditions de paiement',
    'delivery-overview:no-payment-terms': 'Aucune condition de payement n’a été choisie',
    'delivery-overview:po-reference': 'Référence du client',
    'delivery-overview:order-id': 'Numéro de commande',
    'delivery-overview:same-as-delivery-address': 'Identique à l’adresse de livraison',
    'delivery-overview:agree-with-terms': 'En passant une commande, vous acceptez',
    'delivery-overview:submit': 'Passer commande',
    'delivery-overview:terms-of-delivery': 'Conditions de livraison',
    'delivery-overview:INCO-terms': 'Incoterms',
    'delivery-overview:status': 'Statut',
    'delivery-overview:packaging': 'Emballage',
    'delivery-overview:agree-with-terms-warning': 'Veuillez accepter nos conditions générales de vente',
    'delivery-overview:INCO-terms-as-agreed': 'Comme convenu',
    'delivery-overview:payment-terms-as-agreed': 'Comme convenu',

    // Invoices
    'invoices:date-invoice': 'Date de facturation',
    'invoices:date-due': 'Date d’échéance',
    'invoices:download': 'Télécharger la facture',
    'invoices:title': 'Numéro de la facture: {id}',
    'invoices:total-amount': 'Montant total (TVA incl.)',

    // Order
    'order:next-steps.title': 'Suivant',
    'order:line-item.material-no': 'Numéro d’article',
    'order:line-item.pieces': 'Unités',
    'order:line-item.amount': 'Montant',
    'order:line-item.requested': 'Sollicité pour',
    'order:line-item.confirmed': 'Confirmé par',
    'order:line-item.delivered': 'Remis le',
    'order:line-item.order-quantity': 'Quantité de la commande',
    'order:line-item.batch': 'Numéro de lot',
    'order:line-item.total-material-price': 'Prix total de produit',
    'order:line-item.request-price': 'Votre prix sera confirmé',
    'order:line-item.price': 'Prix',
    'order:status.delivered': 'Livré',
    'order:status.pending': 'En attente',
    'order:status.indelivery': 'En course de livraison',
    'order:status.confirmed': 'Confirmé',
    'order:status.invoiced': 'Facturé',
    'order:status.cancelled': 'Annulé',
    'order:status.processed': 'Traité',
    'order:status.loading': 'Chargement',
    'order:status.shipped': 'Expédié',

    // Request types
    'order:status.document': 'Document',
    'order:status.price': 'Prix',
    'order:status.profile': 'Profil',
    'order:status.quote': 'Commande',
    'order:status.sample': 'Échantillon',

    'orderline:pieces': 'Unités',

    // Orders
    'orders:column.status': 'Statut',
    'orders:column.sales-number': 'Brenntag numéro de commande de vente',
    'orders:column.po-reference': 'Référence du client',
    'orders:column.order-date': 'Date de commande',
    'orders:column.delivery-address': 'Adresse de livraison',
    'orders:column.materials-amount': 'Quantité de produit',
    'orders:column.delivery-date': 'Date de livraison',
    'orders:column.delivery-date-from-to': 'Plusieurs livraisons entre',
    'orders:column.requested-delivery-date': 'Date de livraison demandée',
    'orders:column.po-reference-abbreviation': 'Référence du client',
    'orders:button.confirmation': 'Confermation de la commande',

    // Quotes
    'quotes:column.offer-date': 'Date de l’offre',
    'quotes:column.delivery-address': 'Adresse de livraison',

    // Material
    'general:material-id': 'Numéro d’article',
    'general:packaging': 'Emballage',
    'general:pallet': 'Palette',
    'general:price': 'Prix',
    'general:unit-price': 'Prix unitaire',
    'general:price-confirmation': 'Prix ​​à confirmer',
    'general:pieces': 'Unités',
    'general:pieces-plural': '{count, plural, one {Piece} autres {Pieces}}',
    'general:pieces-long': 'Quantité',
    'general:amount': 'Montant',

    // General
    'general:filters': 'Filtre par',
    'general:login': 'Se connecter',
    'general:cancel': 'Annuler',
    'general:save': 'Enregistrer',
    'general:save-and-send': 'Enregistrer & Envoyer',
    'general:send': 'Envoyer',
    'general:delete': 'Effacer',
    'general:date-format.day': 'J',
    'general:date-format.month': 'M',
    'general:date-format.year': 'A',
    'general:error': 'Quelque chose a mal tourné. Veuillez rafraîchir la page.',
    'general:close': 'Terminer',
    'general:type-here': 'Tapez ici',
    'general:send-us-email': 'Besoin d’aide',
    'general:error.label': 'Erreur:',
    'general:back-to-overview': 'Retour au récapitulatif',
    'general:search': 'Rechercher',
    'general:delivery-address': 'Adresse de livraison',
    'general:incoterm': 'Conditions de livraison',
    'general:order': 'Ordre',
    'general:order-id': 'Numéro de commande Brenntag',
    'general:quote': 'Offre',
    'general:offer-id': 'Numéro d’offre Brenntag',
    'general:upload': 'Sélectionnez les fichiers',
    'general:documents': 'Documents',
    'general:select-ship-to': 'Sélectionnez une adresse de livraison',
    'general:ship-to': 'Adresse de livraison',
    'general:po-reference': 'Référence du client',
    'general:sorry': 'Nous sommes désolés!',
    'general:something-went-wrong': 'Quelque chose a mal tourné. Veuillez rafraîchir la page.',
    'general:save-changes': 'Enregistrer les modifications',
    'general:order-amount': 'Quantité',
    'general:order-quantity': 'Quantité de la commande',
    'general:required-fields': '* Champs obligatoires',
    'general:file-attached': 'Document attaché',
    'general:file-attached-plural': 'Documents attachés',
    'general:attach-document': 'Joindre un document',
    'general:attach-document-plural': 'Joignez un ou plusieurs documents',
    'general:price-conversion': 'Conversion de devises',
    'general:id': 'ID',
    'general:quantity-below-minimum': 'La quantité demandée est inférieure à la quantité minimale de commande.',
    'general:po-reference-info':
        'Une référence client est obligatoire pour votre commande. Il peut s’agir de votre numéro de commande ou d’une référence telle que votre nom ou une date. Cette référence sera utilisée lors de la livraison des marchandises et sera également mentionnée sur votre facture.',

    // react-day-picker
    'react-day-picker:first-day-of-week': '1',
    'react-day-picker:months.january': 'Janvier',
    'react-day-picker:months.february': 'Février',
    'react-day-picker:months.march': 'Mars',
    'react-day-picker:months.april': 'Avril',
    'react-day-picker:months.may': 'Mai',
    'react-day-picker:months.june': 'Juin',
    'react-day-picker:months.july': 'Julliet',
    'react-day-picker:months.august': 'Août',
    'react-day-picker:months.september': 'Septembre',
    'react-day-picker:months.october': 'October',
    'react-day-picker:months.november': 'Novembre',
    'react-day-picker:months.december': 'Décembre',
    'react-day-picker:next-month': 'Le mois prochain',
    'react-day-picker:previous-month': 'Le mois dernier',
    'react-day-picker:weekdays-short.monday': 'L',
    'react-day-picker:weekdays-short.tuesday': 'M',
    'react-day-picker:weekdays-short.wednesday': 'M',
    'react-day-picker:weekdays-short.thursday': 'J',
    'react-day-picker:weekdays-short.friday': 'V',
    'react-day-picker:weekdays-short.saturday': 'S',
    'react-day-picker:weekdays-short.sunday': 'D',
    'react-day-picker:weekdays-long.monday': 'Lundi',
    'react-day-picker:weekdays-long.tuesday': 'Mardi',
    'react-day-picker:weekdays-long.wednesday': 'Mecredi',
    'react-day-picker:weekdays-long.thursday': 'Juedi',
    'react-day-picker:weekdays-long.friday': 'Vendredi',
    'react-day-picker:weekdays-long.saturday': 'Samedi',
    'react-day-picker:weekdays-long.sunday': 'Dimanche',

    // Filters and sorting
    'filters:search-by-name': 'Nom du Produit',
    'filters:search-by-name.placeholder': 'Nom du Produit',
    'filters:search-by-cas-no': 'Numéro CAS',
    'filters:search-by-cas-no.tooltip': 'N’oubliez pas les traits d’union.',
    'filters:search-by-cas-no.placeholder': 'Numéro CAS',
    'filters:search-by-role': 'Rôle',
    'filters:search-by-role.placeholder': 'Toutes',
    'filters:search-by-csr.placeholder': 'CSR',
    'filters:search-by-admin.placeholder': 'Admin',
    'filters:search-by-user.placeholder': 'Utilisateur',
    'filters:search-by-viewer.placeholder': 'Read-only',
    'filters:search-by-customer-product-number': 'Numéro de produit',
    'filters:search-by-customer-product-number.placeholder': 'Numéro de produit',
    'filters:search-by-company-name': 'Nom du client',
    'filters:search-by-company-name.placeholder': 'Nom du client',
    'filters:search-by-customer': 'Recherche pour le client',
    'filters:search-by-customerID': 'Identifiant du client',
    'filters:search-by-customerID.placeholder': 'Numéro client',
    'filters:search-by-customerID-or-name.placeholder': 'Nom ou identifiant du client',
    'filters:search-by-customerID-or-name-or-vat.placeholder': 'Rechercher par nom ou identifiant du client ou n° TVA',
    'filters:search-by-offer-number': 'Numéro d’offre',
    'filters:search-by-offer-number.placeholder': 'Numéro d’offre',
    'filters:search-by-po-reference': 'Référence du client',
    'filters:search-by-po-reference.placeholder': 'Référence du client',
    'filters:search-by-material-name': 'Nom du produit',
    'filters:search-by-material-name.placeholder': 'Nom du produit',
    'filters:search-by-username': 'Nom d’utilisateur',

    'filters:search-by-here.placeholder': 'Recherche ici',
    'filters:select-ship-to-address': 'Adresse de livraison',
    'filters:select-an-address': 'Rechercher une adresse',
    'filters:clear-filters': 'Réinitialiser les filtres',
    'filter-packaging.de:filters.names':
        'Drum with screw cap, Box, Drum with lid,Bag, Bulk,IBC,Canister,Sample,Bottle/Can,Pouch,Pail,Cylinder,Can,Big Bag,SAFE-TAINER™,Drum,Steel-Bottle',
    'filter-packaging.de:filters.values':
        'Spundfaß,Karton,Deckelfaß,Sack,TKW,IBC,Kanister,Muster,Flasche/Kanne,Beutel,Eimer,Trommel,Dose,Big bag,Safetainer,Gasbehälter,Gasflasche',
    'filter-packaging.bnl:filters.names':
        'Ampoule,Sac,Ballot,Bouteille,Boite,Seau,Vrac,Bidon,Cartouche,Caisse,Container,Cylindre,Fut,Flexibag,Flexidrum,Flexitank,Feuille,Frame,IBC,Log,Octabin,Palette,Palletbox,Piece,Bobine,Roll,Safetainer,Echantillon,Securitainer,Set,Aérosol,Tube',
    'filter-packaging.bnl:filters.values':
        'AMPULE,BAG,BALE,BOTTLE,BOX,BUCKET,BULK,CAN,CARTRIDGE,CASE,CONTAINER,CYLINDER,DRUM,FLEXIBAG,FLEXIDRUM,FLEXITANK,FOILBAG,FRAME,IBC,LOG,OCTABIN,PALLET,PALLETBOX,PIECE,REEL,ROLL,SAFETAINER,SAMPLE,SECURITAINER,SET,SPRAY,TUBE',
    'filter-packaging.cee:filters.names':
        'Sac, Bouteille, Citerne, Vrac, Bidon, Fût, IBC, Octabine, Palette, Safetainer, Autre, Bidon, Bigbag, Fût en carton, Carton, Coffre, Emballage',
    'filter-packaging.cee:filters.values':
        'BAG,BOTTLE,BUCKET,BULK,CAN,DRUM,IBC,OCTABIN,PALLET,SAFETAINER,OTHER,CANISTER,BIGBAG,PAPERBOARD BARREL,CARDBOARD,CHEST,PACKAGE',

    'filters:select-a-packaging-title': 'Sélectionner l’Emballage',
    'filters:order-status': 'Statut de commande',
    'filters:select-a-status': 'Sélectionner un Statut',
    'filters:search-by-request-no': 'Numéro de Demande',
    'filters:search-by-request-no.placeholder': 'Numéro de Demande',
    'filters:search-by-status': 'Statut',
    'filters:search-by-status.placeholder': 'Sélectionner un Statut',
    'filters:request-type': 'Type de Demande',
    'filters:select-request-type': 'Type de Demande',
    'filters:select-country': 'Pays',
    'filters:search-by-invoiceID': 'Numéro de la Facture',
    'filters:search-by-invoiceID.placeholder': 'Numéro de la Facture',
    'filters:search-by-einecs-no': 'Numéro EINECS',
    'filters:search-by-einecs-no.placeholder': 'Numéro EINECS',
    'filters:packaging-title': 'Emballage',
    'filters:search-by-country': 'Pays',
    'filters:search-by-country.placeholder': 'Tous les pays',
    'filters:search-by-role.error-select-one': 'Veuillez sélectionner au moins un rôle.',
    'filters:copy-link': 'Copier le lien',
    'filters:copy-link-success': 'Le lien a été copié dans le presse-papiers.',
    'filters:copy-link-error': 'Veuillez copier le lien suivant :',

    'sort-direction:numeric:asc': '(0-9)',
    'sort-direction:numeric:desc': '(9-0)',
    'sort-direction:string:asc': '(A-Z)',
    'sort-direction:string:desc': '(Z-A)',
    'sort-direction:date:asc': '(ancien-nouveau)',
    'sort-direction:date:desc': '(nouveau-ancien)',

    'sort-select:sort-by': 'Trier par',
    'sort-by-request-id': 'Numéro',
    'sort-by-type': 'Type',
    'sort-by-date': 'Date',
    'sort-by-status': 'Statut',
    'sort-by-due-date': 'Date d’échéance',
    'sort-by-invoice-date': 'Date de facturation',
    'sort-by-order-date': 'Date de commande',
    'sort-by-po-reference': 'Référence du client',
    'sort-by-invoice-type': 'Type de facture',
    'sort-by-invoice-id': 'Numéro de la facture',
    'sort-by-requested-delivery-date': 'Date de livraison',
    'sort-by-offer-number': 'Numéro d’offre',
    'sort-by-material-name': 'Nom du produit',
    'sort-by-quote-date': 'Date de l’offre',

    // Packaging Names
    'package:Spundfaß': 'Tambour (bouchon à vis)',
    'package:Safetainer': 'SAFE-TAINER™',
    'package:Gasbehälter': 'Fût',
    'package:Gasflasche': 'Bouteille en acier',
    'package:Karton': 'Boîte',
    'package:Deckelfaß': 'Tambour (couvercle)',
    'package:Sack': 'Sac',
    'package:TKW': 'Vrac',
    'package:IBC': 'IBC',
    'package:Kanister': 'Boîte',
    'package:Muster': 'Échantillon',
    'package:Flasche/Kanne': 'Bouteille/Bidon',
    'package:Beutel': 'Poche',
    'package:Eimer': 'Seau',
    'package:Trommel': 'Cylindre',
    'package:Dose': 'Boîte',
    'package:Big bag': 'Big Bag',

    'package-pl:Spundfaß': 'Tambours (bouchons à vis)',
    'package-pl:Safetainer': 'SAFE-TAINERs™',
    'package-pl:Gasbehälter': 'Fûts',
    'package-pl:Gasflasche': 'Bouteilles en acier',
    'package-pl:Karton': 'Boîtes',
    'package-pl:Deckelfaß': 'Tambours (couvercles)',
    'package-pl:Sack': 'Sacs',
    'package-pl:TKW': 'Vrac',
    'package-pl:IBC': 'IBC',
    'package-pl:Kanister': 'Boîtes',
    'package-pl:Muster': 'Échantillons',
    'package-pl:Flasche/Kanne': 'Bouteilles/Bidons',
    'package-pl:Beutel': 'Poches',
    'package-pl:Eimer': 'Seaux',
    'package-pl:Trommel': 'Cylindres',
    'package-pl:Dose': 'Boîtes',
    'package-pl:Big bag': 'Bis Bags',

    // BNL Packaging Names
    'package:AMPULE': 'Ampoule',
    'package:BAG': 'Sac',
    'package:BALE': 'Ballot',
    'package:BOTTLE': 'Bouteille',
    'package:BOX': 'Boîte',
    'package:BUCKET': 'Seau',
    'package:BULK': 'Vrac',
    'package:CAN': 'Bidon',
    'package:CARTRIDGE': 'Cartouche',
    'package:CASE': 'Caisse',
    'package:CONTAINER': 'Container',
    'package:CYLINDER': 'Cylindre',
    'package:DRUM': ' Fût',
    'package:FLEXIBAG': 'Flexibag',
    'package:FLEXIDRUM': 'Flexidrum',
    'package:FLEXITANK': 'Flexitank',
    'package:FOILBAG': 'Feuille',
    'package:FRAME': 'Frame',
    'package:LOG': 'Log',
    'package:OCTABIN': 'Octabin',
    'package:PALLET': 'Palette',
    'package:PALLETBOX': 'Palletbox',
    'package:PIECE': 'Pièce',
    'package:REEL': 'Bobine',
    'package:ROLL': 'Roll',
    'package:SAFETAINER': 'Safetainer',
    'package:SAMPLE': 'Échantillon',
    'package:SECURITAINER': 'Securitainer',
    'package:SET': 'Set',
    'package:SPRAY': 'Aérosol',
    'package:TUBE': 'Tube',
    'package:OTHER': 'Autre',
    'package:CANISTER': 'Bidon',
    'package:BIGBAG': 'Bigbag',
    'package:PAPERBOARD BARREL': 'Baril en carton',
    'package:CARDBOARD': 'Carton',
    'package:CHEST': 'Coffre',
    'package:PACKAGE': 'Paquets',

    // BNL packaging plurals
    'package-pl:AMPULE': 'Ampoules',
    'package-pl:BAG': 'Sacs',
    'package-pl:BALE': 'Balles',
    'package-pl:BOTTLE': 'Bouteilles',
    'package-pl:BOX': 'Boîtes',
    'package-pl:BUCKET': 'Seaux',
    'package-pl:BULK': 'Vrac',
    'package-pl:CAN': 'Bidons',
    'package-pl:CARTRIDGE': 'Cartouches',
    'package-pl:CASE': 'Caisses',
    'package-pl:CONTAINER': 'Conteneurs',
    'package-pl:CYLINDER': 'Cylindres',
    'package-pl:DRUM': ' Fûts',
    'package-pl:FLEXIBAG': 'Flexibags',
    'package-pl:FLEXIDRUM': 'Flexidrums',
    'package-pl:FLEXITANK': 'Flexitanks',
    'package-pl:FOILBAG': 'Sacs en feuilles',
    'package-pl:FRAME': 'Frames',
    'package-pl:LOG': 'Logs',
    'package-pl:OCTABIN': 'Octabins',
    'package-pl:PALLET': 'Palettes',
    'package-pl:PALLETBOX': 'Boîtes à palettes',
    'package-pl:PIECE': 'Pièces',
    'package-pl:REEL': 'Bobines',
    'package-pl:ROLL': 'Rolls',
    'package-pl:SAFETAINER': 'Safetainers',
    'package-pl:SAMPLE': 'Échantillons',
    'package-pl:SECURITAINER': 'Securitainers',
    'package-pl:SET': 'Sets',
    'package-pl:SPRAY': 'Aérosols',
    'package-pl:TUBE': 'Tubes',
    'package-pl:OTHER': 'Autres',
    'package-pl:CANISTER': 'Bidons',
    'package-pl:BIGBAG': 'Bigbags',
    'package-pl:PAPERBOARD BARREL': 'Barils en carton',
    'package-pl:CARDBOARD': 'Cartons',
    'package-pl:CHEST': 'Coffres',
    'package-pl:PACKAGE': 'Packages',

    // Surcharge codes
    'surcharge:ZPR2': 'Prix net',
    'surcharge:ZBA2': 'Prix net',
    'surcharge:ZPRS': 'contrat SISO',
    'surcharge:ZPF0': 'Consigne',
    'surcharge:ZVEM': 'Frais d’emballage',
    'surcharge:ZVER': 'Frais d’emballage',
    'surcharge:ZVE2': 'Frais d’emballage',
    'surcharge:ZVKM': 'Coût d’emballage',
    'surcharge:ZM01': 'Markup (petit volume)',
    'surcharge:ZM00': 'Markup (petit volume)',
    'surcharge:ZSOK': 'Frais divers',
    'surcharge:ZSO1': 'Frais divers',
    'surcharge:ZVP0': 'Emballage spécial',
    'surcharge:ZMAU': 'Supplement de peage/carburant',
    'surcharge:ZMA2': 'ADR Surtaxe',
    'surcharge:ZTP1': 'Coûts de transport',
    'surcharge:ZTPS': 'Coûts de transport',
    'surcharge:ZSTD': 'Frais divers',
    'surcharge:ZST1': 'Frais divers',
    'surcharge:ZABK': 'Les coûts de remplissage',
    'surcharge:ZAB1': 'Les coûts de remplissage',
    'surcharge:ZLG1': 'Frais de consigne',
    'surcharge:ZLG2': 'Frais de consigne',
    'surcharge:ZFLG': 'remplissage de charge',
    'surcharge:ZGVS': 'ADR supplément',
    'surcharge:ZGV1': 'ADR supplément',
    'surcharge:Z110': 'Regate Abs.',
    'surcharge:Z111': 'Regate Abs.',
    'surcharge:Z112': 'Regate Abs.',
    'surcharge:Z15P': 'Regate Abs.',
    'surcharge:ZRAB': 'Rabais %',
    'surcharge:ZRB1': 'Regate Abs.',
    'surcharge:ZKA2': 'Client/Matériel',
    'surcharge:ZCVH': 'Abs remise.',
    'surcharge:ZCVM': 'Client/Matériel',
    'surcharge:ZUWP': 'Surtaxe environnementale',
    'surcharge:ZSKT': 'Escompte',
    'surcharge:ZPRB': 'base de la Commission',
    'surcharge:ZPRV': 'Commission',
    'surcharge:ZVPR': 'Coût',
    'surcharge:ZSTA': 'Prix net',
    'surcharge:ZSTM': 'Prix net',
    'surcharge:ZB10': 'Mat/Groupe de remise',
    'surcharge:ZB11': 'Ristourne Matériel',
    'surcharge:ZB12': 'Ristourne client',
    'surcharge:ZB14': 'Ristourne article',
    'surcharge:ZB15': 'Ristourne article',
    'surcharge:ZSUB': 'Subvention',
    'surcharge:ZSUP': 'Subvention en %',
    'surcharge:ZDCL': 'DOWCLENE',
    'surcharge:ZWEG': 'Client/Matériel',
    'surcharge:ZTUK': 'Transport/coûts Uml',
    'surcharge:ZTRA': 'Coûts de transport',
    'surcharge:ZF00': 'Coûts de transport',
    'surcharge:ZLFR': 'Livraison dans les 24 heures',
    'surcharge:ZVPP': 'Coût',
    'surcharge:ZVPL': 'Verp.Kost. Lieferpl.',
    'surcharge:ZVP1': 'Coût',
    'surcharge:ZVKD': 'condition Cumulation',
    'surcharge:ZVK1': 'Coût',
    'surcharge:ZUKE': 'Client/Matériel',
    'surcharge:ZUKM': 'Mélanger',
    'surcharge:ZUKA': 'Embouteillage',

    'surcharge:ZSLK': 'Sonder-/Dienstleistg',
    'surcharge:ZALG': 'Sonder-/Dienstleistg',
    'surcharge:ZLKW': 'Le chargement des camions',
    'surcharge:ZLA1': 'Client/Matériel',
    'surcharge:ZLA2': 'Client/Matériel',
    'surcharge:ZBKO': 'Client/Matériel',
    'surcharge:ZVKO': 'Client/Matériel',
    'surcharge:ZDIK': 'coûts à usage unique',
    'surcharge:ZDK1': 'Commande en cours de traitement',
    'surcharge:ZDK2': 'coûts à usage unique',

    'surcharge:ZSLO': 'Sonst.Logistikkosten',
    'surcharge:ZNLK': 'sonst. NL-Kosten',
    'surcharge:ZZKO': 'Zentralkosten',
    'surcharge:ZVBR': 'Taxes d’accise',
    'surcharge:ZABS': 'Brannt./Energ. T.V.A.',
    'surcharge:MWST': 'TVA',
    'surcharge:SKTO': 'Escompte',
    'surcharge:ZVOC': 'COV charge',
    'surcharge:ZIVU': 'IV Umschlag',
    'surcharge:GRWR': 'Grenzübergangswert',
    'surcharge:ZMW1': 'Netto FW',
    'surcharge:ZMW2': 'Umsatzsteuer FW',
    'surcharge:ZMW3': 'Brutto FW',

    // Order History
    'order-history:headline': 'Commandes',

    // Quote List
    'quotes:headline': 'Des Offres',

    // Order Detail
    'order-detail:headline': 'Commande {orderId}',

    // Order detail - Not found
    'warning:order-not-found:title': 'Votre commande n’a été pas trouvée',
    'warning:order-not-found:explanation': 'La sollicitation que vous avez demandé n’a pu pas être trouvé',
    'warning:order-not-found:link-text': 'Retour aux sollicitations',

    // Order history - Reorder
    'order-detail:reorder': 'Commander tout au nouveau',
    'reorder:success-toast': 'Les produits ont été ajoutés avec succès à votre panier',
    'reorder:error-toast': 'Les produits n’ont pas été ajouté à votre panier',
    'reorder:warning-toast': 'Certains produits n’ont pas été ajouté à votre panier',

    // Generic Error
    'warning:generic-error:title': 'Nous sommes désolés!',
    'warning:generic-error:explanation': 'Votre Brenntag Connect est pas en service au moment.',
    'warning:generic-error:contact':
        'Ne vous inquiétez pas! S’il vous plaît, contactez votre gestionnaire de compte pour vous aider.',

    // Invalid customer
    'warning:invalid-customer-id:title': 'Désolé!',
    'warning:invalid-customer-id:explanation': 'Votre Brenntag Connect n’est pas en service pour le moment.',
    'warning:invalid-customer-id:contact':
        'Ne vous inquiétez pas! Veuillez contacter votre gestionnaire de compte pour vous aider.',

    // Login failed
    'warning:login-failed:title': 'Quelque chose a mal tourné. Veuillez rafraîchir la page.',
    'warning:login-failed:explanation': 'La connexion a echoué, s’il vous plaît verifiez votre donnees.',
    'warning:login-failed:button-text': 'Essayez à nouveau',
    'warning:too-many-results:title': 'Veuillez nous aider à trouver votre (vos) commande(s)',
    'warning:too-many-results:explanation':
        'Nous avons besoin de plus d’informations pour trouver votre (vos) commande(s) - veuillez entrer plus de lettres du nom du produit.',
    'warning:customer-migrated-bep:title': 'Nouveau Brenntag Connect',
    'warning:customer-migrated-bep:explanation':
        'Nous avons à présent une nouvelle version de Brenntag Connect.<br/>Veuillez vous connecter ici: <a href="https://emea.brenntag.com/{region}">https://emea.brenntag.com/{region}/</a>',

    // Session expired
    'session-expired:header': 'La session a expiré',
    'session-expired:text': 'Votre session a expiré, connectez-vou a nouveau, s’il vous plaît',
    'session-expired:login-button': 'Connexion',

    // Not found
    'not-found:title': 'La page n’a pas été trouvée!',
    'not-found:message': 'La page que vous cherchez n’a pas été trouvée.',
    'not-found:orders': 'Aucun résultat trouvé.',
    'not-found:customers': 'Aucun client trouvé.',
    'not-found:users': 'Aucun utilisateur trouvé.',
    'not-found:link-text': 'Retour à la page d’acceuil',
    'not-found:invoices': 'Aucune facture trouvée.',
    'not-found:requests': 'Aucune demande trouvée.',
    'not-found:select': 'Aucun résultat trouvé.',

    // Maintenance
    'maintenance:title': 'Brenntag Connect n’est pas disponible',
    'maintenance:message':
        'Brenntag Connect est temporairement indisponible dû à une maintenance du système. Nous vous prions de bien vouloir nous excuser pour la gêne occasionnée',

    // Document not found
    'document-not-found:title': 'Ce document n’est pas disponible!',
    'document-not-found:message':
        "Ce document est actuellement indisponible. Pour plus d’informations, veuillez nous contacter à <a href='mailto:{csrEmail}'>{csrEmail}</a>.",

    // Scale prices
    'scale-prices:from': 'De',

    // Form validation
    'form-validation:required': 'S’il vous plaît, saisissez une valeur valide.',
    'form-validation:confirm': 'Le mot de passe ne correspond pas.',
    'form-validation:email': 'S’il vous plaît saisissez une adresse e-mail valide.',
    'form-validation:email-csr':
        'Désolé, vous ne pouvez créer un utilisateur CSR que s’il appartient à l’organisation Brenntag ou DigiB. Veuillez contacter le support de deuxième ligne en cas de questions.',
    'form-validation:email-in-use': 'Email déjà utilisé.',
    'form-validation:password': 'S’il vous plaît, saisissez un mot de passe valide.',
    'form-validation:password-repeat': 'S’il vous plaît répéter votre mot de passe.',
    'form-validation:date': 'S’il vous plaît, saisissez une date valide.',
    'form-validation:date.past': 'S’il vous plaît, selectionnez une date avant {date}.',
    'form-validation:date.future': 'S’il vous plaît, selectionnez une date après {date}.',
    'form-validation:date.weekday': 'S’il vous plaît sélectionner un jour de la semaine.',
    'form-validation:max-length': 'Vous pouvez remplir jusqu’à des caractères {length}.',
    'form-validation:min-length': 'Une quantité minimum de {length} caractères est nécessaire.',
    'form-validation:min-amount': 'Désolé, vous devez commander au moins {amount} {type}.',
    'form-validation:number': 'S’il vous plaît, saisissez une valeur valide.',
    'form-validation:has-uppercase': 'Le mot de passe doit contenir au minimum 1 majuscule.',
    'form-validation:has-lowercase': 'Le mot de passe doit contenir au minimum 1 minuscule.',
    'form-validation:has-numeric-character': 'Le mot de passe doit contenir au minimum 1 chiffre.',
    'form-validation:has-symbol-character': 'Le mot de passe doit contenir au minimum 1 symbol.',
    'form-validation:has-word-character': 'Les caractères spéciaux ne sont pas autorisés.',
    'form-validation:min-length-values': '{values} doivent avoir une longueur minimale de {length}.',
    'form-validation:do-not-exist': 'Le client suivant (s) n’existent pas: {values} valeurs.',
    'form-validation:max-amount': 'Désolé, vous ne pouvez commander qu’au plus {amount} {type}.',
    'form-validation:number-too-big': 'Le nombre est trop grand.',
    'form-validation:reference-required': 'S’il vous plaît ajouter une référence du client.',

    // Account and preference page
    'account:profile': 'Mon profil',
    'account:title': 'Compte et préférences',
    'account:first-name': 'Prénom',
    'account:last-name': 'Nom',
    'account:email': 'Adresse email',
    'account:name': 'Nom',
    'account:phone': 'Numéro de téléphone',
    'account:shipping-address': 'Adresse de livraison',
    'account:billing-address': 'Adresse de facturation',
    'account:personal-details': 'Détails personnels',
    'account:company-details': 'Client données',
    'account:users': 'Utilisateurs',
    'account:company-name': 'Nom de Client',
    'account:preferences': 'Préférences',
    'account:language': 'Langue par défaut',
    'account:related:title': 'Utilisateurs pour {company}',
    'account:related:intro':
        "La liste suivante inclut les utilisateurs ayant accès à Brenntag Connect au sein de votre organisation. Pour ajouter ou supprimer des utilisateurs, veuillez contacter <a href='mailto:{csrEmail}'>{ csrEmail }</a>.",
    'account:related:no-results':
        "Il n'y a pas d'autres utilisateurs qui ont accès à Brenntag Connect au sein de votre organisation. Pour ajouter ou supprimer des utilisateurs, veuillez contacter: <a href='mailto:{csrEmail}'>{csrEmail}</a>.",

    // Request information Modal
    'request-information-modal:add-to-cart': 'Ajouter au chariot',
    'request-information-modal:edit-quantity-cart': 'Modifiez quantite',
    'request-information-modal:add-to-cart.instruction': 'Entrez la quantité que vous souhaitez commander.',
    'request-information-modal:toasts-product-added': 'Ce produit à été ajouté à votre chariot.',
    'request-information-modal:toasts-product-adjusted': 'La quantité a été ajusté dans votre panier.',
    'request-information-modal:remove-from-cart': 'Supprimer le produit',
    'request-information-modal:remove-from-cart.instruction':
        'Êtes-vous sûr que vous voulez supprimer {productName} de votre panier?',
    'request-information-modal:toasts-product-removed': 'Ce produit a été supprimé de votre panier.',

    'request-information-modal:request-information': 'Demande d’information',
    'request-information-modal:request-quote': 'Commander',
    'request-information-modal:request-quote-confirm': 'Aperçu de la commande',
    'request-information-modal:step': 'étape',
    'request-information-modal:step.of': 'de',
    'request-information-modal:text':
        'Remplissez ce formulaire pour demander des informations supplémentaires sur ce produit.',
    'request-information-modal:text.quote': 'Saisissez cet formulaire pour commander a nouveau',
    'request-information-modal:success-toast':
        'Votre demande a été envoyée avec succès, l’équipe Brenntag Connect vous contactera dans les plus brefs délais.',
    'request-information-modal:send-request': 'Envoyer la demande',
    'request-information-modal:needs': 'Que souhaitez-vous demander?',
    'request-information-modal:document-types': 'Types de documents',
    'request-information-modal:application': 'Application',
    'request-information-modal:amount': 'Quantité',
    'request-information-modal:sample-quantity': 'Quantité d’échantillon',
    'request-information-modal:annual-quantity': 'Quantité annuelle',
    'request-information-modal:call-off-quantity': 'Quantité par livraison',
    'request-information-modal:requested-delivery-date': 'Date de livraison',
    'request-information-modal:requested-pickup-date': 'Date de ramassage demandée',
    'request-information-modal:order-quantity': 'Quantité de la commande',
    'request-information-modal:comment': 'Commentaires',
    'request-information-modal:step2': 'Suivant',
    'request-information-modal:requested-information': 'Informations demandées',

    'request-information-modal:document-types-other-placeholder': 'Nom de document',
    'request-information-modal:select-an-application': 'Sélectionnez une application',
    'request-information-modal:me': 'Moi',

    'request-information-modal:someone-else': 'Quelque’un d’autre',
    'request-information-modal:back-to-step-1': 'Retourner à l’étape 1',
    'request-information-modal:back-to-step-2': 'Retourner à l’étape 2',
    'request-information-modal:my-address-book': 'Adresse de livraison',
    'request-information-modal:new-address': 'Autre adresse de livraison',
    'request-information-modal:document-types.msds': 'Fiches de données de sécurité (FDS)',
    'request-information-modal:document-types.tds': 'Fiche Technique',
    'request-information-modal:document-types.specification': 'Spécification',
    'request-information-modal:document-types.coa': 'Certificat d’analyse (COA)',
    'request-information-modal:document-types.other': 'Autre document',
    'request-information-modal:requirement.price': 'Prix',
    'request-information-modal:requirement.document': 'Documents',
    'request-information-modal:requirement.sample': 'Échantillon',
    'request-information-modal:requirement.quote': 'Devis',
    'request-information-modal:order': 'Panier d’achat',
    'request-information-modal:reorder': 'Recommander',
    'request-information-modal:reorder:disabled-unavailableMaterial':
        'Cet article a été ajusté. Contactez votre équipe Brenntag Connect.',
    'request-information-modal:reorder:disabled-unavailableForCustomer':
        'Produit non disponible les conditions s’appliquent',
    'request-information-modal:reorder:disabled-unavailable':
        'Ceci est une échantillon. Contactez votre équipe Brenntag Connect.',
    'request-information-modal:reorder:select-delivery-date':
        'Veuillez sélectionner une date et une adresse de livraison',
    'request-information-modal:reorder:no-prices-available': 'Votre prix sera confirmé',
    'request-information-modal:preview-and-submit': 'Vérifier & soumettre',
    'request-information-modal:confirmation':
        'Merci pour votre commande. L’équipe Brenntag Connect confirmera votre commande dans les plus brefs délais. Après avoir reçu votre confirmation de commande, vous pouvez suivre l’état de votre commande dans l’aperçu des commandes Brenntag Connect.',
    'request-information-modal:available-prices': 'Prix disponibles',
    'request-information-modal:reorder:self-pickup': 'Ramassage dans un entrepôt',
    'request-information-modal:different-shipto-warning':
        'Si une autre adresse est choisie, le prix doit être confirmé.',

    'request-information:auto-complete:price':
        'Merci pour votre demande de prix. L’équipe Brenntag Connect reviendra chez vous dans les plus brefs délais.',
    'request-information:auto-complete:sample':
        'Merci pour votre demande d’echantillon. L’équipe Brenntag Connect reviendra chez vous dans les plus brefs délais.',
    'request-information:auto-complete:quote':
        'Votre commande a été envoyée à Brenntag et sera traitée dans les meilleurs délais. Une fois votre commande traitée, elle apparaît dans la liste des commandes dans Brenntag Connect.',
    'request-information:auto-complete:cart':
        'Votre commande a été envoyée à Brenntag et sera traitée dans les plus brefs délais. Dès que la commande a été traitée, elle apparaîtra dans la liste des commandes dans Brenntag Connect.',

    // Applications list for BNL and DE
    'request-information:application:ai-automotive': 'Automobile',
    'request-information:application:ai-construction-flooring': 'construction Plancher',
    'request-information:application:ai-coatings-architect-coatings': 'Architecte de revêtements. revêtements',
    'request-information:application:ai-construction-building-chemicals': 'Construction chimie de construction',
    'request-information:application:ai-construction-concrete-based-prod-': 'Construction prod base de béton.',
    'request-information:application:ai-decorative-paints': 'Peintures décoratives',
    'request-information:application:ai-dental': 'Dentaire',
    'request-information:application:ai-coatings-sealants': 'Revêtements scellants',
    'request-information:application:ai-printing-publishing': 'Imprimerie et édition',
    'request-information:application:ai-electronic': 'Électronique',
    'request-information:application:ai-epoxy-systems': 'Systèmes époxy',
    'request-information:application:ai-coatings-other': 'Autres revêtements',
    'request-information:application:ai-filter-and-environmental-technology':
        'Filtre et technologie de l’environnement',
    'request-information:application:ai-glass': 'Verre',
    'request-information:application:ai-rubber-seals-gaskets': 'Caoutchouc Joints & Joints',
    'request-information:application:ai-rubber-wires-cables': 'Fils et câbles en caoutchouc',
    'request-information:application:ai-rubber-latex-goods': 'Latex de caoutchouc Marchandises',
    'request-information:application:ai-rubber-medical-parts': 'Pièces en caoutchouc médical',
    'request-information:application:ai-rubber-tyre': 'pneu en caoutchouc',
    'request-information:application:ai-rubber-tubes-hoses': 'Tubes en caoutchouc et flexibles',
    'request-information:application:ai-rubber-other': 'caoutchouc et d’autres',
    'request-information:application:ai-rubber-compounds': 'composés en caoutchouc',
    'request-information:application:ai-homecare': 'soins à domicile',
    'request-information:application:ai-coatings-wood-coatings': 'Revêtements Revêtements de bois',
    'request-information:application:ai-coatings-industrial-coatings': 'Revêtements Revêtements industriels',
    'request-information:application:ai-industrial-cleaning': 'nettoyage industriel',
    'request-information:application:ai-ceramics': 'Céramique',
    'request-information:application:ai-coatings-adhesives': 'Adhésifs revêtements',
    'request-information:application:ai-coatings-raw-materials-other': 'Matières premières Revêtements autres',
    'request-information:application:ai-agro': 'agro',
    'request-information:application:ai-aerospace': 'Aérospatial',
    'request-information:application:ai-metal-surface-treatments': 'Traitements métalliques et surface',
    'request-information:application:ai-oil-upstream-fracs-drill': 'Huile en amont fracturations Drill',
    'request-information:application:ai-oil-midstream-n-spec': 'Huile Midstream N-spec',
    'request-information:application:ai-oil-midstream-gasplants': 'Huile Midstream gasplants',
    'request-information:application:ai-oil-midstream-compounder': 'Huile Midstream formulateur',
    'request-information:application:ai-oil-downstream-refinerie': 'L’aval pétrolier Raffinerie',
    'request-information:application:ai-oil-oil-gas-other': 'Huile et gaz autre',
    'request-information:application:ai-paper-manufacturers': 'Les fabricants de papier',
    'request-information:application:ai-pulp-paper-others': 'Autres pâtes et papiers',
    'request-information:application:ai-polymers-compounders': 'polymères Compounders',
    'request-information:application:ai-polymers-extrusion': 'extrusion des polymères',
    'request-information:application:ai-polymers-extrusion-foil': 'Les polymères d’extrusion, une feuille',
    'request-information:application:ai-polymers-pu-elastomers': 'PU polymères Elastomers',
    'request-information:application:ai-polymers-pvc': 'polymères PVC',
    'request-information:application:ai-polymers-raw-mat-producers': 'Polymères Mat. producteurs',
    'request-information:application:ai-polymers-other': 'polymères autres',
    'request-information:application:ai-polymers-elastomers-other': 'Autres polymères Elastomers',
    'request-information:application:ai-polymers-injection-moulding': 'Polymères moulage par injection',
    'request-information:application:ai-chemical-processing': 'traitement chimique',
    'request-information:application:ai-coatings-powder-coatings': 'Revêtements Les revêtements en poudre',
    'request-information:application:ai-cleaning-vehicles': 'nettoyage des véhicules',
    'request-information:application:ai-cleaning-floorcare': 'nettoyage entretien des sols',
    'request-information:application:ai-cleaning-food-area': 'Nettoyage secteur alimentaire',
    'request-information:application:ai-cleaning-other': 'nettoyage Autres',
    'request-information:application:ai-dry-clean-laundry': 'propre et sèche linge',
    'request-information:application:ai-lubricants': 'lubrifiants',
    'request-information:application:ai-lub-fuel-blends': 'Lub. Et des mélanges de carburant',
    'request-information:application:ai-construction-other': 'construction Autres',
    'request-information:application:ai-chemical-synthesis': 'Synthèse chimique',
    'request-information:application:ai-textile-leather': 'Textile et cuir',
    'request-information:application:ai-coatings-inks': 'Encres revêtements',
    'request-information:application:ai-packaging-converting': 'Emballage & Conversion',
    'request-information:application:ai-water-cond-serv-cy-waste-water': 'L’eau cond. serv. l’eau Cy/déchets',
    'request-information:application:ai-water-mun-waste-water': 'Mun eau. Eaux usées',
    'request-information:application:ai-water-mun-swimming-pool': 'Mun eau. piscine',
    'request-information:application:ai-water-mun-potable-water': 'Mun eau. eau potable',
    'request-information:application:ai-water-teatment-other': 'Eau Teatment Autres',
    'request-information:application:ai-waterconditioning': 'Waterconditioning',
    'request-information:application:ai-tissue-manufacturers': 'Les fabricants de tissus',
    'request-information:application:ai-construction-cement-products': 'Produits pour la Construction de ciment',
    'request-information:application:animal-nutrition:air-treatment': 'Alimentation animale - Traitement de l’air',
    'request-information:application:animal-nutrition:enrichment-(minerals-vitamins-amino-acids)':
        'Animal Nutrition - Enrichment (minerals, vitamins, amino acids…)',
    'request-information:application:animal-nutrition:antioxidants': 'Alimentation animale - Antioxidants',
    'request-information:application:animal-nutrition:flavours': 'Alimentation animale - Arômes',
    'request-information:application:animal-nutrition:binder-flow-additives-coagulation-agents':
        'Alimentation animale - Liants , agents coagulants',
    'request-information:application:animal-nutrition:products-for-biogas-systems':
        'Alimentation animale - Produits pour systèmes de biogaz',
    'request-information:application:animal-nutrition:biocides-and-hygiene-products':
        'Alimentation animale - Produits d’hygiène et agents biocides',
    'request-information:application:animal-nutrition:emulsifiers-stabilizers-thickener':
        'Alimentation animale - Emulsifiants, stabilisateurs, épaississeurs',
    'request-information:application:animal-nutrition:energy-suppliers':
        'Alimentation animale - Suppléments énergétiques',
    'request-information:application:animal-nutrition:colourings': 'Alimentation animale - Colorants',
    'request-information:application:animal-nutrition:coccidiostatics-and-histomonostats':
        'Alimentation animale - Coccidiostatiques et histomonostatiques',
    'request-information:application:animal-nutrition:preservatives': 'Alimentation animale - Agents de conservation',
    'request-information:application:animal-nutrition:performance-booster':
        'Alimentation animale - Booster de performance',
    'request-information:application:animal-nutrition:radionuclide-binder': 'Alimentation animale - Liant bionuclei',
    'request-information:application:animal-nutrition:acidity-regulators':
        'Alimentation animale - Regulateur d’acidité',
    'request-information:application:animal-nutrition:silage-additives':
        'Alimentation animale - Additifs pour ensilages',
    'request-information:application:animal-nutrition:technical-adjuvants':
        'Alimentation animale - Adjuvants techniques',
    'request-information:application:animal-nutrition:release-agents': 'Alimentation animale - Agents de démoulage',
    'request-information:application:animal-nutrition:water-treatment': 'Animal Nutrition - Water Treatment',
    'request-information:application:cosmetics:deodorant': 'Produits cosmétiques - Déodorants',
    'request-information:application:cosmetics:hair-care': 'Produits cosmétiques - Soins cheveux',
    'request-information:application:cosmetics:hair-styling': 'Produits cosmétiques - Produits coiffants',
    'request-information:application:cosmetics:make-up': 'Produits cosmétiques - Maquillage',
    'request-information:application:cosmetics:oral-hygiene': 'Produits cosmétiques - Hygiène orale',
    'request-information:application:cosmetics:perfume': 'Produits cosmétiques - Parfums',
    'request-information:application:cosmetics:skin-care': 'Produits cosmétiques - Soins visage',
    'request-information:application:cosmetics:skin-cleansing': 'Produits cosmétiques - Nettoyants et demaquillants',
    'request-information:application:cosmetics:sun': 'Produits cosmétiques - Produits solaires',
    'request-information:application:food:acidifiers-and-acidity-regulators':
        'Alimentation - Acidifiants et regulateurs d’acidité',
    'request-information:application:food:antioxidants': 'Alimentation - Antioxydants',
    'request-information:application:food:carriers-and-bulking-agents': 'Alimentation - Agents gonflants',
    'request-information:application:food:coating-and-release-agents':
        'Alimentation - Agents de revetments et de démoulage',
    'request-information:application:food:colourings': 'Alimentation - Colorants',
    'request-information:application:food:emulsifier': 'Alimentation - Émulsifiants',
    'request-information:application:food:flavours': 'Alimentation - Arômes',
    'request-information:application:food:foaming-agents': 'Alimentation - Agents moussants',
    'request-information:application:food:humectants': 'Alimentation - Humidifiants',
    'request-information:application:food:nutrition-enrichment': 'Alimentation - Nutrition',
    'request-information:application:food:raising-and-flour-tratment-agents':
        'Alimentation - Agents levants et traitements de la farine',
    'request-information:application:food:rreservatives-and-stabilizers':
        'Alimentation - Conservants et stabilisateurs',
    'request-information:application:food:smelting-agent': 'Alimentation - Agents fondants',
    'request-information:application:food:sugars-and-sweeteners': 'Alimentation - Sucres et édulcorants',
    'request-information:application:food:technical-adjuvants': 'Alimentation - Adjuvants techniques',
    'request-information:application:food:thickener-gelling-and-firming-agent':
        'Alimentation - Agents épaissisants , gelifiants et affermissants',
    'request-information:application:medicinal-product:capsule': 'Produits médicaux - gélule',
    'request-information:application:medicinal-product:cream-gel-salve': 'Produits médicaux - crème , gel , pommade',
    'request-information:application:medicinal-product:drops-(oral)': 'Medicinal Product drops (oral)',
    'request-information:application:medicinal-product:eye-drops': 'Produits médicaux - gouttes oculaires',
    'request-information:application:medicinal-product:parenteral': 'Produits médicaux - parenteral',
    'request-information:application:medicinal-product:sachet': 'Produits médicaux - sachets',
    'request-information:application:medicinal-product:spray-solution-(eyes-nose)':
        'Medicinal Product spray/solution (eyes, nose)',
    'request-information:application:medicinal-product:syrup-(oral)': 'Medicinal Product syrup (oral)',
    'request-information:application:medicinal-product:tablet': 'Produits médicaux - comprimés',
    'request-information:application:mp-ns-ph:others': 'MP/NS/PH - Autre',
    'request-information:application:nutritional-supplement:syrup-drops-solution':
        'Complements alimentaires en sirop , gouttes, solution',
    'request-information:application:nutritional-supplement:tablet-capsule-sachet':
        'Nutrition complements alimentaires en comprimes, gelules , sachets',
    'request-information:application:pharma:api-synthesis': 'Pharma - synthèse de l’API',
    'request-information:application:pharma:biotechnology': 'Pharma - Biotechnologie',
    'request-information:application:pharma:desinfection': 'Pharma - Désinfectants',
    'request-information:application:other': 'Autre',

    // Applications list for CEE

    'request-information:application:adblue:oem-&-truck-dealers': 'AdBlue - OEM & Truck dealers',
    'request-information:application:adblue:distribution': 'AdBlue - Distribution',
    'request-information:application:adblue:endusers,-carriers-&-fleetowners':
        'AdBlue - Endusers, Carriers & Fleetowners',
    'request-information:application:adblue:public-filling-stations-(codo/dodo)':
        'AdBlue - Public Filling Stations (CODO/DODO)',
    'request-information:application:adblue:retail-&-public-filling-stations--(codo)':
        'AdBlue - Retail & Public Filling Stations  (CODO)',
    'request-information:application:adblue:others': 'AdBlue - Others',
    'request-information:application:agro:desinfection': 'Agro - Desinfection',
    'request-information:application:agro:herbicides-&-pesticides': 'Agro - Herbicides & Pesticides',
    'request-information:application:agro:mixed-production': 'Agro - Mixed Production',
    'request-information:application:agro:fertilizers': 'Agro - Fertilizers',
    'request-information:application:agro:horticulture': 'Agro - Horticulture',
    'request-information:application:agro:farmers': 'Agro - Farmers',
    'request-information:application:agro:orchards': 'Agro - Orchards',
    'request-information:application:agro:others': 'Agro - Others',
    'request-information:application:cement-mills:': 'Cement Mills',
    'request-information:application:cleaning:cleaning-&-cleansing': 'Cleaning - Cleaning & Cleansing',
    'request-information:application:cleaning:homecare': 'Cleaning - Homecare',
    'request-information:application:cleaning:distributors': 'Cleaning - Distributors',
    'request-information:application:cleaning:auto-care-cleaning': 'Cleaning - Auto Care Cleaning',
    'request-information:application:cleaning:industrial-&-institutional-cleaning':
        'Cleaning - Industrial & Institutional Cleaning',
    'request-information:application:cleaning:others': 'Cleaning - Others',
    'request-information:application:heat-engineering:': 'Heat Engineering',
    'request-information:application:chemical-synthesis:': 'Chemical Synthesis',
    'request-information:application:distributors:others': 'Distributors - Others',
    'request-information:application:electronics:medical-equipment-&-tools': 'Electronics - Medical Equipment & Tools',
    'request-information:application:electronics:automotive-electronics': 'Electronics - Automotive Electronics',
    'request-information:application:electronics:lighting-equipment': 'Electronics - Lighting Equipment',
    'request-information:application:electronics:brown-goods': 'Electronics - Brown Goods',
    'request-information:application:electronics:others': 'Electronics - Others',
    'request-information:application:power-industry:': 'Power Industry',
    'request-information:application:renewable-energy:biodiesel': 'Renewable Energy - Biodiesel',
    'request-information:application:renewable-energy:biogas': 'Renewable Energy - Biogas',
    'request-information:application:renewable-energy:solar': 'Renewable Energy - Solar',
    'request-information:application:renewable-energy:others': 'Renewable Energy - Others',
    'request-information:application:coatings-&-construction:construction:building-chemicals':
        'Coatings & Construction - Construction - Building Chemicals',
    'request-information:application:coatings-&-construction:construction:fireproof-materials':
        'Coatings & Construction - Construction - Fireproof Materials',
    'request-information:application:coatings-&-construction:construction:gipsum-cardboard':
        'Coatings & Construction - Construction - Gipsum Cardboard',
    'request-information:application:coatings-&-construction:construction:plywood-&-particle-boards':
        'Coatings & Construction - Construction - Plywood & Particle Boards',
    'request-information:application:coatings-&-construction:construction:floorings':
        'Coatings & Construction - Construction - Floorings',
    'request-information:application:coatings-&-construction:construction:concrete-based-products':
        'Coatings & Construction - Construction - Concrete-based Products',
    'request-information:application:coatings-&-construction:construction:cement-based-products':
        'Coatings & Construction - Construction - Cement-based Products',
    'request-information:application:coatings-&-construction:construction:plasters':
        'Coatings & Construction - Construction - Plasters',
    'request-information:application:coatings-&-construction:construction:mineral-wool-':
        'Coatings & Construction - Construction - Mineral Wool ',
    'request-information:application:coatings-&-construction:construction:others':
        'Coatings & Construction - Construction - Others',
    'request-information:application:coatings-&-construction:distributors': 'Coatings & Construction - Distributors',
    'request-information:application:coatings-&-construction:elastomers:pu-elastomers':
        'Coatings & Construction - Elastomers - PU Elastomers',
    'request-information:application:coatings-&-construction:elastomers:others':
        'Coatings & Construction - Elastomers - Others',
    'request-information:application:coatings-&-construction:coatings:architectural-coatings':
        'Coatings & Construction - Coatings - Architectural Coatings',
    'request-information:application:coatings-&-construction:coatings:wood-coatings':
        'Coatings & Construction - Coatings - Wood Coatings',
    'request-information:application:coatings-&-construction:coatings:powder-coatings':
        'Coatings & Construction - Coatings - Powder Coatings',
    'request-information:application:coatings-&-construction:coatings:industrial-coatings':
        'Coatings & Construction - Coatings - Industrial Coatings',
    'request-information:application:coatings-&-construction:coatings:primers':
        'Coatings & Construction - Coatings - Primers',
    'request-information:application:coatings-&-construction:coatings:automotive-coatings':
        'Coatings & Construction - Coatings - Automotive Coatings',
    'request-information:application:coatings-&-construction:coatings:thinners':
        'Coatings & Construction - Coatings - Thinners',
    'request-information:application:coatings-&-construction:coatings:others':
        'Coatings & Construction - Coatings - Others',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:adhesives':
        'Coatings & Construction - Adhesives & Sealants - Adhesives',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:hot-melts':
        'Coatings & Construction - Adhesives & Sealants - Hot Melts',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:sealants':
        'Coatings & Construction - Adhesives & Sealants - Sealants',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:others':
        'Coatings & Construction - Adhesives & Sealants - Others',
    'request-information:application:coatings-&-construction:composites': 'Coatings & Construction - Composites',
    'request-information:application:coatings-&-construction:producers-of-c&c-raw-materials':
        'Coatings & Construction - Producers of C&C Raw Materials',
    'request-information:application:coatings-&-construction:inks': 'Coatings & Construction - Inks',
    'request-information:application:coatings-&-construction:others': 'Coatings & Construction - Others',
    'request-information:application:mining:natural-building-materials-&-stones':
        'Mining - Natural Building Materials & Stones',
    'request-information:application:mining:metal-ore': 'Mining - Metal Ore',
    'request-information:application:mining:coal': 'Mining - Coal',
    'request-information:application:mining:others': 'Mining - Others',
    'request-information:application:steelworks:': 'Steelworks',
    'request-information:application:municipalities-&-institutions:schools-&-universities':
        'Municipalities & Institutions - Schools & Universities',
    'request-information:application:municipalities-&-institutions:hospitals':
        'Municipalities & Institutions - Hospitals',
    'request-information:application:municipalities-&-institutions:others': 'Municipalities & Institutions - Others',
    'request-information:application:coke-engineering:': 'Coke Engineering',
    'request-information:application:fotochemical-&-film-materials:': 'Fotochemical & Film Materials',
    'request-information:application:explosives-&-pyrotechnics:': 'Explosives & Pyrotechnics',
    'request-information:application:environmental-protection:consulting-&-services':
        'Environmental Protection - Consulting & Services',
    'request-information:application:environmental-protection:soil-remediation':
        'Environmental Protection - Soil Remediation',
    'request-information:application:environmental-protection:incineration-plants':
        'Environmental Protection - Incineration Plants',
    'request-information:application:environmental-protection:waste-dumps': 'Environmental Protection - Waste Dumps',
    'request-information:application:reagents:': 'Reagents',
    'request-information:application:printing-&-publishing:narrow-web': 'Printing & Publishing - Narrow Web',
    'request-information:application:printing-&-publishing:packaging-printing':
        'Printing & Publishing - Packaging Printing',
    'request-information:application:printing-&-publishing:distributors': 'Printing & Publishing - Distributors',
    'request-information:application:printing-&-publishing:printing-inks': 'Printing & Publishing - Printing Inks',
    'request-information:application:printing-&-publishing:flexography': 'Printing & Publishing - Flexography',
    'request-information:application:printing-&-publishing:bookbinding': 'Printing & Publishing - Bookbinding',
    'request-information:application:printing-&-publishing:sheet-fed-offset':
        'Printing & Publishing - Sheet Fed Offset',
    'request-information:application:printing-&-publishing:cold-set-offset': 'Printing & Publishing - Cold Set Offset',
    'request-information:application:printing-&-publishing:heat-set-offset': 'Printing & Publishing - Heat Set Offset',
    'request-information:application:printing-&-publishing:serigraphy': 'Printing & Publishing - Serigraphy',
    'request-information:application:printing-&-publishing:rotogravure': 'Printing & Publishing - Rotogravure',
    'request-information:application:printing-&-publishing:others': 'Printing & Publishing - Others',
    'request-information:application:loundries:': 'Loundries',
    'request-information:application:automotive-oems:': 'Automotive OEMs',
    'request-information:application:automotive-oems---service-companies:': 'Automotive OEMs - service companies',
    'request-information:application:machinery-production:measuring-instruments':
        'Machinery Production - Measuring Instruments',
    'request-information:application:machinery-production:mechanized-household-equipment':
        'Machinery Production - Mechanized Household Equipment',
    'request-information:application:machinery-production:others': 'Machinery Production - Others',
    'request-information:application:ceramic-industry:household-ceramic': 'Ceramic Industry - Household Ceramic',
    'request-information:application:ceramic-industry:others': 'Ceramic Industry - Others',
    'request-information:application:wood-industry-&-woodwork:furniture': 'Wood Industry & Woodwork - Furniture',
    'request-information:application:wood-industry-&-woodwork:woodwork': 'Wood Industry & Woodwork - Woodwork',
    'request-information:application:wood-industry-&-woodwork:wood-industry':
        'Wood Industry & Woodwork - Wood Industry',
    'request-information:application:wood-industry-&-woodwork:others': 'Wood Industry & Woodwork - Others',
    'request-information:application:pharma:distributors': 'Pharma - Distributors',
    'request-information:application:pharma:human-&-veterinary-medicines': 'Pharma - Human & Veterinary Medicines',
    'request-information:application:pharma:natural-remedies': 'Pharma - Natural Remedies',
    'request-information:application:pharma:pharmaceutical-starting-material-producers':
        'Pharma - Pharmaceutical Starting Material Producers',
    'request-information:application:pharma:others': 'Pharma - Others',
    'request-information:application:rubber:footwear': 'Rubber - Footwear',
    'request-information:application:rubber:distributors': 'Rubber - Distributors',
    'request-information:application:rubber:non-automotive-profiles': 'Rubber - Non Automotive Profiles',
    'request-information:application:rubber:automotive-profiles': 'Rubber - Automotive Profiles',
    'request-information:application:rubber:general-rubber-goods': 'Rubber - General Rubber Goods',
    'request-information:application:rubber:wire-&-cables': 'Rubber - Wire & Cables',
    'request-information:application:rubber:tires:automotive-tires': 'Rubber - Tires - Automotive Tires',
    'request-information:application:rubber:tires:industrial-tires': 'Rubber - Tires - Industrial Tires',
    'request-information:application:rubber:tires:others': 'Rubber - Tires - Others',
    'request-information:application:rubber:non-automotive-hoses': 'Rubber - Non Automotive Hoses',
    'request-information:application:rubber:automotive-hoses': 'Rubber - Automotive Hoses',
    'request-information:application:rubber:automotive-belts': 'Rubber - Automotive Belts',
    'request-information:application:rubber:non-automotive-belts': 'Rubber - Non Automotive Belts',
    'request-information:application:rubber:rubber-rolls-and-wheels': 'Rubber - Rubber Rolls and Wheels',
    'request-information:application:rubber:latex': 'Rubber - Latex',
    'request-information:application:rubber:custom-mixing': 'Rubber - Custom Mixing',
    'request-information:application:rubber:others': 'Rubber - Others',
    'request-information:application:cosmetics:distributors': 'Cosmetics - Distributors',
    'request-information:application:cosmetics:general': 'Cosmetics - General',
    'request-information:application:cosmetics:decorative-products-&-make-up':
        'Cosmetics - Decorative Products & Make-Up',
    'request-information:application:cosmetics:soap-&-candles': 'Cosmetics - Soap & Candles',
    'request-information:application:cosmetics:perfumes-&-fragrance': 'Cosmetics - Perfumes & Fragrance',
    'request-information:application:cosmetics:skin-care-and-sun-cosmetics': 'Cosmetics - Skin Care and Sun Cosmetics',
    'request-information:application:cosmetics:hair-care-and-body-wash': 'Cosmetics - Hair Care and Body Wash',
    'request-information:application:cosmetics:toll-manufacturers-&-contractors':
        'Cosmetics - Toll Manufacturers & Contractors',
    'request-information:application:cosmetics:others': 'Cosmetics - Others',
    'request-information:application:metal:zinc-coating---automotive-components':
        'Metal - Zinc Coating - Automotive Components',
    'request-information:application:metal:zinc-coating---non-automotive-components':
        'Metal - Zinc Coating - Non Automotive Components',
    'request-information:application:metal:distribution': 'Metal - Distribution',
    'request-information:application:metal:electroplating---automotive-components':
        'Metal - Electroplating - Automotive Components',
    'request-information:application:metal:electroplating---non-automotive-components':
        'Metal - Electroplating - Non Automotive Components',
    'request-information:application:metal:automotive-components---others': 'Metal - Automotive Components - Others',
    'request-information:application:metal:non-automotive-components---others':
        'Metal - Non Automotive Components - Others',
    'request-information:application:metal:vanishing---automotive-components':
        'Metal - Vanishing - Automotive Components',
    'request-information:application:metal:vanishing---non-automotive-components':
        'Metal - Vanishing - Non Automotive Components',
    'request-information:application:metal:foundry---automotive-components': 'Metal - Foundry - Automotive Components',
    'request-information:application:metal:foundry---non-automotive-components':
        'Metal - Foundry - Non Automotive Components',
    'request-information:application:pulp-&-paper:tissue-manufacturers': 'Pulp & Paper - Tissue Manufacturers',
    'request-information:application:pulp-&-paper:board-manufacturers': 'Pulp & Paper - Board Manufacturers',
    'request-information:application:pulp-&-paper:packaging-&-converting': 'Pulp & Paper - Packaging & Converting',
    'request-information:application:pulp-&-paper:paper-manufacturers': 'Pulp & Paper - Paper Manufacturers',
    'request-information:application:pulp-&-paper:others': 'Pulp & Paper - Others',
    'request-information:application:spirit-industry:': 'Spirit Industry',
    'request-information:application:food:chocolate-&-confection:confectionery':
        'Food - Chocolate & Confection - Confectionery',
    'request-information:application:food:chocolate-&-confection:chocolate':
        'Food - Chocolate & Confection - Chocolate',
    'request-information:application:food:food-general:sugar-factories': 'Food - Food General - Sugar Factories',
    'request-information:application:food:beverages:breweries,-malting': 'Food - Beverages - Breweries, malting',
    'request-information:application:food:beverages:alcoholic-beverages---others':
        'Food - Beverages - Alcoholic Beverages - Others',
    'request-information:application:food:beverages:non-alcoholic-beverages':
        'Food - Beverages - Non Alcoholic Beverages',
    'request-information:application:food:beverages:instant-drinks': 'Food - Beverages - Instant drinks',
    'request-information:application:food:bakery-&-bread:bars-and-cereals': 'Food - Bakery & Bread - Bars and cereals',
    'request-information:application:food:bakery-&-bread:bakery-mix-blenders':
        'Food - Bakery & Bread - Bakery Mix Blenders',
    'request-information:application:food:bakery-&-bread:baked-goods': 'Food - Bakery & Bread - Baked goods',
    'request-information:application:food:food-general:flavour-producers': 'Food - Food General - Flavour Producers',
    'request-information:application:food:food-general:other-ingredient-producers':
        'Food - Food General - Other Ingredient Producers',
    'request-information:application:food:meat-and-poultry:blends': 'Food - Meat and poultry - Blends',
    'request-information:application:food:meat-and-poultry:production': 'Food - Meat and poultry - Production',
    'request-information:application:food:meat-and-poultry:fish-industry': 'Food - Meat and poultry - Fish Industry',
    'request-information:application:food:meat-and-poultry:slaughterhouses':
        'Food - Meat and poultry - Slaughterhouses',
    'request-information:application:food:dairy-&-ice-cream:ice-cream': 'Food - Dairy & Ice Cream - Ice Cream',
    'request-information:application:food:dairy-&-ice-cream:dairy-ingredients':
        'Food - Dairy & Ice Cream - Dairy Ingredients',
    'request-information:application:food:dairy-&-ice-cream:dairy-consumer-products':
        'Food - Dairy & Ice Cream - Dairy Consumer Products',
    'request-information:application:food:food-general:starch-industry': 'Food - Food General - Starch Industry',
    'request-information:application:food:food-general:food---distributors':
        'Food - Food General - Food - Distributors',
    'request-information:application:food:food-general:blenders---other': 'Food - Food General - Blenders - Other',
    'request-information:application:food:food-general:fats-&-oils': 'Food - Food General - Fats & Oils',
    'request-information:application:food:food-general:tobacco': 'Food - Food General - Tobacco',
    'request-information:application:food:food-general:potato-processing': 'Food - Food General - Potato Processing',
    'request-information:application:food:fruit-&-vegetable-processing:jams,-fruit-preparations':
        'Food - Fruit & Vegetable Processing - Jams, fruit preparations',
    'request-information:application:food:fruit-&-vegetable-processing:fruit-concentrates':
        'Food - Fruit & Vegetable Processing - Fruit concentrates',
    'request-information:application:food:fruit-&-vegetable-processing:others':
        'Food - Fruit & Vegetable Processing - Others',
    'request-information:application:food:function-food,-supplements:food-supplements':
        'Food - Function food, supplements - Food supplements',
    'request-information:application:food:function-food,-supplements:sport-nutrition':
        'Food - Function food, supplements - Sport nutrition',
    'request-information:application:food:function-food,-supplements:baby-food':
        'Food - Function food, supplements - Baby food',
    'request-information:application:food:function-food,-supplements:special-food':
        'Food - Function food, supplements - Special food',
    'request-information:application:food:convenience-food:savoury---others':
        'Food - Convenience Food - Savoury - Others',
    'request-information:application:food:convenience-food:soups,-sauces,-dressings':
        'Food - Convenience Food - Soups, Sauces, Dressings',
    'request-information:application:food:convenience-food:convenience-food':
        'Food - Convenience Food - Convenience Food',
    'request-information:application:food:others': 'Food - Others',
    'request-information:application:glass-industry:household-glass-&-glass-packaging':
        'Glass Industry - Household Glass & Glass Packaging',
    'request-information:application:glass-industry:industrial-&-technical-glass':
        'Glass Industry - Industrial & Technical Glass',
    'request-information:application:glass-industry:automotive-glass': 'Glass Industry - Automotive Glass',
    'request-information:application:glass-industry:others': 'Glass Industry - Others',
    'request-information:application:textile-&-leather:carpets-&-linings': 'Textile & Leather - Carpets & Linings',
    'request-information:application:textile-&-leather:knitted-fabrics': 'Textile & Leather - Knitted Fabrics',
    'request-information:application:textile-&-leather:automotive-leather-components':
        'Textile & Leather - Automotive Leather Components',
    'request-information:application:textile-&-leather:leather-tanning': 'Textile & Leather - Leather Tanning',
    'request-information:application:textile-&-leather:leather-clothing,-footwear,-goods':
        'Textile & Leather - Leather Clothing, Footwear, Goods',
    'request-information:application:textile-&-leather:yarn-&-threads': 'Textile & Leather - Yarn & Threads',
    'request-information:application:textile-&-leather:tights': 'Textile & Leather - Tights',
    'request-information:application:textile-&-leather:natural-textiles': 'Textile & Leather - Natural Textiles',
    'request-information:application:textile-&-leather:synthetic-textiles': 'Textile & Leather - Synthetic Textiles',
    'request-information:application:textile-&-leather:unwoven-fabrics': 'Textile & Leather - Unwoven Fabrics',
    'request-information:application:textile-&-leather:others': 'Textile & Leather - Others',
    'request-information:application:oil-&-gas:downstream---refineries,-pipelines':
        'Oil & Gas - DownStream - Refineries, Pipelines',
    'request-information:application:oil-&-gas:downstream---chemical-plants':
        'Oil & Gas - DownStream - Chemical Plants',
    'request-information:application:oil-&-gas:distribution': 'Oil & Gas - Distribution',
    'request-information:application:oil-&-gas:midstream---specialty-gases': 'Oil & Gas - MidStream - Specialty Gases',
    'request-information:application:oil-&-gas:midstream---compounders': 'Oil & Gas - MidStream - Compounders',
    'request-information:application:oil-&-gas:midstream---n-spec': 'Oil & Gas - MidStream - N-Spec',
    'request-information:application:oil-&-gas:midstream---production': 'Oil & Gas - MidStream - Production',
    'request-information:application:oil-&-gas:midstream---gasplants': 'Oil & Gas - MidStream - Gasplants',
    'request-information:application:oil-&-gas:upstream---drilling': 'Oil & Gas - UpStream - Drilling',
    'request-information:application:oil-&-gas:services': 'Oil & Gas - Services',
    'request-information:application:oil-&-gas:others': 'Oil & Gas - Others',
    'request-information:application:lubricants:asphalts': 'Lubricants - Asphalts',
    'request-information:application:lubricants:bio-diesel---producers': 'Lubricants - Bio Diesel - Producers',
    'request-information:application:lubricants:bio-ethanol---producers': 'Lubricants - Bio Ethanol - Producers',
    'request-information:application:lubricants:distribution': 'Lubricants - Distribution',
    'request-information:application:lubricants:blending-facilities': 'Lubricants - Blending Facilities',
    'request-information:application:lubricants:industrial-lubricants': 'Lubricants - Industrial Lubricants',
    'request-information:application:lubricants:automotive-lubricants': 'Lubricants - Automotive Lubricants',
    'request-information:application:lubricants:release-agents': 'Lubricants - Release Agents',
    'request-information:application:lubricants:others': 'Lubricants - Others',
    'request-information:application:transportation:airports': 'Transportation - Airports',
    'request-information:application:transportation:others': 'Transportation - Others',
    'request-information:application:tourism:': 'Tourism',
    'request-information:application:polymers:distributors': 'Polymers - Distributors',
    'request-information:application:polymers:polyurethanes:automotive-components':
        'Polymers - Polyurethanes - Automotive Components',
    'request-information:application:polymers:polyurethanes:flexible-&-rigid-polyurethanes-':
        'Polymers - Polyurethanes - Flexible & Rigid Polyurethanes ',
    'request-information:application:polymers:polyurethanes:polyurethane-systems':
        'Polymers - Polyurethanes - Polyurethane Systems',
    'request-information:application:polymers:polyurethanes:others': 'Polymers - Polyurethanes - Others',
    'request-information:application:polymers:compounders': 'Polymers - Compounders',
    'request-information:application:polymers:raw-material-producers': 'Polymers - Raw Material Producers',
    'request-information:application:polymers:injection-moulding:non-automotive-components':
        'Polymers - Injection Moulding - Non Automotive Components',
    'request-information:application:polymers:injection-moulding:automotive-components':
        'Polymers - Injection Moulding - Automotive Components',
    'request-information:application:polymers:regranulates': 'Polymers - Regranulates',
    'request-information:application:polymers:extrusion:films': 'Polymers - Extrusion - Films',
    'request-information:application:polymers:extrusion:automotive-components':
        'Polymers - Extrusion - Automotive Components',
    'request-information:application:polymers:extrusion:flooring,-coated-textiles':
        'Polymers - Extrusion - Flooring, Coated Textiles',
    'request-information:application:polymers:extrusion:plates-&-profiles': 'Polymers - Extrusion - Plates & Profiles',
    'request-information:application:polymers:extrusion:pipes': 'Polymers - Extrusion - Pipes',
    'request-information:application:polymers:extrusion:polymers-textiles-&-fibers':
        'Polymers - Extrusion - Polymers Textiles & Fibers',
    'request-information:application:polymers:extrusion:others': 'Polymers - Extrusion - Others',
    'request-information:application:polymers:chemosets': 'Polymers - Chemosets',
    'request-information:application:polymers:thermosets': 'Polymers - Thermosets',
    'request-information:application:polymers:others': 'Polymers - Others',
    'request-information:application:services:': 'Services',
    'request-information:application:water-treatment:swimming-pools': 'Water Treatment - Swimming Pools',
    'request-information:application:water-treatment:municipalities-for-potable-water':
        'Water Treatment - Municipalities for Potable Water',
    'request-information:application:water-treatment:municipalities-for-potable-and-waste-water':
        'Water Treatment - Municipalities for Potable and Waste Water',
    'request-information:application:water-treatment:distributors': 'Water Treatment - Distributors',
    'request-information:application:water-treatment:environmental-&-equipment-companies':
        'Water Treatment - Environmental & Equipment Companies',
    'request-information:application:water-treatment:municipalities-for-waste-water':
        'Water Treatment - Municipalities for Waste Water',
    'request-information:application:water-treatment:water-conditioning-service-companies':
        'Water Treatment - Water Conditioning Service Companies',
    'request-information:application:water-treatment:institutional-service-companies':
        'Water Treatment - Institutional Service Companies',
    'request-information:application:water-treatment:other-water-treatment-service-companies':
        'Water Treatment - Other Water Treatment Service Companies',
    'request-information:application:water-treatment:other-water-treatment-end-users':
        'Water Treatment - Other Water Treatment end users',
    'request-information:application:water-treatment:others': 'Water Treatment - Others',
    'request-information:application:animal-nutrition:distributors': 'Animal Nutrition - Distributors',
    'request-information:application:animal-nutrition:homemixers-&-farmers:cattle-and-sheep':
        'Animal Nutrition - Homemixers & Farmers - Cattle and Sheep',
    'request-information:application:animal-nutrition:homemixers-&-farmers:poultry':
        'Animal Nutrition - Homemixers & Farmers - Poultry',
    'request-information:application:animal-nutrition:homemixers-&-farmers:pigs':
        'Animal Nutrition - Homemixers & Farmers - Pigs',
    'request-information:application:animal-nutrition:homemixers-&-farmers:others':
        'Animal Nutrition - Homemixers & Farmers - Others',
    'request-information:application:animal-nutrition:additive-blenders': 'Animal Nutrition - Additive Blenders',
    'request-information:application:animal-nutrition:mixed-production': 'Animal Nutrition - Mixed Production',
    'request-information:application:animal-nutrition:aquafeed': 'Animal Nutrition - Aquafeed',
    'request-information:application:animal-nutrition:compound-feed': 'Animal Nutrition - Compound Feed',
    'request-information:application:animal-nutrition:premix': 'Animal Nutrition - Premix',
    'request-information:application:animal-nutrition:milk-replacers': 'Animal Nutrition - Milk Replacers',
    'request-information:application:animal-nutrition:integrators': 'Animal Nutrition - Integrators',
    'request-information:application:animal-nutrition:feed-ingredients': 'Animal Nutrition - Feed Ingredients',
    'request-information:application:animal-nutrition:animal-waste-processing':
        'Animal Nutrition - Animal Waste Processing',
    'request-information:application:animal-nutrition:veterinarians': 'Animal Nutrition - Veterinarians',
    'request-information:application:animal-nutrition:petfood': 'Animal Nutrition - Petfood',
    'request-information:application:animal-nutrition:others': 'Animal Nutrition - Others',
    'request-information:application:others:': 'Others',

    // languages
    'language:de': 'Allemand',
    'language:en': 'Anglais',
    'language:fr': 'Français',
    'language:nl': 'Néerlandais',
    'language:pl': 'Polonaise',

    // countries
    'country:de': 'Allemagne',
    'country:en': 'Angleterre',
    'country:fr': 'France',
    'country:nl': 'Pays-Bas',
    'country:be': 'Belgique',
    'country:za': 'Afrique du Sud',
    'country:ae': 'Emirats Arabes Unis',
    'country:ke': 'Kenya',
    'country:tz': 'Tanzanie',
    'country:ug': 'Ouganda',
    'country:pl': 'Pologne',
    'country:a1': 'Pologne',

    // Onboarding
    'onboarding:skip-tour.skip': 'Annuler le tour',
    'onboarding:skip-tour.close': 'Fermer le tour',
    'onboarding:slider-button.previous': 'Précedent',
    'onboarding:slider-button.next': 'Suivant',
    'onboarding:slider-button.start': 'Start',
    'onboarding:slider-button.finish': 'Découvrir Brenntag Connect',
    'onboarding:slide-sample.header': 'Brenntag Connect permet de:',
    'onboarding:slide-sample.title': 'Demander des informations sur les produits',
    'onboarding:slide-sample.content': 'Demandez des prix et documentation requise directement dans votre compte.',
    'onboarding:slide-order-status.header': 'Brenntag Connect permet de:',
    'onboarding:slide-order-status.title': 'Vérifier le statut et l’historique de vos commandes',
    'onboarding:slide-order-status.content':
        'Suivez le statut de vos commandes et accédez à l’historique de vos commandes des 24 derniers mois.',
    'onboarding:slide-introduction.title':
        'Bienvenue sur Brenntag Connect - votre accès vers les produits et services de Brenntag',
    'onboarding:slide-introduction.content':
        'Il y a plus à découvrir dans Brenntag Connect. Cliquez sur "Start" pour une courte visite!',
    'onboarding:slide-finish.title': 'Nous sommes là pour vous aider!',
    'onboarding:slide-finish.content':
        'Si vous avez des questions sur l’utilisation de Brenntag Connect, veuillez contacter notre <a href="mailto:{csrEmail}"> équipe d’assistance Brenntag Connect</a>. Vous pouvez également consulter la <a href="{faqLink}" target="_blank">FAQ</a>. Vous êtes les bienvenus!',

    // welome videos titles
    'welcome-video-title:WELCOME': 'Bienvenue',
    'welcome-video-title:FIRSTTIME': 'Première connexion, comptes et préférences',
    'welcome-video-title:ORDERS': 'Commandes',
    'welcome-video-title:INVOICES': 'Factures',
    'welcome-video-title:PRODUCTS': 'Produits',
    'welcome-video-title:REQUESTS': 'Demandes',
    'welcome-video-title:FEEDBACK': 'Retour d’information',
    'welcome-video-title:QUOTES': 'Devis',

    'welcome-video:headline': 'Voulez-vous savoir comment utiliser Brenntag Connect?',
    'welcome-video:instruction': 'Regardez notre vidéo avec la démo Brenntag Connect.',

    // Terms of Use
    'terms-of-usage:headline': 'Conditions d’utilisation',
    'terms-of-usage:decline': 'Refuser',
    'terms-of-usage:accept': 'J’accepte',
    'terms-of-usage:updated':
        'Nous vous prions d’accepter les conditions d’utilisation avant de continuer avec Brenntag Connect. Les conditions d’utilisation sont accessibles à tout moment via un lien dans le pied de page de l’application.',
    'terms-of-usage:content': `## Brenntag Connect - Customer Interface client DigiB

### Conditions d’utilisation
DigiB B.V., une société à responsabilité limitée légalement constituée en vertu des lois des Pays-Bas (ci-après dénommée « Exploitant »), est une entité du Groupe Brenntag, leader mondial de la distribution de produits chimiques, et exploite le service en ligne ainsi que la plateforme d’information « Brenntag Connect » (ci-après dénommée « Portail ») ; elle offre aux utilisateurs inscrits (personnes physiques ; les utilisateurs de la même société doivent s’inscrire à titre individuel afin garantir qu’un compte personnel leur sera attribué en tant que personnes physiques), diverses fonctionnalités (sous réserve qu’elles soient activées), par exemple passer ou renouveler une commande, faire une demande de produit sur Internet, consulter l’historique des commandes, rechercher de nouveaux produits, accéder aux produits pertinents et aux documents associés aux commandes. Lorsqu’il utilise le Portail, l’utilisateur est tenu de respecter à tout instant les principes détaillés dans les présentes Conditions d’utilisation ainsi que les dispositions légales.

### 1. Champ d’application
1.1 Les présentes Conditions d’utilisation s’appliquent exclusivement à l’utilisation du Portail. L’Exploitant se réserve le droit de modifier les Conditions d’utilisation à tout moment et avec effet immédiat.

1.2 Les Conditions générales de vente (ci-après dénommées « CGV ») de l’entité vendeuse du Groupe Brenntag (ci-après dénommée « vendeur »), consultables via le Portail, s’appliquent à toutes les transactions commerciales en ligne en cours et à venir passant par le Portail. Les Conditions générales de vente des utilisateurs ou de leurs clients qui seraient incompatibles avec les présentes Conditions d’utilisation ou les CGV du vendeur seront réputées inapplicables, à moins que, selon le cas, le vendeur reconnaisse expressément leur validité par écrit (au sens des présentes Conditions, « par écrit » inclue les e-mails et les télécopies).

### 2. Inscription
2.1 S’il n’est pas activement invité en tant que client existant, tout utilisateur souhaitant utiliser les services du Portail devra soumettre une demande d’inscription à l’Exploitant. L’utilisateur devra transmettre toutes les informations demandées par l’Exploitant, puis devra s’assurer que ces informations soient à jour en continu pendant toute la durée de la procédure de demande et/ou d’inscription. Une fois ces informations transmises en intégralité, l’Exploitant décidera, à sa seule discrétion, s’il convient de procéder à l’inscription. L’utilisateur sera informé par écrit du statut de son inscription.

2.2 L’Exploitant est en droit de refuser ou de résilier l’inscription sans fournir de motifs. L’Exploitant ne saurait être tenu pour responsable d’un éventuel retard ou refus d’inscription.

### 3. Mot de passe et obligations de l’utilisateur
3.1 Une fois la demande d’inscription confirmée, chaque utilisateur se verra attribuer un nom d’utilisateur personnel correspondant à l’adresse e-mail enregistrée, ainsi qu’un mot de passe personnel. L’accès aux services du Portail n’est possible qu’en renseignant ces deux identifiants.

3.2 En cas de perte ou de suspicion fondée de détournement de ces identifiants de connexion par un tiers non autorisé, l’utilisateur devra obligatoirement modifier son mot de passe et en informer l’Exploitant par écrit.

3.3 L’utilisateur est tenu de signaler par écrit à l’Exploitant le départ des employés ou tiers (par exemple consultants) de l’entreprise concernée ayant connaissance des identifiants mentionnés dans la clause 3.1, sans délai indu, au plus tard toutefois à la résiliation du contrat de travail ou de la collaboration, afin d’exclure tout détournement des identifiants de l’utilisateur. Tout mauvais usage réel ou suspecté du Portail doit être immédiatement signalé à l’Exploitant. Dans ce cas, l’Exploitant génèrera immédiatement de nouveaux identifiants, ou a minima un nouveau mot de passe, et en avertira l’utilisateur par écrit.

3.4 Jusqu’à réception de la notification de résiliation ou de détournement, l’utilisateur sera tenu pour responsable de toute déclaration, publication ou comportement inapproprié ou injurieux sur le Portail imputable à un employé ou tiers faisant l’objet d’une procédure de résiliation.

3.5 L’utilisateur s’abstient de publier des contenus ou contributions a) violant ou mettant en péril les droits de l’Exploitant ou de tiers (en particulier droits de nom, droits personnels, droits de marque, droits d’auteur, droits apparentés, etc.), b) illégaux ou c) contenant des virus, des systèmes de contournement ou des messages publicitaires non sollicités (spams). L’utilisateur est tenu de respecter les droits de propriété intellectuelle ou autres droits de propriété de tiers et s’abstient de conclure des accords illégaux, de se comporter ou de se compromettre dans des pratiques concertées susceptibles d’entraîner une violation du droit de la concurrence.

3.6 L’Exploitant est en droit de supprimer toute publication répréhensible et de bloquer l’accès au Portail de l’utilisateur, en particulier si ces publications présentent un caractère immoral, illégal ou préjudiciable à l’entreprise.

3.7 En vertu des lois en vigueur, l’Exploitant se réserve le droit de signaler une infraction aux autorités compétentes du pays dans lequel l’utilisateur exerce ses activités professionnelles. Par ailleurs, en cas de violation des Conditions d’utilisation, l’Exploitant se réserve également le droit de réclamer des dommages et intérêts et d’épuiser les autres voies de recours envisageables.

### 4. Demande/Passation de commande de produits
4.1 Sur la base de la liste de produits et des tarifs figurant sur le Portail, l’utilisateur peut utiliser le Portail pour envoyer une demande en vue d’effectuer ou de renouveler une commande de produits auprès du vendeur (ces activités étant ci-après dénommées collectivement « commande »). Pour valider le processus de commande, l’utilisateur doit sélectionner le produit, la quantité commandée, l’adresse et la date de livraison. Par la suite, l’utilisateur devra saisir un numéro de référence pour sa commande. En cliquant sur « Valider » dans le panier (page de vérification), la commande est considérée au sens juridique comme validée et ferme.

4.2 À la réception de la commande, le vendeur déterminera la faisabilité de la demande de commande, enverra un accusé de réception à l’utilisateur et, selon le cas, lui fournira (i) une confirmation de commande ou (ii) une contre-offre spécifiant les possibles modifications des quantités et/ou des dates de livraison. Dans le cadre d’une confirmation de commande (i), toutes les commandes ou tous les renouvellements de commande devront être acceptés/confirmés par écrit (un e-mail suffit) par le vendeur, ou l’exécution de la commande vaudra acceptation/confirmation implicite ; dans le cadre d’une contre-offre (ii), toutes les contre-offres devront être acceptées/confirmées par écrit (un e-mail suffit) par l’utilisateur, ou le paiement vaudra acceptation/confirmation implicite, de manière à constituer un contrat de livraison ayant force exécutoire.

### 5. Suppression d’un compte utilisateur
Tout utilisateur a la possibilité de demander la suppression de son compte à n’importe quel moment ; il devra en faire la demande par écrit auprès de l’Exploitant. À la suppression du compte utilisateur (notification du caractère effectif de la suppression), le droit de participation aux services offerts sur le Portail s’éteindra immédiatement. L’Exploitant est en droit de supprimer une inscription existante sans avoir à se justifier si, d’après ce que l’Exploitant a raisonnablement pu constater, l’utilisateur a fourni des informations erronées lors de son inscription ou violé les Conditions d’utilisation ou la législation en vigueur. En cas de suppression de son inscription, l’utilisateur concerné sera prévenu par écrit par l’Exploitant. En cas de suppression d’un compte utilisateur, l’Exploitant supprime immédiatement toutes les données personnelles de l’utilisateur concerné. L’Exploitant ne saurait être tenu pour responsable de la suppression de profils utilisateurs, quels qu’ils soient.

### 6. Responsabilité
6.1 La responsabilité de l’Exploitant concernant l’exploitation du Portail est limitée aux seuls cas de négligence grave ou actes volontaires et intentionnels de sa part ou de la part de ses représentants légaux ou auxiliaires d’exécution. La disposition ci-dessus ne s’applique pas en cas de responsabilité découlant d’une atteinte à la vie, à l’intégrité corporelle ou à la santé, ou en cas de violation coupable d’une obligation contractuelle essentielle compromettant la réalisation de l’objet du contrat (obligation cardinale), ou en cas de responsabilité du fait du produit ou de responsabilité imposée par la loi. À l’exception des cas de négligence grave ou intentionnelle et de dommage découlant d’une mise en danger de la vie, de l’intégrité corporelle ou de la santé d’autrui, la responsabilité sera limitée aux dommages types prévisibles à la date de l’inscription de l’utilisateur, sans jamais dépasser 100 000 EUR par incidence et par an, en excluant systématiquement la responsabilité pour les dommages indirects, collatéraux et consécutifs, ainsi que pour les pertes financières et de bénéfices.

6.2 L’utilisateur sera tenu pour responsable de toutes les activités effectuées par le biais de son compte utilisateur et/ou impliquant des données le concernant et sortant du cadre du Portail. L’utilisateur devra entreprendre toutes les mesures commercialement raisonnables pour a) empêcher tout accès illégitime à son compte et/ou aux données le concernant, b) garantir la conformité de ses propres actes et de ceux de ses représentants légaux, auxiliaires d’exécution et autres tiers avec toutes les obligations contractuelles envers l’Exploitant, ainsi qu’avec les autres obligations légales en vigueur, et c) garantir l’innocuité des données soumises via le Portail en matière de cyber risque, notamment mais sans toutefois s’y limiter les logiciels malveillants, les attaques informatiques, etc. La responsabilité de l’utilisateur sera exclue si le mauvais usage ou l’infestation du Portail est survenu dans des circonstances échappant au contrôle raisonnable de l’utilisateur.

### 7. Décharge de responsabilité
L’utilisateur devra indemniser l’Exploitant et le tenir indemne de toutes revendications introduites par d’autres utilisateurs ou tiers à l’encontre de l’Exploitant et résultant de la violation de leurs droits, sous réserve que ces violations soient le fait d’actes ou d’omissions coupables tels que décrits dans la clause 6.2. L’utilisateur devra également prendre en charge les frais raisonnables engagés par l’Exploitant pour assurer sa défense, y compris tous les frais de justice et d’avocat, à hauteur des montants fixés par la loi. L’utilisateur est tenu de transmettre immédiatement, honnêtement et intégralement à l’Exploitant toutes les informations nécessaires à l’évaluation d’une revendication et d’une défense dans le cas ou des tiers porteraient plainte.

### 8. Garantie
8.1 L’Exploitant décline toute responsabilité et ne garantit en aucune façon a) que les informations commerciales figurant sur le Portail sont exhaustives et exactes (ces informations étant fournies « telles quelles » par l’utilisateur), et b) que le Portail ne présente aucun défaut technique, en particulier i) la disponibilité permanente et ininterrompue du Portail et de ses contenus, ou ii) l’affichage/la reproduction exhaustive et exacte des contenus publiés par l’utilisateur. Les services du Portail sont mis à la disposition de l’utilisateur à titre gratuit, sans aucune obligation légale pour l’Exploitant d’en assurer le fonctionnement. L’Exploitant se réserve le droit, à n’importe quel moment, a) de mettre fin au fonctionnement du Portail, complètement ou partiellement, temporairement ou définitivement, à tout moment et sans préavis, ou b) de rendre l’utilisation du Portail payante pour les utilisateurs.

8.2 L’Exploitant s’abstient d’adapter les contenus publiés sur le Portail par des tiers en les présentant comme les siens.

8.3 L’Exploitant ne saurait être tenu pour responsable des dommages subis par un utilisateur du fait de sa confiance dans le caractère exact, exhaustif et inoffensif des contenus tiers publiés sur le Portail.

### 9. Utilisation et protection des données
Pour obtenir des informations sur la catégorie, la portée, l’emplacement et la finalité de la collecte, du traitement et de l’utilisation des données personnelles nécessaires à l’inscription et la conclusion de transactions commerciales, veuillez-vous reporter à notre [Politique de confidentialité]({dataPrivacyLink}).

### 10. Dispositions finales
10.1 Toutes les questions en lien avec l’utilisation et l’interprétation des Conditions d’utilisation du Portail, ainsi que tous les litiges susceptibles d’en découler sont régies par la législation des Pays-Bas, abstraction faite des règles de conflit de lois et à l’exclusion de la Convention des Nations unies sur les contrats de vente internationale de marchandises (CIVM).

10.2 Le tribunal compétent pour l’ensemble des litiges en lien avec les présentes Conditions est celui d’Amsterdam, aux Pays-Bas. Cette disposition s’applique également si l’utilisateur ne relève d’aucun tribunal compétent général aux Pays-Bas, ou si son domicile ou son lieu de résidence habituel est inconnu à la date à laquelle la justice est saisie.

(Statut : 29/08/2019)`,
}

export default messages
