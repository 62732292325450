import React, { useEffect, useMemo } from 'react'

import { Form, FormSpy } from 'react-final-form'

import graphqlUtils from '@bc/graphql-utils'
import { GQLMaterial } from '@bc/types'
import { Button, FormInputComponents } from '@bc/ui'
import { FormatMessage, useFormatNumber } from '@frontend/components/hooks'
import { toLocalizedInputNumber } from '@frontend/utils'

import * as SharedFormComponents from '../_shared'
import { ModalCartFormValues } from './modal-cart-form.types'

export interface ModalCartFormProps {
    onUpdateFormValues?: (values: ModalCartFormValues) => void
    onSubmit: (values: ModalCartFormValues) => void
    onGTMAddToCart?: (values: ModalCartFormValues) => void
    onGTMRemoveFromCart?: () => void
    onClose: () => void
    material: GQLMaterial
    quantity: number
    uom?: string
    uomConversionFactor?: number
    edit?: boolean
    loading: boolean
    isOpen: boolean
    t: FormatMessage
}

let resetForm: any

export const ModalCartForm = ({
    material,
    quantity,
    uom = '',
    uomConversionFactor,
    edit,
    isOpen,
    onUpdateFormValues,
    onSubmit,
    onGTMAddToCart,
    onGTMRemoveFromCart,
    t,
    onClose,
}: ModalCartFormProps) => {
    const formatNumber = useFormatNumber()
    useEffect(() => {
        if (resetForm) {
            resetForm()
        }
    }, [isOpen])

    const pieces = graphqlUtils.material.getPiecesRounded(
        material,
        quantity,
        uom,
        uomConversionFactor,
        material.palletSize,
    )

    const quantityCalculator = useMemo(
        () => SharedFormComponents.createQuantityCalculator(material.packagingQuantity.amount, uomConversionFactor),
        [material.packagingQuantity.amount, uomConversionFactor],
    )

    const initialValues: ModalCartFormValues = {
        pieces: toLocalizedInputNumber(formatNumber, pieces),
        quantity: toLocalizedInputNumber(formatNumber, quantity),
    }

    const handleFormSubmit = (values: ModalCartFormValues) => {
        if (typeof onGTMAddToCart === 'function') {
            onGTMAddToCart(values)
        }
        onSubmit(values)
    }

    const handleClose = () => {
        if (typeof onGTMRemoveFromCart === 'function') {
            onGTMRemoveFromCart()
        }
        onClose()
    }

    return (
        <Form onSubmit={handleFormSubmit} decorators={[quantityCalculator]} initialValues={initialValues}>
            {({ handleSubmit, form, invalid, initialValues: formStateInitialValues, values }) => {
                resetForm = form.reset

                return (
                    <SharedFormComponents.FormWrapper onSubmit={handleSubmit}>
                        <FormInputComponents.InputWrapper>
                            <SharedFormComponents.OrderQuantityFormFields t={t} material={material} uom={uom} />
                            <SharedFormComponents.RequiredFootNote t={t} />
                        </FormInputComponents.InputWrapper>
                        <SharedFormComponents.ButtonsWrapper>
                            <Button
                                onClick={handleClose}
                                icon="Close"
                                variant="outline"
                                title={t('general:cancel')}
                                data-test-id="button-cancel">
                                {t('general:cancel')}
                            </Button>
                            <Button
                                type="submit"
                                icon={edit ? 'Circle_Checkmark' : 'Circle_Add'}
                                disabled={invalid || (edit && formStateInitialValues.pieces === values.pieces)}
                                variant="action"
                                title={
                                    edit
                                        ? t('request-information-modal:edit-quantity-cart')
                                        : t('request-information-modal:add-to-cart')
                                }
                                data-test-id="button-add-to-cart">
                                {edit
                                    ? t('request-information-modal:edit-quantity-cart')
                                    : t('request-information-modal:add-to-cart')}
                            </Button>
                        </SharedFormComponents.ButtonsWrapper>

                        <FormSpy
                            subscription={{ values: true, dirty: true }}
                            onChange={state => {
                                if (state.dirty) {
                                    if (typeof onUpdateFormValues === 'function') {
                                        onUpdateFormValues(state.values as ModalCartFormValues)
                                    }
                                }
                            }}
                        />
                    </SharedFormComponents.FormWrapper>
                )
            }}
        </Form>
    )
}
