import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Puzzle: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Puzzle" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M21 21H13V19.5C13 18.67 12.33 18 11.5 18C10.67 18 10 18.67 10 19.5V21H4V14.96C2.31 14.72 1 13.25 1 11.5C1 9.75 2.31 8.28 4 8.04V4H9.04C9.28 2.31 10.74 1 12.5 1C14.26 1 15.72 2.31 15.96 4H21V6H14V4.5C14 3.67 13.33 3 12.5 3C11.67 3 11 3.67 11 4.5V6H6V10H4.5C3.67 10 3 10.67 3 11.5C3 12.33 3.67 13 4.5 13H6V19H8.04C8.28 17.31 9.74 16 11.5 16C13.26 16 14.72 17.31 14.96 19H19V16.96C17.31 16.72 16 15.25 16 13.5C16 11.75 17.31 10.28 19 10.04V8H21V12H19.5C18.67 12 18 12.67 18 13.5C18 14.33 18.67 15 19.5 15H21V21Z"
                fill={fill === 'gradient' ? 'url(#Puzzle_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Puzzle" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Puzzle" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M21 21H13V19.5C13 18.67 12.33 18 11.5 18C10.67 18 10 18.67 10 19.5V21H4V14.96C2.31 14.72 1 13.25 1 11.5C1 9.75 2.31 8.28 4 8.04V4H9.04C9.28 2.31 10.74 1 12.5 1C14.26 1 15.72 2.31 15.96 4H21V6H14V4.5C14 3.67 13.33 3 12.5 3C11.67 3 11 3.67 11 4.5V6H6V10H4.5C3.67 10 3 10.67 3 11.5C3 12.33 3.67 13 4.5 13H6V19H8.04C8.28 17.31 9.74 16 11.5 16C13.26 16 14.72 17.31 14.96 19H19V16.96C17.31 16.72 16 15.25 16 13.5C16 11.75 17.31 10.28 19 10.04V8H21V12H19.5C18.67 12 18 12.67 18 13.5C18 14.33 18.67 15 19.5 15H21V21Z"
                fill={fill === 'gradient' ? 'url(#Puzzle_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Puzzle" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Puzzle" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M21 21H13V19.5C13 18.67 12.33 18 11.5 18C10.67 18 10 18.67 10 19.5V21H4V14.96C2.31 14.72 1 13.25 1 11.5C1 9.75 2.31 8.28 4 8.04V4H9.04C9.28 2.31 10.74 1 12.5 1C14.26 1 15.72 2.31 15.96 4H21V6H14V4.5C14 3.67 13.33 3 12.5 3C11.67 3 11 3.67 11 4.5V6H6V10H4.5C3.67 10 3 10.67 3 11.5C3 12.33 3.67 13 4.5 13H6V19H8.04C8.28 17.31 9.74 16 11.5 16C13.26 16 14.72 17.31 14.96 19H19V16.96C17.31 16.72 16 15.25 16 13.5C16 11.75 17.31 10.28 19 10.04V8H21V12H19.5C18.67 12 18 12.67 18 13.5C18 14.33 18.67 15 19.5 15H21V21Z"
                fill={fill === 'gradient' ? 'url(#Puzzle_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Puzzle" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Puzzle" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M21 21H13V19.5C13 18.67 12.33 18 11.5 18C10.67 18 10 18.67 10 19.5V21H4V14.96C2.31 14.72 1 13.25 1 11.5C1 9.75 2.31 8.28 4 8.04V4H9.04C9.28 2.31 10.74 1 12.5 1C14.26 1 15.72 2.31 15.96 4H21V6H14V4.5C14 3.67 13.33 3 12.5 3C11.67 3 11 3.67 11 4.5V6H6V10H4.5C3.67 10 3 10.67 3 11.5C3 12.33 3.67 13 4.5 13H6V19H8.04C8.28 17.31 9.74 16 11.5 16C13.26 16 14.72 17.31 14.96 19H19V16.96C17.31 16.72 16 15.25 16 13.5C16 11.75 17.31 10.28 19 10.04V8H21V12H19.5C18.67 12 18 12.67 18 13.5C18 14.33 18.67 15 19.5 15H21V21Z"
                fill={fill === 'gradient' ? 'url(#Puzzle_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Puzzle" />}
        </g>
    ),
}
