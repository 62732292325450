import moment from 'moment'

import { FormatMessage } from '@frontend/components/hooks'

import {
    hasLowercase,
    hasMaximumLength,
    hasMinimumLength,
    hasNumber,
    hasSymbol,
    hasUppercase,
    hasValidSearchInput,
    isAtLeast,
    isAtMost,
    isDateAfter,
    isDateBefore,
    isDateWorkday,
    isTruthy,
    isValidCSREmail,
    isValidDate,
    isValidEmail,
    isWordCharacter,
} from './validations'

export const isEqualTo = (t: FormatMessage, comparedValue: string) => (value: string): string | undefined =>
    value === comparedValue ? undefined : t('form-validation:confirm')

export const number = (t: FormatMessage, parseNumber: (value: string) => number) => (
    value: number | string,
): string | undefined =>
    typeof value === 'string' && isNaN(parseNumber(value)) ? t('form-validation:number') : undefined

export const required = (t: FormatMessage) => (value: string): string | undefined =>
    isTruthy({ value }) ? undefined : t('form-validation:required')

export const email = (t: FormatMessage) => (value: string): string | undefined =>
    isValidEmail({ value }) ? undefined : t('form-validation:email')

export const emailCSR = (t: FormatMessage) => (value: string): string | undefined =>
    isValidEmail({ value })
        ? isValidCSREmail({ value })
            ? undefined
            : t('form-validation:email-csr')
        : t('form-validation:email')

export const password = (t: FormatMessage) => (value: string): string | undefined =>
    isTruthy({ value }) ? undefined : t('form-validation:password')

export const passwordRepeat = (t: FormatMessage) => (value: string): string | undefined =>
    isTruthy({ value }) ? undefined : t('form-validation:password-repeat')

export const date = (t: FormatMessage, format: string) => (value: string = ''): string | undefined =>
    isValidDate({
        value,
        format,
    })
        ? undefined
        : t('form-validation:date')

export const dateIsAfter = (t: FormatMessage, beforeDate: Date, format: string) => (
    value: string = '',
): string | undefined =>
    isDateAfter({
        value,
        format,
        date: beforeDate,
    })
        ? undefined
        : t('form-validation:date.future', undefined, {
              date: moment(beforeDate).subtract(1, 'day').format('DD.MM.YYYY'),
          })

export const dateIsBefore = (t: FormatMessage, afterDate: Date, format: string) => (
    value: string = '',
): string | undefined =>
    isDateBefore({
        value,
        format,
        date: afterDate,
    })
        ? undefined
        : t('form-validation:date.past', undefined, {
              date: moment(afterDate).add(1, 'day').format('DD.MM.YYYY'),
          })

export const dateIsWorkday = (t: FormatMessage, format: string) => (value: string = ''): string | undefined =>
    isDateWorkday({
        value,
        format,
    })
        ? undefined
        : t('form-validation:date.weekday')

export const hasUppercaseCharacter = (t: FormatMessage) => (value: string = '') =>
    hasUppercase({ value }) ? undefined : t('form-validation:has-uppercase')

export const hasLowercaseCharacter = (t: FormatMessage) => (value: string = '') =>
    hasLowercase({ value }) ? undefined : t('form-validation:has-lowercase')
export const hasNumericCharacter = (t: FormatMessage) => (value: string = '') =>
    hasNumber({ value }) ? undefined : t('form-validation:has-numeric-character')

export const hasSymbolCharacter = (t: FormatMessage) => (value: string = '') =>
    hasSymbol({ value }) ? undefined : t('form-validation:has-symbol-character')

export const hasWordCharacters = (t: FormatMessage) => (value: string = '') =>
    !isWordCharacter({ value }) ? undefined : t('form-validation:has-word-character')

export const validSearchInput = (t: FormatMessage) => (value: string = '') =>
    hasValidSearchInput({ value }) ? undefined : t('form-validation:has-word-character')

export const minLength = (t: FormatMessage, length: number) => (value?: string) => {
    if (!value) {
        return undefined
    }

    return hasMinimumLength({
        value,
        length,
    })
        ? undefined
        : t('form-validation:min-length', undefined, { length })
}

export const minLengthValues = (t: FormatMessage, length: number) => (values?: string[]) => {
    if (!values) {
        return undefined
    }

    const items = values.filter(
        (value: string) =>
            !hasMinimumLength({
                value,
                length,
            }),
    )

    return !items.length
        ? undefined
        : t('form-validation:min-length-values', undefined, { values: items.join(', '), length })
}

export const maxLength = (t: FormatMessage, length: number) => (value?: string): string | undefined => {
    if (!value) {
        return undefined
    }

    return hasMaximumLength({
        value,
        length,
    })
        ? undefined
        : t('form-validation:max-length', undefined, { length })
}

export const minAmount = (t: FormatMessage, amount: number = 0, type: string) => (value: string): string | undefined =>
    isAtLeast({
        value,
        amount,
    })
        ? undefined
        : t('form-validation:min-amount', undefined, { amount, type })

export const maxAmount = (t: FormatMessage, amount: number = 0, type: string) => (value: string): string | undefined =>
    isAtMost({
        value,
        amount,
    })
        ? undefined
        : t('form-validation:mmax-amount', undefined, { amount, type })

export const isPositive = (t: FormatMessage, parseNumber: (value: string) => number) => (
    value: number | string,
): string | undefined => {
    const newValue = typeof value === 'string' ? parseNumber(value) : value
    return isNaN(newValue) || newValue > 0 ? undefined : t('form-validation:number')
}

export const notTooBig = (t: FormatMessage, parseNumber: (value: string) => number) => (
    value: number | string,
): string | undefined => {
    const newValue = typeof value === 'string' ? parseNumber(value) : value
    // allow 1 digit less in the input to avoid conversion error string -> number
    return isNaN(newValue) || newValue < Number.MAX_SAFE_INTEGER / 10 ? undefined : t('form-validation:number-too-big')
}

export const composeValidations = (...validators: any[]) => (value: string) =>
    validators.reduce(
        (error, validator) => error || (typeof validator === 'function' ? validator(value) : undefined),
        undefined,
    )
