import { GQLCustomer } from '@bc/types'

export interface SearchCustomerResponse {
    searchCustomer: GQLCustomer[]
}
export interface CustomersResponse {
    customers: GQLCustomer[]
}

export { default as SearchCustomerQuery } from './search-customer.graphql'
export { default as CustomersQuery } from './customers.graphql'
export { default as NotificationsQuery } from './notifications.graphql'
