import { Language } from '@bc/translations'

export type NotificationMessageKey = ReturnType<typeof notificationGetMessageKey>
export const notificationGetMessageKey = (userLocale: Language) => {
    switch (userLocale) {
        case 'en':
            return 'messageEn'
        case 'fr':
            return 'messageFr'
        case 'de':
            return 'messageDe'
        case 'nl':
            return 'messageNl'
        case 'pl':
            return 'messagePl'
        default:
            return 'messageEn'
    }
}
