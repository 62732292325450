import styled from 'styled-components'

import { colors, media, spacing, ButtonTo } from '@bc/ui'

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 30;
    background-color: ${colors.white};
    padding-left: ${spacing.xxs}px;
    height: 40px;
    color: ${colors.neutral90};
    align-items: center;
    padding: 0 ${spacing.xxs}px;
    border-bottom: 1px solid ${colors.neutral20};

    ${media.min('md')} {
        padding: 0 ${spacing.xl}px;
    }
`

export const BackLink = styled(ButtonTo).attrs({ variant: 'text', icon: 'Arrow_Left' })`
    ${media.max('sm')} {
        font-size: 0;
    }
`

export const CompanyName = styled.div`
    padding: 0 ${spacing.xxs}px;
    color: ${colors.deepPurple};
    line-height: normal;

    overflow: hidden;
    font-weight: bold;

    p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: right;
    }
`

export const CompanySalesOrg = styled.div`
    padding: 0 ${spacing.xxs}px;
    color: ${colors.deepPurple};
    line-height: normal;

    border-left: 1px solid ${colors.deepPurple};

    ${media.max('sm')} {
        display: none;
    }
`

export const CompanyInfo = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`
