import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const User: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="User" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M14.5 6.5C14.5 6.18 14.43 5.87 14.32 5.59L15.8 4.11C16.24 4.81 16.5 5.62 16.5 6.5C16.5 8.98 14.48 11 12 11C9.52 11 7.5 8.98 7.5 6.5C7.5 4.02 9.52 2 12 2C12.88 2 13.7 2.26 14.39 2.7L12.91 4.18C12.63 4.07 12.32 4 12 4C10.62 4 9.5 5.12 9.5 6.5C9.5 7.88 10.62 9 12 9C13.38 9 14.5 7.88 14.5 6.5ZM3 11V21H21V11H16V13H19V19H5V13H8V11H3Z"
                fill={fill === 'gradient' ? 'url(#User_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="User" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M14.5 6.5C14.5 6.18 14.43 5.87 14.32 5.59L15.8 4.11C16.24 4.81 16.5 5.62 16.5 6.5C16.5 8.98 14.48 11 12 11C9.52 11 7.5 8.98 7.5 6.5C7.5 4.02 9.52 2 12 2C12.88 2 13.7 2.26 14.39 2.7L12.91 4.18C12.63 4.07 12.32 4 12 4C10.62 4 9.5 5.12 9.5 6.5C9.5 7.88 10.62 9 12 9C13.38 9 14.5 7.88 14.5 6.5ZM3 11V21H21V11H16V13H19V19H5V13H8V11H3Z"
                fill={fill === 'gradient' ? 'url(#User_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="User" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M14.5 6.5C14.5 6.18 14.43 5.87 14.32 5.59L15.8 4.11C16.24 4.81 16.5 5.62 16.5 6.5C16.5 8.98 14.48 11 12 11C9.52 11 7.5 8.98 7.5 6.5C7.5 4.02 9.52 2 12 2C12.88 2 13.7 2.26 14.39 2.7L12.91 4.18C12.63 4.07 12.32 4 12 4C10.62 4 9.5 5.12 9.5 6.5C9.5 7.88 10.62 9 12 9C13.38 9 14.5 7.88 14.5 6.5ZM3 11V21H21V11H16V13H19V19H5V13H8V11H3Z"
                fill={fill === 'gradient' ? 'url(#User_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="User" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M14.5 6.5C14.5 6.18 14.43 5.87 14.32 5.59L15.8 4.11C16.24 4.81 16.5 5.62 16.5 6.5C16.5 8.98 14.48 11 12 11C9.52 11 7.5 8.98 7.5 6.5C7.5 4.02 9.52 2 12 2C12.88 2 13.7 2.26 14.39 2.7L12.91 4.18C12.63 4.07 12.32 4 12 4C10.62 4 9.5 5.12 9.5 6.5C9.5 7.88 10.62 9 12 9C13.38 9 14.5 7.88 14.5 6.5ZM3 11V21H21V11H16V13H19V19H5V13H8V11H3Z"
                fill={fill === 'gradient' ? 'url(#User_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User" />}
        </g>
    ),
}
