import React, { useEffect, useMemo, useState } from 'react'

import { debug } from '@bc/logging'
import { FormState } from 'final-form'
import { Field, Form, FormSpy } from 'react-final-form'

import { DateFormat } from '@bc/config'
import graphqlUtils from '@bc/graphql-utils'
import { GQLLocation, QuotationGroup } from '@bc/types'
import { useFormatMessage, useFormatNumber, useParseFloatNumber } from '@frontend/components/hooks'
import { toLocalizedInputNumber } from '@frontend/utils'

import { Button } from '@bc/ui'
import * as SharedFormComponents from '../_shared'
import { QuoteOrderFormFieldsStep1 } from './quote-order-form-fields-step1'
import { QuoteOrderFormFieldsStep2 } from './quote-order-form-fields-step2'
import { QuoteOrderFormSummary } from './quote-order-form-summary'
import { QuoteOrderFormValues } from './quote-order-form.types'

export interface QuoteOrderFormProps {
    onSubmit: (values: QuoteOrderFormValues) => void
    onUpdateForm: (values: QuoteOrderFormValues) => void
    onGTMAddToCart: (values: QuoteOrderFormValues) => void
    onGTMRemoveFromCart: () => void
    onStep: (step: number) => void
    quote: QuotationGroup
    confirmPrice: boolean
    loading: boolean
    open: boolean
    firstEligibleDeliveryDate: Date
    lastEligibleDeliveryDate: Date
    dateFormat: DateFormat
    customerShipTos: GQLLocation[]
    pickupAvailable: boolean
}

let resetForm: any
const shipToDecorator = SharedFormComponents.createPickupDecorator('shipToId')

export const QuoteOrderForm = ({
    quote,
    confirmPrice,
    open,
    onSubmit,
    onStep,
    onUpdateForm,
    onGTMAddToCart,
    onGTMRemoveFromCart,
    loading,
    firstEligibleDeliveryDate,
    lastEligibleDeliveryDate,
    dateFormat,
    customerShipTos,
    pickupAvailable,
}: QuoteOrderFormProps) => {
    const [step, setStep] = useState<number>(1)
    const t = useFormatMessage()
    const formatNumber = useFormatNumber()
    const parseFloatNumber = useParseFloatNumber()

    const { prices, material, shipToId, uom, uomConversionFactor } = quote

    useEffect(() => {
        if (resetForm && !open) {
            resetForm()
        }
    }, [open])

    const pieces = graphqlUtils.material.getPiecesRounded(material, 1, uom, uomConversionFactor, material.palletSize)
    const quantityCalculator = useMemo(
        () => SharedFormComponents.createQuantityCalculator(material.packagingQuantity.amount, uomConversionFactor),
        [material.packagingQuantity.amount, uomConversionFactor],
    )
    const piecesCalculator = useMemo(
        () => SharedFormComponents.createPiecesCalculator(material.packagingQuantity.amount, uomConversionFactor),
        [material.packagingQuantity.amount, uomConversionFactor],
    )

    const initialValues: QuoteOrderFormValues = {
        customerPoReference: '',
        pieces: toLocalizedInputNumber(formatNumber, pieces),
        quantity: '1',
        shipToId: shipToId || undefined,
        requestedDeliveryDate: undefined,
        insufficientQuantity: false,
        pickup: pickupAvailable,
    }
    const goStep = (formStep: number) => (
        _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        values?: QuoteOrderFormValues,
    ) => {
        switch (formStep) {
            case 1: {
                onGTMRemoveFromCart()
                break
            }
            case 3: {
                onGTMAddToCart(values!)
                break
            }
        }
        setStep(formStep)
        onStep(formStep)
    }

    const mutators = {
        setField: ([field, value]: [string, string | undefined], state: any, tools: any) => {
            debug.frontend(`Manually setting field ${field} to ${value}`)
            tools.changeValue(state, field, () => value)
        },
    }

    const quantityPiecesDecorator = material.isBulk ? piecesCalculator : quantityCalculator

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            decorators={[quantityPiecesDecorator, shipToDecorator]}
            mutators={mutators}>
            {({ handleSubmit, form, values, submitting, pristine, invalid }) => {
                debug.frontend(`New form values ${JSON.stringify(values)}`)
                resetForm = form.reset.bind(form)
                const formPrices = confirmPrice ? [] : prices
                const isQuantityBelowMinimum = SharedFormComponents.isBelowMinimumPrice(
                    parseFloatNumber(values.quantity),
                )
                const insufficientQuantity = formPrices.length > 0 && isQuantityBelowMinimum(formPrices)
                const summaryPrices = insufficientQuantity ? [] : formPrices

                return (
                    <>
                        <SharedFormComponents.FormWrapper onSubmit={handleSubmit}>
                            {step === 1 && (
                                <>
                                    <QuoteOrderFormFieldsStep1
                                        t={t}
                                        locationId={shipToId}
                                        material={material}
                                        shipTos={customerShipTos}
                                        firstEligibleDeliveryDate={firstEligibleDeliveryDate}
                                        lastEligibleDeliveryDate={lastEligibleDeliveryDate}
                                        dateFormat={dateFormat}
                                        uom={uom}
                                        pickupAvailable={pickupAvailable}
                                    />
                                    <SharedFormComponents.RequiredFootNote t={t} />
                                    <SharedFormComponents.ButtonsWrapper>
                                        <Button
                                            isLoading={loading}
                                            onClick={goStep(2)}
                                            disabled={pristine || invalid}
                                            variant="solid"
                                            title={t('order:next-steps.title')}
                                            data-test-id="button-reorder-form-continue">
                                            {t('order:next-steps.title')}
                                        </Button>
                                    </SharedFormComponents.ButtonsWrapper>
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <QuoteOrderFormFieldsStep2
                                        t={t}
                                        file={values.file}
                                        change={form.change}
                                        prices={formPrices}
                                        insufficientQuantity={insufficientQuantity}
                                    />
                                    <SharedFormComponents.RequiredFootNote t={t} />

                                    <SharedFormComponents.ButtonsWrapper>
                                        <Button
                                            onClick={goStep(1)}
                                            icon="Chevron_Left"
                                            variant="outline"
                                            title={t('request-information-modal:back-to-step-1')}
                                            data-test-id="button-back-step-1">
                                            {t('request-information-modal:back-to-step-1')}
                                        </Button>
                                        <Button
                                            onClick={e => goStep(3)(e, values)}
                                            variant="solid"
                                            disabled={pristine || invalid}
                                            title={t('request-information-modal:preview-and-submit')}
                                            data-test-id="button-reorder-form-continue2">
                                            {t('request-information-modal:preview-and-submit')}
                                        </Button>
                                    </SharedFormComponents.ButtonsWrapper>
                                </>
                            )}
                            {step === 3 && (
                                <>
                                    <QuoteOrderFormSummary
                                        file={values.file}
                                        values={values}
                                        material={material}
                                        uom={uom}
                                        shipTo={customerShipTos.find(
                                            (location: GQLLocation) => location.id === values.shipToId,
                                        )}
                                        prices={summaryPrices}
                                        t={t}
                                    />
                                    <SharedFormComponents.ButtonsWrapper>
                                        <Button
                                            onClick={goStep(2)}
                                            icon="Chevron_Left"
                                            variant="outline"
                                            title={t('request-information-modal:back-to-step-2')}
                                            data-test-id="button-back-step-2">
                                            {t('request-information-modal:back-to-step-2')}
                                        </Button>
                                        <Button
                                            isLoading={loading}
                                            type="submit"
                                            variant="action"
                                            disabled={submitting || invalid}
                                            title={t('general:send')}
                                            data-test-id="button-submit-order">
                                            {t('general:send')}
                                        </Button>
                                    </SharedFormComponents.ButtonsWrapper>
                                </>
                            )}
                            <Field name="insufficientQuantity" value={insufficientQuantity} render={() => null} />
                        </SharedFormComponents.FormWrapper>
                        <FormSpy
                            subscription={{ values: true, dirty: true }}
                            onChange={(state: FormState<QuoteOrderFormValues>) => {
                                if (state.dirty) {
                                    onUpdateForm(state.values)
                                }
                            }}
                        />
                    </>
                )
            }}
        </Form>
    )
}
