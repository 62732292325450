import { GQLBaseOrderLine, GQLMaterial, GQLPriceAmount, QuotationGroup } from '@bc/types'

import { ProductRemoveFromCartTag } from '../types'
import { dataLayer, getCurrency, getProductBrand } from '../utils'

const anyProductRemoveFromCart = (material: GQLMaterial, price: GQLPriceAmount | undefined) => {
    const currencyCode = getCurrency({ price })
    const brand = getProductBrand(material.manufacturer)

    return dataLayer<ProductRemoveFromCartTag>({
        event: 'removeFromCart',
        ecommerce: {
            currencyCode,
            remove: {
                products: [
                    {
                        name: material.name || '-',
                        id: material.id,
                        brand,
                        quantity: 1,
                    },
                ],
            },
        },
    })
}

export const baseProductRemoveFromCart = (orderLine: GQLBaseOrderLine) => {
    const { material, unitPrice } = orderLine
    const price: GQLPriceAmount | undefined = unitPrice
        ? {
              amount: unitPrice.price || 0,
              currency: unitPrice.currency || '-',
          }
        : undefined

    anyProductRemoveFromCart(material!, price)
}

export const quoteProductRemoveFromCart = (quoteGroup: QuotationGroup) => {
    const price: GQLPriceAmount | undefined = quoteGroup.prices[0]?.price
    anyProductRemoveFromCart(quoteGroup.material!, price)
}
