import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Untrash: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Untrash" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M13 4V2H11V4H3V6H21V4H13ZM19 22H5V8H7V20H17V8H19V22ZM13.7891 9.79004L15.2091 11.21L13.4091 13L15.2091 14.79L13.7891 16.21L11.9991 14.41L10.2091 16.21L8.78906 14.79L10.5891 13L8.78906 11.21L10.2091 9.79004L11.9991 11.59L13.7891 9.79004Z"
                fill={fill === 'gradient' ? 'url(#Untrash_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Untrash" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Untrash" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M13 4V2H11V4H3V6H21V4H13ZM19 22H5V8H7V20H17V8H19V22ZM13.7891 9.79004L15.2091 11.21L13.4091 13L15.2091 14.79L13.7891 16.21L11.9991 14.41L10.2091 16.21L8.78906 14.79L10.5891 13L8.78906 11.21L10.2091 9.79004L11.9991 11.59L13.7891 9.79004Z"
                fill={fill === 'gradient' ? 'url(#Untrash_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Untrash" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Untrash" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M13 4V2H11V4H3V6H21V4H13ZM19 22H5V8H7V20H17V8H19V22ZM13.7891 9.79004L15.2091 11.21L13.4091 13L15.2091 14.79L13.7891 16.21L11.9991 14.41L10.2091 16.21L8.78906 14.79L10.5891 13L8.78906 11.21L10.2091 9.79004L11.9991 11.59L13.7891 9.79004Z"
                fill={fill === 'gradient' ? 'url(#Untrash_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Untrash" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Untrash" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M13 4V2H11V4H3V6H21V4H13ZM19 22H5V8H7V20H17V8H19V22ZM13.7891 9.79004L15.2091 11.21L13.4091 13L15.2091 14.79L13.7891 16.21L11.9991 14.41L10.2091 16.21L8.78906 14.79L10.5891 13L8.78906 11.21L10.2091 9.79004L11.9991 11.59L13.7891 9.79004Z"
                fill={fill === 'gradient' ? 'url(#Untrash_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Untrash" />}
        </g>
    ),
}
