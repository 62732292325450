import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'

const baseStyles: string = `
    font-size: 1.6rem;
    line-height: 1.4;
    color: ${colors.neutral90};

    &:hover {
        text-decoration: none;
    };

    p > &, span > & {
        font-size: inherit;
    };
`

interface StyledLinkProps {
    color?: keyof typeof colors
    small?: boolean
}

export const StyledLink = styled(NavLink)`
    ${baseStyles}
    color: ${({ color = 'neutral90' }: StyledLinkProps) => colors[color]};
    ${({ small }: StyledLinkProps) =>
        small
            ? `
        font-size: 1.5rem;
         line-height: 1.5;
    `
            : ''}
`

export const StyledA = styled.a`
    ${baseStyles}
    color: ${({ color = 'neutral90' }: StyledLinkProps) => colors[color]};
    ${({ small }: StyledLinkProps) =>
        small
            ? `
        font-size: 1.5rem;
         line-height: 1.5;
    `
            : ''}
`
