import React, { useMemo, useState } from 'react'

import { Field, useField } from 'react-final-form'
import { OptionsType } from 'react-select'

import { ShopFeatures } from '@bc/config'
import { GQLLocation, GQLMaterial } from '@bc/types'
import {
    Checkbox,
    getLocationAddressLine,
    SelectInput,
    SelectInputOption,
    Text,
    TextInput,
    FormInputComponents,
} from '@bc/ui'
import { Datepicker } from '@frontend/components/datepicker'
import { Feature } from '@frontend/components/customer'
import { FormatMessage } from '@frontend/components/hooks'
import { finalFormValidations, getDateFormatLocalized } from '@frontend/utils'

import { deliveryDateValid, isDeliveryDateValid, OrderPiecesFormFields, OrderQuantityFormFields } from '../_shared'
import { QuoteOrderFormValues } from './quote-order-form.types'
interface QuoteOrderFormFieldsProps {
    t: FormatMessage
    locationId: string
    material: GQLMaterial
    uom?: string
    shipTos: GQLLocation[]
    firstEligibleDeliveryDate: Date
    lastEligibleDeliveryDate: Date
    dateFormat: string
    pickupAvailable: boolean
}

export const QuoteOrderFormFieldsStep1 = ({
    t,
    locationId,
    material,
    uom,
    shipTos,
    firstEligibleDeliveryDate,
    lastEligibleDeliveryDate,
    dateFormat,
    pickupAvailable,
}: QuoteOrderFormFieldsProps) => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)
    const {
        input: { value: pickup },
    } = useField('pickup')
    const shipToOptions: OptionsType<SelectInputOption> = useMemo(
        () =>
            shipTos.map((location: GQLLocation) => ({
                value: location.id,
                label: getLocationAddressLine(location),
            })),
        [shipTos],
    )

    const quoteHasSpecificLocation = shipTos.find(shipToLocation => shipToLocation.id === locationId)

    const hideCalendar = () => {
        setIsCalendarOpen(false)
    }

    const showCalendar = () => {
        setIsCalendarOpen(true)
    }

    const validateShipToId = (value: string, values: QuoteOrderFormValues) => {
        if (!values.pickup) {
            return finalFormValidations.required(t)(value)
        }
        return undefined
    }
    const QuantityPiecesFormCalculator = material.isBulk ? OrderPiecesFormFields : OrderQuantityFormFields
    const autoSelectOneShipTo = !pickup && shipToOptions.length === 1 ? shipToOptions[0].value : undefined

    return (
        <FormInputComponents.InputWrapper>
            <QuantityPiecesFormCalculator uom={uom} t={t} material={material} />
            <Field
                name="shipToId"
                initialValue={autoSelectOneShipTo}
                validate={validateShipToId}
                parse={o => o?.value}
                render={({ input }) => {
                    const { value, ...restInput } = input
                    const selectedAddress: any = value && shipToOptions.find(o => o.value === value)
                    const selectedAdddressOtherThanQuote =
                        (quoteHasSpecificLocation && value && value !== locationId) || (pickupAvailable && !pickup)

                    return (
                        <>
                            <SelectInput
                                {...restInput}
                                value={selectedAddress}
                                labelText={t('general:select-ship-to') + (pickup ? '' : ' *')}
                                placeholder={t('filters:select-an-address')}
                                maxMenuHeight={300}
                                isSearchable
                                isClearable
                                isDisabled={pickup}
                                options={shipToOptions}
                                noOptionsMessage={() => t('not-found:select')}
                                classNamePrefix="select-ship-to-address-modal-reorder"
                                data-test-id="select-ship-to-address-modal-reorder"
                            />
                            {selectedAdddressOtherThanQuote && (
                                <Text p>{t('request-information-modal:different-shipto-warning')}</Text>
                            )}
                        </>
                    )
                }}
            />
            <Feature
                flag={ShopFeatures.OrderPickup}
                on={
                    <Field
                        name="pickup"
                        type="checkbox"
                        validateFields={['shipToId']}
                        render={({ input }) => (
                            <Checkbox
                                {...input}
                                id="pickup"
                                labelText={t('request-information-modal:reorder:self-pickup')}
                                data-test-id="checkbox-pickup"
                                colorScheme="deepPurple"
                            />
                        )}
                    />
                }
            />
            <Field
                name="requestedDeliveryDate"
                validate={deliveryDateValid(t, firstEligibleDeliveryDate, lastEligibleDeliveryDate, dateFormat)}>
                {({ input, meta }) => (
                    <Datepicker
                        open={isCalendarOpen}
                        initialMonth={firstEligibleDeliveryDate}
                        onClickOutside={event => {
                            const element = event.target as HTMLElement
                            const elementName = element?.getAttribute && element.getAttribute('name')

                            if (elementName !== input.name) {
                                hideCalendar()
                            }
                        }}
                        onChange={(date, parsedDate) => {
                            if (
                                parsedDate &&
                                isDeliveryDateValid(
                                    t,
                                    firstEligibleDeliveryDate,
                                    lastEligibleDeliveryDate,
                                    dateFormat,
                                    date,
                                )
                            ) {
                                // https://github.com/final-form/react-final-form/pull/438
                                // Recommended by plugin authors is to cast to any
                                input.onChange(date as any)
                                hideCalendar()
                            }
                        }}
                        disabledDays={[
                            { after: lastEligibleDeliveryDate },
                            { before: firstEligibleDeliveryDate },
                            { daysOfWeek: [0, 6] },
                        ]}
                        render={({ datepickerInput }) => (
                            <TextInput
                                {...datepickerInput(input)}
                                onFocus={e => {
                                    showCalendar()
                                    input.onFocus(e)
                                }}
                                labelText={
                                    (pickup
                                        ? t('request-information-modal:requested-pickup-date')
                                        : t('request-information-modal:requested-delivery-date')) + ' *'
                                }
                                placeholder={getDateFormatLocalized(t, dateFormat)}
                                type="text"
                                noMargin
                                autoComplete="off"
                                hasError={input.value && meta.touched && meta.error}
                                errorText={input.value && meta.touched && meta.error}
                                data-test-id="input-requested-delivery-date"
                            />
                        )}
                    />
                )}
            </Field>
        </FormInputComponents.InputWrapper>
    )
}
