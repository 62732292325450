import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Code: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Code" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M9.01953 20.7808L13.017 2.77926L14.9694 3.21282L10.972 21.2143L9.01953 20.7808ZM6.28984 16.71L1.58984 12L6.28984 7.29004L7.70984 8.71004L4.40984 12L7.70984 15.29L6.28984 16.71ZM17.7091 16.71L16.2891 15.29L19.5891 12L16.2891 8.71004L17.7091 7.29004L22.4091 12L17.7091 16.71Z"
                fill={fill === 'gradient' ? 'url(#Code_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Code" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Code" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M9.01953 20.7808L13.017 2.77926L14.9694 3.21282L10.972 21.2143L9.01953 20.7808ZM6.28984 16.71L1.58984 12L6.28984 7.29004L7.70984 8.71004L4.40984 12L7.70984 15.29L6.28984 16.71ZM17.7091 16.71L16.2891 15.29L19.5891 12L16.2891 8.71004L17.7091 7.29004L22.4091 12L17.7091 16.71Z"
                fill={fill === 'gradient' ? 'url(#Code_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Code" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Code" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M9.01953 20.7808L13.017 2.77926L14.9694 3.21282L10.972 21.2143L9.01953 20.7808ZM6.28984 16.71L1.58984 12L6.28984 7.29004L7.70984 8.71004L4.40984 12L7.70984 15.29L6.28984 16.71ZM17.7091 16.71L16.2891 15.29L19.5891 12L16.2891 8.71004L17.7091 7.29004L22.4091 12L17.7091 16.71Z"
                fill={fill === 'gradient' ? 'url(#Code_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Code" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Code" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M9.01953 20.7808L13.017 2.77926L14.9694 3.21282L10.972 21.2143L9.01953 20.7808ZM6.28984 16.71L1.58984 12L6.28984 7.29004L7.70984 8.71004L4.40984 12L7.70984 15.29L6.28984 16.71ZM17.7091 16.71L16.2891 15.29L19.5891 12L16.2891 8.71004L17.7091 7.29004L22.4091 12L17.7091 16.71Z"
                fill={fill === 'gradient' ? 'url(#Code_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Code" />}
        </g>
    ),
}
