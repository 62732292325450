import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Tag: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Tag" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M3 13.41L12.5 22.91L22.91 12.5L13.41 3H7V5H12.59L20.09 12.5L12.5 20.09L5 12.59V3H3V13.41ZM10 9C10 9.55228 9.55228 10 9 10C8.44772 10 8 9.55228 8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9Z"
                fill={fill === 'gradient' ? 'url(#Tag_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Tag" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Tag" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M3 13.41L12.5 22.91L22.91 12.5L13.41 3H7V5H12.59L20.09 12.5L12.5 20.09L5 12.59V3H3V13.41ZM10 9C10 9.55228 9.55228 10 9 10C8.44772 10 8 9.55228 8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9Z"
                fill={fill === 'gradient' ? 'url(#Tag_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Tag" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Tag" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M3 13.41L12.5 22.91L22.91 12.5L13.41 3H7V5H12.59L20.09 12.5L12.5 20.09L5 12.59V3H3V13.41ZM10 9C10 9.55228 9.55228 10 9 10C8.44772 10 8 9.55228 8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9Z"
                fill={fill === 'gradient' ? 'url(#Tag_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Tag" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Tag" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M3 13.41L12.5 22.91L22.91 12.5L13.41 3H7V5H12.59L20.09 12.5L12.5 20.09L5 12.59V3H3V13.41ZM10 9C10 9.55228 9.55228 10 9 10C8.44772 10 8 9.55228 8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9Z"
                fill={fill === 'gradient' ? 'url(#Tag_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Tag" />}
        </g>
    ),
}
