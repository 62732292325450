import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'
import { gridConfig } from './grid-config'

interface ContainerProps {
    backgroundColor?: keyof typeof colors
    relative?: boolean
    noPadding?: boolean
    hasSideBackground?: string
}

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: ${gridConfig.containerGutterSmall}px;
    ${media.min('md')} {
        padding: ${gridConfig.containerGutterY}px ${gridConfig.containerGutterX}px;
    }
    ${({ backgroundColor }) =>
        backgroundColor
            ? `
        background-color: ${colors[backgroundColor || 'white']};
    `
            : ''}
    ${({ hasSideBackground }: ContainerProps) =>
        hasSideBackground
            ? `
                    &:after {
                        content: "";
                        width: 26vw;
                        height: 100%;
                        background: ${colors.neutral10};
                        position: absolute;
                        top: 0;
                        ${hasSideBackground}: 0;
                        z-index: -1;
                        ${media.max('sm')} {
                            display: none;
                        };
                        ${media.min('xl')} {
                            width: 28vw;
                        };
                    };
                `
            : ''};
    ${({ noPadding = false }) =>
        noPadding
            ? `
                    padding: 0 !important;
                `
            : ''};
    ${({ relative = false }) =>
        relative
            ? `
                    position: relative;
                      z-index: 0;
                  `
            : ''};
`
