import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Invoice: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Invoice" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M4 2H20V4H6V20H18V6H20V22H4V2ZM14.9983 10H11.4283H11.4183C11.2983 10 11.1983 10.1 11.1983 10.22V10.42C11.1983 10.5 11.2483 10.58 11.3283 10.62L13.7183 11.75C14.4883 12.12 14.9883 12.91 14.9883 13.76C14.9883 14.91 14.1083 15.85 12.9883 15.96V17.98H10.9883V15.98H8.98828V13.98H12.7683C12.8883 13.98 12.9883 13.88 12.9883 13.76C12.9883 13.68 12.9383 13.6 12.8583 13.56L10.4683 12.43C9.69828 12.06 9.19828 11.27 9.19828 10.42V10.22C9.19828 9.14 9.97828 8.24 10.9983 8.04V6H12.9983V8H14.9983V10Z"
                fill={fill === 'gradient' ? 'url(#Invoice_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Invoice" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Invoice" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M4 2H20V4H6V20H18V6H20V22H4V2ZM14.9983 10H11.4283H11.4183C11.2983 10 11.1983 10.1 11.1983 10.22V10.42C11.1983 10.5 11.2483 10.58 11.3283 10.62L13.7183 11.75C14.4883 12.12 14.9883 12.91 14.9883 13.76C14.9883 14.91 14.1083 15.85 12.9883 15.96V17.98H10.9883V15.98H8.98828V13.98H12.7683C12.8883 13.98 12.9883 13.88 12.9883 13.76C12.9883 13.68 12.9383 13.6 12.8583 13.56L10.4683 12.43C9.69828 12.06 9.19828 11.27 9.19828 10.42V10.22C9.19828 9.14 9.97828 8.24 10.9983 8.04V6H12.9983V8H14.9983V10Z"
                fill={fill === 'gradient' ? 'url(#Invoice_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Invoice" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Invoice" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M4 2H20V4H6V20H18V6H20V22H4V2ZM14.9983 10H11.4283H11.4183C11.2983 10 11.1983 10.1 11.1983 10.22V10.42C11.1983 10.5 11.2483 10.58 11.3283 10.62L13.7183 11.75C14.4883 12.12 14.9883 12.91 14.9883 13.76C14.9883 14.91 14.1083 15.85 12.9883 15.96V17.98H10.9883V15.98H8.98828V13.98H12.7683C12.8883 13.98 12.9883 13.88 12.9883 13.76C12.9883 13.68 12.9383 13.6 12.8583 13.56L10.4683 12.43C9.69828 12.06 9.19828 11.27 9.19828 10.42V10.22C9.19828 9.14 9.97828 8.24 10.9983 8.04V6H12.9983V8H14.9983V10Z"
                fill={fill === 'gradient' ? 'url(#Invoice_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Invoice" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Invoice" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M4 2H20V4H6V20H18V6H20V22H4V2ZM14.9983 10H11.4283H11.4183C11.2983 10 11.1983 10.1 11.1983 10.22V10.42C11.1983 10.5 11.2483 10.58 11.3283 10.62L13.7183 11.75C14.4883 12.12 14.9883 12.91 14.9883 13.76C14.9883 14.91 14.1083 15.85 12.9883 15.96V17.98H10.9883V15.98H8.98828V13.98H12.7683C12.8883 13.98 12.9883 13.88 12.9883 13.76C12.9883 13.68 12.9383 13.6 12.8583 13.56L10.4683 12.43C9.69828 12.06 9.19828 11.27 9.19828 10.42V10.22C9.19828 9.14 9.97828 8.24 10.9983 8.04V6H12.9983V8H14.9983V10Z"
                fill={fill === 'gradient' ? 'url(#Invoice_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Invoice" />}
        </g>
    ),
}
