import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Parcel_Add: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Parcel_Add" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M14.0002 4.7202L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L14.0002 2.6102V4.7202ZM20 17.2799V9.99986H22V18.7199L12 22.0499L2 18.7199V5.60986L12 8.93986L16 7.60986V9.71986L13 10.7199V19.6099L20 17.2799ZM11 10.7199L4 8.38986V17.2799L11 19.6099V10.7199ZM18 4H16V6H18V8H20V6H22V4H20V2H18V4Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Add_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Add" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Parcel_Add" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M14.0002 4.7202L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L14.0002 2.6102V4.7202ZM20 17.2799V9.99986H22V18.7199L12 22.0499L2 18.7199V5.60986L12 8.93986L16 7.60986V9.71986L13 10.7199V19.6099L20 17.2799ZM11 10.7199L4 8.38986V17.2799L11 19.6099V10.7199ZM18 4H16V6H18V8H20V6H22V4H20V2H18V4Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Add_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Add" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Parcel_Add" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M14.0002 4.7202L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L14.0002 2.6102V4.7202ZM20 17.2799V9.99986H22V18.7199L12 22.0499L2 18.7199V5.60986L12 8.93986L16 7.60986V9.71986L13 10.7199V19.6099L20 17.2799ZM11 10.7199L4 8.38986V17.2799L11 19.6099V10.7199ZM18 4H16V6H18V8H20V6H22V4H20V2H18V4Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Add_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Add" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Parcel_Add" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M14.0002 4.7202L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L14.0002 2.6102V4.7202ZM20 17.2799V9.99986H22V18.7199L12 22.0499L2 18.7199V5.60986L12 8.93986L16 7.60986V9.71986L13 10.7199V19.6099L20 17.2799ZM11 10.7199L4 8.38986V17.2799L11 19.6099V10.7199ZM18 4H16V6H18V8H20V6H22V4H20V2H18V4Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Add_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Add" />}
        </g>
    ),
}
