import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Arrow_Left: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Arrow_Left" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M1.59003 12L10.29 20.71L11.71 19.29L4.41003 12L11.71 4.71L10.29 3.29L1.59003 12ZM8.24002 13L7.24002 12L8.24002 11H22V13H8.24002Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Left_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Left" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Arrow_Left" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M1.59003 12L10.29 20.71L11.71 19.29L4.41003 12L11.71 4.71L10.29 3.29L1.59003 12ZM8.24002 13L7.24002 12L8.24002 11H22V13H8.24002Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Left_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Left" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Arrow_Left" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M1.59003 12L10.29 20.71L11.71 19.29L4.41003 12L11.71 4.71L10.29 3.29L1.59003 12ZM8.24002 13L7.24002 12L8.24002 11H22V13H8.24002Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Left_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Left" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Arrow_Left" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M1.59003 12L10.29 20.71L11.71 19.29L4.41003 12L11.71 4.71L10.29 3.29L1.59003 12ZM8.24002 13L7.24002 12L8.24002 11H22V13H8.24002Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Left_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Left" />}
        </g>
    ),
}
