import React, { createContext } from 'react'

import { SelectInputOption } from '@bc/ui/src/components/form-elements/select-input'

export interface SortContextData {
    onSortChange: (sort: SelectInputOption) => void
    sort: string
    sortOptions: SelectInputOption[]
}

interface SortProviderProps extends SortContextData {
    children: React.ReactNode
}

const initialContext: SortContextData = { onSortChange() {}, sort: '', sortOptions: [] }
export const SortContext = createContext(initialContext)

export const SortProvider = ({ onSortChange, sort, sortOptions, children }: SortProviderProps) => (
    <SortContext.Provider value={{ onSortChange, sort, sortOptions }}>{children}</SortContext.Provider>
)
