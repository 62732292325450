import React, { useEffect } from 'react'
import { Form, FormSpy } from 'react-final-form'

import graphqlUtils from '@bc/graphql-utils'
import { tagManager } from '@bc/gtm'
import { GQLMaterial } from '@bc/types'
import { withCurrentUser, WithCurrentUserProps } from '@frontend/components/hocs'

import * as SharedFormComponents from '../_shared'
import { RequestInformationFormOne } from './request-information-form-step-1'
import { RequestInformationFormTwo } from './request-information-form-step-2'
import { RequestInformationFormValues } from './request-information-form.types'

export interface RequestInformationFormBaseProps {
    onSubmit: (values: RequestInformationFormValues) => void
    onUpdateForm: (values: RequestInformationFormValues) => void
    onSetStep: (step: number) => void
    loading: boolean
    step: number
    open: boolean
    material: GQLMaterial
}

type RequestInformationFormProps = RequestInformationFormBaseProps & WithCurrentUserProps

let resetForm: any

const formWrapperStyle = { height: '100%' }

const formInitialValues = {
    recipient: 'me',
    pickup: false,
}
const addressDecorator = SharedFormComponents.createPickupDecorator('selectedAddressId')

const RequestInformationFormClass = ({
    onSubmit,
    onUpdateForm,
    onSetStep,
    step,
    currentCustomer,
    material,
    loading,
    open,
}: RequestInformationFormProps) => {
    const shipTos = graphqlUtils.customer.getShipTos(currentCustomer)

    useEffect(() => {
        if (resetForm && !open) {
            resetForm()
        }
    }, [open])

    return (
        <Form
            initialValues={formInitialValues}
            onSubmit={(values: RequestInformationFormValues) => {
                onSubmit(values)
                tagManager.tags.productRequestInformationStepSubmit(material, values, 'submit')
            }}
            decorators={[addressDecorator]}
            mutators={{
                setAddressType: ([type], state, utils) => {
                    utils.changeValue(state, 'addressType', () => type)
                },
            }}>
            {({ handleSubmit, form, submitting, invalid, values }) => {
                resetForm = form.reset.bind(form)
                return (
                    <SharedFormComponents.FormWrapper onSubmit={handleSubmit} style={formWrapperStyle}>
                        {step === 1 && (
                            <RequestInformationFormOne
                                uom={material.packagingQuantity.uom}
                                invalid={invalid}
                                values={values}
                                onSubmit={() => {
                                    onSetStep(2)
                                    tagManager.tags.productRequestInformationStepSubmit(
                                        material,
                                        values as RequestInformationFormValues,
                                        'step 2',
                                    )
                                }}
                            />
                        )}
                        {step === 2 && (
                            <RequestInformationFormTwo
                                submitting={submitting}
                                loading={loading}
                                invalid={invalid}
                                values={values}
                                mutators={form.mutators}
                                shipTos={shipTos}
                                onBackButtonClick={() => {
                                    onSetStep(1)
                                    tagManager.tags.productRequestInformationBackButton(material)
                                }}
                            />
                        )}
                        <FormSpy
                            subscription={{ values: true, dirty: true }}
                            onChange={state => {
                                if (state.dirty) {
                                    onUpdateForm(state.values)
                                }
                            }}
                        />
                    </SharedFormComponents.FormWrapper>
                )
            }}
        </Form>
    )
}

export default withCurrentUser(RequestInformationFormClass)
