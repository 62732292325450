import Big from 'big.js'
import createDecorator from 'final-form-calculate'

export const createQuantityCalculator = (quantityPerPiece: number = 0, conversionFactor: number = 1.0) =>
    createDecorator({
        field: 'pieces',
        updates: {
            quantity: (pieces: string | number): string | undefined => {
                const piecesValue = typeof pieces === 'string' ? Number(pieces?.replace(/,/, '.') || 0) : pieces
                return piecesValue
                    ? Big(piecesValue).times(quantityPerPiece).div(conversionFactor).toString()
                    : undefined
            },
        },
    })

export const createPiecesCalculator = (quantityPerPiece: number = 0, conversionFactor: number = 1.0) =>
    createDecorator({
        field: 'quantity',
        updates: {
            pieces: (quantity: string | number): string | undefined => {
                const quantityValue = typeof quantity === 'string' ? Number(quantity?.replace(/,/, '.') || 0) : quantity
                return quantityValue
                    ? Big(quantityValue).div(quantityPerPiece).times(conversionFactor).toString()
                    : undefined
            },
        },
    })

export const createPickupDecorator = (field: string) =>
    createDecorator({
        field: 'pickup',
        updates: {
            [field]: (pickup: boolean, values: any): string | undefined => (pickup ? undefined : values[field]),
        },
    })
