import React, { useEffect, useState } from 'react'
import { getCookie } from 'react-use-cookie'

import { FooterBrenntagLogo, spacing, Text, useDocumentTitle, HeaderBrenntagLogo } from '@bc/ui'
import { Cookiebar, FormatMessage } from '@frontend/components'

import { useMobile } from '@frontend/utils'
import { LoginMfaForm, LoginUserForm } from './components'
import { Footer, FormWrapper, LeftSide, LogoCompensation, MainWrapper, RightSide } from './components/login-components'
import LoginHelp from './components/login-help'
import LoginWelcome from './components/login-welcome'
import { LoginFormValues, LoginMfaFormValues } from './login-page-container'
interface Props {
    t: FormatMessage
    cookiesAccepted: boolean
    setCookiesAccepted: () => void
    handleMfaSubmit: (values: LoginMfaFormValues) => void
    handleLoginSubmit: (values: LoginFormValues) => void
    isLoading: boolean
    error: any
    isMfa: boolean
    email: string
}

export const LoginPage = ({
    t,
    cookiesAccepted,
    setCookiesAccepted,
    handleMfaSubmit,
    handleLoginSubmit,
    isLoading,
    error,
    isMfa,
    email,
}: Props): JSX.Element | null => {
    useDocumentTitle('Login')
    const isMobile = useMobile()

    const [locale, setLocale] = useState('')
    const [cookieChecked, setCookieChecked] = useState(false)

    useEffect(() => {
        const localeCookie = getCookie('locale')
        if (localeCookie) {
            setLocale(localeCookie)
        }
        setCookieChecked(true)
    }, [])

    return cookieChecked ? (
        <>
            <Cookiebar t={t} cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} />
            <MainWrapper>
                <LeftSide>
                    <FormWrapper>
                        <HeaderBrenntagLogo />
                        <LogoCompensation height={spacing.xl - 10} />
                        {isMfa ? (
                            <LoginMfaForm handleMfaSubmit={handleMfaSubmit} isLoading={isLoading} error={error} t={t} />
                        ) : (
                            <LoginUserForm
                                handleLoginSubmit={handleLoginSubmit}
                                isLoading={isLoading}
                                error={error}
                                t={t}
                                email={email}
                            />
                        )}
                    </FormWrapper>
                    <Footer>
                        <Text>
                            {t('login:brenntag')}. {t('login:copyright')}
                        </Text>
                        <FooterBrenntagLogo mobile={isMobile} />
                    </Footer>
                </LeftSide>
                <RightSide>
                    <LoginWelcome />
                    <LoginHelp locale={locale} />
                </RightSide>
            </MainWrapper>
        </>
    ) : null
}
