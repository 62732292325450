import React from 'react'
import { GraphQLFormattedError } from 'graphql'

import { useFormatMessage } from '@frontend/components/hooks'

import { PageError } from './page-error'
interface TooManyResultsErrorProps {
    error?: GraphQLFormattedError
}

export const TooManyResultsError = (_props: TooManyResultsErrorProps) => {
    const t = useFormatMessage()
    const key = 'too-many-results'

    return <PageError title={t(`warning:${key}:title`)} text={t(`warning:${key}:explanation`)} />
}
