import styled from 'styled-components'

import { spacing } from '@bc/ui/src/config'

import { ToasterPlacement } from './toaster'

interface ToastHolderProps {
    placement: ToasterPlacement
    isToasting: boolean
}

const baseStyles = `
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: ${spacing.sm}px;
    z-index: -1;
`

function setPosition(placement: ToasterPlacement): string {
    const top = placement.indexOf('top') >= 0
    const bottom = placement.indexOf('bottom') >= 0
    const left = placement.indexOf('left') >= 0
    const right = placement.indexOf('right') >= 0
    const center = placement.indexOf('center') >= 0

    return `
        ${top ? 'top: 0;' : ''}
        ${bottom ? 'bottom: 0;' : ''}
        ${center ? 'left: 50%; transform: translate3d(-50%, 0, 0);' : left ? 'left: 0;' : ''}
        ${right ? 'right: 0;' : ''}
    `
}

/**
 * Export comp
 */
export const ToastHolder = styled.div`
    ${baseStyles}

    z-index: ${({ isToasting }: ToastHolderProps) => (isToasting ? 101 : 'auto')};
    pointer-events: ${({ isToasting }: ToastHolderProps) => (isToasting ? 'all' : 'none')};

    ${({ placement }: ToastHolderProps) => setPosition(placement)}
`
