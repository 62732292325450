import React, { useMemo } from 'react'
import { useNotifications } from '@frontend/context'
import { GQLNotificationType } from '@bc/types'
import { Button } from '@bc/ui'

export const PromotionMenuButton = () => {
    const [notificationStore, notifications, messageKey] = useNotifications()

    const promotions = useMemo(
        () =>
            notifications
                .filter(({ type }) => type === GQLNotificationType.promotion)
                .filter(({ [messageKey]: message }) => Boolean(message)),
        [notifications, messageKey],
    )

    return promotions.length > 0 ? (
        <Button
            variant="text"
            icon="Announcement"
            data-test-id={`link-promotion`}
            onClick={() => notificationStore.show()}
        />
    ) : null
}
