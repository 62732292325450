import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const News: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="News" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M19 18V7H21V18C21 19.65 19.65 21 18 21C16.35 21 15 19.65 15 18V5H5V16C5 17.65 6.35 19 8 19H13.1C13.25 19.73 13.57 20.39 14 20.97V21H8C5.24 21 3 18.76 3 16V3H17V18C17 18.55 17.45 19 18 19C18.55 19 19 18.55 19 18ZM7 7H13V9H7V7ZM7 11H13V13H7V11ZM13 15H7V17H13V15Z"
                fill={fill === 'gradient' ? 'url(#News_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="News" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="News" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M19 18V7H21V18C21 19.65 19.65 21 18 21C16.35 21 15 19.65 15 18V5H5V16C5 17.65 6.35 19 8 19H13.1C13.25 19.73 13.57 20.39 14 20.97V21H8C5.24 21 3 18.76 3 16V3H17V18C17 18.55 17.45 19 18 19C18.55 19 19 18.55 19 18ZM7 7H13V9H7V7ZM7 11H13V13H7V11ZM13 15H7V17H13V15Z"
                fill={fill === 'gradient' ? 'url(#News_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="News" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="News" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M19 18V7H21V18C21 19.65 19.65 21 18 21C16.35 21 15 19.65 15 18V5H5V16C5 17.65 6.35 19 8 19H13.1C13.25 19.73 13.57 20.39 14 20.97V21H8C5.24 21 3 18.76 3 16V3H17V18C17 18.55 17.45 19 18 19C18.55 19 19 18.55 19 18ZM7 7H13V9H7V7ZM7 11H13V13H7V11ZM13 15H7V17H13V15Z"
                fill={fill === 'gradient' ? 'url(#News_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="News" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="News" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M19 18V7H21V18C21 19.65 19.65 21 18 21C16.35 21 15 19.65 15 18V5H5V16C5 17.65 6.35 19 8 19H13.1C13.25 19.73 13.57 20.39 14 20.97V21H8C5.24 21 3 18.76 3 16V3H17V18C17 18.55 17.45 19 18 19C18.55 19 19 18.55 19 18ZM7 7H13V9H7V7ZM7 11H13V13H7V11ZM13 15H7V17H13V15Z"
                fill={fill === 'gradient' ? 'url(#News_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="News" />}
        </g>
    ),
}
