import React from 'react'

import { ApolloError } from 'apollo-client'

import { defaultLocale, Locale } from '@bc/translations'
import { GQLCustomer, GQLMe } from '@bc/types'

export interface UserContextProps {
    me?: GQLMe
    error?: ApolloError
    meLoading: boolean
    userLocale: Locale
    currentCustomer: GQLCustomer | undefined
    userHasMultipleCustomerEntities: boolean
    userHasCustomers: boolean
    isCsr: boolean
    isAdmin: boolean
    showContextBar: boolean
    activeSalesOrganisation?: string
    activeCustomerId?: string
    activeSalesAreaId?: string
}

export const userContextDefault = {
    me: undefined,
    error: undefined,
    meLoading: false,
    currentCustomer: undefined,
    userLocale: defaultLocale,
    userHasMultipleCustomerEntities: false,
    userHasCustomers: false,
    isCsr: false,
    isAdmin: false,
    showContextBar: false,
}

export const UserContext = React.createContext<UserContextProps>(userContextDefault)
