import styled from 'styled-components'

import { StyledA } from '@bc/ui/src/components/links/styled-link'
import { colors, spacing } from '@bc/ui/src/config'

export const Wrapper = styled.aside`
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 260px;
    background: ${colors.white};
    border: 1px solid ${colors.neutral20};
    border-radius: 2px;
    overflow: hidden;
`

export const Image = styled.img`
    width: 90px;
    height: 90px;
`

export const Text = styled.div`
    padding-left: ${spacing.xs}px;
    padding-right: ${spacing.xs}px;
`

export const Tel = styled.a`
    color: ${colors.neutral90};
    text-decoration: none;
    display: block;
    font-size: 1.4rem;
`

export const Mail = styled(StyledA)`
    display: block;
    font-size: 1.4rem;
`
