import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'

interface RichTextProps {
    color?: keyof typeof colors
    themeColor?: keyof typeof colors
}

export const RichText = styled.div`
    display: block;
    color: ${({ color = 'neutral90' }: RichTextProps) => colors[color]};

    strong {
        font-weight: 500;
    }

    span,
    p,
    strong,
    em,
    ol,
    ol > li,
    ul,
    ul > li,
    a {
        color: ${({ color = 'neutral90' }: RichTextProps) => colors[color]};
    }

    a {
        border-color: ${({ themeColor }: RichTextProps) => (themeColor ? colors[themeColor] : 'transparent')};
    }

    ul > li:before,
    ol > li:before {
        background-color: ${({ themeColor }: RichTextProps) => (themeColor ? colors[themeColor] : 'transparent')};
    }
`
