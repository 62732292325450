import { FormatMessage } from '@frontend/components'
import { finalFormValidations, NumberParserFunction } from '@frontend/utils'

export const customerReferenceRequired = (t: FormatMessage, maxLength: number) => {
    const validate = finalFormValidations.composeValidations(
        finalFormValidations.required(t),
        finalFormValidations.maxLength(t, maxLength),
    )
    return (value: string) => (validate(value) ? t('form-validation:reference-required') : undefined)
}

export const deliveryDateValid = (t: FormatMessage, beforeDate: Date, afterDate: Date, dateFormat: string) =>
    finalFormValidations.composeValidations(
        finalFormValidations.required(t),
        finalFormValidations.date(t, dateFormat),
        finalFormValidations.dateIsAfter(t, beforeDate, dateFormat),
        finalFormValidations.dateIsWorkday(t, dateFormat),
        finalFormValidations.dateIsBefore(t, afterDate, dateFormat),
    )

export const isDeliveryDateValid = (
    t: FormatMessage,
    firstEligibleDeliveryDate: Date,
    lastEligibleDeliveryDate: Date,
    dateFormat: string,
    value: string,
) => !deliveryDateValid(t, firstEligibleDeliveryDate, lastEligibleDeliveryDate, dateFormat)(value)

export const positiveNumber = (t: FormatMessage, parseNumber: NumberParserFunction) =>
    finalFormValidations.composeValidations(
        finalFormValidations.number(t, parseNumber),
        finalFormValidations.isPositive(t, parseNumber),
        finalFormValidations.notTooBig(t, parseNumber),
    )

export const positiveNumberRequired = (t: FormatMessage, parseNumber: NumberParserFunction) =>
    finalFormValidations.composeValidations(finalFormValidations.required(t), positiveNumber(t, parseNumber))

export const newPasswordValid = (t: FormatMessage, minLength: number) =>
    finalFormValidations.composeValidations(
        finalFormValidations.minLength(t, minLength),
        finalFormValidations.hasLowercaseCharacter(t),
        finalFormValidations.hasUppercaseCharacter(t),
        finalFormValidations.hasNumericCharacter(t),
        finalFormValidations.hasSymbolCharacter(t),
        finalFormValidations.required(t),
    )
