import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Shop: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Shop" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M17 6V2H7V6H3V22H21V6H17ZM19 20H5V8H7V11H9V8H11V6H9V4H15V6H13V8H15V11H17V8H19V20Z"
                fill={fill === 'gradient' ? 'url(#Shop_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Shop" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Shop" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M17 6V2H7V6H3V22H21V6H17ZM19 20H5V8H7V11H9V8H11V6H9V4H15V6H13V8H15V11H17V8H19V20Z"
                fill={fill === 'gradient' ? 'url(#Shop_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Shop" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Shop" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M17 6V2H7V6H3V22H21V6H17ZM19 20H5V8H7V11H9V8H11V6H9V4H15V6H13V8H15V11H17V8H19V20Z"
                fill={fill === 'gradient' ? 'url(#Shop_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Shop" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Shop" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M17 6V2H7V6H3V22H21V6H17ZM19 20H5V8H7V11H9V8H11V6H9V4H15V6H13V8H15V11H17V8H19V20Z"
                fill={fill === 'gradient' ? 'url(#Shop_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Shop" />}
        </g>
    ),
}
