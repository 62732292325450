import React from 'react'
import { Field } from 'react-final-form'

import graphqlUtils from '@bc/graphql-utils'
import { GQLMaterial } from '@bc/types'
import { TextInput } from '@bc/ui'
import { FormatMessage } from '@frontend/components'
import { useFormatNumber, useFormatUom, useParseFloatNumber, useParseNumber } from '@frontend/components/hooks'
import { finalFormValidations } from '@frontend/utils'
import { restrictions } from '@frontend/utils/validation'

import { positiveNumberRequired } from './validation'
interface QuantityFields {
    t: FormatMessage
    material: GQLMaterial
    uom?: string
}

export const OrderQuantityFormFields = ({ t, material, uom }: QuantityFields) => {
    const formatUom = useFormatUom()
    const parseNumber = useParseNumber()
    const parseFloatNumber = useParseFloatNumber()
    const n = useFormatNumber()

    const { isBulk, packaging, packagingQuantity } = material
    const packagingName = t(`package:${packaging?.type}`)
    const packagingNamePlural = t(`package-pl:${packaging?.type}`)
    const outputUnit = formatUom(uom ?? 'KGM')
    const inputUnit = isBulk ? formatUom(packagingQuantity.uom) : packagingName
    const inputUnitPlural = isBulk ? inputUnit : packagingNamePlural
    const rawInputUnit = isBulk ? packagingQuantity.uom : packaging?.type
    const allowDecimalsInInput = graphqlUtils.material.isUomAllowDecimals(rawInputUnit)
    const minimumOrderQuantity = graphqlUtils.material.getMinimumOrderQuantity(material)

    return (
        <>
            <Field
                name="pieces"
                validate={finalFormValidations.composeValidations(
                    positiveNumberRequired(t, parseNumber),
                    typeof minimumOrderQuantity === 'number'
                        ? finalFormValidations.minAmount(
                              t,
                              minimumOrderQuantity,
                              isBulk ? outputUnit : t('general:pieces'),
                          )
                        : undefined,
                )}
                render={({ input, meta }) => {
                    const { value } = input
                    const valueNumber = parseFloatNumber(value)
                    return (
                        <TextInput
                            {...input}
                            autoFocus
                            labelText={
                                <span>
                                    {isBulk ? t('general:order-amount') : t('general:pieces-long')}
                                    {' *'}
                                </span>
                            }
                            pattern={
                                allowDecimalsInInput
                                    ? `^[0-9|\.|,]{0,${restrictions.MAX_SAFE_NUMBER_LENGTH}}$`
                                    : `^[0-9]{0,${restrictions.MAX_SAFE_NUMBER_LENGTH}}$`
                            }
                            placeholder="0"
                            min="0"
                            step="1"
                            hasError={value && meta.touched && meta.error}
                            errorText={value && meta.touched && meta.error}
                            onKeyDown={
                                allowDecimalsInInput
                                    ? restrictions.restrictFloatsKeyDown
                                    : restrictions.restrictNumbersKeyDown
                            }
                            append={valueNumber > 1 ? inputUnitPlural : inputUnit}
                            data-test-id="input-order-amount"
                        />
                    )
                }}
            />
            <Field
                name="quantity"
                format={value => n(parseFloatNumber(value || 0))}
                render={({ input, meta }) => (
                    <TextInput
                        {...input}
                        labelText={<span>{t('general:order-quantity')}</span>}
                        readOnly
                        placeholder="0"
                        hasError={meta.touched && meta.error}
                        append={outputUnit}
                        data-test-id="input-order-quantity"
                    />
                )}
            />
        </>
    )
}
