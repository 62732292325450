import React, { useState } from 'react'
import styled from 'styled-components'

import { SvgIcon } from '../svg'
import { TextInputComponents } from './components'
import { TextInputProps, TextInput } from './text-input'

export type TogglePasswordInputProps = TextInputProps

const ClickableInputItem = styled(TextInputComponents.InputItem).attrs(() => ({
    disabled: false,
    'data-location': 'right',
}))`
    &:hover {
        cursor: pointer;
    }
`

export const TogglePasswordInput: React.FunctionComponent<
    TogglePasswordInputProps & React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>
> = ({ append, ...rest }) => {
    const [isViewOn, setViewOn] = useState(false)
    const view = (
        <ClickableInputItem onClick={() => setViewOn(viewState => !viewState)}>
            <SvgIcon icon={isViewOn ? 'ViewOff' : 'View'} size="20" />
        </ClickableInputItem>
    )
    return <TextInput {...rest} append={view} type={isViewOn ? 'text' : 'password'} />
}
