import React, { useContext, useEffect, useState } from 'react'

import { tagManager } from '@bc/gtm'
import { GQLBaseOrder, GQLBaseOrderLine } from '@bc/types'
import { Modal, Button } from '@bc/ui/src'
import { ModalCartForm } from '@frontend/components/forms'

import { ModalCartFormValues } from '@frontend/components/forms/modal-cart-form/modal-cart-form.types'
import { useFormatMessage, useParseFloatNumber } from '@frontend/components/hooks'
import { ShoppingCartContext, ShoppingCartContextData, ToastsContext, ToastsContextData } from '@frontend/context'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ModalMaterialInfoColumn, ModalTitle } from './modal-elements'

interface AddToCartModalProps {
    isOpen: boolean
    order: GQLBaseOrder | undefined
    orderLine: GQLBaseOrderLine | undefined
    onClose: () => void
}
type CombinedProps = AddToCartModalProps & RouteComponentProps

const AddToCartModalClass = ({ orderLine, onClose, isOpen, history }: CombinedProps) => {
    const t = useFormatMessage()
    const parseFloatNumber = useParseFloatNumber()
    const [loading, setLoading] = useState<boolean | undefined>(undefined)
    const [shoppingStore]: ShoppingCartContextData<GQLBaseOrderLine> = useContext(ShoppingCartContext)
    const [toastsStore]: ToastsContextData = useContext(ToastsContext)

    const { material, uom, uomConversionFactor } = orderLine ?? {}

    useEffect(
        () => () => {
            resetState()
        },
        [isOpen],
    )

    const resetState = () => {
        setLoading(false)
    }

    const handleSubmit = async (newValues: ModalCartFormValues) => {
        try {
            if (orderLine && shoppingStore) {
                const newOrderLine: GQLBaseOrderLine = {
                    ...orderLine,
                    quantityOrdered: {
                        ...orderLine?.quantityOrdered,
                        amount: parseFloatNumber(newValues.quantity!),
                    },
                }
                shoppingStore.set(orderLine.material?.id!, newOrderLine)

                handleAddToCartSuccess()
            }
        } catch (e) {
            handleAddToCartError(e)
            onCloseModal()
        }
    }

    const handleAddToCartSuccess = () => {
        onClose()
        toastsStore.addToast({
            type: 'success',
            message: t('request-information-modal:toasts-product-added'),
            button: (
                <Button
                    onClick={() => history.push(t('route:cart-overview'))}
                    variant="success"
                    data-test-id="button-go-to-cart">
                    {t('route:cart-overview.label')}
                </Button>
            ),
        })
    }

    const handleAddToCartError = (error: string) => {
        toastsStore.addToast({
            type: 'error',
            message: error,
        })
    }

    const handleAddToCart = () => {
        if (orderLine && material) {
            tagManager.tags.baseProductAddToCart(orderLine)
        }
    }

    const handleRemoveFromCart = () => {
        if (orderLine && material) {
            tagManager.tags.baseProductRemoveFromCart(orderLine)
        }
    }

    const onCloseModal = () => {
        onClose()
        handleRemoveFromCart()
    }

    return (
        <Modal
            id={'re-order'}
            open={isOpen}
            onClose={onCloseModal}
            type="large"
            content={
                material && [
                    <ModalTitle
                        title={t('request-information-modal:add-to-cart')}
                        intro={t('request-information-modal:add-to-cart.instruction')}
                    />,
                    <ModalMaterialInfoColumn material={material} />,
                ]
            }
            aside={
                isOpen
                    ? orderLine &&
                      material && [
                          <ModalCartForm
                              onSubmit={handleSubmit}
                              onGTMAddToCart={handleAddToCart}
                              onGTMRemoveFromCart={handleRemoveFromCart}
                              onClose={onClose}
                              quantity={orderLine.quantityOrdered.amount}
                              uom={uom}
                              uomConversionFactor={uomConversionFactor}
                              isOpen={isOpen}
                              loading={Boolean(loading)}
                              t={t}
                              material={material}
                          />,
                      ]
                    : undefined
            }
        />
    )
}

export const AddToCartModal = withRouter(AddToCartModalClass)
