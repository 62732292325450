const ApplicationKeysCEE = [
    'adblue:oem-&-truck-dealers',
    'adblue:distribution',
    'adblue:endusers,-carriers-&-fleetowners',
    'adblue:public-filling-stations-(codo/dodo)',
    'adblue:retail-&-public-filling-stations--(codo)',
    'adblue:others',
    'agro:desinfection',
    'agro:herbicides-&-pesticides',
    'agro:mixed-production',
    'agro:fertilizers',
    'agro:horticulture',
    'agro:farmers',
    'agro:orchards',
    'agro:others',
    'cement-mills:',
    'cleaning:cleaning-&-cleansing',
    'cleaning:homecare',
    'cleaning:distributors',
    'cleaning:auto-care-cleaning',
    'cleaning:industrial-&-institutional-cleaning',
    'cleaning:others',
    'heat-engineering:',
    'chemical-synthesis:',
    'distributors:others',
    'electronics:medical-equipment-&-tools',
    'electronics:automotive-electronics',
    'electronics:lighting-equipment',
    'electronics:brown-goods',
    'electronics:others',
    'power-industry:',
    'renewable-energy:biodiesel',
    'renewable-energy:biogas',
    'renewable-energy:solar',
    'renewable-energy:others',
    'coatings-&-construction:construction:building-chemicals',
    'coatings-&-construction:construction:fireproof-materials',
    'coatings-&-construction:construction:gipsum-cardboard',
    'coatings-&-construction:construction:plywood-&-particle-boards',
    'coatings-&-construction:construction:floorings',
    'coatings-&-construction:construction:concrete-based-products',
    'coatings-&-construction:construction:cement-based-products',
    'coatings-&-construction:construction:plasters',
    'coatings-&-construction:construction:mineral-wool-',
    'coatings-&-construction:construction:others',
    'coatings-&-construction:distributors',
    'coatings-&-construction:elastomers:pu-elastomers',
    'coatings-&-construction:elastomers:others',
    'coatings-&-construction:coatings:architectural-coatings',
    'coatings-&-construction:coatings:wood-coatings',
    'coatings-&-construction:coatings:powder-coatings',
    'coatings-&-construction:coatings:industrial-coatings',
    'coatings-&-construction:coatings:primers',
    'coatings-&-construction:coatings:automotive-coatings',
    'coatings-&-construction:coatings:thinners',
    'coatings-&-construction:coatings:others',
    'coatings-&-construction:adhesives-&-sealants:adhesives',
    'coatings-&-construction:adhesives-&-sealants:hot-melts',
    'coatings-&-construction:adhesives-&-sealants:sealants',
    'coatings-&-construction:adhesives-&-sealants:others',
    'coatings-&-construction:composites',
    'coatings-&-construction:producers-of-c&c-raw-materials',
    'coatings-&-construction:inks',
    'coatings-&-construction:others',
    'mining:natural-building-materials-&-stones',
    'mining:metal-ore',
    'mining:coal',
    'mining:others',
    'steelworks:',
    'municipalities-&-institutions:schools-&-universities',
    'municipalities-&-institutions:hospitals',
    'municipalities-&-institutions:others',
    'coke-engineering:',
    'fotochemical-&-film-materials:',
    'explosives-&-pyrotechnics:',
    'environmental-protection:consulting-&-services',
    'environmental-protection:soil-remediation',
    'environmental-protection:incineration-plants',
    'environmental-protection:waste-dumps',
    'reagents:',
    'printing-&-publishing:narrow-web',
    'printing-&-publishing:packaging-printing',
    'printing-&-publishing:distributors',
    'printing-&-publishing:printing-inks',
    'printing-&-publishing:flexography',
    'printing-&-publishing:bookbinding',
    'printing-&-publishing:sheet-fed-offset',
    'printing-&-publishing:cold-set-offset',
    'printing-&-publishing:heat-set-offset',
    'printing-&-publishing:serigraphy',
    'printing-&-publishing:rotogravure',
    'printing-&-publishing:others',
    'loundries:',
    'automotive-oems:',
    'automotive-oems---service-companies:',
    'machinery-production:measuring-instruments',
    'machinery-production:mechanized-household-equipment',
    'machinery-production:others',
    'ceramic-industry:household-ceramic',
    'ceramic-industry:others',
    'wood-industry-&-woodwork:furniture',
    'wood-industry-&-woodwork:woodwork',
    'wood-industry-&-woodwork:wood-industry',
    'wood-industry-&-woodwork:others',
    'pharma:distributors',
    'pharma:human-&-veterinary-medicines',
    'pharma:natural-remedies',
    'pharma:pharmaceutical-starting-material-producers',
    'pharma:others',
    'rubber:footwear',
    'rubber:distributors',
    'rubber:non-automotive-profiles',
    'rubber:automotive-profiles',
    'rubber:general-rubber-goods',
    'rubber:wire-&-cables',
    'rubber:tires:automotive-tires',
    'rubber:tires:industrial-tires',
    'rubber:tires:others',
    'rubber:non-automotive-hoses',
    'rubber:automotive-hoses',
    'rubber:automotive-belts',
    'rubber:non-automotive-belts',
    'rubber:rubber-rolls-and-wheels',
    'rubber:latex',
    'rubber:custom-mixing',
    'rubber:others',
    'cosmetics:distributors',
    'cosmetics:general',
    'cosmetics:decorative-products-&-make-up',
    'cosmetics:soap-&-candles',
    'cosmetics:perfumes-&-fragrance',
    'cosmetics:skin-care-and-sun-cosmetics',
    'cosmetics:hair-care-and-body-wash',
    'cosmetics:toll-manufacturers-&-contractors',
    'cosmetics:others',
    'metal:zinc-coating---automotive-components',
    'metal:zinc-coating---non-automotive-components',
    'metal:distribution',
    'metal:electroplating---automotive-components',
    'metal:electroplating---non-automotive-components',
    'metal:automotive-components---others',
    'metal:non-automotive-components---others',
    'metal:vanishing---automotive-components',
    'metal:vanishing---non-automotive-components',
    'metal:foundry---automotive-components',
    'metal:foundry---non-automotive-components',
    'pulp-&-paper:tissue-manufacturers',
    'pulp-&-paper:board-manufacturers',
    'pulp-&-paper:packaging-&-converting',
    'pulp-&-paper:paper-manufacturers',
    'pulp-&-paper:others',
    'spirit-industry:',
    'food:chocolate-&-confection:confectionery',
    'food:chocolate-&-confection:chocolate',
    'food:food-general:sugar-factories',
    'food:beverages:breweries,-malting',
    'food:beverages:alcoholic-beverages---others',
    'food:beverages:non-alcoholic-beverages',
    'food:beverages:instant-drinks',
    'food:bakery-&-bread:bars-and-cereals',
    'food:bakery-&-bread:bakery-mix-blenders',
    'food:bakery-&-bread:baked-goods',
    'food:food-general:flavour-producers',
    'food:food-general:other-ingredient-producers',
    'food:meat-and-poultry:blends',
    'food:meat-and-poultry:production',
    'food:meat-and-poultry:fish-industry',
    'food:meat-and-poultry:slaughterhouses',
    'food:dairy-&-ice-cream:ice-cream',
    'food:dairy-&-ice-cream:dairy-ingredients',
    'food:dairy-&-ice-cream:dairy-consumer-products',
    'food:food-general:starch-industry',
    'food:food-general:food---distributors',
    'food:food-general:blenders---other',
    'food:food-general:fats-&-oils',
    'food:food-general:tobacco',
    'food:food-general:potato-processing',
    'food:fruit-&-vegetable-processing:jams,-fruit-preparations',
    'food:fruit-&-vegetable-processing:fruit-concentrates',
    'food:fruit-&-vegetable-processing:others',
    'food:function-food,-supplements:food-supplements',
    'food:function-food,-supplements:sport-nutrition',
    'food:function-food,-supplements:baby-food',
    'food:function-food,-supplements:special-food',
    'food:convenience-food:savoury---others',
    'food:convenience-food:soups,-sauces,-dressings',
    'food:convenience-food:convenience-food',
    'food:others',
    'glass-industry:household-glass-&-glass-packaging',
    'glass-industry:industrial-&-technical-glass',
    'glass-industry:automotive-glass',
    'glass-industry:others',
    'textile-&-leather:carpets-&-linings',
    'textile-&-leather:knitted-fabrics',
    'textile-&-leather:automotive-leather-components',
    'textile-&-leather:leather-tanning',
    'textile-&-leather:leather-clothing,-footwear,-goods',
    'textile-&-leather:yarn-&-threads',
    'textile-&-leather:tights',
    'textile-&-leather:natural-textiles',
    'textile-&-leather:synthetic-textiles',
    'textile-&-leather:unwoven-fabrics',
    'textile-&-leather:others',
    'oil-&-gas:downstream---refineries,-pipelines',
    'oil-&-gas:downstream---chemical-plants',
    'oil-&-gas:distribution',
    'oil-&-gas:midstream---specialty-gases',
    'oil-&-gas:midstream---compounders',
    'oil-&-gas:midstream---n-spec',
    'oil-&-gas:midstream---production',
    'oil-&-gas:midstream---gasplants',
    'oil-&-gas:upstream---drilling',
    'oil-&-gas:services',
    'oil-&-gas:others',
    'lubricants:asphalts',
    'lubricants:bio-diesel---producers',
    'lubricants:bio-ethanol---producers',
    'lubricants:distribution',
    'lubricants:blending-facilities',
    'lubricants:industrial-lubricants',
    'lubricants:automotive-lubricants',
    'lubricants:release-agents',
    'lubricants:others',
    'transportation:airports',
    'transportation:others',
    'tourism:',
    'polymers:distributors',
    'polymers:polyurethanes:automotive-components',
    'polymers:polyurethanes:flexible-&-rigid-polyurethanes-',
    'polymers:polyurethanes:polyurethane-systems',
    'polymers:polyurethanes:others',
    'polymers:compounders',
    'polymers:raw-material-producers',
    'polymers:injection-moulding:non-automotive-components',
    'polymers:injection-moulding:automotive-components',
    'polymers:regranulates',
    'polymers:extrusion:films',
    'polymers:extrusion:automotive-components',
    'polymers:extrusion:flooring,-coated-textiles',
    'polymers:extrusion:plates-&-profiles',
    'polymers:extrusion:pipes',
    'polymers:extrusion:polymers-textiles-&-fibers',
    'polymers:extrusion:others',
    'polymers:chemosets',
    'polymers:thermosets',
    'polymers:others',
    'services:',
    'water-treatment:swimming-pools',
    'water-treatment:municipalities-for-potable-water',
    'water-treatment:municipalities-for-potable-and-waste-water',
    'water-treatment:distributors',
    'water-treatment:environmental-&-equipment-companies',
    'water-treatment:municipalities-for-waste-water',
    'water-treatment:water-conditioning-service-companies',
    'water-treatment:institutional-service-companies',
    'water-treatment:other-water-treatment-service-companies',
    'water-treatment:other-water-treatment-end-users',
    'water-treatment:others',
    'animal-nutrition:distributors',
    'animal-nutrition:homemixers-&-farmers:cattle-and-sheep',
    'animal-nutrition:homemixers-&-farmers:poultry',
    'animal-nutrition:homemixers-&-farmers:pigs',
    'animal-nutrition:homemixers-&-farmers:others',
    'animal-nutrition:additive-blenders',
    'animal-nutrition:mixed-production',
    'animal-nutrition:aquafeed',
    'animal-nutrition:compound-feed',
    'animal-nutrition:premix',
    'animal-nutrition:milk-replacers',
    'animal-nutrition:integrators',
    'animal-nutrition:feed-ingredients',
    'animal-nutrition:animal-waste-processing',
    'animal-nutrition:veterinarians',
    'animal-nutrition:petfood',
    'animal-nutrition:others',
    'other',
]

export default ApplicationKeysCEE
