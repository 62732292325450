import React, { useMemo, useState } from 'react'

import { Field, useField, useFormState } from 'react-final-form'
import { OptionsType } from 'react-select'

import { ShopFeatures } from '@bc/config'
import { GQLLocation, GQLMaterial } from '@bc/types'
import { Checkbox, getLocationAddressLine, SelectInput, SelectInputOption, TextInput } from '@bc/ui'
import { Feature } from '@frontend/components/customer'
import { Datepicker } from '@frontend/components/datepicker'
import { FormatMessage, useFormatMessage } from '@frontend/components/hooks'
import { finalFormValidations, getDateFormatLocalized } from '@frontend/utils'

import { deliveryDateValid, isDeliveryDateValid } from '../_shared/validation'
import { OrderPiecesFormFields, OrderQuantityFormFields, Wrapper } from '../_shared'
import { ReorderFormValues } from './reorder-form.types'

interface ReorderFormFieldsProps {
    material: GQLMaterial
    uom?: string
    shipTos: GQLLocation[]
    firstEligibleDeliveryDate: Date
    lastEligibleDeliveryDate: Date
    dateFormat: string
}

export const ReorderFormFieldsStep1 = ({
    material,
    uom,
    shipTos,
    firstEligibleDeliveryDate,
    lastEligibleDeliveryDate,
    dateFormat,
}: ReorderFormFieldsProps) => {
    const t: FormatMessage = useFormatMessage()
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)
    const {
        input: { value: pickup },
    } = useField('pickup')
    const shipToOptions: OptionsType<SelectInputOption> = useMemo(
        () =>
            shipTos.map((location: GQLLocation) => ({
                value: location.id,
                label: getLocationAddressLine(location),
            })),
        [shipTos],
    )
    const { initialValues: formInitialValues } = useFormState()

    const hideCalendar = () => {
        setIsCalendarOpen(false)
    }

    const showCalendar = () => {
        setIsCalendarOpen(true)
    }

    const validateShipToId = (value: string, values: ReorderFormValues) => {
        if (!values.pickup) {
            return finalFormValidations.required(t)(value)
        }
        return undefined
    }

    const QuantityPiecesFormCalculator = material.isBulk ? OrderPiecesFormFields : OrderQuantityFormFields
    const autoSelectOneShipTo = pickup
        ? undefined
        : shipToOptions.length === 1
        ? shipToOptions[0].value
        : formInitialValues.shipToId

    return (
        <>
            <QuantityPiecesFormCalculator uom={uom} t={t} material={material} />
            <Wrapper>
                <Field
                    name="shipToId"
                    initialValue={autoSelectOneShipTo}
                    validate={validateShipToId}
                    parse={o => o?.value}
                    render={({ input }) => {
                        const { value, ...restInput } = input
                        const selectedAddress: any = value && shipToOptions.find(o => o.value === value)

                        return (
                            <SelectInput
                                {...restInput}
                                value={selectedAddress}
                                labelText={t('general:select-ship-to') + (pickup ? '' : ' *')}
                                placeholder={t('filters:select-an-address')}
                                maxMenuHeight={300}
                                isSearchable
                                isClearable
                                isDisabled={pickup}
                                options={shipToOptions}
                                noOptionsMessage={() => t('not-found:select')}
                                classNamePrefix="select-ship-to-address-modal-reorder"
                                data-test-id="select-ship-to-address-modal-reorder"
                            />
                        )
                    }}
                />
            </Wrapper>
            <Feature
                flag={ShopFeatures.OrderPickup}
                on={
                    <Wrapper>
                        <Field
                            name="pickup"
                            type="checkbox"
                            validateFields={['shipToId']}
                            render={({ input }) => (
                                <Checkbox
                                    {...input}
                                    id="pickup"
                                    labelText={t('request-information-modal:reorder:self-pickup')}
                                    data-test-id="checkbox-pickup"
                                    colorScheme="deepPurple"
                                />
                            )}
                        />
                    </Wrapper>
                }
            />
            <Wrapper>
                <Field
                    name="requestedDeliveryDate"
                    validate={deliveryDateValid(t, firstEligibleDeliveryDate, lastEligibleDeliveryDate, dateFormat)}>
                    {({ input, meta }) => (
                        <Datepicker
                            open={isCalendarOpen}
                            initialMonth={firstEligibleDeliveryDate}
                            onClickOutside={event => {
                                const element = event.target as HTMLElement
                                const elementName = element?.getAttribute && element.getAttribute('name')

                                if (elementName !== input.name) {
                                    hideCalendar()
                                }
                            }}
                            onChange={(date, parsedDate) => {
                                if (
                                    parsedDate &&
                                    isDeliveryDateValid(
                                        t,
                                        firstEligibleDeliveryDate,
                                        lastEligibleDeliveryDate,
                                        dateFormat,
                                        date,
                                    )
                                ) {
                                    // https://github.com/final-form/react-final-form/pull/438
                                    // Recommended by plugin authors is to cast to any
                                    input.onChange(date as any)
                                    hideCalendar()
                                }
                            }}
                            disabledDays={[
                                { after: lastEligibleDeliveryDate },
                                { before: firstEligibleDeliveryDate },
                                { daysOfWeek: [0, 6] },
                            ]}
                            render={({ datepickerInput }) => (
                                <TextInput
                                    {...datepickerInput(input)}
                                    onFocus={e => {
                                        showCalendar()
                                        input.onFocus(e)
                                    }}
                                    labelText={
                                        (pickup
                                            ? t('request-information-modal:requested-pickup-date')
                                            : t('request-information-modal:requested-delivery-date')) + ' *'
                                    }
                                    placeholder={getDateFormatLocalized(t, dateFormat)}
                                    type="text"
                                    noMargin
                                    autoComplete="off"
                                    hasError={input.value && meta.touched && meta.error}
                                    errorText={input.value && meta.touched && meta.error}
                                    data-test-id="input-requested-delivery-date"
                                />
                            )}
                        />
                    )}
                </Field>
            </Wrapper>
        </>
    )
}
