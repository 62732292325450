import React from 'react'
import { Field, Form } from 'react-final-form'

import { MessageMap } from '@bc/translations'
import { Text, TextInput, TogglePasswordInput, FormInputComponents } from '@bc/ui'
import { FormatMessage } from '@frontend/components'
import { finalFormValidations } from '@frontend/utils'

import { LoginFormValues } from '../login-page-container'
import * as LoginPageComponents from './login-components'
import LoginError from './login-error'

interface LoginPageProps {
    isLoading: boolean
    error: any
    t: FormatMessage
    handleLoginSubmit: (values: LoginFormValues) => void
    email: string
}

const renderErrorMessage = (error: string, t: FormatMessage) => {
    const key = `login-form:error.${error}` as keyof MessageMap
    return t(key, { id: key, defaultMessage: t('login-form:error') })
}

export const LoginUserForm = ({ isLoading, t, error, handleLoginSubmit, email }: LoginPageProps) => (
    <>
        <Text h3 fontWeight="bold" color="deepPurple" data-test-id="text-login-title">
            {t('login:header')}
        </Text>

        <Form onSubmit={handleLoginSubmit} initialValues={{ username: email, password: '' }}>
            {({ handleSubmit }) => (
                <LoginPageComponents.Form method="POST" onSubmit={handleSubmit}>
                    <FormInputComponents.InputWrapper>
                        {error && <LoginError>{renderErrorMessage(error, t)}</LoginError>}
                        <Field
                            name="username"
                            validate={finalFormValidations.email(t)}
                            render={({ input, meta }) => (
                                <TextInput
                                    {...input}
                                    autoComplete="username"
                                    labelText={t('login:username')}
                                    labelColor="neutral90"
                                    placeholder={t('login:username-placeholder')}
                                    type="text"
                                    hasError={meta.touched && meta.error}
                                    errorText={meta.error}
                                    data-test-id="input-login-username"
                                />
                            )}
                        />
                        <Field
                            name="password"
                            validate={finalFormValidations.password(t)}
                            render={({ input, meta }) => (
                                <TogglePasswordInput
                                    {...input}
                                    autoComplete="current-password"
                                    labelText={t('login:password')}
                                    labelColor="neutral90"
                                    placeholder={t('login:password-placeholder')}
                                    type="password"
                                    hasError={meta.touched && meta.error}
                                    errorText={meta.error}
                                    data-test-id="input-login-password"
                                />
                            )}
                        />

                        <LoginPageComponents.ButtonBar>
                            <LoginPageComponents.LoginButton
                                buttonSize="large"
                                type="submit"
                                isLoading={isLoading}
                                variant="action"
                                data-test-id="button-login">
                                {t('general:login')}
                            </LoginPageComponents.LoginButton>
                        </LoginPageComponents.ButtonBar>
                        <LoginPageComponents.ResetLink
                            to={t('route:forgot-password')}
                            data-test-id="link-forgot-password">
                            {t('login:button.forgot-password')}
                        </LoginPageComponents.ResetLink>
                    </FormInputComponents.InputWrapper>
                </LoginPageComponents.Form>
            )}
        </Form>
    </>
)
