import React, { useContext } from 'react'

import { QueryResult } from '@apollo/react-common'
import { useQuery } from '@apollo/react-hooks'

import { EmptyState, FullScreenLoaderRound } from '@bc/ui'
import { useFormatMessage } from '@frontend/components'
import { PlatformConfigContext, UserContext } from '@frontend/context'
import { AccountPage } from './account-page'
import { GetRelatedUsers, GetRelatedUsersQueryResponse } from './queries'

export const AccountContainer = () => {
    const { me, currentCustomer } = useContext(UserContext)
    const { appConfig } = useContext(PlatformConfigContext)
    const t = useFormatMessage()
    const variables = {
        sort: 'email',
    }
    const { error, loading, data }: QueryResult<GetRelatedUsersQueryResponse> = useQuery(GetRelatedUsers, {
        variables,
        skip: !Boolean(currentCustomer),
    })
    const relatedUsers = data?.relatedUsers?.users

    if (error) {
        return <EmptyState title={t('general:error.label')} text={error.message} center />
    }

    return (
        <>
            <FullScreenLoaderRound isVisible={loading} />
            <AccountPage
                t={t}
                me={me}
                currentCustomer={currentCustomer}
                appConfig={appConfig}
                relatedUsers={relatedUsers}
            />
        </>
    )
}
