import styled from 'styled-components'

import { Button, colors, media, spacing, StyledA as Link, SvgIcon, Text, ButtonLink } from '@bc/ui'

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    max-width: 400px;

    ${media.max('sm')} {
        margin-top: ${spacing.sm}px;
        max-width: 100%;
    }
`

export const StyledIntroText = styled(Text)`
    a,
    span {
        color: ${colors.white};
    }
`

export const LoginButton = styled(Button)`
    flex: 1;
    width: 100%;
    min-height: 50px;
`

export const ButtonBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ResetLink = styled(ButtonLink)`
    align-self: flex-start;
    margin-top: ${spacing.sm}px;
    padding: 0;
    font-size: 1.4rem;
`

export const StyledA = styled(Link)`
    align-self: flex-start;
    font-size: 1.6rem;
    line-height: 1.5;

    ${media.min('md')} {
        font-size: 2rem;
        line-height: 1.4;
    }
`

export const Image = styled.img`
    width: 100%;
    align-self: center;
`

// being new login screen components
export const MainWrapper = styled.div`
    height: 100%;
    display: flex;
    ${media.max('md')} {
        flex-direction: column;
    }
    flex-direction: row;
`

const Side = styled.div`
    width: 50%;
    padding: ${spacing.lg}px ${spacing.xl}px ${spacing.sm}px ${spacing.xl}px;
    flex-direction: column;

    ${media.max('md')} {
        width: 100%;
        padding: ${spacing.sm}px;
    }
`
export const LeftSide = styled(Side)`
    background-color: ${colors.white};
    color: ${colors.neutral90};
    align-items: stretch;
`
export const RightSide = styled(Side)`
    background-color: ${colors.deepPurple};
    color: ${colors.white};
`

export const FormWrapper = styled.div`
    height: calc(100% - 50px);
`

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    ${media.max('md')} {
        margin-top: ${spacing.sm}px;
    }
`

export const IntroText = styled(Text)`
    color: ${colors.white};
    margin-bottom: 8px;
`

export const LogoCompensation = styled.div<{ height: number }>`
    padding-bottom: ${({ height }) => height}px;
    ${media.max('md')} {
        padding-bottom: ${spacing.sm}px;
    }
`

export const WelcomeBullets = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: ${spacing.sm}px;
    & li {
        padding-bottom: ${spacing.xxs}px;
    }
`
export const Bullet = styled(SvgIcon).attrs({ icon: 'Circle_Checkmark', size: '20', fill: colors.white })`
    margin-right: ${spacing.xs}px;
    margin-top: -2px;
    vertical-align: top;
`

export const BulletList = styled.li`
    white-space: nowrap;
`

export const BulletText = styled(IntroText)`
    display: inline-block;
    position: relative;
    top: -4px;
    left: -5px;
    white-space: break-spaces;
`
