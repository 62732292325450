import styled from 'styled-components'

import { ButtonTo } from '@bc/ui/src/components'

export const ButtonContinueShopping = styled(ButtonTo).attrs({
    icon: 'Arrow_Left',
    buttonSize: 'medium',
    variant: 'solid',
})`
    align-self: flex-end;
`

export const OverviewTemplateContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
