import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Dialogue: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Dialogue" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M2 2H18V4H4V15H2V2ZM22 6V8H8V17H11.41L14 19.59L16.59 17H20V10H22V19H17.41L14 22.41L10.59 19H6V6H22Z"
                fill={fill === 'gradient' ? 'url(#Dialogue_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Dialogue" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Dialogue" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M2 2H18V4H4V15H2V2ZM22 6V8H8V17H11.41L14 19.59L16.59 17H20V10H22V19H17.41L14 22.41L10.59 19H6V6H22Z"
                fill={fill === 'gradient' ? 'url(#Dialogue_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Dialogue" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Dialogue" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M2 2H18V4H4V15H2V2ZM22 6V8H8V17H11.41L14 19.59L16.59 17H20V10H22V19H17.41L14 22.41L10.59 19H6V6H22Z"
                fill={fill === 'gradient' ? 'url(#Dialogue_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Dialogue" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Dialogue" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M2 2H18V4H4V15H2V2ZM22 6V8H8V17H11.41L14 19.59L16.59 17H20V10H22V19H17.41L14 22.41L10.59 19H6V6H22Z"
                fill={fill === 'gradient' ? 'url(#Dialogue_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Dialogue" />}
        </g>
    ),
}
