import React from 'react'

import { Button, EmptyState, OxygenTemplate } from '@bc/ui'

import { useFormatMessage } from '@frontend/components'
interface EmptyCartPageProps {
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
export const EmptyCartPage = ({ onClick }: EmptyCartPageProps) => {
    const t = useFormatMessage()

    return (
        <OxygenTemplate>
            <EmptyState title={t('checkout:empty-cart')} text={t('checkout:empty-cart-message')} center>
                <Button variant="solid" onClick={onClick} title={t('route:order-history.label')}>
                    {t('route:order-history.label')}
                </Button>
            </EmptyState>
        </OxygenTemplate>
    )
}
