import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'

export const RichTextTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`

export const RichTextThead = styled.thead`
    display: none;
`

export const RichTextTbody = styled.tbody``

export const RichTextTr = styled.tr`
    border-bottom: 1px solid ${colors.neutral20};
`

export const RichTextTd = styled.td`
    padding: 20px 0;
`

export const RichTextTh = styled.th``
