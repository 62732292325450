import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Chevron_Left: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Chevron_Left" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M15.29 21.71L5.59 12L15.29 2.29L16.71 3.71L8.41 12L16.71 20.29L15.29 21.71Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Left_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Left" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Chevron_Left" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M15.29 21.71L5.59 12L15.29 2.29L16.71 3.71L8.41 12L16.71 20.29L15.29 21.71Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Left_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Left" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Chevron_Left" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M15.29 21.71L5.59 12L15.29 2.29L16.71 3.71L8.41 12L16.71 20.29L15.29 21.71Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Left_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Left" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Chevron_Left" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M15.29 21.71L5.59 12L15.29 2.29L16.71 3.71L8.41 12L16.71 20.29L15.29 21.71Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Left_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Left" />}
        </g>
    ),
}
