import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { useFormatMessage } from '@frontend/components'

import { ErrorPage } from './error-page'

const Maintenance = ({ staticContext }: RouteComponentProps) => {
    const t = useFormatMessage()
    if (staticContext) {
        staticContext.statusCode = 404
    }

    return (
        <ErrorPage
            title={t('maintenance:title')}
            message={t('maintenance:message')}
            url={{ href: t('route:logout'), text: t('login:back-to-login') }}
        />
    )
}

export const MaintenancePage = withRouter(Maintenance)
