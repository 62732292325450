import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Unlocked: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Unlocked" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M21 21H3V11H5V19H19V13H7V7C7 4.24 9.24 2 12 2C14.76 2 17 4.24 17 7H15C15 5.35 13.65 4 12 4C10.35 4 9 5.35 9 7V11H21V21Z"
                fill={fill === 'gradient' ? 'url(#Unlocked_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Unlocked" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Unlocked" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M21 21H3V11H5V19H19V13H7V7C7 4.24 9.24 2 12 2C14.76 2 17 4.24 17 7H15C15 5.35 13.65 4 12 4C10.35 4 9 5.35 9 7V11H21V21Z"
                fill={fill === 'gradient' ? 'url(#Unlocked_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Unlocked" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Unlocked" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M21 21H3V11H5V19H19V13H7V7C7 4.24 9.24 2 12 2C14.76 2 17 4.24 17 7H15C15 5.35 13.65 4 12 4C10.35 4 9 5.35 9 7V11H21V21Z"
                fill={fill === 'gradient' ? 'url(#Unlocked_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Unlocked" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Unlocked" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M21 21H3V11H5V19H19V13H7V7C7 4.24 9.24 2 12 2C14.76 2 17 4.24 17 7H15C15 5.35 13.65 4 12 4C10.35 4 9 5.35 9 7V11H21V21Z"
                fill={fill === 'gradient' ? 'url(#Unlocked_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Unlocked" />}
        </g>
    ),
}
