import React from 'react'

import { Sidebar } from '@bc/ui/src/components/sidebar'
import * as Components from './mercury-components'

interface MercuryTemplateProps {
    filterCount?: number
    title?: string
    children: {
        sidebarContent?: React.ReactNode
        content: React.ReactNode
    }
}

export const MercuryTemplate = ({
    title,
    filterCount,
    children: { sidebarContent, content },
}: MercuryTemplateProps) => (
    <Components.Wrapper>
        {sidebarContent && (
            <Sidebar title={title} count={filterCount}>
                {sidebarContent}
            </Sidebar>
        )}
        <Components.ContentWrapper>
            <Components.ChildrenWrapper>{content}</Components.ChildrenWrapper>
        </Components.ContentWrapper>
    </Components.Wrapper>
)
