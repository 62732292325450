import React from 'react'
import { Field, Form } from 'react-final-form'
import { SubmissionErrors } from 'final-form'

import { Button, ButtonLink, Text, TogglePasswordInput, FormInputComponents } from '@bc/ui'
import { FormatMessage } from '@frontend/components/hooks'
import { finalFormValidations } from '@frontend/utils'

import { ButtonsWrapper } from '../_shared/components'
import { newPasswordValid } from '../_shared'

interface Props {
    t: FormatMessage
    onSubmit: (values: any) => void | SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined
}

export const PasswordSetForm = ({ t, onSubmit }: Props) => (
    <Form onSubmit={onSubmit}>
        {({ submitError, handleSubmit, values, hasValidationErrors }) => (
            <form onSubmit={handleSubmit} data-test-id="form-password-set">
                <FormInputComponents.InputWrapper>
                    <Field
                        name="newPassword"
                        validate={newPasswordValid(t, 8)}
                        render={({ input, meta }) => (
                            <TogglePasswordInput
                                {...input}
                                autoComplete="new-password"
                                labelText={t('set-password-form:new-password')}
                                placeholder={t('set-password-form:new-password')}
                                type="password"
                                hasError={meta.touched && meta.error}
                                errorText={meta.error}
                                data-test-id="input-new-password"
                            />
                        )}
                    />
                    <Field
                        name="confirmNewPassword"
                        validate={finalFormValidations.composeValidations(
                            finalFormValidations.passwordRepeat(t),
                            finalFormValidations.isEqualTo(t, values.newPassword),
                        )}
                        render={({ input, meta }) => (
                            <TogglePasswordInput
                                {...input}
                                autoComplete="new-password"
                                labelText={t('set-password-form:new-password-confirmation')}
                                placeholder={t('set-password-form:new-password-confirmation')}
                                type="password"
                                hasError={meta.touched && meta.error}
                                errorText={meta.error}
                                data-test-id="input-confirm-password"
                            />
                        )}
                    />
                    {submitError && (
                        <Text p color="red70">
                            {submitError}
                        </Text>
                    )}
                </FormInputComponents.InputWrapper>
                <ButtonsWrapper>
                    <ButtonLink to="/login" data-test-id="link-back-to-login" title={t('login:back-to-login')}>
                        {t('login:back-to-login')}
                    </ButtonLink>
                    <Button
                        type="submit"
                        variant="action"
                        data-test-id="button-save"
                        title={t('general:save')}
                        disabled={hasValidationErrors}>
                        {t('general:save')}
                    </Button>
                </ButtonsWrapper>
            </form>
        )}
    </Form>
)
