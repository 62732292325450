import React from 'react'
import { CheckboxComponents, FormInputComponents } from './components'
import { CheckboxRadioLabelProps } from './components/styles'
export interface CheckboxProps extends CheckboxRadioLabelProps {
    id: string
    labelText?: React.ElementType | JSX.Element | string
    errorText?: React.ElementType | JSX.Element | string
}

export const Checkbox: React.SFC<CheckboxProps & React.HTMLProps<HTMLInputElement>> = ({
    id,
    labelText,
    errorText,
    hasError,
    colorScheme,
    as: _as,
    ref: _ref,
    ...rest
}) => (
    <FormInputComponents.InputWrapper noFlex>
        <FormInputComponents.ButtonWrapper>
            <FormInputComponents.HiddenInput id={id} type="checkbox" {...rest} />
            <CheckboxComponents.Label
                htmlFor={id}
                hasError={hasError}
                colorScheme={colorScheme}
                data-test-id={`checkbox-${id}`}
                data-test-class="checkbox">
                <FormInputComponents.LabelText>{labelText}</FormInputComponents.LabelText>
            </CheckboxComponents.Label>
        </FormInputComponents.ButtonWrapper>
        {Boolean(hasError && errorText) && <FormInputComponents.ErrorLabel>{errorText}</FormInputComponents.ErrorLabel>}
    </FormInputComponents.InputWrapper>
)
