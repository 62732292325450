import React from 'react'
import styled from 'styled-components'

import { media } from '../../helpers/media'
import { BreakpointsKey } from '../../config'

interface ShowOnlyProps {
    between?: [BreakpointsKey, BreakpointsKey]
    children: React.ReactNode
    greaterThan?: BreakpointsKey
    lessThan?: BreakpointsKey
    not?: boolean
}

export const ShowOnly = styled(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    ({ between, greaterThan, lessThan, not, ...rest }: ShowOnlyProps) => <div {...rest} />,
)`
    display: ${({ not }) => (not ? `inherit` : `none`)};

    ${({ between, not }) =>
        between
            ? `
            display: ${not ? `none` : `inherit`};
                ${media.max(between[0])} {
                    display: ${not ? `inherit` : `none`};
                }
                ${media.min(between[1])} {
                    display: ${not ? `inherit` : `none`};
                }`
            : ``}

    ${({ greaterThan, not }) =>
        greaterThan
            ? `${media.min(greaterThan)} {
                    display: ${not ? `none` : `inherit`};
                }`
            : ``}

    ${({ lessThan, not }) =>
        lessThan
            ? `${media.max(lessThan)} {
                    display: ${not ? `none` : `inherit`};
                }`
            : ``}
`
