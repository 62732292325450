import styled from 'styled-components'

import { colors, media, StyledA, Text } from '@bc/ui'

interface ModalPaddingProps {
    amount: number
}

export const RequestWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    ${media.min('sm')} {
        margin-top: 10px;
    }
`

export const StyledCSRLink = styled(StyledA)`
    font-size: 1.4rem;
    line-height: 1.4286;
    color: ${colors.deepPurple};
    text-decoration: none;
`
export const ResponseHeader = styled(Text).attrs(() => ({ h6: true }))`
    margin-right: 30px;
`

export const ModalPadding = styled.div`
    margin-top: ${({ amount }: ModalPaddingProps) => amount}px;
    display: block;
`
