import React, { useContext, useMemo } from 'react'

import { ButtonLink, ButtonAnchor, FooterBrenntagLogo, SelectInput, selectFindOption, SelectInputOption } from '@bc/ui'

import { Language, languageLabels } from '@bc/translations'
import { useFormatMessage } from '@frontend/components/hooks'
import { PlatformConfigContext, UserContext } from '@frontend/context'
import { OptionsType } from 'react-select'
import { getImprintUrl, getPrivacyPolicyUrl, getFaqUrl } from '@bc/graphql-utils/src/user'
import { useSetUserLanguage } from '../hooks/set-user-language'
import * as FooterComponents from './footer-components'

export const Footer = () => {
    const t = useFormatMessage()
    const {
        appConfig: { footerCopyrightLink },
    } = useContext(PlatformConfigContext)
    const { appConfig } = useContext(PlatformConfigContext)
    const { me } = useContext(UserContext)

    const { setUserLanguage, currentLanguage } = useSetUserLanguage()

    const links = [
        {
            href: getFaqUrl(me?.meta),
            title: t('route:faq.label'),
        },
        {
            href: getImprintUrl(me?.meta),
            title: t('route:imprint.label'),
        },
        {
            href: getPrivacyPolicyUrl(me?.meta),
            title: t('route:data-privacy-policy.label'),
        },
    ]

    const languageOptions: OptionsType<SelectInputOption> = useMemo(
        () =>
            appConfig.languages.map((language: Language) => ({
                value: language,
                label: languageLabels[language],
            })),
        [appConfig],
    )

    const selectedLanguageOption = selectFindOption(languageOptions, currentLanguage)

    const handleLanguageChange = (option: SelectInputOption) => setUserLanguage(option.value as Language)

    return (
        <FooterComponents.Container>
            <FooterComponents.LinkWrapper>
                <ButtonAnchor href={footerCopyrightLink} target="_blank" rel="noopener noreferrer">
                    {t('login:brenntag')}
                </ButtonAnchor>
                <ButtonLink to={t('route:terms-of-usage')}>{t('route:terms-of-usage.label')}</ButtonLink>
                {links.map(link => (
                    <ButtonAnchor key={link.title} href={link.href} target="_blank" rel="noopener">
                        {link.title}
                    </ButtonAnchor>
                ))}
                <FooterComponents.LanguageSelectorContainer>
                    <SelectInput
                        onChange={handleLanguageChange}
                        value={selectedLanguageOption}
                        options={languageOptions}
                        classNamePrefix="select-language-dropdown"
                        data-test-id="select-language-dropdown"
                        menuPlacement="top"
                    />
                </FooterComponents.LanguageSelectorContainer>
            </FooterComponents.LinkWrapper>

            <FooterBrenntagLogo />
        </FooterComponents.Container>
    )
}
