import { useContext } from 'react'
import { FormattedDate, FormattedMessage, FormattedNumber, MessageValue } from 'react-intl'

import { config } from '@bc/config/src'
import { Language, MessageMap, RegionCode } from '@bc/translations'
import { IntlContext, UserContext } from '@frontend/context'
import { getLocalizedNumberParser } from '@frontend/utils'

import { makeFormatNumber } from './format'
interface MessageDescriptor {
    id: keyof MessageMap
    defaultMessage?: string
    description?: string
}

export type FormatMessage = (
    id: FormattedMessage.MessageDescriptor['id'],
    messageDescriptor?: MessageDescriptor,
    values?: { [key: string]: MessageValue },
) => string

export type FormatCurrency = (value: number, options?: FormattedNumber.PropsBase) => string
export type FormatDate = (date: string, options?: FormattedDate.PropsBase) => string
export type FormatNumber = (value: number, options?: FormattedNumber.PropsBase) => string
export type FormatPackaging = (quantity: number, packagingName?: string) => string
export type FormatUom = (uom: string) => string

export const useFormatMessage = () => {
    const { formatMessage } = useContext(IntlContext)
    const formatMessageFn: FormatMessage = (id, messageDescriptor, values) =>
        formatMessage({ id, ...messageDescriptor }, values)
    return formatMessageFn
}

export const useFormatCurrency = () => {
    const { locale } = useContext(IntlContext)
    const { me } = useContext(UserContext)
    const region = me?.meta.region
    const regionConfig = config[region as RegionCode] || config.de
    const currencyDisplay = regionConfig.currencyDisplay ?? 'symbol'

    const formatNumberFn: FormatCurrency = (value, options) =>
        makeFormatNumber(locale)(value, {
            style: 'currency',
            currency: regionConfig.currency,
            currencyDisplay,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            ...options,
        })
    return formatNumberFn
}

// fix ie. 11 bug in date formatting
// react-intl on IE 11 insterts dot after day for nl locale, no way around it
export const useFormatDate = () => {
    const { formatDate } = useContext(IntlContext)
    const formatDateFn: FormatDate = date => {
        const day = formatDate(date, {
            day: '2-digit',
        }).replace('.', '')

        const month = formatDate(date, {
            month: 'short',
        })

        const year = formatDate(date, {
            year: 'numeric',
        })

        return `${day} ${month} ${year}`
    }

    return formatDateFn
}

export const useFormatTime = () => {
    const { formatDate } = useContext(IntlContext)
    const formatDateFn: FormatDate = date =>
        formatDate(date, {
            hour: '2-digit',
            minute: '2-digit',
        })
    return formatDateFn
}

export const useFormatNumber = () => {
    const { locale } = useContext(IntlContext)

    const formatNumberFn: FormatNumber = (value, options) =>
        makeFormatNumber(locale)(value, { minimumFractionDigits: 0, maximumFractionDigits: 2, ...options })

    return formatNumberFn
}

export const useParseNumber = () => {
    const formatNumber = useFormatNumber()

    return getLocalizedNumberParser(formatNumber)
}

export const useParseFloatNumber = () => (value: string | number) =>
    typeof value === 'string' ? parseFloat(value.replace(/,/, '.')) : value

export const useFormatPackaging = () => {
    const t = useFormatMessage()

    const formatPackagingFn: FormatPackaging = (quantity: number, packagingType?: string) =>
        packagingType ? (quantity > 1 ? t(`package-pl:${packagingType}`) : t(`package:${packagingType}`)) : ''

    return formatPackagingFn
}

export const useFormatUom = () => {
    const { me } = useContext(UserContext)
    const region = me?.meta.region
    const language: Language = (me?.meta.language ?? 'en') as Language
    const regionConfig = config[region as RegionCode] || config.de

    const { uomMapper } = regionConfig
    const map = uomMapper[language]

    const formatUomFn: FormatUom = (uom: string) => (map && map(uom)) || uom
    return formatUomFn
}
