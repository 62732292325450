import React, { useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { useApolloClient } from '@apollo/react-hooks'
import { ApolloQueryResult } from 'apollo-client'

import { GQLBaseOrderLine, GQLCustomer, GQLMainSalesArea } from '@bc/types'
import { colors, Dropdown, SvgIcon, Text } from '@bc/ui'

import { SessionContext, SessionContextData, ShoppingCartContext, ShoppingCartContextData } from '@frontend/context'

import { FormatMessage } from '../hooks'
import { CustomerLi } from './customer-li'
import * as NavComponents from './navigation-components'

interface LocalProps {
    customers: GQLCustomer[]
    currentCustomer?: GQLCustomer
    activeSalesAreaId?: string
    open?: boolean
    t: FormatMessage
    showIntro: boolean
    userHasMultipleCustomerEntities: boolean
    children: JSX.Element
}

type CombinedProps = LocalProps & RouteComponentProps<{ page: string }>

const NavigationCustomerSelectionComponent = ({
    currentCustomer,
    customers,
    t,
    activeSalesAreaId,
    history,
    open = false,
    showIntro,
    userHasMultipleCustomerEntities,
    children,
}: CombinedProps) => {
    const client = useApolloClient()
    const [shoppingStore]: ShoppingCartContextData<GQLBaseOrderLine> = useContext(ShoppingCartContext)
    const [sessionStore]: SessionContextData = useContext(SessionContext)

    const handleSelectCustomer = async (customer: GQLCustomer, mainSalesArea: GQLMainSalesArea) => {
        sessionStore.setActiveCustomer({
            cid: customer.id,
            so: mainSalesArea.salesOrganisation,
            sid: mainSalesArea.salesAreaId,
        })

        shoppingStore.clear()
        await resetStore()

        history.push(t('route:order-history'), { page: 1, sort: '-baseOrderDate' })
    }

    const resetStore = async (): Promise<ApolloQueryResult<any>[]> => {
        await client.cache.reset()
        return client.reFetchObservableQueries()
    }

    const dropdownList = (
        <NavComponents.CompanyDropdown>
            {showIntro && (
                <NavComponents.Intro>
                    <Text h3 noMargin color={'deepPurple'}>
                        {t('company:selection:title')}
                    </Text>
                    <Text p color={'neutral90'}>
                        {t('company:selection:intro.one')}
                        <br />
                        {t('company:selection:intro.two')}
                    </Text>
                </NavComponents.Intro>
            )}
            {customers.map((customer: GQLCustomer) => (
                <CustomerLi
                    key={customer.id}
                    t={t}
                    activeSalesAreaId={activeSalesAreaId}
                    currentCustomer={currentCustomer}
                    customer={customer}
                    onClick={handleSelectCustomer}
                />
            ))}
        </NavComponents.CompanyDropdown>
    )

    return userHasMultipleCustomerEntities ? (
        <NavComponents.RightDropdownWrapper>
            <Dropdown open={open} listPadding={false} dropdownList={dropdownList}>
                {({ getToggleButtonProps }) => (
                    <NavComponents.ToggleButton {...getToggleButtonProps()} buttonSize="small">
                        {children}
                        <SvgIcon size="20" icon="Chevron_Down" fill={colors.neutral90} />
                    </NavComponents.ToggleButton>
                )}
            </Dropdown>
        </NavComponents.RightDropdownWrapper>
    ) : (
        children
    )
}

export const NavigationCustomerSelection = withRouter(NavigationCustomerSelectionComponent)
