import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Social_LinkedIn: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Social_LinkedIn" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M3.62952 5.86018C3.62293 5.61142 3.66947 5.36411 3.76604 5.13477C3.86261 4.90542 4.00698 4.69931 4.18952 4.53018C4.59256 4.17009 5.11942 3.98014 5.65952 4.00018C5.92193 3.98804 6.18417 4.02772 6.43125 4.11694C6.67832 4.20617 6.90541 4.34319 7.09952 4.52019C7.28851 4.69756 7.4372 4.91345 7.53554 5.15326C7.63388 5.39307 7.67956 5.6512 7.66951 5.91019C7.67551 6.15205 7.62953 6.39238 7.53464 6.61493C7.43975 6.83748 7.29818 7.03706 7.11951 7.20018C6.91831 7.38232 6.6831 7.52291 6.4274 7.61386C6.1717 7.70482 5.90055 7.74434 5.62952 7.73018C5.36607 7.7444 5.10249 7.70473 4.85489 7.6136C4.6073 7.52247 4.38089 7.3818 4.18952 7.20018C4.00722 7.02874 3.86313 6.82078 3.76663 6.58989C3.67014 6.359 3.62342 6.11036 3.62952 5.86018ZM3.83951 20.0002V9.20018H7.42951V20.0002H3.83951ZM9.40952 20.0002H12.9995V14.0002C12.9891 13.7047 13.0332 13.4098 13.1295 13.1302C13.2753 12.7657 13.513 12.4453 13.8195 12.2002C14.1462 11.9422 14.5534 11.8077 14.9695 11.8202C16.1795 11.8202 16.7795 12.6302 16.7795 14.2602V20.0002H20.3695V13.8102C20.4606 12.5014 20.0573 11.206 19.2395 10.1802C18.8586 9.76985 18.393 9.4473 17.8749 9.23491C17.3569 9.02252 16.7989 8.92536 16.2395 8.95018C15.5874 8.93184 14.9423 9.08812 14.3709 9.40283C13.7995 9.71755 13.3226 10.1793 12.9895 10.7402V9.20018H9.39951C9.39951 9.55018 9.39951 10.6202 9.39951 12.4202C9.39951 14.2202 9.43952 16.7502 9.40952 20.0002Z"
                fill={fill === 'gradient' ? 'url(#Social_LinkedIn_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Social_LinkedIn" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Social_LinkedIn" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M3.62952 5.86018C3.62293 5.61142 3.66947 5.36411 3.76604 5.13477C3.86261 4.90542 4.00698 4.69931 4.18952 4.53018C4.59256 4.17009 5.11942 3.98014 5.65952 4.00018C5.92193 3.98804 6.18417 4.02772 6.43125 4.11694C6.67832 4.20617 6.90541 4.34319 7.09952 4.52019C7.28851 4.69756 7.4372 4.91345 7.53554 5.15326C7.63388 5.39307 7.67956 5.6512 7.66951 5.91019C7.67551 6.15205 7.62953 6.39238 7.53464 6.61493C7.43975 6.83748 7.29818 7.03706 7.11951 7.20018C6.91831 7.38232 6.6831 7.52291 6.4274 7.61386C6.1717 7.70482 5.90055 7.74434 5.62952 7.73018C5.36607 7.7444 5.10249 7.70473 4.85489 7.6136C4.6073 7.52247 4.38089 7.3818 4.18952 7.20018C4.00722 7.02874 3.86313 6.82078 3.76663 6.58989C3.67014 6.359 3.62342 6.11036 3.62952 5.86018ZM3.83951 20.0002V9.20018H7.42951V20.0002H3.83951ZM9.40952 20.0002H12.9995V14.0002C12.9891 13.7047 13.0332 13.4098 13.1295 13.1302C13.2753 12.7657 13.513 12.4453 13.8195 12.2002C14.1462 11.9422 14.5534 11.8077 14.9695 11.8202C16.1795 11.8202 16.7795 12.6302 16.7795 14.2602V20.0002H20.3695V13.8102C20.4606 12.5014 20.0573 11.206 19.2395 10.1802C18.8586 9.76985 18.393 9.4473 17.8749 9.23491C17.3569 9.02252 16.7989 8.92536 16.2395 8.95018C15.5874 8.93184 14.9423 9.08812 14.3709 9.40283C13.7995 9.71755 13.3226 10.1793 12.9895 10.7402V9.20018H9.39951C9.39951 9.55018 9.39951 10.6202 9.39951 12.4202C9.39951 14.2202 9.43952 16.7502 9.40952 20.0002Z"
                fill={fill === 'gradient' ? 'url(#Social_LinkedIn_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Social_LinkedIn" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Social_LinkedIn" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M3.62952 5.86018C3.62293 5.61142 3.66947 5.36411 3.76604 5.13477C3.86261 4.90542 4.00698 4.69931 4.18952 4.53018C4.59256 4.17009 5.11942 3.98014 5.65952 4.00018C5.92193 3.98804 6.18417 4.02772 6.43125 4.11694C6.67832 4.20617 6.90541 4.34319 7.09952 4.52019C7.28851 4.69756 7.4372 4.91345 7.53554 5.15326C7.63388 5.39307 7.67956 5.6512 7.66951 5.91019C7.67551 6.15205 7.62953 6.39238 7.53464 6.61493C7.43975 6.83748 7.29818 7.03706 7.11951 7.20018C6.91831 7.38232 6.6831 7.52291 6.4274 7.61386C6.1717 7.70482 5.90055 7.74434 5.62952 7.73018C5.36607 7.7444 5.10249 7.70473 4.85489 7.6136C4.6073 7.52247 4.38089 7.3818 4.18952 7.20018C4.00722 7.02874 3.86313 6.82078 3.76663 6.58989C3.67014 6.359 3.62342 6.11036 3.62952 5.86018ZM3.83951 20.0002V9.20018H7.42951V20.0002H3.83951ZM9.40952 20.0002H12.9995V14.0002C12.9891 13.7047 13.0332 13.4098 13.1295 13.1302C13.2753 12.7657 13.513 12.4453 13.8195 12.2002C14.1462 11.9422 14.5534 11.8077 14.9695 11.8202C16.1795 11.8202 16.7795 12.6302 16.7795 14.2602V20.0002H20.3695V13.8102C20.4606 12.5014 20.0573 11.206 19.2395 10.1802C18.8586 9.76985 18.393 9.4473 17.8749 9.23491C17.3569 9.02252 16.7989 8.92536 16.2395 8.95018C15.5874 8.93184 14.9423 9.08812 14.3709 9.40283C13.7995 9.71755 13.3226 10.1793 12.9895 10.7402V9.20018H9.39951C9.39951 9.55018 9.39951 10.6202 9.39951 12.4202C9.39951 14.2202 9.43952 16.7502 9.40952 20.0002Z"
                fill={fill === 'gradient' ? 'url(#Social_LinkedIn_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Social_LinkedIn" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Social_LinkedIn" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M3.62952 5.86018C3.62293 5.61142 3.66947 5.36411 3.76604 5.13477C3.86261 4.90542 4.00698 4.69931 4.18952 4.53018C4.59256 4.17009 5.11942 3.98014 5.65952 4.00018C5.92193 3.98804 6.18417 4.02772 6.43125 4.11694C6.67832 4.20617 6.90541 4.34319 7.09952 4.52019C7.28851 4.69756 7.4372 4.91345 7.53554 5.15326C7.63388 5.39307 7.67956 5.6512 7.66951 5.91019C7.67551 6.15205 7.62953 6.39238 7.53464 6.61493C7.43975 6.83748 7.29818 7.03706 7.11951 7.20018C6.91831 7.38232 6.6831 7.52291 6.4274 7.61386C6.1717 7.70482 5.90055 7.74434 5.62952 7.73018C5.36607 7.7444 5.10249 7.70473 4.85489 7.6136C4.6073 7.52247 4.38089 7.3818 4.18952 7.20018C4.00722 7.02874 3.86313 6.82078 3.76663 6.58989C3.67014 6.359 3.62342 6.11036 3.62952 5.86018ZM3.83951 20.0002V9.20018H7.42951V20.0002H3.83951ZM9.40952 20.0002H12.9995V14.0002C12.9891 13.7047 13.0332 13.4098 13.1295 13.1302C13.2753 12.7657 13.513 12.4453 13.8195 12.2002C14.1462 11.9422 14.5534 11.8077 14.9695 11.8202C16.1795 11.8202 16.7795 12.6302 16.7795 14.2602V20.0002H20.3695V13.8102C20.4606 12.5014 20.0573 11.206 19.2395 10.1802C18.8586 9.76985 18.393 9.4473 17.8749 9.23491C17.3569 9.02252 16.7989 8.92536 16.2395 8.95018C15.5874 8.93184 14.9423 9.08812 14.3709 9.40283C13.7995 9.71755 13.3226 10.1793 12.9895 10.7402V9.20018H9.39951C9.39951 9.55018 9.39951 10.6202 9.39951 12.4202C9.39951 14.2202 9.43952 16.7502 9.40952 20.0002Z"
                fill={fill === 'gradient' ? 'url(#Social_LinkedIn_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Social_LinkedIn" />}
        </g>
    ),
}
