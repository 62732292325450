import React from 'react'

import { FullWidthTitleNotificationTemplate, MercuryTemplate, useDocumentTitle } from '@bc/ui'
import { SearchCustomer, useFormatMessage } from '@frontend/components'

import { GQLCustomer, GQLMainSalesArea } from '@bc/types'

interface MyCompaniesPageProps {
    onSelectCustomer: (customer: GQLCustomer, mainSalesArea: GQLMainSalesArea) => void
}

export const MyCompaniesSearchPage = ({ onSelectCustomer }: MyCompaniesPageProps) => {
    const t = useFormatMessage()
    const pageTitle = t('my-customers:headline')
    useDocumentTitle(pageTitle)
    return (
        <MercuryTemplate>
            {{
                content: (
                    <FullWidthTitleNotificationTemplate title={pageTitle}>
                        <SearchCustomer t={t} onSelectCustomer={onSelectCustomer} />
                    </FullWidthTitleNotificationTemplate>
                ),
            }}
        </MercuryTemplate>
    )
}
