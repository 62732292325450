import Bowser from 'bowser'

import { Request } from 'express'

export const supportedBrowser = (req: Request) => {
    // const supported = check({ msie: '11' }, false, req.get('User-Agent'))
    const browser = Bowser.getParser(String(req.get('User-Agent')))
    return !browser.satisfies({
        'internet explorer': '<11',
    })
}
