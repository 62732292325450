import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Print: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Print" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M16 6H18V2H6V6H8V4H16V6ZM22 18H16V15H8V18H2V8H11V10H4V16H6V13H18V16H20V10H13V8H22V18ZM17 20H7V22H17V20Z"
                fill={fill === 'gradient' ? 'url(#Print_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Print" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Print" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M16 6H18V2H6V6H8V4H16V6ZM22 18H16V15H8V18H2V8H11V10H4V16H6V13H18V16H20V10H13V8H22V18ZM17 20H7V22H17V20Z"
                fill={fill === 'gradient' ? 'url(#Print_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Print" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Print" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M16 6H18V2H6V6H8V4H16V6ZM22 18H16V15H8V18H2V8H11V10H4V16H6V13H18V16H20V10H13V8H22V18ZM17 20H7V22H17V20Z"
                fill={fill === 'gradient' ? 'url(#Print_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Print" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Print" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M16 6H18V2H6V6H8V4H16V6ZM22 18H16V15H8V18H2V8H11V10H4V16H6V13H18V16H20V10H13V8H22V18ZM17 20H7V22H17V20Z"
                fill={fill === 'gradient' ? 'url(#Print_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Print" />}
        </g>
    ),
}
