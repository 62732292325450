import { ApolloError } from 'apollo-client'
import React, { useContext, useEffect, useState } from 'react'

import { OrderStatus, ShopFeatures } from '@bc/config'
import { tagManager } from '@bc/gtm'
import { GQLBaseOrder, GQLBaseOrderLine, GQLLocation, GQLMaterial, GQLPagination, GQLBaseOrderFilters } from '@bc/types'
import { FullWidthTitleNotificationTemplate, MercuryTemplate, Paginator, useDocumentTitle } from '@bc/ui'
import {
    AddToCartModal,
    QueryResultList,
    ReorderModal,
    SortSelectWrapper,
    useCopyToClipboard,
    useFormatMessage,
} from '@frontend/components'
import { OrderCardBase } from '@frontend/components/cards'
import { Feature } from '@frontend/components/customer'
import { BaseOrderFilters } from '@frontend/components/filters/base-order-filters'
import { UserContext, FiltersContextData, FiltersContext, getFilterCount } from '@frontend/context'
import { getLocationOrigin, trimLeadingZeros } from '@frontend/utils'

interface MyOrdersBasePageProps {
    shipTos: GQLLocation[]
    loading: boolean
    error: ApolloError | undefined
    pagination: GQLPagination
    orders: GQLBaseOrder[] | undefined
    orderStatuses: OrderStatus[]
    onPageClick: (page: number) => void
}

export const MyOrdersBasePage = ({
    shipTos,
    error,
    orders,
    pagination,
    onPageClick,
    orderStatuses,
}: MyOrdersBasePageProps) => {
    const t = useFormatMessage()
    useDocumentTitle(t('order-history:headline'))
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState<GQLBaseOrder | undefined>()
    const [selectedOrderline, setSelectedOrderline] = useState<GQLBaseOrderLine | undefined>()
    const copyToClipboard = useCopyToClipboard()
    const { activeCustomerId = '', activeSalesOrganisation = '', activeSalesAreaId = '' } = useContext(UserContext)
    const filterContext: FiltersContextData<GQLBaseOrderFilters> = useContext(FiltersContext)

    const copyOrderLink = (orderIdToCopy: string) => {
        const { location } = window
        const origin = getLocationOrigin(location)
        copyToClipboard(
            `${origin}${location.pathname}?orderId=${trimLeadingZeros(
                orderIdToCopy,
            )}&cid=${activeCustomerId}&so=${activeSalesOrganisation}&sid=${activeSalesAreaId}`,
        )
    }

    const openOrderConfirmation = (uri: string): void => {
        window.open(uri, '_blank')
    }

    const onModalClose = () => {
        setModalOpen(false)
        setSelectedOrder(undefined)
        setSelectedOrderline(undefined)
    }

    const onReorder = (order: GQLBaseOrder | undefined, orderLine: GQLBaseOrderLine | undefined) => {
        setSelectedOrder(order)
        setSelectedOrderline(orderLine)
        setModalOpen(true)
    }

    useEffect(() => {
        fireTag(orders)
    }, [orders])

    const fireTag = (baseOrders: GQLBaseOrder[] = []) => {
        const products: GQLMaterial[] = []
        baseOrders.map(
            order =>
                order.orderLines &&
                order.orderLines.map(orderLine => orderLine && orderLine.material && products.push(orderLine.material)),
        )

        if (products.length) {
            tagManager.tags.productImpressions(products, 'product list')
        }
    }

    return (
        <>
            <Feature
                flag={ShopFeatures.ShoppingCart}
                on={
                    <AddToCartModal
                        isOpen={modalOpen}
                        order={selectedOrder}
                        orderLine={selectedOrderline}
                        onClose={onModalClose}
                    />
                }
                off={
                    <ReorderModal
                        isOpen={modalOpen}
                        order={selectedOrder}
                        orderLine={selectedOrderline}
                        onClose={onModalClose}
                    />
                }
            />
            <MercuryTemplate title={t('general:filters')} filterCount={getFilterCount(filterContext?.query)}>
                {{
                    sidebarContent: <BaseOrderFilters shipTos={shipTos} orderStatuses={orderStatuses} />,
                    content: (
                        <FullWidthTitleNotificationTemplate title={t('order-history:headline')}>
                            <SortSelectWrapper />
                            <QueryResultList
                                error={error}
                                amountOfResults={orders?.length}
                                noResultText={t('not-found:orders')}>
                                {orders?.map(order => (
                                    <OrderCardBase
                                        order={order}
                                        key={order.id}
                                        onOpenConfirmation={openOrderConfirmation}
                                        onLinkCopy={copyOrderLink}
                                        reorder={onReorder}
                                    />
                                ))}
                            </QueryResultList>
                            {!error && (
                                <Paginator
                                    maxPage={pagination.pages}
                                    currentPage={pagination.page}
                                    onPageClick={onPageClick}
                                />
                            )}
                        </FullWidthTitleNotificationTemplate>
                    ),
                }}
            </MercuryTemplate>
        </>
    )
}
