import React, { useContext, useState, useMemo, useCallback } from 'react'

import { ApolloError } from 'apollo-client'
import styled from 'styled-components'

import { Resources } from '@bc/config'
import { GQLCreateUserInput, GQLPagination, GQLUpdateUserInput, GQLUser, GQLRoleType, GQLUserFilters } from '@bc/types'
import { Button, FullWidthTemplate, media, MercuryTemplate, PageTitle, spacing, SelectInputOption } from '@bc/ui'
import {
    EditUserModal,
    EditUserModalProps,
    EmailConfirmModal,
    QueryResultList,
    SortSelectWrapper,
    useFormatMessage,
    useHasAccess,
    UserCard,
    UserManagementFilters,
} from '@frontend/components'
import { SearchCountWarning } from '@frontend/components/info-tip'
import { NotificationBanner } from '@frontend/components/notifications'
import {
    ToastsContext,
    ToastsContextData,
    UserContext,
    getFilterCount,
    FiltersContext,
    FiltersContextData,
} from '@frontend/context'
import { OptionsType } from 'react-select'

type SendEmailFunction = (selectedContact: GQLUser, unreachable?: boolean) => void
interface Props {
    error: ApolloError | undefined
    pagination?: GQLPagination
    users: GQLUser[] | undefined
    pageTitle: string
    onPageClick?: (page: number) => void
    onSendPasswordEmailClick: SendEmailFunction
    onSendWelcomeEmailClick: SendEmailFunction
    onUpdateUser: (user: GQLUser, values: GQLUpdateUserInput) => Promise<void>
    onCreateUser: (values: GQLUpdateUserInput) => Promise<void>
    onDeleteUser: (selectedContact: GQLUser) => Promise<void>
    onEmailAvailable: (email: string) => Promise<string | undefined>
    loading: boolean
    limit?: number
    count?: number
}

// eslint-disable-next-line no-shadow
enum EmailTypes {
    welcome = 'welcome',
    pwreset = 'pwreset',
}

const PageHeader = styled.div`
    position: relative;
    justify-content: flex-end;
    ${media.max('sm')} {
        flex-direction: column;
    }
`

const Wrapper = styled.div`
    display: flex;
    gap: ${spacing.sm}px;
`

export const UserManagementPage = ({
    error,
    users,
    pageTitle,
    onCreateUser,
    onDeleteUser,
    onUpdateUser,
    onEmailAvailable,
    onSendWelcomeEmailClick,
    onSendPasswordEmailClick,
    loading,
    limit = Number.MAX_SAFE_INTEGER,
    count = 0,
}: Props) => {
    const t = useFormatMessage()
    const { me } = useContext(UserContext)
    const isCurrentUserAdmin = GQLRoleType.admin === me?.meta.role?.type

    const [emailConfirmModalOpen, setEmailConfirmModalOpen] = useState(false)
    const [userModalOpen, setUserModalOpen] = useState(false)
    const [activeEmailType, setActiveEmailType] = useState<EmailTypes>()
    const [selectedContact, setSelectedContact] = useState<GQLUser>()
    const [unreachableUser, setUnreachableUser] = useState<boolean>(false)
    const [toastsStore]: ToastsContextData = useContext(ToastsContext)
    const filterContext: FiltersContextData<GQLUserFilters> = useContext(FiltersContext)

    const hasAccess = useHasAccess()
    const canEditContacts = hasAccess(Resources.EditUsers)

    const emailActionHandler: (option: SelectInputOption, user: GQLUser) => void = useCallback(
        ({ value }: SelectInputOption, user: GQLUser) => {
            const actionForUnreachableUser = Number(value) >= 2
            switch (Number(value)) {
                case 0:
                case 2: {
                    openEmailConfirmModal(user, EmailTypes.welcome, actionForUnreachableUser)
                    break
                }
                case 1:
                case 3: {
                    openEmailConfirmModal(user, EmailTypes.pwreset, actionForUnreachableUser)
                    break
                }
            }
        },
        [],
    )

    const emailActionOptions: OptionsType<SelectInputOption> = useMemo(() => {
        const actions = [t('user:email-welcome'), t('user:email-password')]
        if (isCurrentUserAdmin) {
            actions.push(t('user:email-welcome-to-support'), t('user:email-password-to-support'))
        }
        return actions.map((action: string, index: number) => ({
            value: index.toString(),
            label: action,
        }))
    }, [t, isCurrentUserAdmin])

    const openUserModal = (contact?: GQLUser): void => {
        setUserModalOpen(true)
        setSelectedContact(contact)
    }

    const closeModal = () => {
        setUserModalOpen(false)
        setSelectedContact(undefined)
    }

    const handleCreateUser = async (values: GQLCreateUserInput) => {
        try {
            await onCreateUser(values)
        } catch (err1) {
            handleError(err1)
        }

        closeModal()
    }

    const handleDeleteUser = async () => {
        if (!selectedContact) {
            return
        }

        try {
            await onDeleteUser(selectedContact)
        } catch (err2) {
            handleError(err2)
        }

        closeModal()
    }

    const handleEditUser = async (values: GQLUpdateUserInput) => {
        if (!selectedContact) {
            return
        }

        try {
            await onUpdateUser(selectedContact, values)
        } catch (err3) {
            handleError(err3)
        }

        closeModal()
    }

    const handleError = (err4: string) => {
        toastsStore.addToast({
            type: 'error',
            message: err4,
        })
    }

    const handleCloseEditUser = () => {
        setUserModalOpen(false)
        setSelectedContact(undefined)
    }

    const editUserModalProps = useMemo<Partial<EditUserModalProps>>(
        () =>
            selectedContact
                ? {
                      type: 'edit',
                      contact: selectedContact,
                      onConfirm: handleEditUser,
                      onDelete: handleDeleteUser,
                  }
                : {
                      type: 'create',
                      onConfirm: handleCreateUser,
                  },
        [selectedContact],
    )

    const handleEmailClose = (): void => {
        setEmailConfirmModalOpen(false)
        setSelectedContact(undefined)
        setUnreachableUser(false)
    }

    const handleEmailConfirm = (): void => {
        if (!selectedContact) {
            throw new Error('no selectedContact set: ' + activeEmailType)
        }

        switch (activeEmailType) {
            case EmailTypes.welcome:
                onSendWelcomeEmailClick(selectedContact, unreachableUser)
                break
            case EmailTypes.pwreset:
                onSendPasswordEmailClick(selectedContact, unreachableUser)
                break
        }
        handleEmailClose()
    }

    const openEmailConfirmModal = (contact: GQLUser, type: EmailTypes, unreachable?: boolean): void => {
        setEmailConfirmModalOpen(true)
        setActiveEmailType(type)
        setSelectedContact(contact)
        setUnreachableUser(Boolean(unreachable))
    }

    return (
        <>
            <MercuryTemplate title={t('general:filters')} filterCount={getFilterCount(filterContext?.query)}>
                {{
                    sidebarContent: <UserManagementFilters />,
                    content: (
                        <FullWidthTemplate>
                            <PageHeader>
                                <NotificationBanner />
                                <PageTitle title={pageTitle}>
                                    {canEditContacts && (
                                        <Wrapper>
                                            <Button
                                                onClick={() => openUserModal()}
                                                variant="action"
                                                icon="User_Add"
                                                title={t('user:create-new-user')}
                                                data-test-id="button-create-user">
                                                {t('user:create-new-user')}
                                            </Button>
                                        </Wrapper>
                                    )}
                                </PageTitle>
                            </PageHeader>

                            <SortSelectWrapper />
                            {users && <SearchCountWarning count={count} limit={limit} />}
                            <QueryResultList
                                error={error}
                                amountOfResults={users?.length}
                                noResultText={t('not-found:users')}
                                chunkSize={10}>
                                {users?.slice(0, limit).map(user => (
                                    <UserCard
                                        key={user.id}
                                        user={user}
                                        readOnly={!canEditContacts}
                                        onEditUser={() => openUserModal(user)}
                                        onEmailAction={option => emailActionHandler(option, user)}
                                        emailActionOptions={emailActionOptions}
                                    />
                                ))}
                            </QueryResultList>
                        </FullWidthTemplate>
                    ),
                }}
            </MercuryTemplate>
            <EmailConfirmModal
                t={t}
                open={emailConfirmModalOpen}
                type={activeEmailType}
                onConfirm={handleEmailConfirm}
                onClose={handleEmailClose}
                unreachable={unreachableUser}
                contact={selectedContact}
            />
            <EditUserModal
                open={userModalOpen}
                onClose={handleCloseEditUser}
                t={t}
                loading={loading}
                validateEmailAvailable={onEmailAvailable}
                {...(editUserModalProps as EditUserModalProps)}
            />
        </>
    )
}
