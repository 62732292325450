import React from 'react'

import { colors } from '@bc/ui/src/config'
interface HeaderBrenntagLogoProps extends React.HTMLProps<SVGSVGElement> {
    startGradientColor?: string
    endGradientColor?: string
    mobile?: boolean
    fill?: string
}
export const HeaderBrenntagLogo: React.SFC<HeaderBrenntagLogoProps> = ({
    mobile = false,
    fill = colors.deepPurple,
    startGradientColor = colors.violet50,
    endGradientColor = colors.blue50,
    ...props
}) => (
    <svg
        height="48"
        viewBox={`0 0 ${mobile ? '48' : '203'} 48`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M34.461,24.02c-1.536,1.918-3.798,3.755-6.549,4.842c1.656,2.04,2.492,4.312,1.889,6.674
            c-0.575,2.255-2.731,4.456-5.8,4.456H8V27.994h14c7.72,0,14-6.279,14-13.997C36,6.279,29.72,0,22,0H0v17.996h8V7.998h14
            c3.31,0,6,2.689,6,5.999s-2.69,5.999-6,5.999H0V47.99V48h24c7.72,0,14-6.279,14-13.997C38,30.579,37.037,26.735,34.461,24.02z"
            fill="url(#header_brenntag_gradient)"
        />
        <linearGradient id="header_brenntag_gradient" gradientUnits="userSpaceOnUse" x1="-5" y1="44" x2="33" y2="5">
            <stop stopColor={startGradientColor} />
            <stop offset="1" stopColor={endGradientColor} />
        </linearGradient>

        {!mobile && (
            <g fillRule="evenodd" clipRule="evenodd">
                <path
                    fill={fill}
                    d="M55.878,24.19c0-7.207,4.669-12.215,11.775-12.215c5.854,0,9.914,3.384,10.76,8.831h-3.417
		c-0.609-3.756-3.553-5.854-7.343-5.854c-5.075,0-8.323,3.722-8.323,9.237c0,5.481,3.248,9.169,8.323,9.169
		c3.79,0,6.767-2.132,7.376-5.921h3.451c-0.879,5.481-4.94,8.898-10.827,8.898C60.547,36.336,55.878,31.363,55.878,24.19z"
                />
                <path
                    fill={fill}
                    d="M82.484,27.438c0-5.177,3.857-8.933,8.933-8.933c5.075,0,8.933,3.756,8.933,8.933
		c0,5.143-3.857,8.898-8.933,8.898C86.342,36.336,82.484,32.581,82.484,27.438z M97.135,27.438c0-3.925-2.132-6.26-5.718-6.26
		c-3.553,0-5.684,2.335-5.684,6.26c0,3.958,2.132,6.259,5.684,6.259C95.004,33.697,97.135,31.396,97.135,27.438z"
                />
                <path
                    fill={fill}
                    d="M121.271,25.273v10.726h-3.181v-10.32c0-2.876-1.726-4.601-4.398-4.601c-2.673,0-4.839,1.658-4.839,5.481
		v9.441h-3.147V18.844h2.977l0.135,3.248c1.116-2.504,3.316-3.586,5.921-3.586C118.835,18.505,121.271,21.077,121.271,25.273z"
                />
                <path
                    fill={fill}
                    d="M142.476,25.273v10.726h-3.18v-10.32c0-2.876-1.726-4.601-4.399-4.601c-2.672,0-4.838,1.658-4.838,5.481v9.441
		h-3.147V18.844h2.977l0.136,3.248c1.116-2.504,3.315-3.586,5.921-3.586C140.04,18.505,142.476,21.077,142.476,25.273z"
                />
                <path
                    fill={fill}
                    d="M164.797,28.182h-13.636c0.203,3.756,2.165,5.718,5.515,5.718c2.606,0,4.501-1.183,4.974-3.485h3.079
		c-0.744,3.757-3.823,5.921-8.053,5.921c-5.447,0-8.797-3.417-8.797-8.898c0-5.346,3.451-8.933,8.628-8.933
		C162.159,18.505,165.034,22.227,164.797,28.182z M151.23,25.949h10.454c-0.338-3.384-2.233-5.007-5.109-5.007
		C153.564,20.942,151.636,22.633,151.23,25.949z"
                />
                <path
                    fill={fill}
                    d="M169.467,27.438c0-5.346,3.688-8.933,8.864-8.933c4.839,0,7.985,2.707,8.426,6.868h-3.181
		c-0.406-2.673-2.3-4.196-5.176-4.196c-3.654,0-5.685,2.267-5.685,6.26s2.031,6.259,5.685,6.259c2.977,0,4.905-1.59,5.245-4.501
		h3.18c-0.406,4.467-3.722,7.139-8.493,7.139C173.156,36.336,169.467,32.75,169.467,27.438z"
                />
                <path
                    fill={fill}
                    d="M203,33.427v2.537c-1.658,0.136-2.876,0.136-4.635,0.136c-2.91,0-4.365-1.252-4.365-3.417V21.415h-3.417
		v-2.572h0.507c1.997,0,2.91-0.677,2.91-2.741V13.87h3.18v4.974h5.719v2.572h-5.719v11.843c0,0.135,0.034,0.169,0.17,0.169H203z"
                />
            </g>
        )}
    </svg>
)
