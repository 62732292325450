import { ApolloError } from 'apollo-client'
import React, { ReactNode, useEffect, useRef, useState } from 'react'

import { Card, Text, ScrollLoader, AnimatedList } from '@bc/ui'
import { PageError } from '@frontend/components/errors'
import { useFormatMessage } from '@frontend/components/hooks'

import { GraphQLFormattedError } from 'graphql'
import { TooManyResultsError } from '../errors/too-many-results-error'
export interface QueryResultListProps {
    error: ApolloError | undefined
    amountOfResults: number | undefined
    noResultText: string
    chunkSize?: number
    children: React.ReactNode
}

// IE11/Edge seem to have some issues with [].slice() so i am implementing a custom version
const sliceArray = (array: any[], from: number | undefined = 0, toNot?: number) => {
    const slicedArray = []
    const to = toNot !== undefined ? Math.min(toNot, array.length) : array.length
    for (let i = from; i < to; i++) {
        slicedArray.push(array[i])
    }
    return slicedArray
}

export const QueryResultList = ({
    error,
    amountOfResults,
    noResultText,
    children,
    chunkSize = amountOfResults || 0,
}: QueryResultListProps) => {
    const t = useFormatMessage()

    const arrayChildren = useRef<ReactNode[]>([])
    const [state, setState] = useState({ current: [] as ReactNode[], more: [] as ReactNode[] })

    const displayMore = () => {
        setState(({ current, more }) => {
            const merged = [...current, ...more]
            return {
                current: merged,
                more: sliceArray(arrayChildren.current, merged.length, merged.length + chunkSize),
            }
        })
    }

    useEffect(() => {
        arrayChildren.current = React.Children.toArray(children)
        setState({ current: [], more: sliceArray(arrayChildren.current, 0, chunkSize) })
    }, [children, chunkSize])

    if (error) {
        const graphqlError: GraphQLFormattedError | undefined = error?.graphQLErrors[0]
        switch (graphqlError?.extensions?.code) {
            case 'TooManyResultsError': {
                return <TooManyResultsError error={graphqlError} />
            }
            default: {
                return <PageError title={t('general:error.label')} text={error.message} generic />
            }
        }
    }

    if (typeof amountOfResults === 'number') {
        return amountOfResults ? (
            <>
                {state.current}
                <AnimatedList>{state.more}</AnimatedList>
                <ScrollLoader
                    hasMore={arrayChildren.current.length > state.current.length + state.more.length}
                    offset={500}
                    callback={displayMore}
                />
            </>
        ) : (
            <Card theme="light">
                <Text p noMargin>
                    {noResultText}
                </Text>
            </Card>
        )
    }

    return null
}
