import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Calendar: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Calendar" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M8 2H6V4H3V6H21V4H18V2H16V4H8V2ZM3 21H21V12H19V19H5V10H21V8H3V21Z"
                fill={fill === 'gradient' ? 'url(#Calendar_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Calendar" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Calendar" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M8 2H6V4H3V6H21V4H18V2H16V4H8V2ZM3 21H21V12H19V19H5V10H21V8H3V21Z"
                fill={fill === 'gradient' ? 'url(#Calendar_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Calendar" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Calendar" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M8 2H6V4H3V6H21V4H18V2H16V4H8V2ZM3 21H21V12H19V19H5V10H21V8H3V21Z"
                fill={fill === 'gradient' ? 'url(#Calendar_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Calendar" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Calendar" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M8 2H6V4H3V6H21V4H18V2H16V4H8V2ZM3 21H21V12H19V19H5V10H21V8H3V21Z"
                fill={fill === 'gradient' ? 'url(#Calendar_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Calendar" />}
        </g>
    ),
}
