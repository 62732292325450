
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LocationAddressFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Location"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"address"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"country"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"street"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"buildingNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"floor"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"unit"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"city"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"postalCode"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":192}};
    doc.loc.source = {"body":"fragment LocationAddressFragment on Location {\n    address {\n        id\n        country\n        street\n        buildingNumber\n        floor\n        unit\n        city\n        postalCode\n    }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
