import { FieldIcon } from '@frontend/components/filters/filters-components'
import React, { useContext, useMemo } from 'react'
import { Field } from 'react-final-form'
import { OptionsType } from 'react-select'

import { OrderStatus } from '@bc/config'
import { GQLBaseOrderFilters, GQLLocation } from '@bc/types'
import {
    CheckboxGroup,
    colors,
    getLocationAddressLine,
    SelectInput,
    SelectInputOption,
    SvgIcon,
    TextInput,
    selectFindOption,
} from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import {
    finalFormValidations,
    NUM_CHAR_START_SEARCH,
    statusAsColor,
    statusAsIconName,
    statusAsTranslationKey,
} from '@frontend/utils'

import { FiltersContextData, FiltersContext } from '@frontend/context'
import { FiltersFormContainer } from './filters-form-container'

interface OrderFiltersProps {
    shipTos: GQLLocation[]
    orderStatuses: OrderStatus[]
}

export const BaseOrderFilters = ({ shipTos = [], orderStatuses }: OrderFiltersProps) => {
    const t = useFormatMessage()
    const { onFilterChange, filters, query, onLinkCopy }: FiltersContextData<GQLBaseOrderFilters> = useContext(
        FiltersContext,
    )
    const shipToOptions: OptionsType<SelectInputOption> = useMemo(
        () =>
            shipTos.map((location: GQLLocation) => ({
                value: location.id,
                label: getLocationAddressLine(location),
            })),
        [shipTos],
    )

    return (
        <FiltersFormContainer
            onFilterChange={onFilterChange}
            initialValues={filters}
            query={query}
            onLinkCopy={onLinkCopy}>
            <Field
                name="customerPoReference"
                render={({ input }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-po-reference')}
                        type="search"
                        placeholder={t('filters:search-by-po-reference.placeholder')}
                        data-test-id="input-search-po-reference"
                    />
                )}
            />
            <Field
                name="orderId"
                parse={(o: string) => o?.toUpperCase()}
                render={({ input }) => (
                    <TextInput
                        {...input}
                        labelText={t('general:order-id')}
                        type="search"
                        placeholder={t('general:order-id')}
                        data-test-id="input-search-order-id"
                    />
                )}
            />
            <Field
                name="materialName"
                validate={finalFormValidations.minLength(t, NUM_CHAR_START_SEARCH)}
                render={({ input, meta }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-material-name')}
                        type="search"
                        placeholder={t('filters:search-by-material-name.placeholder')}
                        hasError={meta.touched && meta.error}
                        errorText={meta.error}
                        data-test-id="input-search-material-name"
                    />
                )}
            />
            {shipTos.length > 1 && (
                <Field
                    name="shipToId"
                    parse={o => o?.value}
                    render={({ input }) => {
                        const { value, ...restInput } = input
                        const selectedOption = selectFindOption(shipToOptions, value)
                        return (
                            <SelectInput
                                {...restInput}
                                value={selectedOption}
                                labelText={t('filters:select-ship-to-address')}
                                placeholder={t('filters:select-an-address')}
                                IsSearchable
                                isClearable
                                options={shipToOptions}
                                noOptionsMessage={() => t('not-found:select')}
                                classNamePrefix="select-ship-to-address"
                                data-test-id="select-ship-to-address"
                            />
                        )
                    }}
                />
            )}
            <CheckboxGroup
                labelText={t('filters:order-status')}
                name="orderStatus"
                id={'order-filter-status'}
                options={orderStatuses}
                labelOptions={orderStatuses.map(status => (
                    <>
                        {t(statusAsTranslationKey(status))}
                        <FieldIcon>
                            <SvgIcon icon={statusAsIconName(status)} fill={colors[statusAsColor(status)]} size="24" />
                        </FieldIcon>
                    </>
                ))}
                colorScheme="deepPurple"
            />
        </FiltersFormContainer>
    )
}
