import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const ThumbsUp: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="ThumbsUp" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M6 20H19.41L22 17.41V7H14V3.59L12.41 2H8V6.7L6 9.7V16H8V10.3L10 7.3V4H11.59L12 4.41V9H20V16.59L18.59 18H6V20ZM2 10H4V20H2V10Z"
                fill={fill === 'gradient' ? 'url(#ThumbsUp_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ThumbsUp" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="ThumbsUp" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M6 20H19.41L22 17.41V7H14V3.59L12.41 2H8V6.7L6 9.7V16H8V10.3L10 7.3V4H11.59L12 4.41V9H20V16.59L18.59 18H6V20ZM2 10H4V20H2V10Z"
                fill={fill === 'gradient' ? 'url(#ThumbsUp_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ThumbsUp" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="ThumbsUp" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M6 20H19.41L22 17.41V7H14V3.59L12.41 2H8V6.7L6 9.7V16H8V10.3L10 7.3V4H11.59L12 4.41V9H20V16.59L18.59 18H6V20ZM2 10H4V20H2V10Z"
                fill={fill === 'gradient' ? 'url(#ThumbsUp_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ThumbsUp" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="ThumbsUp" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M6 20H19.41L22 17.41V7H14V3.59L12.41 2H8V6.7L6 9.7V16H8V10.3L10 7.3V4H11.59L12 4.41V9H20V16.59L18.59 18H6V20ZM2 10H4V20H2V10Z"
                fill={fill === 'gradient' ? 'url(#ThumbsUp_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ThumbsUp" />}
        </g>
    ),
}
