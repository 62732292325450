import { SettingsRegion } from '../types'
import { bnlConfig } from './config.bnl'

const config: SettingsRegion = {
    ...bnlConfig,
    privacyPolicyUrl: {
        nl: 'https://www.brenntag.com/nl-nl/privacy-reglement.html',
        fr: 'https://www.brenntag.com/fr-be/politique-de-confidentialite.html',
        en: 'https://www.brenntag.com/en-nl/privacy-policy.html',
    },
    imprintUrl: {
        nl: 'https://www.brenntag.com/nl-nl/imprint.html',
        fr: 'https://www.brenntag.com/fr-be/imprimer.html',
        en: 'https://www.brenntag.com/en-nl/imprint.html',
    },
    faqUrl: {
        nl: 'https://www.brenntag.com/nl-nl/services/brenntag-connect-online-services/faq/',
        fr: 'https://www.brenntag.com/fr-be/services/services-en-ligne-brenntag-connect/faq/',
        en: 'https://www.brenntag.com/en-nl/services/brenntag-connect-online-services/faq/',
    },
    tacUrl: {
        nl: 'https://www.brenntag.com/nl-nl/algemene-voorwaarden.html',
        fr: 'https://www.brenntag.com/fr-be/conditions-generales.html',
        en: 'https://www.brenntag.com/en-nl/terms-and-conditions.html',
    },
    email: {
        generic: 'BrenntagConnect@brenntag.nl',
        csr: 'brenntagconnect@brenntag.nl',
        gdpo: 'gdpo@brenntag.de',
    },
    phone: {
        csr: '+31 (0)78 65 44 999',
    },
    officeHours: '8:00 - 12:00, 13:00 - 17:00',
    taxes: [
        {
            percentage: 21,
            since: '01-01-2000',
        },
    ],
}

export default config
