import React from 'react'

import { Button } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'

interface Props {
    onClick(): void
}

export const FilterCopyLinkButton = ({ onClick }: Props) => {
    const t = useFormatMessage()
    return (
        <Button
            icon="Link"
            variant="outline"
            onClick={onClick}
            title={t('filters:copy-link')}
            data-test-id="button-filter-copy-link"
        />
    )
}
