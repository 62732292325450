import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Mail: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Mail" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M22 20H2V9.12999L4 10.9V18H20V8.22999L12 15.34L2 6.44999V3.99999H18.73L16.48 5.99999H4.51L12 12.66L22 3.76999V20Z"
                fill={fill === 'gradient' ? 'url(#Mail_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Mail" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Mail" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M22 20H2V9.12999L4 10.9V18H20V8.22999L12 15.34L2 6.44999V3.99999H18.73L16.48 5.99999H4.51L12 12.66L22 3.76999V20Z"
                fill={fill === 'gradient' ? 'url(#Mail_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Mail" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Mail" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M22 20H2V9.12999L4 10.9V18H20V8.22999L12 15.34L2 6.44999V3.99999H18.73L16.48 5.99999H4.51L12 12.66L22 3.76999V20Z"
                fill={fill === 'gradient' ? 'url(#Mail_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Mail" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Mail" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M22 20H2V9.12999L4 10.9V18H20V8.22999L12 15.34L2 6.44999V3.99999H18.73L16.48 5.99999H4.51L12 12.66L22 3.76999V20Z"
                fill={fill === 'gradient' ? 'url(#Mail_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Mail" />}
        </g>
    ),
}
