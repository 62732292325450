import { CrossOriginLoginOptions } from 'auth0-js'

import { auth0 } from '../install'

export const login = (options: any) =>
    new Promise((resolve, reject) => {
        if (!auth0.client) {
            throw new Error()
        }
        const params: CrossOriginLoginOptions = {
            realm: 'Username-Password-Authentication', // connection name or HRD domain
            ...options,
        }

        auth0.client.login(params, (err, authResult) => {
            if (err) {
                reject(err)
            } else {
                resolve(authResult)
            }
        })
    })
