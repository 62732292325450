import React, { ReactElement, useContext } from 'react'

import { ObservableQueryFields, QueryResult } from '@apollo/react-common'
import { useQuery } from '@apollo/react-hooks'
import { ApolloError } from 'apollo-client'

import graphqlUtils from '@bc/graphql-utils'
import { getUserLocale, Locale } from '@bc/translations'
import { GQLCustomer, GQLMe, GQLRoleType } from '@bc/types'

import { UserContext, userContextDefault } from '@frontend/context/user-context'
import { gqlMeQuery, MeResponse } from '@frontend/graphql/me'

import { SessionContext, SessionContextData } from '@frontend/context/session-context'

export interface GqlProps {
    meQuery: ObservableQueryFields<MeResponse, undefined> & QueryResult & MeResponse
}

export interface WithCurrentUserProps {
    me: GQLMe | undefined
    meLoading: boolean
    error?: ApolloError
    currentCustomer: GQLCustomer | undefined
    userLocale: Locale
    userHasMultipleCustomerEntities: boolean
    userHasCustomers: boolean
    isCsr: boolean
    isAdmin: boolean
    activeSalesOrganisation: string | undefined
    showContextBar: boolean
}

/**
 * @param Component
 * @example
 */
export function withCurrentUser<P>(Component: React.ComponentType<P & WithCurrentUserProps>): React.ComponentType<P> {
    const WithCurrentUserHoc = (props: P): ReactElement<P> => {
        const [sessionStore, sessionState]: SessionContextData = useContext(SessionContext)
        // Make sure this HOC is used inside the SesssionProvider
        if (!sessionState) {
            throw new Error('SessionContext must be used with SessionProvider!')
        }

        const { loading: meLoading, error, data } = useQuery(gqlMeQuery, {
            fetchPolicy: 'network-only',
            skip: !sessionStore.isAuthenticated,
        })

        const { me } = data ?? {}

        if (!me || meLoading) {
            const locale = getUserLocale(undefined, sessionState?.locale)

            return (
                <UserContext.Provider value={{ ...userContextDefault, userLocale: locale, meLoading, error }}>
                    <Component
                        {...props}
                        me={me}
                        meLoading={meLoading}
                        error={error}
                        currentCustomer={undefined}
                        userLocale={locale}
                        userHasMultipleCustomerEntities={false}
                        userHasCustomers={false}
                        isCsr={false}
                        isAdmin={false}
                        activeSalesOrganisation={undefined}
                        showContextBar={false}
                    />
                </UserContext.Provider>
            )
        }

        const isCsr = me && graphqlUtils.user.isCsr(me.meta)
        const isAdmin = me && graphqlUtils.user.hasRole(me.meta, GQLRoleType.admin)

        const userHasMultipleCustomerEntities = me && graphqlUtils.user.hasMultipleCustomers(me.meta)

        const userHasCustomers = Boolean(me?.customers?.length > 0)

        const userLocale = getUserLocale(me?.meta, sessionState?.locale)

        const currentCustomer = graphqlUtils.user.getCurrentCustomer(
            userHasMultipleCustomerEntities,
            isCsr,
            sessionState?.activeCustomer,
            me,
        )

        const { activeSalesOrganisation, activeCustomer: activeCustomerId, activeSalesAreaId } = sessionState

        return (
            <UserContext.Provider
                value={{
                    me,
                    error,
                    meLoading,
                    userLocale,
                    userHasMultipleCustomerEntities,
                    userHasCustomers,
                    currentCustomer,
                    isCsr,
                    isAdmin,
                    activeSalesOrganisation,
                    activeCustomerId,
                    activeSalesAreaId,
                    showContextBar: userHasMultipleCustomerEntities,
                }}>
                <Component
                    {...props}
                    me={me}
                    meLoading={meLoading}
                    error={error}
                    currentCustomer={currentCustomer}
                    userLocale={userLocale}
                    userHasMultipleCustomerEntities={userHasMultipleCustomerEntities}
                    userHasCustomers={userHasCustomers}
                    isCsr={isCsr}
                    isAdmin={isAdmin}
                    activeSalesOrganisation={activeSalesOrganisation}
                    activeCustomerId={activeCustomerId}
                    activeSalesAreaId={activeSalesAreaId}
                    showContextBar={userHasMultipleCustomerEntities}
                />
            </UserContext.Provider>
        )
    }

    return WithCurrentUserHoc
}
