import React from 'react'

import { Card } from '@bc/ui/src/components/card'
import { Hr } from '@bc/ui/src/components/hr'
import { spacing } from '@bc/ui/src/config'

import { RichRowHeader } from './rich-row-header'
import { RichRowList, RichRowListItemProps } from './rich-row-list'

interface RichRowProps {
    title: string
    subtitle?: string
    list?: RichRowListItemProps[]
    children: {
        actions?: JSX.Element
        content?: JSX.Element
    }
}

export const RichRow = ({ list, children, title, subtitle }: RichRowProps) => (
    <Card
        css={`
            margin-bottom: ${spacing.xs}px;
        `}
        theme="light">
        <RichRowHeader title={title} subtitle={subtitle}>
            {children.actions}
        </RichRowHeader>
        <Hr small />
        {list && <RichRowList list={list} />}
        {children.content}
    </Card>
)
