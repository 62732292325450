import React from 'react'
import styled from 'styled-components'

import { RichRowList, RichRowListItemProps } from '@bc/ui/src/components/rich-row/rich-row-list'
import { spacing } from '@bc/ui/src/config'

import { Card } from './card'
import { HeaderContent, HeaderItems, RichRowHr } from './card-components'
import { CardTitle } from './card-title'

interface CardEnrichedProps {
    header: {
        title: string | JSX.Element
        subtitle?: string | JSX.Element
        icon?: JSX.Element
    }
    children?: JSX.Element | JSX.Element[]
    actions?: JSX.Element
    list?: RichRowListItemProps[]
    numberOfRows?: number
}

const CardWrapper = styled(Card)`
    margin: 0 0 ${spacing.sm}px 0;
`

function chunk<T>(input: T[], size: number): T[][] {
    const output = []

    while (input.length) {
        output.push(input.splice(0, size))
    }
    return output
}

export const CardEnriched = ({ header, children, actions, list, numberOfRows = 0, ...rest }: CardEnrichedProps) => (
    <CardWrapper theme="light" {...rest}>
        <HeaderContent>
            <HeaderItems>
                <CardTitle title={header.title} subtitle={header.subtitle} icon={header.icon} />
                {actions}
            </HeaderItems>
        </HeaderContent>
        <RichRowHr small />
        {list && (
            <>
                {numberOfRows > 1 ? (
                    chunk(list, Math.ceil(list.length / numberOfRows)).map((r, index) => (
                        <RichRowList list={r} key={index} />
                    ))
                ) : (
                    <RichRowList list={list} />
                )}
            </>
        )}
        {children}
    </CardWrapper>
)
