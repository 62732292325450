import styled from 'styled-components'

import { Card, colors, media, spacing, Text, TextInput } from '@bc/ui'

export const FormCard = styled(Card)`
    margin-bottom: ${spacing.sm}px;
`

export const FormWrapper = styled.form`
    display: flex;
    align-items: flex-start;
    ${media.max('sm')} {
        flex-direction: column;
    }
`

export const SearchInput = styled(TextInput)`
    width: 100%;
    margin: 0 ${spacing.xs}px 0 0;
    ${media.max('sm')} {
        margin: 0 0 ${spacing.xs}px 0;
    }
`

export const ErrorMessages = styled.div`
    margin: 5px 0 0 0;
    flex-basis: 100%;
    color: ${colors.red70};
`

export const CustomerSearchWrapper = styled.div`
    &:hover {
        background-color: ${colors.neutral10};
    }
    background-color: ${colors.white};
    cursor: pointer;
    padding: ${spacing.xxs}px ${spacing.xs}px;
`

export const Label = styled(Text)`
    color: ${colors.neutral50};
    padding-right: ${spacing.xxs};
`

export const Tab = styled.span`
    display: inline-block;
    width: ${spacing.sm}px;
`

export const ValidationError = styled(Text)`
    color: ${colors.red70};
`
export const CompanyName = styled(Text)`
    min-height: 22px;
    margin-bottom: 0px;
    font-weight: bold;
`
