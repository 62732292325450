import React from 'react'

import { colors } from '@bc/ui/src/config'

import { SvgIconMap } from '../types'

const defaultFill = colors.yellow50

export const status_pending: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-pending"
            d="M17,6c0-0.552,0.448-1,1-1s1,0.448,1,1s-0.448,1-1,1S17,6.552,17,6z M13,6c0-2.757,2.243-5,5-5s5,2.243,5,5
            s-2.243,5-5,5S13,8.757,13,6z M15,6c0,1.654,1.346,3,3,3s3-1.346,3-3s-1.346-3-3-3S15,4.346,15,6z M20,12.705
            c0.723-0.216,1.396-0.546,2-0.969v6.984l-10,3.333L2,18.721V5.612l9.594,3.199c0.342,0.777,0.82,1.48,1.406,2.079v8.723l7-2.334
            V12.705z M11,10.72L4,8.388v8.892l7,2.334V10.72z M11.213,4.316c0.206-0.831,0.556-1.604,1.03-2.289L12,1.946L4.663,4.392
            l3.161,1.054L11.213,4.316z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-pending"
            d="M17,6c0-0.552,0.448-1,1-1s1,0.448,1,1s-0.448,1-1,1S17,6.552,17,6z M13,6c0-2.757,2.243-5,5-5s5,2.243,5,5
            s-2.243,5-5,5S13,8.757,13,6z M15,6c0,1.654,1.346,3,3,3s3-1.346,3-3s-1.346-3-3-3S15,4.346,15,6z M20,12.705
            c0.723-0.216,1.396-0.546,2-0.969v6.984l-10,3.333L2,18.721V5.612l9.594,3.199c0.342,0.777,0.82,1.48,1.406,2.079v8.723l7-2.334
            V12.705z M11,10.72L4,8.388v8.892l7,2.334V10.72z M11.213,4.316c0.206-0.831,0.556-1.604,1.03-2.289L12,1.946L4.663,4.392
            l3.161,1.054L11.213,4.316z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-pending"
            d="M17,6c0-0.552,0.448-1,1-1s1,0.448,1,1s-0.448,1-1,1S17,6.552,17,6z M13,6c0-2.757,2.243-5,5-5s5,2.243,5,5
            s-2.243,5-5,5S13,8.757,13,6z M15,6c0,1.654,1.346,3,3,3s3-1.346,3-3s-1.346-3-3-3S15,4.346,15,6z M20,12.705
            c0.723-0.216,1.396-0.546,2-0.969v6.984l-10,3.333L2,18.721V5.612l9.594,3.199c0.342,0.777,0.82,1.48,1.406,2.079v8.723l7-2.334
            V12.705z M11,10.72L4,8.388v8.892l7,2.334V10.72z M11.213,4.316c0.206-0.831,0.556-1.604,1.03-2.289L12,1.946L4.663,4.392
            l3.161,1.054L11.213,4.316z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-pending"
            d="M17,6c0-0.552,0.448-1,1-1s1,0.448,1,1s-0.448,1-1,1S17,6.552,17,6z M13,6c0-2.757,2.243-5,5-5s5,2.243,5,5
            s-2.243,5-5,5S13,8.757,13,6z M15,6c0,1.654,1.346,3,3,3s3-1.346,3-3s-1.346-3-3-3S15,4.346,15,6z M20,12.705
            c0.723-0.216,1.396-0.546,2-0.969v6.984l-10,3.333L2,18.721V5.612l9.594,3.199c0.342,0.777,0.82,1.48,1.406,2.079v8.723l7-2.334
            V12.705z M11,10.72L4,8.388v8.892l7,2.334V10.72z M11.213,4.316c0.206-0.831,0.556-1.604,1.03-2.289L12,1.946L4.663,4.392
            l3.161,1.054L11.213,4.316z"
            transform="scale(0.5)"
        />
    ),
}
