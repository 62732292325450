import GTM from 'react-gtm-module'

import * as tags from './tags'

interface TagManagerProps {
    tags: typeof tags
}

class TagManager implements TagManagerProps {
    public tags = tags

    public initialize(gtmId: string) {
        GTM.initialize({
            gtmId,
        })
    }
}

export const tagManager = new TagManager()
export * from './utils'
