export const MAX_SAFE_NUMBER_LENGTH = String(Number.MAX_SAFE_INTEGER).length - 1

const editKeys = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const code = event.charCode || event.keyCode
    const allowedCodes = [8, 46, 37, 39] // bksp, del, left, right
    return allowedCodes.includes(code)
}
const floatingPoint = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement
    const code = event.charCode || event.keyCode
    const allowedCodes = [44, 46, 188, 190] // ,.
    // check if , or . is typed and it is not contained already in the value
    return allowedCodes.includes(code) && !value.split('').some(c => allowedCodes.includes(c.charCodeAt(0)))
}

const isNumericInput = (event: React.KeyboardEvent<HTMLInputElement>) => !isNaN(Number(event.key))

const isLongerThan = (event: React.KeyboardEvent<HTMLInputElement>, length: number) => {
    const { value } = event.target as HTMLInputElement
    return typeof value === 'string' && value.length >= length
}

export const restrictToInteger = (event: React.KeyboardEvent<HTMLInputElement>) =>
    !editKeys(event) && !isNumericInput(event) && event.preventDefault()

export const restrictToFloat = (event: React.KeyboardEvent<HTMLInputElement>) =>
    !editKeys(event) && !floatingPoint(event) && !isNumericInput(event) && event.preventDefault()

export const restrictLengthTo = (event: React.KeyboardEvent<HTMLInputElement>, length: number) =>
    !editKeys(event) && isLongerThan(event, length) && event.preventDefault()

export const restrictNumbersKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    restrictToInteger(event)
    restrictLengthTo(event, MAX_SAFE_NUMBER_LENGTH)
}

export const restrictFloatsKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    restrictToFloat(event)
    restrictLengthTo(event, MAX_SAFE_NUMBER_LENGTH)
}
