import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'
import { CheckboxRadioLabelProps, checkboxRadioBeforeStyles, labelStyles, afterTransitionStyles } from './styles'

export const Label = styled.label<CheckboxRadioLabelProps>`
    display: flex;
    cursor: pointer;

    ${labelStyles}

    ${checkboxRadioBeforeStyles}
    
    &:before {
        border-radius: 50%;
    }

    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        top: 8px;
        left: 8px;
        background-color: ${colors.white};
        border-radius: 50%;

        ${afterTransitionStyles}
    }
`
