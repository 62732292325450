import { config, salesOrganisationConfig } from '@bc/config'
import { Language, RegionCode } from '@bc/translations'
import {
    GQLCustomer,
    GQLGender,
    GQLMe,
    GQLRole,
    GQLRoleType,
    GQLSalesOrganisationId,
    GQLUser,
    GQLUserSalesArea,
} from '@bc/types'

export const getGender = (female: boolean | undefined): GQLGender => {
    switch (female) {
        case false:
            return GQLGender.male
        case true:
            return GQLGender.female
        default:
            return GQLGender.unknown
    }
}

export const getFemale = (gender: GQLGender | undefined): boolean | null | undefined => {
    switch (gender) {
        case GQLGender.male:
            return false
        case GQLGender.female:
            return true
        case GQLGender.unknown:
            return null
        default:
            return undefined
    }
}

export interface UtilsEmailData {
    to: string
    bcc?: string
    cc?: string
}

export const showRequestLink = (user: GQLUser) => config[user.region as RegionCode].features.Requests

export const getCsrEmail = (user: GQLUser, salesOrganisationId?: GQLSalesOrganisationId) => {
    let email: UtilsEmailData = getDefaultEmailCsr(user)
    if (salesOrganisationId) {
        const shopSalesOrg = salesOrganisationConfig[salesOrganisationId]
        if (shopSalesOrg) {
            email = { ...email, bcc: shopSalesOrg.bcc?.csrs.join(';'), to: shopSalesOrg.email.csr }
        }
    }

    return email
}

export const getCsrPhone = (user: GQLUser, salesOrganisationId?: GQLSalesOrganisationId) => {
    let phone = config[user.region as RegionCode].phone.csr
    if (salesOrganisationId) {
        const shopSalesOrg = salesOrganisationConfig[salesOrganisationId]
        if (shopSalesOrg) {
            phone = shopSalesOrg.phone.csr
        }
    }

    return phone
}

export const getUserEmail = (user: GQLUser, salesOrganisationId?: GQLSalesOrganisationId) => {
    let email: UtilsEmailData = getDefaultEmailUser(user)
    if (salesOrganisationId) {
        const shopSalesOrg = salesOrganisationConfig[salesOrganisationId]
        if (shopSalesOrg) {
            email = { ...email, bcc: shopSalesOrg.bcc?.users.join(';') }
        }
    }

    return email
}

const getDefaultEmailCsr = (user: GQLUser) => ({ to: config[user.region as RegionCode].email.csr })

const getDefaultEmailUser = (user: GQLUser) => ({ to: user.email })

export const getGdpoEmail = (user: GQLUser) => config[user.region as RegionCode].email.gdpo
export const getFromEmail = (user: GQLUser) => config[user.region as RegionCode].email.from
export const getFromName = (user: GQLUser) => config[user.region as RegionCode].email.fromName
export const getReplyEmail = (user: GQLUser) => config[user.region as RegionCode].email.reply
export const getReplyName = (user: GQLUser) => config[user.region as RegionCode].email.replyName

export const getPrivacyPolicyUrl = (user: GQLUser | undefined) =>
    (user && config[user.region as RegionCode].privacyPolicyUrl[user.language as Language]) ||
    `${config.de.brenntagConnectUrl}/en-de/privacy-policy.html`

export const getImprintUrl = (user: GQLUser | undefined) =>
    (user && config[user.region as RegionCode].imprintUrl[user.language as Language]) ||
    `${config.de.brenntagConnectUrl}/en-de/privacy-policy.html`

export const getFaqUrl = (user: GQLUser | undefined) =>
    (user && config[user.region as RegionCode].faqUrl[user.language as Language]) ||
    `${config.de.brenntagConnectUrl}/en-de/privacy-policy.html`

export const getTaCUrl = (user: GQLUser | undefined) =>
    (user && config[user.region as RegionCode].tacUrl[user.language as Language]) ||
    `${config.de.brenntagConnectUrl}/en-de/privacy-policy.html`

export const isCsr = (user: GQLUser | undefined): boolean => {
    const role = user?.role
    const csrRoles = [GQLRoleType.csr, GQLRoleType.admin]

    return Boolean(role?.type && csrRoles.includes(role.type))
}

export const hasRole = (user: GQLUser | undefined, roleType: GQLRoleType): boolean =>
    Boolean(user?.role?.type === roleType)

export const getCurrentCustomer = (
    userHasMultipleCustomerEntities: boolean,
    csr: boolean,
    activeCustomer?: string,
    me?: GQLMe,
): GQLCustomer | undefined =>
    userHasMultipleCustomerEntities || csr
        ? activeCustomer
            ? me?.customers.filter(Boolean).find(customer => customer.id === activeCustomer)
            : undefined
        : me?.customers && me.customers[0]

export const getUserCustomersIds = (user: GQLUser | undefined): string[] =>
    user?.limitUserToSalesAreas ? user.limitUserToSalesAreas.map(usa => usa.customerId) : []

export const hasMultipleCustomers = (user: GQLUser | undefined): boolean => {
    const totalCustomerEntities =
        user?.limitUserToSalesAreas?.reduce(
            (total: number, limit: GQLUserSalesArea) => total + limit?.salesAreas.length ?? 0,
            0,
        ) ?? 0
    return totalCustomerEntities > 1
}

export const hasThisCustomer = (
    user: GQLUser,
    { cid, so, sid }: { cid: string | undefined; so: string | undefined; sid: string | undefined },
): boolean =>
    Boolean(cid && so && sid) &&
    Boolean(
        user.limitUserToSalesAreas.find(
            area =>
                cid === area.customerId &&
                area.salesAreas.some(sa => so === sa.salesOrganisation && sid === sa.salesAreaId),
        ),
    )

export const getDefaultCustomerId = (user: GQLUser | undefined) => {
    const customerIds = getUserCustomersIds(user)
    return customerIds[0] || undefined
}

export const isDigibAccount = (user: GQLUser) => {
    const isDev = process.env.NODE_ENV !== 'production'
    return Boolean(!isDev && user && user.digibAccount)
}

export const hasAcceptedToU = (user: GQLUser): boolean =>
    Boolean(user.acceptedTerms?.version === config[user.region as RegionCode].termsOfUsageVersion)

export const getRole = (user: GQLUser): GQLRole | undefined => user?.role

export const formatRole = (roleType: GQLRoleType = GQLRoleType.user) =>
    roleType === GQLRoleType.csr ? roleType.toUpperCase() : roleType.charAt(0).toUpperCase() + roleType.slice(1)
