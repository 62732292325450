import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Image: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Image" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M5 12.76L3 14.76V3H21V5H5V12.76ZM14 9C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9ZM3 21H21V19H5V18.41L11 12.41L14.07 15.48L19 9.56V17H21V7H18.53L13.93 12.52L11 9.59L3 17.59V21Z"
                fill={fill === 'gradient' ? 'url(#Image_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Image" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Image" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M5 12.76L3 14.76V3H21V5H5V12.76ZM14 9C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9ZM3 21H21V19H5V18.41L11 12.41L14.07 15.48L19 9.56V17H21V7H18.53L13.93 12.52L11 9.59L3 17.59V21Z"
                fill={fill === 'gradient' ? 'url(#Image_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Image" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Image" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M5 12.76L3 14.76V3H21V5H5V12.76ZM14 9C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9ZM3 21H21V19H5V18.41L11 12.41L14.07 15.48L19 9.56V17H21V7H18.53L13.93 12.52L11 9.59L3 17.59V21Z"
                fill={fill === 'gradient' ? 'url(#Image_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Image" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Image" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M5 12.76L3 14.76V3H21V5H5V12.76ZM14 9C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9ZM3 21H21V19H5V18.41L11 12.41L14.07 15.48L19 9.56V17H21V7H18.53L13.93 12.52L11 9.59L3 17.59V21Z"
                fill={fill === 'gradient' ? 'url(#Image_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Image" />}
        </g>
    ),
}
