import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const LicensePlate: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="LicensePlate" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M23 19H1V5H23V7H3V17H21V9H23V19ZM7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12ZM19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12ZM9 11H15V13H9V11Z"
                fill={fill === 'gradient' ? 'url(#LicensePlate_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="LicensePlate" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="LicensePlate" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M23 19H1V5H23V7H3V17H21V9H23V19ZM7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12ZM19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12ZM9 11H15V13H9V11Z"
                fill={fill === 'gradient' ? 'url(#LicensePlate_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="LicensePlate" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="LicensePlate" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M23 19H1V5H23V7H3V17H21V9H23V19ZM7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12ZM19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12ZM9 11H15V13H9V11Z"
                fill={fill === 'gradient' ? 'url(#LicensePlate_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="LicensePlate" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="LicensePlate" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M23 19H1V5H23V7H3V17H21V9H23V19ZM7 12C7 12.5523 6.55228 13 6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12ZM19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12ZM9 11H15V13H9V11Z"
                fill={fill === 'gradient' ? 'url(#LicensePlate_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="LicensePlate" />}
        </g>
    ),
}
