import React from 'react'

import { colors } from '@bc/ui/src/config'

interface FooterBrenntagLogoProps extends React.HTMLProps<SVGSVGElement> {
    startGradientColor?: string
    endGradientColor?: string
    mobile?: boolean
    fill?: string
}
export const FooterBrenntagLogo: React.SFC<FooterBrenntagLogoProps> = ({
    mobile = false,
    fill = colors.deepPurple,
    startGradientColor = colors.violet50,
    endGradientColor = colors.blue50,
    ...props
}) => (
    <svg
        height="48"
        viewBox={`0 0 ${mobile ? '48' : '262'} 48`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M41.17 24.43C39.71 26.15 37.61 27.83 34.97 28.88C34.96 28.88 34.86 28.93 34.78 28.96C34.78 28.96 34.9 29.08 34.91 29.09C35.46 29.59 36.93 31.26 36.89 34C36.85 37.31 34.3 40 30.99 40H15V28H29C36.72 28 43 21.72 43 14C43 6.28 36.72 0 29 0H7V18.01H15V8.01H29C32.31 8.01 35 10.7 35 14.01C35 17.32 32.31 20.01 29 20.01H7V48H31C38.72 48.01 45 41.73 45 34.01C45 30.28 43.55 26.93 41.17 24.42V24.43Z"
            fill="url(#footer_brenntag_gradient"
        />

        <linearGradient id="footer_brenntag_gradient" x1="7" y1="48" x2="43" y2="12" gradientUnits="userSpaceOnUse">
            <stop stopColor={startGradientColor} />
            <stop offset="1" stopColor={endGradientColor} />
        </linearGradient>

        {!mobile && (
            <g fillRule="evenodd" clipRule="evenodd">
                <path
                    d="M249 26V22.01H261C261.1 22.55 261.21 24.01 261.21 24.65C261.21 31.46 256.35 36.51 249.47 36.51C242.59 36.51 237 30.79 237 24.01C237 17.2 242.59 11.67 249.53 11.67C253.29 11.67 256.72 13.38 258.98 16.06L255.98 18.41C254.39 16.58 252.08 15.47 249.53 15.47C244.77 15.47 241 19.27 241 24C241 28.73 244.77 32.5 249.47 32.5C253.39 32.5 255.87 30.54 256.85 27.03C256.9 26.88 257.01 26.33 257.01 26H249ZM83 29C83 32.78 80 36 75.78 36H64V12H75.17C79 12 82 15.22 82 19C82 20.81 81.41 22.45 80 23.5C81.6 24.33 83 26.45 83 29ZM75.01 16H68V22H75.01C76.8 22 78 20.86 78 19C78 17.14 76.8 16 75.01 16ZM75.65 32C77.44 32 79 30.88 79 29.02C79 27.16 77.44 26 75.65 26H68V32H75.65ZM97.25 27L104 36V36.01H109L102 26.51C102.124 26.451 102.356 26.362 102.549 26.2879C102.651 26.249 102.741 26.2141 102.8 26.19C105.56 25.07 107 22.45 107 19.4C107 15.31 104.15 12 99.74 12H89V36H93V27H97.25ZM93 23V16H99.58C101.88 16 103 17.29 103 19.4C103 21.51 101.88 23 99.58 23H93ZM117.98 16H130V12H114V36H130V32H117.98V26H128V22H117.98V16ZM154 12.01H158V35.99H153.11L141 18V36H137V12H142L154 30.14V12.01ZM186 12.01H182V30.14L170 12H165V36H169V18L181.11 35.99H186V12.01ZM212 12V16H203V36H199V16H190V12H212ZM228 29L231 35.99H235L225 12H221L211 36H215L218 29H228ZM223 17L226.55 25.5H219.45L223 17Z"
                    fill={fill}
                />
            </g>
        )}
    </svg>
)
