import React, { useContext } from 'react'
import { Field } from 'react-final-form'

import { GQLRoleType } from '@bc/types'
import { CheckboxGroup, colors, SvgIcon, TextInput, SelectInput, selectFindOption } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { FiltersContext, FiltersContextData, PlatformConfigContext } from '@frontend/context'
import {
    finalFormValidations,
    NUM_CHAR_START_SEARCH,
    userRoleAsIconName,
    userRoleAsTranslationKey,
} from '@frontend/utils'

import { UserFilters } from '@frontend/pages/user-management/user-management-types'
import { FieldIcon } from './filters-components'
import { FiltersFormContainer } from './filters-form-container'

const userRoles = [GQLRoleType.user, GQLRoleType.viewer, GQLRoleType.csr, GQLRoleType.admin]
export const UserManagementFilters = () => {
    const t = useFormatMessage()
    const { appConfig } = useContext(PlatformConfigContext)
    const { onFilterChange, filters, onLinkCopy, query }: FiltersContextData<UserFilters> = useContext(FiltersContext)
    const validateRolesGroup = (roles: string[] | undefined) =>
        roles?.length === 0 ? t('filters:search-by-role.error-select-one') : undefined

    return (
        <FiltersFormContainer
            onFilterChange={onFilterChange}
            initialValues={filters}
            onLinkCopy={onLinkCopy}
            query={query}>
            <Field
                name="email"
                validate={finalFormValidations.minLength(t, NUM_CHAR_START_SEARCH)}
                render={({ input, meta }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-username')}
                        type="search"
                        placeholder={t('filters:search-by-here.placeholder')}
                        hasError={meta.touched && meta.error}
                        errorText={meta.error}
                        data-test-id="input-search-username"
                    />
                )}
            />
            <Field
                name="customerId"
                render={({ input }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-customerID')}
                        type="search"
                        placeholder={t('filters:search-by-here.placeholder')}
                        data-test-id="input-search-customer-id"
                    />
                )}
            />

            {appConfig.countries && appConfig.countries.length > 1 && (
                <Field
                    name="country"
                    parse={option => option?.value}
                    render={({ input }) => {
                        const { value, ...restInput } = input
                        const options = appConfig.countries.map(country => ({
                            value: country,
                            label: country.toUpperCase(),
                        }))

                        const selectedOption = selectFindOption(options, value)
                        return (
                            <SelectInput
                                {...restInput}
                                isClearable
                                value={selectedOption}
                                labelText={t('filters:search-by-country')}
                                options={options}
                                data-test-id="select-filter-country"
                            />
                        )
                    }}
                />
            )}
            <CheckboxGroup
                labelText={t('filters:search-by-role')}
                validate={validateRolesGroup}
                name="role"
                id={'user-filter-role'}
                options={userRoles}
                labelOptions={userRoles.map(role => (
                    <>
                        {t(userRoleAsTranslationKey(role))}
                        <FieldIcon>
                            <SvgIcon icon={userRoleAsIconName(role)} size="24" fill={colors.neutral50} />
                        </FieldIcon>
                    </>
                ))}
                colorScheme="deepPurple"
            />
        </FiltersFormContainer>
    )
}
