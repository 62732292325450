import styled from 'styled-components'

import { colors, transition } from '../../config'

export interface FullScreenWrapperProps {
    transparent?: boolean
    isVisible?: boolean
}

const overlayStyles = `
    z-index: 1;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: "";
        background: ${colors.white};
        opacity: 0.6;
    }
`

export const FullScreenWrapper = styled.div<FullScreenWrapperProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: ${({ isVisible = true }) => (isVisible ? 1 : 0)};
    left: ${({ isVisible = true }) => (isVisible ? 0 : '-99999px')};
    visibility: ${({ isVisible = true }) => (isVisible ? 'visible' : 'hidden')};
    transition: ${({ isVisible = true }) =>
        isVisible
            ? `opacity ${transition.config}`
            : `opacity ${transition.config}, left 0s ${transition.duration}, visibility 0s ${transition.duration}`};

    ${({ transparent }) => (transparent ? `` : overlayStyles)}
`
