import styled from 'styled-components'

import { media } from '@bc/ui/src/helpers'

// Wrapper around the content area
export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    flex-direction: column;

    ${media.min('md')} {
        flex-direction: row;
    }
`

// Content area next to sidebar
export const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    max-width: 100%;
`

// Wrapper around content
export const ChildrenWrapper = styled.section`
    flex: 1 0 auto;
`
