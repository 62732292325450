import { EventTag, VirtualPageviewTag } from '../types'
import { dataLayer } from '../utils'

export const productListFilterByCas = (cas: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.productSearchCas',
        virtualURL: `/productsearchcas?q=${cas}&n=productsearchcasbar`,
        virtualTitle: 'product search - search cas number',
    })
}

export const productListFilterByEinecs = (einecs: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.productSearchEinecs',
        virtualURL: `/productsearcheinecs?q=${einecs}&n=productsearcheinecsbar`,
        virtualTitle: 'product search - search einecs number',
    })
}

export const productListFilterByName = (name: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.productSearchName',
        virtualURL: `/productsearchname?q=${name}&n=productsearchnamebar`,
        virtualTitle: 'product search - search name',
    })
}

export const productListFilterByProductId = (id: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.productSearchId',
        virtualURL: `/productsearchid?q=${id}&n=productsearchidbar`,
        virtualTitle: 'product search - search id',
    })
}

export const productListFilterByPackaging = (packaging: string) => {
    dataLayer<EventTag>({
        event: 'gtm.productSearchFilter',
        eventCategory: 'product search',
        eventAction: 'filter',
        eventLabel: packaging,
    })
}

const getMappedSort = (sortValue: string) => {
    let sortOrder = 'ascending'
    let sortType = sortValue

    if (sortType[0] === '-') {
        sortOrder = 'descending'
        sortType = sortType.substr(1)
    }

    return `${sortType}: ${sortOrder}`
}

export const productListSort = (sortValue: string) => {
    dataLayer<EventTag>({
        event: `gtm.productSearchSort`,
        eventCategory: 'product search',
        eventAction: 'sort',
        eventLabel: getMappedSort(sortValue),
    })
}
