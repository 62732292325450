import graphqlUtils from '@bc/graphql-utils'
import { GQLMaterial, GTMBaseOrderData } from '@bc/types'

import { EcommerceTag } from '../types/index'
import { dataLayer } from '../utils/index'

interface Product {
    name: string
    id: string
    price: number | string
    brand?: string
    variant?: string
    quantity: number
    metric1: number
    dimension1?: string
    dimension2?: string
    dimension4?: string
    dimension6?: string
    dimension8?: string
    dimension14?: number
    dimension15?: number
    dimension16?: string
    dimension17?: string
    dimension18?: string
}
type PurchaseTag = EcommerceTag<{
    currencyCode: string
    purchase: {
        actionField: {
            id?: string
            affiliation: string
            revenue: number
            tax: number
        }
        products: Product[]
    }
}>

export const basePurchase = (id: string, material: GQLMaterial, GTMData: GTMBaseOrderData) => {
    const {
        price = 0,
        pricePerUnit = 0,
        customerPoReference,
        requestedDeliveryDate,
        orderAmount,
        orderQuantity,
        packagingUom,
        orderUom,
        currency: currencyCode,
        tax = 0,
        quoteId,
        shipToId,
    } = GTMData

    dataLayer<PurchaseTag>({
        event: 'purchase',
        ecommerce: {
            currencyCode: currencyCode || '-',
            purchase: {
                actionField: {
                    id,
                    affiliation: 'Brenntag Connect',
                    revenue: price,
                    tax,
                },
                products: [
                    {
                        name: material.name!,
                        id: material.id!,
                        price,
                        brand: material.manufacturer?.name,
                        quantity: orderQuantity,
                        metric1: pricePerUnit,
                        dimension1: packagingUom || '-',
                        dimension2: material.packaging?.name,
                        dimension4: graphqlUtils.material.getCASNumbers(material).join(', ') || '-',
                        dimension6: customerPoReference,
                        dimension8: requestedDeliveryDate,
                        dimension14: orderAmount,
                        dimension15: orderQuantity,
                        dimension16: quoteId || '-',
                        dimension17: orderUom || '-',
                        dimension18: shipToId || '-',
                    },
                ],
            },
        },
    })
}
