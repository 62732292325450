import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Map: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Map" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M22 22.6199L14.92 19.0799L8.92 21.0799L2 17.6199V1.37988L9.08 4.91988L15.08 2.91988L22 6.37988V22.6199ZM15.08 16.9199L20 19.3799V7.61988L14.92 5.07988L8.92 7.07988L4 4.61988V16.3799L9.08 18.9199L15.08 16.9199ZM10 9H8V17H10V9ZM14 7H16V15H14V7Z"
                fill={fill === 'gradient' ? 'url(#Map_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Map" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Map" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M22 22.6199L14.92 19.0799L8.92 21.0799L2 17.6199V1.37988L9.08 4.91988L15.08 2.91988L22 6.37988V22.6199ZM15.08 16.9199L20 19.3799V7.61988L14.92 5.07988L8.92 7.07988L4 4.61988V16.3799L9.08 18.9199L15.08 16.9199ZM10 9H8V17H10V9ZM14 7H16V15H14V7Z"
                fill={fill === 'gradient' ? 'url(#Map_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Map" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Map" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M22 22.6199L14.92 19.0799L8.92 21.0799L2 17.6199V1.37988L9.08 4.91988L15.08 2.91988L22 6.37988V22.6199ZM15.08 16.9199L20 19.3799V7.61988L14.92 5.07988L8.92 7.07988L4 4.61988V16.3799L9.08 18.9199L15.08 16.9199ZM10 9H8V17H10V9ZM14 7H16V15H14V7Z"
                fill={fill === 'gradient' ? 'url(#Map_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Map" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Map" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M22 22.6199L14.92 19.0799L8.92 21.0799L2 17.6199V1.37988L9.08 4.91988L15.08 2.91988L22 6.37988V22.6199ZM15.08 16.9199L20 19.3799V7.61988L14.92 5.07988L8.92 7.07988L4 4.61988V16.3799L9.08 18.9199L15.08 16.9199ZM10 9H8V17H10V9ZM14 7H16V15H14V7Z"
                fill={fill === 'gradient' ? 'url(#Map_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Map" />}
        </g>
    ),
}
