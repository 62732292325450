import { spacing, colors } from '@bc/ui'

import styled from 'styled-components'

export const Ul = styled.ul``
export const Li = styled.li`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing.xxs}px;
    align-items: center;
    background: ${colors.neutral10};
    padding-left: ${spacing.xxs}px;
`
export const Icon = styled.div`
    display: inline-block;
    cursor: pointer;
`

export const HiddenInput = styled.input`
    display: none;
`
