import React from 'react'
import styled from 'styled-components'

// Components are picked from very specific folders to prevent circular dependencies
import { SvgIcon } from '@bc/ui/src/components/svg'
import { Text } from '@bc/ui/src/components/text'
import { colors, spacing } from '@bc/ui/src/config'
import { useNotifications } from '@frontend/context'
import { GQLNotificationType } from '@bc/types'

const NotificationWrapper = styled.div`
    border: 1px solid ${colors.neutral50};
    display: flex;
    margin-bottom: ${spacing.md}px;
    position: relative;
`

const NotificationIcon = styled.div`
    background-color: ${colors.neutral10};
    padding: ${spacing.xxs}px ${spacing.xs}px;
    display: flex;
    justify-content: center;
    flex-direction: column;
`

const NotificationContent = styled.div`
    background-color: ${colors.neutral10};
    padding: ${spacing.xs}px;
    width: 100%;
    white-space: pre-line;
`

const NotificationCloseButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px 4px;
    background: transparent;
    user-select: none;
    outline: none;
    border: none;
    color: ${colors.deepPurple};
    cursor: pointer;
    font-size: 32px;
    line-height: 16px;
`

export const NotificationBanner = () => {
    const [notificationStore, notifications, messageKey] = useNotifications()

    return (
        <>
            {notifications
                .filter(({ type }) => type === GQLNotificationType.maintenance)
                .map(notification => (
                    <NotificationWrapper key={notification.id}>
                        <NotificationCloseButton
                            onClick={() => {
                                notificationStore.discard(notification)
                            }}
                            data-test-id="button-close-notification">
                            &times;
                        </NotificationCloseButton>

                        <NotificationIcon>
                            <SvgIcon size="24" icon="Notification" fill={colors.deepPurple} />
                        </NotificationIcon>
                        <NotificationContent>
                            <Text>{notification[messageKey]}</Text>
                        </NotificationContent>
                    </NotificationWrapper>
                ))}
        </>
    )
}
