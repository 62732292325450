import React from 'react'

import { OxygenTemplate, useDocumentTitle } from '@bc/ui'
import { PageError } from '@frontend/components/errors'
import { ButtonWithTopMargin } from './error-components'

interface Props {
    title: string
    message: string
    url?: {
        href: string
        text: string
    }
}

export const ErrorPage = ({ title, message, url }: Props) => {
    useDocumentTitle(title)
    return (
        <OxygenTemplate>
            <PageError title={title} text={message}>
                {url && (
                    <ButtonWithTopMargin buttonSize="large" variant="action" to={url.href}>
                        {url.text}
                    </ButtonWithTopMargin>
                )}
            </PageError>
        </OxygenTemplate>
    )
}
