import React, { useCallback } from 'react'

import { GQLCustomer } from '@bc/types'
import { TextHighlight } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'

import { trimLeadingZeros } from '@frontend/utils'
import { CompanyName, CustomerSearchWrapper, Label, Tab } from './components'

interface CompanyDropdownResultProps {
    customer: GQLCustomer
    onClick: (customer: GQLCustomer) => void
    highlight: string
}

export const CompanyDropdownResult = ({ customer, onClick, highlight }: CompanyDropdownResultProps) => {
    const t = useFormatMessage()
    const companyOnClick = useCallback(() => {
        onClick(customer)
    }, [customer, onClick])
    const { name = '', id, vatId = '' } = customer

    return (
        <CustomerSearchWrapper onClick={companyOnClick} data-test-id={`select-customer-${id}`}>
            <CompanyName p>
                <TextHighlight search={highlight}>{name}</TextHighlight>
            </CompanyName>
            <Label>{t('company:selection:label-customer-id')}</Label>
            <TextHighlight search={highlight}>{trimLeadingZeros(id)}</TextHighlight>
            <Tab />
            <Label>{t('company:selection:label-vat')}</Label>
            <TextHighlight search={highlight}>{vatId}</TextHighlight>
        </CustomerSearchWrapper>
    )
}
