import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Button } from '@bc/ui/src/components/button/button'
import { ButtonProps } from '@bc/ui/src/components/button/button-types'
import { Text } from '../text'

const CountDownButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`

const CountDownChildrenContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-grow: 1;
`

export type CountDownButton = ButtonProps &
    Omit<JSX.IntrinsicElements['button'], 'onClick'> & {
        countdown: number
        onEnd: () => void
    }

export const CountDownButton: React.FC<CountDownButton> = ({ children, countdown, onEnd, ...rest }) => {
    const [count, setCount] = useState(countdown)
    const timerRef = useRef<number | undefined>()

    const handleTimeout = () => (count === 0 ? handleClick() : setCount(count - 1))

    const handleClick = () => {
        clearTimeout(timerRef.current)
        onEnd()
    }

    useEffect(() => {
        const timer = timerRef.current
        timerRef.current = setTimeout(handleTimeout, 1000)
        return () => clearTimeout(timer)
    }, [count])

    return (
        <Button {...rest} onClick={handleClick}>
            <CountDownButtonContainer>
                <CountDownChildrenContainer>{children}</CountDownChildrenContainer>
                <Text p noMargin>
                    &nbsp; ({count})
                </Text>
            </CountDownButtonContainer>
        </Button>
    )
}
