import React, { useContext, useMemo } from 'react'
import { Field, useField } from 'react-final-form'
import { OptionsType } from 'react-select'
import styled from 'styled-components'

import { ShopFeatures } from '@bc/config'
import { GQLLocation, GQLRequestType } from '@bc/types'
import {
    Checkbox,
    getLocationAddressLine,
    SelectInput,
    SelectInputOption,
    Tabs,
    TextInput,
    Button,
    selectFindOption,
    FormInputComponents,
} from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { UserContext } from '@frontend/context'
import { finalFormValidations } from '@frontend/utils/validation'
import { Feature } from '@frontend/components/customer'

import * as SharedFormComponents from '../_shared'
import { RequestInformationFormValues } from './request-information-form.types'

interface RequestInformationFormTwoProps {
    submitting: boolean
    invalid: boolean
    loading: boolean
    shipTos: GQLLocation[]
    // tslint:disable-next-line ban-types
    mutators: { [key: string]: () => void } | void
    values: RequestInformationFormValues
    onBackButtonClick: () => void
}

const StyledInputSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

export const RequestInformationFormTwo = ({
    mutators,
    submitting,
    invalid,
    values,
    onBackButtonClick,
    loading,
    shipTos,
}: RequestInformationFormTwoProps) => {
    const t = useFormatMessage()
    const { me } = useContext(UserContext)
    const shipToOptions: OptionsType<SelectInputOption> = useMemo(
        () =>
            shipTos.map((location: GQLLocation) => ({
                value: location.id,
                label: getLocationAddressLine(location),
            })),
        [shipTos],
    )
    const {
        input: { value: pickup },
    } = useField('pickup')

    const autoSelectOneShipTo = !pickup && shipToOptions.length === 1 ? shipToOptions[0].value : undefined

    const merecipientFormContent = (
        <FormInputComponents.InputWrapper>
            <FormInputComponents.LabelText>{t('account:first-name')}</FormInputComponents.LabelText>
            <TextInput value={me?.meta.firstName} readOnly={true} type="text" data-test-id="input-first-name" />

            <FormInputComponents.LabelText>{t('account:last-name')}</FormInputComponents.LabelText>
            <TextInput value={me?.meta.lastName} readOnly={true} type="text" data-test-id="input-last-name" />

            <FormInputComponents.LabelText>{t('account:email')}</FormInputComponents.LabelText>
            <TextInput value={me?.meta.email} readOnly={true} type="text" data-test-id="input-email" />
        </FormInputComponents.InputWrapper>
    )

    const validateShipToId = (value: string, formValues: RequestInformationFormValues) => {
        if (!formValues.pickup) {
            return finalFormValidations.required(t)(value)
        }
        return undefined
    }

    return (
        <>
            <StyledInputSection>
                {merecipientFormContent}
                {values.requirements?.some(req => req === GQLRequestType.PRICE || req === GQLRequestType.SAMPLE) && (
                    <>
                        {shipToOptions.length > 0 && (
                            <Tabs
                                full
                                rounded
                                onChange={activeTab => {
                                    ;(mutators as any).setAddressType(
                                        activeTab === 0 ? 'my-address-book' : 'new-address',
                                    )
                                }}
                                items={[
                                    {
                                        id: 'my-address-book',
                                        button: t('request-information-modal:my-address-book'),
                                        content: (
                                            <>
                                                <FormInputComponents.InputWrapper>
                                                    <Field
                                                        name="selectedAddressId"
                                                        initialValue={autoSelectOneShipTo}
                                                        validate={validateShipToId}
                                                        parse={o => o?.value}
                                                        render={({ input }) => {
                                                            const { value, ...restInput } = input
                                                            const selectedOption = selectFindOption(
                                                                shipToOptions,
                                                                value,
                                                            )
                                                            return (
                                                                <SelectInput
                                                                    {...restInput}
                                                                    value={selectedOption}
                                                                    labelText={
                                                                        t('filters:select-ship-to-address') +
                                                                        (pickup ? '' : ' *')
                                                                    }
                                                                    placeholder={t('filters:select-an-address')}
                                                                    isSearchable
                                                                    isClearable
                                                                    isDisabled={pickup}
                                                                    options={shipToOptions}
                                                                    noOptionsMessage={() => t('not-found:select')}
                                                                    maxMenuHeight={200}
                                                                    classNamePrefix="select-ship-to-address-modal-rfi"
                                                                    data-test-id="select-ship-to-address-modal-rfi"
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    <Feature
                                                        flag={ShopFeatures.OrderPickup}
                                                        on={
                                                            <Field
                                                                name="pickup"
                                                                type="checkbox"
                                                                validateFields={['selectedAddressId']}
                                                                render={({ input }) => (
                                                                    <Checkbox
                                                                        {...input}
                                                                        id="pickup"
                                                                        labelText={t(
                                                                            'request-information-modal:reorder:self-pickup',
                                                                        )}
                                                                        data-test-id="checkbox-pickup"
                                                                        colorScheme="deepPurple"
                                                                    />
                                                                )}
                                                            />
                                                        }
                                                    />
                                                </FormInputComponents.InputWrapper>
                                            </>
                                        ),
                                    },
                                    {
                                        id: 'new-address',
                                        button: t('request-information-modal:new-address'),
                                        content: (
                                            <Field
                                                name="newAddress"
                                                validate={finalFormValidations.required(t)}
                                                render={({ input }) => (
                                                    <FormInputComponents.InputWrapper>
                                                        <TextInput
                                                            {...input}
                                                            textarea
                                                            placeholder={t('general:type-here')}
                                                            data-test-id="input-new-address"
                                                        />
                                                    </FormInputComponents.InputWrapper>
                                                )}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        )}
                        <SharedFormComponents.RequiredFootNote t={t} />
                    </>
                )}
            </StyledInputSection>

            <SharedFormComponents.ButtonsWrapper>
                <Button
                    onClick={onBackButtonClick}
                    icon="Chevron_Left"
                    variant="outline"
                    title={t('request-information-modal:back-to-step-1')}
                    data-test-id="button-back-step-1">
                    {t('request-information-modal:back-to-step-1')}
                </Button>
                <Button
                    isLoading={loading}
                    type="submit"
                    variant="action"
                    disabled={submitting || invalid}
                    title={t('request-information-modal:send-request')}
                    data-test-id="button-submit-request">
                    {t('request-information-modal:send-request')}
                </Button>
            </SharedFormComponents.ButtonsWrapper>
        </>
    )
}
