
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"ProductExtendedFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Product"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dangerousGoodsClass"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hsCode"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"reachNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"unNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ghsClass"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"einecsNumbers"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"CASNumbers"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":158}};
    doc.loc.source = {"body":"fragment ProductExtendedFragment on Product {\n    dangerousGoodsClass\n    hsCode\n    reachNumber\n    unNumber\n    ghsClass\n    einecsNumbers\n    CASNumbers\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
