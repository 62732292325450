import React from 'react'
import styled from 'styled-components'

import { Text, TextHighlight } from '@bc/ui/src'
import { colors, spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'

import { ListItemMetaProps } from './list-item-meta-props'

const RichRowListItem = styled.div`
    display: flex;
    align-items: flex-start;
    padding: ${spacing.sm}px;
    margin: 0;
    background-color: ${colors.neutral10};
    justify-content: space-between;
    ${media.max('sm')} {
        flex-direction: column;
        padding: 10px;
    }
`

const RichHeaderRowListItem = styled.div`
    display: flex;
    align-items: flex-start;
    padding: ${spacing.sm}px;
    margin: 15px 0 0 0;
    background-color: ${colors.neutral10};

    ${media.max('sm')} {
        flex-direction: column;
        padding: 10px;
    }
`

const ListItem = styled.div`
    padding: 0 ${spacing.sm}px;
    ${media.max('sm')} {
        margin-right: auto;
        margin-bottom: 5px;
        padding: 0;
    }

    &:first-child,
    &:last-child {
        padding: 0;
    }
`

const HeaderItem = styled.div`
    padding: 0 ${spacing.sm}px;
    ${media.max('sm')} {
        margin-right: auto;
        margin-bottom: 5px;
        padding: 0;
    }
    ${media.min('sm')} {
        &:first-child {
            flex: 1;
        }
    }
    &:first-child,
    &:last-child {
        padding: 0;
    }
`

const OrderLinesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

interface OrderLineBaseProps {
    list: ListItemMetaProps[][] | undefined
}

const HeaderRow = ({ label, icon }: ListItemMetaProps) => (
    <HeaderItem>
        <Text h6 data-test-class="text-product-name">
            {label}
        </Text>
        {icon}
    </HeaderItem>
)

const DataRow = ({ label, value, highlight }: ListItemMetaProps) => (
    <ListItem>
        {label && (
            <Text caption color="neutral50" noMargin>
                {label}
            </Text>
        )}
        <Text p noMargin>
            {typeof value === 'string' ? <TextHighlight search={highlight}>{value}</TextHighlight> : value}
        </Text>
    </ListItem>
)

const ListItemMeta = (props: ListItemMetaProps) => (props.icon ? <HeaderRow {...props} /> : <DataRow {...props} />)

export const OrderLines = ({ list }: OrderLineBaseProps) => (
    <OrderLinesWrapper>
        {list?.map((orderLineList: ListItemMetaProps[], key: number) => {
            const [first, ...content] = orderLineList.map((item: ListItemMetaProps, i: number) => (
                <ListItemMeta key={i} {...item} />
            ))
            return [
                <RichHeaderRowListItem key={`h${key}`}>{first}</RichHeaderRowListItem>,
                <RichRowListItem key={`i${key}`}>{content}</RichRowListItem>,
            ]
        })}
    </OrderLinesWrapper>
)
