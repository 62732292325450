import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Video: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Video" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M22 20H19.59L16 16.41V20H2V8H4V18H14V14H16.41L20 17.59V6.41L16.41 10H14V6H2V4H16V7.59L19.59 4H22V20Z"
                fill={fill === 'gradient' ? 'url(#Video_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Video" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Video" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M22 20H19.59L16 16.41V20H2V8H4V18H14V14H16.41L20 17.59V6.41L16.41 10H14V6H2V4H16V7.59L19.59 4H22V20Z"
                fill={fill === 'gradient' ? 'url(#Video_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Video" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Video" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M22 20H19.59L16 16.41V20H2V8H4V18H14V14H16.41L20 17.59V6.41L16.41 10H14V6H2V4H16V7.59L19.59 4H22V20Z"
                fill={fill === 'gradient' ? 'url(#Video_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Video" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Video" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M22 20H19.59L16 16.41V20H2V8H4V18H14V14H16.41L20 17.59V6.41L16.41 10H14V6H2V4H16V7.59L19.59 4H22V20Z"
                fill={fill === 'gradient' ? 'url(#Video_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Video" />}
        </g>
    ),
}
