import { useContext } from 'react'
import { setCookie } from 'react-use-cookie'
import { useApolloClient, useMutation } from '@apollo/react-hooks'

import { debug } from '@bc/logging'
import { Language, Locale, RegionCode, getUserLanguage } from '@bc/translations'
import { GQLRegion, GQLSourceRegion } from '@bc/types'
import patchUserDataMutation from '@frontend/pages/account/query.graphql'
import { config } from '@bc/config'
import { UserContext } from '@frontend/context'

export const useSetUserLanguage = () => {
    const { me } = useContext(UserContext)
    const client = useApolloClient()
    const [patchUserData, { loading }] = useMutation(patchUserDataMutation)
    const currentLanguage = getUserLanguage(me?.meta)

    const setUserLanguage = async (language: Language, region?: RegionCode) => {
        let userRegion = me?.meta.region as RegionCode
        let userSourceRegion = me?.meta.sourceRegion
        let userLanguage: Language = language

        if (region) {
            userRegion = region
            userSourceRegion =
                userRegion === GQLRegion.de
                    ? GQLSourceRegion.de
                    : userRegion === GQLRegion.pl
                    ? GQLSourceRegion.cee
                    : GQLSourceRegion.bnl
        }

        const regionLanguages = config[userRegion].languages
        userLanguage = regionLanguages.includes(language) ? language : regionLanguages?.[0] ?? ('en' as Language)

        debug.frontend(
            `Starting to set user language to ${userLanguage}, region to ${userRegion} and source region to ${userSourceRegion}`,
        )

        await patchUserData({
            variables: { language: userLanguage, region: userRegion, sourceRegion: userSourceRegion },
        })
        setAppLocale(`${userLanguage}-${userRegion}` as Locale)
    }

    const setAppLocale = async (locale: Locale) => {
        setCookie('locale', locale)
        await client.resetStore()
    }

    return { setUserLanguage, loading, me, currentLanguage }
}
