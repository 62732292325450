import styled from 'styled-components'

import { Card, colors, spacing } from '@bc/ui'

export const Box = styled(Card.Body)`
    position: relative;
    height: calc(100vh - 260px);
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
    background: ${colors.white};
    padding: ${spacing.sm}px;
`

export const Title = styled.div`
    position: relative;
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
    padding: ${spacing.sm}px;
    text-align: center;
`

export const Subtitle = styled(Title)`
    padding-top: 0;
`

export const Scroller = styled.div`
    position: relative;
    overflow: auto;
    height: 100%;
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;

    padding: ${spacing.sm}px;
`

export const ButtonBar = styled.div`
    position: relative;
    text-align: center;
    margin: ${spacing.sm}px 0;

    > * {
        margin: 0 5px;
    }
`
