import React, { useContext } from 'react'
import { GraphQLFormattedError } from 'graphql'

import { Text } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { PlatformConfigContext } from '@frontend/context'

import { PageError } from './page-error'

interface AccountUnavailableErrorProps {
    error?: GraphQLFormattedError
}

export const AccountUnavailableError = ({ error }: AccountUnavailableErrorProps) => {
    const code = error?.extensions?.code
    const t = useFormatMessage()
    const { appConfig } = useContext(PlatformConfigContext)

    let key
    switch (code) {
        case 'CustomerIdNotValidError':
            key = 'invalid-customer-id'
            break
        default:
            key = 'generic-error'
            break
    }

    return (
        <PageError title={t(`warning:${key}:title`)} text={t(`warning:${key}:explanation`)}>
            <Text block>
                {t(`warning:${key}:contact`, undefined, {
                    csrPhone: appConfig.phone.csr,
                })}
            </Text>
        </PageError>
    )
}
