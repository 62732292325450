import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Circle_InfoFilled: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Circle_InfoFilled" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 18H11V10H13V18ZM12 8C11.45 8 11 7.55 11 7C11 6.45 11.45 6 12 6C12.55 6 13 6.45 13 7C13 7.55 12.55 8 12 8Z"
                fill={fill === 'gradient' ? 'url(#Circle_InfoFilled_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Circle_InfoFilled" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Circle_InfoFilled" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 18H11V10H13V18ZM12 8C11.45 8 11 7.55 11 7C11 6.45 11.45 6 12 6C12.55 6 13 6.45 13 7C13 7.55 12.55 8 12 8Z"
                fill={fill === 'gradient' ? 'url(#Circle_InfoFilled_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Circle_InfoFilled" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Circle_InfoFilled" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 18H11V10H13V18ZM12 8C11.45 8 11 7.55 11 7C11 6.45 11.45 6 12 6C12.55 6 13 6.45 13 7C13 7.55 12.55 8 12 8Z"
                fill={fill === 'gradient' ? 'url(#Circle_InfoFilled_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Circle_InfoFilled" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Circle_InfoFilled" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 18H11V10H13V18ZM12 8C11.45 8 11 7.55 11 7C11 6.45 11.45 6 12 6C12.55 6 13 6.45 13 7C13 7.55 12.55 8 12 8Z"
                fill={fill === 'gradient' ? 'url(#Circle_InfoFilled_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Circle_InfoFilled" />}
        </g>
    ),
}
