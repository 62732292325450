import React from 'react'
import { Field } from 'react-final-form'

import graphqlUtils from '@bc/graphql-utils'
import { GQLMaterial } from '@bc/types'
import { TextInput } from '@bc/ui'
import { FormatMessage } from '@frontend/components'
import { useFormatNumber, useFormatUom, useParseFloatNumber, useParseNumber } from '@frontend/components/hooks'
import { finalFormValidations } from '@frontend/utils'

import { restrictions } from '@frontend/utils/validation'

import { positiveNumberRequired } from './validation'

interface PiecesFields {
    t: FormatMessage
    material: GQLMaterial
    uom?: string
}

export const OrderPiecesFormFields = ({ t, material, uom }: PiecesFields) => {
    const formatUom = useFormatUom()
    const parseNumber = useParseNumber()
    const parseFloatNumber = useParseFloatNumber()
    const n = useFormatNumber()

    const { packagingQuantity } = material

    const outputUnit = formatUom(uom ?? 'KGM')
    const inputUnit = formatUom(packagingQuantity.uom)
    const allowDecimalsInInput = graphqlUtils.material.isUomAllowDecimals(uom)
    const minimumOrderQuantity = graphqlUtils.material.getMinimumOrderQuantity(material)

    return (
        <>
            <Field
                name="quantity"
                validate={positiveNumberRequired(t, parseNumber)}
                render={({ input, meta }) => {
                    const { value } = input

                    return (
                        <TextInput
                            {...input}
                            autoFocus
                            labelText={
                                <span>
                                    {t('general:order-quantity')}
                                    {' *'}
                                </span>
                            }
                            pattern={
                                allowDecimalsInInput
                                    ? `^[0-9|\.|,]{0,${restrictions.MAX_SAFE_NUMBER_LENGTH}}$`
                                    : `^[0-9]{0,${restrictions.MAX_SAFE_NUMBER_LENGTH}}$`
                            }
                            placeholder="0"
                            min="0"
                            step="1"
                            hasError={value && meta.touched && meta.error}
                            errorText={value && meta.touched && meta.error}
                            onKeyDown={
                                allowDecimalsInInput
                                    ? restrictions.restrictFloatsKeyDown
                                    : restrictions.restrictNumbersKeyDown
                            }
                            append={outputUnit}
                            data-test-id="input-order-quantity"
                        />
                    )
                }}
            />
            <Field
                name="pieces"
                format={value => n(parseFloatNumber(value || 0))}
                validate={
                    typeof minimumOrderQuantity === 'number'
                        ? finalFormValidations.minAmount(t, minimumOrderQuantity, outputUnit)
                        : undefined
                }
                render={({ input, meta }) => (
                    <TextInput
                        {...input}
                        labelText={<span>{t('general:order-amount')}</span>}
                        readOnly
                        placeholder="0"
                        hasError={meta.touched && meta.error}
                        append={inputUnit}
                        data-test-id="input-order-amount"
                    />
                )}
            />
        </>
    )
}
