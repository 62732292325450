import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Spinner: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Spinner" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M12 22C6.49 22 2 17.51 2 12H4C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4V2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22Z"
                fill={fill === 'gradient' ? 'url(#Spinner_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Spinner" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Spinner" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M12 22C6.49 22 2 17.51 2 12H4C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4V2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22Z"
                fill={fill === 'gradient' ? 'url(#Spinner_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Spinner" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Spinner" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M12 22C6.49 22 2 17.51 2 12H4C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4V2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22Z"
                fill={fill === 'gradient' ? 'url(#Spinner_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Spinner" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Spinner" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M12 22C6.49 22 2 17.51 2 12H4C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4V2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22Z"
                fill={fill === 'gradient' ? 'url(#Spinner_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Spinner" />}
        </g>
    ),
}
