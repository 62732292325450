import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Group: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Group" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M14.9998 3C14.5898 3 14.2098 3.07 13.8398 3.17C14.3198 3.71 14.7198 4.32 14.9998 5C16.3798 5 17.4998 6.12 17.4998 7.5C17.4998 8.88 16.3798 10 14.9998 10C14.7198 10.68 14.3198 11.29 13.8398 11.83C14.2098 11.93 14.5998 12 14.9998 12C17.4798 12 19.4998 9.98 19.4998 7.5C19.4998 5.02 17.4798 3 14.9998 3ZM2 13V21H16V13H11V15H14V19H4V15H7V13H2ZM18 19V21H22V13H18V15H20V19H18ZM11.5 7.5C11.5 7.18 11.43 6.87 11.32 6.59L12.8 5.11C13.24 5.81 13.5 6.62 13.5 7.5C13.5 9.98 11.48 12 9 12C6.52 12 4.5 9.98 4.5 7.5C4.5 5.02 6.52 3 9 3C9.88 3 10.7 3.26 11.39 3.7L9.91 5.18C9.63 5.07 9.32 5 9 5C7.62 5 6.5 6.12 6.5 7.5C6.5 8.88 7.62 10 9 10C10.38 10 11.5 8.88 11.5 7.5Z"
                fill={fill === 'gradient' ? 'url(#Group_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Group" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Group" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M14.9998 3C14.5898 3 14.2098 3.07 13.8398 3.17C14.3198 3.71 14.7198 4.32 14.9998 5C16.3798 5 17.4998 6.12 17.4998 7.5C17.4998 8.88 16.3798 10 14.9998 10C14.7198 10.68 14.3198 11.29 13.8398 11.83C14.2098 11.93 14.5998 12 14.9998 12C17.4798 12 19.4998 9.98 19.4998 7.5C19.4998 5.02 17.4798 3 14.9998 3ZM2 13V21H16V13H11V15H14V19H4V15H7V13H2ZM18 19V21H22V13H18V15H20V19H18ZM11.5 7.5C11.5 7.18 11.43 6.87 11.32 6.59L12.8 5.11C13.24 5.81 13.5 6.62 13.5 7.5C13.5 9.98 11.48 12 9 12C6.52 12 4.5 9.98 4.5 7.5C4.5 5.02 6.52 3 9 3C9.88 3 10.7 3.26 11.39 3.7L9.91 5.18C9.63 5.07 9.32 5 9 5C7.62 5 6.5 6.12 6.5 7.5C6.5 8.88 7.62 10 9 10C10.38 10 11.5 8.88 11.5 7.5Z"
                fill={fill === 'gradient' ? 'url(#Group_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Group" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Group" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M14.9998 3C14.5898 3 14.2098 3.07 13.8398 3.17C14.3198 3.71 14.7198 4.32 14.9998 5C16.3798 5 17.4998 6.12 17.4998 7.5C17.4998 8.88 16.3798 10 14.9998 10C14.7198 10.68 14.3198 11.29 13.8398 11.83C14.2098 11.93 14.5998 12 14.9998 12C17.4798 12 19.4998 9.98 19.4998 7.5C19.4998 5.02 17.4798 3 14.9998 3ZM2 13V21H16V13H11V15H14V19H4V15H7V13H2ZM18 19V21H22V13H18V15H20V19H18ZM11.5 7.5C11.5 7.18 11.43 6.87 11.32 6.59L12.8 5.11C13.24 5.81 13.5 6.62 13.5 7.5C13.5 9.98 11.48 12 9 12C6.52 12 4.5 9.98 4.5 7.5C4.5 5.02 6.52 3 9 3C9.88 3 10.7 3.26 11.39 3.7L9.91 5.18C9.63 5.07 9.32 5 9 5C7.62 5 6.5 6.12 6.5 7.5C6.5 8.88 7.62 10 9 10C10.38 10 11.5 8.88 11.5 7.5Z"
                fill={fill === 'gradient' ? 'url(#Group_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Group" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Group" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M14.9998 3C14.5898 3 14.2098 3.07 13.8398 3.17C14.3198 3.71 14.7198 4.32 14.9998 5C16.3798 5 17.4998 6.12 17.4998 7.5C17.4998 8.88 16.3798 10 14.9998 10C14.7198 10.68 14.3198 11.29 13.8398 11.83C14.2098 11.93 14.5998 12 14.9998 12C17.4798 12 19.4998 9.98 19.4998 7.5C19.4998 5.02 17.4798 3 14.9998 3ZM2 13V21H16V13H11V15H14V19H4V15H7V13H2ZM18 19V21H22V13H18V15H20V19H18ZM11.5 7.5C11.5 7.18 11.43 6.87 11.32 6.59L12.8 5.11C13.24 5.81 13.5 6.62 13.5 7.5C13.5 9.98 11.48 12 9 12C6.52 12 4.5 9.98 4.5 7.5C4.5 5.02 6.52 3 9 3C9.88 3 10.7 3.26 11.39 3.7L9.91 5.18C9.63 5.07 9.32 5 9 5C7.62 5 6.5 6.12 6.5 7.5C6.5 8.88 7.62 10 9 10C10.38 10 11.5 8.88 11.5 7.5Z"
                fill={fill === 'gradient' ? 'url(#Group_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Group" />}
        </g>
    ),
}
