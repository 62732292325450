import moment from 'moment'
import React, { useContext, useState } from 'react'
import { Modifier } from 'react-day-picker/types/common'

import { Calendar } from '@frontend/components/calendar'
import { IntlContext, PlatformConfigContext } from '@frontend/context'
import { composeEventHandlers, parseDate } from '@frontend/utils'

import * as DatepickerComponents from './datepicker-components'

export interface DatepickerRenderProps {
    datepickerInput: (options?: any) => React.HTMLProps<HTMLInputElement> & { ref?: any }
    selectedDate: Date | undefined
    formattedDate: string
}

interface DatepickerBaseProps {
    onChange?: (value: string, date: Date | undefined) => void
    children?: (renderProps: DatepickerRenderProps) => JSX.Element
    render?: (renderProps: DatepickerRenderProps) => JSX.Element
    disabledDays?: Modifier | Modifier[]
    onClickOutside: (e: MouseEvent | TouchEvent) => void
    open: boolean
    initialMonth?: Date
}

interface DatepickerState {
    inputValue: string
    selectedDate: Date | undefined
    formattedDate: string
}

export const Datepicker = ({
    disabledDays,
    initialMonth,
    open,
    children,
    render,
    onClickOutside,
    onChange,
}: DatepickerBaseProps) => {
    const { locale } = useContext(IntlContext)
    const {
        appConfig: { dateFormat },
    } = useContext(PlatformConfigContext)

    const [state, setState] = useState<DatepickerState>({
        inputValue: '',
        formattedDate: '',
        selectedDate: undefined,
    })

    const { formattedDate, selectedDate } = state
    const selectedDays = selectedDate ? [selectedDate] : []

    const renderer = children || render

    const handleInputChange = (input: string) => {
        if (typeof onChange === 'function') {
            const { inputValue } = state
            onChange(inputValue, new Date(inputValue))
        }

        setState({
            ...state,
            inputValue: input,
        })
    }

    const handleSelectDate = (date: Date) => {
        formatDate(date)
    }

    const formatDate = (input: Date | string) => {
        const parsedDate = typeof input === 'string' ? parseDate(input, dateFormat) : input
        const newFormattedDate = moment(parsedDate).format(dateFormat)

        setState({
            selectedDate: parsedDate,
            inputValue: newFormattedDate,
            formattedDate,
        })

        if (onChange) {
            onChange(newFormattedDate, parsedDate)
        }
    }

    const getDatepickerInput = () => (props: any = {}): React.HTMLProps<HTMLInputElement> => ({
        value: state.inputValue,
        ...props,
        onChange: composeEventHandlers(
            (event: React.SyntheticEvent<HTMLInputElement>) => handleInputChange(event.currentTarget.value),
            props.onChange,
        ),
    })

    const datepickerInput = getDatepickerInput()

    return (
        <DatepickerComponents.Wrapper>
            {renderer &&
                renderer({
                    formattedDate,
                    selectedDate,
                    datepickerInput,
                })}
            <DatepickerComponents.Calendar isHidden={!open}>
                <Calendar
                    disabledDays={disabledDays}
                    selectedDays={selectedDays}
                    locale={locale}
                    initialMonth={initialMonth}
                    onSelect={handleSelectDate}
                    onClickOutside={e => onClickOutside(e)}
                />
            </DatepickerComponents.Calendar>
        </DatepickerComponents.Wrapper>
    )
}
