import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Play: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Play" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M6 22.91V5.96L8 7.34V19.09L18.24 12L6.69 4L6 3.52V1.09L21.76 12L6 22.91Z"
                fill={fill === 'gradient' ? 'url(#Play_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Play" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Play" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M6 22.91V5.96L8 7.34V19.09L18.24 12L6.69 4L6 3.52V1.09L21.76 12L6 22.91Z"
                fill={fill === 'gradient' ? 'url(#Play_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Play" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Play" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M6 22.91V5.96L8 7.34V19.09L18.24 12L6.69 4L6 3.52V1.09L21.76 12L6 22.91Z"
                fill={fill === 'gradient' ? 'url(#Play_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Play" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Play" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M6 22.91V5.96L8 7.34V19.09L18.24 12L6.69 4L6 3.52V1.09L21.76 12L6 22.91Z"
                fill={fill === 'gradient' ? 'url(#Play_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Play" />}
        </g>
    ),
}
