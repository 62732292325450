import { env } from '@frontend/config'

interface LoginMfaProps {
    code: string
    state?: string
}

export const loginMfa = (options: LoginMfaProps) => {
    const { code, state } = options
    const domain = `https://${env.AUTH0_DOMAIN}`
    const url = `${domain}/continue?state=${state}&email_code=${code}`
    window.location.replace(url)
}
