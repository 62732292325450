import React from 'react'

import { RegionCode } from '@bc/translations/src/types'
import { Text } from '@bc/ui'
import { useFormatMessage } from '@frontend/components'

import { IntroText } from './login-components'
import LoginOfficeInfo from './login-office-info'

interface LoginHelpProps {
    locale: string
}
const COUNTRY_CODE_REGEXP = /^[a-zA-Z]{2}-([a-zA-Z]{2})$/

export default function LoginHelp({ locale }: LoginHelpProps) {
    const t = useFormatMessage()
    const countryMatch = COUNTRY_CODE_REGEXP.exec(locale)
    const countryCode: RegionCode | null = countryMatch && (RegExp.$1 as RegionCode)
    const allCountryCodes: RegionCode[] = ['de', 'nl', 'be', 'pl']

    return (
        <>
            <Text h6 color="white" data-test-id="text-login-title">
                {t('login:help-title')}
            </Text>
            <IntroText p>{t('login:help-instruction')}</IntroText>
            <IntroText p />

            {countryCode ? (
                <LoginOfficeInfo countryCode={countryCode} />
            ) : (
                allCountryCodes.map(code => <LoginOfficeInfo key={code} countryCode={code} />)
            )}
        </>
    )
}
