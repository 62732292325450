import React, { useContext } from 'react'
import styled from 'styled-components'
import { OptionsType, ActionMeta } from 'react-select'

import { GQLRoleType, GQLUser, GQLUserSalesArea } from '@bc/types'
import {
    Button,
    CardEnriched,
    colors,
    SvgIcon,
    Text,
    TextHighlight,
    RichRowListItemProps,
    SelectInputOption,
    SelectInput,
} from '@bc/ui'
import { useFormatDate, useFormatMessage, useFormatTime } from '@frontend/components/hooks'
import { trimLeadingZeros, userRoleAsIconName } from '@frontend/utils'
import { FiltersContextData, FiltersContext } from '@frontend/context'
import { UserFilters } from '@frontend/pages/user-management/user-management-types'
import { ButtonIcon } from '@bc/ui/src/components/button/button-icon'

const OptionAction = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 5px;
`
const OptionActionText = styled.span`
    text-align: left;
`
const UserIcon = styled(SvgIcon)`
    top: 5px;
    margin-right: 5px;
`

interface UserCardProps {
    user: GQLUser
    onEditUser: () => void
    readOnly: boolean
    onEmailAction: (option: SelectInputOption, meta: ActionMeta) => void
    emailActionOptions: OptionsType<SelectInputOption>
}

const CardEnrichedWarning = styled(CardEnriched)`
    background-color: ${colors.neutral10};
    border: 2px solid ${colors.red70};
`

export const UserCard = ({ user, onEditUser, onEmailAction, emailActionOptions, readOnly }: UserCardProps) => {
    const formatDate = useFormatDate()
    const formatTime = useFormatTime()
    const t = useFormatMessage()
    const filterContext: FiltersContextData<UserFilters> = useContext(FiltersContext)
    const { customerId: filterCustomerId, email: filterEmail } = filterContext?.query ?? {}

    const { firstName, lastName, role, lastLogin, email, region, limitUserToSalesAreas, receivedWelcomeEmail } = user

    const customerSalesAreasToDisplay = (
        customerSalesAreas: GQLUserSalesArea[],
        highlight?: string,
    ): string | JSX.Element => (
        <>
            {customerSalesAreas
                ?.map(
                    ({ salesAreas, customerId }) =>
                        `${trimLeadingZeros(customerId)} [ ${salesAreas
                            .map(({ salesOrganisation }) => salesOrganisation)
                            .join(', ')} ]`,
                )
                .map((value: string) => (
                    <Text key={value}>
                        <TextHighlight search={highlight}>{value}</TextHighlight>
                        <br />
                    </Text>
                ))}
        </>
    )

    const customerLabelToDisplay = (userRole: GQLRoleType = GQLRoleType.user): string | undefined =>
        [GQLRoleType.user, GQLRoleType.viewer].includes(userRole as any) ? t('user:customer-id') : undefined

    const userCardTitle = () => (
        <>
            <UserIcon icon={userRoleAsIconName(role?.type)} size="24" fill={colors.neutral90} />
            {`${firstName || ''} ${lastName || ''}`}
        </>
    )

    const list: RichRowListItemProps[] = [
        {
            label: t('user:last-login'),
            value: lastLogin ? `${formatDate(lastLogin)} ${formatTime(lastLogin)}` : '-',
        },
        {
            label: t('user:role'),
            value: t(`filters:search-by-${role?.type ?? 'user'}.placeholder`),
            id: 'role',
        },
        {
            label: t('user:country'),
            value: region?.toUpperCase() ?? '-',
        },
        {
            label: customerLabelToDisplay(role?.type),
            value: customerSalesAreasToDisplay(limitUserToSalesAreas, filterCustomerId),
            id: 'customer-id',
        },
        {
            label: t('user:email-welcome-sent'),
            value: receivedWelcomeEmail ? formatDate(receivedWelcomeEmail) : '-',
        },
        {
            label: t('user:admin-actions'),
            value: (
                <SelectInput
                    data-test-id="admin-actions-select"
                    classNamePrefix="user-card-admin-actions"
                    isSearchable={false}
                    menuPlacement="top"
                    onChange={onEmailAction}
                    options={emailActionOptions}
                    controlShouldRenderValue={false}
                    placeholder={t('user:select-admin-action')}
                    formatOptionLabel={({ label }) => (
                        <OptionAction>
                            <ButtonIcon icon="Mail" size="20" fill="currentColor" disableMargin />
                            <OptionActionText>{label}</OptionActionText>
                        </OptionAction>
                    )}
                />
            ),
        },
    ]

    const actions =
        readOnly || role?.type === GQLRoleType.admin ? undefined : (
            <Button variant="solid" title={t('user:edit-user')} onClick={onEditUser} data-test-id="button-edit-user">
                {t('user:edit-user')}
            </Button>
        )

    const userDataBroken =
        [GQLRoleType.user, GQLRoleType.viewer].includes(role?.type as any) &&
        !Boolean(user.limitUserToSalesAreas?.length)

    const CardConditional = userDataBroken ? CardEnrichedWarning : CardEnriched
    return (
        <CardConditional
            header={{
                title: userCardTitle(),
                subtitle: <TextHighlight search={filterEmail}>{email}</TextHighlight>,
            }}
            data-test-class={`card-user`}
            data-test-id={`card-user-${email}`}
            data-test-username={`card-user-${firstName}`}
            numberOfRows={2}
            actions={actions}
            list={list}
        />
    )
}
