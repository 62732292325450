import { RouteProps } from 'react-router-dom'

import { RegionFeatures, Resources, ShopFeatures } from '@bc/config'
import { Locale, locales } from '@bc/translations'
import { GQLRoleType } from '@bc/types'
import { getLocalizedPath, routes as routesConfig } from '@frontend/config'
import * as Pages from '@frontend/pages'
import { useMemo } from 'react'

export interface RouteItem extends RouteProps {
    public?: boolean
    feature?: (RegionFeatures | ShopFeatures | Resources)[]
    role?: GQLRoleType
}

type Routes = { [key in Locale]: RouteItem[] }

export const useAppRoutes = () =>
    useMemo(
        () =>
            locales.reduce<Routes>(
                (routesMap: Routes, locale: Locale) => ({
                    ...routesMap,
                    [locale]: routesConfig.map(path => ({
                        path: getLocalizedPath(path, locale),
                        ...getRouteSettings(path),
                    })),
                }),
                // tslint:disable-next-line:no-object-literal-type-assertion
                {} as Routes,
            ),
        [],
    )

function getRouteSettings(path: string): Partial<RouteItem> {
    switch (path) {
        case 'route:terms-of-usage':
            return {
                component: Pages.TermsOfUsagePage,
                exact: true,
                public: true,
            }
        case 'route:maintenance':
            return {
                component: Pages.MaintenancePage,
                exact: true,
                public: true,
            }
        case 'route:session-expired':
            return {
                component: Pages.SessionExpiredPage,
                exact: true,
                public: true,
            }
        case 'route:document-not-found':
            return {
                component: Pages.DocumentNotFoundPage,
                exact: true,
            }
        case 'route:logout':
            return {
                component: Pages.LogoutPage,
                exact: true,
                public: true,
            }
        case 'route:forgot-password':
            return {
                component: Pages.ForgotPasswordPage,
                exact: true,
                public: true,
            }
        case 'route:invoices':
            return {
                component: Pages.InvoicesContainer,
                feature: [ShopFeatures.Invoices, Resources.Invoices],
                exact: true,
            }
        case 'route:order-history':
            return {
                component: Pages.OrderHistory,
                feature: [Resources.Orders],
            }
        case 'route:product-list':
            return {
                component: Pages.ProductList,
                feature: [Resources.ProductList],
            }
        case 'route:account':
            return {
                component: Pages.AccountContainer,
                exact: true,
            }
        case 'route:my-customers':
            return {
                component: Pages.MyCompaniesPage,
                feature: [Resources.MyCompanies],
                exact: true,
            }
        case 'route:contacts':
            return {
                component: Pages.UserManagementPage,
                feature: [Resources.Users],
                exact: true,
            }
        case 'route:quotes':
            return {
                component: Pages.QuotesPage,
                exact: true,
                feature: [ShopFeatures.Quotes, Resources.Quotes],
            }
        case 'route:requests':
            return {
                component: Pages.RequestsPage,
                feature: [RegionFeatures.Requests, Resources.Requests],
                exact: true,
            }
        case 'route:cart-overview':
            return {
                component: Pages.CartCheckout,
                feature: [ShopFeatures.ShoppingCart, Resources.Checkout],
            }
        case 'route:reset-password':
            return {
                component: Pages.ResetPasswordPage,
                exact: true,
                public: true,
            }
        case 'route:set-password':
            return {
                component: Pages.SetPasswordPage,
                exact: true,
                public: true,
            }
        default:
            return {}
    }
}
