import { config } from '@bc/config'

import { translations } from './translations'
import { ActiveLanguagesForRegion, Language, Locale, RegionCode } from './types'

export const locales = Object.keys(translations) as Locale[]
export const defaultLocale: Locale = 'en-de'
export const languages = ['en', 'de', 'nl', 'fr', 'pl']

export const languageLabels: { [key in Language]: string } = {
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
    nl: 'Nederlands',
    pl: 'Polski',
}

export const regions: ActiveLanguagesForRegion = Object.keys(config)
    // Get all regions defined by bc-config, but leave out all regions that don't have any locales defined
    .filter((regionCode): regionCode is RegionCode => config[regionCode as RegionCode].languages!.length > 0)
    // Transform from array to object style
    .reduce(
        (activeLocales: ActiveLanguagesForRegion, regionCode: RegionCode) => ({
            ...activeLocales,
            [regionCode]: config[regionCode].languages!,
        }),
        {},
    )

// TODO custom locales cast for pl??
export const customLocalesCasts: { [key in Locale]?: string } = {
    'en-de': 'en-gb',
    'en-nl': 'en-gb',
    'en-be': 'en-gb',
    'en-pl': 'en-gb',
    'fr-nl': 'fr-be',
}
