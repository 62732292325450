import { GQLMaterial } from '@bc/types'
import { EcommerceTag } from '../types'
import { dataLayer } from '../utils'

interface Impression {
    name: string
    id: string
    price: number | string
    brand: string | undefined
    variant?: string
    list: string
    position: number
}
type ProductImpressionsTag = EcommerceTag<{
    currencyCode: string
    impressions: Impression[]
}>
export const productImpressions = (materials: GQLMaterial[], list: 'product list' | 'my products') => {
    dataLayer<ProductImpressionsTag>({
        event: 'productImpressions',
        ecommerce: {
            currencyCode: '-', // TODO: basePriceCurrency
            impressions: materials.map((material, index) => ({
                name: material.name || '-',
                id: material.id,
                // price: material.basePriceAmount
                //     ? material.basePriceAmount * material.piecesToMt
                //     : '-',
                price: '-',
                brand: material.manufacturer ? material.manufacturer.name : '-',
                list,
                position: index + 1,
            })),
        },
    })
}
