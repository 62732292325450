// tslint:disable no-invalid-template-strings

import { MessageMap } from '..'
import { fr } from './base'

const messages: MessageMap = {
    ...fr,

    // Overwrite translations keys for region/language specific differences (i.e. colors/colours)
}

export default messages
