import React from 'react'

import * as ContactItemComponents from './contact-item-components'

interface ContactItemProps {
    text: string
    icon?: JSX.Element
    link?: string
}

export const ContactItem = ({ icon, link, text, ...rest }: ContactItemProps) => (
    <ContactItemComponents.Wrapper href={link} {...rest}>
        {icon && <ContactItemComponents.Icon>{icon}</ContactItemComponents.Icon>}
        {text}
    </ContactItemComponents.Wrapper>
)
