import moment from 'moment'

import { FormatMessage } from '@frontend/components/hooks'

export const parseDate = (input: string = '', format: string = 'MM/DD/YYYY'): Date | undefined => {
    // replace dashes with default format we allow 02-02-2021
    const formattedDate = input.replace(/-/g, '/')
    const formattedFormat = format.replace(/-/g, '/')
    const date = moment.utc(formattedDate, formattedFormat, true)
    return date.isValid() ? date.toDate() : undefined
}

export const dateToISO = (date: Date): string =>
    new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()

export const getDateFormatLocalized = (t: FormatMessage, dateFormat: string = 'MM/DD/YYYY'): string => {
    const D = t('general:date-format.day')
    const M = t('general:date-format.month')
    const Y = t('general:date-format.year')

    return dateFormat.replace(/D/g, D).replace(/M/g, M).replace(/Y/g, Y)
}
