import React from 'react'

import { GQLPrice } from '@bc/types'
import { Column, Row, Text } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'

interface ModalPriceConversionProps {
    price: GQLPrice
    children?: React.ReactNode
}

export const ModalPriceConversionColumn = ({ price, children }: ModalPriceConversionProps) => {
    const t = useFormatMessage()
    return (
        <>
            {Boolean(price.conversionType) && (
                <Row>
                    <Column>
                        <Text caption noMargin>
                            {t('general:price-conversion')}
                        </Text>
                        <Text p data-test-id="text-currency-conversion">
                            {price.conversionType}
                        </Text>
                    </Column>
                </Row>
            )}
            {children}
        </>
    )
}
