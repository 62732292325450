import styled from 'styled-components'

import { colors, media, spacing } from '@bc/ui'
import { Document } from '@frontend/components/material-documents'

interface ContentProps {
    row: boolean
}

export const ResponseWrapper = styled.div<ContentProps>`
    display: flex;
    padding: ${({ row }: ContentProps) => (row ? spacing.sm : 0)}px;
    background-color: ${({ row }: ContentProps) => colors[row ? 'neutral10' : 'white']};
    flex-direction: ${({ row }: ContentProps) => (row ? 'row' : 'column')};
    margin: ${({ row }: ContentProps) => (row ? '10px 0 0 0' : '0')};
    ${media.max('sm')} {
        flex-wrap: wrap;
    }
    flex-grow: 1;
`

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: calc(100% * 2 / 3);
    margin: 0 0 20px 0;
    &:first-child {
        flex-basis: calc(100% / 3);
    }
    ${media.max('sm')} {
        flex-basis: 100%;
    }
`

export const ContactContainer = styled.div`
    text-align: left;
    ${media.max('sm')} {
        text-align: left;
    }
`

export const MetaWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    ${media.max('sm')} {
        flex-direction: column;
    }
`

export const RequestedDocument = styled(Document)`
    flex-grow: 0;
`
