import { env } from '@frontend/config'

import { Auth0Client } from './types'

export const auth0: Auth0Client = {
    client: undefined,
}

export const installAuth0 = () => {
    const auth0JS = require('auth0-js')
    auth0.client = new auth0JS.WebAuth({
        domain: env.AUTH0_DOMAIN || '',
        clientID: env.AUTH0_CLIENT_ID || '',
        audience: 'brenntag-connect-app-api',
        responseType: 'token',
        scope: 'openid name email nickname',
        redirectUri: env.FE_URL + '/handle-login',
    })
}
