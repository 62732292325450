import { EventTag } from '../types'
import { dataLayer } from '../utils'

export const login = (status: string) => {
    dataLayer<EventTag>({
        event: 'gtm.login',
        eventCategory: 'log in',
        eventAction: 'log in',
        eventLabel: status,
    })
}
