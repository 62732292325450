import React, { useContext } from 'react'
import styled from 'styled-components'

import graphqlUtils from '@bc/graphql-utils'
import { tagManager } from '@bc/gtm'
import { QuotationGroup } from '@bc/types'
import { Address, Button, CardEnriched, spacing } from '@bc/ui/'
import { useFormatMessage, useFormatNumber, useFormatUom } from '@frontend/components/hooks'
import { UserContext } from '@frontend/context'

import { FilterCopyLinkButton } from '../filters/filter-copy-link-button'
import { PriceScale } from '../price-scale'
import { MaterialPackaging } from './material-packaging'

interface QuoteCardBaseProps {
    quote: QuotationGroup
    reorder: (order: QuotationGroup) => void
    onLinkCopy?: (quoteid: string) => void
}

const QuoteOrderButton = styled(Button)`
    margin-left: ${spacing.sm}px;
`

export const QuoteCardBase = ({ quote, reorder, onLinkCopy }: QuoteCardBaseProps) => {
    const formatNumber = useFormatNumber()
    const t = useFormatMessage()
    const formatUom = useFormatUom()

    const { currentCustomer } = useContext(UserContext)
    const { id, prices, material, shipToId } = quote

    const shipTo = currentCustomer?.shipTos?.find(location => location.id === shipToId)
    const packagingDisplay = graphqlUtils.material.getPackagingQuantityLabel(
        material,
        formatNumber(material.packagingQuantity.amount),
        formatUom(material.packagingQuantity.uom),
    )
    // all terms are the same within the price group
    const price = prices[0]

    const list = [
        {
            label: t('general:offer-id'),
            value: id,
            id: 'quote-id',
        },
        {
            label: t('quotes:column.delivery-address'),
            tooltip: shipTo?.name ? <Address location={shipTo} /> : undefined,
            value: shipTo?.name ?? '-',
            id: 'ship-to-address',
        },
        {
            label: t('general:packaging'),
            value: packagingDisplay,
            id: 'packaging',
        },
        {
            label: t('general:unit-price'),
            value: material.volatile ? t('general:price-confirmation') : <PriceScale prices={prices} />,
            id: 'unit-price',
        },
        {
            label: t('delivery-overview:terms-of-delivery'),
            value: price.shippingConditions ?? '-',
            id: 'delivery-terms',
        },
        {
            label: t('delivery-overview:INCO-terms'),
            value: price.incoTermId ?? '-',
            id: 'inco-terms',
        },
        {
            label: t('delivery-overview:packaging'),
            value: price.packagingControl ?? '-',
            id: 'packaging',
        },
        {
            label: t('delivery-overview:payment-terms'),
            value: price.paymentTerms ?? '-',
            id: 'payment-terms',
        },
    ]

    const handleQuoteOrderClick = (selectedQuote: QuotationGroup) => {
        tagManager.tags.quoteProductClick(selectedQuote)
        reorder(selectedQuote)
    }

    return (
        <CardEnriched
            numberOfRows={2}
            header={{
                title: `${material.name}`,
                subtitle: <MaterialPackaging material={material} />,
            }}
            data-test-id={`card-quote-${id}`}
            data-test-class={`card-quote`}
            actions={
                <>
                    {onLinkCopy && <FilterCopyLinkButton onClick={() => onLinkCopy(id)} />}
                    <QuoteOrderButton
                        variant="action"
                        data-test-id="button-reorder-quote"
                        onClick={() => handleQuoteOrderClick(quote)}>
                        {t('request-information-modal:order')}
                    </QuoteOrderButton>
                </>
            }
            list={list}
        />
    )
}
