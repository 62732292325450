import React, { useContext, useEffect } from 'react'

import { GQLBaseOrderLine } from '@bc/types'
import { FullScreenLoaderRound } from '@bc/ui'
import { SessionContext, SessionContextData, ShoppingCartContext, ShoppingCartContextData } from '@frontend/context'

export const LogoutPage = () => {
    const [shoppingStore]: ShoppingCartContextData<GQLBaseOrderLine> = useContext(ShoppingCartContext)
    const [sessionStore]: SessionContextData = useContext(SessionContext)
    useEffect(() => {
        sessionStore.logout()
        shoppingStore.clear()
    }, [])

    return <FullScreenLoaderRound />
}
