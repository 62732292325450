import styled from 'styled-components'

import { Text } from '@bc/ui/src/components/text'
import { colors } from '@bc/ui/src/config'

interface HighlightProps {
    border?: string
    children: string
}

export const Highlight = styled(Text)<HighlightProps>`
    background-color: ${colors.yellow10};
    ${({ border }) => (border ? `border: ${border}` : ``)};
`
