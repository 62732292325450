import React from 'react'

import { defaultConfig, roleConfig, RoleConfig, SettingsRegion, shopConfig, ShopConfig } from '@bc/config'

interface PlatformConfigContextProps {
    appConfig: SettingsRegion
    shopConfig: ShopConfig
    roleConfig: RoleConfig
}

export const PlatformConfigContext = React.createContext<PlatformConfigContextProps>({
    appConfig: defaultConfig,
    shopConfig,
    roleConfig,
})
