import { EventTag } from '../types'
import { dataLayer } from '../utils'

export const editInfo = (section: string, saved: boolean) => {
    dataLayer<EventTag>({
        event: 'gtm.editInfo',
        eventCategory: 'Order review',
        eventAction: `Edit: ${section}`,
        eventLabel: saved ? 'saved' : 'unsaved',
    })
}
