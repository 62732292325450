import styled, { css } from 'styled-components'

import { SvgIcon } from '@bc/ui/src/components/svg'
import { colors, spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'
import { Text } from '../text'

import { ThemeTypes } from './tooltip'

interface PopperBoxProps {
    ref: React.Ref<any>
    theme: ThemeTypes
}

const themeStyles = {
    default: `
        background: ${colors.deepPurple};
        color: ${colors.white};
        border: 1px solid ${colors.deepPurple};
    `,
    light: `
        background: ${colors.white};
        color: ${colors.deepPurple};
        border: 1px solid ${colors.neutral20};
    `,
    button: `
        background: ${colors.deepPurple};
        color: ${colors.white};
        border: 1px solid ${colors.deepPurple};
    `,
}

export const PopperBox = styled.span`
    background: ${colors.deepPurple};
    border-radius: 3px;
    max-width: 350px;
    position: relative;
    display: block;
    color: ${colors.white};
    padding: 10px ${spacing.sm}px;
    ${({ theme }: PopperBoxProps) => themeStyles[theme as ThemeTypes]}

    p, span, div {
        margin: 0;
    }

    [data-x-out-of-boundaries] & {
        display: none;
    }
`

const themeStylesArrow = {
    default: `
        background: ${colors.deepPurple};
        border: 0;
    `,
    light: `
        background: ${colors.white};
    `,
    button: `
        background: ${colors.deepPurple};
        border: 0;
    `,
}

export const Arrow = styled.span`
    width: 14px;
    height: 14px;
    z-index: 1;
    position: absolute;
    margin: 7px;
    background: ${colors.deepPurple};
    transform: rotate(45deg);
    display: block;
    border: 1px solid;

    ${media.max('sm')} {
        display: none;
    }

    &[data-placement*='top'] {
        border-color: transparent ${colors.neutral20} ${colors.neutral20} transparent;
        bottom: -7px;
        left: calc(50% - 14px);
        margin-top: 0;
    }

    &[data-placement*='bottom'] {
        border-color: ${colors.neutral20} transparent transparent ${colors.neutral20};
        top: -7px;
        left: calc(50% - 14px);
        margin-bottom: 0;
    }

    &[data-placement*='right'] {
        border-color: transparent transparent ${colors.neutral20} ${colors.neutral20};
        left: -8px;
        top: calc(50% - 14px);
        margin-left: 0;
        margin-right: 0;
    }

    &[data-placement*='left'] {
        border-color: ${colors.neutral20} ${colors.neutral20} transparent transparent;
        right: -8px;
        top: calc(50% - 14px);
        margin-left: 0;
        margin-right: 0;
    }

    ${({ theme }: PopperBoxProps) => themeStylesArrow[theme as ThemeTypes]}
`

export const Icon = styled(SvgIcon)`
    display: block;
`

const ButtonStyles = css`
    padding: 1rem;
    margin: 0;
`

export const IconWrapper = styled.span`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 0;
    margin: 0 0 0 5px;
    ${({ theme }: PopperBoxProps) => theme === ('button' as ThemeTypes) && ButtonStyles}
`

export const SubjectWrapper = styled.div`
    pointer-events: none;
`

export const PopperWrapper = styled.span`
    z-index: 5;
`
export const TextWrapper = styled(Text)`
    display: inline-flex;
`
