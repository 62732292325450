import React from 'react'

import { tagManager } from '@bc/gtm'
import { GQLRequest, GQLUpdateRequestInput } from '@bc/types'
import { colors, Modal, SvgIcon, Text } from '@bc/ui'
import { EditRequestForm } from '@frontend/components/forms'
import { FormatMessage } from '@frontend/components/hooks'
import { hasCSRResponse, RequestResponse, RequestSummary } from '@frontend/components/request'
import { statusAsColor, statusAsIconName } from '@frontend/utils'

import { ModalTitle, RequestWrapper, ResponseHeader, StyledCSRLink } from './modal-elements'

interface EditRequestModalProps {
    open: boolean
    onClose: () => void
    onConfirm: (values: GQLUpdateRequestInput) => void
    t: FormatMessage
    request: GQLRequest
    isCsrLayout: boolean
    csrEmail: string
}

export const EditRequestModal = ({
    onClose,
    open,
    onConfirm,
    t,
    request,
    isCsrLayout,
    csrEmail,
}: EditRequestModalProps) => (
    <Modal
        id={'edit-request'}
        open={open}
        onClose={onClose}
        type="large"
        aside={
            <RequestWrapper>
                {isCsrLayout ? (
                    <EditRequestForm t={t} request={request} open={open} onConfirm={onConfirm} onCancel={onClose} />
                ) : hasCSRResponse(request) ? (
                    <RequestResponse t={t} request={request} isRow={false} isCsr={isCsrLayout} />
                ) : (
                    <>
                        <ResponseHeader>{t('requests:response')}</ResponseHeader>
                        <Text>{`${t('requests:questions')} `}</Text>
                        <StyledCSRLink
                            href={`mailto:${csrEmail}`}
                            onClick={() => tagManager.tags.requestsEmail(csrEmail)}>
                            {csrEmail}
                        </StyledCSRLink>
                    </>
                )}
            </RequestWrapper>
        }
        content={[
            <ModalTitle
                title={t(`requests:edit-modal-summary`)}
                icon={
                    <SvgIcon
                        icon={statusAsIconName(request.type)}
                        size="32"
                        fill={colors[statusAsColor(request.status)]}
                    />
                }
            />,
            <RequestSummary request={request} />,
        ]}
    />
)
