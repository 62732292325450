import { colors } from '@bc/ui'
import styled from 'styled-components'

interface StatusColorProps {
    color?: string
}

export const FieldIcon = styled.span<StatusColorProps>`
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    ${({ color }) => `background-color: ${colors[color as keyof typeof colors] || 'transparent'};`}
`

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
