import React from 'react'

import { MessageMap } from '@bc/translations'
import { GQLUpdateUserInput, GQLUser } from '@bc/types'
import { colors, Modal, Text } from '@bc/ui'
import { EditUserForm } from '@frontend/components/forms'
import { FormatMessage } from '@frontend/components/hooks'

export interface EditUserModalProps {
    open: boolean
    type: 'edit' | 'create'
    onClose: () => void
    onConfirm: (values: GQLUpdateUserInput) => void
    onDelete?: () => void
    validateEmailAvailable?: (email: string) => Promise<string | undefined>
    t: FormatMessage
    contact?: GQLUser
    loading: boolean
}

export const EditUserModal = ({
    onClose,
    open,
    onConfirm,
    onDelete,
    validateEmailAvailable,
    t,
    type,
    contact,
    loading,
}: EditUserModalProps) => {
    const title = t(`user:${type}-user-modal-title` as keyof MessageMap)

    return (
        <Modal
            id={`${type}-user`}
            open={open}
            onClose={onClose}
            type="small"
            color={colors.white}
            content={[
                <Text key="text" h3>
                    {title}
                </Text>,
                <EditUserForm
                    key="form"
                    open={open}
                    type={type}
                    t={t}
                    contact={contact}
                    onConfirm={onConfirm}
                    onClose={onClose}
                    onDelete={onDelete}
                    validateEmailAvailable={validateEmailAvailable}
                    loading={loading}
                />,
            ]}
        />
    )
}
