import { ButtonTo } from '@bc/ui/src/components'
import React from 'react'
import styled from 'styled-components'
import { spacing } from '@bc/ui'
import { useFormatMessage } from '../hooks'

const NavMenuContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-self: center;
    align-items: center;
    gap: ${spacing.sm}px;
`

export const NavigationUserMenu = () => {
    const t = useFormatMessage()

    return (
        <NavMenuContainer>
            <ButtonTo variant="text" icon="User" to={t('route:account')} data-test-id={`link-account`}>
                {t('account:profile')}
            </ButtonTo>
            <ButtonTo variant="text" to={t('route:logout')} data-test-id={`link-logout`}>
                {t('route:logout.label')}
            </ButtonTo>
        </NavMenuContainer>
    )
}
