import React, { useContext, useEffect, useState } from 'react'

import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { GraphQLError } from 'graphql'

import { ShopFeatures } from '@bc/config'
import graphqlUtils from '@bc/graphql-utils'
import { tagManager } from '@bc/gtm'
import {
    GQLBaseOrder,
    GQLBaseOrderLine,
    GQLLocation,
    GQLMaterial,
    GQLPrice,
    GQLPrices,
    GQLRequestQuoteInput,
    GQLRequestType,
    GTMBaseOrderData,
} from '@bc/types'
import { Button, Modal } from '@bc/ui/src'
import { ReorderForm } from '@frontend/components/forms'
import { ReorderFormValues } from '@frontend/components/forms/reorder/reorder-form.types'
import { useFormatMessage, useFormatUom, useHasAccess, useParseFloatNumber } from '@frontend/components/hooks'
import { PlatformConfigContext, ToastsContext, ToastsContextData, UserContext } from '@frontend/context'
import { getPriceConditions, requestQuote } from '@frontend/pages/my-orders/base/queries'

import { arrayGroupBy, getCurrentTax, parseDate, removeTypename } from '@frontend/utils'

import * as SharedFormComponents from '../forms/_shared'
import { isDeliveryDateValid } from '../forms/_shared/validation'
import {
    ModalGeneralConditions,
    ModalMaterialInfoColumn,
    ModalPriceConversionColumn,
    ModalTitle,
} from './modal-elements'

interface ReorderModalProps {
    isOpen: boolean
    order: GQLBaseOrder | undefined
    orderLine: GQLBaseOrderLine | undefined
    onClose: () => void
}

export const ReorderModal = ({ order, orderLine, onClose, isOpen }: ReorderModalProps) => {
    const client = useApolloClient()
    const t = useFormatMessage()
    const parseFloatNumber = useParseFloatNumber()
    const formatUom = useFormatUom()

    const { currentCustomer } = useContext(UserContext)
    const [toastsStore]: ToastsContextData = useContext(ToastsContext)
    const hasAccess = useHasAccess()

    const {
        appConfig: { deliveryDateRestrictions, dateFormat, taxes },
    } = useContext(PlatformConfigContext)

    const customerShipTos: GQLLocation[] = graphqlUtils.customer.getShipTos(currentCustomer)
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState<boolean>(false)
    const [prices, setPrices] = useState<GQLPrice[][]>([])
    const [formValues, setFormValues] = useState<Partial<ReorderFormValues>>({})

    const [requestQuoteMutation, { loading: requestLoading }] = useMutation(requestQuote)

    const firstEligibleDeliveryDate: Date = graphqlUtils.order.getFirstEligibleDeliveryDate(
        deliveryDateRestrictions.reorderDate,
        hasAccess(ShopFeatures.NextDayDelivery),
    )
    const lastEligibleDeliveryDate: Date = graphqlUtils.order.getLastEligibleDeliveryDate(
        deliveryDateRestrictions.endDate,
    )
    const lastEligibleReorderDate: Date = graphqlUtils.order.getLastEligibleDeliveryDate(
        deliveryDateRestrictions.reorderEndDate,
    )

    const material = orderLine?.material
    const priceConditions = order?.priceConditions
    const pickupAvailable = graphqlUtils.price.isPickupAvailable(priceConditions as GQLPrice)

    const confirmPrice = Boolean(!(prices.length > 0 && formValues.price) || material?.volatile)
    const currentPrice = confirmPrice ? undefined : prices[Number(formValues.price)][0]

    useEffect(
        () => () => {
            resetModal()
        },
        [isOpen],
    )

    useEffect(() => {
        if (formValues.pickup) {
            setPrices([])
        } else if (formValues.requestedDeliveryDate && formValues.shipToId) {
            ;(async () => {
                const newPrices = await handleUpdatePriceConditions()
                setPrices(newPrices)
            })()
        }
    }, [formValues.requestedDeliveryDate, formValues.quantity, formValues.shipToId, formValues.pickup])

    const resetModal = () => {
        setStep(1)
        setLoading(false)
        setFormValues({})
        setPrices([])
    }

    const isReorderDateValid = (date: string): boolean =>
        isDeliveryDateValid(t, firstEligibleDeliveryDate, lastEligibleReorderDate, dateFormat, date)

    const handleSubmit = async (newValues: ReorderFormValues) => {
        try {
            const uid = await sendRequestQuoteMutation(newValues)

            if (uid) {
                handlePurchase(uid, newValues)
            }

            handleReorderMutationSuccess()
        } catch (e) {
            handleReorderMutationError(e)
        }
        resetAndCloseModal()
    }

    const sendRequestQuoteMutation = async (newValues: ReorderFormValues) => {
        const { price, pieces, quantity = '0', ...orderInput } = newValues
        const values: GQLRequestQuoteInput = {
            ...orderInput,
            requirements: ['quote' as GQLRequestType.QUOTE],
            quantity: parseFloatNumber(quantity),
            pieces: parseFloatNumber(pieces),
            prices: price !== undefined ? prices[Number(price)] : [],
            uom: orderLine?.quantityOrdered.uom ?? '',
            isBulk: Boolean(material?.isBulk),
        }

        const requestResult = await requestQuoteMutation({
            variables: {
                materialId: material?.id ?? '0',
                values,
            },
        })

        return requestResult?.data?.requestQuote
    }

    const handleReorderMutationSuccess = () => {
        toastsStore.addToast({
            type: 'success',
            message: t('request-information-modal:confirmation'),
        })
    }

    const handleReorderMutationError = ({
        graphQLErrors,
        networkError,
    }: {
        graphQLErrors: GraphQLError[]
        networkError: any
    }) => {
        if (graphQLErrors.length) {
            graphQLErrors?.map(({ message = '' }: Partial<GraphQLError>) => {
                toastsStore.addToast({
                    message,
                    type: 'error',
                })
            })
        } else if (networkError) {
            toastsStore.addToast({
                message: networkError.message,
                type: 'error',
            })
        }
    }

    const handleUpdatePriceConditions = async (): Promise<GQLPrice[][]> => {
        const { requestedDeliveryDate, quantity: quantityAsString = '0', shipToId } = formValues
        const quantity = parseFloatNumber(quantityAsString)
        const isValidDeliveryDate = requestedDeliveryDate && isReorderDateValid(requestedDeliveryDate)
        let newPrices: GQLPrice[][] = []

        if (requestedDeliveryDate !== undefined && !isValidDeliveryDate) {
            setLoading(false)
        }

        if (isValidDeliveryDate && quantity > 0 && !material?.volatile) {
            const selectedDate = parseDate(requestedDeliveryDate, dateFormat)!
            const unfilteredPrices = await updatePriceConditions(selectedDate, quantity, shipToId)
            const validPrices: GQLPrice[] = removeTypename(unfilteredPrices).filter(
                ({
                    validFrom,
                    validUntil,
                }: {
                    validFrom: string | number | Date
                    validUntil: string | number | Date
                }) => selectedDate >= new Date(validFrom) && selectedDate <= new Date(validUntil),
            )

            // Group prices by same conditionId
            newPrices = arrayGroupBy<GQLPrice>(validPrices, 'conditionId')
        }
        return newPrices
    }

    const updatePriceConditions = async (date: Date, quantity: number, shipToId?: string): Promise<GQLPrice[]> => {
        setLoading(true)
        try {
            const result = await client.query<{
                priceConditions: GQLPrices
            }>({
                query: getPriceConditions,
                variables: {
                    date,
                    materialId: material!.id,
                    quantity,
                    salesChannel: order!.salesChannel,
                    salesOrganisation: order!.salesOrganisation,
                    salesDivision: order!.salesDivision,
                    uom: orderLine?.quantityOrdered.uom ?? '',
                    shipToId,
                },
            })

            return result?.data?.priceConditions?.prices || []
        } catch (error) {
            handleReorderMutationError(error)
            return []
        } finally {
            setLoading(false)
        }
    }

    const getGTMECommerceData = (values: ReorderFormValues, GTMMaterial: GQLMaterial): GTMBaseOrderData => {
        const { customerPoReference, quantity = '0', requestedDeliveryDate, shipToId } = values
        const packagingName = GTMMaterial.packaging?.name
        const packagingUom = formatUom(GTMMaterial.packagingQuantity?.uom ?? 'KGM')
        const orderUom = formatUom(orderLine?.quantityOrdered.uom ?? 'KGM')
        const orderQuantity = parseFloatNumber(quantity)
        const orderAmount = GTMMaterial ? orderQuantity / GTMMaterial.packagingQuantity.amount : 0
        const pricePerUnit = currentPrice
            ? currentPrice.price.amount / (currentPrice.priceUnit?.amount || 1)
            : undefined
        const price = pricePerUnit ? parseFloat((pricePerUnit * orderQuantity).toFixed(2)) : undefined
        const currency = currentPrice ? currentPrice.price.currency : undefined
        const taxPercentage = getCurrentTax(taxes) / 100
        const tax = price ? parseFloat((price * taxPercentage).toFixed(2)) : 0

        return {
            customerPoReference,
            requestedDeliveryDate,
            price,
            pricePerUnit,
            packagingName,
            packagingUom,
            orderUom,
            orderAmount,
            orderQuantity,
            currency,
            tax,
            shipToId,
        }
    }

    const handleFormStep = (formStep: number) => {
        setStep(formStep)
    }

    const handleAddToCart = () => {
        if (material) {
            tagManager.tags.baseProductAddToCart(orderLine!)
        }
    }

    const handleRemoveFromCart = () => {
        if (material) {
            tagManager.tags.baseProductRemoveFromCart(orderLine!)
        }
    }

    const handlePurchase = (uid: string, values: ReorderFormValues) => {
        if (material) {
            tagManager.tags.basePurchase(uid, material, getGTMECommerceData(values, material))
        }
    }

    const onCloseModal = () => {
        if (material && step === 3) {
            handleRemoveFromCart()
        }
        resetAndCloseModal()
    }

    const resetAndCloseModal = () => {
        resetModal()
        onClose()
    }

    return (
        <Modal
            id={'re-order'}
            open={isOpen}
            onClose={onCloseModal}
            type="large"
            content={
                material && [
                    <ModalTitle
                        title={
                            step === 3
                                ? t('request-information-modal:request-quote-confirm')
                                : t('request-information-modal:request-quote')
                        }
                    />,
                    <ModalMaterialInfoColumn material={material} />,
                    currentPrice ? <ModalPriceConversionColumn price={currentPrice} /> : undefined,
                    <ModalGeneralConditions />,
                    <SharedFormComponents.ButtonsWrapper>
                        <Button
                            onClick={onCloseModal}
                            variant="outline"
                            title={t('general:cancel')}
                            data-test-id="button-reordermodal-cancel">
                            {t('general:cancel')}
                        </Button>
                    </SharedFormComponents.ButtonsWrapper>,
                ]
            }
            aside={
                isOpen
                    ? orderLine &&
                      material && [
                          <ReorderForm
                              onSubmit={handleSubmit}
                              onGTMAddToCart={handleAddToCart}
                              onGTMRemoveFromCart={handleRemoveFromCart}
                              onStep={handleFormStep}
                              orderLine={orderLine}
                              open={isOpen}
                              loading={requestLoading || loading}
                              material={material}
                              firstEligibleDeliveryDate={firstEligibleDeliveryDate}
                              lastEligibleDeliveryDate={lastEligibleDeliveryDate}
                              dateFormat={dateFormat}
                              customerShipTos={customerShipTos}
                              onUpdateForm={setFormValues}
                              prices={prices}
                              pickupAvailable={pickupAvailable}
                              orderShipTo={order?.shipTo.id}
                          />,
                      ]
                    : undefined
            }
        />
    )
}
