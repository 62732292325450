export const arrayGroupBy = <T>(array: T[], field: string): T[][] => [
    ...array
        .reduce((map: Map<any, T[]>, object: T) => {
            const objectGroup = map.get((object as any)[field]) || []
            objectGroup.push(object)
            map.set((object as any)[field], objectGroup)
            return map
        }, new Map())
        .values(),
]
