import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Trucks: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Trucks" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M19.54 5H16V3H2V5H14V8H2V10H14V17H8.22C7.67 16.39 6.88 16 6 16C5.23 16 4.53 16.3 4 16.78V12H2V19H3C3 20.65 4.35 22 6 22C7.65 22 9 20.65 9 19H15C15 20.65 16.35 22 18 22C19.65 22 21 20.65 21 19H22V8.7L19.54 5ZM6 20C5.45 20 5 19.55 5 19C5 18.45 5.45 18 6 18C6.55 18 7 18.45 7 19C7 19.55 6.55 20 6 20ZM18 20C17.45 20 17 19.55 17 19C17 18.45 17.45 18 18 18C18.55 18 19 18.45 19 19C19 19.55 18.55 20 18 20ZM20 16.78C19.47 16.3 18.77 16 18 16C17.23 16 16.53 16.3 16 16.78V12H18.46L20 14.3V16.78ZM19.54 10H16V7H18.46L20 9.3V10.69L19.54 9.99V10Z"
                fill={fill === 'gradient' ? 'url(#Trucks_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Trucks" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Trucks" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M19.54 5H16V3H2V5H14V8H2V10H14V17H8.22C7.67 16.39 6.88 16 6 16C5.23 16 4.53 16.3 4 16.78V12H2V19H3C3 20.65 4.35 22 6 22C7.65 22 9 20.65 9 19H15C15 20.65 16.35 22 18 22C19.65 22 21 20.65 21 19H22V8.7L19.54 5ZM6 20C5.45 20 5 19.55 5 19C5 18.45 5.45 18 6 18C6.55 18 7 18.45 7 19C7 19.55 6.55 20 6 20ZM18 20C17.45 20 17 19.55 17 19C17 18.45 17.45 18 18 18C18.55 18 19 18.45 19 19C19 19.55 18.55 20 18 20ZM20 16.78C19.47 16.3 18.77 16 18 16C17.23 16 16.53 16.3 16 16.78V12H18.46L20 14.3V16.78ZM19.54 10H16V7H18.46L20 9.3V10.69L19.54 9.99V10Z"
                fill={fill === 'gradient' ? 'url(#Trucks_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Trucks" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Trucks" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M19.54 5H16V3H2V5H14V8H2V10H14V17H8.22C7.67 16.39 6.88 16 6 16C5.23 16 4.53 16.3 4 16.78V12H2V19H3C3 20.65 4.35 22 6 22C7.65 22 9 20.65 9 19H15C15 20.65 16.35 22 18 22C19.65 22 21 20.65 21 19H22V8.7L19.54 5ZM6 20C5.45 20 5 19.55 5 19C5 18.45 5.45 18 6 18C6.55 18 7 18.45 7 19C7 19.55 6.55 20 6 20ZM18 20C17.45 20 17 19.55 17 19C17 18.45 17.45 18 18 18C18.55 18 19 18.45 19 19C19 19.55 18.55 20 18 20ZM20 16.78C19.47 16.3 18.77 16 18 16C17.23 16 16.53 16.3 16 16.78V12H18.46L20 14.3V16.78ZM19.54 10H16V7H18.46L20 9.3V10.69L19.54 9.99V10Z"
                fill={fill === 'gradient' ? 'url(#Trucks_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Trucks" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Trucks" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M19.54 5H16V3H2V5H14V8H2V10H14V17H8.22C7.67 16.39 6.88 16 6 16C5.23 16 4.53 16.3 4 16.78V12H2V19H3C3 20.65 4.35 22 6 22C7.65 22 9 20.65 9 19H15C15 20.65 16.35 22 18 22C19.65 22 21 20.65 21 19H22V8.7L19.54 5ZM6 20C5.45 20 5 19.55 5 19C5 18.45 5.45 18 6 18C6.55 18 7 18.45 7 19C7 19.55 6.55 20 6 20ZM18 20C17.45 20 17 19.55 17 19C17 18.45 17.45 18 18 18C18.55 18 19 18.45 19 19C19 19.55 18.55 20 18 20ZM20 16.78C19.47 16.3 18.77 16 18 16C17.23 16 16.53 16.3 16 16.78V12H18.46L20 14.3V16.78ZM19.54 10H16V7H18.46L20 9.3V10.69L19.54 9.99V10Z"
                fill={fill === 'gradient' ? 'url(#Trucks_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Trucks" />}
        </g>
    ),
}
