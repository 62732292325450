import React, { useState } from 'react'
import styled from 'styled-components'

import { NotificationBanner } from '@frontend/components/notifications'

import { SettingsRegion, WelcomeVideoDescription } from '@bc/config'
import { Language } from '@bc/translations'
import { GQLCustomer, GQLLocation, GQLMe, GQLUser } from '@bc/types'
import {
    Card,
    colors,
    ContactItem,
    HeliumTemplate,
    LinkButton,
    media,
    PageTitle,
    spacing,
    SvgIcon,
    Text,
    useDocumentTitle,
} from '@bc/ui'
import {
    AccountCompany,
    AccountPersonal,
    AccountPreferences,
    AccountRelatedUsers,
    FormatMessage,
    VideoPlayer,
    WelcomeVideoList,
} from '@frontend/components'

interface Category {
    name: string
    key: string
    ref?: any
}

const scrollTo = (el: any) => {
    if (el) {
        window.scrollTo(0, el.offsetTop)
    }
}

const AccountLinkButton = styled(LinkButton)`
    text-align: left;
    padding: 12px ${spacing.sm}px;
    display: block;
    width: 100%;
    &:hover {
        background-color: ${colors.neutral10};
    }
`

const ContentLeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
const HelpCard = styled(Card)`
    order: 2;
    ${media.min('md')} {
        order: 3;
    }
`

const VideoCard = styled(Card)`
    order: 3;
    ${media.min('md')} {
        order: 2;
    }
`

interface AccountPageProps {
    appConfig: SettingsRegion
    currentCustomer?: GQLCustomer
    me?: GQLMe
    relatedUsers: GQLUser[] | undefined
    t: FormatMessage
}
const noShipTos: GQLLocation[] = []

export const AccountPage = ({ t, me, currentCustomer, appConfig, relatedUsers }: AccountPageProps) => {
    const [openPlayer, setOpenPlayer] = useState<boolean>(false)
    const [video, setVideo] = useState<WelcomeVideoDescription>()

    const onClosePlayer = () => {
        setOpenPlayer(false)
        setVideo(undefined)
    }
    const onClickVideoLink = (selectedVideo: WelcomeVideoDescription) => {
        setOpenPlayer(true)
        setVideo(selectedVideo)
    }

    const contactEmail = appConfig.email.generic
    const pageTitle = t('account:title')
    useDocumentTitle(pageTitle)

    const categories: Category[] = [
        { name: t('account:preferences'), key: 'preferences' },
        { name: t('account:personal-details'), key: 'personal' },
        { name: t('account:company-details'), key: 'company' },
        { name: t('account:users'), key: 'users' },
    ]

    const welcomeVideos = appConfig.welcomeVideos?.[(me?.meta.language ?? 'en') as Language]

    const showAccountLinkButton = (key: string) => !!currentCustomer || (key !== 'company' && key !== 'users')

    const contentLeft = (): React.ReactNode => (
        <ContentLeftWrapper>
            <Card theme="sidebar">
                <Text h3>{t('faq:browse-categories')}</Text>
                {categories
                    .filter(({ key }) => showAccountLinkButton(key))
                    .map((category, i) => (
                        <AccountLinkButton key={`category-${i}`} onClick={() => scrollTo(category.ref)}>
                            {category.name}
                        </AccountLinkButton>
                    ))}
            </Card>
            {welcomeVideos && (
                <VideoCard theme="sidebar">
                    <Text h3>{t('welcome-video:headline')}</Text>
                    <Text p>{t('welcome-video:instruction')}</Text>
                    <WelcomeVideoList list={welcomeVideos} onClick={onClickVideoLink} />
                    <VideoPlayer open={openPlayer} onClose={onClosePlayer} video={video} />
                </VideoCard>
            )}{' '}
            <HelpCard theme="sidebar">
                <Text h3>{t('faq:contact-title')}</Text>
                <Text p>{t('faq:contact-text')}</Text>
                <ContactItem
                    icon={<SvgIcon icon="Mail" size="16" />}
                    text={t('general:send-us-email')}
                    link={'mailto:' + contactEmail}
                />
            </HelpCard>
        </ContentLeftWrapper>
    )

    const contentRight = (): React.ReactNode => (
        <>
            <NotificationBanner />
            <div ref={ref => (categories[0].ref = ref)} />
            <AccountPreferences languages={appConfig.languages} />
            <div ref={ref => (categories[1].ref = ref)} />
            <AccountPersonal me={me} t={t} />
            {currentCustomer && (
                <>
                    <div ref={ref => (categories[2].ref = ref)} />
                    <AccountCompany
                        name={currentCustomer.name}
                        shipTos={currentCustomer.shipTos ?? noShipTos}
                        billTos={currentCustomer.billTos}
                    />

                    <div ref={ref => (categories[3].ref = ref)} />
                    <AccountRelatedUsers
                        t={t}
                        currentCustomer={currentCustomer}
                        relatedUsers={relatedUsers}
                        csrEmail={appConfig.email.generic}
                    />
                </>
            )}
        </>
    )

    const header = <PageTitle title={pageTitle} />

    return (
        <HeliumTemplate>
            {{
                header,
                contentLeft: contentLeft(),
                contentRight: <>{me && contentRight()}</>,
            }}
        </HeliumTemplate>
    )
}
