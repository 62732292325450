import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Attachment: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Attachment" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M12 22C8.14 22 5 18.86 5 15V7C5 4.24 7.24 2 10 2C12.76 2 15 4.24 15 7V14C15 15.65 13.65 17 12 17C10.35 17 9 15.65 9 14V9H11V14C11 14.55 11.45 15 12 15C12.55 15 13 14.55 13 14V7C13 5.35 11.65 4 10 4C8.35 4 7 5.35 7 7V15C7 17.76 9.24 20 12 20C14.76 20 17 17.76 17 15V3H19V15C19 18.86 15.86 22 12 22Z"
                fill={fill === 'gradient' ? 'url(#Attachment_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Attachment" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Attachment" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M12 22C8.14 22 5 18.86 5 15V7C5 4.24 7.24 2 10 2C12.76 2 15 4.24 15 7V14C15 15.65 13.65 17 12 17C10.35 17 9 15.65 9 14V9H11V14C11 14.55 11.45 15 12 15C12.55 15 13 14.55 13 14V7C13 5.35 11.65 4 10 4C8.35 4 7 5.35 7 7V15C7 17.76 9.24 20 12 20C14.76 20 17 17.76 17 15V3H19V15C19 18.86 15.86 22 12 22Z"
                fill={fill === 'gradient' ? 'url(#Attachment_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Attachment" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Attachment" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M12 22C8.14 22 5 18.86 5 15V7C5 4.24 7.24 2 10 2C12.76 2 15 4.24 15 7V14C15 15.65 13.65 17 12 17C10.35 17 9 15.65 9 14V9H11V14C11 14.55 11.45 15 12 15C12.55 15 13 14.55 13 14V7C13 5.35 11.65 4 10 4C8.35 4 7 5.35 7 7V15C7 17.76 9.24 20 12 20C14.76 20 17 17.76 17 15V3H19V15C19 18.86 15.86 22 12 22Z"
                fill={fill === 'gradient' ? 'url(#Attachment_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Attachment" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Attachment" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M12 22C8.14 22 5 18.86 5 15V7C5 4.24 7.24 2 10 2C12.76 2 15 4.24 15 7V14C15 15.65 13.65 17 12 17C10.35 17 9 15.65 9 14V9H11V14C11 14.55 11.45 15 12 15C12.55 15 13 14.55 13 14V7C13 5.35 11.65 4 10 4C8.35 4 7 5.35 7 7V15C7 17.76 9.24 20 12 20C14.76 20 17 17.76 17 15V3H19V15C19 18.86 15.86 22 12 22Z"
                fill={fill === 'gradient' ? 'url(#Attachment_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Attachment" />}
        </g>
    ),
}
