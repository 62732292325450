import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Parcel_Scan: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Parcel_Scan" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M13.0002 2.2802L12.0002 1.9502L4.66016 4.3902L7.82016 5.4502L12.0002 4.0502L13.0002 4.3902V2.2802ZM15 2H16V8H15V2ZM18 2H17V8H18V2ZM19 2H20V8H19V2ZM21 2H22V8H21V2ZM20 17.2799V9.99986H22V18.7199L12 22.0499L2 18.7199V5.60986L12 8.94986L14 8.27986V10.3899L13 10.7199V19.6099L20 17.2799ZM4 17.2799L11 19.6099V10.7199L4 8.38986V17.2799Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Scan_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Scan" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Parcel_Scan" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M13.0002 2.2802L12.0002 1.9502L4.66016 4.3902L7.82016 5.4502L12.0002 4.0502L13.0002 4.3902V2.2802ZM15 2H16V8H15V2ZM18 2H17V8H18V2ZM19 2H20V8H19V2ZM21 2H22V8H21V2ZM20 17.2799V9.99986H22V18.7199L12 22.0499L2 18.7199V5.60986L12 8.94986L14 8.27986V10.3899L13 10.7199V19.6099L20 17.2799ZM4 17.2799L11 19.6099V10.7199L4 8.38986V17.2799Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Scan_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Scan" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Parcel_Scan" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M13.0002 2.2802L12.0002 1.9502L4.66016 4.3902L7.82016 5.4502L12.0002 4.0502L13.0002 4.3902V2.2802ZM15 2H16V8H15V2ZM18 2H17V8H18V2ZM19 2H20V8H19V2ZM21 2H22V8H21V2ZM20 17.2799V9.99986H22V18.7199L12 22.0499L2 18.7199V5.60986L12 8.94986L14 8.27986V10.3899L13 10.7199V19.6099L20 17.2799ZM4 17.2799L11 19.6099V10.7199L4 8.38986V17.2799Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Scan_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Scan" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Parcel_Scan" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M13.0002 2.2802L12.0002 1.9502L4.66016 4.3902L7.82016 5.4502L12.0002 4.0502L13.0002 4.3902V2.2802ZM15 2H16V8H15V2ZM18 2H17V8H18V2ZM19 2H20V8H19V2ZM21 2H22V8H21V2ZM20 17.2799V9.99986H22V18.7199L12 22.0499L2 18.7199V5.60986L12 8.94986L14 8.27986V10.3899L13 10.7199V19.6099L20 17.2799ZM4 17.2799L11 19.6099V10.7199L4 8.38986V17.2799Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Scan_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Scan" />}
        </g>
    ),
}
