import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Trophy: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Trophy" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M22 8V4H18V2H13V4H16V13.7L14.46 16H9.54L8 13.7V4H11V2H6V4H2V8H4V6H6V12H5.41L4 10.59V10H2V11.41L4.59 14H6V14.3L8.46 18H11V20H7V22H17V20H13V18H15.54L18 14.3V14H19.41L22 11.41V10H20V10.59L18.59 12H18V6H20V8H22Z"
                fill={fill === 'gradient' ? 'url(#Trophy_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Trophy" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Trophy" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M22 8V4H18V2H13V4H16V13.7L14.46 16H9.54L8 13.7V4H11V2H6V4H2V8H4V6H6V12H5.41L4 10.59V10H2V11.41L4.59 14H6V14.3L8.46 18H11V20H7V22H17V20H13V18H15.54L18 14.3V14H19.41L22 11.41V10H20V10.59L18.59 12H18V6H20V8H22Z"
                fill={fill === 'gradient' ? 'url(#Trophy_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Trophy" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Trophy" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M22 8V4H18V2H13V4H16V13.7L14.46 16H9.54L8 13.7V4H11V2H6V4H2V8H4V6H6V12H5.41L4 10.59V10H2V11.41L4.59 14H6V14.3L8.46 18H11V20H7V22H17V20H13V18H15.54L18 14.3V14H19.41L22 11.41V10H20V10.59L18.59 12H18V6H20V8H22Z"
                fill={fill === 'gradient' ? 'url(#Trophy_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Trophy" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Trophy" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M22 8V4H18V2H13V4H16V13.7L14.46 16H9.54L8 13.7V4H11V2H6V4H2V8H4V6H6V12H5.41L4 10.59V10H2V11.41L4.59 14H6V14.3L8.46 18H11V20H7V22H17V20H13V18H15.54L18 14.3V14H19.41L22 11.41V10H20V10.59L18.59 12H18V6H20V8H22Z"
                fill={fill === 'gradient' ? 'url(#Trophy_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Trophy" />}
        </g>
    ),
}
