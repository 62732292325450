import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Volume_High: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Volume_High" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M14 4.26001V2.20001C18.56 3.13001 22 7.17001 22 12C22 16.83 18.56 20.87 14 21.8V19.74C17.44 18.85 20 15.72 20 12C20 8.28001 17.44 5.15001 14 4.26001ZM12 21.41L6.59 16H2V8.00003H6.59L12 2.59003V11H10V7.41003L7.41 10H4V14H7.41L10 16.59V13H12V21.41ZM14 6.35001V8.56001C15.19 9.25001 16 10.53 16 12C16 13.47 15.19 14.75 14 15.44V17.65C16.33 16.82 18 14.61 18 12C18 9.39001 16.33 7.18001 14 6.35001Z"
                fill={fill === 'gradient' ? 'url(#Volume_High_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_High" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Volume_High" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M14 4.26001V2.20001C18.56 3.13001 22 7.17001 22 12C22 16.83 18.56 20.87 14 21.8V19.74C17.44 18.85 20 15.72 20 12C20 8.28001 17.44 5.15001 14 4.26001ZM12 21.41L6.59 16H2V8.00003H6.59L12 2.59003V11H10V7.41003L7.41 10H4V14H7.41L10 16.59V13H12V21.41ZM14 6.35001V8.56001C15.19 9.25001 16 10.53 16 12C16 13.47 15.19 14.75 14 15.44V17.65C16.33 16.82 18 14.61 18 12C18 9.39001 16.33 7.18001 14 6.35001Z"
                fill={fill === 'gradient' ? 'url(#Volume_High_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_High" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Volume_High" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M14 4.26001V2.20001C18.56 3.13001 22 7.17001 22 12C22 16.83 18.56 20.87 14 21.8V19.74C17.44 18.85 20 15.72 20 12C20 8.28001 17.44 5.15001 14 4.26001ZM12 21.41L6.59 16H2V8.00003H6.59L12 2.59003V11H10V7.41003L7.41 10H4V14H7.41L10 16.59V13H12V21.41ZM14 6.35001V8.56001C15.19 9.25001 16 10.53 16 12C16 13.47 15.19 14.75 14 15.44V17.65C16.33 16.82 18 14.61 18 12C18 9.39001 16.33 7.18001 14 6.35001Z"
                fill={fill === 'gradient' ? 'url(#Volume_High_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_High" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Volume_High" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M14 4.26001V2.20001C18.56 3.13001 22 7.17001 22 12C22 16.83 18.56 20.87 14 21.8V19.74C17.44 18.85 20 15.72 20 12C20 8.28001 17.44 5.15001 14 4.26001ZM12 21.41L6.59 16H2V8.00003H6.59L12 2.59003V11H10V7.41003L7.41 10H4V14H7.41L10 16.59V13H12V21.41ZM14 6.35001V8.56001C15.19 9.25001 16 10.53 16 12C16 13.47 15.19 14.75 14 15.44V17.65C16.33 16.82 18 14.61 18 12C18 9.39001 16.33 7.18001 14 6.35001Z"
                fill={fill === 'gradient' ? 'url(#Volume_High_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_High" />}
        </g>
    ),
}
