import styled from 'styled-components'

export const Wrapper = styled.div`
    position: relative;
`

interface CalendarProps {
    isHidden: boolean
}

export const Calendar = styled.div`
    position: absolute;
    z-index: 2;
    top: 62px;
    display: ${({ isHidden }: CalendarProps) => (isHidden ? 'none' : 'block')};
`
