import React from 'react'
import { Field, Form } from 'react-final-form'

import { MessageMap } from '@bc/translations'
import { Text, TextInput, FormInputComponents } from '@bc/ui'
import { FormatMessage } from '@frontend/components'
import { finalFormValidations } from '@frontend/utils'

import { LoginMfaFormValues } from '../login-page-container'
import * as LoginPageComponents from './login-components'
import LoginError from './login-error'

const renderErrorMessage = (error: string, t: FormatMessage) => {
    const key = `login-form:error.${error}` as keyof MessageMap
    return t(key, { id: key, defaultMessage: t('login-form:error') })
}

interface LoginPageProps {
    isLoading: boolean
    error: any
    t: FormatMessage
    handleMfaSubmit: (values: LoginMfaFormValues) => void
}

export const LoginMfaForm = ({ isLoading, t, error, handleMfaSubmit }: LoginPageProps) => (
    <>
        <Text h3 fontWeight="bold" color="deepPurple">
            {t('login-mfa:title')}
        </Text>

        <Form onSubmit={handleMfaSubmit}>
            {({ handleSubmit }) => (
                <LoginPageComponents.Form method="POST" onSubmit={handleSubmit}>
                    <FormInputComponents.InputWrapper>
                        <Text caption p color="neutral90">
                            {t('login-mfa:intro')}
                        </Text>
                        {error && <LoginError>{renderErrorMessage(error, t)}</LoginError>}
                        <Field
                            name="mfa_code"
                            validate={finalFormValidations.required(t)}
                            render={({ input, meta }) => (
                                <TextInput
                                    {...input}
                                    placeholder={t('login-mfa-form:input')}
                                    type="text"
                                    hasError={meta.touched && meta.error}
                                    errorText={meta.error}
                                />
                            )}
                        />
                        <LoginPageComponents.ButtonBar>
                            <LoginPageComponents.LoginButton
                                type="submit"
                                buttonSize="large"
                                isLoading={isLoading}
                                variant="action">
                                {t('login-mfa-form:submit')}
                            </LoginPageComponents.LoginButton>
                        </LoginPageComponents.ButtonBar>
                    </FormInputComponents.InputWrapper>
                </LoginPageComponents.Form>
            )}
        </Form>
    </>
)
