import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { useFormatMessage } from '@frontend/components'

import { ErrorPage } from './error-page'

const NotFoundPageConst = ({ staticContext }: RouteComponentProps) => {
    const t = useFormatMessage()
    if (staticContext) {
        staticContext.statusCode = 404
    }
    return (
        <ErrorPage
            title={t('not-found:title')}
            message={t('not-found:message')}
            url={{ href: '/', text: t('not-found:link-text') }}
        />
    )
}

export const NotFoundPage = withRouter(NotFoundPageConst)
