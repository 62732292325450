import React from 'react'
import styled from 'styled-components'

import { Text, TextHighlight } from '@bc/ui/src'
import { colors, spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'

import { ListItemMetaProps } from './list-item-meta-props'

const RichRowListItem = styled.div`
    display: flex;
    align-items: flex-start;
    padding: ${spacing.sm}px;
    margin: 15px 0 0 0;
    background-color: ${colors.neutral10};

    ${media.max('sm')} {
        flex-direction: column;
        padding: 10px;
    }
`

const ListItem = styled.div`
    padding: 0 ${spacing.sm}px;
    ${media.max('sm')} {
        width: 100%;
        margin-bottom: 5px;
        padding: 0;
    }
    ${media.min('sm')} {
        &:first-child {
            flex: 1;
        }
    }
    &:first-child,
    &:last-child {
        padding: 0;
    }
`

const OrderLinesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

interface OrderLineBaseProps {
    list: ListItemMetaProps[][] | undefined
}

const ListItemMeta = ({ label, value, icon, highlight }: ListItemMetaProps) =>
    icon ? (
        <ListItem>
            <Text h6 data-test-class="text-product-name">
                {label}
            </Text>
            {icon}
        </ListItem>
    ) : (
        <ListItem>
            {label && (
                <Text caption color="neutral50" noMargin>
                    {label}
                </Text>
            )}
            {typeof value === 'string' ? (
                <Text p noMargin>
                    <TextHighlight search={highlight}>{value}</TextHighlight>
                </Text>
            ) : (
                value
            )}
        </ListItem>
    )

export const OrderLines = ({ list }: OrderLineBaseProps) => (
    <OrderLinesWrapper>
        {list?.map((orderLineList: ListItemMetaProps[], key: number) => {
            const content = orderLineList.map((item: ListItemMetaProps, i: number) => (
                <ListItemMeta key={i} {...item} />
            ))
            return <RichRowListItem key={`i${key}`}>{content}</RichRowListItem>
        })}
    </OrderLinesWrapper>
)
