import qs from 'query-string'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import { tagManager } from '@bc/gtm'
import { useFormatMessage } from '@frontend/components'
import { SessionContext, SessionContextData, UserContext } from '@frontend/context'

import { login, loginMfa } from '@frontend/utils/auth/services'

import { getFirstEmailFromQuery } from '@frontend/utils'
import { LOGIN_REDIRECT_PREFIX } from '../shared'
import { LoginPage } from './login-page'

export interface LoginFormValues {
    username: string
    password: string
}

export interface LoginMfaFormValues {
    mfa_code: string
}

export interface MfaState {
    active: boolean
    state?: string
}

interface LoginPageLocationState {
    referrer?: string
}
type PropsCombined = RouteComponentProps<any, any, LoginPageLocationState>

const LoginPageContainer = ({ location }: PropsCombined): JSX.Element => {
    const { me } = useContext(UserContext)
    const [sessionStore, sessionState]: SessionContextData = useContext(SessionContext)

    const t = useFormatMessage()

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<any>(undefined)
    const { search } = location
    const { mfa, state, email } = qs.parse(search)
    const oneEmail = getFirstEmailFromQuery(email)

    useEffect(() => {
        const loginTag = mfa ? 'MFA' : 'INITIATED'
        tagManager.tags.login(loginTag)
    }, [])

    const cookiesAccepted = sessionState.cookieConsentAccept !== 'yes'

    const setCookiesAccepted = (): void => {
        tagManager.tags.cookieConsent('yes')
        sessionStore.set('cookieConsentAccept', 'yes', 365, true)
    }

    const handleLoginSubmit = (values: LoginFormValues): void => {
        if (isLoading) {
            return
        }
        setIsLoading(true)
        handleLogin(values)
    }

    const handleLogin = async (values: LoginFormValues): Promise<void> => {
        try {
            await login({ ...values, state: location.state && LOGIN_REDIRECT_PREFIX + location.state.referrer })
        } catch (err) {
            tagManager.tags.login('Error')
            setIsLoading(false)
            setError(err && err.code)
        }
    }

    const handleMfaSubmit = (values: { mfa_code: string }): void => {
        const options = {
            code: values.mfa_code,
            state: state as string,
        }
        loginMfa(options)
    }

    if (me?.acceptedTerms) {
        return <Redirect to="/" />
    }

    return (
        <LoginPage
            t={t}
            cookiesAccepted={cookiesAccepted}
            setCookiesAccepted={setCookiesAccepted}
            handleMfaSubmit={handleMfaSubmit}
            handleLoginSubmit={handleLoginSubmit}
            isLoading={isLoading}
            error={error}
            isMfa={Boolean(mfa)}
            email={oneEmail || ''}
        />
    )
}

export default LoginPageContainer
