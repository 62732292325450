// Spacing
export const spacing = {
    xxs: 10,
    xs: 15,
    sm: 20,
    md: 30,
    ml: 45,
    lg: 60,
    xl: 90,
}
