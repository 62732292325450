import React from 'react'
import { Field, Form } from 'react-final-form'

import { Language, languageLabels, RegionCode } from '@bc/translations'
import { GQLRegion } from '@bc/types'
import { RadioButton, Text, FullScreenLoaderRound, FormInputComponents } from '@bc/ui'
import { AutoSubmit } from '@frontend/components/auto-submit'
import { useFormatMessage } from '@frontend/components/hooks'
import { env } from '@frontend/config'

import { useSetUserLanguage } from '../hooks/set-user-language'
import { AccountCard } from './account-card'

interface FormProps {
    language: Language
    region: string
}

interface AccountPreferencesProps {
    languages: Language[]
}

export const AccountPreferences = ({ languages }: AccountPreferencesProps) => {
    const t = useFormatMessage()
    const { setUserLanguage, loading, me, currentLanguage } = useSetUserLanguage()
    const userRegion = me?.meta.region

    const handleFormSubmit = (values: FormProps) => {
        const { language, region } = values
        setUserLanguage(language, region as RegionCode)
    }

    return (
        <>
            <FullScreenLoaderRound isVisible={loading} />
            <AccountCard
                theme="light"
                header={
                    <FormInputComponents.InputWrapper>
                        <Text h3>{t('account:preferences')}</Text>
                        <Text caption>{t('account:language')}</Text>
                        <Form
                            onSubmit={handleFormSubmit}
                            initialValues={{ language: currentLanguage, region: userRegion }}>
                            {() => (
                                <>
                                    <AutoSubmit submit={handleFormSubmit} />
                                    {languages?.map((language: Language) => (
                                        <Field
                                            key={language}
                                            name="language"
                                            type="radio"
                                            value={language}
                                            render={({ input }) => (
                                                <RadioButton
                                                    {...input}
                                                    id={language}
                                                    value={language}
                                                    labelText={languageLabels[language]}
                                                    colorScheme="deepPurple"
                                                />
                                            )}
                                        />
                                    ))}
                                    {env.DEV_MODE && (
                                        <>
                                            <Text caption>Change your Region as a Developer ( ͡° ͜ʖ ͡°)</Text>
                                            {[GQLRegion.de, GQLRegion.be, GQLRegion.nl, GQLRegion.pl].map(
                                                (region: any, index: number) => (
                                                    <Field
                                                        key={region.toString()}
                                                        name="region"
                                                        type="radio"
                                                        value={region}
                                                        render={({ input }) => (
                                                            <RadioButton
                                                                {...input}
                                                                id={region + index}
                                                                value={region}
                                                                labelText={region}
                                                                colorScheme="deepPurple"
                                                            />
                                                        )}
                                                    />
                                                ),
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Form>
                    </FormInputComponents.InputWrapper>
                }
            />
        </>
    )
}
