import { config, SalutationType } from '@bc/config'
import graphqlUtils from '@bc/graphql-utils'
import { GQLUser } from '@bc/types'

import { defaultLocale } from '../config'
import { Language, Locale, RegionCode } from '../types'
import { getCustomerSalutation } from './salutation'
import { isValidLocale } from './utils'

export const getUserSalutationType = (user: GQLUser): SalutationType => config[user.region as RegionCode].salutation

export const getUserSalutation = (user: GQLUser): string =>
    getCustomerSalutation(
        getUserSalutationType(user),
        graphqlUtils.user.getGender(user.female),
        getUserLocale(user),
        user.lastName,
    )

export const getUserLocale = (
    user: GQLUser | undefined,
    fallbackLocale: Locale = defaultLocale,
    onInvalidLanguage?: () => void,
): string | never => {
    if (!user) {
        return isValidLocale(fallbackLocale) ? fallbackLocale : defaultLocale
    }

    const userRegion = user.region as RegionCode

    if (!userRegion) {
        throw new Error('getUserLocale - User has no region')
    }

    const userLanguage = getUserLanguage(user, onInvalidLanguage)
    const userLocale = `${userLanguage}-${userRegion}` as Locale

    return isValidLocale(userLocale) ? userLocale : isValidLocale(fallbackLocale) ? fallbackLocale : defaultLocale
}

export const getUserLanguage = (user: GQLUser | undefined, onInvalidLanguage?: () => void): Language => {
    if (!user) {
        return 'en'
    }

    const userRegion = user.region as RegionCode
    const userLanguage = user.language as Language

    if (!userRegion) {
        throw new Error('getUserLocale - User has no region')
    }

    if (!config[userRegion].languages.includes(userLanguage)) {
        if (typeof onInvalidLanguage === 'function') {
            onInvalidLanguage()
        }

        return config[userRegion].languages[0]
    }

    return userLanguage
}
