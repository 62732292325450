import styled from 'styled-components'

import { breakpoints, colors, spacing } from '@bc/ui/src/config'
import { hideOnMax, hideOnMin, media } from '@bc/ui/src/helpers'

export interface TableCellProps {
    ratio: number
    basis: number
    first?: boolean
    overflowVisible?: boolean
    stretch?: boolean
    mobileWidth?: number
    hiddenMax?: keyof typeof breakpoints
    hiddenMin?: keyof typeof breakpoints
    backgroundColor?: keyof typeof colors | undefined
}

export const TableCell = styled.div`
    display: flex;
    padding: 0 ${spacing.xs}px;

    flex: ${({ first }: TableCellProps) => (first ? `0 0 auto` : `1 0 auto`)};

    width: ${({ basis }: TableCellProps) => `${basis}%`};

    overflow: ${({ overflowVisible }: TableCellProps) => (overflowVisible ? 'visible' : 'hidden')};

    background-color: ${({ backgroundColor }: TableCellProps) =>
        backgroundColor ? colors[backgroundColor] : 'transparent'};

    &:last-child {
        justify-content: flex-end;
    }

    &:first-child {
        justify-content: flex-start;
    }

    ${media.max('sm')} {
        flex-direction: column;
        width: ${({ mobileWidth = 50 }: TableCellProps) => (mobileWidth ? `${mobileWidth}%` : '50%')};
        flex: auto;
        word-break: break-word;
        margin: ${spacing.xs / 2}px 0;

        &:last-child {
            align-self: flex-end;
        }

        &:first-child {
            align-self: flex-start;
        }
    }

    ${media.min('sm')} {
        white-space: normal;
        padding: 15px ${spacing.sm}px;
    }

    ${({ hiddenMax }: TableCellProps) => (hiddenMax ? hideOnMax(hiddenMax) : {})}
    ${({ hiddenMin }: TableCellProps) => (hiddenMin ? hideOnMin(hiddenMin) : {})}
`
