import React from 'react'

import { Locale } from '@bc/translations'
import { IntlProvider } from './intl-provider'

interface UserIntlProviderProps {
    userLocale: Locale
    children: any
}

export const UserIntlProvider = ({ userLocale, children }: UserIntlProviderProps) => (
    <IntlProvider locale={userLocale}>{children}</IntlProvider>
)
