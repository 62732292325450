import { colors, defaultFontSpacing, spacing } from '@bc/ui/src/config'
import { getFontFamily, media } from '@bc/ui/src/helpers'

export const fontStyles = {
    title: `
        font-size: 10rem;
        line-height: 1;
        margin-bottom: 30px;

        ${media.max('lg')} {
            font-size: 8rem;
        }

        ${media.max('md')} {
            font-size: 6rem;
        }

        ${media.max('sm')} {
            font-size: 4rem;
        }
    `,
    subTitle: `
        font-size: 6rem;

        ${media.max('md')} {
            font-size: 4rem;
        }

        ${media.max('sm')} {
            font-size: 2.5rem;
        }
    `,
    h1: `
        font-family: ${getFontFamily('brenntagsans')};
        font-weight: 700;
        line-height: 1.5;
        font-size: 4rem;
        color: ${colors.deepPurple};
        margin: 0 0 20px 0;

        ${media.max('md')} {
            font-size: 3.6rem;
        }
    `,
    h2: `
        font-family: ${getFontFamily('brenntagsans')};
        font-weight: 700;
        line-height: 1.5;
        font-size: 3.4rem;
        color: ${colors.deepPurple};
        margin: 0 0 20px 0;

        ${media.max('md')} {
            font-size: 3rem;
        }
    `,
    h3: `
        font-family: ${getFontFamily('brenntagsans')};
        font-weight: 700;
        line-height: 1.5;
        font-size: 2.8rem;
        color: ${colors.deepPurple};
        margin: 0 0 20px 0;

        ${media.max('md')} {
            font-size: 2.2rem;
        }
    `,
    h4: `
        font-family: ${getFontFamily('brenntagsans_bold')};
        font-weight: 500;
        line-height: 1.5;
        font-size: 2.4rem;
        color: ${colors.deepPurple};
        margin: 0 0 20px 0;

        ${media.max('md')} {
            font-size: 2rem;
        }
    `,
    h5: `
        font-family: ${getFontFamily('brenntagsans_bold')};
        font-weight: 500;
        line-height: 1.5;
        font-size: 2rem;
        color: ${colors.deepPurple};
        margin: 0 0 20px 0;

        ${media.max('md')} {
            font-size: 1.6rem;
        }
    `,
    h6: `
        font-family: ${getFontFamily('brenntagsans_bold')};
        font-weight: 500;
        line-height: 1.5;
        font-size: 1.6rem;
        color: ${colors.deepPurple};
        margin: 0 0 20px 0;

        ${media.max('md')} {
            font-size: 1.2rem;
        }
    `,
    lead: `
        font-family: ${getFontFamily('brenntagsans')};
        font-weight: normal;
        line-height: 1.4;
        font-size: 2rem;
        color: ${colors.deepPurple};

        margin-top: 0;
        margin-bottom: 0;

        &:not(:last-of-type) {
            margin-bottom: 30px;
        }
    `,
    intro: `
        font-family: ${getFontFamily('brenntagsans')};
        font-weight: normal;
        line-height: 1.4;
        font-size: 2rem;
        color: ${colors.deepPurple};
        margin-top: 0;
        margin-bottom: 0;

        &:not(:last-of-type) {
            margin-bottom: 30px;
        }

        ${media.max('sm')} {
            font-size: 1.6rem;
        }
    `,
    p: `
        margin: 0 0 10px 0;
        &:not(:last-of-type) {
            margin-bottom: ${spacing.sm}px;
        }
    `,
    caption: `
        font-family: ${getFontFamily('brenntagsans')};
        font-weight: normal;
        ${defaultFontSpacing}
        color: ${colors.neutral50};
        margin-top: 0;
        margin-bottom: 5px;
        display: inline-block;
    `,
    small: `
        font-family: ${getFontFamily('brenntagsans')};
        font-size: 1.2rem;
        display: block;
    `,
}
