import React, { useContext } from 'react'

import { GQLCustomer, GQLMainSalesArea } from '@bc/types'
import { FullWidthTitleNotificationTemplate, MercuryTemplate, useDocumentTitle } from '@bc/ui'
import {
    CompaniesListFilters,
    CompanyCard,
    QueryResultList,
    useFormatMessage,
    CompaniesFilters,
} from '@frontend/components'
import { FiltersContextData, FiltersContext, getFilterCount } from '@frontend/context'

interface MyCompaniesPageProps {
    onSelectCustomer: (customer: GQLCustomer, mainSalesArea: GQLMainSalesArea) => void
    customers: GQLCustomer[]
    filterCount?: number
}

export const MyCompaniesListPage = ({ onSelectCustomer, customers }: MyCompaniesPageProps) => {
    const t = useFormatMessage()
    const pageTitle = t('my-customers:headline')
    useDocumentTitle(pageTitle)
    const filterContext: FiltersContextData<CompaniesFilters> = useContext(FiltersContext)
    const { name: filterName, id: filterId } = filterContext?.query ?? {}

    const customersLength = customers?.reduce((length, customer) => length + customer.mainSalesAreas.length, 0) ?? 0

    return (
        <MercuryTemplate title={t('general:filters')} filterCount={getFilterCount(filterContext?.query)}>
            {{
                sidebarContent: <CompaniesListFilters />,
                content: (
                    <FullWidthTitleNotificationTemplate title={pageTitle}>
                        <QueryResultList
                            error={undefined}
                            amountOfResults={customersLength}
                            noResultText={t('not-found:customers')}>
                            {customers
                                ?.sort((customer1, customer2) =>
                                    (customer1.name || '') > (customer2.name || '') ? 1 : -1,
                                )
                                .reduce(
                                    (array, customer) => [
                                        ...array,
                                        ...customer.mainSalesAreas.map(area => (
                                            <CompanyCard
                                                key={customer.id + area.salesAreaId}
                                                customer={customer}
                                                mainSalesArea={area}
                                                onSelectCustomer={onSelectCustomer}
                                                highlight={[filterName, filterId] as string[]}
                                            />
                                        )),
                                    ],
                                    [],
                                )}
                        </QueryResultList>
                    </FullWidthTitleNotificationTemplate>
                ),
            }}
        </MercuryTemplate>
    )
}
