import React, { useContext } from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { NotFoundPage } from '@frontend/pages/error'
import { UserContext } from '@frontend/context'

import { OrdersBaseContainer } from './base'

export const OrderHistory = ({ match: { url } }: RouteComponentProps) => {
    const { currentCustomer } = useContext(UserContext)
    return currentCustomer ? (
        <Switch>
            <Route path={`${url}`} exact component={OrdersBaseContainer} />
            <Route component={NotFoundPage} />
        </Switch>
    ) : null
}
