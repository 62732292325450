import React from 'react'
import styled from 'styled-components'

interface Props {
    children?: React.ReactNode
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    height: 100%;
`

export const OxygenTemplate = (props: Props) => <Wrapper {...props} />
