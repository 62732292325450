import { EventTag } from '../types'
import { dataLayer } from '../utils'

export const cookieConsent = (accepted: string) => {
    dataLayer<EventTag>({
        event: 'gtm.coockiePolicy',
        eventCategory: 'cookie',
        eventAction: 'cookie policy',
        eventLabel: accepted,
    })
}
