import { fontFamily } from '../config'

// Font family
export function getFontFamily(f: keyof typeof fontFamily) {
    const family = fontFamily[f]
    if (!family) {
        return ''
    }
    return family.join(', ')
}
