export const INTL_ADJUST_FORMAT_FOR_NODE = true
export const makeFormatNumber = (localeIso: string, adjustFormat?: boolean) => (number: number, options?: any) => {
    const formatter = new Intl.NumberFormat(localeIso, options)
    let formatted = formatter.format(number)

    // make sure we have currency symbol/code at the end
    if (options?.style === 'currency') {
        const LITERAL = String.fromCharCode(160)
        // RegExp explained: Find at the start of the line
        // - negative minus first and then
        // - any non digit/space chars
        // this must be the currency -> move it to the end
        const currencyAndAmount: RegExpExecArray | null = /^(-*)([^0-9|\s|^-]+)(.*)/.exec(formatted)
        if (currencyAndAmount) {
            formatted = currencyAndAmount[1] + currencyAndAmount[3].trim() + LITERAL + currencyAndAmount[2].trim()
        }
    }

    // We do not seem to have the right locales loaded into Node Intl internal object
    // this is why regardles of the locale Intl ormats the numbers according to en-GB
    // meaning: "5,000,000.25" - quick hack to reformat this
    if (adjustFormat) {
        switch (localeIso.toLowerCase()) {
            case 'nl-nl':
            case 'nl-be':
            case 'de-de': {
                formatted = formatted.replace(/\./, '_').replace(/,/g, '.').replace(/_/, ',')
                break
            }
            case 'pl-pl':
            case 'fr-be': {
                formatted = formatted.replace(/\./, '_').replace(/,/g, ' ').replace(/_/, ',')
                break
            }
        }
    }

    return formatted
}
