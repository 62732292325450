import styled from 'styled-components'

import { SvgComponentProps, SvgIcon } from '@bc/ui/src/components/svg'

import { ButtonSize } from './button-types'

interface ButtonIconProps {
    buttonSize?: ButtonSize
    disableMargin: boolean
}

const marginSizes: Record<ButtonSize, string> = {
    'x-small': '1px',
    small: '3px',
    medium: '5px',
    large: '8px',
    'x-large': '12px',
    'xx-large': '16px',
}

const getMarginSize = (size: ButtonSize): string => marginSizes[size]

export const ButtonIcon = styled(SvgIcon)<ButtonIconProps & SvgComponentProps>`
    flex-shrink: 0;
    margin-right: ${({ buttonSize = 'medium', disableMargin }: ButtonIconProps) =>
        disableMargin ? 0 : getMarginSize(buttonSize)};
`
