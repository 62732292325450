import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'

export const List = styled.ol`
    position: relative;
    margin-bottom: 3rem;
    counter-reset: ordered-list;
`

interface ListItemProps {
    numberColor?: keyof typeof colors
    color?: keyof typeof colors
}

export const ListItem = styled.li`
    position: relative;
    display: flex;
    align-items: flex-start;
    list-style: none;
    color: ${({ color = 'neutral90' }: ListItemProps) => colors[color]};

    &:before {
        counter-increment: ordered-list;
        content: counters(ordered-list, '.') '. ';
        width: 10px;
        height: 10px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        margin-right: 0.8rem;
        font-weight: 500;
        margin-top: 4px;
        color: ${({ numberColor = 'deepPurple' }: ListItemProps) => colors[numberColor]};
    }

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
`
