import { GQLDocumentType, GQLRequestStatus, GQLRequestType } from '@bc/types'

import { ProductView, SalutationType, SettingsRegion } from '../types'
import { defaultUomMapper } from '../uom'

export const defaultConfig: SettingsRegion = {
    currency: 'EUR',
    currencyDisplay: 'symbol',
    dateFormat: 'DD.MM.YYYY',
    addWatermarkToInvoices: false,
    deliveryDateRestrictions: {
        reorderDate: {
            amount: 3,
            unit: 'days',
        },
        reorderEndDate: {
            amount: 15,
            unit: 'days',
        },
        startDate: {
            amount: 3,
            unit: 'days',
        },
        endDate: {
            amount: 2,
            unit: 'years',
        },
    },
    customerDataRestrictions: {
        orders: {
            amount: 2,
            unit: 'years',
        },
        invoices: {
            amount: 2,
            unit: 'years',
        },
        requests: {
            amount: 2,
            unit: 'years',
        },
    },
    footerCopyrightLink: 'http://corporate.brenntag.com/en/',
    privacyPolicyUrl: {
        en: 'https://www.brenntag.com/en-de/privacy-policy.html',
    },
    imprintUrl: {
        en: 'https://www.brenntag.com/en-de/imprint.html',
    },
    faqUrl: {
        en: 'https://www.brenntag.com/en-de/services/brenntag-connect-online-services/faq/',
    },
    tacUrl: {
        en: 'https://www.brenntag.com/en-de/terms-and-conditions.html',
    },
    brenntagConnectUrl: 'https://brenntag.com',
    productView: ProductView.TABLE,
    csrConfirmationEmail: 'info@brenntagconnect.com',
    email: {
        generic: 'connect@brenntag.de',
        csr: 'connect@brenntag.de',
        gdpo: 'gdpo@brenntag.de',
    },
    languages: [],
    countries: [],
    termsOfUsageVersion: '20190829',
    features: {
        Requests: true,
    },
    safetySheets: ['msds'],
    salutation: SalutationType.Formal,
    phone: {
        csr: '+31 (0)78 65 44 999',
    },
    officeHours: '8:00 - 12:00, 13:00 - 17:00 CET',
    filterRequestTypes: [GQLRequestType.PRICE, GQLRequestType.DOCUMENT], // minus SAMPLE, QUOTE and CART
    rfiModalRequestTypes: [GQLRequestType.PRICE, GQLRequestType.DOCUMENT], // minus SAMPLE, QUOTE and CART
    documentTypes: [GQLDocumentType.msds, GQLDocumentType.tds, GQLDocumentType.coa], // minus SPECIFICATION and OTHER
    requestStatusOnCreation: {
        [GQLRequestType.PRICE]: GQLRequestStatus.PENDING,
        [GQLRequestType.DOCUMENT]: GQLRequestStatus.PENDING,
        [GQLRequestType.SAMPLE]: GQLRequestStatus.PENDING,
        [GQLRequestType.QUOTE]: GQLRequestStatus.PENDING,
        [GQLRequestType.PROFILE]: GQLRequestStatus.PENDING,
        [GQLRequestType.CART]: GQLRequestStatus.PENDING,
    },
    uomMapper: {
        en: defaultUomMapper,
    },
    security: {
        disableApi: false,
    },
}

export const welcomeVideos = {
    en: [
        {
            key: 'WELCOME',
            url: 'https://player.vimeo.com/video/407477975',
        },
        {
            key: 'FIRSTTIME',
            url: 'https://player.vimeo.com/video/407478353',
        },
        {
            key: 'ORDERS',
            url: 'https://player.vimeo.com/video/405753244',
        },
        {
            key: 'INVOICES',
            url: 'https://player.vimeo.com/video/407478496',
        },
        {
            key: 'PRODUCTS',
            url: 'https://player.vimeo.com/video/407478589',
        },
        {
            key: 'REQUESTS',
            url: 'https://player.vimeo.com/video/407478710',
        },
        {
            key: 'FEEDBACK',
            url: 'https://player.vimeo.com/video/407478211',
        },
    ],
}
