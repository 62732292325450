import React, { useContext, useEffect } from 'react'

import qs from 'query-string'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { ApolloConsumerProps } from '@apollo/react-common/lib/context/ApolloConsumer'

import { getCsrEmail } from '@bc/graphql-utils/src/user'
import { tagManager } from '@bc/gtm'
import { GQLSalesOrganisationId } from '@bc/types'

import { useFormatMessage } from '@frontend/components'
import { SessionContext, SessionContextData, UserContext } from '@frontend/context'

import { ErrorPage } from './error-page'

const DocumentNotFoundPageConst = ({ staticContext }: RouteComponentProps & ApolloConsumerProps) => {
    const t = useFormatMessage()
    const { me } = useContext(UserContext)
    const [, sessionState]: SessionContextData = useContext(SessionContext)
    const { to: csrEmail } = getCsrEmail(me!.meta, sessionState.activeSalesOrganisation as GQLSalesOrganisationId)

    useEffect(() => {
        const { type, documentId } = qs.parse(location.search)

        tagManager.tags.documentNotFound(String(type).toLocaleLowerCase(), String(documentId))
    }, [])

    if (staticContext) {
        staticContext.statusCode = 404
    }
    return (
        <ErrorPage
            title={t('document-not-found:title')}
            message={t('document-not-found:message', undefined, { csrEmail })}
        />
    )
}

export const DocumentNotFoundPage = withRouter(DocumentNotFoundPageConst)
