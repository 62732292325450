import styled from 'styled-components'
import { colors } from '@bc/ui/src/config/colors'
import { spacing } from '@bc/ui/src/config/spacing'

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${spacing.xxs}px;
`
export const Label = styled.label`
    color: ${colors.neutral90};
    margin-right: ${spacing.xxs}px;
`
