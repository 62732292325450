import React from 'react'
import Highlighter from 'react-highlight-words'
import { Highlight } from './highlight'

interface TextHighlightProps {
    children: string
    search?: string | string[]
    component?: React.ComponentClass | React.FunctionComponent
}

export const TextHighlight = ({ children, search, component = Highlight }: TextHighlightProps) =>
    search ? (
        <Highlighter
            searchWords={typeof search === 'string' ? [search] : search}
            autoEscape
            textToHighlight={children}
            highlightTag={component}
        />
    ) : (
        <>{children}</>
    )
