import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

const getMotionProps = (index: number, delay: number) => ({
    hidden: {
        opacity: 0,
        y: '50%',
        transition: {
            delay: delay * index,
        },
    },
    visible: {
        opacity: 1,
        y: '0%',
        transition: {
            delay: delay * index,
        },
    },
})

export interface AnimatedListProps {
    wrapper?: React.FC<unknown>
    delay?: number
}

const DefaultWrapper: React.FC = ({ children }) => <>{children}</>

export const AnimatedList: React.FC<AnimatedListProps> = ({ children, wrapper = DefaultWrapper, delay = 0.05 }) => {
    const Wrapper = wrapper
    return (
        <AnimatePresence>
            <Wrapper>
                {React.Children.map(children, (child, i) => (
                    <motion.div key={i} variants={getMotionProps(i, delay)} animate="visible" initial="hidden">
                        {child}
                    </motion.div>
                ))}
            </Wrapper>
        </AnimatePresence>
    )
}
