import React from 'react'
import styled from 'styled-components'

import { GQLLocation } from '@bc/types'
import { Address, Column, Text } from '@bc/ui'

const Wrapper = styled.div`
    margin: 5px 0;
`

export const accountAddress = (location: GQLLocation, title: string) => (
    <Column xs={12} md={6} xl={4} key={location.id}>
        <Wrapper>
            <Text caption>{title}</Text>
            {<Address location={location} />}
        </Wrapper>
    </Column>
)
