import React from 'react'
import styled from 'styled-components'

import { breakpoints } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'
import { gridConfig } from './grid-config'

/**
 * USAGE:
 * <Column sm={12} md={6} lg={3} xl={{ size: 12, offset: 3 }}>
 *  ... children
 * </Col>
 */
type ColumnSizes = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

interface ColumBaseProps {
    isAncestor?: boolean
}

type ColumnSizeProps = { [key in keyof typeof breakpoints]?: ColumnSizes | ColumnConfig }

type ColumnProps = ColumBaseProps & ColumnSizeProps

interface ColumnConfig {
    size?: ColumnSizes
    offset?: ColumnSizes
    order?: ColumnSizes
}

const ColumnComponent = styled.div<ColumnProps>`
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding-left: ${gridConfig.gridGutterSmall}px;
    padding-right: ${gridConfig.gridGutterSmall}px;
    ${dynamicCss};
`

export const Column: React.SFC<ColumnProps> = props => <ColumnComponent {...props} />

const paddingStyles: { [key in keyof typeof breakpoints]?: string } = {
    md: `
        padding-left: ${gridConfig.gridGutter}px;
        padding-right: ${gridConfig.gridGutter}px;
    `,
}

/**
 * These properties are derived from the setting used in the component
 */
function dynamicCss(props: ColumnProps = {}): string[] {
    // Map breakpoints to style objects
    // We create a new configuration object using the size provided, or the last known size
    // We keep track of the last known size outside of the map.
    // It's overwritten on every breakpoint

    let lastKnownSize: ColumnSizes | undefined
    let lastKnownOffset: ColumnSizes | undefined
    let lastKnownOrder: ColumnSizes | undefined

    return Object.keys(breakpoints).map((bp: keyof typeof breakpoints): string => {
        const configIsNumber = typeof props[bp] === 'number'
        const configIsUndefined = typeof props[bp] === 'undefined'

        const config: ColumnConfig = {
            size: configIsNumber
                ? (props[bp] as ColumnSizes)
                : configIsUndefined
                ? lastKnownSize
                : (props[bp] as ColumnConfig).size,
            offset: configIsNumber || configIsUndefined ? lastKnownOffset : (props[bp] as ColumnConfig).offset,
            order: configIsNumber || configIsUndefined ? lastKnownOrder : (props[bp] as ColumnConfig).order,
        }

        lastKnownSize = config.size
        lastKnownOffset = config.offset
        lastKnownOrder = config.order

        const styles = `
                ${
                    config.size
                        ? `
                    flex: none;
                    width: ${(config.size / gridConfig.columns) * 100}%;
                `
                        : ''
                }
                ${
                    config.offset !== undefined
                        ? ` margin-left: calc(100% * ${config.offset / gridConfig.columns});`
                        : ''
                }
                ${config.order !== undefined ? ` order: ${config.order};` : ''}
            `

        if (bp === 'xs') {
            return styles
        }

        return `
                ${media.min(bp)} {
                    ${styles}
                    ${paddingStyles[bp] ? paddingStyles[bp] : ''}
                }
            `
    })
}
