import { MessageMap } from '../types'

// ENGLISH
import { default as enBE } from './en-be'
import { default as enDE } from './en-de'
import { default as enNL } from './en-nl'
import { default as enPL } from './en-pl'

// GERMAN
import { default as deDE } from './de-de'

// DUTCH
import { default as nlBE } from './nl-be'
import { default as nlNL } from './nl-nl'

// FRENCH
import { default as frBE } from './fr-be'
import { default as frNL } from './fr-nl'

// POLISH
import { default as plPL } from './pl-pl'

export const translations: { [key: string]: MessageMap } = {
    'en-be': enBE,
    'en-de': enDE,
    'en-nl': enNL,
    'de-de': deDE,
    'nl-nl': nlNL,
    'nl-be': nlBE,
    'fr-be': frBE,
    'fr-nl': frNL,
    'pl-pl': plPL,
    'en-pl': enPL,
}
