import React, { useContext, useMemo } from 'react'
import { Field } from 'react-final-form'
import { OptionsType } from 'react-select'

import { GQLLocation, QuotationFilters } from '@bc/types'
import { getLocationAddressLine, SelectInput, SelectInputOption, TextInput, selectFindOption } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { finalFormValidations, NUM_CHAR_START_SEARCH } from '@frontend/utils'
import { FiltersContextData, FiltersContext } from '@frontend/context'

import { FiltersFormContainer } from './filters-form-container'

interface QuoteFiltersProps {
    shipTos: GQLLocation[]
}
const noShipTos: GQLLocation[] = []

export const QuoteFilters = ({ shipTos = noShipTos }: QuoteFiltersProps) => {
    const t = useFormatMessage()
    const { onFilterChange, filters, query, onLinkCopy }: FiltersContextData<QuotationFilters> = useContext(
        FiltersContext,
    )

    const shipToOptions: OptionsType<SelectInputOption> = useMemo(
        () =>
            shipTos.map((location: GQLLocation) => ({
                value: location.id,
                label: getLocationAddressLine(location),
            })),
        [shipTos],
    )

    return (
        <FiltersFormContainer
            onFilterChange={onFilterChange}
            initialValues={filters}
            query={query}
            onLinkCopy={onLinkCopy}>
            <Field
                name="quoteId"
                parse={(o: string) => o?.toUpperCase()}
                render={({ input }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-offer-number')}
                        type="search"
                        placeholder={t('filters:search-by-offer-number.placeholder')}
                        data-test-id="input-search-offer-number"
                    />
                )}
            />
            <Field
                name="materialName"
                validate={finalFormValidations.minLength(t, NUM_CHAR_START_SEARCH)}
                render={({ input, meta }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-material-name')}
                        type="search"
                        placeholder={t('filters:search-by-material-name.placeholder')}
                        hasError={meta.touched && meta.error}
                        errorText={meta.error}
                        data-test-id="input-search-material-name"
                    />
                )}
            />
            <Field
                name="shipToId"
                parse={o => o?.value}
                render={({ input }) => {
                    const { value, ...restInput } = input
                    const selectedOption = selectFindOption(shipToOptions, value)
                    return (
                        <SelectInput
                            {...restInput}
                            value={selectedOption}
                            labelText={t('filters:select-ship-to-address')}
                            placeholder={t('filters:select-an-address')}
                            isSearchable
                            isClearable
                            options={shipToOptions}
                            noOptionsMessage={() => t('not-found:select')}
                            classNamePrefix="select-ship-to-address"
                            data-test-id="select-ship-to-address"
                        />
                    )
                }}
            />
        </FiltersFormContainer>
    )
}
