import * as ls from 'local-storage'

export type ShoppingCartExportedItem<T> = [string, T]
interface GenericStoreInterface<T> {
    get: (id: string) => T | undefined
    set: (id: string, value: T) => void
    remove: (id: string) => void
    clear: () => void
    data: ShoppingCartExportedItem<T>[]
    subscribe: (subscriber: (value: ShoppingCartExportedItem<T>[]) => void) => void
    unsubscribe: (subscriber: (value: ShoppingCartExportedItem<T>[]) => void) => void
}

type ShoppingCartNotifyFunc<T> = React.Dispatch<React.SetStateAction<ShoppingCartExportedItem<T>[]>>
type ShoppingCartCombineFunc<T> = (item1: T, item2: T) => T

export interface ShoppingStoreConstructorArgs<T> {
    notify: ShoppingCartNotifyFunc<T>
    merge?: ShoppingCartCombineFunc<T>
}

/**
 * @class ShoppingStore
 * @summary Returns a store to manage the shopping cart.
 */
export default class ShoppingStore<T> implements GenericStoreInterface<T> {
    private notifyExternal: ShoppingCartNotifyFunc<T>
    private merge: ShoppingCartCombineFunc<T> | undefined
    private store: Map<string, T> = new Map()
    private readonly localStorageTag: string = 'emea-bc-shopping-cart'

    /**
     * @private
     * @param data
     * @summary Write serialized storedata to persistent storage.
     * @param void.
     * @returns Void.
     * @example
     */
    private writeToPersistent(data: ShoppingCartExportedItem<T>[]): void {
        if (window) {
            if (data.length > 0) {
                try {
                    ls.set(this.localStorageTag, data)
                } catch (error) {
                    console.error(`An error occured when writing to local storage, ${error}. Local storage cleared.`)
                    ls.remove(this.localStorageTag)
                }
            } else {
                ls.remove(this.localStorageTag)
            }
        }
    }
    /**
     * @private
     * @summary Read serialized store data from persistent storage (on init).
     * @param void
     * @returns Object<T>  deserialized store data from locale storage.
     * @example
     */
    private readFromPersistent(): ShoppingCartExportedItem<T>[] | undefined {
        let cart
        if (window) {
            try {
                cart = ls.get<ShoppingCartExportedItem<T>[]>(this.localStorageTag)
            } catch (error) {
                console.error(`An error occured when reading from local storage, ${error}. Local storage cleared.`)
                ls.remove(this.localStorageTag) // clear storage if anything broken in it
            }
        }
        return cart
    }

    /**
     * @private
     * @summary Prepare store data from internal format for notify().
     * @param void
     * @returns Array<string,T>   collection of store items.
     * @example
     */
    private export(): ShoppingCartExportedItem<T>[] {
        // IE 11 does not support Map.entries() method
        const entries: ShoppingCartExportedItem<T>[] = []
        this.store.forEach((value, key) => entries.push([key, value]))
        return entries
    }

    /**
     * @private
     * @summary Notify proxy.
     * @param void.
     * @returns Void.
     * @example
     */
    private notify() {
        const data = this.export()
        this.writeToPersistent(data)
        if (typeof this.notifyExternal === 'function') {
            this.notifyExternal(data)
        }
    }

    /**
     * @private
     * @param item1
     * @param item2
     * @summary Combines two items into one.
     * @param void
     * @returns T   the new item which is a combination of the two if merge fucntion provided.
     * @example
     */
    private combine(item1: T, item2: T) {
        if (typeof this.merge === 'function') {
            return this.merge(item1, item2)
        } else {
            return item2
        }
    }

    /**
     * @public
     * @summary Initialises the class with the notify fucntion and optional new state.
     * @param notify:.notify
     * @param notify:.merge
     * @param notify:.notify
     * @param notify:.merge
     * @param notify: - ShoppingCartNotifyFunc.
     * @param merge: - Fucntion to merge items.
     * @returns T   an item from the store.
     * @example
     */
    public init({ notify, merge }: ShoppingStoreConstructorArgs<T>) {
        this.store = new Map(this.readFromPersistent())
        this.notifyExternal = notify
        this.merge = merge

        this.notify()
    }

    /**
     * @public
     * @param id
     * @summary Retrieves store item by id.
     * @param void
     * @returns T   an item from the store.
     * @example
     */
    public get = (id: string) => this.store.get(id)

    /**
     * @public
     * @summary Adds an object to the store.
     * @returns Void.
     * @param id
     * @param item
     * @param combine
     * @example
     */
    public set(id: string, item: T, combine: boolean = true) {
        if (combine && this.store.has(id)) {
            this.store.set(id, this.combine(item, this.store.get(id)!))
        } else {
            this.store.set(id, item)
        }
        this.notify()
    }

    /**
     * @public
     * @summary Adds an object to the store.
     * @returns Void.
     * @param id
     * @param item
     * @param combine
     * @example
     */
    public update(id: string, item: T) {
        this.store.set(id, item)

        this.notify()
    }

    /**
     * @public
     * @param id
     * @summary Removes an object from the store by id.
     * @param void.
     * @returns Void.
     * @example
     */
    public remove(id: string) {
        this.store.delete(id)
        this.notify()
    }

    /**
     * @public
     * @summary Removes all objecs from the store.
     * @param void.
     * @returns Void.
     * @example
     */
    public clear() {
        this.store = new Map()
        this.notify()
    }

    /**
     * @public
     * @getter
     * @summary Returns stored data.
     * @param void
     * @returns Data as [string, T][].
     */
    public get data() {
        return this.export()
    }

    /**
     * @public
     * @getter
     * @summary Returns the number of items.
     * @param void
     * @returns Data as [string, T][].
     */
    public get length(): number {
        return this.store.size
    }

    /**
     * @public
     * @summary Subscribes to the local storage notificationd.
     * @param subscriber - Function.
     * @returns Void.
     * @example
     */
    public subscribe(subscriber: (value: ShoppingCartExportedItem<T>[]) => void) {
        ls.on(this.localStorageTag, subscriber)
    }

    /**
     * @public
     * @summary Unsubscribes from the local storage notificationd.
     * @param subscriber - Function.
     * @returns Void.
     * @example
     */
    public unsubscribe(subscriber: (value: ShoppingCartExportedItem<T>[]) => void) {
        ls.off(this.localStorageTag, subscriber)
    }
}
