import { IDebugger } from 'debug'
declare global {
    interface Window {
        localStorage: any
    }
}
declare let window: Window

const debugBase = require('debug')
const backend = require('debug')('backend')
const graphql = require('debug')('graphql')
const sap = require('debug')('sap')
const sql = require('debug')('sql')
const email = require('debug')('email')
const frontend = require('debug')('frontend')

const multiLog = (logger: IDebugger, ...args: any[]) => {
    const formatters = args
        .map(message => {
            switch (typeof message) {
                case 'string':
                    return '%s'
                case 'number':
                    return '%d'
                default:
                    return '%O'
            }
        })
        .join('\n')

    logger(formatters, ...args)
}

const withTime = (logger: IDebugger) => (loggerFn: DebugLoggerFn) => {
    const debuggerWithTime = (...args: any[]) => loggerFn(logger, ...args)
    if (logger.enabled) {
        ;(debuggerWithTime as DebuggerWithTimeFn).startTime = (label: string): string => {
            const [sec, nano] = process.hrtime()
            const handle = label + ':' + sec.toString() + nano.toString()
            console.time(handle)
            return handle
        }
        ;(debuggerWithTime as DebuggerWithTimeFn).endTime = (handle: string) => console.timeEnd(handle)
    } else {
        ;(debuggerWithTime as DebuggerWithTimeFn).startTime = (_l: string) => ''
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        ;(debuggerWithTime as DebuggerWithTimeFn).endTime = (_h: string) => {}
    }

    return debuggerWithTime as DebuggerWithTimeFn
}
type DebugLoggerFn = (alogger: IDebugger, ...args: any[]) => void
interface DebuggerWithTimeFn {
    (...args: any[]): void
    startTime: (label: string) => string
    endTime: (handle: string) => void
}

interface Debug {
    backend: DebuggerWithTimeFn
    graphql: DebuggerWithTimeFn
    sap: DebuggerWithTimeFn
    sql: DebuggerWithTimeFn
    email: DebuggerWithTimeFn
    frontend: DebuggerWithTimeFn
}

export const debug: Debug = {
    backend: withTime(backend)(multiLog),
    graphql: withTime(graphql)(multiLog),
    sap: withTime(sap)(multiLog),
    sql: withTime(sql)(multiLog),
    email: withTime(email)(multiLog),
    frontend: withTime(frontend)(multiLog),
}

// We need to reload the window after setting log level since
export const initalizeFrontendLogging = (logLevel?: string) => {
    const currentLogLevel = window?.localStorage?.getItem('debug')

    // We set localStorage and re-initialize logger level so it can pick up the new setting without page refresh
    if (logLevel && currentLogLevel !== logLevel) {
        window?.localStorage?.setItem('debug', logLevel)
        debugBase.enable(logLevel)
        debug.frontend(`Changing log level from ${currentLogLevel || 'none'} to ${logLevel || 'none'}`)
    } else if (!logLevel && currentLogLevel) {
        debug.frontend(`Changing log level from ${currentLogLevel || 'none'} to ${logLevel || 'none'}`) // Execute this line *before* actually removing logs
        window?.localStorage?.removeItem('debug')
        debugBase.disable()
    }
}

export const reportFrontendDebugLevel = () => {
    const currentLogLevel = window?.localStorage?.getItem('debug')
    debug.frontend(`Debug level is ${currentLogLevel}`)
}
