import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Repeat: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Repeat" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M16.2891 9.29004L17.7091 10.71L22.4091 6.00004L17.7091 1.29004L16.2891 2.71004L19.5891 6.00004L16.2891 9.29004ZM1.58984 18L6.28984 22.71L7.70984 21.29L4.40984 18L7.70984 14.71L6.28984 13.29L1.58984 18ZM18.9983 13V17H8.23828L7.23828 18L8.23828 19H20.9983V13H18.9983ZM15.76 5L16.76 6L15.76 7H5V11H3V5H15.76Z"
                fill={fill === 'gradient' ? 'url(#Repeat_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Repeat" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Repeat" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M16.2891 9.29004L17.7091 10.71L22.4091 6.00004L17.7091 1.29004L16.2891 2.71004L19.5891 6.00004L16.2891 9.29004ZM1.58984 18L6.28984 22.71L7.70984 21.29L4.40984 18L7.70984 14.71L6.28984 13.29L1.58984 18ZM18.9983 13V17H8.23828L7.23828 18L8.23828 19H20.9983V13H18.9983ZM15.76 5L16.76 6L15.76 7H5V11H3V5H15.76Z"
                fill={fill === 'gradient' ? 'url(#Repeat_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Repeat" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Repeat" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M16.2891 9.29004L17.7091 10.71L22.4091 6.00004L17.7091 1.29004L16.2891 2.71004L19.5891 6.00004L16.2891 9.29004ZM1.58984 18L6.28984 22.71L7.70984 21.29L4.40984 18L7.70984 14.71L6.28984 13.29L1.58984 18ZM18.9983 13V17H8.23828L7.23828 18L8.23828 19H20.9983V13H18.9983ZM15.76 5L16.76 6L15.76 7H5V11H3V5H15.76Z"
                fill={fill === 'gradient' ? 'url(#Repeat_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Repeat" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Repeat" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M16.2891 9.29004L17.7091 10.71L22.4091 6.00004L17.7091 1.29004L16.2891 2.71004L19.5891 6.00004L16.2891 9.29004ZM1.58984 18L6.28984 22.71L7.70984 21.29L4.40984 18L7.70984 14.71L6.28984 13.29L1.58984 18ZM18.9983 13V17H8.23828L7.23828 18L8.23828 19H20.9983V13H18.9983ZM15.76 5L16.76 6L15.76 7H5V11H3V5H15.76Z"
                fill={fill === 'gradient' ? 'url(#Repeat_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Repeat" />}
        </g>
    ),
}
