
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MaterialExtendedFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Material"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"palletSize"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"packaging"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"details"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"packagingQuantity"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uom"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"minimumOrder"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uom"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"amount"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"manufacturer"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":272}};
    doc.loc.source = {"body":"\n\nfragment MaterialExtendedFragment on Material {\n    palletSize\n    packaging {\n        type\n        name\n        details\n    }\n    packagingQuantity {\n        amount\n        uom\n    }\n    minimumOrder {\n      uom\n      amount\n    }\n    manufacturer {\n      name\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
