import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react'
import { Modal, Button, media } from '@bc/ui/src'
import styled from 'styled-components'
import { spacing, Text } from '@bc/ui'
import { UserContext, useNotifications } from '@frontend/context'
import { GQLNotificationType, GQLNotification } from '@bc/types'
import { useFormatMessage } from '../hooks'
import { BarCarouselIndicator } from './bar-carousel-indicator'

const PromoImage = styled.img`
    display: flex;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 25% 50%;
`
const PromoImageContainer = styled.div`
    flex: 2;
    margin: -${spacing.sm}px -${spacing.sm}px;

    ${media.max('sm')} {
        margin: -${spacing.xs}px -${spacing.xs}px;
    }
`

const PromoContent = styled.div`
    display: flex;
    flex: 3;
    min-height: 450px;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
`

const BarCarouselIndicatorContainer = styled.div`
    padding: ${spacing.xs}px 0;
    ${media.min('sm')} {
        margin-top: ${spacing.sm + 5}px;
    }
`
const PROMOTION_INTERVAL = 10000

export const Promotions = () => {
    const t = useFormatMessage()
    const [notificationStore, notifications, messageKey] = useNotifications()
    const { me } = useContext(UserContext)
    const [isOpen, setIsOpen] = useState(false)

    const promotions = useMemo(
        () =>
            notifications
                .filter(({ type }) => type === GQLNotificationType.promotion)
                .filter(notification => !notificationStore.hasBeenViewed(notification))
                .filter(({ [messageKey]: message }) => Boolean(message)),
        [notifications, messageKey, notificationStore],
    )
    const [promotion, setPromotion] = useState<GQLNotification | undefined>()

    const onCloseModal = () => {
        notificationStore.hideMany(promotions)
        setIsOpen(false)
        setPromotion(undefined)
    }

    const nextPromotion = useCallback((nextIndex: number) => setPromotion(promotions[nextIndex]), [promotions])

    useEffect(() => {
        if (isOpen === false && Boolean(me?.meta.completedOnboarding) && promotions.length > 0) {
            setPromotion(promotions[0])
            setIsOpen(true)
        }
    }, [me, promotions, isOpen])

    if (!promotion?.[messageKey]) {
        return null
    }

    const [header, ...linesOfText] = promotion[messageKey]!.split('\n')

    return (
        <Modal
            id="promotion-modal"
            open={isOpen}
            onClose={onCloseModal}
            type="large"
            proportion="2/3"
            content={
                <PromoImageContainer>
                    <PromoImage src="/static/images/promo-modal-image.png" />
                </PromoImageContainer>
            }
            aside={
                <>
                    <BarCarouselIndicatorContainer>
                        {promotions.length > 1 && isOpen && (
                            <BarCarouselIndicator
                                timeout={PROMOTION_INTERVAL}
                                onTimeout={nextPromotion}
                                feed={promotions}
                            />
                        )}
                    </BarCarouselIndicatorContainer>
                    <PromoContent>
                        <div>
                            <Text h1>{header}</Text>
                            {linesOfText.map(lineOfText => (
                                <Text key={lineOfText} p>
                                    {lineOfText}
                                </Text>
                            ))}
                        </div>
                        <Button
                            onClick={onCloseModal}
                            icon="Close"
                            variant="outline"
                            title={t('general:close')}
                            data-test-id="button-close">
                            {t('general:close')}
                        </Button>
                    </PromoContent>
                </>
            }
        />
    )
}
