import styled from 'styled-components'

import { colors, spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'

export interface TableRowProps {
    header?: boolean
    coloured?: boolean
}

export const TableRow = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 4px;
    padding: ${spacing.xs}px 0;
    background-color: ${colors.white};
    border: 1px solid ${colors.neutral20};
    border-collapse: collapse;

    ${media.min('sm')} {
        flex-wrap: nowrap;
        align-items: flex-start;
        margin-bottom: 0;
        padding: 0;
        width: 100%;
        :nth-child(odd) {
            background-color: ${({ coloured }) => (coloured ? colors.neutral10 : colors.white)};
        }
        :nth-child(even) {
            background-color: ${colors.white};
        }
    }

    ${media.max('sm')} {
        display: ${({ header }: TableRowProps) => (header ? 'none' : 'flex')};
    }
`
