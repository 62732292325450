import { css } from 'styled-components'

// Font
const fallbackFonts = "'Helvetica Neue','Helvetica','Arial',sans-serif"

export const fontFamily = {
    brenntagsans: ['BrenntagSans-Regular', fallbackFonts],
    brenntagsans_medium: ['BrenntagSans-Medium', fallbackFonts],
    brenntagsans_semibold: ['BrenntagSans-SemiBold', fallbackFonts],
    brenntagsans_bold: ['BrenntagSans-Bold', fallbackFonts],
    brenntagsans_extrabold: ['BrenntagSans-ExtraBold', fallbackFonts],
    brenntagsans_black: ['BrenntagSans-Black', fallbackFonts],
}

export const defaultFontSpacing = css`
    font-size: 1.4rem;
    line-height: 1.4286;
`
