import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { spacing, colors } from '@bc/ui'

interface BarCarouselIndicatorProps {
    timeout?: number
    onTimeout: (index: number) => void
    width?: string
    bgColor?: string
    color?: string
    feed: unknown[]
}

interface CarouselBarProps {
    active: boolean
    background: string
    foreground: string
}
const CarouselBar = styled.div<CarouselBarProps>`
    background: ${({ foreground, active, background }) => (active ? foreground : background)};
    flex: 1;
    max-width: ${spacing.lg - 4}px;
    height: 6px;
    cursor: ${({ active }) => (active ? 'default' : 'pointer')};
`

interface CarouselContainerProps {
    width?: string
}
const CarouselContainer = styled.div<CarouselContainerProps>`
    display: flex;
    background: transparent;
    flex: 1;
    justify-items: flex-start;
    gap: 4px;
    ${({ width }) => (width ? `width: ${width}` : '')}
`
const gradient = `linear-gradient(90deg, ${colors.violet50} 0%, ${colors.blue50} 100%)`
export const BarCarouselIndicator = ({
    timeout,
    onTimeout,
    width,
    bgColor = colors.neutral20,
    color = gradient,
    feed,
}: BarCarouselIndicatorProps) => {
    const [index, setIndex] = useState(0)
    const timer = useRef<number | undefined>(undefined)

    const resetTimeout = useCallback(
        (newIndex: number) => {
            onTimeout(newIndex)
            setIndex(newIndex)

            if (timeout) {
                clearTimeout(timer.current)
                timer.current = setTimeout(() => resetTimeout((newIndex + 1) % feed.length), timeout)
            }
        },
        [feed, timeout, onTimeout],
    )

    const handleOnClick = (clickIndex: number) => {
        if (clickIndex === index) {
            return
        }

        resetTimeout(clickIndex)
    }

    useEffect(() => {
        resetTimeout(0)

        return () => clearTimeout(timer.current)
    }, [resetTimeout, feed])

    return (
        <CarouselContainer width={width}>
            {Array(feed.length)
                .fill(0)
                .map((_, idx) => (
                    <CarouselBar
                        key={idx}
                        active={idx === index}
                        foreground={color}
                        background={bgColor}
                        onClick={() => handleOnClick(idx)}
                    />
                ))}
        </CarouselContainer>
    )
}
