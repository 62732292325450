import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Notification: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Notification" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M19 17H21V12.59L17 8.59V7C17 4.24 14.76 2 12 2C9.24 2 7 4.24 7 7V8.59L3 12.59V17H17V15H5V13.41L9 9.41V7C9 5.35 10.35 4 12 4C13.65 4 15 5.35 15 7V9.41L19 13.41V17ZM8 19C8 21.21 9.79 23 12 23C14.21 23 16 21.21 16 19H14C14 20.1 13.1 21 12 21C10.9 21 10 20.1 10 19H8Z"
                fill={fill === 'gradient' ? 'url(#Notification_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Notification" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Notification" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M19 17H21V12.59L17 8.59V7C17 4.24 14.76 2 12 2C9.24 2 7 4.24 7 7V8.59L3 12.59V17H17V15H5V13.41L9 9.41V7C9 5.35 10.35 4 12 4C13.65 4 15 5.35 15 7V9.41L19 13.41V17ZM8 19C8 21.21 9.79 23 12 23C14.21 23 16 21.21 16 19H14C14 20.1 13.1 21 12 21C10.9 21 10 20.1 10 19H8Z"
                fill={fill === 'gradient' ? 'url(#Notification_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Notification" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Notification" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M19 17H21V12.59L17 8.59V7C17 4.24 14.76 2 12 2C9.24 2 7 4.24 7 7V8.59L3 12.59V17H17V15H5V13.41L9 9.41V7C9 5.35 10.35 4 12 4C13.65 4 15 5.35 15 7V9.41L19 13.41V17ZM8 19C8 21.21 9.79 23 12 23C14.21 23 16 21.21 16 19H14C14 20.1 13.1 21 12 21C10.9 21 10 20.1 10 19H8Z"
                fill={fill === 'gradient' ? 'url(#Notification_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Notification" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Notification" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M19 17H21V12.59L17 8.59V7C17 4.24 14.76 2 12 2C9.24 2 7 4.24 7 7V8.59L3 12.59V17H17V15H5V13.41L9 9.41V7C9 5.35 10.35 4 12 4C13.65 4 15 5.35 15 7V9.41L19 13.41V17ZM8 19C8 21.21 9.79 23 12 23C14.21 23 16 21.21 16 19H14C14 20.1 13.1 21 12 21C10.9 21 10 20.1 10 19H8Z"
                fill={fill === 'gradient' ? 'url(#Notification_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Notification" />}
        </g>
    ),
}
