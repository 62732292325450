import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const History: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="History" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M2 12C2 6.49 6.49 2 12 2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22V20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12C4 14.5167 5.17361 16.7556 7 18.219V14H9V21H2V19H4.87C3.1 17.19 2 14.73 2 12ZM14.29 15.71L11 12.41V6H13V11.59L15.71 14.29L14.29 15.71Z"
                fill={fill === 'gradient' ? 'url(#History_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="History" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="History" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M2 12C2 6.49 6.49 2 12 2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22V20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12C4 14.5167 5.17361 16.7556 7 18.219V14H9V21H2V19H4.87C3.1 17.19 2 14.73 2 12ZM14.29 15.71L11 12.41V6H13V11.59L15.71 14.29L14.29 15.71Z"
                fill={fill === 'gradient' ? 'url(#History_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="History" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="History" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M2 12C2 6.49 6.49 2 12 2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22V20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12C4 14.5167 5.17361 16.7556 7 18.219V14H9V21H2V19H4.87C3.1 17.19 2 14.73 2 12ZM14.29 15.71L11 12.41V6H13V11.59L15.71 14.29L14.29 15.71Z"
                fill={fill === 'gradient' ? 'url(#History_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="History" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="History" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M2 12C2 6.49 6.49 2 12 2C17.51 2 22 6.49 22 12C22 17.51 17.51 22 12 22V20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12C4 14.5167 5.17361 16.7556 7 18.219V14H9V21H2V19H4.87C3.1 17.19 2 14.73 2 12ZM14.29 15.71L11 12.41V6H13V11.59L15.71 14.29L14.29 15.71Z"
                fill={fill === 'gradient' ? 'url(#History_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="History" />}
        </g>
    ),
}
