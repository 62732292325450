// tslint:disable no-invalid-template-strings

import { MessageMap } from '../../'

const messages: MessageMap = {
    'gender:male': 'Man',
    'gender:female': 'Vrouw',
    'gender:unknown': 'Onbekend',

    // Routes
    'route:maintenance': '/maintenance',
    'route:document': '/document',
    'route:document-not-found': '/document-not-found',

    'route:session-expired': '/session-expired',

    'route:logout': '/logout',
    'route:logout.label': 'Uitloggen',

    'route:reset-password': '/reset-password',
    'route:reset-password.label': 'Reset wachtwoord',

    'route:set-password': '/set-password',
    'route:set-password.label': 'Stel een wachtwoord in',

    'route:forgot-password': '/forgot-password',

    'route:terms-of-usage': '/terms-of-usage',
    'route:terms-of-usage.label': 'Gebruiksvoorwaarden',

    'route:product-list': '/product-list',
    'route:product-list.label': 'Artikelen',

    'route:order-history': '/my-orders',
    'route:order-history.label': 'Orders',

    'route:invoices': '/invoices',
    'route:invoices.label': 'Facturen',

    'route:cart-overview': '/checkout',
    'route:cart-overview.label': 'Uitchecken',

    'route:imprint.label': 'Colofon',
    'route:data-privacy-policy.label': 'Privacy statement',
    'route:faq.label': 'FAQ',

    'route:my-customers': '/my-customers',
    'route:my-customers.label': 'Mijn accounts',

    'route:contacts': '/contacts',
    'route:contacts.label': 'Mijn gebruikers',

    'route:quotes': '/quotes',
    'route:quotes.label': 'Quotes',

    'route:requests': '/requests',
    'route:requests.label': 'Aanvragen',

    'route:account': '/account',

    // Salutations
    'salutation.formal.male': 'Geachte heer {name}',
    'salutation.formal.female': 'Geachte mevrouw {name}',
    'salutation.formal.unknown': 'Beste klant',
    'salutation.informal.male': 'Beste klant',
    'salutation.informal.female': 'Beste klant',
    'salutation.informal.unknown': 'Beste klant',
    'salutation.internal.unknown': 'Beste collega',

    // Email subjects
    'email:subject:ORDER_CONFIRM': 'Brenntag Connect | Ordernummer {orderID} Nieuw Brenntag Connect Order',
    'email:subject:RFI_SALES_PERSON': 'Brenntag Connect | Informatieaanvraag {uid} van {name}',
    'email:subject:RFI_CUSTOMER': 'Brenntag Connect | Informatieaanvraag {uid}',
    'email:subject:RFI_COMPLETE': 'Brenntag Connect | Antwoord op uw aanvraag {uid}',
    'email:subject:RQ_CUSTOMER': 'Brenntag Connect | Aanvraag tot bestelling {uid}',
    'email:subject:RQ_SALES_PERSON': 'Brenntag Connect | Aanvraag tot bestelling {uid} van {name}',
    'email:subject:ORDER_WO_PRICE': 'Brenntag Connect | Order zonder geldige prijs',
    'email:subject:RESET_PW': 'Brenntag Connect | Aanvraag voor het instellen van een wachtwoord',
    'email:subject:WELCOME': 'Brenntag Connect | Welkom bij Brenntag Connect',
    'email:subject:SC_CUSTOMER': 'Brenntag Connect | Bestellingsaanvraag {uid}',
    'email:subject:SC_SALES_PERSON': 'Brenntag Connect | Bestellingsaanvraag {uid} van {name}',

    // Cookiebar
    'cookiebar:text1':
        'Deze website maakt gebruik van cookies om uw ervaring te verbeteren. Door op OK te klikken, gaat u akkoord met ons cookie-beleid.',
    'cookiebar:text2': 'Voor gedetailleerde richtlijnen, verwijzen wij u naar onze',
    'cookiebar:accept-button': 'OK',

    // FAQ
    'faq:contact-title': 'Laat ons weten hoe we kunnen helpen',
    'faq:contact-text':
        'Wij zijn er om ervoor te zorgen dat u het maximum uit Brenntag Connect haalt. Wat uw vraag of probleem ook is, stuur ons een e-mail en wij zullen deze zo snel mogelijk beantwoorden.',
    'faq:browse-categories': 'Bladeren door rubrieken',

    // Login page
    'login:header': 'Log in op uw account',
    'login:welcome-header': 'Welkom bij Brenntag Connect!',
    'login:intro':
        'Brenntag Connect is uw online platform waarop u eenvoudig chemicaliën kunt bestellen en een uitgebreide, up-to-date verzameling van relevante informatie.',
    'login:intro-benefit': 'Als geregistreerde klant profiteert u van onze online diensten:',
    'login:intro-bullet-1': 'Gedetailleerde productinformatie en documentatie aanvragen',
    'login:intro-bullet-2': 'Bekijk actuele prijzen (afhankelijk van beschikbaarheid)',
    'login:intro-bullet-3': 'Eenvoudig en snel producten bestellen.',
    'login:intro-bullet-4': 'Bekijk uw bestelgeschiedenis en facturen van de laatste 24 maanden',
    'login:help-title': 'Kunnen we u helpen?',
    'login:help-instruction': 'Neem dan contact op met onze Customer Service. We helpen u graag verder.',
    'login:help-phone-number': 'Telefoonnummer:',
    'login:help-office-hours': 'Onze kantooruren:',
    'login:contact-more': 'Of vertel ons meer over uw verzoek:',
    'login:contact-button': 'Neem contact met ons op',

    'login:username': 'E-mailadres',
    'login:username-placeholder': 'uw@email.com',
    'login:password': 'Wachtwoord',
    'login:password-placeholder': 'Voer uw wachtwoord in',
    'login:button.forgot-password': 'Wachtwoord vergeten?',
    'login:back-to-login': 'Ga naar de login pagina',
    'login:copyright': 'Alle rechten voorbehouden.',
    'login:brenntag': '© Brenntag SE',

    // Login page with MFA
    'login-mfa:title': 'Bevestig uw identiteit',
    'login-mfa:intro':
        'We hebben zonet een verificatiecode naar uw e-mailadres verstuurd. Voer hier de ontvangen code in en klik op "Verifiëren."',
    'login-mfa-form:input': 'Voer de 6-cijferige code in',
    'login-mfa-form:submit': 'Verifiëren',

    // Login form
    'login-form:error': 'Aanmelden is mislukt, controleer uw inloggegevens',
    'login-form:error.access_denied': 'Aanmelden is mislukt, controleer uw inloggegevens',
    'login-form:error.too_many_attempts': 'Deze account is geblokkeerd wegens te veel pogingen om in te loggen.',

    // Reset password page
    'reset-password:title': 'Stel uw wachtwoord in',
    'reset-password:body':
        'Gelieve uw inbox te controleren - we hebben u een e-mail gestuurd om uw wachtwoord in te stellen.',

    // Set password token error
    'warning:invalid-token:title': 'Onze excuses!',
    'warning:invalid-token:description': 'Deze token is verlopen! Stel het wachtwoord opnieuw in.',

    // Auth error page
    'auth-error:message': 'Inloggen mislukt, probeer opnieuw in te loggen.',
    'auth-error:expired-link:title': 'Deze activatielink is verlopen',
    'auth-error:expired-link:message':
        'Uw activatie link is verlopen, reset uw wachtwoord zodat de link kan worden vernieuwd.',
    'auth-error:link-text': 'Stel uw wachtwoord opnieuw in',
    'auth0-error:passworddictionaryerror': 'Het wachtwoord is niet veilig genoeg.',
    'auth0-error:passwordstrengtherror': 'Het wachtwoord is niet veilig genoeg.',
    'auth0-error:passwordhistoryerror': 'Het wachtwoord is al eerder gebruikt.',
    'auth0-error:passwordnouserinfoerror': 'Het wachtwoord bevat gebruikersinformatie. ',
    'auth0-error:csrfinvalidtokenerror': 'Deze token is verlopen! Stel het wachtwoord opnieuw in.',

    // Rate limit error
    'rate-limit-error:message': 'Te veel verzoeken. Probeer het later opnieuw.',

    // Forgot password form
    'forgot-password-form:title': 'Uw wachtwoord vergeten?',
    'forgot-password-form:description':
        'Als u zich eerder hebt geregistreerd bij ons, ontvangt u een e-mail op het e-mailadres dat bij ons bekend is om uw wachtwoord te resetten.',

    'forgot-password-form:email': 'E-mailadres',
    'forgot-password-form:submit': 'E-mail verzenden',
    'forgot-password-form:success': 'We hebben net een e-mail gestuurd om uw wachtwoord opnieuw in te stellen.',

    // Set password form
    'set-password-form:title': 'Wachtwoord instellen',
    'set-password-form:description': 'Stel een nieuw wachtwoord in',
    'set-password-form:new-password': 'Nieuw wachtwoord',
    'set-password-form:new-password-confirmation': 'Herhaal nieuw wachtwoord',
    'set-password-form:password-instruction':
        'U dient een nieuw wachtwoord in te stellen. Het nieuwe wachtwoord is 180 dagen geldig.',
    'set-password-form:success-title': 'Succes!',
    'set-password-form:success':
        'Beste klant, \nals onderdeel van onze voortdurende initiatieven om u veilige toegang te bieden, hebben wij een 2 Factor Authentication (2FA) geïmplementeerd. Daarom hebben wij u automatisch uitgelogd nadat u uw eerste wachtwoord heeft ingesteld. \nGaat u a.u.b. naar de login pagina, voer uw wachtwoord in en bevestig het met de verificatiecode die u in uw email inbox zal ontvangen om toegang te krijgen tot Brenntag Connect.',

    // My Customers Page
    'my-customers:city': 'Stad',
    'my-customers:customer-id': 'Klantnummer',
    'my-customers:customer-name': 'Klantnaam (bedrijfsnaam)',
    'my-customers:customer-vat-number': 'BTW-nummer',
    'my-customers:headline': 'Mijn accounts',
    'my-customers:last-order-date': 'Laatste besteldatum',
    'my-customers:products': 'Artikelen',
    'my-customers:outstanding-orders': 'Openstaande orders',
    'my-customers:items-in-cart': 'Artikelen in de winkelwagen',
    'my-customers:search-for-customer': 'Zoeken naar een klant',
    'my-customers:select-user': 'Kiezen',
    'my-customers:use-search-options': 'Gebruik een van de volgende zoekopties',
    'my-customers:search-error': 'Er is een fout opgetreden tijdens het zoeken naar klanten.',
    'my-customers:division': 'Afdeling',

    // Requests Page
    'requests:title-csr': 'Klantaanvragen',
    'requests:title-customer': 'Mijn aanvragen',
    'requests:application': 'Toepassing',
    'requests:csr-subtitle-one': 'Wenst u contact met ons op te nemen? Stuur ons een e-mail via:',
    'requests:csr-subtitle-two': 'of bel op het nummer {csrPhone}',
    'requests:delivery-date': 'Leverdatum',
    'requests:documents': 'Documenten',
    'requests:documents-max-size': '(Max {maxSize}MB)',
    'requests:email': 'e-mail',
    'requests:messages': 'Berichten',
    'requests:po-reference': 'Klantreferentie',
    'requests:quantity-annual': 'Jaarlijkse hoeveelheid',
    'requests:quantity-call-off': 'Hoeveelheid per levering',
    'requests:quantity-sample': 'Sample hoeveelheid',
    'requests:requested': 'Aangevraagd op',
    'requests:requested-documents': 'Gevraagde documenten',
    'requests:ship-to-address': 'Leveringsadres',
    'requests:status': 'Status',
    'requests:button.edit-complete': 'Reactie bewerken',
    'requests:button.edit': 'Reageren',
    'requests:edit-modal-title': 'Aanvraag bewerken',
    'requests:edit-modal-summary': 'Samenvatting Aanvraag',
    'requests:comments': 'Commentaar',
    'requests:file-too-big': 'File {name} is te groot',
    'requests:max-upload': 'Max uploaden van {mb} MB grootte bereikt, file {name} is niet toegevoegd',
    'requests:mime-types': 'File {name} type: {type} is niet toegestaan',
    'requests:customer': 'Klant',
    'requests:recipient': 'Ontvanger',
    'requests:details': 'Details',
    'requests:user-comment': 'Gebruikerscommentaar',
    'requests:response': 'Ons team zal zo snel mogelijk op uw verzoek reageren!',
    'requests:questions': 'Heeft u nog steeds niet ontvangen wat u heeft gevraagd? Neem contact op:',

    // Request enum
    'request-status:COMPLETED': 'Voltooid',
    'request-status:PENDING': 'In afwachting',
    'request-type:DOCUMENT': 'Document',
    'request-type:PRICE': 'Prijs',
    'request-type:PROFILE': 'Profiel',
    'request-type:QUOTE': 'Bestelling',
    'request-type:SAMPLE': 'Staal',
    'request-type:CART': 'Bestelling',

    // User management page
    'user:country': 'Land',
    'user:role': 'Rol',
    'user:customer-id': 'Klantnummer',
    'user:last-login': 'Laatste login',
    'user:send-emails': 'E-mail opnieuw verzenden',
    'user:email-welcome': 'Welkomstmail opnieuw verzenden',
    'user:email-welcome-to-support': 'Welkomstmail naar DigiB Support verzenden',
    'user:email-password': 'Email met wachtwoordreset opnieuw verzenden',
    'user:email-password-to-support': 'Email met wachtwoordreset naar DigiB Support verzenden',
    'user:admin-actions': 'Beheerdersacties',
    'user:select-admin-action': 'Selecteer een actie',
    'user:email-welcome-sent': 'Welkomstmail verzonden op',
    'user:edit-user': 'Bewerk',
    'user:create-new-user': 'Gebruiker aanmaken',
    'user:sort:username': 'Gebruikersnaam',
    'user:sort:logindate': 'Login datum',
    'user:email-modal-welcome-title': 'Welkomstmail opnieuw verzenden',
    'user:email-modal-welcome-text':
        'Weet u zeker dat u opnieuw de welkomstmail wilt verzenden naar deze gebruiker ({email})?',
    'user:email-modal-welcome-to-support-text': 'Weet u zeker dat u de welkomstmail wilt verzenden naar DigiB Support?',
    'user:email-modal-welcome-cancel': 'Annuleren',
    'user:email-modal-welcome-confirm': 'E-mail verzenden',
    'user:email-modal-pwreset-title': 'Stuur Password Reset e-mail',
    'user:email-modal-pwreset-text':
        'Weet u zeker dat u een wachtwoord reset e-mail wil sturen naar deze gebruiker ({email})?',
    'user:email-modal-pwreset-to-support-text':
        'Weet u zeker dat u de e-mail met wachtwoordreset wilt verzenden naar DigiB Support?',
    'user:email-modal-pwreset-cancel': 'Annuleren',
    'user:email-modal-pwreset-confirm': 'E-mail verzenden',
    'user:edit-user-modal-title': 'Gebruiker bewerken',
    'user:create-user-modal-title': 'Nieuwe gebruiker',
    'user:limited-search-results':
        'Tonen {number} van de {count} resultaten. Gebruik de filters aan de linkerkant om specifieke gebruikers te vinden.',

    // Form labels & placeholders user management
    'user:form:label-email': 'E-mail',
    'user:form:label-email-placeholder': 'E-mailadres invoeren',
    'user:form:label-firstname': 'Voornaam',
    'user:form:label-firstname-placeholder': 'Vul voornaam in',
    'user:form:label-lastname': 'Achternaam',
    'user:form:label-lastname-placeholder': 'Vul achternaam in',
    'user:form:label-customerids': 'Klantnummer',
    'user:form:label-customerids-placeholder': 'Vul klantnummer in',
    'user:form:label-language': 'Taal',
    'user:form:label-gender': 'Geslacht',
    'user:form:label-country': 'Land',
    'user:form:label-customer-id-and-sales-org': 'Klantnummer en verkoopsorganisatie',
    'user:form:label-customer-grant-access': 'Verleen toegang aan één of meerdere klanten:',
    'user:form:error-customer-grant-access': 'Verleen toegang aan één of meerdere klanten.',
    'user:form:error-select-one-sales-org': 'Voeg minimaal één verkoopsorganisatie toe.',
    'user:form:error-search': 'Er is iets misgegaan, probeer een nieuwe zoekopdracht.',
    'user:form:search-no-results': 'Geen resultaten gevonden, u kan ook zoeken op naam, klantnummer of btw-nummer.',
    'user:form:usertype-viewer': 'Deze gebruiker kan alleen documenten downloaden.',
    'user:form:label-usertype': 'Gebruikersrechten',
    'user:form:label-mfa': 'Multi-Factor Authentication (MFA)',
    'user:form:label-enablemfa': 'MFA inschakelen',
    'user:form:label-placeholder': 'Selecteer een gebruikersrecht svp',

    // Customer Selection Dropdown
    'company:selection:label-bill-to': 'Factuuradres:',
    'company:selection:label-country': 'Land:',
    'company:selection:change': 'Wijzigen',
    'company:selection:label-customer-id': 'Klantnummer:',
    'company:selection:label-vat': 'BTW-nummer:',
    'company:selection:label-name': 'Naam:',
    'company:selection:title': 'Please select a company',
    'company:selection:intro.one': 'In order to proceed and view relevant information',
    'company:selection:intro.two': 'We request you to select the company name in the table below.',

    // Products
    'products:column.material': 'Artikel',
    'products:card.material': 'Artikelnaam',
    'products:column.cas-no': 'CAS-nummer',
    'products:column.filling-weight': 'Afvulgewicht',
    'products:column.supplier': 'Leverancier',
    'products:column.grade': 'Rang',
    'products:column.detail-packaging': 'Verpakkingsdetails',
    'products:column.einecs': 'Einecs',
    'products:column.unit-capacity': 'Eenheidscapaciteit',

    'products:more-details-button-text': 'Meer details',
    'products:blend': '...',
    'products:column.material-number': 'Artikelnummer',

    // Product list
    'product-list:headline': '{count} Artikelen',
    'product-list:empty-cart': 'Artikellijst',
    'product-list:request-info': 'Info',

    // Product Modal
    'product-modal:scale-prices': 'Actuele prijs',

    // Contact person
    'contact-person:title': 'Heeft u vragen over deze order?',
    'contact-person:subtitle': 'Neem contact op met onze Customer Service',
    'contact-person:customer-service': 'Customer Service ',

    // Add to cart form
    'add-to-cart-form:success-toast': 'Het artikel is succesvol toegevoegd aan uw winkelwagen ',

    // Checkout
    'checkout:headline': 'Orderoverzicht',
    'checkout:products': 'Artikelen',
    'checkout:empty-cart': 'Uw winkelwagentje is leeg',
    'checkout:empty-cart-message':
        'Begin met het toevoegen van producten aan uw bestelling of zoek in de lijst met artikelen.',
    'checkout:button-continue-shopping': 'Verder winkelen',

    'checkout-success:order-submitted': 'Uw bestelling is ingediend!',
    'checkout-success:final-instruction':
        'U ontvangt van ons een e-mail zodra uw bestelling is goedgekeurd. <br/> Gebruik Brenntag Connect om facturen en veiligheidsdocumenten te downloaden.<br/> <br/> Voor vragen over deze bestelling kunt u een e-mail sturen naar <a href="mailto:{email}">{email}</a>',
    'checkout-success:order-number': 'Aanvraagnummer',
    'checkout-success:order-date': 'Aanvraagdatum',
    'checkout-success:button-view-requests': 'Bekijk aanvragen ',
    'checkout-success:button-view-orders': 'Bekijk orders',

    // Delivery overview
    'delivery-overview:title': 'Leveringsoverzicht',
    'delivery-overview:billing-address': 'Factuuradres',
    'delivery-overview:comment': 'Commentaar',
    'delivery-overview:delivery-date': 'Gewenste leverdatum',
    'delivery-overview:legal-terms': ' zijn van toepassing op deze bestelling.',
    'delivery-overview:legal-terms-name': 'Onze algemene verkoopsvoorwaarden',
    'delivery-overview:legal-terms-price': 'Prijs exclusief BTW en toeslagen',
    'delivery-overview:payment-terms': 'Betalingsvoorwaarden',
    'delivery-overview:no-payment-terms': 'Geen betaalvoorwaarden geselecteerd',
    'delivery-overview:po-reference': 'Klantreferentie',
    'delivery-overview:order-id': 'Ordernummer',
    'delivery-overview:same-as-delivery-address': 'Hetzelfde als leveringsadres',
    'delivery-overview:agree-with-terms': 'Door het plaatsen van een bestelling accepteert u',
    'delivery-overview:submit': 'Bestelling plaatsen',
    'delivery-overview:terms-of-delivery': 'Leverings voorwaarden',
    'delivery-overview:INCO-terms': 'Incoterms',
    'delivery-overview:status': 'Status',
    'delivery-overview:packaging': 'Verpakkingsvoorwaarden',
    'delivery-overview:agree-with-terms-warning': 'Gelieve onze algemene verkoopvoorwaarden te accepteren',
    'delivery-overview:INCO-terms-as-agreed': 'Zoals afgesproken',
    'delivery-overview:payment-terms-as-agreed': 'Zoals afgesproken',

    // Invoices
    'invoices:date-invoice': 'Factuurdatum',
    'invoices:date-due': 'Vervaldatum',
    'invoices:download': 'Download factuur',
    'invoices:title': 'Factuurnummer: {id}',
    'invoices:total-amount': 'Totale waarde (incl. BTW)',

    // Order
    'order:next-steps.title': 'Volgende',
    'order:line-item.material-no': 'Artikelnummer',
    'order:line-item.pieces': 'Stuks',
    'order:line-item.amount': 'Bedrag',
    'order:line-item.requested': 'gevraagd voor',
    'order:line-item.confirmed': 'Bevestigd voor',
    'order:line-item.delivered': 'Afgeleverd op',
    'order:line-item.order-quantity': 'Bestelhoeveelheid',
    'order:line-item.batch': 'Batch No.',
    'order:line-item.total-material-price': 'Totale prijs van het artikel',
    'order:line-item.price': 'Prijs',
    'order:line-item.request-price': 'Prijs op aanvraag',
    'order:status.delivered': 'Geleverd',
    'order:status.pending': 'In afwachting',
    'order:status.indelivery': 'Onderweg',
    'order:status.confirmed': 'Bevestigd',
    'order:status.invoiced': 'Gefactureerd',
    'order:status.cancelled': 'Geannuleerd',
    'order:status.processed': 'Verwerkt',
    'order:status.loading': 'Laden',
    'order:status.shipped': 'Verzonden',

    // Request types
    'order:status.document': 'Document',
    'order:status.price': 'Prijs',
    'order:status.profile': 'Profiel',
    'order:status.quote': 'Bestelling',
    'order:status.sample': 'Staal',

    'orderline:pieces': 'Stuks',

    // Orders
    'orders:column.status': 'Status',
    'orders:column.sales-number': 'Brenntag verkooporder nummer',
    'orders:column.po-reference': 'Klantreferentie',
    'orders:column.order-date': 'Besteldatum',
    'orders:column.delivery-address': 'Afleveradres',
    'orders:column.materials-amount': 'Aantal artikelen',
    'orders:column.delivery-date': 'Leverdatum',
    'orders:column.delivery-date-from-to': 'Meerdere leveringen tussen',
    'orders:column.requested-delivery-date': 'Gewenste leverdatum',
    'orders:column.po-reference-abbreviation': 'Klantreferentie',
    'orders:button.confirmation': 'Orderbevestiging',

    // Quotes
    'quotes:column.offer-date': 'Datum aanbieding',
    'quotes:column.delivery-address': 'Afleveradres',

    // Material
    'general:material-id': 'Artikelnummer',
    'general:packaging': 'Verpakkingsvoorwaarden',
    'general:pallet': 'Pallet',
    'general:price': 'Prijs',
    'general:unit-price': 'Prijs per eenheid',
    'general:price-confirmation': 'Prijs nog te bevestigen',
    'general:pieces': 'Stuks',
    'general:pieces-plural': '{Aantal, meervoud, een {Stuk} of {Stuks}}',
    'general:pieces-long': 'Hoeveelheid',
    'general:amount': 'Aantal',

    // General
    'general:filters': 'Filter op',
    'general:login': 'Aanmelden',
    'general:cancel': 'Annuleren',
    'general:save': 'Opslaan',
    'general:save-and-send': 'Opslaan & Verzenden',
    'general:send': 'Verzenden',
    'general:delete': 'Verwijder',
    'general:date-format.day': 'D',
    'general:date-format.month': 'M',
    'general:date-format.year': 'J',
    'general:error': 'Er ging iets mis. Gelieve de pagina te vernieuwen.',
    'general:close': 'Sluiten',
    'general:type-here': 'Typ hier',
    'general:send-us-email': 'Vraag om hulp',
    'general:error.label': 'Fout:',
    'general:back-to-overview': 'Terug naar overzicht',
    'general:search': 'Zoeken',
    'general:delivery-address': 'Bezorgadres',
    'general:incoterm': 'Leveringsvoorwaarden',
    'general:order': 'Bestellen',
    'general:order-id': 'Brenntag Ordernummer',
    'general:quote': 'Aanbieding',
    'general:offer-id': 'Brenntag-aanbiedingsnummer',
    'general:upload': 'Selecteer bestand',
    'general:documents': 'Documenten',
    'general:select-ship-to': 'Selecteer afleveradres',
    'general:ship-to': 'Afleveradres',
    'general:po-reference': 'Klantreferentie',
    'general:sorry': 'Onze excuses!',
    'general:something-went-wrong': 'Er ging iets mis. Gelieve de pagina te vernieuwen.',
    'general:save-changes': 'Wijzigingen opslaan',
    'general:order-amount': 'Hoeveelheid',
    'general:order-quantity': 'Bestelhoeveelheid',
    'general:required-fields': '* Verplichte velden',
    'general:file-attached': 'Bijgevoegd document',
    'general:file-attached-plural': 'Bijgevoegde documenten',
    'general:attach-document': 'Voeg een document bij',
    'general:attach-document-plural': 'Voeg een of meer documenten toe',
    'general:price-conversion': 'Valutaomrekening',
    'general:id': 'ID',
    'general:quantity-below-minimum': 'De gevraagde hoeveelheid ligt onder de minimumorderhoeveelheid.',
    'general:po-reference-info':
        'Een klantreferentie is verplicht bij het plaatsen van uw bestelling. Dit kan uw inkoopordernummer zijn of een referentie zoals uw naam of een datum. Deze referentie wordt gebruikt bij het afleveren van de goederen en tevens vermeld op uw factuur.',

    // react-day-picker
    'react-day-picker:first-day-of-week': '1',
    'react-day-picker:months.january': 'januari',
    'react-day-picker:months.february': 'februari',
    'react-day-picker:months.march': 'maart',
    'react-day-picker:months.april': 'april',
    'react-day-picker:months.may': 'mei',
    'react-day-picker:months.june': 'juni',
    'react-day-picker:months.july': 'juli',
    'react-day-picker:months.august': 'augustus',
    'react-day-picker:months.september': 'september',
    'react-day-picker:months.october': 'oktober',
    'react-day-picker:months.november': 'november',
    'react-day-picker:months.december': 'december',
    'react-day-picker:next-month': 'Volgende maand',
    'react-day-picker:previous-month': 'Vorige maand',
    'react-day-picker:weekdays-short.monday': 'M',
    'react-day-picker:weekdays-short.tuesday': 'T',
    'react-day-picker:weekdays-short.wednesday': 'W',
    'react-day-picker:weekdays-short.thursday': 'D',
    'react-day-picker:weekdays-short.friday': 'V',
    'react-day-picker:weekdays-short.saturday': 'Z',
    'react-day-picker:weekdays-short.sunday': 'Z',
    'react-day-picker:weekdays-long.monday': 'maandag',
    'react-day-picker:weekdays-long.tuesday': 'dinsdag',
    'react-day-picker:weekdays-long.wednesday': 'woensdag',
    'react-day-picker:weekdays-long.thursday': 'donderdag',
    'react-day-picker:weekdays-long.friday': 'vrijdag',
    'react-day-picker:weekdays-long.saturday': 'zaterdag',
    'react-day-picker:weekdays-long.sunday': 'zondag',

    // Filters and sorting
    'filters:search-by-name': 'Artikelnaam',
    'filters:search-by-name.placeholder': 'Artikelnaam',
    'filters:search-by-cas-no': 'CAS-nummer',
    'filters:search-by-cas-no.tooltip': 'Inclusief koppeltekens',
    'filters:search-by-cas-no.placeholder': 'CAS-nummer',
    'filters:search-by-role': 'Rol',
    'filters:search-by-role.placeholder': 'Alle',
    'filters:search-by-csr.placeholder': 'CSR',
    'filters:search-by-admin.placeholder': 'Admin',
    'filters:search-by-user.placeholder': 'Gebruiker',
    'filters:search-by-viewer.placeholder': 'Read-only',
    'filters:search-by-customer-product-number': 'Artikelnummer',
    'filters:search-by-customer-product-number.placeholder': 'Artikelnummer',
    'filters:search-by-company-name': 'Klantnaam',
    'filters:search-by-company-name.placeholder': 'Klantnaam',
    'filters:search-by-customer': 'Zoeken naar Klant',
    'filters:search-by-customerID': 'Klantnummer',
    'filters:search-by-customerID.placeholder': 'Klantnummer',
    'filters:search-by-customerID-or-name.placeholder': 'Klantnaam of Nummer',
    'filters:search-by-customerID-or-name-or-vat.placeholder': 'Zoeken op Klantnaam of Nummer of BTW-nummer',
    'filters:search-by-offer-number': 'Biedingsnummer',
    'filters:search-by-offer-number.placeholder': 'Biedingsnummer',
    'filters:search-by-po-reference': 'Klantreferentie',
    'filters:search-by-po-reference.placeholder': 'Klantreferentie',
    'filters:search-by-material-name': 'Artikelnaam',
    'filters:search-by-material-name.placeholder': 'Artikelnaam',
    'filters:search-by-username': 'Gebruikersnaam',

    'filters:search-by-here.placeholder': 'Zoek hier',
    'filters:select-ship-to-address': 'Afleveradres',
    'filters:select-an-address': 'Zoek of selecteer een Adres ',
    'filters:clear-filters': 'Reset filters',
    'filter-packaging.de:filters.names':
        'Drum with screw cap, Box, Drum with lid,Bag, Bulk,IBC,Canister,Sample,Bottle/Can,Pouch,Pail,Cylinder,Can,Big Bag,SAFE-TAINER™,Drum,Steel-Bottle',
    'filter-packaging.de:filters.values':
        'Spundfaß,Karton,Deckelfaß,Sack,TKW,IBC,Kanister,Muster,Flasche/Kanne,Beutel,Eimer,Trommel,Dose,Big bag,Safetainer,Gasbehälter,Gasflasche',
    'filter-packaging.bnl:filters.names':
        'Ampule,Zak,Baal,Fles,Doos,Emmer,Bulk,Can,Cartridge,Kist,Container,Cilinder,Vat,Flexibag,Flexidrum,Flexitank,Foliezak,Frame,IBC,Log,Octabin,Pallet,Palletbox,Stuk,Rol,Roll,Safetainer,Staal,Securitainer,Set,Spray,Tube',
    'filter-packaging.bnl:filters.values':
        'AMPULE,BAG,BALE,BOTTLE,BOX,BUCKET,BULK,CAN,CARTRIDGE,CASE,CONTAINER,CYLINDER,DRUM,FLEXIBAG,FLEXIDRUM,FLEXITANK,FOILBAG,FRAME,IBC,LOG,OCTABIN,PALLET,PALLETBOX,PIECE,REEL,ROLL,SAFETAINER,SAMPLE,SECURITAINER,SET,SPRAY,TUBE',
    'filter-packaging.cee:filters.names':
        'Zak, Fles, Emmer, Bulk, Can, Vat, IBC, Octabin, Pallet, Safetainer, Andere, Bus, Bigbag, Kartonvat, Karton, Kist, Pakket',
    'filter-packaging.cee:filters.values':
        'BAG,BOTTLE,BUCKET,BULK,CAN,DRUM,IBC,OCTABIN,PALLET,SAFETAINER,OTHER,CANISTER,BIGBAG,PAPERBOARD BARREL,CARDBOARD,CHEST,PACKAGE',

    'filters:select-a-packaging-title': 'Selecteer Verpakking',
    'filters:order-status': 'Orderstatus',
    'filters:select-a-status': 'Selecteer een Status',
    'filters:search-by-request-no': 'Aanvraagnummer',
    'filters:search-by-request-no.placeholder': 'Aanvraagnummer',
    'filters:search-by-status': 'Status',
    'filters:search-by-status.placeholder': 'Selecteer een Status',
    'filters:request-type': 'Aanvraagtype',
    'filters:select-request-type': 'Selecteer een Aanvraagtype',
    'filters:select-country': 'Land',
    'filters:search-by-invoiceID': 'Factuurnummer',
    'filters:search-by-invoiceID.placeholder': 'Factuurnummer',
    'filters:search-by-einecs-no': 'EINECS-nummer',
    'filters:search-by-einecs-no.placeholder': 'EINECS-nummer',
    'filters:packaging-title': 'Verpakkingsvoorwaarden',
    'filters:search-by-country': 'Land',
    'filters:search-by-country.placeholder': 'Alle Landen',
    'filters:search-by-role.error-select-one': 'Selecteer minimaal één rol.',
    'filters:copy-link': 'Koppeling kopiëren',
    'filters:copy-link-success': 'De koppeling is gekopieerd naar het klembord.',
    'filters:copy-link-error': 'Kopieer de volgende link:',

    'sort-direction:numeric:asc': '(0-9)',
    'sort-direction:numeric:desc': '(9-0)',
    'sort-direction:string:asc': '(A-Z)',
    'sort-direction:string:desc': '(Z-A)',
    'sort-direction:date:asc': '(oud-nieuw)',
    'sort-direction:date:desc': '(nieuw-oud)',

    'sort-select:sort-by': 'Sorteer op',
    'sort-by-request-id': 'Nummer',
    'sort-by-type': 'Type',
    'sort-by-date': 'Datum',
    'sort-by-status': 'Status',
    'sort-by-due-date': 'Vervaldatum',
    'sort-by-invoice-date': 'Factuurdatum',
    'sort-by-order-date': 'Besteldatum',
    'sort-by-po-reference': 'Klantreferentie',
    'sort-by-invoice-type': 'Factuurtype',
    'sort-by-invoice-id': 'Factuurnummer',
    'sort-by-requested-delivery-date': 'Leverdatum',
    'sort-by-offer-number': 'Offertenummer',
    'sort-by-material-name': 'Productnaam',
    'sort-by-quote-date': 'Offertedatum',

    // Packaging Names
    'package:Spundfaß': 'Vat (schroefdop)',
    'package:Safetainer': 'SAFE-TAINER™',
    'package:Gasbehälter': 'Vat',
    'package:Gasflasche': 'Stalen fles',
    'package:Karton': 'Doos',
    'package:Deckelfaß': 'Vat (deksel)',
    'package:Sack': 'Zak',
    'package:TKW': 'Bulk',
    'package:IBC': 'IBC',
    'package:Kanister': 'Blik',
    'package:Muster': 'Monster / staal',
    'package:Flasche/Kanne': 'Fles/kan',
    'package:Beutel': 'Zak',
    'package:Eimer': 'Emmer',
    'package:Trommel': 'Cilinder',
    'package:Dose': 'Kan',
    'package:Big bag': 'Big Bag',

    'package-pl:Spundfaß': 'Vatten (schroefdop)',
    'package-pl:Safetainer': 'SAFE-TAINERs™',
    'package-pl:Gasbehälter': 'Vaten',
    'package-pl:Gasflasche': 'Stalen flessen',
    'package-pl:Karton': 'Dozen',
    'package-pl:Deckelfaß': 'Vatten (deksel)',
    'package-pl:Sack': 'Zakken',
    'package-pl:TKW': 'Bulk',
    'package-pl:IBC': 'IBC’s',
    'package-pl:Kanister': 'Blikken',
    'package-pl:Muster': 'Monsters / stalen',
    'package-pl:Flasche/Kanne': 'Flessen/kans',
    'package-pl:Beutel': 'Zakken',
    'package-pl:Eimer': 'Emmers',
    'package-pl:Trommel': 'Cilinders',
    'package-pl:Dose': 'Kans',
    'package-pl:Big bag': 'Big Bags',

    // BNL Packaging Names
    'package:AMPULE': 'Ampule',
    'package:BAG': 'Zak',
    'package:BALE': 'Baal',
    'package:BOTTLE': 'Fles',
    'package:BOX': 'Doos',
    'package:BUCKET': 'Emmer',
    'package:BULK': 'Bulk',
    'package:CAN': 'Can',
    'package:CARTRIDGE': 'Cartridge',
    'package:CASE': 'Kist',
    'package:CONTAINER': 'Container',
    'package:CYLINDER': 'Cilinder',
    'package:DRUM': 'Vat',
    'package:FLEXIBAG': 'Flexibag',
    'package:FLEXIDRUM': 'Flexidrum',
    'package:FLEXITANK': 'Flexitank',
    'package:FOILBAG': 'Foliezak',
    'package:FRAME': 'Frame',
    'package:LOG': 'Blok',
    'package:OCTABIN': 'Octabin',
    'package:PALLET': 'Pallet',
    'package:PALLETBOX': 'Palletbox',
    'package:PIECE': 'Stuk',
    'package:REEL': 'Haspel',
    'package:ROLL': 'Rol',
    'package:SAFETAINER': 'Safetainer',
    'package:SAMPLE': 'Monster / staal',
    'package:SECURITAINER': 'Securitainer',
    'package:SET': 'Set',
    'package:SPRAY': 'Spuitbus',
    'package:TUBE': 'Tube',
    'package:OTHER': 'Andere',
    'package:CANISTER': 'Busje',
    'package:BIGBAG': 'Bigbag',
    'package:PAPERBOARD BARREL': 'Kartonnen vat',
    'package:CARDBOARD': 'Karton',
    'package:CHEST': 'Kist',
    'package:PACKAGE': 'Pakket',

    // BNL packaging plurals
    'package-pl:AMPULE': 'Ampules',
    'package-pl:BAG': 'Zakken',
    'package-pl:BALE': 'Balen',
    'package-pl:BOTTLE': 'Flessen',
    'package-pl:BOX': 'Dozen',
    'package-pl:BUCKET': 'Emmers',
    'package-pl:BULK': 'Bulk',
    'package-pl:CAN': 'Cans',
    'package-pl:CARTRIDGE': 'Cartridges',
    'package-pl:CASE': 'Kisten',
    'package-pl:CONTAINER': 'Containers',
    'package-pl:CYLINDER': 'Cilinders',
    'package-pl:DRUM': 'Vaten',
    'package-pl:FLEXIBAG': 'Flexibags',
    'package-pl:FLEXIDRUM': 'Flexidrums',
    'package-pl:FLEXITANK': 'Flexitanks',
    'package-pl:FOILBAG': 'Foliezakken',
    'package-pl:FRAME': 'Frames',
    'package-pl:LOG': 'Blokken',
    'package-pl:OCTABIN': 'Octabins',
    'package-pl:PALLET': 'Pallets',
    'package-pl:PALLETBOX': 'Palletboxen',
    'package-pl:PIECE': 'Stuks',
    'package-pl:REEL': 'Haspels',
    'package-pl:ROLL': 'Rollen',
    'package-pl:SAFETAINER': 'Safetainers',
    'package-pl:SAMPLE': 'Monsters',
    'package-pl:SECURITAINER': 'Securitainers',
    'package-pl:SET': 'Sets',
    'package-pl:SPRAY': 'Spuitbussen',
    'package-pl:TUBE': 'Tubes',
    'package-pl:OTHER': 'Andere',
    'package-pl:CANISTER': 'Busjes',
    'package-pl:BIGBAG': 'Bigbags',
    'package-pl:PAPERBOARD BARREL': 'Kartonnen vatten',
    'package-pl:CARDBOARD': 'Kartons',
    'package-pl:CHEST': 'Kisten',
    'package-pl:PACKAGE': 'Pakketten',

    // Surcharge codes
    'surcharge:ZPR2': 'Nettoprijs',
    'surcharge:ZBA2': 'Nettoprijs',
    'surcharge:ZPRS': 'SiSo Contract',
    'surcharge:ZPF0': 'Aanbetalen',
    'surcharge:ZVEM': 'Verpakkingstoeslag',
    'surcharge:ZVER': 'Verpakkingstoeslag',
    'surcharge:ZVE2': 'Verpakkingstoeslag',
    'surcharge:ZVKM': 'Verpakkingskosten',
    'surcharge:ZM01': 'Markup (laag volume)',
    'surcharge:ZM00': 'Markup (laag volume)',
    'surcharge:ZSOK': 'Diverse kosten',
    'surcharge:ZSO1': 'Diverse kosten',
    'surcharge:ZVP0': 'Speciale verpakking',
    'surcharge:ZMAU': 'Tol/Brandstoftoeslag',
    'surcharge:ZMA2': 'ADR Toeslag',
    'surcharge:ZTP1': 'Vervoerskosten',
    'surcharge:ZTPS': 'Vervoerskosten',
    'surcharge:ZSTD': 'Diverse kosten',
    'surcharge:ZST1': 'Diverse kosten',
    'surcharge:ZABK': 'Vulkosten',
    'surcharge:ZAB1': 'Vulkosten',
    'surcharge:ZLG1': 'Opslagkosten',
    'surcharge:ZLG2': 'Opslagkosten',
    'surcharge:ZFLG': 'Vultoeslag',
    'surcharge:ZGVS': 'ADR-toeslag',
    'surcharge:ZGV1': 'ADR-toeslag',
    'surcharge:Z110': 'Regate Abs.',
    'surcharge:Z111': 'Regate Abs.',
    'surcharge:Z112': 'Regate Abs.',
    'surcharge:Z15P': 'Regate Abs.',
    'surcharge:ZRAB': 'Korting %',
    'surcharge:ZRB1': 'Regate Abs.',
    'surcharge:ZKA2': 'Klant/Artikel',
    'surcharge:ZCVH': 'Korting Abs.',
    'surcharge:ZCVM': 'Klant/Artikel',
    'surcharge:ZUWP': 'Milieu toeslag',
    'surcharge:ZSKT': 'Korting contante betaling',
    'surcharge:ZPRB': 'Commissiebasis',
    'surcharge:ZPRV': 'Commissie',
    'surcharge:ZVPR': 'Kosten',
    'surcharge:ZSTA': 'Nettoprijs',
    'surcharge:ZSTM': 'Nettoprijs',
    'surcharge:ZB10': 'Mat/groepskorting',
    'surcharge:ZB11': 'Materiaal korting',
    'surcharge:ZB12': 'Klant korting',
    'surcharge:ZB14': 'Materiaal korting',
    'surcharge:ZB15': 'Materiaal korting',
    'surcharge:ZSUB': 'Subsidie',
    'surcharge:ZSUP': 'Subsidie ​​in%',
    'surcharge:ZDCL': 'Dowclene',
    'surcharge:ZWEG': 'Klant/Materiaal',
    'surcharge:ZTUK': 'Transport/Uml kosten',
    'surcharge:ZTRA': 'Transportkosten',
    'surcharge:ZF00': 'Kosten Transport/Uml',
    'surcharge:ZLFR': 'Levertijd <24 uur',
    'surcharge:ZVPP': 'Kosten',
    'surcharge:ZVPL': 'Verp.Kost. Lieferpl.',
    'surcharge:ZVP1': 'Kosten',
    'surcharge:ZVKD': 'cumulatie conditie',
    'surcharge:ZVK1': 'Kosten',
    'surcharge:ZUKE': 'Klant/Materiaal',
    'surcharge:ZUKM': 'Mengen',
    'surcharge:ZUKA': 'Bottelen',

    'surcharge:ZSLK': 'Sonder-/Dienstleistg',
    'surcharge:ZALG': 'Sonder-/Dienstleistg',
    'surcharge:ZLKW': 'Laden van vrachtwagens',
    'surcharge:ZLA1': 'Klant/Materiaal',
    'surcharge:ZLA2': 'Klant/Materiaal',
    'surcharge:ZBKO': 'Klant/Materiaal',
    'surcharge:ZVKO': 'Klant/Materiaal',
    'surcharge:ZDIK': 'Wegwerpkosten',
    'surcharge:ZDK1': 'Orderverwerking',
    'surcharge:ZDK2': 'Wegwerpkosten',

    'surcharge:ZSLO': 'Sonst.Logistikkosten',
    'surcharge:ZNLK': 'sonst. NL-Kosten',
    'surcharge:ZZKO': 'Zentralkosten',
    'surcharge:ZVBR': 'Accijnzen',
    'surcharge:ZABS': 'Brannt./Energ. VAT',
    'surcharge:MWST': 'VAT',
    'surcharge:SKTO': 'Korting contante betaling',
    'surcharge:ZVOC': 'VOC Charge',
    'surcharge:ZIVU': 'IV Umschlag',
    'surcharge:GRWR': 'Grenzübergangswert',
    'surcharge:ZMW1': 'netto FW',
    'surcharge:ZMW2': 'Umsatzsteuer FW',
    'surcharge:ZMW3': 'Brutto FW',

    // Order History
    'order-history:headline': 'Orders',

    // Quote List
    'quotes:headline': 'Aanbiedingen',

    // Order Detail
    'order-detail:headline': 'Order {orderId}',

    // Order detail - Not found
    'warning:order-not-found:title': 'Order niet gevonden',
    'warning:order-not-found:explanation': 'De gevraagde order kon niet worden gevonden.',
    'warning:order-not-found:link-text': 'Terug naar orders',

    // Order history - Reorder
    'order-detail:reorder': 'Bestel alles opnieuw',
    'reorder:success-toast': 'De artikelen zijn succesvol aan uw winkelmandje toegevoegd.',
    'reorder:error-toast': 'De artikelen kunnen niet aan uw winkelmandje worden toegevoegd.',
    'reorder:warning-toast': 'Een deel van de artikelen kunnen niet aan uw winkelmandje worden toegevoegd.',

    // Generic error
    'warning:generic-error:title': 'Onze excuses!',
    'warning:generic-error:explanation': 'Uw Brenntag Connect is momenteel niet in gebruik.',
    'warning:generic-error:contact': 'Maakt u zich geen zorgen en neem contact op met uw account manager voor hulp.',

    // Invalid customer
    'warning:invalid-customer-id:title': 'Onze excuses',
    'warning:invalid-customer-id:explanation': 'Uw Brenntag Connect account is op dit moment niet operationeel.',
    'warning:invalid-customer-id:contact': 'Geen zorgen, neem voor hulp contact op met uw accountmanager.',

    // Login failed
    'warning:login-failed:title': 'Er is iets foutgegaan',
    'warning:login-failed:explanation': 'Aanmelden mislukt, controleer uw gegevens.',
    'warning:login-failed:button-text': 'Probeer het opnieuw',
    'warning:too-many-results:title': 'Help ons alstublieft om uw bestelling(en) te vinden',
    'warning:too-many-results:explanation':
        'We hebben meer informatie nodig om uw bestelling(en) te vinden - vul meer letters van de productnaam in.',
    'warning:customer-migrated-bep:title': 'Nieuwe Brenntag Connect',
    'warning:customer-migrated-bep:explanation':
        'We hebben nu een nieuwe versie van Brenntag Connect.<br/>Gelieve hier in te loggen: <a href="https://emea.brenntag.com/{region}">https://emea.brenntag.com/{region}/</a>',

    // Session expired
    'session-expired:header': 'Sessie verlopen',
    'session-expired:text': 'Sessie verlopen. Log alstublieft nogmaals in.',
    'session-expired:login-button': 'Log in',

    // Not found
    'not-found:title': 'Pagina niet gevonden!',
    'not-found:message': 'De pagina die u zoekt kan niet worden gevonden.',
    'not-found:orders': 'Geen resultaten gevonden.',
    'not-found:customers': 'Geen klanten gevonden.',
    'not-found:users': 'Geen gebruikers gevonden.',
    'not-found:link-text': 'Ga terug naar homepage',
    'not-found:invoices': 'Geen facturen gevonden.',
    'not-found:requests': 'Geen aanvragen gevonden.',
    'not-found:select': 'Geen overeenkomende resultaten.',

    // Maintenance
    'maintenance:title': 'Brenntag Connect is niet beschikbaar',
    'maintenance:message':
        'Brenntag Connect is tijdelijk niet beschikbaar vanwege onderhoudswerkzaamheden. Onze excuses voor het ongemak.',

    // Document not found
    'document-not-found:title': 'Document kon niet gevonden worden!',
    'document-not-found:message':
        "Dit document is momenteel niet beschikbaar. Wenst u meer informatie? Neem contact met ons op <a href='mailto:{csrEmail}'>{csrEmail}</a>.",

    // Scale prices
    'scale-prices:from': 'Van',

    // Form validation
    'form-validation:required': 'Geef alstublieft een waarde in.',
    'form-validation:confirm': 'De velden komen niet overeen.',
    'form-validation:email': 'Vul een geldig emailadres in.',
    'form-validation:email-csr':
        'Sorry, u kunt alleen een CSR-gebruiker aanmaken als deze deel uitmaakt van de Brenntag- of DigiB-organisatie. Neem bij vragen contact op met de tweedelijnsondersteuning.',
    'form-validation:email-in-use': 'Emailadres is al in gebruik',
    'form-validation:password': 'Vul een geldig wachtwoord in.',
    'form-validation:password-repeat': 'Herhaal uw wachtwoord',
    'form-validation:date': 'Geef een geldige datum.',
    'form-validation:date.past': 'Selecteer een datum voor {date}.',
    'form-validation:date.future': 'Selecteer een datum na {date}.',
    'form-validation:date.weekday': 'Selecteer een weekdag.',
    'form-validation:max-length': 'U kunt aanvullen tot {length} tekens.',
    'form-validation:min-length': 'Een minimale lengte van {length} tekens is vereist.',
    'form-validation:min-amount': 'Sorry, u moet ten minste {amount} {type} bestellen.',
    'form-validation:number': 'Geef een geldig nummer.',
    'form-validation:has-uppercase': 'Het wachtwoord moet minstens 1 hoofdletter bevatten.',
    'form-validation:has-lowercase': 'Het wachtwoord moet minstens 1 kleine letter bevatten.',
    'form-validation:has-numeric-character': 'Het wachtwoord moet minimaal 1 numeriek teken bevatten.',
    'form-validation:has-symbol-character': 'Het wachtwoord dient minimaal 1 symbool bevatten.',
    'form-validation:has-word-character': 'Speciale karakters zijn niet toegestaan.',
    'form-validation:min-length-values': '{values} moeten een minimale lengte hebben van {length}.',
    'form-validation:do-not-exist': 'De volgende klant (en) bestaan niet: {values}.',
    'form-validation:max-amount': 'Sorry, U kunt maximaal {amount} {type} bestellen.',
    'form-validation:number-too-big': 'Het aantal is te groot.',
    'form-validation:reference-required': 'Voeg een klantreferentie toe.',

    // Account and preference page
    'account:profile': 'Mijn profiel',
    'account:title': 'Account & voorkeuren',
    'account:first-name': 'Voornaam',
    'account:last-name': 'Achternaam',
    'account:email': 'E-mailadres',
    'account:name': 'Naam',
    'account:phone': 'Telefoonnummer',
    'account:shipping-address': 'Afleveradres',
    'account:billing-address': 'Factuuradres',
    'account:personal-details': 'Persoonlijke gegevens',
    'account:company-details': 'Klant details',
    'account:users': 'Gebruikers',
    'account:company-name': 'Klantnaam',
    'account:preferences': 'Voorkeuren',
    'account:language': 'Standaard taal',
    'account:related:title': 'Gebruikers voor {company}',
    'account:related:intro':
        "De volgende lijst bevat de gebruikers die toegang hebben tot Brenntag Connect binnen uw organisatie. Neem contact op met <a href='mailto:{csrEmail}'>{csrEmail}</a> om gebruikers toe te voegen of te verwijderen.",
    'account:related:no-results':
        "Er zijn geen andere gebruikers die toegang hebben tot Brenntag Connect binnen uw organisatie. Als u gebruikers wilt toevoegen of verwijderen, neemt u contact op met <a href='mailto:{csrEmail}'>{csrEmail}</a>.",

    // Request information Modal
    'request-information-modal:add-to-cart': 'Voeg toe aan winkelmand',
    'request-information-modal:edit-quantity-cart': 'Wijzig hoeveelheid',
    'request-information-modal:add-to-cart.instruction': 'Voer de hoeveelheid in dat u wilt bestellen.',
    'request-information-modal:toasts-product-added': 'Dit product is toegevoegd aan uw winkelmand.',
    'request-information-modal:toasts-product-adjusted': 'De hoeveelheid is aangepast in uw winkelmand.',
    'request-information-modal:remove-from-cart': 'Product verwijderen',
    'request-information-modal:remove-from-cart.instruction':
        'Weet u zeker dat u {productName} uit uw winkelmand wilt verwijderen?',
    'request-information-modal:toasts-product-removed': 'Dit product is verwijderd uit uw winkelmand.',

    'request-information-modal:request-information': 'Informatie aanvragen',
    'request-information-modal:request-quote': 'Bestellen',
    'request-information-modal:request-quote-confirm': 'Bestellen',
    'request-information-modal:step': 'Stap',
    'request-information-modal:step.of': 'van',
    'request-information-modal:text': 'Vul dit formulier in om extra informatie omtrent dit artikel aan te vragen.',
    'request-information-modal:text.quote': 'Vul dit formulier in om opnieuw te bestellen',
    'request-information-modal:success-toast':
        'Uw verzoek is succesvol verzonden, het Brenntag Connect team zal binnenkort contact met u opnemen.',
    'request-information-modal:send-request': 'Verzend aanvraag',
    'request-information-modal:needs': 'Wat wilt u aanvragen?',
    'request-information-modal:document-types': 'Document types',
    'request-information-modal:application': 'Toepassing/Applicatie',
    'request-information-modal:amount': 'Hoeveelheid',
    'request-information-modal:sample-quantity': 'Sample hoeveelheid',
    'request-information-modal:annual-quantity': 'Jaarlijkse hoeveelheid',
    'request-information-modal:call-off-quantity': 'Hoeveelheid / levering',
    'request-information-modal:requested-delivery-date': 'Gevraagde leverdatum',
    'request-information-modal:requested-pickup-date': 'Gevraagde ophaaldatum',
    'request-information-modal:order-quantity': 'Order hoeveelheid',
    'request-information-modal:comment': 'Opmerkingen',
    'request-information-modal:step2': 'Ga naar stap 2',
    'request-information-modal:requested-information': 'Gevraagde informatie',

    'request-information-modal:document-types-other-placeholder': 'Documentnaam',
    'request-information-modal:select-an-application': 'Selecteer een toepassing/applicatie',
    'request-information-modal:me': 'Mezelf',
    'request-information-modal:someone-else': 'Iemand anders',
    'request-information-modal:back-to-step-1': 'Terug naar stap 1',
    'request-information-modal:back-to-step-2': 'Terug naar stap 2',
    'request-information-modal:my-address-book': 'Afleveradres',
    'request-information-modal:new-address': 'Ander afleveradres',
    'request-information-modal:document-types.msds': 'Material Safety Data Sheet (MSDS)',
    'request-information-modal:document-types.tds': 'Technical Data Sheet (TDS)',
    'request-information-modal:document-types.specification': 'Specificatie',
    'request-information-modal:document-types.coa': 'Analysecertificaat (COA)',
    'request-information-modal:document-types.other': 'Overige documenten',
    'request-information-modal:requirement.price': 'Prijs',
    'request-information-modal:requirement.document': 'Documenten',
    'request-information-modal:requirement.sample': 'Staal',
    'request-information-modal:requirement.quote': 'Offer',
    'request-information-modal:order': 'Bestellen',
    'request-information-modal:reorder': 'Opnieuw bestellen',
    'request-information-modal:reorder:disabled-unavailableMaterial':
        'Dit artikel is aangepast. Contacteer uw Brenntag Connect team.',
    'request-information-modal:reorder:disabled-unavailableForCustomer':
        'Product niet beschikbaar; voorwaarden zijn van toepassing',
    'request-information-modal:reorder:disabled-unavailable':
        'Dit is een monster. Neem contact op met het Brenntag Connect team.',
    'request-information-modal:reorder:select-delivery-date': 'Gelieve een leverdatum en leveringsadres te selecteren.',
    'request-information-modal:reorder:no-prices-available': 'Prijs wordt bevestigd',
    'request-information-modal:preview-and-submit': 'Voorbeeld en verzenden',
    'request-information-modal:confirmation':
        'Bedankt voor uw order. Het Brenntag Connect team zal uw bestelling zo snel mogelijk bevestigen. Nadat u uw orderbevestiging heeft ontvangen, kunt u uw orderstatus volgen in het Brenntag Connect Orders overzicht.',
    'request-information-modal:available-prices': 'Beschikbare prijzen',
    'request-information-modal:reorder:self-pickup': 'Ophalen in een magazijn',
    'request-information-modal:different-shipto-warning':
        'Wanneer een ander adres is geselecteerd, moet de prijs worden bevestigd.',

    'request-information:auto-complete:price':
        'Bedankt voor uw prijsaanvraag. Het Brenntag Connect team komt spoedig bij u terug.',
    'request-information:auto-complete:sample':
        'Bedankt voor uw monsteraanvraag. Het Brenntag Connect team komt spoedig bij u terug.',
    'request-information:auto-complete:quote':
        'Uw bestelling wordt verzonden naar Brenntag en zal zo snel mogelijk worden verwerkt. Wanneer de bestelling is verwerkt, verschijnt deze in het besteloverzicht in Brenntag Connect.',
    'request-information:auto-complete:cart':
        'Uw bestelling is verzonden naar Brenntag en zal zo snel mogelijk worden verwerkt. Zodra de bestelling is verwerkt, verschijnt deze in het besteloverzicht in Brenntag Connect.',

    // Applications list for BNL and DE

    'request-information:application:ai-automotive': 'Automotive',
    'request-information:application:ai-construction-flooring': 'Constructie vloeren',
    'request-information:application:ai-coatings-architect-coatings': 'Coatings Architect. coatings',
    'request-information:application:ai-construction-building-chemicals': 'Chemicaiën voor de bouwindustrie',
    'request-information:application:ai-construction-concrete-based-prod-': 'Construction Concrete based prod.',
    'request-information:application:ai-decorative-paints': 'Decoratieve verven',
    'request-information:application:ai-dental': 'Mondverzorging',
    'request-information:application:ai-coatings-sealants': 'Coatings afdichtmiddelen',
    'request-information:application:ai-printing-publishing': 'Afdrukken en publiceren',
    'request-information:application:ai-electronic': 'Elektronisch',
    'request-information:application:ai-epoxy-systems': 'Epoxy systemen',
    'request-information:application:ai-coatings-other': 'Overige coatings',
    'request-information:application:ai-filter-and-environmental-technology': 'Filter en milieutechnologie',
    'request-information:application:ai-glass': 'Glas',
    'request-information:application:ai-rubber-seals-gaskets': 'Rubberen afdichtingen en pakkingen',
    'request-information:application:ai-rubber-wires-cables': 'Rubberen draden en kabels',
    'request-information:application:ai-rubber-latex-goods': 'Rubberen latex artikelen',
    'request-information:application:ai-rubber-medical-parts': 'Rubberen medische onderdelen',
    'request-information:application:ai-rubber-tyre': 'Rubberen banden',
    'request-information:application:ai-rubber-tubes-hoses': 'Rubberen buizen & slangen',
    'request-information:application:ai-rubber-other': 'Rubber overige',
    'request-information:application:ai-rubber-compounds': 'Rubberen verbindingen',
    'request-information:application:ai-homecare': 'Thuiszorg',
    'request-information:application:ai-coatings-wood-coatings': 'Coatings Hout coatings',
    'request-information:application:ai-coatings-industrial-coatings': 'Coatings industriële coatings',
    'request-information:application:ai-industrial-cleaning': 'Industriële reiniging',
    'request-information:application:ai-ceramics': 'Keramiek',
    'request-information:application:ai-coatings-adhesives': 'Coatings lijmen',
    'request-information:application:ai-coatings-raw-materials-other': 'Coatings Grondstoffen overige',
    'request-information:application:ai-agro': 'Agro',
    'request-information:application:ai-aerospace': 'Ruimtevaart',
    'request-information:application:ai-metal-surface-treatments': 'Metal en oppervlaktebehandeling',
    'request-information:application:ai-oil-upstream-fracs-drill': 'Olie Upstream boortoepassingen',
    'request-information:application:ai-oil-midstream-n-spec': 'Olie Midstream N-spec',
    'request-information:application:ai-oil-midstream-gasplants': 'Olie Midstream voor productie van gas',
    'request-information:application:ai-oil-midstream-compounder': 'OlieMidstream compounder',
    'request-information:application:ai-oil-downstream-refinerie': 'Olie downstream-raffinaderijen',
    'request-information:application:ai-oil-oil-gas-other': 'Olie en gas overige',
    'request-information:application:ai-paper-manufacturers': 'Papierfabrikanten',
    'request-information:application:ai-pulp-paper-others': 'Pulp, papier en overige',
    'request-information:application:ai-polymers-compounders': 'Polymeren Compounders',
    'request-information:application:ai-polymers-extrusion': 'Polymeren Extrusie',
    'request-information:application:ai-polymers-extrusion-foil': 'Polymeren Extrusie en folie',
    'request-information:application:ai-polymers-pu-elastomers': 'Polymeren PU Elastomeren',
    'request-information:application:ai-polymers-pvc': 'Polymers PVC',
    'request-information:application:ai-polymers-raw-mat-producers': 'Polymeren Grondstoffen producenten',
    'request-information:application:ai-polymers-other': 'Polymeren Overig',
    'request-information:application:ai-polymers-elastomers-other': 'Polymeren Elastomers overige',
    'request-information:application:ai-polymers-injection-moulding': 'Polymeren Spuitgieten',
    'request-information:application:ai-chemical-processing': 'Chemische processing',
    'request-information:application:ai-coatings-powder-coatings': 'Coatings Poedercoatings',
    'request-information:application:ai-cleaning-vehicles': 'Reinigen van voertuigen',
    'request-information:application:ai-cleaning-floorcare': 'Reinigen floorcare',
    'request-information:application:ai-cleaning-food-area': 'Reinigen food area',
    'request-information:application:ai-cleaning-other': 'Cleaning overige',
    'request-information:application:ai-dry-clean-laundry': 'Stomerij & wasgoed',
    'request-information:application:ai-lubricants': 'Lubricants',
    'request-information:application:ai-lub-fuel-blends': 'Lub. & Brandstofmengsels',
    'request-information:application:ai-construction-other': 'Bouw Overig',
    'request-information:application:ai-chemical-synthesis': 'Chemische Synthese',
    'request-information:application:ai-textile-leather': 'Textiel & leer',
    'request-information:application:ai-coatings-inks': 'Coatings Inkt',
    'request-information:application:ai-packaging-converting': 'Packaging & Converting',
    'request-information:application:ai-water-cond-serv-cy-waste-water': 'Water cond. serv. Cy/afvalwater',
    'request-information:application:ai-water-mun-waste-water': 'Water Mun. afvalwater',
    'request-information:application:ai-water-mun-swimming-pool': 'Water Mun. zwembad',
    'request-information:application:ai-water-mun-potable-water': 'Water Mun. drinkbaar water',
    'request-information:application:ai-water-teatment-other': 'Water treatment overig',
    'request-information:application:ai-waterconditioning': 'Waterconditioning',
    'request-information:application:ai-tissue-manufacturers': 'Tissue fabrikanten',
    'request-information:application:ai-construction-cement-products': 'Construction cement artikelen',
    'request-information:application:animal-nutrition:air-treatment': 'Animal Nutrition - Luchtbehandeling',
    'request-information:application:animal-nutrition:enrichment-(minerals-vitamins-amino-acids)':
        'Animal Nutrition - Enrichment (minerals, vitamins, amino acids…)',
    'request-information:application:animal-nutrition:antioxidants': 'Animal Nutrition - Antioxidanten',
    'request-information:application:animal-nutrition:flavours': 'Animal Nutrition - Fmaken',
    'request-information:application:animal-nutrition:binder-flow-additives-coagulation-agents':
        'Animal Nutrition - Binder, vloeiadditieven, coagulatiemiddelen',
    'request-information:application:animal-nutrition:products-for-biogas-systems':
        'Animal Nutrition - artikelen voor biogas systemen',
    'request-information:application:animal-nutrition:biocides-and-hygiene-products':
        'Animal Nutrition - biociden en hygiëne-artikelen',
    'request-information:application:animal-nutrition:emulsifiers-stabilizers-thickener':
        'Animal Nutrition - emulgatoren, stabilisatoren, verdikkingsmiddel',
    'request-information:application:animal-nutrition:energy-suppliers': 'Animal Nutrition - Energieleveranciers',
    'request-information:application:animal-nutrition:colourings': 'Animal Nutrition - Kleurstoffen',
    'request-information:application:animal-nutrition:coccidiostatics-and-histomonostats':
        'Animal Nutrition - coccidiostatica en histomonostatica',
    'request-information:application:animal-nutrition:preservatives': 'Animal Nutrition - conserveringsmiddelen',
    'request-information:application:animal-nutrition:performance-booster': 'Animal Nutrition - Prestaties Booster',
    'request-information:application:animal-nutrition:radionuclide-binder': 'Animal Nutrition - Radionuclide binder',
    'request-information:application:animal-nutrition:acidity-regulators': 'Animal Nutrition - Zuurteregelaars',
    'request-information:application:animal-nutrition:silage-additives': 'Animal Nutrition - kuilvoer',
    'request-information:application:animal-nutrition:technical-adjuvants': 'Animal Nutrition - technische hulpstoffen',
    'request-information:application:animal-nutrition:release-agents': 'Animal Nutrition - Lossingsmiddelen',
    'request-information:application:animal-nutrition:water-treatment': 'Animal Nutrition - Water Treatment',
    'request-information:application:cosmetics:deodorant': 'Cosmetics - Deodorant',
    'request-information:application:cosmetics:hair-care': 'Cosmetics - Haarverzorging',
    'request-information:application:cosmetics:hair-styling': 'Cosmetics - Hair styling',
    'request-information:application:cosmetics:make-up': 'Cosmetica - Make-up',
    'request-information:application:cosmetics:oral-hygiene': 'Cosmetics - Mondhygiëne',
    'request-information:application:cosmetics:perfume': 'Cosmetics - Perfume',
    'request-information:application:cosmetics:skin-care': 'Cosmetica - Huidverzorging',
    'request-information:application:cosmetics:skin-cleansing': 'Cosmetics - Skin cleansing',
    'request-information:application:cosmetics:sun': 'Cosmetics - Zon',
    'request-information:application:food:acidifiers-and-acidity-regulators': 'Food - Aanzurende en zuurteregelaars',
    'request-information:application:food:antioxidants': 'Food - Antioxidanten',
    'request-information:application:food:carriers-and-bulking-agents': 'Food - Vervoerders en vulstoffen',
    'request-information:application:food:coating-and-release-agents': 'Food - Coating en lossingsmiddelen',
    'request-information:application:food:colourings': 'Food - Kleurstoffen',
    'request-information:application:food:emulsifier': 'Food - emulgator',
    'request-information:application:food:flavours': 'Food - Smaken',
    'request-information:application:food:foaming-agents': 'Food - Schuimmiddelen',
    'request-information:application:food:humectants': 'Food - Bevochtigingsmiddelen',
    'request-information:application:food:nutrition-enrichment': 'Eten - Voeding/Enrichment',
    'request-information:application:food:raising-and-flour-tratment-agents': 'Food - Heffen en meel tratment agenten',
    'request-information:application:food:rreservatives-and-stabilizers': 'Food - Rreservatives en stabilisatoren',
    'request-information:application:food:smelting-agent': 'Food - Smelt middel',
    'request-information:application:food:sugars-and-sweeteners': 'Food - Suikers en zoetstoffen',
    'request-information:application:food:technical-adjuvants': 'Food - Technische hulpstoffen',
    'request-information:application:food:thickener-gelling-and-firming-agent':
        'Food - Verdikkingsmiddel, geleermiddel en verstevigende middel',
    'request-information:application:medicinal-product:capsule': 'Geneesmiddel capsule',
    'request-information:application:medicinal-product:cream-gel-salve': 'Geneesmiddel crème, gel, zalf',
    'request-information:application:medicinal-product:drops-(oral)': 'Medicinal Product drops (oral)',
    'request-information:application:medicinal-product:eye-drops': 'Geneesmiddel oogdruppels',
    'request-information:application:medicinal-product:parenteral': 'Geneesmiddel Parenterale',
    'request-information:application:medicinal-product:sachet': 'Geneesmiddel sachet',
    'request-information:application:medicinal-product:spray-solution-(eyes-nose)':
        'Medicinal Product spray/solution (eyes, nose)',
    'request-information:application:medicinal-product:syrup-(oral)': 'Medicinal Product syrup (oral)',
    'request-information:application:medicinal-product:tablet': 'Geneesmiddel tablet',
    'request-information:application:mp-ns-ph:others': 'MP/NS/PH - Anderen',
    'request-information:application:nutritional-supplement:syrup-drops-solution':
        'Voedingssupplement siroop, druppels, oplossing',
    'request-information:application:nutritional-supplement:tablet-capsule-sachet':
        'Voedingssupplement tablet, capsule, sachet',
    'request-information:application:pharma:api-synthesis': 'Pharma - API synthese',
    'request-information:application:pharma:biotechnology': 'Pharma - Biotechnologie',
    'request-information:application:pharma:desinfection': 'Pharma - Desinfectie',
    'request-information:application:other': 'Overig',

    // Applications list for CEE

    'request-information:application:adblue:oem-&-truck-dealers': 'AdBlue - OEM & Truck dealers',
    'request-information:application:adblue:distribution': 'AdBlue - Distribution',
    'request-information:application:adblue:endusers,-carriers-&-fleetowners':
        'AdBlue - Endusers, Carriers & Fleetowners',
    'request-information:application:adblue:public-filling-stations-(codo/dodo)':
        'AdBlue - Public Filling Stations (CODO/DODO)',
    'request-information:application:adblue:retail-&-public-filling-stations--(codo)':
        'AdBlue - Retail & Public Filling Stations  (CODO)',
    'request-information:application:adblue:others': 'AdBlue - Others',
    'request-information:application:agro:desinfection': 'Agro - Desinfection',
    'request-information:application:agro:herbicides-&-pesticides': 'Agro - Herbicides & Pesticides',
    'request-information:application:agro:mixed-production': 'Agro - Mixed Production',
    'request-information:application:agro:fertilizers': 'Agro - Fertilizers',
    'request-information:application:agro:horticulture': 'Agro - Horticulture',
    'request-information:application:agro:farmers': 'Agro - Farmers',
    'request-information:application:agro:orchards': 'Agro - Orchards',
    'request-information:application:agro:others': 'Agro - Others',
    'request-information:application:cement-mills:': 'Cement Mills',
    'request-information:application:cleaning:cleaning-&-cleansing': 'Cleaning - Cleaning & Cleansing',
    'request-information:application:cleaning:homecare': 'Cleaning - Homecare',
    'request-information:application:cleaning:distributors': 'Cleaning - Distributors',
    'request-information:application:cleaning:auto-care-cleaning': 'Cleaning - Auto Care Cleaning',
    'request-information:application:cleaning:industrial-&-institutional-cleaning':
        'Cleaning - Industrial & Institutional Cleaning',
    'request-information:application:cleaning:others': 'Cleaning - Others',
    'request-information:application:heat-engineering:': 'Heat Engineering',
    'request-information:application:chemical-synthesis:': 'Chemical Synthesis',
    'request-information:application:distributors:others': 'Distributors - Others',
    'request-information:application:electronics:medical-equipment-&-tools': 'Electronics - Medical Equipment & Tools',
    'request-information:application:electronics:automotive-electronics': 'Electronics - Automotive Electronics',
    'request-information:application:electronics:lighting-equipment': 'Electronics - Lighting Equipment',
    'request-information:application:electronics:brown-goods': 'Electronics - Brown Goods',
    'request-information:application:electronics:others': 'Electronics - Others',
    'request-information:application:power-industry:': 'Power Industry',
    'request-information:application:renewable-energy:biodiesel': 'Renewable Energy - Biodiesel',
    'request-information:application:renewable-energy:biogas': 'Renewable Energy - Biogas',
    'request-information:application:renewable-energy:solar': 'Renewable Energy - Solar',
    'request-information:application:renewable-energy:others': 'Renewable Energy - Others',
    'request-information:application:coatings-&-construction:construction:building-chemicals':
        'Coatings & Construction - Construction - Building Chemicals',
    'request-information:application:coatings-&-construction:construction:fireproof-materials':
        'Coatings & Construction - Construction - Fireproof Materials',
    'request-information:application:coatings-&-construction:construction:gipsum-cardboard':
        'Coatings & Construction - Construction - Gipsum Cardboard',
    'request-information:application:coatings-&-construction:construction:plywood-&-particle-boards':
        'Coatings & Construction - Construction - Plywood & Particle Boards',
    'request-information:application:coatings-&-construction:construction:floorings':
        'Coatings & Construction - Construction - Floorings',
    'request-information:application:coatings-&-construction:construction:concrete-based-products':
        'Coatings & Construction - Construction - Concrete-based Products',
    'request-information:application:coatings-&-construction:construction:cement-based-products':
        'Coatings & Construction - Construction - Cement-based Products',
    'request-information:application:coatings-&-construction:construction:plasters':
        'Coatings & Construction - Construction - Plasters',
    'request-information:application:coatings-&-construction:construction:mineral-wool-':
        'Coatings & Construction - Construction - Mineral Wool ',
    'request-information:application:coatings-&-construction:construction:others':
        'Coatings & Construction - Construction - Others',
    'request-information:application:coatings-&-construction:distributors': 'Coatings & Construction - Distributors',
    'request-information:application:coatings-&-construction:elastomers:pu-elastomers':
        'Coatings & Construction - Elastomers - PU Elastomers',
    'request-information:application:coatings-&-construction:elastomers:others':
        'Coatings & Construction - Elastomers - Others',
    'request-information:application:coatings-&-construction:coatings:architectural-coatings':
        'Coatings & Construction - Coatings - Architectural Coatings',
    'request-information:application:coatings-&-construction:coatings:wood-coatings':
        'Coatings & Construction - Coatings - Wood Coatings',
    'request-information:application:coatings-&-construction:coatings:powder-coatings':
        'Coatings & Construction - Coatings - Powder Coatings',
    'request-information:application:coatings-&-construction:coatings:industrial-coatings':
        'Coatings & Construction - Coatings - Industrial Coatings',
    'request-information:application:coatings-&-construction:coatings:primers':
        'Coatings & Construction - Coatings - Primers',
    'request-information:application:coatings-&-construction:coatings:automotive-coatings':
        'Coatings & Construction - Coatings - Automotive Coatings',
    'request-information:application:coatings-&-construction:coatings:thinners':
        'Coatings & Construction - Coatings - Thinners',
    'request-information:application:coatings-&-construction:coatings:others':
        'Coatings & Construction - Coatings - Others',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:adhesives':
        'Coatings & Construction - Adhesives & Sealants - Adhesives',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:hot-melts':
        'Coatings & Construction - Adhesives & Sealants - Hot Melts',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:sealants':
        'Coatings & Construction - Adhesives & Sealants - Sealants',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:others':
        'Coatings & Construction - Adhesives & Sealants - Others',
    'request-information:application:coatings-&-construction:composites': 'Coatings & Construction - Composites',
    'request-information:application:coatings-&-construction:producers-of-c&c-raw-materials':
        'Coatings & Construction - Producers of C&C Raw Materials',
    'request-information:application:coatings-&-construction:inks': 'Coatings & Construction - Inks',
    'request-information:application:coatings-&-construction:others': 'Coatings & Construction - Others',
    'request-information:application:mining:natural-building-materials-&-stones':
        'Mining - Natural Building Materials & Stones',
    'request-information:application:mining:metal-ore': 'Mining - Metal Ore',
    'request-information:application:mining:coal': 'Mining - Coal',
    'request-information:application:mining:others': 'Mining - Others',
    'request-information:application:steelworks:': 'Steelworks',
    'request-information:application:municipalities-&-institutions:schools-&-universities':
        'Municipalities & Institutions - Schools & Universities',
    'request-information:application:municipalities-&-institutions:hospitals':
        'Municipalities & Institutions - Hospitals',
    'request-information:application:municipalities-&-institutions:others': 'Municipalities & Institutions - Others',
    'request-information:application:coke-engineering:': 'Coke Engineering',
    'request-information:application:fotochemical-&-film-materials:': 'Fotochemical & Film Materials',
    'request-information:application:explosives-&-pyrotechnics:': 'Explosives & Pyrotechnics',
    'request-information:application:environmental-protection:consulting-&-services':
        'Environmental Protection - Consulting & Services',
    'request-information:application:environmental-protection:soil-remediation':
        'Environmental Protection - Soil Remediation',
    'request-information:application:environmental-protection:incineration-plants':
        'Environmental Protection - Incineration Plants',
    'request-information:application:environmental-protection:waste-dumps': 'Environmental Protection - Waste Dumps',
    'request-information:application:reagents:': 'Reagents',
    'request-information:application:printing-&-publishing:narrow-web': 'Printing & Publishing - Narrow Web',
    'request-information:application:printing-&-publishing:packaging-printing':
        'Printing & Publishing - Packaging Printing',
    'request-information:application:printing-&-publishing:distributors': 'Printing & Publishing - Distributors',
    'request-information:application:printing-&-publishing:printing-inks': 'Printing & Publishing - Printing Inks',
    'request-information:application:printing-&-publishing:flexography': 'Printing & Publishing - Flexography',
    'request-information:application:printing-&-publishing:bookbinding': 'Printing & Publishing - Bookbinding',
    'request-information:application:printing-&-publishing:sheet-fed-offset':
        'Printing & Publishing - Sheet Fed Offset',
    'request-information:application:printing-&-publishing:cold-set-offset': 'Printing & Publishing - Cold Set Offset',
    'request-information:application:printing-&-publishing:heat-set-offset': 'Printing & Publishing - Heat Set Offset',
    'request-information:application:printing-&-publishing:serigraphy': 'Printing & Publishing - Serigraphy',
    'request-information:application:printing-&-publishing:rotogravure': 'Printing & Publishing - Rotogravure',
    'request-information:application:printing-&-publishing:others': 'Printing & Publishing - Others',
    'request-information:application:loundries:': 'Loundries',
    'request-information:application:automotive-oems:': 'Automotive OEMs',
    'request-information:application:automotive-oems---service-companies:': 'Automotive OEMs - service companies',
    'request-information:application:machinery-production:measuring-instruments':
        'Machinery Production - Measuring Instruments',
    'request-information:application:machinery-production:mechanized-household-equipment':
        'Machinery Production - Mechanized Household Equipment',
    'request-information:application:machinery-production:others': 'Machinery Production - Others',
    'request-information:application:ceramic-industry:household-ceramic': 'Ceramic Industry - Household Ceramic',
    'request-information:application:ceramic-industry:others': 'Ceramic Industry - Others',
    'request-information:application:wood-industry-&-woodwork:furniture': 'Wood Industry & Woodwork - Furniture',
    'request-information:application:wood-industry-&-woodwork:woodwork': 'Wood Industry & Woodwork - Woodwork',
    'request-information:application:wood-industry-&-woodwork:wood-industry':
        'Wood Industry & Woodwork - Wood Industry',
    'request-information:application:wood-industry-&-woodwork:others': 'Wood Industry & Woodwork - Others',
    'request-information:application:pharma:distributors': 'Pharma - Distributors',
    'request-information:application:pharma:human-&-veterinary-medicines': 'Pharma - Human & Veterinary Medicines',
    'request-information:application:pharma:natural-remedies': 'Pharma - Natural Remedies',
    'request-information:application:pharma:pharmaceutical-starting-material-producers':
        'Pharma - Pharmaceutical Starting Material Producers',
    'request-information:application:pharma:others': 'Pharma - Others',
    'request-information:application:rubber:footwear': 'Rubber - Footwear',
    'request-information:application:rubber:distributors': 'Rubber - Distributors',
    'request-information:application:rubber:non-automotive-profiles': 'Rubber - Non Automotive Profiles',
    'request-information:application:rubber:automotive-profiles': 'Rubber - Automotive Profiles',
    'request-information:application:rubber:general-rubber-goods': 'Rubber - General Rubber Goods',
    'request-information:application:rubber:wire-&-cables': 'Rubber - Wire & Cables',
    'request-information:application:rubber:tires:automotive-tires': 'Rubber - Tires - Automotive Tires',
    'request-information:application:rubber:tires:industrial-tires': 'Rubber - Tires - Industrial Tires',
    'request-information:application:rubber:tires:others': 'Rubber - Tires - Others',
    'request-information:application:rubber:non-automotive-hoses': 'Rubber - Non Automotive Hoses',
    'request-information:application:rubber:automotive-hoses': 'Rubber - Automotive Hoses',
    'request-information:application:rubber:automotive-belts': 'Rubber - Automotive Belts',
    'request-information:application:rubber:non-automotive-belts': 'Rubber - Non Automotive Belts',
    'request-information:application:rubber:rubber-rolls-and-wheels': 'Rubber - Rubber Rolls and Wheels',
    'request-information:application:rubber:latex': 'Rubber - Latex',
    'request-information:application:rubber:custom-mixing': 'Rubber - Custom Mixing',
    'request-information:application:rubber:others': 'Rubber - Others',
    'request-information:application:cosmetics:distributors': 'Cosmetics - Distributors',
    'request-information:application:cosmetics:general': 'Cosmetics - General',
    'request-information:application:cosmetics:decorative-products-&-make-up':
        'Cosmetics - Decorative Products & Make-Up',
    'request-information:application:cosmetics:soap-&-candles': 'Cosmetics - Soap & Candles',
    'request-information:application:cosmetics:perfumes-&-fragrance': 'Cosmetics - Perfumes & Fragrance',
    'request-information:application:cosmetics:skin-care-and-sun-cosmetics': 'Cosmetics - Skin Care and Sun Cosmetics',
    'request-information:application:cosmetics:hair-care-and-body-wash': 'Cosmetics - Hair Care and Body Wash',
    'request-information:application:cosmetics:toll-manufacturers-&-contractors':
        'Cosmetics - Toll Manufacturers & Contractors',
    'request-information:application:cosmetics:others': 'Cosmetics - Others',
    'request-information:application:metal:zinc-coating---automotive-components':
        'Metal - Zinc Coating - Automotive Components',
    'request-information:application:metal:zinc-coating---non-automotive-components':
        'Metal - Zinc Coating - Non Automotive Components',
    'request-information:application:metal:distribution': 'Metal - Distribution',
    'request-information:application:metal:electroplating---automotive-components':
        'Metal - Electroplating - Automotive Components',
    'request-information:application:metal:electroplating---non-automotive-components':
        'Metal - Electroplating - Non Automotive Components',
    'request-information:application:metal:automotive-components---others': 'Metal - Automotive Components - Others',
    'request-information:application:metal:non-automotive-components---others':
        'Metal - Non Automotive Components - Others',
    'request-information:application:metal:vanishing---automotive-components':
        'Metal - Vanishing - Automotive Components',
    'request-information:application:metal:vanishing---non-automotive-components':
        'Metal - Vanishing - Non Automotive Components',
    'request-information:application:metal:foundry---automotive-components': 'Metal - Foundry - Automotive Components',
    'request-information:application:metal:foundry---non-automotive-components':
        'Metal - Foundry - Non Automotive Components',
    'request-information:application:pulp-&-paper:tissue-manufacturers': 'Pulp & Paper - Tissue Manufacturers',
    'request-information:application:pulp-&-paper:board-manufacturers': 'Pulp & Paper - Board Manufacturers',
    'request-information:application:pulp-&-paper:packaging-&-converting': 'Pulp & Paper - Packaging & Converting',
    'request-information:application:pulp-&-paper:paper-manufacturers': 'Pulp & Paper - Paper Manufacturers',
    'request-information:application:pulp-&-paper:others': 'Pulp & Paper - Others',
    'request-information:application:spirit-industry:': 'Spirit Industry',
    'request-information:application:food:chocolate-&-confection:confectionery':
        'Food - Chocolate & Confection - Confectionery',
    'request-information:application:food:chocolate-&-confection:chocolate':
        'Food - Chocolate & Confection - Chocolate',
    'request-information:application:food:food-general:sugar-factories': 'Food - Food General - Sugar Factories',
    'request-information:application:food:beverages:breweries,-malting': 'Food - Beverages - Breweries, malting',
    'request-information:application:food:beverages:alcoholic-beverages---others':
        'Food - Beverages - Alcoholic Beverages - Others',
    'request-information:application:food:beverages:non-alcoholic-beverages':
        'Food - Beverages - Non Alcoholic Beverages',
    'request-information:application:food:beverages:instant-drinks': 'Food - Beverages - Instant drinks',
    'request-information:application:food:bakery-&-bread:bars-and-cereals': 'Food - Bakery & Bread - Bars and cereals',
    'request-information:application:food:bakery-&-bread:bakery-mix-blenders':
        'Food - Bakery & Bread - Bakery Mix Blenders',
    'request-information:application:food:bakery-&-bread:baked-goods': 'Food - Bakery & Bread - Baked goods',
    'request-information:application:food:food-general:flavour-producers': 'Food - Food General - Flavour Producers',
    'request-information:application:food:food-general:other-ingredient-producers':
        'Food - Food General - Other Ingredient Producers',
    'request-information:application:food:meat-and-poultry:blends': 'Food - Meat and poultry - Blends',
    'request-information:application:food:meat-and-poultry:production': 'Food - Meat and poultry - Production',
    'request-information:application:food:meat-and-poultry:fish-industry': 'Food - Meat and poultry - Fish Industry',
    'request-information:application:food:meat-and-poultry:slaughterhouses':
        'Food - Meat and poultry - Slaughterhouses',
    'request-information:application:food:dairy-&-ice-cream:ice-cream': 'Food - Dairy & Ice Cream - Ice Cream',
    'request-information:application:food:dairy-&-ice-cream:dairy-ingredients':
        'Food - Dairy & Ice Cream - Dairy Ingredients',
    'request-information:application:food:dairy-&-ice-cream:dairy-consumer-products':
        'Food - Dairy & Ice Cream - Dairy Consumer Products',
    'request-information:application:food:food-general:starch-industry': 'Food - Food General - Starch Industry',
    'request-information:application:food:food-general:food---distributors':
        'Food - Food General - Food - Distributors',
    'request-information:application:food:food-general:blenders---other': 'Food - Food General - Blenders - Other',
    'request-information:application:food:food-general:fats-&-oils': 'Food - Food General - Fats & Oils',
    'request-information:application:food:food-general:tobacco': 'Food - Food General - Tobacco',
    'request-information:application:food:food-general:potato-processing': 'Food - Food General - Potato Processing',
    'request-information:application:food:fruit-&-vegetable-processing:jams,-fruit-preparations':
        'Food - Fruit & Vegetable Processing - Jams, fruit preparations',
    'request-information:application:food:fruit-&-vegetable-processing:fruit-concentrates':
        'Food - Fruit & Vegetable Processing - Fruit concentrates',
    'request-information:application:food:fruit-&-vegetable-processing:others':
        'Food - Fruit & Vegetable Processing - Others',
    'request-information:application:food:function-food,-supplements:food-supplements':
        'Food - Function food, supplements - Food supplements',
    'request-information:application:food:function-food,-supplements:sport-nutrition':
        'Food - Function food, supplements - Sport nutrition',
    'request-information:application:food:function-food,-supplements:baby-food':
        'Food - Function food, supplements - Baby food',
    'request-information:application:food:function-food,-supplements:special-food':
        'Food - Function food, supplements - Special food',
    'request-information:application:food:convenience-food:savoury---others':
        'Food - Convenience Food - Savoury - Others',
    'request-information:application:food:convenience-food:soups,-sauces,-dressings':
        'Food - Convenience Food - Soups, Sauces, Dressings',
    'request-information:application:food:convenience-food:convenience-food':
        'Food - Convenience Food - Convenience Food',
    'request-information:application:food:others': 'Food - Others',
    'request-information:application:glass-industry:household-glass-&-glass-packaging':
        'Glass Industry - Household Glass & Glass Packaging',
    'request-information:application:glass-industry:industrial-&-technical-glass':
        'Glass Industry - Industrial & Technical Glass',
    'request-information:application:glass-industry:automotive-glass': 'Glass Industry - Automotive Glass',
    'request-information:application:glass-industry:others': 'Glass Industry - Others',
    'request-information:application:textile-&-leather:carpets-&-linings': 'Textile & Leather - Carpets & Linings',
    'request-information:application:textile-&-leather:knitted-fabrics': 'Textile & Leather - Knitted Fabrics',
    'request-information:application:textile-&-leather:automotive-leather-components':
        'Textile & Leather - Automotive Leather Components',
    'request-information:application:textile-&-leather:leather-tanning': 'Textile & Leather - Leather Tanning',
    'request-information:application:textile-&-leather:leather-clothing,-footwear,-goods':
        'Textile & Leather - Leather Clothing, Footwear, Goods',
    'request-information:application:textile-&-leather:yarn-&-threads': 'Textile & Leather - Yarn & Threads',
    'request-information:application:textile-&-leather:tights': 'Textile & Leather - Tights',
    'request-information:application:textile-&-leather:natural-textiles': 'Textile & Leather - Natural Textiles',
    'request-information:application:textile-&-leather:synthetic-textiles': 'Textile & Leather - Synthetic Textiles',
    'request-information:application:textile-&-leather:unwoven-fabrics': 'Textile & Leather - Unwoven Fabrics',
    'request-information:application:textile-&-leather:others': 'Textile & Leather - Others',
    'request-information:application:oil-&-gas:downstream---refineries,-pipelines':
        'Oil & Gas - DownStream - Refineries, Pipelines',
    'request-information:application:oil-&-gas:downstream---chemical-plants':
        'Oil & Gas - DownStream - Chemical Plants',
    'request-information:application:oil-&-gas:distribution': 'Oil & Gas - Distribution',
    'request-information:application:oil-&-gas:midstream---specialty-gases': 'Oil & Gas - MidStream - Specialty Gases',
    'request-information:application:oil-&-gas:midstream---compounders': 'Oil & Gas - MidStream - Compounders',
    'request-information:application:oil-&-gas:midstream---n-spec': 'Oil & Gas - MidStream - N-Spec',
    'request-information:application:oil-&-gas:midstream---production': 'Oil & Gas - MidStream - Production',
    'request-information:application:oil-&-gas:midstream---gasplants': 'Oil & Gas - MidStream - Gasplants',
    'request-information:application:oil-&-gas:upstream---drilling': 'Oil & Gas - UpStream - Drilling',
    'request-information:application:oil-&-gas:services': 'Oil & Gas - Services',
    'request-information:application:oil-&-gas:others': 'Oil & Gas - Others',
    'request-information:application:lubricants:asphalts': 'Lubricants - Asphalts',
    'request-information:application:lubricants:bio-diesel---producers': 'Lubricants - Bio Diesel - Producers',
    'request-information:application:lubricants:bio-ethanol---producers': 'Lubricants - Bio Ethanol - Producers',
    'request-information:application:lubricants:distribution': 'Lubricants - Distribution',
    'request-information:application:lubricants:blending-facilities': 'Lubricants - Blending Facilities',
    'request-information:application:lubricants:industrial-lubricants': 'Lubricants - Industrial Lubricants',
    'request-information:application:lubricants:automotive-lubricants': 'Lubricants - Automotive Lubricants',
    'request-information:application:lubricants:release-agents': 'Lubricants - Release Agents',
    'request-information:application:lubricants:others': 'Lubricants - Others',
    'request-information:application:transportation:airports': 'Transportation - Airports',
    'request-information:application:transportation:others': 'Transportation - Others',
    'request-information:application:tourism:': 'Tourism',
    'request-information:application:polymers:distributors': 'Polymers - Distributors',
    'request-information:application:polymers:polyurethanes:automotive-components':
        'Polymers - Polyurethanes - Automotive Components',
    'request-information:application:polymers:polyurethanes:flexible-&-rigid-polyurethanes-':
        'Polymers - Polyurethanes - Flexible & Rigid Polyurethanes ',
    'request-information:application:polymers:polyurethanes:polyurethane-systems':
        'Polymers - Polyurethanes - Polyurethane Systems',
    'request-information:application:polymers:polyurethanes:others': 'Polymers - Polyurethanes - Others',
    'request-information:application:polymers:compounders': 'Polymers - Compounders',
    'request-information:application:polymers:raw-material-producers': 'Polymers - Raw Material Producers',
    'request-information:application:polymers:injection-moulding:non-automotive-components':
        'Polymers - Injection Moulding - Non Automotive Components',
    'request-information:application:polymers:injection-moulding:automotive-components':
        'Polymers - Injection Moulding - Automotive Components',
    'request-information:application:polymers:regranulates': 'Polymers - Regranulates',
    'request-information:application:polymers:extrusion:films': 'Polymers - Extrusion - Films',
    'request-information:application:polymers:extrusion:automotive-components':
        'Polymers - Extrusion - Automotive Components',
    'request-information:application:polymers:extrusion:flooring,-coated-textiles':
        'Polymers - Extrusion - Flooring, Coated Textiles',
    'request-information:application:polymers:extrusion:plates-&-profiles': 'Polymers - Extrusion - Plates & Profiles',
    'request-information:application:polymers:extrusion:pipes': 'Polymers - Extrusion - Pipes',
    'request-information:application:polymers:extrusion:polymers-textiles-&-fibers':
        'Polymers - Extrusion - Polymers Textiles & Fibers',
    'request-information:application:polymers:extrusion:others': 'Polymers - Extrusion - Others',
    'request-information:application:polymers:chemosets': 'Polymers - Chemosets',
    'request-information:application:polymers:thermosets': 'Polymers - Thermosets',
    'request-information:application:polymers:others': 'Polymers - Others',
    'request-information:application:services:': 'Services',
    'request-information:application:water-treatment:swimming-pools': 'Water Treatment - Swimming Pools',
    'request-information:application:water-treatment:municipalities-for-potable-water':
        'Water Treatment - Municipalities for Potable Water',
    'request-information:application:water-treatment:municipalities-for-potable-and-waste-water':
        'Water Treatment - Municipalities for Potable and Waste Water',
    'request-information:application:water-treatment:distributors': 'Water Treatment - Distributors',
    'request-information:application:water-treatment:environmental-&-equipment-companies':
        'Water Treatment - Environmental & Equipment Companies',
    'request-information:application:water-treatment:municipalities-for-waste-water':
        'Water Treatment - Municipalities for Waste Water',
    'request-information:application:water-treatment:water-conditioning-service-companies':
        'Water Treatment - Water Conditioning Service Companies',
    'request-information:application:water-treatment:institutional-service-companies':
        'Water Treatment - Institutional Service Companies',
    'request-information:application:water-treatment:other-water-treatment-service-companies':
        'Water Treatment - Other Water Treatment Service Companies',
    'request-information:application:water-treatment:other-water-treatment-end-users':
        'Water Treatment - Other Water Treatment end users',
    'request-information:application:water-treatment:others': 'Water Treatment - Others',
    'request-information:application:animal-nutrition:distributors': 'Animal Nutrition - Distributors',
    'request-information:application:animal-nutrition:homemixers-&-farmers:cattle-and-sheep':
        'Animal Nutrition - Homemixers & Farmers - Cattle and Sheep',
    'request-information:application:animal-nutrition:homemixers-&-farmers:poultry':
        'Animal Nutrition - Homemixers & Farmers - Poultry',
    'request-information:application:animal-nutrition:homemixers-&-farmers:pigs':
        'Animal Nutrition - Homemixers & Farmers - Pigs',
    'request-information:application:animal-nutrition:homemixers-&-farmers:others':
        'Animal Nutrition - Homemixers & Farmers - Others',
    'request-information:application:animal-nutrition:additive-blenders': 'Animal Nutrition - Additive Blenders',
    'request-information:application:animal-nutrition:mixed-production': 'Animal Nutrition - Mixed Production',
    'request-information:application:animal-nutrition:aquafeed': 'Animal Nutrition - Aquafeed',
    'request-information:application:animal-nutrition:compound-feed': 'Animal Nutrition - Compound Feed',
    'request-information:application:animal-nutrition:premix': 'Animal Nutrition - Premix',
    'request-information:application:animal-nutrition:milk-replacers': 'Animal Nutrition - Milk Replacers',
    'request-information:application:animal-nutrition:integrators': 'Animal Nutrition - Integrators',
    'request-information:application:animal-nutrition:feed-ingredients': 'Animal Nutrition - Feed Ingredients',
    'request-information:application:animal-nutrition:animal-waste-processing':
        'Animal Nutrition - Animal Waste Processing',
    'request-information:application:animal-nutrition:veterinarians': 'Animal Nutrition - Veterinarians',
    'request-information:application:animal-nutrition:petfood': 'Animal Nutrition - Petfood',
    'request-information:application:animal-nutrition:others': 'Animal Nutrition - Others',
    'request-information:application:others:': 'Others',

    // languages
    'language:de': 'Duits',
    'language:en': 'Engels',
    'language:fr': 'Frans',
    'language:nl': 'Nederlands',
    'language:pl': 'Pools',

    // countries
    'country:de': 'Duitsland',
    'country:en': 'Engeland',
    'country:fr': 'Frankrijk',
    'country:nl': 'Nederland',
    'country:be': 'Belgie',
    'country:za': 'Zuid-Afrika',
    'country:ae': 'Verenigde Arabische Emiraten',
    'country:ke': 'Kenia',
    'country:tz': 'Tanzania',
    'country:ug': 'Uganda',
    'country:pl': 'Polen',
    'country:a1': 'Polen',

    // Onboarding
    'onboarding:skip-tour.skip': 'Rondleiding overslaan',
    'onboarding:skip-tour.close': 'Rondleiding overslaan',
    'onboarding:slider-button.previous': 'Vorige',
    'onboarding:slider-button.next': 'Volgende',
    'onboarding:slider-button.start': 'Start',
    'onboarding:slider-button.finish': 'Aan de slag',
    'onboarding:slide-sample.header': 'Brenntag Connect maakt het mogelijk om:',
    'onboarding:slide-sample.title': 'Productinformatie aan te vragen',
    'onboarding:slide-sample.content': 'Vraag prijzen en relevante documentatie direct aan in uw account.',
    'onboarding:slide-order-status.header': 'Brenntag Connect maakt het mogelijk om:',
    'onboarding:slide-order-status.title': 'De order status en vorige orders te bekijken',
    'onboarding:slide-order-status.content':
        'Volg de status van uw recent geplaatste orders en krijg toegang tot uw bestelgeschiedenis van de afgelopen 24 maanden.',
    'onboarding:slide-introduction.title':
        'Welkom bij Brenntag Connect - uw klantenportaal voor Brenntag artikelen en diensten!',
    'onboarding:slide-introduction.content':
        "Er is meer te ontdekken in Brenntag Connect. Klik op 'Start' voor een korte rondleiding!",
    'onboarding:slide-finish.title': 'Wij zijn er om u te helpen!',
    'onboarding:slide-finish.content':
        'Indien u vragen heeft over het gebruik van Brenntag Connect kunt u contact opnemen met ons <a href="mailto:{csrEmail}"> Brenntag Connect support team.</a> U kunt ook de <a href="{faqLink}" target="_blank">FAQ’s</a> raadplegen. U bent van harte welkom!',

    // welome videos titles
    'welcome-video-title:WELCOME': 'Welkom',
    'welcome-video-title:FIRSTTIME': 'Eerste keer inloggen, accounts en voorkeuren',
    'welcome-video-title:ORDERS': 'Bestellingen',
    'welcome-video-title:INVOICES': 'Facturen',
    'welcome-video-title:PRODUCTS': 'Producten',
    'welcome-video-title:REQUESTS': 'Aanvragen',
    'welcome-video-title:FEEDBACK': 'Feedback',
    'welcome-video-title:QUOTES': 'Offerte',

    'welcome-video:headline': 'Wilt u weten hoe u Brenntag Connect gebruikt?',
    'welcome-video:instruction': 'Bekijk onze video met de Brenntag Connect-demo.',

    // Terms of Use
    'terms-of-usage:headline': 'Gebruiksvoorwaarden',
    'terms-of-usage:decline': 'Afwijzen',
    'terms-of-usage:accept': 'Ik accepteer',
    'terms-of-usage:updated':
        'We hebben onze gebruiksvoorwaarden bijgewerkt. Gelieve deze te aanvaarden voordat u doorgaat naar Brenntag Connect. De gebruiksvoorwaarden zijn te allen tijde toegankelijk via een link in de voettekst.',
    'terms-of-usage:content': `## Brenntag Connect - DigiB klanteninterface

### Gebruiksvoorwaarden
DigiB B.V., een besloten vennootschap juridisch gevestigd naar Nederlandse recht (hierna te noemen: "Operator") is een entiteit van Brenntag Group, de wereldmarktleider in chemische distributie, en hanteert de "Brenntag Connect" online-diensten en het informatieplatform (hierna te noemen: "Portal") en biedt geregistreerde gebruikers (natuurlijke personen, gebruikers van hetzelfde bedrijf moeten individueel registreren om toewijzing van een individueel account aan een individuele natuurlijke persoon veilig te stellen) verschillende functionaliteiten (wanneer actief), zoals het (opnieuw) bestellen en opvragen van producten via internet, de bestelgeschiedenis bekijken, bladeren naar nieuwe producten, toegang tot relevante producten en gerelateerde documenten bestellen. Bij het gebruik van de Portal dient de gebruiker te allen tijde de principes zoals in deze gebruiksvoorwaarden uiteengezet alsmede de wettelijke verplichtingen in acht te nemen.

### 1. Omvang van applicatie
1.1 De volgende gebruiksvoorwaarden gelden uitsluitend voor het gebruik van de Portal. De Operator behoudt altijd het recht deze gebruiksvoorwaarden met onmiddellijke ingang in de toekomst te wijzigen.

1.2 Voor alle mogelijke huidige en toekomstige e-commerce transacties via de Portal zullen de algemene verkoop- en leveringsvoorwaarden ("GTC Sales") van de verkopende Brenntag Group entiteit ("Verkoper") zoals voorzien in de Portal gelden. Tegenstrijdige voorwaarden van de gebruikers of hun klanten ten opzichte van deze gebruiksvoorwaarden of de GTC Sales zullen niet van toepassing zijn, tenzij (waar van toepassing) de Verkoper uitdrukkelijk schriftelijk akkoord gaat met hun geldigheid (voor het doel van deze voorwaarden betekent "schriftelijk" ook e-mail en fax).

### 2. Registratie
2.1 Wanneer niet actief uitgenodigd als een bestaande klant, dient iedere gebruiker die geïnteresseerd is in het gebruik van de diensten van de Portal een overeenkomstig registratieverzoek naar de Operator te versturen. De gebruiker zal alle gegevens gevraagd door de Operator doorgeven, en zal vervolgens zorgen dat dergelijke gegevens altijd up-to-date blijven voor de duur van zijn/haar aanvraag en/of registratie. Na volledige verlening van de gevraagde gegevens beslist de Operator geheel naar eigen goeddunken of de gebruiker wordt geregistreerd. De gebruiker wordt schriftelijk geïnformeerd door de Operator over de status van de registratie.

2.2 De Operator is gerechtigd de registratie te weigeren of vervolgens in te trekken zonder opgaaf van redenen. De Operator zal op geen enkele wijze aansprakelijk zijn voor enige vertraging of weigering van de registratie.

### 3. Wachtwoord en verplichtingen van de gebruiker
3.1 Met de bevestiging van het registratieverzoek ontvangt iedere gebruiker een individuele gebruikersnaam in de vorm van zijn geregistreerde e-mailadres en een individueel wachtwoord. Toegang tot de diensten van de Portal is alleen mogelijk met de combinatie van deze twee vertrouwelijke gegevens.

3.2 In geval van verlies van de identificatiemiddelen of gerede verdenking dat een derde ongewenst kennis heeft verkregen omtrent de inlog-identificatie, is de gebruiker verplicht onmiddellijk het wachtwoord te wijzigen en de Operator schriftelijk hiervan in kennis te stellen.

3.3 De gebruiker is verplicht de Operator schriftelijk te informeren omtrent het vertrek van andere medewerkers of derden (bv. consultants) uit het desbetreffende bedrijf, kennis hebbend van de vertrouwelijke informatie zoals vermeld in clausule 3.1, zonder onterechte vertraging maar ten laatste bij beëindiging van de aanstelling of andersoortige medewerking teneinde enig misbruik van de vertrouwelijke informatie van de gebruiker te voorkomen. Ieder opgemerkt daadwerkelijk of dreigend misbruik van de Portal dient onmiddellijk te worden gemeld aan de Operator. In deze gevallen zal de Operator onmiddellijk nieuwe vertrouwelijke gegevens registreren of tenminste een nieuw wachtwoord voor de gebruiker instellen en de gebruiker hieromtrent schriftelijk informeren.

3.4 Totdat melding van dergelijke beëindiging of misbruik is gedaan, zal de gebruiker verantwoordelijk zijn en aansprakelijk worden gehouden voor alle verklaringen en inhoud gepost of enige andere wangedrag op de Portal door een medewerker of derde persoon tot aan beëindiging.

3.5 De gebruiker zal geen inhoud of bijdragen posten die a) op enigerlei wijze de rechten van de Operator of enige derde schendt of in gevaar brengt (in het bijzonder naamrechten, persoonlijke rechten, handelsmerkrechten, copyright, aanvullend copyright enz.) b) illegaal zijn of c) virussen bevatten, apparaten omzeilen, of ongevraagde massa-mailings (spam) behelzen. De gebruiker zal alle bestaande intellectuele eigendoms- en andere eigendomsrechten van derden in acht nemen en mag geen illegale overeenkomsten aangaan of leiding dan wel vereende praktijken tonen die aanleiding zouden kunnen vormen tot inbreuk op anti-trust wetgeving.

3.6 De Operator is gerechtigd geposte, verwerpelijke inhoud te verwijderen en de gebruiker uit te sluiten van verder gebruik van de Portal vooral als immorele, illegale of bedrijfs-beschadigende inhoud is gepost.

3.7 De Operator behoudt zich het recht voor overtreding onder toepasselijke wetgeving aan de autoriteiten van het desbetreffende land waarin de gebruiker zijn handelsvestiging heeft door te geven. Bovendien behoudt de Operator zich het recht om schadevergoeding te eisen en mogelijke andere remedies uit te putten die beschikbaar zijn in geval van overtredingen van de gebruiksvoorwaarden.

### 4. Aanvraag tot bestelling / plaatsen van productbestellingen
4.1 Op basis van de productenlijst en de prijsinformatie op de Portal kan de gebruiker een aanvraag tot bestelling of (her-)bestelling van producten van de Verkoper via de Portal versturen (tezamen: "Bestelling"). Om de procedure van Bestelling volledig te maken, moet de gebruiker het product, de bestelhoeveelheid, de leveringsplaats en de leveringsdatum kiezen. Vervolgens dient de gebruiker een aankoopbestelling (PO; purchase order) referentienummer in te voeren. Door te klikken op de knop "Submit" ("indienen") in het gebied met het winkelmandje ("check-out") is de opdracht in de legale betekenis volledig en bindend voor de gebruiker.

4.2 Na ontvangst van de Bestelling bekijkt de Verkoper de haalbaarheid van de aanvraag tot bestelling, geeft een ontvangstbevestiging aan de gebruiker af, en levert al naar gelang (i) een bestelbevestiging of (ii) een tegenaanbieding, rekening houdend met mogelijke aanpassingen van hoeveelheden en/of leveringsdata. In geval van (i) vereisen alle (her-)bestellingen geplaatst door de gebruiker via de Portal een schriftelijke acceptatie/bevestiging (e-mail volstaat) van de Verkoper, of een impliciete acceptatie/bevestiging door het uitvoeren van de bestelling en in geval van (ii) vereisen alle tegenaanbiedingen een schriftelijke acceptatie/bevestiging (e-mail volstaat) van de gebruiker, of een impliciete acceptatie/bevestiging door betaling teneinde een bindend leveringscontract te vormen.

### 5. Verwijdering van de registratie
Iedere gebruiker mag te allen tijde om verwijdering van zijn/haar registratie verzoeken door een desbetreffend verzoek schriftelijk te versturen naar de Operator. Met de verwijdering van de registratie (respectievelijk melding van succesvolle verwijdering) vervalt het recht van deelname van de gebruiker aan de diensten aangeboden door de Portal onmiddellijk. De Operator is gerechtigd een bestaande registratie zonder opgaaf van redenen te verwijderen als - na redelijke evaluatie door de Operator - de gebruiker onjuiste informatie heeft verstrekt dan wel de gebruiksvoorwaarden of toepasselijke wetgeving overtreedt. Een verwijdering van de registratie wordt schriftelijk gecommuniceerd met de desbetreffende gebruiker door de Operator. In geval van verwijdering van de registratie, verwijdert de Operator onmiddellijk alle persoonlijke gebruiksgegevens. De Operator is niet aansprakelijk voor welke verwijdering van registratieprofielen dan ook.

### 6. Aansprakelijkheid
6.1 De aansprakelijkheid van de Operator met betrekking tot de werking van de Portal zal zijn beperkt tot grove nalatigheid of bewuste opzet door hemzelf, zijn juridische vertegenwoordigers of gedelegeerde agenten. Het vorenstaande is niet van toepassing in het geval van aansprakelijkheid als gevolg van letsel aan lijf, leden of gezondheid, of in het geval van verwijtbaar schenden van een plicht die materieel is jegens het contract, op een wijze die het bereiken van het doel van het contract in gevaar brengt (kardinale verplichting) of productaansprakelijkheid of andere gevallen van verplichte wettelijke aansprakelijkheid. Behalve in het geval van opzet grove nalatigheid en schade resulterend uit letsel aan lijf, leden of gezondheid is aansprakelijkheid beperkt in hoeveelheid tot de typisch te verwachten schade ten tijde van de registratie van de gebruiker, in geen geval EUR 100.000 per geval en jaar overschrijdend, exclusief in ieder geval aansprakelijkheid voor indirecte schade, bijkomende schade, vervolgschade en zekere financiële verliezen alsmede verlies van winst.

6.2 De gebruiker is aansprakelijk voor enige activiteit die wordt uitgevoerd met behulp van zijn gebruikersaccount en/of de geleverde gegevens die het doel van de Portal overstijgen. Gebruiker zal alle commercieel redelijke stappen ondernemen om a) onwettige toegang tot zijn gebruikersaccount en/of geleverde gegevens te voorkomen en b) het voldoen aan alle contractuele verplichtingen jegens de Operator te verzekeren alsmede toepasselijke wetgeving omtrent handelingen door hemzelf, zijn juridische vertegenwoordigers, gedelegeerde agenten of enige andere derde en c) onschadelijkheid van de geleverde gegevens voor de Portal met betrekking tot cyber-risico's zoals maar niet beperkt tot malware, virus-aanvallen enz. Aansprakelijkheid van de gebruiker zal worden uitgesloten wanneer dergelijk misbruik of besmetting van de Portal veroorzaakt is door omstandigheden buiten de redelijke controle van de gebruiker om.

### 7. Vrijstelling van aansprakelijkheid
De gebruiker zal de Operator vrijstellen en vrijgesteld houden van alle claims uitgeoefend door andere gebruikers of andere derden jegens de Operator als gevolg van de inbreuk op hun rechten, wanneer een dergelijke inbreuk is veroorzaakt door enig verwijtbaar handelen of omissie beschreven onder clausule 6.2. De gebruiker zal ook de kosten dragen die redelijkerwijs optreden voor de Operator voor de noodzakelijke juridische verweren inclusief alle rechtbank- en advocaatkosten tegen de wettelijke hoeveelheid. De gebruiker is verplicht de Operator onmiddellijk, oprecht en volledig te voorzien van alle informatie die noodzakelijk is voor de evaluatie van een claim en een verdediging in geval van een claim door derden.

### 8. Garantie
8.1 De Operator geeft geen enkele garantie en aanvaardt geen enkele aansprakelijkheid dat a) enige commerciële informatie op de Portal compleet en correct is (geleverd "as is") en b) de Portal vrij is van technische defecten in het bijzonder i) de permanente en ononderbroken beschikbaarheid van de Portal en zijn inhoud of ii) de volledige en correct weergave/reproductie van de inhoud gepost door de gebruiker. De diensten van de Portal worden gratis door de Operator aangeboden, zonder enige wettelijke verplichting om de werking in stand te houden. De Operator behoudt zich het recht voor om te allen tijde a) werking van de Portal volledig of gedeeltelijk, tijdelijk of permanent, op enig moment zonder voorafgaande mededeling te beëindigen of b) gebruikmaking van de Portal afhankelijk te stellen van een contributie-bijdrage van de gebruikers.

8.2 De Operator past zelf geen inhoud van derden aan die geplaatst is op de Portal.

8.3 De Operator zal op geen enkele wijze aansprakelijk zijn voor schade opgetreden door een gebruiker en veroorzaakt door vertrouwen op de correctheid, volledigheid en onschadelijkheid van inhoud van enige derde op de Portal.

### 9. Gebruik van gegevens en gegevensbescherming
Zie voor informatie met betrekking tot de categorie, omvang, plaats en het doel van de collectie, het verwerken en gebruik van de persoonlijke gegevens vereist voor de registratie en het afsluiten van commerciële transacties onze [Privacy Policy]({dataPrivacyLink}).

### 10. Overige bepalingen
10.1 Alle zaken met betrekking tot het gebruik en de interpretatie van de gebruiksvoorwaarden van de Portal en alle onenigheden voortkomend in relatie daartoe of dientengevolge ressorteren onder Nederlands recht, zonder zijn principes van wetsconflicten en exclusief het Verdrag der Verenigde Naties inzake koopovereenkomsten (zgn. Weens koopverdrag) voor de internationale verkoop van goederen (CISG).

10.2 Plaats van rechtspraak voor alle disputen zal Amsterdam, Nederland zijn. Dit is ook van toepassing als de gebruiker geen plaats van algemene jurisdictie in Nederland of de EU heeft, of zijn residentie of gewoonlijke verblijfplaats onbekend is ten tijde van indienen van de zaak.

(Status: 29-8-2019)`,
}

export default messages
