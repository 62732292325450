import styled from 'styled-components'

import { Text } from '@bc/ui/src/components/text'
import { media } from '@bc/ui/src/helpers'

export const TableMobileLabel = styled(Text)`
    display: block;
    margin-bottom: 0 !important;

    ${media.min('sm')} {
        display: none;
    }
`
TableMobileLabel.defaultProps = {
    caption: true,
}
