import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Social_Facebook: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Social_Facebook" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M17.0881 12.9993L17.5981 9.0793H13.7081V6.5793C13.7081 5.4493 14.0281 4.67929 15.6481 4.67929H17.7081V1.17929C16.7081 1.07929 15.7081 1.0293 14.7081 1.0293C11.7081 1.0293 9.70812 2.8493 9.70812 6.1993V9.08929H6.32812V12.9993H9.70812V22.9993H13.7081V12.9993H17.0881Z"
                fill={fill === 'gradient' ? 'url(#Social_Facebook_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Social_Facebook" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Social_Facebook" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M17.0881 12.9993L17.5981 9.0793H13.7081V6.5793C13.7081 5.4493 14.0281 4.67929 15.6481 4.67929H17.7081V1.17929C16.7081 1.07929 15.7081 1.0293 14.7081 1.0293C11.7081 1.0293 9.70812 2.8493 9.70812 6.1993V9.08929H6.32812V12.9993H9.70812V22.9993H13.7081V12.9993H17.0881Z"
                fill={fill === 'gradient' ? 'url(#Social_Facebook_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Social_Facebook" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Social_Facebook" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M17.0881 12.9993L17.5981 9.0793H13.7081V6.5793C13.7081 5.4493 14.0281 4.67929 15.6481 4.67929H17.7081V1.17929C16.7081 1.07929 15.7081 1.0293 14.7081 1.0293C11.7081 1.0293 9.70812 2.8493 9.70812 6.1993V9.08929H6.32812V12.9993H9.70812V22.9993H13.7081V12.9993H17.0881Z"
                fill={fill === 'gradient' ? 'url(#Social_Facebook_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Social_Facebook" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Social_Facebook" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M17.0881 12.9993L17.5981 9.0793H13.7081V6.5793C13.7081 5.4493 14.0281 4.67929 15.6481 4.67929H17.7081V1.17929C16.7081 1.07929 15.7081 1.0293 14.7081 1.0293C11.7081 1.0293 9.70812 2.8493 9.70812 6.1993V9.08929H6.32812V12.9993H9.70812V22.9993H13.7081V12.9993H17.0881Z"
                fill={fill === 'gradient' ? 'url(#Social_Facebook_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Social_Facebook" />}
        </g>
    ),
}
