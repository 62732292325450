import React from 'react'

import { colors } from '@bc/ui/src/config'

import { SvgIconMap } from '../types'

const defaultFill = colors.yellow30

export const status_processed: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-processed"
            d="M22,2v6h-1V2H22z M20,2h-1v6h1V2z M18,2h-1v6h1V2z M16,2h-1v6h1V2z M12,4.054l1,0.333V2.28l-1-0.333
            L4.663,4.392l3.161,1.054L12,4.054z M20,10h2v8.721l-10,3.333L2,18.721V5.612l10,3.334l2-0.667v2.108l-1,0.333v8.892l7-2.334V10z
            M11,10.72L4,8.388v8.892l7,2.334V10.72z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-processed"
            d="M22,2v6h-1V2H22z M20,2h-1v6h1V2z M18,2h-1v6h1V2z M16,2h-1v6h1V2z M12,4.054l1,0.333V2.28l-1-0.333
            L4.663,4.392l3.161,1.054L12,4.054z M20,10h2v8.721l-10,3.333L2,18.721V5.612l10,3.334l2-0.667v2.108l-1,0.333v8.892l7-2.334V10z
            M11,10.72L4,8.388v8.892l7,2.334V10.72z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-processed"
            d="M22,2v6h-1V2H22z M20,2h-1v6h1V2z M18,2h-1v6h1V2z M16,2h-1v6h1V2z M12,4.054l1,0.333V2.28l-1-0.333
            L4.663,4.392l3.161,1.054L12,4.054z M20,10h2v8.721l-10,3.333L2,18.721V5.612l10,3.334l2-0.667v2.108l-1,0.333v8.892l7-2.334V10z
            M11,10.72L4,8.388v8.892l7,2.334V10.72z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-processed"
            d="M22,2v6h-1V2H22z M20,2h-1v6h1V2z M18,2h-1v6h1V2z M16,2h-1v6h1V2z M12,4.054l1,0.333V2.28l-1-0.333
            L4.663,4.392l3.161,1.054L12,4.054z M20,10h2v8.721l-10,3.333L2,18.721V5.612l10,3.334l2-0.667v2.108l-1,0.333v8.892l7-2.334V10z
            M11,10.72L4,8.388v8.892l7,2.334V10.72z"
            transform="scale(0.5)"
        />
    ),
}
