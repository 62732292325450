import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Close: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Close" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M19.2979 3.28844L3.29607 19.2903L4.71028 20.7045L20.7121 4.70266L19.2979 3.28844ZM4.71017 3.29133L3.29596 4.70555L9.17202 10.5816L10.5862 9.16739L4.71017 3.29133ZM13.4078 14.8255L14.822 13.4113L20.6981 19.2874L19.2839 20.7016L13.4078 14.8255Z"
                fill={fill === 'gradient' ? 'url(#Close_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Close" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Close" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M19.2979 3.28844L3.29607 19.2903L4.71028 20.7045L20.7121 4.70266L19.2979 3.28844ZM4.71017 3.29133L3.29596 4.70555L9.17202 10.5816L10.5862 9.16739L4.71017 3.29133ZM13.4078 14.8255L14.822 13.4113L20.6981 19.2874L19.2839 20.7016L13.4078 14.8255Z"
                fill={fill === 'gradient' ? 'url(#Close_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Close" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Close" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M19.2979 3.28844L3.29607 19.2903L4.71028 20.7045L20.7121 4.70266L19.2979 3.28844ZM4.71017 3.29133L3.29596 4.70555L9.17202 10.5816L10.5862 9.16739L4.71017 3.29133ZM13.4078 14.8255L14.822 13.4113L20.6981 19.2874L19.2839 20.7016L13.4078 14.8255Z"
                fill={fill === 'gradient' ? 'url(#Close_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Close" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Close" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M19.2979 3.28844L3.29607 19.2903L4.71028 20.7045L20.7121 4.70266L19.2979 3.28844ZM4.71017 3.29133L3.29596 4.70555L9.17202 10.5816L10.5862 9.16739L4.71017 3.29133ZM13.4078 14.8255L14.822 13.4113L20.6981 19.2874L19.2839 20.7016L13.4078 14.8255Z"
                fill={fill === 'gradient' ? 'url(#Close_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Close" />}
        </g>
    ),
}
