import React from 'react'

import { colors, NotificationMessage } from '@bc/ui'

const LoginError: React.FC = ({ children }) => (
    <NotificationMessage icon="Circle_Delete" backgroundColor={colors.red10} borderColor={colors.red70}>
        {children}
    </NotificationMessage>
)

export default LoginError
