import styled from 'styled-components'

import { colors, media, spacing } from '@bc/ui'
import { Document } from '@frontend/components/material-documents'

interface ContentProps {
    row: boolean
}

export const ResponseWrapper = styled.div<ContentProps>`
    display: flex;
    padding: ${({ row }: ContentProps) => (row ? spacing.sm : 0)}px;
    background-color: ${({ row }: ContentProps) => colors[row ? 'neutral10' : 'white']};
    flex-direction: ${({ row }: ContentProps) => (row ? 'row' : 'column')};
    margin: ${({ row }: ContentProps) => (row ? '10px 0 0 0' : '0')};
    ${media.max('sm')} {
        flex-wrap: wrap;
    }
`

export const ResponseItem = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 50px;
    flex-basis: calc(100% / 2);
    margin: 0 0 20px 0;
    &:first-child {
        flex-basis: calc(100% / 2);
    }
    ${media.max('sm')} {
        flex-basis: 100%;
    }
`

export const ContactContainer = styled.div`
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    align-items: center;
    ${media.max('sm')} {
        justify-content: flex-start;
    }
`

export const MetaWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    ${media.max('sm')} {
        flex-direction: column;
        padding: 10px;
    }
`

export const RequestedDocument = styled(Document)`
    flex-grow: 0;
`

export const SummaryContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`

export const SummaryQuantityContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

export const SummaryColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
`
