// tslint:disable no-invalid-template-strings

import { MessageMap } from '../../'

const messages: MessageMap = {
    'gender:male': 'Male',
    'gender:female': 'Female',
    'gender:unknown': 'Unknown',

    // Routes
    'route:maintenance': '/maintenance',
    'route:document': '/document',
    'route:document-not-found': '/document-not-found',

    'route:session-expired': '/session-expired',

    'route:logout': '/logout',
    'route:logout.label': 'Log out',

    'route:reset-password': '/reset-password',
    'route:reset-password.label': 'Reset password',

    'route:set-password': '/set-password',
    'route:set-password.label': 'Set password',

    'route:forgot-password': '/forgot-password',

    'route:terms-of-usage': '/terms-of-usage',
    'route:terms-of-usage.label': 'Terms of Use',

    'route:product-list': '/product-list',
    'route:product-list.label': 'Products',

    'route:order-history': '/my-orders',
    'route:order-history.label': 'Orders',

    'route:invoices': '/invoices',
    'route:invoices.label': 'Invoices',

    'route:cart-overview': '/checkout',
    'route:cart-overview.label': 'Checkout',

    'route:imprint.label': 'Imprint',
    'route:data-privacy-policy.label': 'Data Privacy Policy',
    'route:faq.label': 'FAQ',

    'route:my-customers': '/my-customers',
    'route:my-customers.label': 'My accounts',

    'route:contacts': '/contacts',
    'route:contacts.label': 'My Users',

    'route:quotes': '/quotes',
    'route:quotes.label': 'Quotes',

    'route:requests': '/requests',
    'route:requests.label': 'Requests',

    'route:account': '/account',

    // Salutations
    'salutation.formal.male': 'Dear Mr {name}',
    'salutation.formal.female': 'Dear Ms {name}',
    'salutation.formal.unknown': 'Dear customer',
    'salutation.informal.male': 'Dear customer',
    'salutation.informal.female': 'Dear customer',
    'salutation.informal.unknown': 'Dear customer',
    'salutation.internal.unknown': 'Dear colleague',

    // Email subjects
    'email:subject:ORDER_CONFIRM': 'Brenntag Connect | Order number {orderID} New Brenntag Connect Order',
    'email:subject:RFI_SALES_PERSON': 'Brenntag Connect | Information request {uid} from {name}',
    'email:subject:RFI_CUSTOMER': 'Brenntag Connect | Information request {uid}',
    'email:subject:RFI_COMPLETE': 'Brenntag Connect | Response to your request {uid}',
    'email:subject:RQ_CUSTOMER': 'Brenntag Connect | Reorder Request {uid}',
    'email:subject:RQ_SALES_PERSON': 'Brenntag Connect | Reorder Request {uid} from {name}',
    'email:subject:ORDER_WO_PRICE': 'Brenntag Connect | Order without a valid price',
    'email:subject:RESET_PW': 'Brenntag Connect | Set Password Request',
    'email:subject:WELCOME': 'Brenntag Connect | Welcome to Brenntag Connect',
    'email:subject:SC_CUSTOMER': 'Brenntag Connect | Order Request {uid}',
    'email:subject:SC_SALES_PERSON': 'Brenntag Connect | Order Request {uid} from {name}',

    // Cookiebar
    'cookiebar:text1':
        'This website uses cookies to enhance your experience. By clicking the OK button, you agree to our cookie policy. ',
    'cookiebar:text2': 'For detailed guidance, please refer to our ',
    'cookiebar:accept-button': 'OK',

    // FAQ
    'faq:contact-title': 'Let us know how we can help',
    'faq:contact-text':
        "We're here to ensure you get the most out of Brenntag Connect. So whatever your question or issue is, just send us an e-mail, and we will answer you as soon as we can.",
    'faq:browse-categories': 'Browse categories',

    // Login page
    'login:header': 'Log in to your account',
    'login:welcome-header': 'Welcome to Brenntag Connect!',
    'login:intro':
        'Brenntag Connect is your online platform for easy re-ordering of chemicals and a comprehensive, up-to-date collection of information.',
    'login:intro-benefit': 'As a registered customer you benefit from our online services:',
    'login:intro-bullet-1': 'Request detailed product information and documentations',
    'login:intro-bullet-2': 'Review up to date pricing (depending on availability)',
    'login:intro-bullet-3': 'Re-order products uncomplicated and fast',
    'login:intro-bullet-4': 'View your ordering history and invoices from the last 24 months',
    'login:help-title': 'Can we help you?',
    'login:help-instruction': 'Please contact our customer service. We would love to help you.',
    'login:help-phone-number': 'Phone number:',
    'login:help-office-hours': 'Our office hours:',
    'login:contact-more': 'Or tell us more about your request:',
    'login:contact-button': 'Contact us',
    'login:copyright': 'All rights reserved.',
    'login:brenntag': '© Brenntag SE',

    'login:username': 'Email address',
    'login:username-placeholder': 'your@email.com',
    'login:password': 'Password',
    'login:password-placeholder': 'Enter your password',
    'login:button.forgot-password': 'Forgot password?',
    'login:back-to-login': 'Back to login',

    // Login page with MFA
    'login-mfa:title': "Verify it's you",
    'login-mfa:intro':
        'We\'ve sent a verification code to your email. Enter the code from the email and click "Verify"',
    'login-mfa-form:input': 'Enter 6 digit code',
    'login-mfa-form:submit': 'Verify',

    // Login form
    'login-form:error': 'Login failed, please check your credentials',
    'login-form:error.access_denied': 'Login failed, please check your credentials',
    'login-form:error.too_many_attempts': 'The account is blocked due to too many attempts to sign in.',

    // Reset password page
    'reset-password:title': 'Set your password',
    'reset-password:body': 'Please check your inbox – we’ve sent you an email to set your password.',

    // Set password token error
    'warning:invalid-token:title': 'Sorry!',
    'warning:invalid-token:description': 'This token has expired! Please reset your password again.',

    // Auth error page
    'auth-error:message': 'Login failed, please try to login again.',
    'auth-error:expired-link:title': 'Activation link expired',
    'auth-error:expired-link:message':
        'Your activation link is has expired, please request a new link by resetting your password.',
    'auth-error:link-text': 'Reset your password',
    'auth0-error:passworddictionaryerror': 'Password is too common.',
    'auth0-error:passwordstrengtherror': 'Password is too weak.',
    'auth0-error:passwordhistoryerror': 'Password has previously been used.',
    'auth0-error:passwordnouserinfoerror': 'Password contains user information.',
    'auth0-error:csrfinvalidtokenerror': 'This token has expired! Please reset your password again.',

    // Rate limit error
    'rate-limit-error:message': 'Too many requests. Please try again later.',

    // Forgot password form
    'forgot-password-form:title': 'Forgot your password?',
    'forgot-password-form:description':
        "If you've previously registered with us, you will receive an email at that address to reset your password.",

    'forgot-password-form:email': 'Email address',
    'forgot-password-form:submit': 'Send email',
    'forgot-password-form:success': 'Request Sent',

    // Set password form
    'set-password-form:title': 'Set password',
    'set-password-form:description': 'Please set a new password',
    'set-password-form:new-password': 'New password',
    'set-password-form:new-password-confirmation': 'Repeat new password',
    'set-password-form:password-instruction':
        'You must set a new password. The new password will be valid for the next 180 days.',
    'set-password-form:success-title': 'Success!',
    'set-password-form:success':
        'Dear customer, \nas part of our increasing effort to supply you with secure access we have implemented 2-Factor Authentication (2FA). Therefore, we have automatically logged you out after you have set your initial password. \nPlease, go to the login page, enter your password and then confirm it with the authentication code you will receive in your email inbox to access Brenntag Connect.',

    // My Customers Page
    'my-customers:city': 'City',
    'my-customers:customer-id': 'Customer ID',
    'my-customers:customer-name': 'Customer Name',
    'my-customers:customer-vat-number': 'VAT number',
    'my-customers:headline': 'My accounts',
    'my-customers:last-order-date': 'Last order date',
    'my-customers:products': 'Products',
    'my-customers:outstanding-orders': 'Outstanding orders',
    'my-customers:items-in-cart': 'Items in cart',
    'my-customers:search-for-customer': 'Search for Customer',
    'my-customers:select-user': 'Select',
    'my-customers:use-search-options': 'Use any of the following search options',
    'my-customers:search-error': 'An error occured during the customer search.',
    'my-customers:division': 'Department',
    // Requests Page
    'requests:title-csr': 'Customer Requests',
    'requests:title-customer': 'My Requests',
    'requests:application': 'Application',
    'requests:csr-subtitle-one': 'Want to get in touch? Email us at:',
    'requests:csr-subtitle-two': 'or call {csrPhone}',
    'requests:delivery-date': 'Delivery date',
    'requests:documents': 'Documents',
    'requests:documents-max-size': '(Max {maxSize}MB)',
    'requests:email': 'User email',
    'requests:messages': 'Messages',
    'requests:po-reference': 'Customer reference',
    'requests:quantity-annual': 'Annual quantity',
    'requests:quantity-call-off': 'Call-off quantity',
    'requests:quantity-sample': 'Sample quantity',
    'requests:requested': 'Requested On',
    'requests:requested-documents': 'Requested documents',
    'requests:ship-to-address': 'Ship to address',
    'requests:status': 'Status',
    'requests:button.edit-complete': 'Edit response',
    'requests:button.edit': 'Respond',
    'requests:edit-modal-title': 'Edit request',
    'requests:edit-modal-summary': 'Request summary',
    'requests:comments': 'Comments',
    'requests:file-too-big': 'File {name} is too big',
    'requests:max-upload': 'Max upload of {mb}MB size reached, file {name} has not been added',
    'requests:mime-types': 'File {name} of type: {type} is not allowed',
    'requests:customer': 'Customer',
    'requests:recipient': 'Recipient',
    'requests:details': 'Details',
    'requests:user-comment': 'User Comment',
    'requests:response': 'Our team will respond to your request as soon as possible!',
    'requests:questions': 'Still haven’t received what you requested? Contact us:',

    // Request enum
    'request-status:COMPLETED': 'Completed',
    'request-status:PENDING': 'Pending',
    'request-type:DOCUMENT': 'Document',
    'request-type:PRICE': 'Price',
    'request-type:PROFILE': 'Profile',
    'request-type:QUOTE': 'Order',
    'request-type:SAMPLE': 'Sample',
    'request-type:CART': 'Order',

    // User management page
    'user:country': 'Country',
    'user:role': 'Role',
    'user:customer-id': 'Customer ID',
    'user:last-login': 'Last Login',
    'user:send-emails': 'Resend Emails',
    'user:email-welcome': 'Re-send welcome email',
    'user:email-welcome-to-support': 'Send welcome email to DigiB Support',
    'user:email-password': 'Re-send password reset email',
    'user:email-password-to-support': 'Send password reset email to DigiB Support',
    'user:admin-actions': 'Admin actions',
    'user:select-admin-action': 'Select an action',
    'user:email-welcome-sent': 'Welcome email sent on',
    'user:edit-user': 'Edit',
    'user:create-new-user': 'Create user',
    'user:sort:username': 'Username',
    'user:sort:logindate': 'Login date',
    'user:email-modal-welcome-title': 'Re-send welcome email',
    'user:email-modal-welcome-text': 'Are you sure you want to re-send the welcome email to this user ({email})?',
    'user:email-modal-welcome-to-support-text': 'Are you sure you want to send the welcome email to DigiB Support?',
    'user:email-modal-welcome-cancel': 'Cancel',
    'user:email-modal-welcome-confirm': 'Send Email',
    'user:email-modal-pwreset-title': 'Send Password Reset email',
    'user:email-modal-pwreset-text': 'Are you sure you want to send a password reset email to this user ({email})?',
    'user:email-modal-pwreset-to-support-text':
        'Are you sure you want to send the password reset email to DigiB Support?',
    'user:email-modal-pwreset-cancel': 'Cancel',
    'user:email-modal-pwreset-confirm': 'Send Email',
    'user:edit-user-modal-title': 'Edit user',
    'user:create-user-modal-title': 'New user',
    'user:limited-search-results':
        'Showing {number} of {count} results. Please use the filters on the left to find specific users.',

    // Form labels & placeholders user management
    'user:form:label-email': 'Email',
    'user:form:label-email-placeholder': 'Enter Email',
    'user:form:label-firstname': 'First name',
    'user:form:label-firstname-placeholder': 'Enter first name',
    'user:form:label-lastname': 'Last name',
    'user:form:label-lastname-placeholder': 'Enter last name',
    'user:form:label-customerids': 'Customer ID',
    'user:form:label-customerids-placeholder': 'Enter Customer ID',
    'user:form:label-language': 'Language',
    'user:form:label-gender': 'Gender',
    'user:form:label-country': 'Country',
    'user:form:label-customer-id-and-sales-org': 'Customer ID and Sales Organization',
    'user:form:label-customer-grant-access': 'Grant access to one or more customers:',
    'user:form:error-customer-grant-access': 'Grant access to one or more customers.',
    'user:form:error-select-one-sales-org': 'Add at least one sales organization.',
    'user:form:error-search': 'Something went wrong, try a new search.',
    'user:form:search-no-results': 'No results found, remember you can search by name, customer ID or VAT number.',
    'user:form:usertype-viewer': 'This user can only download documents.',
    'user:form:label-usertype': 'User permissions',
    'user:form:label-mfa': 'Multi-Factor Authentication (MFA)',
    'user:form:label-enablemfa': 'Enable MFA',
    'user:form:label-placeholder': 'Please select permissions',

    // Customer Selection Dropdown
    'company:selection:label-bill-to': 'Bill-to address:',
    'company:selection:label-country': 'Country:',
    'company:selection:change': 'Change',
    'company:selection:label-customer-id': 'Customer ID:',
    'company:selection:label-vat': 'VAT no:',
    'company:selection:label-name': 'Name',
    'company:selection:title': 'Please select a company',
    'company:selection:intro.one': 'In order to proceed and view relevant information',
    'company:selection:intro.two': 'We request you to select the company name in the table below.',

    // Products
    'products:column.material': 'Product',
    'products:card.material': 'Product name',
    'products:column.cas-no': 'CAS Number',
    'products:column.filling-weight': 'Filling Weight',
    'products:column.supplier': 'Supplier',
    'products:column.grade': 'Grade',
    'products:column.detail-packaging': 'Detail packaging',
    'products:column.einecs': 'Einecs',
    'products:column.unit-capacity': 'Unit capacity',

    'products:more-details-button-text': 'More details',
    'products:blend': '...',
    'products:column.material-number': 'Product number',

    // Product list
    'product-list:headline': '{count} Products',
    'product-list:empty-cart': 'Product list',
    'product-list:request-info': 'Info',

    // Product Modal
    'product-modal:scale-prices': 'Available Price',

    // Contact person
    'contact-person:title': 'Any questions about this order?',
    'contact-person:subtitle': 'Contact our Life Science Customer Service',
    'contact-person:customer-service': 'Customer Service Life Science',

    // Add to cart form
    'add-to-cart-form:success-toast': 'The product has been added to your cart successfully.',

    // Checkout
    'checkout:headline': 'Order overview',
    'checkout:products': 'Products',
    'checkout:empty-cart': 'Your cart is empty',
    'checkout:empty-cart-message': 'Start by adding products to your order or search the product list',
    'checkout:button-continue-shopping': 'Continue shopping',

    'checkout-success:order-submitted': 'Your order has been submitted!',
    'checkout-success:final-instruction':
        'Expect an email from us once your order is approved.<br/> Use Brenntag Connect to download invoices and MSDSs.<br/><br/>For any questions related to this order please email to <a href="mailto:">{email}</a>',
    'checkout-success:order-number': 'Request number',
    'checkout-success:order-date': 'Request date',
    'checkout-success:button-view-requests': 'View requests',
    'checkout-success:button-view-orders': 'View orders',

    // Delivery overview
    'delivery-overview:title': 'Delivery Overview',
    'delivery-overview:billing-address': 'Billing address',
    'delivery-overview:comment': 'Comments',
    'delivery-overview:delivery-date': 'Desired delivery date',
    'delivery-overview:legal-terms': ' apply to this order.',
    'delivery-overview:legal-terms-name': 'Our general conditions of sale',
    'delivery-overview:legal-terms-price': 'Price excluding VAT and surcharges',
    'delivery-overview:payment-terms': 'Terms of Payment',
    'delivery-overview:no-payment-terms': 'No payment terms selected',
    'delivery-overview:po-reference': 'Customer reference',
    'delivery-overview:order-id': 'Order number',
    'delivery-overview:same-as-delivery-address': 'Same as delivery address',
    'delivery-overview:agree-with-terms': 'By placing an order you accept',
    'delivery-overview:submit': 'Place order',
    'delivery-overview:terms-of-delivery': 'Terms of Delivery',
    'delivery-overview:INCO-terms': 'Incoterms',
    'delivery-overview:status': 'Status',
    'delivery-overview:packaging': 'Packaging terms',
    'delivery-overview:agree-with-terms-warning': 'Please accept our general conditions of sale',
    'delivery-overview:INCO-terms-as-agreed': 'As agreed',
    'delivery-overview:payment-terms-as-agreed': 'As agreed',

    // Invoices
    'invoices:date-invoice': 'Invoice Date',
    'invoices:date-due': 'Due Date',
    'invoices:download': 'Download invoice',
    'invoices:title': 'Invoice number: {id}',
    'invoices:total-amount': 'Total Amount (incl. VAT)',

    // Order
    'order:next-steps.title': 'Next',
    'order:line-item.material-no': 'Product number',
    'order:line-item.pieces': 'Pieces',
    'order:line-item.amount': 'Amount',
    'order:line-item.requested': 'Requested for',
    'order:line-item.confirmed': 'Confirmed for',
    'order:line-item.delivered': 'Delivered on',
    'order:line-item.order-quantity': 'Order Quantity',
    'order:line-item.batch': 'Batch No.',
    'order:line-item.total-material-price': 'Total product price',
    'order:line-item.request-price': 'Price on request',
    'order:line-item.price': 'Price',
    'order:status.delivered': 'Delivered',
    'order:status.pending': 'Pending',
    'order:status.indelivery': 'In delivery ',
    'order:status.confirmed': 'Confirmed',
    'order:status.invoiced': 'Invoiced',
    'order:status.cancelled': 'Cancelled',
    'order:status.processed': 'Processed',
    'order:status.loading': 'Loading',
    'order:status.shipped': 'Shipped',

    // Request Types
    'order:status.document': 'Documents',
    'order:status.price': 'Price',
    'order:status.profile': 'Profile',
    'order:status.quote': 'Order',
    'order:status.sample': 'Sample',

    'orderline:pieces': 'pieces',

    // Orders
    'orders:column.status': 'Status',
    'orders:column.sales-number': 'Brenntag sales order number',
    'orders:column.po-reference': 'Customer reference',
    'orders:column.order-date': 'Order date',
    'orders:column.delivery-address': 'Ship to',
    'orders:column.materials-amount': 'Amount of product',
    'orders:column.requested-delivery-date': 'Requested Delivery date',
    'orders:column.delivery-date': 'Delivery Date',
    'orders:column.delivery-date-from-to': 'Multiple deliveries between',
    'orders:column.po-reference-abbreviation': 'Customer reference',
    'orders:button.confirmation': 'Order Confirmation',

    // Quotes
    'quotes:column.offer-date': 'Quote Date',
    'quotes:column.delivery-address': 'Ship to',

    // Material
    'general:material-id': 'Product number',
    'general:packaging': 'Packaging',
    'general:pallet': 'Pallet',
    'general:price': 'Price',
    'general:unit-price': 'Unit Price',
    'general:price-confirmation': 'Price to be confirmed',
    'general:pieces': 'Pieces',
    'general:pieces-plural': '{count, plural, one {Piece} other {Pieces}}',
    'general:pieces-long': 'Quantity',
    'general:amount': 'Amount',

    // General
    'general:filters': 'Filter by',
    'general:login': 'Log in',
    'general:cancel': 'Cancel',
    'general:save': 'Save',
    'general:save-and-send': 'Save & Send',
    'general:send': 'Send',
    'general:delete': 'Delete',
    'general:date-format.day': 'D',
    'general:date-format.month': 'M',
    'general:date-format.year': 'Y',
    'general:error': 'Something went wrong. Please refresh the page.',
    'general:close': 'Close',
    'general:type-here': 'Type here',
    'general:send-us-email': 'Request assistance',
    'general:error.label': 'Error:',
    'general:back-to-overview': 'Back to overview',
    'general:search': 'Search',
    'general:delivery-address': 'Delivery address',
    'general:incoterm': 'Delivery conditions',
    'general:order': 'Order',
    'general:order-id': 'Brenntag Order Number',
    'general:quote': 'Quote',
    'general:offer-id': 'Brenntag Quote Number',
    'general:upload': 'Select files',
    'general:documents': 'Documents',
    'general:select-ship-to': 'Select Ship-To',
    'general:ship-to': 'Ship To',
    'general:po-reference': 'Customer reference',
    'general:sorry': 'Sorry!',
    'general:something-went-wrong': 'Something went wrong. Please refresh the page.',
    'general:save-changes': 'Save changes',
    'general:order-amount': 'Quantity',
    'general:order-quantity': 'Order quantity',
    'general:required-fields': '* required fields',
    'general:file-attached': 'Attached Document',
    'general:file-attached-plural': 'Attached Documents',
    'general:attach-document': 'Attach a document',
    'general:attach-document-plural': 'Attach one or more documents',
    'general:price-conversion': 'Currency conversion',
    'general:id': 'ID',
    'general:quantity-below-minimum': 'The requested quantity is below the minimum order quantity.',
    'general:po-reference-info':
        'A customer reference is mandatory when placing an order. This can be a PO number or any other reference. The reference is also required when the product gets delivered.',

    // react-day-picker
    'react-day-picker:first-day-of-week': '1',
    'react-day-picker:months.january': 'January',
    'react-day-picker:months.february': 'February',
    'react-day-picker:months.march': 'March',
    'react-day-picker:months.april': 'April',
    'react-day-picker:months.may': 'May',
    'react-day-picker:months.june': 'June',
    'react-day-picker:months.july': 'July',
    'react-day-picker:months.august': 'August',
    'react-day-picker:months.september': 'September',
    'react-day-picker:months.october': 'October',
    'react-day-picker:months.november': 'November',
    'react-day-picker:months.december': 'December',
    'react-day-picker:next-month': 'Next month',
    'react-day-picker:previous-month': 'Previous month',
    'react-day-picker:weekdays-short.monday': 'M',
    'react-day-picker:weekdays-short.tuesday': 'T',
    'react-day-picker:weekdays-short.wednesday': 'W',
    'react-day-picker:weekdays-short.thursday': 'T',
    'react-day-picker:weekdays-short.friday': 'F',
    'react-day-picker:weekdays-short.saturday': 'S',
    'react-day-picker:weekdays-short.sunday': 'S',
    'react-day-picker:weekdays-long.monday': 'Monday',
    'react-day-picker:weekdays-long.tuesday': 'Tuesday',
    'react-day-picker:weekdays-long.wednesday': 'Wednesday',
    'react-day-picker:weekdays-long.thursday': 'Thursday',
    'react-day-picker:weekdays-long.friday': 'Friday',
    'react-day-picker:weekdays-long.saturday': 'Saturday',
    'react-day-picker:weekdays-long.sunday': 'Sunday',

    // Filters and sorting
    'filters:search-by-name': 'Product Name',
    'filters:search-by-name.placeholder': 'Product Name',
    'filters:search-by-cas-no': 'CAS Number',
    'filters:search-by-cas-no.tooltip': 'Include hyphens',
    'filters:search-by-cas-no.placeholder': 'CAS Number',
    'filters:search-by-role': 'Role',
    'filters:search-by-role.placeholder': 'All',
    'filters:search-by-csr.placeholder': 'CSR',
    'filters:search-by-admin.placeholder': 'Admin',
    'filters:search-by-user.placeholder': 'User',
    'filters:search-by-viewer.placeholder': 'Viewer',
    'filters:search-by-customer-product-number': 'Product number',
    'filters:search-by-customer-product-number.placeholder': 'Product number',
    'filters:search-by-company-name': 'Customer name',
    'filters:search-by-company-name.placeholder': 'Customer name',
    'filters:search-by-customer': 'Search for customer',
    'filters:search-by-customerID': 'Customer ID',
    'filters:search-by-customerID.placeholder': 'Customer ID',
    'filters:search-by-customerID-or-name.placeholder': 'Customer name or ID',
    'filters:search-by-customerID-or-name-or-vat.placeholder': 'Search Customer Name, Customer ID or VAT number',
    'filters:search-by-offer-number': 'Quote Number',
    'filters:search-by-offer-number.placeholder': 'Quote Number',
    'filters:search-by-po-reference': 'Customer Reference',
    'filters:search-by-po-reference.placeholder': 'Customer Reference',
    'filters:search-by-material-name': 'Product Name',
    'filters:search-by-material-name.placeholder': 'Product Name',
    'filters:search-by-username': 'Username',

    'filters:search-by-here.placeholder': 'Search here',
    'filters:select-ship-to-address': 'Ship To Address',
    'filters:select-an-address': 'Search or Select an Address',
    'filters:clear-filters': 'Reset filters',
    'filter-packaging.de:filters.names':
        'Drum with screw cap, Box, Drum with lid,Bag, Bulk,IBC,Canister,Sample,Bottle/Can,Pouch,Pail,Cylinder,Can,Big Bag,SAFE-TAINER™,Drum,Steel-Bottle',
    'filter-packaging.de:filters.values':
        'Spundfaß,Karton,Deckelfaß,Sack,TKW,IBC,Kanister,Muster,Flasche/Kanne,Beutel,Eimer,Trommel,Dose,Big bag,Safetainer,Gasbehälter,Gasflasche',
    'filter-packaging.bnl:filters.names':
        'Ampule,Bag,Bale,Bottle,Box,Bucket,Bulk,Can,Cartridge,Case,Container,Cylinder,Drum,Flexibag,Flexidrum,Flexitank,Foilbag,Frame,IBC,Log,Octabin,Pallet,Palletbox,Piece,Reel,Roll,Safetainer,Sample,Securitainer,Set,Spray,Tube',
    'filter-packaging.bnl:filters.values':
        'AMPULE,BAG,BALE,BOTTLE,BOX,BUCKET,BULK,CAN,CARTRIDGE,CASE,CONTAINER,CYLINDER,DRUM,FLEXIBAG,FLEXIDRUM,FLEXITANK,FOILBAG,FRAME,IBC,LOG,OCTABIN,PALLET,PALLETBOX,PIECE,REEL,ROLL,SAFETAINER,SAMPLE,SECURITAINER,SET,SPRAY,TUBE',
    'filter-packaging.cee:filters.names':
        'Bag,Bottle,Bucket,Bulk,Can,Drum,IBC,Octabin,Pallet,Safetainer, Other, Canister, Bigbag, Paperboard Barrel, Cardboard, Chest, Package',
    'filter-packaging.cee:filters.values':
        'BAG,BOTTLE,BUCKET,BULK,CAN,DRUM,IBC,OCTABIN,PALLET,SAFETAINER,OTHER,CANISTER,BIGBAG,PAPERBOARD BARREL,CARDBOARD,CHEST,PACKAGE',

    'filters:select-a-packaging-title': 'Select Packaging',
    'filters:order-status': 'Order Status',
    'filters:select-a-status': 'Select a Status',
    'filters:search-by-request-no': 'Request Number',
    'filters:search-by-request-no.placeholder': 'Request Number',
    'filters:search-by-status': 'Status',
    'filters:search-by-status.placeholder': 'Select Status',
    'filters:request-type': 'Request Type',
    'filters:select-request-type': 'Select Request Type',
    'filters:select-country': 'Country',
    'filters:search-by-invoiceID': 'Invoice Number',
    'filters:search-by-invoiceID.placeholder': 'Invoice Number',
    'filters:search-by-einecs-no': 'EINECS Number',
    'filters:search-by-einecs-no.placeholder': 'EINECS Number',
    'filters:packaging-title': 'Packaging',
    'filters:search-by-country': 'Country',
    'filters:search-by-country.placeholder': 'All countries',
    'filters:search-by-role.error-select-one': 'Please, select at least one role.',
    'filters:copy-link': 'Copy link',
    'filters:copy-link-success': 'The link has been copied to the clipboard.',
    'filters:copy-link-error': 'Please, copy the following link:',

    'sort-direction:numeric:asc': '(0-9)',
    'sort-direction:numeric:desc': '(9-0)',
    'sort-direction:string:asc': '(A-Z)',
    'sort-direction:string:desc': '(Z-A)',
    'sort-direction:date:asc': '(old-new)',
    'sort-direction:date:desc': '(new-old)',

    'sort-select:sort-by': 'Sort by',
    'sort-by-request-id': 'Number',
    'sort-by-type': 'Type',
    'sort-by-date': 'Date',
    'sort-by-status': 'Status',
    'sort-by-due-date': 'Due Date',
    'sort-by-invoice-date': 'Invoice Date',
    'sort-by-order-date': 'Order Date',
    'sort-by-po-reference': 'Customer reference',
    'sort-by-invoice-type': 'Invoice Type',
    'sort-by-invoice-id': 'Invoice Number',
    'sort-by-requested-delivery-date': 'Delivery Date',
    'sort-by-offer-number': 'Quote number',
    'sort-by-material-name': 'Product name',
    'sort-by-quote-date': 'Quote date',

    // Packaging Names
    'package:Spundfaß': 'Drum (screw cap)',
    'package:Safetainer': 'SAFE-TAINER™',
    'package:Gasbehälter': 'Drum',
    'package:Gasflasche': 'Steel-Bottle',
    'package:Karton': 'Box',
    'package:Deckelfaß': 'Drum (lid)',
    'package:Sack': 'Bag',
    'package:TKW': 'Bulk',
    'package:IBC': 'IBC',
    'package:Kanister': 'Canister',
    'package:Muster': 'Sample',
    'package:Flasche/Kanne': 'Bottle/Can',
    'package:Beutel': 'Pouch',
    'package:Eimer': 'Bucket',
    'package:Trommel': 'Cylinder',
    'package:Dose': 'Can',
    'package:Big bag': 'Big Bag',

    'package-pl:Spundfaß': 'Drums (screw cap)',
    'package-pl:Safetainer': 'SAFE-TAINERs™',
    'package-pl:Gasbehälter': 'Drums',
    'package-pl:Gasflasche': 'Steel-Bottles',
    'package-pl:Karton': 'Boxes',
    'package-pl:Deckelfaß': 'Drums (lid)',
    'package-pl:Sack': 'Bags',
    'package-pl:TKW': 'Bulk',
    'package-pl:IBC': 'IBC',
    'package-pl:Kanister': 'Canisters',
    'package-pl:Muster': 'Samples',
    'package-pl:Flasche/Kanne': 'Bottles/Cans',
    'package-pl:Beutel': 'Pouches',
    'package-pl:Eimer': 'Buckets',
    'package-pl:Trommel': 'Cylinders',
    'package-pl:Dose': 'Cans',
    'package-pl:Big bag': 'Big Bags',

    // BNL Packaging Names
    'package:AMPULE': 'Ampule',
    'package:BAG': 'Bag',
    'package:BALE': 'Bale',
    'package:BOTTLE': 'Bottle',
    'package:BOX': 'Box',
    'package:BUCKET': 'Bucket',
    'package:BULK': 'Bulk',
    'package:CAN': 'Can',
    'package:CARTRIDGE': 'Cartridge',
    'package:CASE': 'Case',
    'package:CONTAINER': 'Container',
    'package:CYLINDER': 'Cylinder',
    'package:DRUM': 'Drum',
    'package:FLEXIBAG': 'Flexibag',
    'package:FLEXIDRUM': 'Flexidrum',
    'package:FLEXITANK': 'Flexitank',
    'package:FOILBAG': 'Foilbag',
    'package:FRAME': 'Frame',
    'package:LOG': 'Log',
    'package:OCTABIN': 'Octabin',
    'package:PALLET': 'Pallet',
    'package:PALLETBOX': 'Palletbox',
    'package:PIECE': 'Piece',
    'package:REEL': 'Reel',
    'package:ROLL': 'Roll',
    'package:SAFETAINER': 'Safetainer',
    'package:SAMPLE': 'Sample',
    'package:SECURITAINER': 'Securitainer',
    'package:SET': 'Set',
    'package:SPRAY': 'Spray',
    'package:TUBE': 'Tube',
    'package:OTHER': 'Other',
    'package:CANISTER': 'Canister',
    'package:BIGBAG': 'Bigbag',
    'package:PAPERBOARD BARREL': 'Paperboard barrel',
    'package:CARDBOARD': 'Cardboard',
    'package:CHEST': 'Chest',
    'package:PACKAGE': 'Package',

    // BNL packaging plurals
    'package-pl:AMPULE': 'Ampules',
    'package-pl:BAG': 'Bags',
    'package-pl:BALE': 'Bales',
    'package-pl:BOTTLE': 'Bottles',
    'package-pl:BOX': 'Boxes',
    'package-pl:BUCKET': 'Buckets',
    'package-pl:BULK': 'Bulk',
    'package-pl:CAN': 'Cans',
    'package-pl:CARTRIDGE': 'Cartridges',
    'package-pl:CASE': 'Cases',
    'package-pl:CONTAINER': 'Containers',
    'package-pl:CYLINDER': 'Cylinders',
    'package-pl:DRUM': 'Drums',
    'package-pl:FLEXIBAG': 'Flexibags',
    'package-pl:FLEXIDRUM': 'Flexidrums',
    'package-pl:FLEXITANK': 'Flexitanks',
    'package-pl:FOILBAG': 'Foilbags',
    'package-pl:FRAME': 'Frames',
    'package-pl:LOG': 'Logs',
    'package-pl:OCTABIN': 'Octabins',
    'package-pl:PALLET': 'Pallets',
    'package-pl:PALLETBOX': 'Palletboxes',
    'package-pl:PIECE': 'Pieces',
    'package-pl:REEL': 'Reels',
    'package-pl:ROLL': 'Rolls',
    'package-pl:SAFETAINER': 'Safetainers',
    'package-pl:SAMPLE': 'Samples',
    'package-pl:SECURITAINER': 'Securitainers',
    'package-pl:SET': 'Sets',
    'package-pl:SPRAY': 'Sprays',
    'package-pl:TUBE': 'Tubes',
    'package-pl:OTHER': 'Other',
    'package-pl:CANISTER': 'Canisters',
    'package-pl:BIGBAG': 'Bigbags',
    'package-pl:PAPERBOARD BARREL': 'Paperboard barrels',
    'package-pl:CARDBOARD': 'Cardboards',
    'package-pl:CHEST': 'Chests',
    'package-pl:PACKAGE': 'Packages',

    // Surcharge codes
    'surcharge:ZPR2': 'Net Price',
    'surcharge:ZBA2': 'Net Price',
    'surcharge:ZPRS': 'SiSo Contract',
    'surcharge:ZPF0': 'Deposit',
    'surcharge:ZVEM': 'Packaging charges',
    'surcharge:ZVER': 'Packaging charges',
    'surcharge:ZVE2': 'Packaging charges',
    'surcharge:ZVKM': 'Packaging cost',
    'surcharge:ZM01': 'Markup (Small Volume)',
    'surcharge:ZM00': 'Markup (Small Volume)',
    'surcharge:ZSOK': 'Miscellaneous Costs',
    'surcharge:ZSO1': 'Miscellaneous Costs',
    'surcharge:ZVP0': 'Special Package',
    'surcharge:ZMAU': 'Toll/Fuel surcharge',
    'surcharge:ZMA2': 'ADR Surcharge',
    'surcharge:ZTP1': 'Transportation Costs',
    'surcharge:ZTPS': 'Transportation Costs',
    'surcharge:ZSTD': 'Miscellaneous Costs',
    'surcharge:ZST1': 'Miscellaneous Costs',
    'surcharge:ZABK': 'Filling Costs',
    'surcharge:ZAB1': 'Filling Costs',
    'surcharge:ZLG1': 'Storage Costs',
    'surcharge:ZLG2': 'Storage Costs',
    'surcharge:ZFLG': 'Filling Charge',
    'surcharge:ZGVS': 'ADR surcharge',
    'surcharge:ZGV1': 'ADR surcharge',
    'surcharge:Z110': 'Regate Abs.',
    'surcharge:Z111': 'Regate Abs.',
    'surcharge:Z112': 'Regate Abs.',
    'surcharge:Z15P': 'Regate Abs.',
    'surcharge:ZRAB': 'Rebate %',
    'surcharge:ZRB1': 'Regate Abs.',
    'surcharge:ZKA2': 'Customer/Material',
    'surcharge:ZCVH': 'Rebate Abs.',
    'surcharge:ZCVM': 'Customer/Material',
    'surcharge:ZUWP': 'Environmental surcharge',
    'surcharge:ZSKT': 'Cash Discount',
    'surcharge:ZPRB': 'Commission basis',
    'surcharge:ZPRV': 'Commission',
    'surcharge:ZVPR': 'Cost',
    'surcharge:ZSTA': 'Net Price',
    'surcharge:ZSTM': 'Net Price',
    'surcharge:ZB10': 'Mat/Group Rebate',
    'surcharge:ZB11': 'Material Rebate',
    'surcharge:ZB12': 'Customer Rebate',
    'surcharge:ZB14': 'Material Rebate',
    'surcharge:ZB15': 'Material Rebate',
    'surcharge:ZSUB': 'Subsidy',
    'surcharge:ZSUP': 'Subsidy in %',
    'surcharge:ZDCL': 'Dowclene',
    'surcharge:ZWEG': 'Customer/Material',
    'surcharge:ZTUK': 'Transport/Uml costs',
    'surcharge:ZTRA': 'Transport costs',
    'surcharge:ZF00': 'Costs Transport / Uml',
    'surcharge:ZLFR': 'Delivery time <24 hrs',
    'surcharge:ZVPP': 'Cost',
    'surcharge:ZVPL': 'Verp.Kost. Lieferpl.',
    'surcharge:ZVP1': 'Cost',
    'surcharge:ZVKD': 'Cumulation condition',
    'surcharge:ZVK1': 'Cost',
    'surcharge:ZUKE': 'Customer/Material',
    'surcharge:ZUKM': 'Mix',
    'surcharge:ZUKA': 'Bottling',

    'surcharge:ZSLK': 'Sonder-/Dienstleistg',
    'surcharge:ZALG': 'Sonder-/Dienstleistg',
    'surcharge:ZLKW': 'Truck loading',
    'surcharge:ZLA1': 'Customer/Material',
    'surcharge:ZLA2': 'Customer/Material',
    'surcharge:ZBKO': 'Customer/Material',
    'surcharge:ZVKO': 'Customer/Material',
    'surcharge:ZDIK': 'Disposable Costs',
    'surcharge:ZDK1': 'Order processing',
    'surcharge:ZDK2': 'Disposable Costs',

    'surcharge:ZSLO': 'Sonst.Logistikkosten',
    'surcharge:ZNLK': 'sonst. NL-Kosten',
    'surcharge:ZZKO': 'Zentralkosten',
    'surcharge:ZVBR': 'Excise Taxes',
    'surcharge:ZABS': 'Brannt./Energ. VAT',
    'surcharge:MWST': 'VAT',
    'surcharge:SKTO': 'Cash Discount',
    'surcharge:ZVOC': 'VOC Charge',
    'surcharge:ZIVU': 'IV Umschlag',
    'surcharge:GRWR': 'Grenzübergangswert',
    'surcharge:ZMW1': 'Netto FW',
    'surcharge:ZMW2': 'Umsatzsteuer FW',
    'surcharge:ZMW3': 'Brutto FW',

    // Order History
    'order-history:headline': 'Orders',

    // Quote List
    'quotes:headline': 'Quotes',

    // Order Detail
    'order-detail:headline': 'Order {orderId}',

    // Order detail - Not found
    'warning:order-not-found:title': 'Order not found',
    'warning:order-not-found:explanation': 'The order you requested could not be found.',
    'warning:order-not-found:link-text': 'Back to Orders',

    // Order history - Reorder
    'order-detail:reorder': 'Reorder all',
    'reorder:success-toast': 'The products have been added to your cart successfully.',
    'reorder:error-toast': 'The products could not be added to your cart.',
    'reorder:warning-toast': 'Some of the products could not be added to your cart.',

    // Generic error
    'warning:generic-error:title': 'Sorry!',
    'warning:generic-error:explanation': 'Your Brenntag Connect is not in service at the moment.',
    'warning:generic-error:contact': 'Don’t worry! Please, contact your account manager to help you out.',

    // Invalid customer
    'warning:invalid-customer-id:title': 'Sorry!',
    'warning:invalid-customer-id:explanation': 'Your Brenntag Connect account is not available at the moment.',
    'warning:invalid-customer-id:contact': 'No need to worry, please contact your account manager for help.',

    // Login failed
    'warning:login-failed:title': 'Something went wrong',
    'warning:login-failed:explanation': 'Login failed, please check your credentials.',
    'warning:login-failed:button-text': 'Try again',
    'warning:too-many-results:title': 'Please help us find your order(s)',
    'warning:too-many-results:explanation':
        'We need more information to find your order(s) - please enter more letters of the product name.',
    'warning:customer-migrated-bep:title': 'New Brenntag Connect',
    'warning:customer-migrated-bep:explanation':
        'We have now a new version of Brenntag Connect.<br/>Please login here: <a href="https://emea.brenntag.com/{region}">https://emea.brenntag.com/{region}/</a>',

    // Session expired
    'session-expired:header': 'Session expired',
    'session-expired:text': 'Session Expired. Please, login again.',
    'session-expired:login-button': 'Login',

    // Not found
    'not-found:title': 'Page not found!',
    'not-found:message': 'The page you are looking for could not be found.',
    'not-found:orders': 'No results found.',
    'not-found:customers': 'No customers found.',
    'not-found:users': 'No users found.',
    'not-found:link-text': 'Go back home',
    'not-found:invoices': 'No invoices found.',
    'not-found:requests': 'No requests found.',
    'not-found:select': 'No matches found.',

    // Maintenance
    'maintenance:title': 'Brenntag Connect is unavailable',
    'maintenance:message':
        'Brenntag Connect is temporarily unavailable due to system maintenance. We apologize for any inconvenience.',

    // Document not found
    'document-not-found:title': 'Document not found!',
    'document-not-found:message':
        'This document is currently unavailable. For further information, please contact us at <a href="mailto:{csrEmail}">{csrEmail}</a>.',

    // Scale prices
    'scale-prices:from': 'From',

    // Form validation
    'form-validation:required': 'Please provide a value.',
    'form-validation:confirm': 'Field is not the same.',
    'form-validation:email': 'Please provide a valid email address.',
    'form-validation:email-csr':
        'Sorry, you can only create a CSR user if they belong to the Brenntag or DigiB organisation.  Please, contact the second line support in case of questions.',
    'form-validation:email-in-use': 'Email already in use.',
    'form-validation:password': 'Please provide a valid password.',
    'form-validation:password-repeat': 'Please repeat your password',
    'form-validation:date': 'Please provide a valid date.',
    'form-validation:date.past': 'Please select a date before {date}.',
    'form-validation:date.future': 'Please select a date after {date}.',
    'form-validation:date.weekday': 'Please select a weekday.',
    'form-validation:max-length': 'You can fill in up to {length} characters.',
    'form-validation:min-length': 'A minimum amount of {length} characters are required.',
    'form-validation:min-amount': 'Sorry, you have to order at least {amount} {type}.',
    'form-validation:number': 'Please provide a valid number.',
    'form-validation:has-uppercase': 'The password should contain at least 1 uppercase character.',
    'form-validation:has-lowercase': 'The password should contain at least 1 lowercase character.',
    'form-validation:has-numeric-character': 'The password should contain at least 1 numeric character',
    'form-validation:has-symbol-character': 'The password should contain at least 1 symbol.',
    'form-validation:has-word-character': 'Special characters are not allowed.',
    'form-validation:min-length-values': '{values} must have a minimum length of {length}.',
    'form-validation:do-not-exist': "The following customer(s) don't exist: {values}.",
    'form-validation:max-amount': 'Sorry, you can only order at most {amount} {type}.',
    'form-validation:number-too-big': 'The number is too big.',
    'form-validation:reference-required': 'Please add a customer reference.',

    // Account and preference page
    'account:profile': 'My profile',
    'account:title': 'Account & preferences',
    'account:first-name': 'First name',
    'account:last-name': 'Last name',
    'account:email': 'E-mail address',
    'account:name': 'Name',
    'account:phone': 'Phone number',
    'account:shipping-address': 'Shipping Address',
    'account:billing-address': 'Billing Address',
    'account:personal-details': 'Personal details',
    'account:company-details': 'Customer details',
    'account:users': 'Users',
    'account:company-name': 'Customer name',
    'account:preferences': 'Preferences',
    'account:language': 'Default language',
    'account:related:title': 'Users for {company}',
    'account:related:intro':
        'The following list include the users which have access to Brenntag Connect within your organization. To add or remove users please contact <a href="mailto:{csrEmail}">{csrEmail}</a>.',
    'account:related:no-results':
        'There are no other users which have access to Brenntag Connect within your organization. To add or remove users please contact <a href="mailto:{csrEmail}">{csrEmail}</a>.',

    // Request information Modal
    'request-information-modal:add-to-cart': 'Add to cart',
    'request-information-modal:edit-quantity-cart': 'Change quantity',
    'request-information-modal:add-to-cart.instruction': 'Enter the quantity that you would like to order.',
    'request-information-modal:toasts-product-added': 'This product has been added to your shopping cart.',
    'request-information-modal:toasts-product-adjusted': 'The quantity has been adjusted in your shopping cart.',
    'request-information-modal:remove-from-cart': 'Remove Product',
    'request-information-modal:remove-from-cart.instruction':
        'Are you sure you want to remove {productName} from your cart?',
    'request-information-modal:toasts-product-removed': 'This product has been removed from your shopping cart.',

    'request-information-modal:request-information': 'Request info',
    'request-information-modal:request-quote': 'Order',
    'request-information-modal:request-quote-confirm': 'Confirm quote',
    'request-information-modal:step': 'step',
    'request-information-modal:step.of': 'of',
    'request-information-modal:text': 'Fill this form to request additional product information',
    'request-information-modal:text.quote': 'Fill this form to request a reorder',
    'request-information-modal:success-toast':
        'You’ve successfully sent your request. The Brenntag Connect team will be in touch with you shortly.',
    'request-information-modal:send-request': 'Send request',
    'request-information-modal:needs': 'What do you need?',
    'request-information-modal:document-types': 'Document types',
    'request-information-modal:application': 'Application',
    'request-information-modal:amount': 'Quantity',
    'request-information-modal:sample-quantity': 'Sample quantity',
    'request-information-modal:annual-quantity': 'Annual quantity',
    'request-information-modal:call-off-quantity': 'Call off quantity',
    'request-information-modal:requested-delivery-date': 'Requested Delivery date',
    'request-information-modal:requested-pickup-date': 'Requested Pick-up Date',
    'request-information-modal:order-quantity': 'Order Quantity',
    'request-information-modal:comment': 'Comments',
    'request-information-modal:step2': 'Go to step 2',
    'request-information-modal:requested-information': 'Requested information',

    'request-information-modal:document-types-other-placeholder': 'Document name',
    'request-information-modal:select-an-application': 'Select an application',
    'request-information-modal:me': 'Me',
    'request-information-modal:someone-else': 'Someone else',
    'request-information-modal:back-to-step-1': 'Back to step 1',
    'request-information-modal:back-to-step-2': 'Back to step 2',
    'request-information-modal:my-address-book': 'Delivery address',
    'request-information-modal:new-address': 'Other delivery address',
    'request-information-modal:document-types.msds': 'Material Safety Data Sheet (MSDS)',
    'request-information-modal:document-types.tds': 'Technical Data Sheet (TDS)',
    'request-information-modal:document-types.specification': 'Specification',
    'request-information-modal:document-types.coa': 'Certificate Of Analysis (COA)',
    'request-information-modal:document-types.other': 'Other document',
    'request-information-modal:requirement.price': 'Price',
    'request-information-modal:requirement.document': 'Documents',
    'request-information-modal:requirement.sample': 'Sample',
    'request-information-modal:requirement.quote': 'Quote',
    'request-information-modal:order': 'Order',
    'request-information-modal:reorder': 'Reorder',
    'request-information-modal:reorder:disabled-unavailableMaterial':
        'This article has been adjusted. Contact your Brenntag Connect team.',
    'request-information-modal:reorder:disabled-unavailableForCustomer': 'Product unavailable; conditions apply',
    'request-information-modal:reorder:disabled-unavailable': 'This is a sample. Contact your Brenntag Connect team.',
    'request-information-modal:reorder:select-delivery-date': 'Please select a delivery date and a ship-to address.',
    'request-information-modal:reorder:no-prices-available': 'Price will be confirmed',
    'request-information-modal:preview-and-submit': 'Preview & Submit',
    'request-information-modal:confirmation':
        'Thank you for your order. The Brenntag Connect team will confirm your order as soon as possible. After you receive your order confirmation, you can check your order status in the Brenntag Connect Orders overview. ',
    'request-information-modal:available-prices': 'Available prices',
    'request-information-modal:reorder:self-pickup': 'Pick-up at a warehouse',
    'request-information-modal:different-shipto-warning':
        'When another address is selected, price will need to be confirmed',

    'request-information:auto-complete:price':
        'Thank you for your price request. The Brenntag Connect team will respond to your price request as as soon as possible.',
    'request-information:auto-complete:sample':
        'Thank you for your sample request. The Brenntag Connect team will respond to your sample request as as soon as possible.',
    'request-information:auto-complete:quote':
        'Your order request is sent to Brenntag and will be processed as soon as possible. When the order is processed it will appear in the order overview in Brenntag Connect.',
    'request-information:auto-complete:cart':
        'Your order request is sent to Brenntag and will be processed as soon as possible. When the order is processed it will appear in the order overview in Brenntag Connect.',

    // Request information application dropdown list
    'request-information:application:ai-automotive': 'Automotive',
    'request-information:application:ai-construction-flooring': 'Construction Flooring',
    'request-information:application:ai-coatings-architect-coatings': 'Coatings Architect. coatings',
    'request-information:application:ai-construction-building-chemicals': 'Construction Building chemicals',
    'request-information:application:ai-construction-concrete-based-prod-': 'Construction Concrete based prod.',
    'request-information:application:ai-decorative-paints': 'Decorative Paints',
    'request-information:application:ai-dental': 'Dental',
    'request-information:application:ai-coatings-sealants': 'Coatings Sealants',
    'request-information:application:ai-printing-publishing': 'Printing & publishing',
    'request-information:application:ai-electronic': 'Electronic',
    'request-information:application:ai-epoxy-systems': 'Epoxy Systems',
    'request-information:application:ai-coatings-other': 'Coatings Other',
    'request-information:application:ai-filter-and-environmental-technology': 'Filter and environmental technology',
    'request-information:application:ai-glass': 'Glass',
    'request-information:application:ai-rubber-seals-gaskets': 'Rubber Seals & Gaskets',
    'request-information:application:ai-rubber-wires-cables': 'Rubber Wires & Cables',
    'request-information:application:ai-rubber-latex-goods': 'Rubber Latex Goods',
    'request-information:application:ai-rubber-medical-parts': 'Rubber Medical Parts',
    'request-information:application:ai-rubber-tyre': 'Rubber tyre',
    'request-information:application:ai-rubber-tubes-hoses': 'Rubber Tubes & Hoses',
    'request-information:application:ai-rubber-other': 'Rubber other',
    'request-information:application:ai-rubber-compounds': 'Rubber Compounds',
    'request-information:application:ai-homecare': 'Homecare',
    'request-information:application:ai-coatings-wood-coatings': 'Coatings Wood coatings',
    'request-information:application:ai-coatings-industrial-coatings': 'Coatings Industrial coatings',
    'request-information:application:ai-industrial-cleaning': 'Industrial cleaning',
    'request-information:application:ai-ceramics': 'Ceramics',
    'request-information:application:ai-coatings-adhesives': 'Coatings Adhesives',
    'request-information:application:ai-coatings-raw-materials-other': 'Coatings Raw materials other',
    'request-information:application:ai-agro': 'Agro',
    'request-information:application:ai-aerospace': 'Aerospace',
    'request-information:application:ai-metal-surface-treatments': 'Metal & Surface Treatments',
    'request-information:application:ai-oil-upstream-fracs-drill': 'Oil Upstream fracs Drill',
    'request-information:application:ai-oil-midstream-n-spec': 'Oil Midstream N-spec',
    'request-information:application:ai-oil-midstream-gasplants': 'Oil Midstream gasplants',
    'request-information:application:ai-oil-midstream-compounder': 'Oil Midstream compounder',
    'request-information:application:ai-oil-downstream-refinerie': 'Oil Downstream refinerie',
    'request-information:application:ai-oil-oil-gas-other': 'Oil Oil & gas other',
    'request-information:application:ai-paper-manufacturers': 'Paper Manufacturers',
    'request-information:application:ai-pulp-paper-others': 'Pulp & Paper Others',
    'request-information:application:ai-polymers-compounders': 'Polymers Compounders',
    'request-information:application:ai-polymers-extrusion': 'Polymers Extrusion',
    'request-information:application:ai-polymers-extrusion-foil': 'Polymers Extrusion, foil',
    'request-information:application:ai-polymers-pu-elastomers': 'Polymers PU Elastomers',
    'request-information:application:ai-polymers-pvc': 'Polymers PVC',
    'request-information:application:ai-polymers-raw-mat-producers': 'Polymers Raw mat. producers',
    'request-information:application:ai-polymers-other': 'Polymers other',
    'request-information:application:ai-polymers-elastomers-other': 'Polymers Elastomers Other',
    'request-information:application:ai-polymers-injection-moulding': 'Polymers Injection moulding',
    'request-information:application:ai-chemical-processing': 'Chemical Processing',
    'request-information:application:ai-coatings-powder-coatings': 'Coatings Powder coatings',
    'request-information:application:ai-cleaning-vehicles': 'Cleaning vehicles',
    'request-information:application:ai-cleaning-floorcare': 'Cleaning floorcare',
    'request-information:application:ai-cleaning-food-area': 'Cleaning food area',
    'request-information:application:ai-cleaning-other': 'Cleaning Other',
    'request-information:application:ai-dry-clean-laundry': 'Dry clean & laundry',
    'request-information:application:ai-lubricants': 'Lubricants',
    'request-information:application:ai-lub-fuel-blends': 'Lub.& fuel blends ',
    'request-information:application:ai-construction-other': 'Construction Other',
    'request-information:application:ai-chemical-synthesis': 'Chemical Synthesis',
    'request-information:application:ai-textile-leather': 'Textile & Leather',
    'request-information:application:ai-coatings-inks': 'Coatings Inks',
    'request-information:application:ai-packaging-converting': 'Packaging & Converting',
    'request-information:application:ai-water-cond-serv-cy-waste-water': 'Water cond. serv. Cy / waste water',
    'request-information:application:ai-water-mun-waste-water': 'Water Mun. waste water',
    'request-information:application:ai-water-mun-swimming-pool': 'Water Mun. swimming pool',
    'request-information:application:ai-water-mun-potable-water': 'Water Mun. potable water',
    'request-information:application:ai-water-teatment-other': 'Water Teatment Other',
    'request-information:application:ai-waterconditioning': 'Waterconditioning',
    'request-information:application:ai-tissue-manufacturers': 'Tissue Manufacturers',
    'request-information:application:ai-construction-cement-products': 'Construction Cement products',
    'request-information:application:animal-nutrition:air-treatment': 'Animal Nutrition - Air Treatment',
    'request-information:application:animal-nutrition:enrichment-(minerals-vitamins-amino-acids)':
        'Animal Nutrition - Enrichment (minerals, vitamins, amino acids…)',
    'request-information:application:animal-nutrition:antioxidants': 'Animal Nutrition - Antioxidants',
    'request-information:application:animal-nutrition:flavours': 'Animal Nutrition - Flavours',
    'request-information:application:animal-nutrition:binder-flow-additives-coagulation-agents':
        'Animal Nutrition - Binder, flow additives, coagulation agents',
    'request-information:application:animal-nutrition:products-for-biogas-systems':
        'Animal Nutrition - products for biogas systems',
    'request-information:application:animal-nutrition:biocides-and-hygiene-products':
        'Animal Nutrition - biocides and hygiene products',
    'request-information:application:animal-nutrition:emulsifiers-stabilizers-thickener':
        'Animal Nutrition - Emulsifiers, stabilizers, thickener',
    'request-information:application:animal-nutrition:energy-suppliers': 'Animal Nutrition - Energy suppliers',
    'request-information:application:animal-nutrition:colourings': 'Animal Nutrition - Colourings',
    'request-information:application:animal-nutrition:coccidiostatics-and-histomonostats':
        'Animal Nutrition - coccidiostatics and histomonostats',
    'request-information:application:animal-nutrition:preservatives': 'Animal Nutrition - preservatives',
    'request-information:application:animal-nutrition:performance-booster': 'Animal Nutrition - Performance Booster',
    'request-information:application:animal-nutrition:radionuclide-binder': 'Animal Nutrition - Radionuclide binder',
    'request-information:application:animal-nutrition:acidity-regulators': 'Animal Nutrition - Acidity Regulators',
    'request-information:application:animal-nutrition:silage-additives': 'Animal Nutrition - Silage additives',
    'request-information:application:animal-nutrition:technical-adjuvants': 'Animal Nutrition - Technical adjuvants',
    'request-information:application:animal-nutrition:release-agents': 'Animal Nutrition - Release agents',
    'request-information:application:animal-nutrition:water-treatment': 'Animal Nutrition - Water Treatment',
    'request-information:application:cosmetics:deodorant': 'Cosmetics - Deodorant',
    'request-information:application:cosmetics:hair-care': 'Cosmetics - Hair care',
    'request-information:application:cosmetics:hair-styling': 'Cosmetics - Hair styling',
    'request-information:application:cosmetics:make-up': 'Cosmetics - Make-up',
    'request-information:application:cosmetics:oral-hygiene': 'Cosmetics - Oral hygiene',
    'request-information:application:cosmetics:perfume': 'Cosmetics - Perfume',
    'request-information:application:cosmetics:skin-care': 'Cosmetics - Skin care',
    'request-information:application:cosmetics:skin-cleansing': 'Cosmetics - Skin cleansing',
    'request-information:application:cosmetics:sun': 'Cosmetics - Sun',
    'request-information:application:food:acidifiers-and-acidity-regulators':
        'Food - Acidifiers and acidity regulators',
    'request-information:application:food:antioxidants': 'Food - Antioxidants',
    'request-information:application:food:carriers-and-bulking-agents': 'Food - Carriers and bulking agents',
    'request-information:application:food:coating-and-release-agents': 'Food - Coating and release agents',
    'request-information:application:food:colourings': 'Food - Colourings',
    'request-information:application:food:emulsifier': 'Food - Emulsifier',
    'request-information:application:food:flavours': 'Food - Flavours',
    'request-information:application:food:foaming-agents': 'Food - Foaming agents',
    'request-information:application:food:humectants': 'Food - Humectants',
    'request-information:application:food:nutrition-enrichment': 'Food - Nutrition/Enrichment',
    'request-information:application:food:raising-and-flour-tratment-agents':
        'Food - Raising and flour tratment agents',
    'request-information:application:food:rreservatives-and-stabilizers': 'Food - Preservatives and stabilizers',
    'request-information:application:food:smelting-agent': 'Food - Smelting agent',
    'request-information:application:food:sugars-and-sweeteners': 'Food - Sugars and sweeteners',
    'request-information:application:food:technical-adjuvants': 'Food - Technical adjuvants',
    'request-information:application:food:thickener-gelling-and-firming-agent':
        'Food - Thickener, gelling and firming agent',
    'request-information:application:medicinal-product:capsule': 'Medicinal Product capsule',
    'request-information:application:medicinal-product:cream-gel-salve': 'Medicinal Product cream, gel, salve',
    'request-information:application:medicinal-product:drops-(oral)': 'Medicinal Product drops (oral)',
    'request-information:application:medicinal-product:eye-drops': 'Medicinal Product eye-drops',
    'request-information:application:medicinal-product:parenteral': 'Medicinal Product Parenteral',
    'request-information:application:medicinal-product:sachet': 'Medicinal Product sachet',
    'request-information:application:medicinal-product:spray-solution-(eyes-nose)':
        'Medicinal Product spray/solution (eyes, nose)',
    'request-information:application:medicinal-product:syrup-(oral)': 'Medicinal Product syrup (oral)',
    'request-information:application:medicinal-product:tablet': 'Medicinal Product tablet',
    'request-information:application:mp-ns-ph:others': 'MP / NS / PH - Others',
    'request-information:application:nutritional-supplement:syrup-drops-solution':
        'Nutritional Supplement syrup, drops, solution',
    'request-information:application:nutritional-supplement:tablet-capsule-sachet':
        'Nutritional Supplement tablet, capsule, sachet',
    'request-information:application:pharma:api-synthesis': 'Pharma - API synthesis',
    'request-information:application:pharma:biotechnology': 'Pharma - Biotechnology',
    'request-information:application:pharma:desinfection': 'Pharma - Desinfection',
    'request-information:application:other': 'Other',

    // Applications list for CEE

    'request-information:application:adblue:oem-&-truck-dealers': 'AdBlue - OEM & Truck dealers',
    'request-information:application:adblue:distribution': 'AdBlue - Distribution',
    'request-information:application:adblue:endusers,-carriers-&-fleetowners':
        'AdBlue - Endusers, Carriers & Fleetowners',
    'request-information:application:adblue:public-filling-stations-(codo/dodo)':
        'AdBlue - Public Filling Stations (CODO/DODO)',
    'request-information:application:adblue:retail-&-public-filling-stations--(codo)':
        'AdBlue - Retail & Public Filling Stations  (CODO)',
    'request-information:application:adblue:others': 'AdBlue - Others',
    'request-information:application:agro:desinfection': 'Agro - Desinfection',
    'request-information:application:agro:herbicides-&-pesticides': 'Agro - Herbicides & Pesticides',
    'request-information:application:agro:mixed-production': 'Agro - Mixed Production',
    'request-information:application:agro:fertilizers': 'Agro - Fertilizers',
    'request-information:application:agro:horticulture': 'Agro - Horticulture',
    'request-information:application:agro:farmers': 'Agro - Farmers',
    'request-information:application:agro:orchards': 'Agro - Orchards',
    'request-information:application:agro:others': 'Agro - Others',
    'request-information:application:cement-mills:': 'Cement Mills',
    'request-information:application:cleaning:cleaning-&-cleansing': 'Cleaning - Cleaning & Cleansing',
    'request-information:application:cleaning:homecare': 'Cleaning - Homecare',
    'request-information:application:cleaning:distributors': 'Cleaning - Distributors',
    'request-information:application:cleaning:auto-care-cleaning': 'Cleaning - Auto Care Cleaning',
    'request-information:application:cleaning:industrial-&-institutional-cleaning':
        'Cleaning - Industrial & Institutional Cleaning',
    'request-information:application:cleaning:others': 'Cleaning - Others',
    'request-information:application:heat-engineering:': 'Heat Engineering',
    'request-information:application:chemical-synthesis:': 'Chemical Synthesis',
    'request-information:application:distributors:others': 'Distributors - Others',
    'request-information:application:electronics:medical-equipment-&-tools': 'Electronics - Medical Equipment & Tools',
    'request-information:application:electronics:automotive-electronics': 'Electronics - Automotive Electronics',
    'request-information:application:electronics:lighting-equipment': 'Electronics - Lighting Equipment',
    'request-information:application:electronics:brown-goods': 'Electronics - Brown Goods',
    'request-information:application:electronics:others': 'Electronics - Others',
    'request-information:application:power-industry:': 'Power Industry',
    'request-information:application:renewable-energy:biodiesel': 'Renewable Energy - Biodiesel',
    'request-information:application:renewable-energy:biogas': 'Renewable Energy - Biogas',
    'request-information:application:renewable-energy:solar': 'Renewable Energy - Solar',
    'request-information:application:renewable-energy:others': 'Renewable Energy - Others',
    'request-information:application:coatings-&-construction:construction:building-chemicals':
        'Coatings & Construction - Construction - Building Chemicals',
    'request-information:application:coatings-&-construction:construction:fireproof-materials':
        'Coatings & Construction - Construction - Fireproof Materials',
    'request-information:application:coatings-&-construction:construction:gipsum-cardboard':
        'Coatings & Construction - Construction - Gipsum Cardboard',
    'request-information:application:coatings-&-construction:construction:plywood-&-particle-boards':
        'Coatings & Construction - Construction - Plywood & Particle Boards',
    'request-information:application:coatings-&-construction:construction:floorings':
        'Coatings & Construction - Construction - Floorings',
    'request-information:application:coatings-&-construction:construction:concrete-based-products':
        'Coatings & Construction - Construction - Concrete-based Products',
    'request-information:application:coatings-&-construction:construction:cement-based-products':
        'Coatings & Construction - Construction - Cement-based Products',
    'request-information:application:coatings-&-construction:construction:plasters':
        'Coatings & Construction - Construction - Plasters',
    'request-information:application:coatings-&-construction:construction:mineral-wool-':
        'Coatings & Construction - Construction - Mineral Wool ',
    'request-information:application:coatings-&-construction:construction:others':
        'Coatings & Construction - Construction - Others',
    'request-information:application:coatings-&-construction:distributors': 'Coatings & Construction - Distributors',
    'request-information:application:coatings-&-construction:elastomers:pu-elastomers':
        'Coatings & Construction - Elastomers - PU Elastomers',
    'request-information:application:coatings-&-construction:elastomers:others':
        'Coatings & Construction - Elastomers - Others',
    'request-information:application:coatings-&-construction:coatings:architectural-coatings':
        'Coatings & Construction - Coatings - Architectural Coatings',
    'request-information:application:coatings-&-construction:coatings:wood-coatings':
        'Coatings & Construction - Coatings - Wood Coatings',
    'request-information:application:coatings-&-construction:coatings:powder-coatings':
        'Coatings & Construction - Coatings - Powder Coatings',
    'request-information:application:coatings-&-construction:coatings:industrial-coatings':
        'Coatings & Construction - Coatings - Industrial Coatings',
    'request-information:application:coatings-&-construction:coatings:primers':
        'Coatings & Construction - Coatings - Primers',
    'request-information:application:coatings-&-construction:coatings:automotive-coatings':
        'Coatings & Construction - Coatings - Automotive Coatings',
    'request-information:application:coatings-&-construction:coatings:thinners':
        'Coatings & Construction - Coatings - Thinners',
    'request-information:application:coatings-&-construction:coatings:others':
        'Coatings & Construction - Coatings - Others',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:adhesives':
        'Coatings & Construction - Adhesives & Sealants - Adhesives',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:hot-melts':
        'Coatings & Construction - Adhesives & Sealants - Hot Melts',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:sealants':
        'Coatings & Construction - Adhesives & Sealants - Sealants',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:others':
        'Coatings & Construction - Adhesives & Sealants - Others',
    'request-information:application:coatings-&-construction:composites': 'Coatings & Construction - Composites',
    'request-information:application:coatings-&-construction:producers-of-c&c-raw-materials':
        'Coatings & Construction - Producers of C&C Raw Materials',
    'request-information:application:coatings-&-construction:inks': 'Coatings & Construction - Inks',
    'request-information:application:coatings-&-construction:others': 'Coatings & Construction - Others',
    'request-information:application:mining:natural-building-materials-&-stones':
        'Mining - Natural Building Materials & Stones',
    'request-information:application:mining:metal-ore': 'Mining - Metal Ore',
    'request-information:application:mining:coal': 'Mining - Coal',
    'request-information:application:mining:others': 'Mining - Others',
    'request-information:application:steelworks:': 'Steelworks',
    'request-information:application:municipalities-&-institutions:schools-&-universities':
        'Municipalities & Institutions - Schools & Universities',
    'request-information:application:municipalities-&-institutions:hospitals':
        'Municipalities & Institutions - Hospitals',
    'request-information:application:municipalities-&-institutions:others': 'Municipalities & Institutions - Others',
    'request-information:application:coke-engineering:': 'Coke Engineering',
    'request-information:application:fotochemical-&-film-materials:': 'Fotochemical & Film Materials',
    'request-information:application:explosives-&-pyrotechnics:': 'Explosives & Pyrotechnics',
    'request-information:application:environmental-protection:consulting-&-services':
        'Environmental Protection - Consulting & Services',
    'request-information:application:environmental-protection:soil-remediation':
        'Environmental Protection - Soil Remediation',
    'request-information:application:environmental-protection:incineration-plants':
        'Environmental Protection - Incineration Plants',
    'request-information:application:environmental-protection:waste-dumps': 'Environmental Protection - Waste Dumps',
    'request-information:application:reagents:': 'Reagents',
    'request-information:application:printing-&-publishing:narrow-web': 'Printing & Publishing - Narrow Web',
    'request-information:application:printing-&-publishing:packaging-printing':
        'Printing & Publishing - Packaging Printing',
    'request-information:application:printing-&-publishing:distributors': 'Printing & Publishing - Distributors',
    'request-information:application:printing-&-publishing:printing-inks': 'Printing & Publishing - Printing Inks',
    'request-information:application:printing-&-publishing:flexography': 'Printing & Publishing - Flexography',
    'request-information:application:printing-&-publishing:bookbinding': 'Printing & Publishing - Bookbinding',
    'request-information:application:printing-&-publishing:sheet-fed-offset':
        'Printing & Publishing - Sheet Fed Offset',
    'request-information:application:printing-&-publishing:cold-set-offset': 'Printing & Publishing - Cold Set Offset',
    'request-information:application:printing-&-publishing:heat-set-offset': 'Printing & Publishing - Heat Set Offset',
    'request-information:application:printing-&-publishing:serigraphy': 'Printing & Publishing - Serigraphy',
    'request-information:application:printing-&-publishing:rotogravure': 'Printing & Publishing - Rotogravure',
    'request-information:application:printing-&-publishing:others': 'Printing & Publishing - Others',
    'request-information:application:loundries:': 'Loundries',
    'request-information:application:automotive-oems:': 'Automotive OEMs',
    'request-information:application:automotive-oems---service-companies:': 'Automotive OEMs - service companies',
    'request-information:application:machinery-production:measuring-instruments':
        'Machinery Production - Measuring Instruments',
    'request-information:application:machinery-production:mechanized-household-equipment':
        'Machinery Production - Mechanized Household Equipment',
    'request-information:application:machinery-production:others': 'Machinery Production - Others',
    'request-information:application:ceramic-industry:household-ceramic': 'Ceramic Industry - Household Ceramic',
    'request-information:application:ceramic-industry:others': 'Ceramic Industry - Others',
    'request-information:application:wood-industry-&-woodwork:furniture': 'Wood Industry & Woodwork - Furniture',
    'request-information:application:wood-industry-&-woodwork:woodwork': 'Wood Industry & Woodwork - Woodwork',
    'request-information:application:wood-industry-&-woodwork:wood-industry':
        'Wood Industry & Woodwork - Wood Industry',
    'request-information:application:wood-industry-&-woodwork:others': 'Wood Industry & Woodwork - Others',
    'request-information:application:pharma:distributors': 'Pharma - Distributors',
    'request-information:application:pharma:human-&-veterinary-medicines': 'Pharma - Human & Veterinary Medicines',
    'request-information:application:pharma:natural-remedies': 'Pharma - Natural Remedies',
    'request-information:application:pharma:pharmaceutical-starting-material-producers':
        'Pharma - Pharmaceutical Starting Material Producers',
    'request-information:application:pharma:others': 'Pharma - Others',
    'request-information:application:rubber:footwear': 'Rubber - Footwear',
    'request-information:application:rubber:distributors': 'Rubber - Distributors',
    'request-information:application:rubber:non-automotive-profiles': 'Rubber - Non Automotive Profiles',
    'request-information:application:rubber:automotive-profiles': 'Rubber - Automotive Profiles',
    'request-information:application:rubber:general-rubber-goods': 'Rubber - General Rubber Goods',
    'request-information:application:rubber:wire-&-cables': 'Rubber - Wire & Cables',
    'request-information:application:rubber:tires:automotive-tires': 'Rubber - Tires - Automotive Tires',
    'request-information:application:rubber:tires:industrial-tires': 'Rubber - Tires - Industrial Tires',
    'request-information:application:rubber:tires:others': 'Rubber - Tires - Others',
    'request-information:application:rubber:non-automotive-hoses': 'Rubber - Non Automotive Hoses',
    'request-information:application:rubber:automotive-hoses': 'Rubber - Automotive Hoses',
    'request-information:application:rubber:automotive-belts': 'Rubber - Automotive Belts',
    'request-information:application:rubber:non-automotive-belts': 'Rubber - Non Automotive Belts',
    'request-information:application:rubber:rubber-rolls-and-wheels': 'Rubber - Rubber Rolls and Wheels',
    'request-information:application:rubber:latex': 'Rubber - Latex',
    'request-information:application:rubber:custom-mixing': 'Rubber - Custom Mixing',
    'request-information:application:rubber:others': 'Rubber - Others',
    'request-information:application:cosmetics:distributors': 'Cosmetics - Distributors',
    'request-information:application:cosmetics:general': 'Cosmetics - General',
    'request-information:application:cosmetics:decorative-products-&-make-up':
        'Cosmetics - Decorative Products & Make-Up',
    'request-information:application:cosmetics:soap-&-candles': 'Cosmetics - Soap & Candles',
    'request-information:application:cosmetics:perfumes-&-fragrance': 'Cosmetics - Perfumes & Fragrance',
    'request-information:application:cosmetics:skin-care-and-sun-cosmetics': 'Cosmetics - Skin Care and Sun Cosmetics',
    'request-information:application:cosmetics:hair-care-and-body-wash': 'Cosmetics - Hair Care and Body Wash',
    'request-information:application:cosmetics:toll-manufacturers-&-contractors':
        'Cosmetics - Toll Manufacturers & Contractors',
    'request-information:application:cosmetics:others': 'Cosmetics - Others',
    'request-information:application:metal:zinc-coating---automotive-components':
        'Metal - Zinc Coating - Automotive Components',
    'request-information:application:metal:zinc-coating---non-automotive-components':
        'Metal - Zinc Coating - Non Automotive Components',
    'request-information:application:metal:distribution': 'Metal - Distribution',
    'request-information:application:metal:electroplating---automotive-components':
        'Metal - Electroplating - Automotive Components',
    'request-information:application:metal:electroplating---non-automotive-components':
        'Metal - Electroplating - Non Automotive Components',
    'request-information:application:metal:automotive-components---others': 'Metal - Automotive Components - Others',
    'request-information:application:metal:non-automotive-components---others':
        'Metal - Non Automotive Components - Others',
    'request-information:application:metal:vanishing---automotive-components':
        'Metal - Vanishing - Automotive Components',
    'request-information:application:metal:vanishing---non-automotive-components':
        'Metal - Vanishing - Non Automotive Components',
    'request-information:application:metal:foundry---automotive-components': 'Metal - Foundry - Automotive Components',
    'request-information:application:metal:foundry---non-automotive-components':
        'Metal - Foundry - Non Automotive Components',
    'request-information:application:pulp-&-paper:tissue-manufacturers': 'Pulp & Paper - Tissue Manufacturers',
    'request-information:application:pulp-&-paper:board-manufacturers': 'Pulp & Paper - Board Manufacturers',
    'request-information:application:pulp-&-paper:packaging-&-converting': 'Pulp & Paper - Packaging & Converting',
    'request-information:application:pulp-&-paper:paper-manufacturers': 'Pulp & Paper - Paper Manufacturers',
    'request-information:application:pulp-&-paper:others': 'Pulp & Paper - Others',
    'request-information:application:spirit-industry:': 'Spirit Industry',
    'request-information:application:food:chocolate-&-confection:confectionery':
        'Food - Chocolate & Confection - Confectionery',
    'request-information:application:food:chocolate-&-confection:chocolate':
        'Food - Chocolate & Confection - Chocolate',
    'request-information:application:food:food-general:sugar-factories': 'Food - Food General - Sugar Factories',
    'request-information:application:food:beverages:breweries,-malting': 'Food - Beverages - Breweries, malting',
    'request-information:application:food:beverages:alcoholic-beverages---others':
        'Food - Beverages - Alcoholic Beverages - Others',
    'request-information:application:food:beverages:non-alcoholic-beverages':
        'Food - Beverages - Non Alcoholic Beverages',
    'request-information:application:food:beverages:instant-drinks': 'Food - Beverages - Instant drinks',
    'request-information:application:food:bakery-&-bread:bars-and-cereals': 'Food - Bakery & Bread - Bars and cereals',
    'request-information:application:food:bakery-&-bread:bakery-mix-blenders':
        'Food - Bakery & Bread - Bakery Mix Blenders',
    'request-information:application:food:bakery-&-bread:baked-goods': 'Food - Bakery & Bread - Baked goods',
    'request-information:application:food:food-general:flavour-producers': 'Food - Food General - Flavour Producers',
    'request-information:application:food:food-general:other-ingredient-producers':
        'Food - Food General - Other Ingredient Producers',
    'request-information:application:food:meat-and-poultry:blends': 'Food - Meat and poultry - Blends',
    'request-information:application:food:meat-and-poultry:production': 'Food - Meat and poultry - Production',
    'request-information:application:food:meat-and-poultry:fish-industry': 'Food - Meat and poultry - Fish Industry',
    'request-information:application:food:meat-and-poultry:slaughterhouses':
        'Food - Meat and poultry - Slaughterhouses',
    'request-information:application:food:dairy-&-ice-cream:ice-cream': 'Food - Dairy & Ice Cream - Ice Cream',
    'request-information:application:food:dairy-&-ice-cream:dairy-ingredients':
        'Food - Dairy & Ice Cream - Dairy Ingredients',
    'request-information:application:food:dairy-&-ice-cream:dairy-consumer-products':
        'Food - Dairy & Ice Cream - Dairy Consumer Products',
    'request-information:application:food:food-general:starch-industry': 'Food - Food General - Starch Industry',
    'request-information:application:food:food-general:food---distributors':
        'Food - Food General - Food - Distributors',
    'request-information:application:food:food-general:blenders---other': 'Food - Food General - Blenders - Other',
    'request-information:application:food:food-general:fats-&-oils': 'Food - Food General - Fats & Oils',
    'request-information:application:food:food-general:tobacco': 'Food - Food General - Tobacco',
    'request-information:application:food:food-general:potato-processing': 'Food - Food General - Potato Processing',
    'request-information:application:food:fruit-&-vegetable-processing:jams,-fruit-preparations':
        'Food - Fruit & Vegetable Processing - Jams, fruit preparations',
    'request-information:application:food:fruit-&-vegetable-processing:fruit-concentrates':
        'Food - Fruit & Vegetable Processing - Fruit concentrates',
    'request-information:application:food:fruit-&-vegetable-processing:others':
        'Food - Fruit & Vegetable Processing - Others',
    'request-information:application:food:function-food,-supplements:food-supplements':
        'Food - Function food, supplements - Food supplements',
    'request-information:application:food:function-food,-supplements:sport-nutrition':
        'Food - Function food, supplements - Sport nutrition',
    'request-information:application:food:function-food,-supplements:baby-food':
        'Food - Function food, supplements - Baby food',
    'request-information:application:food:function-food,-supplements:special-food':
        'Food - Function food, supplements - Special food',
    'request-information:application:food:convenience-food:savoury---others':
        'Food - Convenience Food - Savoury - Others',
    'request-information:application:food:convenience-food:soups,-sauces,-dressings':
        'Food - Convenience Food - Soups, Sauces, Dressings',
    'request-information:application:food:convenience-food:convenience-food':
        'Food - Convenience Food - Convenience Food',
    'request-information:application:food:others': 'Food - Others',
    'request-information:application:glass-industry:household-glass-&-glass-packaging':
        'Glass Industry - Household Glass & Glass Packaging',
    'request-information:application:glass-industry:industrial-&-technical-glass':
        'Glass Industry - Industrial & Technical Glass',
    'request-information:application:glass-industry:automotive-glass': 'Glass Industry - Automotive Glass',
    'request-information:application:glass-industry:others': 'Glass Industry - Others',
    'request-information:application:textile-&-leather:carpets-&-linings': 'Textile & Leather - Carpets & Linings',
    'request-information:application:textile-&-leather:knitted-fabrics': 'Textile & Leather - Knitted Fabrics',
    'request-information:application:textile-&-leather:automotive-leather-components':
        'Textile & Leather - Automotive Leather Components',
    'request-information:application:textile-&-leather:leather-tanning': 'Textile & Leather - Leather Tanning',
    'request-information:application:textile-&-leather:leather-clothing,-footwear,-goods':
        'Textile & Leather - Leather Clothing, Footwear, Goods',
    'request-information:application:textile-&-leather:yarn-&-threads': 'Textile & Leather - Yarn & Threads',
    'request-information:application:textile-&-leather:tights': 'Textile & Leather - Tights',
    'request-information:application:textile-&-leather:natural-textiles': 'Textile & Leather - Natural Textiles',
    'request-information:application:textile-&-leather:synthetic-textiles': 'Textile & Leather - Synthetic Textiles',
    'request-information:application:textile-&-leather:unwoven-fabrics': 'Textile & Leather - Unwoven Fabrics',
    'request-information:application:textile-&-leather:others': 'Textile & Leather - Others',
    'request-information:application:oil-&-gas:downstream---refineries,-pipelines':
        'Oil & Gas - DownStream - Refineries, Pipelines',
    'request-information:application:oil-&-gas:downstream---chemical-plants':
        'Oil & Gas - DownStream - Chemical Plants',
    'request-information:application:oil-&-gas:distribution': 'Oil & Gas - Distribution',
    'request-information:application:oil-&-gas:midstream---specialty-gases': 'Oil & Gas - MidStream - Specialty Gases',
    'request-information:application:oil-&-gas:midstream---compounders': 'Oil & Gas - MidStream - Compounders',
    'request-information:application:oil-&-gas:midstream---n-spec': 'Oil & Gas - MidStream - N-Spec',
    'request-information:application:oil-&-gas:midstream---production': 'Oil & Gas - MidStream - Production',
    'request-information:application:oil-&-gas:midstream---gasplants': 'Oil & Gas - MidStream - Gasplants',
    'request-information:application:oil-&-gas:upstream---drilling': 'Oil & Gas - UpStream - Drilling',
    'request-information:application:oil-&-gas:services': 'Oil & Gas - Services',
    'request-information:application:oil-&-gas:others': 'Oil & Gas - Others',
    'request-information:application:lubricants:asphalts': 'Lubricants - Asphalts',
    'request-information:application:lubricants:bio-diesel---producers': 'Lubricants - Bio Diesel - Producers',
    'request-information:application:lubricants:bio-ethanol---producers': 'Lubricants - Bio Ethanol - Producers',
    'request-information:application:lubricants:distribution': 'Lubricants - Distribution',
    'request-information:application:lubricants:blending-facilities': 'Lubricants - Blending Facilities',
    'request-information:application:lubricants:industrial-lubricants': 'Lubricants - Industrial Lubricants',
    'request-information:application:lubricants:automotive-lubricants': 'Lubricants - Automotive Lubricants',
    'request-information:application:lubricants:release-agents': 'Lubricants - Release Agents',
    'request-information:application:lubricants:others': 'Lubricants - Others',
    'request-information:application:transportation:airports': 'Transportation - Airports',
    'request-information:application:transportation:others': 'Transportation - Others',
    'request-information:application:tourism:': 'Tourism',
    'request-information:application:polymers:distributors': 'Polymers - Distributors',
    'request-information:application:polymers:polyurethanes:automotive-components':
        'Polymers - Polyurethanes - Automotive Components',
    'request-information:application:polymers:polyurethanes:flexible-&-rigid-polyurethanes-':
        'Polymers - Polyurethanes - Flexible & Rigid Polyurethanes ',
    'request-information:application:polymers:polyurethanes:polyurethane-systems':
        'Polymers - Polyurethanes - Polyurethane Systems',
    'request-information:application:polymers:polyurethanes:others': 'Polymers - Polyurethanes - Others',
    'request-information:application:polymers:compounders': 'Polymers - Compounders',
    'request-information:application:polymers:raw-material-producers': 'Polymers - Raw Material Producers',
    'request-information:application:polymers:injection-moulding:non-automotive-components':
        'Polymers - Injection Moulding - Non Automotive Components',
    'request-information:application:polymers:injection-moulding:automotive-components':
        'Polymers - Injection Moulding - Automotive Components',
    'request-information:application:polymers:regranulates': 'Polymers - Regranulates',
    'request-information:application:polymers:extrusion:films': 'Polymers - Extrusion - Films',
    'request-information:application:polymers:extrusion:automotive-components':
        'Polymers - Extrusion - Automotive Components',
    'request-information:application:polymers:extrusion:flooring,-coated-textiles':
        'Polymers - Extrusion - Flooring, Coated Textiles',
    'request-information:application:polymers:extrusion:plates-&-profiles': 'Polymers - Extrusion - Plates & Profiles',
    'request-information:application:polymers:extrusion:pipes': 'Polymers - Extrusion - Pipes',
    'request-information:application:polymers:extrusion:polymers-textiles-&-fibers':
        'Polymers - Extrusion - Polymers Textiles & Fibers',
    'request-information:application:polymers:extrusion:others': 'Polymers - Extrusion - Others',
    'request-information:application:polymers:chemosets': 'Polymers - Chemosets',
    'request-information:application:polymers:thermosets': 'Polymers - Thermosets',
    'request-information:application:polymers:others': 'Polymers - Others',
    'request-information:application:services:': 'Services',
    'request-information:application:water-treatment:swimming-pools': 'Water Treatment - Swimming Pools',
    'request-information:application:water-treatment:municipalities-for-potable-water':
        'Water Treatment - Municipalities for Potable Water',
    'request-information:application:water-treatment:municipalities-for-potable-and-waste-water':
        'Water Treatment - Municipalities for Potable and Waste Water',
    'request-information:application:water-treatment:distributors': 'Water Treatment - Distributors',
    'request-information:application:water-treatment:environmental-&-equipment-companies':
        'Water Treatment - Environmental & Equipment Companies',
    'request-information:application:water-treatment:municipalities-for-waste-water':
        'Water Treatment - Municipalities for Waste Water',
    'request-information:application:water-treatment:water-conditioning-service-companies':
        'Water Treatment - Water Conditioning Service Companies',
    'request-information:application:water-treatment:institutional-service-companies':
        'Water Treatment - Institutional Service Companies',
    'request-information:application:water-treatment:other-water-treatment-service-companies':
        'Water Treatment - Other Water Treatment Service Companies',
    'request-information:application:water-treatment:other-water-treatment-end-users':
        'Water Treatment - Other Water Treatment end users',
    'request-information:application:water-treatment:others': 'Water Treatment - Others',
    'request-information:application:animal-nutrition:distributors': 'Animal Nutrition - Distributors',
    'request-information:application:animal-nutrition:homemixers-&-farmers:cattle-and-sheep':
        'Animal Nutrition - Homemixers & Farmers - Cattle and Sheep',
    'request-information:application:animal-nutrition:homemixers-&-farmers:poultry':
        'Animal Nutrition - Homemixers & Farmers - Poultry',
    'request-information:application:animal-nutrition:homemixers-&-farmers:pigs':
        'Animal Nutrition - Homemixers & Farmers - Pigs',
    'request-information:application:animal-nutrition:homemixers-&-farmers:others':
        'Animal Nutrition - Homemixers & Farmers - Others',
    'request-information:application:animal-nutrition:additive-blenders': 'Animal Nutrition - Additive Blenders',
    'request-information:application:animal-nutrition:mixed-production': 'Animal Nutrition - Mixed Production',
    'request-information:application:animal-nutrition:aquafeed': 'Animal Nutrition - Aquafeed',
    'request-information:application:animal-nutrition:compound-feed': 'Animal Nutrition - Compound Feed',
    'request-information:application:animal-nutrition:premix': 'Animal Nutrition - Premix',
    'request-information:application:animal-nutrition:milk-replacers': 'Animal Nutrition - Milk Replacers',
    'request-information:application:animal-nutrition:integrators': 'Animal Nutrition - Integrators',
    'request-information:application:animal-nutrition:feed-ingredients': 'Animal Nutrition - Feed Ingredients',
    'request-information:application:animal-nutrition:animal-waste-processing':
        'Animal Nutrition - Animal Waste Processing',
    'request-information:application:animal-nutrition:veterinarians': 'Animal Nutrition - Veterinarians',
    'request-information:application:animal-nutrition:petfood': 'Animal Nutrition - Petfood',
    'request-information:application:animal-nutrition:others': 'Animal Nutrition - Others',
    'request-information:application:others:': 'Others',

    // languages
    'language:de': 'German',
    'language:en': 'English',
    'language:fr': 'French',
    'language:nl': 'Dutch',
    'language:pl': 'Polish',

    // countries
    'country:de': 'Germany',
    'country:en': 'England',
    'country:fr': 'France',
    'country:nl': 'The Netherlands',
    'country:be': 'Belgium',
    'country:za': 'South Africa',
    'country:ae': 'United Arab Emirates',
    'country:ke': 'Kenya',
    'country:tz': 'Tanzania',
    'country:ug': 'Uganda',
    'country:pl': 'Poland',
    'country:a1': 'Poland',

    // Onboarding
    'onboarding:skip-tour.skip': 'Skip tour',
    'onboarding:skip-tour.close': 'Close tour',
    'onboarding:slider-button.previous': 'Previous',
    'onboarding:slider-button.next': 'Next',
    'onboarding:slider-button.start': 'Start',
    'onboarding:slider-button.finish': 'Start Exploring',
    'onboarding:slide-sample.header': 'Brenntag Connect makes it possible to:',
    'onboarding:slide-sample.title': 'Request product information',
    'onboarding:slide-sample.content':
        'Request product information directly through Brenntag Connect. This includes documents, prices and more.',
    'onboarding:slide-order-status.header': 'Brenntag Connect makes it possible to:',
    'onboarding:slide-order-status.title': 'View Order Status & Past Orders',
    'onboarding:slide-order-status.content':
        'Track the status of your recently placed orders and access your order history for the past 24 months.',
    'onboarding:slide-introduction.title':
        'Welcome to Brenntag Connect – your customer portal to Brenntag’s products and services!',
    'onboarding:slide-introduction.content':
        'There is more to Brenntag Connect than meets the eye. <br/> <br/> Click "Start" for a quick tour!',
    'onboarding:slide-finish.title': 'We are here to help!',
    'onboarding:slide-finish.content':
        'If you have any questions about using Brenntag Connect, please contact our <a href="mailto:{csrEmail}"> Brenntag Connect support team</a>. You can also consult the <a href="{faqLink}" target="_blank">FAQs</a>. You\'re welcome!',

    // welome videos titles
    'welcome-video-title:WELCOME': 'Welcome',
    'welcome-video-title:FIRSTTIME': 'First time Login, Accounts & Preferences',
    'welcome-video-title:ORDERS': 'Orders',
    'welcome-video-title:INVOICES': 'Invoices',
    'welcome-video-title:PRODUCTS': 'Products',
    'welcome-video-title:REQUESTS': 'Requests',
    'welcome-video-title:FEEDBACK': 'Feedback',
    'welcome-video-title:QUOTES': 'Quotes',

    'welcome-video:headline': 'Do you want to know how to use Brenntag Connect?',
    'welcome-video:instruction': 'Watch our video with the Brenntag Connect demo.',

    // Terms of Use
    'terms-of-usage:headline': 'Terms of Use',
    'terms-of-usage:decline': 'Decline',
    'terms-of-usage:accept': 'I accept',
    'terms-of-usage:updated':
        'We’ve updated our terms of use. Please, accept these before continuing to Brenntag Connect. The Terms of Use is accessible at all times via a link in the application footer.',
    'terms-of-usage:content': `## Brenntag Connect - DigiB Customer Interface

### Terms of use
DigiB B.V., a limited liability company legally established under the laws of The Netherlands (hereinafter referred to as "Operator") is an entity of Brenntag Group, the global leader in chemical distribution, and operates the "Brenntag Connect"  online service and information platform (hereinafter referred to as "Portal") and offers registered users (natural persons, users of the same company need to register individually to secure assignment of an individual account to an individual natural person) various functionalities (where activated), such as (re-)ordering and requesting products via the Internet, reviewing the order history, browsing for new products, access to relevant product and order related documents. When using the Portal, the user shall at all times observe the principles set out in these terms of use and the statutory provisions.

### 1. Scope of application
1.1 The following terms of use apply exclusively for the use of the Portal. The Operator always reserves the right to change these terms of use with immediate effect for the future.

1.2 For all possible current and future e-commerce transactions via the Portal, the general terms and conditions of sale and delivery ("GTC Sales") of the selling Brenntag Group entity ("Seller") as provided in the Portal shall apply. Conflicting terms and conditions of the users or their customers to these terms of use or the GTC Sales shall not apply unless, as applicable, the Seller expressly agrees to their validity in writing (for the purpose of these terms, "in writing" shall include email and facsimile).

### 2. Registration
2.1 Where not actively invited as an existing customer, every user interested in using the services of the Portal shall send a corresponding registration request to the Operator. The user shall transmit all data requested by the Operator and subsequently shall ensure that such data is always kept up to date for the duration of his application and/or registration. Upon complete provision of the requested data, the Operator will decide in its sole discretion whether the user will be registered. The user will be informed by the Operator in writing about the status of the registration.

2.2 The Operator is entitled to refuse or subsequently revoke the registration without giving reasons. The Operator shall not be liable for any delay or denial of the registration whatsoever.

### 3. Password and obligations of the user
3.1 With the confirmation of the registration request, each user will have an individual username in the form of his registered e-mail address and an individual password. Access to the services of the Portal is only possible with the combination of these two credentials.

3.2 In the event of loss of the identifiers or justified suspicion that a third party undesirably has gained knowledge of the log in-identifiers, the user is obliged to immediately change the password and inform the Operator in writing.

3.3 The user is obliged to notify the Operator in writing of the departure of other employees or third parties (e.g. consultants) from the respective company, knowing the credentials mentioned under clause 3.1, without undue delay, however at the latest upon termination of the employment or other cooperation, in order to rule out any misuse of the user's credentials. Any noted actual or threatening misuse of the Portal shall be reported immediately to the Operator. In these cases, the Operator will immediately register new credentials or at least set a new password to the user, and inform the user thereof in writing.

3.4 Until notification of such termination or misuse, the user shall be responsible and be held liable for all declarations and content posted or any other abusive behavior on the Portal by an employee or third party subject to termination.

3.5 The user shall not post any content or contributions that a) in any way violate or imperil the rights of the Operator or any third parties (in particular name rights, personal rights, trademark rights, copyrights, ancillary copyrights, etc.), b) are illegal or c) contain viruses, circumvention devices or unsolicited mass mailings (spam). The user shall observe any existing intellectual property and other property rights of third parties, and may not enter into any illegal agreements, or show conduct or concerted practices that could give rise to violations of antitrust law.

3.6 The Operator is entitled to delete objectionable content posted, and to exclude the user from further use of the Portal, especially if immoral, illegal or business-damaging content is posted.

3.7 The operator reserves the right to report violations under applicable law to the authorities of the respective country in which the user has his place of business. Furthermore, the operator reserves the right to claim damages and to exhaust possible other remedies available in the event of violations of the terms of use.

### 4. Request for order / placing of product orders
4.1 Based on the product list and the pricing information in the Portal, the user may send a request for order to or (re)order products from the Seller via the Portal (collectively: "Order"). In order to complete the Order process, the user needs to select the product, the order quantity, the place of delivery and the delivery date. Subsequently, the user needs to enter a purchase order (PO) reference number. By clicking the "Submit"-button in the shopping cart (checkout) area, the offer in the legal sense shall be complete and binding to the user.

4.2 Upon receipt of the Order, the Seller will examine the feasibility of the Order request, provide a notification of receipt to the user, and, as the case may be, provide (i)  an order confirmation, or (ii) a counteroffer accounting for possible amendments to quantities and/or delivery dates. In case of (i), all (re)orders placed by the user via the Portal shall require an acceptance/confirmation in writing (email suffices) of the Seller or an implied acceptance/ confirmation by executing the order, and in case of (ii)  all counteroffers shall require an acceptance/confirmation in writing (email suffices) of the user or an implied acceptance/confirmation by payment in order to form a binding delivery contract.

### 5. Deletion of the registration
Every user may request deletion of his/her registration at any time by sending a respective request to the Operator in writing. With the deletion of the registration (notification of successful deletion respectively), the right of participation of the user for the services offered by the Portal shall immediately cease. The Operator is entitled to delete an existing registration without giving reasons if – in the reasonable evaluation of the Operator – the user has given false information during registration or violates the terms of use or applicable law. A deletion of the registration is communicated to the respective user by the Operator in writing. In case of deletion of the registration, the operator immediately deletes all personal user data. The Operator shall not be liable for any deletion of registration profiles whatsoever.

### 6. Liability
6.1 Operators liability regarding the operation of the Portal shall be limited to gross negligence or willful intent of itself, its legal representatives or vicarious agents. The foregoing shall not apply in the event of liability arising from injury to life, body or health or in the event of a culpable violation of a duty which is material to the contract in a manner which jeopardizes the achievement of the purpose of the contract (cardinal obligation) or product liability or other cases of mandatory statutory liability. Except in the case of intent, gross negligence and damage resulting from injury to life, limb or health, liability is limited in amount to the typically foreseeable damage at the time of registration of the user, in no case exceeding EUR 100.000 per incidence and year, excluding in any case liability for indirect damage, collateral damage, consequential damage and mere financial losses as well as lost profit.

6.2 The user shall be liable for all activities that are carried out using his user account and/or data provided beyond the purpose of the Portal. User shall take all commercially reasonable steps to a) prevent illegitimate access to his  user account and/or data provided, and b) secure compliance with all contractual obligations towards the Operator and applicable statutory law of actions by itself, its legal representatives, vicarious agents or any other third party, and c) innocuousness of the data provided for the Portal with regard to cyber risks, such as but not limited to malware, virus attacks etc. Liability of the user shall be excluded where such misuse or infestation of the Portal has been caused by circumstances beyond user’s reasonable control.

### 7. Release from liability
The user shall indemnify and hold harmless the Operator from all claims asserted by other users or other third parties against the Operator due to the violation of their rights where such violation has been caused by any culpable acts or omissions described under clause 6.2. The user shall also bear the costs reasonably incurred by the Operator for the necessary legal defenses including all court and lawyer's fees to the statutory amount. The user is obliged to provide the Operator immediately, truthfully and completely with all information necessary for the evaluation of a claim and a defense in case of a claim by third parties.

### 8. Warranty
8.1 The Operator does not warrant and assumes no liability whatsoever that a) any commercial information in the Portal is complete and accurate (provided "as is"), and b) the Portal is free of technical defects, in particular i) the permanent and uninterrupted availability of the Portal and its contents, or ii) the complete and accurate display/reproduction of the contents posted by the user. The services of the Portal are offered by the Operator free of charge, without any legal obligations to maintain the operations. The Operator reserves the right at all times to a) cease the operation of the Portal completely or partially, temporarily or permanently at any time without prior notice, or b) to make the use of the Portal subject to fee contribution by the users.

8.2 The Operator does not adapt any third party content placed in the Portal as its own.

8.3 The Operator shall in no way be liable for damages incurred by a user and caused by reliance on the accuracy, completeness and innocuousness of any third party content placed in the Portal.

### 9. Use of data and data protection
For information regarding the category, scope, place and purpose of the collection, processing and use of the personal data required for the registration and conclusion of commercial transactions, please refer to our [Privacy Policy]({dataPrivacyLink}).

### 10. Closing provisions
10.1 All matters regarding use and the interpretation of the terms of use of the Portal, and any disputes arising in connection therewith or thereout of shall be governed by the laws of The Netherlands without its conflict-of-laws principles and excluding the United Nations Convention on Contracts for the International Sale of Goods (CISG).

10.2 Legal venue for all disputes shall be Amsterdam, The Netherlands. This also applies if the user has no place of general jurisdiction in The Netherlands or the EU, or his residency or habitual abode is unknown at the time the action is filed.

(Status: 2019-08-29)`,
}

export default messages
