import { UomMapperFunction } from '../types'

export const defaultUomMapper: UomMapperFunction = (uom: string) => {
    switch (uom) {
        case 'KGM':
            return 'kg'
        case 'LTR':
            return 'ltr'
        case 'PF':
            return 'PAL'
        case 'TNE':
            return 'TO'
        default:
            return uom
    }
}
