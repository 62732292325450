import React from 'react'
import styled from 'styled-components'

import { GQLLocation, GQLMaterial, GQLPrice } from '@bc/types'
import { Address, Text } from '@bc/ui/src'
import {
    FormatMessage,
    FormatNumber,
    FormatPackaging,
    useFormatMessage,
    useFormatNumber,
    useFormatPackaging,
    useFormatUom,
    useParseFloatNumber,
} from '@frontend/components/hooks'
import { PriceScaleConditionConfirmation } from '@frontend/components/price-scale'

import { ReorderFormValues } from './reorder-form.types'

interface Props {
    values: ReorderFormValues
    shipTo: GQLLocation | undefined
    material: GQLMaterial
    uom?: string
    file?: File
    prices: GQLPrice[] | undefined
}

interface FormValue {
    label: string
    value?: JSX.Element
}

const AddressWrapper = styled.div`
    margin-bottom: 15px;
`

const Summary = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 25px;
`

const Comment = styled(Text)`
    overflow: auto;
    max-height: 250px;
`

const AttachmentIcon = styled.img`
    margin-right: 5px;
`
export const ReorderFormSummary = ({ values, shipTo, material, uom = '', file, prices }: Props) => {
    const {
        customerPoReference,
        requestedDeliveryDate,
        pieces: piecesAsString,
        quantity: quantityAsString,
        comment,
        pickup,
    } = values

    const t: FormatMessage = useFormatMessage()
    const formatNumber: FormatNumber = useFormatNumber()
    const formatPackaging: FormatPackaging = useFormatPackaging()
    const formatUom = useFormatUom()
    const parseFloatNumber = useParseFloatNumber()

    const pieces = parseFloatNumber(piecesAsString)
    const quantity = parseFloatNumber(quantityAsString)

    const { isBulk, packagingQuantity } = material
    const formattedUom = formatUom(uom)
    const formattedPackagingUom = formatUom(packagingQuantity.uom)

    const list: FormValue[] = [
        {
            label: t('general:ship-to'),
            value: (
                <AddressWrapper>
                    {pickup ? (
                        <Text data-test-id="text-pickup">{t('request-information-modal:reorder:self-pickup')}</Text>
                    ) : (
                        <Address location={shipTo} />
                    )}
                </AddressWrapper>
            ),
        },
        {
            label: t('general:po-reference'),
            value: (
                <Text data-test-id="text-po-reference">
                    {customerPoReference}
                    {file && (
                        <p>
                            <AttachmentIcon src="/static/images/pdf-icon.png" />
                            {file.name}
                        </p>
                    )}
                </Text>
            ),
        },
        {
            label: t('request-information-modal:order-quantity'),
            value:
                pieces && pieces > 0 ? (
                    <>
                        <Text data-test-id="text-order-quantity">
                            {isBulk
                                ? formattedPackagingUom === formattedUom
                                    ? `${formatNumber(pieces, {
                                          maximumFractionDigits: 2,
                                      })} ${formattedPackagingUom}`
                                    : `${formatNumber(pieces, {
                                          maximumFractionDigits: 2,
                                      })} ${formattedPackagingUom}` +
                                      (quantity
                                          ? ` (${formatNumber(quantity, {
                                                maximumFractionDigits: 2,
                                            })} ${formattedUom})`
                                          : ``)
                                : `${formatNumber(pieces, {
                                      maximumFractionDigits: 2,
                                  })} ${formatPackaging(pieces, material?.packaging?.type)}` +
                                  (quantity
                                      ? ` (${formatNumber(quantity, {
                                            maximumFractionDigits: 2,
                                        })} ${formattedUom})`
                                      : ``)}
                        </Text>
                    </>
                ) : (
                    <Text>{'-'}</Text>
                ),
        },
        {
            label: pickup
                ? t('request-information-modal:requested-pickup-date')
                : t('request-information-modal:requested-delivery-date'),
            value: <Text data-test-id="text-delivery-date">{requestedDeliveryDate}</Text>,
        },
        {
            label: t('general:price'),
            value: <PriceScaleConditionConfirmation prices={prices} />,
        },
        {
            label: t('request-information-modal:comment'),
            value: <Comment data-test-id="text-comment">{comment ? comment : '-'}</Comment>,
        },
    ]

    return (
        <Summary>
            {list.map(item => (
                <React.Fragment key={item.label}>
                    {item.value && (
                        <Text caption noMargin>
                            {item.label}
                        </Text>
                    )}
                    {item.value}
                </React.Fragment>
            ))}
        </Summary>
    )
}
