import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Film: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Film" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M21 5V3H3V5H5V7H3V9H5V11H3V13H5V15H3V17H5V19H3V21H21V19H19V17H21V15H19V13H21V11H19V9H21V7H19V5H21ZM17 19H7V13H17V19ZM17 11H7V5H17V11Z"
                fill={fill === 'gradient' ? 'url(#Film_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Film" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Film" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M21 5V3H3V5H5V7H3V9H5V11H3V13H5V15H3V17H5V19H3V21H21V19H19V17H21V15H19V13H21V11H19V9H21V7H19V5H21ZM17 19H7V13H17V19ZM17 11H7V5H17V11Z"
                fill={fill === 'gradient' ? 'url(#Film_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Film" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Film" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M21 5V3H3V5H5V7H3V9H5V11H3V13H5V15H3V17H5V19H3V21H21V19H19V17H21V15H19V13H21V11H19V9H21V7H19V5H21ZM17 19H7V13H17V19ZM17 11H7V5H17V11Z"
                fill={fill === 'gradient' ? 'url(#Film_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Film" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Film" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M21 5V3H3V5H5V7H3V9H5V11H3V13H5V15H3V17H5V19H3V21H21V19H19V17H21V15H19V13H21V11H19V9H21V7H19V5H21ZM17 19H7V13H17V19ZM17 11H7V5H17V11Z"
                fill={fill === 'gradient' ? 'url(#Film_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Film" />}
        </g>
    ),
}
