import React from 'react'

import { Text } from '@bc/ui/src/components/text'

import * as CardComponents from './card-components'

interface Props {
    title: string | JSX.Element
    subtitle?: string | JSX.Element
    icon?: JSX.Element
}

export const CardTitle = ({ title, subtitle, icon }: Props) => (
    <>
        {icon && <CardComponents.Icon>{icon}</CardComponents.Icon>}
        <CardComponents.Title>
            <Text h5 noMargin data-test-class="text-card-title">
                {title}
            </Text>
            {subtitle && (
                <Text caption color="neutral50" noMargin data-test-class="text-card-subtitle">
                    {subtitle}
                </Text>
            )}
        </CardComponents.Title>
    </>
)
