import React from 'react'

import { colors } from '@bc/ui/src/config'

import { SvgIconMap } from '../types'

const defaultFill = colors.neutral50

export const status_quote: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-cart"
            d="M22,6l-0.008,2.124L20.883,17H5.074l-1-13H2V2h3.926l1,13h12.191l0.875-7H8.39L8.24,6H22z M9,19
            c-0.552,0-1,0.448-1,1s0.448,1,1,1s1-0.448,1-1S9.552,19,9,19z M17,19c-0.552,0-1,0.448-1,1s0.448,1,1,1s1-0.448,1-1
            S17.552,19,17,19z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-cart"
            d="M22,6l-0.008,2.124L20.883,17H5.074l-1-13H2V2h3.926l1,13h12.191l0.875-7H8.39L8.24,6H22z M9,19
            c-0.552,0-1,0.448-1,1s0.448,1,1,1s1-0.448,1-1S9.552,19,9,19z M17,19c-0.552,0-1,0.448-1,1s0.448,1,1,1s1-0.448,1-1
            S17.552,19,17,19z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-cart"
            d="M22,6l-0.008,2.124L20.883,17H5.074l-1-13H2V2h3.926l1,13h12.191l0.875-7H8.39L8.24,6H22z M9,19
            c-0.552,0-1,0.448-1,1s0.448,1,1,1s1-0.448,1-1S9.552,19,9,19z M17,19c-0.552,0-1,0.448-1,1s0.448,1,1,1s1-0.448,1-1
            S17.552,19,17,19z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-cart"
            d="M22,6l-0.008,2.124L20.883,17H5.074l-1-13H2V2h3.926l1,13h12.191l0.875-7H8.39L8.24,6H22z M9,19
            c-0.552,0-1,0.448-1,1s0.448,1,1,1s1-0.448,1-1S9.552,19,9,19z M17,19c-0.552,0-1,0.448-1,1s0.448,1,1,1s1-0.448,1-1
            S17.552,19,17,19z"
            transform="scale(0.5)"
        />
    ),
}
