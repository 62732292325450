// tslint:disable no-invalid-template-strings

import { MessageMap } from '../../'

const messages: MessageMap = {
    'gender:male': 'Männlich',
    'gender:female': 'Weiblich',
    'gender:unknown': 'Unbekannt',

    // Routes
    'route:maintenance': '/maintenance',
    'route:document': '/document',
    'route:document-not-found': '/document-not-found',

    'route:session-expired': '/session-expired',

    'route:logout': '/logout',
    'route:logout.label': 'Abmelden',

    'route:reset-password': '/reset-password',
    'route:reset-password.label': 'Passwort zurücksetzen',

    'route:set-password': '/set-password',
    'route:set-password.label': 'Passwort setzen',

    'route:forgot-password': '/forgot-password',

    'route:terms-of-usage': '/terms-of-usage',
    'route:terms-of-usage.label': 'AGB',

    'route:product-list': '/product-list',
    'route:product-list.label': 'Produkte',

    'route:order-history': '/my-orders',
    'route:order-history.label': 'Bestellungen',

    'route:invoices': '/invoices',
    'route:invoices.label': 'Rechnungen',

    'route:cart-overview': '/checkout',
    'route:cart-overview.label': 'Zur Kasse gehen',

    'route:imprint.label': 'Impressum',
    'route:data-privacy-policy.label': 'Datenschutzerklärung',
    'route:faq.label': 'FAQ',

    'route:my-customers': '/my-customers',
    'route:my-customers.label': 'Meine Firmen',

    'route:contacts': '/contacts',
    'route:contacts.label': 'Nutzerübersicht',

    'route:quotes': '/quotes',
    'route:quotes.label': 'Angebote',

    'route:requests': '/requests',
    'route:requests.label': 'Anfragen',

    'route:account': '/account',

    // Salutations
    'salutation.formal.male': 'Sehr geehrter Herr {name}',
    'salutation.formal.female': 'Sehr geehrte Frau {name}',
    'salutation.formal.unknown': 'Sehr geehrter Kunde',
    'salutation.informal.male': 'Sehr geehrter Kunde',
    'salutation.informal.female': 'Sehr geehrter Kunde',
    'salutation.informal.unknown': 'Sehr geehrter Kunde',
    'salutation.internal.unknown': 'Liebe Kollegin / Lieber Kollege',

    // Email subjects
    'email:subject:ORDER_CONFIRM': 'Brenntag Connect | Bestellnummer {orderID} Neue Brenntag Connect Bestellung',
    'email:subject:RFI_SALES_PERSON': 'Brenntag Connect | Informationsanfrage {uid} von {name}',
    'email:subject:RFI_CUSTOMER': 'Brenntag Connect | Informationsanfrage {uid}',
    'email:subject:RFI_COMPLETE': 'Brenntag Connect | Antwort auf ihre Anfrage {uid}',
    'email:subject:RQ_CUSTOMER': 'Brenntag Connect | Erneute Bestellung {uid}',
    'email:subject:RQ_SALES_PERSON': 'Brenntag Connect | Erneute Bestellung {uid} von {name}',
    'email:subject:ORDER_WO_PRICE': 'Brenntag Connect | Bestellung ohne gültigen Preis',
    'email:subject:RESET_PW': 'Brenntag Connect | Anfrage zum Festlegen Ihres Passworts',
    'email:subject:WELCOME': 'Brenntag Connect | Herzlich Willkommen bei Brenntag Connect',
    'email:subject:SC_CUSTOMER': 'Brenntag Connect | Bestellung {uid}',
    'email:subject:SC_SALES_PERSON': 'Brenntag Connect | Bestellung {uid} von {name}',

    // Cookiebar
    'cookiebar:text1':
        'Zur Optimierung der Benutzerfreundlichkeit verwendet diese Seite Cookies. Klicken Sie auf "Einverstanden", wenn Sie unseren Bestimmungen zur Verwendung von Cookies zustimmen.',
    'cookiebar:text2': 'Weitere Hinweise erhalten Sie in unserer ',
    'cookiebar:accept-button': 'Einverstanden',

    // FAQ
    'faq:contact-title': 'Wie können wir Ihnen helfen?',
    'faq:contact-text':
        'Wir möchten, dass Sie Brenntag Connect bestmöglich für sich nutzen können. Falls Sie Unterstützung benötigen oder Fragen haben, können Sie uns unter der Rufnummer +49 201 6496 1999 erreichen. ',
    'faq:browse-categories': 'Kategorien ansehen',

    // Login page
    'login:header': 'Anmelden',
    'login:welcome-header': 'Willkommen bei Brenntag Connect!',
    'login:intro':
        'Brenntag Connect ist Ihre Online-Plattform für die einfache Suche und  Nachbestellung von Chemikalien und Inhaltsstoffen.',
    'login:intro-benefit': 'Melden Sie sich jetzt an und profitieren Sie von unserem Online-Angebot:',
    'login:intro-bullet-1': 'Unterstützung bei Ihrer Suche nach Produktinformation und Dokumenten',
    'login:intro-bullet-2': 'Zugriff auf tagesaktuelle Verkaufspreise (je nach Verfügbarkeit)',
    'login:intro-bullet-3': 'Einfache und schnelle Nachbestellung Ihrer Produkte',
    'login:intro-bullet-4': 'Übersicht Ihrer Bestellungen und Rechnungen der letzten 24 Monate',
    'login:help-title': 'Sie benötigen weitere Hilfe?',
    'login:help-instruction': 'Bitte kontaktieren Sie unseren Kundenservice. Wir helfen Ihnen gerne weiter.',
    'login:help-phone-number': 'Telefonnummer:',
    'login:help-office-hours': ' Unsere Bürozeiten:',
    'login:contact-more': 'Senden Sie uns Ihre Anfrage zu:',
    'login:contact-button': 'Kontaktieren Sie uns',
    'login:copyright': 'Alle Rechte vorbehalten.',
    'login:brenntag': '© Brenntag SE',

    'login:username': 'E-Mail-Adresse',
    'login:username-placeholder': 'ihre@email.com',
    'login:password': 'Passwort',
    'login:password-placeholder': 'Geben Sie Ihr Passwort ein',
    'login:button.forgot-password': 'Passwort vergessen?',
    'forgot-password-form:title': 'Passwort vergessen?',
    'login:back-to-login': 'Direkt zum Login',

    // Login page with MFA
    'login-mfa:title': 'Bitte bestätigen Sie Ihre Identität.',
    'login-mfa:intro':
        'Wir haben Ihnen einen Bestätigungscode per E-Mail zugeschickt. Geben Sie den Code aus der E-Mail ein und klicken Sie "Bestätigen".',
    'login-mfa-form:input': 'Geben Sie den 6-stelligen Code ein.',
    'login-mfa-form:submit': 'Bestätigen',

    // Login form
    'login-form:error': 'Die Anmeldung ist leider fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.',
    'login-form:error.access_denied':
        'Die Anmeldung ist leider fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.',
    'login-form:error.too_many_attempts': 'Der Zugriff wurde aufgrund zu vieler Fehlversuche gesperrt.',

    // Reset password page
    'reset-password:title': 'Bitte legen Sie ein Passwort fest.',
    'reset-password:body':
        'Bitte überprüfen Sie Ihren Posteingang. Wir haben Ihnen eine E-Mail geschickt, um Ihr Passwort festzulegen.',

    // Set password token error
    'warning:invalid-token:title': 'Entschuldigen Sie bitte!',
    'warning:invalid-token:description': 'Dieser Code ist abgelaufen! Bitte setzen Sie Ihr Passwort erneut zurück.',

    // Auth error page
    'auth-error:message': 'Die Anmeldung ist fehlgeschlagen. Bitte versuchen Sie es erneut.',
    'auth-error:expired-link:title': 'Aktivierungslink abgelaufen',
    'auth-error:expired-link:message':
        'Ihr Aktivierungslink ist abgelaufen. Bitte fordern Sie einen neuen Link an, indem Sie Ihr Passwort zurücksetzen.',
    'auth-error:link-text': 'Passwort zurücksetzen',
    'auth0-error:passworddictionaryerror': 'Das Passwort ist zu schwach.',
    'auth0-error:passwordstrengtherror': 'Das Passwort ist zu schwach.',
    'auth0-error:passwordhistoryerror': 'Dieses Passwort wurde bereits zuvor genutzt.',
    'auth0-error:passwordnouserinfoerror': 'Das Passwort beinhaltet Nutzer-Informationen.',
    'auth0-error:csrfinvalidtokenerror': 'Dieser Code ist abgelaufen! Bitte setzen Sie Ihr Passwort erneut zurück.',

    // Rate limit error
    'rate-limit-error:message': 'Zu viele Fehlversuche. Bitte probieren Sie es zu einem späteren Zeitpunkt erneut.',

    // Forgot password form
    'forgot-password-form:description':
        'Wenn Sie bereits bei uns registriert sind, erhalten Sie eine Nachricht an die von Ihnen angegebene E-Mail-Adresse, um Ihr Passwort zurückzusetzen.',
    'forgot-password-form:email': 'E-Mail-Adresse',
    'forgot-password-form:submit': 'E-Mail verschicken',
    'forgot-password-form:success': 'Wir haben Ihnen eine E-Mail zum zurücksetzen Ihres Passwortes zugesendet.',

    // Set password form
    'set-password-form:title': 'Passwort festlegen',
    'set-password-form:description': 'Bitte geben Sie Ihr neues Passwort ein.',
    'set-password-form:new-password': 'Neues Passwort',
    'set-password-form:new-password-confirmation': 'Neues Passwort wiederholen',
    'set-password-form:password-instruction':
        'Bitte legen Sie ein neues Passwort fest. Das neue Passwort wird für die nächsten 180 Tage gültig sein.',
    'set-password-form:success-title': 'Erfolg!',
    'set-password-form:success':
        'Lieber Kunde, \nim Rahmen unseres Bestrebens, Ihnen einen sicheren Zugang zu bieten, haben wir die 2-Faktor-Authentifizierung (2FA) eingeführt. Daher haben wir Sie automatisch abgemeldet, nachdem Sie Ihr Initial-Passwort festgelegt haben. \nBitte gehen Sie zur LogIn-Seite, geben Sie dieses Passwort ein und bestätigen Sie es mit dem Authentifizierungscode, den Sie in Ihrem E-Mail-Posteingang erhalten, um auf Brenntag Connect zuzugreifen.',

    // My companies
    'my-customers:city': 'Stadt',
    'my-customers:customer-id': 'Kundennummer',
    'my-customers:customer-name': 'Firma',
    'my-customers:customer-vat-number': 'Umsatzsteuer ID',
    'my-customers:headline': 'Meine Firmen',
    'my-customers:last-order-date': 'Letztes Bestelldatum',
    'my-customers:products': 'Produkte',
    'my-customers:outstanding-orders': 'Ausstehende Bestellungen',
    'my-customers:items-in-cart': 'Artikel im Warenkorb',
    'my-customers:search-for-customer': 'Suche nach Firma',
    'my-customers:select-user': 'Auswählen',
    'my-customers:use-search-options': 'Verwenden Sie eine der Suchoptionen',
    'my-customers:search-error': 'Bei der Kundensuche ist ein Fehler aufgetreten.',
    'my-customers:division': 'Abteilung',

    // Requests Page
    'requests:title-csr': 'Kundenanfragen',
    'requests:title-customer': 'Meine Anfragen',
    'requests:application': 'Anmeldung',
    'requests:csr-subtitle-one': 'Möchten Sie uns kontaktieren? Senden Sie eine E-Mail an',
    'requests:csr-subtitle-two': 'E-mailen Sie uns auf',
    'requests:delivery-date': 'Lieferdatum',
    'requests:documents': 'Dokumente',
    'requests:documents-max-size': '(Max {maxSize}MB)',
    'requests:email': 'Benutzer-E-Mail',
    'requests:messages': 'Messages',
    'requests:po-reference': 'Kundenreferenz',
    'requests:quantity-annual': 'Jährlicher Bedarf',
    'requests:quantity-call-off': 'Liefermenge',
    'requests:quantity-sample': 'Menge Muster',
    'requests:requested': 'Angefordert für',
    'requests:requested-documents': 'Erforderliche Dokumente',
    'requests:ship-to-address': 'Lieferadresse',
    'requests:status': 'Status',
    'requests:button.edit-complete': 'Antwort bearbeiten',
    'requests:button.edit': 'Antworten',
    'requests:edit-modal-title': 'Anfrage bearbeiten',
    'requests:edit-modal-summary': 'Bestellzusammenfassung',
    'requests:comments': 'Anmerkungen',
    'requests:file-too-big': 'Datei {name} ist zu groß. ',
    'requests:max-upload': 'Maximale Upload-Größe {mb} überschritten. Die Datei {name} wurde nicht hinzugefügt.',
    'requests:mime-types': 'Dateiformat {name} unzulässig.',
    'requests:customer': 'Kunde',
    'requests:recipient': 'Recipient',
    'requests:details': 'Details',
    'requests:user-comment': 'User Comment',
    'requests:response': 'Our team will respond to your request as soon as possible!',
    'requests:questions': 'Still haven’t received what you requested? Contact us:',

    // Request enum
    'request-status:COMPLETED': 'Erledigt',
    'request-status:PENDING': 'Unerledigt',
    'request-type:DOCUMENT': 'Dokumente',
    'request-type:PRICE': 'Preis',
    'request-type:PROFILE': 'Konto',
    'request-type:QUOTE': 'Angebot',
    'request-type:SAMPLE': 'Muster',
    'request-type:CART': 'Bestellung',

    // User management page
    'user:country': 'Land',
    'user:role': 'Role',
    'user:customer-id': 'Kundennummer',
    'user:last-login': 'Letztes Login',
    'user:send-emails': 'E-Mails senden',
    'user:email-welcome': 'Willkommens-E-Mail erneut senden',
    'user:email-welcome-to-support': 'Willkommens-E-Mail an DigiB Support senden',
    'user:email-password': 'Passwort-Reset-E-Mail erneut senden',
    'user:email-password-to-support': 'Passwort-Reset-E-Mail an DigiB Support senden',
    'user:admin-actions': 'Admin-Aktionen',
    'user:select-admin-action': 'Wählen Sie eine Aktion aus',
    'user:email-welcome-sent': 'Willkommens-E-Mail gesendet am',
    'user:edit-user': 'Bearbeiten',
    'user:create-new-user': 'Benutzer erstellen',
    'user:sort:username': 'Benutzername',
    'user:sort:logindate': 'Login Datum',
    'user:email-modal-welcome-title': 'Willkommens-E-Mail erneut senden',
    'user:email-modal-welcome-text':
        'Sind Sie sicher, dass Sie die Willkommens-E-Mail erneut an diesen Benutzer ({email}) senden möchten?',
    'user:email-modal-welcome-to-support-text':
        'Sind Sie sicher, dass Sie die Willkommens-E-Mail wirklich an DigiB Support senden möchten?',
    'user:email-modal-welcome-cancel': 'Abbrechen',
    'user:email-modal-welcome-confirm': 'E-Mail senden',
    'user:email-modal-pwreset-title': 'E-Mail zum Zurücksetzen des Passwortes senden',
    'user:email-modal-pwreset-text':
        'Sind Sie sicher, dass Sie diesem Benutzer ({email}) eine E-Mail senden möchten, damit er sein Passwort neu festlegen kann?',
    'user:email-modal-pwreset-to-support-text':
        'Sind Sie sicher, dass Sie die E-Mail zum Zurücksetzen des Kennworts wirklich an DigiB Support senden möchten?',
    'user:email-modal-pwreset-cancel': 'Abbrechen',
    'user:email-modal-pwreset-confirm': 'E-Mail senden',
    'user:edit-user-modal-title': 'Benutzer bearbeiten',
    'user:create-user-modal-title': 'Neuer Benutzer',
    'user:limited-search-results':
        'Darstellung {number} der {count} Ergebnisse. Bitte benutzen Sie die Filter auf der linken Seite, um bestimmte Benutzer zu finden.',

    // Form labels & placeholders user management
    'user:form:label-email': 'E-Mail Adresse',
    'user:form:label-email-placeholder': 'E-Mail Adresse eingeben',
    'user:form:label-firstname': 'Vorname',
    'user:form:label-firstname-placeholder': 'Vorname eingeben',
    'user:form:label-lastname': 'Nachname',
    'user:form:label-lastname-placeholder': 'Nachname eingeben',
    'user:form:label-customerids': 'Kundennummer',
    'user:form:label-customerids-placeholder': 'Kundennummer eingeben',
    'user:form:label-language': 'Sprache',
    'user:form:label-gender': 'Geschlecht',
    'user:form:label-country': 'Land',
    'user:form:label-customer-id-and-sales-org': 'Kundennummer und Verkaufsorganisation',
    'user:form:label-customer-grant-access': 'Gewähren Sie einem oder mehreren Kunden Zugriff:',
    'user:form:error-customer-grant-access': 'Gewähren Sie einem oder mehreren Kunden Zugriff.',
    'user:form:error-select-one-sales-org': 'Fügen Sie mindestens eine Verkaufsorganisation hinzu.',
    'user:form:error-search': 'Es ist ein Fehler aufgetreten. Versuchen Sie es mit einer neuen Suche.',
    'user:form:search-no-results':
        'Es wurden keine Ergebnisse gefunden. Denken Sie daran, dass Sie nach Name, Kundennummer oder Umsatzsteuer-Identifikationsnummer suchen können.',
    'user:form:usertype-viewer': 'Dieser Benutzer kann nur Dokumente herunterladen.',
    'user:form:label-usertype': 'Benutzerberechtigungen',
    'user:form:label-mfa': 'Multi-Factor Authentication (MFA)',
    'user:form:label-enablemfa': 'MFA aktivieren',
    'user:form:label-placeholder': 'Bitte wählen Sie eine Benutzerberechtigung',

    // Customer Selection Dropdown
    'company:selection:label-bill-to': 'Rechnungsadresse:',
    'company:selection:label-country': 'Land:',
    'company:selection:change': 'Change',
    'company:selection:label-customer-id': 'Kundennummer:',
    'company:selection:label-vat': 'Umsatzsteuer ID:',
    'company:selection:label-name': 'Name:',
    'company:selection:title': 'Please select a company',
    'company:selection:intro.one': 'In order to proceed and view relevant information',
    'company:selection:intro.two': 'We request you to select the company name in the table below.',

    // Products
    'products:column.material': 'Produkt',
    'products:card.material': 'Produktname',
    'products:column.cas-no': 'CAS-Nummer',
    'products:column.filling-weight': 'Füllgewicht',
    'products:column.supplier': 'Lieferant',
    'products:column.grade': 'Grädigkeit',
    'products:column.detail-packaging': 'Verpackungsdetails',
    'products:column.einecs': 'Einecs',
    'products:column.unit-capacity': 'Verpackungsvolumen',

    'products:more-details-button-text': 'Mehr Details',
    'products:blend': '...',
    'products:column.material-number': 'Produktnummer',

    // Product list
    'product-list:headline': '{count} Produkte',
    'product-list:empty-cart': 'Produktliste',
    'product-list:request-info': 'Info',

    // Product modal
    'product-modal:scale-prices': 'Preis',

    // Contact person
    'contact-person:title': 'Haben Sie Fragen zu dieser Bestellung?',
    'contact-person:subtitle': 'Bitte kontaktieren Sie unseren Kundenservice.',
    'contact-person:customer-service': 'Kundenservice',

    // Add to cart form
    'add-to-cart-form:success-toast': 'Das Produkt wurde erfolgreich in Ihren Warenkorb gelegt.',
    // Checkout
    'checkout:headline': 'Bestellübersicht',
    'checkout:products': 'Produkte',
    'checkout:empty-cart': 'Ihr Warenkorb ist leer.',
    'checkout:empty-cart-message': 'Beginnen Sie Ihren Einkauf, indem Sie Ihrer Bestellung Produkte hinzufügen.',
    'checkout:button-continue-shopping': 'Einkauf fortsetzen',

    'checkout-success:order-submitted': 'Ihre Bestellung wurde gesendet!',
    'checkout-success:final-instruction':
        'Sie werden eine E-Mail von uns erhalten, sobald Ihre Bestellung geprüft wurde. <br/> Nutzen Sie Brenntag Connect, um geplante Versanddaten einzusehen sowie Rechnungen und Sicherheitsdatenblätter herunterzuladen. <br/> <br/> Bei Fragen zu dieser Bestellung senden Sie bitte eine E-Mail an <a href="mailto:{email}">{email}</a>.',
    'checkout-success:order-number': 'Anfragenummer',
    'checkout-success:order-date': 'Anfrage-Datum',
    'checkout-success:button-view-requests': 'Anfragen anzeigen',
    'checkout-success:button-view-orders': 'Bestellungen anzeigen',

    // Delivery overview
    'delivery-overview:title': 'Lieferübersicht',
    'delivery-overview:billing-address': 'Rechnungsadresse',
    'delivery-overview:comment': 'Kommentare',
    'delivery-overview:delivery-date': 'Gewünschtes Lieferdatum',
    'delivery-overview:legal-terms': ' von Brenntag GmbH gelten für diese Bestellung.',
    'delivery-overview:legal-terms-name': 'unsere Allgemeinen Verkaufs- und Lieferbedingungen',
    'delivery-overview:legal-terms-price': 'Preis ohne Mehrwertsteuer und Zuschläge',
    'delivery-overview:payment-terms': 'Zahlungsbedingungen',
    'delivery-overview:no-payment-terms': 'Es wurden keine Zahlungsbedingungen ausgewählt.',
    'delivery-overview:order-id': 'Auftragsnummer',
    'delivery-overview:po-reference': 'Kundenreferenz',
    'delivery-overview:same-as-delivery-address': 'Identisch mit der Lieferadresse',
    'delivery-overview:agree-with-terms': 'Mit Ihrer Bestellung akzeptieren Sie',
    'delivery-overview:submit': 'Bestellung aufgeben',
    'delivery-overview:terms-of-delivery': 'Lieferbedingungen',
    'delivery-overview:INCO-terms': 'Incoterms',
    'delivery-overview:status': 'Status',
    'delivery-overview:packaging': 'Verpackung',
    'delivery-overview:agree-with-terms-warning': 'Bitte akzeptieren Sie unsere allgemeinen Verkaufsbedingungen.',
    'delivery-overview:INCO-terms-as-agreed': 'Wie vereinbart',
    'delivery-overview:payment-terms-as-agreed': 'Wie vereinbart',

    // Invoices
    'invoices:date-invoice': 'Rechnungsdatum',
    'invoices:date-due': 'Fälligkeitsdatum',
    'invoices:download': 'Download Rechnung',
    'invoices:title': 'Rechnung: {id}',
    'invoices:total-amount': 'Rechnungsbetrag (inkl. MwSt)',

    // Order
    'order:line-item.material-no': 'Produktnummer',
    'order:line-item.pieces': 'Stück',
    'order:line-item.amount': 'Menge',
    'order:line-item.requested': 'Angefordert für',
    'order:line-item.confirmed': 'Bestätigt für',
    'order:line-item.delivered': 'Geliefert am',
    'order:line-item.order-quantity': 'Bestellmenge',
    'order:line-item.batch': 'Chargennummer',
    'order:line-item.total-material-price': 'Gesamtproduktpreis',
    'order:line-item.request-price': 'Preis auf Anfrage',
    'order:line-item.price': 'Preis',
    'order:next-steps.title': 'Nächste',
    'order:status.pending': 'Unbestätigt',
    'order:status.delivered': 'Zugestellt',
    'order:status.indelivery': 'Auf dem Versandweg',
    'order:status.confirmed': 'Bestätigt',
    'order:status.invoiced': 'Fakturiert',
    'order:status.cancelled': 'Storniert',
    'order:status.processed': 'In Bearbeitung',
    'order:status.loading': 'Laden',
    'order:status.shipped': 'Gesendet',

    // Request types
    'order:status.document': 'Dokumente',
    'order:status.price': 'Preis',
    'order:status.profile': 'Konto',
    'order:status.quote': 'Bestellung',
    'order:status.sample': 'Muster',

    'orderline:pieces': 'Stück',

    // Orders
    'orders:column.status': 'Status',
    'orders:column.sales-number': 'Brenntag Auftragsnummer',
    'orders:column.po-reference': 'Kundenreferenz',
    'orders:column.order-date': 'Auftragsdatum',
    'orders:column.delivery-address': 'Lieferadresse',
    'orders:column.materials-amount': 'Produktmenge',
    'orders:column.delivery-date': 'Lieferdatum',
    'orders:column.delivery-date-from-to': 'Lieferdatum zwischen',
    'orders:column.requested-delivery-date': 'Gewünschtes Lieferdatum',
    'orders:column.po-reference-abbreviation': 'Kundenreferenz',
    'orders:button.confirmation': 'Auftragsbestätigung',

    // Quotes
    'quotes:column.offer-date': 'Angebotsdatum',
    'quotes:column.delivery-address': 'Lieferadresse',

    // Material
    'general:material-id': 'Produktnummer',
    'general:packaging': 'Verpackung',
    'general:pallet': 'Palette',
    'general:price': 'Preis',
    'general:unit-price': 'Unit Price',
    'general:price-confirmation': 'Preis auf Anfrage',
    'general:pieces': 'Stück',
    'general:pieces-plural': '{count, plural, one {Stück} other {Stücke}}',
    'general:pieces-long': 'Anzahl in Stück',
    'general:amount': 'Bestellmenge',

    // General
    'general:filters': 'Filter nach',
    'general:login': 'Anmelden',
    'general:cancel': 'Abbrechen',
    'general:save': 'Speichern',
    'general:save-and-send': 'Speichern & Senden',
    'general:send': 'Senden',
    'general:delete': 'Löschen',
    'general:date-format.day': 'T',
    'general:date-format.month': 'M',
    'general:date-format.year': 'J',
    'general:error': 'Etwas ist schief gelaufen. Bitte aktualisieren Sie die Seite.',
    'general:close': 'Schließen',
    'general:error.label': 'Fehler:',
    'general:send-us-email': 'Unterstützung anfordern',
    'general:type-here': 'Bitte hier eintragen',
    'general:back-to-overview': 'Zurück zur Übersicht',
    'general:search': 'Suche',
    'general:delivery-address': 'Lieferadresse',
    'general:incoterm': 'Lieferbedingungen',
    'general:order': 'Bestellung',
    'general:order-id': 'Brenntag Auftragsnummer',
    'general:quote': 'Bestellung',
    'general:offer-id': 'Brenntag Angebotsnummer',
    'general:upload': 'Dateien auswählen',
    'general:documents': 'Dokumente',
    'general:select-ship-to': 'Lieferadresse auswählen',
    'general:ship-to': 'Lieferadresse',
    'general:po-reference': 'Kundenreferenz',
    'general:sorry': 'Bitte entschuldigen Sie!',
    'general:something-went-wrong': 'Dieser Vorgang ist fehlgeschlagen',
    'general:save-changes': 'Änderungen speichern',
    'general:order-amount': 'Menge',
    'general:order-quantity': 'Bestellmenge',
    'general:required-fields': '* notwendige Eingabe.',
    'general:file-attached': 'Anhang',
    'general:file-attached-plural': 'Angehängte Dokumente',
    'general:attach-document': 'Dokument einfügen',
    'general:attach-document-plural': 'Ein oder mehr Dokumenten einfügen',
    'general:price-conversion': 'Währungsumrechnung',
    'general:id': 'ID',
    'general:quantity-below-minimum': 'Die angeforderte Menge liegt unter der Mindestbestellmenge.',
    'general:po-reference-info':
        'Dies ist ein Pflichtfeld. Bitte tragen Sie hier Ihre gewünschte Referenz oder Bestellnummer ein. Ihre Angabe wird bei der weiteren Bestellabwicklung übernommen und dient der Zuordnung zu unserer Auftragsnummer.',

    // react-date-picker
    'react-day-picker:first-day-of-week': '1',
    'react-day-picker:months.january': 'Januar',
    'react-day-picker:months.february': 'Februar',
    'react-day-picker:months.march': 'März',
    'react-day-picker:months.april': 'April',
    'react-day-picker:months.may': 'Mai',
    'react-day-picker:months.june': 'Juni',
    'react-day-picker:months.july': 'Juli',
    'react-day-picker:months.august': 'August',
    'react-day-picker:months.september': 'September',
    'react-day-picker:months.october': 'Oktober',
    'react-day-picker:months.november': 'November',
    'react-day-picker:months.december': 'Dezember',
    'react-day-picker:next-month': 'Nüchster Monat',
    'react-day-picker:previous-month': 'Letzter Monat',
    'react-day-picker:weekdays-short.monday': 'M',
    'react-day-picker:weekdays-short.tuesday': 'D',
    'react-day-picker:weekdays-short.wednesday': 'M',
    'react-day-picker:weekdays-short.thursday': 'D',
    'react-day-picker:weekdays-short.friday': 'F',
    'react-day-picker:weekdays-short.saturday': 'S',
    'react-day-picker:weekdays-short.sunday': 'S',
    'react-day-picker:weekdays-long.monday': 'Montag',
    'react-day-picker:weekdays-long.tuesday': 'Dienstag',
    'react-day-picker:weekdays-long.wednesday': 'Mittwoch',
    'react-day-picker:weekdays-long.thursday': 'Donnerstag',
    'react-day-picker:weekdays-long.friday': 'Freitag',
    'react-day-picker:weekdays-long.saturday': 'Samstag',
    'react-day-picker:weekdays-long.sunday': 'Sonntag',

    // Filters and sorting
    'filters:search-by-name': 'Produktname',
    'filters:search-by-name.placeholder': 'Produktname',
    'filters:search-by-cas-no': 'CAS-Nummer',
    'filters:search-by-cas-no.tooltip': 'Bindestriche einfügen',
    'filters:search-by-cas-no.placeholder': 'CAS-Nummer',
    'filters:search-by-role': 'Rolle',
    'filters:search-by-role.placeholder': 'Alle',
    'filters:search-by-csr.placeholder': 'CSR',
    'filters:search-by-admin.placeholder': 'Admin',
    'filters:search-by-user.placeholder': 'Nutzer',
    'filters:search-by-viewer.placeholder': 'Nutzer (nur Download möglich)',
    'filters:search-by-customer-product-number': 'Produktnummer',
    'filters:search-by-customer-product-number.placeholder': 'Produktnummer',
    'filters:search-by-company-name': 'Kundenname',
    'filters:search-by-company-name.placeholder': 'Kundenname',
    'filters:search-by-customer': 'Suche nach Firma',
    'filters:search-by-customerID': 'Kunde',
    'filters:search-by-customerID.placeholder': 'Kundennummer',
    'filters:search-by-customerID-or-name.placeholder': 'Name oder ID des Kunden',
    'filters:search-by-customerID-or-name-or-vat.placeholder': 'Suche nach Name, Kundennummer oder Umsatzsteuer ID',
    'filters:search-by-po-reference': 'Kundenreferenz',
    'filters:search-by-po-reference.placeholder': 'Kundenreferenz',
    'filters:search-by-offer-number': 'Angebotsnummer',
    'filters:search-by-offer-number.placeholder': 'Angebotsnummer',
    'filters:search-by-material-name': 'Produktname',
    'filters:search-by-material-name.placeholder': 'Produktname',
    'filters:search-by-username': 'Benutzername',

    'filters:search-by-here.placeholder': 'Suche hier',
    'filters:select-ship-to-address': 'Lieferadresse',
    'filters:select-an-address': 'Adresse suchen oder auswählen',
    'filters:order-status': 'Bestellstatus',
    'filters:select-a-status': 'Status wählen',
    'filter-packaging.de:filters.names':
        'Spundfass,Karton,Deckelfass,Sack,TKW,IBC,Kanister,Muster,Flasche/Kanne,Beutel,Eimer,Trommel,Dose,Big bag,SAFE-TAINER™,Druckfass,Stahlflasche',
    'filter-packaging.de:filters.values':
        'Spundfaß,Karton,Deckelfaß,Sack,TKW,IBC,Kanister,Muster,Flasche/Kanne,Beutel,Eimer,Trommel,Dose,Big bag,Safetainer,Gasbehälter,Gasflasche',
    'filter-packaging.bnl:filters.names':
        'Ampule,Bag,Bale,Bottle,Box,Bucket,Bulk,Can,Cartridge,Case,Container,Cylinder,Drum,Flexibag,Flexidrum,Flexitank,Foilbag,Frame,Log,Octabin,Pallet,Palletbox,Piece,Reel,Roll,Safetainer,Sample,Securitainer,Set,Spray,Tube',
    'filter-packaging.bnl:filters.values':
        'AMPULE,BAG,BALE,BOTTLE,BOX,BUCKET,BULK,CAN,CARTRIDGE,CASE,CONTAINER,CYLINDER,DRUM,FLEXIBAG,FLEXIDRUM,FLEXITANK,FOILBAG,FRAME,LOG,OCTABIN,PALLET,PALLETBOX,PIECE,REEL,ROLL,SAFETAINER,SAMPLE,SECURITAINER,SET,SPRAY,TUBE',
    'filter-packaging.cee:filters.names':
        'Sack,Flasche,Eimer,Bulk,Kanne,Fass,IBC,Oktabin,Palette,Safetainer, Andere, Kanister, Bigbag, Pappfass, Karton, Truhe, Paket',
    'filter-packaging.cee:filters.values':
        'BAG,BOTTLE,BUCKET,BULK,CAN,DRUM,IBC,OCTABIN,PALLET,SAFETAINER,OTHER,CANISTER,BIGBAG,PAPERBOARD BARREL,CARDBOARD,CHEST,PACKAGE',
    'filters:clear-filters': 'Filter zurücksetzen',
    'filters:select-a-packaging-title': 'Verpackung wählen',
    'filters:search-by-request-no': 'Requestnummer',
    'filters:search-by-request-no.placeholder': 'Requestnummer',
    'filters:search-by-status': 'Status ',
    'filters:search-by-status.placeholder': 'Status',
    'filters:request-type': 'Anfragetyp',
    'filters:select-request-type': 'Anfragetyp',
    'filters:select-country': 'Land',
    'filters:search-by-invoiceID': 'Rechnungsnummer',
    'filters:search-by-invoiceID.placeholder': 'Rechnungsnummer',
    'filters:search-by-einecs-no': 'Einecs-Nummer',
    'filters:search-by-einecs-no.placeholder': 'Einecs-Nummer',
    'filters:packaging-title': 'Verpackung',
    'filters:search-by-country': 'Land ',
    'filters:search-by-country.placeholder': 'Alle Länder',
    'filters:search-by-role.error-select-one': 'Wählen Sie mindestens eine Rolle aus.',
    'filters:copy-link': 'Link kopieren',
    'filters:copy-link-success': 'Der Link wurde in die Zwischenablage kopiert.',
    'filters:copy-link-error': 'Bitte kopieren Sie den folgenden Link:',

    'sort-direction:numeric:asc': '(0-9)',
    'sort-direction:numeric:desc': '(9-0)',
    'sort-direction:string:asc': '(A-Z)',
    'sort-direction:string:desc': '(Z-A)',
    'sort-direction:date:asc': '(alt-neu)',
    'sort-direction:date:desc': '(neu-alt)',

    'sort-select:sort-by': 'Sortiere nach',
    'sort-by-request-id': 'Nummer',
    'sort-by-type': 'Typ',
    'sort-by-date': 'Datum',
    'sort-by-status': 'Status',
    'sort-by-due-date': 'Fälligkeit',
    'sort-by-invoice-date': 'Rechnungsdatum',
    'sort-by-order-date': 'Auftragsdatum',
    'sort-by-po-reference': 'Kundenreferenz',
    'sort-by-invoice-type': 'Rechnungstyp',
    'sort-by-invoice-id': 'Rechnungsnummer',
    'sort-by-requested-delivery-date': 'Gewünschtes Lieferdatum',
    'sort-by-offer-number': 'Angebotsnummer',
    'sort-by-material-name': 'Produktname',
    'sort-by-quote-date': 'Angebotsdatum',

    // Packaging Names
    'package:Spundfaß': 'Spundfass',
    'package:Safetainer': 'SAFE-TAINER™',
    'package:Gasbehälter': 'Druckfass',
    'package:Gasflasche': 'Stahlflasche',
    'package:Karton': 'Karton',
    'package:Deckelfaß': 'Deckelfass',
    'package:Sack': 'Sack',
    'package:TKW': 'TKW',
    'package:IBC': 'IBC',
    'package:Kanister': 'Kanister',
    'package:Muster': 'Muster',
    'package:Flasche/Kanne': 'Flasche/Kanne',
    'package:Beutel': 'Beutel',
    'package:Eimer': 'Eimer',
    'package:Trommel': 'Trommel',
    'package:Dose': 'Dose',
    'package:Big bag': 'Big Bag',

    'package-pl:Spundfaß': 'Spundfässer',
    'package-pl:Safetainer': 'SAFE-TAINERs™',
    'package-pl:Gasbehälter': 'Gasbehälter',
    'package-pl:Gasflasche': 'Gasflaschen',
    'package-pl:Karton': 'Kartons',
    'package-pl:Deckelfaß': 'Deckelfässer',
    'package-pl:Sack': 'Säcke',
    'package-pl:TKW': 'TKW',
    'package-pl:IBC': 'IBC',
    'package-pl:Kanister': 'Kanister',
    'package-pl:Muster': 'Muster',
    'package-pl:Flasche/Kanne': 'Flaschen/Kannen',
    'package-pl:Beutel': 'Beutel',
    'package-pl:Eimer': 'Eimer',
    'package-pl:Trommel': 'Trommeln',
    'package-pl:Dose': 'Dosen',
    'package-pl:Big bag': 'Big Bags',

    // BNL packaging names
    'package:AMPULE': 'Ampule',
    'package:BAG': 'Bag',
    'package:BALE': 'Bale',
    'package:BOTTLE': 'Bottle',
    'package:BOX': 'Box',
    'package:BUCKET': 'Bucket',
    'package:BULK': 'Bulk',
    'package:CAN': 'Can',
    'package:CARTRIDGE': 'Cartridge',
    'package:CASE': 'Case',
    'package:CONTAINER': 'Container',
    'package:CYLINDER': 'Cylinder',
    'package:DRUM': 'Drum',
    'package:FLEXIBAG': 'Flexibag',
    'package:FLEXIDRUM': 'Flexidrum',
    'package:FLEXITANK': 'Flexitank',
    'package:FOILBAG': 'Foilbag',
    'package:FRAME': 'Frame',
    'package:LOG': 'Log',
    'package:OCTABIN': 'Octabin',
    'package:PALLET': 'Pallet',
    'package:PALLETBOX': 'Palletbox',
    'package:PIECE': 'Piece',
    'package:REEL': 'Reel',
    'package:ROLL': 'Roll',
    'package:SAFETAINER': 'Safetainer',
    'package:SAMPLE': 'Sample',
    'package:SECURITAINER': 'Securitainer',
    'package:SET': 'Set',
    'package:SPRAY': 'Spray',
    'package:TUBE': 'Tube',
    'package:OTHER': 'Andere',
    'package:CANISTER': 'Kanister',
    'package:BIGBAG': 'Bigbag',
    'package:PAPERBOARD BARREL': 'Pappfass',
    'package:CARDBOARD': 'Pappe',
    'package:CHEST': 'Chest',
    'package:PACKAGE': 'Paket',

    // BNL packaging names - not used in DE
    'package-pl:AMPULE': 'Ampules',
    'package-pl:BAG': 'Bags',
    'package-pl:BALE': 'Bales',
    'package-pl:BOTTLE': 'Bottles',
    'package-pl:BOX': 'Boxes',
    'package-pl:BUCKET': 'Buckets',
    'package-pl:BULK': 'Bulks',
    'package-pl:CAN': 'Cans',
    'package-pl:CARTRIDGE': 'Cartridges',
    'package-pl:CASE': 'Cases',
    'package-pl:CONTAINER': 'Containers',
    'package-pl:CYLINDER': 'Cylinders',
    'package-pl:DRUM': 'Drums',
    'package-pl:FLEXIBAG': 'Flexibags',
    'package-pl:FLEXIDRUM': 'Flexidrums',
    'package-pl:FLEXITANK': 'Flexitanks',
    'package-pl:FOILBAG': 'Foilbags',
    'package-pl:FRAME': 'Frames',
    'package-pl:LOG': 'Logs',
    'package-pl:OCTABIN': 'Octabins',
    'package-pl:PALLET': 'Pallets',
    'package-pl:PALLETBOX': 'Palletboxs',
    'package-pl:PIECE': 'Pieces',
    'package-pl:REEL': 'Reels',
    'package-pl:ROLL': 'Rolls',
    'package-pl:SAFETAINER': 'Safetainers',
    'package-pl:SAMPLE': 'Samples',
    'package-pl:SECURITAINER': 'Securitainers',
    'package-pl:SET': 'Sets',
    'package-pl:SPRAY': 'Sprays',
    'package-pl:TUBE': 'Tubes',
    'package-pl:OTHER': 'Other',
    'package-pl:CANISTER': 'Canisters',
    'package-pl:BIGBAG': 'Bigbags',
    'package-pl:PAPERBOARD BARREL': 'Paperboard barrels',
    'package-pl:CARDBOARD': 'Cardboards',
    'package-pl:CHEST': 'Chests',
    'package-pl:PACKAGE': 'Packages',

    // Surcharges codes
    'surcharge:ZPR2': 'Basispreis',
    'surcharge:ZBA2': 'Betrag in VK-ME',
    'surcharge:ZPRS': 'Nettopreis',
    'surcharge:ZPF0': 'Gebinde-Pfand',
    'surcharge:ZVEM': 'Verpackungskosten',
    'surcharge:ZVER': 'Verpackungskosten',
    'surcharge:ZVE2': 'Verp.kost in Pr.einh',
    'surcharge:ZVKM': 'VK-Gebindepreis',
    'surcharge:ZM01': 'Mindermengenzuschlag',
    'surcharge:ZM00': 'Mindermengenzuschlag',
    'surcharge:ZSOK': 'Sonstige Nebenkosten',
    'surcharge:ZSO1': 'Sonstige Nebenkosten',
    'surcharge:ZVP0': 'Sonderverpackung',
    'surcharge:ZMAU': 'Maut-/Dieselzuschlag',
    'surcharge:ZMA2': 'Maut in Preiseinheit',
    'surcharge:ZTP1': 'Transportkosten',
    'surcharge:ZTPS': 'Transportkosten',
    'surcharge:ZSTD': 'Standgeld',
    'surcharge:ZST1': 'Standgeld',
    'surcharge:ZABK': 'Abfüllen',
    'surcharge:ZAB1': 'Abfüllen',
    'surcharge:ZLG1': 'Lagerkosten',
    'surcharge:ZLG2': 'Lagerkosten',
    'surcharge:ZFLG': 'Füllgebühr',
    'surcharge:ZGVS': 'ADR-Zuschlag',
    'surcharge:ZGV1': 'ADR-Zuschlag pausch.',
    'surcharge:Z110': 'Rabatt absolut',
    'surcharge:Z111': 'Rabatt Menge',
    'surcharge:Z112': 'Rabatt Prozent',
    'surcharge:Z15P': 'Frühkaufrabatt',
    'surcharge:ZRAB': 'Rabatt %',
    'surcharge:ZRB1': 'Rabatt absolut',
    'surcharge:ZKA2': 'Dienstlst.zuschlag',
    'surcharge:ZCVH': 'Umschlagskosten',
    'surcharge:ZCVM': 'CVM-Zuschlag',
    'surcharge:ZUWP': 'Umweltpauschale',
    'surcharge:ZSKT': 'Skonto (kalk.)',
    'surcharge:ZPRB': 'Provisionsgrundlage',
    'surcharge:ZPRV': 'Provision',
    'surcharge:ZVPR': 'Verrechnungspr./GLD',
    'surcharge:ZSTA': 'Startpreis >011011',
    'surcharge:ZSTM': 'Startpreis manuell',
    'surcharge:ZB10': 'Bonus % Menge o.Z.',
    'surcharge:ZB11': 'Bonus abs.Menge',
    'surcharge:ZB12': 'Bonus % Umsatz o.Z.',
    'surcharge:ZB14': 'Bonus % Menge m.Z.',
    'surcharge:ZB15': 'Bonus % Umsatz m.Z.',
    'surcharge:ZSUB': 'Subvention',
    'surcharge:ZSUP': 'Subvention in %',
    'surcharge:ZDCL': 'Dowclene',
    'surcharge:ZWEG': 'Wareneins.Gesch.',
    'surcharge:ZTUK': 'Kosten Transport/Uml',
    'surcharge:ZTRA': 'Transportkosten',
    'surcharge:ZF00': 'Kalk.Frachtkosten',
    'surcharge:ZLFR': 'Lieferfrist < 24 Std',
    'surcharge:ZVPP': 'VPRS Gebinde',
    'surcharge:ZVPL': 'Verp.Kost. Lieferpl.',
    'surcharge:ZVP1': 'Geb.-ZVPP- Ausgleich',
    'surcharge:ZVKD': 'Vk-Gebindepreis',
    'surcharge:ZVK1': 'Geb.-ZVKD- Ausgleich',
    'surcharge:ZUKE': 'Einlagern',
    'surcharge:ZUKM': 'Mischen',
    'surcharge:ZUKA': 'Abfüllen',

    'surcharge:ZSLK': 'Sonder-/Dienstleistg',
    'surcharge:ZALG': 'Kommissionieren',
    'surcharge:ZLKW': 'LKW beladen',
    'surcharge:ZLA1': 'Lagerhaltung, Fremd',
    'surcharge:ZLA2': 'Auslagern,Fremd',
    'surcharge:ZBKO': 'Beschaffen',
    'surcharge:ZVKO': 'Vertriebskosten',
    'surcharge:ZDIK': 'Disposition',
    'surcharge:ZDK1': 'Auftragsabwicklung',
    'surcharge:ZDK2': 'Dispokosten',

    'surcharge:ZSLO': 'Sonst.Logistikkosten',
    'surcharge:ZNLK': 'sonst. NL-Kosten',
    'surcharge:ZZKO': 'Zentralkosten',
    'surcharge:ZVBR': 'Verbrauchssteuern',
    'surcharge:ZABS': 'Brannt./Energ.Steuer',
    'surcharge:MWST': 'Umsatzsteuer',
    'surcharge:SKTO': 'Skonto',
    'surcharge:ZVOC': 'VOC-Gebühr',
    'surcharge:ZIVU': 'IV Umschlag',
    'surcharge:GRWR': 'Grenzübergangswert',
    'surcharge:ZMW1': 'Netto FW',
    'surcharge:ZMW2': 'Umsatzsteuer FW',
    'surcharge:ZMW3': 'Brutto FW',

    // Order history
    'order-history:headline': 'Meine Bestellungen',

    // Quote List
    'quotes:headline': 'Angebote',

    // Order detail
    'order-detail:headline': 'Bestellung {orderId}',

    // Order detail - Not found
    'warning:order-not-found:title': 'Bestellung nicht gefunden',
    'warning:order-not-found:explanation': 'Die von Ihnen angeforderte Bestellung konnte nicht gefunden werden.',
    'warning:order-not-found:link-text': 'Zurück zu Bestellungen',

    // Order history - Reorder
    'order-detail:reorder': 'Erneut bestellen',
    'reorder:success-toast': 'Die Produkte wurden erfolgreich in Ihren Warenkorb gelegt.',
    'reorder:error-toast': 'Die Produkte konnten nicht in Ihren Warenkorb übernommen werden.',
    'reorder:warning-toast': 'Einige Produkte konnten nicht in Ihren Warenkorb übernommen werden.',

    // Generic error
    'warning:generic-error:title': 'Bitte entschuldigen Sie!',
    'warning:generic-error:explanation': 'Leider ist Brenntag Connect momentan nicht zu erreichen.',
    'warning:generic-error:contact':
        'Für Fragen rund um unser Angebot steht Ihnen währenddessen Ihr persönlicher Ansprechpartner oder unsere Service-Hotline {csrPhone} sehr gerne zur Verfügung.',

    // Invalid customer
    'warning:invalid-customer-id:title': 'Bitte entschuldigen Sie!',
    'warning:invalid-customer-id:explanation': 'Leider ist Brenntag Connect momentan nicht zu erreichen.',
    'warning:invalid-customer-id:contact':
        'Für Fragen rund um unser Angebot steht Ihnen währenddessen Ihr persönlicher Ansprechpartner.',

    // Login failed
    'warning:login-failed:title': 'Etwas ist schief gelaufen.',
    'warning:login-failed:explanation': 'Die Anmeldung ist fehlgeschlagen. Bitte überprüfen Sie Ihre Login-Daten.',
    'warning:login-failed:button-text': 'Versuchen Sie es nochmal.',
    'warning:too-many-results:title': 'Bitte helfen Sie uns um Ihre Bestellung(en) zu finden',
    'warning:too-many-results:explanation':
        'Wir benötigen weitere Informationen, um Ihre Bestellung(en) zu finden - bitte geben Sie mehr Buchstaben des Produktnamens ein.',
    'warning:customer-migrated-bep:title': 'Neuer Brenntag Connect',
    'warning:customer-migrated-bep:explanation':
        'Wir haben eine neue Version von Brenntag Connect für Sie.<br/>Bitte loggen Sie sich hier ein: <a href="https://emea.brenntag.com/{region}">https://emea.brenntag.com/{region}/</a>',

    // Session expired
    'session-expired:header': 'Sitzung abgelaufen',
    'session-expired:text': 'Sitzung abgelaufen. Bitte melden Sie sich erneut an.',
    'session-expired:login-button': 'Anmelden',

    // Not found
    'not-found:title': 'Seite nicht gefunden!',
    'not-found:message': 'Die gesuchte Seite konnte nicht gefunden werden.',
    'not-found:orders': 'Keine Ergebnisse gefunden.',
    'not-found:customers': 'Keine Firmen gefunden.',
    'not-found:users': 'Keine Benutzer gefunden.',
    'not-found:link-text': 'Zurück zur Startseite',
    'not-found:invoices': 'Keine Rechnungen gefunden.',
    'not-found:requests': 'Keine Anfragen gefunden.',
    'not-found:select': 'Keine passende Ergebnisse.',

    // Maintenance
    'maintenance:title': 'Brenntag Connect ist nicht verfügbar',
    'maintenance:message':
        'Bitte entschuldigen Sie! Brenntag Connect ist aufgrund von Wartungsarbeiten vorübergehend nicht erreichbar.',

    // Document not found
    'document-not-found:title': 'Dokument nicht gefunden!',
    'document-not-found:message':
        'Dieses Dokument ist derzeit nicht verfügbar. Wünschen Sie weitere Informationen? Bitte kontaktieren Sie uns unter <a href="mailto:{csrEmail}">{csrEmail}</a>.',

    // Scale prices
    'scale-prices:from': 'Ab',

    // Form validation
    'form-validation:confirm': 'Feld stimmt nicht überein.',
    'form-validation:required': 'Bitte wählen Sie einen Wert ein.',
    'form-validation:email': 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
    'form-validation:email-csr':
        'Sie können leider nur CSR-Benutzer erstellen, die der Brenntag- oder DigiB-Organisation angehören. Bei Fragen wenden Sie sich bitte an den Second Line Support.',
    'form-validation:email-in-use': 'E-Mail wird bereits verwendet.',
    'form-validation:password': 'Bitte geben Sie Ihr Passwort ein.',
    'form-validation:password-repeat': 'Bitte wiederholen Sie Ihr Passwort.',
    'form-validation:date': 'Bitte wählen Sie ein gültiges Datum ein.',
    'form-validation:date.future': 'Bitte wählen Sie ein Datum nach dem {date}.',
    'form-validation:date.past': 'Bitte wählen Sie ein Datum vor dem {date}.',
    'form-validation:date.weekday': 'Bitte wählen Sie einen Werktag aus.',
    'form-validation:max-length': 'Mindestanzahl von {length} Zeichen erforderlich.',
    'form-validation:min-length': 'Mindestanzahl von {length} Zeichen erforderlich.',
    'form-validation:min-amount': 'Die Mindestbestellmenge beträgt {amount} {type}.',
    'form-validation:number': 'Bitte wählen Sie ein gültiges Nummer ein.',
    'form-validation:has-uppercase': 'Das Passwort sollte mindestens einen Großbuchstaben enthalten.',
    'form-validation:has-word-character': 'Sonderzeichen sind nicht erlaubt.',
    'form-validation:has-lowercase': 'Das Passwort sollte mindestens einen Kleinbuchstaben enthalten.',
    'form-validation:has-numeric-character': 'Das Passwort sollte mindestens eine Zahl enthalten.',
    'form-validation:has-symbol-character': 'Das Passwort sollte mindestens ein Symbol enthalten.',
    'form-validation:min-length-values': '{values} Mindestanzahl von {length} Zeichen erforderlich.',
    'form-validation:do-not-exist': 'Diese Kunden existieren nicht: {values}.',
    'form-validation:max-amount': 'Es tut uns leid, Sie können nur höchstens {amount} {type} bestellen.',
    'form-validation:number-too-big': 'Die Zahl ist zu groß.',
    'form-validation:reference-required': 'Bitte fügen Sie eine Kundenreferenz hinzu.',

    // Account and preferences
    'account:profile': 'Mein Profil',
    'account:title': 'Konto & Einstellungen',
    'account:first-name': 'Vorname',
    'account:last-name': 'Nachname',
    'account:email': 'E-Mail-Adresse',
    'account:name': 'Name',
    'account:phone': 'Telefon',
    'account:shipping-address': 'Lieferadresse',
    'account:billing-address': 'Rechnungsadresse',
    'account:personal-details': 'Personendaten',
    'account:company-details': 'Kundendetails',
    'account:users': 'Benutzer',
    'account:company-name': 'Kundennamen',
    'account:preferences': 'Einstellungen',
    'account:language': 'Standardsprache',
    'account:related:title': 'Benutzer für {company}',
    'account:related:intro':
        'Die folgende Liste enthält die Benutzer, die innerhalb Ihrer Organisation Zugriff auf Brenntag Connect haben. Um Benutzer hinzuzufügen oder zu entfernen, wenden Sie sich bitte an: <a href="mailto:{csrEmail}">{csrEmail}</a>.',
    'account:related:no-results':
        'Es gibt keine anderen Benutzer in Ihrer Organisation, die Zugriff auf Brenntag Connect haben. Um Benutzer hinzuzufügen oder zu entfernen, wenden Sie sich bitte an <a href="mailto:{csrEmail}">{csrEmail}</a>.',

    // Request information Modal
    'request-information-modal:add-to-cart': 'In den Warenkorb legen',
    'request-information-modal:edit-quantity-cart': 'Menge ändern',
    'request-information-modal:add-to-cart.instruction': 'Wählen Sie einen Menge aus, den Sie bestellen möchten.',
    'request-information-modal:toasts-product-added': 'Dieses Produkt wurde Ihrem Warenkorb hinzugefügt.',
    'request-information-modal:toasts-product-adjusted': 'Die Menge wurde auf Ihrer Einkaufskarte angepasst.',
    'request-information-modal:remove-from-cart': 'Produkt entfernen',
    'request-information-modal:remove-from-cart.instruction':
        'Sind Sie {productName} sicher dass Sie dieses Produkt aus Ihrem Warenkorb entfernen wöllte?',
    'request-information-modal:toasts-product-removed': 'Dieses Produkt wurde aus Ihrem Warenkorb entfernt.',

    'request-information-modal:request-information': 'Informationsanfrage',
    'request-information-modal:request-quote': 'Bestellung',
    'request-information-modal:request-quote-confirm': 'Bestellübersicht',
    'request-information-modal:step': 'Schritt',
    'request-information-modal:step.of': 'von',
    'request-information-modal:text':
        'Bitte vervollständigen Sie das Formular, um zusätzliche Informationen zu dem unten aufgeführten Produkt zu erhalten.',
    'request-information-modal:text.quote':
        'Bitte vervollständigen Sie das Formular, um eine Nachbestellung vorzunehmen.',
    'request-information-modal:success-toast':
        'Ihre Anfrage wurde erfolgreich weitergeleitet. Wir werden uns kurzfristig mit Ihnen in Verbindung setzen.',
    'request-information-modal:send-request': 'Anfrage senden',
    'request-information-modal:needs': 'Welche Information benötigen Sie?',
    'request-information-modal:document-types': 'Art des Dokumentes',
    'request-information-modal:application': 'Anwendung',
    'request-information-modal:amount': 'Menge',
    'request-information-modal:sample-quantity': 'Menge Muster',
    'request-information-modal:annual-quantity': 'Jährlicher Bedarf',
    'request-information-modal:call-off-quantity': 'Liefermenge',
    'request-information-modal:requested-delivery-date': 'Gewünschtes Lieferdatum',
    'request-information-modal:requested-pickup-date': 'Gewünschtes Abholdatum',
    'request-information-modal:order-quantity': 'Bestellmenge',
    'request-information-modal:comment': 'Kommentar',
    'request-information-modal:step2': 'Gehe zu Schritt 2',
    'request-information-modal:requested-information': 'Erforderliche Informationen',
    'request-information-modal:document-types-other-placeholder': 'Dokumentenname',
    'request-information-modal:select-an-application': 'Wählen Sie eine Anwendung',
    'request-information-modal:me': 'Ich selbst',
    'request-information-modal:someone-else': 'Jemand anders',
    'request-information-modal:back-to-step-1': 'Zurück zu Schritt 1',
    'request-information-modal:back-to-step-2': 'Zurück zu Schritt 2',
    'request-information-modal:my-address-book': 'Mein Adressbuch',
    'request-information-modal:new-address': 'Neue Adresse',
    'request-information-modal:document-types.msds': 'Sicherheitsdatenblatt (SDS)',
    'request-information-modal:document-types.tds': 'Technisches Datenblatt (TDS)',
    'request-information-modal:document-types.specification': 'Spezifikation',
    'request-information-modal:document-types.coa': 'Analysenzertifikat (COA)',
    'request-information-modal:document-types.other': 'Anderes Dokument',
    'request-information-modal:requirement.price': 'Preis',
    'request-information-modal:requirement.document': 'Dokumente',
    'request-information-modal:requirement.sample': 'Muster',
    'request-information-modal:requirement.quote': 'Quote',
    'request-information-modal:order': 'Bestellung',
    'request-information-modal:reorder': 'Erneute Bestellung',
    'request-information-modal:reorder:disabled-unavailableMaterial':
        'Dieser Artikel wurde angepasst. Wenden Sie sich an Ihr Brenntag Connect-Team.',
    'request-information-modal:reorder:disabled-unavailableForCustomer': 'Produkt ist genehmigungspflichtig',
    'request-information-modal:reorder:disabled-unavailable':
        'Dies ist ein Muster. Wenden Sie sich an Ihr Brenntag Connect-Team.',
    'request-information-modal:reorder:select-delivery-date':
        'Bitte wählen Sie ein Lieferdatum und eine Lieferadresse aus',
    'request-information-modal:reorder:no-prices-available': 'Preis auf Anfrage',
    'request-information-modal:preview-and-submit': 'Vorschau & Senden',
    'request-information-modal:confirmation':
        'Vielen Dank für Ihre Bestellanfrage. Unser Verkaufsservice wird sich mit Ihnen in Verbindung setzen, um die Details zu besprechen.',
    'request-information-modal:available-prices': 'Verfügbare Preise',
    'request-information-modal:reorder:self-pickup': 'Abholung in einem Lager ',
    'request-information-modal:different-shipto-warning':
        'Wenn eine andere Adresse ausgewählt ist, muss der Preis bestätigt werden',

    'request-information:auto-complete:price':
        'Ihre Preisanfrage wird an Brenntag gesendet und so schnell wie möglich bearbeitet. Der angeforderte Preis wird außerhalb von Brenntag Connect verarbeitet.',
    'request-information:auto-complete:sample':
        'Ihre Musteranfrage wird an Brenntag gesendet und so schnell wie möglich bearbeitet.',
    'request-information:auto-complete:quote':
        'Ihre Bestellanfrage wird an Brenntag gesendet und so schnell wie möglich bearbeitet. Wenn die Bestellung bearbeitet wird, wird sie in der Bestellübersicht in Brenntag Connect angezeigt.',
    'request-information:auto-complete:cart':
        'Ihre Bestellanfrage wird an Brenntag gesendet und so schnell wie möglich bearbeitet. Wenn die Bestellung bearbeitet wird, wird diese in der Bestellübersicht in Brenntag Connect angezeigt.',

    // Applications list for BNL and DE
    'request-information:application:ai-automotive': 'Automobiltechnik',
    'request-information:application:ai-construction-flooring': 'Bau Bodenbeläge',
    'request-information:application:ai-coatings-architect-coatings': 'Baulacke & -farben',
    'request-information:application:ai-construction-building-chemicals': 'Baustoffe',
    'request-information:application:ai-construction-concrete-based-prod-': 'Betonprodukte',
    'request-information:application:ai-decorative-paints': 'Dekorfarben',
    'request-information:application:ai-dental': 'Dental',
    'request-information:application:ai-coatings-sealants': 'Dichtstoffe',
    'request-information:application:ai-printing-publishing': 'Drucktechnik',
    'request-information:application:ai-electronic': 'Elektrotechnik',
    'request-information:application:ai-epoxy-systems': 'Epoxidsysteme',
    'request-information:application:ai-coatings-other': 'Farben & Lacke sonstige',
    'request-information:application:ai-filter-and-environmental-technology': 'Filter- und Umwelttechnik',
    'request-information:application:ai-glass': 'Glas',
    'request-information:application:ai-rubber-seals-gaskets': 'Gummi Dichtungen',
    'request-information:application:ai-rubber-wires-cables': 'Gummi Draht und Kabel',
    'request-information:application:ai-rubber-latex-goods': 'Gummi Latexprodukte',
    'request-information:application:ai-rubber-medical-parts': 'Gummi Medizintechnische Teile',
    'request-information:application:ai-rubber-tyre': 'Gummi Reifen',
    'request-information:application:ai-rubber-tubes-hoses': 'Gummi Schläuche',
    'request-information:application:ai-rubber-other': 'Gummi sonstige',
    'request-information:application:ai-rubber-compounds': 'Gummimischungen',
    'request-information:application:ai-homecare': 'Haushaltsreiniger',
    'request-information:application:ai-coatings-wood-coatings': 'Holzlacke',
    'request-information:application:ai-coatings-industrial-coatings': 'Industrielacke',
    'request-information:application:ai-industrial-cleaning': 'Industriereiniger',
    'request-information:application:ai-ceramics': 'Keramik',
    'request-information:application:ai-coatings-adhesives': 'Klebstoffe',
    'request-information:application:ai-coatings-raw-materials-other': 'Lacke & Farben Farben Sonstige',
    'request-information:application:ai-agro': 'Landwirtschaft',
    'request-information:application:ai-aerospace': 'Luft- und Raumfahrttechnik',
    'request-information:application:ai-metal-surface-treatments': 'Metall- und Oberflächenveredelung',
    'request-information:application:ai-oil-upstream-fracs-drill': 'Öl Hochfraktion',
    'request-information:application:ai-oil-midstream-n-spec': 'Öl Mittelfraktion',
    'request-information:application:ai-oil-midstream-gasplants': 'Öl Mittelfraktion Gasgewinnung',
    'request-information:application:ai-oil-midstream-compounder': 'Öl Mittelfraktion Mischer',
    'request-information:application:ai-oil-downstream-refinerie': 'Öl Mittelfraktion Rafinerien',
    'request-information:application:ai-oil-oil-gas-other': 'Öl und Gas sonstige',
    'request-information:application:ai-paper-manufacturers': 'Papierherstellung',
    'request-information:application:ai-pulp-paper-others': 'Papierindustrie - Sonstige',
    'request-information:application:ai-polymers-compounders': 'Polymere Compounder',
    'request-information:application:ai-polymers-extrusion': 'Polymere Extrusion',
    'request-information:application:ai-polymers-extrusion-foil': 'Polymere Folienherstellung',
    'request-information:application:ai-polymers-pu-elastomers': 'Polymere PU Elastomere',
    'request-information:application:ai-polymers-pvc': 'Polymere PVC',
    'request-information:application:ai-polymers-raw-mat-producers': 'Polymere Rohstoffhersteller',
    'request-information:application:ai-polymers-other': 'Polymere sonstige',
    'request-information:application:ai-polymers-elastomers-other': 'Polymere sonstige Elastomere',
    'request-information:application:ai-polymers-injection-moulding': 'Polymere Spritzguss',
    'request-information:application:ai-chemical-processing': 'Prozesschemie',
    'request-information:application:ai-coatings-powder-coatings': 'Pulverlacke',
    'request-information:application:ai-cleaning-vehicles': 'Reinigung Fahrzeuge',
    'request-information:application:ai-cleaning-floorcare': 'Reinigung Fußböden',
    'request-information:application:ai-cleaning-food-area': 'Reinigung Lebensmittelindustrie',
    'request-information:application:ai-cleaning-other': 'Reinigung sonstige',
    'request-information:application:ai-dry-clean-laundry': 'Reinigungen und Industriewäschereien',
    'request-information:application:ai-lubricants': 'Schmierstoffe sonstige',
    'request-information:application:ai-lub-fuel-blends': 'Schmierstoffe und Treibstoffmischungen',
    'request-information:application:ai-construction-other': 'Sonstige Bauchemikalien',
    'request-information:application:ai-chemical-synthesis': 'Synthesechemie',
    'request-information:application:ai-textile-leather': 'Textil- und Lederveredelung',
    'request-information:application:ai-coatings-inks': 'Tinten und Druckfarben',
    'request-information:application:ai-packaging-converting': 'Verpackungen',
    'request-information:application:ai-water-cond-serv-cy-waste-water': 'Wasser Aufbereitung / Abwasser',
    'request-information:application:ai-water-mun-waste-water': 'Wasser Kühlwasser',
    'request-information:application:ai-water-mun-swimming-pool': 'Wasser Schwimmbäder',
    'request-information:application:ai-water-mun-potable-water': 'Wasser Trinkwasser',
    'request-information:application:ai-water-teatment-other': 'Wasserbehandlung Sonstige',
    'request-information:application:ai-waterconditioning': 'Wasserkonditionierung',
    'request-information:application:ai-tissue-manufacturers': 'Zellstoffherstellung',
    'request-information:application:ai-construction-cement-products': 'Zementprodukte',
    'request-information:application:animal-nutrition:air-treatment': 'Animal Nutrition - Abluftreinigung, Luftwäscher',
    'request-information:application:animal-nutrition:enrichment-(minerals-vitamins-amino-acids)':
        'Animal Nutrition - Anreicherung/Ernährungsphysiologie (Aminosäuren, Mineralstoffe, Probiotika, Spurenelemente, Vitamine…)',
    'request-information:application:animal-nutrition:antioxidants': 'Animal Nutrition - Antioxidation',
    'request-information:application:animal-nutrition:flavours': 'Animal Nutrition - Aroma, Akzeptanzsteigerung',
    'request-information:application:animal-nutrition:binder-flow-additives-coagulation-agents':
        'Animal Nutrition - Bindung, Fließ- und Gerinnungshilfe',
    'request-information:application:animal-nutrition:products-for-biogas-systems': 'Animal Nutrition - Biogasanlagen',
    'request-information:application:animal-nutrition:biocides-and-hygiene-products':
        'Animal Nutrition - Biozide, Hygieneprodukte',
    'request-information:application:animal-nutrition:emulsifiers-stabilizers-thickener':
        'Animal Nutrition - Emulgierung, Stabilisierung, Verdickung- und Gelierung',
    'request-information:application:animal-nutrition:energy-suppliers':
        'Animal Nutrition - Energielieferung (Proteine, Energieträger)',
    'request-information:application:animal-nutrition:colourings': 'Animal Nutrition - Färbung',
    'request-information:application:animal-nutrition:coccidiostatics-and-histomonostats':
        'Animal Nutrition - Kokzidiostatika und Histomonostatika',
    'request-information:application:animal-nutrition:preservatives': 'Animal Nutrition - Konservierung',
    'request-information:application:animal-nutrition:performance-booster':
        'Animal Nutrition - Leistungssteigerung, positiver Umwelteinfluss (Mikroorganismen, Enzyme)',
    'request-information:application:animal-nutrition:radionuclide-binder': 'Animal Nutrition - Radionuklidbindung',
    'request-information:application:animal-nutrition:acidity-regulators': 'Animal Nutrition - Säureregulation',
    'request-information:application:animal-nutrition:silage-additives': 'Animal Nutrition - Silierzusatzstoff',
    'request-information:application:animal-nutrition:technical-adjuvants':
        'Animal Nutrition - Technische Hilfsstoffe, Animal Nutrition - Betriebsmittel',
    'request-information:application:animal-nutrition:release-agents': 'Animal Nutrition - Trennmittel',
    'request-information:application:animal-nutrition:water-treatment': 'Animal Nutrition - Wasseraufbereitung',
    'request-information:application:cosmetics:deodorant': 'Kosmetik - Deo',
    'request-information:application:cosmetics:hair-care': 'Kosmetik - Haarpflege',
    'request-information:application:cosmetics:hair-styling': 'Kosmetik - Haarstyling',
    'request-information:application:cosmetics:make-up': 'Kosmetik - Dekorative Kosmetik',
    'request-information:application:cosmetics:oral-hygiene': 'Kosmetik - Mundpflege',
    'request-information:application:cosmetics:perfume': 'Kosmetik - Parfüm',
    'request-information:application:cosmetics:skin-care': 'Kosmetik - Hautpflege',
    'request-information:application:cosmetics:skin-cleansing': 'Kosmetik - Hautreinigung',
    'request-information:application:cosmetics:sun': 'Kosmetik - Sonne',
    'request-information:application:food:acidifiers-and-acidity-regulators':
        'Food - Säuerungsmittel und Säureregulatoren',
    'request-information:application:food:antioxidants': 'Food - Antioxidationsmittel',
    'request-information:application:food:carriers-and-bulking-agents': 'Food - Träger- und Füllstoffe',
    'request-information:application:food:coating-and-release-agents': 'Food - Trenn- und Überzugsmittel',
    'request-information:application:food:colourings': 'Food - Farbstoffe',
    'request-information:application:food:emulsifier': 'Food - Emulgatoren',
    'request-information:application:food:flavours': 'Food - Aromen',
    'request-information:application:food:foaming-agents': 'Food - Schaumbildner',
    'request-information:application:food:humectants': 'Food - Feuchthaltemittel',
    'request-information:application:food:nutrition-enrichment': 'Food - Anreicherung',
    'request-information:application:food:raising-and-flour-tratment-agents':
        'Food - Backtrieb- und Mehlbehandlungsmittel',
    'request-information:application:food:rreservatives-and-stabilizers':
        'Food - Konservierungsstoffe und Stabilisatoren',
    'request-information:application:food:smelting-agent': 'Food - Schmelzsalz',
    'request-information:application:food:sugars-and-sweeteners': 'Food - Zucker und Süßungsmittel',
    'request-information:application:food:technical-adjuvants': 'Food - Technische Hilfsstoffe',
    'request-information:application:food:thickener-gelling-and-firming-agent':
        'Food - Festigungs-, Verdickungs- und Geliermittel',
    'request-information:application:medicinal-product:capsule': 'Arzneimittel Kapsel',
    'request-information:application:medicinal-product:cream-gel-salve': 'Arzneimittel Creme, Gel, Salbe',
    'request-information:application:medicinal-product:drops-(oral)': 'Arzneimittel Tropfen (oral)',
    'request-information:application:medicinal-product:eye-drops': 'Arzneimittel Augentropfen',
    'request-information:application:medicinal-product:parenteral': 'Arzneimittel Parenteral',
    'request-information:application:medicinal-product:sachet': 'Arzneimittel Sachet',
    'request-information:application:medicinal-product:spray-solution-(eyes-nose)':
        'Arzneimittel Spray/Lösung (Augen, Nase)',
    'request-information:application:medicinal-product:syrup-(oral)': 'Arzneimittel Saft (oral)',
    'request-information:application:medicinal-product:tablet': 'Arzneimittel Tablette',
    'request-information:application:mp-ns-ph:others': 'AM / NEM / PH - Andere',
    'request-information:application:nutritional-supplement:syrup-drops-solution':
        'Nahrungsergänzungsmittel Saft, Tropfen, Lösung',
    'request-information:application:nutritional-supplement:tablet-capsule-sachet':
        'Nahrungsergänzungsmittel Tablette, Kapsel, Sachet',
    'request-information:application:pharma:api-synthesis': 'Pharma - API-Synthese',
    'request-information:application:pharma:biotechnology': 'Pharma - Biotechnologie',
    'request-information:application:pharma:desinfection': 'Pharma - Desinfektion',
    'request-information:application:other': 'Andere',

    // Applications list for CEE

    'request-information:application:adblue:oem-&-truck-dealers': 'AdBlue - OEM & Truck dealers',
    'request-information:application:adblue:distribution': 'AdBlue - Distribution',
    'request-information:application:adblue:endusers,-carriers-&-fleetowners':
        'AdBlue - Endusers, Carriers & Fleetowners',
    'request-information:application:adblue:public-filling-stations-(codo/dodo)':
        'AdBlue - Public Filling Stations (CODO/DODO)',
    'request-information:application:adblue:retail-&-public-filling-stations--(codo)':
        'AdBlue - Retail & Public Filling Stations  (CODO)',
    'request-information:application:adblue:others': 'AdBlue - Others',
    'request-information:application:agro:desinfection': 'Agro - Desinfection',
    'request-information:application:agro:herbicides-&-pesticides': 'Agro - Herbicides & Pesticides',
    'request-information:application:agro:mixed-production': 'Agro - Mixed Production',
    'request-information:application:agro:fertilizers': 'Agro - Fertilizers',
    'request-information:application:agro:horticulture': 'Agro - Horticulture',
    'request-information:application:agro:farmers': 'Agro - Farmers',
    'request-information:application:agro:orchards': 'Agro - Orchards',
    'request-information:application:agro:others': 'Agro - Others',
    'request-information:application:cement-mills:': 'Cement Mills',
    'request-information:application:cleaning:cleaning-&-cleansing': 'Cleaning - Cleaning & Cleansing',
    'request-information:application:cleaning:homecare': 'Cleaning - Homecare',
    'request-information:application:cleaning:distributors': 'Cleaning - Distributors',
    'request-information:application:cleaning:auto-care-cleaning': 'Cleaning - Auto Care Cleaning',
    'request-information:application:cleaning:industrial-&-institutional-cleaning':
        'Cleaning - Industrial & Institutional Cleaning',
    'request-information:application:cleaning:others': 'Cleaning - Andere',
    'request-information:application:heat-engineering:': 'Heat Engineering',
    'request-information:application:chemical-synthesis:': 'Chemical Synthesis',
    'request-information:application:distributors:others': 'Distributors - Others',
    'request-information:application:electronics:medical-equipment-&-tools': 'Electronics - Medical Equipment & Tools',
    'request-information:application:electronics:automotive-electronics': 'Electronics - Automotive Electronics',
    'request-information:application:electronics:lighting-equipment': 'Electronics - Lighting Equipment',
    'request-information:application:electronics:brown-goods': 'Electronics - Brown Goods',
    'request-information:application:electronics:others': 'Electronics - Others',
    'request-information:application:power-industry:': 'Power Industry',
    'request-information:application:renewable-energy:biodiesel': 'Renewable Energy - Biodiesel',
    'request-information:application:renewable-energy:biogas': 'Renewable Energy - Biogas',
    'request-information:application:renewable-energy:solar': 'Renewable Energy - Solar',
    'request-information:application:renewable-energy:others': 'Renewable Energy - Others',
    'request-information:application:coatings-&-construction:construction:building-chemicals':
        'Coatings & Construction - Construction - Building Chemicals',
    'request-information:application:coatings-&-construction:construction:fireproof-materials':
        'Coatings & Construction - Construction - Fireproof Materials',
    'request-information:application:coatings-&-construction:construction:gipsum-cardboard':
        'Coatings & Construction - Construction - Gipsum Cardboard',
    'request-information:application:coatings-&-construction:construction:plywood-&-particle-boards':
        'Coatings & Construction - Construction - Plywood & Particle Boards',
    'request-information:application:coatings-&-construction:construction:floorings':
        'Coatings & Construction - Construction - Floorings',
    'request-information:application:coatings-&-construction:construction:concrete-based-products':
        'Coatings & Construction - Construction - Concrete-based Products',
    'request-information:application:coatings-&-construction:construction:cement-based-products':
        'Coatings & Construction - Construction - Cement-based Products',
    'request-information:application:coatings-&-construction:construction:plasters':
        'Coatings & Construction - Construction - Plasters',
    'request-information:application:coatings-&-construction:construction:mineral-wool-':
        'Coatings & Construction - Construction - Mineral Wool ',
    'request-information:application:coatings-&-construction:construction:others':
        'Coatings & Construction - Construction - Others',
    'request-information:application:coatings-&-construction:distributors': 'Coatings & Construction - Distributors',
    'request-information:application:coatings-&-construction:elastomers:pu-elastomers':
        'Coatings & Construction - Elastomers - PU Elastomers',
    'request-information:application:coatings-&-construction:elastomers:others':
        'Coatings & Construction - Elastomers - Others',
    'request-information:application:coatings-&-construction:coatings:architectural-coatings':
        'Coatings & Construction - Coatings - Architectural Coatings',
    'request-information:application:coatings-&-construction:coatings:wood-coatings':
        'Coatings & Construction - Coatings - Wood Coatings',
    'request-information:application:coatings-&-construction:coatings:powder-coatings':
        'Coatings & Construction - Coatings - Powder Coatings',
    'request-information:application:coatings-&-construction:coatings:industrial-coatings':
        'Coatings & Construction - Coatings - Industrial Coatings',
    'request-information:application:coatings-&-construction:coatings:primers':
        'Coatings & Construction - Coatings - Primers',
    'request-information:application:coatings-&-construction:coatings:automotive-coatings':
        'Coatings & Construction - Coatings - Automotive Coatings',
    'request-information:application:coatings-&-construction:coatings:thinners':
        'Coatings & Construction - Coatings - Thinners',
    'request-information:application:coatings-&-construction:coatings:others':
        'Coatings & Construction - Coatings - Others',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:adhesives':
        'Coatings & Construction - Adhesives & Sealants - Adhesives',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:hot-melts':
        'Coatings & Construction - Adhesives & Sealants - Hot Melts',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:sealants':
        'Coatings & Construction - Adhesives & Sealants - Sealants',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:others':
        'Coatings & Construction - Adhesives & Sealants - Others',
    'request-information:application:coatings-&-construction:composites': 'Coatings & Construction - Composites',
    'request-information:application:coatings-&-construction:producers-of-c&c-raw-materials':
        'Coatings & Construction - Producers of C&C Raw Materials',
    'request-information:application:coatings-&-construction:inks': 'Coatings & Construction - Inks',
    'request-information:application:coatings-&-construction:others': 'Coatings & Construction - Andere',
    'request-information:application:mining:natural-building-materials-&-stones':
        'Mining - Natural Building Materials & Stones',
    'request-information:application:mining:metal-ore': 'Mining - Metal Ore',
    'request-information:application:mining:coal': 'Mining - Coal',
    'request-information:application:mining:others': 'Mining - Others',
    'request-information:application:steelworks:': 'Steelworks',
    'request-information:application:municipalities-&-institutions:schools-&-universities':
        'Municipalities & Institutions - Schools & Universities',
    'request-information:application:municipalities-&-institutions:hospitals':
        'Municipalities & Institutions - Hospitals',
    'request-information:application:municipalities-&-institutions:others': 'Municipalities & Institutions - Others',
    'request-information:application:coke-engineering:': 'Coke Engineering',
    'request-information:application:fotochemical-&-film-materials:': 'Fotochemical & Film Materials',
    'request-information:application:explosives-&-pyrotechnics:': 'Explosives & Pyrotechnics',
    'request-information:application:environmental-protection:consulting-&-services':
        'Environmental Protection - Consulting & Services',
    'request-information:application:environmental-protection:soil-remediation':
        'Environmental Protection - Soil Remediation',
    'request-information:application:environmental-protection:incineration-plants':
        'Environmental Protection - Incineration Plants',
    'request-information:application:environmental-protection:waste-dumps': 'Environmental Protection - Waste Dumps',
    'request-information:application:reagents:': 'Reagents',
    'request-information:application:printing-&-publishing:narrow-web': 'Printing & Publishing - Narrow Web',
    'request-information:application:printing-&-publishing:packaging-printing':
        'Printing & Publishing - Packaging Printing',
    'request-information:application:printing-&-publishing:distributors': 'Printing & Publishing - Distributors',
    'request-information:application:printing-&-publishing:printing-inks': 'Printing & Publishing - Printing Inks',
    'request-information:application:printing-&-publishing:flexography': 'Printing & Publishing - Flexography',
    'request-information:application:printing-&-publishing:bookbinding': 'Printing & Publishing - Bookbinding',
    'request-information:application:printing-&-publishing:sheet-fed-offset':
        'Printing & Publishing - Sheet Fed Offset',
    'request-information:application:printing-&-publishing:cold-set-offset': 'Printing & Publishing - Cold Set Offset',
    'request-information:application:printing-&-publishing:heat-set-offset': 'Printing & Publishing - Heat Set Offset',
    'request-information:application:printing-&-publishing:serigraphy': 'Printing & Publishing - Serigraphy',
    'request-information:application:printing-&-publishing:rotogravure': 'Printing & Publishing - Rotogravure',
    'request-information:application:printing-&-publishing:others': 'Printing & Publishing - Others',
    'request-information:application:loundries:': 'Loundries',
    'request-information:application:automotive-oems:': 'Automotive OEMs',
    'request-information:application:automotive-oems---service-companies:': 'Automotive OEMs - service companies',
    'request-information:application:machinery-production:measuring-instruments':
        'Machinery Production - Measuring Instruments',
    'request-information:application:machinery-production:mechanized-household-equipment':
        'Machinery Production - Mechanized Household Equipment',
    'request-information:application:machinery-production:others': 'Machinery Production - Others',
    'request-information:application:ceramic-industry:household-ceramic': 'Ceramic Industry - Household Ceramic',
    'request-information:application:ceramic-industry:others': 'Ceramic Industry - Others',
    'request-information:application:wood-industry-&-woodwork:furniture': 'Wood Industry & Woodwork - Furniture',
    'request-information:application:wood-industry-&-woodwork:woodwork': 'Wood Industry & Woodwork - Woodwork',
    'request-information:application:wood-industry-&-woodwork:wood-industry':
        'Wood Industry & Woodwork - Wood Industry',
    'request-information:application:wood-industry-&-woodwork:others': 'Wood Industry & Woodwork - Others',
    'request-information:application:pharma:distributors': 'Pharma - Distributors',
    'request-information:application:pharma:human-&-veterinary-medicines': 'Pharma - Human & Veterinary Medicines',
    'request-information:application:pharma:natural-remedies': 'Pharma - Natural Remedies',
    'request-information:application:pharma:pharmaceutical-starting-material-producers':
        'Pharma - Pharmaceutical Starting Material Producers',
    'request-information:application:pharma:others': 'Pharma - Others',
    'request-information:application:rubber:footwear': 'Rubber - Footwear',
    'request-information:application:rubber:distributors': 'Rubber - Distributors',
    'request-information:application:rubber:non-automotive-profiles': 'Rubber - Non Automotive Profiles',
    'request-information:application:rubber:automotive-profiles': 'Rubber - Automotive Profiles',
    'request-information:application:rubber:general-rubber-goods': 'Rubber - General Rubber Goods',
    'request-information:application:rubber:wire-&-cables': 'Rubber - Wire & Cables',
    'request-information:application:rubber:tires:automotive-tires': 'Rubber - Tires - Automotive Tires',
    'request-information:application:rubber:tires:industrial-tires': 'Rubber - Tires - Industrial Tires',
    'request-information:application:rubber:tires:others': 'Rubber - Tires - Others',
    'request-information:application:rubber:non-automotive-hoses': 'Rubber - Non Automotive Hoses',
    'request-information:application:rubber:automotive-hoses': 'Rubber - Automotive Hoses',
    'request-information:application:rubber:automotive-belts': 'Rubber - Automotive Belts',
    'request-information:application:rubber:non-automotive-belts': 'Rubber - Non Automotive Belts',
    'request-information:application:rubber:rubber-rolls-and-wheels': 'Rubber - Rubber Rolls and Wheels',
    'request-information:application:rubber:latex': 'Rubber - Latex',
    'request-information:application:rubber:custom-mixing': 'Rubber - Custom Mixing',
    'request-information:application:rubber:others': 'Rubber - Others',
    'request-information:application:cosmetics:distributors': 'Cosmetics - Distributors',
    'request-information:application:cosmetics:general': 'Cosmetics - General',
    'request-information:application:cosmetics:decorative-products-&-make-up':
        'Cosmetics - Decorative Products & Make-Up',
    'request-information:application:cosmetics:soap-&-candles': 'Cosmetics - Soap & Candles',
    'request-information:application:cosmetics:perfumes-&-fragrance': 'Cosmetics - Perfumes & Fragrance',
    'request-information:application:cosmetics:skin-care-and-sun-cosmetics': 'Cosmetics - Skin Care and Sun Cosmetics',
    'request-information:application:cosmetics:hair-care-and-body-wash': 'Cosmetics - Hair Care and Body Wash',
    'request-information:application:cosmetics:toll-manufacturers-&-contractors':
        'Cosmetics - Toll Manufacturers & Contractors',
    'request-information:application:cosmetics:others': 'Cosmetics - Others',
    'request-information:application:metal:zinc-coating---automotive-components':
        'Metal - Zinc Coating - Automotive Components',
    'request-information:application:metal:zinc-coating---non-automotive-components':
        'Metal - Zinc Coating - Non Automotive Components',
    'request-information:application:metal:distribution': 'Metal - Distribution',
    'request-information:application:metal:electroplating---automotive-components':
        'Metal - Electroplating - Automotive Components',
    'request-information:application:metal:electroplating---non-automotive-components':
        'Metal - Electroplating - Non Automotive Components',
    'request-information:application:metal:automotive-components---others': 'Metal - Automotive Components - Others',
    'request-information:application:metal:non-automotive-components---others':
        'Metal - Non Automotive Components - Others',
    'request-information:application:metal:vanishing---automotive-components':
        'Metal - Vanishing - Automotive Components',
    'request-information:application:metal:vanishing---non-automotive-components':
        'Metal - Vanishing - Non Automotive Components',
    'request-information:application:metal:foundry---automotive-components': 'Metal - Foundry - Automotive Components',
    'request-information:application:metal:foundry---non-automotive-components':
        'Metal - Foundry - Non Automotive Components',
    'request-information:application:pulp-&-paper:tissue-manufacturers': 'Pulp & Paper - Tissue Manufacturers',
    'request-information:application:pulp-&-paper:board-manufacturers': 'Pulp & Paper - Board Manufacturers',
    'request-information:application:pulp-&-paper:packaging-&-converting': 'Pulp & Paper - Packaging & Converting',
    'request-information:application:pulp-&-paper:paper-manufacturers': 'Pulp & Paper - Paper Manufacturers',
    'request-information:application:pulp-&-paper:others': 'Pulp & Paper - Others',
    'request-information:application:spirit-industry:': 'Spirit Industry',
    'request-information:application:food:chocolate-&-confection:confectionery':
        'Food - Chocolate & Confection - Confectionery',
    'request-information:application:food:chocolate-&-confection:chocolate':
        'Food - Chocolate & Confection - Chocolate',
    'request-information:application:food:food-general:sugar-factories': 'Food - Food General - Sugar Factories',
    'request-information:application:food:beverages:breweries,-malting': 'Food - Beverages - Breweries, malting',
    'request-information:application:food:beverages:alcoholic-beverages---others':
        'Food - Beverages - Alcoholic Beverages - Others',
    'request-information:application:food:beverages:non-alcoholic-beverages':
        'Food - Beverages - Non Alcoholic Beverages',
    'request-information:application:food:beverages:instant-drinks': 'Food - Beverages - Instant drinks',
    'request-information:application:food:bakery-&-bread:bars-and-cereals': 'Food - Bakery & Bread - Bars and cereals',
    'request-information:application:food:bakery-&-bread:bakery-mix-blenders':
        'Food - Bakery & Bread - Bakery Mix Blenders',
    'request-information:application:food:bakery-&-bread:baked-goods': 'Food - Bakery & Bread - Baked goods',
    'request-information:application:food:food-general:flavour-producers': 'Food - Food General - Flavour Producers',
    'request-information:application:food:food-general:other-ingredient-producers':
        'Food - Food General - Other Ingredient Producers',
    'request-information:application:food:meat-and-poultry:blends': 'Food - Meat and poultry - Blends',
    'request-information:application:food:meat-and-poultry:production': 'Food - Meat and poultry - Production',
    'request-information:application:food:meat-and-poultry:fish-industry': 'Food - Meat and poultry - Fish Industry',
    'request-information:application:food:meat-and-poultry:slaughterhouses':
        'Food - Meat and poultry - Slaughterhouses',
    'request-information:application:food:dairy-&-ice-cream:ice-cream': 'Food - Dairy & Ice Cream - Ice Cream',
    'request-information:application:food:dairy-&-ice-cream:dairy-ingredients':
        'Food - Dairy & Ice Cream - Dairy Ingredients',
    'request-information:application:food:dairy-&-ice-cream:dairy-consumer-products':
        'Food - Dairy & Ice Cream - Dairy Consumer Products',
    'request-information:application:food:food-general:starch-industry': 'Food - Food General - Starch Industry',
    'request-information:application:food:food-general:food---distributors':
        'Food - Food General - Food - Distributors',
    'request-information:application:food:food-general:blenders---other': 'Food - Food General - Blenders - Other',
    'request-information:application:food:food-general:fats-&-oils': 'Food - Food General - Fats & Oils',
    'request-information:application:food:food-general:tobacco': 'Food - Food General - Tobacco',
    'request-information:application:food:food-general:potato-processing': 'Food - Food General - Potato Processing',
    'request-information:application:food:fruit-&-vegetable-processing:jams,-fruit-preparations':
        'Food - Fruit & Vegetable Processing - Jams, fruit preparations',
    'request-information:application:food:fruit-&-vegetable-processing:fruit-concentrates':
        'Food - Fruit & Vegetable Processing - Fruit concentrates',
    'request-information:application:food:fruit-&-vegetable-processing:others':
        'Food - Fruit & Vegetable Processing - Others',
    'request-information:application:food:function-food,-supplements:food-supplements':
        'Food - Function food, supplements - Food supplements',
    'request-information:application:food:function-food,-supplements:sport-nutrition':
        'Food - Function food, supplements - Sport nutrition',
    'request-information:application:food:function-food,-supplements:baby-food':
        'Food - Function food, supplements - Baby food',
    'request-information:application:food:function-food,-supplements:special-food':
        'Food - Function food, supplements - Special food',
    'request-information:application:food:convenience-food:savoury---others':
        'Food - Convenience Food - Savoury - Others',
    'request-information:application:food:convenience-food:soups,-sauces,-dressings':
        'Food - Convenience Food - Soups, Sauces, Dressings',
    'request-information:application:food:convenience-food:convenience-food':
        'Food - Convenience Food - Convenience Food',
    'request-information:application:food:others': 'Food - Others',
    'request-information:application:glass-industry:household-glass-&-glass-packaging':
        'Glass Industry - Household Glass & Glass Packaging',
    'request-information:application:glass-industry:industrial-&-technical-glass':
        'Glass Industry - Industrial & Technical Glass',
    'request-information:application:glass-industry:automotive-glass': 'Glass Industry - Automotive Glass',
    'request-information:application:glass-industry:others': 'Glass Industry - Others',
    'request-information:application:textile-&-leather:carpets-&-linings': 'Textile & Leather - Carpets & Linings',
    'request-information:application:textile-&-leather:knitted-fabrics': 'Textile & Leather - Knitted Fabrics',
    'request-information:application:textile-&-leather:automotive-leather-components':
        'Textile & Leather - Automotive Leather Components',
    'request-information:application:textile-&-leather:leather-tanning': 'Textile & Leather - Leather Tanning',
    'request-information:application:textile-&-leather:leather-clothing,-footwear,-goods':
        'Textile & Leather - Leather Clothing, Footwear, Goods',
    'request-information:application:textile-&-leather:yarn-&-threads': 'Textile & Leather - Yarn & Threads',
    'request-information:application:textile-&-leather:tights': 'Textile & Leather - Tights',
    'request-information:application:textile-&-leather:natural-textiles': 'Textile & Leather - Natural Textiles',
    'request-information:application:textile-&-leather:synthetic-textiles': 'Textile & Leather - Synthetic Textiles',
    'request-information:application:textile-&-leather:unwoven-fabrics': 'Textile & Leather - Unwoven Fabrics',
    'request-information:application:textile-&-leather:others': 'Textile & Leather - Others',
    'request-information:application:oil-&-gas:downstream---refineries,-pipelines':
        'Oil & Gas - DownStream - Refineries, Pipelines',
    'request-information:application:oil-&-gas:downstream---chemical-plants':
        'Oil & Gas - DownStream - Chemical Plants',
    'request-information:application:oil-&-gas:distribution': 'Oil & Gas - Distribution',
    'request-information:application:oil-&-gas:midstream---specialty-gases': 'Oil & Gas - MidStream - Specialty Gases',
    'request-information:application:oil-&-gas:midstream---compounders': 'Oil & Gas - MidStream - Compounders',
    'request-information:application:oil-&-gas:midstream---n-spec': 'Oil & Gas - MidStream - N-Spec',
    'request-information:application:oil-&-gas:midstream---production': 'Oil & Gas - MidStream - Production',
    'request-information:application:oil-&-gas:midstream---gasplants': 'Oil & Gas - MidStream - Gasplants',
    'request-information:application:oil-&-gas:upstream---drilling': 'Oil & Gas - UpStream - Drilling',
    'request-information:application:oil-&-gas:services': 'Oil & Gas - Services',
    'request-information:application:oil-&-gas:others': 'Oil & Gas - Andere',
    'request-information:application:lubricants:asphalts': 'Lubricants - Asphalts',
    'request-information:application:lubricants:bio-diesel---producers': 'Lubricants - Bio Diesel - Producers',
    'request-information:application:lubricants:bio-ethanol---producers': 'Lubricants - Bio Ethanol - Producers',
    'request-information:application:lubricants:distribution': 'Lubricants - Distribution',
    'request-information:application:lubricants:blending-facilities': 'Lubricants - Blending Facilities',
    'request-information:application:lubricants:industrial-lubricants': 'Lubricants - Industrial Lubricants',
    'request-information:application:lubricants:automotive-lubricants': 'Lubricants - Automotive Lubricants',
    'request-information:application:lubricants:release-agents': 'Lubricants - Release Agents',
    'request-information:application:lubricants:others': 'Lubricants - Andere',
    'request-information:application:transportation:airports': 'Transportation - Airports',
    'request-information:application:transportation:others': 'Transportation - Others',
    'request-information:application:tourism:': 'Tourism',
    'request-information:application:polymers:distributors': 'Polymers - Distributors',
    'request-information:application:polymers:polyurethanes:automotive-components':
        'Polymers - Polyurethanes - Automotive Components',
    'request-information:application:polymers:polyurethanes:flexible-&-rigid-polyurethanes-':
        'Polymers - Polyurethanes - Flexible & Rigid Polyurethanes ',
    'request-information:application:polymers:polyurethanes:polyurethane-systems':
        'Polymers - Polyurethanes - Polyurethane Systems',
    'request-information:application:polymers:polyurethanes:others': 'Polymers - Polyurethanes - Others',
    'request-information:application:polymers:compounders': 'Polymers - Compounders',
    'request-information:application:polymers:raw-material-producers': 'Polymers - Raw Material Producers',
    'request-information:application:polymers:injection-moulding:non-automotive-components':
        'Polymers - Injection Moulding - Non Automotive Components',
    'request-information:application:polymers:injection-moulding:automotive-components':
        'Polymers - Injection Moulding - Automotive Components',
    'request-information:application:polymers:regranulates': 'Polymers - Regranulates',
    'request-information:application:polymers:extrusion:films': 'Polymers - Extrusion - Films',
    'request-information:application:polymers:extrusion:automotive-components':
        'Polymers - Extrusion - Automotive Components',
    'request-information:application:polymers:extrusion:flooring,-coated-textiles':
        'Polymers - Extrusion - Flooring, Coated Textiles',
    'request-information:application:polymers:extrusion:plates-&-profiles': 'Polymers - Extrusion - Plates & Profiles',
    'request-information:application:polymers:extrusion:pipes': 'Polymers - Extrusion - Pipes',
    'request-information:application:polymers:extrusion:polymers-textiles-&-fibers':
        'Polymers - Extrusion - Polymers Textiles & Fibers',
    'request-information:application:polymers:extrusion:others': 'Polymers - Extrusion - Others',
    'request-information:application:polymers:chemosets': 'Polymers - Chemosets',
    'request-information:application:polymers:thermosets': 'Polymers - Thermosets',
    'request-information:application:polymers:others': 'Polymers - Others',
    'request-information:application:services:': 'Services',
    'request-information:application:water-treatment:swimming-pools': 'Water Treatment - Swimming Pools',
    'request-information:application:water-treatment:municipalities-for-potable-water':
        'Water Treatment - Municipalities for Potable Water',
    'request-information:application:water-treatment:municipalities-for-potable-and-waste-water':
        'Water Treatment - Municipalities for Potable and Waste Water',
    'request-information:application:water-treatment:distributors': 'Water Treatment - Distributors',
    'request-information:application:water-treatment:environmental-&-equipment-companies':
        'Water Treatment - Environmental & Equipment Companies',
    'request-information:application:water-treatment:municipalities-for-waste-water':
        'Water Treatment - Municipalities for Waste Water',
    'request-information:application:water-treatment:water-conditioning-service-companies':
        'Water Treatment - Water Conditioning Service Companies',
    'request-information:application:water-treatment:institutional-service-companies':
        'Water Treatment - Institutional Service Companies',
    'request-information:application:water-treatment:other-water-treatment-service-companies':
        'Water Treatment - Other Water Treatment Service Companies',
    'request-information:application:water-treatment:other-water-treatment-end-users':
        'Water Treatment - Other Water Treatment end users',
    'request-information:application:water-treatment:others': 'Water Treatment - Others',
    'request-information:application:animal-nutrition:distributors': 'Animal Nutrition - Distributors',
    'request-information:application:animal-nutrition:homemixers-&-farmers:cattle-and-sheep':
        'Animal Nutrition - Homemixers & Farmers - Cattle and Sheep',
    'request-information:application:animal-nutrition:homemixers-&-farmers:poultry':
        'Animal Nutrition - Homemixers & Farmers - Poultry',
    'request-information:application:animal-nutrition:homemixers-&-farmers:pigs':
        'Animal Nutrition - Homemixers & Farmers - Pigs',
    'request-information:application:animal-nutrition:homemixers-&-farmers:others':
        'Animal Nutrition - Homemixers & Farmers - Others',
    'request-information:application:animal-nutrition:additive-blenders': 'Animal Nutrition - Additive Blenders',
    'request-information:application:animal-nutrition:mixed-production': 'Animal Nutrition - Mixed Production',
    'request-information:application:animal-nutrition:aquafeed': 'Animal Nutrition - Aquafeed',
    'request-information:application:animal-nutrition:compound-feed': 'Animal Nutrition - Compound Feed',
    'request-information:application:animal-nutrition:premix': 'Animal Nutrition - Premix',
    'request-information:application:animal-nutrition:milk-replacers': 'Animal Nutrition - Milk Replacers',
    'request-information:application:animal-nutrition:integrators': 'Animal Nutrition - Integrators',
    'request-information:application:animal-nutrition:feed-ingredients': 'Animal Nutrition - Feed Ingredients',
    'request-information:application:animal-nutrition:animal-waste-processing':
        'Animal Nutrition - Animal Waste Processing',
    'request-information:application:animal-nutrition:veterinarians': 'Animal Nutrition - Veterinarians',
    'request-information:application:animal-nutrition:petfood': 'Animal Nutrition - Petfood',
    'request-information:application:animal-nutrition:others': 'Animal Nutrition - Andere',
    'request-information:application:others:': 'Others',

    // languages
    'language:de': 'Deutsch',
    'language:en': 'Englisch',
    'language:fr': 'Französisch',
    'language:nl': 'Niederländisch',
    'language:pl': 'Polnisch',

    // countries
    'country:de': 'Deutschland',
    'country:en': 'England',
    'country:fr': 'Frankreich',
    'country:nl': 'Niederlande',
    'country:be': 'Belgien',
    'country:za': 'Südafrika',
    'country:ae': 'Vereinigte Arabische Emirate',
    'country:ke': 'Kenia',
    'country:tz': 'Tansania',
    'country:ug': 'Uganda',
    'country:pl': 'Polen',
    'country:a1': 'Polen',

    // Onboarding
    'onboarding:skip-tour.skip': 'Tour überspringen',
    'onboarding:skip-tour.close': 'Tour verlassen',
    'onboarding:slider-button.previous': 'Zurück',
    'onboarding:slider-button.next': 'Vor',
    'onboarding:slider-button.start': 'Start',
    'onboarding:slider-button.finish': "Los geht's",
    'onboarding:slide-sample.header': 'Mit Brenntag Connect können Sie:',
    'onboarding:slide-sample.title': 'Produktinformationen anfordern',
    'onboarding:slide-sample.content':
        'Produktinformationen direkt über Brenntag Connect anfordern. Diese beinhalteten Dokumente, Preise und mehr.',
    'onboarding:slide-order-status.header': 'Brenntag Connect bietet Ihnen:',
    'onboarding:slide-order-status.title': 'Ihre aktuellen und vorherigen Bestellungen und Rechnungen auf einem Blick.',
    'onboarding:slide-order-status.content':
        'Verfolgen Sie den Status Ihrer gegenwärtigen Bestellung und sehen Sie Ihre Bestellhistorie der letzten 24 Monate ein.',
    'onboarding:slide-introduction.title':
        'Willkommen bei Brenntag Connect – Ihr direkter Weg zu Brenntag’s Produkten und Leistungen.',
    'onboarding:slide-introduction.content':
        'Brenntag Connect bietet Ihnen mehr als Sie mit einem Blick erfassen können. <br/> <br/> Drücken Sie "Start", um eine kurze Tour zu starten.',
    'onboarding:slide-finish.title': 'Wir sind da, um Ihnen zu helfen!',
    'onboarding:slide-finish.content':
        'Die Navigation auf einer neuen Plattform kann anfangs ungewohnt sein. Wenn Sie Schwierigkeiten haben, ein Produkt zu finden, eine Bestellung zu platzieren oder Informationen auf Brenntag Connect anzufragen, laden wir Sie ein, die <a href="{faqLink}" target="_blank">FAQ</a> zu besuchen. Gerne können Sie jederzeit das <a href="mailto:{csrEmail}">Brenntag Connect Support Team</a> oder Ihren direkten Ansprechpartner bei Brenntag kontaktieren.',

    // welome videos titles
    'welcome-video-title:WELCOME': 'Willkommen',
    'welcome-video-title:FIRSTTIME': 'Erstmalige Anmeldung, Konten & Einstellungen',
    'welcome-video-title:ORDERS': 'Bestellungen',
    'welcome-video-title:INVOICES': 'Rechnungen',
    'welcome-video-title:PRODUCTS': 'Produkte',
    'welcome-video-title:REQUESTS': 'Anfragen',
    'welcome-video-title:FEEDBACK': 'Feedback',
    'welcome-video-title:QUOTES': 'Angebote',

    'welcome-video:headline': 'Möchten Sie wissen, wie Sie Brenntag Connect verwenden?',
    'welcome-video:instruction': 'Sehen Sie sich unser Video mit der Brenntag Connect-Demo an.',

    // Terms of Usage
    'terms-of-usage:headline': 'Nutzungsbedingungen',
    'terms-of-usage:decline': 'Zurück',
    'terms-of-usage:accept': 'Akzeptieren',
    'terms-of-usage:updated':
        'Wir haben unsere Nutzungsbedingungen aktualisiert. Bitte akzeptieren Sie diese, bevor Sie sich mit Brenntag Connect in Verbindung setzen. Die Nutzungsbedingungen sind jederzeit über einen Link in der Fußzeile der Anwendung zugänglich.',
    'terms-of-usage:content': `## Brenntag Connect - DigiB Kundenportal

Nutzungsbedingungen
DigiB B.V., eine nach niederländischem Recht gegründete Gesellschaft mit beschränkter Haftung (im Weiteren bezeichnet als der „Betreiber"), ist ein Unternehmen der Brenntag Gruppe, der Weltmarktführer in der Chemiedistribution, und betreibt die „Brenntag Connect"  Online-Service- und Informationsplattform (im Weiteren bezeichnet als „Portal"), die registrierten Nutzern (natürliche Personen, Nutzer desselben Unternehmens müssen sich einzeln registrieren, um die Zuweisung eines individuellen Kontos zu einer einzelnen natürlichen Person sicherzustellen) verschiedene Funktionen (soweit diese aktiviert sind) bietet, wie beispielsweise (Nach-) Bestellungen von und Anfragen nach Produkten über das Internet, Durchsicht der Bestellhistorie, Browsen nach neuen Produkten, Zugriff auf relevante Produktdokumente sowie auf auftragsbezogene Dokumente. Bei der Verwendung des Portals muss der Nutzer jederzeit die in diesen Nutzungsbedingungen dargelegten Grundsätze sowie die gesetzlichen Vorschriften beachten.

### 1. Geltungsbereich
1.1 Die folgenden Nutzungsbedingungen gelten ausschließlich für die Nutzung des Portals. Der Betreiber behält sich das Recht vor, diese Nutzungsbedingungen jederzeit mit sofortiger Wirkung für die Zukunft zu ändern.

1.2 Für alle möglichen aktuellen und künftigen E-Commerce-Geschäfte über das Portal gelten die allgemeinen Verkaufs- und Lieferbedingungen („Allgemeine Verkaufsbedingungen) des veräußernden Unternehmens der Brenntag Gruppe („Verkäufer"), die im Portal verfügbar sind. Gegenteilige Geschäftsbedingungen der Nutzer oder deren Kunden zu diesen Nutzungsbedingungen oder zu den Allgemeinen Verkaufsbedingungen gelten nur dann, wenn der Verkäufer gegebenenfalls deren Gültigkeit ausdrücklich schriftlich anerkennt (im Sinne dieser Bedingungen schließt „schriftlich" auch per E-Mail oder Fax ein).

### 2. Registrierung
2.1 Alle Nutzer, die an der Nutzung der Dienste des Portals interessiert sind, müssen, wenn sie nicht aktiv als bestehender Kunde eingeladen werden, einen entsprechenden Registrierungsantrag an den Betreiber senden. Der Nutzer übermittelt alle vom Betreiber geforderten Daten und versichert danach, dass diese Daten für die Dauer seines Antrags und/oder seiner Registrierung immer auf dem neuesten Stand gehalten werden. Wenn die geforderten Daten vollständig vorgelegt wurden, entscheidet der Betreiber allein, ob der Nutzer registriert wird. Der Nutzer wird vom Betreiber schriftlich über den Status der Registrierung informiert.

2.2 Der Betreiber hat das Recht, die Registrierung ohne Angabe von Gründen abzulehnen oder sie später wieder zurückzunehmen. Der Betreiber haftet nicht für verspätete oder abgelehnte Registrierungen.



### 3. Passwort und Verpflichtungen des Nutzers
3.1 Wenn die Registrierungsanfrage bestätigt wird, erhält jeder Nutzer einen individuellen Nutzernamen in Form seiner registrierten E-Mail-Adresse sowie ein individuelles Passwort. Ein Zugriff auf die Dienste des Portals ist nur mit der Kombination dieser beiden Zugangsdaten möglich.

3.2 Bei Verlust der Zugangsdaten oder wenn der berechtigte Verdacht besteht, dass Dritte sich unerwünscht Zugang zu den Log-In-Daten verschafft haben, ist der Nutzer verpflichtet, das Passwort unverzüglich zu ändern und den Betreiber schriftlich darüber zu informieren.

3.3 Der Nutzer ist verpflichtet, den Betreiber unverzüglich schriftlich darüber zu informieren, wenn andere Mitarbeiter oder Dritte (z. B. Berater), denen die unter Punkt 3.1 genannten Zugangsdaten bekannt waren, das jeweilige Unternehmen verlassen, jedoch spätestens zum Zeitpunkt der Beendigung des Beschäftigungsverhältnisses oder einer anderen Zusammenarbeit, um einen Missbrauch der Zugangsdaten des Nutzers auszuschließen. Jeglicher bekannte tatsächliche oder drohende Missbrauch des Portals ist dem Betreiber unverzüglich zu melden. In diesen Fällen registriert der Betreiber sofort neue Zugangsdaten oder mindestens ein neues Passwort für den Nutzer und informiert den Nutzer schriftlich darüber.

3.4 Bis zur Mitteilung einer solchen Beendigung oder eines Missbrauchs ist der Nutzer für alle veröffentlichten Erklärungen oder Inhalte oder für jegliches andere missbräuchliche Verhalten auf dem Portal durch einen Mitarbeiter oder Dritte, deren Beschäftigungsverhältnis beendet wird, verantwortlich und wird dafür haftbar gemacht.

3.5 Der Nutzer veröffentlicht keine Inhalte oder Beiträge, die a) auf irgendeine Weise die Rechte des Betreibers oder Dritter (insbesondere Namensrechte, persönliche Rechte, Markenrechte, Urheberrechte, Leistungsschutzrechte usw.) verletzen oder gefährden, b) illegal sind oder c) Viren, Umgehungsvorrichtungen oder unerwünschte Massen-E-Mails (Spam) enthalten. Der Nutzer beachtet alle bestehenden geistigen Eigentumsrechte und andere Eigentumsrechte Dritter und darf keine illegalen Vereinbarungen abschließen oder durch seine Verhaltensweisen oder durch verabredete Aktionen möglicherweise gegen Kartellrecht verstoßen.

3.6 Der Betreiber hat das Recht, veröffentlichte anstößige Inhalte zu löschen und den Nutzer von der weiteren Nutzung des Portals auszuschließen, insbesondere wenn unmoralische, illegale oder geschäftsschädigende Inhalte veröffentlicht werden.

3.7 Der Betreiber behält sich das Recht vor, Verstöße gegen geltendes Recht den Behörden des jeweiligen Landes, in dem der Geschäftssitz des Nutzers angesiedelt ist, zu melden. Außerdem behält sich der Betreiber das Recht vor, Schadensersatz zu verlangen und alle möglichen anderen Rechtsmittel auszuschöpfen, die ihm im Falle von Verstößen gegen die Nutzungsbedingungen zur Verfügung stehen.

### 4. Bestellanfrage / Erteilung von Produktbestellungen
4.1 Auf der Basis der Produktliste und der Preisangaben im Portal kann der Nutzer eine Bestellanfrage senden, um Produkte beim Verkäufer über das Portal (nach) zu bestellen (gemeinsam: „Bestellung"). Um den Bestellprozess abzuschließen, muss der Nutzer Produkt, Bestellmenge, Lieferort und Liefertermin auswählen. Danach muss vom Nutzer eine Referenznummer für die Bestellung (PO) eingegeben werden. Durch Anklicken der Schaltfläche „Absenden/ Senden" im Bereich Warenkorb (Checkout) wird der Auftrag im rechtlichen Sinne abgeschlossen und für den Nutzer verbindlich.

4.2 Nach Eingang der Bestellung prüft der Verkäufer, ob die Bestellanfrage machbar ist, lässt dem Nutzer eine Eingangsbestätigung zukommen und stellt entweder (i) eine Auftragsbestätigung, oder (ii) ein Gegenangebot im Falle möglicher Mengen- und/oder Lieferterminänderungen aus. Im Falle von (i) erfordern alle (Nach-)Bestellungen, die der Nutzer über das Portal erteilt, eine schriftliche Annahme/Bestätigung (E-Mail reicht) des Verkäufers oder durch Ausführung des Auftrags eine indirekte Annahme/Bestätigung, und im Falle von (ii) erfordern alle Gegenangebote eine schriftliche Annahme/Bestätigung (E-Mail reicht) des Nutzers oder eine indirekte Annahme/Bestätigung in Form der Bezahlung, um einen verbindlichen Liefervertrag herzustellen.

### 5. Löschung der Registrierung
Jeder Nutzer kann jederzeit die Löschung seiner Registrierung durch Zusenden eines entsprechenden schriftlichen Antrags an den Betreiber verlangen. Durch die Löschung der Registrierung (beziehungsweise die Mitteilung einer erfolgreichen Löschung) erlischt unverzüglich das Recht des Nutzers auf Beteiligung an den vom Portal angebotenen Diensten. Der Betreiber ist berechtigt, eine bestehende Registrierung ohne Angabe von Gründen zu löschen, wenn nach hinreichender Bewertung durch den Betreiber der Nutzer bei der Registrierung falsche Angaben gemacht hat oder gegen die Nutzerbedingungen oder gegen geltendes Recht verstößt. Der Betreiber informiert den jeweiligen Nutzer schriftlich über die Löschung der Registrierung. Im Falle einer Löschung der Registrierung löscht der Betreiber unverzüglich alle personenbezogenen Nutzerdaten. Der Betreiber haftet in keiner Weise für eine Löschung von Registrierungsprofilen.

### 6. Haftung
6.1 Die Haftung des Betreibers bezüglich der Funktion des Portals beschränkt sich auf grobe Fahrlässigkeit oder Vorsatz des Betreibers selbst, seiner rechtlichen Vertreter oder Erfüllungsgehilfen. Die vorstehende Bestimmung gilt nicht, wenn die Haftung auf der Verletzung von Leben, Körper oder Gesundheit basiert, oder im Falle einer schuldhaften Verletzung einer Pflicht, die für den Vertrag in dem Maße eine wesentliche Verletzung darstellt, dass sie das Erreichen des Zwecks des Vertrages (Kardinalspflicht) gefährdet, oder im Falle von Produkthaftung oder sonstigen Fällen von zwingender gesetzlicher Haftung. Ausgenommen im Falle von Vorsatz, grober Fahrlässigkeit und Schadensersatz für Verletzungen von Leben, Körper oder Gesundheit, ist die Haftung insgesamt auf den üblicherweise vorhersehbaren Schaden zum Zeitpunkt der Registrierung des Nutzers beschränkt und übersteigt in keinem Falle 100.000 EUR pro Vorfall und Jahr, wobei in jedem Fall die Haftung für indirekte Schäden, Kollateralschäden, Folgeschäden und bloße Vermögensschäden sowie entgangene Gewinne ausgeschlossen ist.

6.2 Der Nutzer haftet für alle Aktivitäten, die unter Verwendung seines Nutzerkontos und/oder den bereitgestellten Daten über den Zweck des Portals hinaus durchgeführt werden. Der Nutzer ergreift alle wirtschaftlich angemessenen Maßnahmen, um a) unberechtigten Zugriff auf sein Nutzerkonto und/oder die bereitgestellten Daten zu verhindern, und b) die Einhaltung aller vertraglichen Verpflichtungen gegenüber dem Betreiber sowie aller geltenden gesetzlichen Vorschriften durch seine eigenen Handlungen, oder durch die Handlungen seiner gesetzlichen Vertreter, Erfüllungsgehilfen oder von sonstigen Dritten sicherzustellen, und c) die Unbedenklichkeit der für das Portal bereitgestellten Daten bezüglich Cybergefahren, wie beispielsweise Schadsoftware, Virenangriffen, usw. sicherzustellen. Die Haftung des Nutzers wird ausgeschlossen, wenn ein solcher Missbrauch oder ein solcher Angriff auf das Portal durch Umstände verursacht wurde, die außerhalb der zumutbaren Kontrolle des Nutzers liegen.

### 7. Haftungsfreistellung
Der Nutzer stellt den Betreiber von allen durch andere Nutzer oder Dritte gegenüber dem Betreiber geltend gemachten Ansprüchen aufgrund einer Verletzung ihrer Rechte frei und hält ihn schadlos, wenn diese Verletzung durch schuldhaftes Handeln oder Unterlassungen gemäß Punkt 6.2 verursacht wurde. Der Nutzer trägt auch die dem Betreiber auf angemessene Weise für die notwendige Rechtsverteidigung entstandenen Kosten, einschließlich aller Gerichts- und Anwaltsgebühren in gesetzlicher Höhe. Der Nutzer ist verpflichtet, den Betreiber sofort, wahrheitsgetreu und vollständig mit allen für die Bewertung eines Anspruchs und die Verteidigung notwendigen Informationen zu versorgen, falls Ansprüche von Dritten geltend gemacht werden.

### 8. Garantie
8.1 Der Betreiber gibt keine Garantien ab und übernimmt keinerlei Haftung, dass a) die geschäftlichen Informationen im Portal vollständig und richtig sind („ohne Gewähr" bereitgestellt), und b) das Portal frei von technischen Mängeln ist, insbesondere bezüglich i) der ständigen und ununterbrochenen Verfügbarkeit des Portals und seiner Inhalte, oder ii) der vollständigen und richtigen Anzeige/Wiedergabe der vom Nutzer veröffentlichten Inhalte. Der Betreiber bietet die Dienste des Portals kostenlos an, ohne eine gesetzliche Verpflichtung, den Betrieb aufrecht zu erhalten. Der Betreiber behält sich das Recht vor jederzeit a) den Betrieb des Portals vollständig oder teilweise, vorübergehend oder dauerhaft zu jeder Zeit ohne Vorankündigung einzustellen, oder b) die Nutzung des Portals an Gebührenzahlungen durch den Nutzer zu knüpfen.

8.2 Der Betreiber übernimmt keine im Portal dargestellten Inhalte Dritter als seine eigenen.

8.3 Der Betreiber haftet in keiner Weise für dem Nutzer entstandenen Schaden, der durch das Vertrauen auf die Richtigkeit, Vollständigkeit und Unbedenklichkeit von im Portal dargestellten Inhalten Dritter verursacht wurde.

### 9. Datennutzung und Datenschutz
Informationen hinsichtlich Kategorie, Umfang, Ort und Zweck der Erhebung, Verarbeitung und Nutzung personenbezogener Daten, die für die Registrierung und Abschluss geschäftlicher Transaktionen benötigt werden, finden Sie in unserer [Datenschutzerklärung]({dataPrivacyLink}).

### 10. Abschließende Bestimmungen

10.1 Alle Angelegenheiten bezüglich der Nutzung und Auslegung der Nutzungsbedingungen des Portals sowie alle in diesem Zusammenhang oder daraus entstehenden Streitigkeiten unterliegen niederländischem Recht mit Ausnahme des Kollisionsrecht und des Übereinkommens der vereinten Nationen über Verträge über den internationalen Warenkauf (CISG).

10.2 Gerichtsstand für alle Streitigkeiten ist Amsterdam, Niederlande. Dies gilt auch, wenn der Nutzer keinen allgemeinen Gerichtsstand in den Niederlanden oder der EU hat oder sein Wohnsitz oder gewöhnlicher Aufenthaltsort zum Zeitpunkt der Klageeinreichung unbekannt ist.

(Status: 29.08.2019)`,
}

export default messages
