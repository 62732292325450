import React from 'react'

import { Text } from '@bc/ui/'
import { GQLMaterial } from '@bc/types'

interface MaterialPackagingProps {
    material: GQLMaterial | undefined
}

export const MaterialPackaging = ({ material }: MaterialPackagingProps) =>
    material?.packaging?.details || material?.packaging?.name ? (
        <Text small fontWeight="bold" color="neutral50" data-test-class="text-product-name-packaging-details">
            {material.packaging.details ?? material.packaging.name}
        </Text>
    ) : null
