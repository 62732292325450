export interface GTMBaseOrderData {
    orderId?: string
    customerPoReference?: string
    requestedDeliveryDate?: string
    price?: number
    pricePerUnit?: number
    packagingName?: string
    packagingUom?: string
    orderAmount: number
    orderUom?: string
    orderQuantity: number
    tax: number
    currency?: string
    quoteId?: string
    shipToId?: string
}

export enum SurchargeCode {
    ZPR2 = 'ZPR2',
    ZBA2 = 'ZBA2',
    ZPRS = 'ZPRS',
    ZPF0 = 'ZPF0',
    ZVEM = 'ZVEM',
    ZVER = 'ZVER',
    ZVE2 = 'ZVE2',
    ZVKM = 'ZVKM',
    ZM01 = 'ZM01',
    ZM00 = 'ZM00',
    ZSOK = 'ZSOK',
    ZSO1 = 'ZSO1',
    ZVP0 = 'ZVP0',
    ZMAU = 'ZMAU',
    ZMA2 = 'ZMA2',
    ZTP1 = 'ZTP1',
    ZTPS = 'ZTPS',
    ZSTD = 'ZSTD',
    ZST1 = 'ZST1',
    ZABK = 'ZABK',
    ZAB1 = 'ZAB1',
    ZLG1 = 'ZLG1',
    ZLG2 = 'ZLG2',
    ZFLG = 'ZFLG',
    ZGVS = 'ZGVS',
    ZGV1 = 'ZGV1',
    Z110 = 'Z110',
    Z111 = 'Z111',
    Z112 = 'Z112',
    Z15P = 'Z15P',
    ZRAB = 'ZRAB',
    ZRB1 = 'ZRB1',
    ZKA2 = 'ZKA2',
    ZCVH = 'ZCVH',
    ZCVM = 'ZCVM',
    ZSKT = 'ZSKT',
    ZPRB = 'ZPRB',
    ZPRV = 'ZPRV',
    ZVPR = 'ZVPR',
    ZSTA = 'ZSTA',
    ZSTM = 'ZSTM',
    ZB10 = 'ZB10',
    ZB11 = 'ZB11',
    ZB12 = 'ZB12',
    ZB14 = 'ZB14',
    ZB15 = 'ZB15',
    ZSUB = 'ZSUB',
    ZSUP = 'ZSUP',
    ZDCL = 'ZDCL',
    ZWEG = 'ZWEG',
    ZTUK = 'ZTUK',
    ZTRA = 'ZTRA',
    ZF00 = 'ZF00',
    ZLFR = 'ZLFR',
    ZVPP = 'ZVPP',
    ZVPL = 'ZVPL',
    ZVP1 = 'ZVP1',
    ZVKD = 'ZVKD',
    ZVK1 = 'ZVK1',
    ZUKE = 'ZUKE',
    ZUKM = 'ZUKM',
    ZUKA = 'ZUKA',
    ZSLK = 'ZSLK',
    ZALG = 'ZALG',
    ZLKW = 'ZLKW',
    ZLA1 = 'ZLA1',
    ZLA2 = 'ZLA2',
    ZBKO = 'ZBKO',
    ZVKO = 'ZVKO',
    ZDIK = 'ZDIK',
    ZDK1 = 'ZDK1',
    ZDK2 = 'ZDK2',
    ZSLO = 'ZSLO',
    ZNLK = 'ZNLK',
    ZZKO = 'ZZKO',
    ZVBR = 'ZVBR',
    ZABS = 'ZABS',
    MWST = 'MWST',
    SKTO = 'SKTO',
    ZVOC = 'ZVOC',
    ZIVU = 'ZIVU',
    GRWR = 'GRWR',
    ZMW1 = 'ZMW1',
    ZMW2 = 'ZMW2',
    ZMW3 = 'ZMW3',
    ZUWP = 'ZUWP',
}

export type GetOrdersQuerySort = 'orderNumber' | 'customerReference' | 'orderDate' | 'shipTo.name' | 'lastModified'
