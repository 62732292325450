import { auth0 } from '../install'

export const refreshToken = (): Promise<auth0.Auth0DecodedHash> =>
    new Promise((resolve, reject) => {
        if (!auth0.client) {
            throw new Error()
        }

        auth0.client.checkSession({}, (error, data: auth0.Auth0DecodedHash) => {
            if (error) {
                reject(error)
            } else {
                resolve(data)
            }
        })
    })
