import React from 'react'
import styled from 'styled-components'
import { SvgIconName, SvgIcon } from '../../components/svg'
import { Text } from '../../components/text'
import { ColorValue, spacing, colors } from '../../config'

interface NotificationMessageProps {
    children?: React.ReactNode
    iconColor?: ColorValue
    backgroundColor?: ColorValue
    color?: ColorValue
    borderColor?: ColorValue
    icon?: SvgIconName
}

const MessageWrapper = styled.div<NotificationMessageProps>`
    display: flex;
    width: 100%;
    align-items: stretch;
    ${({ borderColor }) => (borderColor ? `border: 1px solid ${borderColor};` : '')}
    ${({ backgroundColor }) => (backgroundColor ? `background-color:  ${backgroundColor};` : '')}
`

const MessageIconBox = styled.div<NotificationMessageProps>`
    display: flex;
    width: 50px;
    min-width: 50px;
    align-items: center;
    justify-content: center;
    ${({ color }) => (color ? `color: ${color};` : '')}
    ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
`

const MessageText = styled(Text)<NotificationMessageProps>`
    padding: ${spacing.xxs}px;
    ${({ color }) => (color ? `color: ${color};` : '')}
    ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor};` : '')}
`

/**
 * @param root0
 * @param root0.children
 * @example
 */
export const NotificationMessage = ({
    children,
    icon,
    iconColor = colors.deepPurple,
    color = colors.deepPurple,
    backgroundColor,
    borderColor,
}: NotificationMessageProps) => (
    <MessageWrapper borderColor={borderColor} backgroundColor={backgroundColor}>
        {icon && (
            <MessageIconBox>
                <SvgIcon icon={icon} fill={iconColor} />
            </MessageIconBox>
        )}
        <MessageText color={color} noMargin data-test-id="notification-message">
            {children}
        </MessageText>
    </MessageWrapper>
)
