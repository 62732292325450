import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Grid: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Grid" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M3 11H11V3H3V11ZM9 9H5V5H9V9ZM3 21H11V13H3V21ZM9 19H5V15H9V19ZM21 11H13V3H21V11ZM15 9H19V5H15V9ZM13 21H21V13H13V21ZM19 19H15V15H19V19Z"
                fill={fill === 'gradient' ? 'url(#Grid_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Grid" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Grid" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M3 11H11V3H3V11ZM9 9H5V5H9V9ZM3 21H11V13H3V21ZM9 19H5V15H9V19ZM21 11H13V3H21V11ZM15 9H19V5H15V9ZM13 21H21V13H13V21ZM19 19H15V15H19V19Z"
                fill={fill === 'gradient' ? 'url(#Grid_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Grid" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Grid" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M3 11H11V3H3V11ZM9 9H5V5H9V9ZM3 21H11V13H3V21ZM9 19H5V15H9V19ZM21 11H13V3H21V11ZM15 9H19V5H15V9ZM13 21H21V13H13V21ZM19 19H15V15H19V19Z"
                fill={fill === 'gradient' ? 'url(#Grid_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Grid" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Grid" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M3 11H11V3H3V11ZM9 9H5V5H9V9ZM3 21H11V13H3V21ZM9 19H5V15H9V19ZM21 11H13V3H21V11ZM15 9H19V5H15V9ZM13 21H21V13H13V21ZM19 19H15V15H19V19Z"
                fill={fill === 'gradient' ? 'url(#Grid_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Grid" />}
        </g>
    ),
}
