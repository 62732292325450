import React from 'react'

import { colors } from '@bc/ui/src/config'

import { SvgIconMap } from '../types'

const defaultFill = colors.neutral50

export const status_price: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-price"
            d="M22,6v8h-2V8h-6V6H22z M13,13.586l-4.5-4.5l-7.207,7.207l1.414,1.414L8.5,11.914l4.5,4.5l5-5V10h-1.414
	        L13,13.586z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-price"
            d="M22,6v8h-2V8h-6V6H22z M13,13.586l-4.5-4.5l-7.207,7.207l1.414,1.414L8.5,11.914l4.5,4.5l5-5V10h-1.414
	        L13,13.586z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-price"
            d="M22,6v8h-2V8h-6V6H22z M13,13.586l-4.5-4.5l-7.207,7.207l1.414,1.414L8.5,11.914l4.5,4.5l5-5V10h-1.414
	        L13,13.586z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-price"
            d="M22,6v8h-2V8h-6V6H22z M13,13.586l-4.5-4.5l-7.207,7.207l1.414,1.414L8.5,11.914l4.5,4.5l5-5V10h-1.414
	        L13,13.586z"
            transform="scale(0.5)"
        />
    ),
}
