import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Parcel_Check: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Parcel_Check" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M14.0002 4.7202L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L14.0002 2.6102V4.7202ZM18.4991 7.91004L15.7891 5.21004L17.2091 3.79004L18.4991 5.09004L21.2891 2.29004L22.7091 3.71004L18.4991 7.91004ZM20 9.99986V17.2799L13 19.6099V10.7199L16 9.71986V7.60986L12 8.93986L2 5.60986V18.7199L12 22.0499L22 18.7199V9.99986H20ZM4 8.38986L11 10.7199V19.6099L4 17.2799V8.38986Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Check_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Check" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Parcel_Check" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M14.0002 4.7202L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L14.0002 2.6102V4.7202ZM18.4991 7.91004L15.7891 5.21004L17.2091 3.79004L18.4991 5.09004L21.2891 2.29004L22.7091 3.71004L18.4991 7.91004ZM20 9.99986V17.2799L13 19.6099V10.7199L16 9.71986V7.60986L12 8.93986L2 5.60986V18.7199L12 22.0499L22 18.7199V9.99986H20ZM4 8.38986L11 10.7199V19.6099L4 17.2799V8.38986Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Check_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Check" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Parcel_Check" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M14.0002 4.7202L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L14.0002 2.6102V4.7202ZM18.4991 7.91004L15.7891 5.21004L17.2091 3.79004L18.4991 5.09004L21.2891 2.29004L22.7091 3.71004L18.4991 7.91004ZM20 9.99986V17.2799L13 19.6099V10.7199L16 9.71986V7.60986L12 8.93986L2 5.60986V18.7199L12 22.0499L22 18.7199V9.99986H20ZM4 8.38986L11 10.7199V19.6099L4 17.2799V8.38986Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Check_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Check" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Parcel_Check" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M14.0002 4.7202L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L14.0002 2.6102V4.7202ZM18.4991 7.91004L15.7891 5.21004L17.2091 3.79004L18.4991 5.09004L21.2891 2.29004L22.7091 3.71004L18.4991 7.91004ZM20 9.99986V17.2799L13 19.6099V10.7199L16 9.71986V7.60986L12 8.93986L2 5.60986V18.7199L12 22.0499L22 18.7199V9.99986H20ZM4 8.38986L11 10.7199V19.6099L4 17.2799V8.38986Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Check_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Check" />}
        </g>
    ),
}
