import * as React from 'react'

import { PageError } from './page-error'

interface ErrorBounderyState {
    hasError: boolean
}

export class ErrorBoundary extends React.Component<any, ErrorBounderyState> {
    public state = {
        hasError: false,
        stack: '',
    }

    public static getDerivedStateFromError = (
        error: Error, // Update state so the next render will show the fallback UI.
    ) => ({ hasError: true, stack: error.stack })

    public componentDidCatch(error: Error, info: any) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info)
        this.setState(state => ({ ...state, stack: error.stack }))
        console.error(info)
    }

    public render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <PageError title="" text={this.state.stack} generic />
        }

        return this.props.children
    }
}
