// Import individually to fix circular dependencies
import { Locale } from '@bc/translations'
import { logout, parseHash, refreshToken } from '@frontend/utils/auth/services'
import { isBrowser } from '@frontend/utils/is-browser'
import { UniCookie } from '@frontend/utils/isomorphic-cookie'
import { jsonParse } from '@frontend/utils/json-parse'

export interface SessionState {
    cookieConsentAccept: string
    accessToken: string | undefined
    isTokenExpired: boolean | undefined
    tokenExpiry: string | undefined
    defaultShipTo: string | undefined
    defaultBillTo: string | undefined
    loginRedirectUrl: string
    activeCustomer: string | undefined
    activeSalesOrganisation: string | undefined
    activeSalesAreaId: string | undefined
    locale: Locale | undefined
}

export const defaultSessionState: SessionState = {
    cookieConsentAccept: '',
    accessToken: '',
    isTokenExpired: undefined,
    tokenExpiry: '',
    defaultShipTo: undefined,
    defaultBillTo: undefined,
    loginRedirectUrl: '',
    activeCustomer: undefined,
    locale: undefined,
    activeSalesOrganisation: undefined,
    activeSalesAreaId: undefined,
}
export type SessionCartNotifyFunc = (data: SessionState) => void

export interface SessionStoreConstructorArgs {
    initialState?: SessionState
}
export class SessionStore {
    private state: SessionState = defaultSessionState
    private notifyExternal: SessionCartNotifyFunc
    /**
     * @private
     * @param cookies
     * @summary Readfs the initial state from cookies.
     * @param void.
     * @returns Void.
     * @example
     */
    private static getInitialState = (cookies?: any) =>
        Object.keys(defaultSessionState).reduce((state, key) => {
            const value = UniCookie.get(key, cookies)
            if (value) {
                return {
                    ...state,
                    [key]: value,
                }
            }
            return state
        }, defaultSessionState) as SessionState

    public constructor({ initialState }: SessionStoreConstructorArgs = {}) {
        this.state = initialState ?? SessionStore.getInitialState()
    }

    public enrichWithCookies(cookies: any) {
        this.state = SessionStore.getInitialState(cookies)
        this.notify()
    }

    /**
     * @private
     * @summary Notify proxy.
     * @param void.
     * @returns Void.
     * @example
     */
    private notify() {
        if (typeof this.notifyExternal === 'function') {
            this.notifyExternal(this.getState())
        }
    }

    /**
     * @private
     * @summary Sets an update to the state.
     * @param stateUpdate: - Partial<SessionState>.
     * @returns Void.
     */

    private setState(stateUpdate: Partial<SessionState>) {
        this.state = { ...this.state, ...stateUpdate }
    }

    /**
     * @public
     * @summary Gets the copy of the current state.
     * @param void
     * @returns SessionState.
     */

    public getState = (): SessionState => ({ ...this.state })

    public addNotifier(notify: SessionCartNotifyFunc) {
        this.notifyExternal = notify
        this.notify()
    }

    /**
     * @public
     * @summary Sets the value to the state.
     * @param key:string, - Key in the state object.
     * @param value:SessionState[key], -  Value  of the state.
     * @param expires: - Number, of seconds to expire after.
     * @param domain: - Boolean, set a cookie to this domain only.
     * @returns SessionState.
     */

    public set<K extends keyof SessionState>(key: K, value: SessionState[K], expires?: number, domain?: boolean): void {
        this.setCookie(key, value, expires, domain)
        this.setState({ [key]: value })
        this.notify()
    }

    /**
     * @public
     * @summary Sets the value to the cookkie.
     * @param key:string, - Key for the cookie.
     * @param value:SessionState[key], -  Value  of the cookie.
     * @param expires: - Number, of seconds to expire after.
     * @param domain: - Boolean, set a cookie to this domain only.
     * @returns SessionState.
     */

    public setCookie<K extends keyof SessionState>(key: K, value: SessionState[K], expires?: number, domain?: boolean) {
        if (isBrowser) {
            UniCookie.set(key, String(value), expires, domain)
        }
    }

    /**
     * @public
     * @param key
     * @summary Removes the key/value from the store.
     * @param key: - Keyof SessionState.
     * @returns Void.
     * @example
     */
    public remove<K extends keyof SessionState>(key: K, isNotify: boolean | undefined = true) {
        this.removeCookie(key)
        this.setState({ [key]: defaultSessionState[key] })
        if (isNotify) {
            this.notify()
        }
    }

    /**
     * @public
     * @param key
     * @summary Removes the key/value from the cookies.
     * @param key: - Keyof SessionState.
     * @returns Void.
     * @example
     */
    public removeCookie<K extends keyof SessionState>(key: K) {
        if (isBrowser) {
            UniCookie.remove(key)
        }
    }

    /**
     * @public
     * @getter
     * @summary Checks if the user is authenticated by the access token.
     * @param void
     * @returns Boolean.
     */
    public get isAuthenticated() {
        if (!this.state.accessToken) {
            return false
        }

        this.checkTokenExpiracy()

        return !this.isTokenExpired
    }

    /**
     * @public
     * @summary Checks if the access token has expired and clear session if yes.
     * @param void
     * @returns Boolean.
     * @example
     */
    public checkTokenExpiracy() {
        const isTokenExpired = this.isTokenExpired

        if (isTokenExpired !== this.state.isTokenExpired) {
            // Forces re-render
            this.setState({ isTokenExpired })

            if (isTokenExpired) {
                this.clearSession()
            }
        }
    }

    /**
     * @public
     * @getter
     * @summary Checks how miuhc time left until the access token expires.
     * @param void
     * @returns Number, of seconds.
     */
    public get tokenTimeLeft() {
        return new Date().getTime() - jsonParse(this.state.tokenExpiry)
    }

    /**
     * @public
     * @getter
     * @summary Checks if the token has alrady expired.
     * @param void
     * @returns Boolean.
     */
    public get isTokenExpired() {
        return new Date().getTime() > jsonParse(this.state.tokenExpiry)
    }

    /**
     * @public
     * @summary Refreshes user access token.
     * @param void
     * @returns Hash: Auth0DecodedHash.
     * @example
     */
    public async refreshToken(): Promise<auth0.Auth0DecodedHash> {
        const decodedHash = await refreshToken()
        return this.storeUser(decodedHash)
    }

    /**
     * @public
     * @async
     * @summary Parses Auth0 hash and saves it to the user state.
     * @param void
     * @returns Hash: Auth0DecodedHash.
     * @example
     */
    public async handleLogin(): Promise<auth0.Auth0DecodedHash> {
        const decodedHash = await parseHash()
        return this.storeUser(decodedHash)
    }

    /**
     * @public
     * @param hash
     * @summary Stores Auth0 hash in the user state.
     * @param hash: - Auth0DecodedHash.
     * @returns Hash: Auth0DecodedHash.
     * @example
     */
    public storeUser(hash: auth0.Auth0DecodedHash): auth0.Auth0DecodedHash {
        const { accessToken, expiresIn = 0 } = hash

        if (!accessToken) {
            return hash
        }

        const tokenExpiry = JSON.stringify(expiresIn * 1000 + new Date().getTime())

        this.set('accessToken', accessToken)
        this.set('tokenExpiry', tokenExpiry)

        return hash
    }

    /**
     * @public
     * @summary sets state and cookies for the active customer.
     * @param void.
     * @returns Void.
     * @example
     */
    public setActiveCustomer({ cid, so, sid }: { cid: string; so: string; sid: string }) {
        this.set('activeCustomer', cid)
        this.set('activeSalesOrganisation', so)
        this.set('activeSalesAreaId', sid)
    }

    /**
     * @public
     * @summary Removes state and cookies for the active customer.
     * @param void.
     * @returns Void.
     * @example
     */
    public removeActiveCustomer() {
        this.remove('activeCustomer')
        this.remove('activeSalesOrganisation')
        this.remove('activeSalesAreaId')
    }

    /**
     * @public
     * @summary Clears the session and log the user out as a result.
     * @param void.
     * @returns Void.
     * @example
     */
    public logout() {
        this.clearSession()
        logout()
    }

    /**
     * @public
     * @summary Clears the session: acive customer, token etc.
     * @param void.
     * @returns Void.
     * @example
     */
    public clearSession() {
        this.removeActiveCustomer()
        this.removeCookie('tokenExpiry')
        this.removeCookie('accessToken')
    }

    public clearSessionNoNotify() {
        const NOTIFY_CONTEXT = false
        this.remove('activeCustomer', NOTIFY_CONTEXT)
        this.remove('activeSalesOrganisation', NOTIFY_CONTEXT)
        this.remove('activeSalesAreaId', NOTIFY_CONTEXT)
        this.remove('tokenExpiry', NOTIFY_CONTEXT)
        this.remove('accessToken', NOTIFY_CONTEXT)
    }
}
