import React, { useContext, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { GQLBaseOrderLine } from '@bc/types'
import { Button, EmptyState, OxygenTemplate, useDocumentTitle } from '@bc/ui'
import { useFormatMessage } from '@frontend/components'
import { ShoppingCartContext, ShoppingCartContextData } from '@frontend/context'

type CombinedProps = RouteComponentProps<undefined>

export const SessionExpiredPage = ({ location, history }: CombinedProps) => {
    const t = useFormatMessage()
    useDocumentTitle('Login')
    const [shoppingStore]: ShoppingCartContextData<GQLBaseOrderLine> = useContext(ShoppingCartContext)

    useEffect(() => {
        shoppingStore.clear()
    }, [])

    const handleOnClick = () => history.replace('/login', location.state)

    return (
        <OxygenTemplate>
            <EmptyState title={t('session-expired:header')} text={t('session-expired:text')} center>
                <Button onClick={handleOnClick} variant="action" title={t('session-expired:login-button')}>
                    {t('session-expired:login-button')}
                </Button>
            </EmptyState>
        </OxygenTemplate>
    )
}
