import { useEffect } from 'react'

const isBrowser = typeof window !== 'undefined'

export const useDocumentTitle = (title: string) => {
    const metatag = isBrowser && (window.document.getElementsByName('title')[0] as HTMLMetaElement)
    useEffect(() => {
        if (isBrowser) {
            document.title = title
        }
        if (metatag) {
            metatag.content = title
        }
    }, [title])
}
