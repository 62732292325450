import { ToastsContext, ToastsContextData } from '@frontend/context'
import React, { useContext } from 'react'

import { FileInput } from '@bc/ui/src/components/form-elements/upload-files'
import { useFormatMessage } from '@frontend/components/hooks/intl-hooks'

interface AttachFileFieldProps {
    change: any
}

export const AttachFileField = ({ change }: AttachFileFieldProps) => {
    const t = useFormatMessage()

    const [toastsStore]: ToastsContextData = useContext(ToastsContext)

    const mimeTypes = ['image/jpeg', 'image/png', 'application/pdf']

    const isValidFile = ({ name, size, type }: File): boolean => {
        const maxFileSize = 5 * 1024 * 1024
        // Filter max file-size
        if (size > maxFileSize) {
            toastsStore.addToast({
                message: t('requests:file-too-big', undefined, { name }),
                type: 'error',
            })
            return false
        }

        // Filter allowed mime types
        if (!mimeTypes.includes(type)) {
            toastsStore.addToast({
                message: t('requests:mime-types', undefined, {
                    name,
                    type,
                }),
                type: 'error',
            })
            return false
        }

        return true
    }

    const updateFile = (f?: File) => {
        const newFile = f && isValidFile(f) ? f : undefined
        return change('file', newFile)
    }

    return (
        <FileInput
            multiple={false}
            accept={mimeTypes.join(',')}
            title={t('general:attach-document')}
            change={(files: FileList) => updateFile(files[0])}
        />
    )
}
