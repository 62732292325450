import { useEffect, useState } from 'react'
import { breakpoints } from '@bc/ui'
import { isBrowser } from '@frontend/utils/is-browser'

export const useMobile = () => {
    const isMobileFn = () => isBrowser && window.innerWidth < breakpoints.md
    const [isMobile, setIsMobile] = useState(true)

    useEffect(() => {
        const resize = () => setIsMobile(isMobileFn())
        if (isBrowser) {
            window.addEventListener('resize', resize)
        }

        return () => {
            if (isBrowser) {
                window.removeEventListener('resize', resize)
            }
        }
    })

    useEffect(() => {
        setIsMobile(isMobileFn())
    }, [])

    return isMobile
}
