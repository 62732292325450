import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Briefcase: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Briefcase" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M7 9H22V7H17V3H7V7H2V21H22V11H20V19H4V9H7ZM9 5H15V7H9V5Z"
                fill={fill === 'gradient' ? 'url(#Briefcase_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Briefcase" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Briefcase" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M7 9H22V7H17V3H7V7H2V21H22V11H20V19H4V9H7ZM9 5H15V7H9V5Z"
                fill={fill === 'gradient' ? 'url(#Briefcase_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Briefcase" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Briefcase" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M7 9H22V7H17V3H7V7H2V21H22V11H20V19H4V9H7ZM9 5H15V7H9V5Z"
                fill={fill === 'gradient' ? 'url(#Briefcase_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Briefcase" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Briefcase" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M7 9H22V7H17V3H7V7H2V21H22V11H20V19H4V9H7ZM9 5H15V7H9V5Z"
                fill={fill === 'gradient' ? 'url(#Briefcase_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Briefcase" />}
        </g>
    ),
}
