import React from 'react'

import { GQLAddress, GQLLocation } from '@bc/types'

const capitalize = (what: string | undefined) =>
    what
        ? what
              .toLowerCase()
              .replace(/[a-zA-Z\u00C0-\u024C]{3,}/g, match =>
                  match.replace(/[a-zA-Z\u00C0-\u024C]/, m => m.toUpperCase()),
              )
        : ''

export const getJSXAddress = ({ name, street, postalCode, city, country }: GQLAddress): JSX.Element => (
    <>
        {name} {name && <br />}
        {street} <br />
        {postalCode} {capitalize(city)}
        {', '}
        {country}
    </>
)

export const getJSXLocationAddress = ({ name, address }: GQLLocation) =>
    address ? (
        <>
            {name} <br />
            {address.street} <br />
            {address.postalCode} {capitalize(address.city)}
            {', '}
            {address.country}
        </>
    ) : (
        ''
    )

export const getLocationAddressLine = ({ name, address }: GQLLocation) =>
    `${name} ${address?.street} ${address?.postalCode} ${capitalize(address?.city)}, ${address?.country}`
