import { GQLCustomer } from '@bc/types'
import { GraphQLError } from 'graphql'

export interface EditUserFormState {
    confirmDelete: boolean
    searchResult: GQLCustomer[] | undefined
    searchError: readonly GraphQLError[] | undefined
    customers: GQLCustomer[]
}

export enum EditUserFormActionTypes {
    CUSTOMERS_INIT,
    CUSTOMERS_ADD,
    CUSTOMERS_DELETE,
    SEARCH_RESULT,
    SEARCH_ERROR,
    SEARCH_RESET,
    DELETE_CONFIRM,
    DELETE_CANCEL,
    RESET,
}

type Action =
    | { type: EditUserFormActionTypes.CUSTOMERS_INIT; payload: GQLCustomer[] }
    | { type: EditUserFormActionTypes.CUSTOMERS_ADD; payload: GQLCustomer }
    | { type: EditUserFormActionTypes.CUSTOMERS_DELETE; payload: GQLCustomer }
    | { type: EditUserFormActionTypes.SEARCH_RESULT; payload: GQLCustomer[] | undefined }
    | { type: EditUserFormActionTypes.SEARCH_ERROR; payload: readonly GraphQLError[] | undefined }
    | { type: EditUserFormActionTypes.SEARCH_RESET }
    | { type: EditUserFormActionTypes.DELETE_CONFIRM }
    | { type: EditUserFormActionTypes.DELETE_CANCEL }
    | { type: EditUserFormActionTypes.RESET }

export const createCustomersInitAction = (payload: GQLCustomer[]): Action => ({
    type: EditUserFormActionTypes.CUSTOMERS_INIT,
    payload,
})
export const createCustomersAddAction = (payload: GQLCustomer): Action => ({
    type: EditUserFormActionTypes.CUSTOMERS_ADD,
    payload,
})
export const createCustomersDeleteAction = (payload: GQLCustomer): Action => ({
    type: EditUserFormActionTypes.CUSTOMERS_DELETE,
    payload,
})
export const createSearchResultAction = (payload: GQLCustomer[] | undefined): Action => ({
    type: EditUserFormActionTypes.SEARCH_RESULT,
    payload,
})
export const createSearchErrorAction = (payload: readonly GraphQLError[] | undefined): Action => ({
    type: EditUserFormActionTypes.SEARCH_ERROR,
    payload,
})
export const createSearchResetAction = (): Action => ({
    type: EditUserFormActionTypes.SEARCH_RESET,
})
export const createDeleteConfirmAction = (): Action => ({
    type: EditUserFormActionTypes.DELETE_CONFIRM,
})
export const createDeleteCancelAction = (): Action => ({
    type: EditUserFormActionTypes.DELETE_CANCEL,
})
export const createResetAction = (): Action => ({
    type: EditUserFormActionTypes.RESET,
})

const clearSearch = { searchError: undefined, searchResult: undefined }

export const userFormReducer = (state: EditUserFormState, action: Action): EditUserFormState => {
    switch (action.type) {
        case EditUserFormActionTypes.CUSTOMERS_INIT: {
            return { ...state, customers: action.payload }
        }
        case EditUserFormActionTypes.CUSTOMERS_ADD: {
            // add new customer in front
            const { payload: customer } = action
            const customers = state.customers.filter(c => c.id !== customer.id)
            customers.unshift(customer)
            return { ...state, ...clearSearch, customers }
        }
        case EditUserFormActionTypes.CUSTOMERS_DELETE: {
            const { payload: customer } = action
            const customers = state.customers.filter(c => c.id !== customer.id)
            return { ...state, ...clearSearch, customers }
        }
        case EditUserFormActionTypes.SEARCH_RESULT: {
            return { ...state, searchError: undefined, searchResult: action.payload }
        }
        case EditUserFormActionTypes.SEARCH_ERROR: {
            return { ...state, searchError: action.payload, searchResult: undefined }
        }
        case EditUserFormActionTypes.SEARCH_RESET: {
            return { ...state, ...clearSearch }
        }
        case EditUserFormActionTypes.DELETE_CONFIRM: {
            return { ...state, confirmDelete: true, ...clearSearch }
        }
        case EditUserFormActionTypes.DELETE_CANCEL: {
            return { ...state, confirmDelete: false }
        }
        case EditUserFormActionTypes.RESET: {
            return {
                confirmDelete: false,
                ...clearSearch,
                customers: [],
            }
        }
        default:
            return state
    }
}
