import { GQLCustomer, GQLInclusion, QueryToSearchCustomerArgs } from '@bc/types'
// Numbers only (Search “VAT” or “Customer ID”) /^\d+/g
// Letters (Search by “Name”) - /^[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF\s]+/g
// Alphanumeric [Matches VAT pattern] (“VAT number”) /^[a-zA-Z]{2}[u,U]?\d\d{0,7}\w*/g
// Alphanumeric [Does not match VAT pattern] (Search “Name”)

export const transformIntoCustomersInput = (input: string): QueryToSearchCustomerArgs => {
    if (/^\d+$/g.test(input)) {
        return { inclusion: GQLInclusion.OR, vatId: input, customerId: input }
    } else if (/^[a-zA-Z]{2}[u,U]?\d\d{0,7}\w*/g.test(input)) {
        return { vatId: input }
    }
    // else if (/^[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF\s]+/g.test(input)) {
    //     return { customerName: input }
    // }
    // the rest matches name anyway
    else {
        return { customerName: input }
    }
}

export const PRIORITIZATION_CUSTOMER_NAME = 'customerName'
export const PRIORITIZATION_CUSTOMER_ID = 'customerId'

const sortByVatId = (a: GQLCustomer, b: GQLCustomer) => (a.vatId! > b.vatId! ? 1 : -1)
const sortByCustomerId = (a: GQLCustomer, b: GQLCustomer) => (a.id > b.id! ? 1 : -1)

export function prioritizeCustomerOver(
    searchCustomerData: GQLCustomer[] | undefined,
    searchInput: string,
    prioritization: string,
): GQLCustomer[] | undefined {
    if (!searchCustomerData) {
        return searchCustomerData
    }

    switch (prioritization) {
        case PRIORITIZATION_CUSTOMER_ID: {
            const vatSet: GQLCustomer[] = []
            const customerSet: GQLCustomer[] = []
            for (const customer of searchCustomerData) {
                if (customer.id.includes(searchInput)) {
                    customerSet.push(customer)
                } else {
                    vatSet.push(customer)
                }
            }

            return [...customerSet.sort(sortByCustomerId), ...vatSet.sort(sortByVatId)]
        }
        case PRIORITIZATION_CUSTOMER_NAME:
        default: {
            return searchCustomerData // already sorted by customerName in the response
        }
    }
}
