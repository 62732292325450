import React from 'react'

import { SvgIconMap } from '../types'

const defaultFill = '#1A0033'

export const Double_Caret_Left: SvgIconMap = {
    '16': ({ fill = defaultFill }) => (
        <g id="Double_Caret_Left" stroke="none" strokeWidth="1" fill={fill} transform="scale(0.666)">
            >
            <path
                transform="translate(-3, 0)"
                d="M15.29 21.71L5.58997 12L15.29 2.29004L16.71 3.71004L8.40997 12L16.71 20.29L15.29 21.71Z"
            />
            <path
                transform="translate(3, 0)"
                d="M15.29 21.71L5.58997 12L15.29 2.29004L16.71 3.71004L8.40997 12L16.71 20.29L15.29 21.71Z"
            />
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Double_Caret_Left" stroke="none" strokeWidth="1" fill={fill}>
            <path
                transform="translate(-3, 0)"
                d="M15.29 21.71L5.58997 12L15.29 2.29004L16.71 3.71004L8.40997 12L16.71 20.29L15.29 21.71Z"
            />
            <path
                transform="translate(3, 0)"
                d="M15.29 21.71L5.58997 12L15.29 2.29004L16.71 3.71004L8.40997 12L16.71 20.29L15.29 21.71Z"
            />
        </g>
    ),
}
