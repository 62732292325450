import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const File_Certificate: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="File_Certificate" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M13 2H15.41L20 6.59V22H4V2H11V4H6V20H18V9H13V2ZM15 4.41V7H17.59L15 4.41ZM12 10C13.65 10 15 11.35 15 13H14.98C14.98 14.08 14.41 15.02 13.55 15.55L14.82 17.45L13.16 18.56L11.99 16.81L10.82 18.56L9.16 17.45L10.43 15.55C9.58 15.02 9 14.08 9 13C9 11.35 10.35 10 12 10ZM11.22 13.6C11.41 13.84 11.68 14 12 14C12.32 14 12.6 13.84 12.78 13.6C12.82 13.55 12.85 13.5 12.88 13.45C12.89 13.43 12.8975 13.41 12.905 13.39C12.9125 13.37 12.92 13.35 12.93 13.33C12.97 13.22 13 13.11 13 12.99C13 12.44 12.55 11.99 12 11.99C11.45 11.99 11 12.44 11 12.99C11 13.11 11.03 13.22 11.07 13.33C11.08 13.37 11.1 13.41 11.12 13.45C11.15 13.5 11.18 13.55 11.22 13.6Z"
                fill={fill === 'gradient' ? 'url(#File_Certificate_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_Certificate" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="File_Certificate" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M13 2H15.41L20 6.59V22H4V2H11V4H6V20H18V9H13V2ZM15 4.41V7H17.59L15 4.41ZM12 10C13.65 10 15 11.35 15 13H14.98C14.98 14.08 14.41 15.02 13.55 15.55L14.82 17.45L13.16 18.56L11.99 16.81L10.82 18.56L9.16 17.45L10.43 15.55C9.58 15.02 9 14.08 9 13C9 11.35 10.35 10 12 10ZM11.22 13.6C11.41 13.84 11.68 14 12 14C12.32 14 12.6 13.84 12.78 13.6C12.82 13.55 12.85 13.5 12.88 13.45C12.89 13.43 12.8975 13.41 12.905 13.39C12.9125 13.37 12.92 13.35 12.93 13.33C12.97 13.22 13 13.11 13 12.99C13 12.44 12.55 11.99 12 11.99C11.45 11.99 11 12.44 11 12.99C11 13.11 11.03 13.22 11.07 13.33C11.08 13.37 11.1 13.41 11.12 13.45C11.15 13.5 11.18 13.55 11.22 13.6Z"
                fill={fill === 'gradient' ? 'url(#File_Certificate_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_Certificate" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="File_Certificate" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M13 2H15.41L20 6.59V22H4V2H11V4H6V20H18V9H13V2ZM15 4.41V7H17.59L15 4.41ZM12 10C13.65 10 15 11.35 15 13H14.98C14.98 14.08 14.41 15.02 13.55 15.55L14.82 17.45L13.16 18.56L11.99 16.81L10.82 18.56L9.16 17.45L10.43 15.55C9.58 15.02 9 14.08 9 13C9 11.35 10.35 10 12 10ZM11.22 13.6C11.41 13.84 11.68 14 12 14C12.32 14 12.6 13.84 12.78 13.6C12.82 13.55 12.85 13.5 12.88 13.45C12.89 13.43 12.8975 13.41 12.905 13.39C12.9125 13.37 12.92 13.35 12.93 13.33C12.97 13.22 13 13.11 13 12.99C13 12.44 12.55 11.99 12 11.99C11.45 11.99 11 12.44 11 12.99C11 13.11 11.03 13.22 11.07 13.33C11.08 13.37 11.1 13.41 11.12 13.45C11.15 13.5 11.18 13.55 11.22 13.6Z"
                fill={fill === 'gradient' ? 'url(#File_Certificate_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_Certificate" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="File_Certificate" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M13 2H15.41L20 6.59V22H4V2H11V4H6V20H18V9H13V2ZM15 4.41V7H17.59L15 4.41ZM12 10C13.65 10 15 11.35 15 13H14.98C14.98 14.08 14.41 15.02 13.55 15.55L14.82 17.45L13.16 18.56L11.99 16.81L10.82 18.56L9.16 17.45L10.43 15.55C9.58 15.02 9 14.08 9 13C9 11.35 10.35 10 12 10ZM11.22 13.6C11.41 13.84 11.68 14 12 14C12.32 14 12.6 13.84 12.78 13.6C12.82 13.55 12.85 13.5 12.88 13.45C12.89 13.43 12.8975 13.41 12.905 13.39C12.9125 13.37 12.92 13.35 12.93 13.33C12.97 13.22 13 13.11 13 12.99C13 12.44 12.55 11.99 12 11.99C11.45 11.99 11 12.44 11 12.99C11 13.11 11.03 13.22 11.07 13.33C11.08 13.37 11.1 13.41 11.12 13.45C11.15 13.5 11.18 13.55 11.22 13.6Z"
                fill={fill === 'gradient' ? 'url(#File_Certificate_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_Certificate" />}
        </g>
    ),
}
