import moment from 'moment'

import { DateDurationConfig } from '@bc/config'

export const getFirstEligibleDeliveryDate = (
    dateConfig: DateDurationConfig,
    useNextDayEligibility: boolean = false,
): Date => {
    // convert to CET time with known UTC offsets depending on DST
    const cetDate = moment().utcOffset(moment().isDST() ? 120 : 60)
    const date = moment().startOf('day')
    const minimumDeliveryDate = useNextDayEligibility && cetDate.hours() < 12 ? 1 : dateConfig.amount
    const weekdays = [1, 2, 3, 4, 5]

    let numberOfWorkdays = 0
    while (numberOfWorkdays < minimumDeliveryDate) {
        date.add(1, 'days')

        if (weekdays.indexOf(date.day()) > -1) {
            numberOfWorkdays++
        }
    }

    return date.toDate()
}

export const getLastEligibleDeliveryDate = ({ amount, unit }: DateDurationConfig) =>
    moment().add(amount, unit).subtract(1, 'days').toDate()
