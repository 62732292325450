import React from 'react'

import { LoaderRound } from './loader-round'
import { FullScreenWrapper, FullScreenWrapperProps } from './full-screen-wrapper'

export const FullScreenLoaderRound: React.SFC<FullScreenWrapperProps> = props => (
    <FullScreenWrapper {...props} data-test-id="loader-round">
        <LoaderRound size="medium" />
    </FullScreenWrapper>
)
