import React from 'react'

import graphqlUtils from '@bc/graphql-utils'
import { MessageMap } from '@bc/translations'
import { GQLMaterial, GQLPrice, GQLRequest, GQLRequestMaterial } from '@bc/types'
import { FormatMessage, FormatNumber, FormatUom } from '@frontend/components/hooks'

export interface ListItemProps {
    label: string
    value: any
    id: string
}

export const hasUploads = (request: GQLRequest) => {
    const files = request?.files
    return files && files.length > 0
}

export const hasCSRResponse = (request: GQLRequest) => hasUploads(request) || Boolean(request.comments)

export const getDocuments = (t: FormatMessage, request: GQLRequest): ListItemProps => ({
    label: t('requests:requested-documents'),
    value: (request.values.documentTypes || []).map((documentType, index) => {
        const document =
            documentType === 'other'
                ? request.values.documentTypeOther
                : t(`request-information-modal:document-types.${documentType}` as keyof MessageMap)

        return (
            <React.Fragment key={`document-${index}`}>
                {document}
                <br />
            </React.Fragment>
        )
    }),
    id: 'documents',
})

export const getCustomerPoReference = (t: FormatMessage, request: GQLRequest): ListItemProps => ({
    label: t('requests:po-reference'),
    value: request.values.customerPoReference,
    id: 'pro-reference',
})

export const getRequestedDeliveryDate = (t: FormatMessage, request: GQLRequest) => {
    const date = request?.values?.requestedDeliveryDate
    return {
        label: t('requests:delivery-date'),
        value: date ? date : '-',
        id: 'delivery-date',
    }
}

export const getPackaging = (
    t: FormatMessage,
    formatNumber: FormatNumber,
    formatUom: FormatUom,
    material: GQLMaterial | GQLRequestMaterial,
) => {
    const packaging = graphqlUtils.material.getPackagingQuantityLabel(
        material,
        formatNumber(material.packagingQuantity.amount),
        formatUom(material.packagingQuantity.uom),
    )
    return {
        label: t('general:packaging'),
        value: packaging,
        id: 'packaging',
    }
}

export const getPrices = (material: GQLRequestMaterial): GQLPrice[] =>
    material.prices?.filter((p): p is GQLPrice => p !== null) || []
