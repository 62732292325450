import React, { useContext } from 'react'

import { useFormatMessage } from '@frontend/components/hooks'
import { SortContextData, SortContext } from '@frontend/context'
import { SelectInput, media, selectFindOption } from '@bc/ui'
import styled from 'styled-components'

const SortInputLimiterOnPage = styled.div`
    width: 50%;
    ${media.max('sm')} {
        width: 100%;
    }
`
export const SortSelectWrapper = () => {
    const t = useFormatMessage()
    const { onSortChange, sort, sortOptions }: SortContextData = useContext(SortContext)
    const selectedOption = selectFindOption(sortOptions, sort)
    return (
        <SortInputLimiterOnPage>
            <SelectInput
                row
                isSearchable={false}
                onChange={onSortChange}
                value={selectedOption}
                labelText={t('sort-select:sort-by')}
                options={sortOptions}
                classNamePrefix="select-sort"
                data-test-id="select-sort"
            />
        </SortInputLimiterOnPage>
    )
}
