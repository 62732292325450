import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const At: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="At" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M15 16C15 16.55 15.45 17 16 17H17C19.76 17 22 14.76 22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C14.79 22 17.31 20.85 19.13 19H15.87C14.72 19.64 13.41 20 12.01 20C7.6 20 4.01 16.41 4.01 12C4.01 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.65 18.65 15 17 15V12C17 9.24 14.76 7 12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C12.34 17 12.68 16.96 13 16.9V14.82C12.69 14.93 12.35 15 12 15C10.35 15 9 13.65 9 12C9 10.35 10.35 9 12 9C13.65 9 15 10.35 15 12V16Z"
                fill={fill === 'gradient' ? 'url(#At_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="At" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="At" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M15 16C15 16.55 15.45 17 16 17H17C19.76 17 22 14.76 22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C14.79 22 17.31 20.85 19.13 19H15.87C14.72 19.64 13.41 20 12.01 20C7.6 20 4.01 16.41 4.01 12C4.01 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.65 18.65 15 17 15V12C17 9.24 14.76 7 12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C12.34 17 12.68 16.96 13 16.9V14.82C12.69 14.93 12.35 15 12 15C10.35 15 9 13.65 9 12C9 10.35 10.35 9 12 9C13.65 9 15 10.35 15 12V16Z"
                fill={fill === 'gradient' ? 'url(#At_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="At" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="At" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M15 16C15 16.55 15.45 17 16 17H17C19.76 17 22 14.76 22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C14.79 22 17.31 20.85 19.13 19H15.87C14.72 19.64 13.41 20 12.01 20C7.6 20 4.01 16.41 4.01 12C4.01 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.65 18.65 15 17 15V12C17 9.24 14.76 7 12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C12.34 17 12.68 16.96 13 16.9V14.82C12.69 14.93 12.35 15 12 15C10.35 15 9 13.65 9 12C9 10.35 10.35 9 12 9C13.65 9 15 10.35 15 12V16Z"
                fill={fill === 'gradient' ? 'url(#At_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="At" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="At" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M15 16C15 16.55 15.45 17 16 17H17C19.76 17 22 14.76 22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C14.79 22 17.31 20.85 19.13 19H15.87C14.72 19.64 13.41 20 12.01 20C7.6 20 4.01 16.41 4.01 12C4.01 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.65 18.65 15 17 15V12C17 9.24 14.76 7 12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C12.34 17 12.68 16.96 13 16.9V14.82C12.69 14.93 12.35 15 12 15C10.35 15 9 13.65 9 12C9 10.35 10.35 9 12 9C13.65 9 15 10.35 15 12V16Z"
                fill={fill === 'gradient' ? 'url(#At_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="At" />}
        </g>
    ),
}
