import React from 'react'

import { FormInputComponents, RadioButtonComponents } from './components'
import { CheckboxRadioLabelProps } from './components/styles'

export interface RadioButtonProps extends CheckboxRadioLabelProps {
    id: string
    labelText?: React.ElementType | JSX.Element | string
    errorText?: React.ElementType | JSX.Element | string
}

export const RadioButton: React.SFC<RadioButtonProps & React.HTMLProps<HTMLInputElement>> = ({
    id,
    labelText,
    errorText,
    hasError,
    colorScheme,
    ref: _ref,
    as: _as,
    ...rest
}) => (
    <FormInputComponents.InputWrapper noFlex>
        <FormInputComponents.ButtonWrapper>
            <FormInputComponents.HiddenInput id={id} type="radio" {...rest} />
            <RadioButtonComponents.Label
                htmlFor={id}
                hasError={hasError}
                colorScheme={colorScheme}
                data-test-id={`radio-button-${id}`}
                data-test-class="radio-button">
                <FormInputComponents.LabelText>{labelText}</FormInputComponents.LabelText>
            </RadioButtonComponents.Label>
        </FormInputComponents.ButtonWrapper>
        {Boolean(hasError && errorText) && <FormInputComponents.ErrorLabel>{errorText}</FormInputComponents.ErrorLabel>}
    </FormInputComponents.InputWrapper>
)
