import React from 'react'

import { ToastData } from './toast'
import { Toaster } from './toaster'

interface ToastMessagesProps {
    toasts: ToastData[] | undefined
    onClose: (id: string) => void
}

export const ToastMessages: React.SFC<ToastMessagesProps> = ({ toasts = [], onClose }) => (
    <Toaster placement="bottom-center" toasts={toasts} onClose={onClose} />
)
