import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Circle_Alert: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Circle_Alert" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M19.74 5.68L18.31 7.11C19.36 8.47 20 10.16 20 12.01C20 16.42 16.41 20.01 12 20.01C7.59 20.01 4 16.42 4 12.01C4 7.6 7.59 4 12 4C13.85 4 15.54 4.63 16.9 5.69L18.33 4.26C16.61 2.85 14.4 2 12.01 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 9.6 21.15 7.4 19.74 5.68ZM11 6V14H13V6H11ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
                fill={fill === 'gradient' ? 'url(#Circle_Alert_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Circle_Alert" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Circle_Alert" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M19.74 5.68L18.31 7.11C19.36 8.47 20 10.16 20 12.01C20 16.42 16.41 20.01 12 20.01C7.59 20.01 4 16.42 4 12.01C4 7.6 7.59 4 12 4C13.85 4 15.54 4.63 16.9 5.69L18.33 4.26C16.61 2.85 14.4 2 12.01 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 9.6 21.15 7.4 19.74 5.68ZM11 6V14H13V6H11ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
                fill={fill === 'gradient' ? 'url(#Circle_Alert_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Circle_Alert" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Circle_Alert" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M19.74 5.68L18.31 7.11C19.36 8.47 20 10.16 20 12.01C20 16.42 16.41 20.01 12 20.01C7.59 20.01 4 16.42 4 12.01C4 7.6 7.59 4 12 4C13.85 4 15.54 4.63 16.9 5.69L18.33 4.26C16.61 2.85 14.4 2 12.01 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 9.6 21.15 7.4 19.74 5.68ZM11 6V14H13V6H11ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
                fill={fill === 'gradient' ? 'url(#Circle_Alert_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Circle_Alert" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Circle_Alert" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M19.74 5.68L18.31 7.11C19.36 8.47 20 10.16 20 12.01C20 16.42 16.41 20.01 12 20.01C7.59 20.01 4 16.42 4 12.01C4 7.6 7.59 4 12 4C13.85 4 15.54 4.63 16.9 5.69L18.33 4.26C16.61 2.85 14.4 2 12.01 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 9.6 21.15 7.4 19.74 5.68ZM11 6V14H13V6H11ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
                fill={fill === 'gradient' ? 'url(#Circle_Alert_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Circle_Alert" />}
        </g>
    ),
}
