import React, { useEffect, useState } from 'react'
import { debounce } from 'debounce'
import { LoaderRound } from '../loader'

interface ScrollLoaderProps {
    hasMore: boolean
    offset?: number
    callback: (a?: any) => void
}
export const ScrollLoader = ({ hasMore, callback, offset = 0 }: ScrollLoaderProps) => {
    const [loading, setLoading] = useState(false)
    const onScrollHandler = debounce(() => {
        if (!hasMore) {
            return
        }

        // Checks that the page has scrolled to the bottom minus small custom offset
        if (
            document.documentElement.scrollHeight - document.documentElement.scrollTop <=
            document.documentElement.clientHeight + offset
        ) {
            setLoading(true)
            callback()
        }
    }, 50)

    useEffect(() => {
        setLoading(false)
        window.addEventListener('scroll', onScrollHandler)
        return () => {
            window.removeEventListener('scroll', onScrollHandler)
        }
    }, [hasMore])

    return loading ? <LoaderRound /> : null
}
