import React from 'react'
import styled from 'styled-components'

import { Hr, spacing, Text } from '@bc/ui'

interface ModalTitleProps {
    title: string
    intro?: string
    icon?: JSX.Element
}

const Title = styled.div`
    display: flex;
`

const IconWrapper = styled.div`
    margin: 0 ${spacing.xs}px 0 0;
`

export const ModalTitle = ({ title, intro, icon }: ModalTitleProps) => (
    <>
        <Title>
            {icon && <IconWrapper>{icon}</IconWrapper>}
            <Text h3 noMargin={!Boolean(intro)}>
                {title}
            </Text>
        </Title>
        {intro && <Text p>{intro}</Text>}
        <Hr small />
    </>
)
