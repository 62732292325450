import React from 'react'
import styled from 'styled-components'

import { ButtonLink, spacing, Text } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'

import { PageError } from './page-error'

const ButtonLoginFailed = styled(ButtonLink)`
    margin-right: ${spacing.xs}px;
`

export const LoginFailedError = () => {
    const t = useFormatMessage()

    return (
        <PageError title={t('warning:login-failed:title')} text={t('warning:login-failed:explanation')}>
            <Text block>
                <ButtonLoginFailed
                    buttonSize="large"
                    variant="action"
                    to="/login"
                    title={t('warning:login-failed:button-text')}>
                    {t('warning:login-failed:button-text')}
                </ButtonLoginFailed>
            </Text>
        </PageError>
    )
}
