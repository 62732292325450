import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { NotFoundPage } from '@frontend/pages/error'
import { default as ProductListPageContainer } from './product-list-page-container'

export class ProductList extends React.Component<RouteComponentProps<undefined>> {
    public render() {
        const {
            match: { url },
        } = this.props

        return (
            <Switch>
                <Route path={`${url}`} exact component={ProductListPageContainer} />
                <Route component={NotFoundPage} />
            </Switch>
        )
    }
}
