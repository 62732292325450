import React from 'react'

import { FormInputComponents, SelectComponents } from './components'

export interface SelectOption {
    value: string
    label: string
}

export interface SelectProps {
    labelText?: React.ElementType | JSX.Element | string
    errorText?: React.ElementType | JSX.Element | string
    hasError?: boolean
    options: SelectOption[]
    row?: boolean
}

export const Select = ({
    labelText,
    errorText,
    hasError,
    options = [],
    ref: _ref,
    as: _as,
    row = false,
    ...rest
}: SelectProps & React.HTMLProps<HTMLSelectElement>) => {
    const labelSelect = labelText && <SelectComponents.Label>{labelText}</SelectComponents.Label>

    const select = (
        <>
            <FormInputComponents.InputWrapper>
                <SelectComponents.Select {...rest}>
                    {options.map(({ value, label }, i) => (
                        <option key={value + i} value={value}>
                            {label}
                        </option>
                    ))}
                </SelectComponents.Select>
                <SelectComponents.Icon />
            </FormInputComponents.InputWrapper>
            {Boolean(hasError && errorText) && (
                <FormInputComponents.ErrorLabel>{errorText}</FormInputComponents.ErrorLabel>
            )}
        </>
    )

    return row ? (
        <FormInputComponents.Row>
            <FormInputComponents.RowItem>{labelSelect}</FormInputComponents.RowItem>
            <FormInputComponents.RowItem>{select}</FormInputComponents.RowItem>
        </FormInputComponents.Row>
    ) : (
        <FormInputComponents.InputWrapper>
            {labelSelect}
            {select}
        </FormInputComponents.InputWrapper>
    )
}
