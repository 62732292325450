import { GQLRoleType } from '@bc/types'

import { MessageMap } from '@bc/translations'
import { SvgIconName } from '@bc/ui'
import { capitalizeFirstLetter } from '@bc/ui/src/util'

export const userRoleAsTranslationKey = (role: GQLRoleType): keyof MessageMap =>
    `filters:search-by-${role}.placeholder` as keyof MessageMap

export const userRoleAsIconName = (role: GQLRoleType | undefined = GQLRoleType.user): SvgIconName => {
    switch (role.toLowerCase()) {
        case 'user':
            return 'User'
        case 'csr':
            return 'User_CSR'
        default:
            return `User_${capitalizeFirstLetter(role)}` as SvgIconName
    }
}
