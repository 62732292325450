import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Heart: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Heart" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M16.5 5C15.73 5 15.02 5.26 14.45 5.68C14.19 5.05 13.85 4.47 13.44 3.93C14.32 3.34 15.37 3 16.5 3C19.53 3 22 5.47 22 8.5V9C22 11.2 21.14 13.27 19.59 14.83L12 22.42L4.41 14.83C2.85 13.27 2 11.2 2 9V8.5C2 5.47 4.47 3 7.5 3C10.53 3 13 5.47 13 8.5V10H11V8.5C11 6.57 9.43 5 7.5 5C5.57 5 4 6.57 4 8.5V9C4 10.67 4.65 12.24 5.83 13.41L12 19.58L18.17 13.41C19.35 12.23 20 10.66 20 9V8.5C20 6.57 18.43 5 16.5 5Z"
                fill={fill === 'gradient' ? 'url(#Heart_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Heart" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Heart" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M16.5 5C15.73 5 15.02 5.26 14.45 5.68C14.19 5.05 13.85 4.47 13.44 3.93C14.32 3.34 15.37 3 16.5 3C19.53 3 22 5.47 22 8.5V9C22 11.2 21.14 13.27 19.59 14.83L12 22.42L4.41 14.83C2.85 13.27 2 11.2 2 9V8.5C2 5.47 4.47 3 7.5 3C10.53 3 13 5.47 13 8.5V10H11V8.5C11 6.57 9.43 5 7.5 5C5.57 5 4 6.57 4 8.5V9C4 10.67 4.65 12.24 5.83 13.41L12 19.58L18.17 13.41C19.35 12.23 20 10.66 20 9V8.5C20 6.57 18.43 5 16.5 5Z"
                fill={fill === 'gradient' ? 'url(#Heart_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Heart" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Heart" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M16.5 5C15.73 5 15.02 5.26 14.45 5.68C14.19 5.05 13.85 4.47 13.44 3.93C14.32 3.34 15.37 3 16.5 3C19.53 3 22 5.47 22 8.5V9C22 11.2 21.14 13.27 19.59 14.83L12 22.42L4.41 14.83C2.85 13.27 2 11.2 2 9V8.5C2 5.47 4.47 3 7.5 3C10.53 3 13 5.47 13 8.5V10H11V8.5C11 6.57 9.43 5 7.5 5C5.57 5 4 6.57 4 8.5V9C4 10.67 4.65 12.24 5.83 13.41L12 19.58L18.17 13.41C19.35 12.23 20 10.66 20 9V8.5C20 6.57 18.43 5 16.5 5Z"
                fill={fill === 'gradient' ? 'url(#Heart_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Heart" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Heart" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M16.5 5C15.73 5 15.02 5.26 14.45 5.68C14.19 5.05 13.85 4.47 13.44 3.93C14.32 3.34 15.37 3 16.5 3C19.53 3 22 5.47 22 8.5V9C22 11.2 21.14 13.27 19.59 14.83L12 22.42L4.41 14.83C2.85 13.27 2 11.2 2 9V8.5C2 5.47 4.47 3 7.5 3C10.53 3 13 5.47 13 8.5V10H11V8.5C11 6.57 9.43 5 7.5 5C5.57 5 4 6.57 4 8.5V9C4 10.67 4.65 12.24 5.83 13.41L12 19.58L18.17 13.41C19.35 12.23 20 10.66 20 9V8.5C20 6.57 18.43 5 16.5 5Z"
                fill={fill === 'gradient' ? 'url(#Heart_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Heart" />}
        </g>
    ),
}
