import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Scan: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Scan" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M5 8H3V3H8V5H5V8ZM8 21H3V16H5V19H8V21ZM16 21H21V16H19V19H16V21ZM21 8H19V5H16V3H21V8ZM11 7H13V17H11V7ZM15 7V17H17V7H15ZM7 7H9V17H7V7Z"
                fill={fill === 'gradient' ? 'url(#Scan_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Scan" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Scan" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M5 8H3V3H8V5H5V8ZM8 21H3V16H5V19H8V21ZM16 21H21V16H19V19H16V21ZM21 8H19V5H16V3H21V8ZM11 7H13V17H11V7ZM15 7V17H17V7H15ZM7 7H9V17H7V7Z"
                fill={fill === 'gradient' ? 'url(#Scan_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Scan" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Scan" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M5 8H3V3H8V5H5V8ZM8 21H3V16H5V19H8V21ZM16 21H21V16H19V19H16V21ZM21 8H19V5H16V3H21V8ZM11 7H13V17H11V7ZM15 7V17H17V7H15ZM7 7H9V17H7V7Z"
                fill={fill === 'gradient' ? 'url(#Scan_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Scan" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Scan" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M5 8H3V3H8V5H5V8ZM8 21H3V16H5V19H8V21ZM16 21H21V16H19V19H16V21ZM21 8H19V5H16V3H21V8ZM11 7H13V17H11V7ZM15 7V17H17V7H15ZM7 7H9V17H7V7Z"
                fill={fill === 'gradient' ? 'url(#Scan_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Scan" />}
        </g>
    ),
}
