import { GQLLocation } from '@bc/types'

import { EventTag, VirtualPageviewTag } from '../types'
import { dataLayer } from '../utils'

export const quotesFilterByQuoteId = (id: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.quotesQuoteId',
        virtualURL: `/quotesSearchQuoteId?q=${id}&n=quotessearchquoteidbar`,
        virtualTitle: 'Quotes - quote id',
    })
}

export const quotesFilterByMaterialName = (name: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.quotesMaterialName',
        virtualURL: `/quotesSearchMaterialName?q=${name}&n=quotessearchmaterialnamebar`,
        virtualTitle: 'Quotes - material name',
    })
}

export const quotesFilterByAddress = (address: GQLLocation) => {
    dataLayer<EventTag>({
        event: 'gtm.quotesAddress',
        eventCategory: 'quotes',
        eventAction: 'filter',
        eventLabel: `${address.name} ${address.address && address.address.street} ${
            address.address && address.address.city
        }, ${address.address && address.address.country}`,
    })
}

const getMappedSort = (sort: string) => {
    let sortOrder = 'ascending'
    let sortType = sort

    if (sortType[0] === '-') {
        sortOrder = 'descending'
        sortType = sortType.substr(1)
    }

    return `${sortType}: ${sortOrder}`
}

export const quotesSort = (sort: string) => {
    dataLayer<EventTag>({
        event: 'gtm.quotesSort',
        eventCategory: 'quotes',
        eventAction: 'sort',
        eventLabel: getMappedSort(sort),
    })
}
