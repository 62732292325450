import { createGlobalStyle } from 'styled-components'

import { colors, defaultFontSpacing } from '@bc/ui/src/config'
import { getFontFamily } from '@bc/ui/src/helpers'
import { normalize } from './normalize'

export const GlobalStyles = createGlobalStyle`
    ${normalize}

    *, *:before, *:after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        -webkit-font-feature-settings: "tnum" on, "lnum" on;
           -moz-font-feature-settings: "tnum" on, "lnum" on;
            -ms-font-feature-settings: "tnum" on, "lnum" on;
                font-feature-settings: "tnum" on, "lnum" on; 
    }

    html, body {
        -webkit-font-smoothing: antialiased; 
        -moz-osx-font-smoothing: grayscale;
        min-width: 320px;
    }

    html {
        font-family: ${getFontFamily('brenntagsans')};
        font-size: 10px;
        color: ${colors.deepPurple};
        background-color: ${colors.white};
        height: 100%;
    }

    .no-scroll {
        overflow: hidden;
    }

    body {
        ${defaultFontSpacing}
        display: flex;
        min-height: 100%;
        height: auto;
        word-break: normal;
        &:after {
            content: "";
            min-height: inherit;
            font-size: 0;
        }
    }

    #app {
        width: 100%;
        min-height: 100vh;
    }

    button {
        appearance: none;
        user-select: none;
        background: transparent;
        border: 0;
        font-family: ${getFontFamily('brenntagsans_bold')};
        &:focus {
            outline: 0;
        }
    }
`
