import { Tax } from '@bc/config/src/types'
import { GQLBaseOrderLine } from '@bc/types'

import { parseDate } from './date'

export const mergeCartOrderLines = (line1: GQLBaseOrderLine, line2: GQLBaseOrderLine): GQLBaseOrderLine | never => {
    if (line1.material?.id && line2.material?.id && line1.material?.id !== line2.material?.id) {
        throw Error(`Cannot combine materials with different ids: [${line1.material?.id}, ${line2.material?.id}]`)
    }
    const {
        quantityOrdered: { amount: amount1 },
    } = line1
    const {
        quantityOrdered: { amount: amount2 },
    } = line2

    const copyOrderLine: GQLBaseOrderLine = JSON.parse(JSON.stringify(line1))

    const newOrderLine: GQLBaseOrderLine = {
        ...copyOrderLine,
        quantityOrdered: {
            ...copyOrderLine.quantityOrdered,
            amount: amount1 + amount2,
        },
    }

    return newOrderLine
}

/**
 * @param taxes
 * @example
 */
export function getCurrentTax(taxes: Tax[] | undefined): number {
    const now = new Date().valueOf()
    // filter out dates in the future and use the last current tax

    const tax = taxes
        ?.map(t => ({
            percentage: t.percentage,
            timestamp: parseDate(t.since, 'DD/MM/YYYY')?.valueOf() ?? Number.POSITIVE_INFINITY,
        }))
        .sort((t1, t2) => t2.timestamp - t1.timestamp)
        .filter(t => t.timestamp < now)[0]

    return tax?.percentage ?? 0
}
