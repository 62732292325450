export const formatSorting = (sortValue: string) => {
    let sortOrder = 'ascending'
    let sortType = sortValue

    if (sortType[0] === '-') {
        sortOrder = 'descending'
        sortType = sortType.substr(1)
    }

    return `${sortType}: ${sortOrder}`
}
