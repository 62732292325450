import React from 'react'
import styled from 'styled-components'

import { GQLLocation } from '@bc/types'
import { colors, getJSXLocationAddress, media, spacing, Text, TextInputComponents } from '@bc/ui'

interface FormProps {
    row?: boolean
}

export const Form = styled.form`
    display: flex;
    flex-direction: ${({ row }: FormProps) => (row ? 'row' : 'column')};
    justify-content: space-between;
    margin-top: 7px;
`

export const FormWrapper = styled(Form)`
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 7px;
    height: 100%;
    justify-content: space-between;
    gap: 20px;
`

export const Row = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`

export const FormCard = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;

    width: 340px;
    padding: ${spacing.sm}px;
    margin-bottom: ${spacing.sm * 2}px;

    background: white;

    ${media.min('sm')} {
        padding: ${spacing.sm}px;
    }
`

export interface WrapperProps {
    css?: string
}

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    flex-direction: column;
    gap: ${spacing.xxs}px;
`

export const CommentWrapper = styled(Wrapper)`
    margin-bottom: 60px;
`

interface InputWrapperProps {
    noMargin?: boolean
}
export const InputWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    &:not(:first-child) {
        margin-top: ${({ noMargin }: InputWrapperProps) => (noMargin ? '0px' : `${spacing.sm}px`)};
    }
`

export const InputLabel = styled(Text)`
    color: ${colors.neutral90};

    /* &:not(:last-child) {
        margin-bottom: ${spacing.xxs}px;
    } */
`

export const InputUnitGroup = styled(Text)`
    display: flex;
    align-items: center;
    margin: 0;
    border: 1px solid;
    border-left: 0;
    border-color: ${({ hasError }: NumberInputProps) => (hasError ? colors.red70 : colors.neutral20)};
    background: white;
    border-radius: 0 3px 3px 0;
    padding: 0 10px;
    min-width: 50px;
`

interface NumberInputProps {
    hasError?: boolean
    quantity?: boolean
    append?: boolean
}

export const NumberInput = styled(TextInputComponents.Input)`
    color: ${({ quantity }: NumberInputProps) => (quantity ? colors.neutral50 : colors.neutral90)};
    border-color: ${({ hasError }: NumberInputProps) => (hasError ? colors.red70 : colors.neutral20)};
    -moz-appearance: textfield;
    transition: border-color 200ms ease-out;
    padding-right: 0;
    width: 100%;
    &::-webkit-input-placeholder {
        color: ${colors.neutral50};
    }

    &:-ms-input-placeholder {
        color: ${colors.neutral50} !important;
    }

    &::-ms-input-placeholder {
        color: ${colors.neutral50};
    }

    &::placeholder {
        color: ${colors.neutral50};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:hover,
    &:focus {
        border-color: ${({ hasError, quantity }: NumberInputProps) =>
            hasError ? colors.red70 : quantity ? colors.neutral20 : colors.neutral90};
    }

    &:disabled {
        cursor: initial;
        border-color: ${colors.neutral20};
        color: ${colors.neutral50};
    }
`

export const InputTextWrapper = styled(InputWrapper)`
    padding-left: 25px;
`

export const SharedFormWrapper = styled(Wrapper)`
    flex-direction: row;
    justify-content: flex-end;
`

export const Stretched = styled.div`
    flex-grow: 1;
`

export const ButtonsWrapper = styled(Wrapper)`
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
    margin-top: ${spacing.xs}px;
`

export const HelperWrapper = styled.div`
    margin-top: -20px;
`

const AddressSummaryWrapper = styled.div`
    background-color: ${colors.neutral10};
    color: ${colors.neutral70};
    padding: ${spacing.xs}px;
    margin-bottom: ${spacing.xs}px;
    margin-top: -${spacing.xxs}px;
    display: flex;
    flex-direction: column;
`
interface AddressSummaryProps {
    location: GQLLocation | undefined
}

export const AddressSummary = ({ location }: AddressSummaryProps) => {
    if (!location) {
        return null
    }

    return <AddressSummaryWrapper>{getJSXLocationAddress(location)}</AddressSummaryWrapper>
}

export interface FormActions {
    setField?: (field: string, value: string | undefined) => void
}

export const PriceLabelWrapper = styled.div`
    width: 350px;
`
