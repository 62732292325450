import React, { useContext, useEffect, useState } from 'react'

import { GQLBaseOrderLine, GQLMaterial } from '@bc/types'
import { Modal } from '@bc/ui/src'

import { ModalCartForm } from '@frontend/components/forms'
import { ModalCartFormValues } from '@frontend/components/forms/modal-cart-form/modal-cart-form.types'
import { useFormatMessage, useParseFloatNumber } from '@frontend/components/hooks'
import { ShoppingCartContext, ToastsContext } from '@frontend/context'

import { ModalMaterialInfoColumn, ModalTitle } from './modal-elements'

export interface Item {
    id: string
    material: GQLMaterial
    amount: number
    uom: string
    uomConversionFactor: number
}

interface ChangeQuantityModalProps {
    isOpen: boolean
    item?: Item
    onClose: () => void
}

export const ChangeQuantityModal = ({ item, onClose, isOpen }: ChangeQuantityModalProps) => {
    const t = useFormatMessage()
    const parseFloatNumber = useParseFloatNumber()
    const [loading, setLoading] = useState<boolean | undefined>(undefined)
    const [shoppingStore] = useContext(ShoppingCartContext)
    const [toastsStore] = useContext(ToastsContext)

    const resetState = () => {
        setLoading(false)
    }

    useEffect(
        () => () => {
            resetState()
        },
        [isOpen],
    )

    if (!item) {
        return null
    }

    const { id, material, amount, uom, uomConversionFactor } = item

    const handleSubmit = async (newValues: ModalCartFormValues) => {
        try {
            if (shoppingStore) {
                const newItem: GQLBaseOrderLine = shoppingStore.get(id)
                newItem.quantityOrdered.amount = parseFloatNumber(newValues.quantity!)
                shoppingStore.update(id, newItem)
                handleChangeQuantitySuccess()
            }
        } catch (e) {
            handleChangeQuantityError(e)
            onCloseModal()
        }
    }

    const handleChangeQuantitySuccess = () => {
        onClose()
        toastsStore?.addToast({
            type: 'success',
            message: t('request-information-modal:toasts-product-adjusted'),
        })
    }

    const handleChangeQuantityError = (error: string) => {
        toastsStore?.addToast({
            type: 'error',
            message: error,
        })
    }

    const onCloseModal = () => {
        onClose()
    }

    return (
        <Modal
            id={'change-quantity'}
            open={isOpen}
            onClose={onCloseModal}
            type="large"
            content={
                material && [
                    <ModalTitle
                        title={t('request-information-modal:edit-quantity-cart')}
                        intro={t('request-information-modal:add-to-cart.instruction')}
                    />,
                    <ModalMaterialInfoColumn material={material} />,
                ]
            }
            aside={
                isOpen
                    ? material && (
                          <ModalCartForm
                              onSubmit={handleSubmit}
                              onClose={onClose}
                              quantity={amount}
                              uom={uom}
                              uomConversionFactor={uomConversionFactor}
                              edit
                              isOpen={isOpen}
                              loading={Boolean(loading)}
                              t={t}
                              material={material}
                          />
                      )
                    : undefined
            }
        />
    )
}
