import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const ViewOff: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="ViewOff" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M3.70718 2.29138L2.29297 3.7056L20.2959 21.7085L21.7101 20.2943L3.70718 2.29138ZM9.69187 5.43979C10.4219 5.14979 11.2019 4.99979 12.0019 4.99979C13.6719 4.99979 15.2419 5.64979 16.4119 6.82979L21.0019 11.4198V12.5898L18.9119 14.6798L20.3219 16.0898L22.9919 13.4198V10.5898L17.8219 5.41979C16.2619 3.85979 14.1919 3.00979 11.9919 3.00979C10.6419 3.00979 9.33187 3.33979 8.17188 3.94979L9.68188 5.45979L9.69187 5.43979ZM12.0017 19C12.8017 19 13.5817 18.85 14.3117 18.5599L14.3217 18.5499L15.8317 20.0599C14.6717 20.67 13.3617 21 12.0117 21C9.81172 21 7.74172 20.15 6.18172 18.59L1.01172 13.42V10.59L3.68172 7.91995L5.09172 9.32995L3.00172 11.42V12.59L7.59172 17.17C8.76172 18.35 10.3317 19 12.0017 19ZM16.9286 12.69C16.9349 12.6416 16.9417 12.5937 16.9484 12.5459C16.9737 12.3666 16.9986 12.1895 16.9986 12C16.9986 9.24003 14.7586 7.00003 11.9986 7.00003C11.8091 7.00003 11.632 7.02497 11.4527 7.05023C11.4049 7.05696 11.357 7.06372 11.3086 7.07003L16.9286 12.69ZM7.0502 11.4542C7.05693 11.4064 7.06369 11.3585 7.07 11.3101L12.69 16.9301C12.6416 16.9364 12.5937 16.9432 12.5459 16.9499C12.3665 16.9751 12.1895 17.0001 12 17.0001C9.24 17.0001 7 14.7601 7 12.0001C7 11.8106 7.02494 11.6335 7.0502 11.4542Z"
                fill={fill === 'gradient' ? 'url(#ViewOff_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ViewOff" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="ViewOff" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M3.70718 2.29138L2.29297 3.7056L20.2959 21.7085L21.7101 20.2943L3.70718 2.29138ZM9.69187 5.43979C10.4219 5.14979 11.2019 4.99979 12.0019 4.99979C13.6719 4.99979 15.2419 5.64979 16.4119 6.82979L21.0019 11.4198V12.5898L18.9119 14.6798L20.3219 16.0898L22.9919 13.4198V10.5898L17.8219 5.41979C16.2619 3.85979 14.1919 3.00979 11.9919 3.00979C10.6419 3.00979 9.33187 3.33979 8.17188 3.94979L9.68188 5.45979L9.69187 5.43979ZM12.0017 19C12.8017 19 13.5817 18.85 14.3117 18.5599L14.3217 18.5499L15.8317 20.0599C14.6717 20.67 13.3617 21 12.0117 21C9.81172 21 7.74172 20.15 6.18172 18.59L1.01172 13.42V10.59L3.68172 7.91995L5.09172 9.32995L3.00172 11.42V12.59L7.59172 17.17C8.76172 18.35 10.3317 19 12.0017 19ZM16.9286 12.69C16.9349 12.6416 16.9417 12.5937 16.9484 12.5459C16.9737 12.3666 16.9986 12.1895 16.9986 12C16.9986 9.24003 14.7586 7.00003 11.9986 7.00003C11.8091 7.00003 11.632 7.02497 11.4527 7.05023C11.4049 7.05696 11.357 7.06372 11.3086 7.07003L16.9286 12.69ZM7.0502 11.4542C7.05693 11.4064 7.06369 11.3585 7.07 11.3101L12.69 16.9301C12.6416 16.9364 12.5937 16.9432 12.5459 16.9499C12.3665 16.9751 12.1895 17.0001 12 17.0001C9.24 17.0001 7 14.7601 7 12.0001C7 11.8106 7.02494 11.6335 7.0502 11.4542Z"
                fill={fill === 'gradient' ? 'url(#ViewOff_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ViewOff" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="ViewOff" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M3.70718 2.29138L2.29297 3.7056L20.2959 21.7085L21.7101 20.2943L3.70718 2.29138ZM9.69187 5.43979C10.4219 5.14979 11.2019 4.99979 12.0019 4.99979C13.6719 4.99979 15.2419 5.64979 16.4119 6.82979L21.0019 11.4198V12.5898L18.9119 14.6798L20.3219 16.0898L22.9919 13.4198V10.5898L17.8219 5.41979C16.2619 3.85979 14.1919 3.00979 11.9919 3.00979C10.6419 3.00979 9.33187 3.33979 8.17188 3.94979L9.68188 5.45979L9.69187 5.43979ZM12.0017 19C12.8017 19 13.5817 18.85 14.3117 18.5599L14.3217 18.5499L15.8317 20.0599C14.6717 20.67 13.3617 21 12.0117 21C9.81172 21 7.74172 20.15 6.18172 18.59L1.01172 13.42V10.59L3.68172 7.91995L5.09172 9.32995L3.00172 11.42V12.59L7.59172 17.17C8.76172 18.35 10.3317 19 12.0017 19ZM16.9286 12.69C16.9349 12.6416 16.9417 12.5937 16.9484 12.5459C16.9737 12.3666 16.9986 12.1895 16.9986 12C16.9986 9.24003 14.7586 7.00003 11.9986 7.00003C11.8091 7.00003 11.632 7.02497 11.4527 7.05023C11.4049 7.05696 11.357 7.06372 11.3086 7.07003L16.9286 12.69ZM7.0502 11.4542C7.05693 11.4064 7.06369 11.3585 7.07 11.3101L12.69 16.9301C12.6416 16.9364 12.5937 16.9432 12.5459 16.9499C12.3665 16.9751 12.1895 17.0001 12 17.0001C9.24 17.0001 7 14.7601 7 12.0001C7 11.8106 7.02494 11.6335 7.0502 11.4542Z"
                fill={fill === 'gradient' ? 'url(#ViewOff_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ViewOff" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="ViewOff" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M3.70718 2.29138L2.29297 3.7056L20.2959 21.7085L21.7101 20.2943L3.70718 2.29138ZM9.69187 5.43979C10.4219 5.14979 11.2019 4.99979 12.0019 4.99979C13.6719 4.99979 15.2419 5.64979 16.4119 6.82979L21.0019 11.4198V12.5898L18.9119 14.6798L20.3219 16.0898L22.9919 13.4198V10.5898L17.8219 5.41979C16.2619 3.85979 14.1919 3.00979 11.9919 3.00979C10.6419 3.00979 9.33187 3.33979 8.17188 3.94979L9.68188 5.45979L9.69187 5.43979ZM12.0017 19C12.8017 19 13.5817 18.85 14.3117 18.5599L14.3217 18.5499L15.8317 20.0599C14.6717 20.67 13.3617 21 12.0117 21C9.81172 21 7.74172 20.15 6.18172 18.59L1.01172 13.42V10.59L3.68172 7.91995L5.09172 9.32995L3.00172 11.42V12.59L7.59172 17.17C8.76172 18.35 10.3317 19 12.0017 19ZM16.9286 12.69C16.9349 12.6416 16.9417 12.5937 16.9484 12.5459C16.9737 12.3666 16.9986 12.1895 16.9986 12C16.9986 9.24003 14.7586 7.00003 11.9986 7.00003C11.8091 7.00003 11.632 7.02497 11.4527 7.05023C11.4049 7.05696 11.357 7.06372 11.3086 7.07003L16.9286 12.69ZM7.0502 11.4542C7.05693 11.4064 7.06369 11.3585 7.07 11.3101L12.69 16.9301C12.6416 16.9364 12.5937 16.9432 12.5459 16.9499C12.3665 16.9751 12.1895 17.0001 12 17.0001C9.24 17.0001 7 14.7601 7 12.0001C7 11.8106 7.02494 11.6335 7.0502 11.4542Z"
                fill={fill === 'gradient' ? 'url(#ViewOff_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ViewOff" />}
        </g>
    ),
}
