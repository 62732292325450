import React from 'react'
import styled from 'styled-components'

import { GQLCustomer, GQLUser } from '@bc/types'
import { Hr, nl2br, Text } from '@bc/ui'
import { FormatMessage } from '@frontend/components/hooks'
import { AccountCard } from './account-card'

interface AccountRelatedUsersProps {
    t: FormatMessage
    currentCustomer: GQLCustomer
    relatedUsers: GQLUser[] | undefined
    csrEmail: string
}

const Wrapper = styled.div`
    display: flex;
    flex: 1;
`

const Item = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
`

export const AccountRelatedUsers = ({ t, currentCustomer, relatedUsers, csrEmail }: AccountRelatedUsersProps) => {
    const { name } = currentCustomer
    return (
        <AccountCard
            theme="light"
            header={
                <div>
                    <Text h3>
                        {t('account:related:title', undefined, {
                            company: name,
                        })}
                    </Text>
                    {relatedUsers ? (
                        relatedUsers.length > 0 ? (
                            <>
                                <Text p>{nl2br(t('account:related:intro', undefined, { csrEmail }))}</Text>
                                <br />
                                <Wrapper>
                                    <Item>
                                        <Text caption>{t('account:email')}</Text>
                                    </Item>
                                    <Item>
                                        <Text caption>{t('account:name')}</Text>
                                    </Item>
                                </Wrapper>
                                {relatedUsers.map((user: GQLUser) => (
                                    <React.Fragment key={user.email}>
                                        <Hr small />
                                        <Wrapper>
                                            <Item>
                                                <Text p noMargin>
                                                    {user.email}
                                                </Text>
                                            </Item>
                                            <Item>
                                                <Text p noMargin>{`${user.firstName} ${user.lastName}`}</Text>
                                            </Item>
                                        </Wrapper>
                                    </React.Fragment>
                                ))}
                            </>
                        ) : (
                            <Text p>{nl2br(t('account:related:no-results', undefined, { csrEmail }))}</Text>
                        )
                    ) : null}
                </div>
            }
        />
    )
}
