import React from 'react'

import { SvgIcon } from '@bc/ui/src/components/svg'

import * as PaginatorComponents from './paginator.components'

export interface PaginatorProps {
    /** max number of pages to show */
    maxPage: number
    /** the current/active page */
    currentPage: number
    /** event handler when a page number is clicked */
    onPageClick: (page: number) => void
    /** the amount of pagination buttons */
    buttonCount?: number
}

export const Paginator: React.SFC<PaginatorProps> = ({ onPageClick, maxPage, currentPage = 1, buttonCount = 2 }) => {
    currentPage = Math.min(currentPage, maxPage)

    let pages = Array.from(Array(maxPage).keys()).map(index => index + 1)
    let pageButtons = []

    const getPageButton = (pgs: number[], current: number) =>
        pgs.map(page => (
            <PaginatorComponents.Button
                isActive={page === current}
                disabled={page === current}
                key={page}
                onClick={() => onPageClick(page)}>
                {page}
            </PaginatorComponents.Button>
        ))

    if (maxPage > buttonCount * 2) {
        const start = Math.max(1, currentPage - buttonCount)
        pages = pages.slice(start - 1, currentPage + buttonCount)

        pageButtons = getPageButton(pages, currentPage)

        // Show start divider
        if (currentPage - 1 > buttonCount) {
            pageButtons.unshift(<PaginatorComponents.Separator key={1000000} />)
        }

        // Show end divider
        if (currentPage < maxPage - buttonCount) {
            pageButtons.push(<PaginatorComponents.Separator key={-1000000} />)
        }
    } else {
        pageButtons = getPageButton(pages, currentPage)
    }

    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === maxPage

    return maxPage > 1 ? (
        <PaginatorComponents.Wrapper>
            <PaginatorComponents.Button
                prevNextButton
                isHidden={isFirstPage}
                disabled={isFirstPage}
                aria-label={'First'}
                onClick={() => onPageClick(1)}>
                <SvgIcon icon="Double_Caret_Left" size="20" fill="currentColor" />
            </PaginatorComponents.Button>
            <PaginatorComponents.Button
                prevNextButton
                isHidden={isFirstPage}
                disabled={isFirstPage}
                aria-label={'Previous'}
                onClick={() => onPageClick(currentPage - 1)}>
                <SvgIcon icon="Chevron_Left" size="20" fill="currentColor" />
            </PaginatorComponents.Button>
            {pageButtons}
            <PaginatorComponents.Button
                prevNextButton
                isHidden={isLastPage}
                disabled={isLastPage}
                aria-label={'Next'}
                onClick={() => onPageClick(currentPage + 1)}>
                <SvgIcon icon="Chevron_Right" size="20" fill="currentColor" />
            </PaginatorComponents.Button>
            <PaginatorComponents.Button
                prevNextButton
                isHidden={isLastPage}
                disabled={isLastPage}
                aria-label={'Last'}
                onClick={() => onPageClick(maxPage)}>
                <SvgIcon icon="Double_Caret_Right" size="20" fill="currentColor" />
            </PaginatorComponents.Button>
        </PaginatorComponents.Wrapper>
    ) : null
}
