import React, { useContext } from 'react'

import { Field } from 'react-final-form'

import { MessageMap } from '@bc/translations'
import { GQLRequestsFilters, GQLRequestStatus, GQLRequestType } from '@bc/types'
import { CheckboxGroup, SvgIcon, TextInput, colors } from '@bc/ui'

import { FieldIcon } from '@frontend/components/filters/filters-components'
import { useFormatMessage } from '@frontend/components/hooks'
import { FiltersContextData, FiltersContext } from '@frontend/context'
import { finalFormValidations, NUM_CHAR_START_SEARCH, statusAsColor, statusAsIconName } from '@frontend/utils'

import { FiltersFormContainer } from './filters-form-container'

interface RequestsFiltersProps {
    filterRequestTypes: GQLRequestType[]
    isCsrLayout: boolean
}

const statuses = Object.keys(GQLRequestStatus) as GQLRequestStatus[]

export const RequestsFilters = ({ filterRequestTypes, isCsrLayout }: RequestsFiltersProps) => {
    const t = useFormatMessage()
    const { onFilterChange, filters, query, onLinkCopy }: FiltersContextData<GQLRequestsFilters> = useContext(
        FiltersContext,
    )

    return (
        <FiltersFormContainer
            onFilterChange={onFilterChange}
            initialValues={filters}
            query={query}
            onLinkCopy={onLinkCopy}>
            <Field
                name="material"
                validate={finalFormValidations.minLength(t, NUM_CHAR_START_SEARCH)}
                render={({ input, meta }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-name')}
                        type="search"
                        placeholder={t('filters:search-by-name.placeholder')}
                        hasError={meta.touched && meta.error}
                        errorText={meta.error}
                        data-test-id="input-filter-material-name"
                    />
                )}
            />
            <Field
                name="uid"
                render={({ input }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-request-no')}
                        type="search"
                        placeholder={t('filters:search-by-request-no.placeholder')}
                        data-test-id="input-filter-uid"
                    />
                )}
            />
            {isCsrLayout && (
                <Field
                    name="customer"
                    validate={finalFormValidations.composeValidations(
                        finalFormValidations.minLength(t, NUM_CHAR_START_SEARCH),
                        finalFormValidations.validSearchInput(t),
                    )}
                    render={({ input, meta }) => (
                        <TextInput
                            {...input}
                            labelText={t('filters:search-by-company-name')}
                            type="search"
                            placeholder={t('filters:search-by-company-name.placeholder')}
                            hasError={meta.touched && meta.error}
                            errorText={meta.error}
                            data-test-id="input-filter-customer-name"
                        />
                    )}
                />
            )}
            <CheckboxGroup
                labelText={t('filters:search-by-status')}
                name="status"
                id={'request-filter-status'}
                options={statuses}
                labelOptions={statuses.map(status => (
                    <>
                        {t(`request-status:${status}` as keyof MessageMap)}
                        <FieldIcon color={statusAsColor(status)} />
                    </>
                ))}
                colorScheme="deepPurple"
            />
            <CheckboxGroup
                labelText={t('filters:request-type')}
                name="type"
                id={'request-filter-type'}
                options={filterRequestTypes}
                labelOptions={filterRequestTypes.map(type => (
                    <>
                        {t(`request-type:${type}` as keyof MessageMap)}
                        <FieldIcon>
                            <SvgIcon icon={statusAsIconName(type)} size="24" fill={colors.deepPurple} />
                        </FieldIcon>
                    </>
                ))}
                colorScheme="deepPurple"
            />
        </FiltersFormContainer>
    )
}
