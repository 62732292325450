import React, { createContext } from 'react'

export type AnyFilter = Partial<Record<string, string | string[]>>
export interface FiltersContextData<T = AnyFilter> {
    onFilterChange: (filters: T) => void
    filters: T
    query?: Partial<T>
    onLinkCopy?: (filters: T) => void
}

interface FiltersProviderProps<T> extends FiltersContextData<T> {
    children: React.ReactNode
}

const initialContext: FiltersContextData<any> = { onFilterChange() {}, filters: {} }
export const FiltersContext = createContext(initialContext)

export const FiltersProvider = <T,>({
    onFilterChange,
    filters,
    query,
    onLinkCopy,
    children,
}: FiltersProviderProps<T>) => (
    <FiltersContext.Provider value={{ onFilterChange, filters, query, onLinkCopy }}>{children}</FiltersContext.Provider>
)

export const getFilterCount = (filters: AnyFilter | undefined) =>
    filters
        ? Object.values(filters).filter(
              value => !(typeof value === 'undefined' || (Array.isArray(value) && value.length === 0)),
          ).length
        : 0
