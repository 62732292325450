import { GQLCustomer } from '@bc/types'

export const getShipTos = (customer: GQLCustomer | undefined) => {
    if (!customer) {
        return []
    }
    return customer.shipTos || []
}

export const getBillTos = (customer: GQLCustomer | undefined) => {
    if (!customer) {
        return []
    }
    return customer.billTos || []
}

export const getTerms = (
    customer: GQLCustomer | undefined,
    selectedSalesOrganization: string,
    areaCode: string | undefined,
) =>
    customer?.terms.find(
        ({ salesOrganisation, salesAreaId, salesDivision }) =>
            salesOrganisation === selectedSalesOrganization &&
            (areaCode && areaCode.length > 2 ? salesAreaId === areaCode : salesDivision === areaCode),
    )
