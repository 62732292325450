import { QuoteCardBase } from '@frontend/components/cards/quote-card-base'
import React, { useContext, useState } from 'react'

import { ApolloError } from 'apollo-client'

import { QueryResultList, SortSelectWrapper, useCopyToClipboard, useFormatMessage } from '@frontend/components'
import { QuoteFilters } from '@frontend/components/filters/quote-filters'

import { GQLLocation, QuotationGroup, QuotationFilters } from '@bc/types'
import { FullWidthTitleNotificationTemplate, MercuryTemplate, useDocumentTitle } from '@bc/ui'
import { QuoteOrderModal } from '@frontend/components/modals/quote-order-modal'
import { UserContext, getFilterCount, FiltersContextData, FiltersContext } from '@frontend/context'
import { getLocationOrigin, trimLeadingZeros } from '@frontend/utils'

interface QuotesPageProps {
    shipTos: GQLLocation[]
    error: ApolloError | undefined
    quotations: QuotationGroup[] | undefined
}

export const QuotesPage = ({ error, quotations, shipTos }: QuotesPageProps) => {
    const t = useFormatMessage()
    useDocumentTitle(t('quotes:headline'))
    const copyToClipboard = useCopyToClipboard()
    const { activeCustomerId = '', activeSalesOrganisation = '', activeSalesAreaId = '' } = useContext(UserContext)
    const filterContext: FiltersContextData<QuotationFilters> = useContext(FiltersContext)

    const [modalOpen, setModalOpen] = useState(false)
    const [quote, setQuote] = useState<QuotationGroup>()

    const copyQuoteLink = (quoteIdToCopy: string) => {
        const { location } = window
        const origin = getLocationOrigin(location)
        copyToClipboard(
            `${origin}${location.pathname}?quoteId=${trimLeadingZeros(
                quoteIdToCopy,
            )}&cid=${activeCustomerId}&so=${activeSalesOrganisation}&sid=${activeSalesAreaId}`,
        )
    }

    const onModalClose = () => {
        setModalOpen(false)
        setQuote(undefined)
    }

    const onReorder = (selectedQuote: QuotationGroup) => {
        setQuote(selectedQuote)
        setModalOpen(true)
    }

    return (
        <MercuryTemplate title={t('general:filters')} filterCount={getFilterCount(filterContext?.query)}>
            {{
                sidebarContent: <QuoteFilters shipTos={shipTos} />,
                content: (
                    <FullWidthTitleNotificationTemplate title={t('quotes:headline')}>
                        <SortSelectWrapper />
                        <QueryResultList
                            error={error}
                            amountOfResults={quotations?.length}
                            noResultText={t('not-found:orders')}
                            chunkSize={10}>
                            {quotations?.map((q, index) => (
                                <QuoteCardBase
                                    quote={q}
                                    key={`${q.id}-${index}`}
                                    reorder={onReorder}
                                    onLinkCopy={copyQuoteLink}
                                />
                            ))}
                        </QueryResultList>
                        {quote && <QuoteOrderModal quote={quote} isOpen={modalOpen} onClose={onModalClose} />}
                    </FullWidthTitleNotificationTemplate>
                ),
            }}
        </MercuryTemplate>
    )
}
