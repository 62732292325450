import React, { useContext, useState } from 'react'

import { ExecutionResult } from '@apollo/react-common'
import { ApolloError } from 'apollo-client'

import { getCsrEmail, getCsrPhone } from '@bc/graphql-utils/src/user'
import { tagManager } from '@bc/gtm'
import {
    GQLPagination,
    GQLRequest,
    GQLRequestStatus,
    GQLRequestType,
    GQLSalesOrganisationId,
    GQLUpdateRequestInput,
    QuotationFilters,
} from '@bc/types'
import {
    Button,
    FullWidthTitleNotificationTemplate,
    MercuryTemplate,
    Paginator,
    StyledA,
    Text,
    useDocumentTitle,
} from '@bc/ui'
import {
    EditRequestModal,
    FormatNumber,
    QueryResultList,
    RequestResponse,
    RequestsCsrCard,
    RequestsFilters,
    SortSelectWrapper,
    useFormatMessage,
} from '@frontend/components'

import {
    SessionContext,
    SessionContextData,
    UserContext,
    FiltersContextData,
    FiltersContext,
    getFilterCount,
} from '@frontend/context'

import { ButtonMobileWrapper } from '@bc/ui/src/components/card/card-components'
import { ContactContainer, MetaWrapper } from './components'

interface RequestsPageProps {
    isCsrLayout: boolean
    formatNumber: FormatNumber
    error: ApolloError | undefined
    pagination: GQLPagination
    requests: GQLRequest[] | undefined
    onPageClick: (page: number) => void
    onEditRequest: (request: GQLRequest, values: GQLUpdateRequestInput) => Promise<ExecutionResult<GQLRequest>>
    filterRequestTypes: GQLRequestType[]
}

export const RequestsPage = ({
    error,
    requests,
    pagination,
    onPageClick,
    isCsrLayout,
    onEditRequest,
    filterRequestTypes,
}: RequestsPageProps) => {
    const t = useFormatMessage()

    const [editRequestModalOpen, setEditRequestModalOpen] = useState(false)
    const [selectedRequest, setSelectedRequest] = useState<GQLRequest | undefined>(undefined)
    const { me } = useContext(UserContext)
    const [, sessionState]: SessionContextData = useContext(SessionContext)
    const filterContext: FiltersContextData<QuotationFilters> = useContext(FiltersContext)

    const { to: csrEmail } = getCsrEmail(me!.meta, sessionState.activeSalesOrganisation as GQLSalesOrganisationId)
    const csrPhone = getCsrPhone(me!.meta, sessionState.activeSalesOrganisation as GQLSalesOrganisationId)

    const pageTitle = isCsrLayout ? t('requests:title-csr') : t('requests:title-customer')
    useDocumentTitle(pageTitle)

    const openEditRequestModal = (request: GQLRequest): void => {
        setEditRequestModalOpen(true)
        setSelectedRequest(request)
        if (isCsrLayout) {
            tagManager.tags.csrRequestsDocumentRespond(request.uid)
        } else {
            tagManager.tags.requestsDetails(request.uid)
        }
    }

    const handleEditRequestClose = () => {
        setEditRequestModalOpen(false)
    }

    const handleEditRequestSave = (values: GQLUpdateRequestInput): void => {
        if (!selectedRequest) {
            return
        }

        onEditRequest(selectedRequest, values)
        tagManager.tags.csrRequestsDocumentResponse(selectedRequest.uid)
        handleEditRequestClose()
    }

    return (
        <>
            <MercuryTemplate title={t('general:filters')} filterCount={getFilterCount(filterContext?.query)}>
                {{
                    sidebarContent: (
                        <RequestsFilters filterRequestTypes={filterRequestTypes} isCsrLayout={isCsrLayout} />
                    ),
                    content: (
                        <FullWidthTitleNotificationTemplate title={pageTitle}>
                            <MetaWrapper>
                                <SortSelectWrapper />
                                {!isCsrLayout && (
                                    <ContactContainer>
                                        <Text p noMargin>
                                            {t('requests:csr-subtitle-one')}
                                        </Text>
                                        <Text p>
                                            <StyledA
                                                href={`mailto:${csrEmail}`}
                                                onClick={() => tagManager.tags.requestsEmail(csrEmail)}>
                                                {csrEmail}
                                            </StyledA>{' '}
                                            {t('requests:csr-subtitle-two', undefined, { csrPhone })}
                                        </Text>
                                    </ContactContainer>
                                )}
                            </MetaWrapper>
                            <QueryResultList
                                error={error}
                                amountOfResults={requests?.length}
                                noResultText={t('not-found:requests')}>
                                {requests?.map((request, index) => {
                                    const buttonText = isCsrLayout
                                        ? request.status === GQLRequestStatus.COMPLETED
                                            ? t('requests:button.edit-complete')
                                            : t('requests:button.edit')
                                        : t('requests:details')
                                    const actions = (
                                        <ButtonMobileWrapper>
                                            <Button
                                                fullOnMobile
                                                variant="action"
                                                title={buttonText}
                                                onClick={() => openEditRequestModal(request)}
                                                data-test-class="button-answer-request">
                                                {buttonText}
                                            </Button>
                                        </ButtonMobileWrapper>
                                    )

                                    return (
                                        <RequestsCsrCard
                                            actions={actions}
                                            request={request}
                                            key={`${request.uid}-${index}`}
                                            content={<RequestResponse t={t} request={request} isCsr={isCsrLayout} />}
                                        />
                                    )
                                })}
                            </QueryResultList>
                            {!error && (
                                <Paginator
                                    maxPage={pagination.pages}
                                    currentPage={pagination.page}
                                    onPageClick={onPageClick}
                                />
                            )}
                        </FullWidthTitleNotificationTemplate>
                    ),
                }}
            </MercuryTemplate>
            {selectedRequest && (
                <EditRequestModal
                    t={t}
                    open={editRequestModalOpen}
                    request={selectedRequest}
                    onConfirm={handleEditRequestSave}
                    onClose={handleEditRequestClose}
                    isCsrLayout={isCsrLayout}
                    csrEmail={csrEmail}
                />
            )}
        </>
    )
}
