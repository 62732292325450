import React, { createRef } from 'react'

import { GQLFile } from '@bc/types'

import { Button } from '../button'
import { Text } from '../text'

import { FormInputComponents, TextInputComponents } from './components'
import { HiddenInput, Li, Ul } from './components/upload-files-components'

interface UploadFilesComponentProps {
    label: string
    change: any
    deleteFiles?: number[]
    files?: GQLFile[]
    filterFiles: (files: any[]) => any[]
    fileInputLabel: string
    maxUpload: string
    mimeTypes: string[]
    value: any
    onChange: any
    multiple: boolean
}

export const FileInput = ({
    change,
    accept,
    title,
    multiple,
}: {
    change: (value: any) => void
    accept: string
    title: string
    multiple: boolean
}) => {
    const fileRef = createRef<HTMLInputElement>()
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        change(event.target.files)
    }

    const handleFileClick = (event: React.MouseEvent<HTMLInputElement>) => {
        // reset the input control to enable the same file selection
        const element = event.target as HTMLInputElement
        element.value = ''
    }

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault()
        if (fileRef.current) {
            fileRef.current.click()
        }
    }

    return (
        <>
            <HiddenInput
                ref={fileRef}
                type="file"
                multiple={multiple}
                onChange={handleChange}
                onClick={handleFileClick}
                accept={accept}
                value=""
            />
            <Button variant="outline" title={title} onClick={handleClick}>
                {title}
            </Button>
        </>
    )
}

export const ListFiles = ({
    files,
    fileList,
    changeFileList,
    changeFiles,
}: {
    files: GQLFile[]
    fileList: FileList
    changeFileList: (value: any) => void
    changeFiles: (value: any) => void
}) => {
    const removeFile = (file: GQLFile) => {
        changeFiles(files.filter(f => f.id !== file.id))
    }
    const removeUploadFile = (file: File) => {
        changeFileList(Array.from(fileList).filter(f => f.name !== file.name))
    }
    const allFiles = files
        .map(f => ({
            fileName: f.fileName,
            fileSize: f.fileSize,
            mimeType: f.mimeType,
            remove: () => removeFile(f),
        }))
        .concat(
            Array.from(fileList).map(f => ({
                fileName: f.name,
                fileSize: f.size,
                mimeType: f.type,
                remove: () => removeUploadFile(f),
            })),
        )

    return allFiles.length ? (
        <Ul>
            {allFiles.map((file, i) => (
                <Li key={`file-list-${i}`}>
                    <Text noMargin>{file.fileName}</Text>
                    <Button onClick={file.remove} variant="error" icon="Delete" buttonSize="small" />
                </Li>
            ))}
        </Ul>
    ) : null
}

export const UploadFiles: React.FunctionComponent<UploadFilesComponentProps> = ({
    label,
    fileInputLabel,
    filterFiles,
    mimeTypes,
    maxUpload,
    change,
    value,
    onChange,
    deleteFiles,
    files,
    multiple,
}) => (
    <FormInputComponents.InputWrapper>
        <TextInputComponents.Label>{label}</TextInputComponents.Label>

        <FileInput
            multiple={multiple}
            accept={mimeTypes.join(',')}
            title={fileInputLabel}
            change={(f: FileList) => {
                // do not add the same files which already added
                const filteredValue = value || []
                const filteredFiles = Array.from(f).filter((file: File) =>
                    filteredValue.every((v: File) => v.name !== file.name),
                )
                return change('files', filterFiles([...filteredFiles, ...filteredValue]))
            }}
        />
        <FormInputComponents.LabelText>{maxUpload}</FormInputComponents.LabelText>

        <ListFiles
            files={(files || []).filter((f: any) => !(deleteFiles || []).includes(f.id))}
            fileList={value}
            changeFileList={onChange}
            changeFiles={newFiles => {
                const fileIds = newFiles.map((f: GQLFile) => f.id)
                const remove = (files || []).filter((f: GQLFile) => !fileIds.includes(f.id)).map((f: GQLFile) => f.id)
                const deleteFilesFn = Array.from(new Set([...(deleteFiles || []), ...remove]))
                change('deleteFiles', deleteFilesFn)
            }}
        />
    </FormInputComponents.InputWrapper>
)
