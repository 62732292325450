import styled from 'styled-components'

import { breakpoints, colors, spacing, transition } from '@bc/ui/src/config'
import { hideOnMax, hideOnMin, media } from '@bc/ui/src/helpers'

interface TableHeaderProps {
    ratio: number
    basis: number
    sortable: boolean
    first?: boolean
    hiddenMax?: keyof typeof breakpoints
    hiddenMin?: keyof typeof breakpoints
}
export const TableHeader = styled.div`
    display: flex;
    align-items: center;
    transition: ${transition.config};
    transition-property: color, background;
    overflow: hidden;
    color: ${colors.neutral50};
    text-overflow: ellipsis;
    cursor: ${({ sortable }: TableHeaderProps) => (sortable ? 'pointer' : 'default')};
    flex: ${({ first }: TableHeaderProps) => (first ? `0 0 auto` : `1 0 auto`)};
    width: ${({ basis }: TableHeaderProps) => `${basis}%`};

    ${media.min('sm')} {
        padding: 16px ${spacing.sm}px;
    }

    ${media.max('sm')} {
        width: 50%;
        flex: auto;
    }

    ${({ hiddenMax }: TableHeaderProps) => (hiddenMax ? hideOnMax(hiddenMax) : {})}
    ${({ hiddenMin }: TableHeaderProps) => (hiddenMin ? hideOnMin(hiddenMin) : {})}
`
