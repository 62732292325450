import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Track: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Track" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M19.07 4.93L17.66 6.34C19.17 7.85 20 9.86 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12C4 7.93 7.06 4.56 11 4.06V6.09C8.17 6.57 6 9.03 6 12C6 15.31 8.69 18 12 18C15.31 18 18 15.31 18 12C18 10.4 17.38 8.89 16.24 7.76L14.83 9.17C15.59 9.92 16 10.93 16 12C16 14.21 14.21 16 12 16C9.79 16 8 14.21 8 12C8 10.14 9.28 8.59 11 8.14V13H13V2H12C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 9.33 20.96 6.82 19.07 4.93Z"
                fill={fill === 'gradient' ? 'url(#Track_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Track" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Track" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M19.07 4.93L17.66 6.34C19.17 7.85 20 9.86 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12C4 7.93 7.06 4.56 11 4.06V6.09C8.17 6.57 6 9.03 6 12C6 15.31 8.69 18 12 18C15.31 18 18 15.31 18 12C18 10.4 17.38 8.89 16.24 7.76L14.83 9.17C15.59 9.92 16 10.93 16 12C16 14.21 14.21 16 12 16C9.79 16 8 14.21 8 12C8 10.14 9.28 8.59 11 8.14V13H13V2H12C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 9.33 20.96 6.82 19.07 4.93Z"
                fill={fill === 'gradient' ? 'url(#Track_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Track" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Track" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M19.07 4.93L17.66 6.34C19.17 7.85 20 9.86 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12C4 7.93 7.06 4.56 11 4.06V6.09C8.17 6.57 6 9.03 6 12C6 15.31 8.69 18 12 18C15.31 18 18 15.31 18 12C18 10.4 17.38 8.89 16.24 7.76L14.83 9.17C15.59 9.92 16 10.93 16 12C16 14.21 14.21 16 12 16C9.79 16 8 14.21 8 12C8 10.14 9.28 8.59 11 8.14V13H13V2H12C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 9.33 20.96 6.82 19.07 4.93Z"
                fill={fill === 'gradient' ? 'url(#Track_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Track" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Track" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M19.07 4.93L17.66 6.34C19.17 7.85 20 9.86 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12C4 7.93 7.06 4.56 11 4.06V6.09C8.17 6.57 6 9.03 6 12C6 15.31 8.69 18 12 18C15.31 18 18 15.31 18 12C18 10.4 17.38 8.89 16.24 7.76L14.83 9.17C15.59 9.92 16 10.93 16 12C16 14.21 14.21 16 12 16C9.79 16 8 14.21 8 12C8 10.14 9.28 8.59 11 8.14V13H13V2H12C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 9.33 20.96 6.82 19.07 4.93Z"
                fill={fill === 'gradient' ? 'url(#Track_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Track" />}
        </g>
    ),
}
