import React, { useState } from 'react'

import { QueryResult } from '@apollo/react-common'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { FORM_ERROR, SubmissionErrors } from 'final-form'
import qs from 'query-string'

import { FullScreenLoaderRound, useDocumentTitle } from '@bc/ui'
import { useFormatMessage } from '@frontend/components'

import { finalFormValidations } from '@frontend/utils'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ResetPassword, ValidateResetPasswordToken, ValidateResetPasswordTokenResponse } from './queries'
import { SetPasswordPage } from './set-password-page'
import { SetPasswordSuccessPage } from './set-password-success-page'

export interface SetPasswordValues {
    newPassword: string
    confirmNewPassword: string
}

const SetPasswordPageContainerClass = ({ history }: RouteComponentProps<any>) => {
    const t = useFormatMessage()
    const { token, email } = qs.parse(location.search) as { token: string; email: string; locale: string }
    const [passwordChanged, setPasswordChanged] = useState(false)

    const variables = { token, email }
    const { error, loading: tokenloading, data }: QueryResult<ValidateResetPasswordTokenResponse> = useQuery(
        ValidateResetPasswordToken,
        {
            variables,
            skip: !token,
        },
    )

    const [resetPassword, { loading: resetLoading }] = useMutation(ResetPassword)

    useDocumentTitle(t('route:set-password.label'))

    const translateAuth0ErrorToLocalMessage = (message: string) => {
        const regexResult = /(\w+):./.exec(message)
        let localMessage = ''
        if (regexResult) {
            const key = `auth0-error:${regexResult[1]?.toLowerCase()}`
            localMessage = t(key)
            localMessage = localMessage === key ? t('form-validation:password') : localMessage
        }

        return localMessage
    }

    const redirectToLogin = () => {
        setPasswordChanged(false)
        history.replace(`/login`)
    }

    const onSubmit = async ({
        newPassword,
        confirmNewPassword,
    }: SetPasswordValues): Promise<SubmissionErrors | undefined | void> => {
        // check if new password is the same as the confirmation one
        const err = finalFormValidations.isEqualTo(t, String(confirmNewPassword))(String(newPassword))
        if (err) {
            return { [FORM_ERROR]: err }
        }

        const { data: resetpasswordData } = await resetPassword({
            variables: {
                email,
                token,
                password: newPassword,
            },
        })

        if (resetpasswordData.resetPassword.error) {
            // Auth0 errors:
            // PasswordDictionaryError: Password is too common
            // PasswordStrengthError: Password is too weak
            // PasswordHistoryError: Password has previously been used.
            // PasswordNoUserInfoError: Password contains user information
            // CsrfInvalidTokenError: Invalid CSRF token
            return { [FORM_ERROR]: translateAuth0ErrorToLocalMessage(resetpasswordData.resetPassword.error) }
        }

        setPasswordChanged(true)
        return
    }

    return passwordChanged ? (
        <SetPasswordSuccessPage t={t} onClick={redirectToLogin} />
    ) : (
        <>
            <FullScreenLoaderRound isVisible={tokenloading || resetLoading} />
            {data && (
                <SetPasswordPage
                    isTokenValid={data.validateResetPasswordToken && !error}
                    onSubmit={onSubmit}
                    token={token}
                    email={email}
                    t={t}
                />
            )}
        </>
    )
}

export default withRouter(SetPasswordPageContainerClass)
