import { useEffect, useState } from 'react'

export const useClickOutside = (
    refs: React.RefObject<HTMLElement>[],
    onOutsideClick: (e: MouseEvent | TouchEvent) => void,
): [boolean] => {
    // const ref = useRef<HTMLElement>(null)
    const [hasClickedOutside, setHasClickedOutside] = useState(false)

    function handleEvent(e: MouseEvent | TouchEvent) {
        if (refs) {
            const test = refs.map(ref => ref.current !== null && !ref.current.contains(e.target as HTMLElement))

            if (test.every(Boolean)) {
                setHasClickedOutside(true)
                if (typeof onOutsideClick === 'function') {
                    onOutsideClick(e)
                }
            } else {
                setHasClickedOutside(false)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleEvent)
        document.addEventListener('touchstart', handleEvent)

        return () => {
            document.removeEventListener('mousedown', handleEvent)
            document.removeEventListener('touchstart', handleEvent)
        }
    }, [[refs, onOutsideClick]])

    return [hasClickedOutside]
}
