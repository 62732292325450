import React, { useEffect, useState } from 'react'
import { getCookie } from 'react-use-cookie'

import { config, defaultConfig, roleConfig, shopConfig } from '@bc/config'
import { withCurrentUser, WithCurrentUserProps } from '@frontend/components/hocs/with-current-user'
import { extractLocale, RegionCode, languages, getDefaultLocaleForLanguage, Language } from '@bc/translations'
import { UserIntlProvider } from '@frontend/components/intl/user-intl-provider'

import { PlatformConfigContext } from './platform-config-context'

type UserIntlProviderProps = WithCurrentUserProps & { children: any }

const PlatformProviderComponent = ({
    me,
    userLocale,
    currentCustomer,
    children,
}: UserIntlProviderProps): JSX.Element => {
    const region = (me?.meta.region as RegionCode) || extractLocale(userLocale).region
    const appConfig = region ? config[region] : defaultConfig
    shopConfig.current = currentCustomer ? shopConfig[currentCustomer.shopId] : undefined
    const [locale, setLocale] = useState(userLocale)

    useEffect(() => {
        // this is to support the login page if no locale is set to cookies first time
        // on login the locale is set to cookie and this no longer runs
        const localeCookie = getCookie('locale')
        if (localeCookie === locale) {
            return // we have correct user locale
        }

        let browserLocale: string | undefined = localeCookie
        if (!localeCookie) {
            const navigatorLanguage = navigator.language ?? (navigator as IENavigator).userLanguage
            const appLanguage = languages.find(l => l === navigatorLanguage) ?? 'en'
            browserLocale = getDefaultLocaleForLanguage(appLanguage as Language) as string
        }
        setLocale(browserLocale)
    }, [userLocale, me])

    return (
        <UserIntlProvider userLocale={locale}>
            <PlatformConfigContext.Provider value={{ appConfig, shopConfig, roleConfig }}>
                {children}
            </PlatformConfigContext.Provider>
        </UserIntlProvider>
    )
}

export const PlatformProvider = withCurrentUser(PlatformProviderComponent)
