import React from 'react'

import { colors, media, spacing, SvgIcon } from '@bc/ui'
import { useFormatMessage } from '@frontend/components'

import styled from 'styled-components'

import { config } from '@bc/config'
import { RegionCode } from '@bc/translations'
import { IntroText } from './login-components'

interface LoginOfficeInfoProps {
    countryCode: RegionCode
}

const OfficeInfoWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    margin: ${spacing.xxs}px 0;
    ${media.max('sm')} {
        flex-direction: column;
    }
`

const OfficeInfoIcon = styled(SvgIcon)`
    margin-right: 10px;
`

const OfficeInfoText = styled(IntroText)`
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    padding-right: 20px;
    margin: 8px inherit;
    min-width: 180px;
    &:nth-child(odd) {
        border-right: solid 1px white;
    }
    &:nth-child(even) {
        padding-left: 20px;
    }

    ${media.max('sm')} {
        &:nth-child(odd) {
            border-right: none;
        }
        &:nth-child(even) {
            padding-left: 0;
        }
    }
`

/**
 * @param root0
 * @param root0.countryCode
 * @example
 */
export default function LoginOfficeInfo({ countryCode }: LoginOfficeInfoProps) {
    const t = useFormatMessage()
    const countryConfig = config[countryCode]
    return countryConfig ? (
        <>
            <IntroText fontWeight="bold" color="white">
                {t(`country:${countryCode}`)}
            </IntroText>
            <OfficeInfoWrapper>
                <OfficeInfoText noMargin data-test-id={`label-login-office-info-${countryCode}`}>
                    <OfficeInfoIcon icon="Phone" fill={colors.white} size="20" />
                    {countryConfig.phone.csr}
                </OfficeInfoText>

                <OfficeInfoText noMargin data-test-id="label-login-OfficeInfo">
                    <OfficeInfoIcon icon="Mail" fill={colors.white} size="20" />
                    {countryConfig.email.csr}
                </OfficeInfoText>
            </OfficeInfoWrapper>
        </>
    ) : null
}
