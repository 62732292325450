// tslint:disable no-invalid-template-strings

import { MessageMap } from '../../'

// TODO pending for translation
const messages: MessageMap = {
    'gender:male': 'Mężczyzna',
    'gender:female': 'Kobieta',
    'gender:unknown': 'Nieznana',

    // Routes
    'route:maintenance': '/maintenance',
    'route:document': '/document',
    'route:document-not-found': '/document-not-found',

    'route:session-expired': '/session-expired',

    'route:logout': '/logout',
    'route:logout.label': 'Wyloguj',

    'route:reset-password': '/reset-password',
    'route:reset-password.label': 'Zresetuj hasło',

    'route:set-password': '/set-password',
    'route:set-password.label': 'Ustaw hasło',

    'route:forgot-password': '/forgot-password',

    'route:terms-of-usage': '/terms-of-usage',
    'route:terms-of-usage.label': 'Warunki korzystania z usług',

    'route:product-list': '/product-list',
    'route:product-list.label': 'Lista produktów',

    'route:order-history': '/my-orders',
    'route:order-history.label': 'Historia zamówień',

    'route:invoices': '/invoices',
    'route:invoices.label': 'Faktury',

    'route:cart-overview': '/checkout',
    'route:cart-overview.label': 'Sprawdź koszyk',

    'route:imprint.label': 'Dane firmy',
    'route:data-privacy-policy.label': 'Polityka prywatności',
    'route:faq.label': 'Najczęściej zadawane pytania',

    'route:my-customers': '/my-customers',
    'route:my-customers.label': 'Moje konta',

    'route:contacts': '/contacts',
    'route:contacts.label': 'Użytkownicy',

    'route:quotes': '/oferty',
    'route:quotes.label': 'Oferty',

    'route:requests': '/requests',
    'route:requests.label': 'Zapytania',

    'route:account': '/account',

    // Salutations
    'salutation.formal.male': 'Witaj',
    'salutation.formal.female': 'Witaj',
    'salutation.formal.unknown': 'Drogi Kliencie',
    'salutation.informal.male': 'Drogi Kliencie',
    'salutation.informal.female': 'Drogi Kliencie',
    'salutation.informal.unknown': 'Drogi Kliencie',
    'salutation.internal.unknown': 'Drogi kolego',

    // Email subjects
    'email:subject:ORDER_CONFIRM': 'Brenntag Connect | Numer zamówienia {orderID} Nowe zamówienie Brenntag Connect',
    'email:subject:RFI_SALES_PERSON': 'Brenntag Connect | Prośba o informację {uid} od {name}',
    'email:subject:RFI_CUSTOMER': 'Brenntag Connect | Prośba o informację {uid}',
    'email:subject:RFI_COMPLETE': 'Brenntag Connect | Odpowiedź na zapytanie {uid}',
    'email:subject:RQ_CUSTOMER': 'Brenntag Connect | Zamówienie {uid}',
    'email:subject:RQ_SALES_PERSON': 'Brenntag Connect | Zamówienie {uid} od {name}',
    'email:subject:ORDER_WO_PRICE': 'Brenntag Connect | Zamówienie bez aktualnej oferty',
    'email:subject:RESET_PW': 'Brenntag Connect | Prośba o zmianę hasła',
    'email:subject:WELCOME': 'Brenntag Connect | Witamy w Brenntag Connect',
    'email:subject:SC_CUSTOMER': 'Brenntag Connect | Zamówienie {uid}',
    'email:subject:SC_SALES_PERSON': 'Brenntag Connect | Zamówienie {uid} od {name}',

    // Cookiebar
    'cookiebar:text1':
        'W trosce o jakość świadczonych usług nasza strona korzysta z plików cookies w celu pozyskania danych statystycznych. Wybiesz OK aby wyrazić zgodę na naszą politykę plików cookies. ',
    'cookiebar:text2': 'W celu uzyskania szczegółowych informacji, zapoznaj się z',
    'cookiebar:accept-button': 'OK',

    // FAQ
    'faq:contact-title': 'Jak możemy Ci pomóc?',
    'faq:contact-text':
        'Jesteśmy tu po to, abyś uzyskał/a jak najwięcej z Brenntag Connect. Bez względu na pytanie lub problem, wystarczy wysłać do nas e-mail, a my odpowiemy tak szybko jak to możliwe.',
    'faq:browse-categories': 'Kategorie wyszukiwania',

    // Login page
    'login:header': 'Zaloguj się na swoje konto',
    'login:welcome-header': 'Witamy w Brenntag Connect!',
    'login:intro':
        'Brenntag Connect to platforma e-commerce, która zapewnia łatwy dostęp do wszystkich produktów chemicznych, dokumentów oraz do aktualnych informacji o złożonych zamówieniach w firmie Brenntag.',
    'login:intro-benefit': 'Jako zarejestrowany klient otrzymasz możliwość korzystania z szeregu usług online:',
    'login:intro-bullet-1':
        'Wyszukiwania i przeglądania szczegółowych informacji o produktach i załączonych dokumentów technicznych',
    'login:intro-bullet-2': 'Przeglądania aktualnych cen (w zależności od dostępności)',
    'login:intro-bullet-3': 'Składania zamówień online',
    'login:intro-bullet-4': 'Przeglądania historii zamówień i listy faktur z ostatnich 24 miesięcy',
    'login:help-title': 'Czy możemy Ci jeszcze jakoś pomóc?',
    'login:help-instruction':
        'Prosimy o kontakt z naszym działem obsługi klienta. Chętnie odpowiemy na wszelkie pytania.',
    'login:help-phone-number': 'Numer telefonu:',
    'login:help-office-hours': 'Nasze godziny pracy:',
    'login:contact-more': 'Lub wyślij nam wiadomość e-mail:',
    'login:contact-button': 'Skontaktuj się z nami',
    'login:copyright': 'Wszelkie prawa zastrzeżone.',
    'login:brenntag': '© Brenntag SE',

    'login:username': 'Adres e-mail',
    'login:username-placeholder': 'twoj@email.com',
    'login:password': 'Hasło',
    'login:password-placeholder': 'Wprowadź swoje hasło',
    'login:button.forgot-password': 'Nie pamiętasz hasła?',
    'login:back-to-login': 'Przejdź do logowania',

    // Login page with MFA
    'login-mfa:title': 'Proszę potwierdzić swoją tożsamość.',
    'login-mfa:intro':
        'Wysłaliśmy kod weryfikacyjny na adres e-mail. Wprowadź kod z e-mail i kliknij "Potwierdź". Jako zarejestrowany klient otrzymasz dostęp do naszych usług online:',
    'login-mfa-form:input': 'Wprowadź 6-cyfrowy kod',
    'login-mfa-form:submit': 'Potwierdź',

    // Login form
    'login-form:error': 'Logowanie nie powiodło się. Sprawdź swoje dane.',
    'login-form:error.access_denied': 'Logowanie nie powiodło się. Sprawdź swoje dane.',
    'login-form:error.too_many_attempts': 'Konto jest zablokowane z powodu zbyt wielu prób zalogowania.',

    // Reset password page
    'reset-password:title': 'Ustaw swoje hasło.',
    'reset-password:body':
        'Sprawdź swoją skrzynkę odbiorczą - wysłaliśmy Ci e-mail z instrukcją jak ustawić nowe hasło.',

    // Set password token error
    'warning:invalid-token:title': 'Błąd!',
    'warning:invalid-token:description': 'Link wygasł! Proszę ponownie zresetować hasło.',

    // Auth error page
    'auth-error:message': 'Logowanie nie powiodło się. Spróbuj zalogować się ponownie.',
    'auth-error:expired-link:title': 'Link aktywacyjny wygasł.',
    'auth-error:expired-link:message': 'Twój link aktywacyjny wygasł. Aby uzyskać nowy link, zresetuj ponownie hasło.',
    'auth-error:link-text': 'Zresetuj swoje hasło',
    'auth0-error:passworddictionaryerror': 'Podane hasło jest zbyt powszechne.',
    'auth0-error:passwordstrengtherror': 'Podane hasło jest zbyt słabe.',
    'auth0-error:passwordhistoryerror': 'Podane hasło było już wcześnej użyte.',
    'auth0-error:passwordnouserinfoerror': 'Podane hasło zawiera dane użykownika.',
    'auth0-error:csrfinvalidtokenerror': 'Link wygasł! Proszę ponownie zresetować hasło.',

    // Rate limit error
    'rate-limit-error:message': 'Zbyt wiele nieudanych prób. Spróbuj ponownie później.',

    // Forgot password form
    'forgot-password-form:title': 'Nie pamiętasz hasła?',
    'forgot-password-form:description':
        'Jeśli Twoje konto zostało już założone, otrzymasz wiadomość na podany adres e-mail z instrukcją jak zresetować hasło.',

    'forgot-password-form:email': 'E-mail',
    'forgot-password-form:submit': 'Wyślij e-mail',
    'forgot-password-form:success': 'Wysłaliśmy do Ciebie e-mail z instrukcją jak zresetować hasło.',

    // Set password form
    'set-password-form:title': 'Ustaw hasło',
    'set-password-form:description':
        'Proszę ustaw nowe hasło. Hasło powinno składać się z min. 8 znaków, w tym z co najmniej jednej wielkiej litery oraz jednej cyfry.',
    'set-password-form:new-password': 'Nowe hasło',
    'set-password-form:new-password-confirmation': 'Powtórz nowe hasło',
    'set-password-form:password-instruction': 'Ustaw nowe hasło. Nowe hasło będzie ważne przez następne 90 dni.',
    'set-password-form:success-title': 'Sukces!',
    'set-password-form:success':
        'Drogi Kliencie, \nw trosce o zapewnienie bezpiecznego dostępu do platformy Brenntag Connect, wdrożyliśmy uwierzytelnianie dwuetapowe (2FA). Zostaliście Państwo wylogowani automatycznie. \nAby uzyskać dostęp do Brenntag Connect, prosimy o przejście do strony logowania, wprowadzenie swojego hasła, a następnie potwierdzenie go kodem uwierzytelniającym, który otrzymacie Państwo na swoją skrzynkę e-mail.',

    // My Customers Page
    'my-customers:city': 'Miasto',
    'my-customers:customer-id': 'Numer klienta',
    'my-customers:customer-name': 'Nazwa klienta',
    'my-customers:customer-vat-number': 'Numer VAT',
    'my-customers:headline': 'Moje konta',
    'my-customers:last-order-date': 'Data ostatniego zamówienia',
    'my-customers:products': 'Produkty',
    'my-customers:outstanding-orders': 'Niezrealizowane zamówienia',
    'my-customers:items-in-cart': 'Produkty w koszyku',
    'my-customers:search-for-customer': 'Szukaj Klienta',
    'my-customers:select-user': 'Wybierz',
    'my-customers:use-search-options': 'Użyj dowolnej z następujących opcji wyszukiwania',
    'my-customers:search-error': 'Wystąpił błąd podczas wyszukiwania klientów.',
    'my-customers:division': 'Wydział',

    // Requests Page
    'requests:title-csr': 'Zapytania klientów',
    'requests:title-customer': 'Moje zapytania',
    'requests:application': 'Zastosowanie',
    'requests:csr-subtitle-one': 'Chcesz się z nami skontaktować? Napisz do nas na adres:',
    'requests:csr-subtitle-two': 'lub zadzwoń {csrPhone}',
    'requests:delivery-date': 'Data dostarczenia',
    'requests:documents': 'Dokumenty',
    'requests:documents-max-size': '(Maks. {maxSize} MB)',
    'requests:email': 'E-mail użytkownika',
    'requests:messages': 'Wiadomości',
    'requests:po-reference': 'Numer zamówienia klienta',
    'requests:quantity-annual': 'Roczne zapotrzebowanie',
    'requests:quantity-call-off': 'Zapotrzebowanie w pojedynczej dostawie',
    'requests:quantity-sample': 'Ilość próbki',
    'requests:requested': 'Zapytanie złożono',
    'requests:requested-documents': 'Wnioskowane dokumenty',
    'requests:ship-to-address': 'Adres dostawy',
    'requests:status': 'Status',
    'requests:button.edit-complete': 'Edytuj odpowiedź',
    'requests:button.edit': 'Odpowiedz',
    'requests:edit-modal-title': 'Edytuj zapytanie',
    'requests:edit-modal-summary': 'Podsumowanie wniosku',
    'requests:comments': 'Komentarze',
    'requests:file-too-big': 'Plik {name} jest zbyt duży',
    'requests:max-upload': 'Przekroczono maks. wielkoś pliku {mb}MB. Plik {name} nie został dodany.',
    'requests:mime-types': 'Plik {name} typu: {type} nie jest dozwolony',
    'requests:customer': 'Klient',
    'requests:recipient': 'Osoba kontaktowa',
    'requests:details': 'Szczegóły',
    'requests:user-comment': 'Komentarz użytkownika',
    'requests:response': 'Nasz zespół odpowie na zapytanie jak najszybciej!',
    'requests:questions':
        'Nadal nie otrzymałeś odpowiedzi na przesłane zapytanie? Przykro nam! Coś musiało pójść nie tak. Skontaktuj się z nami bezpośrednio pod adresem email:',

    // Request enum
    'request-status:COMPLETED': 'Zakończony',
    'request-status:PENDING': 'Oczekuje',
    'request-type:DOCUMENT': 'Dokument',
    'request-type:PRICE': 'Oferta',
    'request-type:PROFILE': 'Profil',
    'request-type:QUOTE': 'Zamówienie',
    'request-type:SAMPLE': 'Próbka',
    'request-type:CART': 'Zamówienie',

    // User management page
    'user:country': 'Kraj',
    'user:role': 'Rola',
    'user:customer-id': 'Numer klienta',
    'user:last-login': 'Ostatnie logowanie',
    'user:send-emails': 'Wyślij ponownie wiadomości e-mail',
    'user:email-welcome': 'Wyślij ponownie e-mail powitalny',
    'user:email-welcome-to-support': 'Wyślij e-mail powitalny do pomocy technicznej DigiB',
    'user:email-password': 'Wyślij ponownie e-mail z resetowaniem hasła',
    'user:email-password-to-support': 'Wyślij e-mail z resetowaniem hasła do pomocy technicznej DigiB',
    'user:admin-actions': 'Działania administratora',
    'user:select-admin-action': 'Wybierz działanie',
    'user:email-welcome-sent': 'E-mail powitalny wysłano',
    'user:edit-user': 'Edytuj',
    'user:create-new-user': 'Utwórz użytkownika',
    'user:sort:username': 'Nazwa użytkownika',
    'user:sort:logindate': 'Data logowania',
    'user:email-modal-welcome-title': 'Ponownie wyślij e-mail powitalny',
    'user:email-modal-welcome-text':
        'Czy na pewno chcesz ponownie wysłać powitalną wiadomość e-mail do tego użytkownika ({email})?',
    'user:email-modal-welcome-to-support-text':
        'Czy na pewno chcesz wysłać powitalną wiadomość e-mail do pomocy technicznej DigiB?',
    'user:email-modal-welcome-cancel': 'Anuluj',
    'user:email-modal-welcome-confirm': 'Wyślij e-mail',
    'user:email-modal-pwreset-title': 'Wyślij e-mail do resetowania hasła',
    'user:email-modal-pwreset-text':
        'Czy na pewno chcesz ponownie wysłać wiadomość e-mail resetowania hasła do tego użytkownika ({email})?',
    'user:email-modal-pwreset-to-support-text':
        'Czy na pewno chcesz wysłać wiadomość e-mail resetowania hasła do pomocy technicznej DigiB?',
    'user:email-modal-pwreset-cancel': 'Anuluj',
    'user:email-modal-pwreset-confirm': 'Wyślij e-mail',
    'user:edit-user-modal-title': 'Edytuj użytkownika',
    'user:create-user-modal-title': 'Nowy użytkownik',
    'user:limited-search-results':
        'Pokazanie {number} z {count} wyników. Proszę użyć filtrów po lewej stronie, aby znaleźć konkretnych użytkowników.',

    // Form labels & placeholders user management
    'user:form:label-email': 'E-mail',
    'user:form:label-email-placeholder': 'Wprowadź e-mail',
    'user:form:label-firstname': 'Imię',
    'user:form:label-firstname-placeholder': 'Wprowadź imię',
    'user:form:label-lastname': 'Nazwisko',
    'user:form:label-lastname-placeholder': 'Wprowadź nazwisko',
    'user:form:label-customerids': 'Numer klienta',
    'user:form:label-customerids-placeholder': 'Wprowadź numer klienta',
    'user:form:label-language': 'Język',
    'user:form:label-gender': 'Płeć',
    'user:form:label-country': 'Kraj',
    'user:form:label-customer-id-and-sales-org': 'Numer klienta i organizacja sprzedaży',
    'user:form:label-customer-grant-access': 'Udziel klientowi lub klientom dostęp do:',
    'user:form:error-customer-grant-access': 'Udziel klientowi lub klientom dostęp do:',
    'user:form:error-select-one-sales-org': 'Dodaj co najmniej jedną organizację sprzedaży.',
    'user:form:error-search': 'Coś poszło nie tak, wyszukaj ponownie.',
    'user:form:search-no-results':
        'Nie znaleziono żadnych wyników wyszukiwania, można wyszukiwać według nazwy klienta, numeru klienta lub numeru VAT.',
    'user:form:usertype-viewer': 'Ten użytkownik może pobierać tylko dokumenty.',
    'user:form:label-usertype': 'Uprawnienia użytkownika',
    'user:form:label-mfa': 'Uwierzytelnianie wieloskładnikowe (MFA)',
    'user:form:label-enablemfa': 'Włączanie usługi MFA',
    'user:form:label-placeholder': 'Proszę wybrać uprawnienia użytkownika',

    // Customer Selection Dropdown
    'company:selection:label-bill-to': 'Adres faktury:',
    'company:selection:label-country': 'Kraj:',
    'company:selection:change': 'Zmiana',
    'company:selection:label-customer-id': 'Numer klienta:',
    'company:selection:label-vat': 'Numer VAT:',
    'company:selection:label-name': 'Nazwa',
    'company:selection:title': 'Wybierz firmę',
    'company:selection:intro.one': 'Aby przejść dalej i zobaczyć szczegóły',
    'company:selection:intro.two': 'Prosimy o wybranie nazwy firmy z tabeli poniżej',

    // Products
    'products:column.material': 'Produkt',
    'products:card.material': 'Nazwa produktu',
    'products:column.cas-no': 'Numer CAS',
    'products:column.filling-weight': 'Waga jednostkowa',
    'products:column.supplier': 'Dostawca',
    'products:column.grade': 'Jakość',
    'products:column.detail-packaging': 'Szczegóły opakowania',
    'products:column.einecs': 'EINECS',
    'products:column.unit-capacity': 'Pojemność jednostkowa',

    'products:more-details-button-text': 'Więcej szczegółów',
    'products:blend': '...',
    'products:column.material-number': 'Numer produktu',

    // Product list
    'product-list:headline': '{count} produktów',
    'product-list:empty-cart': 'Lista produktów',
    'product-list:request-info': 'Info',

    // Product Modal
    'product-modal:scale-prices': 'Cena netto',

    // Contact person
    'contact-person:title': 'Masz pytania dotyczące tego zamówienia?',
    'contact-person:subtitle': 'Kontakt z Działem Obsługi Klienta',
    'contact-person:customer-service': 'Dział Obsługi Klienta',

    // Add to cart form
    'add-to-cart-form:success-toast': 'Produkt został pomyślnie dodany do koszyka.',

    // Checkout
    'checkout:headline': 'Podsumowanie zamówienia',
    'checkout:products': 'Produkty',
    'checkout:empty-cart': 'Twój koszyk jest pusty',
    'checkout:empty-cart-message': 'Rozpocznij dodając produkty do zamówienia lub przeglądając listę produktów',
    'checkout:button-continue-shopping': 'Kontynuuj zakupy',

    'checkout-success:order-submitted': 'Twoje zamówienie zostało przesłane!',
    'checkout-success:final-instruction':
        'Oczekuj od nas wiadomości e-mail po potwierdzeniu Twojego zamówienia. <br/>Użyj Brenntag Connect do pobierania faktur, specyfikacji technicznych i kart charakterystyki.  <br/><br/>W przypadku pytań związanych z tym zamówieniem prosimy o kontakt mailowy na adres <a href="mailto:">{email}</a>',
    'checkout-success:order-number': 'Numer zamówienia',
    'checkout-success:order-date': 'Data zapytania',
    'checkout-success:button-view-requests': 'Przeglądaj prośby',
    'checkout-success:button-view-orders': 'Przeglądaj zamówienia',

    // Delivery overview
    'delivery-overview:title': 'Podsumowanie dostawy',
    'delivery-overview:billing-address': 'Adres na fakturze',
    'delivery-overview:comment': 'Komentarze',
    'delivery-overview:delivery-date': 'Żądana data dostawy',
    'delivery-overview:legal-terms': ' stanowią integralną część zamówienia.',
    'delivery-overview:legal-terms-name': 'Ogólne Warunki Sprzedaży',
    'delivery-overview:legal-terms-price': 'Cena netto',
    'delivery-overview:payment-terms': 'Termin płatności',
    'delivery-overview:no-payment-terms': 'Nie wybrano warunków płatności',
    'delivery-overview:po-reference': 'Numer zamówienia klienta',
    'delivery-overview:order-id': 'Numer zamówienia',
    'delivery-overview:same-as-delivery-address': 'Identyczny z adresem dostawy',
    'delivery-overview:agree-with-terms': 'Składając zamówienie, akceptujecie Państwo',
    'delivery-overview:submit': 'Złóż zamówienie',
    'delivery-overview:terms-of-delivery': 'Warunki dostawy',
    'delivery-overview:INCO-terms': 'Incoterms',
    'delivery-overview:status': 'Status',
    'delivery-overview:packaging': 'Status opakowania',
    'delivery-overview:agree-with-terms-warning': 'Prosimy o zaakceptowanie naszych ogólnych warunków sprzedaży',
    'delivery-overview:INCO-terms-as-agreed': 'Zgodnie z ustaleniami',
    'delivery-overview:payment-terms-as-agreed': 'Zgodnie z ustaleniami',

    // Invoices
    'invoices:date-invoice': 'Data faktury',
    'invoices:date-due': 'Termin płatności',
    'invoices:download': 'Pobierz fakturę',
    'invoices:title': 'Numer faktury {id}',
    'invoices:total-amount': 'Wartość faktury (w tym VAT)',

    // Order
    'order:next-steps.title': 'Dalej',
    'order:line-item.material-no': 'Ilość produktów',
    'order:line-item.pieces': 'Sztuk',
    'order:line-item.amount': 'Ilość',
    'order:line-item.requested': 'Wnioskowana',
    'order:line-item.confirmed': 'Potwierdzona',
    'order:line-item.delivered': 'Dostarczone w dniu',
    'order:line-item.order-quantity': 'Ilość',
    'order:line-item.batch': 'Numer partii',
    'order:line-item.total-material-price': 'Cena netto',
    'order:line-item.request-price': 'Cena na żądanie',
    'order:line-item.price': 'Cena netto',
    'order:status.delivered': 'Dostarczone',
    'order:status.pending': 'Niepotwierdzone',
    'order:status.indelivery': 'W drodze',
    'order:status.confirmed': 'Potwierdzone',
    'order:status.invoiced': 'Zafakturowane',
    'order:status.cancelled': 'Anulowane',
    'order:status.processed': 'W toku',
    'order:status.loading': 'W załadunku',
    'order:status.shipped': 'Wysłane',

    // Request Types
    'order:status.document': 'Dokumenty',
    'order:status.price': 'Cena netto',
    'order:status.profile': 'Konto',
    'order:status.quote': 'Zamówienie',
    'order:status.sample': 'Próbka',

    'orderline:pieces': 'Sztuk',

    // Orders
    'orders:column.status': 'Status',
    'orders:column.sales-number': 'Numer zlecenia sprzedaży Brenntag',
    'orders:column.po-reference': 'Numer zamówienia klienta',
    'orders:column.order-date': 'Data zamówienia',
    'orders:column.delivery-address': 'Adres dostawy',
    'orders:column.materials-amount': 'Ilość produktu',
    'orders:column.requested-delivery-date': 'Żądana data dostawy',
    'orders:column.delivery-date': 'Data dostawy',
    'orders:column.delivery-date-from-to': 'Dostawa między',
    'orders:column.po-reference-abbreviation': 'Numer zamówienia klienta',
    'orders:button.confirmation': 'Potwierdzenie zamówienia',

    // Quotes
    'quotes:column.offer-date': 'Data oferty',
    'quotes:column.delivery-address': 'Adres dostawy',

    // Material
    'general:material-id': 'Numer produktu',
    'general:packaging': 'Opakowanie',
    'general:pallet': 'Paleta',
    'general:price': 'Cena',
    'general:unit-price': 'Cena netto',
    'general:price-confirmation': 'Cena na żądanie',
    'general:pieces': 'Sztuk',
    'general:pieces-plural': '{count, plural, one {Piece} other {Pieces}}',
    'general:pieces-long': 'Ilość',
    'general:amount': 'Ilość',

    // General
    'general:filters': 'Filtruj według',
    'general:login': 'Zaloguj się',
    'general:cancel': 'Anuluj',
    'general:save': 'Zapisz',
    'general:save-and-send': 'Zapisz i wyślij',
    'general:send': 'Wyślij',
    'general:delete': 'Usuń',
    'general:date-format.day': 'D',
    'general:date-format.month': 'M',
    'general:date-format.year': 'R',
    'general:error': 'Coś poszło nie tak. Spróbuj odświeżyć stronę.',
    'general:close': 'Zamknij',
    'general:type-here': 'Proszę wpisz wiadomość tutaj:',
    'general:send-us-email': 'Prośba o pomoc',
    'general:error.label': 'Błąd:',
    'general:back-to-overview': 'Powrót do przeglądu zamówienia',
    'general:search': 'Szukaj',
    'general:delivery-address': 'Adres dostawy',
    'general:incoterm': 'Warunki dostawy',
    'general:order': 'Zamówienie',
    'general:order-id': 'Zlecenie sprzedaży Brenntag',
    'general:quote': 'Zacytować',
    'general:offer-id': 'Numer oferty Brenntag',
    'general:upload': 'Wybierz pliki',
    'general:documents': 'Dokumenty',
    'general:select-ship-to': 'Wybierz adres dostawy',
    'general:ship-to': 'Adres dostawy',
    'general:po-reference': 'Numer zamówienia klienta',
    'general:sorry': 'Przepraszamy!',
    'general:something-went-wrong': 'Coś poszło nie tak',
    'general:save-changes': 'Zapisz zmiany',
    'general:order-amount': 'Ilość',
    'general:order-quantity': 'Ilość w jednorazowej dostawie',
    'general:required-fields': '* Wymagane pola',
    'general:file-attached': 'Załączony dokument',
    'general:file-attached-plural': 'Załączony dokumenty',
    'general:attach-document': 'Załącz dokument',
    'general:attach-document-plural': 'Załącz jeden lub więcej dokumentów',
    'general:price-conversion': 'Przeliczanie walut',
    'general:id': 'ID',
    'general:quantity-below-minimum': 'Żądana ilość jest poniżej minimalnej ilości zamówienia.',
    'general:po-reference-info':
        'Numer zamówienia klienta jest obowiązkowy przy składaniu zamówienia. Może to być wewnętrzny numer zamówienia lub inna referencja. ',

    // react-day-picker
    'react-day-picker:first-day-of-week': '1',
    'react-day-picker:months.january': 'styczeń',
    'react-day-picker:months.february': 'luty',
    'react-day-picker:months.march': 'marzec',
    'react-day-picker:months.april': 'kwiecień',
    'react-day-picker:months.may': 'maj',
    'react-day-picker:months.june': 'czerwiec',
    'react-day-picker:months.july': 'lipiec',
    'react-day-picker:months.august': 'sierpień',
    'react-day-picker:months.september': 'wrzesień',
    'react-day-picker:months.october': 'październik',
    'react-day-picker:months.november': 'listopad',
    'react-day-picker:months.december': 'grudzień',
    'react-day-picker:next-month': 'Następny miesiąc',
    'react-day-picker:previous-month': 'Poprzedni miesiac',
    'react-day-picker:weekdays-short.monday': 'pon',
    'react-day-picker:weekdays-short.tuesday': 'wt',
    'react-day-picker:weekdays-short.wednesday': 'śr',
    'react-day-picker:weekdays-short.thursday': 'czw',
    'react-day-picker:weekdays-short.friday': 'pt',
    'react-day-picker:weekdays-short.saturday': 'sb',
    'react-day-picker:weekdays-short.sunday': 'nd',
    'react-day-picker:weekdays-long.monday': 'poniedziałek',
    'react-day-picker:weekdays-long.tuesday': 'wtorek',
    'react-day-picker:weekdays-long.wednesday': 'środa',
    'react-day-picker:weekdays-long.thursday': 'czwartek',
    'react-day-picker:weekdays-long.friday': 'piątek',
    'react-day-picker:weekdays-long.saturday': 'sobota',
    'react-day-picker:weekdays-long.sunday': 'niedziela',

    // Filters and sorting
    'filters:search-by-name': 'Nazwa produktu',
    'filters:search-by-name.placeholder': 'Nazwa produktu',
    'filters:search-by-cas-no': 'Numer CAS',
    'filters:search-by-cas-no.tooltip': 'Uwzględnij myślniki',
    'filters:search-by-cas-no.placeholder': 'Numer CAS',
    'filters:search-by-role': 'Rola',
    'filters:search-by-role.placeholder': 'Wszystkie',
    'filters:search-by-csr.placeholder': 'CSR',
    'filters:search-by-admin.placeholder': 'Admin',
    'filters:search-by-user.placeholder': 'Użytkownik',
    'filters:search-by-viewer.placeholder': 'Podgląd',
    'filters:search-by-customer-product-number': 'Numer produktu',
    'filters:search-by-customer-product-number.placeholder': 'Numer produktu',
    'filters:search-by-company-name': 'Nazwa firmy',
    'filters:search-by-company-name.placeholder': 'Nazwa firmy',
    'filters:search-by-customer': 'Szukaj firmy',
    'filters:search-by-customerID': 'Numer klienta',
    'filters:search-by-customerID.placeholder': 'Numer klienta',
    'filters:search-by-customerID-or-name.placeholder': 'Nazwa lub numer klienta',
    'filters:search-by-customerID-or-name-or-vat.placeholder':
        'Wyszukaj po nazwie klienta, numerze klienta lub numerze VAT',
    'filters:search-by-offer-number': 'Numer Oferty',
    'filters:search-by-offer-number.placeholder': 'Numer Oferty',
    'filters:search-by-po-reference': 'Numer zamówienia klienta',
    'filters:search-by-po-reference.placeholder': 'Numer zamówienia klienta',
    'filters:search-by-material-name': 'Nazwa produktu',
    'filters:search-by-material-name.placeholder': 'Nazwa produktu',
    'filters:search-by-username': 'Nazwa użytkownika',

    'filters:search-by-here.placeholder': 'Szukaj',
    'filters:select-ship-to-address': 'Adres dostawy',
    'filters:select-an-address': 'Wybierz adres',
    'filters:clear-filters': 'Wyczyść filtr',
    'filter-packaging.de:filters.names':
        'Beczka z zakrętką, karton, beczka z pokrywą, worek, luz, kontener, kanister, próbka, butelka, torba, wiadro, cylinder, puszka, worek big bag, SAFE-TAINER™, beczka, butelka stalowa',
    'filter-packaging.de:filters.values':
        'Beczka z zakrętką, karton, beczka z pokrywą, worek, luz, kontener, kanister, próbka, butelka, torba, wiadro, cylinder, puszka, worek big bag, SAFE-TAINER™, beczka, butelka stalowa',
    'filter-packaging.bnl:filters.names':
        'Beczka z zakrętką, karton, beczka z pokrywą, worek, luz, kontener, kanister, próbka, butelka, torba, wiadro, cylinder, puszka, worek big bag, SAFE-TAINER™, beczka, butelka stalowa',
    'filter-packaging.bnl:filters.values':
        'Beczka z zakrętką, karton, beczka z pokrywą, worek, luz, kontener, kanister, próbka, butelka, torba, wiadro, cylinder, puszka, worek big bag, SAFE-TAINER™, beczka, butelka stalowa',
    'filter-packaging.cee:filters.names':
        'Worek, Butelka, Wiadro, Luz, Puszka, Beczka, Kontener, Oktabin, Paleta, SAFE-TAINER™, Inne, Kanister, Bigbag, Bęben tekturowy, Karton, Skrzynia, Opakowanie',
    'filter-packaging.cee:filters.values':
        'BAG,BOTTLE,BUCKET,BULK,CAN,DRUM,IBC,OCTABIN,PALLET,SAFETAINER,OTHER,CANISTER,BIGBAG,PAPERBOARD BARREL,CARDBOARD,CHEST,PACKAGE',

    'filters:select-a-packaging-title': 'Wybierz opakowanie',
    'filters:order-status': 'Status zamówienia',
    'filters:select-a-status': 'Wybierz status',
    'filters:search-by-request-no': 'Numer zapytania',
    'filters:search-by-request-no.placeholder': 'Numer zapytania',
    'filters:search-by-status': 'Status',
    'filters:search-by-status.placeholder': 'Wybierz status',
    'filters:request-type': 'Typ zapytania',
    'filters:select-request-type': 'Wybierz typ zapytania',
    'filters:select-country': 'Kraj',
    'filters:search-by-invoiceID': 'Numer faktury',
    'filters:search-by-invoiceID.placeholder': 'Numer faktury',
    'filters:search-by-einecs-no': 'Numer EINECS',
    'filters:search-by-einecs-no.placeholder': 'Numer EINECS',
    'filters:packaging-title': 'Opakowanie',
    'filters:search-by-country': 'Kraj',
    'filters:search-by-country.placeholder': 'Wszystkie kraje',
    'filters:search-by-role.error-select-one': 'Wybierz co najmniej jedną rolę.',
    'filters:copy-link': 'Skopiuj link',
    'filters:copy-link-success': 'Link został skopiowany do schowka',
    'filters:copy-link-error': 'Proszę skopiować następujący link:',

    'sort-direction:numeric:asc': '(rosnąco)',
    'sort-direction:numeric:desc': '(malejąco)',
    'sort-direction:string:asc': '(A-Z)',
    'sort-direction:string:desc': '(Z-A)',
    'sort-direction:date:asc': '(rosnąco)',
    'sort-direction:date:desc': '(malejąco)',

    'sort-select:sort-by': 'Sortuj według',
    'sort-by-request-id': 'Numer',
    'sort-by-type': 'Typ zapytania',
    'sort-by-date': 'Data',
    'sort-by-status': 'Status',
    'sort-by-due-date': 'Termin płatności',
    'sort-by-invoice-date': 'Data faktury',
    'sort-by-order-date': 'Data zamówienia',
    'sort-by-po-reference': 'Numer zamówienia klienta',
    'sort-by-invoice-type': 'Rodzaj faktury',
    'sort-by-invoice-id': 'Numer faktury',
    'sort-by-requested-delivery-date': 'Data dostawy',
    'sort-by-offer-number': 'Numer oferty',
    'sort-by-material-name': 'Nazwa produktu',
    'sort-by-quote-date': 'Data oferty',

    // Packaging Names
    'package:Spundfaß': 'Beczka (z zakrętką)',
    'package:Safetainer': 'SAFE-TAINER ™',
    'package:Gasbehälter': 'Beczka',
    'package:Gasflasche': 'Butelka stalowa',
    'package:Karton': 'Karton',
    'package:Deckelfaß': 'Beczka (z pokrywą)',
    'package:Sack': 'Worek',
    'package:TKW': 'Luz',
    'package:IBC': 'Kontener',
    'package:Kanister': 'Kanister',
    'package:Muster': 'Próbka',
    'package:Flasche/Kanne': 'Butelka',
    'package:Beutel': 'Torba',
    'package:Eimer': 'Wiadro',
    'package:Trommel': 'Cylinder',
    'package:Dose': 'Puszka',
    'package:Big bag': 'Worek big bag',

    'package-pl:Spundfaß': '× Beczka (z zakrętką)',
    'package-pl:Safetainer': '× SAFE-TAINER™',
    'package-pl:Gasbehälter': '× Beczka',
    'package-pl:Gasflasche': '× Butelka stalowa',
    'package-pl:Karton': '× Karton',
    'package-pl:Deckelfaß': '× Beczka (z pokrywą)',
    'package-pl:Sack': '× Worek',
    'package-pl:TKW': '× Luz',
    'package-pl:IBC': '× Kontener',
    'package-pl:Kanister': '× Kanister',
    'package-pl:Muster': '× Próbka',
    'package-pl:Flasche/Kanne': '× Butelka',
    'package-pl:Beutel': '× Torba',
    'package-pl:Eimer': '× Wiadro',
    'package-pl:Trommel': '× Cylinder',
    'package-pl:Dose': '× Puszka',
    'package-pl:Big bag': '× Worek big bag',

    // BNL Packaging Names
    'package:AMPULE': 'Ampułka',
    'package:BAG': 'Worek',
    'package:BALE': 'Bela',
    'package:BOTTLE': 'Butelka',
    'package:BOX': 'Pudełko',
    'package:BUCKET': 'Wiadro',
    'package:BULK': 'Luz',
    'package:CAN': 'Puszka',
    'package:CARTRIDGE': 'Nabój',
    'package:CASE': 'Walizka',
    'package:CONTAINER': 'Kontener',
    'package:CYLINDER': 'Cylinder',
    'package:DRUM': 'Beczka',
    'package:FLEXIBAG': 'Worek flexi',
    'package:FLEXIDRUM': 'Beczka flexi',
    'package:FLEXITANK': 'Zbiornik flexi',
    'package:FOILBAG': 'Torba foliowa',
    'package:FRAME': 'Rama',
    'package:LOG': 'Pojemnik',
    'package:OCTABIN': 'Oktabin',
    'package:PALLET': 'Paleta',
    'package:PALLETBOX': 'Karton na palecie',
    'package:PIECE': 'Sztuka',
    'package:REEL': 'Szpula',
    'package:ROLL': 'Rolka',
    'package:SAFETAINER': 'SAFE-TAINER™',
    'package:SAMPLE': 'Próbka',
    'package:SECURITAINER': 'SECURI-TAINER™',
    'package:SET': 'Zestaw',
    'package:SPRAY': 'Aerosol',
    'package:TUBE': 'Rura',
    'package:OTHER': 'Inne',
    'package:CANISTER': 'Kanister',
    'package:BIGBAG': 'Bigbag',
    'package:PAPERBOARD BARREL': 'Bęben tekturowy',
    'package:CARDBOARD': 'Karton',
    'package:CHEST': 'Skrzynia',
    'package:PACKAGE': 'Opakowanie',

    // BNL Packaging plurals
    'package-pl:AMPULE': '× Ampułka',
    'package-pl:BAG': '× Worek',
    'package-pl:BALE': '× Bela',
    'package-pl:BOTTLE': '× Butelka',
    'package-pl:BOX': '× Pudełko',
    'package-pl:BUCKET': '× Wiadro',
    'package-pl:BULK': '× Luz',
    'package-pl:CAN': '× Puszka',
    'package-pl:CARTRIDGE': '× Nabój',
    'package-pl:CASE': '× Walizka',
    'package-pl:CONTAINER': '× Kontener',
    'package-pl:CYLINDER': '× Cylinder',
    'package-pl:DRUM': '× Beczka',
    'package-pl:FLEXIBAG': '× Worek flexi',
    'package-pl:FLEXIDRUM': '× Beczka flexi',
    'package-pl:FLEXITANK': '× Zbiornik flexi',
    'package-pl:FOILBAG': '× Torba foliowa',
    'package-pl:FRAME': '× Rama',
    'package-pl:LOG': '× Pojemnik',
    'package-pl:OCTABIN': '× Oktabin',
    'package-pl:PALLET': '× Paleta',
    'package-pl:PALLETBOX': '× Karton na palecie',
    'package-pl:PIECE': '× Sztuka',
    'package-pl:REEL': '× Szpula',
    'package-pl:ROLL': '× Rolka',
    'package-pl:SAFETAINER': '× SAFE-TAINER™',
    'package-pl:SAMPLE': '× Próbka',
    'package-pl:SECURITAINER': '× SECURI-TAINER™',
    'package-pl:SET': '× Zestaw',
    'package-pl:SPRAY': '× Aerosol',
    'package-pl:TUBE': '× Rura',
    'package-pl:OTHER': '× Inne',
    'package-pl:CANISTER': '× Kanister',
    'package-pl:BIGBAG': '× Bigbag',
    'package-pl:PAPERBOARD BARREL': '× Bęben tekturowy',
    'package-pl:CARDBOARD': '× Karton',
    'package-pl:CHEST': '× Skrzynia',
    'package-pl:PACKAGE': '× Opakowanie',

    // Surcharge codes
    'surcharge:ZPR2': 'Cena netto',
    'surcharge:ZBA2': 'Cena netto',
    'surcharge:ZPRS': 'Kontrakt',
    'surcharge:ZPF0': 'Kaucja',
    'surcharge:ZVEM': 'Koszt opakowań',
    'surcharge:ZVER': 'Koszt opakowań',
    'surcharge:ZVE2': 'Koszt opakowań',
    'surcharge:ZVKM': 'Koszt opakowania',
    'surcharge:ZM01': 'Dodatkowy koszt (mała ilość)',
    'surcharge:ZM00': 'Dodatkowy koszt (mała ilość)',
    'surcharge:ZSOK': 'Pozostałe koszty',
    'surcharge:ZSO1': 'Pozostałe koszty',
    'surcharge:ZVP0': 'Opakowanie specjalne',
    'surcharge:ZMAU': 'Opłata paliwowa / drogowa',
    'surcharge:ZMA2': 'Opłata ADR',
    'surcharge:ZTP1': 'Koszt transportu',
    'surcharge:ZTPS': 'Koszt transportu',
    'surcharge:ZSTD': 'Pozostałe koszty',
    'surcharge:ZST1': 'Pozostałe koszty',
    'surcharge:ZABK': 'Koszty napełnienia',
    'surcharge:ZAB1': 'Koszty napełnienia',
    'surcharge:ZLG1': 'Koszty składowania',
    'surcharge:ZLG2': 'Koszty składowania',
    'surcharge:ZFLG': 'Koszty napełnienia',
    'surcharge:ZGVS': 'Opłata ADR',
    'surcharge:ZGV1': 'Opłata ADR',
    'surcharge:Z110': 'Rabat',
    'surcharge:Z111': 'Rabat',
    'surcharge:Z112': 'Rabat',
    'surcharge:Z15P': 'Rabat',
    'surcharge:ZRAB': 'Rabat %',
    'surcharge:ZRB1': 'Rabat',
    'surcharge:ZKA2': 'Klient / Produkt',
    'surcharge:ZCVH': 'Rabat',
    'surcharge:ZCVM': 'Klient / Produkt',
    'surcharge:ZUWP': 'Opłata środowiskowa',
    'surcharge:ZSKT': 'Rabat gotówkowy',
    'surcharge:ZPRB': 'Podstawa prowizji',
    'surcharge:ZPRV': 'Prowizja',
    'surcharge:ZVPR': 'Koszt',
    'surcharge:ZSTA': 'Cena netto',
    'surcharge:ZSTM': 'Cena nie uwzględnia podatku VAT',
    'surcharge:ZB10': 'Rabat na grupę produktu',
    'surcharge:ZB11': 'Rabat na produkt',
    'surcharge:ZB12': 'Rabat na klienta',
    'surcharge:ZB14': 'Rabat na produkt',
    'surcharge:ZB15': 'Rabat na produkt',
    'surcharge:ZSUB': 'Dotacja',
    'surcharge:ZSUP': 'Dotacja %',
    'surcharge:ZDCL': 'Dowclene',
    'surcharge:ZWEG': 'Klient / Produkt',
    'surcharge:ZTUK': 'Koszty transportu',
    'surcharge:ZTRA': 'Koszty transportu',
    'surcharge:ZF00': 'Koszty transportu',
    'surcharge:ZLFR': 'Czas dostawy < 24 godz',
    'surcharge:ZVPP': 'Koszt',
    'surcharge:ZVPL': 'Dodatkowe koszty',
    'surcharge:ZVP1': 'Koszt',
    'surcharge:ZVKD': 'Suma kosztów',
    'surcharge:ZVK1': 'Koszt',
    'surcharge:ZUKE': 'Klient / Produkt',
    'surcharge:ZUKM': 'Mieszanie',
    'surcharge:ZUKA': 'Rozlewanie',

    'surcharge:ZSLK': 'Specjalna usługa',
    'surcharge:ZALG': 'Specjalna usługa',
    'surcharge:ZLKW': 'Ładowanie ciężarówki',
    'surcharge:ZLA1': 'Klient / Produkt',
    'surcharge:ZLA2': 'Klient / Produkt',
    'surcharge:ZBKO': 'Klient / Produkt',
    'surcharge:ZVKO': 'Klient / Produkt',
    'surcharge:ZDIK': 'Koszty utylizacji',
    'surcharge:ZDK1': 'Koszt za przetwarzanie zamówienia',
    'surcharge:ZDK2': 'Koszty utylizacji',

    'surcharge:ZSLO': 'Koszty logistyczne',
    'surcharge:ZNLK': 'Dodatkowe koszty',
    'surcharge:ZZKO': 'Koszty stałe',
    'surcharge:ZVBR': 'Podatek akcyzowy',
    'surcharge:ZABS': 'VAT',
    'surcharge:MWST': 'VAT',
    'surcharge:SKTO': 'Rabat gotówkowy',
    'surcharge:ZVOC': 'Opłata za substancje lotne',
    'surcharge:ZIVU': 'Dodatkowe koszty',
    'surcharge:GRWR': 'Dodatkowe koszty',
    'surcharge:ZMW1': 'Netto',
    'surcharge:ZMW2': 'VAT',
    'surcharge:ZMW3': 'Brutto',

    // Order History
    'order-history:headline': 'Zamówienia',

    // Quote List
    'quotes:headline': 'Oferty',

    // Order Detail
    'order-detail:headline': 'Zamówienie {orderId}',

    // Order detail - Not found
    'warning:order-not-found:title': 'Nie znaleziono zamówienia',
    'warning:order-not-found:explanation': 'Nie znaleziono zamówienia',
    'warning:order-not-found:link-text': 'Powrót do listy zamówień',

    // Order history - Reorder
    'order-detail:reorder': 'Zamów ponownie',
    'reorder:success-toast': 'Produkty pomyślnie dodano do koszyka.',
    'reorder:error-toast': 'Produkt nie może być dodany do koszyka.',
    'reorder:warning-toast': 'Niektóre z wybranych produktów nie mogą zostać dodane do koszyka.',

    // Generic error
    'warning:generic-error:title': 'Przepraszamy!',
    'warning:generic-error:explanation': 'Brenntag Connect jest w tej chwili niedostępny.',
    'warning:generic-error:contact':
        'W celu złożenia zamówienia, zapytania lub uzyskania pomocy skontaktuj się ze swoim Przedstawicielem Handlowym lub z członkami Zespółu Brenntag Connect poprzez adres email connect@brenntag.pl.',

    // Invalid customer
    'warning:invalid-customer-id:title': 'Przepraszamy!',
    'warning:invalid-customer-id:explanation': 'Brenntag Connect jest obecnie niedostępny.',
    'warning:invalid-customer-id:contact':
        'Nie martw się! W celu uzyskania pomocy proszę skontaktuj się z Przedstawicielem Handlowym firmy Brenntag.',

    // Login failed
    'warning:login-failed:title': 'Coś poszło nie tak',
    'warning:login-failed:explanation': 'Logowanie nie powiodło się. Sprawdź ponownie swoje dane logowania.',
    'warning:login-failed:button-text': 'Spróbuj ponownie',
    'warning:too-many-results:title': 'Pomóż nam znaleźć Twoje zamówienie',
    'warning:too-many-results:explanation':
        'Potrzebujemy więcej informacji, aby znaleźć Twoje zamówienie – proszę podaj więcej szczegółów w nazwie produktu.',
    'warning:customer-migrated-bep:title': 'Nowy Brenntag Connect',
    'warning:customer-migrated-bep:explanation':
        'Zapraszamy do nowej wersji Brenntag Connect.<br/>Zaloguj się tutaj: <a href="https://emea.brenntag.com/{region}">https://emea.brenntag.com/{region}/</a>',

    // Session expired
    'session-expired:header': 'Sesja wygasła',
    'session-expired:text': 'Sesja wygasła. Proszę zaloguj się ponownie.',
    'session-expired:login-button': 'Zaloguj sie',

    // Not found
    'not-found:title': 'Strona nie została znaleziona!',
    'not-found:message': 'Strona nie została znaleziona!',
    'not-found:orders': 'Nie znaleziono wyników wyszukiwania',
    'not-found:customers': 'Nie znaleziono klientów',
    'not-found:users': 'Nie znaleziono uzytkowników',
    'not-found:link-text': 'Powrót do strony startowej',
    'not-found:invoices': 'Nie znaleziono faktur',
    'not-found:requests': 'Nie znaleziono zapytań',
    'not-found:select': 'Nie znaleziono wyników.',

    // Maintenance
    'maintenance:title': 'Brenntag Connect jest niedostępny',
    'maintenance:message':
        'Brenntag Connect jest chwilowo niedostępny z powodu prac systemowych. Przepraszamy za wszelkie niedogodności.',

    // Document not found
    'document-not-found:title': 'Dokument nie został znaleziony!',
    'document-not-found:message':
        'Wybrany dokument jest przetwarzany i będzie dostępny następnego dnia roboczego. W celu uzyskania dalszych informacji prosimy o kontakt pod adresem <a href="mailto:{csrEmail}">{csrEmail}</a>',

    // Scale prices
    'scale-prices:from': 'Od',

    // Form validation
    'form-validation:required': 'Proszę uzupełnić pole',
    'form-validation:confirm': 'Pole nie jest takie same.',
    'form-validation:email': 'Prosimy o wprowadzenie poprawnego adresu e-mail.',
    'form-validation:email-csr':
        'Można utworzyć użytkownika CSR tylko będącego członkiem organizacji Brenntag lub DigiB. Proszę skontaktuj się z administratorem.',
    'form-validation:email-in-use': 'Adres e-mail już w użyciu.',
    'form-validation:password': 'Proszę podać poprawne hasło.',
    'form-validation:password-repeat': 'Proszę powtórzyć hasło.',
    'form-validation:date': 'Proszę podać poprawną datę.',
    'form-validation:date.past': 'Proszę wybrać wcześniejszą datę niż {date}.',
    'form-validation:date.future': 'Proszę wybrać datę po {date}.',
    'form-validation:date.weekday': 'Proszę wybrać dzień tygodnia.',
    'form-validation:max-length': 'Maksymalna liczba znaków to {length}.',
    'form-validation:min-length': 'Minimalna liczba znaków to {length}.',
    'form-validation:min-amount': 'Minimalna ilość zamówienia to {amount} {type}.',
    'form-validation:number': 'Proszę podać prawidłowy numer.',
    'form-validation:has-uppercase': 'Hasło powinno zawierać co najmniej 1 wielką literę.',
    'form-validation:has-lowercase': 'Hasło powinno zawierać co najmniej 1 małą literę.',
    'form-validation:has-numeric-character': 'Hasło powinno zawierać co najmniej 1 cyfrę.',
    'form-validation:has-symbol-character': 'Hasło powinno zawierać co najmniej 1 symbol.',
    'form-validation:has-word-character': 'Znaki specjalne nie są dozwolone.',
    'form-validation:min-length-values': 'Minimalna liczba znaków w {values} to {length}.',
    'form-validation:do-not-exist': 'Nie znaleziono klientów: {values}.',
    'form-validation:max-amount': 'Niestety, można zamówić tylko co najwyżej {amount} {type}.',
    'form-validation:number-too-big': 'Liczba jest zbyt duża.',
    'form-validation:reference-required': 'Proszę podać numer zamówienia klienta.',

    // Account and preference page
    'account:profile': 'Mój profil',
    'account:title': 'Ustawienia konta',
    'account:first-name': 'Imię',
    'account:last-name': 'Nazwisko',
    'account:email': 'E-mail',
    'account:name': 'Nazwa',
    'account:phone': 'Numer telefonu',
    'account:shipping-address': 'Adres wysyłki',
    'account:billing-address': 'Adres na fakturze',
    'account:personal-details': 'Dane osobowe',
    'account:company-details': 'Szczegóły firmy',
    'account:users': 'Użytkownicy',
    'account:company-name': 'Nazwa firmy',
    'account:preferences': 'Ustawienia',
    'account:language': 'Domyślny język',
    'account:related:title': 'Użytkownicy dla {company}',
    'account:related:intro':
        'Poniższa lista obejmuje użytkowników, którzy mają dostęp do Brenntag Connect w Twojej firmie. Aby dodać lub usunąć użytkowników prosimy o kontakt <a href="mailto:{csrEmail}">{csrEmail}</a>.',
    'account:related:no-results':
        'Nie ma żadnych innych użytkowników, którzy mają dostęp do Brenntag Connect w Twojej firmie. Aby dodać lub usunąć użytkowników prosimy o kontakt <a href="mailto:{csrEmail}">{csrEmail}</a>.',

    // Request information Modal
    'request-information-modal:add-to-cart': 'Dodaj do koszyka',
    'request-information-modal:edit-quantity-cart': 'Zmień ilość',
    'request-information-modal:add-to-cart.instruction': 'Wybierz kwotę, którą chcesz zamówić.',
    'request-information-modal:toasts-product-added': 'Ten produkt został dodany do koszyka.',
    'request-information-modal:toasts-product-adjusted': 'Ilość została poprawiona w koszyku.',
    'request-information-modal:remove-from-cart': 'Usuń produkt',
    'request-information-modal:remove-from-cart.instruction': 'Czy na pewno chcesz usunąć {productName} z koszyka?',
    'request-information-modal:toasts-product-removed': 'Ten produkt został usunięty z koszyka.',

    'request-information-modal:request-information': 'Poproś o informację',
    'request-information-modal:request-quote': 'Zamówienie',
    'request-information-modal:request-quote-confirm': 'Potwierdź zamówienie',
    'request-information-modal:step': 'Krok',
    'request-information-modal:step.of': 'z',
    'request-information-modal:text': 'Wypełnij formularz, aby uzyskać dodatkowe informacje o produkcie',
    'request-information-modal:text.quote': 'Wypełnij formularz, aby złożyć ponownie zamówienie.',
    'request-information-modal:success-toast':
        'Wysłano zapytanie. Zespół Brenntag Connect wkrótce się z Państwem skontaktuje.',
    'request-information-modal:send-request': 'Wyślij zapytanie',
    'request-information-modal:needs': 'Jakich szukasz informacji?',
    'request-information-modal:document-types': 'Typ dokumentu',
    'request-information-modal:application': 'Zastosowanie',
    'request-information-modal:amount': 'Ilość',
    'request-information-modal:sample-quantity': 'Ilość próbki',
    'request-information-modal:annual-quantity': 'Roczne zapotrzebowanie',
    'request-information-modal:call-off-quantity': 'Ilość w jednorazowej dostawie',
    'request-information-modal:requested-delivery-date': 'Żądana data dostawy',
    'request-information-modal:requested-pickup-date': 'Żądana data odbioru',
    'request-information-modal:order-quantity': 'Ilość zamówienia',
    'request-information-modal:comment': 'Komentarze',
    'request-information-modal:step2': 'Dalej',
    'request-information-modal:requested-information': 'Żądane informacje',

    'request-information-modal:document-types-other-placeholder': 'Nazwa dokumentu',
    'request-information-modal:select-an-application': 'Wybierz zastosowanie',
    'request-information-modal:me': 'Ja',
    'request-information-modal:someone-else': 'Ktoś inny',
    'request-information-modal:back-to-step-1': 'Powrót ',
    'request-information-modal:back-to-step-2': 'Powrót ',
    'request-information-modal:my-address-book': 'Adres dostawy',
    'request-information-modal:new-address': 'Inny adres dostawy',
    'request-information-modal:document-types.msds': 'Karta charakterystyki (MSDS)',
    'request-information-modal:document-types.tds': 'Specyfikacja techniczna (TDS)',
    'request-information-modal:document-types.specification': 'Specyfikacja',
    'request-information-modal:document-types.coa': 'Świadectwo analizy (COA)',
    'request-information-modal:document-types.other': 'Inny dokument',
    'request-information-modal:requirement.price': 'Oferta',
    'request-information-modal:requirement.document': 'Dokumenty',
    'request-information-modal:requirement.sample': 'Próbka',
    'request-information-modal:requirement.quote': 'Oferta',
    'request-information-modal:order': 'Zamów',
    'request-information-modal:reorder': 'Zamów ponownie',
    'request-information-modal:reorder:disabled-unavailableMaterial':
        'Wybrany produkt został zablokowany. Proszę skontaktuj się z Przedstawicielem Handlowym lub Zespołem Brenntag Connect.',
    'request-information-modal:reorder:disabled-unavailableForCustomer': 'Produkt niedostępny',
    'request-information-modal:reorder:disabled-unavailable':
        'To jest próbka. Proszę skontaktuj się z zespołem Brenntag Connect.',
    'request-information-modal:reorder:select-delivery-date': 'Proszę wybrać datę i adres dostawy',
    'request-information-modal:reorder:no-prices-available': 'Cena zostanie potwierdzona',
    'request-information-modal:preview-and-submit': 'Podgląd i zatwierdzanie',
    'request-information-modal:confirmation':
        'Dziękujemy za złożenie zamówienia. Zespół Brenntag Connect potwierdzi zamówienie tak szybko jak to możliwe. Po otrzymaniu potwierdzenia zamówienia, będzie można sprawdzić status zamówienia w historii zamówień na portalu Brenntag Connect.',
    'request-information-modal:available-prices': 'Dostępne oferty',
    'request-information-modal:different-shipto-warning': 'W przypadku wybrania innego adresu należy potwierdzić cenę',
    'request-information-modal:reorder:self-pickup': 'Odbiór własny',

    'request-information:auto-complete:price':
        'Dziękujemy za złożenie zapytania o próbkę produktu. Zespół Brenntag Connect odpowie na zapytanie tak szybko jak to możliwe.',
    'request-information:auto-complete:sample':
        'Dziękujemy za złożenie zapytania ofertowego. Zespół Brenntag Connect odpowie na zapytanie tak szybko jak to możliwe.',
    'request-information:auto-complete:quote':
        'Dziękujemy za złożenie zamówienia. Zespół Brenntag Connect potwierdzi zamówienie tak szybko jak to możliwe. Po otrzymaniu potwierdzenia zamówienia, będzie można sprawdzić status zamówienia w historii zamówień na portalu Brenntag Connect.',
    'request-information:auto-complete:cart':
        'Dziękujemy za złożenie zamówienia. Zespół Brenntag Connect potwierdzi zamówienie tak szybko jak to możliwe. Po otrzymaniu potwierdzenia zamówienia, będzie można sprawdzić status zamówienia w historii zamówień na portalu Brenntag Connect.',

    // Applications list for BNL and DE
    'request-information:application:ai-automotive': 'Przemysł motoryzacyjny',
    'request-information:application:ai-construction-flooring': 'Chemia budowlana - Posadzki przemysłowe',
    'request-information:application:ai-coatings-architect-coatings': 'Farby - Farby dekoracyjne',
    'request-information:application:ai-construction-building-chemicals':
        'Chemia budowlana - Ceramika budowlana i techniczna ',
    'request-information:application:ai-construction-concrete-based-prod-':
        'Chemia budowlana - Produkty na bazie betonu',
    'request-information:application:ai-decorative-paints': 'Farby - Farby dekoracyjne',
    'request-information:application:ai-dental': 'Stomatologia',
    'request-information:application:ai-coatings-sealants': 'Chemia budowlana - Uszczelniacze silikonowe i akrylowe',
    'request-information:application:ai-printing-publishing': 'Druk i Poligrafia',
    'request-information:application:ai-electronic': 'Przemysł elektroniczny',
    'request-information:application:ai-epoxy-systems': 'Chemia budowlana - Systemy epoksydowe',
    'request-information:application:ai-coatings-other': 'Farby - Pozostałe farby',
    'request-information:application:ai-filter-and-environmental-technology': 'Technologia ochrony środowiska',
    'request-information:application:ai-glass': 'Produkcja szkła',
    'request-information:application:ai-rubber-seals-gaskets': 'Przemysł gumowy - Uszczelki',
    'request-information:application:ai-rubber-wires-cables': 'Przemysł gumowy - Kable i przewody gumowe',
    'request-information:application:ai-rubber-latex-goods': 'Przemysł gumowy - Produkty z lateksu',
    'request-information:application:ai-rubber-medical-parts': 'Przemysł gumowy - Produkty medyczne',
    'request-information:application:ai-rubber-tyre': 'Przemysł gumowy - Opony',
    'request-information:application:ai-rubber-tubes-hoses': 'Przemysł gumowy - Rury i węże',
    'request-information:application:ai-rubber-other': 'Przemysł gumowy - Pozostałe produkty z gumy',
    'request-information:application:ai-rubber-compounds': 'Przemysł gumowy - Mieszanki',
    'request-information:application:ai-homecare': 'Chemia gospodarcza - Domowa chemia gospodarcza',
    'request-information:application:ai-coatings-wood-coatings': 'Farby - Farby do drewna',
    'request-information:application:ai-coatings-industrial-coatings': 'Farby - Farby przemysłowe',
    'request-information:application:ai-industrial-cleaning':
        'Chemia gospodarcza - Mycie przemysłowe i instytucjonalne',
    'request-information:application:ai-ceramics': 'Chemia budowlana - Ceramika',
    'request-information:application:ai-coatings-adhesives': 'Chemia budowlana - Tynki i kleje',
    'request-information:application:ai-coatings-raw-materials-other': 'Farby - Producenci surowców do produkcji farb',
    'request-information:application:ai-agro': 'Rolnictwo',
    'request-information:application:ai-aerospace': 'Przemysł lotniczy',
    'request-information:application:ai-metal-surface-treatments': 'Przemysł metalowy i obróbka powierzchni',
    'request-information:application:ai-oil-upstream-fracs-drill': 'Ropa i gaz - Upstream - Wiertnictwo',
    'request-information:application:ai-oil-midstream-n-spec': 'Ropa i gaz - Midstream - N-spec',
    'request-information:application:ai-oil-midstream-gasplants': 'Ropa i gaz - Midstream - Wydobycie gazu',
    'request-information:application:ai-oil-midstream-compounder': 'Ropa i gaz - Midstream - Mieszalnie',
    'request-information:application:ai-oil-downstream-refinerie': 'Ropa i gaz - Downstream - Rafinerie',
    'request-information:application:ai-oil-oil-gas-other': 'Ropa i gaz - Pozostałe firmy',
    'request-information:application:ai-paper-manufacturers': 'Producenci papieru',
    'request-information:application:ai-pulp-paper-others': 'Przemysł papierniczy - Pozostałe firmy',
    'request-information:application:ai-polymers-compounders': 'Tworzywa sztuczne - Producenci mieszanek',
    'request-information:application:ai-polymers-extrusion': 'Tworzywa sztuczne - Wytłaczanie',
    'request-information:application:ai-polymers-extrusion-foil': 'Tworzywa sztuczne - Folie',
    'request-information:application:ai-polymers-pu-elastomers': 'Tworzywa sztuczne - Poliuretany',
    'request-information:application:ai-polymers-pvc': 'Tworzywa sztuczne - PVC',
    'request-information:application:ai-polymers-raw-mat-producers':
        'Tworzywa sztuczne - Producenci surowców do produkcji tworzyw sztucznych',
    'request-information:application:ai-polymers-other': 'Tworzywa sztuczne - Pozostałe firmy',
    'request-information:application:ai-polymers-elastomers-other': 'Tworzywa sztuczne - Pozostałe elastomery',
    'request-information:application:ai-polymers-injection-moulding': 'Tworzywa sztuczne - Wtrysk',
    'request-information:application:ai-chemical-processing': 'Wielobranżowe zakłady chemiczne',
    'request-information:application:ai-coatings-powder-coatings': 'Farby - Farby proszkowe',
    'request-information:application:ai-cleaning-vehicles': 'Chemia gospodarcza - Kosmetyka samochodowa',
    'request-information:application:ai-cleaning-floorcare': 'Chemia gospodarcza - Pielęgnacja podłóg',
    'request-information:application:ai-cleaning-food-area': 'Chemia gospodarcza - Chemia kuchenna',
    'request-information:application:ai-cleaning-other': 'Chemia gospodarcza - Pozostałe firmy',
    'request-information:application:ai-dry-clean-laundry': 'Pralnie Chemiczne',
    'request-information:application:ai-lubricants': 'Środki smarne - Produkcja środków smarnych',
    'request-information:application:ai-lub-fuel-blends': 'Środki smarne - Mieszalnie paliw i smarów',
    'request-information:application:ai-construction-other': 'Chemia budowlana - Pozostałe firmy',
    'request-information:application:ai-chemical-synthesis': 'Synteza chemiczna',
    'request-information:application:ai-textile-leather': 'Przemysł włókienniczy i skórzany',
    'request-information:application:ai-coatings-inks': 'Farby - Tusze i farby drukarskie',
    'request-information:application:ai-packaging-converting': 'Produkcja opakowań papierowych',
    'request-information:application:ai-water-cond-serv-cy-waste-water':
        'Woda i ścieki - Usługi uzdatniania wody i ścieków',
    'request-information:application:ai-water-mun-waste-water': 'Woda i ścieki - Oczyszczanie ścieków',
    'request-information:application:ai-water-mun-swimming-pool': 'Woda i ścieki - Baseny i pływalnie',
    'request-information:application:ai-water-mun-potable-water': 'Woda i ścieki - Dostarczanie wody pitnej',
    'request-information:application:ai-water-teatment-other': 'Woda i ścieki - Pozostałe firmy',
    'request-information:application:ai-waterconditioning': 'Woda i ścieki - Usługi uzdatniania wody',
    'request-information:application:ai-tissue-manufacturers': 'Produkcja chusteczek',
    'request-information:application:ai-construction-cement-products': 'Chemia budowlana - Produkty na bazie cementu',
    'request-information:application:animal-nutrition:air-treatment':
        'Ochrona środowiska - Oczyszczanie gazów i powietrza',
    'request-information:application:animal-nutrition:enrichment-(minerals-vitamins-amino-acids)':
        'Żywienie zwierząt - Wzbogacanie pasz (minerały, witaminy, aminokwasy, itp.)',
    'request-information:application:animal-nutrition:antioxidants': 'Żywienie zwierząt - Przeciwutleniacze',
    'request-information:application:animal-nutrition:flavours': 'Żywienie zwierząt - Aromaty',
    'request-information:application:animal-nutrition:binder-flow-additives-coagulation-agents':
        'Żywienie zwierząt - Spoiwa, dodatki poprawiające płynność lub krzepnięcie',
    'request-information:application:animal-nutrition:products-for-biogas-systems': 'Energetyka odnawialna - Biogaz',
    'request-information:application:animal-nutrition:biocides-and-hygiene-products':
        'Żywienie zwierząt - Biocydy i środków higieny',
    'request-information:application:animal-nutrition:emulsifiers-stabilizers-thickener':
        'Żywienie zwierząt - Emulgatory, stabilizatory, substancje zagęszczające',
    'request-information:application:animal-nutrition:energy-suppliers': 'Żywienie zwierząt - Białka, źródła energii',
    'request-information:application:animal-nutrition:colourings': 'Żywienie zwierząt - Barwniki',
    'request-information:application:animal-nutrition:coccidiostatics-and-histomonostats':
        'Żywienie zwierząt - Kokcydiostatyki i histomonostatyki',
    'request-information:application:animal-nutrition:preservatives': 'Żywienie zwierząt - Konserwanty',
    'request-information:application:animal-nutrition:performance-booster':
        'Żywienie zwierząt - Substancje zwiększające wydajność produkcji',
    'request-information:application:animal-nutrition:radionuclide-binder': 'Żywienie zwierząt - Radionukleidy',
    'request-information:application:animal-nutrition:acidity-regulators': 'Żywienie zwierząt - Regulatory kwasowości',
    'request-information:application:animal-nutrition:silage-additives': 'Żywienie zwierząt - Dodatki do kiszonki',
    'request-information:application:animal-nutrition:technical-adjuvants':
        'Żywienie zwierząt - Techniczne środki pomocnicze',
    'request-information:application:animal-nutrition:release-agents': 'Żywienie zwierząt - Środki antyadhezyjne',
    'request-information:application:animal-nutrition:water-treatment':
        'Żywienie zwierząt - Oczyszczanie woday i ścieków',
    'request-information:application:cosmetics:deodorant': 'Przemysł kosmetyczny - Dezodoranty',
    'request-information:application:cosmetics:hair-care': 'Przemysł kosmetyczny - Pielęgnacja włosów',
    'request-information:application:cosmetics:hair-styling': 'Przemysł kosmetyczny - Stylizacja włosów',
    'request-information:application:cosmetics:make-up': 'Przemysł kosmetyczny - Kosmetyka ozdobna i make-up',
    'request-information:application:cosmetics:oral-hygiene': 'Przemysł kosmetyczny - Higiena jamy ustnej',
    'request-information:application:cosmetics:perfume': 'Przemysł kosmetyczny - Perfumy',
    'request-information:application:cosmetics:skin-care': 'Przemysł kosmetyczny - Pielęgnacja skóry',
    'request-information:application:cosmetics:skin-cleansing': 'Przemysł kosmetyczny - Oczyszczanie skóry',
    'request-information:application:cosmetics:sun': 'Przemysł kosmetyczny - Kosmetyki słoneczne',
    'request-information:application:food:acidifiers-and-acidity-regulators':
        'Przemysł spożywczy - Środki zakwaszające i regulatory kwasowości',
    'request-information:application:food:antioxidants': 'Przemysł spożywczy - Przeciwutleniacze',
    'request-information:application:food:carriers-and-bulking-agents': 'Przemysł spożywczy - Nośniki i wypełniacze',
    'request-information:application:food:coating-and-release-agents':
        'Przemysł spożywczy - Powłoki i środki antyadhezyjne',
    'request-information:application:food:colourings': 'Przemysł spożywczy - Barwniki',
    'request-information:application:food:emulsifier': 'Przemysł spożywczy - Emulgatory',
    'request-information:application:food:flavours': 'Przemysł spożywczy - Flavors',
    'request-information:application:food:foaming-agents': 'Przemysł spożywczy - Środki spieniające',
    'request-information:application:food:humectants': 'Przemysł spożywczy - Środki zwilżające',
    'request-information:application:food:nutrition-enrichment': 'Przemysł spożywczy - Środki wzbogacające',
    'request-information:application:food:raising-and-flour-tratment-agents':
        'Przemysł spożywczy - Środki dla przemysłu zbożowego',
    'request-information:application:food:rreservatives-and-stabilizers':
        'Przemysł spożywczy - Konserwanty i stabilizatory',
    'request-information:application:food:smelting-agent': 'Przemysł spożywczy - Środki do wytapiania',
    'request-information:application:food:sugars-and-sweeteners': 'Przemysł spożywczy - Cukry i słodziki',
    'request-information:application:food:technical-adjuvants': 'Przemysł spożywczy - Techniczne środki pomocnicze',
    'request-information:application:food:thickener-gelling-and-firming-agent':
        'Przemysł spożywczy - Środki zagęszczające, środki żelujące i ujędrniające',
    'request-information:application:medicinal-product:capsule': 'Przemysł farmaceutyczny - Leki w kapsułkach',
    'request-information:application:medicinal-product:cream-gel-salve': 'Przemysł farmaceutyczny - Kremy, żele, maści',
    'request-information:application:medicinal-product:drops-(oral)':
        'Przemysł farmaceutyczny - Leki w kroplach doustnych',
    'request-information:application:medicinal-product:eye-drops': 'Przemysł farmaceutyczny - Leki w kroplach do oczu',
    'request-information:application:medicinal-product:parenteral': 'Przemysł farmaceutyczny - Leki pozajelitowe',
    'request-information:application:medicinal-product:sachet': 'Przemysł farmaceutyczny - Leki w saszetkach',
    'request-information:application:medicinal-product:spray-solution-(eyes-nose)':
        'Przemysł farmaceutyczny - Leki w sprayu',
    'request-information:application:medicinal-product:syrup-(oral)': 'Przemysł farmaceutyczny – Syropy',
    'request-information:application:medicinal-product:tablet': 'Przemysł farmaceutyczny - Leki w tabletkach',
    'request-information:application:mp-ns-ph:others': 'Przemysł farmaceutyczny - Pozostałe firmy',
    'request-information:application:nutritional-supplement:syrup-drops-solution':
        'Przemysł farmaceutyczny - Płynne suplementy diety',
    'request-information:application:nutritional-supplement:tablet-capsule-sachet':
        'Przemysł farmaceutyczny - Suplementy diety w tabletkach, kapsułkach i saszetkach',
    'request-information:application:pharma:api-synthesis': 'Przemysł farmaceutyczny - Synteza środków aktywnych',
    'request-information:application:pharma:biotechnology': 'Przemysł farmaceutyczny - Biotechnologia',
    'request-information:application:pharma:desinfection': 'Przemysł farmaceutyczny - Dezynfekcja',
    'request-information:application:other': 'Pozostałe',

    // Applications list for CEE

    'request-information:application:adblue:oem-&-truck-dealers': 'AdBlue - Dealerzy samochodów ciężarowych',
    'request-information:application:adblue:distribution': 'AdBlue - Dystrybucja',
    'request-information:application:adblue:endusers,-carriers-&-fleetowners':
        'AdBlue - Odbiorcy końcowi, przewoźnicy i właściciele flot',
    'request-information:application:adblue:public-filling-stations-(codo/dodo)': 'AdBlue - Stacje paliw - franczyza',
    'request-information:application:adblue:retail-&-public-filling-stations--(codo)':
        'AdBlue - Stacje paliw - koncerny naftowe',
    'request-information:application:adblue:others': 'AdBlue - Pozostałe',
    'request-information:application:agro:desinfection': 'Agro - Dezynfekcja',
    'request-information:application:agro:herbicides-&-pesticides': 'Agro - Herbicydy i pestycydy',
    'request-information:application:agro:mixed-production': 'Agro - Mieszany zakres',
    'request-information:application:agro:fertilizers': 'Agro - Nawozy',
    'request-information:application:agro:horticulture': 'Agro - Ogrodnictwo',
    'request-information:application:agro:farmers': 'Agro - Rolnictwo',
    'request-information:application:agro:orchards': 'Agro - Sadownictwo',
    'request-information:application:agro:others': 'Agro - Pozostałe',
    'request-information:application:cement-mills:': 'Cementownie',
    'request-information:application:cleaning:cleaning-&-cleansing':
        'Chemia gospodarcza - Chemia gospodarcza - klienci wielobranżowi',
    'request-information:application:cleaning:homecare': 'Chemia gospodarcza - Domowa chemia gospodarcza',
    'request-information:application:cleaning:distributors': 'Chemia gospodarcza - Dystrybucja',
    'request-information:application:cleaning:auto-care-cleaning': 'Chemia gospodarcza - Kosmetyka samochodowa',
    'request-information:application:cleaning:industrial-&-institutional-cleaning':
        'Chemia gospodarcza - Mycie przemysłowe i instytucjonalne',
    'request-information:application:cleaning:others': 'Chemia gospodarcza - Pozostałe',
    'request-information:application:heat-engineering:': 'Ciepłownictwo',
    'request-information:application:chemical-synthesis:': 'Duże wielobranżowe zakłady chemiczne',
    'request-information:application:distributors:others': 'Dystrybucja',
    'request-information:application:electronics:medical-equipment-&-tools':
        'Elektronika - Aparatura i instrumenty medyczne',
    'request-information:application:electronics:automotive-electronics':
        'Elektronika - Elementy elektroniczne do samochodów',
    'request-information:application:electronics:lighting-equipment':
        'Elektronika - Sprzęt oświetleniowy i elektroinstalacje',
    'request-information:application:electronics:brown-goods': 'Elektronika - Sprzęt RTV i komputery',
    'request-information:application:electronics:others': 'Elektronika - Pozostałe',
    'request-information:application:power-industry:': 'Energetyka konwencjonalna',
    'request-information:application:renewable-energy:biodiesel': 'Energetyka odnawialna - Biodiesel',
    'request-information:application:renewable-energy:biogas': 'Energetyka odnawialna - Biogaz',
    'request-information:application:renewable-energy:solar': 'Energetyka odnawialna - Energia słoneczna',
    'request-information:application:renewable-energy:others': 'Energetyka odnawialna - Pozostałe',
    'request-information:application:coatings-&-construction:construction:building-chemicals':
        'Farby i chemia budowlana - Chemia budowlana - Ceramika budowlana i techniczna ',
    'request-information:application:coatings-&-construction:construction:fireproof-materials':
        'Farby i chemia budowlana - Chemia budowlana - Materiały ogniotrwałe',
    'request-information:application:coatings-&-construction:construction:gipsum-cardboard':
        'Farby i chemia budowlana - Chemia budowlana - Płyty kartonowo-gipsowe',
    'request-information:application:coatings-&-construction:construction:plywood-&-particle-boards':
        'Farby i chemia budowlana - Chemia budowlana - Płyty wiórowe i sklejki',
    'request-information:application:coatings-&-construction:construction:floorings':
        'Farby i chemia budowlana - Chemia budowlana - Posadzki przemysłowe',
    'request-information:application:coatings-&-construction:construction:concrete-based-products':
        'Farby i chemia budowlana - Chemia budowlana - Produkty na bazie betonu',
    'request-information:application:coatings-&-construction:construction:cement-based-products':
        'Farby i chemia budowlana - Chemia budowlana - Produkty na bazie cementu',
    'request-information:application:coatings-&-construction:construction:plasters':
        'Farby i chemia budowlana - Chemia budowlana - Tynki',
    'request-information:application:coatings-&-construction:construction:mineral-wool-':
        'Farby i chemia budowlana - Chemia budowlana - Wełna mineralna',
    'request-information:application:coatings-&-construction:construction:others':
        'Farby i chemia budowlana - Chemia budowlana - Pozostałe',
    'request-information:application:coatings-&-construction:distributors': 'Farby i chemia budowlana - Dystrybucja',
    'request-information:application:coatings-&-construction:elastomers:pu-elastomers':
        'Farby i chemia budowlana - Elastomery - Elastomery poliuretanowe',
    'request-information:application:coatings-&-construction:elastomers:others':
        'Farby i chemia budowlana - Elastomery - Pozostałe',
    'request-information:application:coatings-&-construction:coatings:architectural-coatings':
        'Farby i chemia budowlana - Farby - Farby dekoracyjne',
    'request-information:application:coatings-&-construction:coatings:wood-coatings':
        'Farby i chemia budowlana - Farby - Farby do drewna',
    'request-information:application:coatings-&-construction:coatings:powder-coatings':
        'Farby i chemia budowlana - Farby - Farby proszkowe',
    'request-information:application:coatings-&-construction:coatings:industrial-coatings':
        'Farby i chemia budowlana - Farby - Farby przemysłowe',
    'request-information:application:coatings-&-construction:coatings:primers':
        'Farby i chemia budowlana - Farby - Grunty',
    'request-information:application:coatings-&-construction:coatings:automotive-coatings':
        'Farby i chemia budowlana - Farby - Lakiery samochodowe',
    'request-information:application:coatings-&-construction:coatings:thinners':
        'Farby i chemia budowlana - Farby - Rozcieńczalniki',
    'request-information:application:coatings-&-construction:coatings:others':
        'Farby i chemia budowlana - Farby - Pozostałe',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:adhesives':
        'Farby i chemia budowlana - Kleje i uszczelniacze - Kleje',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:hot-melts':
        'Farby i chemia budowlana - Kleje i uszczelniacze - Kleje topliwe',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:sealants':
        'Farby i chemia budowlana - Kleje i uszczelniacze - Uszczelniacze silikonowe i akrylowe',
    'request-information:application:coatings-&-construction:adhesives-&-sealants:others':
        'Farby i chemia budowlana - Kleje i uszczelniacze - Pozostałe',
    'request-information:application:coatings-&-construction:composites': 'Farby i chemia budowlana - Kompozyty',
    'request-information:application:coatings-&-construction:producers-of-c&c-raw-materials':
        'Farby i chemia budowlana - Producenci surowców do farb i chemii budowlanej',
    'request-information:application:coatings-&-construction:inks': 'Farby i chemia budowlana - Tusze',
    'request-information:application:coatings-&-construction:others': 'Farby i chemia budowlana - Pozostałe',
    'request-information:application:mining:natural-building-materials-&-stones':
        'Górnictwo - Naturalne materiały budowlane i kamienie',
    'request-information:application:mining:metal-ore': 'Górnictwo - Rudy metali',
    'request-information:application:mining:coal': 'Górnictwo - Węgiel',
    'request-information:application:mining:others': 'Górnictwo - Pozostałe',
    'request-information:application:steelworks:': 'Hutnictwo',
    'request-information:application:municipalities-&-institutions:schools-&-universities':
        'Instytucje i urzędy - Szkoły i uczelnie wyższe',
    'request-information:application:municipalities-&-institutions:hospitals': 'Instytucje i urzędy - Szpitale',
    'request-information:application:municipalities-&-institutions:others': 'Instytucje i urzędy - Pozostałe',
    'request-information:application:coke-engineering:': 'Koksownictwo',
    'request-information:application:fotochemical-&-film-materials:': 'Materiały fotochemiczne i filmowe',
    'request-information:application:explosives-&-pyrotechnics:': 'Materiały wybuchowe i pirotechniczne',
    'request-information:application:environmental-protection:consulting-&-services':
        'Ochrona środowiska - Doardztwo i serwis',
    'request-information:application:environmental-protection:soil-remediation':
        'Ochrona środowiska - Remediacja gruntów',
    'request-information:application:environmental-protection:incineration-plants':
        'Ochrona środowiska - Spalarnie odpadów',
    'request-information:application:environmental-protection:waste-dumps': 'Ochrona środowiska - Wysypiska odpadów',
    'request-information:application:reagents:': 'Odczynniki',
    'request-information:application:printing-&-publishing:narrow-web':
        'Poligrafia - Druk na maszynach wąskowstęgowych ',
    'request-information:application:printing-&-publishing:packaging-printing': 'Poligrafia - Druk na opakowaniach',
    'request-information:application:printing-&-publishing:distributors': 'Poligrafia - Dystrybucja',
    'request-information:application:printing-&-publishing:printing-inks': 'Poligrafia - Farby graficzne',
    'request-information:application:printing-&-publishing:flexography': 'Poligrafia - Fleksografia ',
    'request-information:application:printing-&-publishing:bookbinding': 'Poligrafia - Introligatornie ',
    'request-information:application:printing-&-publishing:sheet-fed-offset': 'Poligrafia - Offset arkuszowy',
    'request-information:application:printing-&-publishing:cold-set-offset': 'Poligrafia - Offset zwojowy - cold-set',
    'request-information:application:printing-&-publishing:heat-set-offset': 'Poligrafia - Offset zwojowy - heat-set',
    'request-information:application:printing-&-publishing:serigraphy': 'Poligrafia - Sitodruk',
    'request-information:application:printing-&-publishing:rotogravure': 'Poligrafia - Wklęsłodruk ',
    'request-information:application:printing-&-publishing:others': 'Poligrafia - Pozostałe',
    'request-information:application:loundries:': 'Pralnie chemiczne',
    'request-information:application:automotive-oems:': 'Producenci samochodów',
    'request-information:application:automotive-oems---service-companies:': 'Producenci samochodów - firmy serwisowe',
    'request-information:application:machinery-production:measuring-instruments':
        'Produkcja maszyn - Przyrządy i narzędzia pomiarowe',
    'request-information:application:machinery-production:mechanized-household-equipment':
        'Produkcja maszyn - Sprzęt AGD',
    'request-information:application:machinery-production:others': 'Produkcja maszyn - Pozostałe',
    'request-information:application:ceramic-industry:household-ceramic': 'Przemysł ceramiczny - Ceramika domowa',
    'request-information:application:ceramic-industry:others': 'Przemysł ceramiczny - Pozostałe',
    'request-information:application:wood-industry-&-woodwork:furniture': 'Przemysł drzewny i obróbka drewna - Meble',
    'request-information:application:wood-industry-&-woodwork:woodwork':
        'Przemysł drzewny i obróbka drewna - Obróbka drewna',
    'request-information:application:wood-industry-&-woodwork:wood-industry':
        'Przemysł drzewny i obróbka drewna - Przemysł drzewny',
    'request-information:application:wood-industry-&-woodwork:others': 'Przemysł drzewny i obróbka drewna - Pozostałe',
    'request-information:application:pharma:distributors': 'Przemysł farmaceutyczny - Dystrybucja',
    'request-information:application:pharma:human-&-veterinary-medicines':
        'Przemysł farmaceutyczny - Leki dla ludzi i zwierząt (GX, RX i OTC) ',
    'request-information:application:pharma:natural-remedies': 'Przemysł farmaceutyczny - Medycyna naturalna',
    'request-information:application:pharma:pharmaceutical-starting-material-producers':
        'Przemysł farmaceutyczny - Producenci substancji aktywnych, pomocniczych i intermediatów',
    'request-information:application:pharma:others': 'Przemysł farmaceutyczny - Pozostałe',
    'request-information:application:rubber:footwear': 'Przemysł gumowy - Buty',
    'request-information:application:rubber:distributors': 'Przemysł gumowy - Dystrybucja',
    'request-information:application:rubber:non-automotive-profiles':
        'Przemysł gumowy - Gumowe profile nie samochodowe',
    'request-information:application:rubber:automotive-profiles': 'Przemysł gumowy - Gumowe profile samochodowe',
    'request-information:application:rubber:general-rubber-goods': 'Przemysł gumowy - Gumowe wyroby techniczne',
    'request-information:application:rubber:wire-&-cables': 'Przemysł gumowy - Kable i otuliny',
    'request-information:application:rubber:tires:automotive-tires':
        'Przemysł gumowy - Opony - Opony do samochodów i motocykli',
    'request-information:application:rubber:tires:industrial-tires': 'Przemysł gumowy - Opony - Opony przemysłowe',
    'request-information:application:rubber:tires:others': 'Przemysł gumowy - Opony - Pozostałe',
    'request-information:application:rubber:non-automotive-hoses': 'Przemysł gumowy - Przewody nie samochodowe',
    'request-information:application:rubber:automotive-hoses': 'Przemysł gumowy - Przewody samochodowe',
    'request-information:application:rubber:automotive-belts': 'Przemysł gumowy - Taśmy i paski samochodowe',
    'request-information:application:rubber:non-automotive-belts':
        'Przemysł gumowy - Taśmy i paski transporterowe nie samochodowe',
    'request-information:application:rubber:rubber-rolls-and-wheels': 'Przemysł gumowy - Wałki i koła gumowe',
    'request-information:application:rubber:latex': 'Przemysł gumowy - Wyroby lateksowe',
    'request-information:application:rubber:custom-mixing': 'Przemysł gumowy - Wyroby z gotowych mieszanek',
    'request-information:application:rubber:others': 'Przemysł gumowy - Pozostałe',
    'request-information:application:cosmetics:distributors': 'Przemysł kosmetyczny - Dystrybucja',
    'request-information:application:cosmetics:general': 'Przemysł kosmetyczny - Klienci wielobranżowi',
    'request-information:application:cosmetics:decorative-products-&-make-up':
        'Przemysł kosmetyczny - Kosmetyka ozdobna i make-up',
    'request-information:application:cosmetics:soap-&-candles': 'Przemysł kosmetyczny - Mydła i świece zapachowe',
    'request-information:application:cosmetics:perfumes-&-fragrance': 'Przemysł kosmetyczny - Perfumy i wody toaletowe',
    'request-information:application:cosmetics:skin-care-and-sun-cosmetics':
        'Przemysł kosmetyczny - Pielęgnacja skóry i kosmetyki słoneczne',
    'request-information:application:cosmetics:hair-care-and-body-wash':
        'Przemysł kosmetyczny - Pielęgnacja włosów i ciała',
    'request-information:application:cosmetics:toll-manufacturers-&-contractors':
        'Przemysł kosmetyczny - Usługodawcy produkcyjni i wykonawcy',
    'request-information:application:cosmetics:others': 'Przemysł kosmetyczny - Pozostałe',
    'request-information:application:metal:zinc-coating---automotive-components':
        'Przemysł metalowy - Cynkownie - komponenty do samochodów',
    'request-information:application:metal:zinc-coating---non-automotive-components':
        'Przemysł metalowy - Cynkownie - komponenty nie samochodowe',
    'request-information:application:metal:distribution': 'Przemysł metalowy - Dystrybucja',
    'request-information:application:metal:electroplating---automotive-components':
        'Przemysł metalowy - Galwanizernie - komponenty do samochodów',
    'request-information:application:metal:electroplating---non-automotive-components':
        'Przemysł metalowy - Galwanizernie - Pozostałe komponenty',
    'request-information:application:metal:automotive-components---others':
        'Przemysł metalowy - Komponenty do samochodów - Pozostałe',
    'request-information:application:metal:non-automotive-components---others':
        'Przemysł metalowy - Komponenty nie samochodowe - Pozostałe',
    'request-information:application:metal:vanishing---automotive-components':
        'Przemysł metalowy - Lakiernie - komponenty do samochodów',
    'request-information:application:metal:vanishing---non-automotive-components':
        'Przemysł metalowy - Lakiernie - komponenty nie samochodowe',
    'request-information:application:metal:foundry---automotive-components':
        'Przemysł metalowy - Odlewnie - komponenty do samochodów',
    'request-information:application:metal:foundry---non-automotive-components':
        'Przemysł metalowy - Odlewnie - komponenty nie samochodowe',
    'request-information:application:pulp-&-paper:tissue-manufacturers':
        'Przemysł papierniczy - Producenci chusteczek papierowych',
    'request-information:application:pulp-&-paper:board-manufacturers': 'Przemysł papierniczy - Producenci kartonów',
    'request-information:application:pulp-&-paper:packaging-&-converting':
        'Przemysł papierniczy - Producenci opakować papierowych',
    'request-information:application:pulp-&-paper:paper-manufacturers':
        'Przemysł papierniczy - Producenci papieru i celulozy',
    'request-information:application:pulp-&-paper:others': 'Przemysł papierniczy - Pozostałe',
    'request-information:application:spirit-industry:': 'Przemysł spirytusowy',
    'request-information:application:food:chocolate-&-confection:confectionery': 'Przemysł spożywczy - Cukiernictwo',
    'request-information:application:food:chocolate-&-confection:chocolate':
        'Przemysł spożywczy - Cukiernictwo - Wyroby czekoladowe',
    'request-information:application:food:food-general:sugar-factories': 'Przemysł spożywczy - Cukrownie',
    'request-information:application:food:beverages:breweries,-malting':
        'Przemysł spożywczy - Napoje - Browary, słodownie',
    'request-information:application:food:beverages:alcoholic-beverages---others':
        'Przemysł spożywczy - Napoje - Napoje alkoholowe - Pozostałe',
    'request-information:application:food:beverages:non-alcoholic-beverages':
        'Przemysł spożywczy - Napoje - Napoje i soki',
    'request-information:application:food:beverages:instant-drinks': 'Przemysł spożywczy - Napoje - Napoje instant',
    'request-information:application:food:bakery-&-bread:bars-and-cereals':
        'Przemysł spożywczy - Piekarnictwo  - Konfekcja śniadaniowa',
    'request-information:application:food:bakery-&-bread:bakery-mix-blenders':
        'Przemysł spożywczy - Piekarnictwo  - Mieszanki',
    'request-information:application:food:bakery-&-bread:baked-goods':
        'Przemysł spożywczy - Piekarnictwo  - Wyroby gotowe',
    'request-information:application:food:food-general:flavour-producers': 'Przemysł spożywczy - Producenci aromatów',
    'request-information:application:food:food-general:other-ingredient-producers':
        'Przemysł spożywczy - Producenci dodatków do żywności - pozostali',
    'request-information:application:food:meat-and-poultry:blends': 'Przemysł spożywczy - Przemysł mięsny - Mieszanki',
    'request-information:application:food:meat-and-poultry:production':
        'Przemysł spożywczy - Przemysł mięsny - Produkcja',
    'request-information:application:food:meat-and-poultry:fish-industry':
        'Przemysł spożywczy - Przemysł mięsny - Przemysł rybny',
    'request-information:application:food:meat-and-poultry:slaughterhouses':
        'Przemysł spożywczy - Przemysł mięsny - Rzeźnie',
    'request-information:application:food:dairy-&-ice-cream:ice-cream':
        'Przemysł spożywczy - Przemysł mleczarski i lody - Lody',
    'request-information:application:food:dairy-&-ice-cream:dairy-ingredients':
        'Przemysł spożywczy - Przemysł mleczarski i lody - Pochodne mleka',
    'request-information:application:food:dairy-&-ice-cream:dairy-consumer-products':
        'Przemysł spożywczy - Przemysł mleczarski i lody - Wyroby',
    'request-information:application:food:food-general:starch-industry': 'Przemysł spożywczy - Przemysł skrobiowy',
    'request-information:application:food:food-general:food---distributors':
        'Przemysł spożywczy - Przemysł spożywczy - dystrybucja',
    'request-information:application:food:food-general:blenders---other':
        'Przemysł spożywczy - Przemysł spożywczy - mieszanki Pozostałe',
    'request-information:application:food:food-general:fats-&-oils': 'Przemysł spożywczy - Przemysł tłuszczowy',
    'request-information:application:food:food-general:tobacco': 'Przemysł spożywczy - Przemysł tytoniowy ',
    'request-information:application:food:food-general:potato-processing': 'Przemysł spożywczy - Przemysł ziemniaczany',
    'request-information:application:food:fruit-&-vegetable-processing:jams,-fruit-preparations':
        'Przemysł spożywczy - Przetwórstwo owocowo-warzywne - Dżemy, wsady owocowe',
    'request-information:application:food:fruit-&-vegetable-processing:fruit-concentrates':
        'Przemysł spożywczy - Przetwórstwo owocowo-warzywne - Koncentraty owocowe',
    'request-information:application:food:fruit-&-vegetable-processing:others':
        'Przemysł spożywczy - Przetwórstwo owocowo-warzywne - Pozostałe',
    'request-information:application:food:function-food,-supplements:food-supplements':
        'Przemysł spożywczy - Żywność funkcjonalna - Suplementy diety',
    'request-information:application:food:function-food,-supplements:sport-nutrition':
        'Przemysł spożywczy - Żywność funkcjonalna - Suplementy dla sportowców',
    'request-information:application:food:function-food,-supplements:baby-food':
        'Przemysł spożywczy - Żywność funkcjonalna - Żywność dla dzieci',
    'request-information:application:food:function-food,-supplements:special-food':
        'Przemysł spożywczy - Żywność funkcjonalna - Żywność specjalna',
    'request-information:application:food:convenience-food:savoury---others':
        'Przemysł spożywczy - Żywność wygodna - Przemysł kulinarny - Pozostałe',
    'request-information:application:food:convenience-food:soups,-sauces,-dressings':
        'Przemysł spożywczy - Żywność wygodna - Zupy, sosy, dressingi',
    'request-information:application:food:convenience-food:convenience-food':
        'Przemysł spożywczy - Żywność wygodna - Żywność wygodna',
    'request-information:application:food:others': 'Przemysł spożywczy - Pozostałe',
    'request-information:application:glass-industry:household-glass-&-glass-packaging':
        'Przemysł szklarski - Szkło domowe i opakowania szklane',
    'request-information:application:glass-industry:industrial-&-technical-glass':
        'Przemysł szklarski - Szkło przemysłowe i techniczne',
    'request-information:application:glass-industry:automotive-glass': 'Przemysł szklarski - Szyby samochodowe',
    'request-information:application:glass-industry:others': 'Przemysł szklarski - Pozostałe',
    'request-information:application:textile-&-leather:carpets-&-linings':
        'Przemysł włókienniczy i skórzany - Dywany i wykładziny',
    'request-information:application:textile-&-leather:knitted-fabrics': 'Przemysł włókienniczy i skórzany - Dzianiny',
    'request-information:application:textile-&-leather:automotive-leather-components':
        'Przemysł włókienniczy i skórzany - Elementy samochodowe ze skór',
    'request-information:application:textile-&-leather:leather-tanning':
        'Przemysł włókienniczy i skórzany - Garbarstwo',
    'request-information:application:textile-&-leather:leather-clothing,-footwear,-goods':
        'Przemysł włókienniczy i skórzany - Odzież, obuwie i galanteria skórzana',
    'request-information:application:textile-&-leather:yarn-&-threads':
        'Przemysł włókienniczy i skórzany - Przędze i nici',
    'request-information:application:textile-&-leather:tights': 'Przemysł włókienniczy i skórzany - Rajstopy',
    'request-information:application:textile-&-leather:natural-textiles':
        'Przemysł włókienniczy i skórzany - Tkaniny naturalne',
    'request-information:application:textile-&-leather:synthetic-textiles':
        'Przemysł włókienniczy i skórzany - Tkaniny syntetyczne',
    'request-information:application:textile-&-leather:unwoven-fabrics': 'Przemysł włókienniczy i skórzany - Włókniny',
    'request-information:application:textile-&-leather:others': 'Przemysł włókienniczy i skórzany - Pozostałe',
    'request-information:application:oil-&-gas:downstream---refineries,-pipelines':
        'Ropa i gaz - Downstream - rafinerie, rurociągi',
    'request-information:application:oil-&-gas:downstream---chemical-plants':
        'Ropa i gaz - Downstream - zakłady chemiczne',
    'request-information:application:oil-&-gas:distribution': 'Ropa i gaz - Dystrybucja',
    'request-information:application:oil-&-gas:midstream---specialty-gases': 'Ropa i gaz - Midstream - gazy techniczne',
    'request-information:application:oil-&-gas:midstream---compounders': 'Ropa i gaz - Midstream - mieszalnie',
    'request-information:application:oil-&-gas:midstream---n-spec': 'Ropa i gaz - Midstream - N-spec',
    'request-information:application:oil-&-gas:midstream---production': 'Ropa i gaz - Midstream - produkcja',
    'request-information:application:oil-&-gas:midstream---gasplants': 'Ropa i gaz - Midstream - wydobycie gazu',
    'request-information:application:oil-&-gas:upstream---drilling': 'Ropa i gaz - Upstream - wiertnictwo',
    'request-information:application:oil-&-gas:services': 'Ropa i gaz - Usługi',
    'request-information:application:oil-&-gas:others': 'Ropa i gaz - Pozostałe',
    'request-information:application:lubricants:asphalts': 'Środki smarne - Asfalty',
    'request-information:application:lubricants:bio-diesel---producers': 'Środki smarne - Biodiesel - produkcja',
    'request-information:application:lubricants:bio-ethanol---producers': 'Środki smarne - Bioetanol - produkcja',
    'request-information:application:lubricants:distribution': 'Środki smarne - Dystrybucja',
    'request-information:application:lubricants:blending-facilities': 'Środki smarne - Mieszalnie paliw i smarów',
    'request-information:application:lubricants:industrial-lubricants': 'Środki smarne - Smary przemysłowe',
    'request-information:application:lubricants:automotive-lubricants': 'Środki smarne - Smary samochodowe',
    'request-information:application:lubricants:release-agents': 'Środki smarne - Środki antyadhezyjne',
    'request-information:application:lubricants:others': 'Środki smarne - Pozostałe',
    'request-information:application:transportation:airports': 'Transport - Lotniska',
    'request-information:application:transportation:others': 'Transport - Pozostałe',
    'request-information:application:tourism:': 'Turystyka',
    'request-information:application:polymers:distributors': 'Tworzywa sztuczne - Dystrybucja',
    'request-information:application:polymers:polyurethanes:automotive-components':
        'Tworzywa sztuczne - Poliuretany - Komponenty samochodowe',
    'request-information:application:polymers:polyurethanes:flexible-&-rigid-polyurethanes-':
        'Tworzywa sztuczne - Poliuretany - Pianki miękkie i sztywne',
    'request-information:application:polymers:polyurethanes:polyurethane-systems':
        'Tworzywa sztuczne - Poliuretany - Systemy poliuretanowe',
    'request-information:application:polymers:polyurethanes:others': 'Tworzywa sztuczne - Poliuretany - Pozostałe',
    'request-information:application:polymers:compounders': 'Tworzywa sztuczne - Producenci mieszanek',
    'request-information:application:polymers:raw-material-producers':
        'Tworzywa sztuczne - Producenci surowców do tworzyw sztucznych',
    'request-information:application:polymers:injection-moulding:non-automotive-components':
        'Tworzywa sztuczne - Produkcja metodą wtrysku - Komponenty nie samochodowe',
    'request-information:application:polymers:injection-moulding:automotive-components':
        'Tworzywa sztuczne - Produkcja metodą wtrysku - Komponenty samochodowe',
    'request-information:application:polymers:regranulates': 'Tworzywa sztuczne - Regranulaty',
    'request-information:application:polymers:extrusion:films': 'Tworzywa sztuczne - Tworzywa wytłaczane - Folie',
    'request-information:application:polymers:extrusion:automotive-components':
        'Tworzywa sztuczne - Tworzywa wytłaczane - Komponenty samochodowe',
    'request-information:application:polymers:extrusion:flooring,-coated-textiles':
        'Tworzywa sztuczne - Tworzywa wytłaczane - Materiały powlekane, wykładziny ',
    'request-information:application:polymers:extrusion:plates-&-profiles':
        'Tworzywa sztuczne - Tworzywa wytłaczane - Płyty, profile ',
    'request-information:application:polymers:extrusion:pipes': 'Tworzywa sztuczne - Tworzywa wytłaczane - Rury ',
    'request-information:application:polymers:extrusion:polymers-textiles-&-fibers':
        'Tworzywa sztuczne - Tworzywa wytłaczane - Włókna, sznurki i tekstylia z tworzyw sztucznych',
    'request-information:application:polymers:extrusion:others': 'Tworzywa sztuczne - Tworzywa wytłaczane - Pozostałe',
    'request-information:application:polymers:chemosets': 'Tworzywa sztuczne - Wyroby chemoutwardzalne',
    'request-information:application:polymers:thermosets': 'Tworzywa sztuczne - Wyroby termoutwardzalne',
    'request-information:application:polymers:others': 'Tworzywa sztuczne - Pozostałe',
    'request-information:application:services:': 'Usługi',
    'request-information:application:water-treatment:swimming-pools': 'Woda i ścieki - Baseny i pływalnie',
    'request-information:application:water-treatment:municipalities-for-potable-water':
        'Woda i ścieki - Dostarczanie wody pitnej - zasięg lokalny',
    'request-information:application:water-treatment:municipalities-for-potable-and-waste-water':
        'Woda i ścieki - Dostarczanie wody pitnej i oczyszczanie ścieków - zasięg lokalny',
    'request-information:application:water-treatment:distributors': 'Woda i ścieki - Dystrybucja',
    'request-information:application:water-treatment:environmental-&-equipment-companies':
        'Woda i ścieki - Firmy inżynieryjne i budowlane',
    'request-information:application:water-treatment:municipalities-for-waste-water':
        'Woda i ścieki - Oczyszczanie ścieków - zasięg lokalny',
    'request-information:application:water-treatment:water-conditioning-service-companies':
        'Woda i ścieki - Usługi uzdatniania wody - firmy korporacyjne',
    'request-information:application:water-treatment:institutional-service-companies':
        'Woda i ścieki - Usługi uzdatniania wody - korporacje zarządzające',
    'request-information:application:water-treatment:other-water-treatment-service-companies':
        'Woda i ścieki - Usługi uzdatniania wody - Pozostałe',
    'request-information:application:water-treatment:other-water-treatment-end-users':
        'Woda i ścieki - Woda i ścieki - pozostali użytkownicy końcowi',
    'request-information:application:water-treatment:others': 'Woda i ścieki - Pozostałe',
    'request-information:application:animal-nutrition:distributors': 'Żywienie zwierząt - Dystrybucja',
    'request-information:application:animal-nutrition:homemixers-&-farmers:cattle-and-sheep':
        'Żywienie zwierząt - Hodowcy zwierząt - Hodowcy bydła i owiec',
    'request-information:application:animal-nutrition:homemixers-&-farmers:poultry':
        'Żywienie zwierząt - Hodowcy zwierząt - Hodowcy drobiu',
    'request-information:application:animal-nutrition:homemixers-&-farmers:pigs':
        'Żywienie zwierząt - Hodowcy zwierząt - Hodowcy trzody chlewnej',
    'request-information:application:animal-nutrition:homemixers-&-farmers:others':
        'Żywienie zwierząt - Hodowcy zwierząt - Pozostałe',
    'request-information:application:animal-nutrition:additive-blenders':
        'Żywienie zwierząt - Mieszalnie dodatków do pasz ',
    'request-information:application:animal-nutrition:mixed-production': 'Żywienie zwierząt - Mieszany zakres',
    'request-information:application:animal-nutrition:aquafeed': 'Żywienie zwierząt - Pasze dla ryb',
    'request-information:application:animal-nutrition:compound-feed': 'Żywienie zwierząt - Pasze i koncentraty',
    'request-information:application:animal-nutrition:premix': 'Żywienie zwierząt - Premixy',
    'request-information:application:animal-nutrition:milk-replacers': 'Żywienie zwierząt - Preparaty mlekozastępcze ',
    'request-information:application:animal-nutrition:integrators': 'Żywienie zwierząt - Produkcja zintegrowana ',
    'request-information:application:animal-nutrition:feed-ingredients': 'Żywienie zwierząt - Surowce paszowe ',
    'request-information:application:animal-nutrition:animal-waste-processing':
        'Żywienie zwierząt - Utylizacja odpadów zwierzęcych',
    'request-information:application:animal-nutrition:veterinarians': 'Żywienie zwierząt - Weterynarze',
    'request-information:application:animal-nutrition:petfood': 'Żywienie zwierząt - Żywienie zwierząt domowych',
    'request-information:application:animal-nutrition:others': 'Żywienie zwierząt - Pozostałe',
    'request-information:application:others:': 'Pozostałe',

    // languages
    'language:de': 'Niemiecki',
    'language:en': 'Angielski',
    'language:fr': 'Francuski',
    'language:nl': 'Holenderski',
    'language:pl': 'Polski',

    // countries
    'country:de': 'Niemcy',
    'country:en': 'Anglia',
    'country:fr': 'Francja',
    'country:nl': 'Holandia',
    'country:be': 'Belgia',
    'country:za': 'Afryka Południowa',
    'country:ae': 'Zjednoczone Emiraty Arabskie',
    'country:ke': 'Kenia',
    'country:tz': 'Tanzania',
    'country:ug': 'Uganda',
    'country:pl': 'Polska',
    'country:a1': 'Polska',

    // Onboarding
    'onboarding:skip-tour.skip': 'Pomiń prezentację',
    'onboarding:skip-tour.close': 'Zamknij prezentację',
    'onboarding:slider-button.previous': 'Powrót',
    'onboarding:slider-button.next': 'Dalej',
    'onboarding:slider-button.start': 'Rozpocznij',
    'onboarding:slider-button.finish': 'Rozpocznij',
    'onboarding:slide-sample.header': 'Brenntag Connect umożliwia:',
    'onboarding:slide-sample.title': 'Dostęp do bazy tysięcy produktów on-line',
    'onboarding:slide-sample.content':
        'Dzięki platformie możesz pobrać specyfikacje techniczne (TDS) tysięcy produktów, ich karty charakterystyki (MSDS) oraz wysłać zapytanie o ofertę, próbkę, dodatkowe dokumenty lub informacje.',
    'onboarding:slide-order-status.header': 'Brenntag Connect umożliwia:',
    'onboarding:slide-order-status.title': 'Składanie zamówień online i śledzenie ich statusu na Brenntag Connect',
    'onboarding:slide-order-status.content':
        'Otrzymujesz dostęp do aktualnych ofert przygotowanych specjalnie dla Ciebie przez Przedstawicieli Handlowych firmy Brenntag. Wybierz interesujący Cię produkt i złóż zamówienie on-line w dowolnym momencie, w dowolnym miejscu. Brenntag Connect umożliwia dodatkowo dostęp do historii zamówień z ostatnich 24 miesięcy oraz statusu realizacji bieżących zamówień on-line. ',
    'onboarding:slide-introduction.title': 'Zapraszamy do Twojego Portalu Klienta - Brenntag Connect',
    'onboarding:slide-introduction.content':
        'Brenntag Connect to więcej niż myślisz. <br/> <br/>Kliknij „Rozpocznij” aby zobaczyć krótką prezentację!',
    'onboarding:slide-finish.title': 'Jesteśmy tutaj aby pomóc!',
    'onboarding:slide-finish.content': `Jeśli masz jakieś pytania dotyczące korzystania z Brenntag Connect, prosimy o kontakt z Działem Wsparcia pod adresem email <a href="mailto:{csrEmail}">{csrEmail}</a>. Dodatkowo, możesz zawsze zapoznać się z <a href="{faqLink}" target="_blank"> FAQ </a>. Zapraszamy!`,

    // welome videos titles
    'welcome-video-title:WELCOME': 'Witamy',
    'welcome-video-title:FIRSTTIME': 'Ustawienia Konta',
    'welcome-video-title:ORDERS': 'Historia Zamówień',
    'welcome-video-title:INVOICES': 'Faktury',
    'welcome-video-title:PRODUCTS': 'Lista Produktów',
    'welcome-video-title:REQUESTS': 'Zapytania',
    'welcome-video-title:FEEDBACK': 'Twoja Opinia',
    'welcome-video-title:QUOTES': 'Oferty',

    'welcome-video:headline': 'Czy chcesz wiedzieć, jak korzystać z Brenntag Connect?',
    'welcome-video:instruction': 'Obejrzyj nasze wideo z prezentacją Brenntag Connect.',

    // Terms of Use
    'terms-of-usage:headline': 'Warunki korzystania z usług',
    'terms-of-usage:decline': 'Anuluj',
    'terms-of-usage:accept': 'Akceptuję',
    'terms-of-usage:updated':
        'Przed rozpoczęciem korzystania z Brenntag Connect prosimy o zapoznanie się i akceptację Warunków Korzystania z Usług. Warunki Korzystania z Usług są dostępne przez cały czas poprzez link w stopce aplikacji.',
    'terms-of-usage:content': `## Brenntag Connect – Portal klienta DigiB

### Warunki Korzystania z Usług
DigiB B.V., spółka z ograniczoną odpowiedzialnością założona zgodnie z prawem holenderskim (zwana dalej „Operatorem”) jest podmiotem należącym do Grupy Brenntag, światowego lidera w dystrybucji produktów chemicznych, oraz obsługuje serwis internetowy i platformę informacyjną „Brenntag Connect” (zwane dalej „Portalem”) i oferuje zarejestrowanym użytkownikom (osobom fizycznym, użytkownicy tej samej firmy muszą rejestrować się indywidualnie, aby zabezpieczyć przydział indywidualnego konta indywidualnej osobie fizycznej) różne funkcje (jeśli zostały aktywowane), takie jak zamawianie (i zamawianie ponowne) oraz zgłaszanie chęci zakupu produktów przez internet, sprawdzanie historii zamówień, przeglądanie nowych produktów, dostęp do odpowiednich dokumentów związanych z produktem i zamówieniem. Podczas korzystania z Portalu użytkownik będzie zawsze stosować się do zasad wyszczególnionych w niniejszych Warunkach Korzystania z Usług oraz obowiązujących przepisów. 

### 1. Zakres obowiązywania
1.1 Poniższe Warunki Korzystania z Usług dotyczą wyłącznie korzystania z Portalu. Operator zastrzega sobie prawo do zmiany tych warunków w przyszłości ze skutkiem natychmiastowym.

1.2 W przypadku wszelkich potencjalnych aktualnych i przyszłych transakcji internetowych za pośrednictwem Portalu obowiązują udostępnione w Portalu ogólne warunki i postanowienia dotyczące sprzedaży i dostaw (zwane dalej „Ogólnymi Warunkami Sprzedaży”) podmiotu należącego do Grupy Brenntag i dokonującego sprzedaży (zwanego dalej „Sprzedającym”). Warunki i postanowienia użytkowników lub ich klientów kolidujące z niniejszymi Warunkami Korzystania z Usług lub Ogólnymi Warunkami Sprzedaży nie będą obowiązywać, o ile, jeśli dotyczy, Sprzedający nie potwierdzi wyraźnie ich ważności w formie pisemnej (na potrzeby niniejszych warunków „forma pisemna” obejmuje pocztę elektroniczną i faks). 

### 2. Rejestracja
2.1 Każdy użytkownik zainteresowany korzystaniem z usług Portalu, jeśli nie został aktywnie zaproszony jako istniejący klient, może wysłać odpowiednie zgłoszenie rejestracji do Operatora. Użytkownik prześle wszelkie dane wymagane przez Operatora, a następnie upewni się, że takie dane będą aktualne przez cały okres przetwarzania jego wniosku i/lub rejestracji. Po dostarczeniu pełnych wymaganych danych Operator podejmie według własnego uznania decyzję o rejestracji użytkownika. Użytkownik zostanie poinformowany o statusie rejestracji przez Operatora w formie pisemnej. 

2.2 Operator jest uprawniony do odmowy lub unieważnienia rejestracji bez podania przyczyny. Operator nie ponosi odpowiedzialności za jakiekolwiek opóźnienia lub odmowę rejestracji. 

### 3. Hasło i zobowiązania użytkownika
3.1 Wraz z potwierdzeniem zgłoszenia rejestracji każdy użytkownik otrzyma indywidualną nazwę użytkownika w formie jego zarejestrowanego adresu e-mail oraz indywidualne hasło. Dostęp do usług Portalu będzie możliwy wyłącznie po wprowadzeniu tych dwóch rodzajów danych uwierzytelniających.

3.2 W razie utraty tych danych identyfikacyjnych lub uzasadnionego podejrzenia, że strona trzecia w sposób niepożądany uzyskała dostęp do danych identyfikacyjnych logowania, użytkownik jest zobowiązany do bezzwłocznej zmiany hasła i poinformowania o tym Operatora w formie pisemnej.

3.3 Użytkownik jest zobowiązany do bezzwłocznego powiadomienia Operatora w formie pisemnej o odejściu z danej firmy innych pracowników lub stron trzecich (np. konsultantów), którzy znają dane uwierzytelniania opisane w punkcie 3.1, jednak nie później niż w chwili rozwiązania stosunku pracy lub innej formy współpracy, aby wykluczyć wszelkie nieprawidłowe wykorzystanie danych uwierzytelniania użytkownika. Wszelkie wykryte występujące lub potencjalne przypadki nieprawidłowego użytkowania Portalu wymagają bezzwłocznego zgłoszenia Operatorowi. W takich przypadkach Operator bezzwłocznie zarejestruje nowe dane uwierzytelniania lub co najmniej ustawi nowe hasło użytkownika oraz poinformuje o tym użytkownika w formie pisemnej. 

3.4 Do czasu otrzymania powiadomienia o takim rozwiązaniu współpracy lub nieprawidłowym użytkowaniu użytkownik ponosi odpowiedzialność za wszelkie publikowane deklaracje i treści bądź za wszelkie inne agresywne zachowania związane z Portalem ze strony pracownika lub strony trzeciej, których dotyczy rozwiązanie współpracy.

3.5 Użytkownik nie będzie zamieszczać żadnych treści ani zawartości, które a) w jakikolwiek sposób naruszają lub zagrażają prawom Operatora lub jakichkolwiek stron trzecich (zwłaszcza prawom do nazwy, prawom osobistym, prawom dotyczącym znaków towarowych, prawom autorskim, itp., b) są nielegalne lub c) zawierają wirusy, narzędzia pirackie lub niechciane masowe wiadomości poczty elektronicznej (spam). Użytkownik jest zobowiązany do stosowania się do wszelkich obowiązujących praw własności intelektualnej i innych praw własności stron trzecich i nie może zawierać jakichkolwiek nielegalnych porozumień bądź podejmować działań lub uzgodnionych praktyk, które mogą prowadzić do naruszenia prawa antymonopolowego.

3.6 Operator jest uprawniony do usuwania zamieszczonych niestosownych treści oraz do wykluczenia użytkownika z dalszego korzystania z Portalu, zwłaszcza w przypadku publikacji treści niemoralnych, nielegalnych lub szkodliwych dla działalności.

3.7 Operator zastrzega sobie prawo do zgłaszania naruszeń obowiązującego prawa władzom odpowiedniego kraju, w którym użytkownik prowadzi działalność. Ponadto Operator zastrzega sobie prawo do dochodzenia roszczeń oraz do wyczerpania innych potencjalnych dróg działania dostępnych w przypadku naruszenia Warunków Korzystania z Usług.

### 4. Zgłoszenie zamówienia / składanie zamówień na produkty
4.1 W oparciu o listę produktów oraz ceny zamieszczone w Portalu, które stanowią wyłącznie niewiążące informacje handlowe, użytkownik może wysłać zgłoszenie zamówienia, aby zamówić (lub zamówić ponownie) produkty od Sprzedającego za pośrednictwem Portalu (zwane dalej łącznie „Zamówieniem”). Aby zakończyć proces składania Zamówienia, użytkownik musi wybrać produkt, ilość, miejsce dostawy oraz termin dostawy. Następnie użytkownik musi wprowadzić numer referencyjny zlecenia zakupu. Kliknięcie przycisku „Wyślij” w obszarze Zamówienia lub koszyka (kasy) oznacza wiążące w sensie prawnym dla użytkownika złożenie oferty zakupu. 

4.2 Po otrzymaniu Zamówienia Sprzedający zbada jego wykonalność, przekaże powiadomienie odbioru użytkownikowi oraz, w zależności od sytuacji, przekaże (i) potwierdzenie zamówienia (przyjęcie oferty zakupu) lub (ii) kontrofertę uwzględniającą potencjalne zmiany ilości, ceny i/lub terminu dostawy. Wszystkie zamówienia (lub ponowne zamówienia) złożone przez użytkownika za pośrednictwem Portalu wymagają akceptacji/potwierdzenia w formie pisemnej (wystarczy wiadomość poczty elektronicznej) ze strony Sprzedającego lub dorozumianej akceptacji/potwierdzenia poprzez realizację zamówienia. Wszystkie kontroferty natomiast wymagają akceptacji/potwierdzenia w formie pisemnej (wystarczy wiadomość poczty elektronicznej) ze strony użytkownika lub dorozumianej akceptacji/potwierdzenia poprzez realizację płatności w celu zawarcia wiążącej umowy dostawy.

### 5. Usuwanie rejestracji
Każdy użytkownik może zażądać usunięcia swojej rejestracji w dowolnej chwili, wysyłając odpowiednie żądanie do Operatora w formie pisemnej. Po usunięciu rejestracji (z odpowiednim powiadomieniem o pozytywnym usunięciu) użytkownik natychmiast straci prawo do korzystania z usług oferowanych przez Portal. Obowiązują odpowiednie ustawowe wymogi w zakresie przechowywania danych lub inne podstawy uzasadniające przechowywanie danych. Operator jest uprawniony do usunięcia istniejącej rejestracji bez podania przyczyny, jeśli – w uzasadnionej ocenie Operatora – użytkownik podał fałszywe informacje podczas rejestracji lub w razie naruszenia Warunków Korzystania z Usług lub obowiązującego prawa przez użytkownika. Użytkownik zostanie powiadomiony przez Operatora w formie pisemnej o usunięciu rejestracji. W razie usunięcia rejestracji operator bezzwłocznie usunie wszelkie dane osobowe użytkownika, jeżeli nie będzie miał innych podstaw do przetwarzania tych danych Operator nie ponosi odpowiedzialności za jakiekolwiek usuwanie profili rejestracji. 

### 6. Odpowiedzialność
6.1 Odpowiedzialność Operatora dotycząca obsługi Portalu jest ograniczona do rażącego zaniedbania lub działania umyślnego Operatora, jego prawnych przedstawicieli lub pełnomocników. Powyższe nie ma zastosowania w przypadku odpowiedzialności wynikającej z utraty życia, obrażeń ciała lub pogorszenia stanu zdrowia lub w razie zawinionego naruszenia zobowiązań będących istotną częścią umowy w sposób zagrażający realizacji celu umowy (zobowiązanie zasadnicze) bądź odpowiedzialności za produkt lub w innych przypadkach obowiązkowej odpowiedzialności ustawowej. Z wyjątkiem działania umyślnego, rażącego zaniedbania i szkód wynikających z utraty życia, obrażeń ciała lub pogorszenia stanu zdrowia odpowiedzialność jest ograniczona do kwoty typowych przewidywalnych szkód w chwili rejestracji użytkownika, która w żadnym przypadku nie może przekroczyć 100 000 EUR za jeden przypadek i na jeden rok, z wyłączeniem jakichkolwiek przypadków odpowiedzialności za szkody pośrednie, uboczne, wynikowe oraz za straty finansowe oraz utraty zysków.

6.2 Użytkownik ponosi odpowiedzialność za wszelkie działania wykonywane z wykorzystaniem jego konta użytkownika i/lub danych przekazanych w celach wykraczających poza przeznaczenie Portalu. Użytkownik podejmie wszelkie uzasadnione działania mające na celu a) zapobieganie nieupoważnionemu dostępowi do jego konta użytkownika i/lub udostępnianych danych oraz b) zapewnienie zgodności z wszelkimi zobowiązaniami umownymi na rzecz Operatora oraz z obowiązującymi przepisami ustawowymi działań swoich, swoich prawnych przedstawicieli, pełnomocników lub jakichkolwiek innych stron trzecich oraz c) zapewnienie nieszkodliwości danych przekazywanych do Portalu pod względem zagrożeń cyfrowych, np. m.in. złośliwego oprogramowania, ataków wirusów itp. Odpowiedzialność użytkownika jest wyłączona, jeśli takie nieprawidłowe korzystanie z Portalu lub jego zainfekowanie jest spowodowane okolicznościami pozostającymi poza uzasadnioną kontrolą użytkownika. 

### 7. Zwolnienie od odpowiedzialności
Użytkownik zwalnia z odpowiedzialności i zabezpiecza Operatora przed wszelkimi roszczeniami wnoszonymi przez innych użytkowników lub inne strony trzecie przeciw Operatorowi w związku z naruszeniem ich praw, jeśli takie naruszenie zostało spowodowane przez jakiekolwiek zawinione działania lub zaniechania opisane w punkcie 6.2. Użytkownik pokryje również uzasadnione koszty poniesione przez Operatora w związku z wymaganymi formami obrony prawnej, w tym wszelkie opłaty sądowe i za pomoc prawną. Użytkownik jest zobowiązany do przekazania Operatorowi bezzwłocznie, zgodnie z prawdą i wyczerpująco wszystkich informacji wymaganych do oceny roszczenia oraz obrony w przypadku roszczenia wnoszonego przez strony trzecie. 

### 8. Gwarancja
8.1 Operator nie gwarantuje i nie ponosi jakiejkolwiek odpowiedzialności w zakresie a) kompletności i dokładności wszelkich informacji zamieszczonych w Portalu oraz b) braku usterek technicznych Portalu, w szczególności i) braku ciągłej i nieprzerwanej dostępności Portalu i jego zawartości lub ii) braku kompletnego i dokładnego wyświetlania/odtworzenia treści opublikowanych przez użytkownika. Usługi Portalu są oferowane przez Operatora bezpłatnie i bez jakichkolwiek zobowiązań prawnych w zakresie ciągłości działania. Operator zastrzega sobie prawo, aby w dowolnej chwili a) całkowicie lub częściowo, tymczasowo lub trwale zaprzestać obsługi Portalu w dowolnym momencie bez uprzedniego powiadomienia lub b) uzależnić korzystanie z Portalu od opłat wnoszonych przez użytkowników. 

8.2 Operator nie przejmuje treści opublikowanych w Portalu przez stronę trzecią jako swoich własnych.

8.3 Operator nie ponosi jakiejkolwiek odpowiedzialności za szkody poniesione przez użytkownika i spowodowane przez opieranie się na dokładności, kompletności i nieszkodliwości treści opublikowanych w Portalu przez jakąkolwiek stronę trzecią. 

### 9. Wykorzystanie danych i ochrona danych
Informacje dotyczące kategorii, zakresu, miejsca i celu gromadzenia, przetwarzania i wykorzystywania danych osobowych wymaganych podczas rejestracji oraz do realizacji transakcji komercyjnych są dostępne w naszej [Polityce Prywatności]({dataPrivacyLink}).

### 10. Postanowienia końcowe
10.1 Jeśli jakikolwiek zapis stanowiący część niniejszych Warunków Korzystania z Usług w ramach tej Umowy jest lub stanie się nieważny lub niewykonalny, pozostałe zapisy stanowiące część niniejszych Warunków Korzystania z Usług będą nadal obowiązywać. Nieważny lub niewykonalny zapis zostanie automatycznie zastąpiony przez ważny i wykonalny zapis, który w sposób najbliższy oddaje ekonomiczny i prawny cel nieważnego lub niewykonalnego zapisu. Ma to również zastosowanie w przypadku ujawnienia luki bądź pominięcia.

10.2 Wszelkie kwestie dotyczące stosowania i interpretacji Warunków Korzystania z Usług Portalu oraz wszelkie spory związane z tymi kwestiami będą podlegać prawu holenderskiemu bez zasad kolizji przepisów prawnych oraz z wyłączeniem Konwencji Narodów Zjednoczonych o umowach międzynarodowej sprzedaży towarów (CISG). 

10.3 Miejscem prawnego rozstrzygania wszelkich sporów będzie Amsterdam w Holandii. Ma to również zastosowanie, jeśli użytkownik nie jest objęty ogólną jurysdykcją w Holandii lub UE bądź jego miejsce zamieszkania jest nieznane w chwili wszczęcia postępowania.

(Status: 07.04.2020)`,
}

export default messages
