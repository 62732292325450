import { DurationInputArg1, DurationInputArg2, ISO_8601 } from 'moment'

import { Language } from '@bc/translations'
import { GQLRequestStatus, GQLRequestType } from '@bc/types'

export type safetySheetTypes = 'msds' | 'tds'

export type ProductColumnTypes =
    | 'materialId'
    | 'name'
    | 'einecsNumber'
    | 'casNumber'
    | 'packagingName'
    | 'fillingWeight'
    | 'msdsFile'
    | 'supplier'
    | 'actions'
    | 'unitCapacity'
export interface DateDurationConfig {
    amount: NonNullable<DurationInputArg1>
    unit: DurationInputArg2
}
export interface DeliveryDateRestrictions {
    reorderDate: DateDurationConfig
    reorderEndDate: DateDurationConfig
    startDate: DateDurationConfig
    endDate: DateDurationConfig
}
export interface CustomerDataDateRestrictions {
    orders: DateDurationConfig
    invoices: DateDurationConfig
    requests: DateDurationConfig
}

export enum SalutationType {
    Formal = 'formal',
    Informal = 'informal',
    Internal = 'internal',
}

export enum ProductView {
    CARDS = 'CARDS',
    TABLE = 'TABLE',
}

export type Currency = 'EUR' | 'GBP' | 'USD' | 'AUD' | 'PLN'
export type DateFormat = 'DD-MM-YYYY' | 'DD/MM/YYYY' | 'MM/DD/YYYY' | 'DD.MM.YYYY'
export type CurrencyDisplay = 'symbol' | 'code' | 'narrowSymbol' | 'name'

// support both old format YYYYMMDD and new convention ISO_8601
export const SapDateFormats = ['YYYYMMDD', ISO_8601]

export interface WelcomeVideoDescription {
    key: string
    url: string
}
export type WelcomeVideos = { [key in Language]?: WelcomeVideoDescription[] }

export type UomMapperFunction = (uom: string) => string
export type UomMapper = { [key in Language]?: UomMapperFunction }

export interface Tax {
    percentage: number
    since: string
}

export type RequestStatusOnCreationDictionary = Record<GQLRequestType, GQLRequestStatus>

export interface SecurityConfigOptions {
    disableApi: boolean
}
