import React from 'react'

import { useMutation } from '@apollo/react-hooks'

import { GeneratePasswordSetLinkPage } from './generate-set-password-link-page'
import { ResetPasswordToken } from './queries'

const GeneratePasswordSetLinkContainer = () => {
    const [resetPasswordToken] = useMutation(ResetPasswordToken)

    const resetToken = (email: string) =>
        resetPasswordToken({
            variables: {
                email,
            },
        })
    return <GeneratePasswordSetLinkPage resetToken={resetToken} />
}

export default GeneratePasswordSetLinkContainer
