import { salesOrganisationConfigDev } from '../salesOrganisation'
import { ShopConfigDev } from '../types'

export const shopConfigDev: ShopConfigDev = {
    DE2: {
        salesOrganisations: {
            DE01: salesOrganisationConfigDev.DE01,
        },
    },
    BNL1: {
        salesOrganisations: {
            BE01: salesOrganisationConfigDev.BE01,
            NL01: salesOrganisationConfigDev.NL01,
        },
    },
    CEE1: {
        salesOrganisations: {
            A100: salesOrganisationConfigDev.A100,
        },
    },
}
