import React from 'react'
import styled from 'styled-components'

import { Text } from '@bc/ui/src/components/text'

interface PageTitleProps {
    title: string
    children?: React.ReactNode
}

export const PageTitleComponent = styled.div`
    display: flex;
    margin: 0 0 30px 0;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`

export const PageTitle = ({ title, children }: PageTitleProps) => (
    <PageTitleComponent>
        <Text h1 noMargin data-test-id="text-page-title">
            {title}
        </Text>
        {children}
    </PageTitleComponent>
)
