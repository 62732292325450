import { Placement as PopperPlacement } from 'popper.js'
import React from 'react'
import { Manager, Popper, Reference } from 'react-popper'

import { colors } from '@bc/ui/src/config'
import { SvgIconName } from '../svg'
import { Arrow, Icon, IconWrapper, PopperBox, PopperWrapper } from './components'

export type ThemeTypes = 'default' | 'light' | 'button'
export type Placement = PopperPlacement
interface TooltipProps {
    subject?: React.ReactNode
    children: React.ReactNode
    placement: Placement
    theme?: ThemeTypes
    icon?: SvgIconName
}

interface TooltipState {
    visible: boolean
}

const modifiers = {
    offset: { offset: '0, 10px' },
}

export class Tooltip extends React.Component<TooltipProps, TooltipState> {
    public state: TooltipState = {
        visible: false,
    }

    private onMouseEnterHandler = () => {
        this.setState({
            visible: true,
        })
    }

    private onMouseLeaveHandler = () => {
        this.setState({
            visible: false,
        })
    }
    private onClickHandler = () => {
        this.setState(state => ({
            visible: !state.visible,
        }))
    }

    public render() {
        const {
            subject,
            children,
            placement: popperPlacement = 'left',
            theme = 'default',
            icon = 'Circle_Info',
        } = this.props
        const { visible } = this.state

        return (
            <Manager>
                <Reference>
                    {({ ref }) => (
                        <IconWrapper
                            ref={ref}
                            onMouseEnter={this.onMouseEnterHandler}
                            onMouseLeave={this.onMouseLeaveHandler}
                            onClick={this.onClickHandler}
                            theme={theme}>
                            {subject ? subject : <Icon icon={icon} size="20" fill={colors.deepPurple} />}
                        </IconWrapper>
                    )}
                </Reference>
                {visible && (
                    <Popper placement={popperPlacement} modifiers={modifiers}>
                        {({ ref, style, placement, arrowProps }) => (
                            <PopperWrapper ref={ref} style={style} data-placement={placement}>
                                <PopperBox ref={ref} theme={theme}>
                                    {children}
                                    <Arrow
                                        ref={arrowProps.ref}
                                        data-placement={placement}
                                        style={arrowProps.style}
                                        theme={theme}
                                    />
                                </PopperBox>
                            </PopperWrapper>
                        )}
                    </Popper>
                )}
            </Manager>
        )
    }
}
