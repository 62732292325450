import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Column, Container, Row } from '@bc/ui/src/components'
import { spacing } from '@bc/ui/src/config'

interface Props {
    children: {
        header: ReactNode
        content: ReactNode
    }
}

const ColumnContainer = styled(Column)`
    margin-bottom: ${spacing.sm}px;
`

export const CalciumTemplate = (props: Props) => {
    const { header, content } = props.children
    return (
        <Container>
            <Row>
                <Column xs={12} md={{ size: 9, offset: 3 }} lg={{ size: 9, offset: 2 }}>
                    {header}
                </Column>
            </Row>
            <Row>
                <ColumnContainer xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 2 }}>
                    {content}
                </ColumnContainer>
            </Row>
        </Container>
    )
}
