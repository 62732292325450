import React, { createContext, useEffect, useState } from 'react'

import { SessionCartNotifyFunc, SessionState, SessionStore } from '@frontend/stores/session'

interface SessionProviderProps {
    children: JSX.Element
    cookies: any
}

export type SessionContextData = [SessionStore, Partial<SessionState>]

const sessionStoreInstance = new SessionStore()
export const SessionContext = createContext<SessionContextData>([sessionStoreInstance, {}])

export const SessionProvider = (props: SessionProviderProps) => {
    const { cookies } = props
    const [sessionState, setSessionState] = useState({})

    const notify: SessionCartNotifyFunc = (data: SessionState) => {
        setSessionState(data)
    }

    useEffect(() => {
        // browser cookies is only available in the client not in SSR so
        // we have to useEffect to load the store
        sessionStoreInstance.addNotifier(notify)
        if (cookies) {
            sessionStoreInstance.enrichWithCookies(cookies)
        }
    }, [cookies])

    return <SessionContext.Provider value={[sessionStoreInstance, sessionState]} {...props} />
}
