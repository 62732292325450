import { GQLBaseOrderLine, GQLMaterial, GQLPriceAmount, QuotationGroup } from '@bc/types'

import { ProductAddToCartTag } from '../types'
import { dataLayer, getCurrency, getProductBrand } from '../utils'

const anyProductAddToCart = (material: GQLMaterial, price: GQLPriceAmount | undefined) => {
    const currencyCode = getCurrency({ price })
    const brand = getProductBrand(material.manufacturer)

    return dataLayer<ProductAddToCartTag>({
        event: 'addToCart',
        ecommerce: {
            currencyCode,
            add: {
                products: [
                    {
                        name: material.name || '-',
                        id: material.id,
                        brand,
                        quantity: 1,
                    },
                ],
            },
        },
    })
}

export const baseProductAddToCart = (orderLine: GQLBaseOrderLine) => {
    const { material, unitPrice } = orderLine
    const price: GQLPriceAmount | undefined = unitPrice
        ? {
              amount: unitPrice.price || 0,
              currency: unitPrice.currency || '-',
          }
        : undefined

    anyProductAddToCart(material!, price)
}

export const quoteProductAddToCart = (quoteGroup: QuotationGroup) => {
    const price: GQLPriceAmount | undefined = quoteGroup.prices[0]?.price
    anyProductAddToCart(quoteGroup.material!, price)
}
