import { GQLMaterial, GQLPrice, GQLPriceAmount, GQLRequestType, GTMBaseOrderData } from '@bc/types'

import { EcommerceTag } from '../types'
import { dataLayer, getCurrency } from '../utils'

interface Product {
    name: string
    id: string
    price: number | string
    brand?: string
    variant?: string
    quantity: number
    metric1: number
    dimension1: string
    dimension2?: string
    dimension14?: number
    dimension15?: number
}
type CheckoutTag = EcommerceTag<{
    currencyCode: string | undefined
    checkout: {
        actionField: {
            step: number
        }
        products: Product[]
    }
}>

export interface ReorderFormValues {
    shipToId?: string
    customerPoReference?: string
    quantity?: number
    requestedDeliveryDate?: string
    pieces?: number
    comment?: string
    recipient?: string
    requirements?: [GQLRequestType]
    prices?: GQLPrice[]
    unitPrice?: string
}

export const baseCheckout = (material: GQLMaterial, price: GQLPriceAmount, GTMData: GTMBaseOrderData) => {
    const currencyCode = getCurrency({ price })

    dataLayer<CheckoutTag>({
        event: 'checkout',
        ecommerce: {
            currencyCode,
            checkout: {
                actionField: {
                    step: 1,
                },
                products: [
                    {
                        name: material.name!,
                        id: material.id!,
                        price: GTMData.price ? String(GTMData.price) : '-',
                        brand: material && material.manufacturer && material.manufacturer.name,
                        quantity: 1,
                        metric1: GTMData.pricePerUnit ? GTMData.pricePerUnit : 0,
                        dimension1: 'kg',
                        dimension2: material && material.packaging!.name,
                        dimension14: GTMData.orderAmount,
                        dimension15: GTMData.orderQuantity,
                    },
                ],
            },
        },
    })
}
