import React, { memo, useRef, useState } from 'react'

import { GQLCustomer } from '@bc/types'

import { useClickOutside } from '../hooks'
import * as DropdownComponents from './dropdown-components'

export interface RenderOptions {
    getToggleButtonProps: () => any
    isOpen: boolean
}

export interface DropdownLinkItem {
    to: string
    text: string
    lang?: string
    noHoverTranslate?: boolean
    id?: string
}

interface Props {
    items?: DropdownLinkItem[]
    children?: (props: RenderOptions) => React.ReactChild
    itemToComponent?: (renderOptions: DropdownLinkItem | GQLCustomer, itemIndex: number) => React.ReactChild
    open?: boolean
    listPadding?: boolean
    dropdownList?: JSX.Element
}

export const Dropdown = memo(
    ({ open, items, children, itemToComponent, listPadding = true, dropdownList }: Props): JSX.Element => {
        const ref = useRef<HTMLDivElement>(null)
        useClickOutside([ref], () => onClickOutside())

        const [isOpen, setIsOpen] = useState(Boolean(open))

        const toggleDropdown = () => {
            setIsOpen(!isOpen)
        }

        const onClickOutside = () => {
            setIsOpen(false)
        }

        const getToggleButtonProps = () => ({
            onClick: toggleDropdown,
            isOpen,
        })

        return (
            <DropdownComponents.Wrapper ref={ref}>
                {children ? (
                    children({
                        getToggleButtonProps,
                        isOpen,
                    })
                ) : (
                    <DropdownComponents.Toggle {...getToggleButtonProps()} />
                )}
                <DropdownComponents.Holder isOpen={isOpen} hasListPadding={listPadding}>
                    <DropdownComponents.List>
                        <DropdownComponents.Content onClick={onClickOutside}>
                            {dropdownList}
                            {items &&
                                items.map((item: any, itemIndex) =>
                                    itemToComponent ? (
                                        itemToComponent(item, itemIndex)
                                    ) : (
                                        <DropdownComponents.ItemButton key={itemIndex}>
                                            {item}
                                        </DropdownComponents.ItemButton>
                                    ),
                                )}
                        </DropdownComponents.Content>
                    </DropdownComponents.List>
                </DropdownComponents.Holder>
            </DropdownComponents.Wrapper>
        )
    },
)
