import React, { ButtonHTMLAttributes, InputHTMLAttributes } from 'react'
import styled from 'styled-components'

import { SvgComponentProps, SvgIcon } from '@bc/ui/src/components/svg'
import { colors, defaultFontSpacing, spacing } from '@bc/ui/src/config'
import { getFontFamily } from '@bc/ui/src/helpers'

export interface InputProps {
    hasError?: boolean
    textarea?: boolean
    readOnly?: boolean
    type?: string
    hasItem?: boolean
}

export interface LabelProps {
    labelColor?: keyof typeof colors
}

export const Label = styled.label<LabelProps>`
    display: block;
    margin-bottom: ${spacing.xxs};
    ${({ labelColor }) =>
        labelColor
            ? `
        color: ${colors[labelColor]}};
    `
            : ''}
`

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    textarea?: boolean
    hasError?: boolean
}

export const Input = styled.input<InputProps>`
    ${defaultFontSpacing}
    min-width: 0;
    height: 40px;
    padding: 8px 15px;
    caret-color: ${colors.blue50};

    font-family: ${getFontFamily('brenntagsans')};
    color: ${colors.deepPurple};
    background-color: ${colors.white};

    border: 1px solid;
    border-color: ${colors.neutral30};
    outline: 0;
    -webkit-appearance: none;
    transition: border-color 200ms ease-out;

    ::placeholder , ::-ms-input-placeholder, :-ms-input-placeholder, ::-webkit-input-placeholder { 
        color: ${colors.neutral40} !important;
    }
    &:hover:read-write { 
        border-color: ${colors.deepPurple};
    }
    &:focus:read-write { 
        outline: 3px solid;
        outline-color: ${colors.blue50};
        outline-offset: -3px;
    }
    &:disabled { 
        border-color: ${colors.neutral30};
        color: ${colors.neutral30};
        cursor: not-allowed;
    }
    &:read-only {
        background-color: ${colors.neutral10};
        border-color: ${colors.neutral30};
        border: none;
        color: ${colors.deepPurple};
    }

    ${({ readOnly, disabled }) =>
        readOnly || disabled
            ? ``
            : `
            & + button[data-location="left"] {
                border-right: none;
            };
            & + button[data-location="right"] {
                border-left: none;
            };
            `}
    
     ${({ textarea }) =>
         textarea
             ? `
            resize: none;
            height: 150px;
            max-width: 100%;
            `
             : ``}
     ${({ type }) =>
         type
             ? `
            padding-right: ${spacing.sm}px;
            `
             : ``}
    ${({ hasItem }) =>
        hasItem
            ? `
            flex: 1;
            @-moz-document url-prefix() {
                flex: 1 1 auto;
            }
            padding-right: 0;
            `
            : `width: 100%;`}

    ${({ hasError }) =>
        hasError
            ? `
            &:read-write, &:focus:read-write, &:hover:read-write {
                background-color: ${colors.red10};
                outline: 2px solid;
                outline-color: ${colors.red70};
                outline-offset: -2px;
            };
            `
            : ``}      
        
    ${({ hasError }) => `
        &:-internal-autofill-selected, -webkit-autofill {
        background-color: ${hasError ? colors.red10 : colors.white} !important;
        -webkit-text-fill-color: ${colors.deepPurple};
        -webkit-box-shadow: 0 0 0px 1000px ${hasError ? colors.red10 : colors.white} inset;
        font-family: ${getFontFamily('brenntagsans')};
    `}
    }    
    
`

export const Textarea = Input.withComponent('textarea')

export const SearchIconWrapper = styled.div`
    position: absolute;
    top: 50%;
    right: 0;
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    pointer-events: none;
    justify-content: center;

    transform: translateY(-50%);
`
interface InputItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    'data-location'?: 'left' | 'right'
}
export const InputItem = styled.button.attrs<InputItemProps>(
    ({ disabled = true, 'data-location': location = 'right' }) => ({
        type: 'button',
        disabled,
        'data-location': location,
    }),
)`
    background-color: ${colors.neutral10};
    color: ${colors.deepPurple};
    font: inherit;
    font-weight: normal;
    border: 1px solid ${colors.neutral30};
    padding: 7px 10px;
    display: flex;
    align-items: center;
    min-height: 40px;
`

const InputIcon: React.SFC<SvgComponentProps> = props => (
    <SearchIconWrapper>
        <SvgIcon size="20" fill={colors.neutral70} {...props} />
    </SearchIconWrapper>
)
export const SearchIcon: React.SFC = () => <InputIcon icon="Search" />

export const EmailIcon: React.SFC = () => <InputIcon icon="At" />

export const PasswordIcon: React.SFC = () => <InputIcon icon="Key" />
