import React from 'react'
import styled from 'styled-components'

import { Text } from '@bc/ui/src/components/text'
import { Transition } from '@bc/ui/src/components/transition'

import { ButtonVariant } from '../button'
import { ToastComponent } from './toast-component'
import { ToastCloseButton } from './toast-close-button'

export type ToastType = 'success' | 'warning' | 'error'

export interface ToastData {
    id: string
    message: string
    button?: JSX.Element
    type?: ToastType
    closeIn?: number
}

const ToasterBody = styled.div`
    width: 100%;
    margin-top: 0px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-basis: content;
    padding-left: 1em;
    flex-basis: 1;
    flex-shrink: 0;
    min-width: auto;
`

const TextWrapper = styled.div`
    display: flex;
    flex-basis: 1;
`

export interface ToastProps {
    reverse?: boolean
    toast: ToastData
    onClose?: () => void
}

export const Toast = ({ toast, onClose, ...restProps }: ToastProps) => {
    if (!toast) {
        return null
    }
    const { type, message, button } = toast

    return (
        <Transition transitionStyle={'fade-up'} {...restProps}>
            <ToastComponent type={type} data-test-id={`toast-${type}`}>
                <ToastCloseButton
                    icon="Close"
                    buttonSize="small"
                    variant={type as ButtonVariant}
                    onClick={onClose}
                    data-test-id="button-close-toast"
                />
                <ToasterBody>
                    <TextWrapper>
                        <Text p noMargin data-test-id="toast-message">
                            {message}
                        </Text>
                    </TextWrapper>
                    {button && (
                        <ButtonWrapper>
                            {React.Children.map(button, child =>
                                React.cloneElement(child, {
                                    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                                        child.props.onClick(e)
                                        if (onClose) {
                                            onClose()
                                        }
                                    },
                                }),
                            )}
                        </ButtonWrapper>
                    )}
                </ToasterBody>
            </ToastComponent>
        </Transition>
    )
}
