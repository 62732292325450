import React from 'react'
import styled from 'styled-components'

import * as icons from './brand'
import { SvgComponentProps, SvgIconSize } from './types'

const Svg = styled.svg`
    position: relative;
    display: inline-block;
`

const SvgIconButtonComponent = styled.button`
    cursor: pointer;
    background: transparent;
    border: 0;
    line-height: 1;
`

// Looks up the correct component in the icons dir
export const SvgIcon: React.SFC<SvgComponentProps> = ({ icon, size = '20', fill, title, width, height, ...rest }) => {
    const Icon = icons[icon]
    const iconExistsInRightSize = Icon?.[size]
    const iconSize: SvgIconSize = iconExistsInRightSize ? size : (Object.keys(Icon)[0] as SvgIconSize)
    const IconPath = Icon[iconSize]
    const svgWidth = width ? width : size
    const svgHeight = height ? height : size
    const viewBox = iconExistsInRightSize ? `0 0 ${svgWidth} ${svgHeight}` : `0 0 ${iconSize} ${iconSize}`

    if (IconPath) {
        return (
            <Svg width={svgWidth} height={svgHeight} viewBox={viewBox} {...rest}>
                {title && <title>{title}</title>}
                <IconPath fill={fill} />
            </Svg>
        )
    }

    return null
}

export type SvgComponentButtonProps = React.HTMLAttributes<HTMLButtonElement> & SvgComponentProps

export const SvgIconButton: React.SFC<SvgComponentButtonProps> = ({ onClick, icon, fill, size, type, ...rest }) => (
    <SvgIconButtonComponent onClick={onClick} type="button" {...rest}>
        <SvgIcon
            icon={icon}
            fill={fill}
            size={size}
            css={`
                display: block;
            `}
        />
    </SvgIconButtonComponent>
)
