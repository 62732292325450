import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Inbound: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Inbound" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M18 19.9998V11.9998H20V21.9998H2V11.5898L11 2.58984L15.09 6.66984L13.67 8.08984L11 5.40984L4 12.4098V19.9998H18ZM20.2954 4.29106L11 13.5864V10H9V17H16V15H12.4149L21.7096 5.70528L20.2954 4.29106ZM11 15V14.9927L11.0073 15H11Z"
                fill={fill === 'gradient' ? 'url(#Inbound_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Inbound" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Inbound" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M18 19.9998V11.9998H20V21.9998H2V11.5898L11 2.58984L15.09 6.66984L13.67 8.08984L11 5.40984L4 12.4098V19.9998H18ZM20.2954 4.29106L11 13.5864V10H9V17H16V15H12.4149L21.7096 5.70528L20.2954 4.29106ZM11 15V14.9927L11.0073 15H11Z"
                fill={fill === 'gradient' ? 'url(#Inbound_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Inbound" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Inbound" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M18 19.9998V11.9998H20V21.9998H2V11.5898L11 2.58984L15.09 6.66984L13.67 8.08984L11 5.40984L4 12.4098V19.9998H18ZM20.2954 4.29106L11 13.5864V10H9V17H16V15H12.4149L21.7096 5.70528L20.2954 4.29106ZM11 15V14.9927L11.0073 15H11Z"
                fill={fill === 'gradient' ? 'url(#Inbound_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Inbound" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Inbound" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M18 19.9998V11.9998H20V21.9998H2V11.5898L11 2.58984L15.09 6.66984L13.67 8.08984L11 5.40984L4 12.4098V19.9998H18ZM20.2954 4.29106L11 13.5864V10H9V17H16V15H12.4149L21.7096 5.70528L20.2954 4.29106ZM11 15V14.9927L11.0073 15H11Z"
                fill={fill === 'gradient' ? 'url(#Inbound_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Inbound" />}
        </g>
    ),
}
