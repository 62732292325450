import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Chemistry: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Chemistry" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M2 21H22V16.59L15 9.59V2H13V10.41L20 17.41V19H4V17.41L11 10.41V2H9V9.59L2 16.59V21ZM14.7583 15L16.7583 17H7.23828L9.23828 15H14.7583Z"
                fill={fill === 'gradient' ? 'url(#Chemistry_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Chemistry" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Chemistry" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M2 21H22V16.59L15 9.59V2H13V10.41L20 17.41V19H4V17.41L11 10.41V2H9V9.59L2 16.59V21ZM14.7583 15L16.7583 17H7.23828L9.23828 15H14.7583Z"
                fill={fill === 'gradient' ? 'url(#Chemistry_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Chemistry" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Chemistry" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M2 21H22V16.59L15 9.59V2H13V10.41L20 17.41V19H4V17.41L11 10.41V2H9V9.59L2 16.59V21ZM14.7583 15L16.7583 17H7.23828L9.23828 15H14.7583Z"
                fill={fill === 'gradient' ? 'url(#Chemistry_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Chemistry" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Chemistry" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M2 21H22V16.59L15 9.59V2H13V10.41L20 17.41V19H4V17.41L11 10.41V2H9V9.59L2 16.59V21ZM14.7583 15L16.7583 17H7.23828L9.23828 15H14.7583Z"
                fill={fill === 'gradient' ? 'url(#Chemistry_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Chemistry" />}
        </g>
    ),
}
