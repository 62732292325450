import React from 'react'

import { colors } from '@bc/ui/src/config'
import { SvgIconMap } from '../types'

const defaultFill = colors.neutral90

export const status_confirmed: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-confirmed"
            d="M18.5,7.914l-2.707-2.707l1.414-1.414L18.5,5.086l2.793-2.793l1.414,1.414L18.5,7.914z M20,10h2v8.721
            l-10,3.333L2,18.721V5.612l10,3.334l4-1.334v2.108l-3,1v8.892l7-2.334V10z M11,10.72L4,8.388v8.892l7,2.334V10.72z M12,4.054
            l2,0.667V2.613l-2-0.667L4.663,4.392l3.161,1.054L12,4.054z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-confirmed"
            d="M18.5,7.914l-2.707-2.707l1.414-1.414L18.5,5.086l2.793-2.793l1.414,1.414L18.5,7.914z M20,10h2v8.721
            l-10,3.333L2,18.721V5.612l10,3.334l4-1.334v2.108l-3,1v8.892l7-2.334V10z M11,10.72L4,8.388v8.892l7,2.334V10.72z M12,4.054
            l2,0.667V2.613l-2-0.667L4.663,4.392l3.161,1.054L12,4.054z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-confirmed"
            d="M18.5,7.914l-2.707-2.707l1.414-1.414L18.5,5.086l2.793-2.793l1.414,1.414L18.5,7.914z M20,10h2v8.721
            l-10,3.333L2,18.721V5.612l10,3.334l4-1.334v2.108l-3,1v8.892l7-2.334V10z M11,10.72L4,8.388v8.892l7,2.334V10.72z M12,4.054
            l2,0.667V2.613l-2-0.667L4.663,4.392l3.161,1.054L12,4.054z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-confirmed"
            d="M18.5,7.914l-2.707-2.707l1.414-1.414L18.5,5.086l2.793-2.793l1.414,1.414L18.5,7.914z M20,10h2v8.721
            l-10,3.333L2,18.721V5.612l10,3.334l4-1.334v2.108l-3,1v8.892l7-2.334V10z M11,10.72L4,8.388v8.892l7,2.334V10.72z M12,4.054
            l2,0.667V2.613l-2-0.667L4.663,4.392l3.161,1.054L12,4.054z"
            transform="scale(0.5)"
        />
    ),
}
