export const getFormattedQuantity = (formattedQuantity: string, uom: string): string =>
    [formattedQuantity, uom].join(' ')

export const getFormattedOrderQuantity = (
    packagingName: string,
    formatPieces: string,
    formatQuantity: string,
    uom: string,
    isBulk: boolean | undefined,
): string => {
    const unitString = getFormattedQuantity(formatQuantity, uom)
    return isBulk ? unitString : `${formatPieces} ${packagingName} (${unitString})`
}
