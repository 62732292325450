import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Music: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Music" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M5.5 21.9999C3.02 21.9999 1 19.9799 1 17.4999C1 15.0199 3.02 12.9999 5.5 12.9999H6V14.9999H5.5C4.12 14.9999 3 16.1199 3 17.4999C3 18.8799 4.12 19.9999 5.5 19.9999C6.88 19.9999 8 18.8799 8 17.4999V5.23994L21 1.68994V15.4999C21 17.9799 18.98 19.9999 16.5 19.9999C14.02 19.9999 12 17.9799 12 15.4999C12 13.0199 14.02 10.9999 16.5 10.9999H17V12.9999H16.5C15.12 12.9999 14 14.1199 14 15.4999C14 16.8799 15.12 17.9999 16.5 17.9999C17.88 17.9999 19 16.8799 19 15.4999V4.30994L10 6.75994V17.4999C10 19.9799 7.98 21.9999 5.5 21.9999Z"
                fill={fill === 'gradient' ? 'url(#Music_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Music" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Music" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M5.5 21.9999C3.02 21.9999 1 19.9799 1 17.4999C1 15.0199 3.02 12.9999 5.5 12.9999H6V14.9999H5.5C4.12 14.9999 3 16.1199 3 17.4999C3 18.8799 4.12 19.9999 5.5 19.9999C6.88 19.9999 8 18.8799 8 17.4999V5.23994L21 1.68994V15.4999C21 17.9799 18.98 19.9999 16.5 19.9999C14.02 19.9999 12 17.9799 12 15.4999C12 13.0199 14.02 10.9999 16.5 10.9999H17V12.9999H16.5C15.12 12.9999 14 14.1199 14 15.4999C14 16.8799 15.12 17.9999 16.5 17.9999C17.88 17.9999 19 16.8799 19 15.4999V4.30994L10 6.75994V17.4999C10 19.9799 7.98 21.9999 5.5 21.9999Z"
                fill={fill === 'gradient' ? 'url(#Music_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Music" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Music" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M5.5 21.9999C3.02 21.9999 1 19.9799 1 17.4999C1 15.0199 3.02 12.9999 5.5 12.9999H6V14.9999H5.5C4.12 14.9999 3 16.1199 3 17.4999C3 18.8799 4.12 19.9999 5.5 19.9999C6.88 19.9999 8 18.8799 8 17.4999V5.23994L21 1.68994V15.4999C21 17.9799 18.98 19.9999 16.5 19.9999C14.02 19.9999 12 17.9799 12 15.4999C12 13.0199 14.02 10.9999 16.5 10.9999H17V12.9999H16.5C15.12 12.9999 14 14.1199 14 15.4999C14 16.8799 15.12 17.9999 16.5 17.9999C17.88 17.9999 19 16.8799 19 15.4999V4.30994L10 6.75994V17.4999C10 19.9799 7.98 21.9999 5.5 21.9999Z"
                fill={fill === 'gradient' ? 'url(#Music_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Music" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Music" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M5.5 21.9999C3.02 21.9999 1 19.9799 1 17.4999C1 15.0199 3.02 12.9999 5.5 12.9999H6V14.9999H5.5C4.12 14.9999 3 16.1199 3 17.4999C3 18.8799 4.12 19.9999 5.5 19.9999C6.88 19.9999 8 18.8799 8 17.4999V5.23994L21 1.68994V15.4999C21 17.9799 18.98 19.9999 16.5 19.9999C14.02 19.9999 12 17.9799 12 15.4999C12 13.0199 14.02 10.9999 16.5 10.9999H17V12.9999H16.5C15.12 12.9999 14 14.1199 14 15.4999C14 16.8799 15.12 17.9999 16.5 17.9999C17.88 17.9999 19 16.8799 19 15.4999V4.30994L10 6.75994V17.4999C10 19.9799 7.98 21.9999 5.5 21.9999Z"
                fill={fill === 'gradient' ? 'url(#Music_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Music" />}
        </g>
    ),
}
