import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Barcode: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Barcode" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M4 5H2V19H4V5ZM7 5H6V19H7V5ZM20 5H22V19H20V5ZM15 5H9V19H15V5ZM17 5H18V19H17V5Z"
                fill={fill === 'gradient' ? 'url(#Barcode_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Barcode" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Barcode" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M4 5H2V19H4V5ZM7 5H6V19H7V5ZM20 5H22V19H20V5ZM15 5H9V19H15V5ZM17 5H18V19H17V5Z"
                fill={fill === 'gradient' ? 'url(#Barcode_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Barcode" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Barcode" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M4 5H2V19H4V5ZM7 5H6V19H7V5ZM20 5H22V19H20V5ZM15 5H9V19H15V5ZM17 5H18V19H17V5Z"
                fill={fill === 'gradient' ? 'url(#Barcode_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Barcode" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Barcode" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M4 5H2V19H4V5ZM7 5H6V19H7V5ZM20 5H22V19H20V5ZM15 5H9V19H15V5ZM17 5H18V19H17V5Z"
                fill={fill === 'gradient' ? 'url(#Barcode_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Barcode" />}
        </g>
    ),
}
