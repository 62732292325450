import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Arrow_Down: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Arrow_Down" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M13 15.76L12 16.76L11 15.76V2H13V15.76ZM3.28998 13.71L12 22.41L20.71 13.71L19.29 12.29L12 19.59L4.70998 12.29L3.28998 13.71Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Down_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Down" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Arrow_Down" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M13 15.76L12 16.76L11 15.76V2H13V15.76ZM3.28998 13.71L12 22.41L20.71 13.71L19.29 12.29L12 19.59L4.70998 12.29L3.28998 13.71Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Down_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Down" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Arrow_Down" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M13 15.76L12 16.76L11 15.76V2H13V15.76ZM3.28998 13.71L12 22.41L20.71 13.71L19.29 12.29L12 19.59L4.70998 12.29L3.28998 13.71Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Down_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Down" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Arrow_Down" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M13 15.76L12 16.76L11 15.76V2H13V15.76ZM3.28998 13.71L12 22.41L20.71 13.71L19.29 12.29L12 19.59L4.70998 12.29L3.28998 13.71Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Down_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Down" />}
        </g>
    ),
}
