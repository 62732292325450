import React from 'react'
import styled from 'styled-components'

import { Column, Container, Row } from '@bc/ui/src/components'

interface Props {
    children: {
        header: React.ReactNode
        content: React.ReactNode
    }
}

const ColumnContainer = styled(Container)`
    overflow: hidden;
`

export const CarbonTemplate = (props: Props) => {
    const { header, content } = props.children
    return (
        <ColumnContainer>
            <Row>
                <Column>
                    {header}
                    {content}
                </Column>
            </Row>
        </ColumnContainer>
    )
}
