import qs from 'query-string'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { useFormatMessage } from '@frontend/components'
import { ErrorPage } from './error-page'

const AuthError = ({ location }: RouteComponentProps<undefined>) => {
    const t = useFormatMessage()
    const { errorDescription } = qs.parse(location.search)

    const expired = errorDescription === 'Access expired.'

    const title = expired ? t('auth-error:expired-link:title') : t('general:something-went-wrong')
    const message = expired ? t('auth-error:expired-link:message') : t('auth-error:message')
    const text = expired ? t('auth-error:link-text') : t('login:back-to-login')
    const href = expired ? t('route:forgot-password') : '/login'

    return <ErrorPage title={title} message={message} url={{ href, text }} />
}

export const AuthErrorPage = withRouter(AuthError)
