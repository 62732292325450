// Colors
export const palette = {
    neutral: {
        10: '#F0EEF1',
        20: '#D6D2DA',
        30: '#BDB3C7',
        40: '#9C8DAA',
        50: '#826F95',
        60: '#5E4C71',
        70: '#554466',
        80: '#3F2F4E',
        90: '#301F41',
    },
    tan: { 10: '#F2EDE7', 20: '#DFD1C3', 30: '#CAB39C' },
    blue: {
        10: '#DCECFF',
        20: '#B4D5FE',
        30: '#84BBFC',
        40: '#4496F8',
        50: '#0C72ED',
        60: '#0651AD',
        70: '#04489B',
        80: '#023574',
        90: '#002757',
    },
    indigo: {
        10: '#F2E5FF',
        20: '#E4C8FF',
        30: '#D1A3FF',
        40: '#BA73FF',
        50: '#A03FFF',
        60: '#7100E2',
        70: '#6600C9',
        80: '#4C0097',
        90: '#3A0073',
    },
    violet: {
        10: '#FCE3FF',
        20: '#F3C2F9',
        30: '#E99BF3',
        40: '#DA64E9',
        50: '#C815E0',
        60: '#920BA3',
        70: '#830892',
        80: '#62046E',
        90: '#4C0056',
    },
    green: {
        10: '#C3F4E4',
        20: '#77E7C2',
        30: '#21CF95',
        40: '#13A977',
        50: '#0A875E',
        60: '#046142',
        70: '#02573A',
        80: '#013F2A',
        90: '#002F1F',
    },
    yellow: {
        10: '#FFE8BA',
        20: '#FFCB64',
        30: '#FAA500',
        40: '#D67D00',
        50: '#B85C00',
        60: '#884000',
        70: '#7B3800',
        80: '#5F2600',
        90: '#481A00',
    },
    red: {
        10: '#FFE4E8',
        20: '#FFC3CD',
        30: '#FF9AAB',
        40: '#FF5A76',
        50: '#EE0028',
        60: '#AD001E',
        70: '#9C001B',
        80: '#740014',
        90: '#58000F',
    },
}

type PaletteColorScheme = keyof typeof palette
type PrimaryColorScheme = 'white' | 'black' | 'deepPurple'
const primaryColors: Readonly<Record<PrimaryColorScheme, string>> = {
    white: '#FFFFFF',
    black: '#000000',
    deepPurple: '#1A0033',
}
// not using Object.entries() because of IE11
const paletteColors: Readonly<Record<string, string>> = Object.keys(palette).reduce(
    (colorz: Record<string, string>, key: PaletteColorScheme) => ({
        ...colorz,
        ...Object.keys(palette[key]).reduce(
            (hue: Record<string, string>, shade: keyof typeof palette[typeof key]) => ({
                ...hue,
                [`${key}${shade}`]: palette[key][shade],
            }),
            {},
        ),
    }),
    {},
)

export const colors: Readonly<Record<PrimaryColorScheme | keyof typeof paletteColors, string>> = {
    ...primaryColors,
    ...paletteColors,
}

export type ColorScheme = PaletteColorScheme | PrimaryColorScheme
export type ColorValue = keyof typeof colors
