import React from 'react'

import { ButtonLink, OxygenTemplate } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'

import { PageError } from './page-error'

interface Props {
    email: string | undefined
}

export const TokenInvalidError = ({ email }: Props) => {
    const t = useFormatMessage()
    return (
        <OxygenTemplate>
            <PageError title={t('general:sorry')} text={t('warning:invalid-token:description')}>
                {email && (
                    <ButtonLink
                        to={`${t('route:reset-password')}?email=${email}`}
                        variant="action"
                        title={t('route:reset-password.label')}>
                        {t('route:reset-password.label')}
                    </ButtonLink>
                )}
            </PageError>
        </OxygenTemplate>
    )
}
