// Breakpoints
export const breakpoints = {
    xs: 320,
    sm: 768,
    md: 1024,
    lg: 1366,
    xl: 1600,
}

export type BreakpointsKey = keyof typeof breakpoints
