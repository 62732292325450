import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Dash: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Dash" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path d="M21 11H3V13H21V11Z" fill={fill === 'gradient' ? 'url(#Dash_gradient)' : fill} />
            {fill === 'gradient' && <Gradient name="Dash" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Dash" fillRule="evenodd" clipRule="evenodd">
            <path d="M21 11H3V13H21V11Z" fill={fill === 'gradient' ? 'url(#Dash_gradient)' : fill} />
            {fill === 'gradient' && <Gradient name="Dash" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Dash" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path d="M21 11H3V13H21V11Z" fill={fill === 'gradient' ? 'url(#Dash_gradient)' : fill} />
            {fill === 'gradient' && <Gradient name="Dash" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Dash" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path d="M21 11H3V13H21V11Z" fill={fill === 'gradient' ? 'url(#Dash_gradient)' : fill} />
            {fill === 'gradient' && <Gradient name="Dash" />}
        </g>
    ),
}
