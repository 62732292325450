import React from 'react'

import { Column, Container, Row } from '@bc/ui/src/components'

interface Props {
    children: {
        header: React.ReactNode
        contentLeft: React.ReactNode
        contentRight: React.ReactNode
    }
}

export const HeliumTemplate = (props: Props) => {
    const { header, contentLeft, contentRight } = props.children
    return (
        <Container>
            {header}
            <Row>
                <Column xs={12} md={3}>
                    {contentLeft}
                </Column>
                <Column xs={12} md={8}>
                    {contentRight}
                </Column>
            </Row>
        </Container>
    )
}
