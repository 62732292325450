import { GQLDocumentType, GQLRequestType } from '@bc/types/types'

import { SalutationType, SettingsRegion } from '../types'
import { CEE_EN_UomMapper, CEE_PL_UomMapper } from '../uom'

import { defaultConfig } from './config.default'

export const ceeConfig: SettingsRegion = {
    ...defaultConfig,
    currency: 'PLN',
    languages: ['pl', 'en'],
    countries: ['pl'],
    safetySheets: ['msds', 'tds'],
    salutation: SalutationType.Informal,
    addWatermarkToInvoices: true,
    deliveryDateRestrictions: {
        reorderDate: {
            amount: 2,
            unit: 'days',
        },
        reorderEndDate: {
            amount: 15,
            unit: 'days',
        },
        startDate: {
            amount: 2,
            unit: 'days',
        },
        endDate: {
            amount: 2,
            unit: 'years',
        },
    },
    welcomeVideos: {
        pl: [
            {
                key: 'FIRSTTIME',
                url: 'https://player.vimeo.com/video/458914987',
            },
            {
                key: 'QUOTES',
                url: 'https://player.vimeo.com/video/458915114',
            },
            {
                key: 'ORDERS',
                url: 'https://player.vimeo.com/video/458915358',
            },
            {
                key: 'INVOICES',
                url: 'https://player.vimeo.com/video/458915539',
            },
            {
                key: 'PRODUCTS',
                url: 'https://player.vimeo.com/video/458915212',
            },
            {
                key: 'REQUESTS',
                url: 'https://player.vimeo.com/video/458914700',
            },
            {
                key: 'FEEDBACK',
                url: 'https://player.vimeo.com/video/458915043',
            },
        ],
    },
    filterRequestTypes: [...defaultConfig.filterRequestTypes, GQLRequestType.SAMPLE, GQLRequestType.QUOTE],
    rfiModalRequestTypes: [...defaultConfig.rfiModalRequestTypes, GQLRequestType.SAMPLE],
    documentTypes: [...defaultConfig.documentTypes, GQLDocumentType.other],
    uomMapper: {
        en: CEE_EN_UomMapper,
        pl: CEE_PL_UomMapper,
    },
    security: {
        disableApi: true,
    },
}
