import styled from 'styled-components'

import { colors, spacing } from '@bc/ui/src/config'

interface InputWrapperProps {
    row?: boolean
    noFlex?: boolean
    noMargin?: boolean
}

export const InputWrapper = styled.div<InputWrapperProps>`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: ${({ row }) => (row ? 'row' : 'column')};
    align-items: ${({ row }) => (row ? 'center' : 'unset')};
    ${({ noFlex }) =>
        noFlex
            ? ``
            : `& > div {
                    flex: 1;
                }
                `}
    ${({ noMargin }) => (noMargin ? `` : `gap: ${spacing.xxs}`)}px;
`

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const HiddenInput = styled.input`
    display: none;
`

export const LabelText = styled.span<{ disabled?: boolean }>`
    padding: 2px 0;
    color: ${({ disabled }) => (disabled ? colors.neutral50 : colors.neutral90)};
    margin-left: 10px;
`

export const ErrorLabel = styled.label`
    color: ${colors.red70};
    margin-top: 5px;
`

export const Row = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 0 0 5px 0;
`

export const RowItem = styled.div`
    flex: 1;
`
