import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Key: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Key" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M2 22H9V20H11V18H13V15.41L13.41 15H20.41L22 13.41V9H20V12.59L19.59 13H12.59L11 14.59V16H9V18H7V20H4V16.41L11 9.41V4.41L11.41 4H19.59L20 4.41V7H22V3.59L20.41 2H10.59L9 3.59V8.59L2 15.59V22ZM18 7C18 7.55228 17.5523 8 17 8C16.4477 8 16 7.55228 16 7C16 6.44772 16.4477 6 17 6C17.5523 6 18 6.44772 18 7Z"
                fill={fill === 'gradient' ? 'url(#Key_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Key" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Key" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M2 22H9V20H11V18H13V15.41L13.41 15H20.41L22 13.41V9H20V12.59L19.59 13H12.59L11 14.59V16H9V18H7V20H4V16.41L11 9.41V4.41L11.41 4H19.59L20 4.41V7H22V3.59L20.41 2H10.59L9 3.59V8.59L2 15.59V22ZM18 7C18 7.55228 17.5523 8 17 8C16.4477 8 16 7.55228 16 7C16 6.44772 16.4477 6 17 6C17.5523 6 18 6.44772 18 7Z"
                fill={fill === 'gradient' ? 'url(#Key_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Key" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Key" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M2 22H9V20H11V18H13V15.41L13.41 15H20.41L22 13.41V9H20V12.59L19.59 13H12.59L11 14.59V16H9V18H7V20H4V16.41L11 9.41V4.41L11.41 4H19.59L20 4.41V7H22V3.59L20.41 2H10.59L9 3.59V8.59L2 15.59V22ZM18 7C18 7.55228 17.5523 8 17 8C16.4477 8 16 7.55228 16 7C16 6.44772 16.4477 6 17 6C17.5523 6 18 6.44772 18 7Z"
                fill={fill === 'gradient' ? 'url(#Key_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Key" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Key" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M2 22H9V20H11V18H13V15.41L13.41 15H20.41L22 13.41V9H20V12.59L19.59 13H12.59L11 14.59V16H9V18H7V20H4V16.41L11 9.41V4.41L11.41 4H19.59L20 4.41V7H22V3.59L20.41 2H10.59L9 3.59V8.59L2 15.59V22ZM18 7C18 7.55228 17.5523 8 17 8C16.4477 8 16 7.55228 16 7C16 6.44772 16.4477 6 17 6C17.5523 6 18 6.44772 18 7Z"
                fill={fill === 'gradient' ? 'url(#Key_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Key" />}
        </g>
    ),
}
