import React from 'react'
import { Redirect } from 'react-router-dom'

export const getRedirectWithState = (pathname: string, state?: any) => (
    <Redirect
        to={{
            pathname,
            state: state ?? { referrer: window.location.pathname + window.location.search },
        }}
    />
)
