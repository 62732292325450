import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Hashtag: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Hashtag" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M21 9.99998V7.99998H17.96L19.94 2.32998L18.05 1.66998L15.83 7.99998H10.95L12.93 2.32998L11.04 1.66998L8.82 7.99998H3V9.99998H8.14L6.74 14H3V16H6.04L4.06 21.67L5.95 22.33L8.17 16H13.05L11.07 21.67L12.96 22.33L15.18 16H21.02V14H15.88L17.28 9.99998H21.02H21ZM13.74 14H8.86L10.26 9.99998H15.14L13.74 14Z"
                fill={fill === 'gradient' ? 'url(#Hashtag_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Hashtag" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Hashtag" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M21 9.99998V7.99998H17.96L19.94 2.32998L18.05 1.66998L15.83 7.99998H10.95L12.93 2.32998L11.04 1.66998L8.82 7.99998H3V9.99998H8.14L6.74 14H3V16H6.04L4.06 21.67L5.95 22.33L8.17 16H13.05L11.07 21.67L12.96 22.33L15.18 16H21.02V14H15.88L17.28 9.99998H21.02H21ZM13.74 14H8.86L10.26 9.99998H15.14L13.74 14Z"
                fill={fill === 'gradient' ? 'url(#Hashtag_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Hashtag" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Hashtag" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M21 9.99998V7.99998H17.96L19.94 2.32998L18.05 1.66998L15.83 7.99998H10.95L12.93 2.32998L11.04 1.66998L8.82 7.99998H3V9.99998H8.14L6.74 14H3V16H6.04L4.06 21.67L5.95 22.33L8.17 16H13.05L11.07 21.67L12.96 22.33L15.18 16H21.02V14H15.88L17.28 9.99998H21.02H21ZM13.74 14H8.86L10.26 9.99998H15.14L13.74 14Z"
                fill={fill === 'gradient' ? 'url(#Hashtag_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Hashtag" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Hashtag" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M21 9.99998V7.99998H17.96L19.94 2.32998L18.05 1.66998L15.83 7.99998H10.95L12.93 2.32998L11.04 1.66998L8.82 7.99998H3V9.99998H8.14L6.74 14H3V16H6.04L4.06 21.67L5.95 22.33L8.17 16H13.05L11.07 21.67L12.96 22.33L15.18 16H21.02V14H15.88L17.28 9.99998H21.02H21ZM13.74 14H8.86L10.26 9.99998H15.14L13.74 14Z"
                fill={fill === 'gradient' ? 'url(#Hashtag_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Hashtag" />}
        </g>
    ),
}
