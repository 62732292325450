import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'

export const List = styled.ol`
    position: relative;

    &:not(:last-child) {
        margin-bottom: 3rem;
    }
`

interface ListItemProps {
    numberColor?: keyof typeof colors
    color?: keyof typeof colors
}

export const ListItem = styled.li<ListItemProps>`
    position: relative;
    display: flex;
    align-items: flex-start;
    list-style: none;
    color: ${({ color = 'neutral90' }) => colors[color]};

    &:before {
        content: '';
        width: 7px;
        height: 7px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        margin-top: 8px;
        background-color: ${({ numberColor = 'deepPurple' }) => colors[numberColor]};
    }

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }
`
