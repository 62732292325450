import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Inbox: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Inbox" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M21 21H3V10H7.62L9.62 14H14.38L16.38 10H19V5H5V8H3V3H21V12H17.62L15.62 16H8.38L6.38 12H5V19H19V14H21V21Z"
                fill={fill === 'gradient' ? 'url(#Inbox_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Inbox" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Inbox" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M21 21H3V10H7.62L9.62 14H14.38L16.38 10H19V5H5V8H3V3H21V12H17.62L15.62 16H8.38L6.38 12H5V19H19V14H21V21Z"
                fill={fill === 'gradient' ? 'url(#Inbox_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Inbox" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Inbox" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M21 21H3V10H7.62L9.62 14H14.38L16.38 10H19V5H5V8H3V3H21V12H17.62L15.62 16H8.38L6.38 12H5V19H19V14H21V21Z"
                fill={fill === 'gradient' ? 'url(#Inbox_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Inbox" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Inbox" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M21 21H3V10H7.62L9.62 14H14.38L16.38 10H19V5H5V8H3V3H21V12H17.62L15.62 16H8.38L6.38 12H5V19H19V14H21V21Z"
                fill={fill === 'gradient' ? 'url(#Inbox_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Inbox" />}
        </g>
    ),
}
