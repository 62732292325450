import styled from 'styled-components'

export interface BoxProps {
    center?: boolean
}

export const Box = styled.div<BoxProps>`
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    display: flex;
    flex-direction: column;
    align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
`
