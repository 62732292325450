import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Globe: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Globe" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M19.74 5.68L18.31 7.1C19.1922 8.22992 19.752 9.57759 19.93 11H16C15.9726 8.77905 15.5598 6.57973 14.78 4.5C15.5432 4.78634 16.2581 5.18764 16.9 5.69L18.32 4.26C16.3332 2.63746 13.7989 1.84045 11.2411 2.03377C8.68322 2.22708 6.29747 3.39594 4.57712 5.29865C2.85676 7.20136 1.93336 9.69242 1.99785 12.2567C2.06235 14.8211 3.1098 17.2626 4.92363 19.0764C6.73745 20.8902 9.17894 21.9377 11.7433 22.0022C14.3076 22.0667 16.7987 21.1433 18.7014 19.4229C20.6041 17.7025 21.7729 15.3168 21.9663 12.759C22.1596 10.2011 21.3626 7.66678 19.74 5.68ZM12 20C11.52 20 10.19 17.6 10 13H14C13.81 17.6 12.48 20 12 20ZM14 11H10C10.17 6.4 11.5 4 12 4C12.5 4 13.81 6.4 14 11ZM9.24003 4.5C8.45343 6.57852 8.03386 8.77787 8.00003 11H4.07003C4.25427 9.54712 4.8338 8.1727 5.74544 7.02653C6.65709 5.88036 7.86586 5.00643 9.24003 4.5ZM4.07003 13H8.00003C8.02746 15.2209 8.44026 17.4203 9.22003 19.5C7.8496 18.9906 6.64498 18.1154 5.73704 16.9694C4.82909 15.8235 4.25254 14.4506 4.07003 13ZM14.76 19.5C15.5466 17.4215 15.9662 15.2221 16 13H19.95C19.7641 14.4552 19.1816 15.8312 18.2662 16.9775C17.3509 18.1239 16.1379 18.9966 14.76 19.5Z"
                fill={fill === 'gradient' ? 'url(#Globe_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Globe" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Globe" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M19.74 5.68L18.31 7.1C19.1922 8.22992 19.752 9.57759 19.93 11H16C15.9726 8.77905 15.5598 6.57973 14.78 4.5C15.5432 4.78634 16.2581 5.18764 16.9 5.69L18.32 4.26C16.3332 2.63746 13.7989 1.84045 11.2411 2.03377C8.68322 2.22708 6.29747 3.39594 4.57712 5.29865C2.85676 7.20136 1.93336 9.69242 1.99785 12.2567C2.06235 14.8211 3.1098 17.2626 4.92363 19.0764C6.73745 20.8902 9.17894 21.9377 11.7433 22.0022C14.3076 22.0667 16.7987 21.1433 18.7014 19.4229C20.6041 17.7025 21.7729 15.3168 21.9663 12.759C22.1596 10.2011 21.3626 7.66678 19.74 5.68ZM12 20C11.52 20 10.19 17.6 10 13H14C13.81 17.6 12.48 20 12 20ZM14 11H10C10.17 6.4 11.5 4 12 4C12.5 4 13.81 6.4 14 11ZM9.24003 4.5C8.45343 6.57852 8.03386 8.77787 8.00003 11H4.07003C4.25427 9.54712 4.8338 8.1727 5.74544 7.02653C6.65709 5.88036 7.86586 5.00643 9.24003 4.5ZM4.07003 13H8.00003C8.02746 15.2209 8.44026 17.4203 9.22003 19.5C7.8496 18.9906 6.64498 18.1154 5.73704 16.9694C4.82909 15.8235 4.25254 14.4506 4.07003 13ZM14.76 19.5C15.5466 17.4215 15.9662 15.2221 16 13H19.95C19.7641 14.4552 19.1816 15.8312 18.2662 16.9775C17.3509 18.1239 16.1379 18.9966 14.76 19.5Z"
                fill={fill === 'gradient' ? 'url(#Globe_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Globe" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Globe" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M19.74 5.68L18.31 7.1C19.1922 8.22992 19.752 9.57759 19.93 11H16C15.9726 8.77905 15.5598 6.57973 14.78 4.5C15.5432 4.78634 16.2581 5.18764 16.9 5.69L18.32 4.26C16.3332 2.63746 13.7989 1.84045 11.2411 2.03377C8.68322 2.22708 6.29747 3.39594 4.57712 5.29865C2.85676 7.20136 1.93336 9.69242 1.99785 12.2567C2.06235 14.8211 3.1098 17.2626 4.92363 19.0764C6.73745 20.8902 9.17894 21.9377 11.7433 22.0022C14.3076 22.0667 16.7987 21.1433 18.7014 19.4229C20.6041 17.7025 21.7729 15.3168 21.9663 12.759C22.1596 10.2011 21.3626 7.66678 19.74 5.68ZM12 20C11.52 20 10.19 17.6 10 13H14C13.81 17.6 12.48 20 12 20ZM14 11H10C10.17 6.4 11.5 4 12 4C12.5 4 13.81 6.4 14 11ZM9.24003 4.5C8.45343 6.57852 8.03386 8.77787 8.00003 11H4.07003C4.25427 9.54712 4.8338 8.1727 5.74544 7.02653C6.65709 5.88036 7.86586 5.00643 9.24003 4.5ZM4.07003 13H8.00003C8.02746 15.2209 8.44026 17.4203 9.22003 19.5C7.8496 18.9906 6.64498 18.1154 5.73704 16.9694C4.82909 15.8235 4.25254 14.4506 4.07003 13ZM14.76 19.5C15.5466 17.4215 15.9662 15.2221 16 13H19.95C19.7641 14.4552 19.1816 15.8312 18.2662 16.9775C17.3509 18.1239 16.1379 18.9966 14.76 19.5Z"
                fill={fill === 'gradient' ? 'url(#Globe_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Globe" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Globe" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M19.74 5.68L18.31 7.1C19.1922 8.22992 19.752 9.57759 19.93 11H16C15.9726 8.77905 15.5598 6.57973 14.78 4.5C15.5432 4.78634 16.2581 5.18764 16.9 5.69L18.32 4.26C16.3332 2.63746 13.7989 1.84045 11.2411 2.03377C8.68322 2.22708 6.29747 3.39594 4.57712 5.29865C2.85676 7.20136 1.93336 9.69242 1.99785 12.2567C2.06235 14.8211 3.1098 17.2626 4.92363 19.0764C6.73745 20.8902 9.17894 21.9377 11.7433 22.0022C14.3076 22.0667 16.7987 21.1433 18.7014 19.4229C20.6041 17.7025 21.7729 15.3168 21.9663 12.759C22.1596 10.2011 21.3626 7.66678 19.74 5.68ZM12 20C11.52 20 10.19 17.6 10 13H14C13.81 17.6 12.48 20 12 20ZM14 11H10C10.17 6.4 11.5 4 12 4C12.5 4 13.81 6.4 14 11ZM9.24003 4.5C8.45343 6.57852 8.03386 8.77787 8.00003 11H4.07003C4.25427 9.54712 4.8338 8.1727 5.74544 7.02653C6.65709 5.88036 7.86586 5.00643 9.24003 4.5ZM4.07003 13H8.00003C8.02746 15.2209 8.44026 17.4203 9.22003 19.5C7.8496 18.9906 6.64498 18.1154 5.73704 16.9694C4.82909 15.8235 4.25254 14.4506 4.07003 13ZM14.76 19.5C15.5466 17.4215 15.9662 15.2221 16 13H19.95C19.7641 14.4552 19.1816 15.8312 18.2662 16.9775C17.3509 18.1239 16.1379 18.9966 14.76 19.5Z"
                fill={fill === 'gradient' ? 'url(#Globe_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Globe" />}
        </g>
    ),
}
