import React from 'react'
import styled, { keyframes } from 'styled-components'

import { colors, ColorScheme } from '@bc/ui/src/config'

interface LoaderRoundProps {
    colorScheme?: ColorScheme
    thickness?: number
    size?: 'small' | 'medium' | 'large' | 'x-large'
}
const loaderSizes = {
    small: 24,
    medium: 48,
    large: 96,
    'x-large': 160,
}

const rotation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`
const LoaderSvg = styled.svg`
    animation: ${rotation} linear 1s infinite;
    transform-origin: center;
`

export const LoaderRound: React.FC<LoaderRoundProps> = ({ colorScheme, size = 'small', thickness: t = 3 }) => {
    const s = loaderSizes[size]
    const colorStart = colorScheme ? colors[`${colorScheme}80`] : colors.violet50
    const colorStop = colorScheme ? colors[`${colorScheme}80`] : colors.blue50
    const colorBack = colorScheme ? colors[`${colorScheme}20`] : colors.neutral20

    return (
        <LoaderSvg width={s} height={s} viewBox={`0 0 ${s} ${s}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x={t} y={t} width={s - 2 * t} height={s - 2 * t} rx={s / 2 - t} stroke={colorBack} strokeWidth={t} />
            <path
                d={`M${s / 2} ${t} A${s / 2 - t} ${s / 2 - t} 0 1 1 ${t} ${s / 2}`}
                stroke={colorScheme ? colorStart : 'url(#linear_gradient_loader)'}
                strokeWidth={t}
            />
            {!colorScheme && (
                <defs>
                    <linearGradient
                        id="linear_gradient_loader"
                        gradientTransform={`rotate(-45 ${s / 2} ${s / 2})`}
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor={colorStart} />
                        <stop offset="1" stopColor={colorStop} />
                    </linearGradient>
                </defs>
            )}
        </LoaderSvg>
    )
}
