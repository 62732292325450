import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Search: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Search" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M14.88 6.52999L16.31 5.09999C17.37 6.45999 18 8.14999 18 9.99999C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 9.99999C2 5.58999 5.59 1.99999 9.99 1.98999C11.84 1.98999 13.53 2.62999 14.89 3.67999L13.46 5.10999C12.48 4.41999 11.28 3.99999 9.99 3.99999C6.68 3.99999 3.99 6.68999 3.99 9.99999C3.99 13.31 6.68 16 9.99 16C13.3 16 15.99 13.31 15.99 9.99999C15.99 8.70999 15.58 7.50999 14.88 6.52999ZM16.33 17.73C16.84 17.31 17.32 16.84 17.74 16.32L21.71 20.29L20.3 21.7L16.33 17.73Z"
                fill={fill === 'gradient' ? 'url(#Search_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Search" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Search" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M14.88 6.52999L16.31 5.09999C17.37 6.45999 18 8.14999 18 9.99999C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 9.99999C2 5.58999 5.59 1.99999 9.99 1.98999C11.84 1.98999 13.53 2.62999 14.89 3.67999L13.46 5.10999C12.48 4.41999 11.28 3.99999 9.99 3.99999C6.68 3.99999 3.99 6.68999 3.99 9.99999C3.99 13.31 6.68 16 9.99 16C13.3 16 15.99 13.31 15.99 9.99999C15.99 8.70999 15.58 7.50999 14.88 6.52999ZM16.33 17.73C16.84 17.31 17.32 16.84 17.74 16.32L21.71 20.29L20.3 21.7L16.33 17.73Z"
                fill={fill === 'gradient' ? 'url(#Search_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Search" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Search" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M14.88 6.52999L16.31 5.09999C17.37 6.45999 18 8.14999 18 9.99999C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 9.99999C2 5.58999 5.59 1.99999 9.99 1.98999C11.84 1.98999 13.53 2.62999 14.89 3.67999L13.46 5.10999C12.48 4.41999 11.28 3.99999 9.99 3.99999C6.68 3.99999 3.99 6.68999 3.99 9.99999C3.99 13.31 6.68 16 9.99 16C13.3 16 15.99 13.31 15.99 9.99999C15.99 8.70999 15.58 7.50999 14.88 6.52999ZM16.33 17.73C16.84 17.31 17.32 16.84 17.74 16.32L21.71 20.29L20.3 21.7L16.33 17.73Z"
                fill={fill === 'gradient' ? 'url(#Search_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Search" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Search" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M14.88 6.52999L16.31 5.09999C17.37 6.45999 18 8.14999 18 9.99999C18 14.41 14.41 18 10 18C5.59 18 2 14.41 2 9.99999C2 5.58999 5.59 1.99999 9.99 1.98999C11.84 1.98999 13.53 2.62999 14.89 3.67999L13.46 5.10999C12.48 4.41999 11.28 3.99999 9.99 3.99999C6.68 3.99999 3.99 6.68999 3.99 9.99999C3.99 13.31 6.68 16 9.99 16C13.3 16 15.99 13.31 15.99 9.99999C15.99 8.70999 15.58 7.50999 14.88 6.52999ZM16.33 17.73C16.84 17.31 17.32 16.84 17.74 16.32L21.71 20.29L20.3 21.7L16.33 17.73Z"
                fill={fill === 'gradient' ? 'url(#Search_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Search" />}
        </g>
    ),
}
