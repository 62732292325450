import { ApolloError } from 'apollo-client'
import React, { useContext } from 'react'

import { GQLInvoice, GQLPagination, GQLInvoiceFilters } from '@bc/types'
import { FullWidthTitleNotificationTemplate, MercuryTemplate, Paginator, useDocumentTitle } from '@bc/ui'
import {
    InvoiceCard,
    InvoiceFilters,
    QueryResultList,
    SortSelectWrapper,
    useCopyToClipboard,
    useFormatMessage,
} from '@frontend/components'
import { UserContext, getFilterCount, FiltersContextData, FiltersContext } from '@frontend/context'
import { getLocationOrigin, trimLeadingZeros } from '@frontend/utils'

interface InvoicesPageProps {
    error: ApolloError | undefined
    pagination: GQLPagination
    invoices: GQLInvoice[] | undefined
    onPageClick: (page: number) => void
}

export const InvoicesPage = ({ error, invoices, pagination, onPageClick }: InvoicesPageProps) => {
    const t = useFormatMessage()
    const pageTitle = t('route:invoices.label')
    useDocumentTitle(pageTitle)
    const copyToClipboard = useCopyToClipboard()
    const { activeCustomerId = '', activeSalesOrganisation = '', activeSalesAreaId = '' } = useContext(UserContext)
    const filterContext: FiltersContextData<GQLInvoiceFilters> = useContext(FiltersContext)

    const openInvoice = (uri: string): void => {
        window.open(uri, '_blank')
    }

    const copyInvoiceLink = (invoiceDisplayId: string) => {
        const { location } = window
        const origin = getLocationOrigin(location)
        copyToClipboard(
            `${origin}${location.pathname}?invoiceDisplayId=${trimLeadingZeros(
                invoiceDisplayId,
            )}&cid=${activeCustomerId}&so=${activeSalesOrganisation}&sid=${activeSalesAreaId}`,
        )
    }

    return (
        <MercuryTemplate title={t('general:filters')} filterCount={getFilterCount(filterContext?.query)}>
            {{
                sidebarContent: <InvoiceFilters />,
                content: (
                    <FullWidthTitleNotificationTemplate title={pageTitle}>
                        <SortSelectWrapper />
                        <QueryResultList
                            error={error}
                            amountOfResults={invoices?.length}
                            noResultText={t('not-found:invoices')}>
                            {invoices?.map(invoice => (
                                <InvoiceCard
                                    invoice={invoice}
                                    key={invoice.id}
                                    onOpenInvoice={openInvoice}
                                    onLinkCopy={copyInvoiceLink}
                                />
                            ))}
                        </QueryResultList>
                        {!error && (
                            <Paginator
                                maxPage={pagination.pages}
                                currentPage={pagination.page}
                                onPageClick={onPageClick}
                            />
                        )}
                    </FullWidthTitleNotificationTemplate>
                ),
            }}
        </MercuryTemplate>
    )
}
