import { colors, spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'

export const lightTheme = {
    wrapper: `
        background-color: ${colors.white};
        color: ${colors.neutral90};
    `,
    footer: `
        border-top: 1px ${colors.neutral20} solid;
    `,
}

export const darkTheme = {
    wrapper: `
        background-color: ${colors.neutral20};
        color: ${colors.neutral90};
    `,
}

export const blueTheme = {
    wrapper: `
        background-color: ${colors.neutral10};
    `,
    body: `
        background-color: ${colors.white};
    `,
    header: `
        background-color: ${colors.neutral90};
        color: ${colors.neutral10};
    `,
    footer: `
        background-color: ${colors.neutral90};
        color: ${colors.neutral10};
    `,
}

export const defaultTheme = {
    wrapper: `
        background-color: transparent;
        color: ${colors.neutral90};
    `,
    footer: `
        border-top: 1px ${colors.neutral20} solid;
    `,
}

export const sidebarTheme = {
    wrapper: `
        background-color: ${colors.white};
        margin:0 0 ${spacing.sm}px 0;
        ${media.min('sm')} {
            margin:0 0 ${spacing.xs}px 0;
        }
    `,
}
