import React, { useRef } from 'react'

import { useLazyQuery } from '@apollo/react-hooks'
import { Field, Form } from 'react-final-form'

import { GQLCustomer, GQLInclusion, GQLMainSalesArea } from '@bc/types'
import { Button, Text } from '@bc/ui'
import { FormatMessage } from '@frontend/components'
import { AutoSubmit } from '@frontend/components/auto-submit'
import {
    finalFormValidations,
    NUM_CHAR_START_SEARCH,
    PRIORITIZATION_CUSTOMER_ID,
    PRIORITIZATION_CUSTOMER_NAME,
    prioritizeCustomerOver,
    transformIntoCustomersInput,
} from '@frontend/utils'

import { tagManager } from '@bc/gtm'
import { debounce } from 'debounce'
import { SearchCustomerQuery, SearchCustomerResponse } from '../../graphql/shared/'

import { SearchCustomerResult } from './company-search-result'
import * as SearchComponents from './components'

interface SearchCustomerProps {
    t: FormatMessage
    onSelectCustomer: (customer: GQLCustomer, mainSalesArea: GQLMainSalesArea) => void
}

interface CustomerSearchFormProps {
    customerSearchInput?: string
}

interface CustomerSearchInput {
    customerId?: string
    customerName?: string
    vatId?: string
    inclusion?: GQLInclusion
    [key: string]: any
}

let debounceCustomerSearch: any
const customerSearchLogTagManager = (searchQuery: string) => {
    if (debounceCustomerSearch) {
        debounceCustomerSearch.clear()
    }
    debounceCustomerSearch = debounce(() => tagManager.tags.customerSearch(searchQuery), 1000)
    debounceCustomerSearch()
}

export const SearchCustomer = ({ t, onSelectCustomer }: SearchCustomerProps) => {
    const prioritization = useRef<string>('')
    const [searchCustomer, { data: searchCustomerData, loading, error }] = useLazyQuery<SearchCustomerResponse>(
        SearchCustomerQuery,
        { fetchPolicy: 'network-only' },
    )

    const onSubmit = ({ customerSearchInput = '' }: CustomerSearchFormProps) => {
        const variables: CustomerSearchInput = transformIntoCustomersInput(customerSearchInput)
        const { inclusion, ...tagManagerValues } = variables
        for (const value of Object.values(tagManagerValues)) {
            customerSearchLogTagManager(String(value))
        }

        prioritization.current = variables.customerName ? PRIORITIZATION_CUSTOMER_NAME : PRIORITIZATION_CUSTOMER_ID
        searchCustomer({ variables })
    }

    return (
        <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, values, values: { customerSearchInput }, valid, pristine }) => {
                const searchCustomerDataPrioritized: GQLCustomer[] | undefined =
                    customerSearchInput && customerSearchInput.length >= NUM_CHAR_START_SEARCH
                        ? prioritizeCustomerOver(
                              searchCustomerData?.searchCustomer,
                              customerSearchInput,
                              prioritization.current,
                          )
                        : undefined
                return (
                    <>
                        <SearchComponents.FormCard theme="light">
                            <Text h4 as={'p'} noMargin>
                                {t('my-customers:search-for-customer')}
                            </Text>
                            <Text p>{t('my-customers:use-search-options')}</Text>
                            <SearchComponents.FormWrapper onSubmit={handleSubmit}>
                                <AutoSubmit
                                    submit={() => valid && !pristine && onSubmit(values as CustomerSearchFormProps)}
                                    debounceTime={400}
                                />

                                <Field
                                    name="customerSearchInput"
                                    validate={finalFormValidations.composeValidations(
                                        finalFormValidations.minLength(t, NUM_CHAR_START_SEARCH),
                                        finalFormValidations.validSearchInput(t),
                                    )}
                                    render={({ input, meta }) => (
                                        <SearchComponents.SearchInput
                                            {...input}
                                            type="text"
                                            noMargin
                                            placeholder={t('filters:search-by-customerID-or-name-or-vat.placeholder')}
                                            hasError={meta.touched && meta.error}
                                            errorText={meta.error}
                                            data-test-id="input-search-customer-name-or-id-or-vat"
                                        />
                                    )}
                                />

                                <Button
                                    disabled={pristine || !valid}
                                    isLoading={loading}
                                    variant="solid"
                                    title={t('general:search')}
                                    data-test-id="button-search-submit">
                                    {t('general:search')}
                                </Button>
                            </SearchComponents.FormWrapper>
                        </SearchComponents.FormCard>

                        {!loading && (
                            <SearchCustomerResult
                                data={searchCustomerDataPrioritized}
                                error={error}
                                onSelectCustomer={onSelectCustomer}
                                highlight={customerSearchInput}
                            />
                        )}
                    </>
                )
            }}
        />
    )
}
