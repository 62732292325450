import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const File_CSV: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="File_CSV" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M4 22H20V6.59L15.41 2H13V9H18V20H6V4H11V2H4V22ZM17.59 7H15V4.41L17.59 7ZM8.85828 12.9502C7.84828 12.9502 7.24828 13.7702 7.24828 15.0102H7.23828C7.23828 16.2402 7.83828 17.0602 8.84828 17.0602C9.69828 17.0602 10.2383 16.4602 10.3483 15.4202H9.55828C9.49828 16.1002 9.21828 16.3602 8.84828 16.3602C8.34828 16.3602 8.05828 15.9102 8.05828 15.0102C8.05828 14.1102 8.34828 13.6502 8.84828 13.6502C9.21828 13.6502 9.49828 13.9102 9.55828 14.5702H10.3483C10.2483 13.5402 9.70828 12.9502 8.85828 12.9502ZM10.7617 15.6102H11.5417C11.5717 16.1702 11.8017 16.4302 12.2217 16.4302C12.5817 16.4302 12.7617 16.2502 12.7617 15.9702C12.7617 15.581 12.3914 15.4723 11.9711 15.3489C11.4195 15.187 10.7817 14.9999 10.7817 14.1202C10.7817 13.4002 11.2517 12.9502 12.1017 12.9502C12.9517 12.9502 13.4217 13.4302 13.4317 14.3302H12.6517C12.6317 13.8002 12.4417 13.5802 12.0717 13.5802C11.7417 13.5802 11.5517 13.7302 11.5517 14.0402C11.5517 14.4637 11.9332 14.5739 12.3596 14.697C12.8997 14.8529 13.5117 15.0296 13.5117 15.8902C13.5117 16.6302 13.0617 17.0702 12.2017 17.0702C11.3417 17.0702 10.7917 16.6202 10.7717 15.6202L10.7617 15.6102ZM15.9183 17C16.3283 15.7 16.6583 14.4 16.9583 13H16.1383C15.9483 13.94 15.7283 14.89 15.3583 16.21C14.9783 14.89 14.7583 13.99 14.5683 13H13.7383C14.0183 14.36 14.3583 15.69 14.7683 17H15.9183Z"
                fill={fill === 'gradient' ? 'url(#File_CSV_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_CSV" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="File_CSV" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M4 22H20V6.59L15.41 2H13V9H18V20H6V4H11V2H4V22ZM17.59 7H15V4.41L17.59 7ZM8.85828 12.9502C7.84828 12.9502 7.24828 13.7702 7.24828 15.0102H7.23828C7.23828 16.2402 7.83828 17.0602 8.84828 17.0602C9.69828 17.0602 10.2383 16.4602 10.3483 15.4202H9.55828C9.49828 16.1002 9.21828 16.3602 8.84828 16.3602C8.34828 16.3602 8.05828 15.9102 8.05828 15.0102C8.05828 14.1102 8.34828 13.6502 8.84828 13.6502C9.21828 13.6502 9.49828 13.9102 9.55828 14.5702H10.3483C10.2483 13.5402 9.70828 12.9502 8.85828 12.9502ZM10.7617 15.6102H11.5417C11.5717 16.1702 11.8017 16.4302 12.2217 16.4302C12.5817 16.4302 12.7617 16.2502 12.7617 15.9702C12.7617 15.581 12.3914 15.4723 11.9711 15.3489C11.4195 15.187 10.7817 14.9999 10.7817 14.1202C10.7817 13.4002 11.2517 12.9502 12.1017 12.9502C12.9517 12.9502 13.4217 13.4302 13.4317 14.3302H12.6517C12.6317 13.8002 12.4417 13.5802 12.0717 13.5802C11.7417 13.5802 11.5517 13.7302 11.5517 14.0402C11.5517 14.4637 11.9332 14.5739 12.3596 14.697C12.8997 14.8529 13.5117 15.0296 13.5117 15.8902C13.5117 16.6302 13.0617 17.0702 12.2017 17.0702C11.3417 17.0702 10.7917 16.6202 10.7717 15.6202L10.7617 15.6102ZM15.9183 17C16.3283 15.7 16.6583 14.4 16.9583 13H16.1383C15.9483 13.94 15.7283 14.89 15.3583 16.21C14.9783 14.89 14.7583 13.99 14.5683 13H13.7383C14.0183 14.36 14.3583 15.69 14.7683 17H15.9183Z"
                fill={fill === 'gradient' ? 'url(#File_CSV_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_CSV" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="File_CSV" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M4 22H20V6.59L15.41 2H13V9H18V20H6V4H11V2H4V22ZM17.59 7H15V4.41L17.59 7ZM8.85828 12.9502C7.84828 12.9502 7.24828 13.7702 7.24828 15.0102H7.23828C7.23828 16.2402 7.83828 17.0602 8.84828 17.0602C9.69828 17.0602 10.2383 16.4602 10.3483 15.4202H9.55828C9.49828 16.1002 9.21828 16.3602 8.84828 16.3602C8.34828 16.3602 8.05828 15.9102 8.05828 15.0102C8.05828 14.1102 8.34828 13.6502 8.84828 13.6502C9.21828 13.6502 9.49828 13.9102 9.55828 14.5702H10.3483C10.2483 13.5402 9.70828 12.9502 8.85828 12.9502ZM10.7617 15.6102H11.5417C11.5717 16.1702 11.8017 16.4302 12.2217 16.4302C12.5817 16.4302 12.7617 16.2502 12.7617 15.9702C12.7617 15.581 12.3914 15.4723 11.9711 15.3489C11.4195 15.187 10.7817 14.9999 10.7817 14.1202C10.7817 13.4002 11.2517 12.9502 12.1017 12.9502C12.9517 12.9502 13.4217 13.4302 13.4317 14.3302H12.6517C12.6317 13.8002 12.4417 13.5802 12.0717 13.5802C11.7417 13.5802 11.5517 13.7302 11.5517 14.0402C11.5517 14.4637 11.9332 14.5739 12.3596 14.697C12.8997 14.8529 13.5117 15.0296 13.5117 15.8902C13.5117 16.6302 13.0617 17.0702 12.2017 17.0702C11.3417 17.0702 10.7917 16.6202 10.7717 15.6202L10.7617 15.6102ZM15.9183 17C16.3283 15.7 16.6583 14.4 16.9583 13H16.1383C15.9483 13.94 15.7283 14.89 15.3583 16.21C14.9783 14.89 14.7583 13.99 14.5683 13H13.7383C14.0183 14.36 14.3583 15.69 14.7683 17H15.9183Z"
                fill={fill === 'gradient' ? 'url(#File_CSV_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_CSV" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="File_CSV" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M4 22H20V6.59L15.41 2H13V9H18V20H6V4H11V2H4V22ZM17.59 7H15V4.41L17.59 7ZM8.85828 12.9502C7.84828 12.9502 7.24828 13.7702 7.24828 15.0102H7.23828C7.23828 16.2402 7.83828 17.0602 8.84828 17.0602C9.69828 17.0602 10.2383 16.4602 10.3483 15.4202H9.55828C9.49828 16.1002 9.21828 16.3602 8.84828 16.3602C8.34828 16.3602 8.05828 15.9102 8.05828 15.0102C8.05828 14.1102 8.34828 13.6502 8.84828 13.6502C9.21828 13.6502 9.49828 13.9102 9.55828 14.5702H10.3483C10.2483 13.5402 9.70828 12.9502 8.85828 12.9502ZM10.7617 15.6102H11.5417C11.5717 16.1702 11.8017 16.4302 12.2217 16.4302C12.5817 16.4302 12.7617 16.2502 12.7617 15.9702C12.7617 15.581 12.3914 15.4723 11.9711 15.3489C11.4195 15.187 10.7817 14.9999 10.7817 14.1202C10.7817 13.4002 11.2517 12.9502 12.1017 12.9502C12.9517 12.9502 13.4217 13.4302 13.4317 14.3302H12.6517C12.6317 13.8002 12.4417 13.5802 12.0717 13.5802C11.7417 13.5802 11.5517 13.7302 11.5517 14.0402C11.5517 14.4637 11.9332 14.5739 12.3596 14.697C12.8997 14.8529 13.5117 15.0296 13.5117 15.8902C13.5117 16.6302 13.0617 17.0702 12.2017 17.0702C11.3417 17.0702 10.7917 16.6202 10.7717 15.6202L10.7617 15.6102ZM15.9183 17C16.3283 15.7 16.6583 14.4 16.9583 13H16.1383C15.9483 13.94 15.7283 14.89 15.3583 16.21C14.9783 14.89 14.7583 13.99 14.5683 13H13.7383C14.0183 14.36 14.3583 15.69 14.7683 17H15.9183Z"
                fill={fill === 'gradient' ? 'url(#File_CSV_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_CSV" />}
        </g>
    ),
}
