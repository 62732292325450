import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Filter: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Filter" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M11 22H9V14.41L2 7.41V3H22V7.41L15 14.41V20H13V13.59L20 6.59V5H4V6.59L11 13.59V22Z"
                fill={fill === 'gradient' ? 'url(#Filter_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Filter" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Filter" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M11 22H9V14.41L2 7.41V3H22V7.41L15 14.41V20H13V13.59L20 6.59V5H4V6.59L11 13.59V22Z"
                fill={fill === 'gradient' ? 'url(#Filter_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Filter" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Filter" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M11 22H9V14.41L2 7.41V3H22V7.41L15 14.41V20H13V13.59L20 6.59V5H4V6.59L11 13.59V22Z"
                fill={fill === 'gradient' ? 'url(#Filter_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Filter" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Filter" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M11 22H9V14.41L2 7.41V3H22V7.41L15 14.41V20H13V13.59L20 6.59V5H4V6.59L11 13.59V22Z"
                fill={fill === 'gradient' ? 'url(#Filter_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Filter" />}
        </g>
    ),
}
