import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { colors, defaultFontSpacing } from '@bc/ui/src/config'
import { getFontFamily } from '@bc/ui/src/helpers'

const linkStyles: string = `
    ${defaultFontSpacing}
    color: ${colors.neutral90};
    text-decoration: none;
    font-family: ${getFontFamily('brenntagsans')};
`

export const Link = styled(NavLink)`
    ${linkStyles}
`

export const LinkButton = styled.button`
    ${linkStyles}
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    user-select: none;
    cursor: pointer;
`
