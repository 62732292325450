import React from 'react'
import styled from 'styled-components'

import { GQLCustomer, GQLMainSalesArea } from '@bc/types'
import { colors, getJSXAddress, media, RadioButton, RichRowList, RichRowListItemProps } from '@bc/ui'
import { FormatMessage } from '@frontend/components/hooks'
import { trimLeadingZeros } from '@frontend/utils'

interface Props {
    customer: GQLCustomer
    currentCustomer?: GQLCustomer
    activeSalesAreaId?: string
    t: FormatMessage
    onClick: (c: GQLCustomer, m: GQLMainSalesArea) => void
}

const Wrapper = styled.div`
    padding: 10px 20px 10px 10px;
    ${media.min('sm')} {
        width: 720px;
    }
    color: ${colors.neutral90};
    cursor: pointer;
    &:hover {
        background: ${colors.neutral10};
    }
    label {
        font-weight: bold;
        span {
            padding-top: 3px;
        }
    }
    border-bottom: 1px solid ${colors.neutral20};
`

const MetaWrapper = styled.div`
    margin: 5px 0 5px 34px;
`

export const CustomerLi = ({ activeSalesAreaId, currentCustomer, customer, t, onClick }: Props) => {
    const { id, vatId, name, billTos } = customer

    const ListItem = customer.mainSalesAreas.map(area => {
        const defaultList: RichRowListItemProps[] = [
            {
                label: t('company:selection:label-customer-id'),
                value: trimLeadingZeros(id),
            },
            {
                label: t('company:selection:label-vat'),
                value: vatId || '-',
            },
        ]
        const billToLocation = billTos && billTos[0]
        const address = billToLocation ? getJSXAddress(billToLocation.address) : '-'

        const list: RichRowListItemProps[] = defaultList.concat([
            {
                label: t('account:billing-address'),
                value: address,
            },
            {
                label: t('company:selection:label-country'),
                value: t(`country:${area.salesOrganisation.toLowerCase().substring(0, 2)}`),
            },
        ])

        const isActive = currentCustomer?.id === customer.id && activeSalesAreaId === area.salesAreaId

        return (
            <Wrapper onClick={() => onClick(customer, area)} key={`${customer.id}-${area.salesAreaId}`}>
                <RadioButton
                    id={`radio-${id}`}
                    labelText={name}
                    checked={Boolean(isActive)}
                    readOnly={true}
                    colorScheme="deepPurple"
                />
                <MetaWrapper>
                    <RichRowList list={list} />
                </MetaWrapper>
            </Wrapper>
        )
    })

    return <>{ListItem}</>
}
