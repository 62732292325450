import React, { useState } from 'react'

import graphqlUtils from '@bc/graphql-utils'
import { GQLPrice } from '@bc/types'
import { Button, Text } from '@bc/ui'

import { ChangeQuantityModal, Item, ModalPricesInfo, RemoveFromModal } from '@frontend/components'
import { useFormatMessage, useFormatNumber, useFormatPackaging, useFormatUom } from '@frontend/components/hooks'
import { PackagingTooltip } from '@frontend/components/tooltips'
import { ShoppingCartExportedItem } from '@frontend/stores'

import * as LineItemComponent from '../components'

interface LineItemsProps {
    shoppingCardExportedItems: ShoppingCartExportedItem<any>[]
    priceConditions?: { [key: string]: GQLPrice[] }
    isPriceLoading: boolean
    isPricingShown: boolean
    deliveryDate?: string
}

export const LineItems = ({
    shoppingCardExportedItems,
    priceConditions,
    isPriceLoading = false,
    isPricingShown = false,
    deliveryDate,
}: LineItemsProps) => {
    const formatNumber = useFormatNumber()
    const formatPackaging = useFormatPackaging()
    const t = useFormatMessage()
    const formatUom = useFormatUom()

    const [editModalOpen, setEditModalOpen] = useState(false)
    const [editItem, setEditItem] = useState<Item | undefined>()

    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const [removeItem, setRemoveItem] = useState<Item | undefined>()

    const onEditQuantityModalClose = () => {
        setEditModalOpen(false)
        setEditItem(undefined)
    }

    const onRemoveModalClose = () => {
        setRemoveModalOpen(false)
        setRemoveItem(undefined)
    }

    return (
        <>
            <ChangeQuantityModal item={editItem} isOpen={editModalOpen} onClose={onEditQuantityModalClose} />
            <RemoveFromModal item={removeItem} isOpen={removeModalOpen} onClose={onRemoveModalClose} />
            <LineItemComponent.Wrapper>
                {shoppingCardExportedItems.map(item => {
                    const [
                        id,
                        {
                            material,
                            batch,
                            quantityOrdered: { amount },
                            uom,
                            uomConversionFactor,
                        },
                    ] = item

                    if (!material) {
                        throw new Error('No material is present')
                    }
                    const { name, isBulk } = material
                    const formattedUom = formatUom(uom)
                    const formattedPackagingUom = formatUom(material.packagingQuantity.uom)
                    const pieces = graphqlUtils.material.getPieces(material, amount, uom, uomConversionFactor)
                    const prices = isPricingShown && priceConditions ? priceConditions[material.id] : []

                    return (
                        <LineItemComponent.Content key={id}>
                            {/* Product name */}
                            <LineItemComponent.CardRow>
                                <LineItemComponent.HeaderWrapper>
                                    <Text h5>{name}</Text>
                                </LineItemComponent.HeaderWrapper>
                                <LineItemComponent.ActionButtonsWrapper>
                                    <Button
                                        variant="outline"
                                        icon="Edit"
                                        data-test-id="button-edit-product"
                                        onClick={() => {
                                            setEditItem({ id, material, amount, uom, uomConversionFactor })
                                            setEditModalOpen(true)
                                        }}
                                    />
                                    <Button
                                        variant="error"
                                        icon="Delete"
                                        data-test-id="button-delete-product"
                                        onClick={() => {
                                            setRemoveItem({ id, material, amount, uom, uomConversionFactor })
                                            setRemoveModalOpen(true)
                                        }}
                                    />
                                </LineItemComponent.ActionButtonsWrapper>
                            </LineItemComponent.CardRow>

                            {/* Product stats */}
                            <LineItemComponent.CardRow>
                                <LineItemComponent.Stat>
                                    <Text caption>{t('order:line-item.material-no')}</Text>
                                    {material.id}
                                </LineItemComponent.Stat>
                                {pieces && pieces > 0 ? (
                                    <LineItemComponent.Stat>
                                        <Text caption>
                                            {!isBulk ? t('order:line-item.pieces') : t('order:line-item.amount')}
                                        </Text>
                                        <LineItemComponent.StatIconAlign>
                                            {formatNumber(pieces, {
                                                maximumFractionDigits: 2,
                                            })}{' '}
                                            {!isBulk
                                                ? formatPackaging(pieces, material.packaging?.type)
                                                : formattedPackagingUom}
                                            {material.packaging?.details && (
                                                <PackagingTooltip title={material.packaging.details} />
                                            )}
                                        </LineItemComponent.StatIconAlign>
                                    </LineItemComponent.Stat>
                                ) : null}
                                <LineItemComponent.Stat>
                                    <Text caption>{t('order:line-item.order-quantity')}</Text>
                                    {formatNumber(amount, {
                                        maximumFractionDigits: 2,
                                    })}{' '}
                                    {formattedUom}
                                </LineItemComponent.Stat>
                                {batch && batch !== 'OHNE' && (
                                    <LineItemComponent.Stat>
                                        <Text caption>{t('order:line-item.batch')}</Text>
                                        {batch}
                                    </LineItemComponent.Stat>
                                )}
                                <LineItemComponent.DoubleStat>
                                    <ModalPricesInfo
                                        prices={prices}
                                        packagingQuantity={material!.packagingQuantity}
                                        isLoading={isPriceLoading}
                                        deliveryDate={deliveryDate}
                                        noTerms
                                    />
                                </LineItemComponent.DoubleStat>
                            </LineItemComponent.CardRow>
                        </LineItemComponent.Content>
                    )
                })}
            </LineItemComponent.Wrapper>
        </>
    )
}
