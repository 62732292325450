import { EventTag, VirtualPageviewTag } from '../types'
import { dataLayer, formatSorting } from '../utils'

export const csrRequestsSearchNumber = (filter: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.csrRequestsSearchNumber',
        virtualURL: `/csrrequestssearchnumber?q=${filter}&n=csrrequestssearchnumberbar`,
        virtualTitle: 'csr requests search - search number',
    })
}

export const csrRequestsFilterByProductName = (name: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.csrRequestsSearchProductName',
        virtualURL: `/csrrequestssearchproductname?q=${name}&n=csrrequestssearchproductnamebar`,
        virtualTitle: 'csr requests search - search product name',
    })
}

export const csrRequestsFilter = (filter: string) => {
    dataLayer<EventTag>({
        event: 'gtm.csrFilter',
        eventCategory: 'csr',
        eventAction: 'filter',
        eventLabel: filter,
    })
}

export const csrRequestsSort = (option: string) => {
    dataLayer<EventTag>({
        event: 'gtm.csrSort',
        eventCategory: 'csr',
        eventAction: 'sort',
        eventLabel: formatSorting(option),
    })
}

export const csrRequestsDocumentDownload = (uid: string) => {
    dataLayer<EventTag>({
        event: 'gtm.csrDownload',
        eventCategory: 'csr',
        eventAction: 'download',
        eventLabel: uid,
    })
}

export const csrRequestsDocumentRespond = (uid: string) => {
    dataLayer<EventTag>({
        event: 'gtm.csrRespond',
        eventCategory: 'csr',
        eventAction: 'click respond',
        eventLabel: uid,
    })
}

export const csrRequestsDocumentResponse = (uid: string) => {
    dataLayer<EventTag>({
        event: 'gtm.csrRespond',
        eventCategory: 'csr',
        eventAction: 'submit response',
        eventLabel: uid,
    })
}

export const crsRequestListFilterByCustomerName = (name: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.csrRequestsSearchCustomerName',
        virtualURL: `/csrrequestssearchcustomername?q=${name}&n=csrrequestssearchcustomernamebar`,
        virtualTitle: 'csr requests search - search customer name',
    })
}
