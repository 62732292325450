import styled from 'styled-components'

import { colors, spacing, transition } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: ${spacing.xs}px 0;

    ${media.min('sm')} {
        justify-content: flex-start;
        margin: ${spacing.sm}px 0;
    }
`

interface ButtonProps {
    isActive?: boolean
    disabled?: boolean
    isHidden?: boolean
    prevNextButton?: boolean
}
export const Button = styled.button`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    background: ${({ isActive }: ButtonProps) => (isActive ? colors.deepPurple : colors.white)};
    color: ${({ isActive }: ButtonProps) => (isActive ? colors.white : colors.neutral50)};
    transition: ${transition.config};
    cursor: pointer;
    transition-property: color, background, border-color, opacity;

    cursor: ${({ disabled }: ButtonProps) => (disabled ? 'default' : 'pointer')};
    pointer-events: ${({ disabled }: ButtonProps) => (disabled ? 'none' : 'all')};

    opacity: ${({ isHidden }: ButtonProps) => (isHidden ? 0.5 : 1)};

    &:hover {
        background: ${colors.deepPurple};
        border-color: ${colors.deepPurple};
        color: ${colors.white};
    }

    ${media.max('sm')} {
        display: ${({ prevNextButton }: ButtonProps) => (prevNextButton ? 'none' : 'flex')};
    }
`

export const Separator = styled(Button)`
    &:before {
        content: '...';
    }
    &:hover {
        background: ${colors.white};
        border-color: ${colors.neutral20};
        color: ${colors.neutral50};
        cursor: default;
    }
`
