import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const View: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="View" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M17.5 7.91001L18.91 6.50001L23 10.59V13.42L17.83 18.59C16.27 20.14 14.2 21 12 21C9.8 21 7.73 20.15 6.17 18.59L1 13.42V10.59L6.17 5.42001C7.73 3.87001 9.8 3.01001 12 3.01001C14.04 3.01001 15.97 3.76001 17.48 5.11001L16.07 6.52001C14.94 5.54001 13.51 5.00001 12 5.00001C10.34 5.00001 8.77 5.65001 7.59 6.83001L3 11.42V12.59L7.59 17.17C8.76 18.35 10.34 19 12 19C13.66 19 15.23 18.35 16.41 17.17L21 12.58V11.41L17.5 7.91001ZM15 12C15 11.53 14.89 11.1 14.7 10.71L14.71 10.72L16.18 9.25002C16.7 10.04 17.01 10.98 17.01 12C17.01 14.76 14.77 17 12.01 17C9.25001 17 7.01001 14.76 7.01001 12C7.01001 9.24002 9.25001 7.00002 12.01 7.00002C13.02 7.00002 13.97 7.31002 14.76 7.83002L13.29 9.30002C12.89 9.11002 12.46 9.00002 12 9.00002C10.35 9.00002 9.00001 10.35 9.00001 12C9.00001 13.65 10.35 15 12 15C13.65 15 15 13.65 15 12Z"
                fill={fill === 'gradient' ? 'url(#View_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="View" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="View" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M17.5 7.91001L18.91 6.50001L23 10.59V13.42L17.83 18.59C16.27 20.14 14.2 21 12 21C9.8 21 7.73 20.15 6.17 18.59L1 13.42V10.59L6.17 5.42001C7.73 3.87001 9.8 3.01001 12 3.01001C14.04 3.01001 15.97 3.76001 17.48 5.11001L16.07 6.52001C14.94 5.54001 13.51 5.00001 12 5.00001C10.34 5.00001 8.77 5.65001 7.59 6.83001L3 11.42V12.59L7.59 17.17C8.76 18.35 10.34 19 12 19C13.66 19 15.23 18.35 16.41 17.17L21 12.58V11.41L17.5 7.91001ZM15 12C15 11.53 14.89 11.1 14.7 10.71L14.71 10.72L16.18 9.25002C16.7 10.04 17.01 10.98 17.01 12C17.01 14.76 14.77 17 12.01 17C9.25001 17 7.01001 14.76 7.01001 12C7.01001 9.24002 9.25001 7.00002 12.01 7.00002C13.02 7.00002 13.97 7.31002 14.76 7.83002L13.29 9.30002C12.89 9.11002 12.46 9.00002 12 9.00002C10.35 9.00002 9.00001 10.35 9.00001 12C9.00001 13.65 10.35 15 12 15C13.65 15 15 13.65 15 12Z"
                fill={fill === 'gradient' ? 'url(#View_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="View" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="View" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M17.5 7.91001L18.91 6.50001L23 10.59V13.42L17.83 18.59C16.27 20.14 14.2 21 12 21C9.8 21 7.73 20.15 6.17 18.59L1 13.42V10.59L6.17 5.42001C7.73 3.87001 9.8 3.01001 12 3.01001C14.04 3.01001 15.97 3.76001 17.48 5.11001L16.07 6.52001C14.94 5.54001 13.51 5.00001 12 5.00001C10.34 5.00001 8.77 5.65001 7.59 6.83001L3 11.42V12.59L7.59 17.17C8.76 18.35 10.34 19 12 19C13.66 19 15.23 18.35 16.41 17.17L21 12.58V11.41L17.5 7.91001ZM15 12C15 11.53 14.89 11.1 14.7 10.71L14.71 10.72L16.18 9.25002C16.7 10.04 17.01 10.98 17.01 12C17.01 14.76 14.77 17 12.01 17C9.25001 17 7.01001 14.76 7.01001 12C7.01001 9.24002 9.25001 7.00002 12.01 7.00002C13.02 7.00002 13.97 7.31002 14.76 7.83002L13.29 9.30002C12.89 9.11002 12.46 9.00002 12 9.00002C10.35 9.00002 9.00001 10.35 9.00001 12C9.00001 13.65 10.35 15 12 15C13.65 15 15 13.65 15 12Z"
                fill={fill === 'gradient' ? 'url(#View_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="View" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="View" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M17.5 7.91001L18.91 6.50001L23 10.59V13.42L17.83 18.59C16.27 20.14 14.2 21 12 21C9.8 21 7.73 20.15 6.17 18.59L1 13.42V10.59L6.17 5.42001C7.73 3.87001 9.8 3.01001 12 3.01001C14.04 3.01001 15.97 3.76001 17.48 5.11001L16.07 6.52001C14.94 5.54001 13.51 5.00001 12 5.00001C10.34 5.00001 8.77 5.65001 7.59 6.83001L3 11.42V12.59L7.59 17.17C8.76 18.35 10.34 19 12 19C13.66 19 15.23 18.35 16.41 17.17L21 12.58V11.41L17.5 7.91001ZM15 12C15 11.53 14.89 11.1 14.7 10.71L14.71 10.72L16.18 9.25002C16.7 10.04 17.01 10.98 17.01 12C17.01 14.76 14.77 17 12.01 17C9.25001 17 7.01001 14.76 7.01001 12C7.01001 9.24002 9.25001 7.00002 12.01 7.00002C13.02 7.00002 13.97 7.31002 14.76 7.83002L13.29 9.30002C12.89 9.11002 12.46 9.00002 12 9.00002C10.35 9.00002 9.00001 10.35 9.00001 12C9.00001 13.65 10.35 15 12 15C13.65 15 15 13.65 15 12Z"
                fill={fill === 'gradient' ? 'url(#View_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="View" />}
        </g>
    ),
}
