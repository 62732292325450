import { spacing } from '@bc/ui'

import styled from 'styled-components'

import { FormWrapper } from '../_shared'

export const Box = styled.div`
    margin-bottom: ${spacing.sm}px;
`

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${spacing.sm}px 0px;
`

export const DeliveryFormWrapper = styled(FormWrapper)`
    margin-top: 0;
`
