import { config } from '@bc/config'

import { customLocalesCasts, defaultLocale } from '../config'
import { ExtractedLocale, Language, Locale, RegionCode } from '../types'

/**
 * en-de -> { language: 'en', region: 'de' }
 */
export function extractLocale(key: string | Locale = defaultLocale): ExtractedLocale {
    const [language, region] = (key as string).toLowerCase().split('-')

    return {
        language: language as Language,
        region: region as RegionCode,
    }
}

/**
 * en-de -> en-DE
 * fr-ca -> fr-CA
 */
export const getLocaleWithRegionCodeUppercase = (locale: Locale): string => {
    const { region, language } = extractLocale(locale)
    return `${language}-${region.toUpperCase()}`
}

/**
 * en-de -> true
 * en-DE -> false
 * en-adsfdasd -> false
 */
export const isValidLocale = (locale: Locale) => {
    const { language, region } = extractLocale(locale)
    return config[region] && config[region].languages.indexOf(language) > -1
}

/**
 * en-gb -> en-gb
 * en-de -> en-gb
 */
export const getLocaleIso = (locale: Locale): string => customLocalesCasts[locale] || locale

/**
 * https://brenntagconnect.com/en-de/foo/bar -> en-de
 */
export const localeFromUrl = (url: string = ''): Locale =>
    url
        .split('/')
        .reduce<Locale>((_, chunk: string) => (isValidLocale(chunk as Locale) ? (chunk as Locale) : _), defaultLocale)

export const getDefaultLocaleForLanguage = (language: Language): Locale | undefined => {
    switch (language) {
        case 'nl':
            return 'nl-nl'
        case 'fr':
            return 'fr-be'
        case 'de':
            return 'de-de'
        case 'en':
            return 'en-de'
        case 'pl':
            return 'pl-pl'
        default:
            return undefined
    }
}
