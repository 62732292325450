import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Store: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Store" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M22 12V5.59L18.41 2H15.59L20 6.41V10H18V7H16V10H13V7H11V10H8V7H6V10H4V6.41L6.41 4H14.76L12.76 2H5.59L2 5.59V12H3V21H5V12H9V21H21V14H19V19H11V12H22Z"
                fill={fill === 'gradient' ? 'url(#Store_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Store" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Store" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M22 12V5.59L18.41 2H15.59L20 6.41V10H18V7H16V10H13V7H11V10H8V7H6V10H4V6.41L6.41 4H14.76L12.76 2H5.59L2 5.59V12H3V21H5V12H9V21H21V14H19V19H11V12H22Z"
                fill={fill === 'gradient' ? 'url(#Store_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Store" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Store" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M22 12V5.59L18.41 2H15.59L20 6.41V10H18V7H16V10H13V7H11V10H8V7H6V10H4V6.41L6.41 4H14.76L12.76 2H5.59L2 5.59V12H3V21H5V12H9V21H21V14H19V19H11V12H22Z"
                fill={fill === 'gradient' ? 'url(#Store_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Store" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Store" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M22 12V5.59L18.41 2H15.59L20 6.41V10H18V7H16V10H13V7H11V10H8V7H6V10H4V6.41L6.41 4H14.76L12.76 2H5.59L2 5.59V12H3V21H5V12H9V21H21V14H19V19H11V12H22Z"
                fill={fill === 'gradient' ? 'url(#Store_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Store" />}
        </g>
    ),
}
