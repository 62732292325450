import { GQLMaterial } from '@bc/types'
import { EventTag } from '../types'
import { dataLayer } from '../utils'

export const productRequestInformationStart = (material: GQLMaterial) => {
    dataLayer<EventTag>({
        event: 'gtm.requestInfo',
        eventCategory: 'request info',
        eventAction: 'step 1',
        eventLabel: material.name,
    })
}

interface RequestInformationFormValues {
    requirements?: string[]
    documentTypes?: string[]
    application?: string
}

export const documentNotFound = (type: string, documentId: string) => {
    dataLayer<EventTag>({
        event: 'gtm.download',
        eventCategory: 'download',
        eventAction: type,
        eventLabel: `not found: ${documentId}`,
    })
}

export const productRequestInformationStepSubmit = (
    material: GQLMaterial,
    { requirements, documentTypes, application }: RequestInformationFormValues,
    step: 'step 2' | 'submit',
) => {
    dataLayer<EventTag>({
        event: 'gtm.requestInfo',
        eventCategory: 'request info',
        eventAction: step,
        eventLabel: material.name,
        dimension4: getRequirementsAndDocumentTypes(requirements, documentTypes),
        dimension5: application,
    })
}

const getRequirementsAndDocumentTypes = (requirements: string[] | undefined, documentTypes: string[] | undefined) => {
    if (!requirements) {
        return ''
    }

    return requirements
        .map(requirement => {
            switch (requirement) {
                case 'documents':
                    return documentTypes ? documentTypes.map(type => `documents - ${type}`) : requirement
                default:
                    return requirement
            }
        })
        .reduce<string[]>((a, b) => a.concat(b), [])
        .join('/')
}

export const productRequestInformationAbandon = (material: GQLMaterial) => {
    dataLayer<EventTag>({
        event: 'gtm.requestInfo',
        eventCategory: 'request info',
        eventAction: 'abandon',
        eventLabel: material.name || '-',
    })
}

export const productRequestInformationBackButton = (material: GQLMaterial) => {
    dataLayer<EventTag>({
        event: 'gtm.requestInfo',
        eventCategory: 'request info',
        eventAction: 'back to step 1',
        eventLabel: material.name,
    })
}
