import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Rocket: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Rocket" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M9.59 8L15.59 2H22V8.41L16 14.41V20.62L11.24 23H9V19.24L11 17.24V20.88L14 19.38V13.59L20 7.59V4H16.41L10.41 10H4.62L3.12 13H6.76L4.76 15H1V12.76L3.38 8H9.59ZM4.29688 18.2881L10.3002 12.2847L11.7144 13.699L5.71109 19.7023L4.29688 18.2881Z"
                fill={fill === 'gradient' ? 'url(#Rocket_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Rocket" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Rocket" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M9.59 8L15.59 2H22V8.41L16 14.41V20.62L11.24 23H9V19.24L11 17.24V20.88L14 19.38V13.59L20 7.59V4H16.41L10.41 10H4.62L3.12 13H6.76L4.76 15H1V12.76L3.38 8H9.59ZM4.29688 18.2881L10.3002 12.2847L11.7144 13.699L5.71109 19.7023L4.29688 18.2881Z"
                fill={fill === 'gradient' ? 'url(#Rocket_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Rocket" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Rocket" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M9.59 8L15.59 2H22V8.41L16 14.41V20.62L11.24 23H9V19.24L11 17.24V20.88L14 19.38V13.59L20 7.59V4H16.41L10.41 10H4.62L3.12 13H6.76L4.76 15H1V12.76L3.38 8H9.59ZM4.29688 18.2881L10.3002 12.2847L11.7144 13.699L5.71109 19.7023L4.29688 18.2881Z"
                fill={fill === 'gradient' ? 'url(#Rocket_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Rocket" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Rocket" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M9.59 8L15.59 2H22V8.41L16 14.41V20.62L11.24 23H9V19.24L11 17.24V20.88L14 19.38V13.59L20 7.59V4H16.41L10.41 10H4.62L3.12 13H6.76L4.76 15H1V12.76L3.38 8H9.59ZM4.29688 18.2881L10.3002 12.2847L11.7144 13.699L5.71109 19.7023L4.29688 18.2881Z"
                fill={fill === 'gradient' ? 'url(#Rocket_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Rocket" />}
        </g>
    ),
}
