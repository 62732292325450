import React from 'react'

import { colors } from '@bc/ui/src/config'

import { SvgIconMap } from '../types'

const defaultFill = colors.neutral50

export const status_sample: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-sample"
            d="M22,16.586V21H2v-4.414l7-7V2h2v8.414l-7,7V19h16v-1.586l-7-7V2h2v7.586L22,16.586z M7.242,17h9.515l-2-2H9.242
	        L7.242,17z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-sample"
            d="M22,16.586V21H2v-4.414l7-7V2h2v8.414l-7,7V19h16v-1.586l-7-7V2h2v7.586L22,16.586z M7.242,17h9.515l-2-2H9.242
	        L7.242,17z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-sample"
            d="M22,16.586V21H2v-4.414l7-7V2h2v8.414l-7,7V19h16v-1.586l-7-7V2h2v7.586L22,16.586z M7.242,17h9.515l-2-2H9.242
	        L7.242,17z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-sample"
            d="M22,16.586V21H2v-4.414l7-7V2h2v8.414l-7,7V19h16v-1.586l-7-7V2h2v7.586L22,16.586z M7.242,17h9.515l-2-2H9.242
	        L7.242,17z"
            transform="scale(0.5)"
        />
    ),
}
