import { GQLPriceConditionsList } from '@bc/types'

export interface OverviewPageState {
    shipToId?: string
    deliveryDate?: string
    compliance?: Map<string, boolean>
    priceConditionsList?: GQLPriceConditionsList[]
    priceLoading: boolean
    isPricingShown: boolean
}

export enum OverviewPageActionTypes {
    DISPLAY_PRICE_SET,
    PRICE_CONDITIONS_LOAD,
    PRICE_CONDITIONS_SET,
    SHIPPING_SET,
}

type Action =
    | { type: OverviewPageActionTypes.DISPLAY_PRICE_SET; payload: boolean }
    | { type: OverviewPageActionTypes.PRICE_CONDITIONS_LOAD }
    | { type: OverviewPageActionTypes.PRICE_CONDITIONS_SET; payload: GQLPriceConditionsList[] }
    | { type: OverviewPageActionTypes.SHIPPING_SET; payload: { deliveryDate?: string; shipToId?: string } }

export const createDisplayPriceSetAction = (payload: boolean): Action => ({
    type: OverviewPageActionTypes.DISPLAY_PRICE_SET,
    payload,
})

export const createPriceConditionsLoadAction = (): Action => ({
    type: OverviewPageActionTypes.PRICE_CONDITIONS_LOAD,
})

export const createPriceConditionsSetAction = (payload: GQLPriceConditionsList[]): Action => ({
    type: OverviewPageActionTypes.PRICE_CONDITIONS_SET,
    payload,
})

export const createShippingSetAction = (payload: { deliveryDate?: string; shipToId?: string }): Action => ({
    type: OverviewPageActionTypes.SHIPPING_SET,
    payload,
})

export const overviewPageReducer = (state: OverviewPageState, action: Action): OverviewPageState => {
    switch (action.type) {
        case OverviewPageActionTypes.DISPLAY_PRICE_SET: {
            return { ...state, isPricingShown: action.payload }
        }
        case OverviewPageActionTypes.PRICE_CONDITIONS_LOAD: {
            return { ...state, priceConditionsList: undefined, priceLoading: true }
        }
        case OverviewPageActionTypes.PRICE_CONDITIONS_SET: {
            return { ...state, priceConditionsList: action.payload, priceLoading: false }
        }
        case OverviewPageActionTypes.SHIPPING_SET: {
            return { ...state, ...action.payload }
        }
        default:
            return state
    }
}
