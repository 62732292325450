import { Locale, locales, MessageMap, translations } from '@bc/translations'

export type Routes = (keyof MessageMap)[]

type TranslatedRoutes = { [key in Locale]: string[] }

export const routes: Routes = [
    'route:maintenance',
    'route:terms-of-usage',
    'route:document-not-found',
    'route:session-expired',
    'route:logout',
    'route:forgot-password',
    'route:order-history',
    'route:product-list',
    'route:account',
    'route:my-customers',
    'route:contacts',
    'route:requests',
    'route:reset-password',
    'route:set-password',
    'route:invoices',
    'route:quotes',
    'route:cart-overview',
]

export const translatedRoutes = locales.reduce<TranslatedRoutes>(
    (routesMap: TranslatedRoutes, locale: Locale) => ({
        ...routesMap,
        [locale]: routes.map(key => translations[locale][key]),
    }),
    // tslint:disable-next-line:no-object-literal-type-assertion
    {} as TranslatedRoutes,
)

export const translatedRoutesList = locales.reduce(
    (routesList: string[], locale: Locale) => [...routesList, ...translatedRoutes[locale]],
    [],
)

export const getLocalizedPath = (routeKey: keyof MessageMap, locale: Locale) => translations[locale][routeKey]
