export enum RegionFeatures {
    Requests = 'Requests',
}

export type RegionFeatureDictionary = { [key in RegionFeatures]: boolean }

export enum ShopFeatures {
    Quotes = 'Quotes',
    Invoices = 'Invoices',
    UseTwoLineOrderlines = 'UseTwoLineOrderlines',
    OrderXMLAttachment = 'OrderXMLAttachment',
    DirectXMLOrder = 'DirectXMLOrder',
    DownloadOrderDocument = 'DownloadOrderDocument',
    ShoppingCart = 'ShoppingCart',
    SortIndustryApplications = 'SortIndustryApplications',
    RequiredIndustryApplications = 'RequiredIndustryApplications',
    OrderPickup = 'OrderPickup',
    NextDayDelivery = 'NextDayDelivery',
    TermsPerDivision = 'TermsPerDivision',
}

export type PlatformFeatures = RegionFeatures | ShopFeatures
export type ShopFeatureDictionary = { [key in ShopFeatures]: boolean }
