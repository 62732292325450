import React, { useState, useMemo } from 'react'
import { Props as ReactSelectProps, Styles, components, IndicatorProps, OptionsType } from 'react-select'
import { ContainerState } from 'react-select/src/components/containers'

import { colors, transition } from '@bc/ui/src/config'

import { SvgIcon } from '../svg'
import { FormInputComponents, SelectComponents } from './components'

export interface SelectInputOption {
    value: string
    label: string
}

export type OnSelectChangeFn = (sort: SelectInputOption) => void

export interface SelectInputProps {
    // export interface SelectInputProps extends ReactSelectProps {
    labelText?: React.ElementType | JSX.Element | string
    errorText?: React.ElementType | JSX.Element | string
    hasError?: boolean
    row?: boolean
    noOptionsMessage?: () => JSX.Element | string
}

const DropdownIndicator = (props: IndicatorProps<any> & ContainerState) => {
    const fill = props.isDisabled ? colors.neutral30 : colors.neutral70
    return (
        <components.DropdownIndicator {...props}>
            <SvgIcon icon="Chevron_Down" size="20" fill={fill} />
        </components.DropdownIndicator>
    )
}

const ClearIndicator = (props: IndicatorProps<any> & ContainerState) => {
    const fill = props.isDisabled ? colors.neutral30 : colors.neutral70
    return (
        <components.ClearIndicator {...props}>
            <SvgIcon icon="Close" size="20" fill={fill} />
        </components.ClearIndicator>
    )
}
/* Select state looks like this ->
state: 
    children: {$$typeof: Symbol(react.element), type: {…}, key: null, ref: null, props: {…}, …}
    clearValue: ƒ ()
    cx: ƒ ()
    data: {value: '3', label: 'string option'}
    getStyles: ƒ (key, props)
    getValue: ƒ getValue()
    hasValue: false
    innerProps: {id: 'react-select-2-option-3', tabIndex: -1, onClick: ƒ, onMouseMove: ƒ, onMouseOver: ƒ}
    innerRef: undefined
    isDisabled: false
    isFocused: false
    isMulti: false
    isRtl: false
    isSelected: false
    label: "string option"
    options: (4) [{…}, {…}, {…}, {…}]
    selectOption: ƒ (newValue)
    selectProps: {styles: {…}, classNamePrefix: 'user-card-admin-actions', isSearchable: false, menuPlacement: 'top', onChange: ƒ, …}
    setValue: ƒ (newValue)
    theme: {borderRadius: 4, colors: {…}, spacing: {…}}
    type: "option"
    value: "3"
    key: undefined
*/

const defaultStylesSelectInput: Styles = {
    container: styles => ({
        ...styles,
        pointerEvents: 'unset',
    }),
    control: (styles, state) => ({
        ...styles,
        color: colors.deepPurple,
        border: `1px solid ${state.isFocused ? colors.blue50 : colors.neutral30}`,
        borderRadius: 0,
        boxShadow: 'none',
        outline: 0,
        transition: 'border-color 200ms ease-out',
        cursor: state.isDisabled ? 'not-allowed' : 'default',
        ':hover': {
            borderColor: state.isDisabled ? colors.neutral30 : colors.deepPurple,
        },
        ...(state.isFocused
            ? {
                  outline: '3px solid',
                  outlineColor: colors.blue50,
                  outlineOffset: '-3px',
              }
            : {}),
    }),
    menu: (styles, { selectProps }) => ({
        ...styles,
        marginTop: selectProps.menuPlacement === 'bottom' ? 0 : undefined,
        marginBottom: selectProps.menuPlacement === 'top' ? 0 : undefined,
        border: `1px solid ${colors.neutral30}`,
        borderRadius: 0,
    }),
    option: (styles, state) => ({
        ...styles,
        marginTop: 0,
        backgroundColor: state.isSelected ? colors.deepPurple : colors.neutral10,
        color: state.isSelected ? colors.white : colors.deepPurple,
        ':hover': {
            backgroundColor: state.isSelected ? colors.neutral90 : colors.neutral20,
        },
        ':hover:active': {
            backgroundColor: colors.deepPurple,
            color: colors.white,
        },
    }),
    placeholder: styles => ({
        ...styles,
        color: colors.neutral50,
    }),
    dropdownIndicator: (styles, state) => {
        const color = state.isDisabled ? colors.neutral30 : colors.neutral70
        return {
            ...styles,
            color,
            ':hover': {
                color,
            },
            ':focus': {
                color,
            },
            transform: `rotate(${state.selectProps.components?.isMenuOpen ? '180deg' : '0'})`,
            transition: `transform ${transition.config}`,
        }
    },
    clearIndicator: (styles, state) => {
        const color = state.isDisabled ? colors.neutral30 : colors.neutral70
        return {
            ...styles,
            color,
            ':hover': {
                color,
            },
            ':focus': {
                color,
            },
        }
    },
    indicatorSeparator: (styles, state) => ({
        ...styles,
        backgroundColor: state.isDisabled ? colors.neutral30 : colors.neutral70,
    }),
}

const emptyStyles = {}
export const SelectInput = ({
    labelText,
    errorText,
    hasError,
    ref: _ref,
    as: _as,
    row = false,
    styles = emptyStyles,
    'data-test-id': dataTestId,
    onMenuOpen,
    onMenuClose,
    ...rest
}: ReactSelectProps & SelectInputProps) => {
    const labelSelect = labelText && <SelectComponents.Label>{labelText}</SelectComponents.Label>
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const mergedStyles = useMemo(() => ({ ...defaultStylesSelectInput, ...styles }), [styles])

    const select = (
        <>
            <SelectComponents.SelectInput
                styles={mergedStyles}
                {...rest}
                onMenuOpen={() => {
                    setIsMenuOpen(true)
                    onMenuOpen?.()
                }}
                onMenuClose={() => {
                    setIsMenuOpen(false)
                    onMenuClose?.()
                }}
                components={{ isMenuOpen, DropdownIndicator, ClearIndicator }}
            />
            {Boolean(hasError && errorText) && (
                <FormInputComponents.ErrorLabel>{errorText}</FormInputComponents.ErrorLabel>
            )}
        </>
    )

    return (
        <FormInputComponents.InputWrapper data-test-id={dataTestId} row={row}>
            {labelSelect}
            {select}
        </FormInputComponents.InputWrapper>
    )
}

export const selectFindOption = (options: OptionsType<SelectInputOption>, value: string) =>
    options.find(o => o.value === value) ?? null
