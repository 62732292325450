import React, { createRef, useContext, useEffect, useMemo, useReducer } from 'react'

import { QueryResult } from '@apollo/react-common'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { debounce } from 'debounce'
import { FormApi, MutableState, Tools, ValidationErrors } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'

import { SettingsRegion } from '@bc/config'
import { env } from '@frontend/config'
import graphqlUtils from '@bc/graphql-utils'
import { tagManager } from '@bc/gtm'
import { Language, MessageMap, RegionCode } from '@bc/translations'
import {
    GQLCustomer,
    GQLGender,
    GQLMainSalesArea,
    GQLRoleType,
    GQLUpdateUserInput,
    GQLUser,
    QueryToCustomersArgs,
    QueryToSearchCustomerArgs,
} from '@bc/types'
import {
    Button,
    CheckboxGroup,
    colors,
    RadioButton,
    spacing,
    TextInput,
    LoaderRound,
    Box,
    SelectInput,
    SelectInputOption,
    selectFindOption,
} from '@bc/ui'
import { Row } from '@bc/ui/src/components/grid/'
import { media } from '@bc/ui/src/helpers'
import { FormatMessage } from '@frontend/components/hooks'
import { PlatformConfigContext } from '@frontend/context'
import {
    finalFormValidations,
    PRIORITIZATION_CUSTOMER_ID,
    PRIORITIZATION_CUSTOMER_NAME,
    prioritizeCustomerOver,
    transformIntoCustomersInput,
} from '@frontend/utils'

import { OptionsType } from 'react-select'
import { CustomersQuery, CustomersResponse, SearchCustomerQuery, SearchCustomerResponse } from '../../graphql/shared/'
import { CompanyDropdownResult, CompanyEditContactCard } from '../company/'
import {
    createCustomersAddAction,
    createCustomersDeleteAction,
    createCustomersInitAction,
    createDeleteCancelAction,
    createDeleteConfirmAction,
    createResetAction,
    createSearchErrorAction,
    createSearchResetAction,
    createSearchResultAction,
    EditUserFormState,
    userFormReducer,
} from './edit-user-rorm-reducer'

import * as SharedFormComponents from './_shared'

const Label = styled.label`
    flex-basis: 100%;
`

const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    text-align: left;
`

const FormRow = styled(Row)`
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    margin: 0 0 ${spacing.xxs}px 0;
    ${media.min('md')} {
        margin: 0 0 ${spacing.sm}px 0;
    }

    row-gap: ${spacing.xxs}px;
    column-gap: ${spacing.sm}px;
`
const TwoColumnFormRow = styled(FormRow)`
    flex-wrap: nowrap;
    & > div {
        flex-basis: 50%;
    }

    ${media.max('md')} {
        flex-direction: column;
        & > div {
            flex-basis: 100%;
        }
    }
`
const ThreeColumnFormRow = styled(FormRow)`
    justify-content: flex-start;
    flex-wrap: nowrap;
    & > div {
        flex-basis: 30%;
    }

    ${media.max('md')} {
        flex-direction: column;
        & > div {
            flex-basis: 100%;
        }
    }
`

const FormRowSeparator = styled.div`
    border-top: 2px solid ${colors.neutral20};
    margin: 0 0 20px 0;
`

const SearchResults = styled.div`
    background-color: ${colors.white};
    position: absolute;
    display: block;
    width: calc(100% - ${2 * spacing.sm}px);
    border: 1px solid ${colors.neutral20};
    border-radius: 3px;
    z-index: 10;
`

const SearchResultDropDown = styled(SearchResults)`
    overflow-y: scroll;
    max-height: 160px;
`

const SearchNoResultDropDown = styled(SearchResults)`
    padding: ${spacing.xxs}px ${spacing.xs}px;
`

const StyledLabelOrganisation = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
`

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

interface EditUserFormProps {
    open: boolean
    type: 'edit' | 'create'
    onClose: () => void
    onDelete?: () => void
    onConfirm: (values: GQLUpdateUserInput) => void
    validateEmailAvailable?: (email: string) => Promise<string | undefined>
    t: FormatMessage
    contact?: GQLUser
    loading: boolean
}

interface EditUserFormExtraValues {
    searchCustomerId?: string | undefined
    formUserSalesAreas?: FormUserSalesAreas
    mfa: string[]
}

interface FormUserSalesAreas {
    [key: string]: boolean[]
}

const genders: GQLGender[] = [GQLGender.male, GQLGender.female, GQLGender.unknown]
const csrGenders: GQLGender[] = [GQLGender.male, GQLGender.female]

const saveButtonRef = createRef<HTMLButtonElement>()
const cancelButtonRef = createRef<HTMLButtonElement>()

const NUM_CHAR_START_SEARCH: number = 3
const MFA: string = 'mfa'

const getInitialFormValues = (
    appConfig: SettingsRegion,
    customers: GQLCustomer[] | undefined,
    contact?: GQLUser,
): GQLUpdateUserInput & EditUserFormExtraValues => {
    const initialValues: GQLUpdateUserInput & EditUserFormExtraValues = {
        language: appConfig.languages[0],
        region: appConfig.countries[0],
        gender: GQLGender.male,
        mfa: [MFA],
    }
    const formUserSalesAreas = customers?.reduce((userAreas: FormUserSalesAreas, customer: GQLCustomer) => {
        userAreas[`_${customer.id}`] = customer.mainSalesAreas.map(customerSalesArea =>
            Boolean(
                contact?.limitUserToSalesAreas.find(
                    userArea =>
                        userArea.customerId === customer.id &&
                        userArea.salesAreas.some(
                            userSalesArea => userSalesArea.salesAreaId === customerSalesArea.salesAreaId,
                        ),
                ),
            ),
        )

        return userAreas
    }, {})

    initialValues.role = {
        type: contact?.role?.type ?? GQLRoleType.user,
    }
    const { email, firstName, lastName, language, region, role } = contact ?? {}
    return contact
        ? {
              ...initialValues,
              email,
              firstName,
              lastName,
              language,
              region,
              role: { type: role!.type },
              mfa: [MFA],
              formUserSalesAreas,
              gender: graphqlUtils.user.getGender(contact.female),
          }
        : initialValues
}

const initialState: EditUserFormState = {
    confirmDelete: false,
    searchResult: undefined,
    searchError: undefined,
    customers: [],
}

const isCsr = (formRole: GQLRoleType | undefined) => formRole === GQLRoleType.csr || formRole === GQLRoleType.admin

const getUserRoleList = (contact: GQLUser | undefined) => {
    const role = contact?.role?.type
    switch (role) {
        case GQLRoleType.viewer:
        case GQLRoleType.user: {
            return [GQLRoleType.viewer, GQLRoleType.user]
        }
        case GQLRoleType.csr: {
            return [GQLRoleType.csr]
        }
        case undefined:
        default: {
            return [GQLRoleType.viewer, GQLRoleType.user, GQLRoleType.csr]
        }
    }
}

export const EditUserForm = ({
    open,
    type,
    onClose,
    onDelete,
    onConfirm,
    t,
    contact,
    validateEmailAvailable,
    loading,
}: EditUserFormProps) => {
    const [{ confirmDelete, searchResult, searchError, customers }, dispatch] = useReducer(
        userFormReducer,
        initialState,
    )

    const { appConfig } = useContext(PlatformConfigContext)
    const client = useApolloClient()

    const customerIds = useMemo(() => (contact?.limitUserToSalesAreas ?? []).map(({ customerId }) => customerId), [
        contact,
        open,
    ])
    const { data: customersQueryResponse, loading: loadingCustomers }: QueryResult<CustomersResponse> = useQuery<
        CustomersResponse,
        QueryToCustomersArgs
    >(CustomersQuery, {
        variables: { customerIds },
        ssr: false,
        skip: customerIds.length === 0,
    })
    let loadedCustomers: GQLCustomer[] | undefined = customersQueryResponse?.customers

    const initialFormValues: GQLUpdateUserInput & EditUserFormExtraValues = useMemo(
        () => getInitialFormValues(appConfig, loadedCustomers, contact),
        [contact, type, loadedCustomers],
    )

    let debounceCustomerSearchLogging: any
    let debounceCustomerSearch: any

    const roleOptions: OptionsType<SelectInputOption> = useMemo(
        () =>
            getUserRoleList(contact).map((roleName: GQLRoleType) => ({
                value: roleName,
                label: t(`filters:search-by-${roleName ?? 'user'}.placeholder`),
            })),
        [contact],
    )

    useEffect(() => {
        if (contact) {
            dispatch(createCustomersInitAction(loadedCustomers?.filter(Boolean) ?? []))
            // select Save or Cancel button on load so that pressing Enter actions correctly.
            // do this on timeout so final-form validations for disabled state has run
            setTimeout(
                () =>
                    saveButtonRef.current?.disabled ? cancelButtonRef.current?.focus() : saveButtonRef.current?.focus(),
                250,
            )
        }
    }, [contact, loadedCustomers])

    useEffect(() => {
        if (!open) {
            cleanup()
        }
    }, [open])

    const customerSearchLogging = (searchQuery: string) => {
        if (debounceCustomerSearchLogging) {
            debounceCustomerSearchLogging.clear()
        }
        debounceCustomerSearchLogging = debounce(() => tagManager.tags.customerSearch(searchQuery), 1000)
        debounceCustomerSearchLogging()
    }

    const cleanup = () => {
        dispatch(createResetAction())
        loadedCustomers = []
    }

    const onSubmit = (newValues: GQLUpdateUserInput & EditUserFormExtraValues) => {
        // extract form values not required for the backend
        const { searchCustomerId, formUserSalesAreas = {}, mfa, ...values } = newValues

        // re-map formUserSalesAreas to limitUserToSalesAreas
        values.limitUserToSalesAreas = customers.map(({ id: customerId, mainSalesAreas }) => ({
            customerId,
            salesAreas: mainSalesAreas
                .filter((_, index) => formUserSalesAreas[`_${customerId}`][index])
                .map(({ __typename, salesOffice, ...rest }: GQLMainSalesArea & { __typename: string }) => ({
                    ...rest,
                })),
        }))

        values.enableMfa = mfa.includes(MFA)
        values.updateMfa = true

        onConfirm(values as GQLUpdateUserInput)
    }

    const handleClose = () => {
        onClose()
    }

    const handleDelete = () => {
        if (onDelete) {
            onDelete()
        }
    }

    const doCustomerSearch = async (id: string) => {
        if (id.length >= NUM_CHAR_START_SEARCH) {
            customerSearchLogging(id)
            const variables: QueryToSearchCustomerArgs = transformIntoCustomersInput(id)
            const prioritization = variables.customerName ? PRIORITIZATION_CUSTOMER_NAME : PRIORITIZATION_CUSTOMER_ID
            try {
                const { data, errors } = await client.query<SearchCustomerResponse, QueryToSearchCustomerArgs>({
                    query: SearchCustomerQuery,
                    variables,
                })
                if (errors) {
                    dispatch(createSearchErrorAction(errors))
                } else {
                    dispatch(createSearchResultAction(prioritizeCustomerOver(data.searchCustomer, id, prioritization)))
                }
            } catch (err) {
                dispatch(createSearchErrorAction(err))
            }
        } else {
            dispatch(createSearchResetAction())
        }
    }

    const handleCustomerSearchInputChange = (customerId: string): void => {
        if (debounceCustomerSearch) {
            debounceCustomerSearch.clear()
        }
        debounceCustomerSearch = debounce(() => doCustomerSearch(customerId), 500)
        debounceCustomerSearch()
    }

    const handleDeleteCustomerFromContact = (customer: GQLCustomer, form: FormApi) => {
        dispatch(createCustomersDeleteAction(customer))
        form.mutators.clear(`formUserSalesAreas._${customer.id}`)
    }

    const handleAddSearchCustomer = (customer: GQLCustomer, form: FormApi) => {
        dispatch(createCustomersAddAction(customer))
        // set checkbox ON for sales area if there is only one
        if (customer.mainSalesAreas.length === 1) {
            form.mutators.checkOneSalesArea(customer)
        }

        // clear search results
        form.mutators.clear('searchCustomerId')
    }

    const checkOneSalesArea = (
        [customer]: any,
        state: MutableState<GQLUpdateUserInput & EditUserFormExtraValues>,
        { changeValue }: Tools<GQLUpdateUserInput & EditUserFormExtraValues>,
    ) => {
        changeValue(state, `formUserSalesAreas._${customer.id}`, () => [true])
    }

    const clear = (
        [field]: any,
        state: MutableState<GQLUpdateUserInput & EditUserFormExtraValues>,
        { changeValue }: Tools<GQLUpdateUserInput & EditUserFormExtraValues>,
    ) => {
        changeValue(state, field, () => undefined)
    }

    const validateForm = (values: GQLUpdateUserInput & EditUserFormExtraValues): ValidationErrors => {
        let errors: ValidationErrors = {}

        if (!isCsr(values.role!.type) && customers.length < 1) {
            errors = { ...errors, searchCustomerId: t('user:form:error-customer-grant-access') }
        }

        return errors
    }

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialFormValues}
            mutators={{
                ...arrayMutators,
                clear,
                checkOneSalesArea,
            }}
            validate={validateForm}>
            {({ handleSubmit, form, values, errors }) => {
                const isCsrRole = isCsr(values.role!.type)

                const otherUserEmailValidation = (value: string) =>
                    contact?.email === value ? undefined : validateEmailAvailable && validateEmailAvailable(value)

                const editUserEmailValidations = finalFormValidations.composeValidations(
                    finalFormValidations.required(t),
                    isCsrRole ? finalFormValidations.emailCSR(t) : finalFormValidations.email(t),
                    otherUserEmailValidation,
                )
                const createUserEmailValidations = finalFormValidations.composeValidations(
                    editUserEmailValidations,
                    validateEmailAvailable,
                )

                return (
                    <FormWrapper onSubmit={handleSubmit}>
                        <FormRow>
                            <Field
                                name="email"
                                validate={type === 'create' ? createUserEmailValidations : editUserEmailValidations}
                                render={({ input, meta }) => (
                                    <TextInput
                                        {...input}
                                        labelText={t('user:form:label-email')}
                                        placeholder={t('user:form:label-email-placeholder')}
                                        type="email"
                                        hasError={meta.touched && meta.error}
                                        errorText={meta.error}
                                        data-test-id="input-email"
                                    />
                                )}
                            />
                        </FormRow>
                        <TwoColumnFormRow>
                            <Field
                                name="firstName"
                                validate={finalFormValidations.required(t)}
                                render={({ input, meta }) => (
                                    <TextInput
                                        {...input}
                                        labelText={t('user:form:label-firstname')}
                                        placeholder={t('user:form:label-firstname-placeholder')}
                                        type="text"
                                        hasError={meta.touched && meta.error}
                                        errorText={meta.error}
                                        data-test-id="input-first-name"
                                    />
                                )}
                            />

                            <Field
                                name="lastName"
                                validate={finalFormValidations.required(t)}
                                render={({ input, meta }) => (
                                    <TextInput
                                        {...input}
                                        labelText={t('user:form:label-lastname')}
                                        placeholder={t('user:form:label-lastname-placeholder')}
                                        type="text"
                                        hasError={meta.touched && meta.error}
                                        errorText={meta.error}
                                        data-test-id="input-last-name"
                                    />
                                )}
                            />
                        </TwoColumnFormRow>
                        <FormRow>
                            <Label>{t('user:form:label-language')}</Label>
                            <ThreeColumnFormRow>
                                {appConfig.languages?.map((language: Language) => (
                                    <Field
                                        key={language}
                                        name="language"
                                        type="radio"
                                        value={language}
                                        render={({ input }) => (
                                            <RadioButton
                                                {...input}
                                                id={language + type}
                                                labelText={t(`language:${language}` as keyof MessageMap)}
                                                colorScheme="deepPurple"
                                            />
                                        )}
                                    />
                                ))}
                            </ThreeColumnFormRow>
                        </FormRow>
                        <FormRow>
                            <Label>{t('user:form:label-gender')}</Label>
                            <ThreeColumnFormRow>
                                {(isCsrRole ? csrGenders : genders).map((gender: GQLGender) => (
                                    <Field
                                        key={gender}
                                        name="gender"
                                        type="radio"
                                        value={gender}
                                        render={({ input }) => (
                                            <RadioButton
                                                {...input}
                                                id={`${gender}--${type}`}
                                                labelText={t(`gender:${gender}` as keyof MessageMap)}
                                                colorScheme="deepPurple"
                                            />
                                        )}
                                    />
                                ))}
                            </ThreeColumnFormRow>
                        </FormRow>
                        {appConfig?.countries.length > 1 && (
                            <FormRow>
                                <Label>{t('user:form:label-country')}</Label>
                                <ThreeColumnFormRow>
                                    {appConfig.countries.map((country: RegionCode) => (
                                        <Field
                                            key={country}
                                            name="region"
                                            type="radio"
                                            value={country}
                                            render={({ input }) => (
                                                <RadioButton
                                                    {...input}
                                                    id={`${country}--${type}`}
                                                    labelText={country.toUpperCase()}
                                                    colorScheme="deepPurple"
                                                />
                                            )}
                                        />
                                    ))}
                                </ThreeColumnFormRow>
                            </FormRow>
                        )}
                        <TwoColumnFormRow>
                            <Field
                                name="role"
                                parse={o => ({ type: o?.value })}
                                render={({ input }) => {
                                    const { value, ...restInput } = input
                                    const selectedOption = selectFindOption(roleOptions, value.type)
                                    return (
                                        <SelectInput
                                            {...restInput}
                                            isSearchable={false}
                                            isDisabled={roleOptions.length === 1}
                                            value={selectedOption}
                                            labelText={t('user:form:label-usertype')}
                                            placeholder={t('user:form:label-placeholder')}
                                            options={roleOptions}
                                            noOptionsMessage={() => t('not-found:select')}
                                            classNamePrefix="select-user-role"
                                            data-test-id="select-user-role"
                                        />
                                    )
                                }}
                            />
                            {env.DEV_MODE && (
                                <CheckboxGroup
                                    labelText={t('user:form:label-mfa')}
                                    name="mfa"
                                    id={`mfa.${contact?.id}`}
                                    options={[MFA]}
                                    labelOptions={[t('user:form:label-enablemfa')]}
                                    colorScheme="deepPurple"
                                />
                            )}
                        </TwoColumnFormRow>
                        {!isCsrRole && (
                            <>
                                <FormRowSeparator />
                                <FormRow>
                                    <Field
                                        name="searchCustomerId"
                                        validate={finalFormValidations.composeValidations(
                                            finalFormValidations.validSearchInput(t),
                                            finalFormValidations.minLength(t, NUM_CHAR_START_SEARCH),
                                        )}
                                        render={({ input, meta }) => {
                                            const { onChange, ...restInput } = input

                                            return (
                                                <>
                                                    <StyledLabelOrganisation>
                                                        {t('user:form:label-customer-id-and-sales-org')}
                                                    </StyledLabelOrganisation>
                                                    <TextInput
                                                        {...restInput}
                                                        autoComplete="off"
                                                        onChange={e => {
                                                            handleCustomerSearchInputChange(
                                                                (e.target as HTMLInputElement).value,
                                                            )
                                                            onChange(e)
                                                        }}
                                                        labelText={t('user:form:label-customer-grant-access')}
                                                        placeholder={t(
                                                            'filters:search-by-customerID-or-name-or-vat.placeholder',
                                                        )}
                                                        type="text"
                                                        hasError={meta.touched && meta.error}
                                                        errorText={meta.error}
                                                        data-test-id="input-customer-ids"
                                                    />
                                                </>
                                            )
                                        }}
                                    />
                                    {searchError ? (
                                        <SearchNoResultDropDown>{t('user:form:error-search')}</SearchNoResultDropDown>
                                    ) : (
                                        searchResult && (
                                            <>
                                                {searchResult.length > 0 ? (
                                                    <SearchResultDropDown>
                                                        {searchResult.map((customer, i) => (
                                                            <CompanyDropdownResult
                                                                highlight={
                                                                    (values as GQLUpdateUserInput &
                                                                        EditUserFormExtraValues).searchCustomerId ?? ''
                                                                }
                                                                key={`${customer.id}-${i}`}
                                                                customer={customer}
                                                                onClick={e => {
                                                                    handleAddSearchCustomer(e, form)
                                                                }}
                                                            />
                                                        ))}
                                                    </SearchResultDropDown>
                                                ) : (
                                                    <SearchNoResultDropDown>
                                                        {t('user:form:search-no-results')}
                                                    </SearchNoResultDropDown>
                                                )}
                                            </>
                                        )
                                    )}
                                    {loadingCustomers ? (
                                        <Box center>
                                            <LoaderRound />
                                        </Box>
                                    ) : (
                                        <CardContainer>
                                            {customers.map(customer => (
                                                <CompanyEditContactCard
                                                    inactive={Boolean(searchResult)}
                                                    name={`formUserSalesAreas._${customer.id}`}
                                                    key={customer.id}
                                                    customer={customer}
                                                    onDelete={customerToDelete =>
                                                        handleDeleteCustomerFromContact(customerToDelete, form)
                                                    }
                                                />
                                            ))}
                                        </CardContainer>
                                    )}
                                </FormRow>
                            </>
                        )}
                        {!confirmDelete ? (
                            <SharedFormComponents.ButtonsWrapper>
                                {onDelete && (
                                    <Button
                                        key="delete1"
                                        onClick={() => dispatch(createDeleteConfirmAction())}
                                        variant="error"
                                        title={t('general:delete')}
                                        data-test-id="button-delete-user">
                                        {t('general:delete')}
                                    </Button>
                                )}
                                <Button
                                    key="cancel1"
                                    ref={cancelButtonRef}
                                    onClick={handleClose}
                                    variant="outline"
                                    title={t('general:cancel')}
                                    data-test-id="button-cancel-edit">
                                    {t('general:cancel')}
                                </Button>
                                <Button
                                    key="save"
                                    ref={saveButtonRef}
                                    type="submit"
                                    variant="action"
                                    title={t('general:save')}
                                    isLoading={loading}
                                    data-test-id="button-save-user"
                                    disabled={Object.keys(errors ?? {}).length > 0}>
                                    {t('general:save')}
                                </Button>
                            </SharedFormComponents.ButtonsWrapper>
                        ) : (
                            <SharedFormComponents.ButtonsWrapper>
                                <Button
                                    key="cancel2"
                                    onClick={() => dispatch(createDeleteCancelAction())}
                                    variant="outline"
                                    title={t('general:cancel')}
                                    data-test-id="button-delete-cancel">
                                    {t('general:cancel')}
                                </Button>
                                <Button
                                    key="delete2"
                                    onClick={handleDelete}
                                    variant="error"
                                    title={t('general:delete')}
                                    isLoading={loading}
                                    data-test-id="button-delete-confirm">
                                    {t('general:delete')}
                                </Button>
                            </SharedFormComponents.ButtonsWrapper>
                        )}
                    </FormWrapper>
                )
            }}
        </Form>
    )
}
