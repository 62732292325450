import React, { useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { media, ToastMessages } from '@bc/ui'
import { Footer } from '@frontend/components/footer'
import { Navigation } from '@frontend/components/navigation'
import { SessionContext, SessionContextData, ToastsContext, ToastsContextData, UserContext } from '@frontend/context'
import { Onboarding } from '@frontend/components/onboarding'
import { Promotions } from '@frontend/components/notifications/promotion-modal'

interface Props {
    children: React.ReactNode
}

interface WrapperProps {
    extraPadding: boolean
}
const Wrapper = styled.div<WrapperProps>`
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding-top: ${({ extraPadding }: WrapperProps) => (extraPadding ? '150px' : '100px')};
    overflow-x: hidden;

    ${media.min('md')} {
        padding-top: ${({ extraPadding }: WrapperProps) => (extraPadding ? '170px' : '130px')};
        overflow: hidden;
    }
`

const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 0 auto;
`

type AppBaseProps = Props & RouteComponentProps<any>

const AppBaseComponent = ({ children, location }: AppBaseProps) => {
    const { currentCustomer, userHasMultipleCustomerEntities, me, activeSalesAreaId } = useContext(UserContext)
    const [toastsStore]: ToastsContextData = useContext(ToastsContext)
    const [sessionStore]: SessionContextData = useContext(SessionContext)

    // reset the selectedUser here
    const removeActiveCustomer = (): void => {
        sessionStore.removeActiveCustomer()
    }

    // app needs extra padding when showing the customerHeader
    const hasExtraPadding = Boolean(currentCustomer)

    return (
        <Wrapper extraPadding={hasExtraPadding}>
            {me && (
                <>
                    <Navigation
                        userHasMultipleCustomerEntities={userHasMultipleCustomerEntities}
                        currentCustomer={currentCustomer}
                        me={me}
                        removeActiveCustomer={removeActiveCustomer}
                        activeSalesAreaId={activeSalesAreaId}
                    />
                    {!me.meta.completedOnboarding && <Onboarding />}
                    <Promotions />
                </>
            )}
            <ContentWrapper>{children}</ContentWrapper>

            {location.pathname !== '/login' && <Footer />}
            {/* Global app messages are displayed in a global Toast */}
            <ToastMessages toasts={toastsStore.getToasts()} onClose={(id: string) => toastsStore.removeToast(id)} />
        </Wrapper>
    )
}

export const AppBase = withRouter(AppBaseComponent)
