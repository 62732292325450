import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Logout: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Logout" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M10 21H3V3H10V5H5V19H10V21ZM15.7091 18.71L14.2891 17.29L19.5891 12L14.2891 6.71004L15.7091 5.29004L22.4091 12L15.7091 18.71ZM15.76 11L16.76 12L15.76 13H7V11H15.76Z"
                fill={fill === 'gradient' ? 'url(#Logout_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Logout" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Logout" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M10 21H3V3H10V5H5V19H10V21ZM15.7091 18.71L14.2891 17.29L19.5891 12L14.2891 6.71004L15.7091 5.29004L22.4091 12L15.7091 18.71ZM15.76 11L16.76 12L15.76 13H7V11H15.76Z"
                fill={fill === 'gradient' ? 'url(#Logout_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Logout" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Logout" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M10 21H3V3H10V5H5V19H10V21ZM15.7091 18.71L14.2891 17.29L19.5891 12L14.2891 6.71004L15.7091 5.29004L22.4091 12L15.7091 18.71ZM15.76 11L16.76 12L15.76 13H7V11H15.76Z"
                fill={fill === 'gradient' ? 'url(#Logout_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Logout" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Logout" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M10 21H3V3H10V5H5V19H10V21ZM15.7091 18.71L14.2891 17.29L19.5891 12L14.2891 6.71004L15.7091 5.29004L22.4091 12L15.7091 18.71ZM15.76 11L16.76 12L15.76 13H7V11H15.76Z"
                fill={fill === 'gradient' ? 'url(#Logout_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Logout" />}
        </g>
    ),
}
