import React from 'react'

import { colors } from '@bc/ui/src/config'

import { SvgIconMap } from '../types'

const defaultFill = colors.deepPurple

export const status_shipped: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-shipped"
            d="M19.535,5H16V3H2v2h12v3H2v2h12v7H8.22C7.67,16.391,6.883,16,6,16c-0.771,0-1.468,0.301-2,0.78V12H2v7h1
            c0,1.654,1.346,3,3,3s3-1.346,3-3h6c0,1.654,1.346,3,3,3s3-1.346,3-3h1V8.697L19.535,5z M6,20c-0.551,0-1-0.448-1-1s0.449-1,1-1
            s1,0.448,1,1S6.551,20,6,20z M18,20c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1S18.551,20,18,20z M20,15v1.78
            c-0.532-0.48-1.229-0.78-2-0.78s-1.468,0.301-2,0.78V12h2.465L20,14.303V15z M20,10.697L19.535,10H16V8V7h2.465L20,9.303V10.697z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-shipped"
            d="M19.535,5H16V3H2v2h12v3H2v2h12v7H8.22C7.67,16.391,6.883,16,6,16c-0.771,0-1.468,0.301-2,0.78V12H2v7h1
            c0,1.654,1.346,3,3,3s3-1.346,3-3h6c0,1.654,1.346,3,3,3s3-1.346,3-3h1V8.697L19.535,5z M6,20c-0.551,0-1-0.448-1-1s0.449-1,1-1
            s1,0.448,1,1S6.551,20,6,20z M18,20c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1S18.551,20,18,20z M20,15v1.78
            c-0.532-0.48-1.229-0.78-2-0.78s-1.468,0.301-2,0.78V12h2.465L20,14.303V15z M20,10.697L19.535,10H16V8V7h2.465L20,9.303V10.697z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-shipped"
            d="M19.535,5H16V3H2v2h12v3H2v2h12v7H8.22C7.67,16.391,6.883,16,6,16c-0.771,0-1.468,0.301-2,0.78V12H2v7h1
            c0,1.654,1.346,3,3,3s3-1.346,3-3h6c0,1.654,1.346,3,3,3s3-1.346,3-3h1V8.697L19.535,5z M6,20c-0.551,0-1-0.448-1-1s0.449-1,1-1
            s1,0.448,1,1S6.551,20,6,20z M18,20c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1S18.551,20,18,20z M20,15v1.78
            c-0.532-0.48-1.229-0.78-2-0.78s-1.468,0.301-2,0.78V12h2.465L20,14.303V15z M20,10.697L19.535,10H16V8V7h2.465L20,9.303V10.697z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-shipped"
            d="M19.535,5H16V3H2v2h12v3H2v2h12v7H8.22C7.67,16.391,6.883,16,6,16c-0.771,0-1.468,0.301-2,0.78V12H2v7h1
            c0,1.654,1.346,3,3,3s3-1.346,3-3h6c0,1.654,1.346,3,3,3s3-1.346,3-3h1V8.697L19.535,5z M6,20c-0.551,0-1-0.448-1-1s0.449-1,1-1
            s1,0.448,1,1S6.551,20,6,20z M18,20c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1S18.551,20,18,20z M20,15v1.78
            c-0.532-0.48-1.229-0.78-2-0.78s-1.468,0.301-2,0.78V12h2.465L20,14.303V15z M20,10.697L19.535,10H16V8V7h2.465L20,9.303V10.697z"
            transform="scale(0.5)"
        />
    ),
}
