import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Home: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Home" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M21 21H14V19H19V11.41L12 4.41003L5 11.41V19H9V13H14V15H11V21H3V10.59L12 1.59003L21 10.59V21Z"
                fill={fill === 'gradient' ? 'url(#Home_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Home" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Home" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M21 21H14V19H19V11.41L12 4.41003L5 11.41V19H9V13H14V15H11V21H3V10.59L12 1.59003L21 10.59V21Z"
                fill={fill === 'gradient' ? 'url(#Home_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Home" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Home" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M21 21H14V19H19V11.41L12 4.41003L5 11.41V19H9V13H14V15H11V21H3V10.59L12 1.59003L21 10.59V21Z"
                fill={fill === 'gradient' ? 'url(#Home_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Home" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Home" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M21 21H14V19H19V11.41L12 4.41003L5 11.41V19H9V13H14V15H11V21H3V10.59L12 1.59003L21 10.59V21Z"
                fill={fill === 'gradient' ? 'url(#Home_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Home" />}
        </g>
    ),
}
