import styled from 'styled-components'

import { spacing, colors } from '@bc/ui/src/config'
import { media, getFontFamily } from '@bc/ui/src/helpers'
import { ButtonPropsForButton } from '../button/button-types'
import { Button } from '../button'

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: ${spacing.xs}px;

    ${media.min('sm')} {
        justify-content: flex-start;
        margin-top: ${spacing.sm}px;
    }
`

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap;
    margin-bottom: ${spacing.sm}px;
`

export const Content = styled.div`
    width: 100%;
`

interface TabButtonProps extends ButtonPropsForButton {
    isHidden?: boolean
    grow?: boolean
    active: boolean
}

export const TabButton = styled(Button).attrs({ variant: 'outline' })<TabButtonProps>`
    display: inline-block;

    ${({ isHidden }) => `opacity: ${isHidden ? 0.5 : 1};`}
    ${({ grow }) => grow && `flex-grow: 1;`}

    border: none;
    &:not(:last-child) {
        margin-right: 2px;
    }

    ${({ active }) =>
        active
            ? `
                &, &:focus {
                    border: unset;
                    border-bottom: 3px solid;
                    border-image-slice: 1;
                    border-image-source: linear-gradient(90deg, ${colors.violet50}, ${colors.blue50});

                    font-family: ${getFontFamily('brenntagsans_extrabold')};

                    color: ${colors.deepPurple};
                    background-color: unset;
                    box-shadow: unset;
                }

                &:hover, 
                &:active { 
                    border-image-source: unset;
                    color: ${colors.deepPurple};
                }

                &:hover {
                    background-color: ${colors.blue10};
                    border-bottom-color: ${colors.blue50};
                }

                &:active {
                    background-color: ${colors.blue30};
                    border-bottom-color: ${colors.blue60};
                    border-bottom: 3px solid;
                }

                `
            : `
                &, &:focus {
                    border: unset;
                    border-bottom: 1px solid;
                    border-bottom-color: ${colors.neutral30};

                    font-family: ${getFontFamily('brenntagsans')};
                    font-weight: 400;
                    
                    color: ${colors.neutral70};
                    background-color: unset;
                    box-shadow: unset;
                }

                &:hover,
                &:active {
                    color: ${colors.deepPurple};
                    border-bottom-color: ${colors.deepPurple};
                }

                &:hover {
                    background-color: ${colors.neutral10};
                }

                &:active {
                    background-color: ${colors.neutral30};
                }
            `}
`
