import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const File_Text: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="File_Text" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M15.41 2H13V9H18V20H6V4H11V2H4V22H20V6.59L15.41 2ZM15 7V4.41L17.59 7H15ZM16 12H8V14H16V12ZM8 16H16V18H8V16Z"
                fill={fill === 'gradient' ? 'url(#File_Text_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_Text" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="File_Text" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M15.41 2H13V9H18V20H6V4H11V2H4V22H20V6.59L15.41 2ZM15 7V4.41L17.59 7H15ZM16 12H8V14H16V12ZM8 16H16V18H8V16Z"
                fill={fill === 'gradient' ? 'url(#File_Text_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_Text" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="File_Text" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M15.41 2H13V9H18V20H6V4H11V2H4V22H20V6.59L15.41 2ZM15 7V4.41L17.59 7H15ZM16 12H8V14H16V12ZM8 16H16V18H8V16Z"
                fill={fill === 'gradient' ? 'url(#File_Text_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_Text" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="File_Text" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M15.41 2H13V9H18V20H6V4H11V2H4V22H20V6.59L15.41 2ZM15 7V4.41L17.59 7H15ZM16 12H8V14H16V12ZM8 16H16V18H8V16Z"
                fill={fill === 'gradient' ? 'url(#File_Text_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="File_Text" />}
        </g>
    ),
}
