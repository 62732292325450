/**
 * This is intended to be used to compose event handlers.
 * They are executed in order until one of them sets.
 *
 * @param fns - The event handler functions.
 * @returns The event handler to add to an element.
 * @example
 */
export const composeEventHandlers = (...fns: any[]) => (event: any, ...args: any[]) =>
    fns.some(fn => {
        if (fn && typeof fn === 'function') {
            fn(event, ...args)
        }
        return event.defaultPrevented
    })
