import { GQLLocation } from '@bc/types'

import { EventTag, VirtualPageviewTag } from '../types'
import { dataLayer } from '../utils'

export const orderHistoryFilterByName = (name: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.orderHistoryName',
        virtualURL: `/historysearchname?q=${name}&n=historysearchnamebar`,
        virtualTitle: 'order history - search name',
    })
}

export const orderHistoryFilterByOrderId = (id: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.orderHistoryOrderNumber',
        virtualURL: `/historysearchordernumber?q=${id}&n=historysearchordernumberbar`,
        virtualTitle: 'order history - search order number',
    })
}

export const orderHistoryFilterByPo = (name: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.orderHistoryPo',
        virtualURL: `/historysearchpo?q=${name}&n=historysearchpobar`,
        virtualTitle: 'order history - PO reference',
    })
}
export const orderHistoryFilterByMaterialName = (name: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.orderHistoryMaterialName',
        virtualURL: `/historymaterialname?q=${name}&n=historysearchmaterialname`,
        virtualTitle: 'order history - material name',
    })
}

export const orderHistoryFilterByAddress = (address: GQLLocation) => {
    dataLayer<EventTag>({
        event: 'gtm.orderHistoryAddress',
        eventCategory: 'order history',
        eventAction: 'filter',
        eventLabel: `${address.name} ${address.address && address.address.street} ${
            address.address && address.address.city
        }, ${address.address && address.address.country}`,
    })
}

export const orderHistoryFilterByStatus = (status: string) => {
    dataLayer<EventTag>({
        event: 'gtm.orderHistoryFilter',
        eventCategory: 'order history',
        eventAction: 'filter',
        eventLabel: status,
    })
}

const getMappedSort = (sort: string) => {
    let sortOrder = 'ascending'
    let sortType = sort

    if (sortType[0] === '-') {
        sortOrder = 'descending'
        sortType = sortType.substr(1)
    }

    return `${sortType}: ${sortOrder}`
}

export const orderHistorySort = (sort: string) => {
    dataLayer<EventTag>({
        event: 'gtm.orderHistorySort',
        eventCategory: 'order history',
        eventAction: 'sort',
        eventLabel: getMappedSort(sort),
    })
}
