import React from 'react'
import { withRouter } from 'react-router-dom'

import { QueryResult } from '@apollo/react-common'
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'

import { debounce } from 'debounce'

import { tagManager } from '@bc/gtm'
import {
    GQLCreateUserInput,
    GQLRoleType,
    GQLUpdateUserInput,
    GQLUser,
    GQLUserFilters,
    MutationToCreateUserArgs,
    MutationToDeleteUserArgs,
    MutationToUpdateUserArgs,
} from '@bc/types'
import { FullScreenLoaderRound, useDocumentTitle, SelectInputOption } from '@bc/ui'
import { useFormatMessage } from '@frontend/components'
import ForgotPassword from '@frontend/graphql/shared/forgot-password.graphql'
import { FiltersContextData, FiltersProvider, SortContextData, SortProvider } from '@frontend/context'

import { useFilters } from '@frontend/components/hooks/use-filters'
import { UserManagementPage } from './user-management-page'

import {
    CreateUser,
    DeleteUser,
    FindUserByEmail,
    GetUsers,
    GetUsersQueryResponse,
    SendWelcomeEmail,
    UpdateUser,
} from './queries'
import { UserFilters } from './user-management-types'

type UsersPageQuery = UserFilters

const initialFilters: UserFilters = { role: [GQLRoleType.user] }
const initialPage = 1
const initialSort = '-lastLogin'
const MAX_SEARCH_RESULTS = 100

const filtersConvertToGql = (filters: UserFilters): GQLUserFilters => ({
    ...filters,
    role: filters.role?.join(','), // merge array of roles form form checkboxs into auth0 role string
})

const queryToFilter = (query: UsersPageQuery) => {
    const { role: qRole, ...rest } = query
    const role: string[] | undefined = (typeof qRole === 'string' ? [qRole] : qRole) ?? initialFilters.role

    return {
        ...initialFilters,
        ...rest,
        role,
    }
}

const MyCompaniesPageContainer = () => {
    const t = useFormatMessage()
    const { filters, sort = initialSort, updateFilters } = useFilters<UserFilters, UsersPageQuery>(queryToFilter)
    const client = useApolloClient()

    const variables = {
        page: 1,
        pageSize: MAX_SEARCH_RESULTS,
        sort,
        filters: filtersConvertToGql(filters),
    }

    const { error, loading, data }: QueryResult<GetUsersQueryResponse> = useQuery(GetUsers, {
        variables,
        fetchPolicy: 'network-only',
    })
    const users = data?.users?.users
    const usersCount = data?.users?.pagination.count

    const refetchQueries = () => [
        { query: GetUsers, variables, fetchPolicy: 'network-only', awaitRefetchQueries: true },
    ]

    const [forgotPassword] = useMutation(ForgotPassword)
    const [sendWelcomeEmail] = useMutation(SendWelcomeEmail, {
        refetchQueries,
    })
    const [updateUser, { loading: updateUserLoading }] = useMutation<{ updateUser: GQLUser }, MutationToUpdateUserArgs>(
        UpdateUser,
        {
            refetchQueries,
        },
    )
    const [createUser, { loading: createUserLoading }] = useMutation<{ createUser: GQLUser }, MutationToCreateUserArgs>(
        CreateUser,
        {
            refetchQueries,
            onCompleted: createData => {
                const id = createData.createUser?.id

                if (id) {
                    tagManager.tags.createUser(id)
                }
            },
        },
    )
    const [deleteUser, { loading: deleteUserLoading }] = useMutation<{ deleteUser: boolean }, MutationToDeleteUserArgs>(
        DeleteUser,
        {
            refetchQueries,
        },
    )

    const pageTitle = t('route:contacts.label')
    useDocumentTitle(pageTitle)

    let debounceCustomerEmail: any
    let debounceCustomerId: any

    const handleSortChange = (newSort: SelectInputOption) => {
        const { value } = newSort
        tagManager.tags.contactsSort(value)
        updateFilters({ ...filters, sort: value, page: initialPage })
    }

    const customerEmailFilterLogging = (filter: string) => {
        if (debounceCustomerEmail) {
            debounceCustomerEmail.clear()
        }
        debounceCustomerEmail = debounce(() => tagManager.tags.searchUsername(filter), 1000)
        debounceCustomerEmail()
    }

    const customerIdFilterLogging = (filter: string) => {
        if (debounceCustomerId) {
            debounceCustomerId.clear()
        }
        debounceCustomerId = debounce(() => tagManager.tags.searchCustomerId(filter), 1000)
        debounceCustomerId()
    }

    const handleFilterChange = (newFilters: UserFilters) => {
        if (newFilters.email !== filters.email && newFilters.email) {
            customerEmailFilterLogging(newFilters.email)
        }

        if (newFilters.customerId !== filters.customerId && newFilters.customerId) {
            customerIdFilterLogging(newFilters.customerId)
        }
        updateFilters({ ...newFilters, sort, page: initialPage })
    }

    const handleSendPasswordEmailClick = (user: GQLUser, unreachable: boolean = false) => {
        forgotPassword({
            variables: {
                email: user.email,
                values: { unreachable },
            },
        })
    }

    const handleSendWelcomeEmailClick = (user: GQLUser, unreachable: boolean = false) => {
        sendWelcomeEmail({
            variables: {
                userID: user.id,
                values: { unreachable },
            },
        })
    }

    const handleDeleteUser = async (user: GQLUser) => {
        await deleteUser({
            variables: {
                userID: user.id,
            },
        })
    }

    const handleUpdateUser = async (user: GQLUser, values: GQLUpdateUserInput) => {
        await updateUser({
            variables: {
                userID: user.id,
                values,
            },
        })
    }

    const handleCreateUser = async (values: GQLCreateUserInput) => {
        await createUser({
            variables: {
                values,
            },
        })
    }

    const handleEmailAvailable = async (userEmail: string): Promise<string | undefined> => {
        const result = await client.query({
            query: FindUserByEmail,
            variables: { email: userEmail.toLowerCase() },
            fetchPolicy: 'no-cache',
        })

        return Boolean(result?.data?.userByEmail) ? t('form-validation:email-in-use') : undefined
    }

    const sortOptions: SelectInputOption[] = [
        {
            value: 'lastLogin',
            label: `${t('user:sort:logindate')} ${t('sort-direction:date:asc')}`,
        },
        {
            value: '-lastLogin',
            label: `${t('user:sort:logindate')} ${t('sort-direction:date:desc')}`,
        },
        {
            value: 'email',
            label: `${t('user:sort:username')} ${t('sort-direction:string:asc')}`,
        },
        {
            value: '-email',
            label: `${t('user:sort:username')} ${t('sort-direction:string:desc')}`,
        },
    ]

    const filterProviderProps: FiltersContextData<UserFilters> = {
        onFilterChange: handleFilterChange,
        filters: initialFilters,
        query: filters,
    }
    const sortProviderProps: SortContextData = {
        onSortChange: handleSortChange,
        sort,
        sortOptions,
    }

    return (
        <FiltersProvider {...filterProviderProps}>
            <SortProvider {...sortProviderProps}>
                <FullScreenLoaderRound isVisible={loading} />
                <UserManagementPage
                    error={error}
                    users={users}
                    pageTitle={pageTitle}
                    onSendPasswordEmailClick={handleSendPasswordEmailClick}
                    onSendWelcomeEmailClick={handleSendWelcomeEmailClick}
                    onUpdateUser={handleUpdateUser}
                    onCreateUser={handleCreateUser}
                    onDeleteUser={handleDeleteUser}
                    onEmailAvailable={handleEmailAvailable}
                    loading={updateUserLoading || createUserLoading || deleteUserLoading}
                    limit={MAX_SEARCH_RESULTS}
                    count={usersCount}
                />
            </SortProvider>
        </FiltersProvider>
    )
}

export default withRouter(MyCompaniesPageContainer)
