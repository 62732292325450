import React, { useContext, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import styled from 'styled-components'

import { RegionFeatures } from '@bc/config'
import { getCsrEmail } from '@bc/graphql-utils/src/user'
import { GQLSalesOrganisationId } from '@bc/types'
import {
    colors,
    EmptyState,
    media,
    nl2br,
    OxygenTemplate,
    spacing,
    SvgIcon,
    Text,
    useDocumentTitle,
    Hr,
    ButtonTo,
} from '@bc/ui'
import { useFormatMessage } from '@frontend/components'
import { trimLeadingZeros } from '@frontend/utils'
import { Feature } from '@frontend/components/customer'
import { SessionContext, SessionContextData, ShoppingCartContext, UserContext } from '@frontend/context'

const IconWrapper = styled.span`
    align-items: center;
    padding: 16px;
    border: 3px solid ${colors.green50};
    width: 80px;
    display: inline-block;
    margin: ${spacing.sm}px auto;
    height: 80px;
    border-radius: 40px;
`

const Table = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    ${media.max('sm')} {
        flex-direction: column;
    }
    justify-content: space-between;
`
const TableCell = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 0 25%;
    justify-content: flex-start;
    text-align: left;
    padding: ${spacing.xs}px;
    &:first-child {
        padding-left: 0px;
    }
    &:last-child {
        padding-right: 0px;
    }
`

const TableHeader = styled.div`
    color: ${colors.neutral50};
    margin-bottom: ${spacing.xxs}px;
`

const TableContent = styled.div`
    color: ${colors.neutral90};
`

const ContinueButton = styled(ButtonTo).attrs({ buttonSize: 'medium', variant: 'solid' })`
    margin: ${spacing.xs}px auto;
`

interface SuccessPageLocationState {
    orderId: string
    orderDate: string
}

const SuccessPageComponent = ({ history, location }: RouteComponentProps<any, any, SuccessPageLocationState>) => {
    const t = useFormatMessage()

    const pageTitle = t('checkout-success:order-submitted')
    useDocumentTitle(pageTitle)
    const { orderId, orderDate } = location.state ?? {}
    const { me, currentCustomer } = useContext(UserContext)
    const [, sessionState]: SessionContextData = useContext(SessionContext)
    const [shoppingStore] = useContext(ShoppingCartContext)
    const { to: email } = getCsrEmail(me!.meta, sessionState.activeSalesOrganisation as GQLSalesOrganisationId)

    useEffect(() => {
        if (shoppingStore.length === 0 || !orderId) {
            history.replace(t('route:order-history'), { page: 1, sort: '-baseOrderDate' })
        } else {
            shoppingStore.clear()
        }
    }, [orderId])

    return (
        <OxygenTemplate>
            {orderId && (
                <EmptyState title={t('checkout-success:order-submitted')} center>
                    <IconWrapper>
                        <SvgIcon size="40" icon="Circle_Checkmark" fill={colors.green50} />
                    </IconWrapper>
                    <Hr />
                    <Table>
                        <TableCell>
                            <TableHeader>{t('checkout-success:order-number')}</TableHeader>
                            <TableContent data-test-id="text-order-id">{orderId}</TableContent>
                        </TableCell>
                        <TableCell>
                            <TableHeader>{t('checkout-success:order-date')}</TableHeader>
                            <TableContent data-test-id="text-order-date">{orderDate}</TableContent>
                        </TableCell>
                        <TableCell>
                            <TableHeader>{t('my-customers:customer-id')}</TableHeader>
                            <TableContent data-test-id="text-customer-id">
                                {trimLeadingZeros(currentCustomer?.id ?? '-')}
                            </TableContent>
                        </TableCell>
                        <TableCell>
                            <TableHeader>{t('my-customers:customer-name')}</TableHeader>
                            <TableContent data-test-id="text-customer-name">{currentCustomer?.name}</TableContent>
                        </TableCell>
                    </Table>
                    <Hr />
                    <Text p>{nl2br(t('checkout-success:final-instruction', undefined, { email }))}</Text>

                    <Feature
                        flag={RegionFeatures.Requests}
                        on={
                            <ContinueButton to={t('route:requests')} data-test-id="button-continue-requests">
                                {t('checkout-success:button-view-requests')}
                            </ContinueButton>
                        }
                        off={
                            <ContinueButton to={t('route:order-history')} data-test-id="button-continue-orders">
                                {t('checkout-success:button-view-orders')}
                            </ContinueButton>
                        }
                    />
                </EmptyState>
            )}
        </OxygenTemplate>
    )
}

export const SuccessPage = withRouter(SuccessPageComponent)
