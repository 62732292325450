import React, { createContext, useEffect, useState } from 'react'

import { ToastsState, ToastsStore } from '@frontend/stores/toasts'

interface ToastsProviderProps {
    children: JSX.Element
}

export type ToastsContextData = [ToastsStore]

const toastsStoreInstance = new ToastsStore()
const initialContext: ToastsContextData = [toastsStoreInstance]
export const ToastsContext = createContext(initialContext)

export const ToastsProvider = (props: ToastsProviderProps) => {
    const [, setToasts] = useState<ToastsState>()

    const notify = (data: ToastsState) => {
        setToasts(data)
    }

    useEffect(() => {
        toastsStoreInstance.init({ notify })
    }, [])

    return <ToastsContext.Provider value={[toastsStoreInstance]} {...props} />
}
