import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { QueryResult } from '@apollo/react-common'
import { useQuery } from '@apollo/react-hooks'

import debounce from 'debounce'

import { tagManager } from '@bc/gtm'
import { GQLInvoiceFilters } from '@bc/types'
import { FullScreenLoaderRound, SelectInputOption } from '@bc/ui'
import { useCopyToClipboard, useFormatMessage } from '@frontend/components'
import { FiltersContextData, FiltersProvider, SortContextData, SortProvider, UserContext } from '@frontend/context'

import { useFilters } from '@frontend/components/hooks/use-filters'
import { InvoicesPage } from './invoices-page'
import { getInvoices, GetInvoicesResponse } from './queries'

type InvoicePageQuery = GQLInvoiceFilters

const initialPage = 1
const initialSort = '-invoiceDate'
const initialFilters: GQLInvoiceFilters = {}

const InvoicesContainerClass = () => {
    const t = useFormatMessage()
    const { activeCustomerId = '', activeSalesOrganisation = '', activeSalesAreaId = '' } = useContext(UserContext)
    const copyToClipboard = useCopyToClipboard()

    const { filters, page = initialPage, sort = initialSort, updateFilters } = useFilters<
        GQLInvoiceFilters,
        InvoicePageQuery
    >()

    const variables = {
        page,
        pageSize: 10,
        sort,
        filters,
    }
    const { error, loading, data }: QueryResult<GetInvoicesResponse> = useQuery(getInvoices, {
        variables,
    })
    const invoices = data?.invoices?.invoices
    const pagination = data?.invoices?.pagination ?? {
        count: 0,
        page: 1,
        pages: 1,
    }

    useEffect(() => {
        // reset to first page if more than available results is requested in the url
        if (invoices && page > pagination.pages) {
            updateFilters({ ...filters, sort, page: pagination.page })
        }
    }, [invoices])

    let debounceInvoiceId: any
    let debouncePo: any

    const handleSortChange = (newSort: SelectInputOption) => {
        const { value } = newSort
        tagManager.tags.invoicesSort(value)
        updateFilters({ ...filters, sort: value, page: initialPage })
    }

    const handlePageClick = (newPage: number) => {
        updateFilters({ ...filters, sort, page: newPage })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    const invoiceIdFilter = (filter: string) => {
        if (debounceInvoiceId) {
            debounceInvoiceId.clear()
        }
        debounceInvoiceId = debounce(() => tagManager.tags.invoiceFilterByInvoiceId(filter), 1000)
        debounceInvoiceId()
    }

    const customerPoFilter = (filter: string) => {
        if (debouncePo) {
            debouncePo.clear()
        }
        debouncePo = debounce(() => tagManager.tags.invoiceFilterByPo(filter), 1000)
        debouncePo()
    }

    const handleFilterChange = (newFilters: GQLInvoiceFilters): void => {
        if (newFilters.customerPoReferences !== filters.customerPoReferences && newFilters.customerPoReferences) {
            customerPoFilter(newFilters.customerPoReferences)
        }

        if (newFilters.invoiceDisplayId !== filters.invoiceDisplayId && newFilters.invoiceDisplayId) {
            invoiceIdFilter(newFilters.invoiceDisplayId)
        }

        updateFilters({ ...newFilters, sort, page: initialPage })
    }

    const handleCopyRequestLink = (_formFilters: GQLInvoiceFilters) => {
        copyToClipboard(
            `${window.location.href}&cid=${activeCustomerId}&so=${activeSalesOrganisation}&sid=${activeSalesAreaId}`,
        )
    }

    const sortOptions: SelectInputOption[] = [
        {
            value: 'invoiceDate',
            label: `${t('sort-by-invoice-date')} ${t('sort-direction:date:asc')}`,
        },
        {
            value: '-invoiceDate',
            label: `${t('sort-by-invoice-date')} ${t('sort-direction:date:desc')}`,
        },
        {
            value: 'dueDate',
            label: `${t('sort-by-due-date')} ${t('sort-direction:date:asc')}`,
        },
        {
            value: '-dueDate',
            label: `${t('sort-by-due-date')} ${t('sort-direction:date:desc')}`,
        },
        {
            value: 'invoiceDisplayId',
            label: `${t('sort-by-invoice-id')} ${t('sort-direction:numeric:asc')}`,
        },
        {
            value: '-invoiceDisplayId',
            label: `${t('sort-by-invoice-id')} ${t('sort-direction:numeric:desc')}`,
        },
    ]

    const filterProviderProps: FiltersContextData<GQLInvoiceFilters> = {
        onFilterChange: handleFilterChange,
        filters: initialFilters,
        query: filters,
        onLinkCopy: handleCopyRequestLink,
    }
    const sortProviderProps: SortContextData = {
        onSortChange: handleSortChange,
        sort,
        sortOptions,
    }

    return (
        <FiltersProvider {...filterProviderProps}>
            <SortProvider {...sortProviderProps}>
                <FullScreenLoaderRound isVisible={loading} />
                <InvoicesPage error={error} pagination={pagination} invoices={invoices} onPageClick={handlePageClick} />
            </SortProvider>
        </FiltersProvider>
    )
}

export const InvoicesContainer = withRouter(InvoicesContainerClass)
