import React from 'react'

import { GQLCustomer, GQLMainSalesArea } from '@bc/types'
import { AnimatedList, Text } from '@bc/ui'

import { useFormatMessage } from '../hooks'
import { CompanyCard } from './company-card'
import * as SearchComponents from './components'

interface SearchCustomerResultProps {
    data: GQLCustomer[] | undefined
    error: any
    onSelectCustomer: (customer: GQLCustomer, mainSalesArea: GQLMainSalesArea) => void
    highlight?: string
}
export const SearchCustomerResult = ({ data, error, onSelectCustomer, highlight }: SearchCustomerResultProps) => {
    const t = useFormatMessage()

    if (data) {
        return data.length > 0 ? (
            <AnimatedList>
                {data.map(customer =>
                    customer.mainSalesAreas.map(area => (
                        <CompanyCard
                            key={area.salesAreaId}
                            customer={customer}
                            mainSalesArea={area}
                            onSelectCustomer={onSelectCustomer}
                            highlight={highlight}
                        />
                    )),
                )}
            </AnimatedList>
        ) : (
            <SearchComponents.FormCard theme="light">
                <Text p noMargin data-test-id="text-no-company-found">
                    {t('not-found:customers')}
                </Text>
            </SearchComponents.FormCard>
        )
    }

    if (error) {
        return (
            <SearchComponents.FormCard theme="light">
                <Text p noMargin data-test-id="text-company-search-error">
                    {t('my-customers:search-error')}
                </Text>
            </SearchComponents.FormCard>
        )
    }

    return null
}
