import styled from 'styled-components'

import { media, spacing } from '@bc/ui'

export const LinkWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 64px;

    ${media.min('sm')} {
        flex-direction: row;
        align-items: center;
    }

    & a {
        display: inline;
    }
`

export const Container = styled.footer`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 50px;
    ${media.max('md')} {
        padding: 0 20px;
        flex-direction: column;
    }
    gap: ${spacing.sm}px;
`

export const LanguageSelectorContainer = styled.div`
    width: 200px;
    display: flex;
    flex: 1 1 auto;
    padding: 14px; // same as A-links
`
