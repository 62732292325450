import React from 'react'
import styled from 'styled-components'

import { GQLPackagingQuantity, GQLPrice } from '@bc/types'
import { Text, LoaderRound } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { PriceScaleWithPackaging } from '@frontend/components/price-scale'
import { ModalGeneralConditions } from './modal-general-conditions'

const WrapperExtraMargin = styled.div`
    margin-bottom: 30px;
`

const StatusWrapper = styled.div`
    display: flex;
    min-height: 20px;
`

interface ModalPriceInfoProps {
    prices?: GQLPrice[]
    packagingQuantity: GQLPackagingQuantity
    isLoading?: boolean
    noTerms?: boolean
    deliveryDate: string | undefined
}

export const ModalPricesInfo = ({
    packagingQuantity,
    prices,
    isLoading,
    noTerms,
    deliveryDate,
}: ModalPriceInfoProps) => {
    const t = useFormatMessage()

    return (
        <WrapperExtraMargin>
            <Text caption>{t('product-modal:scale-prices')}</Text>
            {isLoading ? (
                <StatusWrapper>
                    <LoaderRound colorScheme="neutral" />
                </StatusWrapper>
            ) : deliveryDate && prices ? (
                prices.length > 0 ? (
                    <>
                        <PriceScaleWithPackaging footNote prices={prices} packagingQuantity={packagingQuantity} />
                        {'* '}
                        {t('delivery-overview:legal-terms-price')}
                    </>
                ) : (
                    <StatusWrapper>
                        <Text p>{t('request-information-modal:reorder:no-prices-available')}</Text>
                    </StatusWrapper>
                )
            ) : (
                <StatusWrapper>
                    <Text p>{t('request-information-modal:reorder:select-delivery-date')}</Text>
                </StatusWrapper>
            )}
            {!noTerms && <ModalGeneralConditions />}
        </WrapperExtraMargin>
    )
}
