import React from 'react'
import styled from 'styled-components'

import { ButtonPropsForButton } from '@bc/ui/src/components/button/button-types'
import { media } from '@bc/ui/src/helpers/media'
import { Button } from './button'

interface AddToCartButtonBaseProps {
    inCart?: boolean
}

const ButtonWidth100Mobile = styled(Button)`
    ${media.max('sm')} {
        width: 100%;
    }
`

type AddToCartButtonProps = ButtonPropsForButton & AddToCartButtonBaseProps

export const AddToCartButton: React.SFC<AddToCartButtonProps> = ({ inCart, ref, ...rest }) => (
    <ButtonWidth100Mobile
        icon={inCart ? 'Checkmark' : 'Cart'}
        variant="action"
        data-test-id="button-add-to-cart"
        {...rest}
    />
)
