import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const AddTruck: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="AddTruck" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M6 9H4V7H2V5H4V3H6V5H8V7H6V9ZM16 7H19.54L22 10.7V18H21C21 19.65 19.65 21 18 21C16.35 21 15 19.65 15 18H9C9 19.65 7.65 21 6 21C4.35 21 3 19.65 3 18H2V11H4V15.78C4.53 15.3 5.23 15 6 15C6.88 15 7.67 15.39 8.22 16H14V7H10V5H16V7ZM5 18C5 18.55 5.45 19 6 19C6.55 19 7 18.55 7 18C7 17.45 6.55 17 6 17C5.45 17 5 17.45 5 18ZM20 11.3L18.46 9H16V15.78C16.53 15.3 17.23 15 18 15C18.77 15 19.47 15.3 20 15.78V11.3ZM17 18C17 18.55 17.45 19 18 19C18.55 19 19 18.55 19 18C19 17.45 18.55 17 18 17C17.45 17 17 17.45 17 18Z"
                fill={fill === 'gradient' ? 'url(#AddTruck_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="AddTruck" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="AddTruck" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M6 9H4V7H2V5H4V3H6V5H8V7H6V9ZM16 7H19.54L22 10.7V18H21C21 19.65 19.65 21 18 21C16.35 21 15 19.65 15 18H9C9 19.65 7.65 21 6 21C4.35 21 3 19.65 3 18H2V11H4V15.78C4.53 15.3 5.23 15 6 15C6.88 15 7.67 15.39 8.22 16H14V7H10V5H16V7ZM5 18C5 18.55 5.45 19 6 19C6.55 19 7 18.55 7 18C7 17.45 6.55 17 6 17C5.45 17 5 17.45 5 18ZM20 11.3L18.46 9H16V15.78C16.53 15.3 17.23 15 18 15C18.77 15 19.47 15.3 20 15.78V11.3ZM17 18C17 18.55 17.45 19 18 19C18.55 19 19 18.55 19 18C19 17.45 18.55 17 18 17C17.45 17 17 17.45 17 18Z"
                fill={fill === 'gradient' ? 'url(#AddTruck_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="AddTruck" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="AddTruck" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M6 9H4V7H2V5H4V3H6V5H8V7H6V9ZM16 7H19.54L22 10.7V18H21C21 19.65 19.65 21 18 21C16.35 21 15 19.65 15 18H9C9 19.65 7.65 21 6 21C4.35 21 3 19.65 3 18H2V11H4V15.78C4.53 15.3 5.23 15 6 15C6.88 15 7.67 15.39 8.22 16H14V7H10V5H16V7ZM5 18C5 18.55 5.45 19 6 19C6.55 19 7 18.55 7 18C7 17.45 6.55 17 6 17C5.45 17 5 17.45 5 18ZM20 11.3L18.46 9H16V15.78C16.53 15.3 17.23 15 18 15C18.77 15 19.47 15.3 20 15.78V11.3ZM17 18C17 18.55 17.45 19 18 19C18.55 19 19 18.55 19 18C19 17.45 18.55 17 18 17C17.45 17 17 17.45 17 18Z"
                fill={fill === 'gradient' ? 'url(#AddTruck_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="AddTruck" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="AddTruck" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M6 9H4V7H2V5H4V3H6V5H8V7H6V9ZM16 7H19.54L22 10.7V18H21C21 19.65 19.65 21 18 21C16.35 21 15 19.65 15 18H9C9 19.65 7.65 21 6 21C4.35 21 3 19.65 3 18H2V11H4V15.78C4.53 15.3 5.23 15 6 15C6.88 15 7.67 15.39 8.22 16H14V7H10V5H16V7ZM5 18C5 18.55 5.45 19 6 19C6.55 19 7 18.55 7 18C7 17.45 6.55 17 6 17C5.45 17 5 17.45 5 18ZM20 11.3L18.46 9H16V15.78C16.53 15.3 17.23 15 18 15C18.77 15 19.47 15.3 20 15.78V11.3ZM17 18C17 18.55 17.45 19 18 19C18.55 19 19 18.55 19 18C19 17.45 18.55 17 18 17C17.45 17 17 17.45 17 18Z"
                fill={fill === 'gradient' ? 'url(#AddTruck_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="AddTruck" />}
        </g>
    ),
}
