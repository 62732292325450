import React, { useContext } from 'react'

import { StyledA, Text } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { UserContext } from '@frontend/context'
import { getPrivacyPolicyUrl } from '@bc/graphql-utils/src/user'

export const ModalGeneralConditions = () => {
    const t = useFormatMessage()
    const { me } = useContext(UserContext)

    return (
        <Text p small>
            <StyledA color="neutral90" href={getPrivacyPolicyUrl(me?.meta)} target="_blank" rel="noopener noreferrer">
                {t('delivery-overview:legal-terms-name')}
            </StyledA>
            {t('delivery-overview:legal-terms')}
        </Text>
    )
}
