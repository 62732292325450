import React from 'react'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'

import { NotFoundPage } from '@frontend/pages/error'

import { OverviewPage } from './overview'
import { SuccessPage } from './success'

export const CartCheckout = ({ match: { url } }: RouteComponentProps) => (
    <Switch>
        <Route path={`${url}`} exact component={OverviewPage} />
        <Route path={`${url}/success/`} component={SuccessPage} />
        <Route component={NotFoundPage} />
    </Switch>
)
