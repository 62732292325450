import React, { useContext, useMemo, useState } from 'react'

import { FormState } from 'final-form'
import { Field, Form, FormSpy } from 'react-final-form'
import { OptionsType } from 'react-select'

import { ShopFeatures } from '@bc/config'
import graphqlUtils from '@bc/graphql-utils'
import { debug } from '@bc/logging'
import { GQLCheckoutTerms, GQLLocation } from '@bc/types'
import {
    Button,
    Card,
    Checkbox,
    Column,
    getLocationAddressLine,
    Hr,
    SelectInput,
    SelectInputOption,
    StyledA,
    Text,
    TextInput,
    TextWrapper,
    Tooltip,
    selectFindOption,
    FormInputComponents,
} from '@bc/ui'
import { Datepicker } from '@frontend/components/datepicker'
import { AttachFileField, ListAttachedFiles } from '@frontend/components/forms/attach-file'
import { Feature } from '@frontend/components/customer'
import { FormatMessage, useFormatMessage } from '@frontend/components/hooks'
import { PlatformConfigContext, UserContext } from '@frontend/context'
import { finalFormValidations, getDateFormatLocalized } from '@frontend/utils'

import { getTaCUrl } from '@bc/graphql-utils/src/user'
import {
    AddressSummary,
    customerReferenceRequired,
    deliveryDateValid,
    HelperWrapper,
    isDeliveryDateValid,
    createPickupDecorator,
} from '../_shared'
import * as DeliveryOverviewComponent from './delivery-overview-component'
import { DeliveryOverviewFormValues } from './delivery-overview-form.types'

interface DeliveryOverviewFormProps {
    onSubmit: (values: DeliveryOverviewFormValues) => void
    onUpdate: (values: DeliveryOverviewFormValues) => void
    loading: boolean
    terms: GQLCheckoutTerms | undefined
}

const isFormValidWithoutTerms = ({
    customerPoReference,
    requestedDeliveryDate,
    shipToId,
    pickup,
}: DeliveryOverviewFormValues) => customerPoReference && requestedDeliveryDate && (shipToId || pickup)

const initialFormValues: DeliveryOverviewFormValues = {
    shipToId: undefined,
    requestedDeliveryDate: undefined,
    customerPoReference: undefined,
    termsAccepted: false,
    pickup: false,
}

const shipToDecorator = createPickupDecorator('shipToId')
const noShipTos: GQLLocation[] = []

export const DeliveryOverviewForm = ({ onSubmit, onUpdate, loading, terms }: DeliveryOverviewFormProps) => {
    const t: FormatMessage = useFormatMessage()
    const { currentCustomer } = useContext(UserContext)
    const { me } = useContext(UserContext)
    const {
        appConfig: { deliveryDateRestrictions, dateFormat },
    } = useContext(PlatformConfigContext)
    const [deliveryDateCalendarOpen, setDeliveryDateCalendarOpen] = useState(false)

    const shipTos = currentCustomer?.shipTos ?? noShipTos
    const firstEligibleDeliveryDate = graphqlUtils.order.getFirstEligibleDeliveryDate(
        deliveryDateRestrictions.reorderDate,
    )
    const lastEligibleDeliveryDate = graphqlUtils.order.getLastEligibleDeliveryDate(deliveryDateRestrictions.endDate)
    const hideCalendar = () => {
        setDeliveryDateCalendarOpen(false)
    }

    const showCalendar = () => {
        setDeliveryDateCalendarOpen(true)
    }

    const shipToOptions: OptionsType<SelectInputOption> = useMemo(
        () =>
            shipTos.map((location: GQLLocation) => ({
                value: location.id,
                label: getLocationAddressLine(location),
            })),
        [shipTos],
    )

    const onSubmitForm = (values: DeliveryOverviewFormValues) => {
        // Terms check is out of the general form validation because we block the submit button when other fields are unavailable but in case of conditions — show a warning label
        if (!values.termsAccepted) {
            debug.frontend('Terms not accepted, not submitting the form!')
            return { termsAccepted: t('delivery-overview:agree-with-terms-warning') }
        }

        return onSubmit(values)
    }

    const validateShipToId = (value: string, values: DeliveryOverviewFormValues) => {
        if (!values.pickup) {
            return finalFormValidations.required(t)(value)
        }
        return undefined
    }

    const autoSelectOneShipTo = shipToOptions.length === 1 ? shipToOptions[0].value : undefined

    return (
        <Form initialValues={initialFormValues} onSubmit={onSubmitForm} decorators={[shipToDecorator]}>
            {({ handleSubmit, values, values: { pickup, file }, form, hasSubmitErrors }) => (
                <DeliveryOverviewComponent.DeliveryFormWrapper onSubmit={handleSubmit}>
                    <DeliveryOverviewComponent.Wrapper>
                        <Card theme="light">
                            <Text h3>{t('delivery-overview:title')}</Text>
                            <FormInputComponents.InputWrapper>
                                <Field
                                    name="shipToId"
                                    initialValue={autoSelectOneShipTo}
                                    parse={option => option?.value}
                                    validate={validateShipToId}
                                    render={({ input }) => {
                                        const { value, ...restInput } = input
                                        const selectedOptions = selectFindOption(shipToOptions, value)
                                        const selectedLocation = shipTos.find(
                                            shipToLocation => shipToLocation.id === value,
                                        )

                                        return (
                                            <>
                                                <SelectInput
                                                    {...restInput}
                                                    value={selectedOptions}
                                                    labelText={t('filters:select-ship-to-address') + ' *'}
                                                    placeholder={t('filters:select-an-address')}
                                                    IsSearchable
                                                    isClearable
                                                    isDisabled={pickup}
                                                    options={shipToOptions}
                                                    noOptionsMessage={() => t('not-found:select')}
                                                    classNamePrefix="select-ship-to-address"
                                                    data-test-id="select-ship-to-address"
                                                />

                                                <AddressSummary location={selectedLocation} />
                                            </>
                                        )
                                    }}
                                />

                                <Feature
                                    flag={ShopFeatures.OrderPickup}
                                    on={
                                        <Field
                                            name="pickup"
                                            type="checkbox"
                                            validateFields={['shipToId']}
                                            render={({ input }) => (
                                                <Checkbox
                                                    {...input}
                                                    id="pickup"
                                                    labelText={t('request-information-modal:reorder:self-pickup')}
                                                    data-test-id="checkbox-pickup"
                                                    colorScheme="deepPurple"
                                                />
                                            )}
                                        />
                                    }
                                />

                                <Field
                                    name="requestedDeliveryDate"
                                    validate={deliveryDateValid(
                                        t,
                                        firstEligibleDeliveryDate,
                                        lastEligibleDeliveryDate,
                                        dateFormat,
                                    )}>
                                    {({ input, meta }) => (
                                        <Datepicker
                                            open={deliveryDateCalendarOpen}
                                            initialMonth={firstEligibleDeliveryDate}
                                            onClickOutside={event => {
                                                const element = event.target as HTMLElement
                                                const elementName =
                                                    element && element.getAttribute && element.getAttribute('name')

                                                if (elementName !== input.name) {
                                                    hideCalendar()
                                                }
                                            }}
                                            onChange={(date, parsedDate) => {
                                                if (
                                                    parsedDate &&
                                                    isDeliveryDateValid(
                                                        t,
                                                        firstEligibleDeliveryDate,
                                                        lastEligibleDeliveryDate,
                                                        dateFormat,
                                                        date,
                                                    )
                                                ) {
                                                    input.onChange(date as any)
                                                    hideCalendar()
                                                }
                                            }}
                                            disabledDays={[
                                                { after: lastEligibleDeliveryDate },
                                                { before: firstEligibleDeliveryDate },
                                                { daysOfWeek: [0, 6] },
                                            ]}
                                            render={({ datepickerInput }) => (
                                                <TextInput
                                                    {...datepickerInput(input)}
                                                    onFocus={e => {
                                                        showCalendar()
                                                        input.onFocus(e)
                                                    }}
                                                    labelText={
                                                        (pickup
                                                            ? t('request-information-modal:requested-pickup-date')
                                                            : t('request-information-modal:requested-delivery-date')) +
                                                        ' *'
                                                    }
                                                    // Todo: use moment to get placeholders
                                                    placeholder={getDateFormatLocalized(t, dateFormat)}
                                                    type="text"
                                                    autoComplete="off"
                                                    hasError={input.value && meta.touched && meta.error}
                                                    errorText={input.value && meta.touched && meta.error}
                                                    data-test-id="input-delivery-date"
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                                <Field name="customerPoReference" validate={customerReferenceRequired(t, 35)}>
                                    {({ input, meta }) => (
                                        <>
                                            <TextInput
                                                {...input}
                                                maxLength={35}
                                                labelText={
                                                    <TextWrapper noMargin>
                                                        {t('general:po-reference') + ' *'}
                                                        <Tooltip placement="top">
                                                            {t('general:po-reference-info')}
                                                        </Tooltip>
                                                    </TextWrapper>
                                                }
                                                type="text"
                                                hasError={meta.touched && meta.error}
                                                errorText={meta.error}
                                                data-test-id="input-po-reference"
                                                append={<AttachFileField change={form.change} />}
                                            />
                                            <ListAttachedFiles file={file} change={form.change} />
                                        </>
                                    )}
                                </Field>

                                <Field name="comment" validate={finalFormValidations.maxLength(t, 132)}>
                                    {({ input, meta }) => (
                                        <>
                                            <TextInput
                                                {...input}
                                                textarea
                                                maxLength={132}
                                                labelText={t('delivery-overview:comment')}
                                                placeholder={t('general:type-here')}
                                                type="text"
                                                hasError={meta.touched && meta.error}
                                                errorText={meta.error}
                                                data-test-id="input-comments"
                                            />
                                            {t('general:required-fields')}
                                        </>
                                    )}
                                </Field>

                                {terms?.incoTermId && (
                                    <Column>
                                        <Text caption>{t('delivery-overview:INCO-terms')}</Text>
                                        <Text p>{terms.incoTermId}</Text>
                                    </Column>
                                )}

                                {terms?.paymentTerms && (
                                    <Column>
                                        <Text caption>{t('delivery-overview:payment-terms')}</Text>
                                        <Text p>{terms.paymentTerms}</Text>
                                    </Column>
                                )}

                                <Hr small />

                                <Field name="termsAccepted" type="checkbox">
                                    {({ input, meta }) => {
                                        const { onChange, ...restInput } = input
                                        return (
                                            <>
                                                <Checkbox
                                                    {...restInput}
                                                    onChange={e => {
                                                        if (hasSubmitErrors && input.checked) {
                                                            form.resetFieldState('termsAccepted')
                                                        }
                                                        onChange(e)
                                                    }}
                                                    id="terms-and-conditions-checkbox"
                                                    labelText={
                                                        <Text color="neutral50">
                                                            {t('delivery-overview:agree-with-terms') + ' '}
                                                            <StyledA
                                                                color="neutral90"
                                                                href={getTaCUrl(me!.meta)}
                                                                target="_blank"
                                                                rel="noopener noreferrer">
                                                                {t('delivery-overview:legal-terms-name')}
                                                            </StyledA>
                                                        </Text>
                                                    }
                                                    value="terms_conditions"
                                                    colorScheme="deepPurple"
                                                    hasError={meta.submitError}
                                                />

                                                {meta.submitError && !input.checked && (
                                                    <HelperWrapper>
                                                        <Text noMargin color={'red70'}>
                                                            {meta.submitError}
                                                        </Text>
                                                    </HelperWrapper>
                                                )}
                                            </>
                                        )
                                    }}
                                </Field>

                                <Button
                                    disabled={!isFormValidWithoutTerms(values)}
                                    type="submit"
                                    variant="action"
                                    buttonSize="large"
                                    fullOnMobile
                                    fullWidth
                                    icon="Checkmark"
                                    isLoading={loading}
                                    title={t('delivery-overview:submit')}
                                    data-test-id="button-submit">
                                    {t('delivery-overview:submit')}
                                </Button>
                            </FormInputComponents.InputWrapper>
                        </Card>
                    </DeliveryOverviewComponent.Wrapper>
                    <FormSpy
                        subscription={{ values: true, dirty: true }}
                        onChange={(state: FormState<DeliveryOverviewFormValues>) => {
                            if (state.dirty) {
                                onUpdate(state.values)
                            }
                        }}
                    />
                </DeliveryOverviewComponent.DeliveryFormWrapper>
            )}
        </Form>
    )
}
