import React from 'react'

import { colors, Dropdown, ItemLink as DropdownItemLink, SvgIcon } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import * as NavComponents from './navigation-components'

export const NavigationDropdown = () => {
    const t = useFormatMessage()
    return (
        <Dropdown
            items={[
                {
                    text: t('account:profile'),
                    to: t('route:account'),
                    id: 'account',
                },
                {
                    to: t('route:logout'),
                    text: t('route:logout.label'),
                    id: 'logout',
                },
            ]}
            itemToComponent={(item: { to: string; id: string; text: string }, itemIndex) => (
                <DropdownItemLink to={item.to} data-test-id={`link-${item.id}`} key={itemIndex}>
                    {item.text}
                </DropdownItemLink>
            )}>
            {({ getToggleButtonProps }) => (
                <NavComponents.ProfileButton {...getToggleButtonProps()} data-test-id="button-user-menu">
                    <SvgIcon size="24" icon="User" fill={colors.neutral90} />
                    <NavComponents.ProfileText>{t('account:profile')}</NavComponents.ProfileText>
                    <SvgIcon size="20" icon="Chevron_Down" fill={colors.neutral90} />
                </NavComponents.ProfileButton>
            )}
        </Dropdown>
    )
}
