import { EventTag, VirtualPageviewTag } from '../types'
import { dataLayer, formatSorting } from '../utils'

export const requestsSearchNumber = (filter: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.requestsSearchNumber',
        virtualURL: `/requestssearchnumber?q=${filter}&n=requestssearchnumberbar`,
        virtualTitle: 'requests search - search number',
    })
}

export const requestsFilterByProductName = (name: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.requestsSearchProductName',
        virtualURL: `/requestssearchproductname?q=${name}&n=requestssearchproductnamebar`,
        virtualTitle: 'requests search - search product name',
    })
}

export const requestsFilter = (filter: string) => {
    dataLayer<EventTag>({
        event: 'gtm.requestsFilter',
        eventCategory: 'requests',
        eventAction: 'filter',
        eventLabel: filter,
    })
}

export const requestsSort = (option: string) => {
    dataLayer<EventTag>({
        event: 'gtm.requestsSort',
        eventCategory: 'requests',
        eventAction: 'sort',
        eventLabel: formatSorting(option),
    })
}

export const requestsEmail = (email: string) => {
    dataLayer<EventTag>({
        event: 'gtm.email',
        eventCategory: 'click',
        eventAction: 'email app',
        eventLabel: email,
    })
}

export const requestsDetails = (uid: string) => {
    dataLayer<EventTag>({
        event: 'gtm.requestsDetails',
        eventCategory: 'requests',
        eventAction: 'click details',
        eventLabel: uid,
    })
}

export const requestsDocumentDownload = (uid: string) => {
    dataLayer<EventTag>({
        event: 'gtm.requestsDownload',
        eventCategory: 'requests',
        eventAction: 'download',
        eventLabel: uid,
    })
}
