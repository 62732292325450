import { getFormattedOrderQuantity, getFormattedQuantity } from '@frontend/utils/get-material-quantity'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { GQLRequestMaterial, GQLRequestType, GQLRequestValues, GQLRequestsFilters } from '@bc/types'

import { colors, RichRowList, RichRowListItemProps, spacing, Text } from '@bc/ui'

import {
    useFormatMessage,
    useFormatNumber,
    useFormatPackaging,
    useFormatUom,
} from '@frontend/components/hooks/intl-hooks'
import { FiltersContextData, FiltersContext } from '@frontend/context'

interface MaterialRequestCardProps {
    materials: GQLRequestMaterial[]
    type: GQLRequestType
    values: GQLRequestValues
}

const MaterialCardContent = styled.div`
    padding: ${spacing.sm}px;
    margin: ${spacing.sm}px 0;
    background: ${colors.neutral10};
`

export const MaterialRequestCard = ({ materials, values, type }: MaterialRequestCardProps) => {
    const t = useFormatMessage()
    const formatNumber = useFormatNumber()
    const formatPackaging = useFormatPackaging()
    const formatUom = useFormatUom()

    const filterContext: FiltersContextData<GQLRequestsFilters> = useContext(FiltersContext)
    const { material: filterMaterial } = filterContext?.query ?? {}

    return (
        <>
            {materials.map(({ id, name, packagingQuantity, isBulk, packaging, pieces }) => {
                const { amount, uom } = packagingQuantity

                const list: RichRowListItemProps[] = [
                    {
                        label: t('products:column.material'),
                        value: name ?? '-',
                        id: `request-material-name-id`,
                        highlight: filterMaterial,
                    },
                ]
                switch (type) {
                    case GQLRequestType.QUOTE:
                    case GQLRequestType.CART:
                        list.push(
                            {
                                label: t('request-information-modal:order-quantity'),
                                value: (
                                    <Text>
                                        {pieces && pieces > 0
                                            ? getFormattedOrderQuantity(
                                                  formatPackaging(pieces, packaging?.type),
                                                  formatNumber(pieces, {
                                                      maximumFractionDigits: 2,
                                                  }),
                                                  formatNumber(amount, {
                                                      maximumFractionDigits: 2,
                                                  }),
                                                  formatUom(uom),
                                                  isBulk,
                                              )
                                            : '-'}
                                    </Text>
                                ),
                                id: 'order-quantity',
                            },
                            { label: '', value: '' },
                        )
                        break
                    case GQLRequestType.PRICE:
                    case GQLRequestType.DOCUMENT:
                        list.push(
                            {
                                label: t('requests:quantity-annual'),
                                value: getFormattedQuantity(
                                    formatNumber(values.quantityAnnual ?? 0),
                                    formatUom(packagingQuantity.uom),
                                ),
                                id: 'annual-quantity',
                            },
                            {
                                label: t('requests:quantity-call-off'),
                                value: getFormattedQuantity(
                                    formatNumber(values.quantityCallOff ?? 0),
                                    formatUom(packagingQuantity.uom),
                                ),
                                id: 'call-off-quantity',
                            },
                        )
                        break
                    case GQLRequestType.SAMPLE:
                        list.push(
                            {
                                label: t('requests:quantity-sample'),
                                value: getFormattedQuantity(
                                    formatNumber(values.quantityAnnual ?? 0),
                                    formatUom(packagingQuantity.uom),
                                ),
                                id: 'sample-quantity',
                            },
                            { label: '', value: '' },
                        )
                        break
                }

                return (
                    <MaterialCardContent key={id}>
                        <RichRowList list={list} />
                    </MaterialCardContent>
                )
            })}
        </>
    )
}
