import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Truck: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Truck" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M19.54 7H16V5H2V7H14V16H8.22C7.67 15.39 6.88 15 6 15C5.23 15 4.53 15.3 4 15.78V9H2V18H3C3 19.65 4.35 21 6 21C7.65 21 9 19.65 9 18H15C15 19.65 16.35 21 18 21C19.65 21 21 19.65 21 18H22V10.7L19.54 7ZM6 19C5.45 19 5 18.55 5 18C5 17.45 5.45 17 6 17C6.55 17 7 17.45 7 18C7 18.55 6.55 19 6 19ZM18.46 9L20 11.3V15.78C19.47 15.3 18.77 15 18 15C17.23 15 16.53 15.3 16 15.78V9H18.46ZM18 19C17.45 19 17 18.55 17 18C17 17.45 17.45 17 18 17C18.55 17 19 17.45 19 18C19 18.55 18.55 19 18 19Z"
                fill={fill === 'gradient' ? 'url(#Truck_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Truck" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Truck" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M19.54 7H16V5H2V7H14V16H8.22C7.67 15.39 6.88 15 6 15C5.23 15 4.53 15.3 4 15.78V9H2V18H3C3 19.65 4.35 21 6 21C7.65 21 9 19.65 9 18H15C15 19.65 16.35 21 18 21C19.65 21 21 19.65 21 18H22V10.7L19.54 7ZM6 19C5.45 19 5 18.55 5 18C5 17.45 5.45 17 6 17C6.55 17 7 17.45 7 18C7 18.55 6.55 19 6 19ZM18.46 9L20 11.3V15.78C19.47 15.3 18.77 15 18 15C17.23 15 16.53 15.3 16 15.78V9H18.46ZM18 19C17.45 19 17 18.55 17 18C17 17.45 17.45 17 18 17C18.55 17 19 17.45 19 18C19 18.55 18.55 19 18 19Z"
                fill={fill === 'gradient' ? 'url(#Truck_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Truck" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Truck" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M19.54 7H16V5H2V7H14V16H8.22C7.67 15.39 6.88 15 6 15C5.23 15 4.53 15.3 4 15.78V9H2V18H3C3 19.65 4.35 21 6 21C7.65 21 9 19.65 9 18H15C15 19.65 16.35 21 18 21C19.65 21 21 19.65 21 18H22V10.7L19.54 7ZM6 19C5.45 19 5 18.55 5 18C5 17.45 5.45 17 6 17C6.55 17 7 17.45 7 18C7 18.55 6.55 19 6 19ZM18.46 9L20 11.3V15.78C19.47 15.3 18.77 15 18 15C17.23 15 16.53 15.3 16 15.78V9H18.46ZM18 19C17.45 19 17 18.55 17 18C17 17.45 17.45 17 18 17C18.55 17 19 17.45 19 18C19 18.55 18.55 19 18 19Z"
                fill={fill === 'gradient' ? 'url(#Truck_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Truck" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Truck" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M19.54 7H16V5H2V7H14V16H8.22C7.67 15.39 6.88 15 6 15C5.23 15 4.53 15.3 4 15.78V9H2V18H3C3 19.65 4.35 21 6 21C7.65 21 9 19.65 9 18H15C15 19.65 16.35 21 18 21C19.65 21 21 19.65 21 18H22V10.7L19.54 7ZM6 19C5.45 19 5 18.55 5 18C5 17.45 5.45 17 6 17C6.55 17 7 17.45 7 18C7 18.55 6.55 19 6 19ZM18.46 9L20 11.3V15.78C19.47 15.3 18.77 15 18 15C17.23 15 16.53 15.3 16 15.78V9H18.46ZM18 19C17.45 19 17 18.55 17 18C17 17.45 17.45 17 18 17C18.55 17 19 17.45 19 18C19 18.55 18.55 19 18 19Z"
                fill={fill === 'gradient' ? 'url(#Truck_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Truck" />}
        </g>
    ),
}
