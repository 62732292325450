import React, { useContext } from 'react'

import graphqlUtils from '@bc/graphql-utils'
import { GQLMaterial, GQLPackaging, GQLGetMaterialsFilters } from '@bc/types'
import { ButtonLink, RichRow, TextHighlight, RichRowListItemProps } from '@bc/ui'
import { useFormatMessage, useFormatNumber, useFormatUom } from '@frontend/components/hooks'
import { PackagingTooltip, TooltipWrapper } from '@frontend/components/tooltips'
import { FiltersContextData, FiltersContext } from '@frontend/context'

interface ProductCardProps {
    material: GQLMaterial
}

interface PackagingProps {
    packaging?: GQLPackaging
    search?: string
}
const Packaging = ({ packaging, search }: PackagingProps) =>
    packaging?.name ? (
        <TooltipWrapper>
            <TextHighlight search={search}>{packaging.name}</TextHighlight>
            {packaging.details?.toLowerCase() !== packaging.name?.toLowerCase() && (
                <PackagingTooltip title={packaging.details ?? ''} />
            )}
        </TooltipWrapper>
    ) : (
        <>'-'</>
    )
export const ProductCard = ({ material }: ProductCardProps) => {
    const t = useFormatMessage()
    const formatNumber = useFormatNumber()
    const formatUom = useFormatUom()
    const filterContext: FiltersContextData<GQLGetMaterialsFilters> = useContext(FiltersContext)
    const { casNumber: filterCasNumber, packagingName: filterPackagingName } = filterContext?.query ?? {}

    const { packaging, packagingQuantity, name, id } = material

    const list: RichRowListItemProps[] = [
        {
            label: t('products:column.cas-no'),
            value: graphqlUtils.material.getCASNumbers(material).join(', ') || '-',
            highlight: filterCasNumber,
        },
        {
            label: t('general:packaging'),
            value: <Packaging packaging={packaging} search={filterPackagingName} />,
        },
        {
            label: t('products:column.filling-weight'),
            value: formatNumber(packagingQuantity.amount) + ' ' + formatUom(packagingQuantity.uom),
        },
        {
            label: t('products:column.grade'),
            value: '-',
        },
    ]

    return (
        <RichRow list={list} title={name || 'unknown'}>
            {{
                actions: (
                    <>
                        <ButtonLink
                            to={`${t('route:product-list')}/${id}`}
                            variant="solid"
                            title={t('products:more-details-button-text')}>
                            {t('products:more-details-button-text')}
                        </ButtonLink>
                    </>
                ),
            }}
        </RichRow>
    )
}
