import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const ChangeOrder: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="ChangeOrder" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M6.99906 4.40984L10.2891 7.70984L11.7091 6.28984L6.99906 1.58984L2.28906 6.28984L3.70906 7.70984L6.99906 4.40984ZM12.2891 17.71L16.9991 22.41L21.7091 17.71L20.2891 16.29L16.9991 19.59L13.7091 16.29L12.2891 17.71ZM17 16.76L18 15.76V3H16V15.76L17 16.76ZM6 8.24023L7 7.24023L8 8.24023V21.0002H6V8.24023Z"
                fill={fill === 'gradient' ? 'url(#ChangeOrder_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ChangeOrder" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="ChangeOrder" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M6.99906 4.40984L10.2891 7.70984L11.7091 6.28984L6.99906 1.58984L2.28906 6.28984L3.70906 7.70984L6.99906 4.40984ZM12.2891 17.71L16.9991 22.41L21.7091 17.71L20.2891 16.29L16.9991 19.59L13.7091 16.29L12.2891 17.71ZM17 16.76L18 15.76V3H16V15.76L17 16.76ZM6 8.24023L7 7.24023L8 8.24023V21.0002H6V8.24023Z"
                fill={fill === 'gradient' ? 'url(#ChangeOrder_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ChangeOrder" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="ChangeOrder" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M6.99906 4.40984L10.2891 7.70984L11.7091 6.28984L6.99906 1.58984L2.28906 6.28984L3.70906 7.70984L6.99906 4.40984ZM12.2891 17.71L16.9991 22.41L21.7091 17.71L20.2891 16.29L16.9991 19.59L13.7091 16.29L12.2891 17.71ZM17 16.76L18 15.76V3H16V15.76L17 16.76ZM6 8.24023L7 7.24023L8 8.24023V21.0002H6V8.24023Z"
                fill={fill === 'gradient' ? 'url(#ChangeOrder_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ChangeOrder" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="ChangeOrder" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M6.99906 4.40984L10.2891 7.70984L11.7091 6.28984L6.99906 1.58984L2.28906 6.28984L3.70906 7.70984L6.99906 4.40984ZM12.2891 17.71L16.9991 22.41L21.7091 17.71L20.2891 16.29L16.9991 19.59L13.7091 16.29L12.2891 17.71ZM17 16.76L18 15.76V3H16V15.76L17 16.76ZM6 8.24023L7 7.24023L8 8.24023V21.0002H6V8.24023Z"
                fill={fill === 'gradient' ? 'url(#ChangeOrder_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="ChangeOrder" />}
        </g>
    ),
}
