import { GQLBaseOrderStatus, GQLDocumentType } from '@bc/types'

import industrialApplications from '../industrialApplications'
import { salesOrganisationConfig } from '../salesOrganisation'
import { SettingsShop, ShopConfig } from '../types'

export const defaultShopConfig: SettingsShop = {
    productListColumns: ['materialId', 'name', 'casNumber', 'packagingName', 'actions'],
    features: {
        Quotes: false,
        Invoices: false,
        UseTwoLineOrderlines: false,
        OrderXMLAttachment: false,
        DirectXMLOrder: false,
        DownloadOrderDocument: false,
        ShoppingCart: false,
        SortIndustryApplications: false,
        RequiredIndustryApplications: false,
        OrderPickup: false,
        NextDayDelivery: false,
        TermsPerDivision: false,
    },
    orderStatuses: [
        GQLBaseOrderStatus.processed,
        GQLBaseOrderStatus.confirmed,
        GQLBaseOrderStatus.inDelivery,
        GQLBaseOrderStatus.invoiced,
    ],
    industrialApplications: industrialApplications.DE,
}

export const shopConfig: ShopConfig = {
    DE2: {
        ...defaultShopConfig,
        productListColumns: [...defaultShopConfig.productListColumns, 'fillingWeight', 'supplier', 'msdsFile'],
        features: {
            ...defaultShopConfig.features,
            Invoices: true,
            DownloadOrderDocument: true,
            ShoppingCart: true,
            SortIndustryApplications: true,
            RequiredIndustryApplications: true,
            OrderPickup: true,
            TermsPerDivision: true,
            DirectXMLOrder: true,
        },
        ...salesOrganisationConfig.DE01,
        industrialApplications: industrialApplications.DE,
    },
    BNL1: {
        ...defaultShopConfig,
        productListColumns: [
            ...defaultShopConfig.productListColumns,
            'einecsNumber',
            'fillingWeight',
            'supplier',
            'msdsFile',
        ],
        features: {
            ...defaultShopConfig.features,
            Invoices: true,
            UseTwoLineOrderlines: true,
            ShoppingCart: true,
            SortIndustryApplications: true,
            RequiredIndustryApplications: true,
        },
        orderStatuses: [
            GQLBaseOrderStatus.processed,
            GQLBaseOrderStatus.confirmed,
            GQLBaseOrderStatus.loading,
            GQLBaseOrderStatus.shipped,
            GQLBaseOrderStatus.delivered,
            GQLBaseOrderStatus.invoiced,
        ],
        industrialApplications: industrialApplications.BNL,
        documentDownloadEnglishOnly: [GQLDocumentType.tds],
    },
    CEE1: {
        ...defaultShopConfig,
        productListColumns: [...defaultShopConfig.productListColumns, 'einecsNumber', 'unitCapacity', 'msdsFile'],
        features: {
            ...defaultShopConfig.features,
            Quotes: true,
            Invoices: true,
            OrderXMLAttachment: true,
            DownloadOrderDocument: true,
            OrderPickup: true,
            NextDayDelivery: true,
        },
        orderStatuses: [
            GQLBaseOrderStatus.confirmed,
            GQLBaseOrderStatus.inDelivery,
            GQLBaseOrderStatus.invoiced,
            GQLBaseOrderStatus.cancelled,
        ],
        industrialApplications: industrialApplications.CEE,
    },
}
