import React from 'react'

import { colors } from '@bc/ui/src/config'

import { SvgIconMap } from '../types'

const defaultFill = colors.blue30

export const status_loading: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-invoiced"
            d="M20,17.279l-7,2.334V10.72l3-1V7.613l-4,1.334L2,5.612v13.108l10,3.333l10-3.333V10h-2V17.279z M11,19.613
            l-7-2.334V8.388l7,2.333V19.613z M7.824,5.446L4.663,4.392L12,1.946l2,0.667V4.72l-2-0.667L7.824,5.446z M22,4v2h-2v2h-2V6h-2V4h2V2
            h2v2H22z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-invoiced"
            d="M20,17.279l-7,2.334V10.72l3-1V7.613l-4,1.334L2,5.612v13.108l10,3.333l10-3.333V10h-2V17.279z M11,19.613
            l-7-2.334V8.388l7,2.333V19.613z M7.824,5.446L4.663,4.392L12,1.946l2,0.667V4.72l-2-0.667L7.824,5.446z M22,4v2h-2v2h-2V6h-2V4h2V2
            h2v2H22z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-invoiced"
            d="M20,17.279l-7,2.334V10.72l3-1V7.613l-4,1.334L2,5.612v13.108l10,3.333l10-3.333V10h-2V17.279z M11,19.613
            l-7-2.334V8.388l7,2.333V19.613z M7.824,5.446L4.663,4.392L12,1.946l2,0.667V4.72l-2-0.667L7.824,5.446z M22,4v2h-2v2h-2V6h-2V4h2V2
            h2v2H22z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-invoiced"
            d="M20,17.279l-7,2.334V10.72l3-1V7.613l-4,1.334L2,5.612v13.108l10,3.333l10-3.333V10h-2V17.279z M11,19.613
            l-7-2.334V8.388l7,2.333V19.613z M7.824,5.446L4.663,4.392L12,1.946l2,0.667V4.72l-2-0.667L7.824,5.446z M22,4v2h-2v2h-2V6h-2V4h2V2
            h2v2H22z"
            transform="scale(0.5)"
        />
    ),
}
