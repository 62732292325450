import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Delete: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Delete" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M11 2H13V4H21V6H3V4H11V2ZM19 22H5V8H7V20H17V8H19V22ZM13 8H15V18H13V8ZM11 8H9V18H11V8Z"
                fill={fill === 'gradient' ? 'url(#Delete_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Delete" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Delete" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M11 2H13V4H21V6H3V4H11V2ZM19 22H5V8H7V20H17V8H19V22ZM13 8H15V18H13V8ZM11 8H9V18H11V8Z"
                fill={fill === 'gradient' ? 'url(#Delete_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Delete" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Delete" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M11 2H13V4H21V6H3V4H11V2ZM19 22H5V8H7V20H17V8H19V22ZM13 8H15V18H13V8ZM11 8H9V18H11V8Z"
                fill={fill === 'gradient' ? 'url(#Delete_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Delete" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Delete" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M11 2H13V4H21V6H3V4H11V2ZM19 22H5V8H7V20H17V8H19V22ZM13 8H15V18H13V8ZM11 8H9V18H11V8Z"
                fill={fill === 'gradient' ? 'url(#Delete_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Delete" />}
        </g>
    ),
}
