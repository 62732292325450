import React from 'react'

import { colors } from '@bc/ui/src/config'
import { SvgIconMap } from '../types'

const defaultFill = colors.red50

export const status_cancelled: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-cancelled"
            d="M7,13v-2h10v2H7z M21,3H3v18h18V7h-2v12H5V5h16V3z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path fill={fill} id="status-cancelled" d="M7,13v-2h10v2H7z M21,3H3v18h18V7h-2v12H5V5h16V3z" />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-cancelled"
            d="M7,13v-2h10v2H7z M21,3H3v18h18V7h-2v12H5V5h16V3z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-cancelled"
            d="M7,13v-2h10v2H7z M21,3H3v18h18V7h-2v12H5V5h16V3z"
            transform="scale(0.5)"
        />
    ),
}
