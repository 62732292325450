import { GQLDocumentType, GQLRequestStatus, GQLRequestType } from '@bc/types/types'

import { SalutationType, SettingsRegion } from '../types'
import { defaultUomMapper } from '../uom'

import { defaultConfig } from './config.default'

const config: SettingsRegion = {
    ...defaultConfig,
    currency: 'EUR',
    addWatermarkToInvoices: true,
    deliveryDateRestrictions: {
        reorderDate: {
            amount: 3,
            unit: 'days',
        },
        reorderEndDate: {
            amount: 91,
            unit: 'days',
        },
        startDate: {
            amount: 3,
            unit: 'days',
        },
        endDate: {
            amount: 2,
            unit: 'years',
        },
    },
    footerCopyrightLink: 'http://corporate.brenntag.com/de/',
    privacyPolicyUrl: {
        de: 'https://www.brenntag.com/de-de/datenschutzerklaerung.html',
        en: 'https://www.brenntag.com/en-de/privacy-policy.html',
    },
    imprintUrl: {
        de: 'https://www.brenntag.com/de-de/impressum.html',
        en: 'https://www.brenntag.com/en-de/imprint.html',
    },
    faqUrl: {
        de: 'https://www.brenntag.com/de-de/services/brenntag-connect-online-services/faq/',
        en: 'https://www.brenntag.com/en-de/services/brenntag-connect-online-services/faq/',
    },
    tacUrl: {
        de: 'https://www.brenntag.com/de-de/agb.html',
        en: 'https://www.brenntag.com/en-de/terms-and-conditions.html',
    },
    languages: ['de', 'en'],
    countries: ['de'],
    csrConfirmationEmail: 'connect@brenntag.de',
    email: {
        generic: 'connect@brenntag.de',
        csr: 'connect@brenntag.de',
        gdpo: 'gdpo@brenntag.de',
    },
    salutation: SalutationType.Formal,
    phone: {
        csr: '+49 201 6496 1999',
    },
    officeHours: '9:00 - 17:00',
    taxes: [
        {
            percentage: 19,
            since: '01-01-2000',
        },
        {
            percentage: 16,
            since: '01-07-2020',
        },
    ],
    filterRequestTypes: [...defaultConfig.filterRequestTypes, GQLRequestType.SAMPLE],
    rfiModalRequestTypes: [...defaultConfig.rfiModalRequestTypes, GQLRequestType.SAMPLE],
    documentTypes: [...defaultConfig.documentTypes, GQLDocumentType.specification, GQLDocumentType.other],
    requestStatusOnCreation: {
        [GQLRequestType.PRICE]: GQLRequestStatus.PENDING,
        [GQLRequestType.DOCUMENT]: GQLRequestStatus.PENDING,
        [GQLRequestType.SAMPLE]: GQLRequestStatus.COMPLETED,
        [GQLRequestType.QUOTE]: GQLRequestStatus.COMPLETED,
        [GQLRequestType.PROFILE]: GQLRequestStatus.COMPLETED,
        [GQLRequestType.CART]: GQLRequestStatus.COMPLETED,
    },
    uomMapper: {
        en: defaultUomMapper,
        de: defaultUomMapper,
    },
    welcomeVideos: {
        de: [
            {
                key: 'WELCOME',
                url: 'https://player.vimeo.com/video/516678054',
            },
            {
                key: 'FIRSTTIME',
                url: 'https://player.vimeo.com/video/516677485',
            },
            {
                key: 'ORDERS',
                url: 'https://player.vimeo.com/video/516677704',
            },
            {
                key: 'INVOICES',
                url: 'https://player.vimeo.com/video/516677634',
            },
            {
                key: 'PRODUCTS',
                url: 'https://player.vimeo.com/video/516677929',
            },
            {
                key: 'FEEDBACK',
                url: 'https://player.vimeo.com/video/516677394',
            },
        ],
    },
}

export default config
