import React, { useContext } from 'react'
import { Field } from 'react-final-form'

import { TextInput } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { FiltersContextData, FiltersContext } from '@frontend/context'

import { FiltersFormContainer } from './filters-form-container'
export interface CompaniesFilters {
    name?: string
    id?: string
}

export const CompaniesListFilters = () => {
    const t = useFormatMessage()
    const { onFilterChange, filters, onLinkCopy, query }: FiltersContextData<CompaniesFilters> = useContext(
        FiltersContext,
    )

    return (
        <FiltersFormContainer
            onFilterChange={onFilterChange}
            initialValues={filters}
            onLinkCopy={onLinkCopy}
            query={query}>
            <Field
                name="name"
                render={({ input }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-company-name')}
                        type="search"
                        placeholder={t('filters:search-by-company-name.placeholder')}
                    />
                )}
            />
            <Field
                name="id"
                render={({ input }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-customerID')}
                        type="search"
                        placeholder={t('filters:search-by-customerID.placeholder')}
                    />
                )}
            />
        </FiltersFormContainer>
    )
}
