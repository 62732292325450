import { useContext } from 'react'

import { RegionFeatures, Resources, Rule, ShopFeatures } from '@bc/config'
import graphqlUtils from '@bc/graphql-utils'
import { GQLRole } from '@bc/types'
import { PlatformConfigContext, UserContext } from '@frontend/context'

type HasAccessHook = (feature: ShopFeatures | RegionFeatures | Resources) => boolean
type ShopAccessHook = (feature: ShopFeatures) => boolean
type RegionAccessHook = (feature: RegionFeatures) => boolean

type PlatformFeatures = RegionFeatures | ShopFeatures | Resources

export const useHasAccess = () => {
    const { shopConfig, appConfig, roleConfig } = useContext(PlatformConfigContext)
    const { me } = useContext(UserContext)

    const authConfig = me && roleConfig[me.meta.sourceRegion]
    const meRole = me && graphqlUtils.user.getRole(me.meta)

    const checkShopAccess: ShopAccessHook = (feature: ShopFeatures) => Boolean(shopConfig.current?.features[feature])

    const checkRegionAccess: RegionAccessHook = (feature: RegionFeatures) => Boolean(appConfig.features[feature])

    const checkResourceAccess = (userRole: GQLRole | undefined, resource: Resources): boolean => {
        const allowedResources =
            authConfig?.rules
                .filter(({ role }: Rule) => role === userRole?.type)
                .filter(({ resources }: Rule) => resources.includes(resource as Resources)) || []

        return allowedResources.length > 0
    }

    const hasAccessFn: HasAccessHook = (feature: PlatformFeatures) => {
        const isRegionFeature = Object.keys(RegionFeatures).indexOf(feature) > -1
        const isShopFeature = Object.keys(ShopFeatures).indexOf(feature) > -1
        const isResourceCheck = authConfig && authConfig.rules.some(rule => rule.role === meRole?.type)

        if (isShopFeature || isRegionFeature || isResourceCheck) {
            return isShopFeature
                ? checkShopAccess(feature as ShopFeatures)
                : isRegionFeature
                ? checkRegionAccess(feature as RegionFeatures)
                : isResourceCheck
                ? checkResourceAccess(meRole, feature as Resources)
                : false
        }
        return false
    }
    return hasAccessFn
}
