import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Clipboard: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Clipboard" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M8 8H16V2H8V8ZM14 6H10V4H14V6ZM4 22H20V3H18V20H6V3H4V22ZM16 16H8V18H16V16ZM8 12H16V14H8V12Z"
                fill={fill === 'gradient' ? 'url(#Clipboard_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Clipboard" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Clipboard" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M8 8H16V2H8V8ZM14 6H10V4H14V6ZM4 22H20V3H18V20H6V3H4V22ZM16 16H8V18H16V16ZM8 12H16V14H8V12Z"
                fill={fill === 'gradient' ? 'url(#Clipboard_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Clipboard" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Clipboard" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M8 8H16V2H8V8ZM14 6H10V4H14V6ZM4 22H20V3H18V20H6V3H4V22ZM16 16H8V18H16V16ZM8 12H16V14H8V12Z"
                fill={fill === 'gradient' ? 'url(#Clipboard_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Clipboard" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Clipboard" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M8 8H16V2H8V8ZM14 6H10V4H14V6ZM4 22H20V3H18V20H6V3H4V22ZM16 16H8V18H16V16ZM8 12H16V14H8V12Z"
                fill={fill === 'gradient' ? 'url(#Clipboard_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Clipboard" />}
        </g>
    ),
}
