import React, { createContext, useEffect, useState, useContext } from 'react'

import { NotificationsStore } from '@frontend/stores/notifications'

import { QueryResult } from '@apollo/react-common'
import { useQuery } from '@apollo/react-hooks'
import { GQLNotification } from '@bc/types'
import { NotificationsQuery } from '@frontend/graphql/shared/'

import { logException } from '@frontend/utils/sentry'
import { getUserLanguage } from '@bc/translations'
import { notificationGetMessageKey, NotificationMessageKey } from '@frontend/components/notifications/utils'
import { UserContext } from './user-context'

interface NotificationsProviderProps {
    children: JSX.Element
}

interface GetNotificationsQueryResponse {
    notifications: GQLNotification[]
}
export type NotificationsContextData = [NotificationsStore, readonly GQLNotification[]]

const notificationsInstance = new NotificationsStore()

export const NotificationContext = createContext<NotificationsContextData>([notificationsInstance, []])

export const NotificationsProvider = (props: NotificationsProviderProps) => {
    const { error, data }: QueryResult<GetNotificationsQueryResponse> = useQuery(NotificationsQuery)

    const [notifications, setNotifications] = useState(notificationsInstance.notifications)

    const notify = (newNotifications: readonly GQLNotification[]) => setNotifications(newNotifications)

    // window is only available in the client not in SSR so
    // we have to useEffect to load the store
    useEffect(() => {
        if (error) {
            logException(error)
            return
        }

        if (data) {
            notificationsInstance.init(data.notifications, notify)
        }

        return () => notificationsInstance.deinit()
    }, [data, error])

    return <NotificationContext.Provider value={[notificationsInstance, notifications]} {...props} />
}
type UseNotificationReturn = [NotificationsStore, readonly GQLNotification[], NotificationMessageKey]
export const useNotifications = (): UseNotificationReturn => {
    const [notificationStore, notifications] = useContext(NotificationContext)
    const { me } = useContext(UserContext)

    const messageKey = notificationGetMessageKey(getUserLanguage(me?.meta))

    return [notificationStore, notifications, messageKey]
}
