import React from 'react'

import * as TabsComponents from './tabs.components'

export interface TabsProps {
    activeTab?: number
    full?: boolean
    items: TabsItem[]
    onChange?: (activeTab: number) => void
    rounded?: boolean
}

export interface TabsItem {
    id: string
    button: string
    content: JSX.Element
}

export interface TabsState {
    activeTab: number
}

export class Tabs extends React.Component<TabsProps, TabsState> {
    public state: TabsState = {
        activeTab: 0,
    }

    public componentDidMount() {
        const { activeTab } = this.props
        if (activeTab) {
            this.setState({ activeTab })
        }
    }

    public componentDidUpdate(prevProps: TabsProps) {
        const { activeTab } = this.props
        if (activeTab && activeTab !== prevProps.activeTab) {
            this.setState({ activeTab })
        }
    }

    private onButtonClick = (activeTab: number) => {
        this.setState({ activeTab })

        if (this.props.onChange) {
            this.props.onChange(activeTab)
        }
    }

    public render() {
        const { items = [], full } = this.props
        const { activeTab } = this.state

        return (
            <TabsComponents.Wrapper>
                <TabsComponents.Buttons>
                    {items.map((item, index) => (
                        <TabsComponents.TabButton
                            key={item.button}
                            grow={full}
                            active={index === activeTab}
                            onClick={() => this.onButtonClick(index)}
                            data-test-id={`button-${item.id}`}>
                            {item.button}
                        </TabsComponents.TabButton>
                    ))}
                </TabsComponents.Buttons>
                {items.map(
                    (item, index) =>
                        index === activeTab && (
                            <TabsComponents.Content key={item.button}>{item.content}</TabsComponents.Content>
                        ),
                )}
            </TabsComponents.Wrapper>
        )
    }
}
