import { isBrowser } from '@frontend/utils/is-browser'

const serverEnv = {
    AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
    AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
    BC_DOMAIN: process.env.BC_DOMAIN,
    BE_URL: process.env.BE_URL,
    DEBUG: process.env.DEBUG,
    DEV_MODE: process.env.DEV_MODE === 'true',
    FE_GOOGLE_TAG_MANAGER_TOKEN: process.env.FE_GOOGLE_TAG_MANAGER_TOKEN || '',
    FE_URL: process.env.FE_URL || 'http://localhost:3000',
    HEAP_ID: process.env.HEAP_ID || '',
    QUESTIONPRO_ID: process.env.QUESTIONPRO_ID || '',
    SENTRY_DSN: process.env.SENTRY_DSN || '',
}

export const env = isBrowser ? window.env || serverEnv : serverEnv
