import React from 'react'

import { GQLLocation } from '@bc/types'
import { Column, Hr, Row, Text } from '@bc/ui'

import { useFormatMessage } from '@frontend/components/hooks'
import { accountAddress } from './account-address'
import { AccountCard } from './account-card'

interface AccountPersonalProps {
    name?: string
    shipTos: GQLLocation[]
    billTos: GQLLocation[]
}

export const AccountCompany = ({ shipTos, billTos, name }: AccountPersonalProps) => {
    const t = useFormatMessage()
    const getShippingTitle = (i: number): string => `
        ${t('account:shipping-address')}
        ${shipTos.length > 1 ? i + 1 : ''}`

    const getBillingTitle = (i: number): string => `
        ${t('account:billing-address')}
        ${billTos.length > 1 ? i + 1 : ''}`

    const renderShipTos = () => shipTos.map((address, i) => accountAddress(address, getShippingTitle(i)))

    const renderBillTos = () => billTos.map((address, i) => accountAddress(address, getBillingTitle(i)))

    return (
        <AccountCard
            theme="light"
            header={
                <div>
                    <Text h3>{t('account:company-details')}</Text>
                    <Row>
                        <Column xs={12}>
                            <Text caption>{t('account:company-name')}</Text>
                            <Text p>{name}</Text>
                        </Column>
                    </Row>
                    <Hr small />
                    {shipTos && <Row>{renderShipTos()}</Row>}
                    <Hr small />
                    {billTos && <Row>{renderBillTos()}</Row>}
                </div>
            }
        />
    )
}
