import { logException } from './sentry'

export const jsonParse = (json: any, skipLog: boolean = false) => {
    if (!json) {
        return undefined
    }
    let obj
    try {
        obj = JSON.parse(json)
    } catch (error) {
        if (!skipLog) {
            logException(error)
        }
        return undefined
    }
    return obj
}
