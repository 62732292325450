import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { SvgIcon, SvgIconSize } from '@bc/ui/src/components/svg'
import { colors, transition } from '@bc/ui/src/config'
import { getFontFamily } from '@bc/ui/src/helpers'

type Sizes = 'small' | 'medium'

type IconSizeMap = { [key in Sizes]: SvgIconSize }
export const iconSizes: IconSizeMap = {
    small: '12',
    medium: '16',
}

type TextSizeMap = { [key in Sizes]: number }
export const textSizes: TextSizeMap = {
    small: 15,
    medium: 20,
}

type IconOffsetMap = { [key in Sizes]: number }
export const iconOffset: IconOffsetMap = {
    small: 25,
    medium: 30,
}

type IconMarginMap = { [key in Sizes]: number }
export const iconMargins: IconMarginMap = {
    small: 5,
    medium: 6,
}

interface ArrowLinkProps {
    to: string
    color: keyof typeof colors
    // would have wanted to call it size but then you get weird typing issues because 'size' is a reserved prop name,
    // just try and set a prop named size on ArrowLinkDiv to see the issue
    arrowLinkSize: Sizes
}

// Need to use a filterProps because by default styled will forward all props which results in
// react giving warnings about arrowLinkSize and color props on the a tag
const ArrowLinkAnchor = styled(NavLink)<ArrowLinkProps>`
    font-family: ${getFontFamily('brenntagsans_bold')};
    position: relative;
    text-decoration: none;
    font-weight: 500;
    font-stretch: condensed;
    margin-bottom: 1rem;
    line-height: 1.3;
    overflow: hidden;
    display: block;
    :hover > svg {
        transform: translateX(5px);
    }

    ${({ arrowLinkSize, color }) => `
        font-size: ${textSizes[arrowLinkSize]};
        padding-left: ${iconOffset[arrowLinkSize]}px;
        color: ${colors[color]};
    `}
`

export interface IconProps {
    arrowLinkSize: Sizes
}

const Icon = styled(SvgIcon)<IconProps>`
    position: absolute;
    left: 0;
    top: 0;
    transition: ${transition.config};
    transition-property: transform;
    ${({ arrowLinkSize }) => ` 
            margin-top: ${iconMargins[arrowLinkSize]}px;
    `}
`

const Text = styled.span`
    display: inline-block;
`

export const ArrowLink: React.SFC<ArrowLinkProps> = ({ to, arrowLinkSize, children, color }) => (
    <ArrowLinkAnchor to={to} arrowLinkSize={arrowLinkSize} color={color}>
        <Icon icon="Arrow_Right" size={iconSizes[arrowLinkSize]} arrowLinkSize={arrowLinkSize} fill="currentColor" />
        <Text>{children}</Text>
    </ArrowLinkAnchor>
)
