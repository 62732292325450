import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { Column, Container, Row } from '@bc/ui/src/components'
import { media } from '@bc/ui/src/helpers/media'

interface Props {
    children: {
        header: ReactNode
        contentLeft: React.ReactNode
        contentRight: React.ReactNode
    }
}

const OverviewContainer = styled(Container)`
    ${media.min('md')} {
        padding-left: 50px;
        padding-right: 50px;
    }
`

export const OverviewTemplate = (props: Props) => {
    const { header, contentLeft, contentRight } = props.children
    return (
        <OverviewContainer>
            {header}
            <Row>
                <Column xs={12} md={7} lg={8}>
                    {contentLeft}
                </Column>
                <Column xs={12} md={5} lg={4}>
                    {contentRight}
                </Column>
            </Row>
        </OverviewContainer>
    )
}
