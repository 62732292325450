import React from 'react'

import { colors } from '@bc/ui/src/config'
import { SvgIconMap } from '../types'

const defaultFill = colors.green50

export const status_delivered: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-delivered"
            d="M12,8.946L2,5.612v13.108l10,3.333l10-3.333V5.612L12,8.946z M11,19.613l-7-2.334V8.388l7,2.333V19.613z
	        M20,17.279l-7,2.334V10.72l7-2.333V17.279z M7.824,5.446L4.663,4.392L12,1.946l7.337,2.446l-3.161,1.054L12,4.054L7.824,5.446z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-delivered"
            d="M12,8.946L2,5.612v13.108l10,3.333l10-3.333V5.612L12,8.946z M11,19.613l-7-2.334V8.388l7,2.333V19.613z
	        M20,17.279l-7,2.334V10.72l7-2.333V17.279z M7.824,5.446L4.663,4.392L12,1.946l7.337,2.446l-3.161,1.054L12,4.054L7.824,5.446z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-delivered"
            d="M12,8.946L2,5.612v13.108l10,3.333l10-3.333V5.612L12,8.946z M11,19.613l-7-2.334V8.388l7,2.333V19.613z
	        M20,17.279l-7,2.334V10.72l7-2.333V17.279z M7.824,5.446L4.663,4.392L12,1.946l7.337,2.446l-3.161,1.054L12,4.054L7.824,5.446z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-delivered"
            d="M12,8.946L2,5.612v13.108l10,3.333l10-3.333V5.612L12,8.946z M11,19.613l-7-2.334V8.388l7,2.333V19.613z
	        M20,17.279l-7,2.334V10.72l7-2.333V17.279z M7.824,5.446L4.663,4.392L12,1.946l7.337,2.446l-3.161,1.054L12,4.054L7.824,5.446z"
            transform="scale(0.5)"
        />
    ),
}
