import { colors, ColorScheme } from '@bc/ui/src/config'

export interface CheckboxRadioLabelProps {
    hasError?: boolean
    disabled?: boolean
    colorScheme?: ColorScheme
}
const disabledColor = colors.neutral30

export const labelDisabledStyles = `
    input:disabled + & {
        cursor: initial;
        color: ${disabledColor};
        & > span {
            color: ${disabledColor};
        }
    }
`

export const checkboxRadioDisabledStyle = `
    input:disabled + & {
        background: ${colors.white};
        border-color: ${disabledColor};
        cursor: not-allowed;
    }
    input:checked:disabled + & {
        background: ${disabledColor};
        border: none;
        cursor: not-allowed;
    }
`

const getLabelColorScheme = (colorScheme: ColorScheme | 'gradient' = 'gradient') => {
    switch (colorScheme) {
        case 'deepPurple': {
            return {
                hoverBg: colors.neutral10,
                hoverBorder: colors.neutral80,
                hoverCheckedBg: colors.neutral80,
                activeBg: colors.neutral20,
                activeBorder: colors.neutral90,
                activeCheckedBg: colors.neutral90,
                focusBg: colors.neutral30,
                focusCheckedBg: colors.deepPurple,
                focusBorder: colors.white,
            }
        }
        case 'gradient': {
            return {
                hoverBg: colors.neutral10,
                hoverBorder: colors.neutral80,
                hoverCheckedBg: colors.neutral80,
                activeBg: colors.neutral20,
                activeBorder: colors.neutral90,
                activeCheckedBg: colors.blue60,
                focusBg: colors.neutral30,
                focusCheckedBg: `linear-gradient(90deg,${colors.violet50} 0%, ${colors.blue50} 100%)`,
                focusBorder: colors.white,
            }
        }
        default: {
            return {
                hoverBg: colors[`${colorScheme}10`],
                hoverBorder: colors[`${colorScheme}80`],
                hoverCheckedBg: colors[`${colorScheme}50`],
                activeBg: colors[`${colorScheme}20`],
                activeBorder: colors[`${colorScheme}90`],
                activeCheckedBg: colors[`${colorScheme}60`],
                focusBg: colors[`${colorScheme}30`],
                focusCheckedBg: colors[`${colorScheme}50`],
                focusBorder: colors.white,
            }
        }
    }
}

export const labelStyles = ({ hasError, colorScheme }: CheckboxRadioLabelProps) => {
    const {
        hoverBg,
        hoverBorder,
        hoverCheckedBg,
        activeBg,
        activeBorder,
        activeCheckedBg,
        focusBg,
        focusCheckedBg,
        focusBorder,
    } = getLabelColorScheme(colorScheme)

    return `
    &:hover {
        &:before {
            background: ${hoverBg};
            border-color: ${hoverBorder};
            input:checked + & {
                background: ${hoverCheckedBg};
            }
            ${checkboxRadioDisabledStyle}
        }
    }
    &:active {
        &:before {
            background: ${activeBg};
            border-color: ${activeBorder};
            input:checked + & {
                background: ${activeCheckedBg};
            }
            ${checkboxRadioDisabledStyle}
        }
    }
    &:focus {
        &:before {
            background: ${focusBg};
            border: 1px solid ${focusBorder} !important;
            input:checked + & {
                background: ${focusCheckedBg};
            }
            box-shadow: 0px 0px 0px 1px ${colors.deepPurple};
            ${checkboxRadioDisabledStyle}
        }
    }
    ${labelDisabledStyles}

    // label span tag when error
    ${
        hasError
            ? `
            input + & > span {
                color: ${colors.red70};
            }
            `
            : ``
    }
`
}

const getCheckboxRadioBeforeSColorScheme = (colorScheme: ColorScheme | 'gradient' = 'gradient') => {
    switch (colorScheme) {
        case 'deepPurple': {
            return {
                inputCheckedBg: colors.deepPurple,
                border: colors.deepPurple,
            }
        }
        case 'gradient': {
            return {
                inputCheckedBg: `linear-gradient(90deg,${colors.violet50} 0%, ${colors.blue50} 100%)`,
                border: colors.neutral80,
            }
        }
        default: {
            return {
                inputCheckedBg: colors[`${colorScheme}50`],
                border: colors[`${colorScheme}80`],
            }
        }
    }
}
export const checkboxRadioBeforeStyles = ({ colorScheme: colorScheme, hasError }: CheckboxRadioLabelProps) => {
    const { inputCheckedBg, border } = getCheckboxRadioBeforeSColorScheme(colorScheme)

    return `
    &:before {
        content: '';
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: ${colors.white};
        border: 2px solid ${border};
        ${hasError ? `border-color: ${colors.red70};` : ``}
        transition: border 200ms ease-out, box-shadow 200ms ease-out;
        input:checked + & {
            background: ${inputCheckedBg};
            ${hasError ? `border: 2px solid ${colors.red70};` : `border: none`};
        }
        ${checkboxRadioDisabledStyle}
    }
`
}

export const afterTransitionStyles = `
    opacity: 0;
    transform: scale(0.5);
    transition: all 200ms ease-out;
    input:checked + & {
        transform: scale(1);
        opacity: 1;
    }
`
