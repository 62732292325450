import React from 'react'
import styled from 'styled-components'

import { Text } from '@bc/ui/src/components/text'
import { spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'

interface InlineDialogProps {
    text: string
}

const Wrapper = styled.div`
    width: 100%;
    padding: 0 ${spacing.xs}px;

    ${media.min('sm')} {
        padding: 0 ${spacing.sm}px;
    }
`

const Content = styled.div`
    padding: 15px;
    background-color: #f5e4c4;
    border-radius: 2px;
`

export const InlineDialog = ({ text }: InlineDialogProps) => (
    <Wrapper>
        <Content>
            <Text p noMargin>
                {text}
            </Text>
        </Content>
    </Wrapper>
)
