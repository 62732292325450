import React, { useContext } from 'react'

import graphqlUtils from '@bc/graphql-utils'
import { tagManager } from '@bc/gtm'
import { Language } from '@bc/translations'
import { GQLDocumentType, GQLMaterial } from '@bc/types'
import { TableMobileLabel } from '@bc/ui'
import { PlatformConfigContext } from '@frontend/context'

import { Document, DocumentContainer } from './document'
export interface MaterialDocumentProps {
    material: GQLMaterial
    title: string | undefined
    language: Language
}

export const MaterialDocuments = ({ material, title, language }: MaterialDocumentProps): JSX.Element => {
    const { shopConfig } = useContext(PlatformConfigContext)

    const msdsFilterLanguage = shopConfig.current?.documentDownloadEnglishOnly?.includes(GQLDocumentType.msds)
        ? 'en'
        : language
    const msdsDocuments = graphqlUtils.material
        .getMsdsDocuments(material)
        .filter(d => d.language === msdsFilterLanguage)

    const tdsFilterLanguage = shopConfig.current?.documentDownloadEnglishOnly?.includes(GQLDocumentType.tds)
        ? 'en'
        : language
    const tdsDocuments = graphqlUtils.material.getTdsDocuments(material).filter(d => d.language === tdsFilterLanguage)
    const documents = [msdsDocuments[0], tdsDocuments[0]].filter(Boolean)

    return (
        <DocumentContainer>
            {title && <TableMobileLabel>{title}</TableMobileLabel>}
            {documents.length > 0
                ? documents.map(d => (
                      <Document
                          href={d.uri}
                          key={d.type}
                          target="_blank"
                          rel="noopener noreferrer"
                          text={d.type.toUpperCase()}
                          onClick={() =>
                              tagManager.tags.materialDocumentDownload(
                                  d.type.toUpperCase(),
                                  material.name || '-',
                                  material.id,
                              )
                          }
                          data-test-id="link-document"
                      />
                  ))
                : '-'}
        </DocumentContainer>
    )
}
