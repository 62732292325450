import { parseDate } from '@frontend/utils/date'
/**
 *
 * Simple validations.
 */
export interface ValidationOptions {
    value: string
}

export const NUM_CHAR_START_SEARCH: number = 3

export const isTruthy = ({ value }: ValidationOptions): boolean => Boolean(value)
const allowedCsrDomains = [
    'digib.com',
    'brenntag.com',
    'brenntag.de',
    'brenntag.be',
    'brenntag.nl',
    'brenntag.pl',
    'brenntag-cee.com',
    'brenntag.at',
    'brenntag.ch',
]
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const emailCSRStart = /^^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@/
const emailCSRRegex = new RegExp(`${emailCSRStart.source}(${allowedCsrDomains.join('|').replace(/\./g, '\\.')})+$`)

export const isValidEmail = ({ value }: ValidationOptions): boolean => emailRegex.test(value)
export const isValidCSREmail = ({ value }: ValidationOptions): boolean => emailCSRRegex.test(value.toLowerCase())

/**
 *
 * Date validations.
 */
export interface DateValidationOptions extends ValidationOptions {
    format?: string
}

export interface SpecificDateValidationOptions extends DateValidationOptions {
    date: Date
}

const defaultFormat = 'MM/DD/YYYY'

export const isValidDate = ({ value, format = defaultFormat }: DateValidationOptions): boolean => {
    const parsedDate = parseDate(value, format)
    return Boolean(parsedDate)
}

export const isDateAfter = ({ value, format = defaultFormat, date }: SpecificDateValidationOptions): boolean => {
    const parsedDate = parseDate(value, format)
    return !!(parsedDate && parsedDate > date)
}

export const isDateBefore = ({ value, format = defaultFormat, date }: SpecificDateValidationOptions): boolean => {
    const parsedDate = parseDate(value, format)
    return !!(parsedDate && date > parsedDate)
}

export const isDateWorkday = ({ value, format = defaultFormat }: DateValidationOptions): boolean => {
    const parsedDate = parseDate(value, format)
    return !!(parsedDate && [1, 2, 3, 4, 5].indexOf(parsedDate.getDay()) > -1)
}

/**
 *
 * Length validations.
 */
export interface LengthValidationOptions extends ValidationOptions {
    length: number
}

export const hasMinimumLength = ({ value, length }: LengthValidationOptions) => value.length >= length

export const hasMaximumLength = ({ value, length }: LengthValidationOptions) => value.length <= length

/**
 *
 * Amount validations.
 */
export interface AmountValidationOptions extends ValidationOptions {
    amount: number
}

export const isAtLeast = ({ value, amount }: AmountValidationOptions) => parseFloat(value) >= amount

export const isAtMost = ({ value, amount }: AmountValidationOptions) => parseFloat(value) <= amount

/**
 * Password reset validations.
 *
 * @param root0
 * @param root0.value
 * @example
 */
export const hasUppercase = ({ value }: ValidationOptions) => {
    if (!value) {
        return
    }
    let count = 0
    const len = value.length
    for (let i = 0; i < len; i++) {
        if (/[A-Z]/.test(value.charAt(i))) {
            count++
        }
    }

    return count > 0
}

export const hasLowercase = ({ value }: ValidationOptions) => {
    if (!value) {
        return
    }
    let count = 0
    const len = value.length
    for (let i = 0; i < len; i++) {
        if (/[a-z]/.test(value.charAt(i))) {
            count++
        }
    }

    return count > 0
}

export const hasNumber = ({ value }: ValidationOptions) => /\d/.test(value)

export const hasSymbol = ({ value }: ValidationOptions) =>
    /[\\\/~\^<>\.,:;?!#&%@\*\+\-=$€+'"\|\(\)\[\]\{\}]/.test(value)

export const isWordCharacter = ({ value }: ValidationOptions) => /\W|_/.test(value)

export const hasValidSearchInput = ({ value }: ValidationOptions) =>
    // Allow all possible languages
    /^[\s\p{L}0-9,:\s@&+„“"`'´().,-]*$/u.test(value)
