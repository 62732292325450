import { RegionConfig } from '../types'

import be from './config.be'
import de from './config.de'
import nl from './config.nl'
import pl from './config.pl'

export const config: RegionConfig = {
    be,
    de,
    nl,
    pl,
}

export { defaultConfig } from './config.default'
