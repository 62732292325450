import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'

interface LoaderProps {
    color?: keyof typeof colors
    size?: 'default' | 'small'
    count?: number
}

const Wrapper = styled.div`
    margin: 0 auto;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Stripe = styled(motion.div)<LoaderProps>`
    display: inline-block;
    width: ${({ size }: LoaderProps) => (size === 'small' ? 2 : 4)}px;
    height: ${({ size }: LoaderProps) => (size === 'small' ? 16 : 32)}px;
    margin: 0 ${({ size }: LoaderProps) => (size === 'small' ? 4 : 8)}px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    background-color: ${({ color = 'deepPurple' }: LoaderProps) => colors[color]};
`

const barVariants = (index: number) => ({
    animate: {
        rotate: [-30, 30],
        transition: {
            repeatType: 'reverse' as const,
            repeat: Infinity,
            duration: 0.7,
            delay: 0.2 * index,
            type: 'tween',
            ease: 'linear',
        },
    },
})
export const Loader = ({ color = 'deepPurple', count = 5, size }: LoaderProps) => (
    <Wrapper>
        {Array(count)
            .fill(0)
            .map((_, index) => (
                <Stripe key={index} color={color} size={size} variants={barVariants(index)} animate="animate" />
            ))}
    </Wrapper>
)
