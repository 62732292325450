import React from 'react'

import styled from 'styled-components'

import { MessageMap } from '@bc/translations'
import { GQLRequest, GQLRequestType } from '@bc/types'
import { Column, RichRowListItemProps, spacing, Text } from '@bc/ui'

import { useFormatMessage, useFormatNumber, useFormatPackaging, useFormatUom } from '@frontend/components/hooks'
import { getFormattedOrderQuantity, getFormattedQuantity } from '@frontend/utils/get-material-quantity'

import { SummaryContent } from './request-components'

interface Props {
    request: GQLRequest
}

const MaterialWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacing.xs}px;
`
const MaterialAttributeRow = styled.div`
    display: flex;
    margin: 0px -20px;
`
interface MaterialAttributeProps {
    name: string | JSX.Element
    value: string | JSX.Element
}
const MaterialAttribute = ({ name, value }: MaterialAttributeProps) => (
    <MaterialAttributeRow>
        <Column>
            <Text caption>{name}</Text>
        </Column>
        <Column>{value}</Column>
    </MaterialAttributeRow>
)

export const RequestSummary = ({ request }: Props) => {
    const t = useFormatMessage()
    const formatPackaging = useFormatPackaging()
    const formatNumber = useFormatNumber()
    const formatUom = useFormatUom()

    const { customer, user, values, materials, type, uid } = request

    const summary: RichRowListItemProps[] = [
        {
            label: t('requests:customer'),
            value: customer.name || '—',
            id: 'customer',
        },
        {
            label: t('requests:recipient'),
            value: (
                <>
                    {`${user.firstName} ${user.lastName}`}
                    <br />
                    {user.email}
                    <br />
                    {values.phone}
                </>
            ),
            id: 'recipient',
        },
        {
            label: t('requests:comments'),
            value: values.comment || '-',
            id: 'comments',
        },
    ]

    return (
        <>
            <Text caption margin data-test-id="text-request-number">
                {uid}
            </Text>
            {summary.map(({ id, label, value }) => (
                <SummaryContent key={`summary-${id}`}>
                    <Text caption noMargin>
                        {label}
                    </Text>
                    <Text data-test-id={`text-${id}`}>{value}</Text>
                </SummaryContent>
            ))}
            <Text caption>{t('checkout:products')}</Text>
            {materials.map(({ id, name, packagingQuantity: { amount, uom }, isBulk, packaging, pieces }) => {
                const packagingName = formatPackaging(pieces ?? 0, packaging?.type)
                const formatPieces = formatNumber(pieces ?? 0, {
                    maximumFractionDigits: 2,
                })
                const formatQuantity = formatNumber(amount, {
                    maximumFractionDigits: 2,
                })
                const formattedUom = formatUom(uom)

                const materialContent = () => {
                    switch (type) {
                        case GQLRequestType.QUOTE:
                        case GQLRequestType.CART:
                            return (
                                <MaterialAttribute
                                    name={t('request-information-modal:order-quantity')}
                                    value={
                                        pieces && pieces > 0
                                            ? getFormattedOrderQuantity(
                                                  packagingName,
                                                  formatPieces,
                                                  formatQuantity,
                                                  formattedUom,
                                                  isBulk,
                                              )
                                            : '-'
                                    }
                                />
                            )
                        case GQLRequestType.PRICE:
                            return (
                                <>
                                    <MaterialAttribute
                                        name={t('requests:quantity-annual')}
                                        value={getFormattedQuantity(
                                            formatNumber(values.quantityAnnual ?? 0),
                                            formattedUom,
                                        )}
                                    />
                                    <MaterialAttribute
                                        name={t('requests:quantity-call-off')}
                                        value={getFormattedQuantity(
                                            formatNumber(values.quantityCallOff ?? 0),
                                            formattedUom,
                                        )}
                                    />
                                </>
                            )
                        case GQLRequestType.DOCUMENT:
                            const documents = (values.documentTypes ?? []).map((documentType, index) => (
                                <Text block noMargin key={`document-${index}`}>
                                    {documentType === 'other'
                                        ? values.documentTypeOther
                                        : t(
                                              `request-information-modal:document-types.${documentType}` as keyof MessageMap,
                                          )}
                                </Text>
                            ))

                            return (
                                <Column>
                                    <MaterialAttribute
                                        name={t('requests:quantity-annual')}
                                        value={getFormattedQuantity(
                                            formatNumber(values.quantityAnnual ?? 0),
                                            formattedUom,
                                        )}
                                    />
                                    <MaterialAttribute
                                        name={t('requests:quantity-call-off')}
                                        value={getFormattedQuantity(
                                            formatNumber(values.quantityCallOff ?? 0),
                                            formattedUom,
                                        )}
                                    />
                                    <Text caption>{t('requests:requested-documents')}</Text>
                                    {documents}
                                </Column>
                            )
                        case GQLRequestType.SAMPLE:
                            return (
                                <Column>
                                    <MaterialAttribute
                                        name={t('requests:quantity-sample')}
                                        value={getFormattedQuantity(
                                            formatNumber(values.quantitySample ?? 0),
                                            formattedUom,
                                        )}
                                    />
                                </Column>
                            )
                    }
                    return '-'
                }
                return (
                    <MaterialWrapper key={id}>
                        <Text noMargin captionBold data-test-id="text-material-name">
                            {name}
                        </Text>
                        {materialContent()}
                    </MaterialWrapper>
                )
            })}
        </>
    )
}
