import { useDocumentTitle } from '@bc/ui'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'

interface Props {
    resetToken: (email: string) => any
}

export const GeneratePasswordSetLinkPage = (props: Props) => {
    const [link, setLink] = useState('')
    const [loading, setLoading] = useState(false)

    useDocumentTitle('Yo!')

    useEffect(() => {
        fetchLink()
    }, [])

    async function fetchLink() {
        setLoading(true)
        const { email } = qs.parse(location.search)
        const fetchedLink = await props.resetToken(email as string)
        setLink(fetchedLink.data.resetPasswordToken.link)
        setLoading(false)
    }

    return (
        <>
            {loading ? (
                <p>Generating link...</p>
            ) : (
                <a href={link} data-test-id="link-set-password">
                    Click to set password
                </a>
            )}
        </>
    )
}
