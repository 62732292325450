import React, { memo, useContext } from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps, withRouter } from 'react-router-dom'

import { OxygenTemplate } from '@bc/ui'
import { getRedirectWithState } from '@frontend/bootstrap/shared/redirect'
import { AccountUnavailableError, UserMigratedError } from '@frontend/components/errors'
import { useFormatMessage } from '@frontend/components/hooks'
import { SessionContext, SessionContextData, UserContext } from '@frontend/context'
import { GraphQLFormattedError } from 'graphql'

type PrivateRouteProps = RouteProps

type CombinedProps = PrivateRouteProps & RouteComponentProps<any>

const ComposedPrivateRoute = memo(({ path, location, component }: CombinedProps) => {
    const t = useFormatMessage()
    const { me, error, meLoading, userHasMultipleCustomerEntities, isCsr, userHasCustomers } = useContext(UserContext)
    const [sessionStore, sessionState]: SessionContextData = useContext(SessionContext)

    // UserContext is too slow to render the currentCustomer at this step, it's handle in parallel
    // and we don't need the currentCustomer information at all, just if exists
    const hasActiveCustomer = !!sessionState.activeCustomer

    const renderAuthenticatedRoute = () => {
        // if there is something undefined means the context is still loading
        const contextLoading =
            typeof meLoading === 'undefined' ||
            typeof userHasCustomers === 'undefined' ||
            typeof userHasMultipleCustomerEntities === 'undefined' ||
            typeof isCsr === 'undefined'
        if (
            !!error ||
            (!contextLoading && !meLoading && !userHasCustomers && !userHasMultipleCustomerEntities && !isCsr)
        ) {
            const graphqlError: GraphQLFormattedError | undefined = error?.graphQLErrors[0]
            let ErrorPage
            switch (graphqlError?.extensions?.code) {
                case 'UserMigratedToBEPError': {
                    ErrorPage = UserMigratedError
                    break
                }
                case 'CustomerIdNotValidError':
                default: {
                    ErrorPage = AccountUnavailableError
                    break
                }
            }
            return (
                <OxygenTemplate>
                    <ErrorPage error={graphqlError} />
                </OxygenTemplate>
            )
        }

        if (!meLoading && me && !me.acceptedTerms) {
            return <Redirect to="/login" />
        }

        const notRedirectableRoutes = [
            t('route:account'),
            t('route:contacts'),
            t('route:requests'),
            t('route:my-customers'),
        ]

        if (
            !meLoading &&
            !hasActiveCustomer &&
            // @todo create a util ? and test
            !notRedirectableRoutes.includes(location.pathname)
        ) {
            return <Redirect to={t('route:my-customers')} />
        }
        return <Route path={path} component={component} />
    }

    if (sessionStore.isAuthenticated) {
        return renderAuthenticatedRoute()
    } else if (sessionStore.isTokenExpired) {
        return getRedirectWithState(t('route:session-expired'))
    } else {
        return getRedirectWithState('/login')
    }
})

export const PrivateRoute = withRouter(ComposedPrivateRoute)
