import React from 'react'

import { Field } from 'react-final-form'

import { GQLPrice } from '@bc/types'
import { colors, Text, TextInput, TextWrapper, Tooltip, FormInputComponents } from '@bc/ui'
import { FormatMessage } from '@frontend/components/hooks'
import { GenericWarning } from '@frontend/components/info-tip'
import { PriceScaleConditionConfirmation } from '@frontend/components/price-scale'

import { AttachFileField, ListAttachedFiles } from '../attach-file'
import { customerReferenceRequired } from '../_shared'

interface QuoteOrderFormFieldsProps {
    t: FormatMessage
    file?: File
    change: any
    prices: GQLPrice[]
    insufficientQuantity: boolean
}

export const QuoteOrderFormFieldsStep2 = ({
    t,
    file,
    change,
    prices,
    insufficientQuantity,
}: QuoteOrderFormFieldsProps) => (
    <FormInputComponents.InputWrapper>
        <Field name="customerPoReference" validate={customerReferenceRequired(t, 35)}>
            {({ input, meta }) => (
                <>
                    <TextInput
                        {...input}
                        maxLength={35}
                        labelText={
                            <TextWrapper noMargin>
                                {t('general:po-reference') + ' *'}
                                <Tooltip placement="top">{t('general:po-reference-info')}</Tooltip>
                            </TextWrapper>
                        }
                        type="text"
                        hasError={meta.touched && meta.error}
                        errorText={meta.error}
                        data-test-id="input-po-reference"
                        append={<AttachFileField change={change} />}
                    />
                    <ListAttachedFiles file={file} change={change} />
                </>
            )}
        </Field>

        <FormInputComponents.LabelText>{t('request-information-modal:available-prices')}</FormInputComponents.LabelText>
        {insufficientQuantity && (
            <GenericWarning icon="Circle_Info" bgColor={colors.yellow10} iconColor={colors.yellow50} iconSize="24">
                <Text noMargin>{`${t('general:quantity-below-minimum')} ${t('general:price-confirmation')}.`}</Text>
            </GenericWarning>
        )}
        <PriceScaleConditionConfirmation prices={prices} disabled={insufficientQuantity} />

        <Field
            name="comment"
            render={({ input, meta }) => (
                <TextInput
                    {...input}
                    labelText={t('request-information-modal:comment')}
                    textarea
                    placeholder={t('general:type-here')}
                    type="text"
                    hasError={meta.touched && meta.error}
                    errorText={meta.error}
                    data-test-id="input-comment"
                />
            )}
        />
    </FormInputComponents.InputWrapper>
)
