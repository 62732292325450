import { env } from '@frontend/config'
import Raven, { RavenOptions } from 'raven-js'

export const installSentry = () => {
    Raven.config(env.SENTRY_DSN).install()
}

/* Use this exception logger to fail gracefully */
export const logException = (ex: Error, options?: RavenOptions | undefined, logToConsole: boolean = true) => {
    Raven.captureException(ex, options)

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    logToConsole && console && console.error && console.error(ex)
}
