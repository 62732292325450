import React, { createContext, useEffect, useState } from 'react'

import ShoppingStore, { ShoppingCartExportedItem } from '@frontend/stores/shopping-cart/shopping-store'

interface ShoppingCartProviderProps<T> {
    children: JSX.Element
    merge?: (item1: T, item2: T) => T
}

export type ShoppingCartContextData<T> = [ShoppingStore<T>, ShoppingCartExportedItem<T>[]]

const shoppingStoreInstance = new ShoppingStore()
const initialContext: ShoppingCartContextData<any> = [shoppingStoreInstance, []]

export const ShoppingCartContext = createContext(initialContext)

export const ShoppingCartProvider = <T,>({ merge, ...restProps }: ShoppingCartProviderProps<T>) => {
    const [shoppingData, setShoppingData] = useState([] as ShoppingCartExportedItem<T>[])

    // when localStorage changes in another tab -
    // this will update the current tab
    const notify = (data: ShoppingCartExportedItem<T>[]) => {
        setShoppingData(data)
    }

    useEffect(() => {
        // window is only available in the client not in SSR so
        // we have to useEffect to load the store
        shoppingStoreInstance.init({ notify, merge })
        shoppingStoreInstance?.subscribe(notify)
        return () => shoppingStoreInstance?.unsubscribe(notify)
    }, [])

    return <ShoppingCartContext.Provider value={[shoppingStoreInstance, shoppingData]} {...restProps} />
}
