import React from 'react'
import { Router } from 'react-router-dom'

import { ApolloProvider } from '@apollo/react-common'
import ApolloClient from 'apollo-client'

import { ScrollToTop } from '@frontend/components'
import { history } from '@frontend/utils'

import { App } from '@frontend/bootstrap/shared'

export default ({ client }: { client: ApolloClient<any> }) => (
    <ApolloProvider client={client}>
        <Router history={history}>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </Router>
    </ApolloProvider>
)
