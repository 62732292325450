
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CustomerLocationFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Customer"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"terms"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"incoTermId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"incoTerms"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"paymentTermId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"paymentTerms"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"salesOrganisation"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"salesDivision"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"salesAreaId"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"shipTos"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LocationFragment"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LocationAddressFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"billTos"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"LocationFragment"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LocationAddressFragment"},"directives":[]}]}}]}}],"loc":{"start":0,"end":467}};
    doc.loc.source = {"body":"#import './location.fragment.graphql'\n#import './location-address.fragment.graphql'\n\nfragment CustomerLocationFragment on Customer {\n    terms {\n        incoTermId\n        incoTerms\n        paymentTermId\n        paymentTerms\n        salesOrganisation\n        salesDivision\n        salesAreaId\n    }\n    shipTos {\n        ...LocationFragment\n        ...LocationAddressFragment\n    }\n    billTos {\n        ...LocationFragment\n        ...LocationAddressFragment\n    }\n\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('./location.fragment.graphql').definitions));
doc.definitions = doc.definitions.concat(unique(require('./location-address.fragment.graphql').definitions));


      module.exports = doc;
    
