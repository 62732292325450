import React, { forwardRef, useEffect } from 'react'

import { colors, ColorValue } from '@bc/ui/src/config'
import * as ModalComponents from './modal-components'

export interface ModalProps {
    open: boolean
    onClose?: () => void
    content: JSX.Element | (JSX.Element | undefined)[] | undefined
    aside?: JSX.Element | (JSX.Element | undefined)[] | undefined
    type: ModalComponents.ModalType
    id?: string
    equalWidth?: boolean
    color?: ColorValue
    proportion?: string // example: 1/2 or 2/3 etc
}

type Ref = (instance: HTMLDivElement | null) => void | React.RefObject<HTMLDivElement> | null | undefined

const ESC_KEY = 27
const isBrowser = typeof window !== 'undefined'

const ModalComponent = (
    { open, content, aside, type = 'default', proportion = '1/1', color = colors.neutral10, id, onClose }: ModalProps,
    ref: Ref,
) => {
    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp)
        if (isBrowser) {
            if (open) {
                document!.documentElement!.classList.add('no-scroll')
            } else {
                document!.documentElement!.classList.remove('no-scroll')
            }
        }
        return () => {
            window.removeEventListener('keyup', handleKeyUp)
            if (isBrowser) {
                document!.documentElement!.classList.remove('no-scroll')
            }
        }
    }, [open])

    const handleKeyUp = (e: KeyboardEvent) => {
        if (e.keyCode === ESC_KEY && onClose) {
            onClose()
        }
    }

    const closeModal = () => {
        if (onClose) {
            onClose()
        }
    }

    const [leftProportion, rightProportion] = proportion.split('/')

    return (
        <ModalComponents.Wrapper open={open} ref={ref}>
            <ModalComponents.ScrollBox>
                <ModalComponents.Overlay />
                <ModalComponents.ScrollBoxInner>
                    <ModalComponents.CloseOverlay onClick={closeModal} />
                    <ModalComponents.ContentContainer type={type} data-test-id={`modal-${id}`}>
                        <ModalComponents.CloseButton
                            icon="Close"
                            buttonSize="small"
                            variant="outline"
                            onClick={closeModal}
                            data-test-id="button-close-modal"
                        />
                        <ModalComponents.ContentOverflowBox>
                            <ModalComponents.Content proportion={leftProportion} color={color}>
                                {content}
                            </ModalComponents.Content>
                            {aside && (
                                <ModalComponents.Aside proportion={rightProportion} type={type}>
                                    {aside}
                                </ModalComponents.Aside>
                            )}
                        </ModalComponents.ContentOverflowBox>
                    </ModalComponents.ContentContainer>
                </ModalComponents.ScrollBoxInner>
            </ModalComponents.ScrollBox>
        </ModalComponents.Wrapper>
    )
}

export const Modal = forwardRef(ModalComponent)
