import styled from 'styled-components'

import { colors, ColorValue } from '@bc/ui/src/config'
import { SvgIconSize } from '..'

export interface CountTagProps {
    count?: number
    size?: SvgIconSize
    width?: string
    height?: string
    color: ColorValue
    bgColor: ColorValue | 'gradient'
}
export const CounterTag = styled.div<CountTagProps>`
    display: flex;
    align-items: center;
    ${({ width = '0px' }) => `width: ${width};`}
    ${({ height = '0px' }) => `height: ${height};`}

    ${({ count, size = '20', color, bgColor }) =>
        count && count > 0
            ? `::after {
                    content: "${count}";
                    position: relative;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    color: ${colors[color] || 'white'};
                    background: ${
                        (bgColor === 'gradient'
                            ? `linear-gradient(45deg,${colors.violet50} 0%, ${colors.blue50} 100%);`
                            : colors[bgColor]) || 'black'
                    };

                    font-size: 1.3rem;
                    font-weight: 700;
                    text-align: center;
                    text-decoration: none;

                    min-width: ${size}px;
                    min-height: ${size}px;
                    border-radius: 50%;
                    z-index: 10;
                }`
            : ``}
`
