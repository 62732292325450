import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Chevron_Down: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Chevron_Down" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M12 18.41L2.28998 8.71L3.70998 7.29L12 15.59L20.29 7.29L21.71 8.71L12 18.41Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Down_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Down" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Chevron_Down" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M12 18.41L2.28998 8.71L3.70998 7.29L12 15.59L20.29 7.29L21.71 8.71L12 18.41Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Down_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Down" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Chevron_Down" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M12 18.41L2.28998 8.71L3.70998 7.29L12 15.59L20.29 7.29L21.71 8.71L12 18.41Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Down_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Down" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Chevron_Down" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M12 18.41L2.28998 8.71L3.70998 7.29L12 15.59L20.29 7.29L21.71 8.71L12 18.41Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Down_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Down" />}
        </g>
    ),
}
