import { env } from '@frontend/config'

import { auth0 } from '../install'

export const logout = (options?: auth0.LogoutOptions) => {
    if (auth0.client) {
        auth0.client.logout({
            returnTo: env.FE_URL,
            ...options,
        })
    }
}
