import { useHistory, useLocation } from 'react-router-dom'
import { useState, useCallback } from 'react'
import qs from 'query-string'

interface UseFilterResult<F> {
    filters: Partial<F>
    page?: number
    sort?: string
    updateFilters: (filters: Partial<F> & PageAndSort) => void
}
type QueryToFilter<F, Q> = (q: Partial<Q>) => Partial<F>
interface PageAndSort {
    page?: number | undefined
    sort?: string | undefined
}

export const useFilters = <F, Q>(queryToFilter?: QueryToFilter<F, Q>): UseFilterResult<F> => {
    const history = useHistory()
    const location = useLocation()

    const { page: queryPage = '1', sort: querySort, ...query } = qs.parse(location.search) as PageAndSort & Partial<Q>
    const parsedPage = parseInt(String(queryPage), 10)
    const finalPage = !isNaN(parsedPage) ? Math.max(parsedPage, 1) : 1
    const [filters, setFilters] = useState<Partial<F> & PageAndSort>(
        queryToFilter
            ? { ...queryToFilter((query as unknown) as Partial<Q>), page: finalPage, sort: querySort }
            : ({ page: finalPage, sort: querySort } as Partial<F> & PageAndSort),
    )

    const updateFilters = useCallback(
        (newFilters: F) => {
            setFilters(newFilters)
            history.push({
                search: qs.stringify(newFilters),
            })
        },
        [history],
    )

    const { page, sort, ...filtersWithoutPageAndSort } = filters

    return { filters: filtersWithoutPageAndSort as Partial<F>, page, sort, updateFilters }
}
