import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Dashboard: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Dashboard" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M15.1211 4.64012L16.6211 3.14012C12.8511 1.18012 8.08109 1.78012 4.92109 4.94012C1.02109 8.84012 1.02109 15.1801 4.92109 19.0801L6.33109 17.6701C3.21109 14.5501 3.21109 9.47012 6.33109 6.36012C8.70109 3.99012 12.1911 3.43012 15.1111 4.66012L15.1211 4.64012ZM19.0005 3.58592L10.5859 12.0005L12.0002 13.4147L20.4147 5.00013L19.0005 3.58592ZM17.6602 17.6601C20.0202 15.2901 20.5902 11.7901 19.3602 8.88012L19.3702 8.87012L20.8702 7.37012C22.8302 11.1401 22.2302 15.9101 19.0702 19.0701L17.6602 17.6601Z"
                fill={fill === 'gradient' ? 'url(#Dashboard_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Dashboard" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Dashboard" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M15.1211 4.64012L16.6211 3.14012C12.8511 1.18012 8.08109 1.78012 4.92109 4.94012C1.02109 8.84012 1.02109 15.1801 4.92109 19.0801L6.33109 17.6701C3.21109 14.5501 3.21109 9.47012 6.33109 6.36012C8.70109 3.99012 12.1911 3.43012 15.1111 4.66012L15.1211 4.64012ZM19.0005 3.58592L10.5859 12.0005L12.0002 13.4147L20.4147 5.00013L19.0005 3.58592ZM17.6602 17.6601C20.0202 15.2901 20.5902 11.7901 19.3602 8.88012L19.3702 8.87012L20.8702 7.37012C22.8302 11.1401 22.2302 15.9101 19.0702 19.0701L17.6602 17.6601Z"
                fill={fill === 'gradient' ? 'url(#Dashboard_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Dashboard" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Dashboard" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M15.1211 4.64012L16.6211 3.14012C12.8511 1.18012 8.08109 1.78012 4.92109 4.94012C1.02109 8.84012 1.02109 15.1801 4.92109 19.0801L6.33109 17.6701C3.21109 14.5501 3.21109 9.47012 6.33109 6.36012C8.70109 3.99012 12.1911 3.43012 15.1111 4.66012L15.1211 4.64012ZM19.0005 3.58592L10.5859 12.0005L12.0002 13.4147L20.4147 5.00013L19.0005 3.58592ZM17.6602 17.6601C20.0202 15.2901 20.5902 11.7901 19.3602 8.88012L19.3702 8.87012L20.8702 7.37012C22.8302 11.1401 22.2302 15.9101 19.0702 19.0701L17.6602 17.6601Z"
                fill={fill === 'gradient' ? 'url(#Dashboard_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Dashboard" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Dashboard" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M15.1211 4.64012L16.6211 3.14012C12.8511 1.18012 8.08109 1.78012 4.92109 4.94012C1.02109 8.84012 1.02109 15.1801 4.92109 19.0801L6.33109 17.6701C3.21109 14.5501 3.21109 9.47012 6.33109 6.36012C8.70109 3.99012 12.1911 3.43012 15.1111 4.66012L15.1211 4.64012ZM19.0005 3.58592L10.5859 12.0005L12.0002 13.4147L20.4147 5.00013L19.0005 3.58592ZM17.6602 17.6601C20.0202 15.2901 20.5902 11.7901 19.3602 8.88012L19.3702 8.87012L20.8702 7.37012C22.8302 11.1401 22.2302 15.9101 19.0702 19.0701L17.6602 17.6601Z"
                fill={fill === 'gradient' ? 'url(#Dashboard_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Dashboard" />}
        </g>
    ),
}
