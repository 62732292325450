import { Text } from '@bc/ui'
import { FormatMessage } from '@frontend/components/hooks'
import React from 'react'
import styled from 'styled-components'

const FootNoteWrapper = styled(Text)`
    padding: 2rem 0rem;
`

export const RequiredFootNote = ({ t }: { t: FormatMessage }) => (
    <FootNoteWrapper noMargin>{t('general:required-fields')}</FootNoteWrapper>
)
