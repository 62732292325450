import React from 'react'
import styled from 'styled-components'

import { Text } from '@bc/ui/src/components/text'
import { nl2br } from '../../util'
import { Card } from '../card'
import { BoxProps, Box } from '../box'

interface Props extends BoxProps {
    title: string
    text?: string
    children?: any
}

const EmptyStateWrapper = styled(Card)`
    max-width: 720px;
    border-top: 0;
`

export const EmptyState = ({ text, title, children, center }: Props) => (
    <Box center>
        <Text h1>{title}</Text>
        <EmptyStateWrapper theme="light">
            {text && (
                <Box center={center}>
                    <Text p noMargin={!Boolean(children)}>
                        {nl2br(text)}
                    </Text>
                </Box>
            )}
            {children}
        </EmptyStateWrapper>
    </Box>
)
