import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Volume_Low: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Volume_Low" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M16 21.41L10.59 16H6V7.99997H10.59L16 2.58997V11H14V7.40997L11.41 9.99997H8V14H11.41L14 16.59V13H16V21.41Z"
                fill={fill === 'gradient' ? 'url(#Volume_Low_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Volume_Low" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Volume_Low" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M16 21.41L10.59 16H6V7.99997H10.59L16 2.58997V11H14V7.40997L11.41 9.99997H8V14H11.41L14 16.59V13H16V21.41Z"
                fill={fill === 'gradient' ? 'url(#Volume_Low_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Volume_Low" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Volume_Low" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M16 21.41L10.59 16H6V7.99997H10.59L16 2.58997V11H14V7.40997L11.41 9.99997H8V14H11.41L14 16.59V13H16V21.41Z"
                fill={fill === 'gradient' ? 'url(#Volume_Low_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Volume_Low" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Volume_Low" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M16 21.41L10.59 16H6V7.99997H10.59L16 2.58997V11H14V7.40997L11.41 9.99997H8V14H11.41L14 16.59V13H16V21.41Z"
                fill={fill === 'gradient' ? 'url(#Volume_Low_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Volume_Low" />}
        </g>
    ),
}
