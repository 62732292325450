import React from 'react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'

import { ColorScheme } from '@bc/ui/src/config'
import { Checkbox } from './checkbox'
import { FormInputComponents } from './components'
import * as CheckboxGroupComponents from './components/checkbox-group-components'

export interface CheckboxGroupProps {
    colorScheme?: ColorScheme
    disabled?: boolean
    disabledOptions?: boolean[]
    id: string
    labelText?: string
    labelOptions: React.ElementType[] | JSX.Element[] | string[]
    options: string[]
    name: string
    validate?: (value: any, allValues: Record<string, unknown>) => any | undefined
}

export const CheckboxGroup: React.FunctionComponent<CheckboxGroupProps & React.HTMLProps<HTMLInputElement>> = ({
    labelText,
    id,
    options,
    labelOptions,
    disabled,
    disabledOptions,
    name,
    validate,
    colorScheme,
}) => (
    <CheckboxGroupComponents.FormGroup>
        {labelText && <CheckboxGroupComponents.Label>{labelText}</CheckboxGroupComponents.Label>}
        <FieldArray
            validate={validate}
            id={id}
            name={name}
            options={options}
            render={({ fields, meta }) => {
                const toggle = (checked: boolean, option: string) => {
                    if (checked) {
                        fields.push(option)
                    } else {
                        fields.remove(fields.value?.indexOf(option))
                    }
                }
                return (
                    <>
                        {options.map((option, index) => {
                            const checked = fields.value?.indexOf(option) >= 0
                            return (
                                <Field
                                    name={`${name}.${index}`}
                                    key={`${name}.${index}`}
                                    type="checkbox"
                                    render={({ input }) => (
                                        <Checkbox
                                            {...input}
                                            checked={checked}
                                            disabled={disabled || disabledOptions?.[index]}
                                            onChange={e => {
                                                toggle(e.currentTarget.checked, option)
                                            }}
                                            id={`${id}.${option}`}
                                            labelText={labelOptions[index]}
                                            value={option}
                                            colorScheme={colorScheme}
                                            hasError={meta.error}
                                        />
                                    )}
                                />
                            )
                        })}
                        {meta.error && <FormInputComponents.ErrorLabel>{meta.error}</FormInputComponents.ErrorLabel>}
                    </>
                )
            }}
        />
    </CheckboxGroupComponents.FormGroup>
)
