import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Camera: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Camera" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M2 5V20H22V9H20V18H4V7H7.62L8.62 5H15.38L16.38 7H22V5H17.62L16.62 3H7.38L6.38 5H2ZM14 12C14 11.82 13.97 11.65 13.93 11.49L13.92 11.48L15.43 9.97001C15.78 10.57 16 11.25 16 11.99C16 14.2 14.21 15.99 12 15.99C9.79 15.99 8 14.2 8 11.99C8 9.78001 9.79 7.99001 12 7.99001C12.74 7.99001 13.43 8.21001 14.02 8.56001L12.51 10.07C12.35 10.03 12.18 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12Z"
                fill={fill === 'gradient' ? 'url(#Camera_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Camera" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Camera" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M2 5V20H22V9H20V18H4V7H7.62L8.62 5H15.38L16.38 7H22V5H17.62L16.62 3H7.38L6.38 5H2ZM14 12C14 11.82 13.97 11.65 13.93 11.49L13.92 11.48L15.43 9.97001C15.78 10.57 16 11.25 16 11.99C16 14.2 14.21 15.99 12 15.99C9.79 15.99 8 14.2 8 11.99C8 9.78001 9.79 7.99001 12 7.99001C12.74 7.99001 13.43 8.21001 14.02 8.56001L12.51 10.07C12.35 10.03 12.18 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12Z"
                fill={fill === 'gradient' ? 'url(#Camera_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Camera" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Camera" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M2 5V20H22V9H20V18H4V7H7.62L8.62 5H15.38L16.38 7H22V5H17.62L16.62 3H7.38L6.38 5H2ZM14 12C14 11.82 13.97 11.65 13.93 11.49L13.92 11.48L15.43 9.97001C15.78 10.57 16 11.25 16 11.99C16 14.2 14.21 15.99 12 15.99C9.79 15.99 8 14.2 8 11.99C8 9.78001 9.79 7.99001 12 7.99001C12.74 7.99001 13.43 8.21001 14.02 8.56001L12.51 10.07C12.35 10.03 12.18 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12Z"
                fill={fill === 'gradient' ? 'url(#Camera_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Camera" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Camera" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M2 5V20H22V9H20V18H4V7H7.62L8.62 5H15.38L16.38 7H22V5H17.62L16.62 3H7.38L6.38 5H2ZM14 12C14 11.82 13.97 11.65 13.93 11.49L13.92 11.48L15.43 9.97001C15.78 10.57 16 11.25 16 11.99C16 14.2 14.21 15.99 12 15.99C9.79 15.99 8 14.2 8 11.99C8 9.78001 9.79 7.99001 12 7.99001C12.74 7.99001 13.43 8.21001 14.02 8.56001L12.51 10.07C12.35 10.03 12.18 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12Z"
                fill={fill === 'gradient' ? 'url(#Camera_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Camera" />}
        </g>
    ),
}
