import Cookie from 'js-cookie'
import { env } from '@frontend/config'
import { isBrowser } from './is-browser'

const get = (key: string, cookies?: any) => {
    const cookie = cookies ? cookies[key] : Cookie.get(key)

    try {
        // Only parse if it's a real object
        const obj = JSON.parse(cookie)
        return typeof obj === 'object' ? obj : cookie
    } catch (e) {
        return cookie
    }
}

const set = (key: string, value: any, expires?: number, domain?: boolean) => {
    const expiryDate = expires ? expires : undefined
    const cookieDomain = domain && env.BC_DOMAIN ? `.${env.BC_DOMAIN}` : undefined
    const secure = process.env.NODE_ENV === 'production'

    const options = {
        expiryDate,
        domain: cookieDomain,
        secure,
    }

    if (isBrowser && value) {
        Cookie.set(key, value, options)
    }
}

const remove = (key: string) => {
    if (isBrowser) {
        Cookie.remove(key)
    }
}

export const UniCookie = { get, set, remove }
