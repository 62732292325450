import React, { useContext } from 'react'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'
import styled from 'styled-components'

import { CalciumTemplate, Card, colors, spacing, Text, useDocumentTitle } from '@bc/ui'
import { useFormatMessage } from '@frontend/components'
import { UserContext } from '@frontend/context'
import { markdownRenderers } from '@frontend/utils'
import { getPrivacyPolicyUrl } from '@bc/graphql-utils/src/user'

const Box = styled(Card.Body)`
    position: relative;
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
    background: ${colors.white};
    padding: ${spacing.sm}px;
`

export const TermsOfUsagePage = () => {
    const t = useFormatMessage()
    const title = t('terms-of-usage:headline')
    useDocumentTitle(title)
    const { me } = useContext(UserContext)
    return (
        <CalciumTemplate>
            {{
                header: (
                    <Text h1 noMargin>
                        {title}
                    </Text>
                ),
                content: (
                    <Box>
                        <ReactMarkdown
                            renderers={markdownRenderers}
                            source={t('terms-of-usage:content', undefined, {
                                dataPrivacyLink: getPrivacyPolicyUrl(me?.meta),
                            })}
                            plugins={[breaks]}
                        />
                    </Box>
                ),
            }}
        </CalciumTemplate>
    )
}
