import React from 'react'

import { MessageMap } from '@bc/translations'
import { Button, Modal, Text } from '@bc/ui'
import { FormatMessage } from '@frontend/components/hooks'
import styled from 'styled-components'
import { GQLUser } from '@bc/types'

import * as SharedFormComponents from '../../forms/_shared'

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 270px;
`

// eslint-disable-next-line no-shadow
enum EmailTypes {
    welcome = 'welcome',
    pwreset = 'pwreset',
}

interface EmailConfirmModalProps {
    t: FormatMessage
    open: boolean
    unreachable?: boolean
    type?: EmailTypes
    onClose?: () => void
    onConfirm: () => void
    contact: GQLUser | undefined
}

export const EmailConfirmModal: React.FC<EmailConfirmModalProps> = ({
    t,
    open,
    unreachable,
    type,
    onConfirm,
    onClose,
    contact,
}: EmailConfirmModalProps) => {
    if (!type) {
        return null
    }

    const title = t(`user:email-modal-${type}-title` as keyof MessageMap)
    const text = unreachable
        ? t(`user:email-modal-${type}-to-support-text` as keyof MessageMap, undefined, { email: contact?.email })
        : t(`user:email-modal-${type}-text` as keyof MessageMap, undefined, { email: contact?.email })

    return (
        <Modal
            open={open}
            onClose={onClose}
            type="small"
            content={
                <ContentWrapper>
                    <div>
                        <Text h3>{title}</Text>
                        <Text p>{text}</Text>
                    </div>
                    <SharedFormComponents.ButtonsWrapper>
                        <Button
                            onClick={onClose}
                            variant="outline"
                            title={t('general:cancel')}
                            data-test-id="button-emailconfirm-cancel">
                            {t('general:cancel')}
                        </Button>
                        <Button
                            onClick={onConfirm}
                            variant="action"
                            title={t('general:send')}
                            data-test-id="button-emailconfirm-send">
                            {t('general:send')}
                        </Button>
                    </SharedFormComponents.ButtonsWrapper>
                </ContentWrapper>
            }
        />
    )
}
