import React from 'react'

import { GQLMe } from '@bc/types'
import { Column, Hr, Row, Text } from '@bc/ui'
import { FormatMessage } from '@frontend/components/hooks'
import { AccountCard } from './account-card'

interface AccountPersonalProps {
    me: GQLMe | undefined
    t: FormatMessage
}

export const AccountPersonal = ({ t, me }: AccountPersonalProps) => (
    <AccountCard
        theme="light"
        header={
            <div>
                <Text h3>{t('account:personal-details')}</Text>
                {me && (me.meta.firstName || me.meta.lastName) && (
                    <>
                        <Row>
                            {me.meta.lastName && (
                                <Column xs={6} sm={4}>
                                    <Text caption>{t('account:last-name')}</Text>
                                    <Text p noMargin>
                                        {me.meta.lastName}
                                    </Text>
                                </Column>
                            )}
                            {me.meta.firstName && (
                                <Column xs={6} sm={4}>
                                    <Text caption>{t('account:first-name')}</Text>
                                    <Text p noMargin>
                                        {me.meta.firstName}
                                    </Text>
                                </Column>
                            )}
                        </Row>
                        <Hr small />
                    </>
                )}
                <Row>
                    <Column>
                        <Text caption>{t('account:email')}</Text>
                        <Text p noMargin>
                            {me && me.meta.email}
                        </Text>
                    </Column>
                </Row>
            </div>
        }
    />
)
