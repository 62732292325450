import React, { useContext } from 'react'
import { Field } from 'react-final-form'

import { GQLInvoiceFilters } from '@bc/types'
import { TextInput } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { FiltersContextData, FiltersContext } from '@frontend/context'

import { FiltersFormContainer } from './filters-form-container'

export const InvoiceFilters = () => {
    const t = useFormatMessage()
    const { onFilterChange, filters, query, onLinkCopy }: FiltersContextData<GQLInvoiceFilters> = useContext(
        FiltersContext,
    )

    return (
        <FiltersFormContainer
            onFilterChange={onFilterChange}
            initialValues={filters}
            query={query}
            onLinkCopy={onLinkCopy}>
            <Field
                name="invoiceDisplayId"
                render={({ input }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-invoiceID')}
                        type="search"
                        placeholder={t('filters:search-by-invoiceID.placeholder')}
                        data-test-id="input-search-invoice-id"
                    />
                )}
            />
            <Field
                name="customerPoReferences"
                render={({ input }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-po-reference')}
                        type="search"
                        placeholder={t('filters:search-by-po-reference.placeholder')}
                        data-test-id="input-search-po-reference"
                    />
                )}
            />
        </FiltersFormContainer>
    )
}
