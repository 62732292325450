import React from 'react'

import { GQLCustomer, GQLMainSalesArea } from '@bc/types'
import { Button, CardEnriched, getJSXAddress, RichRowListItemProps, TextHighlight } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { env } from '@frontend/config'
import { trimLeadingZeros } from '@frontend/utils'
import { ButtonMobileWrapper } from '@bc/ui/src/components/card/card-components'

interface CompanyCardProps {
    customer: GQLCustomer
    onSelectCustomer: (customer: GQLCustomer, mainSalesArea: GQLMainSalesArea) => void
    mainSalesArea: GQLMainSalesArea
    highlight?: string | string[]
}

export const CompanyCard = ({
    customer,
    customer: { id, shopId, vatId, name, billTos },
    onSelectCustomer,
    mainSalesArea,
    highlight,
}: CompanyCardProps) => {
    const t = useFormatMessage()
    const country = t(`country:${mainSalesArea.salesOrganisation.toLowerCase().substring(0, 2)}`)

    const defaultList: RichRowListItemProps[] = [
        {
            label: t('my-customers:customer-id'),
            // Remove starting zeros if for CEE region
            value: trimLeadingZeros(id),
            highlight,
        },

        {
            label: t('my-customers:customer-vat-number'),
            value: vatId ?? '-',
            highlight,
        },
    ]

    const billToLocation = billTos && billTos[0]
    const address = billToLocation ? getJSXAddress(billToLocation.address) : '-'

    const list: RichRowListItemProps[] = defaultList.concat([
        {
            label: t('account:billing-address'),
            value: address,
        },
        {
            label: t('user:form:label-country'),
            value: country || '-',
        },
    ])

    const title = `${name}${env.DEV_MODE ? ' - ' + shopId : ''}` ?? ''

    return (
        <CardEnriched
            header={{
                title: <TextHighlight search={highlight}>{title}</TextHighlight>,
            }}
            data-test-id={`card-company-${id}`}
            actions={
                <ButtonMobileWrapper>
                    <Button
                        fullOnMobile
                        variant="action"
                        title={t('my-customers:select-user')}
                        onClick={() => onSelectCustomer?.(customer, mainSalesArea)}
                        data-test-id="button-select-company">
                        {t('my-customers:select-user')}
                    </Button>
                </ButtonMobileWrapper>
            }
            list={list}
        />
    )
}
