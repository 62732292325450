import React, { useContext, useMemo } from 'react'
import { Field } from 'react-final-form'

import { OptionsType } from 'react-select'
import styled from 'styled-components'

import { ShopFeatures } from '@bc/config'
import { MessageMap } from '@bc/translations'
import { GQLDocumentType, GQLRequestType } from '@bc/types'
import {
    Button,
    Checkbox,
    FormInputComponents,
    SelectInput,
    SelectInputOption,
    TextInput,
    selectFindOption,
} from '@bc/ui'
import { useFormatMessage, useFormatUom, useHasAccess, useParseNumber } from '@frontend/components/hooks'
import { PlatformConfigContext } from '@frontend/context'
import { finalFormValidations, restrictions } from '@frontend/utils/validation'

import * as SharedFormComponents from '../_shared'
import { positiveNumberRequired, positiveNumber } from '../_shared/validation'
import { RequestInformationFormValues } from './request-information-form.types'

interface RequestInformationFormOneBaseProps {
    values: RequestInformationFormValues
    invalid: boolean
    onSubmit: () => void
    uom: string
}

type RequestInformationFormOneProps = RequestInformationFormOneBaseProps

const StyledInputSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

const noApplications: string[] = []
export const RequestInformationFormOne = ({ onSubmit, values, invalid, uom }: RequestInformationFormOneProps) => {
    const t = useFormatMessage()
    const parseNumber = useParseNumber()
    const formatUom = useFormatUom()
    const hasAccess = useHasAccess()

    const {
        appConfig: { rfiModalRequestTypes, documentTypes },
        shopConfig,
    } = useContext(PlatformConfigContext)

    const formattedUom = formatUom(uom)

    /**
     * Because checkbox groups don't have required validation in react-final-form,
     * instead of using the 'invalid' render prop,
     * here is a custom form validation function
     * that checks if the form is valid and can go to step 2.
     *
     * @param newValues
     * @example
     */
    const isValid = (newValues: RequestInformationFormValues) => {
        const { requirements, documentTypes: formDocumentTypes, documentTypeOther } = newValues
        // Invalid if there is no selected requirement
        if (!requirements || !requirements.length) {
            return false
        }

        // Invalid if 'documents' is selected as requirement, but there is no selected document type
        if (
            requirements &&
            requirements.includes(GQLRequestType.DOCUMENT) &&
            !(newValues.documentTypes && newValues.documentTypes.length)
        ) {
            return false
        }

        // Invalid if 'other' is selected but no document type name was supplied
        if (formDocumentTypes && formDocumentTypes.includes(GQLDocumentType.other) && !documentTypeOther) {
            return false
        }

        if (isApplicationRequired && !newValues.application) {
            return false
        }

        return true
    }

    const validateQuantityForPrice = (value: string, allValues: RequestInformationFormValues) => {
        const { requirements = [] } = allValues
        return requirements.includes(GQLRequestType.PRICE) || requirements.includes(GQLRequestType.SAMPLE)
            ? positiveNumberRequired(t, parseNumber)(value)
            : positiveNumber(t, parseNumber)(value)
    }

    const industrialApplications = shopConfig.current?.industrialApplications ?? noApplications
    const applicationOptions: OptionsType<SelectInputOption> = useMemo(() => {
        let options = industrialApplications.map(ak => ({
            value: ak,
            label: t(`request-information:application:${ak}` as keyof MessageMap),
        }))
        if (hasAccess(ShopFeatures.SortIndustryApplications)) {
            options = options.sort((a: SelectInputOption, b: SelectInputOption) => a.label.localeCompare(b.label))
        }
        return options
    }, [industrialApplications])

    const {
        requirements: propRequirements = [],
        documentTypes: propDocumentTypes = [],
        application: propApplication = '',
    } = values

    const isDocumentRequested = propRequirements.includes(GQLRequestType.DOCUMENT)
    const isSampleRequested = propRequirements.includes(GQLRequestType.SAMPLE)
    const isPriceRequested = propRequirements.includes(GQLRequestType.PRICE)
    const isDocumentTypeOther = propDocumentTypes.includes(GQLDocumentType.other)
    const isApplicationOther = propApplication.includes('other')
    const isApplicationRequired =
        hasAccess(ShopFeatures.RequiredIndustryApplications) || isSampleRequested || isPriceRequested
    // if 'documents' is deselected always clear document types
    if (!isDocumentRequested) {
        values.documentTypes = []
    }
    // if 'sample' is deselected always clear sample quantity
    if (!isSampleRequested) {
        delete values.quantitySample
    }

    return (
        <>
            <StyledInputSection>
                <FormInputComponents.InputWrapper>
                    <FormInputComponents.LabelText>
                        {t('request-information-modal:needs')} {' *'}
                    </FormInputComponents.LabelText>
                    {rfiModalRequestTypes.map(requirement => (
                        <Field
                            key={requirement}
                            name="requirements"
                            type="checkbox"
                            value={requirement}
                            validate={finalFormValidations.required(t)}
                            render={({ input }) => (
                                <Checkbox
                                    {...input}
                                    id={requirement}
                                    labelText={t(
                                        `request-information-modal:requirement.${requirement.toLowerCase()}` as keyof MessageMap,
                                    )}
                                    colorScheme="deepPurple"
                                />
                            )}
                        />
                    ))}

                    {isDocumentRequested && (
                        <>
                            <FormInputComponents.LabelText>
                                {t('request-information-modal:document-types')}
                                {' *'}
                            </FormInputComponents.LabelText>
                            {documentTypes.map(documentType => (
                                <Field
                                    key={documentType}
                                    name="documentTypes"
                                    type="checkbox"
                                    value={documentType}
                                    validate={finalFormValidations.required(t)}
                                    render={({ input }) => (
                                        <Checkbox
                                            {...input}
                                            id={documentType}
                                            value={documentType}
                                            labelText={
                                                <>
                                                    {t(
                                                        `request-information-modal:document-types.${documentType}` as keyof MessageMap,
                                                    )}
                                                    {documentType === GQLDocumentType.other &&
                                                        isDocumentTypeOther &&
                                                        ' *'}
                                                </>
                                            }
                                            colorScheme="deepPurple"
                                        />
                                    )}
                                />
                            ))}
                            {isDocumentTypeOther && (
                                <Field
                                    name="documentTypeOther"
                                    validate={finalFormValidations.required(t)}
                                    render={({ input, meta }) => (
                                        <TextInput
                                            {...input}
                                            type="text"
                                            placeholder={t(
                                                'request-information-modal:document-types-other-placeholder',
                                            )}
                                            errorText={meta.error}
                                            hasError={meta.touched && meta.error}
                                            data-test-id="input-other-document"
                                        />
                                    )}
                                />
                            )}
                        </>
                    )}

                    <FormInputComponents.LabelText>
                        {t('request-information-modal:application')}
                        {isApplicationRequired || isApplicationOther ? ' *' : ''}
                    </FormInputComponents.LabelText>
                    <Field
                        name="application"
                        parse={option => option?.value}
                        render={({ input }) => {
                            const { value, ...restInput } = input
                            const selectedOption = selectFindOption(applicationOptions, value)
                            return (
                                <SelectInput
                                    {...restInput}
                                    value={selectedOption}
                                    placeholder={t('request-information-modal:select-an-application')}
                                    isSearchable
                                    isClearable
                                    options={applicationOptions}
                                    noOptionsMessage={() => t('not-found:select')}
                                    classNamePrefix="select-application-modal-rfi"
                                    data-test-id="select-application-modal-rfi"
                                />
                            )
                        }}
                    />
                    {isApplicationOther && (
                        <Field
                            name="applicationOther"
                            validate={finalFormValidations.required(t)}
                            render={({ input, meta }) => (
                                <TextInput
                                    {...input}
                                    type="text"
                                    placeholder={t('request-information-modal:application')}
                                    errorText={meta.error}
                                    hasError={meta.touched && meta.error}
                                    data-test-id="input-other-application"
                                />
                            )}
                        />
                    )}
                    <FormInputComponents.LabelText>
                        {t('request-information-modal:annual-quantity')} ({formattedUom})
                        {(isPriceRequested || isSampleRequested) && ' *'}
                    </FormInputComponents.LabelText>
                    <Field
                        name="quantityAnnual"
                        validate={validateQuantityForPrice}
                        render={({ input, meta }) => (
                            <FormInputComponents.InputWrapper>
                                <SharedFormComponents.NumberInput
                                    {...input}
                                    type="text"
                                    pattern={`^[0-9]{0,${restrictions.MAX_SAFE_NUMBER_LENGTH}}$`}
                                    placeholder="0"
                                    min="0"
                                    step="1"
                                    required
                                    onKeyDown={restrictions.restrictNumbersKeyDown}
                                    hasError={meta.touched && meta.error}
                                    data-test-id="input-annual-quantity"
                                />

                                {meta.touched && meta.error && (
                                    <FormInputComponents.ErrorLabel>{meta.error}</FormInputComponents.ErrorLabel>
                                )}
                            </FormInputComponents.InputWrapper>
                        )}
                    />
                    <FormInputComponents.LabelText>
                        {t('request-information-modal:call-off-quantity')} ({formattedUom})
                        {(isPriceRequested || isSampleRequested) && ' *'}
                    </FormInputComponents.LabelText>
                    <Field
                        name="quantityCallOff"
                        validate={validateQuantityForPrice}
                        render={({ input, meta }) => (
                            <FormInputComponents.InputWrapper>
                                <SharedFormComponents.NumberInput
                                    {...input}
                                    type="text"
                                    pattern={`^[0-9]{0,${restrictions.MAX_SAFE_NUMBER_LENGTH}}$`}
                                    placeholder="0"
                                    min="0"
                                    step="1"
                                    required
                                    onKeyDown={restrictions.restrictNumbersKeyDown}
                                    hasError={meta.touched && meta.error}
                                    data-test-id="input-call-off-quantity"
                                />

                                {meta.touched && meta.error && (
                                    <FormInputComponents.ErrorLabel>{meta.error}</FormInputComponents.ErrorLabel>
                                )}
                            </FormInputComponents.InputWrapper>
                        )}
                    />
                    {isSampleRequested && (
                        <>
                            <FormInputComponents.LabelText>
                                {t('request-information-modal:sample-quantity')} ({formattedUom}){' *'}
                            </FormInputComponents.LabelText>
                            <Field
                                name="quantitySample"
                                validate={positiveNumberRequired(t, parseNumber)}
                                render={({ input, meta }) => (
                                    <FormInputComponents.InputWrapper>
                                        <SharedFormComponents.NumberInput
                                            {...input}
                                            type="text"
                                            placeholder="0,0"
                                            pattern={`^[0-9|\.|,]{0,${restrictions.MAX_SAFE_NUMBER_LENGTH}}$`}
                                            min="0"
                                            step="1"
                                            required
                                            onKeyDown={restrictions.restrictFloatsKeyDown}
                                            hasError={meta.touched && meta.error}
                                            data-test-id="input-sample-quantity"
                                        />
                                        {meta.touched && meta.error && (
                                            <FormInputComponents.ErrorLabel>
                                                {meta.error}
                                            </FormInputComponents.ErrorLabel>
                                        )}
                                    </FormInputComponents.InputWrapper>
                                )}
                            />
                        </>
                    )}
                    <FormInputComponents.LabelText>
                        {t('request-information-modal:comment')}
                    </FormInputComponents.LabelText>
                    <Field
                        name="comment"
                        render={({ input, meta }) => (
                            <TextInput
                                {...input}
                                textarea
                                placeholder={t('general:type-here')}
                                type="text"
                                hasError={meta.touched && meta.error}
                                errorText={meta.error}
                                data-test-id="input-comment"
                            />
                        )}
                    />
                </FormInputComponents.InputWrapper>
                <SharedFormComponents.RequiredFootNote t={t} />
            </StyledInputSection>
            <SharedFormComponents.ButtonsWrapper>
                <Button
                    onClick={onSubmit}
                    variant="action"
                    disabled={invalid || !isValid(values)}
                    title={t('request-information-modal:step2')}
                    data-test-id="button-go-step-2">
                    {t('request-information-modal:step2')}
                </Button>
            </SharedFormComponents.ButtonsWrapper>
        </>
    )
}
