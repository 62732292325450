import React from 'react'
import styled from 'styled-components'

import { Card, Text } from '@bc/ui'

interface Props {
    title: string
    description?: string
    children: React.ReactNode
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (min-width: 400px) {
        width: 400px;
    }
`

const Title = styled(Text)`
    text-align: center;
`

const Description = styled(Text)`
    text-align: justify;
`

export const TitleDescriptionWrapper = ({ description, title, children }: Props) => (
    <Wrapper>
        <Title h1>{title}</Title>
        <Card theme="light">
            {description && <Description p>{description}</Description>}
            {children}
        </Card>
    </Wrapper>
)
