import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Cart: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Cart" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M20.88 17H5.07L4.07 4H2V2H5.93L6.93 15H19.12L19.99 8H8V6H22L21.99 8.12L20.88 17ZM9 21C9.55228 21 10 20.5523 10 20C10 19.4477 9.55228 19 9 19C8.44772 19 8 19.4477 8 20C8 20.5523 8.44772 21 9 21ZM18 20C18 20.5523 17.5523 21 17 21C16.4477 21 16 20.5523 16 20C16 19.4477 16.4477 19 17 19C17.5523 19 18 19.4477 18 20Z"
                fill={fill === 'gradient' ? 'url(#Cart_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Cart" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Cart" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M20.88 17H5.07L4.07 4H2V2H5.93L6.93 15H19.12L19.99 8H8V6H22L21.99 8.12L20.88 17ZM9 21C9.55228 21 10 20.5523 10 20C10 19.4477 9.55228 19 9 19C8.44772 19 8 19.4477 8 20C8 20.5523 8.44772 21 9 21ZM18 20C18 20.5523 17.5523 21 17 21C16.4477 21 16 20.5523 16 20C16 19.4477 16.4477 19 17 19C17.5523 19 18 19.4477 18 20Z"
                fill={fill === 'gradient' ? 'url(#Cart_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Cart" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Cart" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M20.88 17H5.07L4.07 4H2V2H5.93L6.93 15H19.12L19.99 8H8V6H22L21.99 8.12L20.88 17ZM9 21C9.55228 21 10 20.5523 10 20C10 19.4477 9.55228 19 9 19C8.44772 19 8 19.4477 8 20C8 20.5523 8.44772 21 9 21ZM18 20C18 20.5523 17.5523 21 17 21C16.4477 21 16 20.5523 16 20C16 19.4477 16.4477 19 17 19C17.5523 19 18 19.4477 18 20Z"
                fill={fill === 'gradient' ? 'url(#Cart_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Cart" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Cart" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M20.88 17H5.07L4.07 4H2V2H5.93L6.93 15H19.12L19.99 8H8V6H22L21.99 8.12L20.88 17ZM9 21C9.55228 21 10 20.5523 10 20C10 19.4477 9.55228 19 9 19C8.44772 19 8 19.4477 8 20C8 20.5523 8.44772 21 9 21ZM18 20C18 20.5523 17.5523 21 17 21C16.4477 21 16 20.5523 16 20C16 19.4477 16.4477 19 17 19C17.5523 19 18 19.4477 18 20Z"
                fill={fill === 'gradient' ? 'url(#Cart_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Cart" />}
        </g>
    ),
}
