import React, { createRef, useContext, useRef, useState } from 'react'

import styled from 'styled-components'

import { useMutation } from '@apollo/react-hooks'
import { GraphQLError } from 'graphql'

import graphqlUtils from '@bc/graphql-utils'
import { tagManager } from '@bc/gtm'
import { MessageMap } from '@bc/translations'
import { Modal, spacing, Text } from '@bc/ui'

import { GQLMaterial } from '@bc/types'
import { default as RequestInformationForm } from '@frontend/components/forms/rfi/request-information-form'
import {
    RequestInformationFormTwoValues,
    RequestInformationFormValues,
} from '@frontend/components/forms/rfi/request-information-form.types'
import {
    useFormatMessage,
    useFormatNumber,
    useFormatPackaging,
    useFormatUom,
    useParseNumber,
} from '@frontend/components/hooks'
import { ToastsContext, ToastsContextData } from '@frontend/context'
import { requestInformation } from '@frontend/pages/product-list/queries'

import { ModalTitle } from './modal-elements'

interface RequestInformationModalProps {
    isOpen: boolean
    material: GQLMaterial | undefined
    onClose: () => void
}
const SummaryLabel = styled.div`
    margin-bottom: ${spacing.xs}px;
`

const TextWrapper = styled(Text)`
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
`

const ScrollReference = styled.div`
    height: 100%;
`

const modalRef = createRef<HTMLDivElement>()

export const RequestInformationModal = ({ material, onClose, isOpen }: RequestInformationModalProps) => {
    const parseNumber = useParseNumber()
    const formatNumber = useFormatNumber()
    const formatPackaging = useFormatPackaging()
    const formatUom = useFormatUom()
    const t = useFormatMessage()

    const [step, setStep] = useState(1)
    const [formValues, setFormValues] = useState<RequestInformationFormValues>({})
    const [toastsStore]: ToastsContextData = useContext(ToastsContext)

    const form = useRef<HTMLDivElement>(null)

    const [requestInformationMutation, { loading }] = useMutation(requestInformation)

    const intro = t('request-information-modal:text')
    const title = `${t('request-information-modal:request-information')} (${t(
        'request-information-modal:step',
    )} ${step} ${t('request-information-modal:step.of')} 2)`
    const formattedPackagingUom = formatUom(material?.packagingQuantity.uom ?? '')

    const resetModal = () => {
        setStep(1)
        setFormValues({})
    }

    const sendRequestInformationMutation = async (newValues: RequestInformationFormValues) => {
        await requestInformationMutation({
            variables: {
                materialId: material?.id ?? '0',
                values: {
                    ...(newValues as RequestInformationFormTwoValues),
                    quantityAnnual: newValues.quantityAnnual ? parseNumber(newValues.quantityAnnual, true) : undefined,
                    quantityCallOff: newValues.quantityCallOff
                        ? parseNumber(newValues.quantityCallOff, true)
                        : undefined,
                    quantitySample: newValues.quantitySample ? parseNumber(newValues.quantitySample, true) : undefined,
                },
            },
        })

        toastsStore.addToast({
            message: t('request-information-modal:success-toast'),
            type: 'success',
        })
    }

    const onSubmit = async (newValues: RequestInformationFormValues) => {
        if (material) {
            try {
                await sendRequestInformationMutation(newValues)
            } catch (error) {
                handleReorderMutationError(error)
            }

            resetModal()
            onClose()
        }
    }

    const handleReorderMutationError = ({
        graphQLErrors,
        networkError,
    }: {
        graphQLErrors: GraphQLError[]
        networkError: any
    }) => {
        if (graphQLErrors.length) {
            graphQLErrors?.map(({ message = '' }: Partial<GraphQLError>) => {
                toastsStore.addToast({
                    message,
                    type: 'error',
                })
            })
        } else if (networkError) {
            toastsStore.addToast({
                message: networkError.message,
                type: 'error',
            })
        }
    }

    const onModalClose = () => {
        if (material) {
            tagManager.tags.productRequestInformationAbandon(material)
        }

        resetModal()
        onClose()
    }

    const onSetStep = (newStep: number) => {
        setStep(newStep)

        if (modalRef && modalRef.current && form && form.current) {
            modalRef.current.scrollTop = form.current.offsetTop - 30
        }
    }
    const {
        requirements,
        documentTypes,
        quantitySample,
        quantityAnnual,
        quantityCallOff,
        documentTypeOther,
        application,
        applicationOther,
        comment,
    } = formValues

    return (
        <Modal
            id={'request-information'}
            open={isOpen}
            ref={modalRef}
            onClose={onModalClose}
            type="large"
            content={
                material && (
                    <>
                        <ModalTitle title={title} intro={intro} />
                        {material.name && (
                            <Text h4 textTransform="none" data-test-id="text-product-name">
                                {material.name}
                            </Text>
                        )}

                        <SummaryLabel>
                            <Text caption noMargin>
                                {t('general:material-id')}
                            </Text>
                            <Text p noMargin data-test-id="text-product-number">
                                {material.id}
                            </Text>
                        </SummaryLabel>

                        {material.packaging && (
                            <SummaryLabel>
                                <Text caption noMargin>
                                    {t('general:packaging')}
                                </Text>
                                <Text p noMargin data-test-id="text-product-packaging">
                                    {graphqlUtils.material.getPackagingQuantityLabel(
                                        material,
                                        formatNumber(material.packagingQuantity.amount),
                                        formattedPackagingUom,
                                    )}
                                </Text>
                            </SummaryLabel>
                        )}
                        {material.packaging && material.palletSize && (
                            <SummaryLabel>
                                <Text caption noMargin>
                                    {t('general:pallet')}
                                </Text>
                                <Text p noMargin data-test-id="text-pallet-size">
                                    {formatNumber(material.palletSize)}{' '}
                                    {formatPackaging(material.palletSize, material?.packaging?.type)}
                                </Text>
                            </SummaryLabel>
                        )}

                        {requirements && requirements.length > 0 && (
                            <SummaryLabel>
                                <Text caption noMargin>
                                    {t('request-information-modal:requested-information')}
                                </Text>
                                <Text p noMargin data-test-id="text-requested-information">
                                    {requirements.map(requirement => (
                                        <span key={requirement}>
                                            -{' '}
                                            {t(
                                                `request-information-modal:requirement.${requirement.toLowerCase()}` as keyof MessageMap,
                                            )}
                                            <br />
                                        </span>
                                    ))}
                                </Text>
                            </SummaryLabel>
                        )}

                        {documentTypes && documentTypes.length > 0 && (
                            <SummaryLabel>
                                <Text caption noMargin>
                                    {t('request-information-modal:document-types')}
                                </Text>
                                <Text p noMargin data-test-id="text-document-types">
                                    {documentTypes.map(documentType => (
                                        <span key={documentType}>
                                            -{' '}
                                            {t(
                                                `request-information-modal:document-types.${documentType}` as keyof MessageMap,
                                            )}
                                            {documentType === 'other' && (
                                                <>
                                                    {': '}
                                                    {documentTypeOther}
                                                </>
                                            )}
                                            <br />
                                        </span>
                                    ))}
                                </Text>
                            </SummaryLabel>
                        )}

                        {application && (
                            <SummaryLabel>
                                <Text caption noMargin>
                                    {t('request-information-modal:application')}
                                </Text>
                                <Text p noMargin data-test-id="text-application">
                                    {t(`request-information:application:${application}` as keyof MessageMap)}

                                    {application === 'other' && (
                                        <>
                                            {': '}
                                            {applicationOther}
                                        </>
                                    )}
                                </Text>
                            </SummaryLabel>
                        )}

                        {quantityAnnual && !isNaN(parseNumber(quantityAnnual, true)) && (
                            <SummaryLabel>
                                <Text caption noMargin>
                                    {t('request-information-modal:annual-quantity')}
                                </Text>
                                <Text p noMargin data-test-id="text-annual-quantity">
                                    {formatNumber(parseNumber(quantityAnnual, true))} {formattedPackagingUom}
                                </Text>
                            </SummaryLabel>
                        )}

                        {quantityCallOff && !isNaN(parseNumber(quantityCallOff, true)) && (
                            <SummaryLabel>
                                <Text caption noMargin>
                                    {t('request-information-modal:call-off-quantity')}
                                </Text>
                                <Text p noMargin data-test-id="text-call-off-quantity">
                                    {formatNumber(parseNumber(quantityCallOff, true))} {formattedPackagingUom}
                                </Text>
                            </SummaryLabel>
                        )}

                        {quantitySample && !isNaN(parseNumber(quantitySample, true)) && (
                            <SummaryLabel>
                                <Text caption noMargin>
                                    {t('request-information-modal:sample-quantity')}
                                </Text>
                                <Text p noMargin data-test-id="text-sample-quantity">
                                    {formatNumber(parseNumber(quantitySample, true))} {formattedPackagingUom}
                                </Text>
                            </SummaryLabel>
                        )}

                        {comment && (
                            <SummaryLabel>
                                <Text caption noMargin>
                                    {t('request-information-modal:comment')}
                                </Text>
                                <TextWrapper p data-test-id="text-comment">
                                    {comment}
                                </TextWrapper>
                            </SummaryLabel>
                        )}
                    </>
                )
            }
            aside={
                <ScrollReference ref={form}>
                    {material && (
                        <RequestInformationForm
                            open={isOpen}
                            onSubmit={onSubmit}
                            onSetStep={onSetStep}
                            material={material}
                            loading={loading}
                            step={step}
                            onUpdateForm={setFormValues}
                        />
                    )}
                </ScrollReference>
            }
        />
    )
}
