import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Folder: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Folder" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M22 21H2V3H10.41L12.41 5H22V7H11.59L9.59 5H4V19H20V9H22V21Z"
                fill={fill === 'gradient' ? 'url(#Folder_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Folder" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Folder" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M22 21H2V3H10.41L12.41 5H22V7H11.59L9.59 5H4V19H20V9H22V21Z"
                fill={fill === 'gradient' ? 'url(#Folder_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Folder" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Folder" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M22 21H2V3H10.41L12.41 5H22V7H11.59L9.59 5H4V19H20V9H22V21Z"
                fill={fill === 'gradient' ? 'url(#Folder_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Folder" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Folder" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M22 21H2V3H10.41L12.41 5H22V7H11.59L9.59 5H4V19H20V9H22V21Z"
                fill={fill === 'gradient' ? 'url(#Folder_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Folder" />}
        </g>
    ),
}
