import { FormatNumber } from '@frontend/components/hooks'

export type NumberParserFunction = (value: string, lenient?: boolean) => number

interface ParserCache {
    [key: string]: NumberParserFunction
}

const thousandsRegex = /.*1(\S)2.*/
const decimalRegex = /.*4(\S)5.*/

const parserCache: ParserCache = {}

export const getLocalizedNumberParser = (formatNumber: FormatNumber): NumberParserFunction => {
    const detectionNumber = formatNumber(1234.56)
    const thousandsSeparator = detectionNumber.replace(thousandsRegex, '$1')
    const decimalSeparator = detectionNumber.replace(decimalRegex, '$1')
    const cacheKey = `${thousandsSeparator}${decimalSeparator}`

    if (!parserCache[cacheKey]) {
        const strictNumberRegex = new RegExp(
            `^([1-9]\\d{0,2}((\\${thousandsSeparator}\\d{3})*|\\d*)|0)(\\${decimalSeparator}\\d+)?$`,
        )

        const lenientNumberRegex = new RegExp(
            `^([1-9](\\d|\\${thousandsSeparator}\\d)*|0)(\\${decimalSeparator}\\d+)?$`,
        )

        parserCache[cacheKey] = (value: string, lenient: boolean = false): number => {
            const numberRegex = lenient ? lenientNumberRegex : strictNumberRegex
            if (numberRegex.test(value)) {
                const [decimal, fraction] = value.split(decimalSeparator)
                const decimalValue = Number(decimal.split(thousandsSeparator).join(''))
                const fractionValue = fraction ? Number(fraction) / Math.pow(10, fraction.length) : 0

                if (!isNaN(decimalValue) && !isNaN(fractionValue)) {
                    return decimalValue + fractionValue
                }
            }

            return NaN
        }
    }

    return parserCache[cacheKey]
}

export const toLocalizedInputNumber = (formatNumber: FormatNumber, inputNumber: number) => {
    if (inputNumber < 0) {
        throw new RangeError('This cannot be used with negative numbers')
    }

    const decimalDetectionMatch = formatNumber(1.23).match(/\d(.)\d{2}/)
    const separator = decimalDetectionMatch && decimalDetectionMatch[1]
    return formatNumber(inputNumber).replace(
        new RegExp(`([^${separator}]+)(${separator})?(\\d*)$`),
        (_m: string, p1: string, _p2: string, p3: string) => p1.replace(/\D/g, '') + (p3 ? separator + p3 : ''),
    )
}
