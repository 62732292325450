import { Text, Tooltip } from '@bc/ui'
import React from 'react'

interface Props {
    title: string
}

export const PackagingTooltip = ({ title }: Props) => {
    if (title) {
        return (
            <Tooltip placement="bottom">
                <Text noMargin>{title}</Text>
            </Tooltip>
        )
    }

    return null
}
