import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { QueryResult } from '@apollo/react-common'
import { useQuery } from '@apollo/react-hooks'

import debounce from 'debounce'

import graphqlUtils from '@bc/graphql-utils'
import { tagManager } from '@bc/gtm'
import { GQLBaseOrderFilters, GQLBaseOrderStatus } from '@bc/types'
import { FullScreenLoaderRound, SelectInputOption } from '@bc/ui'
import { useCopyToClipboard, useFormatMessage } from '@frontend/components'
import {
    FiltersContextData,
    FiltersProvider,
    PlatformConfigContext,
    SortContextData,
    SortProvider,
    UserContext,
} from '@frontend/context'

import { useFilters } from '@frontend/components/hooks/use-filters'
import { MyOrdersBasePage } from './my-orders-base-page'
import { GetBaseOrderResponse, getBaseOrders } from './queries'

type BaseOrderPageQuery = Omit<GQLBaseOrderFilters, 'orderStatus'> & { orderStatus: GQLBaseOrderStatus }

const initialPage = 1
const initialSort = '-baseOrderDate'
const noStatuses: GQLBaseOrderStatus[] = []
const initialFilters: GQLBaseOrderFilters = {
    orderStatus: noStatuses,
}

const queryToFilter = (query: BaseOrderPageQuery) => {
    const { orderStatus: qStatus, ...rest } = query
    const orderStatus: GQLBaseOrderStatus[] =
        typeof qStatus === 'string' ? [qStatus] : qStatus ?? initialFilters.orderStatus
    return {
        ...initialFilters,
        ...rest,
        orderStatus,
    }
}

const OrderHistoryPageContainer = () => {
    const t = useFormatMessage()
    const { currentCustomer, activeCustomerId = '', activeSalesOrganisation = '', activeSalesAreaId = '' } = useContext(
        UserContext,
    )
    const { shopConfig } = useContext(PlatformConfigContext)
    const copyToClipboard = useCopyToClipboard()

    const orderStatuses = shopConfig.current?.orderStatuses ?? noStatuses
    const { filters, page = initialPage, sort = initialSort, updateFilters } = useFilters<
        GQLBaseOrderFilters,
        BaseOrderPageQuery
    >(queryToFilter)

    const shipTos = graphqlUtils.customer.getShipTos(currentCustomer)

    const variables = {
        page,
        pageSize: 10,
        sort,
        filters,
    }
    const { error, loading, data }: QueryResult<GetBaseOrderResponse> = useQuery(getBaseOrders, {
        variables,
        ssr: false,
        fetchPolicy: 'cache-first',
    })
    const orders = data?.baseOrders?.baseOrders
    const pagination = data?.baseOrders?.pagination ?? {
        count: 0,
        page: 1,
        pages: 1,
    }

    useEffect(() => {
        // reset to first page if more than available results is requested in the url
        if (orders && page > pagination.pages) {
            updateFilters({ ...filters, sort, page: pagination.page })
        }
    }, [orders])

    let debouncePo: any
    let debounceMaterialName: any
    let debounceOrderId: any

    const handleSortChange = (newSort: SelectInputOption) => {
        const { value } = newSort
        tagManager.tags.orderHistorySort(value)
        updateFilters({ ...filters, sort: value, page: initialPage })
    }

    const handlePageClick = (newPage: number) => {
        updateFilters({ ...filters, sort, page: newPage })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    const materialNameFilter = (filter: string) => {
        if (debounceMaterialName) {
            debounceMaterialName.clear()
        }
        debounceMaterialName = debounce(() => tagManager.tags.orderHistoryFilterByName(filter), 1000)
        debounceMaterialName()
    }

    const orderIdFilter = (filter: string) => {
        if (debounceOrderId) {
            debounceOrderId.clear()
        }
        debounceOrderId = debounce(() => tagManager.tags.orderHistoryFilterByOrderId(filter), 1000)
        debounceOrderId()
    }

    const customerReferenceFilter = (filter: string) => {
        if (debouncePo) {
            debouncePo.clear()
        }
        debouncePo = debounce(() => tagManager.tags.orderHistoryFilterByPo(filter), 1000)
        debouncePo()
    }

    const shipToIdFilter = (filter: string) => {
        const filterAddress = shipTos.find(shipToLocation => shipToLocation.id === filter)
        if (filterAddress) {
            tagManager.tags.orderHistoryFilterByAddress(filterAddress)
        }
    }
    const orderStatusFilter = (filter: string) => {
        if (filter) {
            tagManager.tags.orderHistoryFilterByStatus(filter)
        }
    }

    const handleFilterChange = (newFilters: GQLBaseOrderFilters) => {
        if (newFilters.customerPoReference && newFilters.customerPoReference !== filters.customerPoReference) {
            customerReferenceFilter(newFilters.customerPoReference)
        }
        if (newFilters.materialName && newFilters.materialName !== filters.materialName) {
            materialNameFilter(newFilters.materialName)
        }
        if (newFilters.orderId && newFilters.orderId !== filters.orderId) {
            orderIdFilter(newFilters.orderId)
        }

        if (newFilters.shipToId && newFilters.shipToId !== filters.shipToId) {
            shipToIdFilter(newFilters.shipToId)
        }

        if (newFilters.orderStatus.length > 0 && newFilters.orderStatus.length !== filters.orderStatus?.length) {
            newFilters.orderStatus.forEach(s => orderStatusFilter(s))
        }

        updateFilters({ ...newFilters, sort, page: initialPage })
    }

    const handleCopyRequestLink = (_formFilters: GQLBaseOrderFilters) => {
        copyToClipboard(
            `${window.location.href}&cid=${activeCustomerId}&so=${activeSalesOrganisation}&sid=${activeSalesAreaId}`,
        )
    }

    const sortOptions: SelectInputOption[] = [
        {
            value: 'orderId',
            label: `${t('general:order-id')} ${t('sort-direction:numeric:asc')}`,
        },
        {
            value: '-orderId',
            label: `${t('general:order-id')} ${t('sort-direction:numeric:desc')}`,
        },
        {
            value: 'customerPoReference',
            label: `${t('sort-by-po-reference')} ${t('sort-direction:numeric:asc')}`,
        },
        {
            value: '-customerPoReference',
            label: `${t('sort-by-po-reference')} ${t('sort-direction:numeric:desc')}`,
        },
        {
            value: 'baseOrderDate',
            label: `${t('sort-by-order-date')} ${t('sort-direction:date:asc')}`,
        },
        {
            value: '-baseOrderDate',
            label: `${t('sort-by-order-date')} ${t('sort-direction:date:desc')}`,
        },
    ]

    const filterProviderProps: FiltersContextData<GQLBaseOrderFilters> = {
        onFilterChange: handleFilterChange,
        filters: initialFilters,
        query: filters,
        onLinkCopy: handleCopyRequestLink,
    }
    const sortProviderProps: SortContextData = {
        onSortChange: handleSortChange,
        sort,
        sortOptions,
    }

    return (
        <FiltersProvider {...filterProviderProps}>
            <SortProvider {...sortProviderProps}>
                <FullScreenLoaderRound isVisible={loading} />
                <MyOrdersBasePage
                    error={error}
                    loading={loading}
                    pagination={pagination}
                    orders={orders}
                    shipTos={shipTos}
                    orderStatuses={orderStatuses}
                    onPageClick={handlePageClick}
                />
            </SortProvider>
        </FiltersProvider>
    )
}

export default withRouter(OrderHistoryPageContainer)
