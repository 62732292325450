import React from 'react'
import styled from 'styled-components'

import { GQLPrice } from '@bc/types'
import { colors, spacing, Text } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { PriceScale } from './price-scale'

interface PriceScaleConditionProps {
    prices: GQLPrice[]
    footNote?: boolean
    disabled?: boolean
}

const PriceScaleTable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 350px;
    margin-bottom: ${spacing.xs}px;
`

const PriceRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`

const ConditionsRow = styled(PriceRow)`
    margin-top: ${spacing.xxs}px;
`

const PriceColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
`

const TextValue = styled(Text).attrs(() => ({
    noMargin: true,
}))`
    display: block;
`

const Caption = styled(TextValue).attrs(() => ({
    caption: true,
}))``

const Disable = styled.span<Pick<PriceScaleConditionProps, 'disabled'>>`
    ${({ disabled }) => (disabled ? 'color: ' + colors.neutral50 : '')};
`
export const PriceScaleCondition = ({ prices, footNote, disabled }: PriceScaleConditionProps) => {
    const t = useFormatMessage()
    const { paymentTerms, packagingControl, shippingConditions, incoTermId } = prices[0]

    return (
        <Disable disabled={disabled}>
            <PriceScale prices={prices} footNote={footNote} />
            <PriceScaleTable>
                <ConditionsRow>
                    <PriceColumn>
                        <Caption>{t('delivery-overview:packaging')}</Caption>
                        <TextValue data-test-class="text-price-scale-packaging">{packagingControl}</TextValue>
                    </PriceColumn>
                    <PriceColumn>
                        <Caption>{t('delivery-overview:terms-of-delivery')}</Caption>
                        <TextValue data-test-class="text-price-scale-terms-of-delivery">{shippingConditions}</TextValue>
                    </PriceColumn>
                </ConditionsRow>

                <ConditionsRow>
                    <PriceColumn>
                        <Caption>{t('delivery-overview:INCO-terms')}</Caption>

                        <TextValue data-test-class="text-price-scale-inco-terms">{incoTermId}</TextValue>
                    </PriceColumn>
                    <PriceColumn>
                        <Caption>{t('delivery-overview:payment-terms')}</Caption>
                        <TextValue data-test-class="text-price-scale-terms-of-payment">{paymentTerms}</TextValue>
                    </PriceColumn>
                </ConditionsRow>
            </PriceScaleTable>
        </Disable>
    )
}

interface PriceScaleConditionConfirmationProps {
    prices?: GQLPrice[]
    disabled?: boolean
}

export const PriceScaleConditionConfirmation = ({ prices, disabled }: PriceScaleConditionConfirmationProps) => {
    const t = useFormatMessage()

    return prices && prices.length > 0 ? (
        <>
            <PriceScaleCondition prices={prices} footNote disabled={disabled} />
            {'* '}
            {t('delivery-overview:legal-terms-price')}
        </>
    ) : (
        <Text>{t('general:price-confirmation')}</Text>
    )
}
