import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Chevron_Right: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Chevron_Right" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M8.71004 21.71L7.29004 20.29L15.59 12L7.29004 3.71L8.71004 2.29L18.41 12L8.71004 21.71Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Right_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Right" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Chevron_Right" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M8.71004 21.71L7.29004 20.29L15.59 12L7.29004 3.71L8.71004 2.29L18.41 12L8.71004 21.71Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Right_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Right" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Chevron_Right" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M8.71004 21.71L7.29004 20.29L15.59 12L7.29004 3.71L8.71004 2.29L18.41 12L8.71004 21.71Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Right_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Right" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Chevron_Right" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M8.71004 21.71L7.29004 20.29L15.59 12L7.29004 3.71L8.71004 2.29L18.41 12L8.71004 21.71Z"
                fill={fill === 'gradient' ? 'url(#Chevron_Right_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Chevron_Right" />}
        </g>
    ),
}
