import { GlobalStyles } from '@bc/ui'
import { ErrorBoundary, VirtualPageview } from '@frontend/components'
import {
    NotificationsProvider,
    PlatformProvider,
    SessionProvider,
    ShoppingCartProvider,
    ToastsProvider,
} from '@frontend/context'
import { LoginHandlerPage, LoginPage } from '@frontend/pages'
import { mergeCartOrderLines } from '@frontend/utils'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { AppBase } from './appBase'
import { AppRoutes } from './appRoutes'

interface AppProps {
    cookies?: any
}

export const App = ({ cookies }: AppProps) => (
    <>
        <GlobalStyles />
        <SessionProvider cookies={cookies}>
            <PlatformProvider>
                <VirtualPageview>
                    <Switch>
                        <Route path="/login" exact component={LoginPage} />
                        <Route path="/handle-login" exact component={LoginHandlerPage} />
                        <Route
                            path="/"
                            render={() => (
                                <NotificationsProvider>
                                    <ShoppingCartProvider merge={mergeCartOrderLines}>
                                        <ToastsProvider>
                                            <AppBase>
                                                <ErrorBoundary>
                                                    <AppRoutes />
                                                </ErrorBoundary>
                                            </AppBase>
                                        </ToastsProvider>
                                    </ShoppingCartProvider>
                                </NotificationsProvider>
                            )}
                        />
                    </Switch>
                </VirtualPageview>
            </PlatformProvider>
        </SessionProvider>
    </>
)
