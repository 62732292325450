import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'
import { CheckboxRadioLabelProps, checkboxRadioBeforeStyles, labelStyles, afterTransitionStyles } from './styles'

export const Label = styled.label<CheckboxRadioLabelProps>`
    display: flex;
    cursor: pointer;

    ${labelStyles}

    ${checkboxRadioBeforeStyles}

    &:after {
        content: '✓';
        position: absolute;
        display: block;
        width: 24px;
        height: 24px;
        left: 6px;
        top: 3px;
        color: ${colors.white};

        ${afterTransitionStyles}
    }
`
