import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Square_Delete: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Square_Delete" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M3 3H21V5H5V19H19V7H21V21H3V3ZM11.9991 10.59L15.2891 7.29004L16.7091 8.71004L13.4091 12L16.7091 15.29L15.2891 16.71L11.9991 13.41L8.70906 16.71L7.28906 15.29L10.5891 12L7.28906 8.71004L8.70906 7.29004L11.9991 10.59Z"
                fill={fill === 'gradient' ? 'url(#Square_Delete_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Square_Delete" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Square_Delete" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M3 3H21V5H5V19H19V7H21V21H3V3ZM11.9991 10.59L15.2891 7.29004L16.7091 8.71004L13.4091 12L16.7091 15.29L15.2891 16.71L11.9991 13.41L8.70906 16.71L7.28906 15.29L10.5891 12L7.28906 8.71004L8.70906 7.29004L11.9991 10.59Z"
                fill={fill === 'gradient' ? 'url(#Square_Delete_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Square_Delete" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Square_Delete" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M3 3H21V5H5V19H19V7H21V21H3V3ZM11.9991 10.59L15.2891 7.29004L16.7091 8.71004L13.4091 12L16.7091 15.29L15.2891 16.71L11.9991 13.41L8.70906 16.71L7.28906 15.29L10.5891 12L7.28906 8.71004L8.70906 7.29004L11.9991 10.59Z"
                fill={fill === 'gradient' ? 'url(#Square_Delete_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Square_Delete" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Square_Delete" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M3 3H21V5H5V19H19V7H21V21H3V3ZM11.9991 10.59L15.2891 7.29004L16.7091 8.71004L13.4091 12L16.7091 15.29L15.2891 16.71L11.9991 13.41L8.70906 16.71L7.28906 15.29L10.5891 12L7.28906 8.71004L8.70906 7.29004L11.9991 10.59Z"
                fill={fill === 'gradient' ? 'url(#Square_Delete_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Square_Delete" />}
        </g>
    ),
}
