import { colors, defaultFontSpacing, transition } from '@bc/ui/src/config'
import { getFontFamily, media } from '@bc/ui/src/helpers'

import { ButtonProps, ButtonVariant } from './button-types'

const buttonStyles: string = `
    position: relative;
    align-items: center;
    justify-content: center;
    font-family: ${getFontFamily('brenntagsans_bold')};
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;
    color: white;
    line-height: 1.6;
    border: 1px solid transparent
`

const fullOnMobileStyles = ({ fullOnMobile }: ButtonProps) =>
    fullOnMobile &&
    `${media.max('sm')} {
        width: 100%;
    }`

const fullWidthStyles = ({ fullWidth }: ButtonProps) => fullWidth && `width: 100%`

const sizeStyles = ({ buttonSize, hasText }: ButtonProps): string => {
    switch (buttonSize) {
        case 'xx-large':
            return `
                font-size: 2.5rem;
                line-height: 2;
                padding: 1.65rem ${hasText ? `3` : `1.65`}rem;

                ${media.max('sm')} {
                    font-size: 2.2rem;
                    padding: 1rem ${hasText ? `2` : `1`}rem;
                };
            `
        case 'x-large':
            return `
                font-size: 2.2rem;
                line-height: 2;
                padding: 1.4rem ${hasText ? `2.5` : `1.4`}rem;

                ${media.max('md')} {
                    font-size: 1.8rem;
                    padding: 1rem ${hasText ? `1.6` : `1`}rem;
                };
            `
        case 'large':
            return `
                line-height: 1.5;
                font-size: 1.5rem;
                padding: 1.2rem ${hasText ? `2` : `1.2`}rem;
                flex: none;
            `
        case 'medium':
        default:
            return `
                ${defaultFontSpacing}
                padding: 0.9rem ${hasText ? `1.6` : `0.9`}rem;
                flex: none;
            `
        case 'small':
            return `
                line-height: 1.3;
                font-size: 1.3rem;
                padding: 0.6rem ${hasText ? `1.2` : `0.6`}rem;
                flex: none;
            `
        case 'x-small':
            return `
                line-height: 1.2;
                font-size: 1.0rem;
                padding: 0.4rem ${hasText ? `0.8` : `0.4`}rem;
                flex: none;
            `
    }
}

export interface ThemeColors {
    background: string
    active: string
    hover: string
    focus: string
    text: string
}

const getThemeButtonColors = (variant: ButtonVariant | undefined): ThemeColors => {
    switch (variant) {
        case 'action':
        default:
            return {
                background: `linear-gradient(90deg,${colors.violet50} 0%, ${colors.blue50} 100%);`,
                active: colors.blue60,
                hover: colors.blue50,
                focus: `linear-gradient(90deg,${colors.violet50} 0%, ${colors.blue50} 100%);`,
                text: colors.white,
            }
        case 'outline':
            return {
                background: colors.white,
                active: colors.neutral90,
                hover: colors.neutral80,
                focus: colors.deepPurple,
                text: colors.deepPurple,
            }
        case 'solid':
            return {
                background: colors.deepPurple,
                active: colors.neutral90,
                hover: colors.neutral80,
                focus: colors.deepPurple,
                text: colors.white,
            }
        case 'success':
            return {
                background: colors.white,
                active: colors.green80,
                hover: colors.green70,
                focus: colors.green70,
                text: colors.green70,
            }
        case 'error':
            return {
                background: colors.white,
                active: colors.red80,
                hover: colors.red70,
                focus: colors.red70,
                text: colors.red70,
            }
        case 'text':
            return {
                background: 'transparent !important',
                hover: colors.neutral80,
                focus: colors.deepPurple,
                text: colors.deepPurple,
                active: colors.neutral90,
            }
    }
}

const variantStyles = ({ variant }: ButtonProps) => {
    const { text, background, hover, focus, active } = getThemeButtonColors(variant)

    const colorStyles = `
        background: ${background};
        background-origin: border-box;
        color: ${text};

        &:hover {
            background: ${hover};
            background-origin: border-box;
        };

        &:focus {
            background: ${focus};
            background-origin: border-box;
            box-shadow: 0px 0px 0px 2px ${colors.white}, 0px 0px 0px 4px ${colors.deepPurple};
        };

        &:active,&.active {
            background: ${active};
            background-origin: border-box;
        };
    `
    switch (variant) {
        default:
            return colorStyles
        case 'outline':
        case 'error':
        case 'success':
            return `
                    ${colorStyles}
                    border-color: ${text};
                    &:focus,&:hover, &:active,&.active  {
                        color: ${colors.white}; 
                    };
                    `
        case 'text':
            return `
                    ${colorStyles}
                    padding: 0;
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid ${background};
                    background: transparent !important;
                    text-align: right;
                    transition: none;
                    &:hover {
                        color: ${hover};
                    };
            
                    &:focus {
                        color: ${focus};
                        box-shadow: unset;
                    };
            
                    &:active,&.active {
                        color: ${active};
                    };
                    `
    }
}

const disabledStyles = ({ disabled, variant }: ButtonProps): string => {
    if (!disabled) {
        return ''
    }

    const style = `
        background: ${variant === 'outline' ? 'transparent' : colors.neutral30};
        color: ${variant === 'outline' || variant === 'text' ? colors.neutral30 : colors.white};
        border: 1px solid ${colors.neutral30};
    `

    return `
        cursor: ${disabled ? 'not-allowed' : 'pointer'};
        ${style}

        &:hover,&:focus,&:active,&.active {
            ${style}
            box-shadow: unset;
        };
    `
}

const loadingStyles = ({ isLoading }: ButtonProps): string => {
    if (!isLoading) {
        return ''
    }

    const style = `
        color: rgba(0, 0, 0, 0);
    `

    return `
        cursor: default;
        ${style}
    `
}

const tooltipStyles = ({ variant, tooltipText }: ButtonProps): string => {
    const { background, hover } = getThemeButtonColors(variant)
    return tooltipText
        ? `
            path {
                fill:  ${background};
            }
            &:hover path {
                fill:  ${hover};
                transition: fill ${transition.config};
            }​
    `
        : ``
}

const linkStyles = (): string => `
    color: ${colors.blue60};
    &.active, &:active {
        color: ${colors.blue70};
    }
    &:focus {
        color: ${colors.blue60};
    }
    &:hover {
        color: ${colors.blue50};
    }
    &:visited {
        color: ${colors.violet60};
        &.active, &:active {
            color: ${colors.violet70};
        }
        &:focus {
            color: ${colors.violet60};
        }
        &:hover {
            color: ${colors.violet50};
        }
    }
`

export const styles = {
    default: buttonStyles,
    size: sizeStyles,
    variant: variantStyles,
    disabled: disabledStyles,
    loading: loadingStyles,
    fullOnMobile: fullOnMobileStyles,
    fullWidth: fullWidthStyles,
    tooltip: tooltipStyles,
    link: linkStyles,
}
