import React, { FC, ReactNode } from 'react'

import { RegionFeatures, Resources, ShopFeatures } from '@bc/config'
import { useHasAccess } from '@frontend/components/hooks'

interface Props {
    flag: ShopFeatures | RegionFeatures | Resources | (ShopFeatures | RegionFeatures | Resources)[]
    on?: ReactNode
    off?: ReactNode
}

export const Feature: FC<Props> = ({ flag, on, off }: Props) => {
    const hasAccess = useHasAccess()
    const gotAccess = Array.isArray(flag) ? flag.every(hasAccess) : hasAccess(flag)

    return gotAccess ? <>{on}</> : <>{off}</>
}
