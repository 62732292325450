import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Finance: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Finance" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M3 7.44982L12 1.81982L21 7.44982V9.99982H13V7.99982H18.11L12 4.17982L5.89 7.99982H11V9.99982H3V7.44982ZM19 12H17V18H19V12ZM21 22V20H3V22H21ZM11 18V12H13V18H11ZM7 12H5V18H7V12Z"
                fill={fill === 'gradient' ? 'url(#Finance_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Finance" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Finance" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M3 7.44982L12 1.81982L21 7.44982V9.99982H13V7.99982H18.11L12 4.17982L5.89 7.99982H11V9.99982H3V7.44982ZM19 12H17V18H19V12ZM21 22V20H3V22H21ZM11 18V12H13V18H11ZM7 12H5V18H7V12Z"
                fill={fill === 'gradient' ? 'url(#Finance_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Finance" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Finance" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M3 7.44982L12 1.81982L21 7.44982V9.99982H13V7.99982H18.11L12 4.17982L5.89 7.99982H11V9.99982H3V7.44982ZM19 12H17V18H19V12ZM21 22V20H3V22H21ZM11 18V12H13V18H11ZM7 12H5V18H7V12Z"
                fill={fill === 'gradient' ? 'url(#Finance_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Finance" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Finance" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M3 7.44982L12 1.81982L21 7.44982V9.99982H13V7.99982H18.11L12 4.17982L5.89 7.99982H11V9.99982H3V7.44982ZM19 12H17V18H19V12ZM21 22V20H3V22H21ZM11 18V12H13V18H11ZM7 12H5V18H7V12Z"
                fill={fill === 'gradient' ? 'url(#Finance_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Finance" />}
        </g>
    ),
}
