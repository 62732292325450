import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Location: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Location" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M4 10C4 5.59333 7.58457 2.00543 11.99 2.00001C16.3954 2.00543 19.98 5.59333 19.98 10V11C19.98 13.2 19.13 15.27 17.57 16.83L14.81 19.59L13.4 18.18L16.16 15.42C17.34 14.25 17.99 12.68 17.99 11.01V10.01C17.99 6.7 15.3 4.01 11.99 4.01C8.68 4.01 5.99 6.7 5.99 10.01V11.01C5.99 12.67 6.64 14.24 7.82 15.42L13.41 21.01L12 22.42L6.41 16.83C4.86 15.27 4 13.2 4 11V10ZM11.99 2.00001C11.9933 2 11.9967 2 12 2H11.98C11.9833 2 11.9867 2 11.99 2.00001ZM14 10C14 9.82001 13.97 9.65001 13.93 9.49001L13.92 9.48001L15.43 7.97001C15.78 8.57001 16 9.25001 16 9.99001C16 12.2 14.21 13.99 12 13.99C9.79 13.99 8 12.2 8 9.99001C8 7.78001 9.79 5.99001 12 5.99001C12.74 5.99001 13.43 6.21001 14.02 6.56001L12.51 8.07001C12.35 8.03001 12.18 8.00001 12 8.00001C10.9 8.00001 10 8.90001 10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10Z"
                fill={fill === 'gradient' ? 'url(#Location_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Location" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Location" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M4 10C4 5.59333 7.58457 2.00543 11.99 2.00001C16.3954 2.00543 19.98 5.59333 19.98 10V11C19.98 13.2 19.13 15.27 17.57 16.83L14.81 19.59L13.4 18.18L16.16 15.42C17.34 14.25 17.99 12.68 17.99 11.01V10.01C17.99 6.7 15.3 4.01 11.99 4.01C8.68 4.01 5.99 6.7 5.99 10.01V11.01C5.99 12.67 6.64 14.24 7.82 15.42L13.41 21.01L12 22.42L6.41 16.83C4.86 15.27 4 13.2 4 11V10ZM11.99 2.00001C11.9933 2 11.9967 2 12 2H11.98C11.9833 2 11.9867 2 11.99 2.00001ZM14 10C14 9.82001 13.97 9.65001 13.93 9.49001L13.92 9.48001L15.43 7.97001C15.78 8.57001 16 9.25001 16 9.99001C16 12.2 14.21 13.99 12 13.99C9.79 13.99 8 12.2 8 9.99001C8 7.78001 9.79 5.99001 12 5.99001C12.74 5.99001 13.43 6.21001 14.02 6.56001L12.51 8.07001C12.35 8.03001 12.18 8.00001 12 8.00001C10.9 8.00001 10 8.90001 10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10Z"
                fill={fill === 'gradient' ? 'url(#Location_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Location" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Location" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M4 10C4 5.59333 7.58457 2.00543 11.99 2.00001C16.3954 2.00543 19.98 5.59333 19.98 10V11C19.98 13.2 19.13 15.27 17.57 16.83L14.81 19.59L13.4 18.18L16.16 15.42C17.34 14.25 17.99 12.68 17.99 11.01V10.01C17.99 6.7 15.3 4.01 11.99 4.01C8.68 4.01 5.99 6.7 5.99 10.01V11.01C5.99 12.67 6.64 14.24 7.82 15.42L13.41 21.01L12 22.42L6.41 16.83C4.86 15.27 4 13.2 4 11V10ZM11.99 2.00001C11.9933 2 11.9967 2 12 2H11.98C11.9833 2 11.9867 2 11.99 2.00001ZM14 10C14 9.82001 13.97 9.65001 13.93 9.49001L13.92 9.48001L15.43 7.97001C15.78 8.57001 16 9.25001 16 9.99001C16 12.2 14.21 13.99 12 13.99C9.79 13.99 8 12.2 8 9.99001C8 7.78001 9.79 5.99001 12 5.99001C12.74 5.99001 13.43 6.21001 14.02 6.56001L12.51 8.07001C12.35 8.03001 12.18 8.00001 12 8.00001C10.9 8.00001 10 8.90001 10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10Z"
                fill={fill === 'gradient' ? 'url(#Location_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Location" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Location" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M4 10C4 5.59333 7.58457 2.00543 11.99 2.00001C16.3954 2.00543 19.98 5.59333 19.98 10V11C19.98 13.2 19.13 15.27 17.57 16.83L14.81 19.59L13.4 18.18L16.16 15.42C17.34 14.25 17.99 12.68 17.99 11.01V10.01C17.99 6.7 15.3 4.01 11.99 4.01C8.68 4.01 5.99 6.7 5.99 10.01V11.01C5.99 12.67 6.64 14.24 7.82 15.42L13.41 21.01L12 22.42L6.41 16.83C4.86 15.27 4 13.2 4 11V10ZM11.99 2.00001C11.9933 2 11.9967 2 12 2H11.98C11.9833 2 11.9867 2 11.99 2.00001ZM14 10C14 9.82001 13.97 9.65001 13.93 9.49001L13.92 9.48001L15.43 7.97001C15.78 8.57001 16 9.25001 16 9.99001C16 12.2 14.21 13.99 12 13.99C9.79 13.99 8 12.2 8 9.99001C8 7.78001 9.79 5.99001 12 5.99001C12.74 5.99001 13.43 6.21001 14.02 6.56001L12.51 8.07001C12.35 8.03001 12.18 8.00001 12 8.00001C10.9 8.00001 10 8.90001 10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10Z"
                fill={fill === 'gradient' ? 'url(#Location_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Location" />}
        </g>
    ),
}
