import React, { useContext } from 'react'
import styled from 'styled-components'

import { GQLRequest, GQLRequestsFilters, GQLRequestType } from '@bc/types'
import { CardEnriched, colors, RichRowListItemProps, SvgIcon, TextHighlight } from '@bc/ui'

import { useFormatDate, useFormatMessage } from '@frontend/components/hooks/intl-hooks'
import { statusAsColor, statusAsIconName } from '@frontend/utils'

import { FiltersContextData, FiltersContext } from '@frontend/context'
import { MessageMap } from '@bc/translations/src/types'
import { MaterialRequestCard } from './material-request-card'

interface RequestsCsrCardProps {
    request: GQLRequest
    content: JSX.Element
    actions: JSX.Element
}

interface ColorProps {
    color: keyof typeof colors
}

const CardSidebarColor = styled.div`
    border-left: 3px solid ${({ color }: ColorProps) => colors[color]};
`

export const RequestsCsrCard = ({ request, content, actions }: RequestsCsrCardProps) => {
    const formatDate = useFormatDate()
    const t = useFormatMessage()

    const filterContext: FiltersContextData<GQLRequestsFilters> = useContext(FiltersContext)
    const { customer: filterCustomer, uid: filterUid } = filterContext?.query ?? {}

    const { type, status, uid, customer, user, values, createdAt, materials } = request

    const listItemsForRequest = (): RichRowListItemProps[] => {
        const contents: RichRowListItemProps[] = [
            {
                label: t('requests:customer'),
                value: customer.name || '—',
                id: 'customer',
                highlight: filterCustomer,
            },
            {
                label: t('requests:recipient'),
                value: (
                    <>
                        {`${user.firstName} ${user.lastName}`}
                        <br />
                        {user.email}
                        <br />
                        {values.phone}
                    </>
                ),
                id: 'recipient',
            },
            { label: '', value: '' },
        ]

        switch (type) {
            case GQLRequestType.DOCUMENT:
            case GQLRequestType.PRICE:
            case GQLRequestType.SAMPLE:
                contents.pop()
                contents.push({
                    label: t('requests:application'),
                    value: values.application
                        ? t(`request-information:application:${values.application}` as keyof MessageMap)
                        : '-',
                    id: 'application',
                })
        }
        contents.push({
            label: t('requests:requested'),
            value: formatDate(createdAt),
            id: 'request-date',
        })

        return contents
    }

    const list = request ? listItemsForRequest() : []

    return (
        <CardSidebarColor
            color={statusAsColor(status)}
            data-test-id={uid}
            data-test-class="card-request"
            data-test-status={status}
            data-test-type={type}>
            <CardEnriched
                header={{
                    title: formatDate(request.createdAt),
                    subtitle: <TextHighlight search={filterUid}>{`#${uid}`}</TextHighlight>,
                    icon: <SvgIcon icon={statusAsIconName(type)} size="32" fill={colors[statusAsColor(status)]} />,
                }}
                actions={actions}
                list={list}>
                <MaterialRequestCard values={values} type={type} materials={materials} />
                {content}
            </CardEnriched>
        </CardSidebarColor>
    )
}
