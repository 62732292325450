import React from 'react'
import styled from 'styled-components'

import { SvgIcon } from '@bc/ui/src/components/svg'
import { breakpoints, colors } from '@bc/ui/src/config'

import { GQLMaterial, GQLBaseOrder } from '@bc/types'
import { OnSelectChangeFn } from '../form-elements/select-input'
import { TableHeader } from './table-header'
import { getBasis } from './table-helpers'
import { TableRow } from './table-row'

export enum ContentType {
    STRING,
    ARRAY_STRING,
    OTHER,
}

export interface ColumnContentArguments {
    column: TableColumnType
    material?: GQLMaterial
    order?: GQLBaseOrder
}

export interface TableColumnType<TableDataType = { [key: string]: any }> {
    name: keyof TableDataType | 'actions'
    sticky: boolean
    ratio: number
    title?: string
    type: ContentType
    sortable?: boolean
    overflowVisible?: boolean
    hiddenMax?: keyof typeof breakpoints
    hiddenMin?: keyof typeof breakpoints
    mobileWidth?: number
    Content: React.ComponentType<ColumnContentArguments>
}

export interface TableBaseProps {
    sort?: string
    columns: TableColumnType[]
    children?: React.ReactNode
    onChangeSort?: OnSelectChangeFn
    loading?: boolean
    hasFixedFirst?: boolean
}

export type TableProps = TableBaseProps

export const TableWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    margin-bottom: 20px;
`

const Span = styled.span`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SortIcon = styled(SvgIcon).attrs(() => ({ size: '16' }))`
    position: relative;
    top: 2px;
    flex-shrink: 0;
    align-self: flex-start;
    margin-left: 5px;
`

const changeSort = (column: string, sort: string | undefined, callback?: OnSelectChangeFn) => {
    let newSort

    if (sort === column) {
        newSort = `-${column}`
    } else if (sort === `-${column}`) {
        newSort = undefined
    } else {
        newSort = column
    }

    newSort && callback?.({ value: newSort, label: newSort })
}

export const Table: React.FunctionComponent<TableProps> = ({
    sort,
    columns,
    hasFixedFirst,
    children,
    onChangeSort,
}) => (
    <>
        <TableWrapper>
            <TableRow header>
                {columns.map((column, columnIndex) => {
                    const columnSortable = !!onChangeSort && column.sortable !== false
                    return (
                        <TableHeader
                            key={column.name}
                            ratio={column.ratio}
                            first={hasFixedFirst && columnIndex === 0}
                            hiddenMin={column.hiddenMin}
                            hiddenMax={column.hiddenMax}
                            basis={getBasis(columns, column.ratio)}
                            sortable={columnSortable}
                            title={column.title}
                            onClick={columnSortable ? () => changeSort(column.name, sort, onChangeSort) : undefined}
                            data-test-id={`header-${column.name}`}>
                            <Span>{column.title}</Span>
                            {columnSortable &&
                                (column.name === sort || `-${column.name}` === sort ? (
                                    <SortIcon icon={sort.startsWith('-') ? 'Arrow_Up' : 'Arrow_Down'} />
                                ) : (
                                    <SortIcon icon="ChangeOrder" fill={colors.neutral20} />
                                ))}
                        </TableHeader>
                    )
                })}
            </TableRow>
            {children}
        </TableWrapper>
    </>
)
