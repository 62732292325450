import React from 'react'
import { SubmissionErrors } from 'final-form'

import { colors, NotificationMessage, OxygenTemplate } from '@bc/ui'
import { FormatMessage, PasswordSetForm, TitleDescriptionWrapper, TokenInvalidError } from '@frontend/components'

import { SetPasswordValues } from './set-password-page-container'

interface SetPasswordProps {
    t: FormatMessage
    onSubmit: (values: SetPasswordValues) => void | SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined
    token: string | undefined
    email: string | undefined
    isTokenValid: boolean | undefined
}
export const SetPasswordPage = ({ t, onSubmit, email, isTokenValid }: SetPasswordProps): JSX.Element => (
    <OxygenTemplate>
        {isTokenValid ? (
            <TitleDescriptionWrapper title={t('set-password-form:title')}>
                <NotificationMessage icon="Key" backgroundColor={colors.red10} borderColor={colors.red70}>
                    {t('set-password-form:password-instruction')}
                </NotificationMessage>
                <PasswordSetForm t={t} onSubmit={onSubmit} />
            </TitleDescriptionWrapper>
        ) : (
            <TokenInvalidError email={email} />
        )}
    </OxygenTemplate>
)
