import { GQLBaseOrderStatus, GQLRequestStatus, GQLRequestType } from '@bc/types'

import { MessageMap } from '@bc/translations'
import { colors, SvgIconName } from '@bc/ui'

type Status = GQLBaseOrderStatus | GQLRequestType | GQLRequestStatus

export const statusAsTranslationKey = (status: Status): keyof MessageMap => {
    const base = 'order:status.'
    return (base + status.toLowerCase()) as keyof MessageMap
}

export const requestTypeAsTranslationKey = (type: Status): keyof MessageMap =>
    `request-type:${type}` as keyof MessageMap

export const statusAsIconName = (status: Status): SvgIconName => `status_${status.toLowerCase()}` as SvgIconName

// eslint-disable-next-line no-shadow
export enum statusColors {
    delivered = 'green50',
    indelivery = 'blue50',
    shipped = 'deepPurple',
    cancelled = 'red50',
    confirmed = 'neutral90',
    pending = 'yellow50',
    processed = 'yellow30',
    invoiced = 'green70',
    completed = 'green50',
    loading = 'blue30',
}

export const statusAsColor = (status: Status): keyof typeof colors => {
    const typedColorStatus = status.toLowerCase() as keyof typeof statusColors
    return statusColors[typedColorStatus]
}
