import React from 'react'
import styled from 'styled-components'

import { colors, defaultFontSpacing } from '@bc/ui/src/config'
import { selectElementStyles } from './select-components'

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: auto;
    align-items: center;
`

export const Select = styled.select`
    ${defaultFontSpacing}
    ${selectElementStyles}
`

export const IconWrapper = styled.div`
    position: relative;
    right: 30px;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    pointer-events: none;
    justify-content: center;
`

export const Triangle = styled.span`
    width: 10px;
    height: 6px;
    border-top: solid 6px ${colors.neutral90};
    border-left: solid 6px transparent;
    border-right: solid 6px transparent;
`

export const Icon = () => (
    <IconWrapper>
        <Triangle />
    </IconWrapper>
)
