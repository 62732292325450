import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Arrow_Up: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Arrow_Up" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M12 4.41L19.29 11.71L20.71 10.29L12 1.59L3.28998 10.29L4.70998 11.71L12 4.41ZM11 8.24L12 7.24L13 8.24V22H11V8.24Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Up_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Up" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Arrow_Up" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M12 4.41L19.29 11.71L20.71 10.29L12 1.59L3.28998 10.29L4.70998 11.71L12 4.41ZM11 8.24L12 7.24L13 8.24V22H11V8.24Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Up_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Up" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Arrow_Up" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M12 4.41L19.29 11.71L20.71 10.29L12 1.59L3.28998 10.29L4.70998 11.71L12 4.41ZM11 8.24L12 7.24L13 8.24V22H11V8.24Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Up_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Up" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Arrow_Up" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M12 4.41L19.29 11.71L20.71 10.29L12 1.59L3.28998 10.29L4.70998 11.71L12 4.41ZM11 8.24L12 7.24L13 8.24V22H11V8.24Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Up_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Up" />}
        </g>
    ),
}
