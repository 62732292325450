import { GQLRoleType, GQLSourceRegion } from '@bc/types'
export enum Resources {
    SearchCustomers = 'R_SearchCustomers',
    Requests = 'R_Requests',
    Users = 'R_Users',
    EditUsers = 'R_EditUsers',
    Quotes = 'R_Quotes',
    Invoices = 'R_Invoices',
    ProductList = 'R_ProductList',
    MyCompanies = 'R_MyCompanies',
    Checkout = 'R_Checkout',
    Orders = 'R_Orders',
}

export interface Rule {
    role: GQLRoleType
    resources: Resources[]
}

export interface SettingsRole {
    rules: Rule[]
}

export type RoleConfig = { [key in GQLSourceRegion]: SettingsRole }
