import uuid from 'uuid/v1'

import { ToastData } from '@bc/ui'

export type ToastConstructor = Omit<ToastData, 'id'>
export type ToastsState = ToastData[]

type ToastsNotifyFunc = (state: ToastsState) => void

export const defaultToastsState: ToastsState = []
export interface ToastStoreConstructorArgs {
    notify: ToastsNotifyFunc
    initialState?: ToastsState
}

const TOAST_ONSCREEN_TIME = 7000
export class ToastsStore {
    private state: ToastsState = defaultToastsState
    private notify: ToastsNotifyFunc

    public init({ notify, initialState = defaultToastsState }: ToastStoreConstructorArgs): void {
        this.state = initialState
        this.notify = notify
    }

    public addToast(toast: ToastConstructor): void {
        this.state = [
            {
                id: uuid(),
                closeIn: TOAST_ONSCREEN_TIME,
                ...toast,
            },
            ...this.state,
        ]
        if (typeof this.notify === 'function') {
            this.notify(this.state)
        }
    }

    public removeToast(id: string): void {
        this.state = this.state.filter(t => t.id !== id)
        this.notify(this.state)
    }

    public getToasts = (): ToastsState => this.state
}
