// import React from 'react'
import styled from 'styled-components'

import { colors, spacing, ColorScheme } from '@bc/ui/src/config'

export interface HrProps {
    small?: boolean
    width?: number | string
    colorScheme?: ColorScheme
}

export const Hr = styled.hr<HrProps>`
    border: 0;
    height: ${({ small }) => (small ? '1' : '4')}px;
    background-color: ${({ colorScheme }) => (colorScheme ? colors[colorScheme + '90'] : colors.deepPurple)};
    width: ${({ width = '100%' }) => width};

    margin: ${spacing.xs}px 0;
`
