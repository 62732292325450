import React, { Fragment } from 'react'

import { Field } from 'react-final-form'
import styled from 'styled-components'

import { GQLPrice } from '@bc/types'
import { colors, RadioButton, Text, TextInput, TextWrapper, Tooltip } from '@bc/ui'

import { FormatMessage, useFormatMessage } from '@frontend/components/hooks'
import { GenericWarning } from '@frontend/components/info-tip'
import { PriceScaleCondition } from '@frontend/components/price-scale'
import { finalFormValidations } from '@frontend/utils'

import { AttachFileField, ListAttachedFiles } from '../attach-file'
import { isBelowMinimumPrice, customerReferenceRequired, PriceLabelWrapper, Wrapper } from '../_shared'

interface ReorderFormFieldsProps {
    file?: File
    change: any
    prices: GQLPrice[][]
    quantity: number
    insufficientQuantity: boolean
}

const DisabledPricePadder = styled.div`
    margin-left: 26px;
`
const PriceSeparator = styled.hr`
    margin: 10px 0px;
    height: 1px;
    border: none;
    background-color: ${colors.neutral20};
`

export const ReorderFormFieldsStep2 = ({
    file,
    change,
    prices,
    quantity,
    insufficientQuantity,
}: ReorderFormFieldsProps) => {
    const t: FormatMessage = useFormatMessage()
    const isQuantityBelowMinimum = isBelowMinimumPrice(quantity)

    return (
        <>
            <Wrapper>
                <Field name="customerPoReference" validate={customerReferenceRequired(t, 35)}>
                    {({ input, meta }) => (
                        <>
                            <TextInput
                                {...input}
                                maxLength={35}
                                labelText={
                                    <TextWrapper noMargin>
                                        {t('general:po-reference') + ' *'}
                                        <Tooltip placement="top">{t('general:po-reference-info')}</Tooltip>
                                    </TextWrapper>
                                }
                                type="text"
                                hasError={meta.touched && meta.error}
                                errorText={meta.error}
                                data-test-id="input-po-reference"
                                append={<AttachFileField change={change} />}
                            />
                            <ListAttachedFiles file={file} change={change} />
                        </>
                    )}
                </Field>
            </Wrapper>

            <Wrapper>
                <label>{t('request-information-modal:available-prices') + ' *'}</label>
                {insufficientQuantity && (
                    <GenericWarning
                        icon="Circle_Info"
                        bgColor={colors.yellow10}
                        iconColor={colors.yellow50}
                        iconSize="20">
                        <Text noMargin>{`${t('general:quantity-below-minimum')} ${t(
                            'general:price-confirmation',
                        )}.`}</Text>
                    </GenericWarning>
                )}

                {prices?.length > 0 ? (
                    <>
                        {prices.map((price, index) => {
                            const quantityBelowPriceMinimum = isQuantityBelowMinimum(price)
                            const priceConditionComponent = (
                                <PriceScaleCondition prices={price} footNote disabled={quantityBelowPriceMinimum} />
                            )
                            return quantityBelowPriceMinimum ? (
                                <Fragment key={index}>
                                    <PriceSeparator />
                                    {!insufficientQuantity && (
                                        <GenericWarning
                                            icon="Circle_Info"
                                            bgColor={colors.yellow10}
                                            iconColor={colors.yellow50}
                                            iconSize="20">
                                            <Text noMargin>{`${t('general:quantity-below-minimum')}`}</Text>
                                        </GenericWarning>
                                    )}
                                    <DisabledPricePadder>{priceConditionComponent}</DisabledPricePadder>
                                </Fragment>
                            ) : (
                                <Fragment key={index}>
                                    <PriceSeparator />
                                    <Field
                                        key={index}
                                        name="price"
                                        type="radio"
                                        value={index.toString()}
                                        validate={
                                            quantityBelowPriceMinimum ? undefined : finalFormValidations.required(t)
                                        }
                                        render={({ input }) => (
                                            <>
                                                <RadioButton
                                                    {...input}
                                                    id={`price${index}`}
                                                    disabled={quantityBelowPriceMinimum}
                                                    labelText={
                                                        <PriceLabelWrapper>{priceConditionComponent}</PriceLabelWrapper>
                                                    }
                                                    colorScheme="deepPurple"
                                                />
                                            </>
                                        )}
                                    />
                                </Fragment>
                            )
                        })}
                        {'* '}
                        {t('delivery-overview:legal-terms-price')}
                    </>
                ) : (
                    t('general:price-confirmation')
                )}
            </Wrapper>

            <Wrapper>
                <Field
                    name="comment"
                    render={({ input, meta }) => (
                        <TextInput
                            {...input}
                            labelText={t('request-information-modal:comment')}
                            textarea
                            placeholder={t('general:type-here')}
                            type="text"
                            hasError={meta.touched && meta.error}
                            errorText={meta.error}
                            data-test-id="input-comment"
                        />
                    )}
                />
            </Wrapper>
        </>
    )
}
