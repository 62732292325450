import { SalesOrganisationConfigDev } from '../types'

export const salesOrganisationConfigDev: SalesOrganisationConfigDev = {
    DE01: {
        email: {
            csr: 'csr.al@digib.com',
            user: 'customer.test@digib.com',
        },
        bcc: {
            csrs: [],
            users: [],
        },
    },
    BE01: {
        email: {
            csr: 'csr.be@digib.com',
            user: 'customer.test@digib.com',
        },
        bcc: {
            csrs: [],
            users: [],
        },
    },
    NL01: {
        email: {
            csr: 'csr.nl@digib.com',
            user: 'customer.test@digib.com',
        },
        bcc: {
            csrs: [],
            users: [],
        },
    },
    A100: {
        email: {
            csr: 'csr.pl@digib.com',
            user: 'customer.test@digib.com',
        },
        bcc: {
            csrs: [],
            users: ['customertest@brenntag.pl'],
        },
    },
}
