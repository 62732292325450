import React from 'react'
import { hydrate } from 'react-dom'

import { tagManager } from '@bc/gtm'
import { initalizeFrontendLogging, reportFrontendDebugLevel } from '@bc/logging'
import { defaultLocale, translations } from '@bc/translations'
import { createClientLinks } from '@frontend/bootstrap/shared'
import { env } from '@frontend/config'
import { installAuth0, installSentry } from '@frontend/utils'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'

if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill')
    require('@formatjs/intl-pluralrules/dist/locale-data/de') // Add locale data for de
    require('@formatjs/intl-pluralrules/dist/locale-data/en') // Add locale data for en
    require('@formatjs/intl-pluralrules/dist/locale-data/fr') // Add locale data for fr
    require('@formatjs/intl-pluralrules/dist/locale-data/nl') // Add locale data for nl
}

// this is stage 4 draft proposal
// if (!Intl.RelativeTimeFormat) {
//     require('@formatjs/intl-relativetimeformat/polyfill')
//     require('@formatjs/intl-relativetimeformat/dist/locale-data/de') // Add locale data for de
//     require('@formatjs/intl-relativetimeformat/dist/locale-data/en') // Add locale data for en
//     require('@formatjs/intl-relativetimeformat/dist/locale-data/fr') // Add locale data for fr
//     require('@formatjs/intl-relativetimeformat/dist/locale-data/nl') // Add locale data for nl
// }

const prod = process.env.NODE_ENV === 'production'

if (prod && env.SENTRY_DSN) {
    installSentry()
}

if (env.DEBUG !== undefined) {
    initalizeFrontendLogging(env.DEBUG)
}
reportFrontendDebugLevel() // Will be executed only if logging is active

installAuth0()

if (env.FE_GOOGLE_TAG_MANAGER_TOKEN) {
    tagManager.initialize(env.FE_GOOGLE_TAG_MANAGER_TOKEN)
}

const link = createClientLinks({
    onAuthError: () => {
        const t = translations[defaultLocale]
        if (window.location.pathname !== t['route:session-expired']) {
            window.location.href = t['route:session-expired']
        }
    },
    onMaintenanceError: () => {
        const t = translations[defaultLocale]
        if (window.location.pathname !== t['route:maintenance']) {
            window.location.href = t['route:maintenance']
        }
    },
    onRateLimitError: () => {
        console.error('Rate Limit Error occured.')
    },
})

export const client = new ApolloClient({
    cache: new InMemoryCache({
        // needed for apollo's aggressive caching. material->locations->prices are the same because locations are the same eg.
        // to see this in action. remove `dataIdFromObject` property and render my products page
        dataIdFromObject: () => undefined,
    }).restore(window.__APOLLO_STATE__),
    connectToDevTools: !prod,
    link,
})

const App = require('./clientApp').default
hydrate(<App client={client} />, document.getElementById('app'))

if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
        module.hot.accept()
    }
}
