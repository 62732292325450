import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Parcel: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Parcel" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M16.1802 5.4502L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L19.3402 4.3902L16.1802 5.4502ZM2 5.60986L12 8.94986V8.93986L22 5.60986V18.7199L12 22.0499L2 18.7199V5.60986ZM11 10.7199L4 8.38986V17.2799L11 19.6099V10.7199ZM13 19.6099L20 17.2799V8.38986L13 10.7199V19.6099Z"
                fill={fill === 'gradient' ? 'url(#Parcel_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Parcel" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M16.1802 5.4502L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L19.3402 4.3902L16.1802 5.4502ZM2 5.60986L12 8.94986V8.93986L22 5.60986V18.7199L12 22.0499L2 18.7199V5.60986ZM11 10.7199L4 8.38986V17.2799L11 19.6099V10.7199ZM13 19.6099L20 17.2799V8.38986L13 10.7199V19.6099Z"
                fill={fill === 'gradient' ? 'url(#Parcel_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Parcel" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M16.1802 5.4502L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L19.3402 4.3902L16.1802 5.4502ZM2 5.60986L12 8.94986V8.93986L22 5.60986V18.7199L12 22.0499L2 18.7199V5.60986ZM11 10.7199L4 8.38986V17.2799L11 19.6099V10.7199ZM13 19.6099L20 17.2799V8.38986L13 10.7199V19.6099Z"
                fill={fill === 'gradient' ? 'url(#Parcel_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Parcel" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M16.1802 5.4502L12.0002 4.0502L7.82016 5.4502L4.66016 4.3902L12.0002 1.9502L19.3402 4.3902L16.1802 5.4502ZM2 5.60986L12 8.94986V8.93986L22 5.60986V18.7199L12 22.0499L2 18.7199V5.60986ZM11 10.7199L4 8.38986V17.2799L11 19.6099V10.7199ZM13 19.6099L20 17.2799V8.38986L13 10.7199V19.6099Z"
                fill={fill === 'gradient' ? 'url(#Parcel_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel" />}
        </g>
    ),
}
