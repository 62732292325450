import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Undo: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Undo" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M1.59 9L8.29 15.71L9.71 14.29L4.41 9L9.71 3.71L8.29 2.29L1.59 9ZM7.24 9L8.24 10H15.5C17.98 10 20 12.02 20 14.5C20 16.98 17.98 19 15.5 19H7V21H15.5C19.08 21 22 18.08 22 14.5C22 10.92 19.08 8 15.5 8H8.24L7.24 9Z"
                fill={fill === 'gradient' ? 'url(#Undo_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Undo" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Undo" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M1.59 9L8.29 15.71L9.71 14.29L4.41 9L9.71 3.71L8.29 2.29L1.59 9ZM7.24 9L8.24 10H15.5C17.98 10 20 12.02 20 14.5C20 16.98 17.98 19 15.5 19H7V21H15.5C19.08 21 22 18.08 22 14.5C22 10.92 19.08 8 15.5 8H8.24L7.24 9Z"
                fill={fill === 'gradient' ? 'url(#Undo_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Undo" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Undo" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M1.59 9L8.29 15.71L9.71 14.29L4.41 9L9.71 3.71L8.29 2.29L1.59 9ZM7.24 9L8.24 10H15.5C17.98 10 20 12.02 20 14.5C20 16.98 17.98 19 15.5 19H7V21H15.5C19.08 21 22 18.08 22 14.5C22 10.92 19.08 8 15.5 8H8.24L7.24 9Z"
                fill={fill === 'gradient' ? 'url(#Undo_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Undo" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Undo" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M1.59 9L8.29 15.71L9.71 14.29L4.41 9L9.71 3.71L8.29 2.29L1.59 9ZM7.24 9L8.24 10H15.5C17.98 10 20 12.02 20 14.5C20 16.98 17.98 19 15.5 19H7V21H15.5C19.08 21 22 18.08 22 14.5C22 10.92 19.08 8 15.5 8H8.24L7.24 9Z"
                fill={fill === 'gradient' ? 'url(#Undo_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Undo" />}
        </g>
    ),
}
