import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Contacts: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Contacts" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M4 22H21V2H4V4H19V20H4V22ZM3 15H6V17H3V15ZM6 7H3V9H6V7ZM3 11H6V13H3V11ZM12 12C10.35 12 9 10.65 9 9C9 7.35 10.35 6 12 6C13.65 6 15 7.35 15 9C15 10.65 13.65 12 12 12ZM12 8C11.45 8 11 8.45 11 9C11 9.55 11.45 10 12 10C12.55 10 13 9.55 13 9C13 8.45 12.55 8 12 8ZM14 17H16V13H8V17H10V15H14V17Z"
                fill={fill === 'gradient' ? 'url(#Contacts_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Contacts" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Contacts" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M4 22H21V2H4V4H19V20H4V22ZM3 15H6V17H3V15ZM6 7H3V9H6V7ZM3 11H6V13H3V11ZM12 12C10.35 12 9 10.65 9 9C9 7.35 10.35 6 12 6C13.65 6 15 7.35 15 9C15 10.65 13.65 12 12 12ZM12 8C11.45 8 11 8.45 11 9C11 9.55 11.45 10 12 10C12.55 10 13 9.55 13 9C13 8.45 12.55 8 12 8ZM14 17H16V13H8V17H10V15H14V17Z"
                fill={fill === 'gradient' ? 'url(#Contacts_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Contacts" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Contacts" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M4 22H21V2H4V4H19V20H4V22ZM3 15H6V17H3V15ZM6 7H3V9H6V7ZM3 11H6V13H3V11ZM12 12C10.35 12 9 10.65 9 9C9 7.35 10.35 6 12 6C13.65 6 15 7.35 15 9C15 10.65 13.65 12 12 12ZM12 8C11.45 8 11 8.45 11 9C11 9.55 11.45 10 12 10C12.55 10 13 9.55 13 9C13 8.45 12.55 8 12 8ZM14 17H16V13H8V17H10V15H14V17Z"
                fill={fill === 'gradient' ? 'url(#Contacts_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Contacts" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Contacts" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M4 22H21V2H4V4H19V20H4V22ZM3 15H6V17H3V15ZM6 7H3V9H6V7ZM3 11H6V13H3V11ZM12 12C10.35 12 9 10.65 9 9C9 7.35 10.35 6 12 6C13.65 6 15 7.35 15 9C15 10.65 13.65 12 12 12ZM12 8C11.45 8 11 8.45 11 9C11 9.55 11.45 10 12 10C12.55 10 13 9.55 13 9C13 8.45 12.55 8 12 8ZM14 17H16V13H8V17H10V15H14V17Z"
                fill={fill === 'gradient' ? 'url(#Contacts_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Contacts" />}
        </g>
    ),
}
