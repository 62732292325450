import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Volume_Mid: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Volume_Mid" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M8.59 16L14 21.41V13H12V16.59L9.41 14H6V9.99997H9.41L12 7.40997V11H14V2.58997L8.59 7.99997H4V16H8.59ZM16 8.55998V6.34998C18.33 7.17998 20 9.38998 20 12C20 14.61 18.33 16.82 16 17.65V15.44C17.19 14.75 18 13.47 18 12C18 10.53 17.19 9.24998 16 8.55998Z"
                fill={fill === 'gradient' ? 'url(#Volume_Mid_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_Mid" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Volume_Mid" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M8.59 16L14 21.41V13H12V16.59L9.41 14H6V9.99997H9.41L12 7.40997V11H14V2.58997L8.59 7.99997H4V16H8.59ZM16 8.55998V6.34998C18.33 7.17998 20 9.38998 20 12C20 14.61 18.33 16.82 16 17.65V15.44C17.19 14.75 18 13.47 18 12C18 10.53 17.19 9.24998 16 8.55998Z"
                fill={fill === 'gradient' ? 'url(#Volume_Mid_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_Mid" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Volume_Mid" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M8.59 16L14 21.41V13H12V16.59L9.41 14H6V9.99997H9.41L12 7.40997V11H14V2.58997L8.59 7.99997H4V16H8.59ZM16 8.55998V6.34998C18.33 7.17998 20 9.38998 20 12C20 14.61 18.33 16.82 16 17.65V15.44C17.19 14.75 18 13.47 18 12C18 10.53 17.19 9.24998 16 8.55998Z"
                fill={fill === 'gradient' ? 'url(#Volume_Mid_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_Mid" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Volume_Mid" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M8.59 16L14 21.41V13H12V16.59L9.41 14H6V9.99997H9.41L12 7.40997V11H14V2.58997L8.59 7.99997H4V16H8.59ZM16 8.55998V6.34998C18.33 7.17998 20 9.38998 20 12C20 14.61 18.33 16.82 16 17.65V15.44C17.19 14.75 18 13.47 18 12C18 10.53 17.19 9.24998 16 8.55998Z"
                fill={fill === 'gradient' ? 'url(#Volume_Mid_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_Mid" />}
        </g>
    ),
}
