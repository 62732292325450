import React, { useContext, useMemo } from 'react'
import { Field } from 'react-final-form'
import { OptionsType } from 'react-select'

import { ProductColumnTypes } from '@bc/config'
import { GQLGetMaterialsFilters } from '@bc/types'
import { SelectInput, SelectInputOption, TextInput, TextWrapper, Tooltip, selectFindOption } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { FiltersContext, FiltersContextData, PlatformConfigContext, UserContext } from '@frontend/context'

import { finalFormValidations, NUM_CHAR_START_SEARCH } from '@frontend/utils'
import { FiltersFormContainer } from './filters-form-container'

export const ProductFilters = () => {
    const t = useFormatMessage()
    const { me } = useContext(UserContext)
    const { shopConfig } = useContext(PlatformConfigContext)
    const { onFilterChange, filters, query, onLinkCopy }: FiltersContextData<GQLGetMaterialsFilters> = useContext(
        FiltersContext,
    )

    const userSourceRegion = me?.meta.sourceRegion
    const packagingOptions: OptionsType<SelectInputOption> = useMemo(() => {
        const packagingTypes = t(`filter-packaging.${userSourceRegion}:filters.names`).split(/,\s*/)
        const packagingTypesValues = t(`filter-packaging.${userSourceRegion}:filters.values`).split(/,\s*/)
        return packagingTypes
            .map((type: string, i: number) => ({
                value: packagingTypesValues[i],
                label: type,
            }))
            .sort((a, b) => (a.label < b.label ? -1 : 1))
    }, [userSourceRegion])

    const hasEinecsColumn = shopConfig.current?.productListColumns.includes('einecsNumber' as ProductColumnTypes)

    return (
        <FiltersFormContainer
            onFilterChange={onFilterChange}
            initialValues={filters}
            query={query}
            onLinkCopy={onLinkCopy}
            linkAlways>
            <Field
                name="materialId"
                validate={finalFormValidations.minLength(t, NUM_CHAR_START_SEARCH)}
                render={({ input, meta }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-customer-product-number')}
                        type="search"
                        placeholder={t('filters:search-by-customer-product-number.placeholder')}
                        hasError={meta.touched && meta.error}
                        errorText={meta.error}
                        data-test-id="input-filter-material-id"
                    />
                )}
            />
            <Field
                name="name"
                validate={finalFormValidations.minLength(t, NUM_CHAR_START_SEARCH)}
                render={({ input, meta }) => (
                    <TextInput
                        {...input}
                        labelText={t('filters:search-by-material-name')}
                        type="search"
                        placeholder={t('filters:search-by-material-name.placeholder')}
                        hasError={meta.touched && meta.error}
                        errorText={meta.error}
                        data-test-id="input-filter-name"
                    />
                )}
            />
            <Field
                name="casNumber"
                render={({ input }) => (
                    <TextInput
                        {...input}
                        labelText={
                            <TextWrapper noMargin>
                                {t('filters:search-by-cas-no')}
                                <Tooltip placement="top">{t('filters:search-by-cas-no.tooltip')}</Tooltip>
                            </TextWrapper>
                        }
                        type="search"
                        placeholder={t('filters:search-by-cas-no.placeholder')}
                        data-test-id="input-filter-cas"
                    />
                )}
            />
            {hasEinecsColumn && (
                <Field
                    name="einecsNumber"
                    parse={value => value?.match(/\d{3}(?=\d{1,3})|\d+/g)?.join('-')}
                    render={({ input }) => (
                        <TextInput
                            {...input}
                            labelText={t('filters:search-by-einecs-no')}
                            type="search"
                            placeholder={t('filters:search-by-einecs-no.placeholder')}
                            data-test-id="input-filter-einecs"
                        />
                    )}
                />
            )}
            <Field
                name="packagingName"
                parse={o => o?.value}
                render={({ input }) => {
                    const { value, ...restInput } = input
                    const selectedOption = selectFindOption(packagingOptions, value)
                    return (
                        <SelectInput
                            {...restInput}
                            value={selectedOption}
                            labelText={t('filters:packaging-title')}
                            placeholder={t('filters:select-a-packaging-title')}
                            isSearchable
                            isClearable
                            options={packagingOptions}
                            noOptionsMessage={() => t('not-found:select')}
                            classNamePrefix="select-filter-packaging"
                            data-test-id="select-filter-packaging"
                        />
                    )
                }}
            />
        </FiltersFormContainer>
    )
}
