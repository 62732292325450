import React, { useEffect, useState } from 'react'

import { Toast, ToastData } from './toast'
import { ToastHolder } from './toast-holder'

export type ToasterPlacement =
    | 'top-right'
    | 'top-left'
    | 'top-center'
    | 'bottom-left'
    | 'bottom-right'
    | 'bottom-center'

export interface ToasterProps {
    /** placement of toast on page. For example: 'top-left', 'bottom-center'  */
    placement: ToasterPlacement
    /** array of toast objects */
    toasts: ToastData[]
    /** reverse the progressbar animation */
    reverse?: boolean
    /** on close event handler */
    onClose?: (id: string) => void
}

/**
 * Toaster: **React.Component**
 */
export const Toaster = ({ placement, toasts = [], reverse, onClose }: ToasterProps) => {
    const [timer, setTimer] = useState(new Map())
    const [isToasting, setIsToasting] = useState(false)

    /**
     * When starting to toast, we use the set state to kick it off.
     * To minimise the amount of times we call setState, we also pass in
     * the toasts that we're provided (defaults to the state)
     *
     * While setting the state we also clear any existing timers and
     * set a new timer for the toast to show.
     */
    const startToasting = () => {
        setIsToasting(true)

        toasts.forEach(({ id, closeIn }) => {
            if (!timer.has(id) && closeIn && closeIn > 0) {
                timer.set(id, setTimeout(stopToasting, closeIn, id))
            }
        })

        setTimer(new Map(timer))
    }

    /**
     * When stopping the toast we grab the current toast and
     * mark the rest as buffer.
     *
     * We update the state.
     *
     * After that we clear the timeout that belongs to that toast
     * then we call the onClose from the props
     * so the parent knows this toast is done.
     *
     * If we have another toast after this, we play that one
     */
    const stopToasting = (id: string) => {
        const t = timer.get(id)
        clearTimeout(t)
        timer.delete(id)

        if (typeof onClose === 'function') {
            onClose(id)
        }
        if (timer.size === 0) {
            setIsToasting(false)
        }
        setTimer(new Map(timer))
    }

    useEffect(() => {
        // If we have pending toasts, start toasting
        if (toasts.length) {
            startToasting()
        }
    }, [toasts])

    // Clean timeout when unmount
    useEffect(() => () => timer.forEach(clearTimeout), [])

    return (
        <ToastHolder placement={placement} isToasting={isToasting}>
            {isToasting &&
                toasts.map(toast => (
                    <Toast toast={toast} reverse={reverse} onClose={() => stopToasting(toast.id)} key={toast.id} />
                ))}
        </ToastHolder>
    )
}
