import React, { useContext, useState } from 'react'

import { useMutation } from '@apollo/react-hooks'

import { Field, Form } from 'react-final-form'
import Modal from 'react-modal'

import { getCsrEmail, getFaqUrl } from '@bc/graphql-utils/src/user'
import { MessageMap } from '@bc/translations'
import { Button, Checkbox, nl2br, Text } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { PlatformConfigContext, SessionContext, SessionContextData, UserContext } from '@frontend/context'
import { gqlMeQuery, patchUserDataMutation } from '@frontend/graphql/me'
import { isBrowser } from '@frontend/utils'

import { GQLRequestType, GQLSalesOrganisationId } from '@bc/types'
import * as OnboardingComponents from './onboarding-components'

interface OnboardingSlides {
    header?: keyof MessageMap
    title: keyof MessageMap
    content: keyof MessageMap
    image?: string
    inquiry?: boolean
}

type InquiryQuestion = 'trackTrace' | 'coaEmail' | 'manageMultipleAccounts'
const inquiryQuestions: InquiryQuestion[] = ['trackTrace', 'manageMultipleAccounts', 'coaEmail']

type OnboardingFormValues = { [key in InquiryQuestion]?: boolean[] }

if (isBrowser && process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#app')
}

export const Onboarding = () => {
    const t = useFormatMessage()
    const { me } = useContext(UserContext)
    const [, sessionState]: SessionContextData = useContext(SessionContext)
    const {
        appConfig: { filterRequestTypes },
    } = useContext(PlatformConfigContext)

    const { to: csrEmail } = getCsrEmail(me!.meta, sessionState.activeSalesOrganisation as GQLSalesOrganisationId)
    const faqLink = getFaqUrl(me?.meta)
    const hasSample = Boolean(filterRequestTypes.find((type: GQLRequestType) => type === GQLRequestType.SAMPLE))

    const [currentSlide, setCurrentSlide] = useState(0)
    const [header, setHeader] = useState('')
    const [isOpen, setIsOpen] = useState(Boolean(me && !me.meta.completedOnboarding))

    const [patchUserData, { loading }] = useMutation(patchUserDataMutation)

    const slides: OnboardingSlides[] = [
        {
            title: 'onboarding:slide-introduction.title',
            content: 'onboarding:slide-introduction.content',
            image: '/static/onboarding/welcome.jpg',
        },
        {
            header: 'onboarding:slide-order-status.header',
            title: 'onboarding:slide-order-status.title',
            content: 'onboarding:slide-order-status.content',
            image: `/static/onboarding/order-status.png`,
        },
        {
            header: 'onboarding:slide-sample.header',
            title: 'onboarding:slide-sample.title',
            content: 'onboarding:slide-sample.content',
            image: `/static/onboarding/rfi${hasSample ? '-sample' : ''}.png`,
        },
        {
            title: 'onboarding:slide-finish.title',
            content: 'onboarding:slide-finish.content',
            image: '/static/onboarding/help.png',
        },
    ]

    const firstSlide = currentSlide === 0
    const lastSlide = currentSlide === slides.length - 1

    const setActiveSlide = (activeSlide: number) => {
        if (activeSlide < 0 || activeSlide > slides.length - 1) {
            return
        }

        setCurrentSlide(activeSlide)
        const currentHeader = slides[activeSlide].header
        setHeader(currentHeader ? t(currentHeader) : '')
    }

    const completeOnboarding = async (onboardingFormValues?: OnboardingFormValues) => {
        const onboardingForm = {
            trackTrace: Boolean(onboardingFormValues?.trackTrace?.[0]),
            coaEmail: Boolean(onboardingFormValues?.coaEmail?.[0]),
            manageMultipleAccounts: Boolean(onboardingFormValues?.manageMultipleAccounts?.[0]),
        }

        if (!loading) {
            setIsOpen(false)

            await patchUserData({
                variables: { completedOnboarding: true, onboardingForm },
                refetchQueries: [{ query: gqlMeQuery }],
                awaitRefetchQueries: true,
            })
        }
    }

    const getSliderStyles = () => {
        const totalSlidesWidth = slides.length * 20
        const translateValue = (currentSlide / slides.length) * totalSlidesWidth
        const transform = `translateX(-${translateValue}px)`

        return {
            transform,
        }
    }

    return (
        <Form onSubmit={completeOnboarding}>
            {({ handleSubmit }) => (
                <Modal
                    testId={'onboarding'}
                    isOpen={isOpen}
                    onRequestClose={() => handleSubmit()}
                    className="react-modal__content"
                    overlayClassName="react-modal__overlay">
                    <OnboardingComponents.Header>
                        <OnboardingComponents.SliderDots>
                            {slides.map((slide, index) => (
                                <OnboardingComponents.SliderDot
                                    key={slide.title}
                                    isActive={currentSlide === index}
                                    onClick={() => setActiveSlide(index)}
                                />
                            ))}
                        </OnboardingComponents.SliderDots>
                        <OnboardingComponents.HeaderTitle h2>{header}</OnboardingComponents.HeaderTitle>
                        <Button variant="solid" onClick={() => handleSubmit()} data-test-id="button-skip-onboarding">
                            {currentSlide === slides.length - 1
                                ? t('onboarding:skip-tour.close')
                                : t('onboarding:skip-tour.skip')}
                        </Button>
                    </OnboardingComponents.Header>

                    <OnboardingComponents.Content>
                        <OnboardingComponents.Slider style={getSliderStyles()}>
                            {slides.map(({ title, image, inquiry, content }, index) => (
                                <OnboardingComponents.Slide
                                    key={title}
                                    isActive={index === currentSlide}
                                    index={index}
                                    aria-hidden={index !== currentSlide}>
                                    <OnboardingComponents.SlideContent>
                                        <Text h3>
                                            {nl2br(
                                                t(title, undefined, {
                                                    name: me ? me.meta.firstName || '' : '',
                                                }),
                                            )}
                                        </Text>
                                        <Text p>{nl2br(t(content, undefined, { csrEmail, faqLink }))}</Text>
                                    </OnboardingComponents.SlideContent>
                                    {image && (
                                        <OnboardingComponents.SlideImageWrapper>
                                            <OnboardingComponents.SlideImage src={image} />
                                        </OnboardingComponents.SlideImageWrapper>
                                    )}
                                    {inquiry && (
                                        <OnboardingComponents.Inquiry>
                                            {inquiryQuestions.map(question => (
                                                <Field
                                                    key={question}
                                                    name={question}
                                                    type="checkbox"
                                                    value={1}
                                                    render={({ input }) => (
                                                        <OnboardingComponents.InquiryCard>
                                                            <Checkbox
                                                                {...input}
                                                                id={question}
                                                                labelText={t(
                                                                    `onboarding:inquiry.${question}` as keyof MessageMap,
                                                                )}
                                                                colorScheme="deepPurple"
                                                            />
                                                        </OnboardingComponents.InquiryCard>
                                                    )}
                                                />
                                            ))}
                                        </OnboardingComponents.Inquiry>
                                    )}
                                </OnboardingComponents.Slide>
                            ))}
                        </OnboardingComponents.Slider>
                    </OnboardingComponents.Content>

                    <OnboardingComponents.Footer>
                        <OnboardingComponents.SliderButton
                            variant="solid"
                            isVisible={!firstSlide}
                            onClick={() => setActiveSlide(currentSlide - 1)}>
                            {t('onboarding:slider-button.previous')}
                        </OnboardingComponents.SliderButton>
                        <OnboardingComponents.SliderButton
                            variant={firstSlide || lastSlide ? 'action' : 'solid'}
                            onClick={() => {
                                if (lastSlide) {
                                    handleSubmit()
                                } else {
                                    setActiveSlide(currentSlide + 1)
                                }
                            }}>
                            {firstSlide
                                ? t('onboarding:slider-button.start')
                                : lastSlide
                                ? t('onboarding:slider-button.finish')
                                : t('onboarding:slider-button.next')}
                        </OnboardingComponents.SliderButton>
                    </OnboardingComponents.Footer>
                    <OnboardingComponents.ModalGlobalStyles />
                </Modal>
            )}
        </Form>
    )
}
