import React, { useContext, useEffect, useState } from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'

import graphqlUtils from '@bc/graphql-utils'
import { PrivateRoute } from '@frontend/components'
import { useHasAccess } from '@frontend/components/hooks'
import { SessionContext, SessionContextData, UserContext } from '@frontend/context'
import {
    AccountContainer,
    AuthErrorPage,
    GenerateSetPasswordLinkPage,
    LoginOrRegisterPage,
    MyCompaniesPage,
    NotFoundPage,
    OrderHistory,
} from '@frontend/pages'

import qs from 'query-string'

import { useApolloClient } from '@apollo/react-hooks'
import { RouteItem, useAppRoutes } from './get-route-settings'
import { getRedirectWithState } from './redirect'

type AppRoutesComponent = RouteComponentProps<any>
interface RequestQueryString {
    cid?: string
    so?: string
    sid?: string
}
const AppRoutesComponent = ({ location }: AppRoutesComponent) => {
    const hasAccess = useHasAccess()
    const client = useApolloClient()
    const { userLocale, meLoading, me, isCsr, isAdmin, currentCustomer, userHasMultipleCustomerEntities } = useContext(
        UserContext,
    )
    const [sessionStore]: SessionContextData = useContext(SessionContext)
    const routes = useAppRoutes()[userLocale]

    const hasRouteAccess = ({ feature }: RouteItem) => !feature || feature.every(hasAccess)
    const getHomeRoute = () => {
        const component = me?.meta.completedOnboarding
            ? (isCsr || isAdmin) && !currentCustomer
                ? MyCompaniesPage
                : OrderHistory
            : AccountContainer

        return <PrivateRoute path="/" exact component={component} />
    }

    const [filteredRoutes, setFilterRoutes] = useState<RouteItem[]>([])
    const query = qs.parse(location.search)
    const { cid, so, sid } = query as RequestQueryString
    const loadingFromExternalUrl = cid && so && sid

    const switchToCustomer = () => {
        const { limitUserToSalesAreas } = me!.meta

        if (loadingFromExternalUrl) {
            // check if it ia CSR
            // or if these IDs actually belong to the user
            if (isCsr || graphqlUtils.user.hasThisCustomer(me!.meta, { cid, so, sid })) {
                sessionStore.setActiveCustomer({ cid: cid!, so: so!, sid: sid! })
            }
        } else {
            // auto-switch the user to its only one customer
            if (!isCsr && !userHasMultipleCustomerEntities) {
                sessionStore.setActiveCustomer({
                    cid: limitUserToSalesAreas[0].customerId,
                    so: limitUserToSalesAreas[0].salesAreas[0].salesOrganisation,
                    sid: limitUserToSalesAreas[0].salesAreas[0].salesAreaId,
                })
            }
        }
        client.reFetchObservableQueries()
    }

    useEffect(() => {
        if (meLoading) {
            return
        }
        if (!sessionStore.isAuthenticated || loadingFromExternalUrl || !currentCustomer) {
            setFilterRoutes(routes)
        } else {
            setFilterRoutes(routes.filter(hasRouteAccess))
        }
    }, [meLoading, currentCustomer, loadingFromExternalUrl, sessionStore.isAuthenticated])

    useEffect(() => {
        if (me) {
            switchToCustomer()
        }
    }, [me])

    // without me it is not possible to correctly filter the routes by feature,
    // so the redirect can happen earlier to a different route not present in the filtered because me is not available
    if (meLoading || !filteredRoutes.length) {
        return null
    }

    return (
        <Switch>
            <Route path="/authentication-error" component={AuthErrorPage} exact />
            <Route path="/login-or-register" component={LoginOrRegisterPage} exact />
            <Route path="/e2e-gsp" component={GenerateSetPasswordLinkPage} exact />
            {filteredRoutes.map(route => {
                const RouteComponent = route.public ? Route : PrivateRoute
                return <RouteComponent key={`route-${route.path}`} {...route} />
            })}
            {getHomeRoute()}
            {sessionStore.isAuthenticated ? <Route component={NotFoundPage} /> : getRedirectWithState('/login')}
        </Switch>
    )
}

export const AppRoutes = withRouter(AppRoutesComponent)
