const ApplicationKeysDE = [
    'ai-automotive',
    'ai-construction-flooring',
    'ai-coatings-architect-coatings',
    'ai-construction-building-chemicals',
    'ai-construction-concrete-based-prod-',
    'ai-decorative-paints',
    'ai-dental',
    'ai-coatings-sealants',
    'ai-printing-publishing',
    'ai-electronic',
    'ai-epoxy-systems',
    'ai-coatings-other',
    'ai-filter-and-environmental-technology',
    'ai-glass',
    'ai-rubber-seals-gaskets',
    'ai-rubber-wires-cables',
    'ai-rubber-latex-goods',
    'ai-rubber-medical-parts',
    'ai-rubber-tyre',
    'ai-rubber-tubes-hoses',
    'ai-rubber-other',
    'ai-rubber-compounds',
    'ai-homecare',
    'ai-coatings-wood-coatings',
    'ai-coatings-industrial-coatings',
    'ai-industrial-cleaning',
    'ai-ceramics',
    'ai-coatings-adhesives',
    'ai-coatings-raw-materials-other',
    'ai-agro',
    'ai-aerospace',
    'ai-metal-surface-treatments',
    'ai-oil-upstream-fracs-drill',
    'ai-oil-midstream-n-spec',
    'ai-oil-midstream-gasplants',
    'ai-oil-midstream-compounder',
    'ai-oil-downstream-refinerie',
    'ai-oil-oil-gas-other',
    'ai-paper-manufacturers',
    'ai-pulp-paper-others',
    'ai-polymers-compounders',
    'ai-polymers-extrusion',
    'ai-polymers-extrusion-foil',
    'ai-polymers-pu-elastomers',
    'ai-polymers-pvc',
    'ai-polymers-raw-mat-producers',
    'ai-polymers-other',
    'ai-polymers-elastomers-other',
    'ai-polymers-injection-moulding',
    'ai-chemical-processing',
    'ai-coatings-powder-coatings',
    'ai-cleaning-vehicles',
    'ai-cleaning-floorcare',
    'ai-cleaning-food-area',
    'ai-cleaning-other',
    'ai-dry-clean-laundry',
    'ai-lubricants',
    'ai-lub-fuel-blends',
    'ai-construction-other',
    'ai-chemical-synthesis',
    'ai-textile-leather',
    'ai-coatings-inks',
    'ai-packaging-converting',
    'ai-water-cond-serv-cy-waste-water',
    'ai-water-mun-waste-water',
    'ai-water-mun-swimming-pool',
    'ai-water-mun-potable-water',
    'ai-water-teatment-other',
    'ai-waterconditioning',
    'ai-tissue-manufacturers',
    'ai-construction-cement-products',
    'animal-nutrition:air-treatment',
    'animal-nutrition:enrichment-(minerals-vitamins-amino-acids)',
    'animal-nutrition:antioxidants',
    'animal-nutrition:flavours',
    'animal-nutrition:binder-flow-additives-coagulation-agents',
    'animal-nutrition:products-for-biogas-systems',
    'animal-nutrition:biocides-and-hygiene-products',
    'animal-nutrition:emulsifiers-stabilizers-thickener',
    'animal-nutrition:energy-suppliers',
    'animal-nutrition:colourings',
    'animal-nutrition:coccidiostatics-and-histomonostats',
    'animal-nutrition:preservatives',
    'animal-nutrition:performance-booster',
    'animal-nutrition:radionuclide-binder',
    'animal-nutrition:acidity-regulators',
    'animal-nutrition:silage-additives',
    'animal-nutrition:technical-adjuvants',
    'animal-nutrition:release-agents',
    'animal-nutrition:water-treatment',
    'cosmetics:deodorant',
    'cosmetics:hair-care',
    'cosmetics:hair-styling',
    'cosmetics:make-up',
    'cosmetics:oral-hygiene',
    'cosmetics:perfume',
    'cosmetics:skin-care',
    'cosmetics:skin-cleansing',
    'cosmetics:sun',
    'food:acidifiers-and-acidity-regulators',
    'food:antioxidants',
    'food:carriers-and-bulking-agents',
    'food:coating-and-release-agents',
    'food:colourings',
    'food:emulsifier',
    'food:flavours',
    'food:foaming-agents',
    'food:humectants',
    'food:nutrition-enrichment',
    'food:raising-and-flour-tratment-agents',
    'food:rreservatives-and-stabilizers',
    'food:smelting-agent',
    'food:sugars-and-sweeteners',
    'food:technical-adjuvants',
    'food:thickener-gelling-and-firming-agent',
    'medicinal-product:capsule',
    'medicinal-product:cream-gel-salve',
    'medicinal-product:drops-(oral)',
    'medicinal-product:eye-drops',
    'medicinal-product:parenteral',
    'medicinal-product:sachet',
    'medicinal-product:spray-solution-(eyes-nose)',
    'medicinal-product:syrup-(oral)',
    'medicinal-product:tablet',
    'mp-ns-ph:others',
    'nutritional-supplement:syrup-drops-solution',
    'nutritional-supplement:tablet-capsule-sachet',
    'pharma:api-synthesis',
    'pharma:biotechnology',
    'pharma:desinfection',
    'other',
]

export default ApplicationKeysDE
