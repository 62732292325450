import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Speaker: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Speaker" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M20 22H13V20H18V4H6V20H11V22H4V2H20V22ZM12 10C9.79 10 8 11.79 8 14C8 15.86 9.28 17.41 11 17.86V15.72C10.41 15.37 10 14.74 10 14C10 12.9 10.9 12 12 12C13.1 12 14 12.9 14 14C14 14.74 13.59 15.37 13 15.72V17.86C14.72 17.41 16 15.86 16 14C16 11.79 14.21 10 12 10ZM13 7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7Z"
                fill={fill === 'gradient' ? 'url(#Speaker_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Speaker" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Speaker" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M20 22H13V20H18V4H6V20H11V22H4V2H20V22ZM12 10C9.79 10 8 11.79 8 14C8 15.86 9.28 17.41 11 17.86V15.72C10.41 15.37 10 14.74 10 14C10 12.9 10.9 12 12 12C13.1 12 14 12.9 14 14C14 14.74 13.59 15.37 13 15.72V17.86C14.72 17.41 16 15.86 16 14C16 11.79 14.21 10 12 10ZM13 7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7Z"
                fill={fill === 'gradient' ? 'url(#Speaker_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Speaker" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Speaker" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M20 22H13V20H18V4H6V20H11V22H4V2H20V22ZM12 10C9.79 10 8 11.79 8 14C8 15.86 9.28 17.41 11 17.86V15.72C10.41 15.37 10 14.74 10 14C10 12.9 10.9 12 12 12C13.1 12 14 12.9 14 14C14 14.74 13.59 15.37 13 15.72V17.86C14.72 17.41 16 15.86 16 14C16 11.79 14.21 10 12 10ZM13 7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7Z"
                fill={fill === 'gradient' ? 'url(#Speaker_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Speaker" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Speaker" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M20 22H13V20H18V4H6V20H11V22H4V2H20V22ZM12 10C9.79 10 8 11.79 8 14C8 15.86 9.28 17.41 11 17.86V15.72C10.41 15.37 10 14.74 10 14C10 12.9 10.9 12 12 12C13.1 12 14 12.9 14 14C14 14.74 13.59 15.37 13 15.72V17.86C14.72 17.41 16 15.86 16 14C16 11.79 14.21 10 12 10ZM13 7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7Z"
                fill={fill === 'gradient' ? 'url(#Speaker_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Speaker" />}
        </g>
    ),
}
