import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Phone_In: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Phone_In" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M19.5 22H22V20.02H19.5C10.95 20.02 4 13.07 4 4.52V4.02H8.45L10.76 7.86L7.65 10.97L8.07 11.64C9.16 13.38 10.65 14.88 12.39 15.96L13.06 16.38L16.17 13.27L20.01 15.58V18.01H22.01V14.44L15.85 10.75L12.78 13.82C11.78 13.1 10.91 12.22 10.19 11.23L13.26 8.16L9.57 2H2V4.5C2 14.15 9.85 22 19.5 22ZM16 8H22V6H18V2H16V8Z"
                fill={fill === 'gradient' ? 'url(#Phone_In_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Phone_In" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Phone_In" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M19.5 22H22V20.02H19.5C10.95 20.02 4 13.07 4 4.52V4.02H8.45L10.76 7.86L7.65 10.97L8.07 11.64C9.16 13.38 10.65 14.88 12.39 15.96L13.06 16.38L16.17 13.27L20.01 15.58V18.01H22.01V14.44L15.85 10.75L12.78 13.82C11.78 13.1 10.91 12.22 10.19 11.23L13.26 8.16L9.57 2H2V4.5C2 14.15 9.85 22 19.5 22ZM16 8H22V6H18V2H16V8Z"
                fill={fill === 'gradient' ? 'url(#Phone_In_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Phone_In" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Phone_In" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M19.5 22H22V20.02H19.5C10.95 20.02 4 13.07 4 4.52V4.02H8.45L10.76 7.86L7.65 10.97L8.07 11.64C9.16 13.38 10.65 14.88 12.39 15.96L13.06 16.38L16.17 13.27L20.01 15.58V18.01H22.01V14.44L15.85 10.75L12.78 13.82C11.78 13.1 10.91 12.22 10.19 11.23L13.26 8.16L9.57 2H2V4.5C2 14.15 9.85 22 19.5 22ZM16 8H22V6H18V2H16V8Z"
                fill={fill === 'gradient' ? 'url(#Phone_In_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Phone_In" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Phone_In" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M19.5 22H22V20.02H19.5C10.95 20.02 4 13.07 4 4.52V4.02H8.45L10.76 7.86L7.65 10.97L8.07 11.64C9.16 13.38 10.65 14.88 12.39 15.96L13.06 16.38L16.17 13.27L20.01 15.58V18.01H22.01V14.44L15.85 10.75L12.78 13.82C11.78 13.1 10.91 12.22 10.19 11.23L13.26 8.16L9.57 2H2V4.5C2 14.15 9.85 22 19.5 22ZM16 8H22V6H18V2H16V8Z"
                fill={fill === 'gradient' ? 'url(#Phone_In_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Phone_In" />}
        </g>
    ),
}
