import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'
import { fontStyles } from './text-styles'

/**
 * Each style has the right sematic HTML behind it :)
 */
export interface TextComponentProps extends TextProps {
    cssOverrides?: string
}

const H1 = styled.h1<TextComponentProps>`
    ${fontStyles.h1}
    ${({ cssOverrides }) => cssOverrides}
`
const H2 = styled.h2<TextComponentProps>`
    ${fontStyles.h2}
    ${({ cssOverrides }) => cssOverrides}
`
const H3 = styled.h3<TextComponentProps>`
    ${fontStyles.h3}
    ${({ cssOverrides }) => cssOverrides}
`
const H4 = styled.h4<TextComponentProps>`
    ${fontStyles.h4}
    ${({ cssOverrides }) => cssOverrides}
`
const H5 = styled.h5<TextComponentProps>`
    ${fontStyles.h5}
    ${({ cssOverrides }) => cssOverrides}
`
const H6 = styled.h6<TextComponentProps>`
    ${fontStyles.h6}
    ${({ cssOverrides }) => cssOverrides}
`
const Span = styled.span<TextComponentProps>`
    ${fontStyles.p}
    ${({ cssOverrides }) => cssOverrides}
`
const P = styled.p<TextComponentProps>`
    ${fontStyles.p}
    ${({ cssOverrides }) => cssOverrides}
`
const Lead = styled.p<TextComponentProps>`
    ${fontStyles.lead}
    ${({ cssOverrides }) => cssOverrides}
`
const Intro = styled.p<TextComponentProps>`
    ${fontStyles.intro}
    ${({ cssOverrides }) => cssOverrides}
`
const Caption = styled.span<TextComponentProps>`
    ${fontStyles.caption}
    ${({ cssOverrides }) => cssOverrides}
`
const Small = styled.span<TextComponentProps>`
    ${fontStyles.small}
    ${({ cssOverrides }) => cssOverrides}
`

export interface TextProps {
    h1?: boolean
    h2?: boolean
    h3?: boolean
    h4?: boolean
    h5?: boolean
    h6?: boolean
    p?: boolean
    css?: string
    lead?: boolean
    intro?: boolean
    caption?: boolean
    captionBold?: boolean
    small?: boolean
    as?: keyof typeof fontStyles
    color?: keyof typeof colors
    noMargin?: boolean
    margin?: boolean
    block?: boolean
    title?: boolean
    subTitle?: boolean
    titleUnderline?: boolean
    textTransform?: CSSProperties['textTransform']
    wordBreak?: CSSProperties['wordBreak']
    fontWeight?: CSSProperties['fontWeight']
    whiteSpace?: CSSProperties['whiteSpace']
}

export const Text: React.FC<TextProps> = ({
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    lead,
    caption,
    p,
    as,
    color,
    block,
    noMargin,
    margin,
    title,
    subTitle,
    small,
    intro,
    textTransform,
    whiteSpace,
    wordBreak,
    fontWeight,
    titleUnderline,
    css,
    ...rest
}) => {
    const styleOverride = as ? fontStyles[as as keyof typeof fontStyles] : ''
    const colorOverride = color
        ? `
        color: ${colors[color]};
    `
        : ''
    const marginOverride = noMargin
        ? `
        margin: 0 !important;
    `
        : ''
    const addMarginOverride = margin
        ? `
        margin-bottom: 15px !important;
    `
        : ''
    const blockOverride = block
        ? `
        display: block;
    `
        : ''
    const titleOverride = title ? fontStyles.title : ''
    const titleUnderlineOverride = titleUnderline
        ? `
        border-bottom: 1px solid ${colors.neutral50};
        padding-bottom: 20px;
    `
        : ''
    const subTitleOverride = subTitle ? fontStyles.subTitle : ''
    const textTransformOverride = textTransform
        ? `
        text-transform: ${textTransform};
    `
        : ''
    const fontWeightOverride = fontWeight
        ? `
        font-weight: ${fontWeight};
    `
        : ''
    const wordBreakOverride = wordBreak
        ? `
        word-break: ${wordBreak};
    `
        : ''

    const whiteSpaceOverride = whiteSpace
        ? `
        white-space: ${whiteSpace};
    `
        : ''

    const cssOverrides = [
        styleOverride,
        colorOverride,
        addMarginOverride,
        marginOverride,
        blockOverride,
        titleOverride,
        subTitleOverride,
        textTransformOverride,
        fontWeightOverride,
        titleUnderlineOverride,
        wordBreakOverride,
        whiteSpaceOverride,
        css,
    ].join(' ')

    return (
        (h1 && <H1 {...rest} cssOverrides={cssOverrides} />) ||
        (h2 && <H2 {...rest} cssOverrides={cssOverrides} />) ||
        (h3 && <H3 {...rest} cssOverrides={cssOverrides} />) ||
        (h4 && <H4 {...rest} cssOverrides={cssOverrides} />) ||
        (h5 && <H5 {...rest} cssOverrides={cssOverrides} />) ||
        (h6 && <H6 {...rest} cssOverrides={cssOverrides} />) ||
        (lead && <Lead {...rest} cssOverrides={cssOverrides} />) ||
        (caption && <Caption {...rest} cssOverrides={cssOverrides} />) ||
        (small && <Small {...rest} cssOverrides={cssOverrides} />) ||
        (intro && <Intro {...rest} cssOverrides={cssOverrides} />) ||
        (p && <P {...rest} cssOverrides={cssOverrides} />) || <Span {...rest} cssOverrides={cssOverrides} />
    )
}
