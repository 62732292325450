import React from 'react'
import styled from 'styled-components'

import { GQLAddress, GQLLocation } from '@bc/types'
import { Text } from '@bc/ui/src/components/text'
import { getJSXAddress, getJSXLocationAddress } from '@bc/ui/src/util'

export interface AddressProps {
    location?: GQLLocation | undefined
    baseAddress?: GQLAddress | undefined
}

const StyledAddress = styled.address`
    font-style: normal;
`

export const Address = ({ location, baseAddress }: AddressProps) => {
    let content: JSX.Element | string = '-'
    if (baseAddress) {
        content = getJSXAddress(baseAddress)
    }

    if (location?.address) {
        content = getJSXLocationAddress(location)
    }

    return (
        <StyledAddress>
            <Text data-test-id="text-address">{content}</Text>
        </StyledAddress>
    )
}
