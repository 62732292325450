import styled from 'styled-components'

import { colors, transition } from '@bc/ui/src/config'

export const Wrapper = styled.a`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    text-decoration: none;
    color: ${colors.deepPurple};
    svg > * {
        transition: ${transition.config};
        transition-property: fill;
        fill: ${colors.deepPurple};
    }

    &:hover svg > * {
        fill: ${colors.deepPurple};
    }
`
export const Icon = styled.div`
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
`
