import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const BarGraph: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="BarGraph" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M21 3H19V21H21V3ZM13 11H11V21H13V11ZM5 19H3V21H5V19ZM7 15H9V21H7V15ZM15 7H17V21H15V7Z"
                fill={fill === 'gradient' ? 'url(#BarGraph_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="BarGraph" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="BarGraph" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M21 3H19V21H21V3ZM13 11H11V21H13V11ZM5 19H3V21H5V19ZM7 15H9V21H7V15ZM15 7H17V21H15V7Z"
                fill={fill === 'gradient' ? 'url(#BarGraph_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="BarGraph" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="BarGraph" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M21 3H19V21H21V3ZM13 11H11V21H13V11ZM5 19H3V21H5V19ZM7 15H9V21H7V15ZM15 7H17V21H15V7Z"
                fill={fill === 'gradient' ? 'url(#BarGraph_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="BarGraph" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="BarGraph" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M21 3H19V21H21V3ZM13 11H11V21H13V11ZM5 19H3V21H5V19ZM7 15H9V21H7V15ZM15 7H17V21H15V7Z"
                fill={fill === 'gradient' ? 'url(#BarGraph_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="BarGraph" />}
        </g>
    ),
}
