import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

class ScrollToTopClass extends React.Component<RouteComponentProps<any>> {
    public componentDidUpdate(prevProps: RouteComponentProps<any>) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    public render = () => this.props.children
}

export const ScrollToTop = withRouter(ScrollToTopClass)
