import { Locale } from '@bc/translations'

export interface GetUrlFragments {
    locale: Locale
    slug: string
    rest: string[] | undefined
}

export const getUrlFragments = (url: string): GetUrlFragments | undefined => {
    if (!url) {
        return
    }
    const pathArray = removeTrailingSlash(removeProtocol(url)).split('/')
    if (!pathArray[1]) {
        return
    }
    return {
        locale: pathArray[1] as Locale,
        slug: pathArray[2] ? pathArray[2] : 'homepage',
        rest: pathArray.slice(3, pathArray.length),
    }
}

export const removeTrailingSlash = (url: string) => url.replace(/\/$/, '')

export const removeProtocol = (url: string) => url.replace(/(^\w+:|^)\/\//, '')

// IE11 location.origin may give incorrect link
// https://stackoverflow.com/questions/22564167/window-location-origin-gives-wrong-value-when-using-ie
export const getLocationOrigin = (location: Location) => {
    const { protocol, hostname, port } = location
    return `${protocol}//${hostname}${port ? ':' + port : ''}`
}

export const getFirstEmailFromQuery = (email: string | string[] | null | undefined) =>
    Array.isArray(email) ? (email as string[])[0] : (email as string | null | undefined)?.split(';')[0]
