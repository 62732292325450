import React from 'react'

import { Text } from '@bc/ui'

import { useFormatMessage } from '../hooks'
import { GenericWarning } from './generic-warning'

interface SearchCountWarningProps {
    count?: number
    limit?: number
}

export const SearchCountWarning = ({ count = -1, limit = 0 }: SearchCountWarningProps) => {
    const t = useFormatMessage()
    return count >= limit && limit !== 0 ? (
        <GenericWarning icon="Circle_Info" noPadding>
            <Text small fontWeight={700} noMargin>
                {t('user:limited-search-results', undefined, { number: limit, count })}
            </Text>
        </GenericWarning>
    ) : null
}
