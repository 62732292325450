import React, { memo } from 'react'
import DayPicker from 'react-day-picker'
import { Modifier } from 'react-day-picker/types/common'
import styled from 'styled-components'

import { useClickOutside } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'

import { staticCss } from './calendar-styles'

export interface CalendarProps {
    locale?: string
    disabledDays?: Modifier | Modifier[]
    selectedDays?: Date[]
    initialMonth?: Date
    onSelect: (day: Date) => void
    onClickOutside: (e: MouseEvent | TouchEvent) => void
}

const StyledDayPicker = styled(DayPicker)`
    ${staticCss};
`

/**
 * Calendar: **React.memo**.
 *
 * @see https://github.com/gpbl/react-day-picker
 */
export const Calendar = memo(
    ({ onClickOutside, disabledDays, initialMonth, locale, onSelect, selectedDays = [] }: CalendarProps) => {
        const t = useFormatMessage()
        const ref = React.useRef<HTMLDivElement>(null)
        useClickOutside([ref], e => onClickOutside(e))

        return (
            <div ref={ref}>
                <StyledDayPicker
                    locale={locale}
                    months={[
                        t('react-day-picker:months.january'),
                        t('react-day-picker:months.february'),
                        t('react-day-picker:months.march'),
                        t('react-day-picker:months.april'),
                        t('react-day-picker:months.may'),
                        t('react-day-picker:months.june'),
                        t('react-day-picker:months.july'),
                        t('react-day-picker:months.august'),
                        t('react-day-picker:months.september'),
                        t('react-day-picker:months.october'),
                        t('react-day-picker:months.november'),
                        t('react-day-picker:months.december'),
                    ]}
                    weekdaysLong={[
                        t('react-day-picker:weekdays-long.sunday'),
                        t('react-day-picker:weekdays-long.monday'),
                        t('react-day-picker:weekdays-long.tuesday'),
                        t('react-day-picker:weekdays-long.wednesday'),
                        t('react-day-picker:weekdays-long.thursday'),
                        t('react-day-picker:weekdays-long.friday'),
                        t('react-day-picker:weekdays-long.saturday'),
                    ]}
                    weekdaysShort={[
                        t('react-day-picker:weekdays-short.sunday'),
                        t('react-day-picker:weekdays-short.monday'),
                        t('react-day-picker:weekdays-short.tuesday'),
                        t('react-day-picker:weekdays-short.wednesday'),
                        t('react-day-picker:weekdays-short.thursday'),
                        t('react-day-picker:weekdays-short.friday'),
                        t('react-day-picker:weekdays-short.saturday'),
                    ]}
                    firstDayOfWeek={parseInt(t('react-day-picker:first-day-of-week'), 10)}
                    labels={{
                        nextMonth: t('react-day-picker:next-month'),
                        previousMonth: t('react-day-picker:previous-month'),
                    }}
                    fixedWeeks
                    disabledDays={disabledDays}
                    selectedDays={selectedDays}
                    onDayClick={onSelect}
                    initialMonth={initialMonth}
                />
            </div>
        )
    },
)
