import { VirtualPageviewTag } from '../types'
import { dataLayer } from '../utils'

export const virtualPageview = () => {
    dataLayer<VirtualPageviewTag>({
        event: 'VirtualPageview',
        virtualURL: window.location.href,
        virtualTitle: window.location.href,
    })
}
