import { EventTag } from '../types'
import { dataLayer } from '../utils'

export const documentDownload = (type: string, name: string) => {
    dataLayer<EventTag>({
        event: 'gtm.download',
        eventCategory: 'download',
        eventAction: type,
        eventLabel: name,
    })
}

export const materialDocumentDownload = (type: string, name: string, id: string) => {
    dataLayer<EventTag>({
        event: 'gtm.download',
        eventCategory: 'download',
        eventAction: type,
        eventLabel: name,
        dimension4: id,
    })
}
