import React from 'react'
import { GraphQLFormattedError } from 'graphql'

import { useFormatMessage } from '@frontend/components/hooks'

import { PageError } from './page-error'

interface UserMigratedErrorProps {
    error?: GraphQLFormattedError
}

export const UserMigratedError = ({ error }: UserMigratedErrorProps) => {
    const region = error?.extensions?.data.user.region ?? 'en'
    const t = useFormatMessage()
    const key = 'customer-migrated-bep'

    return <PageError title={t(`warning:${key}:title`)} text={t(`warning:${key}:explanation`, undefined, { region })} />
}
