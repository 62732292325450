import { colors, fontStyles, spacing } from '@bc/ui'

export interface CalendarStylesProps {
    /** Weather or not to open the datePicker on top */
    openOnTop?: boolean
}

export const staticCss = `
    width: 280px;

    .DayPicker-NavBar {
        position absolute;
        padding: 1.7rem 2rem;
        top: 0;
        right: 0;
        z-index: 8;
        display: flex;
    }

    .DayPicker-wrapper {
        width: 100%;
        padding: 13px 0;
        background-color: white;
        border: 1px solid ${colors.neutral20};
        border-radius: 2px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    }

    .DayPicker-Month {
        display: block;
        position: relative;
        width: auto;
        margin: 0 ${spacing.sm}px;
    }

    .DayPicker-Caption {
        font-size: 1.3rem;
        padding: 0;
        display: block;

        > div {
            ${fontStyles.h6}
            margin: 0;
        }
    }

    .DayPicker-Week {
        display: flex;
    }

    .DayPicker-Weekdays {
        display: block;
        width: auto;
        margin: 5px -7px 0;
    }

    .DayPicker-WeekdaysRow {
        display: flex;
        width: 100%;
        margin-top: 0;
    }

    .DayPicker-Weekday {
        font-size: 1.3rem;
        display: flex;
        flex: 1;
        color: ${colors.neutral90};
        font-weight: 700;
        text-transform: uppercase;
        line-height: 3.5rem;
        margin-top: 5px;
        justify-content: center;
        letter-spacing: 1;

        abbr[title] {
            text-decoration: none;
        }
    }

    .DayPicker-Body {
        display: block;
        margin: 0 -7px;
    }

    .DayPicker-Day {
        display: block;
        width: auto;
        flex: 1;
        cursor: pointer;
        line-height: 3rem;
        font-size: 1.3rem;
        font-weight: 500;
        background-color: ${colors.neutral10};
        border: none;
        border-radius: 2px;
        text-align: center;
        padding: 0;
        position: relative;
        margin: 2px;
        z-index: 7;
    }

    .DayPicker-Day--today {
        color: ${colors.neutral90};
    }

    .DayPicker-Day--disabled {
        color: ${colors.neutral50};
        cursor: default;
    }

    &.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: ${colors.deepPurple};
        color: ${colors.white};
        border-radius: 2px;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        border-radius: 2px;
        background-color: ${colors.neutral90};
        color: ${colors.white};
    }

    .DayPicker-NavButton {
        position: relative;
        width: 30px;
        height: 30px;
        top: auto;
        right: auto;
        margin-top: auto;
        border-radius: 2px;
        margin-left: 10px;
        cursor: pointer;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        border: solid 1px ${colors.neutral90};
    }
    .DayPicker-NavButton--prev {
        // tslint:disable-next-line:max-line-length
        background-image:
            url(data:image/svg+xml;base64,DQo8c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMCAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4NCiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ5ICg1MTAwMikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+DQogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+DQogICAgPGRlZnM+DQogICAgICAgIDxwb2x5Z29uIGlkPSJwYXRoLTEiIHBvaW50cz0iMTAuOTU3IDAuMDAwMSAzIDcuOTU3MSAxMC45NTcgMTUuOTE0MSAxMi4zNzEgMTQuNTAwMSA1LjgyOCA3Ljk1NzEgMTIuMzcxIDEuNDE0MSI+PC9wb2x5Z29uPg0KICAgIDwvZGVmcz4NCiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgICAgIDxnIGlkPSJJY29ucy8xNi9DaGV2cm9uLUxlZnQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zLjAwMDAwMCwgMC4wMDAwMDApIj4NCiAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4NCiAgICAgICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPg0KICAgICAgICAgICAgPC9tYXNrPg0KICAgICAgICAgICAgPHVzZSBpZD0iUGFnZS0xIiBmaWxsPSIjNUU2ODk2IiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4NCiAgICAgICAgPC9nPg0KICAgIDwvZz4NCjwvc3ZnPg==);
    }
    .DayPicker-NavButton--next {
        // tslint:disable-next-line:max-line-length
        background-image:
            url(data:image/svg+xml;base64,DQo8c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxMCAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4NCiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ5ICg1MTAwMikgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+DQogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+DQogICAgPGRlZnM+DQogICAgICAgIDxwb2x5Z29uIGlkPSJwYXRoLTEiIHBvaW50cz0iNC40MTQgMC4wMDAxIDMgMS40MTQxIDkuNTQzIDcuOTU3MSAzIDE0LjUwMDEgNC40MTQgMTUuOTE0MSAxMi4zNzEgNy45NTcxIj48L3BvbHlnb24+DQogICAgPC9kZWZzPg0KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4NCiAgICAgICAgPGcgaWQ9Ikljb25zLzE2L0NoZXZyb24tUmlnaHQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zLjAwMDAwMCwgMC4wMDAwMDApIj4NCiAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIGZpbGw9IndoaXRlIj4NCiAgICAgICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNwYXRoLTEiPjwvdXNlPg0KICAgICAgICAgICAgPC9tYXNrPg0KICAgICAgICAgICAgPHVzZSBpZD0iaWNvbiIgZmlsbD0iIzVFNjg5NiIgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+DQogICAgICAgIDwvZz4NCiAgICA8L2c+DQo8L3N2Zz4=);
    }
    .DayPicker-Day--outside {
        color: ${colors.neutral50};
    }
}`
