export const gridConfig = {
    columns: 12,
    containerGutter: 30,
    containerGutterSmall: 10,
    containerGutterY: 30,
    containerGutterX: 20,
    gridGutter: 20,
    gridGutterSmall: 10,
    maxGridWidth: 1440,
}
