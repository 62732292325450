import React from 'react'

import { Resources } from '@bc/config'
import { GQLCustomer, GQLMe } from '@bc/types'
import { Text, HeaderBrenntagLogo, SvgIcon, colors } from '@bc/ui'
import { useFormatMessage, useHasAccess } from '@frontend/components/hooks'
import { trimLeadingZeros, useMobile } from '@frontend/utils'

import * as CustomerHeader from './customer-header'
import { NavigationCustomerSelection } from './customer-selection'
import * as NavComponents from './navigation-components'
import { NavigationDropdown } from './navigation-dropdown'
import { NavigationUserMenu } from './navigation-user-menu'
import { PromotionMenuButton } from './pormotion-menu-button'
import { ShoppingCart } from './shopping-cart-menu'
import { MainMenu } from './main-menu'

interface NavigationProps {
    userHasMultipleCustomerEntities: boolean
    currentCustomer?: GQLCustomer
    me: GQLMe
    activeSalesAreaId?: string
    removeActiveCustomer: () => void
}

export const Navigation = ({
    userHasMultipleCustomerEntities,
    currentCustomer,
    me,
    removeActiveCustomer,
    activeSalesAreaId,
}: NavigationProps) => {
    const t = useFormatMessage()
    const isMobile = useMobile()
    const hasAccess = useHasAccess()

    const myCompaniesLink = {
        href: t('route:my-customers'),
        title: t('route:my-customers.label'),
        id: 'my-customers',
    }

    const country = activeSalesAreaId && t(`country:${activeSalesAreaId.toLowerCase().substring(0, 2)}`)
    const salesOrganisation = activeSalesAreaId?.substring(0, 4) ?? ''

    return (
        <NavComponents.Wrapper>
            {currentCustomer && (
                <CustomerHeader.Wrapper>
                    {hasAccess(Resources.SearchCustomers) && (
                        <CustomerHeader.BackLink to={myCompaniesLink.href} onClick={removeActiveCustomer}>
                            {myCompaniesLink.title}
                        </CustomerHeader.BackLink>
                    )}
                    <CustomerHeader.CompanyInfo>
                        <NavigationCustomerSelection
                            customers={me.customers}
                            currentCustomer={currentCustomer}
                            activeSalesAreaId={activeSalesAreaId}
                            userHasMultipleCustomerEntities={userHasMultipleCustomerEntities}
                            t={t}
                            showIntro={!Boolean(currentCustomer)}
                            open={!Boolean(currentCustomer)}>
                            {currentCustomer ? (
                                <>
                                    <SvgIcon icon="Enterprise" size="16" fill={colors.deepPurple} />
                                    <CustomerHeader.CompanyName>
                                        {`${currentCustomer.name} - ${trimLeadingZeros(currentCustomer.id)}`}
                                    </CustomerHeader.CompanyName>
                                    <CustomerHeader.CompanySalesOrg>{`${country} (${salesOrganisation})`}</CustomerHeader.CompanySalesOrg>
                                </>
                            ) : (
                                <Text noMargin p>
                                    {t('company:selection:change')}
                                </Text>
                            )}
                        </NavigationCustomerSelection>
                    </CustomerHeader.CompanyInfo>
                </CustomerHeader.Wrapper>
            )}

            {isMobile ? (
                <>
                    <NavComponents.NavUserWrapper extraPadding={Boolean(currentCustomer)}>
                        <NavComponents.LogoContainer>
                            <HeaderBrenntagLogo mobile />
                        </NavComponents.LogoContainer>
                        <NavComponents.IconButtons>
                            <PromotionMenuButton />
                            <NavigationDropdown />
                            <ShoppingCart enable={currentCustomer !== undefined} />
                        </NavComponents.IconButtons>
                    </NavComponents.NavUserWrapper>
                    <MainMenu currentCustomer={currentCustomer} />
                </>
            ) : (
                <NavComponents.SlidingHeadroom pinStart={currentCustomer ? 40 : 0}>
                    <NavComponents.NavUserWrapper>
                        <NavComponents.LogoContainer>
                            <HeaderBrenntagLogo />
                        </NavComponents.LogoContainer>
                        <NavComponents.IconButtons>
                            <PromotionMenuButton />
                            <NavigationUserMenu />
                            <ShoppingCart enable={currentCustomer !== undefined} />
                        </NavComponents.IconButtons>
                    </NavComponents.NavUserWrapper>
                    <MainMenu currentCustomer={currentCustomer} />
                </NavComponents.SlidingHeadroom>
            )}
        </NavComponents.Wrapper>
    )
}
