import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Microphone: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Microphone" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M8 13C8 15.21 9.79 17 12 17C14.21 17 16 15.21 16 13V9H14V13C14 14.1 13.1 15 12 15C10.9 15 10 14.1 10 13V6C10 4.9 10.9 4 12 4C13.1 4 14 4.9 14 6V7H16V6C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6V13ZM18 13V9H20V13C20 17.07 16.94 20.44 13 20.93V23H11V20.93C7.06 20.44 4 17.07 4 13V9H6V13C6 16.31 8.69 19 12 19C15.31 19 18 16.31 18 13Z"
                fill={fill === 'gradient' ? 'url(#Microphone_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Microphone" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Microphone" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M8 13C8 15.21 9.79 17 12 17C14.21 17 16 15.21 16 13V9H14V13C14 14.1 13.1 15 12 15C10.9 15 10 14.1 10 13V6C10 4.9 10.9 4 12 4C13.1 4 14 4.9 14 6V7H16V6C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6V13ZM18 13V9H20V13C20 17.07 16.94 20.44 13 20.93V23H11V20.93C7.06 20.44 4 17.07 4 13V9H6V13C6 16.31 8.69 19 12 19C15.31 19 18 16.31 18 13Z"
                fill={fill === 'gradient' ? 'url(#Microphone_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Microphone" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Microphone" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M8 13C8 15.21 9.79 17 12 17C14.21 17 16 15.21 16 13V9H14V13C14 14.1 13.1 15 12 15C10.9 15 10 14.1 10 13V6C10 4.9 10.9 4 12 4C13.1 4 14 4.9 14 6V7H16V6C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6V13ZM18 13V9H20V13C20 17.07 16.94 20.44 13 20.93V23H11V20.93C7.06 20.44 4 17.07 4 13V9H6V13C6 16.31 8.69 19 12 19C15.31 19 18 16.31 18 13Z"
                fill={fill === 'gradient' ? 'url(#Microphone_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Microphone" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Microphone" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M8 13C8 15.21 9.79 17 12 17C14.21 17 16 15.21 16 13V9H14V13C14 14.1 13.1 15 12 15C10.9 15 10 14.1 10 13V6C10 4.9 10.9 4 12 4C13.1 4 14 4.9 14 6V7H16V6C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6V13ZM18 13V9H20V13C20 17.07 16.94 20.44 13 20.93V23H11V20.93C7.06 20.44 4 17.07 4 13V9H6V13C6 16.31 8.69 19 12 19C15.31 19 18 16.31 18 13Z"
                fill={fill === 'gradient' ? 'url(#Microphone_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Microphone" />}
        </g>
    ),
}
