import { colors, Column, media, Row, spacing, SvgIconButton } from '@bc/ui'
import styled from 'styled-components'

export const ColumnMaterial = styled(Column)`
    position: relative;
`

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${spacing.sm}px 0px;
`

export const Content = styled.div`
    padding: ${spacing.xs}px;
    background: ${colors.white};
    border: 1px solid ${colors.neutral20};
    &:not(:last-child) {
        margin-bottom: ${spacing.xs}px;
    }
`

export const Stats = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const Stat = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 1rem;
    flex-basis: 100%;
    ${media.min('sm')} {
        flex-basis: 50%;
    }

    ${media.min('md')} {
        flex-basis: 20%;
    }
`

export const DoubleStat = styled(Stat)`
    flex-basis: 100%;
    ${media.min('sm')} {
        flex-basis: 50%;
    }
    ${media.min('md')} {
        flex-basis: 40%;
    }
`

export const ActionButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    & > *:not(:last-child) {
        margin-right: ${spacing.xxs}px;
    }
`

// To leave space for the buttons on the right
export const HeaderWrapper = styled.div`
    flex: 4 0;
`

export const CardRow = styled(Row)`
    display: flex;
    justify-content: space-between;
    margin: 0;
    ${media.min('md')} {
        margin: 0;
        flex-wrap: nowrap;
    }
`

export const ActionButton = styled(SvgIconButton)`
    padding: ${spacing.xxs}px;
    border: solid 1px ${colors.neutral50};
    border-radius: 5px;
`

export const StatIconAlign = styled.span`
    display: flex;
    align-items: center;
`

export const BoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
`
