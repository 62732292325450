import React from 'react'
import ReactTransition from 'react-transition-group/Transition'
import styled from 'styled-components'

import { TransitionStates, TransitionStyles, transitionStyles } from './transition-styles'

/**
 * Element to handle the transition CSS
 */
interface TransitionElementProps {
    transitionStyle: keyof typeof TransitionStyles
    transitionState: keyof typeof TransitionStates
}

const TransitionElement = styled.div`
    ${({ transitionStyle, transitionState }: TransitionElementProps) =>
        transitionStyles[transitionStyle][transitionState]}
`

/** Actual transition Component */
interface TransitionProps {
    transitionStyle: keyof typeof TransitionStyles
    duration?: number
    show?: boolean
    manual?: boolean
    key?: string | number
}

export class Transition extends React.PureComponent<TransitionProps, any> {
    public state = {
        mounted: false,
    }

    public componentDidMount() {
        this.setState({ mounted: true })
    }

    public render() {
        const { key, manual, show, transitionStyle = 'fade-down', duration = 300 } = this.props
        const durationInMs = duration as number

        const inProp = manual ? show : this.state.mounted

        return (
            <ReactTransition key={key} in={inProp} timeout={durationInMs} appear={true} unmountOnExit={true}>
                {(state: keyof typeof TransitionStates) => (
                    <TransitionElement transitionStyle={transitionStyle} transitionState={state}>
                        {this.props.children}
                    </TransitionElement>
                )}
            </ReactTransition>
        )
    }
}
