import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Parcel_Location: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Parcel_Location" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M13 6C13 8.76 15.24 11 18 11C20.76 11 23 8.76 23 6C23 3.24 20.76 1 18 1C15.24 1 13 3.24 13 6ZM15 6C15 4.35 16.35 3 18 3C19.65 3 21 4.35 21 6C21 7.65 19.65 9 18 9C16.35 9 15 7.65 15 6ZM19 6C19 6.55228 18.5523 7 18 7C17.4477 7 17 6.55228 17 6C17 5.44772 17.4477 5 18 5C18.5523 5 19 5.44772 19 6ZM20 12.7099V17.2799L13 19.6099V10.8899C12.41 10.2899 11.94 9.58986 11.59 8.80986L2 5.60986V18.7199L12 22.0499L22 18.7199V11.7399C21.4 12.1599 20.72 12.4899 20 12.7099ZM11 19.6199L4 17.2899V8.38986L11 10.7199V19.6099V19.6199ZM12.0002 1.9502L12.2402 2.0302C11.7702 2.7202 11.4202 3.4902 11.2102 4.3202L7.82016 5.4502L4.66016 4.4002L12.0002 1.9502Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Location_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Location" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Parcel_Location" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M13 6C13 8.76 15.24 11 18 11C20.76 11 23 8.76 23 6C23 3.24 20.76 1 18 1C15.24 1 13 3.24 13 6ZM15 6C15 4.35 16.35 3 18 3C19.65 3 21 4.35 21 6C21 7.65 19.65 9 18 9C16.35 9 15 7.65 15 6ZM19 6C19 6.55228 18.5523 7 18 7C17.4477 7 17 6.55228 17 6C17 5.44772 17.4477 5 18 5C18.5523 5 19 5.44772 19 6ZM20 12.7099V17.2799L13 19.6099V10.8899C12.41 10.2899 11.94 9.58986 11.59 8.80986L2 5.60986V18.7199L12 22.0499L22 18.7199V11.7399C21.4 12.1599 20.72 12.4899 20 12.7099ZM11 19.6199L4 17.2899V8.38986L11 10.7199V19.6099V19.6199ZM12.0002 1.9502L12.2402 2.0302C11.7702 2.7202 11.4202 3.4902 11.2102 4.3202L7.82016 5.4502L4.66016 4.4002L12.0002 1.9502Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Location_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Location" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Parcel_Location" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M13 6C13 8.76 15.24 11 18 11C20.76 11 23 8.76 23 6C23 3.24 20.76 1 18 1C15.24 1 13 3.24 13 6ZM15 6C15 4.35 16.35 3 18 3C19.65 3 21 4.35 21 6C21 7.65 19.65 9 18 9C16.35 9 15 7.65 15 6ZM19 6C19 6.55228 18.5523 7 18 7C17.4477 7 17 6.55228 17 6C17 5.44772 17.4477 5 18 5C18.5523 5 19 5.44772 19 6ZM20 12.7099V17.2799L13 19.6099V10.8899C12.41 10.2899 11.94 9.58986 11.59 8.80986L2 5.60986V18.7199L12 22.0499L22 18.7199V11.7399C21.4 12.1599 20.72 12.4899 20 12.7099ZM11 19.6199L4 17.2899V8.38986L11 10.7199V19.6099V19.6199ZM12.0002 1.9502L12.2402 2.0302C11.7702 2.7202 11.4202 3.4902 11.2102 4.3202L7.82016 5.4502L4.66016 4.4002L12.0002 1.9502Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Location_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Location" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Parcel_Location" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M13 6C13 8.76 15.24 11 18 11C20.76 11 23 8.76 23 6C23 3.24 20.76 1 18 1C15.24 1 13 3.24 13 6ZM15 6C15 4.35 16.35 3 18 3C19.65 3 21 4.35 21 6C21 7.65 19.65 9 18 9C16.35 9 15 7.65 15 6ZM19 6C19 6.55228 18.5523 7 18 7C17.4477 7 17 6.55228 17 6C17 5.44772 17.4477 5 18 5C18.5523 5 19 5.44772 19 6ZM20 12.7099V17.2799L13 19.6099V10.8899C12.41 10.2899 11.94 9.58986 11.59 8.80986L2 5.60986V18.7199L12 22.0499L22 18.7199V11.7399C21.4 12.1599 20.72 12.4899 20 12.7099ZM11 19.6199L4 17.2899V8.38986L11 10.7199V19.6099V19.6199ZM12.0002 1.9502L12.2402 2.0302C11.7702 2.7202 11.4202 3.4902 11.2102 4.3202L7.82016 5.4502L4.66016 4.4002L12.0002 1.9502Z"
                fill={fill === 'gradient' ? 'url(#Parcel_Location_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Parcel_Location" />}
        </g>
    ),
}
