import React, { useContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { useMutation } from '@apollo/react-hooks'

import { tagManager } from '@bc/gtm'
import { OxygenTemplate, useDocumentTitle } from '@bc/ui'
import { useFormatMessage, UserMigratedError } from '@frontend/components'
import { PasswordForgotForm, TitleDescriptionWrapper, ForgotPasswordFormState } from '@frontend/components/forms'
import { UserContext } from '@frontend/context'
import ForgotPasswordMutation from '@frontend/graphql/shared/forgot-password.graphql'
import { GraphQLFormattedError } from 'graphql'

const ForgotPassword = () => {
    const t = useFormatMessage()
    const { me } = useContext(UserContext)

    const [forgotPassword, { error, data }] = useMutation(ForgotPasswordMutation)

    useEffect(() => {
        tagManager.tags.userId()
    }, [])

    useDocumentTitle(t('route:reset-password.label'))

    const handleRequestResetPasswordSubmit = async ({ email }: any): Promise<void> =>
        void forgotPassword({
            variables: { email },
        })

    if (me) {
        return <Redirect to="/" />
    }

    let formState: ForgotPasswordFormState
    let userMigrated: boolean = false
    let graphqlError: GraphQLFormattedError | undefined

    if (data) {
        formState = ForgotPasswordFormState.success
    } else if (error) {
        formState = ForgotPasswordFormState.failure
        graphqlError = error.graphQLErrors[0]
        switch (graphqlError?.extensions?.code) {
            case 'UserMigratedToBEPError': {
                userMigrated = true
                break
            }
            default:
                break
        }
    } else {
        formState = ForgotPasswordFormState.initial
    }

    return (
        <OxygenTemplate>
            {userMigrated ? (
                <UserMigratedError error={graphqlError} />
            ) : (
                <TitleDescriptionWrapper
                    title={t('forgot-password-form:title')}
                    description={t('forgot-password-form:description')}>
                    <PasswordForgotForm
                        state={formState}
                        t={t}
                        resetPasswordSubmit={handleRequestResetPasswordSubmit}
                    />
                </TitleDescriptionWrapper>
            )}
        </OxygenTemplate>
    )
}

export default ForgotPassword
