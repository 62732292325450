import React, { useContext, useEffect, useState } from 'react'

import { useMutation } from '@apollo/react-hooks'
import { GraphQLError } from 'graphql'

import { ShopFeatures } from '@bc/config'
import graphqlUtils from '@bc/graphql-utils'
import { tagManager } from '@bc/gtm'
import {
    GQLLocation,
    GQLMaterial,
    GQLRequestQuoteInput,
    GQLRequestType,
    GTMBaseOrderData,
    QuotationGroup,
} from '@bc/types'
import { Button, Modal } from '@bc/ui/src'
import { QuoteOrderForm } from '@frontend/components/forms'
import { QuoteOrderFormValues } from '@frontend/components/forms/quote-order/quote-order-form.types'
import { useFormatMessage, useFormatUom, useHasAccess, useParseFloatNumber } from '@frontend/components/hooks'
import { PlatformConfigContext, ToastsContext, ToastsContextData, UserContext } from '@frontend/context'
import { requestQuote } from '@frontend/pages/my-orders/base/queries'

import { getCurrentTax, removeTypename } from '@frontend/utils'

import * as SharedFormComponents from '../forms/_shared'
import { isDeliveryDateValid } from '../forms/_shared/validation'
import {
    ModalGeneralConditions,
    ModalMaterialInfoColumn,
    ModalPriceConversionColumn,
    ModalTitle,
} from './modal-elements'

interface QuoteOrderModalProps {
    isOpen: boolean
    quote: QuotationGroup
    onClose: () => void
}

export const QuoteOrderModal = ({ quote, onClose, isOpen }: QuoteOrderModalProps) => {
    const t = useFormatMessage()
    const parseFloatNumber = useParseFloatNumber()
    const formatUom = useFormatUom()

    const { currentCustomer } = useContext(UserContext)
    const [toastsStore]: ToastsContextData = useContext(ToastsContext)
    const {
        appConfig: { deliveryDateRestrictions, dateFormat, taxes },
    } = useContext(PlatformConfigContext)
    const hasAccess = useHasAccess()

    const [step, setStep] = useState(1)
    const [formValues, setFormValues] = useState<Partial<QuoteOrderFormValues>>({})
    const { prices, material, shipToId } = quote
    const price = prices[0]
    const pickupAvailable = graphqlUtils.price.isPickupAvailable(price)

    const customerShipTos: GQLLocation[] = graphqlUtils.customer.getShipTos(currentCustomer)
    const [requestQuoteMutation, { loading: requestLoading }] = useMutation(requestQuote)

    const firstEligibleDeliveryDate: Date = graphqlUtils.order.getFirstEligibleDeliveryDate(
        deliveryDateRestrictions.reorderDate,
        hasAccess(ShopFeatures.NextDayDelivery),
    )
    const lastEligibleDeliveryDate: Date = graphqlUtils.order.getLastEligibleDeliveryDate(
        deliveryDateRestrictions.endDate,
    )
    const lastEligibleReorderDate: Date = graphqlUtils.order.getLastEligibleDeliveryDate(
        deliveryDateRestrictions.reorderEndDate,
    )
    const isReorderDateValid = (date: string): boolean =>
        isDeliveryDateValid(t, firstEligibleDeliveryDate, lastEligibleReorderDate, dateFormat, date)

    const confirmPrice = Boolean(
        (formValues.pickup && !pickupAvailable) ||
            (formValues.shipToId && pickupAvailable) ||
            (shipToId && formValues.shipToId !== shipToId) ||
            (formValues.requestedDeliveryDate && !isReorderDateValid(formValues.requestedDeliveryDate)) ||
            material.volatile,
    )
    const currentPrice = confirmPrice ? undefined : price

    useEffect(
        () => () => {
            resetModal()
        },
        [isOpen],
    )

    const resetModal = () => {
        setStep(1)
        setFormValues({})
    }

    const handleSubmit = async (newValues: QuoteOrderFormValues) => {
        try {
            await sendRequestQuoteMutation(newValues)
        } catch (e) {
            handleReorderMutationError(e)
        }
        resetAndCloseModal()
    }

    const sendRequestQuoteMutation = async (newValues: QuoteOrderFormValues) => {
        const { quantity, pieces, insufficientQuantity, ...quoteInput } = newValues
        const formPrices = confirmPrice || insufficientQuantity ? [] : removeTypename(prices)
        const values: GQLRequestQuoteInput = {
            ...quoteInput,
            requirements: ['quote' as GQLRequestType.QUOTE],
            quantity: parseFloatNumber(quantity),
            pieces: parseFloatNumber(pieces),
            prices: formPrices,
            uom: price.priceUnit?.uom ?? '',
            isBulk: Boolean(material?.isBulk),
        }

        const requestResult = await requestQuoteMutation({
            variables: {
                materialId: material?.id ?? '0',
                values,
            },
        })

        const uid = requestResult?.data?.requestQuote

        if (uid) {
            handlePurchase(uid, newValues)
        }

        handleReorderMutationSuccess()
    }

    const handleReorderMutationSuccess = () => {
        toastsStore.addToast({
            type: 'success',
            message: t('request-information-modal:confirmation'),
        })
    }

    const handleReorderMutationError = ({
        graphQLErrors,
        networkError,
    }: {
        graphQLErrors: GraphQLError[]
        networkError: any
    }) => {
        if (graphQLErrors.length) {
            graphQLErrors?.map(({ message = '' }: Partial<GraphQLError>) => {
                toastsStore.addToast({
                    message,
                    type: 'error',
                })
            })
        } else if (networkError) {
            toastsStore.addToast({
                message: networkError.message,
                type: 'error',
            })
        }
    }

    const getGTMECommerceData = (
        GTMQuote: QuotationGroup,
        values: QuoteOrderFormValues,
        GTMMaterial: GQLMaterial,
    ): GTMBaseOrderData => {
        const { customerPoReference, quantity = '0', requestedDeliveryDate, shipToId: formShipToId } = values
        const packagingName = GTMMaterial.packaging?.name
        const packagingUom = formatUom(GTMMaterial.packagingQuantity?.uom ?? 'KGM')
        const orderUom = formatUom(GTMQuote.uom)
        const orderQuantity = parseFloatNumber(quantity)
        const orderAmount = GTMMaterial ? orderQuantity / GTMMaterial.packagingQuantity.amount : 0
        const currency = price ? price.price.currency : undefined
        const pricePerUnit = price ? price.price.amount / (price.priceUnit?.amount || 1) : undefined
        const priceFinal = pricePerUnit ? parseFloat((pricePerUnit * orderQuantity).toFixed(2)) : undefined
        const taxPercentage = getCurrentTax(taxes) / 100
        const tax = priceFinal ? parseFloat((priceFinal * taxPercentage).toFixed(2)) : 0

        return {
            customerPoReference,
            requestedDeliveryDate,
            price: priceFinal,
            pricePerUnit,
            packagingName,
            packagingUom,
            orderUom,
            orderAmount,
            orderQuantity,
            currency,
            tax,
            shipToId: formShipToId,
            quoteId: GTMQuote.id,
        }
    }

    const handleFormStep = (formStep: number) => {
        setStep(formStep)
    }

    const handleAddToCart = () => {
        if (material) {
            tagManager.tags.quoteProductAddToCart(quote)
        }
    }

    const handleRemoveFromCart = () => {
        if (material) {
            tagManager.tags.quoteProductRemoveFromCart(quote)
        }
    }

    const handlePurchase = (uid: string, values: QuoteOrderFormValues) => {
        if (material) {
            tagManager.tags.basePurchase(uid, material, getGTMECommerceData(quote, values, material))
        }
    }

    const onCloseModal = () => {
        if (material && step === 3) {
            handleRemoveFromCart()
        }
        resetAndCloseModal()
    }

    const resetAndCloseModal = () => {
        resetModal()
        onClose()
    }

    return (
        <Modal
            id={'quote-order'}
            open={isOpen}
            onClose={onCloseModal}
            type="large"
            content={
                material && [
                    <ModalTitle
                        title={
                            step === 3
                                ? t('request-information-modal:request-quote-confirm')
                                : t('request-information-modal:request-quote')
                        }
                    />,
                    <ModalMaterialInfoColumn material={material} />,
                    currentPrice ? <ModalPriceConversionColumn price={currentPrice} /> : undefined,
                    <ModalGeneralConditions />,
                    <SharedFormComponents.ButtonsWrapper>
                        <Button
                            onClick={onCloseModal}
                            variant="outline"
                            title={t('general:cancel')}
                            data-test-id="button-quoteordermodal-cancel">
                            {t('general:cancel')}
                        </Button>
                    </SharedFormComponents.ButtonsWrapper>,
                ]
            }
            aside={
                isOpen
                    ? material && [
                          <QuoteOrderForm
                              onSubmit={handleSubmit}
                              onGTMAddToCart={handleAddToCart}
                              onGTMRemoveFromCart={handleRemoveFromCart}
                              onUpdateForm={setFormValues}
                              onStep={handleFormStep}
                              quote={quote}
                              confirmPrice={confirmPrice}
                              open={isOpen}
                              loading={requestLoading}
                              firstEligibleDeliveryDate={firstEligibleDeliveryDate}
                              lastEligibleDeliveryDate={lastEligibleDeliveryDate}
                              dateFormat={dateFormat}
                              customerShipTos={customerShipTos}
                              pickupAvailable={pickupAvailable}
                          />,
                      ]
                    : undefined
            }
        />
    )
}
