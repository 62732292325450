import React, { useContext } from 'react'

import styled from 'styled-components'

import { Button, colors, Modal, spacing, SvgIcon, Text } from '@bc/ui/src'
import { useFormatMessage } from '@frontend/components/hooks'
import { ShoppingCartContext, ToastsContext } from '@frontend/context'
import { GQLBaseOrderLine } from '@bc/types'
import { tagManager } from '@bc/gtm'

import * as SharedFormComponents from '../forms/_shared'
import { Item } from './change-quantity-modal'

interface RemoveFromModalProps {
    item: Item | undefined
    isOpen: boolean
    onClose: () => void
}

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${spacing.md}px;
    text-align: left;
`

export const IconWrapper = styled.span`
    display: inline-block;
    align-items: center;
    padding: 16px;
    border: 3px solid ${colors.red70};
    width: 80px;
    margin: ${spacing.md}px auto;
    height: 80px;
    border-radius: 40px;
`

export const RemoveFromModal = ({ item, onClose, isOpen }: RemoveFromModalProps) => {
    const t = useFormatMessage()
    const [shoppingStore] = useContext(ShoppingCartContext)
    const [toastsStore] = useContext(ToastsContext)

    if (!item) {
        return null
    }
    const { id, material } = item

    const handleRemove = async () => {
        try {
            if (shoppingStore) {
                const orderLine: GQLBaseOrderLine = shoppingStore.get(id)
                handleRemoveFromCart(orderLine)
                shoppingStore.remove(id)
                handleRemoveFromCartSuccess()
            }
        } catch (e) {
            onClose()
        }
    }

    const handleRemoveFromCartSuccess = () => {
        toastsStore?.addToast({
            type: 'success',
            message: t('request-information-modal:toasts-product-removed'),
        })
        onClose()
    }

    const handleRemoveFromCart = (orderLine: GQLBaseOrderLine) => {
        tagManager.tags.baseProductRemoveFromCart(orderLine)
    }

    return (
        <Modal
            id={'remove-item'}
            open={isOpen}
            onClose={onClose}
            type="dialog"
            color={colors.white}
            content={
                material && (
                    <>
                        <IconWrapper>
                            <SvgIcon size="40" icon="Delete" fill={colors.red70} />
                        </IconWrapper>
                        <TextWrapper>
                            <Text h3 noMargin={true}>
                                {t('request-information-modal:remove-from-cart')}
                            </Text>
                            <Text p>
                                {t('request-information-modal:remove-from-cart.instruction', undefined, {
                                    productName: material.name,
                                })}
                            </Text>
                        </TextWrapper>
                        <SharedFormComponents.ButtonsWrapper>
                            <Button
                                onClick={onClose}
                                icon="Close"
                                variant="outline"
                                title={t('general:cancel')}
                                data-test-id="button-removefromcart-form-cancel">
                                {t('general:cancel')}
                            </Button>
                            <Button
                                onClick={handleRemove}
                                icon="Delete"
                                variant="error"
                                title={t('request-information-modal:remove-from-cart')}
                                data-test-id="button-removefromcart-form-add">
                                {t('request-information-modal:remove-from-cart')}
                            </Button>
                        </SharedFormComponents.ButtonsWrapper>
                    </>
                )
            }
        />
    )
}
