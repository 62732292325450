import React from 'react'

import { colors } from '@bc/ui/src/config'

import { SvgIconMap } from '../types'

const defaultFill = colors.neutral50

export const status_document: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-document"
            d="M15.414,2H13v7h5v11H6V4h5V2H4v20h16V6.586L15.414,2z M15,7V4.414L17.586,7H15z M16,14H8v-2h8V14z M8,16h8v2H8
            V16z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-document"
            d="M15.414,2H13v7h5v11H6V4h5V2H4v20h16V6.586L15.414,2z M15,7V4.414L17.586,7H15z M16,14H8v-2h8V14z M8,16h8v2H8
            V16z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-document"
            d="M15.414,2H13v7h5v11H6V4h5V2H4v20h16V6.586L15.414,2z M15,7V4.414L17.586,7H15z M16,14H8v-2h8V14z M8,16h8v2H8
            V16z"
            transform="scale(0.666)"
        />
    ),
}
