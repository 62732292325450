import React from 'react'
import styled from 'styled-components'

import { GQLPackagingQuantity, GQLPrice } from '@bc/types'
import { colors, spacing, Text } from '@bc/ui'
import { useFormatCurrency, useFormatMessage, useFormatNumber, useFormatUom } from '@frontend/components/hooks'

interface PriceScaleWithPackagingProps {
    prices: GQLPrice[] | undefined
    packagingQuantity: GQLPackagingQuantity
    footNote?: boolean
}

interface PriceScaleProps {
    prices: GQLPrice[] | undefined
    uom?: string
    footNote?: boolean
}

const PriceScaleTable = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const PriceRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    &:not(:last-child) {
        padding-bottom: ${spacing.xxs / 5}px;
        border-bottom: 1px solid ${colors.neutral20};
    }
    &:not(:first-child) {
        padding-top: ${spacing.xxs / 5}px;
    }
`

const PriceColumn = styled.div`
    display: inline-flex;
    margin: 5px;

    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
`
const noPrices: GQLPrice[] = []
export const PriceScale = ({ prices = noPrices, uom = '', footNote = false }: PriceScaleProps) => {
    const t = useFormatMessage()
    const formatNumber = useFormatNumber()
    const formatUom = useFormatUom()
    const formatCurrency = useFormatCurrency()

    return (
        <PriceScaleTable>
            {prices.map(({ startsAt, price: { amount, currency }, priceUnit }, index) => (
                <PriceRow key={index}>
                    {/* If there is only one price and it is not "startsAt", then drop column name completely */}
                    {(startsAt || prices.length > 1) && (
                        <PriceColumn>
                            {startsAt ? (
                                <Text noMargin data-test-class="text-price-scale-from">
                                    {t('scale-prices:from')} {formatNumber(startsAt.amount)} {formatUom(startsAt.uom)}
                                </Text>
                            ) : (
                                <Text noMargin>{t('general:price')}</Text>
                            )}
                        </PriceColumn>
                    )}
                    <PriceColumn>
                        <Text noMargin data-test-class="text-price-scale-cost">
                            {formatCurrency(amount, {
                                currency,
                            })}{' '}
                            / {priceUnit && priceUnit.amount > 1 ? formatNumber(priceUnit.amount) : ''}{' '}
                            {formatUom(priceUnit?.uom ?? uom)}
                            {footNote && ' *'}
                        </Text>
                    </PriceColumn>
                </PriceRow>
            ))}
        </PriceScaleTable>
    )
}

export const PriceScaleWithPackaging = ({
    prices = noPrices,
    packagingQuantity,
    footNote = false,
}: PriceScaleWithPackagingProps) => <PriceScale prices={prices} uom={packagingQuantity?.uom} footNote={footNote} />

interface PriceScaleConfirmationProps {
    prices?: GQLPrice[]
}

export const PriceScaleConfirmation = ({ prices }: PriceScaleConfirmationProps) => {
    const t = useFormatMessage()

    return prices && prices.length > 0 ? (
        <>
            <PriceScale prices={prices} footNote />
            {'* '}
            {t('delivery-overview:legal-terms-price')}
        </>
    ) : (
        <Text>{t('general:price-confirmation')}</Text>
    )
}
