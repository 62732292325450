import { breakpoints } from '../config'
import { media } from './media'

export const hideOnMax = (hidden: keyof typeof breakpoints | undefined): string => {
    if (hidden) {
        return `
            ${media.max(hidden)} {
                display: none;
            };
        `
    }
    return ''
}
export const hideOnMin = (hidden: keyof typeof breakpoints | undefined): string => {
    if (hidden) {
        return `
            ${media.min(hidden)} {
                display: none;
            };
        `
    }
    return ''
}
