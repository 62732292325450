import { EventTag, VirtualPageviewTag } from '../types'
import { dataLayer } from '../utils'

export const invoiceFilterByPo = (name: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.invoicesSearchPo',
        virtualURL: `/invoicessearchpo?q=${name}&n=invoicessearchpobar`,
        virtualTitle: 'invoices - PO reference',
    })
}

export const invoiceFilterByInvoiceId = (name: string) => {
    dataLayer<VirtualPageviewTag>({
        event: 'gtm.invoicesSearchInvoiceNumber',
        virtualURL: `/invoicessearchinvoicenumber?q=${name}&n=invoicessearchinvoicenumberbar`,
        virtualTitle: 'invoices - search invoice number',
    })
}

const getMappedSort = (sort: string) => {
    let sortOrder = 'ascending'
    let sortType = sort

    if (sortType[0] === '-') {
        sortOrder = 'descending'
        sortType = sortType.substr(1)
    }

    return `${sortType}: ${sortOrder}`
}

export const invoicesSort = (sort: string) => {
    dataLayer<EventTag>({
        event: 'gtm.invoicesSort',
        eventCategory: 'invoices',
        eventAction: 'sort',
        eventLabel: getMappedSort(sort),
    })
}
