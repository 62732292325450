import { GQLDocumentType, GQLMaterial, GQLRequestMaterial } from '@bc/types'

import * as documentUtils from './document'

export const getCASNumbers = (material: GQLMaterial | undefined): string[] => material?.product?.CASNumbers ?? []

export const getEinecsNumber = (material: GQLMaterial | undefined): string[] => material?.product?.einecsNumbers ?? []

export const getPieces = (
    material: GQLMaterial,
    quantity: number,
    uom: string,
    uomConversionFactor: number = 1.0,
    palletSize?: number,
): number => {
    let pieces = 0
    switch (uom) {
        case 'PF': {
            pieces = (palletSize && palletSize * quantity) ?? 0
            break
        }
        case 'CT': {
            pieces = quantity
            break
        }
        default: {
            pieces = (quantity / material.packagingQuantity.amount) * uomConversionFactor
        }
    }
    return pieces
}

export const getPiecesRounded = (
    material: GQLMaterial,
    quantity: number,
    uom: string = '',
    uomConversionFactor: number = 1.0,
    palletSize?: number,
) => Math.ceil(getPieces(material, quantity, uom, uomConversionFactor, palletSize) || 1)

// @todo rewrite
export const getMsdsDocuments = (material: GQLMaterial | undefined) => {
    const documents = material?.product?.documents ?? []
    return documentUtils.getDocumentsByType(documents, GQLDocumentType.msds)
}

// @todo rewrite
export const getTdsDocuments = (material: GQLMaterial | undefined) => {
    const documents = material?.product?.documents ?? []
    return documentUtils.getDocumentsByType(documents, GQLDocumentType.tds)
}

export const getPackagingQuantityLabel = (
    material: GQLMaterial | GQLRequestMaterial,
    packagingQuantityAmount: string,
    uom: string,
) => {
    const packagingName = material.packaging?.name ?? ''
    return material.isBulk ? packagingName : `${packagingQuantityAmount} ${uom} / ${packagingName}`
}

export const getUom = (material: GQLMaterial | GQLRequestMaterial, uom?: string) =>
    uom ?? material.packagingQuantity.uom

export const getMinimumOrderQuantity = (material: GQLMaterial | undefined) => material?.minimumOrder?.amount

const allowDecimalsUoms = ['TNE', 'MTQ', 'KGW']
export const isUomAllowDecimals = (uom: string = '') => allowDecimalsUoms.includes(uom)
