import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'

import { QueryResult } from '@apollo/react-common'
import { useQuery } from '@apollo/react-hooks'

import debounce from 'debounce'

import { tagManager } from '@bc/gtm'
import { getQuotationConditions } from '@frontend/pages/quotes/queries'
import graphqlUtils from '@bc/graphql-utils'
import { GQLQuotation, GQLQuotations, QuotationFilters, QuotationGroup } from '@bc/types'
import { FullScreenLoaderRound, SelectInputOption } from '@bc/ui'
import { useCopyToClipboard, useFormatMessage } from '@frontend/components'
import { FiltersContextData, FiltersProvider, SortContextData, SortProvider, UserContext } from '@frontend/context'
import { arrayGroupBy } from '@frontend/utils'

import { useFilters } from '@frontend/components/hooks/use-filters'
import { QuotesPage } from './quotes-page'

type QuoteSortFunction = (q1: GQLQuotation, q2: GQLQuotation) => number
interface QuoteSortFunctionSelector {
    [key: string]: QuoteSortFunction
}

type QuotationPageQuery = QuotationFilters

// sorting functions
const sortByQuoteIdAsc: QuoteSortFunction = (q1: GQLQuotation, q2: GQLQuotation) => (q1.id < q2.id ? -1 : 1)
const sortByQuoteidDesc: QuoteSortFunction = (q1: GQLQuotation, q2: GQLQuotation) => (q2.id < q1.id ? -1 : 1)

const sortByMaterialNameAsc: QuoteSortFunction = (q1: GQLQuotation, q2: GQLQuotation) =>
    q1.material?.name?.localeCompare(q2.material?.name!) ?? 0
const sortByMaterialNameDesc: QuoteSortFunction = (q1: GQLQuotation, q2: GQLQuotation) =>
    q2.material?.name?.localeCompare(q1.material?.name!) ?? 0

const sortFunctions: QuoteSortFunctionSelector = {
    quoteId: sortByQuoteIdAsc,
    ['-quoteId']: sortByQuoteidDesc,
    materialName: sortByMaterialNameAsc,
    ['-materialName']: sortByMaterialNameDesc,
}

const initialPage = 1
const initialSort = 'materialName'
const initialFilters: QuotationFilters = {}

const QuotesPageContainer = () => {
    let debounceofferId: any
    let debounceMaterialName: any

    const t = useFormatMessage()
    const { currentCustomer, activeCustomerId = '', activeSalesOrganisation = '', activeSalesAreaId = '' } = useContext(
        UserContext,
    )
    const copyToClipboard = useCopyToClipboard()

    const { filters, sort = initialSort, updateFilters } = useFilters<QuotationFilters, QuotationPageQuery>()

    const shipTos = graphqlUtils.customer.getShipTos(currentCustomer)
    const variables = {
        customerId: currentCustomer?.id,
        shipToId: '', // always get all quotes and filter locally
        materialId: '',
        salesOrganisation: activeSalesOrganisation,
        salesChannel: activeSalesAreaId?.slice(-4, -2),
        salesDivision: activeSalesAreaId?.slice(-2),
    }

    const { error, loading, data }: QueryResult<{ quotationConditions: GQLQuotations }> = useQuery(
        getQuotationConditions,
        {
            variables,
            ssr: false,
            fetchPolicy: 'cache-first',
            skip: typeof currentCustomer?.id === 'undefined',
        },
    )

    // filtering functions
    const filterByQuoteId = (q: GQLQuotation) => (filters.quoteId ? q.id.includes(filters.quoteId) : true)
    const filterByMaterialName = (q: GQLQuotation) =>
        filters.materialName
            ? Boolean(q.material?.name?.toLowerCase().includes(filters.materialName.toLowerCase()))
            : true
    const filterByShipToId = (q: GQLQuotation) =>
        filters.shipToId ? q.shipToId === '' || q.shipToId === filters.shipToId : true

    const quotations: GQLQuotation[] | undefined = data?.quotationConditions?.quotations
        .filter(filterByQuoteId)
        .filter(filterByMaterialName)
        .filter(filterByShipToId)
        .sort(sortFunctions[sort])

    const quotationGroups: QuotationGroup[] | undefined = quotations
        ? arrayGroupBy<GQLQuotation>(quotations, 'conditionId').map(
              (group: GQLQuotation[]) =>
                  group.reduce((quotationGroup: QuotationGroup | Record<string, unknown>, quotation: GQLQuotation) => {
                      const { price, ...restQuotation } = quotation
                      const { prices = [] } = quotationGroup as QuotationGroup
                      prices.push(price)
                      return { ...restQuotation, prices }
                  }, {}) as QuotationGroup,
          )
        : undefined

    const handleSortChange = (newSort: SelectInputOption) => {
        const { value } = newSort
        tagManager.tags.quotesSort(value)
        updateFilters({ ...filters, sort: value, page: initialPage })
    }
    const quoteIdFilter = (filter: string) => {
        if (debounceofferId) {
            debounceofferId.clear()
        }
        debounceofferId = debounce(() => tagManager.tags.quotesFilterByQuoteId(filter), 1000)
        debounceofferId()
    }
    const materialNameFilter = (filter: string) => {
        if (debounceMaterialName) {
            debounceMaterialName.clear()
        }

        debounceMaterialName = debounce(() => tagManager.tags.quotesFilterByMaterialName(filter), 1000)
        debounceMaterialName()
    }
    const shipToIdFilter = (filter: string) => {
        const filterAddress = shipTos.find(shipToLocation => shipToLocation.id === filter)
        if (filterAddress) {
            tagManager.tags.quotesFilterByAddress(filterAddress)
        }
    }
    const handleFilterChange = (newFilters: QuotationFilters) => {
        if (newFilters.quoteId && newFilters.quoteId !== filters.quoteId) {
            quoteIdFilter(newFilters.quoteId)
        }
        if (newFilters.materialName && newFilters.materialName !== filters.materialName) {
            materialNameFilter(newFilters.materialName)
        }

        if (newFilters.shipToId && newFilters.shipToId !== filters.shipToId) {
            shipToIdFilter(newFilters.shipToId)
        }

        updateFilters({ ...newFilters, sort, page: initialPage })
    }

    const handleCopyQuoteLink = (_formFilters: QuotationFilters) => {
        copyToClipboard(
            `${window.location.href}&cid=${activeCustomerId}&so=${activeSalesOrganisation}&sid=${activeSalesAreaId}`,
        )
    }

    const sortOptions: SelectInputOption[] = [
        {
            value: 'quoteId',
            label: `${t('sort-by-offer-number')} ${t('sort-direction:numeric:asc')}`,
        },
        {
            value: '-quoteId',
            label: `${t('sort-by-offer-number')} ${t('sort-direction:numeric:desc')}`,
        },
        {
            value: 'materialName',
            label: `${t('sort-by-material-name')} ${t('sort-direction:string:asc')}`,
        },
        {
            value: '-materialName',
            label: `${t('sort-by-material-name')} ${t('sort-direction:string:desc')}`,
        },
    ]

    const filterProviderProps: FiltersContextData<QuotationFilters> = {
        onFilterChange: handleFilterChange,
        filters: initialFilters,
        query: filters,
        onLinkCopy: handleCopyQuoteLink,
    }
    const sortProviderProps: SortContextData = {
        onSortChange: handleSortChange,
        sort,
        sortOptions,
    }

    return (
        <FiltersProvider {...filterProviderProps}>
            <SortProvider {...sortProviderProps}>
                <FullScreenLoaderRound isVisible={loading} />
                <QuotesPage error={error} shipTos={shipTos} quotations={quotationGroups} />
            </SortProvider>
        </FiltersProvider>
    )
}

export default withRouter(QuotesPageContainer)
