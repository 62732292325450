import React from 'react'

import { Button } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'

interface Props {
    onClick(): void
}

export const FilterResetButton = ({ onClick }: Props) => {
    const t = useFormatMessage()
    return (
        <Button
            icon="Close"
            variant="outline"
            onClick={onClick}
            title={t('filters:clear-filters')}
            data-test-id="button-filter-reset">
            {t('filters:clear-filters')}
        </Button>
    )
}
