import React from 'react'
import styled from 'styled-components'

import { LoaderRound } from '@bc/ui/src/components/loader'

const LoaderWrapper = styled.div`
    position: absolute;
    overflow: hidden;
`

export const ButtonLoader: React.SFC = () => (
    <LoaderWrapper>
        <LoaderRound />
    </LoaderWrapper>
)
