import React, { useContext } from 'react'

import { Resources, ShopFeatures } from '@bc/config'
import graphqlUtils from '@bc/graphql-utils'
import { tagManager } from '@bc/gtm'
import { MessageMap } from '@bc/translations'
import {
    GQLBaseOrder,
    GQLBaseOrderLine,
    GQLBaseOrderStatus,
    GQLBaseUnitPrice,
    GQLPrice,
    GQLReOrderAvailability,
    GQLBaseOrderFilters,
} from '@bc/types'
import {
    Address,
    AddToCartButton,
    Button,
    capitalizeFirstLetter,
    CardEnriched,
    colors,
    SvgIcon,
    Text,
    TextHighlight,
    RichRowListItemProps,
} from '@bc/ui/'
import {
    FormatDate,
    FormatMessage,
    useFormatCurrency,
    useFormatDate,
    useFormatMessage,
    useFormatNumber,
    useFormatPackaging,
    useFormatUom,
} from '@frontend/components/hooks'
import { PlatformConfigContext, FiltersContextData, FiltersContext } from '@frontend/context'
import { statusAsColor, statusAsIconName, statusAsTranslationKey, trimLeadingZeros } from '@frontend/utils'

import { ButtonMobileWrapper } from '@bc/ui/src/components/card/card-components'
import { Feature } from '../customer'
import { FilterCopyLinkButton } from '../filters/filter-copy-link-button'
import { ListItemMetaProps } from './list-item-meta-props'
import { MaterialPackaging } from './material-packaging'
import { OrderLines } from './order-lines'
import { OrderLines as OrderLinesTwoLine } from './order-lines-two-lines'

interface OrderCardBaseProps {
    order: GQLBaseOrder
    onOpenConfirmation: (uri: string) => void
    onLinkCopy?: (orderId: string) => void
    reorder: (order: GQLBaseOrder, orderLine: GQLBaseOrderLine) => void
}

const generatedDeliveryDate = (
    formatDate: FormatDate,
    t: FormatMessage,
    deliveryDateFrom: string | undefined,
    deliveryDateTo?: string | undefined,
) => {
    let message = { label: t('orders:column.delivery-date'), value: '-' }

    if (deliveryDateFrom && deliveryDateTo) {
        message =
            deliveryDateFrom !== deliveryDateTo
                ? {
                      label: t('orders:column.delivery-date-from-to'),
                      value: `${formatDate(deliveryDateFrom)} - ${formatDate(deliveryDateTo)}`,
                  }
                : {
                      label: t('orders:column.delivery-date'),
                      value: `${formatDate(deliveryDateFrom)}`,
                  }
    }

    return message
}
export const OrderCardBase = ({ onOpenConfirmation, onLinkCopy, order, reorder }: OrderCardBaseProps) => {
    const {
        id,
        orderDate,
        requestedDeliveryDate,
        shipTo,
        orderLines,
        orderStatus,
        customerReference,
        document,
        priceConditions,
    } = order
    const formatDate = useFormatDate()
    const formatNumber = useFormatNumber()
    const formatCurrency = useFormatCurrency()
    const t = useFormatMessage()
    const formatPackaging = useFormatPackaging()
    const formatUom = useFormatUom()
    const { shopConfig } = useContext(PlatformConfigContext)
    const filterContext: FiltersContextData<GQLBaseOrderFilters> = useContext(FiltersContext)
    const { customerPoReference: filterCustomerPoReference, materialName: filterMaterialName, orderId: filterOrderId } =
        filterContext?.query ?? {}

    const useBnlOrderLines = shopConfig.current?.features.UseTwoLineOrderlines || false

    const formattedOrderDate = orderDate ? formatDate(orderDate) : ''
    const formattedDeliveryDate = requestedDeliveryDate ? formatDate(requestedDeliveryDate) : ''

    const shipToElement = order?.shipTo && <Address baseAddress={shipTo} />
    const pickupAvailable = graphqlUtils.price.isPickupAvailable(priceConditions as GQLPrice)
    const formattedCustomerReference = customerReference ? customerReference : '-'

    const openConfirmation = (): void => {
        if (document?.uri && onOpenConfirmation) {
            onOpenConfirmation(document.uri)
            tagManager.tags.documentDownload('order confirmation', id)
        }
    }

    const list: RichRowListItemProps[] = [
        {
            label: t('orders:column.po-reference-abbreviation'),
            value: formattedCustomerReference,
            id: 'po-reference',
            highlight: filterCustomerPoReference,
        },
        {
            label: t('orders:column.order-date'),
            value: formattedOrderDate,
            id: 'order-date',
        },

        {
            label: t('orders:column.requested-delivery-date'),
            value: formattedDeliveryDate,
            id: 'delivery-date',
        },
        {
            label: t('orders:column.delivery-address'),
            value: pickupAvailable ? t('request-information-modal:reorder:self-pickup') : shipToElement,
            id: 'ship-to-address',
        },
    ]

    // @todo this needs to be rewritten
    const getOrderLinesItems = (orderLine: GQLBaseOrderLine, position: number) => {
        const {
            material,
            orderStatus: orderLineOrderStatus,
            quantityOrdered,
            uom,
            uomConversionFactor,
            deliveryDateFrom,
            deliveryDateTo,
            reOrderAvailable,
        } = orderLine

        const isBulk = material?.isBulk
        const pieces =
            graphqlUtils.material.getPiecesRounded(
                material!,
                quantityOrdered?.amount,
                uom,
                uomConversionFactor,
                material?.palletSize,
            ) ?? 0
        const status = t(statusAsTranslationKey(orderLineOrderStatus))
        const packagingNameQuantity = formatPackaging(quantityOrdered.amount, material?.packaging?.type)
        const packagingNamePieces = formatPackaging(pieces, material?.packaging?.type)
        const materialAndStatusField = {
            label: <TextHighlight search={filterMaterialName}>{material?.name || '-'}</TextHighlight>,
            icon: (
                <Text p color={statusAsColor(orderLineOrderStatus)} noMargin data-test-class="text-order-status">
                    <MaterialPackaging material={material} />
                    <SvgIcon
                        icon={statusAsIconName(orderLineOrderStatus)}
                        fill={colors[statusAsColor(orderLineOrderStatus)]}
                        title={status}
                        size="12"
                    />{' '}
                    {capitalizeFirstLetter(status)}
                </Text>
            ),
        }

        // @todo needs more input and generic fix
        const quantityField = {
            label: t('order:line-item.order-quantity'),
            value: quantityOrdered
                ? `${formatNumber(quantityOrdered.amount)} ${
                      quantityOrdered.uom === 'CT' ? packagingNameQuantity : formatUom(quantityOrdered.uom)
                  }`
                : '-',
        }

        // @todo needs more input and generic fix
        const piecesfield = {
            label: t('general:pieces'),
            value: `${pieces && formatNumber(pieces)} ${isBulk ? formatUom(uom) : packagingNamePieces}`,
        }

        const fields = [materialAndStatusField, quantityField]

        if (!isBulk) {
            fields.push(piecesfield)
        }

        if (useBnlOrderLines) {
            fields.push(generatedDeliveryDate(formatDate, t, deliveryDateFrom, deliveryDateTo))
        }

        const openReorderModal = () => {
            reorder(order, orderLine)
            tagManager.tags.baseProductClick(orderLine, position)
        }

        const disableReorderButton = reOrderAvailable !== GQLReOrderAvailability.available

        return [
            ...fields,
            {
                value: material ? (
                    <Feature
                        flag={Resources.Checkout}
                        on={
                            <Feature
                                flag={ShopFeatures.ShoppingCart}
                                on={
                                    <AddToCartButton
                                        disabled={disableReorderButton}
                                        onClick={openReorderModal}
                                        tooltipText={
                                            disableReorderButton
                                                ? t(
                                                      `request-information-modal:reorder:disabled-${orderLine.reOrderAvailable}` as keyof MessageMap,
                                                  )
                                                : undefined
                                        }>
                                        {t('request-information-modal:add-to-cart')}
                                    </AddToCartButton>
                                }
                                off={
                                    <Button
                                        disabled={disableReorderButton}
                                        variant="action"
                                        data-test-id="button-reorder"
                                        onClick={openReorderModal}
                                        tooltipText={
                                            disableReorderButton
                                                ? t(
                                                      `request-information-modal:reorder:disabled-${orderLine.reOrderAvailable}` as keyof MessageMap,
                                                  )
                                                : undefined
                                        }>
                                        {t('request-information-modal:reorder')}
                                    </Button>
                                }
                            />
                        }
                    />
                ) : undefined,
            },
        ]
    }

    // @todo this needs to be rewritten!
    const orderOrderLines = orderLines
        ?.filter(orderLine => orderLine.material)
        .map((orderLine, position) => {
            const listItems: ListItemMetaProps[] = getOrderLinesItems(orderLine, position)

            // Price is an optional field, if there is no data , do not show this field
            const formatUnitPrice = (unitPrice?: GQLBaseUnitPrice) => {
                if (unitPrice?.price || unitPrice?.quantity) {
                    const { currency, price, quantity, uom = '' } = unitPrice
                    return `${
                        (price &&
                            formatCurrency(price, {
                                currency,
                            })) ||
                        '-'
                    } / ${quantity} ${formatUom(uom)}`
                }
                return undefined
            }
            const formattedUnitPrice: string | undefined = formatUnitPrice(orderLine.unitPrice)

            if (formattedUnitPrice) {
                const unitPriceField = {
                    label: `${t('general:unit-price')}`,
                    value: formattedUnitPrice,
                }
                listItems.splice(1, 0, unitPriceField)
            }

            return listItems
        })

    const showDownloadButton = orderStatus !== GQLBaseOrderStatus.processed && Boolean(document?.uri)

    return (
        <CardEnriched
            header={{
                title: (
                    <TextHighlight search={filterOrderId}>{`${t('general:order-id')} ${trimLeadingZeros(
                        id,
                    )}`}</TextHighlight>
                ),
                icon: orderStatus && (
                    <SvgIcon
                        icon={statusAsIconName(orderStatus)}
                        title={t(statusAsTranslationKey(orderStatus))}
                        fill={colors[statusAsColor(orderStatus)]}
                        size="32"
                    />
                ),
            }}
            data-test-id={`card-order-${trimLeadingZeros(id)}`}
            data-test-class={`card-order`}
            actions={
                <>
                    {onLinkCopy && <FilterCopyLinkButton onClick={() => onLinkCopy(id)} />}

                    <Feature
                        flag={ShopFeatures.DownloadOrderDocument}
                        on={
                            showDownloadButton ? (
                                <ButtonMobileWrapper>
                                    <Button
                                        fullOnMobile
                                        icon="File_Text"
                                        variant="outline"
                                        title={t('orders:button.confirmation')}
                                        onClick={openConfirmation}
                                        data-test-id="button-order-confirmation">
                                        {t('orders:button.confirmation')}
                                    </Button>
                                </ButtonMobileWrapper>
                            ) : undefined
                        }
                    />
                </>
            }
            list={list}>
            {orderOrderLines && (
                <Feature
                    flag={ShopFeatures.UseTwoLineOrderlines}
                    on={<OrderLinesTwoLine list={orderOrderLines} />}
                    off={<OrderLines list={orderOrderLines} />}
                />
            )}
        </CardEnriched>
    )
}
