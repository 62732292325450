import { diff } from 'deep-object-diff'
import React from 'react'
import { FormSpy, FormSpyProps, FormSpyRenderProps } from 'react-final-form'
import { debounce } from 'throttle-debounce'

export interface AutoSubmitHandlerProps {
    submit(values: any): void
    debounceTime?: number
}

type AutoSubmitProps = AutoSubmitHandlerProps & FormSpyProps & FormSpyRenderProps

class AutoSubmitHandler extends React.Component<AutoSubmitProps> {
    public componentDidUpdate(prevProps: AutoSubmitProps) {
        const difference = diff(this.props.values, prevProps.values)

        if (Object.keys(difference).length) {
            this.submit()
        }
    }

    public handleSubmit = () => {
        const { values, submit } = this.props
        submit(values)
    }

    public submit = debounce(this.props.debounceTime ? this.props.debounceTime : 200, this.handleSubmit)

    public render = () => null
}

export const AutoSubmit = (autoSubmitProps: AutoSubmitHandlerProps) => (
    <FormSpy
        subscription={{ active: true, values: true }}
        render={formSpyProps => <AutoSubmitHandler {...autoSubmitProps} {...formSpyProps} />}
    />
)
