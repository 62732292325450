import React from 'react'

import { CountDownButton, EmptyState, OxygenTemplate } from '@bc/ui'
import { FormatMessage } from '@frontend/components'

interface SetPasswordSuccessProps {
    t: FormatMessage
    onClick: () => void
}
export const SetPasswordSuccessPage = ({ t, onClick }: SetPasswordSuccessProps): JSX.Element => (
    <OxygenTemplate>
        <EmptyState title={t('set-password-form:success-title')} text={t('set-password-form:success')}>
            <CountDownButton
                countdown={30}
                onEnd={onClick}
                type="submit"
                variant="action"
                data-test-id="button-countdown-login"
                title={t('login:back-to-login')}>
                {t('login:back-to-login')}
            </CountDownButton>
        </EmptyState>
    </OxygenTemplate>
)

// 'login:back-to-login'
