import React from 'react'

import { colors } from '@bc/ui/src/config'

import * as SquareListComponents from './square-list-components'

export interface SquareListProps {
    items: string[]
    dotColor?: keyof typeof colors
    color?: keyof typeof colors
}

export const SquareList: React.SFC<SquareListProps> = ({ color, items = [] }) => (
    <SquareListComponents.List>
        {items.map((item: string, index: number) => (
            <SquareListComponents.ListItem color={color} key={`${item}-${index}`}>
                {item}
            </SquareListComponents.ListItem>
        ))}
    </SquareListComponents.List>
)
