import React from 'react'

import { colors } from '@bc/ui/src/config'
import { SvgIconMap } from '../types'

const defaultFill = colors.green70

export const status_invoiced: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-invoiced"
            d="M20,4H6v16h12V6h2v16H4V2h16V4z M11.429,10H15V8h-2V6h-2v2.043c-1.022,0.201-1.796,1.102-1.796,2.181v0.204
            c0,0.854,0.5,1.645,1.274,2.012l2.392,1.131c0.079,0.037,0.13,0.117,0.13,0.204C13,13.899,12.899,14,12.775,14H9v2h2v2h2v-2.023
            c1.12-0.115,2-1.052,2-2.202c0-0.854-0.499-1.644-1.273-2.012l-2.393-1.131c-0.08-0.038-0.13-0.116-0.13-0.204v-0.204
            C11.204,10.101,11.305,10,11.429,10z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-invoiced"
            d="M20,4H6v16h12V6h2v16H4V2h16V4z M11.429,10H15V8h-2V6h-2v2.043c-1.022,0.201-1.796,1.102-1.796,2.181v0.204
            c0,0.854,0.5,1.645,1.274,2.012l2.392,1.131c0.079,0.037,0.13,0.117,0.13,0.204C13,13.899,12.899,14,12.775,14H9v2h2v2h2v-2.023
            c1.12-0.115,2-1.052,2-2.202c0-0.854-0.499-1.644-1.273-2.012l-2.393-1.131c-0.08-0.038-0.13-0.116-0.13-0.204v-0.204
            C11.204,10.101,11.305,10,11.429,10z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-invoiced"
            d="M20,4H6v16h12V6h2v16H4V2h16V4z M11.429,10H15V8h-2V6h-2v2.043c-1.022,0.201-1.796,1.102-1.796,2.181v0.204
            c0,0.854,0.5,1.645,1.274,2.012l2.392,1.131c0.079,0.037,0.13,0.117,0.13,0.204C13,13.899,12.899,14,12.775,14H9v2h2v2h2v-2.023
            c1.12-0.115,2-1.052,2-2.202c0-0.854-0.499-1.644-1.273-2.012l-2.393-1.131c-0.08-0.038-0.13-0.116-0.13-0.204v-0.204
            C11.204,10.101,11.305,10,11.429,10z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-invoiced"
            d="M20,4H6v16h12V6h2v16H4V2h16V4z M11.429,10H15V8h-2V6h-2v2.043c-1.022,0.201-1.796,1.102-1.796,2.181v0.204
            c0,0.854,0.5,1.645,1.274,2.012l2.392,1.131c0.079,0.037,0.13,0.117,0.13,0.204C13,13.899,12.899,14,12.775,14H9v2h2v2h2v-2.023
            c1.12-0.115,2-1.052,2-2.202c0-0.854-0.499-1.644-1.273-2.012l-2.393-1.131c-0.08-0.038-0.13-0.116-0.13-0.204v-0.204
            C11.204,10.101,11.305,10,11.429,10z"
            transform="scale(0.5)"
        />
    ),
}
