import React from 'react'

import styled from 'styled-components'

import { WelcomeVideoDescription } from '@bc/config'
import { ButtonAnchor } from '@bc/ui'
import { useFormatMessage } from '../hooks'

interface WelcomeVideoListProps {
    list: WelcomeVideoDescription[] | undefined
    onClick: (video: WelcomeVideoDescription) => void
}

const VideoLink = styled(ButtonAnchor)`
    justify-content: flex-start;
`

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
`

export const WelcomeVideoList = ({ list, onClick }: WelcomeVideoListProps) => {
    const t = useFormatMessage()
    return (
        <ListWrapper>
            {list?.map(video => (
                <VideoLink key={video.url} icon="Social_Youtube" onClick={() => onClick(video)} href="#">
                    {t(`welcome-video-title:${video.key}`)}
                </VideoLink>
            ))}
        </ListWrapper>
    )
}
