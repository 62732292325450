import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Phone: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Phone" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M22 22H19.5C9.85 22 2 14.15 2 4.5V2H9.57L13.26 8.16L10.19 11.23C10.91 12.22 11.79 13.1 12.78 13.82L15.85 10.75L22 14.44V18.01H20V15.58L16.15 13.27L13.04 16.38L12.37 15.96C10.63 14.87 9.14 13.38 8.05 11.64L7.63 10.97L10.74 7.86L8.43 4.02H4V4.52C4 13.07 10.95 20.02 19.5 20.02H22V22.02V22Z"
                fill={fill === 'gradient' ? 'url(#Phone_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Phone" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Phone" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M22 22H19.5C9.85 22 2 14.15 2 4.5V2H9.57L13.26 8.16L10.19 11.23C10.91 12.22 11.79 13.1 12.78 13.82L15.85 10.75L22 14.44V18.01H20V15.58L16.15 13.27L13.04 16.38L12.37 15.96C10.63 14.87 9.14 13.38 8.05 11.64L7.63 10.97L10.74 7.86L8.43 4.02H4V4.52C4 13.07 10.95 20.02 19.5 20.02H22V22.02V22Z"
                fill={fill === 'gradient' ? 'url(#Phone_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Phone" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Phone" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M22 22H19.5C9.85 22 2 14.15 2 4.5V2H9.57L13.26 8.16L10.19 11.23C10.91 12.22 11.79 13.1 12.78 13.82L15.85 10.75L22 14.44V18.01H20V15.58L16.15 13.27L13.04 16.38L12.37 15.96C10.63 14.87 9.14 13.38 8.05 11.64L7.63 10.97L10.74 7.86L8.43 4.02H4V4.52C4 13.07 10.95 20.02 19.5 20.02H22V22.02V22Z"
                fill={fill === 'gradient' ? 'url(#Phone_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Phone" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Phone" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M22 22H19.5C9.85 22 2 14.15 2 4.5V2H9.57L13.26 8.16L10.19 11.23C10.91 12.22 11.79 13.1 12.78 13.82L15.85 10.75L22 14.44V18.01H20V15.58L16.15 13.27L13.04 16.38L12.37 15.96C10.63 14.87 9.14 13.38 8.05 11.64L7.63 10.97L10.74 7.86L8.43 4.02H4V4.52C4 13.07 10.95 20.02 19.5 20.02H22V22.02V22Z"
                fill={fill === 'gradient' ? 'url(#Phone_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Phone" />}
        </g>
    ),
}
