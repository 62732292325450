import React, { useState, useContext } from 'react'

import { ApolloError } from 'apollo-client'

import { ProductView } from '@bc/config'
import { GQLMaterial, GQLPagination, MaterialsFilters } from '@bc/types'
import { FullWidthTitleNotificationTemplate, MercuryTemplate, Paginator, useDocumentTitle } from '@bc/ui'
import { ProductCard, ProductFilters, QueryResultList, SortSelectWrapper, useFormatMessage } from '@frontend/components'
import { RequestInformationModal } from '@frontend/components/modals'
import { ProductsTable } from '@frontend/components/table/products-table'
import { getFilterCount, FiltersContextData, FiltersContext } from '@frontend/context'

interface ProductListPageProps {
    error: ApolloError | undefined
    onPageClick: (page: number) => void
    materials: GQLMaterial[] | undefined
    pagination: GQLPagination
    view: ProductView
}

export const ProductListPage = ({ error, onPageClick, materials, pagination, view }: ProductListPageProps) => {
    const t = useFormatMessage()
    const pageTitle = t('product-list:headline', undefined, { count: pagination.count })
    useDocumentTitle(pageTitle)

    const filterContext: FiltersContextData<MaterialsFilters> = useContext(FiltersContext)

    const [modalOpen, setModalOpen] = useState(false)
    const [selectedMaterial, setSelectedMaterial] = useState<GQLMaterial | undefined>()

    const onModalClose = () => {
        setModalOpen(false)
        setSelectedMaterial(undefined)
    }

    const handleRequest = (requestedMaterial: GQLMaterial | undefined) => {
        setSelectedMaterial(requestedMaterial)
        setModalOpen(true)
    }

    return (
        <>
            <MercuryTemplate title={t('general:filters')} filterCount={getFilterCount(filterContext?.query)}>
                {{
                    sidebarContent: <ProductFilters />,
                    content: (
                        <FullWidthTitleNotificationTemplate title={pageTitle}>
                            {view === ProductView.CARDS ? (
                                <>
                                    <SortSelectWrapper />
                                    <QueryResultList
                                        error={error}
                                        amountOfResults={materials?.length}
                                        noResultText={t('not-found:orders')}>
                                        {materials?.map(material => (
                                            <ProductCard key={material.id} material={material} />
                                        ))}
                                    </QueryResultList>
                                </>
                            ) : (
                                <ProductsTable
                                    error={error}
                                    materials={materials}
                                    list="product list"
                                    onRequest={handleRequest}
                                />
                            )}
                            {!error && (
                                <Paginator
                                    maxPage={pagination.pages}
                                    currentPage={pagination.page}
                                    onPageClick={onPageClick}
                                />
                            )}
                        </FullWidthTitleNotificationTemplate>
                    ),
                }}
            </MercuryTemplate>
            <RequestInformationModal material={selectedMaterial} isOpen={modalOpen} onClose={onModalClose} />
        </>
    )
}
