import React from 'react'
import styled from 'styled-components'

import { Button } from '@bc/ui/src/components/button/button'
import { ButtonProps } from '@bc/ui/src/components/button/button-types'
import { spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'

const ResponsiveButtonComponent = styled(Button)`
    margin-left: ${spacing.xxs}px;
    ${media.max('md')} {
        svg {
            margin-right: 0;
        }
    }
    ${media.max('sm')} {
        margin-left: 0;
        width: 100%;
    }
    ${media.min('lg')} {
        max-width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
`

const ResponsiveButtonLabel = styled.span`
    ${media.max('md')} {
        display: none;
    }
    white-space: nowrap;
`

export const ResponsiveButton: React.FC<ButtonProps & JSX.IntrinsicElements['button']> = ({
    children,
    ref: _ref,
    ...rest
}) => (
    <ResponsiveButtonComponent {...rest}>
        {children && <ResponsiveButtonLabel>{children}</ResponsiveButtonLabel>}
    </ResponsiveButtonComponent>
)
