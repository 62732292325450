import React from 'react'

import { PageTitle } from '@bc/ui/src/components/page-title'

import { NotificationBanner } from '../../../../frontend/src/components/notifications'
import { FullWidthTemplate } from './full-width-tpl'

interface FullWidthTitleNotificationTemplateProps {
    children?: any
    title: string
}

export const FullWidthTitleNotificationTemplate = (props: FullWidthTitleNotificationTemplateProps) => (
    <FullWidthTemplate>
        <PageTitle title={props.title} />
        <NotificationBanner />
        {props.children}
    </FullWidthTemplate>
)
