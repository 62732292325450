import React from 'react'

import { WelcomeVideoDescription } from '@bc/config'
import { colors, Modal, Text } from '@bc/ui'

import { useFormatMessage } from '../hooks'

interface VideoPlayerProps {
    open: boolean
    onClose?: () => void
    video?: WelcomeVideoDescription
    width?: number
    height?: number
}

export const VideoPlayer = ({ open, onClose, video, width = 680, height = (width / 16) * 9 }: VideoPlayerProps) => {
    const t = useFormatMessage()
    return (
        <Modal
            id={'welcome-video-modal'}
            open={open}
            onClose={onClose}
            type="small"
            color={colors.white}
            content={[
                <Text h3 key="text">
                    {video?.key && t(`welcome-video-title:${video?.key}`)}
                </Text>,
                <iframe
                    key="frame"
                    src={video?.url}
                    width={width}
                    height={height}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    seamless
                />,
            ]}
        />
    )
}
