import React, { useEffect, useMemo, useState } from 'react'

import { debug } from '@bc/logging'
import { FormState } from 'final-form'
import { Form, FormSpy } from 'react-final-form'

import { DateFormat } from '@bc/config'
import { Button } from '@bc/ui'
import graphqlUtils from '@bc/graphql-utils'
import { GQLBaseOrderLine, GQLLocation, GQLMaterial, GQLPrice } from '@bc/types'
import { useFormatMessage, useFormatNumber, useParseFloatNumber } from '@frontend/components/hooks'
import { toLocalizedInputNumber } from '@frontend/utils'

import * as SharedFormComponents from '../_shared'
import { ReorderFormFieldsStep1 } from './reorder-form-fields-step1'
import { ReorderFormFieldsStep2 } from './reorder-form-fields-step2'
import { ReorderFormSummary } from './reorder-form-summary'
import { ReorderFormValues } from './reorder-form.types'

export interface ReorderFormProps {
    onSubmit: (values: ReorderFormValues) => void
    onUpdateForm: (values: ReorderFormValues) => void
    onGTMAddToCart: (values: ReorderFormValues) => void
    onGTMRemoveFromCart: () => void
    onStep: (step: number) => void
    orderLine: GQLBaseOrderLine
    material: GQLMaterial
    loading: boolean
    open: boolean
    firstEligibleDeliveryDate: Date
    lastEligibleDeliveryDate: Date
    dateFormat: DateFormat
    customerShipTos: GQLLocation[]
    prices: GQLPrice[][]
    pickupAvailable: boolean
    orderShipTo?: string
}

let resetForm: any
const formActions: SharedFormComponents.FormActions = {}
const shipToDecorator = SharedFormComponents.createPickupDecorator('shipToId')

export const ReorderForm = ({
    orderLine,
    material,
    open,
    onSubmit,
    onStep,
    onGTMAddToCart,
    onGTMRemoveFromCart,
    loading,
    firstEligibleDeliveryDate,
    lastEligibleDeliveryDate,
    dateFormat,
    customerShipTos,
    onUpdateForm,
    prices,
    pickupAvailable,
    orderShipTo,
}: ReorderFormProps) => {
    const [step, setStep] = useState<number>(1)
    const t = useFormatMessage()
    const formatNumber = useFormatNumber()
    const parseFloatNumber = useParseFloatNumber()

    useEffect(() => {
        if (resetForm && !open) {
            resetForm()
        }
    }, [open])

    const { quantityOrdered, uom, uomConversionFactor } = orderLine

    const pieces = graphqlUtils.material.getPiecesRounded(
        material,
        quantityOrdered.amount,
        uom,
        uomConversionFactor,
        material.palletSize,
    )

    const quantityCalculator = useMemo(
        () => SharedFormComponents.createQuantityCalculator(material.packagingQuantity.amount, uomConversionFactor),
        [material.packagingQuantity.amount, uomConversionFactor],
    )

    const piecesCalculator = useMemo(
        () => SharedFormComponents.createPiecesCalculator(material.packagingQuantity.amount, uomConversionFactor),
        [material.packagingQuantity.amount, uomConversionFactor],
    )

    const selectedLocationShipTo = customerShipTos.find(location => location.address.id === orderShipTo)
    const initialValues: ReorderFormValues = {
        customerPoReference: '',
        pieces: toLocalizedInputNumber(formatNumber, pieces),
        quantity: toLocalizedInputNumber(formatNumber, quantityOrdered.amount),
        shipToId: pickupAvailable ? undefined : selectedLocationShipTo?.id,
        requestedDeliveryDate: undefined,
        price: undefined,
        pickup: pickupAvailable,
    }

    const goStep = (formStep: number) => (
        _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        values?: ReorderFormValues,
    ) => {
        switch (formStep) {
            case 1: {
                onGTMRemoveFromCart()
                break
            }
            case 2: {
                if (formActions.setField) {
                    formActions.setField('price', prices.length === 1 ? '0' : undefined)
                }
                break
            }
            case 3: {
                onGTMAddToCart(values!)
                break
            }
        }
        setStep(formStep)
        onStep(formStep)
    }

    const mutators = {
        setField: ([field, value]: [string, string | undefined], state: any, tools: any) => {
            debug.frontend(`Manually setting field ${field} to ${value}`)
            tools.changeValue(state, field, () => value)
        },
    }
    const quantityPiecesDecorator = material.isBulk ? piecesCalculator : quantityCalculator

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            decorators={[quantityPiecesDecorator, shipToDecorator]}
            mutators={mutators}>
            {({ handleSubmit, form, values, submitting, pristine, invalid }) => {
                debug.frontend(`New form values ${JSON.stringify(values, undefined, 2)}`)
                formActions.setField = form.mutators.setField.bind(form)
                resetForm = form.reset.bind(form)
                const quantity = parseFloatNumber(values.quantity)
                const isQuantityBelowMinimum = SharedFormComponents.isBelowMinimumPrice(quantity)
                const insufficientQuantity = prices.length > 0 && prices.every(isQuantityBelowMinimum) // every returns true for empty arrays

                return (
                    <>
                        <SharedFormComponents.FormWrapper onSubmit={handleSubmit}>
                            {step === 1 && (
                                <>
                                    <ReorderFormFieldsStep1
                                        material={material}
                                        shipTos={customerShipTos}
                                        firstEligibleDeliveryDate={firstEligibleDeliveryDate}
                                        lastEligibleDeliveryDate={lastEligibleDeliveryDate}
                                        dateFormat={dateFormat}
                                        uom={uom}
                                    />
                                    <SharedFormComponents.RequiredFootNote t={t} />
                                    <SharedFormComponents.ButtonsWrapper>
                                        <Button
                                            isLoading={loading}
                                            onClick={goStep(2)}
                                            disabled={pristine || invalid}
                                            variant="solid"
                                            title={t('order:next-steps.title')}
                                            data-test-id="button-reorder-form-continue">
                                            {t('order:next-steps.title')}
                                        </Button>
                                    </SharedFormComponents.ButtonsWrapper>
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <ReorderFormFieldsStep2
                                        file={values.file}
                                        change={form.change}
                                        prices={prices}
                                        quantity={quantity}
                                        insufficientQuantity={insufficientQuantity}
                                    />
                                    <SharedFormComponents.RequiredFootNote t={t} />

                                    <SharedFormComponents.ButtonsWrapper>
                                        <Button
                                            onClick={goStep(1)}
                                            icon="Chevron_Left"
                                            variant="outline"
                                            title={t('request-information-modal:back-to-step-1')}
                                            data-test-id="button-back-step-1">
                                            {t('request-information-modal:back-to-step-1')}
                                        </Button>
                                        <Button
                                            onClick={e => goStep(3)(e, values)}
                                            variant="solid"
                                            disabled={pristine || invalid}
                                            title={t('request-information-modal:preview-and-submit')}
                                            data-test-id="button-reorder-form-continue2">
                                            {t('request-information-modal:preview-and-submit')}
                                        </Button>
                                    </SharedFormComponents.ButtonsWrapper>
                                </>
                            )}
                            {step === 3 && (
                                <>
                                    <ReorderFormSummary
                                        file={values.file}
                                        values={values}
                                        material={material}
                                        uom={uom}
                                        shipTo={customerShipTos.find(
                                            (location: GQLLocation) => location.id === values.shipToId,
                                        )}
                                        prices={prices?.length > 0 ? prices[Number(values.price)] : undefined}
                                    />
                                    <SharedFormComponents.ButtonsWrapper>
                                        <Button
                                            onClick={goStep(2)}
                                            icon="Chevron_Left"
                                            variant="outline"
                                            title={t('request-information-modal:back-to-step-2')}
                                            data-test-id="button-back-step-2">
                                            {t('request-information-modal:back-to-step-2')}
                                        </Button>
                                        <Button
                                            isLoading={loading}
                                            type="submit"
                                            variant="action"
                                            disabled={submitting || invalid}
                                            title={t('general:send')}
                                            data-test-id="button-submit-order">
                                            {t('general:send')}
                                        </Button>
                                    </SharedFormComponents.ButtonsWrapper>
                                </>
                            )}
                        </SharedFormComponents.FormWrapper>
                        <FormSpy
                            subscription={{ values: true, dirty: true }}
                            onChange={(state: FormState<ReorderFormValues>) => {
                                if (state.dirty) {
                                    onUpdateForm(state.values)
                                }
                            }}
                        />
                    </>
                )
            }}
        </Form>
    )
}
