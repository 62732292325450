import { GQLRoleType } from '@bc/types'

import { Resources, RoleConfig, SettingsRole } from '../types'

export const defaultRoleConfig: SettingsRole = {
    rules: [
        {
            role: GQLRoleType.admin,
            resources: [...Object.values(Resources)],
        },
        {
            role: GQLRoleType.csr,
            resources: [...Object.values(Resources)].filter(r => r !== Resources.EditUsers), // remove EditUsers
        },
        {
            role: GQLRoleType.user,
            resources: [
                Resources.MyCompanies,
                Resources.Orders,
                Resources.Invoices,
                Resources.ProductList,
                Resources.Checkout,
                Resources.Requests,
                Resources.Quotes,
            ],
        },
        {
            role: GQLRoleType.viewer,
            resources: [
                Resources.MyCompanies,
                Resources.Orders,
                Resources.Invoices,
                Resources.ProductList,
                Resources.Requests,
            ],
        },
    ],
}

export const roleConfig: RoleConfig = {
    de: {
        ...defaultRoleConfig,
    },
    bnl: {
        ...defaultRoleConfig,
    },
    cee: {
        ...defaultRoleConfig,
    },
}
