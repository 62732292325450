import styled from 'styled-components'

import { gridConfig } from './grid-config'

export const Grid = styled.div`
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: ${gridConfig.maxGridWidth}px;
`
