import React, { Fragment, useCallback } from 'react'
import { Field } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import styled from 'styled-components'

import { GQLCustomer } from '@bc/types'
import { Button, Checkbox, colors, spacing, Text, media } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { trimLeadingZeros } from '@frontend/utils'

import { CompanyName, Label, Tab, ValidationError } from './components'
interface CompanyEditContactCardProps {
    name: string
    customer: GQLCustomer
    inactive: boolean
    onDelete: (customer: GQLCustomer) => void
}

interface CustomerSaleArea {
    salesOrganisation: string
    salesAreaId?: string
}

interface CompanyWrapperProps {
    inactive: boolean
}

const CompanyCard = styled.div<CompanyWrapperProps>`
    background-color: ${colors.neutral10};
    flex: 1;
    min-height: 135px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid ${colors.neutral20};
    border-radius: 3px;
    padding: ${spacing.xs}px;
    margin-bottom: ${spacing.xxs}px;
    &:last-child {
        margin-bottom: 0;
    }
    opacity: ${props => (props.inactive ? 0.5 : 1)};

    ${media.max('sm')} {
        min-width: unset;
    }
`

const TitleHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const DetailsHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    min-width: 400px;
    margin-bottom: ${spacing.sm}px;

    ${media.max('sm')} {
        min-width: unset;
        flex-direction: column;
    }
`

const SalesOrgSelection = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
`

const AccessSubheader = styled(Text)`
    margin-bottom: 5px;
`

export const CompanyEditContactCard = ({ name, customer, onDelete, inactive }: CompanyEditContactCardProps) => {
    const t = useFormatMessage()
    const { name: customerName = '', id = '0', vatId = '' } = customer
    const handleDeleteCustomer = useCallback(() => onDelete(customer), [customer, onDelete])

    const requiredArray = (value: string[]) =>
        value?.some(el => el) ? undefined : t('user:form:error-select-one-sales-org')

    return (
        <CompanyCard inactive={inactive} data-test-id="box-company-edit">
            <TitleHeader>
                <CompanyName p>{customerName}</CompanyName>
                <Button
                    disabled={inactive}
                    variant="error"
                    icon="Delete"
                    onClick={handleDeleteCustomer}
                    buttonSize="small"
                />
            </TitleHeader>
            <DetailsHeader>
                <Label small>{t('company:selection:label-customer-id')}</Label>
                <Text small>{trimLeadingZeros(id)}</Text>
                <Tab />
                <Label small>{t('company:selection:label-vat')}</Label>
                <Text small>{vatId}</Text>
            </DetailsHeader>
            <AccessSubheader small>{t('user:form:label-customer-grant-access')}</AccessSubheader>
            <FieldArray name={`${name}`} validate={requiredArray}>
                {({ meta: { error } }) => (
                    <>
                        <SalesOrgSelection>
                            {customer.mainSalesAreas.map(
                                ({ salesOrganisation, salesAreaId }: Partial<CustomerSaleArea>, index) => (
                                    <Fragment key={salesAreaId}>
                                        <Field
                                            name={`${name}.${index}`}
                                            type="checkbox"
                                            render={({ input }) => (
                                                <Checkbox
                                                    {...input}
                                                    id={`${name}.${index}.${salesAreaId}`}
                                                    labelText={salesOrganisation}
                                                    hasError={error}
                                                    disabled={inactive}
                                                    colorScheme="deepPurple"
                                                />
                                            )}
                                        />
                                        <Tab />
                                    </Fragment>
                                ),
                            )}
                        </SalesOrgSelection>
                        <ValidationError>{error && <span>{error}</span>}</ValidationError>
                    </>
                )}
            </FieldArray>
        </CompanyCard>
    )
}
