import React from 'react'

import { tagManager } from '@bc/gtm'
import { GQLRequest } from '@bc/types'
import { Text } from '@bc/ui'
import { FormatMessage } from '@frontend/components'

import { RequestedDocument, ResponseItem, ResponseWrapper } from './request-components'
import { hasCSRResponse } from './request-list-items'

interface Props {
    isCsr: boolean
    request: GQLRequest
    t: FormatMessage
    isRow?: boolean
}

export const RequestResponse = ({ t, request, isRow = true, isCsr }: Props) => {
    const { files, comments } = request

    if (!hasCSRResponse(request)) {
        return <></>
    }

    const uploads =
        files && files.length > 0
            ? files.map(file => {
                  const { id, url, fileName } = file
                  return (
                      <RequestedDocument
                          key={id}
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          text={fileName}
                          onClick={() =>
                              isCsr
                                  ? tagManager.tags.csrRequestsDocumentDownload(request.uid)
                                  : tagManager.tags.requestsDocumentDownload(request.uid)
                          }
                          data-test-class="link-response-documents"
                      />
                  )
              })
            : '-'

    const documents = (
        <ResponseItem>
            <Text caption>{t('requests:documents')}</Text>
            {uploads}
        </ResponseItem>
    )

    const comment = (
        <ResponseItem>
            <Text caption>{t('requests:messages')}</Text>
            <Text p whiteSpace="pre-wrap" data-test-class="text-response-messages">
                {comments ? comments : '-'}
            </Text>
        </ResponseItem>
    )

    return (
        <ResponseWrapper row={isRow}>
            {documents}
            {comment}
        </ResponseWrapper>
    )
}
