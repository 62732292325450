import styled from 'styled-components'

import { media } from '@bc/ui/src/helpers'

type SpacerSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg'
type AlignItems = 'initial' | 'inherit' | 'unset' | 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
type JustifyContent =
    | 'initial'
    | 'inherit'
    | 'unset'
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse'

const heightStyles = (height: number | string | undefined, mobileHeight: number | string | undefined): string => {
    if (!height) {
        return ``
    }

    const smallHeight = mobileHeight ? mobileHeight : 'auto'

    return `
        height: ${height}px;

        ${media.max('md')} {
            height: ${smallHeight}px;
            overflow: ${mobileHeight ? 'hidden' : 'visible'};
        }
    `
}

const minHeightStyles = (minHeight: number | string | undefined): string => {
    if (!minHeight) {
        return ``
    }

    return `
        min-height: ${minHeight}px;

        ${media.max('md')} {
            min-height: 0;
        }
    `
}

const noPaddingOnMobileStyles = (noPadding: boolean | undefined): string => {
    if (!noPadding) {
        return ``
    }

    return `
        ${media.max('md')} {
            padding: 0;
        }
    `
}

const spacerSizeStyles = (size: SpacerSizes): string => {
    switch (size) {
        case 'xxs':
            return `
                padding: 1.5rem 0;

                ${media.max('md')} {
                    padding: 1.5rem 0;
                }
            `
        case 'xs':
            return `
                padding: 3rem 0;

                ${media.max('md')} {
                    padding: 3rem 0;
                }
            `
        case 'sm':
            return `
                padding: 6rem 0;

                ${media.max('md')} {
                    padding: 3rem 0;
                }
            `
        case 'md':
            return `
                padding: 9rem 0;

                ${media.max('md')} {
                    padding: 3rem 0;
                }
            `
        case 'lg':
            return `
                padding: 12rem 0;

                ${media.max('md')} {
                    padding: 3rem 0;
                }
            `
    }
}

interface SectionSpacerProps {
    spacer?: SpacerSizes
    alignItems?: AlignItems
    justifyContent?: JustifyContent
    flexDirection?: FlexDirection
    mobileHeight?: string | number
    height?: string | number
    minHeight?: string | number
    noPaddingOnMobile?: boolean
}

export const SectionSpacer = styled.div`
    display: flex;
    padding: 0;
    flex-direction: ${({ flexDirection }: SectionSpacerProps) => flexDirection || 'column'};
    align-items: ${({ alignItems }: SectionSpacerProps) => alignItems || 'initial'};

    ${media.max('md')} {
        padding: 0;
    }

    ${media.max('sm')} {
        flex-direction: column;
    }

    ${({ spacer = 'sm' }: SectionSpacerProps) => spacerSizeStyles(spacer)}
    ${({ height, mobileHeight }: SectionSpacerProps) => heightStyles(height, mobileHeight)}
    ${({ minHeight }: SectionSpacerProps) => minHeightStyles(minHeight)}
    ${({ noPaddingOnMobile }: SectionSpacerProps) => noPaddingOnMobileStyles(noPaddingOnMobile)}
`
