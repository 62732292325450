import styled from 'styled-components'

import { Hr } from '@bc/ui/src/components/hr'
import { breakpoints, colors, spacing } from '@bc/ui/src/config'
import { hideOnMax, hideOnMin, media } from '@bc/ui/src/helpers'

import { CardTheme, getCardThemeConfig } from './utils'

export interface ThemeProps {
    cardTheme?: CardTheme
}

export interface WrapperProps {
    hiddenMax?: keyof typeof breakpoints
    hiddenMin?: keyof typeof breakpoints
    sidebarColor?: keyof typeof colors
}

export const Wrapper = styled.div<WrapperProps & ThemeProps>`
    position: relative;
    width: 100%;
    ${({ cardTheme = 'none' }) => getCardThemeConfig(cardTheme).wrapper}
    ${({ hiddenMax }) => hideOnMax(hiddenMax)}
    ${({ hiddenMin }) => hideOnMin(hiddenMin)}
    border-left: ${({ sidebarColor }) => (sidebarColor ? `3px solid ${colors[sidebarColor]}` : `0`)};
    border: 1px solid ${colors.neutral20};
`

export const Header = styled.div<ThemeProps>`
    padding: ${spacing.xs}px;
    ${media.min('sm')} {
        padding: ${spacing.xs}px;
    }
    ${media.min('md')} {
        padding: ${spacing.sm}px;
    }
    ${({ cardTheme = 'none' }) => getCardThemeConfig(cardTheme).header}
`

export const Footer = styled.div<ThemeProps>`
    padding: ${spacing.xs}px;

    ${media.min('sm')} {
        padding: ${spacing.xs}px;
    }

    ${media.min('md')} {
        padding: ${spacing.sm}px;
    }

    ${({ cardTheme = 'none' }) => getCardThemeConfig(cardTheme).footer}
`

export const Body = styled.div<ThemeProps>`
    padding: ${spacing.xs}px;
    ${media.min('sm')} {
        padding: ${spacing.xs}px;
    }
    ${media.min('md')} {
        padding: ${spacing.sm}px;
    }
    ${({ cardTheme = 'none' }) => getCardThemeConfig(cardTheme).body}
`

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex: 1;
    ${media.max('sm')} {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }
`

export const Icon = styled.div`
    display: flex;
    align-items: center;
    margin: 0 ${spacing.xs}px 0 0;
`

export const RichRowHr = styled(Hr)`
    margin: ${spacing.xs}px 0;
`

export const HeaderContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
`

export const HeaderItems = styled.div`
    display: flex;
    align-items: center;
    flex-basis: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    gap: ${spacing.sm}px;
    ${media.max('sm')} {
        gap: ${spacing.xs}px;
    }
`
export const ButtonMobileWrapper = styled.div`
    ${media.max('sm')} {
        display: flex;
        width: 100%;
        flex: 1 1 auto;
    }
`
