import React from 'react'
import { Field, Form } from 'react-final-form'

import { Button, ButtonLink, Text, TextInput, FormInputComponents } from '@bc/ui'
import { FormatMessage } from '@frontend/components/hooks'
import { finalFormValidations } from '@frontend/utils'
import { ButtonsWrapper } from '../_shared/components'

export enum ForgotPasswordFormState {
    initial,
    success,
    failure,
}
interface ForgotPasswordProps {
    t: FormatMessage
    state: ForgotPasswordFormState
    resetPasswordSubmit: (values: any) => void
}

export const PasswordForgotForm = ({ t, state, resetPasswordSubmit }: ForgotPasswordProps) => (
    <Form onSubmit={resetPasswordSubmit}>
        {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} data-test-id="form-password-forgot">
                <FormInputComponents.InputWrapper>
                    <Field
                        name="email"
                        validate={finalFormValidations.email(t)}
                        render={({ input, meta }) => (
                            <TextInput
                                {...input}
                                labelText={t('forgot-password-form:email')}
                                placeholder={t('forgot-password-form:email')}
                                type="text"
                                disabled={state === ForgotPasswordFormState.success}
                                hasError={meta.touched && meta.error}
                                errorText={meta.error}
                                data-test-id="input-email"
                            />
                        )}
                    />
                    {state === ForgotPasswordFormState.success && (
                        <Text p data-test-id="text-forgot-success">
                            {t('forgot-password-form:success')}
                        </Text>
                    )}
                    {state === ForgotPasswordFormState.failure && (
                        <Text p data-test-id="text-forgot-error" color="red70">
                            Error: {t('general:something-went-wrong')}
                        </Text>
                    )}
                </FormInputComponents.InputWrapper>
                <ButtonsWrapper>
                    <ButtonLink to="/login" data-test-id="link-back-to-login" title={t('login:back-to-login')}>
                        {t('login:back-to-login')}
                    </ButtonLink>
                    <Button
                        type="submit"
                        variant="action"
                        data-test-id="button-submit"
                        title={t('forgot-password-form:submit')}>
                        {t('forgot-password-form:submit')}
                    </Button>
                </ButtonsWrapper>
            </form>
        )}
    </Form>
)
