import { GQLBaseOrderLine, GQLMaterial, QuotationGroup, GQLPriceAmount } from '@bc/types'

import { BaseProductClickTag, ProductList } from '../types'
import { dataLayer, getCurrency, getProductBrand } from '../utils'

const anyProductClick = (
    material: GQLMaterial,
    price: GQLPriceAmount | undefined,
    index: number,
    list: ProductList,
) => {
    const brand = getProductBrand(material.manufacturer)
    const currencyCode = getCurrency({ price })

    dataLayer<BaseProductClickTag>({
        event: 'productClick',
        ecommerce: {
            currencyCode,
            click: {
                actionField: { list },
                products: [
                    {
                        name: material.name || '-',
                        id: material.id,
                        price: price?.amount || '-',
                        brand,
                        list,
                        position: index + 1,
                    },
                ],
            },
        },
    })
}

export const baseProductClick = (orderLine: GQLBaseOrderLine, index: number) => {
    const { material, unitPrice } = orderLine
    const price: GQLPriceAmount | undefined = unitPrice
        ? {
              amount: unitPrice.price || 0,
              currency: unitPrice.currency || '-',
          }
        : undefined
    anyProductClick(material!, price, index, 'order history')
}

export const quoteProductClick = (quoteGroup: QuotationGroup) => {
    const price: GQLPriceAmount | undefined = quoteGroup.prices[0]?.price
    anyProductClick(quoteGroup.material!, price, 0, 'quotation list')
}
