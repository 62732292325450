import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { QueryResult } from '@apollo/react-common'
import { useQuery } from '@apollo/react-hooks'

import debounce from 'debounce'
import qs from 'query-string'

import { tagManager } from '@bc/gtm'
import { GQLGetMaterialsFilters, QueryToMaterialsArgs } from '@bc/types'
import { FullScreenLoaderRound, SelectInputOption } from '@bc/ui'
import { useCopyToClipboard, useFormatMessage } from '@frontend/components'
import {
    FiltersContextData,
    FiltersProvider,
    PlatformConfigContext,
    SortContextData,
    SortProvider,
} from '@frontend/context'

import { useFilters } from '@frontend/components/hooks/use-filters'
import { ProductListPage } from './product-list-page'
import { GetMaterialsQuery, GetMaterialsQueryResponse } from './queries'

interface ProductListPaginationQuery extends qs.ParsedQuery {
    page: string
    sort: string
}

type ProductListPageQuery = ProductListPaginationQuery & GQLGetMaterialsFilters

const initialPage = 1
const initialSort = 'name'
const initialFilters: GQLGetMaterialsFilters = {}

const ProductListPageContainer = () => {
    const t = useFormatMessage()
    const { appConfig } = useContext(PlatformConfigContext)
    const copyToClipboard = useCopyToClipboard()

    const { filters, page = initialPage, sort = initialSort, updateFilters } = useFilters<
        GQLGetMaterialsFilters,
        ProductListPageQuery
    >()

    const variables: QueryToMaterialsArgs = {
        page,
        pageSize: 30,
        sort,
        filters,
    }
    const { error, loading, data }: QueryResult<GetMaterialsQueryResponse> = useQuery(GetMaterialsQuery, {
        variables,
        ssr: false,
        fetchPolicy: 'cache-first',
    })
    const materials = data?.materials?.materials
    const pagination = data?.materials?.pagination ?? {
        count: 0,
        pages: 1,
        page: 1,
    }

    useEffect(() => {
        // reset to first page if more than available results is requested in the url
        if (materials && page > pagination.pages) {
            updateFilters({ ...filters, sort, page: pagination.page })
        }
    }, [materials])

    let debounceName: any
    let debounceMaterialId: any
    let debounceCas: any
    let debounceEinecs: any

    const handleSortChange = (newSort: SelectInputOption) => {
        const { value } = newSort
        tagManager.tags.productListSort(value)
        updateFilters({ ...filters, sort: value, page: initialPage })
    }

    const handlePageClick = (newPage: number) => {
        updateFilters({ ...filters, sort, page: newPage })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    const materialIdFilter = (filter: string) => {
        if (debounceMaterialId) {
            debounceMaterialId.clear()
        }
        debounceMaterialId = debounce(() => tagManager.tags.productListFilterByProductId(filter), 1000)
        debounceMaterialId()
    }

    const nameFilter = (filter: string) => {
        if (debounceName) {
            debounceName.clear()
        }
        debounceName = debounce(() => tagManager.tags.productListFilterByName(filter), 1000)
        debounceName()
    }

    const casFilter = (filter: string) => {
        if (debounceCas) {
            debounceCas.clear()
        }
        debounceCas = debounce(() => tagManager.tags.productListFilterByCas(filter), 1000)
        debounceCas()
    }

    const einecsFilter = (filter: string) => {
        if (debounceEinecs) {
            debounceEinecs.clear()
        }
        debounceEinecs = debounce(() => tagManager.tags.productListFilterByEinecs(filter), 1000)
        debounceEinecs()
    }

    const handleFilterChange = (newFilters: GQLGetMaterialsFilters) => {
        if (newFilters.materialId !== filters.materialId && newFilters.materialId) {
            materialIdFilter(newFilters.materialId)
        }

        if (newFilters.name !== filters.name && newFilters.name) {
            nameFilter(newFilters.name)
        }

        if (newFilters.casNumber !== filters.casNumber && newFilters.casNumber) {
            casFilter(newFilters.casNumber)
        }

        if (newFilters.einecsNumber !== filters.einecsNumber && newFilters.einecsNumber) {
            einecsFilter(newFilters.einecsNumber)
        }

        if (newFilters.packagingName !== filters.packagingName && newFilters.packagingName) {
            tagManager.tags.productListFilterByPackaging(newFilters.packagingName)
        }

        updateFilters({ ...newFilters, sort, page: initialPage })
    }

    const handleCopyRequestLink = (_formFilters: GQLGetMaterialsFilters) => {
        copyToClipboard(window.location.href)
    }

    const sortOptions: SelectInputOption[] = [
        {
            value: 'name',
            label: `${t('products:card.material')} ${t('sort-direction:string:asc')}`,
        },
        {
            value: '-name',
            label: `${t('products:card.material')} ${t('sort-direction:string:desc')}`,
        },
        {
            value: 'casNumber',
            label: `${t('products:column.cas-no')} ${t('sort-direction:numeric:asc')}`,
        },
        {
            value: '-casNumber',
            label: `${t('products:column.cas-no')} ${t('sort-direction:numeric:desc')}`,
        },
        {
            value: 'einecsNumber',
            label: `${t('products:column.cas-no')} ${t('sort-direction:numeric:asc')}`,
        },
        {
            value: '-einecsNumber',
            label: `${t('products:column.cas-no')} ${t('sort-direction:numeric:desc')}`,
        },
        {
            value: 'packagingName',
            label: `${t('general:packaging')} ${t('sort-direction:string:asc')}`,
        },
        {
            value: '-packagingName',
            label: `${t('general:packaging')} ${t('sort-direction:string:desc')}`,
        },
    ]
    const filterProviderProps: FiltersContextData<GQLGetMaterialsFilters> = {
        onFilterChange: handleFilterChange,
        filters: initialFilters,
        query: filters,
        onLinkCopy: handleCopyRequestLink,
    }
    const sortProviderProps: SortContextData = {
        onSortChange: handleSortChange,
        sort,
        sortOptions,
    }

    return (
        <FiltersProvider {...filterProviderProps}>
            <SortProvider {...sortProviderProps}>
                <FullScreenLoaderRound isVisible={loading} />
                <ProductListPage
                    onPageClick={handlePageClick}
                    error={error}
                    materials={materials}
                    pagination={pagination}
                    view={appConfig.productView}
                />
            </SortProvider>
        </FiltersProvider>
    )
}

export default withRouter(ProductListPageContainer)
