import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Question: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Question" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M12 22.41L8.59 19H3V3H21V5H5V17H9.41L12 19.59L14.59 17H19V7H21V19H15.41L12 22.41ZM13 14H11V12.59L13 10.59V10C13 9.45 12.55 9 12 9C11.45 9 11 9.45 11 10H9C9 8.35 10.35 7 12 7C13.65 7 15 8.35 15 10V11.41L13 13.41V14ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
                fill={fill === 'gradient' ? 'url(#Question_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Question" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Question" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M12 22.41L8.59 19H3V3H21V5H5V17H9.41L12 19.59L14.59 17H19V7H21V19H15.41L12 22.41ZM13 14H11V12.59L13 10.59V10C13 9.45 12.55 9 12 9C11.45 9 11 9.45 11 10H9C9 8.35 10.35 7 12 7C13.65 7 15 8.35 15 10V11.41L13 13.41V14ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
                fill={fill === 'gradient' ? 'url(#Question_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Question" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Question" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M12 22.41L8.59 19H3V3H21V5H5V17H9.41L12 19.59L14.59 17H19V7H21V19H15.41L12 22.41ZM13 14H11V12.59L13 10.59V10C13 9.45 12.55 9 12 9C11.45 9 11 9.45 11 10H9C9 8.35 10.35 7 12 7C13.65 7 15 8.35 15 10V11.41L13 13.41V14ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
                fill={fill === 'gradient' ? 'url(#Question_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Question" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Question" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M12 22.41L8.59 19H3V3H21V5H5V17H9.41L12 19.59L14.59 17H19V7H21V19H15.41L12 22.41ZM13 14H11V12.59L13 10.59V10C13 9.45 12.55 9 12 9C11.45 9 11 9.45 11 10H9C9 8.35 10.35 7 12 7C13.65 7 15 8.35 15 10V11.41L13 13.41V14ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
                fill={fill === 'gradient' ? 'url(#Question_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Question" />}
        </g>
    ),
}
