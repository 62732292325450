import styled from 'styled-components'

import { colors, spacing } from '@bc/ui/src/config'

interface BoxProps {
    height?: number
}

export const ScrollBox = styled.div`
    position: relative;
    padding: ${spacing.sm}px;
    border: 1px solid ${colors.neutral20};
    height: ${({ height }: BoxProps) => `${height ?? 250}px`};
    overflow: auto;
    -webkit-overflow-scrolling: touch;
`

export const NoScrollBox = styled.div`
    position: relative;
    padding: ${spacing.sm}px;
    border: 1px solid ${colors.neutral20};
    ${({ height }: BoxProps) => (height ? `${height}px` : ``)};
    overflow: visible;
`
