import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Star: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Star" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M19.78 9.8L15.88 13.71L17.53 19.48L12 15.8L6.47 19.48L8.12 13.71L4.22 9.8L9.47 8.9L12 4.18L14.53 8.9L17.37 9.39L19.1 7.66L15.83 7.1L13.1 2H10.9L8.17 7.1L2 8.16V10.41L5.88 14.29L3.67 22H6.3L12 18.2L17.7 22H20.33L18.12 14.29L22 10.41V8.16L21.51 8.07L19.78 9.8Z"
                fill={fill === 'gradient' ? 'url(#Star_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Star" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Star" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M19.78 9.8L15.88 13.71L17.53 19.48L12 15.8L6.47 19.48L8.12 13.71L4.22 9.8L9.47 8.9L12 4.18L14.53 8.9L17.37 9.39L19.1 7.66L15.83 7.1L13.1 2H10.9L8.17 7.1L2 8.16V10.41L5.88 14.29L3.67 22H6.3L12 18.2L17.7 22H20.33L18.12 14.29L22 10.41V8.16L21.51 8.07L19.78 9.8Z"
                fill={fill === 'gradient' ? 'url(#Star_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Star" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Star" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M19.78 9.8L15.88 13.71L17.53 19.48L12 15.8L6.47 19.48L8.12 13.71L4.22 9.8L9.47 8.9L12 4.18L14.53 8.9L17.37 9.39L19.1 7.66L15.83 7.1L13.1 2H10.9L8.17 7.1L2 8.16V10.41L5.88 14.29L3.67 22H6.3L12 18.2L17.7 22H20.33L18.12 14.29L22 10.41V8.16L21.51 8.07L19.78 9.8Z"
                fill={fill === 'gradient' ? 'url(#Star_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Star" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Star" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M19.78 9.8L15.88 13.71L17.53 19.48L12 15.8L6.47 19.48L8.12 13.71L4.22 9.8L9.47 8.9L12 4.18L14.53 8.9L17.37 9.39L19.1 7.66L15.83 7.1L13.1 2H10.9L8.17 7.1L2 8.16V10.41L5.88 14.29L3.67 22H6.3L12 18.2L17.7 22H20.33L18.12 14.29L22 10.41V8.16L21.51 8.07L19.78 9.8Z"
                fill={fill === 'gradient' ? 'url(#Star_gradient)' : fill}
            />
            {fill === 'gradient' && <Gradient name="Star" />}
        </g>
    ),
}
