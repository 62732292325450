import React, { useContext } from 'react'

import { tagManager } from '@bc/gtm'
import { GQLInvoice, GQLInvoiceFilters } from '@bc/types'
import { Button, CardEnriched, TextHighlight, RichRowListItemProps } from '@bc/ui'
import { useFormatCurrency, useFormatDate, useFormatMessage } from '@frontend/components/hooks'
import { trimLeadingZeros } from '@frontend/utils'
import { FiltersContextData, FiltersContext } from '@frontend/context'
import { ButtonMobileWrapper } from '@bc/ui/src/components/card/card-components'

import { FilterCopyLinkButton } from '../filters/filter-copy-link-button'

interface InvoiceCardProps {
    invoice: GQLInvoice
    onOpenInvoice: (uri: string) => void
    onLinkCopy?: (invoiceId: string) => void
}

export const InvoiceCard = ({ invoice, onOpenInvoice, onLinkCopy }: InvoiceCardProps) => {
    const formatCurrency = useFormatCurrency()
    const formatDate = useFormatDate()
    const t = useFormatMessage()
    const filterContext: FiltersContextData<GQLInvoiceFilters> = useContext(FiltersContext)
    const { customerPoReferences: filterCustomerPoReference, invoiceDisplayId: filterInvoiceDisplayId } =
        filterContext?.query ?? {}

    const { id, displayId, customerPoReferences, issueDate, dueDate, amountGross, document, currency } = invoice
    const title = t('invoices:title', undefined, { id: trimLeadingZeros(displayId) })
    const list: RichRowListItemProps[] = [
        {
            label: t('general:po-reference'),
            value: customerPoReferences?.length ? customerPoReferences.join(', ') : '-',
            id: 'po-reference',
            highlight: filterCustomerPoReference,
        },
        {
            label: t('invoices:date-invoice'),
            value: formatDate(issueDate),
            id: 'date-invoice',
        },
        {
            label: t('invoices:date-due'),
            value: dueDate ? formatDate(dueDate) : '-',
            id: 'date-due',
        },
        {
            label: t('invoices:total-amount'),
            value: formatCurrency(amountGross, {
                currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }),
            id: 'total-amount',
        },
    ]

    const openInvoice = (): void => {
        if (document && onOpenInvoice) {
            onOpenInvoice(document.uri)
            tagManager.tags.documentDownload('invoice', id)
        }
    }

    const showDownloadButton = document?.uri !== ''
    return (
        <CardEnriched
            header={{
                title: <TextHighlight search={filterInvoiceDisplayId}>{title}</TextHighlight>,
            }}
            data-test-id={`card-invoice-${trimLeadingZeros(displayId)}`}
            actions={
                <>
                    {onLinkCopy && <FilterCopyLinkButton onClick={() => onLinkCopy(trimLeadingZeros(displayId))} />}
                    {showDownloadButton && (
                        <ButtonMobileWrapper>
                            <Button
                                fullOnMobile
                                variant="action"
                                icon="File_Text"
                                onClick={openInvoice}
                                title={t('invoices:download')}
                                data-test-id={'button-open-invoice'}>
                                {t('invoices:download')}
                            </Button>
                        </ButtonMobileWrapper>
                    )}
                </>
            }
            list={list}
        />
    )
}
