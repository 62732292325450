import * as ls from 'local-storage'

import { GQLNotification } from '@bc/types'
import { debug } from '@bc/logging'

type notifyFunc = (notification: readonly GQLNotification[]) => void

/**
 * @class NotificationsStore
 * @summary Returns a store to manage notification banners.
 */
export class NotificationsStore {
    private allNotifications: GQLNotification[] = []
    private filteredNotifications: GQLNotification[] = []
    private discardedNotificationsUids: string[] = []
    private viewedNotificationsUids: string[] = []
    private initialized = false
    private notify?: notifyFunc
    private updateCallback: () => void
    private readonly localStorageTag = 'emea-bc-discarded-notifications'
    private readonly maxElements = 10

    /**
     * @private
     * @summary Log state.
     * @param void.
     * @returns Void.
     * @example
     */
    private logState() {
        debug.frontend(`Current notifications are ${JSON.stringify(this.allNotifications)}`)
        debug.frontend(`Viewed notification ids are ${JSON.stringify(this.viewedNotificationsUids)}`)
        debug.frontend(`Discarded notification ids are ${JSON.stringify(this.discardedNotificationsUids)}`)
        debug.frontend(`Overall visible notifications are ${JSON.stringify(this.filteredNotifications)}`)
    }

    /**
     * @private
     * @param notification
     * @summary Generates a unique notification id, based on id and updatedAt date.
     * @param void
     * @returns String.
     * @example
     */
    private generateUid = (notification: GQLNotification): string =>
        `${notification.id}-${new Date(notification.updatedAt).valueOf()}`

    /**
     * @private
     * @summary Write hidden notifications to persistent storage.
     * @param void.
     * @returns Void.
     * @example
     */
    private writeDiscardedToStorage(): void {
        if (!window) {
            return
        }

        // Keep array of remembered notification to X elements tops
        while (this.discardedNotificationsUids.length > this.maxElements) {
            this.discardedNotificationsUids.shift()
        }

        try {
            // Using set to filter out duplicates
            ls.set(this.localStorageTag, [...new Set(this.discardedNotificationsUids)])
        } catch (error) {
            console.error(`An error occured when writing to local storage, ${error}. Local storage cleared.`)
            ls.remove(this.localStorageTag)
        }
    }

    /**
     * @private
     * @summary Read serialized store data from persistent storage (on init).
     * @param void
     * @returns String[] of notification uids.
     * @example
     */
    private readDiscardedFromStorage(): void {
        if (!window) {
            return
        }

        try {
            this.discardedNotificationsUids = ls.get<string[]>(this.localStorageTag) ?? []
            this.updateNotifications()
        } catch (error) {
            console.error(`An error occured when reading from local storage, ${error}. Local storage cleared.`)
            ls.remove(this.localStorageTag) // clear storage if anything broken in it
        }
    }

    /**
     * @private
     * @summary Regenerate filtered notifications. This function is triggered manually to avoid reruns on each react state update.
     * @param void.
     * @returns Void.
     * @example
     */
    private updateNotifications(): void {
        this.filteredNotifications = this.allNotifications.filter(
            notification => !this.discardedNotificationsUids.includes(this.generateUid(notification)),
        )
        if (this.notify) {
            this.notify(this.notifications)
        }
    }

    /**
     * @param notifications
     * @param notify
     * @param notifications
     * @param notify
     * @public
     * @summary Initialises the class with the new state.
     * @returns Void.
     * @example
     */
    public init(notifications: GQLNotification[], notify: notifyFunc) {
        if (this.initialized) {
            return
        }
        this.readDiscardedFromStorage()
        this.allNotifications = notifications
        this.notify = notify
        this.save(false)
        this.subscribe()
        this.initialized = true
    }

    /**
     * @public
     * @summary Deinitializes the class.
     * @returns Void.
     * @example
     */
    public deinit() {
        if (!this.initialized) {
            return
        }
        this.notify = undefined
        this.allNotifications = []
        this.filteredNotifications = []
        this.discardedNotificationsUids = []
        this.viewedNotificationsUids = []

        this.unsubscribe()
        this.initialized = false
    }

    /**
     * @public
     * @summary Discards notification and saves viewed state to permanent storage.
     * @returns Void.
     * @param notification
     * @example
     */
    private save(toStorage: boolean) {
        if (toStorage) {
            this.writeDiscardedToStorage()
        }
        this.updateNotifications()
        this.logState()
    }

    /**
     * @public
     * @summary Discards notification and saves viewed state to permanent storage.
     * @returns Void.
     * @param notification
     * @example
     */
    public discard(notification: GQLNotification) {
        this.discardedNotificationsUids.push(this.generateUid(notification))
        this.save(true)
    }

    /**
     * @public
     * @summary Discards multiple notifications and saves viewed state to permanent storage.
     * @returns Void.
     * @param notification
     * @example
     */
    public discardMany(notifications: GQLNotification[]) {
        notifications
            .map(notification => this.generateUid(notification))
            .forEach(id => this.discardedNotificationsUids.push(id))

        this.save(true)
    }

    /**
     * @public
     * @summary Hides notification, it can be viewed later again.
     * @returns Void.
     * @param notification
     * @example
     */
    public hide(notification: GQLNotification) {
        this.viewedNotificationsUids.push(this.generateUid(notification))
        this.save(false)
    }

    /**
     * @public
     * @summary Hides multiple notifications, it can be viewed later again.
     * @returns Void.
     * @param notification
     * @example
     */
    public hideMany(notifications: GQLNotification[]) {
        notifications
            .map(notification => this.generateUid(notification))
            .forEach(id => this.viewedNotificationsUids.push(id))
        this.save(false)
    }

    /**
     * @public
     * @summary Shows all notifications that were temporarily hidden.
     * @returns Void.
     * @param notification
     * @example
     */
    public show() {
        this.viewedNotificationsUids = []
        this.save(false)
    }

    /**
     * @public
     * @summary Checks if a notification has been viewed but not discarded.
     * @returns Boolean.
     * @param notification
     * @example
     */
    public hasBeenViewed = (notification: GQLNotification) =>
        this.viewedNotificationsUids.includes(this.generateUid(notification))

    /**
     * @public
     * @getter
     * @summary Returns stored notifications.
     * @param notification
     * @returns Data as GQLNotification[].
     */
    public get notifications() {
        return Object.freeze(this.filteredNotifications)
    }

    /**
     * @private
     * @summary Subscribes to the local storage notificationd.
     * @param subscriber - Function.
     * @returns Void.
     * @example
     */
    private subscribe() {
        this.updateCallback = () => {
            if (!this.initialized) {
                return
            }
            this.readDiscardedFromStorage()
        }

        ls.on(this.localStorageTag, () => {
            this.readDiscardedFromStorage()
        })
    }

    /**
     * @private
     * @summary Unsubscribes from the local storage notification.
     * @param subscriber - Function.
     * @returns Void.
     * @example
     */
    private unsubscribe() {
        ls.off(this.localStorageTag, this.updateCallback)
    }
}
