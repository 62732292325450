import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Volume_Off: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Volume_Off" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M3.70621 2.29138L2.29199 3.7056L20.2949 21.7085L21.7091 20.2943L3.70621 2.29138ZM7 11.24V14H10.41L15 18.59V21.41L9.59 16H5V9.23996L7 11.24ZM15 2.58997L10.91 6.66997L12.33 8.08997L13 7.40997V8.75997L15 10.76V2.58997Z"
                fill={fill === 'gradient' ? 'url(#Volume_Off_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_Off" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Volume_Off" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M3.70621 2.29138L2.29199 3.7056L20.2949 21.7085L21.7091 20.2943L3.70621 2.29138ZM7 11.24V14H10.41L15 18.59V21.41L9.59 16H5V9.23996L7 11.24ZM15 2.58997L10.91 6.66997L12.33 8.08997L13 7.40997V8.75997L15 10.76V2.58997Z"
                fill={fill === 'gradient' ? 'url(#Volume_Off_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_Off" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Volume_Off" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M3.70621 2.29138L2.29199 3.7056L20.2949 21.7085L21.7091 20.2943L3.70621 2.29138ZM7 11.24V14H10.41L15 18.59V21.41L9.59 16H5V9.23996L7 11.24ZM15 2.58997L10.91 6.66997L12.33 8.08997L13 7.40997V8.75997L15 10.76V2.58997Z"
                fill={fill === 'gradient' ? 'url(#Volume_Off_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_Off" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Volume_Off" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M3.70621 2.29138L2.29199 3.7056L20.2949 21.7085L21.7091 20.2943L3.70621 2.29138ZM7 11.24V14H10.41L15 18.59V21.41L9.59 16H5V9.23996L7 11.24ZM15 2.58997L10.91 6.66997L12.33 8.08997L13 7.40997V8.75997L15 10.76V2.58997Z"
                fill={fill === 'gradient' ? 'url(#Volume_Off_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Volume_Off" />}
        </g>
    ),
}
