import React from 'react'

import { colors } from '@bc/ui/src/config'
import { FormInputComponents, TextInputComponents } from './components'

export interface TextInputProps {
    labelText?: React.ElementType | JSX.Element | string
    errorText?: React.ElementType | JSX.Element | string
    hasError?: boolean
    textarea?: boolean
    noMargin?: boolean
    labelColor?: keyof typeof colors
    row?: boolean
    append?: string | JSX.Element
    prepend?: string | JSX.Element
}

export const TextInput: React.FunctionComponent<
    TextInputProps & React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>
> = ({
    labelText,
    labelColor,
    errorText,
    hasError,
    as: _as,
    ref: _ref,
    noMargin,
    textarea,
    type,
    row = false,
    append,
    prepend,
    ...rest
}) => {
    const label = labelText && (
        <TextInputComponents.Label labelColor={labelColor}>{labelText}</TextInputComponents.Label>
    )

    const hasInputItem = Boolean(append) || Boolean(prepend)
    const TextInputConditional: React.FC<TextInputComponents.InputProps> = textarea
        ? TextInputComponents.Textarea
        : TextInputComponents.Input

    const input = (
        <>
            <FormInputComponents.InputWrapper row={hasInputItem} noMargin>
                {Boolean(prepend) &&
                    (typeof prepend === 'string' ? (
                        <TextInputComponents.InputItem data-location="left">{prepend}</TextInputComponents.InputItem>
                    ) : (
                        prepend
                    ))}

                <TextInputConditional
                    textarea={textarea}
                    hasError={hasError}
                    type={type}
                    hasItem={hasInputItem}
                    {...rest}
                />

                {Boolean(append) ? (
                    typeof append === 'string' ? (
                        <TextInputComponents.InputItem data-location="right">{append}</TextInputComponents.InputItem>
                    ) : (
                        append
                    )
                ) : (
                    <>
                        {type === 'search' && <TextInputComponents.SearchIcon />}
                        {type === 'email' && <TextInputComponents.EmailIcon />}
                        {type === 'password' && <TextInputComponents.PasswordIcon />}
                    </>
                )}
            </FormInputComponents.InputWrapper>
            {Boolean(hasError && errorText) && (
                <FormInputComponents.ErrorLabel data-test-id={`label-${type}-error`}>
                    {errorText}
                </FormInputComponents.ErrorLabel>
            )}
        </>
    )

    return row ? (
        <FormInputComponents.Row>
            <FormInputComponents.RowItem>{label}</FormInputComponents.RowItem>
            <FormInputComponents.RowItem>{input}</FormInputComponents.RowItem>
        </FormInputComponents.Row>
    ) : (
        <FormInputComponents.InputWrapper noMargin={noMargin}>
            {label}
            {input}
        </FormInputComponents.InputWrapper>
    )
}
