import React, { useContext } from 'react'
import { ShoppingCartContextData, ShoppingCartContext } from '@frontend/context'
import { GQLBaseOrderLine } from '@bc/types'
import { ShopFeatures, Resources } from '@bc/config'
import { colors } from '@bc/ui'

import { Feature } from '../customer'
import { useFormatMessage } from '../hooks'
import * as NavComponents from './navigation-components'

interface ShoppingCartProps {
    enable: boolean
}
export const ShoppingCart = ({ enable }: ShoppingCartProps) => {
    const t = useFormatMessage()
    const [shoppingStore]: ShoppingCartContextData<GQLBaseOrderLine> = useContext(ShoppingCartContext)

    return enable ? (
        <Feature
            flag={[ShopFeatures.ShoppingCart, Resources.Checkout]}
            on={
                <NavComponents.IconLink to={t('route:cart-overview')} title={t('route:cart-overview.label')} exact>
                    <NavComponents.ShoppingCartIndicator
                        size="24"
                        icon="Cart"
                        fill={colors.neutral90}
                        count={shoppingStore.length}
                        color="white"
                        bgColor="deepPurple"
                    />
                </NavComponents.IconLink>
            }
        />
    ) : null
}
