import React from 'react'
import ReactSelect from 'react-select'

import styled, { css } from 'styled-components'

import { SvgIcon } from '@bc/ui/src/components/svg/svg-icon'
import { colors, defaultFontSpacing, spacing } from '@bc/ui/src/config'
import { getFontFamily } from '@bc/ui/src/helpers'

export const Label = styled.label`
    color: ${colors.neutral90};
    margin-right: ${spacing.xxs}px;
`

export interface SelectProps {
    hasError?: boolean
    isRow?: boolean
}

export const selectElementStyles = css`
    ${defaultFontSpacing}
    appearance: none;
    padding: 8px 46px 8px 15px;
    font-family: ${getFontFamily('brenntagsans')};
    background: ${colors.white};
    border-radius: 0;
    color: ${colors.deepPurple};
    border: 1px solid;
    border-color: ${colors.neutral30};
    outline: 0;

    transition: border-color 200ms ease-out;

    &:hover {
        cursor: pointer;
        border-color: ${colors.deepPurple};
    }

    &:focus {
        outline: 3px solid;
        outline-color: ${colors.blue50};
        outline-offset: -3px;
    }

    &:disabled {
        cursor: initial;
        color: ${colors.neutral30};
        border-color: ${colors.neutral30};
    }

    & > option {
        background: ${colors.neutral10};
    }

    ::-ms-expand {
        display: none;
    }
    width: ${({ isRow }: SelectProps) => (isRow ? `100%` : '')}
        ${({ hasError }: SelectProps) =>
            hasError
                ? `
            &, &:focus, &:hover {
                background-color: ${colors.red10};
                outline: 2px solid;
                outline-color: ${colors.red70};
                outline-offset: -2px;
            }
    `
                : ''};
`

export const Select = styled.select`
    ${selectElementStyles}
`

export const SelectInput = styled(ReactSelect)`
    ${selectElementStyles}
    border: none;
    padding: 0;
`

export const IconWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    pointer-events: none;
    justify-content: center;
`

export const Icon: React.SFC = () => (
    <IconWrapper>
        <SvgIcon icon="Chevron_Down" size="20" fill={colors.neutral70} />
    </IconWrapper>
)
