import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const PieChart: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="PieChart" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M13 4.06V11H21.95C21.98 11.33 22 11.66 22 12V13H11V2H12C16.47 2 20.26 4.95 21.54 9H19.39C18.32 6.37 15.91 4.42 13 4.06ZM12 20C15.35 20 18.22 17.93 19.41 15H21.54C20.26 19.05 16.47 22 12 22C6.49 22 2 17.51 2 12C2 7.52996 4.95 3.73996 9 2.45996V4.58996C6.07 5.77996 4 8.64996 4 12C4 16.41 7.59 20 12 20Z"
                fill={fill === 'gradient' ? 'url(#PieChart_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="PieChart" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="PieChart" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M13 4.06V11H21.95C21.98 11.33 22 11.66 22 12V13H11V2H12C16.47 2 20.26 4.95 21.54 9H19.39C18.32 6.37 15.91 4.42 13 4.06ZM12 20C15.35 20 18.22 17.93 19.41 15H21.54C20.26 19.05 16.47 22 12 22C6.49 22 2 17.51 2 12C2 7.52996 4.95 3.73996 9 2.45996V4.58996C6.07 5.77996 4 8.64996 4 12C4 16.41 7.59 20 12 20Z"
                fill={fill === 'gradient' ? 'url(#PieChart_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="PieChart" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="PieChart" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M13 4.06V11H21.95C21.98 11.33 22 11.66 22 12V13H11V2H12C16.47 2 20.26 4.95 21.54 9H19.39C18.32 6.37 15.91 4.42 13 4.06ZM12 20C15.35 20 18.22 17.93 19.41 15H21.54C20.26 19.05 16.47 22 12 22C6.49 22 2 17.51 2 12C2 7.52996 4.95 3.73996 9 2.45996V4.58996C6.07 5.77996 4 8.64996 4 12C4 16.41 7.59 20 12 20Z"
                fill={fill === 'gradient' ? 'url(#PieChart_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="PieChart" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="PieChart" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M13 4.06V11H21.95C21.98 11.33 22 11.66 22 12V13H11V2H12C16.47 2 20.26 4.95 21.54 9H19.39C18.32 6.37 15.91 4.42 13 4.06ZM12 20C15.35 20 18.22 17.93 19.41 15H21.54C20.26 19.05 16.47 22 12 22C6.49 22 2 17.51 2 12C2 7.52996 4.95 3.73996 9 2.45996V4.58996C6.07 5.77996 4 8.64996 4 12C4 16.41 7.59 20 12 20Z"
                fill={fill === 'gradient' ? 'url(#PieChart_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="PieChart" />}
        </g>
    ),
}
