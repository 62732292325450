import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { useMutation } from '@apollo/react-hooks'

import { EmptyState, OxygenTemplate, useDocumentTitle } from '@bc/ui'
import { useFormatMessage } from '@frontend/components'
import ForgotPasswordMutation from '@frontend/graphql/shared/forgot-password.graphql'
import { isValidEmail } from '@frontend/utils'

import qs from 'query-string'

const ResetPassword = ({ location }: RouteComponentProps<any>) => {
    const t = useFormatMessage()
    const { email } = qs.parse(location.search.toLowerCase())

    const [forgotPassword] = useMutation(ForgotPasswordMutation)

    const resetPassword = async () => {
        if (isValidEmail({ value: email as string })) {
            try {
                await forgotPassword({ variables: { email } })
            } catch (err) {
                console.error(err)
            }
        }
    }

    useEffect(() => {
        resetPassword()
    }, [])

    useDocumentTitle(t('route:reset-password.label'))

    return (
        <OxygenTemplate>
            <EmptyState title={t('reset-password:title')} text={t('reset-password:body')} center />
        </OxygenTemplate>
    )
}

export default withRouter(ResetPassword)
