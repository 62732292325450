import styled from 'styled-components'

import { Button } from '@bc/ui/src/components/button'
import { colors, ColorValue, spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'

export type ModalType = 'dialog' | 'small' | 'large' | 'default'
export interface ModalTypeProps {
    type: ModalType
}

interface ModalColors {
    color: ColorValue
}

export interface ModalOpenProps {
    open: boolean
}

export const modalAnimationSpeed = 150

const paddingStyles = `    
    padding: ${spacing.xs}px;
    
    ${media.min('sm')} {
        padding: ${spacing.sm}px;
    }
`

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0.95);
    transition: visibility 0ms ease-out ${modalAnimationSpeed}ms, opacity ${modalAnimationSpeed}ms ease-out,
        transform ${modalAnimationSpeed}ms ease-out;

    z-index: 100;

    visibility: ${({ open }: ModalOpenProps) => (open ? 'visible' : 'hidden')};
    opacity: ${({ open }: ModalOpenProps) => (open ? 1 : 0)};
    transition-delay: ${({ open }: ModalOpenProps) => (open ? '0ms' : `visibility ${modalAnimationSpeed}ms`)};
    transform: ${({ open }: ModalOpenProps) => (open ? 'none' : 'scale(0.95)')};
`

export const ScrollBox = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
`

// This div is required because of scroll issues
export const ScrollBoxInner = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto 0;
    padding: 20px 10px;
    min-height: 100vh;
    align-items: center;
`

export const ContentContainer = styled.div`
    position: relative;
    width: 720px;
    max-width: 100%;

    ${({ type }: ModalTypeProps) =>
        type === 'dialog'
            ? `
          text-align: center;
        `
            : ''}

    ${media.min('md')} {
        width: ${({ type }: ModalTypeProps) => {
            switch (type) {
                case 'dialog':
                    return '480px'
                case 'large':
                    return '840px'
                default:
                    return '720px'
            }
        }};
    }
`

export const ContentOverflowBox = styled.div`
    border-radius: 4px;
    background-color: ${colors.white};
    box-shadow: 0 2px 4px 0 rgba(16, 31, 99, 0.1);
    display: flex;
    flex-direction: column;

    ${media.min('sm')} {
        flex-direction: row;
    }

    min-width: 360px;
    min-height: 270px;
`
interface ModalProportion {
    proportion?: string
}

type ModalSide = Partial<ModalColors & ModalTypeProps & ModalProportion>

export const Content = styled.div<ModalSide>`
    display: flex;
    flex-direction: column;
    flex: ${({ proportion = '1' }) => proportion};
    background: ${({ color = colors.white }) => color};
    ${paddingStyles}
`

export const Aside = styled.aside<ModalSide>`
    display: flex;
    flex-direction: column;
    flex: ${({ proportion = '1' }) => proportion};
    background: ${({ color = colors.white }) => color};
    width: 100%;

    ${media.min('sm')} {
        width: ${({ type = 'default' }) => (type === 'large' ? '50%' : '267px')};
    }

    ${paddingStyles}
`

export const Overlay = styled.div`
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    min-height: 100vh;
    opacity: 0.5;
    background-color: ${colors.neutral90};
    z-index: -1;
`

export const CloseOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
`

export const CloseButton = styled(Button)`
    position: absolute;
    color: ${colors.neutral90};
    top: 10px;
    right: 10px;
    z-index: 100;
    width: 35px;
    height: 35px;
`
