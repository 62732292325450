import React from 'react'
import styled, { keyframes } from 'styled-components'

import { colors } from '@bc/ui/src/config'

const NUMBER_OF_STRIPES = 5
interface LoaderProps {
    stripeColor?: keyof typeof colors
    size?: 'default' | 'small'
    centered?: boolean
}

export const Centered = styled.div`
    display: flex;
    justify-content: center;
`

const Wrapper = styled.div<LoaderProps>`
    position: relative;
    ${({ centered }) => (centered ? 'display: flex; justify-content: center;' : '')}
`

const rotate = keyframes`
    0% {
        transform: rotate(20deg);
    }
    50% {
        transform: rotate(-20deg);
    }
    100% {
        transform: rotate(20deg);
    }
`

const nthChildStyles = Array.from(Array(NUMBER_OF_STRIPES).keys()).reduce(
    (styles, index) => `
        ${styles}
        
        &:nth-child(${index + 1}) {
            animation-delay: -0.${index + 1}s
        }
    `,
    ``,
)

const Stripe = styled.div`
    animation: ${rotate} 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);

    ${nthChildStyles}

    width: ${({ size }: LoaderProps) => (size === 'default' ? 4 : 2)}px;
    height: ${({ size }: LoaderProps) => (size === 'default' ? 35 : 16)}px;
    margin: ${({ size }: LoaderProps) => (size === 'default' ? 8 : 4)}px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;

    background-color: ${({ stripeColor = 'deepPurple' }: LoaderProps) => colors[stripeColor]};
`

export const LegacyLoader: React.SFC<LoaderProps> = ({ stripeColor, size = 'default', centered }) => (
    <Wrapper centered={centered}>
        {Array(NUMBER_OF_STRIPES).map(() => (
            <Stripe stripeColor={stripeColor} size={size} />
        ))}
    </Wrapper>
)
