import moment from 'moment'
import React from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

import { defaultLocale, getLocaleIso, Locale, translations } from '@bc/translations'
import { InjectIntlContext } from '@frontend/components/hooks/react-intl/inject-intl-context'

export interface IntlProviderProps {
    locale?: Locale
    children: React.ReactNode
}

export const IntlProvider = ({ children, locale = defaultLocale }: IntlProviderProps) => {
    const iso = getLocaleIso(locale)
    moment.locale(iso)

    return (
        <ReactIntlProvider messages={translations[locale]} locale={iso} key={locale}>
            <InjectIntlContext>{children}</InjectIntlContext>
        </ReactIntlProvider>
    )
}
