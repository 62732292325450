import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Touchpoint: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Touchpoint" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M13 3V5H19V11H21V3H13ZM11 14C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12C10.4477 12 10 12.4477 10 13C10 13.5523 10.4477 14 11 14ZM10.9991 8C12.0091 8 12.9591 8.31 13.7491 8.83L15.5791 7H16.9891V8.41L15.1591 10.24C15.6791 11.03 15.9891 11.97 15.9891 12.99C15.9891 15.75 13.7491 17.99 10.9891 17.99C9.97906 17.99 9.02906 17.68 8.23906 17.16L3.69906 21.7L2.28906 20.29L6.82906 15.75C6.30906 14.96 5.99906 14.02 5.99906 13C5.99906 10.24 8.23906 8 10.9991 8ZM7.99906 13C7.99906 14.65 9.34906 16 10.9991 16C12.6491 16 13.9991 14.65 13.9991 13C13.9991 11.35 12.6491 10 10.9991 10C9.34906 10 7.99906 11.35 7.99906 13Z"
                fill={fill === 'gradient' ? 'url(#Touchpoint_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Touchpoint" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Touchpoint" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M13 3V5H19V11H21V3H13ZM11 14C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12C10.4477 12 10 12.4477 10 13C10 13.5523 10.4477 14 11 14ZM10.9991 8C12.0091 8 12.9591 8.31 13.7491 8.83L15.5791 7H16.9891V8.41L15.1591 10.24C15.6791 11.03 15.9891 11.97 15.9891 12.99C15.9891 15.75 13.7491 17.99 10.9891 17.99C9.97906 17.99 9.02906 17.68 8.23906 17.16L3.69906 21.7L2.28906 20.29L6.82906 15.75C6.30906 14.96 5.99906 14.02 5.99906 13C5.99906 10.24 8.23906 8 10.9991 8ZM7.99906 13C7.99906 14.65 9.34906 16 10.9991 16C12.6491 16 13.9991 14.65 13.9991 13C13.9991 11.35 12.6491 10 10.9991 10C9.34906 10 7.99906 11.35 7.99906 13Z"
                fill={fill === 'gradient' ? 'url(#Touchpoint_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Touchpoint" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Touchpoint" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M13 3V5H19V11H21V3H13ZM11 14C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12C10.4477 12 10 12.4477 10 13C10 13.5523 10.4477 14 11 14ZM10.9991 8C12.0091 8 12.9591 8.31 13.7491 8.83L15.5791 7H16.9891V8.41L15.1591 10.24C15.6791 11.03 15.9891 11.97 15.9891 12.99C15.9891 15.75 13.7491 17.99 10.9891 17.99C9.97906 17.99 9.02906 17.68 8.23906 17.16L3.69906 21.7L2.28906 20.29L6.82906 15.75C6.30906 14.96 5.99906 14.02 5.99906 13C5.99906 10.24 8.23906 8 10.9991 8ZM7.99906 13C7.99906 14.65 9.34906 16 10.9991 16C12.6491 16 13.9991 14.65 13.9991 13C13.9991 11.35 12.6491 10 10.9991 10C9.34906 10 7.99906 11.35 7.99906 13Z"
                fill={fill === 'gradient' ? 'url(#Touchpoint_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Touchpoint" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Touchpoint" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M13 3V5H19V11H21V3H13ZM11 14C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12C10.4477 12 10 12.4477 10 13C10 13.5523 10.4477 14 11 14ZM10.9991 8C12.0091 8 12.9591 8.31 13.7491 8.83L15.5791 7H16.9891V8.41L15.1591 10.24C15.6791 11.03 15.9891 11.97 15.9891 12.99C15.9891 15.75 13.7491 17.99 10.9891 17.99C9.97906 17.99 9.02906 17.68 8.23906 17.16L3.69906 21.7L2.28906 20.29L6.82906 15.75C6.30906 14.96 5.99906 14.02 5.99906 13C5.99906 10.24 8.23906 8 10.9991 8ZM7.99906 13C7.99906 14.65 9.34906 16 10.9991 16C12.6491 16 13.9991 14.65 13.9991 13C13.9991 11.35 12.6491 10 10.9991 10C9.34906 10 7.99906 11.35 7.99906 13Z"
                fill={fill === 'gradient' ? 'url(#Touchpoint_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Touchpoint" />}
        </g>
    ),
}
