import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const User_CSR: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="User_CSR" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M19.7 11.992H14v10h8v-7.7l-2.3-2.3zm.3 8h-4v-6h2v2h2v4zM5.5 6.491c0-2.481 2.019-4.5 4.5-4.5.879 0 1.693.263 2.386.7l-1.477 1.477A2.502 2.502 0 0 0 10 3.991c-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5 2.5-1.121 2.5-2.5c0-.321-.066-.626-.177-.909L13.8 4.105c.437.693.7 1.508.7 2.386 0 2.481-2.019 4.5-4.5 4.5s-4.5-2.018-4.5-4.5zm6.5 11.5v2H3v-8h5v2H5v4h7z"
                fill={fill === 'gradient' ? 'url(#User_CSR_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_CSR" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="User_CSR" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M19.7 11.992H14v10h8v-7.7l-2.3-2.3zm.3 8h-4v-6h2v2h2v4zM5.5 6.491c0-2.481 2.019-4.5 4.5-4.5.879 0 1.693.263 2.386.7l-1.477 1.477A2.502 2.502 0 0 0 10 3.991c-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5 2.5-1.121 2.5-2.5c0-.321-.066-.626-.177-.909L13.8 4.105c.437.693.7 1.508.7 2.386 0 2.481-2.019 4.5-4.5 4.5s-4.5-2.018-4.5-4.5zm6.5 11.5v2H3v-8h5v2H5v4h7z"
                fill={fill === 'gradient' ? 'url(#User_CSR_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_CSR" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="User_CSR" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M19.7 11.992H14v10h8v-7.7l-2.3-2.3zm.3 8h-4v-6h2v2h2v4zM5.5 6.491c0-2.481 2.019-4.5 4.5-4.5.879 0 1.693.263 2.386.7l-1.477 1.477A2.502 2.502 0 0 0 10 3.991c-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5 2.5-1.121 2.5-2.5c0-.321-.066-.626-.177-.909L13.8 4.105c.437.693.7 1.508.7 2.386 0 2.481-2.019 4.5-4.5 4.5s-4.5-2.018-4.5-4.5zm6.5 11.5v2H3v-8h5v2H5v4h7z"
                fill={fill === 'gradient' ? 'url(#User_CSR_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_CSR" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="User_CSR" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M19.7 11.992H14v10h8v-7.7l-2.3-2.3zm.3 8h-4v-6h2v2h2v4zM5.5 6.491c0-2.481 2.019-4.5 4.5-4.5.879 0 1.693.263 2.386.7l-1.477 1.477A2.502 2.502 0 0 0 10 3.991c-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5 2.5-1.121 2.5-2.5c0-.321-.066-.626-.177-.909L13.8 4.105c.437.693.7 1.508.7 2.386 0 2.481-2.019 4.5-4.5 4.5s-4.5-2.018-4.5-4.5zm6.5 11.5v2H3v-8h5v2H5v4h7z"
                fill={fill === 'gradient' ? 'url(#User_CSR_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_CSR" />}
        </g>
    ),
}
