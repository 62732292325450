import React, { useContext, useEffect, useMemo, useReducer } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { useApolloClient, useMutation } from '@apollo/react-hooks'

import graphqlUtils from '@bc/graphql-utils'
import {
    GQLBaseOrderLine,
    GQLCheckoutQueryMaterialInput,
    GQLCheckoutTerms,
    GQLMaterial,
    GQLMaterialPriceInput,
    GQLPrice,
    GQLPriceConditionsList,
    GQLPriceInput,
    GQLTerms,
    GTMBaseOrderData,
} from '@bc/types'
import { OverviewTemplate, PageTitle, useDocumentTitle } from '@bc/ui'

import {
    FormatDate,
    FormatMessage,
    useFormatDate,
    useFormatMessage,
    useFormatUom,
    useHasAccess,
} from '@frontend/components'
import {
    PlatformConfigContext,
    ShoppingCartContext,
    ShoppingCartContextData,
    ToastsContext,
    ToastsContextData,
    UserContext,
} from '@frontend/context'
import { getCurrentTax, parseDate, removeTypename } from '@frontend/utils'

import { DeliveryOverviewForm, DeliveryOverviewFormValues } from '@frontend/components/forms'
import { isDeliveryDateValid } from '@frontend/components/forms/_shared/validation'
import { GraphQLError } from 'graphql'
import { tagManager } from '@bc/gtm'
import { ShopFeatures } from '@bc/config'

import { CheckoutShoppingCart } from '../mutations'
import { GetPriceConditionsList } from '../queries'
import { ButtonContinueShopping, OverviewTemplateContentWrapper } from './components'
import { LineItems } from './elements'
import { EmptyCartPage } from './empty-cart-page'
import {
    createDisplayPriceSetAction,
    createPriceConditionsLoadAction,
    createPriceConditionsSetAction,
    createShippingSetAction,
    overviewPageReducer,
    OverviewPageState,
} from './overview-reducer'

const initialState: OverviewPageState = {
    isPricingShown: false,
    priceLoading: false,
}
const noPrices: GQLPrice[] = []

const OverviewPageComponent = ({ history, location }: RouteComponentProps) => {
    const t: FormatMessage = useFormatMessage()
    const d: FormatDate = useFormatDate()
    const formatUom = useFormatUom()
    const hasAccess = useHasAccess()

    const pageTitle = t('checkout:headline')
    useDocumentTitle(pageTitle)

    /* 
      Context, state & Custom hooks
    */
    const {
        appConfig: { deliveryDateRestrictions, dateFormat, taxes },
    } = useContext(PlatformConfigContext)
    const [, shoppingData]: ShoppingCartContextData<GQLBaseOrderLine> = useContext(ShoppingCartContext)
    const [toastsStore]: ToastsContextData = useContext(ToastsContext)
    const { currentCustomer, activeSalesOrganisation = '', activeSalesAreaId = '' } = useContext(UserContext)

    const [{ shipToId, deliveryDate, priceConditionsList, priceLoading, isPricingShown }, dispatch] = useReducer(
        overviewPageReducer,
        initialState,
    )

    const [checkoutShoppingCartMutation, { loading: isCheckoutLoading }] = useMutation<{
        checkoutShoppingCart: string
    }>(CheckoutShoppingCart)
    const client = useApolloClient()

    const firstOrderWithPricesDate: Date = graphqlUtils.order.getFirstEligibleDeliveryDate(
        deliveryDateRestrictions.reorderDate,
    )
    const lastOrderWithPricesDate: Date = graphqlUtils.order.getLastEligibleDeliveryDate(
        deliveryDateRestrictions.reorderEndDate,
    )

    useEffect(() => {
        // on product removed - need to remove the price from the list
        const prices = priceConditionsList?.filter(({ materialId }) => shoppingData.find(([id]) => id === materialId))
        if (prices) {
            dispatch(createPriceConditionsSetAction(prices))
        }
    }, [shoppingData])

    const materialsPrice: GQLMaterialPriceInput[] = useMemo(
        () =>
            shoppingData &&
            shoppingData.map(([, { material, quantityOrdered: { amount, uom } }]) => ({
                materialId: material!.id,
                quantity: amount,
                uom,
            })),
        [shoppingData],
    )

    const priceConditionsMapped = useMemo(
        () =>
            priceConditionsList?.reduce(
                (map: { [key: string]: GQLPrice[] }, { materialId, prices }: GQLPriceConditionsList) => {
                    map[materialId] = prices
                    return map
                },
                {},
            ),
        [priceConditionsList],
    )

    const getAsAgreedTerms = (): GQLCheckoutTerms => ({
        incoTermId: t('delivery-overview:INCO-terms-as-agreed'),
        incoTerms: t('delivery-overview:INCO-terms-as-agreed'),
        paymentTermId: t('delivery-overview:INCO-terms-as-agreed'),
        paymentTerms: t('delivery-overview:INCO-terms-as-agreed'),
    })

    const currentTerms: GQLCheckoutTerms | undefined = useMemo(() => {
        let customerTerms: GQLCheckoutTerms | undefined
        let customerTermsFull: Partial<GQLTerms> | undefined

        if (hasAccess(ShopFeatures.TermsPerDivision)) {
            const salesDivisions = priceConditionsList
                ?.reduce(
                    (allPrices, { prices }) => [...allPrices, ...(prices.length > 0 ? prices : [undefined])],
                    [] as GQLPrice[],
                )
                .map(p => p?.salesDivision)

            const isAllPricesOfSameDivision =
                salesDivisions && salesDivisions.every(Boolean) && new Set(salesDivisions).size === 1

            customerTermsFull =
                deliveryDate && !priceLoading
                    ? (priceConditionsList &&
                          isAllPricesOfSameDivision &&
                          isPricingShown &&
                          graphqlUtils.customer.getTerms(
                              currentCustomer,
                              activeSalesOrganisation,
                              salesDivisions && salesDivisions[0],
                          )) ||
                      getAsAgreedTerms()
                    : ({} as GQLCheckoutTerms)
        } else {
            customerTermsFull = graphqlUtils.customer.getTerms(
                currentCustomer,
                activeSalesOrganisation,
                activeSalesAreaId,
            )
        }

        if (customerTermsFull) {
            const { incoTermId, incoTerms, paymentTermId, paymentTerms } = customerTermsFull
            customerTerms = { incoTermId, incoTerms, paymentTermId, paymentTerms }
        }
        return customerTerms
    }, [priceConditionsList, currentCustomer, isPricingShown, deliveryDate, priceLoading])

    const isEligibleForPricingDate = (date: string | undefined): boolean =>
        !!date && isDeliveryDateValid(t, firstOrderWithPricesDate, lastOrderWithPricesDate, dateFormat, date)

    const updatePriceConditions = async (formDate: string, formShipToId: string): Promise<GQLPriceConditionsList[]> => {
        const date = parseDate(formDate, dateFormat)!
        const filterDateBetween = ({ validFrom, validUntil }: GQLPrice) =>
            date >= new Date(validFrom) && date <= new Date(validUntil)
        let prices: GQLPriceConditionsList[]

        try {
            const { data: newPriceData } = await client.query<{
                priceConditionsList: GQLPriceConditionsList[]
            }>({
                query: GetPriceConditionsList,
                variables: {
                    date,
                    materialsPrice,
                    shipToId: formShipToId,
                },
            })

            // validate dates in the prices and filter out not valid ones
            prices = newPriceData?.priceConditionsList.map(condition => ({
                ...condition,
                prices: condition.prices.filter(filterDateBetween),
            }))
        } catch (error) {
            handleGraphqlError(error)
            // return empty price conditions list
            prices = materialsPrice.map(({ materialId }) => ({ materialId, prices: [] }))
        }

        return prices
    }

    const checkPrices = async (formShipToId: string | undefined, formDeliveryDate: string | undefined) => {
        // Reset prices whenever shipping date or shipTo are updated
        const isElegibleDate = isEligibleForPricingDate(formDeliveryDate)
        dispatch(createDisplayPriceSetAction(isElegibleDate))

        if (formDeliveryDate && isElegibleDate) {
            let prices: GQLPriceConditionsList[] = []
            dispatch(createPriceConditionsLoadAction())
            if (formShipToId) {
                prices = await updatePriceConditions(formDeliveryDate, formShipToId)
            }
            dispatch(createPriceConditionsSetAction(prices))
        }
    }

    /*
      Update hook
    */
    const onUpdateForm = ({
        requestedDeliveryDate: formDeliveryDate,
        shipToId: formShipToId,
        pickup,
    }: DeliveryOverviewFormValues) => {
        formShipToId = pickup ? currentCustomer?.id : formShipToId
        if (formDeliveryDate === deliveryDate && formShipToId === shipToId) {
            return
        }

        dispatch(createShippingSetAction({ deliveryDate: formDeliveryDate, shipToId: formShipToId }))
        checkPrices(formShipToId, formDeliveryDate)
    }

    /*
      Checkout / Submit
    */

    const handleGraphqlError = ({
        graphQLErrors,
        networkError,
    }: {
        graphQLErrors: GraphQLError[]
        networkError: any
    }) => {
        if (graphQLErrors.length) {
            graphQLErrors?.map(({ message = '' }: Partial<GraphQLError>) => {
                toastsStore.addToast({
                    message,
                    type: 'error',
                })
            })
        } else if (networkError) {
            toastsStore.addToast({
                message: networkError.message,
                type: 'error',
            })
        }
    }

    const onSubmitForm = async (values: DeliveryOverviewFormValues) => {
        const mutationInputMaterials: GQLCheckoutQueryMaterialInput[] = shoppingData.map(
            ([
                materialId,
                {
                    material,
                    quantityOrdered: { amount: quantity },
                    uom,
                    uomConversionFactor,
                },
            ]) => {
                const pieces = graphqlUtils.material.getPieces(
                    material!,
                    quantity,
                    uom,
                    uomConversionFactor,
                    material!.palletSize,
                )
                const pricesWithType =
                    isPricingShown && priceConditionsMapped ? priceConditionsMapped[materialId] : noPrices
                const prices = (pricesWithType.length > 0
                    ? removeTypename(pricesWithType)
                    : noPrices) as GQLPriceInput[]
                return {
                    materialId,
                    pieces,
                    quantity,
                    uom,
                    isBulk: material!.isBulk,
                    prices,
                }
            },
        )

        const { termsAccepted, ...requiredValues }: DeliveryOverviewFormValues = values
        const materials: GQLMaterial[] = shoppingData.map(([, { material }]) => material!)
        const inputValues = { ...requiredValues, terms: currentTerms }
        try {
            const result = await checkoutShoppingCartMutation({
                variables: { materials: mutationInputMaterials, values: inputValues },
            })
            if (result && result.data) {
                const { checkoutShoppingCart: orderId } = result.data

                handlePurchase(orderId, values, materials, mutationInputMaterials)

                history.push(`${location.pathname}/success/${orderId}`, {
                    orderId,
                    orderDate: d(new Date().toString()),
                })
            }
        } catch (error) {
            handleGraphqlError(error)
        }
    }

    const getGTMECommerceData = (
        values: DeliveryOverviewFormValues,
        material: GQLMaterial,
        prices: GQLPriceInput[] | undefined,
        orderQuantity: number | undefined = 0,
        uom: string,
    ): GTMBaseOrderData => {
        const { customerPoReference, requestedDeliveryDate, shipToId: formShipToId } = values
        const packagingName = material.packaging?.name
        const packagingUom = formatUom(material.packagingQuantity?.uom ?? 'KGM')
        const orderUom = formatUom(uom ?? 'KGM')
        const orderAmount = material ? orderQuantity / material.packagingQuantity.amount : 0
        const selectedPrice = prices?.length ? prices[0] : undefined
        const pricePerUnit = selectedPrice
            ? selectedPrice.price.amount / (selectedPrice.priceUnit?.amount || 1)
            : undefined
        const currency = selectedPrice ? selectedPrice.price.currency : undefined
        const price = pricePerUnit ? parseFloat((pricePerUnit * orderQuantity).toFixed(2)) : undefined
        const taxPercentage = getCurrentTax(taxes) / 100
        const tax = price ? parseFloat((price * taxPercentage).toFixed(2)) : 0

        return {
            customerPoReference,
            requestedDeliveryDate,
            price,
            pricePerUnit,
            packagingName,
            packagingUom,
            orderUom,
            orderAmount,
            orderQuantity,
            currency,
            tax,
            shipToId: formShipToId,
        }
    }

    const handlePurchase = (
        uid: string,
        values: DeliveryOverviewFormValues,
        materials: GQLMaterial[],
        inputMaterials: GQLCheckoutQueryMaterialInput[],
    ) =>
        inputMaterials?.map(({ prices, quantity, uom }, index) =>
            tagManager.tags.basePurchase(
                uid,
                materials[index],
                getGTMECommerceData(values, materials[index], prices, quantity, uom),
            ),
        )

    const handleEmptyCartClick = () => history.replace(t('route:order-history'), location.state)

    if (!shoppingData.length) {
        return <EmptyCartPage onClick={handleEmptyCartClick} />
    }

    return (
        <OverviewTemplate>
            {{
                header: <PageTitle title={pageTitle} />,
                contentLeft: (
                    <OverviewTemplateContentWrapper>
                        <LineItems
                            shoppingCardExportedItems={shoppingData}
                            priceConditions={priceConditionsMapped}
                            isPriceLoading={priceLoading}
                            isPricingShown={isPricingShown}
                            deliveryDate={deliveryDate}
                        />
                        <ButtonContinueShopping to={t('route:order-history')}>
                            {t('checkout:button-continue-shopping')}
                        </ButtonContinueShopping>
                    </OverviewTemplateContentWrapper>
                ),
                contentRight: (
                    <DeliveryOverviewForm
                        onSubmit={onSubmitForm}
                        loading={isCheckoutLoading}
                        onUpdate={onUpdateForm}
                        terms={currentTerms}
                    />
                ),
            }}
        </OverviewTemplate>
    )
}

export const OverviewPage = withRouter(OverviewPageComponent)
