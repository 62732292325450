import { transition } from '@bc/ui/src/config'

/**
 * Possible transition states
 */
export enum TransitionStyles {
    'fade-up',
    'fade-down',
    'fade-down-small',
    'fade-in',
    'slide-down',
}
export enum TransitionStates {
    'entering',
    'entered',
    'exiting',
    'exited',
}
export type TransitionStateMap = { [key in keyof typeof TransitionStates]: string }
export type TransitionStyleMap = { [key in keyof typeof TransitionStyles]: TransitionStateMap }

/**
 * Fade Up transition styles
 */
export const transitionStyles: TransitionStyleMap = {
    'fade-up': {
        entering: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 0;
            transform: translateY(20px);
        `,
        entered: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 1;
            transform: translateY(0px);
        `,
        exiting: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 1;
            transform: translateY(0px);
        `,
        exited: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 0;
            transform: translateY(20px);
        `,
    },
    'fade-down': {
        entering: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 0;
            transform: translateY(-20px);
        `,
        entered: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 1;
            transform: translateY(0px);
        `,
        exiting: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 1;
            transform: translateY(0px);
        `,
        exited: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 0;
            transform: translateY(-20px);
        `,
    },
    'fade-down-small': {
        entering: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 0;
            transform: translateY(-5px);
        `,
        entered: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 1;
            transform: translateY(0px);
        `,
        exiting: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 1;
            transform: translateY(0px);
        `,
        exited: `
            transition: ${transition.config};
            transition-property: opacity, transform;
            opacity: 0;
            transform: translateY(-5px);
        `,
    },
    'fade-in': {
        entering: `
            transition: ${transition.config};
            transition-property: opacity;
            opacity: 0;
        `,
        entered: `
            transition: ${transition.config};
            transition-property: opacity;
            opacity: 1;
        `,
        exiting: `
            transition: ${transition.config};
            transition-property: opacity;
            opacity: 1;
        `,
        exited: `
            transition: ${transition.config};
            transition-property: opacity;
            opacity: 0;
        `,
    },
    'slide-down': {
        entering: `
            transition: ${transition.config};
            transition-property: opacity, max-height;
            opacity: 0;
            maxHeight: 0;
            overflow: hidden;
        `,
        entered: `
            transition: ${transition.config};
            transition-property: opacity, max-height;
            opacity: 1;
            maxHeight: 9999px;
            overflow: visible;
        `,
        exiting: `
            transition: ${transition.config};
            transition-property: opacity, max-height;
            opacity: 1;
            maxHeight: 9999px;
            overflow: visible;
        `,
        exited: `
            transition: ${transition.config};
            transition-property: opacity, max-height;
            opacity: 0;
            maxHeight: 0;
            overflow: hidden;
        `,
    },
}
