import React from 'react'

import styled from 'styled-components'

import { colors, SvgIcon, SvgIconName, SvgIconSize } from '@bc/ui'

interface WarningWrapperProps {
    bgColor?: string
    color?: string
    noPadding?: boolean
    width?: number
    height?: number
}
interface GenericWarningProps extends WarningWrapperProps {
    icon?: SvgIconName
    iconSize?: SvgIconSize
    iconColor?: string

    children: JSX.Element | string | number
}

const WarningWrapper = styled.div<WarningWrapperProps>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: ${({ noPadding }) => (noPadding ? 0 : 10)}px;
    margin: 5px 0px;
    ${({ bgColor }) => bgColor && 'background-color: ' + bgColor}
    ${({ color }) => color && 'color: ' + color}
    ${({ width }) => width && 'width: ' + width + 'px'}
    ${({
        height,
    }) => height && 'height: ' + height + 'px'}
`
const StyledSvgIcon = styled(SvgIcon)`
    margin-right: 10px;
    display: block;
`

export const GenericWarning = ({
    icon = 'Circle_Info',
    iconSize = '24',
    iconColor = colors.deepPurple,
    children,
    ...restProps
}: GenericWarningProps) => (
    <WarningWrapper {...restProps}>
        <div>
            <StyledSvgIcon icon={icon} size={iconSize} fill={iconColor} />
        </div>
        {children}
    </WarningWrapper>
)
