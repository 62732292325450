import { ApolloLink, Observable } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { ErrorResponse, onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'

import { Request } from 'express'

import { env } from '@frontend/config'
import { logException, UniCookie } from '@frontend/utils'

interface CreateApolloArgs {
    req?: Request
    onRateLimitError?: () => void
    onAuthError?: () => void
    onMaintenanceError?: () => void
}

const GRAPHQL_TIMEOUT_PERIOD = 60 * 1000

/**
 * @param root0
 * @param root0.networkError
 * @param root0.graphQLErrors
 * @example
 */
export function normalizeApolloErrors({ networkError, graphQLErrors }: ErrorResponse) {
    let authError = false
    let rateLimitError = false
    let maintenanceError = false

    if (graphQLErrors) {
        graphQLErrors.forEach(error => {
            logException(error)
            if (error.message === 'authentication is required') {
                authError = true
            }
            if (error.message === 'maintenance mode enabled') {
                maintenanceError = true
            }
        })
    }

    if (networkError) {
        // @ts-ignore => statuscode does exist!
        if (networkError.statusCode === 401 || networkError.statusCode === 403) {
            authError = true
        }

        // @ts-ignore => statuscode does exist!
        if (networkError.statusCode === 429) {
            rateLimitError = true
        }

        logException(networkError)
    }

    return {
        authError,
        rateLimitError,
        maintenanceError,
    }
}

/**
 * @param root0
 * @param root0.onAuthError
 * @param root0.onRateLimitError
 * @param root0.onMaintenanceError
 * @param root0.req
 * @example
 */
export function createSharedLinks({ onAuthError, onRateLimitError, onMaintenanceError, req }: CreateApolloArgs) {
    const addAuthLink = setContext((_, { headers }) => {
        // return the headers to the context so httpLink can read them
        const token = UniCookie.get('accessToken', req?.cookies)
        const selectedUser = UniCookie.get('activeCustomer', req?.cookies)
        const activeSalesOrganisation = UniCookie.get('activeSalesOrganisation', req?.cookies)
        const activeSalesAreaId = UniCookie.get('activeSalesAreaId', req?.cookies)

        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : '',
                'x-selected-company': selectedUser || '',
                'x-selected-sales-organisation': activeSalesOrganisation || '',
                'x-selected-sales-area-id': activeSalesAreaId || '',
            },
        }
    })

    const checkAuthLink = onError(errors => {
        const { authError, rateLimitError, maintenanceError } = normalizeApolloErrors(errors)

        if (authError && typeof onAuthError === 'function') {
            // Trigger the onAuthError handler and swallow the error.
            // This ensures that the default error handler isn't used
            // as we'll handle the error in the onAuthError callback.
            onAuthError()

            if (errors.response) {
                errors.response.errors = undefined
            }
        }

        if (rateLimitError && typeof onRateLimitError === 'function') {
            onRateLimitError()
        }

        if (maintenanceError && typeof onMaintenanceError === 'function') {
            onMaintenanceError()
        }
    })

    const timeoutLink = new ApolloLink((operation, forward) => {
        if (!forward) {
            return null
        }
        const chainObservable = forward(operation)
        return new Observable(observer => {
            const subscription = chainObservable.subscribe(
                (result: any) => {
                    clearTimeout(timer)
                    observer.next(result)
                    observer.complete()
                },
                (error: Error) => {
                    clearTimeout(timer)
                    observer.error(error)
                    observer.complete()
                },
            )
            const timer = setTimeout(() => {
                observer.error(new Error('Timeout exceeded'))
                subscription.unsubscribe()
            }, GRAPHQL_TIMEOUT_PERIOD)
            return () => {
                clearTimeout(timer)
                subscription.unsubscribe()
            }
        })
    })

    const uploadLink = createUploadLink({
        credentials: 'same-origin',
        uri: env.BE_URL ? env.BE_URL + '/graphql' : 'http://localhost:4000/graphql',
    })

    return {
        addAuthLink,
        checkAuthLink,
        timeoutLink,
        uploadLink,
    }
}

/**
 * @param options
 * @example
 */
export function createClientLinks(options: CreateApolloArgs) {
    const links = createSharedLinks(options)

    return ApolloLink.from(Object.values(links))
}

/**
 * @param options
 * @example
 */
export function createServerLinks(options: CreateApolloArgs) {
    const links = createSharedLinks(options)

    const serverRequestHeaderLink = setContext((_, { headers }) => ({
        headers: {
            ...headers,
            'x-is-server': 'true',
        },
    }))

    return ApolloLink.from([serverRequestHeaderLink, ...Object.values(links)])
}
