import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'
import { Button } from '../button'

/**
 * Component
 */
export const ToastCloseButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
    min-width: unset;
    height: unset;
    padding: 4px 6px;
    background: transparent;
    user-select: none;
    outline: none;
    border: none;
    color: ${colors.white};

    &:not(:disabled) {
        cursor: pointer;
    }
`
