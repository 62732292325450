import React from 'react'

import graphqlUtils from '@bc/graphql-utils'
import { GQLMaterial } from '@bc/types'
import { Column, Row, Text } from '@bc/ui'
import { useFormatMessage, useFormatNumber, useFormatPackaging, useFormatUom } from '@frontend/components/hooks'

interface ModalMaterialInfoProps {
    material: GQLMaterial
    children?: React.ReactNode
}

export const ModalMaterialInfoColumn = ({ material, children }: ModalMaterialInfoProps) => {
    const t = useFormatMessage()
    const formatNumber = useFormatNumber()
    const formatPackaging = useFormatPackaging()
    const formatUom = useFormatUom()

    return (
        <>
            <Row>
                <Column>
                    <Text h4 textTransform="none" data-test-id="text-material-name" noMargin>
                        {material.name}
                    </Text>
                    <Text caption noMargin>
                        {t('general:material-id')}
                        {': '}
                        <Text data-test-id="text-material-number">{material.id}</Text>
                    </Text>
                </Column>
            </Row>
            {material?.packaging?.name && (
                <Row>
                    <Column>
                        <Text caption noMargin>
                            {t('general:packaging')}
                        </Text>
                        <Text p data-test-id="text-packaging">
                            {graphqlUtils.material.getPackagingQuantityLabel(
                                material,
                                formatNumber(material.packagingQuantity.amount),
                                formatUom(material.packagingQuantity.uom),
                            )}
                        </Text>
                    </Column>
                </Row>
            )}
            {material.packaging && material.palletSize && (
                <Row>
                    <Column>
                        <Text caption noMargin>
                            {t('general:pallet')}
                        </Text>
                        <Text p data-test-id="text-pallet-size">
                            {formatNumber(material.palletSize)}{' '}
                            {formatPackaging(material.palletSize, material?.packaging?.type)}
                        </Text>
                    </Column>
                </Row>
            )}
            {children}
        </>
    )
}
