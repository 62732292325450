import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Change: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Change" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M3.06 11C3.56 6.51 7.38 3 12 3C13.83 3 15.55 3.55 17 4.51V6H15.58C14.52 5.37 13.3 5 12 5C8.48 5 5.57 7.61 5.08 11H3.06ZM15 10H21V4H19V8H15V10ZM3 20H5V16H9V14H3V20ZM18.92 13C18.43 16.39 15.52 19 12 19C10.7 19 9.48 18.63 8.42 18H7V19.49C8.45 20.45 10.17 21 12 21C16.62 21 20.44 17.49 20.94 13H18.92Z"
                fill={fill === 'gradient' ? 'url(#Change_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Change" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Change" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M3.06 11C3.56 6.51 7.38 3 12 3C13.83 3 15.55 3.55 17 4.51V6H15.58C14.52 5.37 13.3 5 12 5C8.48 5 5.57 7.61 5.08 11H3.06ZM15 10H21V4H19V8H15V10ZM3 20H5V16H9V14H3V20ZM18.92 13C18.43 16.39 15.52 19 12 19C10.7 19 9.48 18.63 8.42 18H7V19.49C8.45 20.45 10.17 21 12 21C16.62 21 20.44 17.49 20.94 13H18.92Z"
                fill={fill === 'gradient' ? 'url(#Change_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Change" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Change" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M3.06 11C3.56 6.51 7.38 3 12 3C13.83 3 15.55 3.55 17 4.51V6H15.58C14.52 5.37 13.3 5 12 5C8.48 5 5.57 7.61 5.08 11H3.06ZM15 10H21V4H19V8H15V10ZM3 20H5V16H9V14H3V20ZM18.92 13C18.43 16.39 15.52 19 12 19C10.7 19 9.48 18.63 8.42 18H7V19.49C8.45 20.45 10.17 21 12 21C16.62 21 20.44 17.49 20.94 13H18.92Z"
                fill={fill === 'gradient' ? 'url(#Change_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Change" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Change" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M3.06 11C3.56 6.51 7.38 3 12 3C13.83 3 15.55 3.55 17 4.51V6H15.58C14.52 5.37 13.3 5 12 5C8.48 5 5.57 7.61 5.08 11H3.06ZM15 10H21V4H19V8H15V10ZM3 20H5V16H9V14H3V20ZM18.92 13C18.43 16.39 15.52 19 12 19C10.7 19 9.48 18.63 8.42 18H7V19.49C8.45 20.45 10.17 21 12 21C16.62 21 20.44 17.49 20.94 13H18.92Z"
                fill={fill === 'gradient' ? 'url(#Change_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Change" />}
        </g>
    ),
}
