import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { setCookie } from 'react-use-cookie'

import { tagManager } from '@bc/gtm'
import { getUserLocale, MessageMap, translations } from '@bc/translations'
import { OxygenTemplate, useDocumentTitle, LoaderRound } from '@bc/ui'
import { AccountUnavailableError, LoginFailedError, UserMigratedError } from '@frontend/components'
import { SessionContext, SessionContextData, UserContext } from '@frontend/context'
import { AcceptTermsOfUsage } from '@frontend/pages/accept-terms/accept-terms'
import { logException } from '@frontend/utils'

import qs from 'query-string'
import { useApolloClient } from '@apollo/react-hooks'
import { GraphQLFormattedError } from 'graphql'
import { LOGIN_REDIRECT_PREFIX } from '../shared'
import { ResetPasswordToken } from '../e2e/generate-set-password-link/queries'

type LoginPageHandlerProps = RouteComponentProps<any>

const LoginPageHandlerClass = ({ location: { hash }, history }: LoginPageHandlerProps) => {
    const [hasError, setHasError] = useState(false)
    const { me, meLoading, error: meError, userHasMultipleCustomerEntities, userHasCustomers, isCsr } = useContext(
        UserContext,
    )
    const [sessionStore, sessionState]: SessionContextData = useContext(SessionContext)

    const client = useApolloClient()

    useDocumentTitle('login')
    const { state: redirectTo } = qs.parse(hash)

    async function handleLogin() {
        if (sessionStore.isAuthenticated) {
            setHasError(false)
        } else {
            try {
                await sessionStore.handleLogin()
            } catch (err) {
                tagManager.tags.login('Error')
                setHasError(true)
            }
        }
    }

    const isPasswordExpired = (expiryDate: string | undefined = '') => {
        const expiryTime = new Date(expiryDate).valueOf()
        return expiryDate ? new Date().valueOf() >= expiryTime : true
    }

    const handlePasswordExpired = async (email: string) => {
        const { data } = await client.mutate<{ resetPasswordToken: { link: string } }>({
            mutation: ResetPasswordToken,
            variables: {
                email,
            },
        })
        if (data?.resetPasswordToken?.link) {
            sessionStore.clearSessionNoNotify()
            const parsedUrl = qs.parseUrl(data.resetPasswordToken.link)
            return goToHrefUrl('route:set-password', parsedUrl.query)
        } else {
            return redirectToRoute('route:logout')
        }
    }

    useEffect(() => {
        if (!hash) {
            setHasError(true)
        }
        handleLogin()
    }, [hash])

    const getRedirectUrl = (route: keyof MessageMap, queryParams?: qs.ParsedQuery) => {
        const { locale: fallbackLocale } = sessionState
        const userLocale = getUserLocale(me?.meta, fallbackLocale, () => {
            logException(new Error('user has invalid locale config'), { extra: me })
        })
        const query = queryParams ? `?${qs.stringify(queryParams)}` : ''
        return `${translations[userLocale][route]}${query}`
    }

    const redirectToRoute = (route: keyof MessageMap, queryParams?: qs.ParsedQuery) => {
        history.replace(getRedirectUrl(route, queryParams))
    }

    const goToHrefUrl = (route: keyof MessageMap, queryParams?: qs.ParsedQuery) => {
        const path = getRedirectUrl(route, queryParams)
        window.location.replace(`${window.location.origin}${path}`)
    }

    const redirectToFirstURL = () =>
        !me?.meta.completedOnboarding
            ? redirectToRoute('route:account')
            : redirectTo && redirectTo.indexOf(LOGIN_REDIRECT_PREFIX) === 0 // IE does not support String.startsWith()
            ? history.replace(redirectTo.slice(LOGIN_REDIRECT_PREFIX.length) as string)
            : userHasMultipleCustomerEntities || isCsr
            ? redirectToRoute('route:my-customers')
            : redirectToRoute('route:order-history')

    const renderComponent = () => {
        // If something went wrong with the Auth0 callback
        if (hasError) {
            return <LoginFailedError />
        }
        // If the meQuery is in progress
        else if (meLoading) {
            return <LoaderRound size="medium" />
        }
        // If the meQuery has an error
        else if (!!meError) {
            const graphqlError: GraphQLFormattedError | undefined = meError?.graphQLErrors[0]
            let ErrorPage
            switch (graphqlError?.extensions?.code) {
                case 'UserMigratedToBEPError': {
                    ErrorPage = UserMigratedError
                    break
                }
                case 'CustomerIdNotValidError':
                default: {
                    ErrorPage = AccountUnavailableError
                    break
                }
            }
            return (
                <OxygenTemplate>
                    <ErrorPage error={graphqlError} />
                </OxygenTemplate>
            )
        }
        if (me) {
            // logged in and got user info - gtm events here, auth0 id first to strt trackign the session
            tagManager.tags.auth0UserId(me.meta.id)
            tagManager.tags.login('OK')
            // if password expired - we will redirect to the page
            if (isPasswordExpired(me.meta.passwordExpiresOn)) {
                handlePasswordExpired(me.meta.email)
            }

            // If the user has accepted the terms - to first URlk
            // if not - to accept terms form
            if (me.acceptedTerms) {
                // set language/country cookie here form the me.meta
                setCookie('locale', `${me.meta.language}-${me.meta.region}`)

                redirectToFirstURL()
            } else {
                return <AcceptTermsOfUsage />
            }

            // or if the meQuery succeeded, but the user has no customer data
            if (!(isCsr || userHasMultipleCustomerEntities || userHasCustomers)) {
                return <AccountUnavailableError error={meError} />
            }
        }

        return <LoaderRound size="medium" />
    }

    return <OxygenTemplate>{renderComponent()}</OxygenTemplate>
}

export const LoginHandlerPage = withRouter(LoginPageHandlerClass)
