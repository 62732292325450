import styled, { createGlobalStyle } from 'styled-components'

import { Button, colors, media, spacing, Text, transition } from '@bc/ui'

export const Header = styled.header`
    position: relative;
    padding: ${spacing.xs}px ${spacing.sm}px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.min('sm')} {
        padding: ${spacing.sm}px;
    }
`

export const HeaderTitle = styled(Text)`
    margin: 0;
    line-height: 1;
`

export const Content = styled.div`
    position: relative;
    background-color: ${colors.neutral10};
    flex: 1;
    overflow-x: hidden;
`

export const Footer = styled.footer`
    position: relative;
    padding: ${spacing.xs}px ${spacing.sm}px;
    display: flex;
    justify-content: flex-end;

    ${media.min('sm')} {
        padding: ${spacing.sm}px;
    }

    ${media.max('sm')} {
        margin-bottom: 50px; /* space for the feedbackbutton */
    }
`

export const SliderDots = styled.div`
    display: inline-flex;
`

export interface SliderDotProps {
    isActive: boolean
}

export const SliderDot = styled.button<SliderDotProps>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px ${colors.neutral20} solid;
    background-color: transparent;
    transform-origin: 50% 50%;
    transition: ${transition.easing};
    transition-duration: 150ms;
    transition-property: border-color, background-color, transform;
    cursor: pointer;
    outline: 0;

    &:not(:last-child) {
        margin-right: 8px;
    }

    &:hover {
        background-color: ${colors.neutral10};
        transform: scale(1.2);
    }

    &:active {
        transform: scale(1);
    }

    ${({ isActive }) =>
        isActive
            ? `
        border-color: ${colors.red70};
        background-color: ${colors.red70};

        &:hover {
            background-color: ${colors.red40};
            transform: none;
        }
    `
            : ''};
`

export interface SliderButtonProps {
    isVisible?: boolean
}

export const SliderButton = styled(Button)<SliderButtonProps>`
    &:not(:first-child) {
        margin-left: ${spacing.xs}px;
    }

    ${media.max('sm')} {
        flex: 1;
    }

    ${({ isVisible = true }) => `
        opacity: ${isVisible ? 1 : 0};
        cursor: ${isVisible ? 'pointer' : 'default'};
    `};
`

export const Slider = styled.div`
    position: relative;
    transition: ${transition.config};
    transition-property: transform;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    ${media.min('sm')} {
        height: 400px;
    }
`

export interface SlideProps {
    isActive: boolean
    index: number
}

export const Slide = styled.div<SlideProps>`
    position: absolute;
    width: 100%;
    transition: ${transition.config};
    transition-property: opacity;

    ${({ isActive, index }) => `
        opacity: ${isActive ? 1 : 0};
        left: ${index * 20}px;
        z-index: ${isActive ? 1 : 0};
    `} ${media.min('sm')} {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
    }
`

export const SlideImageWrapper = styled.div`
    position: relative;

    ${media.min('sm')} {
        flex: 1;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
`

export const SlideImage = styled.img`
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100%;
`

export const SlideContent = styled.div`
    flex: 1;
    padding: ${spacing.sm}px;

    ${media.min('sm')} {
        max-width: 50%;
    }
    & a {
        color: ${colors.deepPurple};
    }
`

export const ModalGlobalStyles = createGlobalStyle`
    .react-modal__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 200;
        background-color: rgba(214, 214, 214, 0.89);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .react-modal__content {
        position: relative;
        max-width: 840px;
        width: 100%;
        background: ${colors.white};
        overflow: auto;
        outline: 0;
    }

    ${media.max('sm')} {
        .react-modal__content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
        }
    }
`

export const Inquiry = styled.div`
    padding: ${spacing.sm}px;
    flex: 1;
`

export const InquiryCard = styled.div`
    background: ${colors.white};
    padding: ${spacing.xs}px ${spacing.sm}px;

    &:not(:last-child) {
        margin-bottom: 5px;
    }
`
