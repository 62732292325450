import React from 'react'

import { breakpoints } from '@bc/ui/src/config'

import { colors } from '@bc/ui'
import * as CardComponents from './card-components'
import { CardTheme } from './utils/config'

export interface CardProps {
    css?: string
    header?: JSX.Element
    footer?: JSX.Element
    theme?: CardTheme
    hiddenMax?: keyof typeof breakpoints
    hiddenMin?: keyof typeof breakpoints
    sidebarColor?: keyof typeof colors
}

export class Card extends React.Component<CardProps> {
    public static Wrapper = CardComponents.Wrapper
    public static Header = CardComponents.Header
    public static Body = CardComponents.Body
    public static Footer = CardComponents.Footer

    public render() {
        const { header, children, footer, theme = 'none', sidebarColor, ...rest } = this.props
        return (
            <Card.Wrapper cardTheme={theme} {...rest} sidebarColor={sidebarColor}>
                {header && <Card.Header cardTheme={theme}>{header}</Card.Header>}
                {children && <Card.Body cardTheme={theme}>{children}</Card.Body>}
                {footer && <Card.Footer cardTheme={theme}>{footer}</Card.Footer>}
            </Card.Wrapper>
        )
    }
}
