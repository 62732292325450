import React from 'react'

import { EmptyState } from '@bc/ui'
import { useFormatMessage } from '../hooks/intl-hooks'

interface ErrorProps {
    title: string
    text: string
    generic?: boolean
    children?: any
}

export const PageError = ({ title, text, generic, children }: ErrorProps) => {
    const t = useFormatMessage()

    console.group('BC-CONNECT APPLICATION ERROR!')
    console.log(`%c${title}`, 'font-weight:700;font-size:16px;color:#c00;')
    console.error(text)
    console.groupEnd()

    return generic ? (
        <EmptyState title={t('general:error.label')} text={t('general:error')} center>
            {children}
        </EmptyState>
    ) : (
        <EmptyState title={title} text={text} center>
            {children}
        </EmptyState>
    )
}
