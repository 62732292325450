import React, { ReactType } from 'react'

import {
    Hr,
    OrderedList,
    RichTextTable,
    RichTextTbody,
    RichTextTd,
    RichTextThead,
    RichTextTr,
    SquareList,
    StyledA,
    Text,
} from '@bc/ui'

interface Renderers {
    [x: string]: ReactType
}

export const markdownRenderers: Renderers = {
    paragraph: (props: any) => <Text p {...props} />,
    heading: (props: any) => {
        switch (props.level) {
            case 1:
                return <Text h4 {...props} />
            case 2:
                return <Text h5 {...props} />
            case 3:
                return <Text h6 {...props} />
        }
        return <Text h4 {...props} />
    },
    link: ({ href, ...rest }) => (
        <StyledA
            small={true}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            color="neutral90"
            borderColor="neutral90"
            {...rest}
        />
    ),
    listItem: ({ children }) => children,
    list: ({ ordered, children }: any) =>
        ordered ? (
            <OrderedList color="neutral90" items={children.map((child: JSX.Element) => child?.props?.children?.[0])} />
        ) : (
            <SquareList color="neutral90" items={children.map((child: JSX.Element) => child?.props?.children?.[0])} />
        ),

    thematicBreak: () => <Hr small />,
    table: (props: any) => <RichTextTable>{props.children}</RichTextTable>,
    tableHead: (props: any) => <RichTextThead>{props.children}</RichTextThead>,
    tableBody: (props: any) => <RichTextTbody>{props.children}</RichTextTbody>,
    tableRow: (props: any) => <RichTextTr>{props.children}</RichTextTr>,
    tableCell: (props: any) => <RichTextTd>{props.children}</RichTextTd>,
}
