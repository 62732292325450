import { config, salesOrganisationConfigDev } from '@bc/config'
import { RegionCode } from '@bc/translations'
import { GQLSalesOrganisationId, GQLUser } from '@bc/types'
import { UtilsEmailData } from './user'

export const getCsrEmailDevMode = (user: GQLUser, salesOrganisationId?: GQLSalesOrganisationId) => {
    let email: UtilsEmailData = getDefaultEmailCsr(user)
    if (salesOrganisationId) {
        const shopSalesOrg = salesOrganisationConfigDev[salesOrganisationId]
        if (shopSalesOrg) {
            email = { ...email, bcc: shopSalesOrg.bcc?.csrs.join(';'), to: shopSalesOrg.email.csr }
        }
    }

    return email
}

export const getUserEmailDevMode = (user: GQLUser, salesOrganisationId?: GQLSalesOrganisationId) => {
    let email: UtilsEmailData = getDefaultEmailUser(user)
    if (salesOrganisationId) {
        const shopSalesOrg = salesOrganisationConfigDev[salesOrganisationId]
        if (shopSalesOrg) {
            email = { ...email, bcc: shopSalesOrg.bcc?.users.join(';'), to: shopSalesOrg.email.user }
        }
    }

    return email
}

const getDefaultEmailCsr = (user: GQLUser) => ({ to: config[user.region as RegionCode].email.csr })

const getDefaultEmailUser = (user: GQLUser) =>
    user.region.includes('pl')
        ? { to: 'customer.test@digib.com', bcc: 'customertest@brenntag.pl' }
        : { to: 'test@digib.com' }
