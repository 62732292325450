import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { QueryResult } from '@apollo/react-common'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { debounce } from 'debounce'

import { tagManager } from '@bc/gtm'
import { MessageMap } from '@bc/translations'
import {
    GQLCustomer,
    GQLRequest,
    GQLRequestsFilters,
    GQLRequestStatus,
    GQLRequestType,
    GQLUpdateRequestInput,
} from '@bc/types'
import { FullScreenLoaderRound, SelectInputOption } from '@bc/ui'
import { useCopyToClipboard, useFormatMessage, useFormatNumber } from '@frontend/components'
import {
    FiltersContextData,
    FiltersProvider,
    PlatformConfigContext,
    SortContextData,
    SortProvider,
    UserContext,
} from '@frontend/context'

import { useFilters } from '@frontend/components/hooks/use-filters'
import { GetRequests, GetRequestsQueryResponse, UpdateRequest } from './queries'
import { RequestsPage } from './requests-page'

interface ExternalQuery {
    ext?: string
}

type RequestPageQuery = GQLRequestsFilters & ExternalQuery

const initialPage = 1
const initialFiltersPlaceholder = {
    status: [] as GQLRequestStatus[],
    type: [] as GQLRequestType[],
}

const queryToFilterByCustomer = (customer: GQLCustomer | undefined) => (query: RequestPageQuery) => {
    const { type: qType, ext, status: qStatus, ...rest } = query
    const initialFilters = getInitialFilters(customer)

    const type: GQLRequestType[] = (typeof qType === 'string' ? [qType] : qType) ?? (ext ? [] : initialFilters.type)
    const status: GQLRequestStatus[] =
        (typeof qStatus === 'string' ? [qStatus] : qStatus) ?? (ext ? [] : initialFilters.status)

    return {
        ...initialFilters,
        ...rest,
        type,
        status,
    }
}

const getInitialFilters = (customer?: GQLCustomer): GQLRequestsFilters => {
    if (customer) {
        initialFiltersPlaceholder.status = []
    } else {
        initialFiltersPlaceholder.status = [GQLRequestStatus.PENDING]
    }
    return initialFiltersPlaceholder
}

const RequestsPageContainer = () => {
    const t = useFormatMessage()
    const formatNumber = useFormatNumber()
    const { currentCustomer, activeCustomerId = '', activeSalesOrganisation = '', activeSalesAreaId = '' } = useContext(
        UserContext,
    )
    const { appConfig } = useContext(PlatformConfigContext)
    const copyToClipboard = useCopyToClipboard()

    const isCsrLayout = !currentCustomer
    const initialSort = isCsrLayout ? 'createdAt' : '-createdAt'
    const initialFilters: GQLRequestsFilters = getInitialFilters(currentCustomer)

    const { filters, page = initialPage, sort = initialSort, updateFilters } = useFilters<
        GQLRequestsFilters,
        RequestPageQuery
    >(queryToFilterByCustomer(currentCustomer))

    const filterRequestTypes = appConfig.filterRequestTypes

    const variables = {
        page,
        pageSize: 10,
        sort,
        filters,
    }
    const { error, loading, data }: QueryResult<GetRequestsQueryResponse> = useQuery(GetRequests, {
        variables,
        ssr: false,
        fetchPolicy: 'cache-and-network',
    })
    const requests = data?.requests?.requests
    const pagination = data?.requests?.pagination ?? {
        count: 0,
        page: 1,
        pages: 1,
    }
    const [updateRequest] = useMutation(UpdateRequest)

    useEffect(() => {
        // reset to first page if more than available results is requested in the url
        if (requests && page > pagination.pages) {
            updateFilters({ ...filters, sort, page: pagination.page })
        }
    }, [requests])

    let debounceCustomerName: any
    let debounceUid: any
    let debounceProductName: any

    const handleSortChange = (newSort: SelectInputOption) => {
        const { value } = newSort
        if (isCsrLayout) {
            tagManager.tags.csrRequestsSort(value)
        } else {
            tagManager.tags.requestsSort(value)
        }
        updateFilters({ ...filters, sort: value, page: initialPage })
    }

    const handlePageClick = (newPage: number) => {
        updateFilters({ ...filters, sort, page: newPage })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    const uidFilter = (filter: string) => {
        if (debounceUid) {
            debounceUid.clear()
        }
        debounceUid = debounce(() => {
            if (isCsrLayout) {
                tagManager.tags.csrRequestsSearchNumber(filter)
            } else {
                tagManager.tags.requestsSearchNumber(filter)
            }
        }, 1000)
        debounceUid()
    }

    const productNameFilter = (filter: string) => {
        if (debounceProductName) {
            debounceProductName.clear()
        }
        debounceProductName = debounce(() => {
            if (isCsrLayout) {
                tagManager.tags.csrRequestsFilterByProductName(filter)
            } else {
                tagManager.tags.requestsFilterByProductName(filter)
            }
        }, 1000)
        debounceProductName()
    }

    const customerNameFilter = (filter: string) => {
        if (debounceCustomerName) {
            debounceCustomerName.clear()
        }
        debounceCustomerName = debounce(() => {
            if (isCsrLayout) {
                tagManager.tags.crsRequestListFilterByCustomerName(filter)
            }
        }, 1000)
        debounceCustomerName()
    }

    const statusFilter = (filter: string) => {
        if (filter) {
            if (isCsrLayout) {
                tagManager.tags.csrRequestsFilter(filter)
            } else {
                tagManager.tags.requestsFilter(filter)
            }
        }
    }

    const handleFilterChange = (formFilters: GQLRequestsFilters) => {
        // Make a copy so we can add QUOTE type later without modifying form itself
        const newFilters = { ...formFilters, type: [...formFilters.type] }

        if (newFilters.uid !== filters.uid && newFilters.uid) {
            uidFilter(newFilters.uid)
        }

        if (newFilters.customer !== filters.customer && newFilters.customer) {
            customerNameFilter(newFilters.customer)
        }

        if (newFilters.status.length !== filters.status?.length) {
            newFilters.status.forEach(s => statusFilter(t(`request-status:${s}` as keyof MessageMap)))
        }

        // Make sure that we get both QUOTE and CART filters if CART is selected.
        // Because some customers can still have older QUOTES even though they started to use CART
        if (newFilters.type.includes(GQLRequestType.CART) && !newFilters.type.includes(GQLRequestType.QUOTE)) {
            newFilters.type.push(GQLRequestType.QUOTE)
        }

        if (newFilters.type.length !== filters.type?.length) {
            newFilters.type.forEach(typ => statusFilter(t(`request-type:${typ}` as keyof MessageMap)))
        }

        if (newFilters.material && newFilters.material !== filters.material) {
            productNameFilter(newFilters.material)
        }
        updateFilters({ ...newFilters, sort, page: initialPage })
    }

    const handleEditRequest = (request: GQLRequest, values: GQLUpdateRequestInput) =>
        updateRequest({
            variables: {
                requestUID: request.uid,
                values,
            },
            refetchQueries: ['GetRequests'],
        })

    const handleCopyRequestLink = (_formFilters: GQLRequestsFilters) => {
        // requests may not have customer info
        if (activeCustomerId && activeSalesOrganisation && activeSalesAreaId) {
            copyToClipboard(
                `${window.location.href}&cid=${activeCustomerId}&so=${activeSalesOrganisation}&sid=${activeSalesAreaId}&ext=1`,
            )
        } else {
            copyToClipboard(`${window.location.href}&ext=1`)
        }
    }

    const sortOptions: SelectInputOption[] = [
        {
            value: 'createdAt',
            label: `${t('sort-by-date')} ${t('sort-direction:date:asc')}`,
        },
        {
            value: '-createdAt',
            label: `${t('sort-by-date')} ${t('sort-direction:date:desc')}`,
        },
    ]

    const filterProviderProps: FiltersContextData<GQLRequestsFilters> = {
        onFilterChange: handleFilterChange,
        filters: initialFilters,
        query: filters,
        onLinkCopy: handleCopyRequestLink,
    }
    const sortProviderProps: SortContextData = {
        onSortChange: handleSortChange,
        sort,
        sortOptions,
    }

    return (
        <FiltersProvider {...filterProviderProps}>
            <SortProvider {...sortProviderProps}>
                <FullScreenLoaderRound isVisible={loading} />
                <RequestsPage
                    isCsrLayout={isCsrLayout}
                    error={error}
                    pagination={pagination}
                    requests={requests}
                    formatNumber={formatNumber}
                    onPageClick={handlePageClick}
                    onEditRequest={handleEditRequest}
                    filterRequestTypes={filterRequestTypes}
                />
            </SortProvider>
        </FiltersProvider>
    )
}

export default withRouter(RequestsPageContainer)
