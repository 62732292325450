import { Locale } from '@bc/translations'
import { UserIdTag } from '../types'
import { dataLayer } from '../utils'

export const userId = (
    id?: number,
    sapId?: string,
    shopId?: string,
    locale?: Locale,
    userType?: 'CSR' | 'Customer',
) => {
    dataLayer<UserIdTag>({
        event: 'userId',
        userId: id,
        sapId,
        userType,
        shopId,
        locale,
        status: id ? 'logged in' : 'not logged in',
    })
}
