import styled from 'styled-components'

import { colors, spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'

import { ToastType } from './toast'

interface ToastComponentProps {
    type?: ToastType
}

function setType(type?: ToastType): string {
    switch (type) {
        case 'success':
            return `
                background-color: ${colors.green70};
                color: ${colors.white};
            `
        case 'error':
            return `
                background-color: ${colors.violet70};
                color: ${colors.white};
            `
        default:
            return `
                background-color: ${colors.deepPurple};
                color: ${colors.white};
            `
    }
}

/**
 * Component
 */
export const ToastComponent = styled.div`
    position: relative;
    padding: ${spacing.xs}px ${spacing.sm}px;
    border-radius: 2px;
    max-width: calc(100vw - ${spacing.xs * 2}px);
    margin-top: ${spacing.xs}px;

    ${media.min('sm')} {
        padding: ${spacing.sm}px;
        margin-top: ${spacing.sm}px;
    }

    ${({ type }: ToastComponentProps) => setType(type)}
`
