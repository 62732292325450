import React from 'react'
import styled from 'styled-components'

import { Column, Container, Row } from '@bc/ui/src/components'
import { spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers/media'

interface FullWidthTemplateProps {
    children?: JSX.Element | JSX.Element[] | React.ReactNode
}

const ColumnContainer = styled(Container)`
    flex: 1 0 auto;
    padding: ${spacing.xxs}px ${spacing.sm}px;

    ${media.min('md')} {
        padding: 30px 50px 30px 50px;
    }
`

export const FullWidthTemplate = (props: FullWidthTemplateProps) => (
    <ColumnContainer>
        <Row>
            <Column>{props.children}</Column>
        </Row>
    </ColumnContainer>
)
