import styled from 'styled-components'

import { colors } from '@bc/ui/src/config'

export const List = styled.ol`
    position: relative;
    margin-bottom: 3rem;
    counter-reset: gradient-list;
`

export const ListItem = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    list-style: none;
    color: ${colors.deepPurple};

    &:before {
        counter-increment: gradient-list;
        content: counter(gradient-list);
        width: 50px;
        height: 50px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        color: ${colors.white};
        font-weight: 500;
        font-size: 3rem;
        margin-right: 1rem;
        background-color: ${colors.deepPurple};
        border-radius: 4px;
    }

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`
