import { useContext, useRef } from 'react'
import { useClipboard } from 'use-clipboard-copy'

import { ToastsContext } from '@frontend/context'

import { useFormatMessage } from './intl-hooks'

export interface UseCopyToClipboardOptions {
    onSuccess?: (text: string) => void
    onError?: (text: string) => void
    silent?: boolean
}
export const useCopyToClipboard = (options?: UseCopyToClipboardOptions) => {
    const { onSuccess, onError, silent = false } = options ?? {}
    const t = useFormatMessage()
    const [toastsStore] = useContext(ToastsContext)
    const clipboardText = useRef('')

    const handleSuccess = () =>
        onSuccess
            ? onSuccess(clipboardText.current)
            : !silent
            ? toastsStore.addToast({
                  type: 'success',
                  message: t('filters:copy-link-success'),
                  closeIn: 5000,
              })
            : undefined
    const handleError = () =>
        onError
            ? onError(clipboardText.current)
            : !silent
            ? toastsStore.addToast({
                  type: 'success',
                  message: `${t('filters:copy-link-error')} ${clipboardText.current}`,
                  closeIn: 10000,
              })
            : undefined

    const clipboard = useClipboard({
        onSuccess: handleSuccess,
        onError: handleError,
        selectOnError: false,
    })

    const copy = (text: string) => {
        clipboardText.current = text
        clipboard.copy(text)
    }

    return copy
}
