import React, { useState } from 'react'
import styled from 'styled-components'

import { Text } from '@bc/ui/src/components/text'
import { CounterTag } from '../counter-tag'
import * as SidebarComponents from './sidebar-components'

interface SidebarProps {
    count?: number
    title?: string
    children: JSX.Element | React.ReactNode | string
}

const useToggle = (initialToggle = false): [boolean, () => void] => {
    const [toggle, setToggle] = useState(initialToggle)
    const handleToggle = () => setToggle(isActive => !isActive)
    return [toggle, handleToggle]
}

const FilterHeading = styled(Text).attrs({ h6: true, noMargin: true })`
    padding-right: 8px;
`
const FilterCounterContainer = styled.div`
    display: flex;
    align-items: center;
    & > h6 {
        transform: translateY(-2px);
    }
`
export const Sidebar = ({ title, count, children }: SidebarProps) => {
    const [open, handleToggle] = useToggle(true)

    return (
        <>
            <SidebarComponents.Filter open={open}>
                <SidebarComponents.Wrapper>
                    <SidebarComponents.AnimatedToggleWrapper open={open}>
                        <FilterCounterContainer>
                            <FilterHeading>{title}</FilterHeading>
                            <CounterTag
                                count={count}
                                color="white"
                                bgColor="deepPurple"
                                className="filter-counter"
                                width="20px"
                                height="20px"
                            />
                        </FilterCounterContainer>
                        <SidebarComponents.Toggle open={open} onClick={handleToggle} />
                    </SidebarComponents.AnimatedToggleWrapper>

                    <SidebarComponents.Content open={open}>{children}</SidebarComponents.Content>
                </SidebarComponents.Wrapper>
            </SidebarComponents.Filter>
            <SidebarComponents.Toolbar>
                <SidebarComponents.ToolbarToggle open={open} onClick={handleToggle}>
                    <SidebarComponents.Toggle open={open} />
                    <FilterHeading>{title}</FilterHeading>
                    <CounterTag
                        count={count}
                        color="white"
                        bgColor="deePurple"
                        className="filter-counter"
                        width="20px"
                        height="20px"
                    />
                </SidebarComponents.ToolbarToggle>
            </SidebarComponents.Toolbar>
        </>
    )
}
