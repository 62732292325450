import React from 'react'

import { spacing, Text } from '@bc/ui'
import { useFormatMessage } from '@frontend/components'

import { Bullet, BulletList, BulletText, IntroText, LogoCompensation, WelcomeBullets } from './login-components'

/**
 * @example
 */
export default function LoginWelcome() {
    const t = useFormatMessage()

    const bullets = ['login:intro-bullet-1', 'login:intro-bullet-2', 'login:intro-bullet-3', 'login:intro-bullet-4']
    return (
        <>
            <LogoCompensation height={spacing.xl + 10} />
            <Text h3 fontWeight="bold" color="white" data-test-id="text-login-title">
                {t('login:welcome-header')}
            </Text>
            <IntroText p>{t('login:intro')}</IntroText>
            <IntroText p fontWeight="bold">
                {t('login:intro-benefit')}
            </IntroText>
            <WelcomeBullets>
                {bullets.map((b, index) => (
                    <BulletList key={index}>
                        <Bullet />
                        <BulletText noMargin>{t(b)}</BulletText>
                    </BulletList>
                ))}
            </WelcomeBullets>
        </>
    )
}
