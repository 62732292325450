import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const User_Check: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="User_Check" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M12.5 7.5C12.5 7.18 12.43 6.87 12.32 6.59L13.8 5.11C14.24 5.81 14.5 6.62 14.5 7.5C14.5 9.98 12.48 12 10 12C7.52 12 5.5 9.98 5.5 7.5C5.5 5.02 7.52 3 10 3C10.88 3 11.7 3.26 12.39 3.7L10.91 5.18C10.63 5.07 10.32 5 10 5C8.62 5 7.5 6.12 7.5 7.5C7.5 8.88 8.62 10 10 10C11.38 10 12.5 8.88 12.5 7.5ZM18.4991 8.91004L15.7891 6.21004L17.2091 4.79004L18.4991 6.09004L21.2891 3.29004L22.7091 4.71004L18.4991 8.91004ZM18 12V21H2V12H6V14H4V19H16V14H14V12H18Z"
                fill={fill === 'gradient' ? 'url(#User_Check_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_Check" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="User_Check" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M12.5 7.5C12.5 7.18 12.43 6.87 12.32 6.59L13.8 5.11C14.24 5.81 14.5 6.62 14.5 7.5C14.5 9.98 12.48 12 10 12C7.52 12 5.5 9.98 5.5 7.5C5.5 5.02 7.52 3 10 3C10.88 3 11.7 3.26 12.39 3.7L10.91 5.18C10.63 5.07 10.32 5 10 5C8.62 5 7.5 6.12 7.5 7.5C7.5 8.88 8.62 10 10 10C11.38 10 12.5 8.88 12.5 7.5ZM18.4991 8.91004L15.7891 6.21004L17.2091 4.79004L18.4991 6.09004L21.2891 3.29004L22.7091 4.71004L18.4991 8.91004ZM18 12V21H2V12H6V14H4V19H16V14H14V12H18Z"
                fill={fill === 'gradient' ? 'url(#User_Check_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_Check" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="User_Check" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M12.5 7.5C12.5 7.18 12.43 6.87 12.32 6.59L13.8 5.11C14.24 5.81 14.5 6.62 14.5 7.5C14.5 9.98 12.48 12 10 12C7.52 12 5.5 9.98 5.5 7.5C5.5 5.02 7.52 3 10 3C10.88 3 11.7 3.26 12.39 3.7L10.91 5.18C10.63 5.07 10.32 5 10 5C8.62 5 7.5 6.12 7.5 7.5C7.5 8.88 8.62 10 10 10C11.38 10 12.5 8.88 12.5 7.5ZM18.4991 8.91004L15.7891 6.21004L17.2091 4.79004L18.4991 6.09004L21.2891 3.29004L22.7091 4.71004L18.4991 8.91004ZM18 12V21H2V12H6V14H4V19H16V14H14V12H18Z"
                fill={fill === 'gradient' ? 'url(#User_Check_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_Check" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="User_Check" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M12.5 7.5C12.5 7.18 12.43 6.87 12.32 6.59L13.8 5.11C14.24 5.81 14.5 6.62 14.5 7.5C14.5 9.98 12.48 12 10 12C7.52 12 5.5 9.98 5.5 7.5C5.5 5.02 7.52 3 10 3C10.88 3 11.7 3.26 12.39 3.7L10.91 5.18C10.63 5.07 10.32 5 10 5C8.62 5 7.5 6.12 7.5 7.5C7.5 8.88 8.62 10 10 10C11.38 10 12.5 8.88 12.5 7.5ZM18.4991 8.91004L15.7891 6.21004L17.2091 4.79004L18.4991 6.09004L21.2891 3.29004L22.7091 4.71004L18.4991 8.91004ZM18 12V21H2V12H6V14H4V19H16V14H14V12H18Z"
                fill={fill === 'gradient' ? 'url(#User_Check_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_Check" />}
        </g>
    ),
}
