import React, { useEffect, useState } from 'react'

import { Button, StyledA, Text } from '@bc/ui'
import { FormatMessage } from '@frontend/components/hooks'

import * as Components from './cookiebar-components'

interface Props {
    cookiesAccepted: boolean
    setCookiesAccepted: () => void
    t: FormatMessage
}

export const Cookiebar = ({ cookiesAccepted, setCookiesAccepted, t }: Props): JSX.Element | null => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setIsOpen(cookiesAccepted)
    }, [])

    const acceptCookieConsent = () => {
        setIsOpen(false)
        setTimeout(() => {
            setCookiesAccepted()
        }, 600)
    }

    return cookiesAccepted ? (
        <Components.Wrapper isOpen={isOpen} data-test-id="box-cookie-notification">
            <Components.Content>
                <Components.Text>
                    <Text color="neutral90" noMargin>
                        {t('cookiebar:text1')}{' '}
                    </Text>
                    <Text color="neutral90" noMargin>
                        {t('cookiebar:text2')}{' '}
                        <StyledA color="neutral90" href={`http://brenntagconnect.com/en-de/privacy-policy.html`}>
                            {t('route:data-privacy-policy.label')}
                        </StyledA>
                        .
                    </Text>
                </Components.Text>
                <Button
                    onClick={acceptCookieConsent}
                    variant="action"
                    title={t('cookiebar:accept-button')}
                    data-test-id="button-cookie-accept">
                    {t('cookiebar:accept-button')}
                </Button>
            </Components.Content>
        </Components.Wrapper>
    ) : null
}
