import styled from 'styled-components'

import { colors, media, spacing, transition } from '@bc/ui'

export interface WrapperProps {
    isOpen: boolean
}

export const Wrapper = styled.aside<WrapperProps>`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    background: ${colors.neutral10};
    padding: ${spacing.xs}px;
    transform: ${({ isOpen }) => (isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(0, 100%, 0)')};
    transition: ${transition.config};
    transition-property: transform;

    ${media.min('sm')} {
        padding: ${spacing.sm}px;
    }
`

export const Content = styled.div`
    display: flex;
    margin: auto;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;

    ${media.min('sm')} {
        flex-direction: row;
        align-items: center;
    }
`

export const Text = styled.div`
    margin-bottom: 10px;
    margin-right: 0;

    ${media.min('sm')} {
        margin-right: 25px;
        margin-bottom: 0;
        max-width: 70%;
    }
`
