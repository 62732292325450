import { UomMapperFunction } from '../types'

export const CEE_PL_UomMapper: UomMapperFunction = (uom: string) => {
    switch (uom) {
        case 'KGM':
            return 'KG'
        case 'KGW':
        case 'KG%':
            return 'KG100%'
        case 'LTR':
            return 'L'
        case 'TST':
            return 'TSZT'
        case 'MTQ':
            return 'M3'
        case 'MTK':
            return 'M2'
        case 'PCE':
            return 'SZT'
        case 'TNE':
            return 'T'
        case 'GRM':
            return 'G'
        case 'TAG':
            return 'DNI'
        default:
            return uom
    }
}
export const CEE_EN_UomMapper: UomMapperFunction = (uom: string) => {
    switch (uom) {
        case 'KGM':
            return 'KG'
        case 'KGW':
        case 'KG%':
            return 'KG100%'
        case 'LTR':
            return 'L'
        case 'TST':
            return 'TPCS'
        case 'MTQ':
            return 'M3'
        case 'MTK':
            return 'M2'
        case 'PCE':
            return 'PCS'
        case 'TNE':
            return 'T'
        case 'GRM':
            return 'G'
        case 'TAG':
            return 'DAYS'
        default:
            return uom
    }
}
