import React from 'react'
import styled from 'styled-components'

import { Column, Row } from '@bc/ui/src/components/grid'
import { Text } from '@bc/ui/src/components/text'
import { spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'

export const ActionsColumn = styled(Column)`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ${media.max('sm')} {
        margin-top: ${spacing.xs}px;
    }
`

export const TitleColumn = styled(Column)`
    ${media.max('sm')} {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }
`

interface Props {
    title: string
    subtitle?: string
    children: React.ReactNode
}
const CenteredRow = styled(Row)`
    align-items: center;
`

export const RichRowHeader = ({ title, subtitle, children }: Props) => (
    <CenteredRow>
        <TitleColumn xs={12} md={8}>
            <Text h5 noMargin>
                {title}
            </Text>
            {subtitle && (
                <Text color="neutral50" noMargin>
                    {subtitle}
                </Text>
            )}
        </TitleColumn>
        {children && (
            <ActionsColumn xs={12} md={4}>
                {children}
            </ActionsColumn>
        )}
    </CenteredRow>
)
