import { auth0 } from '@frontend/utils/auth/install'

export const parseHash = (): Promise<auth0.Auth0DecodedHash> =>
    new Promise((resolve, reject) => {
        if (!auth0.client) {
            throw new Error()
        }

        auth0.client.parseHash((error: auth0.Auth0Error | null, result: auth0.Auth0DecodedHash | null) => {
            if (result && result.accessToken) {
                resolve(result)
            } else {
                reject(error)
            }
        })
    })
