import React from 'react'
import styled from 'styled-components'

import { colors, SvgIcon, Text, transition } from '@bc/ui'

export const DocumentContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const DocumentWrapper = styled.a`
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    text-decoration: none;
    color: ${colors.deepPurple};
    font-weight: 600;
    transition: ${transition.config};
    transition-property: color;

    padding-bottom: 10px;

    &:last-child {
        padding: 0;
    }

    &:hover {
        color: ${colors.deepPurple};
    }
`
const SvgIconDocument = styled(SvgIcon)`
    margin-right: 10px;
`

export const Document = ({
    text,
    ref: _ref,
    as: _as,
    ...rest
}: { text: string } & React.HTMLProps<HTMLAnchorElement>) => (
    <DocumentWrapper {...rest}>
        <SvgIconDocument icon="File_PDF" size="20" fill="currentColor" />
        <Text fontWeight={500} noMargin>
            {text}
        </Text>
    </DocumentWrapper>
)
