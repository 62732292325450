import { GQLGetMaterialsFilters } from './graphql-schema'

export interface BaseOrdersSearchObject {
    header?: { customerOrderNumber?: string; orderStatus?: string; shippedToPartyID?: string }
    orderLines?: { materialLineText?: string; materialType?: string; materialId?: string }
    customerId?: string
    orderId?: string
    customerPoReference?: string
    shipTo?: { partnerId?: string }
    orderStatus?: string
    materialName?: string
    materialId?: string[]
    salesOrganisation?: string
}

export enum PackagingIds {
    Spundfaß = 'Spundfaß',
    Karton = 'Karton',
    Deckelfaß = 'Deckelfaß',
    Sack = 'Sack',
    TKW = 'TKW',
    IBC = 'IBC',
    Kanister = 'Kanister',
    Muster = 'Muster',
    'Flasche/Kanne' = 'Flasche/Kanne',
    Beutel = 'Beutel',
    Eimer = 'Eimer',
    Trommel = 'Trommel',
    Dose = 'Dose',
    'Große Sack' = 'Große Sack',
}

export type MaterialsFilters = Omit<GQLGetMaterialsFilters, 'orderableFlag'>
