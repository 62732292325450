import { colors, transition } from '@bc/ui/src/config'
import { getFontFamily, media } from '@bc/ui/src/helpers'
import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styled from 'styled-components'
import { spacing } from '../../config'
import { styles } from '../button/button-styles'

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-shrink: 0;
    ${media.max('md')} {
        flex-direction: column;
    }
`

const ToggleWrapper = styled.button`
    cursor: pointer;
    border: 1px ${colors.neutral20} solid;
    border-radius: 2px;
    display: inline-block;
    line-height: 0;
    outline: 0;
    background: ${colors.white};
    box-shadow: 0 2px 4px 0 rgba(16, 31, 99, 0.05);
    padding: 1rem;
    transition: background ${transition.config};
    &:hover {
        background: ${colors.neutral10};
    }
`

const ToggleDot = styled.span`
    border-radius: 50%;
    display: inline-block;
    height: 3px;
    line-height: 3px;
    margin: 0 0.3rem;
    width: 3px;
    background: ${colors.red70};
`

interface ToggleProps {
    onClick: () => void
}

export const Toggle = ({ onClick }: ToggleProps) => (
    <ToggleWrapper {...onClick}>
        <ToggleDot />
        <ToggleDot />
        <ToggleDot />
    </ToggleWrapper>
)

export const Content = styled.li`
    display: block;
`

export interface ItemProps {
    disabled?: boolean
}

const defaultItemButtonStyles = `
    border: 0;
    text-align: left;
    display: flex;
    align-items: center;
    font-family: ${getFontFamily('brenntagsans')};
    padding: 1rem 2rem;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    transition: color ${transition.config}, transform ${transition.config};
    z-index: 1;
    flex-shrink: 0;

    & svg {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        transition: opacity ${transition.config};
    }
    &:hover {
        & svg {
            opacity: 1;
        }
    }

    ${styles.link}

    
`

const disabledStyles = `
    cursor: default;
    opacity: 0.5;
    color: ${colors.neutral30}

    &:hover {
        transform: none;
        z-index: 0;

        &:before {
            opacity: 0;
        };
    };
`
export const ItemLink = styled(NavLink)<ItemProps & NavLinkProps>`
    ${defaultItemButtonStyles}
    ${({ disabled }) => (disabled ? disabledStyles : '')}
`

export const ItemButton = styled.button`
    ${defaultItemButtonStyles}
    ${({ disabled }) => (disabled ? disabledStyles : '')}
`

export const ItemText = styled.span`
    ${defaultItemButtonStyles}
    ${disabledStyles}
`

export interface DropdownListProps {
    isOpen: boolean
    isNav?: boolean
    hasListPadding: boolean
}

const isOpenStyles = (isOpen: boolean | undefined, isNav: boolean | undefined): string => {
    if (isOpen) {
        return `
            opacity: 1;
            transform: scale(1) translate(0, 0);
            transition: opacity ${transition.config}, transform ${transition.config}, height ${
            transition.config
        }, padding ${transition.config};
            z-index: 5;
            pointer-events: all;
            ${media.max('md')} {
                height: ${isNav ? `100%` : 'auto'};
                padding: ${isNav ? `0 ${spacing.sm}px` : '0'};
               
            }
        `
    }
    return `
        opacity: 0;
        transform: scale(0.9) translate(10%, -10%);
        ${media.max('md')} {
            transform: scale(0.9) translate(10%, -10%);
            height: 0;
            margin-top: ${isNav ? 0 : ''};
        }
        transform-origin: 100% 0;
        transition: opacity ${transition.config}, transform ${transition.config}, z-index 0s ${
        transition.duration
    }, height 0s ${transition.config} ${transition.duration}, padding ${transition.config};
        z-index: -1;
        pointer-events: none;
        padding: 0;
        select {
            pointer-events: none;
        }
    `
}

export const Holder = styled(({ isNav, isOpen, onOutsideClick, hasListPadding, ...rest }) => <div {...rest} />)<
    DropdownListProps
>`
    background: ${colors.white};
    border: 1px ${colors.neutral20} solid;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    min-width: 240px;
    position: absolute;
    right: 0;
    top: 100%;
    display: block;
    box-sizing: border-box;
    margin: 2px 0px;
    padding: ${({ hasListPadding }) => (hasListPadding ? '1rem 0px' : '0')};
    transition: opacity 200ms ease-in-out 0s, transform 200ms ease-in-out 0s, height 200ms ease-in-out 0s,
        padding 200ms ease-in-out 0s;
    &:last-child {
        border-bottom: 0px;
    }

    ${({ isNav }) =>
        isNav
            ? `${media.max('md')} {
        display: flex;
        position: relative;
        width: calc(100vw + 7.5px);
        margin-left: ${spacing.sm * -1.5}px;
        background: ${colors.neutral10};
        box-shadow: none;
        border: none;
        flex-direction: column;
        min-width: 0 !important;
    }`
            : ``}
    ${({ isOpen, isNav }) => isOpenStyles(isOpen, isNav)}
`

export const List = styled.ul`
    flex: 1 0 0px;
`
