import { useContext, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { shouldTriggerVirtualPageview, tagManager } from '@bc/gtm'
import { getUserLocale } from '@bc/translations'
import { UserContext } from '@frontend/context'
import { GQLMe, GQLCustomer } from '@bc/types'

interface Props {
    children: JSX.Element
}

type CombinedProps = RouteComponentProps<any> & Props

const fireUserIdTag = (me: GQLMe, customer: GQLCustomer, isCsr: boolean) => {
    const userType = isCsr ? 'CSR' : 'Customer'
    const locale = getUserLocale(me.meta)

    tagManager.tags.userId(Number(customer.id), customer.sapId, customer.shopId, locale, userType)
}

const VirtualPageviewClass = ({ children, location }: CombinedProps) => {
    const { currentCustomer, me, isCsr } = useContext(UserContext)

    useEffect(() => {
        if (me) {
            tagManager.tags.auth0UserId(me.meta.id)
        }
    }, [me])

    useEffect(() => {
        if (me && currentCustomer) {
            fireUserIdTag(me, currentCustomer, isCsr)
        }
    }, [me, currentCustomer, isCsr])

    useEffect(() => {
        if (shouldTriggerVirtualPageview(location.pathname)) {
            tagManager.tags.virtualPageview()

            // Simple implementation for Heap, adjust when we extend the analytics
            window?.heap?.track('VirtualPageview', {
                virtualURL: window.location.href,
                virtualTitle: window.location.href,
            })
        }
    }, [location.pathname])

    return children
}

export const VirtualPageview = withRouter(VirtualPageviewClass)
