// tslint:disable no-invalid-template-strings

import { MessageMap } from '../'
import { en } from './base'

const messages: MessageMap = {
    ...en,

    // Overwrite translations keys for region/language specific differences (i.e. colors/colours)
}

export default messages
