import React from 'react'
import { NavLink } from 'react-router-dom'
import Headroom from 'react-headroom'
import styled from 'styled-components'

import {
    colors,
    getFontFamily,
    media,
    spacing,
    transition,
    SvgIcon,
    SvgComponentProps,
    CounterTag,
    CountTagProps,
} from '@bc/ui'

export const Wrapper = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 50;

    ${media.min('md')} {
        justify-content: space-between;
        flex-direction: row;
        padding-top: 0;
        padding-bottom: 0;
    }
`

export const NavWrapper = styled.div`
    display: flex;
    height: 50px;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background: ${colors.deepPurple};
    padding: 0 ${spacing.xxs}px;

    ${media.min('md')} {
        height: 60px;
        padding: 0 ${spacing.xl}px;
    }
`

export const NavUserWrapper = styled.div<{ extraPadding?: boolean }>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: ${colors.white};

    padding: ${spacing.xxs}px;
    ${media.min('md')} {
        padding: ${spacing.xxs}px ${spacing.xl}px;
    }
    ${({ extraPadding = false }) => (extraPadding ? `padding-top: ${40 + spacing.xxs}px` : ``)}
`

// Button holders
interface IconButtonsProps {
    isLogin?: boolean
}
export const IconButtons = styled.div`
    display: flex;
    height: 100%;
    width: ${({ isLogin }: IconButtonsProps) => (isLogin ? '100%' : 'auto')};
    justify-content: ${({ isLogin }: IconButtonsProps) => (isLogin ? 'flex-end' : 'inherit')};
    align-items: center;
    gap: ${spacing.sm}px;
`

// Button with icon
interface IconButtonProps {
    spacingRight?: boolean
}
export const IconButton = styled.button`
    position: relative;
    display: block;

    background: transparent;
    outline: 0;
    border: none;
    cursor: pointer;

    padding-right: ${({ spacingRight }: IconButtonProps) => (spacingRight ? `${spacing.xs}px` : undefined)};

    ${media.min('sm')} {
        padding-right: ${({ spacingRight }: IconButtonProps) => (spacingRight ? `${spacing.sm}px` : undefined)};
    }
`

const ShoppingCartCounterTag = styled(CounterTag)`
    ::after {
        left: 12px;
        top: 3px;
    }
`

export const ShoppingCartIndicator: React.FC<CountTagProps & SvgComponentProps> = ({
    size = '24',
    icon = 'Cart',
    fill = colors.neutral90,
    color,
    bgColor,
    count,
    ...svgProps
}) => (
    <>
        <ShoppingCartCounterTag count={count} bgColor={bgColor} color={color} />
        <SvgIcon size={size} icon={icon} fill={fill} {...svgProps} />
    </>
)
// Burger menu icon
export const BurgerMenu = styled(IconButton)`
    padding: 12px 0;
    display: flex;
    align-items: center;

    ${media.min('md')} {
        display: none;
    }
`

// Close button on mobile menu
export const CloseButton = styled(IconButton)`
    position: absolute;
    top: 0;
    left: 0;
    padding: ${spacing.xs}px;

    ${media.min('md')} {
        display: none;
    }
`

// Logo link, not on mobile
export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: ${spacing.sm}px;
    margin-left: 0;
    margin-right: auto;
`

// Links wrapper

interface LinksProps {
    open?: boolean
}
export const Links = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 100%;
    height: 100%;
    z-index: 100;

    transform: ${({ open }: LinksProps) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    transition: transform 150ms ease-out;

    background: ${colors.white};

    ${media.min('xs')} {
        justify-content: flex-start;
        padding: ${spacing.lg}px ${spacing.sm}px;
    }

    ${media.min('md')} {
        position: relative;
        flex-direction: row;
        flex: 1 0 auto;
        justify-content: flex-start;
        overflow: visible;
        width: auto;
        height: 100%;
        padding: 0;
        transform: translateX(0);
        background: transparent;
    }
`

// Link item
interface LinkProps {
    button?: boolean
    isOpen?: boolean
}

const linkStyles = `
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    padding: ${spacing.xs}px;
    font-family: ${getFontFamily('brenntagsans_bold')};
    text-decoration: none;
    transition: background-color ${transition.config}, color ${transition.config};

    &:hover {
        color: ${colors.white};
    }

    &.active {
        color: ${colors.blue50};
    }

    > div {
        position: relative;
        width: 24px;
        height: 24px;
    }

    ${media.min('xs')} {
        color: ${colors.deepPurple};
    }

    ${media.min('md')} {
        color: ${colors.white};
        padding-left: ${spacing.sm}px;
        padding-right: ${spacing.sm}px;

        &:hover {
            background-color: ${colors.neutral70};
            color: ${colors.white};
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 56px;
            left: 0;
            height: 4px;
            width: 100%;
            opacity: 0;
            background: linear-gradient(90deg,${colors.violet50} 0%, ${colors.blue50} 100%);
        }

        &.active {
            background-color: ${colors.deepPurple};
            color: ${colors.white};
            font-weight: bold;

            &:before {
                opacity: 1;
            }
        }
    }
`

export const Link = styled(NavLink)`
    ${linkStyles} &.active {
        &:after {
            opacity: ${({ button }: LinkProps) => (button ? 0 : 1)};
        }
    }

    &:hover {
        background: ${({ button }: LinkProps) => (button ? 'transparent !important' : colors.blue50)};
    }
`

export const IconLink = styled(NavLink)`
    height: 16px;
    line-height: 0;
    text-decoration: none;
    ${media.min('sm')} {
        height: 20px;
    }
`

interface DropdownButtonProps {
    isOpen: boolean
}
export const DropdownButton = styled.button`
    svg {
        transform: ${({ isOpen }: DropdownButtonProps) => (isOpen ? 'rotate(180deg)' : 'none')};
        transition: transform ${transition.config};

        polygon {
            fill: currentColor;
            transition: fill ${transition.config};
        }
    }
`

export const ProfileButton = styled.button`
    display: flex;
    cursor: pointer;
    height: 100%;
    align-items: center;
    svg:not(:first-child) {
        transform: ${({ isOpen }: DropdownButtonProps) => (isOpen ? 'rotate(180deg)' : 'none')};
        transition: ${transition.duration} ${transition.easing} 0s,
            transform ${transition.duration} ${transition.easing} 0s;
    }
    font-family: ${getFontFamily('brenntagsans')};
`

export const ProfileText = styled.span`
    padding: 0 ${spacing.xxs}px;
    ${media.max('sm')} {
        display: none;
    }
`

interface DropdownButtonProps {
    isOpen: boolean
}

export const ToggleButton = styled(ProfileButton)`
    background: ${({ isOpen }: DropdownButtonProps) => (isOpen ? colors.white : 'inherit')};
    color: ${({ isOpen }: DropdownButtonProps) => (isOpen ? colors.neutral70 : colors.neutral70)};
    padding: 4px 10px;
    svg {
        g {
            path {
                transition: ${transition.duration} ${transition.easing} 0s,
                    transform ${transition.duration} ${transition.easing} 0s;
                fill: ${({ isOpen }: DropdownButtonProps) => (isOpen ? colors.neutral70 : colors.neutral70)};
            }
        }
    }
    &:hover {
        color: ${colors.neutral70};
        background: ${colors.white};
        svg {
            g {
                path {
                    fill: ${colors.neutral70};
                }
            }
        }
    }
`

export const RightDropdownWrapper = styled.div`
    margin: 0 0 0 20px;
    z-index: 15;
`

export const Intro = styled.div`
    padding: 10px;
    border-bottom: 1px solid ${colors.neutral30};
`

export const CompanyDropdown = styled.div`
    max-height: 455px;
    overflow-y: auto;
    overflow-x: hidden;
    ${media.max('md')} {
        max-height: 440px;
    }
`

export const LoaderWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
    display: flex;
    background: white;
    justify-content: center;
    align-items: center;
    z-index: 1;
`
export const SlidingHeadroom = styled(Headroom).attrs(() => ({
    disableInlineStyles: true,
}))`
    z-index: 5;
    & div[class*='headroom'] {
        position: absolute;
        ${({ pinStart }) => `top: ${pinStart}px`};
        left: 0;
        right: 0;
    }
    & div[class*='headroom--unfixed'],
    & div[class*='headroom--pinned'] {
        transform: translateY(0px);
    }
    & div[class*='headroom--scrolled'] {
        transition: transform 500ms ease;
    }
    & div[class*='headroom--unpinned'] {
        transform: translateY(-100%);
    }
`
