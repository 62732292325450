import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Warehouse: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Warehouse" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M20 20.9998H22V8.4598L12 1.7998L2 8.4598V20.9998H4V9.5398L12 4.1998L20 9.5398V20.9998ZM18 21H16V13H8V21H6V11H18V21ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16ZM13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20C11 19.4477 11.4477 19 12 19C12.5523 19 13 19.4477 13 20Z"
                fill={fill === 'gradient' ? 'url(#Warehouse_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Warehouse" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Warehouse" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M20 20.9998H22V8.4598L12 1.7998L2 8.4598V20.9998H4V9.5398L12 4.1998L20 9.5398V20.9998ZM18 21H16V13H8V21H6V11H18V21ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16ZM13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20C11 19.4477 11.4477 19 12 19C12.5523 19 13 19.4477 13 20Z"
                fill={fill === 'gradient' ? 'url(#Warehouse_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Warehouse" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Warehouse" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M20 20.9998H22V8.4598L12 1.7998L2 8.4598V20.9998H4V9.5398L12 4.1998L20 9.5398V20.9998ZM18 21H16V13H8V21H6V11H18V21ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16ZM13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20C11 19.4477 11.4477 19 12 19C12.5523 19 13 19.4477 13 20Z"
                fill={fill === 'gradient' ? 'url(#Warehouse_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Warehouse" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Warehouse" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M20 20.9998H22V8.4598L12 1.7998L2 8.4598V20.9998H4V9.5398L12 4.1998L20 9.5398V20.9998ZM18 21H16V13H8V21H6V11H18V21ZM13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16ZM13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20C11 19.4477 11.4477 19 12 19C12.5523 19 13 19.4477 13 20Z"
                fill={fill === 'gradient' ? 'url(#Warehouse_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Warehouse" />}
        </g>
    ),
}
