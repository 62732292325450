import React from 'react'
import styled from 'styled-components'

import { TextHighlight } from '@bc/ui/src/components/highlight'
import { Text } from '@bc/ui/src/components/text'
import { spacing } from '@bc/ui/src/config'
import { media } from '@bc/ui/src/helpers'

import { Tooltip } from '../tooltip'

export interface RichRowListItemProps<Input = string | number | JSX.Element> {
    label?: string
    value: Input
    tooltip?: JSX.Element
    icon?: JSX.Element
    id?: string
    highlight?: string | string[]
}

interface Props {
    list: RichRowListItemProps[]
}

const List = styled.ul`
    display: flex;
    width: 100%;

    ${media.max('md')} {
        flex-wrap: wrap;
    }
    &:not(:last-child) {
        margin-bottom: ${spacing.xs}px;
    }
`

const StyledListItem = styled.li`
    display: block;
    flex: 1;

    ${media.max('sm')} {
        width: 100%;
        flex: auto;
        margin-top: ${spacing.xs}px;
    }
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    ${media.min('sm')} {
        &:first-child {
            margin-left: 0px;
        }
        &:last-child {
            margin-right: 0px;
        }
    }
`

const Wrapper = styled.div`
    display: flex;
`

const Icon = styled.div`
    align-items: center;
    padding: 0 ${spacing.sm}px 0 0;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
`

const ListItemContent = ({ label, value, id, tooltip, highlight }: RichRowListItemProps) => (
    <Content>
        {label && (
            <Text caption noMargin>
                <Wrapper>
                    {label} {tooltip && <Tooltip placement={'top-start'}>{tooltip}</Tooltip>}
                </Wrapper>
            </Text>
        )}
        <Text noMargin data-test-class={`text-${id}`}>
            {typeof value === 'string' ? <TextHighlight search={highlight}>{value}</TextHighlight> : value}
        </Text>
    </Content>
)

const ListItem = ({ icon, ...props }: RichRowListItemProps) => (
    <StyledListItem>
        {icon ? (
            <Wrapper>
                <Icon>{icon}</Icon>
                <ListItemContent {...props} />
            </Wrapper>
        ) : (
            <ListItemContent {...props} />
        )}
    </StyledListItem>
)

export const RichRowList = ({ list }: Props) => (
    <List>
        {list.map((listItem, index) => (
            <ListItem key={`${listItem.label}${index}`} {...listItem} />
        ))}
    </List>
)
