import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Edit: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Edit" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M12 6.59L17 1.59L22.41 7L13.41 16H8V10.59L10.59 8L12 9.41L10 11.41V14H12.59L19.59 7L17 4.41L13.41 8L12 6.59ZM19 19V13.24L21 11.24V21H3V3H12.76L10.76 5H5V19H19Z"
                fill={fill === 'gradient' ? 'url(#Edit_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Edit" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Edit" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M12 6.59L17 1.59L22.41 7L13.41 16H8V10.59L10.59 8L12 9.41L10 11.41V14H12.59L19.59 7L17 4.41L13.41 8L12 6.59ZM19 19V13.24L21 11.24V21H3V3H12.76L10.76 5H5V19H19Z"
                fill={fill === 'gradient' ? 'url(#Edit_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Edit" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Edit" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M12 6.59L17 1.59L22.41 7L13.41 16H8V10.59L10.59 8L12 9.41L10 11.41V14H12.59L19.59 7L17 4.41L13.41 8L12 6.59ZM19 19V13.24L21 11.24V21H3V3H12.76L10.76 5H5V19H19Z"
                fill={fill === 'gradient' ? 'url(#Edit_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Edit" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Edit" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M12 6.59L17 1.59L22.41 7L13.41 16H8V10.59L10.59 8L12 9.41L10 11.41V14H12.59L19.59 7L17 4.41L13.41 8L12 6.59ZM19 19V13.24L21 11.24V21H3V3H12.76L10.76 5H5V19H19Z"
                fill={fill === 'gradient' ? 'url(#Edit_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Edit" />}
        </g>
    ),
}
