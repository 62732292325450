import React, { useContext, useEffect } from 'react'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'

import { useQuery } from '@apollo/react-hooks'
import qs from 'query-string'

import { FullScreenLoaderRound } from '@bc/ui'
import { useFormatMessage } from '@frontend/components/hooks'
import { PlatformConfigContext, UserContext } from '@frontend/context'

import { getFirstEmailFromQuery } from '@frontend/utils'
import { FindUserByEmail } from './queries'

type LoginOrRegisterPageProps = RouteComponentProps<any>

const LoginOrRegister = ({ location, history }: LoginOrRegisterPageProps) => {
    const t = useFormatMessage()
    const { me } = useContext(UserContext)
    const {
        appConfig: { brenntagConnectUrl },
    } = useContext(PlatformConfigContext)
    const { email, locale } = qs.parse(location.search.toLowerCase())
    const oneEmail = getFirstEmailFromQuery(email)
    const { loading, data } = useQuery(FindUserByEmail, {
        variables: { email: oneEmail },
        skip: Boolean(oneEmail) === false,
    })

    useEffect(() => {
        if (data) {
            const { userByEmail } = data

            if (userByEmail) {
                let redirect = `/login?email=${oneEmail}`
                if (!Boolean(userByEmail.loginsCount)) {
                    redirect = `${t('route:reset-password')}?email=${oneEmail}`
                }
                history.push(redirect)
            } else {
                const redirect = `${brenntagConnectUrl}/${locale ?? 'de-de'}/faq`
                window.location.href = redirect
            }
        }
    }, [data, history, oneEmail, t])

    if (me) {
        return <Redirect to="/" />
    }

    if (loading) {
        return <FullScreenLoaderRound />
    }

    return null
}

export const LoginOrRegisterPage = withRouter(LoginOrRegister)
