import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const Arrow_Right: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="Arrow_Right" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M12.29 19.29L13.71 20.71L22.41 12L13.71 3.29L12.29 4.71L19.59 12L12.29 19.29ZM15.76 11L16.76 12L15.76 13H2V11H15.76Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Right_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Right" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="Arrow_Right" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M12.29 19.29L13.71 20.71L22.41 12L13.71 3.29L12.29 4.71L19.59 12L12.29 19.29ZM15.76 11L16.76 12L15.76 13H2V11H15.76Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Right_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Right" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="Arrow_Right" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M12.29 19.29L13.71 20.71L22.41 12L13.71 3.29L12.29 4.71L19.59 12L12.29 19.29ZM15.76 11L16.76 12L15.76 13H2V11H15.76Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Right_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Right" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="Arrow_Right" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M12.29 19.29L13.71 20.71L22.41 12L13.71 3.29L12.29 4.71L19.59 12L12.29 19.29ZM15.76 11L16.76 12L15.76 13H2V11H15.76Z"
                fill={fill === 'gradient' ? 'url(#Arrow_Right_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="Arrow_Right" />}
        </g>
    ),
}
