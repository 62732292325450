import React from 'react'
import { SvgIconMap, SvgIconName } from '../types'
const defaultFill = '#1A0033'

interface GradientProps {
    name: SvgIconName
}
const Gradient = ({ name }: GradientProps) => (
    <defs>
        <linearGradient id={`${name}_gradient`} x1="0" x2="24" y1="24" y2="0" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C815E0" />
            <stop offset="1" stopColor="#0C72ED" />
        </linearGradient>
    </defs>
)

export const User_Viewer: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <g id="User_Viewer" fillRule="evenodd" clipRule="evenodd" transform="scale(1.3333333333333333)">
            <path
                d="M5.5 6.491c0-2.481 2.019-4.5 4.5-4.5.879 0 1.693.263 2.386.7l-1.477 1.477A2.502 2.502 0 0 0 10 3.991c-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5 2.5-1.121 2.5-2.5c0-.321-.066-.626-.177-.909L13.8 4.105c.437.693.7 1.508.7 2.386 0 2.481-2.019 4.5-4.5 4.5s-4.5-2.018-4.5-4.5zM5 18v-4h3v-2H3v8h8.528a5.977 5.977 0 0 1-1.033-2H5zm16.957 2.594l-1.414 1.414-2.274-2.274a3.96 3.96 0 0 1-2.019.567c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4c0 .74-.215 1.424-.567 2.019l2.274 2.274zm-3.707-4.292c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2 2-.898 2-2z"
                fill={fill === 'gradient' ? 'url(#User_Viewer_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_Viewer" />}
        </g>
    ),
    '24': ({ fill = defaultFill }) => (
        <g id="User_Viewer" fillRule="evenodd" clipRule="evenodd">
            <path
                d="M5.5 6.491c0-2.481 2.019-4.5 4.5-4.5.879 0 1.693.263 2.386.7l-1.477 1.477A2.502 2.502 0 0 0 10 3.991c-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5 2.5-1.121 2.5-2.5c0-.321-.066-.626-.177-.909L13.8 4.105c.437.693.7 1.508.7 2.386 0 2.481-2.019 4.5-4.5 4.5s-4.5-2.018-4.5-4.5zM5 18v-4h3v-2H3v8h8.528a5.977 5.977 0 0 1-1.033-2H5zm16.957 2.594l-1.414 1.414-2.274-2.274a3.96 3.96 0 0 1-2.019.567c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4c0 .74-.215 1.424-.567 2.019l2.274 2.274zm-3.707-4.292c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2 2-.898 2-2z"
                fill={fill === 'gradient' ? 'url(#User_Viewer_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_Viewer" />}
        </g>
    ),
    '16': ({ fill = defaultFill }) => (
        <g id="User_Viewer" fillRule="evenodd" clipRule="evenodd" transform="scale(0.6666666666666666)">
            <path
                d="M5.5 6.491c0-2.481 2.019-4.5 4.5-4.5.879 0 1.693.263 2.386.7l-1.477 1.477A2.502 2.502 0 0 0 10 3.991c-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5 2.5-1.121 2.5-2.5c0-.321-.066-.626-.177-.909L13.8 4.105c.437.693.7 1.508.7 2.386 0 2.481-2.019 4.5-4.5 4.5s-4.5-2.018-4.5-4.5zM5 18v-4h3v-2H3v8h8.528a5.977 5.977 0 0 1-1.033-2H5zm16.957 2.594l-1.414 1.414-2.274-2.274a3.96 3.96 0 0 1-2.019.567c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4c0 .74-.215 1.424-.567 2.019l2.274 2.274zm-3.707-4.292c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2 2-.898 2-2z"
                fill={fill === 'gradient' ? 'url(#User_Viewer_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_Viewer" />}
        </g>
    ),
    '12': ({ fill = defaultFill }) => (
        <g id="User_Viewer" fillRule="evenodd" clipRule="evenodd" transform="scale(0.5)">
            <path
                d="M5.5 6.491c0-2.481 2.019-4.5 4.5-4.5.879 0 1.693.263 2.386.7l-1.477 1.477A2.502 2.502 0 0 0 10 3.991c-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5 2.5-1.121 2.5-2.5c0-.321-.066-.626-.177-.909L13.8 4.105c.437.693.7 1.508.7 2.386 0 2.481-2.019 4.5-4.5 4.5s-4.5-2.018-4.5-4.5zM5 18v-4h3v-2H3v8h8.528a5.977 5.977 0 0 1-1.033-2H5zm16.957 2.594l-1.414 1.414-2.274-2.274a3.96 3.96 0 0 1-2.019.567c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4c0 .74-.215 1.424-.567 2.019l2.274 2.274zm-3.707-4.292c0-1.103-.897-2-2-2s-2 .897-2 2 .897 2 2 2 2-.898 2-2z"
                fill={fill === 'gradient' ? 'url(#User_Viewer_gradient)' : fill}
                fillRule="evenodd"
            />
            {fill === 'gradient' && <Gradient name="User_Viewer" />}
        </g>
    ),
}
