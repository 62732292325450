import React from 'react'

import { colors } from '@bc/ui/src/config'

import { SvgIconMap } from '../types'

const defaultFill = colors.blue50

export const status_indelivery: SvgIconMap = {
    '32': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-in-delivery"
            d="M19.535,7H16V5H2v2h12v9H8.22C7.67,15.391,6.883,15,6,15c-0.771,0-1.468,0.301-2,0.78V9H2v9h1
            c0,1.654,1.346,3,3,3s3-1.346,3-3h6c0,1.654,1.346,3,3,3s3-1.346,3-3h1v-7.303L19.535,7z M6,19c-0.552,0-1-0.448-1-1s0.448-1,1-1
            s1,0.448,1,1S6.552,19,6,19z M18,19c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S18.552,19,18,19z M20,15.78
            c-0.532-0.48-1.229-0.78-2-0.78s-1.468,0.301-2,0.78V9h2.465L20,11.303V15.78z"
            transform="scale(1.25)"
        />
    ),
    '24': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-in-delivery"
            d="M19.535,7H16V5H2v2h12v9H8.22C7.67,15.391,6.883,15,6,15c-0.771,0-1.468,0.301-2,0.78V9H2v9h1
            c0,1.654,1.346,3,3,3s3-1.346,3-3h6c0,1.654,1.346,3,3,3s3-1.346,3-3h1v-7.303L19.535,7z M6,19c-0.552,0-1-0.448-1-1s0.448-1,1-1
            s1,0.448,1,1S6.552,19,6,19z M18,19c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S18.552,19,18,19z M20,15.78
            c-0.532-0.48-1.229-0.78-2-0.78s-1.468,0.301-2,0.78V9h2.465L20,11.303V15.78z"
        />
    ),
    '16': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-in-delivery"
            d="M19.535,7H16V5H2v2h12v9H8.22C7.67,15.391,6.883,15,6,15c-0.771,0-1.468,0.301-2,0.78V9H2v9h1
            c0,1.654,1.346,3,3,3s3-1.346,3-3h6c0,1.654,1.346,3,3,3s3-1.346,3-3h1v-7.303L19.535,7z M6,19c-0.552,0-1-0.448-1-1s0.448-1,1-1
            s1,0.448,1,1S6.552,19,6,19z M18,19c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S18.552,19,18,19z M20,15.78
            c-0.532-0.48-1.229-0.78-2-0.78s-1.468,0.301-2,0.78V9h2.465L20,11.303V15.78z"
            transform="scale(0.666)"
        />
    ),
    '12': ({ fill = defaultFill }) => (
        <path
            fill={fill}
            id="status-in-delivery"
            d="M19.535,7H16V5H2v2h12v9H8.22C7.67,15.391,6.883,15,6,15c-0.771,0-1.468,0.301-2,0.78V9H2v9h1
            c0,1.654,1.346,3,3,3s3-1.346,3-3h6c0,1.654,1.346,3,3,3s3-1.346,3-3h1v-7.303L19.535,7z M6,19c-0.552,0-1-0.448-1-1s0.448-1,1-1
            s1,0.448,1,1S6.552,19,6,19z M18,19c-0.552,0-1-0.448-1-1s0.448-1,1-1s1,0.448,1,1S18.552,19,18,19z M20,15.78
            c-0.532-0.48-1.229-0.78-2-0.78s-1.468,0.301-2,0.78V9h2.465L20,11.303V15.78z"
            transform="scale(0.5)"
        />
    ),
}
